import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { CollapsibleContainer } from '../../../../common/components/side-menu';
import { Container, Content, LoadingOverlay } from '../../../../common/utils/stylingUtils';
import { StatusCrossClientsPreview } from '../../types';
const PreviewResult = ({ contentHeight, className, results, basicUrl, isLoading, onChange }) => {
    const { t } = useI18n();
    const { clientId } = useParams();
    const [imgLoading, setImgLoading] = useState(false);
    const menuItems = useMemo(() => {
        const clients = Object.values(results || {});
        const items = clients.map((client) => ({
            sectionTitle: client.category,
            title: `${client.displayName} - ${client.os}`,
            key: client.id,
            to: `${basicUrl}/${client.id}/`,
            style: {
                icon: getStyleIcon(client)
            }
        }));
        return sortBy(items, ['sectionTitle', 'title']);
    }, [basicUrl, results]);
    const activeClient = useMemo(() => {
        if (clientId) {
            return results?.[clientId];
        }
    }, [clientId, results]);
    const onLoadImage = useCallback(() => {
        setImgLoading(false);
    }, []);
    useEffect(() => {
        if (!clientId && menuItems[0]?.key) {
            onChange(menuItems[0].key);
        }
    }, [menuItems, clientId, onChange]);
    useEffect(() => {
        if (clientId) {
            setImgLoading(true);
        }
    }, [clientId]);
    return (React.createElement(CollapsibleContainer, { sectionName: "templatePreview", title: t('PREVIEW'), items: menuItems, isFailed: false, isLoading: isLoading, withSearch: true, className: `${className || ''} list-group` },
        activeClient && (React.createElement("div", { className: "previewResultTitle text-center" },
            activeClient.displayName,
            " - ",
            activeClient.os)),
        React.createElement(Container, { className: "previewResultContainer ms-2 me-3" },
            React.createElement(Content, { height: `calc(${contentHeight} + 50px)` },
                (isLoading || imgLoading) && (React.createElement(LoadingOverlay, null,
                    React.createElement(LoadingRectangles, null))),
                activeClient && (React.createElement("div", { className: "d-flex justify-content-evenly align-items-start flex-sm-wrap flex-lg-nowrap w-100" }, Object.values(activeClient.screenshots || {}).map((image, index) => (React.createElement("div", { key: index, className: "p-2" },
                    React.createElement("img", { src: image, onLoad: onLoadImage, className: "mw-100" }))))))))));
};
export default PreviewResult;
const getStyleIcon = (client) => {
    if (client.status == StatusCrossClientsPreview.PENDING ||
        client.status == StatusCrossClientsPreview.PROCESSING) {
        return {
            name: 'spinner',
            color: '#3A3878',
            className: 'fa-spin'
        };
    }
    if (client.status == StatusCrossClientsPreview.BOUNDCED) {
        return {
            name: 'exclamation-triangle',
            color: '#cc3338'
        };
    }
    if (client.category == 'Mobile') {
        return {
            name: 'mobile',
            color: '#fff',
            backgroundColor: '#0074FF'
        };
    }
    if (client.category == 'Application') {
        return {
            name: 'desktop',
            color: '#fff',
            backgroundColor: '#FFA500'
        };
    }
    return {
        name: 'envelope',
        color: '#fff',
        backgroundColor: '#68D391'
    };
};
