import { WORKFLOWS_API_PATH } from '../common/utils/constants';
// ////////////////////
// ProcessMeta single
// ////////////////////
export const fetchProcessMeta = (id) => ({
    type: 'PROCESS_META__FETCH_SINGLE',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${id}`,
            method: 'GET',
            actionContext: { id },
            allowPromiseReject: true
        }
    }
});
export const createProcessMeta = (process) => ({
    type: 'PROCESS_META__CREATE',
    payload: process,
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process`,
            method: 'POST',
            allowPromiseReject: true,
            actionContext: {
                process
            }
        }
    }
});
export const saveProcessMeta = (process) => ({
    type: 'PROCESS_META__SAVE',
    payload: process,
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${process.id}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
export const deleteProcessMeta = (id) => ({
    type: 'PROCESS_META__DELETE',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${id}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: { id }
        }
    }
});
// ////////////////////
// MetaDefinition
// ////////////////////
const metaDefinitionUrl = '/v2-custom-data/definition';
export const fetchMetaDefinition = (metaDefinitionId) => ({
    type: 'META_DEFINITION__FETCH',
    meta: {
        http: {
            path: `${metaDefinitionUrl}/${metaDefinitionId}`,
            method: 'GET',
            actionContext: { metaDefinitionId },
            allowPromiseReject: true
        }
    }
});
export const saveMetaDefinition = (metaDefinition) => ({
    type: 'META_DEFINITION__SAVE',
    payload: metaDefinition,
    meta: {
        http: {
            path: `${metaDefinitionUrl}/${metaDefinition.definitionId}`,
            method: 'POST',
            allowPromiseReject: true,
            actionContext: { metaDefinitionId: metaDefinition.definitionId }
        }
    }
});
