import memoize from 'fast-memoize';
import jwtDecode from 'jwt-decode';
import { has } from 'lodash';
import { PLATFORM_ADMIN_PERMISSION } from '../common/utils/constants';
import { REQUIRED_JWT_PROPERTIES } from './constants';
const getAuthState = (s) => s.auth;
export const getIsApiAuthenticated = (s) => {
    const authState = getAuthState(s);
    return (authState.jwt != null &&
        authState.jwtExpiry != null &&
        new Date(authState.jwtExpiry).getTime() > Date.now());
};
export const getIsUiAuthenticated = (s) => {
    const authState = getAuthState(s);
    return (authState.refreshTokenExpiry != null &&
        new Date(authState.refreshTokenExpiry).getTime() > Date.now());
};
export const getRefreshTokenExpiry = (s) => getAuthState(s).refreshTokenExpiry;
export const getAuthRefreshToken = (s) => getAuthState(s).refreshToken;
export const getAuthJwt = (s) => getAuthState(s).jwt;
export const getAuthJwtExpiry = (s) => getAuthState(s).jwtExpiry;
export const getAuthEmail = (s) => getAuthState(s).email;
export const getAccountId = (s) => getAuthState(s).accountId;
export const isDecodedTokenValid = (decodedToken) => {
    return !!decodedToken && REQUIRED_JWT_PROPERTIES.every((prop) => has(decodedToken, prop));
};
export const getUserTenantPermissions = memoize((jwt) => {
    if (!jwt) {
        return [];
    }
    const decodedJwt = jwtDecode(jwt);
    return isDecodedTokenValid(decodedJwt) ? decodedJwt.tenantPermissions : [];
});
export const getUserGroups = memoize((jwt) => {
    if (!jwt) {
        return [];
    }
    const decodedJwt = jwtDecode(jwt);
    return isDecodedTokenValid(decodedJwt) ? decodedJwt.groups : [];
});
export const hasTenantPermission = (s, permission) => {
    const isAuthenticated = getIsUiAuthenticated(s);
    const jwt = getAuthJwt(s);
    let hasPermission = false;
    if (jwt) {
        const userTenantPermissions = getUserTenantPermissions(jwt);
        hasPermission =
            (permission && userTenantPermissions.includes(permission)) ||
                userTenantPermissions.includes(PLATFORM_ADMIN_PERMISSION);
    }
    return isAuthenticated && hasPermission;
};
export const hasPermission = (userPermissions, requiredPermission) => userPermissions.includes(requiredPermission);
export const hasAnyOfTheGroups = (s, groups) => {
    const isAuthenticated = getIsUiAuthenticated(s);
    const jwt = getAuthJwt(s);
    let hasAnyGroup = false;
    if (jwt) {
        const userGroups = getUserGroups(jwt);
        hasAnyGroup = groups.filter((group) => userGroups.indexOf(group) != -1).length > 0;
    }
    return isAuthenticated && hasAnyGroup;
};
