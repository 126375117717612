import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isString } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WithResizable } from '../../../../../common/components/WithResizable';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import useElementSize from '../../../../../common/hooks/useElementSize';
import { fetchItemReferrals } from '../../../actions';
import DataListContainer from '../../item-property/DataListContainer';
import { InfoPanel } from '../InfoPanel';
import { ACTIVE_TAB_RIGHT_PANEL_KEY } from '../InfoPanel/InfoPanel';
import EditableItemHeader from './EditableItemHeader';
import styles from './EditableItemPage.module.scss';
const LOCAL_STORAGE_KEY = `desktop-right-panel-collapsed-cms`;
const MIN_WIDTH_RIGHT_BLOCK = 400;
const EditableItemPage = ({ definitionId, entityId, loading, blacklistedPropertyIds, entityTitle, baseUrl, canDeleteItem }) => {
    const { ref: contentRef, size: { width: parentWidth } } = useElementSize();
    const { language } = useI18n();
    const dispatch = useDispatch();
    const { isDesktop, isMobile } = useBrowserBreakpoints();
    const location = useLocation();
    const hasActivityTabFromSearch = isString(queryString.parse(location.search)[ACTIVE_TAB_RIGHT_PANEL_KEY]);
    const [infoPanelCollapsed, setInfoPanelCollapsed] = useState(isDesktop && !(localStorage.getItem(LOCAL_STORAGE_KEY) === '0' || hasActivityTabFromSearch));
    useEffect(() => {
        dispatch(fetchItemReferrals(definitionId, entityId, language));
    }, [dispatch, definitionId, entityId, language]);
    const onCollapseChange = useCallback(() => {
        setInfoPanelCollapsed(!infoPanelCollapsed);
        if (isDesktop) {
            localStorage.setItem(LOCAL_STORAGE_KEY, !infoPanelCollapsed ? '1' : '0');
        }
    }, [infoPanelCollapsed, isDesktop]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: `${styles.container} p-2 pt-0` },
            React.createElement(EditableItemHeader, { baseUrl: baseUrl, canDeleteItem: canDeleteItem, definitionId: definitionId, entityId: entityId, entityTitle: entityTitle }),
            React.createElement("div", { className: `scrollable-area ${loading ? 'loading' : ''}` },
                React.createElement("div", { ref: contentRef, className: `data-list-outer d-flex overflow-hidden gap-3 ${infoPanelCollapsed ? 'info-panel-collapsed' : ''}` },
                    React.createElement("div", { className: "data-list-container w-100 overflow-hidden" },
                        React.createElement(DataListContainer, { doubleCol: true, definitionId: definitionId, entityId: entityId, blacklistedPropertyIds: blacklistedPropertyIds, showRequiredFieldsSwitcher: false })),
                    React.createElement(WithResizable, { minWidth: MIN_WIDTH_RIGHT_BLOCK, enabled: !isMobile && !infoPanelCollapsed, uniqKey: definitionId, parentWidth: parentWidth },
                        React.createElement(InfoPanel, { definitionId: definitionId, entityId: entityId, collapsed: infoPanelCollapsed, onCollapseClick: onCollapseChange })))))));
};
export default EditableItemPage;
