import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { FieldWrapper } from '../../FieldElements';
const NumberDisplay = ({ value, disabled, asField, meta: { precision, units, useGrouping }, className, style, tabIndex, onKeyDown }) => {
    const { language } = useI18n();
    const formatted = useMemo(() => {
        if (value !== '' && value !== null && value !== undefined && !isNaN(+value)) {
            return `${Number(value).toLocaleString(language, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
                useGrouping: !!useGrouping
            })} ${getTranslation(units, language)}`;
        }
        return value || '\u00a0';
    }, [language, precision, units, useGrouping, value]);
    return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, className: className ?? '', simple: !asField, disabled: disabled, style: style },
        React.createElement(TooltipTruncate, null, formatted)));
};
export default NumberDisplay;
