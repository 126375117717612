import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils/timeUtils';
import { Tooltip } from 'antd';
import React from 'react';
import { MdFileDownload } from 'react-icons/md';
import styled from 'styled-components';
import { LoadingBlock } from '../../../../common/utils/formUtils';
import { ContactDisplayName } from '../../../../crm';
const Wrapper = styled.div `
  overflow-y: auto;
`;
const LatestVersion = styled.div `
  font-weight: 500;
  color: var(--successColor-6);
`;
const Date = styled.div `
  font-weight: 600;
`;
const Version = styled.div `
  border-top: 1px solid var(--grayscaleColor-3);
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  position: relative;
  background: ${(props) => (props.isActive ? 'var(--primaryColor-1)' : null)};

  ${Date} {
    font-weight: ${(props) => (props.isActive ? '600' : null)};
  }

  &:hover {
    background: var(--primaryColor-1);
  }

  &:last-child {
    border-bottom: 1px solid var(--grayscaleColor-3);
  }
`;
const DownloadButton = styled(MdFileDownload) `
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.3rem;
  height: 1.3rem;

  &:hover {
    color: var(--primaryColor-5);
  }
`;
const TemplateEditor = ({ versions, activeVersion, onVersionChange, onVersionDownloadRequest, className }) => {
    const { t } = useI18n();
    return (React.createElement(Wrapper, { className: className }, !versions || versions.length == 0 ? (React.createElement(LoadingBlock, null)) : (React.createElement("div", null, versions.map((version, index) => (React.createElement(Version, { key: version.modifiedAt, onClick: () => onVersionChange(version), isActive: activeVersion && activeVersion.modifiedAt === version.modifiedAt },
        React.createElement(Date, null, formatDateTime(version.modifiedAt)),
        React.createElement("div", null,
            React.createElement(ContactDisplayName, { contactId: version.modifiedBy })),
        index == 0 && React.createElement(LatestVersion, null, t('LATEST_VERSION')),
        React.createElement(Tooltip, { title: t('TEMPLATE_DOWNLOAD') },
            React.createElement(DownloadButton, { onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onVersionDownloadRequest(version);
                } })))))))));
};
export default TemplateEditor;
