import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import React, { useRef } from 'react';
import { MdClose, MdOutlineSearch } from 'react-icons/md';
import styles from './CollapsibleSearchInput.module.scss';
const CollapsibleSearchInput = ({ onCollapseToggle, collapsed, onChange, value }) => {
    const { t } = useI18n();
    const inputRef = useRef(null);
    const handleOnCollapseToggle = () => {
        if (collapsed && onCollapseToggle) {
            inputRef.current?.focus();
            onCollapseToggle();
        }
    };
    const handleOnChange = (event) => {
        if (event.target instanceof HTMLInputElement) {
            onChange(event.target.value || '');
        }
    };
    const reset = () => {
        onChange('');
    };
    return (React.createElement("div", { className: cn(styles.search, { 'ps-3 pb-3': !collapsed }), id: "collapsible-search-input" },
        React.createElement("div", { className: cn('wrapper', { collapsed, 'position-relative': !collapsed }), onClick: handleOnCollapseToggle },
            collapsed ? (React.createElement("div", { className: "search-icon" },
                React.createElement(MdOutlineSearch, null))) : (React.createElement(MdOutlineSearch, { className: "search-icon" })),
            React.createElement("input", { ref: inputRef, className: "search-input", onChange: handleOnChange, value: value, type: "text", placeholder: t('SEARCH') }),
            value && React.createElement(MdClose, { className: "search-clear", onClick: reset }))));
};
export default CollapsibleSearchInput;
