import { Button, Dropdown, Popover, Tooltip } from 'antd';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { getScrollableAreaOrBody } from '../../utils/common';
import styles from './DropdownButton.module.scss';
const DropdownButton = ({ children, className, disabled, dropdownOverlayClassName, inversed, loading, menu, menuDisabled, menuIcon, menuText, onClick, popover, popoverButton, dropdownRootClassName, placement = 'bottomRight', renderToParent = false, tooltipConfig }) => {
    const getPopupContainer = useCallback((node) => renderToParent && node.parentElement ? node.parentElement : getScrollableAreaOrBody(), [renderToParent]);
    return (React.createElement("span", { className: cn(styles.wrapper, className, 'DropdownButton d-flex', {
            withMenu: menu && children
        }) },
        children && (React.createElement(Button, { className: cn('main d-flex align-items-center', { inversed }), onClick: onClick, loading: loading, disabled: disabled }, children)),
        menu && (React.createElement(Tooltip, { ...tooltipConfig },
            React.createElement(Dropdown, { getPopupContainer: getPopupContainer, menu: menu, trigger: ['click'], placement: placement, disabled: menuDisabled, overlayClassName: dropdownOverlayClassName, rootClassName: dropdownRootClassName },
                React.createElement(Button, { className: "menu d-flex align-items-center gap-2 justify-content-center shadow-none", disabled: menuDisabled },
                    menuText ? React.createElement("span", null, menuText) : null,
                    menuIcon || React.createElement(FaAngleDown, null))))),
        popover && (React.createElement(Popover, { trigger: "click", ...popover, overlayClassName: `${styles.popover} ${popover.overlayClassName || ''}`, getPopupContainer: getPopupContainer }, popoverButton || (React.createElement(Button, { className: cn('popover d-flex align-items-center', { inversed }), disabled: menuDisabled },
            menuIcon || React.createElement(FaAngleDown, null),
            menuText ? React.createElement("span", { className: "me-2" }, menuText) : null))))));
};
export default DropdownButton;
