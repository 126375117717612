import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId } from '../../../auth';
import { createMessage } from '../../../common/conversation/actions';
import { getFileData as getFileDataAction, uploadFiles as uploadFilesAction } from '../../../common/storage';
import { useEntityDisplayData } from '../../common/components/withEntityDisplayData';
import { CMS_ITEM_CONVERSATION_NAMESPACE, ENTITY_ID_PROPERTY_ID } from '../../common/utils/constants';
import useCreateItem from '../../hooks/useCreateItem';
import useDisplayData from '../../hooks/useDisplayData';
import { isFileDisplayData, isFileValue } from '../types';
import { getFlattenedDisplayDataList } from '../utils';
const propertyIdsNotToClone = ['entityId', 'entityOwner', 'entityDomain', 'createdAt'];
const useAddItem = ({ definitionId, clonedItemEntityId }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { mutateAsync: asyncCreateItem } = useCreateItem({
        definitionId
    });
    const { displayData, displayDataStatus } = useEntityDisplayData(definitionId);
    const filteredPropertySections = useMemo(() => (displayData?.data || []).reduce((filteredSections, currentSection) => {
        const filteredProperties = currentSection.values.filter((property) => property.propertyId !== ENTITY_ID_PROPERTY_ID && property.canEdit);
        return filteredProperties.length > 0
            ? filteredSections.concat({ ...currentSection, values: filteredProperties })
            : filteredSections;
    }, []), [displayData]);
    const { data: displayDataList } = useDisplayData({ definitionId, entityId: clonedItemEntityId });
    // In case the new Item needs to be cloned from an existing one
    const initialItem = useMemo(() => {
        const updatedInitialItem = {};
        if (clonedItemEntityId != null) {
            getFlattenedDisplayDataList(displayDataList)
                .filter((property) => !property.meta.hidden)
                .forEach((field) => {
                const { propertyId, canEdit, value } = field;
                if (!propertyIdsNotToClone.includes(propertyId) && canEdit && value != null) {
                    if (isFileDisplayData(field) && Array.isArray(value)) {
                        return (updatedInitialItem[propertyId] = value.map((file) => isFileValue(file) && file.id));
                    }
                    return (updatedInitialItem[propertyId] = value);
                }
            });
        }
        return updatedInitialItem;
    }, [clonedItemEntityId, displayDataList]);
    const getFileData = useSelector((s) => (fileId) => getFileDataAction(s, fileId));
    const accountId = useSelector((s) => getAccountId(s) || '');
    const createItem = useCallback((item) => {
        return asyncCreateItem(item, {
            onError: () => {
                dispatch(error({
                    title: t('ERROR'),
                    message: t('ENTITIES_CREATE_FAIL')
                }));
            }
        });
    }, [asyncCreateItem, dispatch, t]);
    const uploadFiles = useCallback((entityId, propertyId, files) => uploadFilesAction(dispatch, files, entityId).catch(() => {
        void dispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
    }), [dispatch, t]);
    const saveComment = useCallback((entityId, content, mentions, accountId) => 
    // @ts-expect-error
    dispatch(createMessage([CMS_ITEM_CONVERSATION_NAMESPACE, definitionId].join('__'), // We don't consider WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE because processes can't be created with this component
    entityId, accountId, content, mentions)), [dispatch, definitionId]);
    return {
        createItem,
        uploadFiles,
        saveComment,
        propertySections: filteredPropertySections,
        getFileData,
        accountId,
        initialItem,
        displayDataStatus
    };
};
export default useAddItem;
