import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaDefinition } from '../../actions';
import { getMetaDefinition, getMetaDefinitionStatus } from '../../selectors';
const metaDefinitionsToFetch = new Set();
const fetchFromQueue = (fetchMetaDefinition) => {
    metaDefinitionsToFetch.forEach((metaDefinitionId) => {
        fetchMetaDefinition(metaDefinitionId);
    });
    metaDefinitionsToFetch.clear();
};
const debouncedFetchFilesData = debounce(fetchFromQueue);
const addToQueueAndFetch = (metaDefinitionId, fetchMetaDefinition) => {
    metaDefinitionsToFetch.add(metaDefinitionId);
    debouncedFetchFilesData(fetchMetaDefinition);
};
export const useDefinition = (definitionId, { needAllPropertiesById, needAllProperties } = {}) => {
    const dispatch = useDispatch();
    const definition = useSelector((s) => getMetaDefinition(s, definitionId));
    const definitionStatus = useSelector((s) => getMetaDefinitionStatus(s, definitionId));
    const { allPropertiesById, allProperties } = useMemo(() => {
        const collection = {
            allProperties: [],
            allPropertiesById: {}
        };
        if (!definition || (!needAllProperties && !needAllPropertiesById)) {
            return collection;
        }
        for (const section of definition.sections) {
            for (const property of section.properties) {
                if (needAllProperties) {
                    collection.allProperties.push(property);
                }
                if (needAllPropertiesById) {
                    collection.allPropertiesById[property.propertyId] = property;
                }
            }
        }
        return collection;
    }, [definition, needAllProperties, needAllPropertiesById]);
    useEffect(() => {
        if (definitionId && !definitionStatus.isAvailable && !definitionStatus.isFailed) {
            addToQueueAndFetch(definitionId, (defId) => dispatch(fetchMetaDefinition(defId)));
        }
    }, [definitionId, definitionStatus.isAvailable, definitionStatus.isFailed, dispatch]);
    return useMemo(() => ({
        definition,
        definitionStatus,
        allProperties,
        allPropertiesById
    }), [allProperties, allPropertiesById, definition, definitionStatus]);
};
