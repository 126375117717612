import React, { useMemo } from 'react';
import EditableItemPageContainer from '../../../cms/data/components/item/EditableItemPageContainer';
import { GROUP_CMS_DEFINITION_ID, SYSTEM_GROUPS } from '../../constants';
import { useGroups } from '../../hooks/useGroups';
import { findTreeItem, getTreeData } from '../Hierarchy/utils';
const GroupItemRoute = ({ contentHeight, entityId, entityTitle }) => {
    const { groups, parentGroupMap } = useGroups();
    const groupTreeItem = useMemo(() => {
        const treeData = getTreeData(groups, parentGroupMap);
        return findTreeItem(treeData, entityId);
    }, [entityId, groups, parentGroupMap]);
    const canDeleteGroup = useMemo(() => {
        if (!groupTreeItem) {
            return false;
        }
        const isSystemGroup = SYSTEM_GROUPS.includes(groupTreeItem.key);
        return !groupTreeItem.children.length && !isSystemGroup;
    }, [groupTreeItem]);
    return (React.createElement(EditableItemPageContainer, { canDeleteItem: canDeleteGroup, contentHeight: contentHeight, definitionId: GROUP_CMS_DEFINITION_ID, entityId: entityId, entityTitle: entityTitle, key: entityId }));
};
export default GroupItemRoute;
