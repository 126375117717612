import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchOption, getOption, saveOption, updateOption } from '@hypercharge/hyper-react-base/lib/metadata';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { goBack } from '@hypercharge/hyper-react-base/lib/router';
import { AVAILABLE_LANGUAGES, TENANT_ID } from 'config';
import { pick } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { getMetadataState } from '../../../../common/reducers/rootSelector';
import { optionFormName } from '../../../utils/constants';
import OptionForm from './OptionForm';
const onSubmit = (formValues, dispatch, { option, groupId, t, ...otherProps }) => {
    const optionToPersist = {
        ...(option || {}),
        groupId: groupId,
        tenantId: TENANT_ID,
        labels: pick(formValues, AVAILABLE_LANGUAGES),
        ...pick(formValues, ['value', 'dependantGroupId', 'dependantValues'])
    };
    const persistOption = option ? updateOption : saveOption;
    return dispatch(persistOption(optionToPersist))
        .then(() => {
        dispatch(goBack());
    })
        .catch((e) => {
        dispatch(error({
            title: t('ERROR'),
            message: t('ADD_OPTION_FAILED')
        }));
    });
};
class OptionFormContainer extends Component {
    componentDidMount() {
        const { groupId, value } = this.props;
        if (value) {
            this.props.fetchOption(groupId, value);
        }
    }
    render() {
        const { groupId, formValues, initialValues, value, submitting, handleSubmit } = this.props;
        return (React.createElement(OptionForm, { groupId: groupId, formValues: formValues, isNew: !value, isLoading: value && !initialValues, submitting: submitting, handleSubmit: handleSubmit }));
    }
}
function mapStateToProps(state, { match }) {
    const metadataState = getMetadataState(state);
    const groupId = match.params.groupId;
    const value = match.params.value;
    let initialValues;
    let option;
    if (value) {
        option = getOption(metadataState, groupId, value);
        if (option) {
            initialValues = {
                ...option.labels,
                ...pick(option, ['value', 'dependantGroupId', 'dependantValues'])
            };
        }
    }
    return {
        // $FlowFixMe
        groupId,
        value,
        option,
        initialValues,
        formValues: getFormValues(optionFormName)(state)
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), connect(mapStateToProps, { fetchOption }), reduxForm({
    form: optionFormName,
    onSubmit
}))(OptionFormContainer);
