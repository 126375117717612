import cn from 'classnames';
import React from 'react';
import styles from './DrawerContentBlockTitle.module.scss';
export const DrawerContentBlockTitle = ({ icon: Icon, title, className }) => {
    if (!Icon && !title) {
        return null;
    }
    return (React.createElement("div", { className: "d-flex align-items-center gap-2" },
        Icon && React.createElement(Icon, { size: 18, color: "var(--neutralColor-6)" }),
        title && (React.createElement("p", { className: cn('d-flex align-items-center flex-wrap fw-600 fs-5 m-0 gap-2', styles.title, className) }, title))));
};
export default DrawerContentBlockTitle;
