import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { supportedColumnPropertyTypes } from '../../../../../../views';
import { ExtendableSortableItem } from '../../../../../../views/components/SortablePropertyList';
import PropertySelector from '../../../components/PropertySelector/PropertySelector';
import { INSPECTION_DEFINITION_ID } from '../../constants';
import { InspectionSteps } from '../../types';
import { BaseSectionSchema, BaseStepSchema } from '../utils/schema';
import { SectionStepDetailsSettingsForm } from './SectionStepDetailsSettingsForm';
const propertyToCommonStepTypeSections = (property) => ({
    propertyId: property.propertyId
});
export const CommonStepSectionSchema = Yup.object().shape({
    ...BaseSectionSchema.fields,
    properties: Yup.array()
        .of(Yup.object().shape({
        propertyId: Yup.string().required(),
        disabled: Yup.boolean()
    }))
        .required()
});
export const CommonStepTypeSchema = Yup.object().shape({
    ...BaseStepSchema.fields,
    sections: Yup.array().of(CommonStepSectionSchema).required(),
    type: Yup.string().required().oneOf([InspectionSteps.common])
});
const CommonStepDetailsSettingsForm = (props) => {
    const { t } = useI18n();
    const { basicPath, disabled } = props;
    const sectionConfig = useCallback(({ section, sectionIndex }) => (React.createElement(React.Fragment, null,
        React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_SECTION__PROPERTIES') }),
        isEmpty(section.properties) && (React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_SECTION_PROPERTIES__EMPTY'), className: "text-danger" })),
        React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].properties`, component: PropertySelector, customSupportedColumnPropertyTypes: supportedColumnPropertyTypes, definitionId: INSPECTION_DEFINITION_ID, itemComponent: ExtendableSortableItem, disabled: disabled, valueAdapter: propertyToCommonStepTypeSections }))), [basicPath, disabled, t]);
    return React.createElement(SectionStepDetailsSettingsForm, { ...props, sectionConfig: sectionConfig });
};
export default CommonStepDetailsSettingsForm;
