import { BasicSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchAllOptionGroups, getAllOptionGroups } from '@hypercharge/hyper-react-base/lib/metadata';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { getMetadataState } from '../../../../common/reducers/rootSelector';
class OptionGroupField extends Component {
    componentDidMount() {
        const { optionGroups } = this.props;
        if (optionGroups.length === 0) {
            this.props.fetchAllOptionGroups();
        }
    }
    render() {
        const { name, label, optionGroups, language, ...otherProps } = this.props;
        return (React.createElement(Field, { name: name, label: label, component: BasicSelect, options: optionGroups.map((o) => ({
                value: o.groupId,
                labels: { [language]: o.groupId }
            })), language: language, ...otherProps }));
    }
}
function mapStateToProps(state) {
    return {
        optionGroups: getAllOptionGroups(getMetadataState(state))
    };
}
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), connect(mapStateToProps, { fetchAllOptionGroups }))(OptionGroupField);
