import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import useXdmsSettingsForm from './useXdmsSettingsForm';
import { formName } from './utils';
import XdmsSettingsForm from './XdmsSettingsForm';
const XdmsSettingsFormWrapper = compose(reduxForm({
    form: formName,
    enableReinitialize: true // due to https://github.com/erikras/redux-form/issues/561
}))(XdmsSettingsForm);
const XdmsSettingsFormContainer = ({ setLinks, setCurrentBreadcrumbText, ...props }) => {
    const settings = useXdmsSettingsForm({
        setLinks,
        setCurrentBreadcrumbText
    });
    return (React.createElement(XdmsSettingsFormWrapper, { ...props, ...settings, 
        // @ts-expect-error
        formValues: settings.config.formValues, initialValues: settings.config.initialValues }));
};
export default XdmsSettingsFormContainer;
