export const defaultColors = [
    '#fd46a3',
    '#ff87c4',
    '#ff87f6',
    '#b087ff',
    '#9959ff',
    '#87c8ff',
    '#87fff4',
    '#4da0ff',
    '#87ffbd',
    '#b5ff87',
    '#65ff78',
    '#e1ff87',
    '#fff687',
    '#fff365',
    '#ffbe87',
    '#fda346',
    '#ff8787',
    '#f74848'
];
