import React, { useCallback, useMemo } from 'react';
import { TITLE_PROPERTY_ID } from '../../../../common/utils/constants';
import { getPropertyById } from '../../../../common/utils/utils';
import useDisplayData from '../../../../hooks/useDisplayData';
import { useUpdateProperty } from '../../../../hooks/useUpdateProperty';
import EditableItemTitle from '../EditableItemTitle';
const EditableItemTitleContainer = ({ definitionId, entityId, validations, className }) => {
    const { data: displayDataList, isLoading } = useDisplayData({
        definitionId,
        entityId
    });
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const titleProperty = useMemo(() => getPropertyById(displayDataList, TITLE_PROPERTY_ID), [displayDataList]);
    const savePropertyTitle = useCallback((propertyId, value) => updateProperty({ entityId, propertyId, value }), [updateProperty, entityId]);
    return (React.createElement(EditableItemTitle, { loading: isLoading, titleProperty: titleProperty, definitionId: definitionId, entityId: entityId, validations: validations, updateTitle: savePropertyTitle, className: className }));
};
export default EditableItemTitleContainer;
