import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Select } from 'antd';
import { find } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdCreate } from 'react-icons/io';
import { MdHistory } from 'react-icons/md';
import { TITLE_PROPERTY_ID, ValueHistoryDrawer, getEditorComponentForType } from '../../../../cms';
import { useProperties } from '../../../../cms/common/context/PropertiesProvider';
import { InlineEditor } from '../../../../common/components/InlineEditor';
import { useDashboards } from '../DashboardsProvider';
import { DASHBOARDS_ENTITY_ID } from '../constants';
import styles from './DashboardSelector.module.scss';
const DashboardSelector = ({ dashboard, onChange, onSelect, onAdd, validations, className }) => {
    const { t } = useI18n();
    const { dashboards } = useDashboards();
    const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
    const [editing, setEditing] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { getDisplayData } = useProperties(DASHBOARDS_ENTITY_ID);
    const dashboardsList = useMemo(() => {
        if (!dashboards || !dashboards.length) {
            return dashboards;
        }
        const currentDashboard = find(dashboards, { entityId: dashboard.entityId });
        if (currentDashboard) {
            return dashboards;
        }
        return [...dashboards, dashboard];
    }, [dashboard, dashboards]);
    const dashboardsListOptions = dashboardsList.map((dashboard) => ({
        value: dashboard.entityId,
        label: dashboard.title
    }));
    const toggleEditing = useCallback(() => {
        setEditing((prev) => {
            return !prev;
        });
    }, []);
    const toggleHistoryDrawer = useCallback(() => {
        setHistoryDrawerOpen((prev) => {
            return !prev;
        });
    }, []);
    const canEdit = useMemo(() => {
        return !!getDisplayData(TITLE_PROPERTY_ID)?.canEdit;
    }, [getDisplayData]);
    const updateTitle = useCallback(async (value) => {
        if (onChange && typeof value === 'string') {
            await onChange(value);
        }
        setEditing(false);
    }, [onChange]);
    const onHistorySave = useCallback((propId, value) => {
        return updateTitle(value);
    }, [updateTitle]);
    const _onAdd = useCallback(() => {
        onAdd && onAdd();
        setDropdownVisible(false);
    }, [onAdd]);
    return (React.createElement("div", { className: `${styles.wrapper} me-3 d-flex flex-row w-100 dashboard-selector ${className || ''}` },
        editing ? (React.createElement(InlineEditor, { autoFocus: true, displayComponent: () => null, editorComponent: getEditorComponentForType('text'), value: dashboard.title || '', canEdit: canEdit, onSave: updateTitle, onHistoryOpened: toggleHistoryDrawer, meta: { validations }, invalid: false, onCancel: toggleEditing })) : (React.createElement(React.Fragment, null,
            dashboardsList.length ? (React.createElement(Select, { onDropdownVisibleChange: setDropdownVisible, open: dropdownVisible, className: `selector ant-select-styled2 me-2`, value: dashboard.entityId, onChange: onSelect, popupMatchSelectWidth: false, popupClassName: "antd-dropdown-popup-styled2", showSearch: true, optionFilterProp: "label", options: dashboardsListOptions, size: "large", dropdownRender: onAdd
                    ? (menu) => (React.createElement(React.Fragment, null,
                        menu,
                        React.createElement(Button, { className: `${styles.addBtn} w-100`, type: "text", onClick: _onAdd },
                            React.createElement(FaPlus, { className: "me-2" }),
                            t('HYPER_BI_ADD_DASHBOARD'))))
                    : undefined })) : null,
            React.createElement("div", { className: "edit-actions d-flex flex-row align-items-center" },
                canEdit && !editing && (React.createElement("div", { role: "button", onClick: toggleEditing, className: "me-2 safe-space" },
                    React.createElement(IoMdCreate, { className: "edit-btn", size: "1.1em" }))),
                React.createElement("div", { role: "button", onClick: toggleHistoryDrawer, className: "safe-space" },
                    React.createElement(MdHistory, { className: "history-btn", size: "1.1em" }))))),
        React.createElement(ValueHistoryDrawer, { open: historyDrawerOpen, definitionId: DASHBOARDS_ENTITY_ID, entityId: dashboard.entityId, propertyId: TITLE_PROPERTY_ID, onSave: onHistorySave, onClose: toggleHistoryDrawer })));
};
export default DashboardSelector;
