import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import NavbarLink from './NavbarLink';
const Container = styled.div `
  padding: 0;
  user-select: none;
`;
const Icon = styled.div `
  line-height: 1.4rem;
  font-size: 1.4rem;
`;
const Text = styled.div `
  line-height: 1.8rem;
  padding-right: 0.2rem;
  padding-left: 0.5rem;
`;
class Navbar extends Component {
    constructor() {
        super(...arguments);
        this.getLinkJsx = ({ to, text, icon, ...otherProps }) => (React.createElement(NavbarLink, { key: `${to}-${text}`, to: to, spacing: this.props.spacing, exact: !!this.props.exactMatching, entity: this.props.entity, ...otherProps },
            React.createElement("div", { className: "cp-c-row" },
                icon && React.createElement(Icon, { className: "cp-i-none" }, icon),
                !this.props.showOnlyIcons && React.createElement(Text, { className: "cp-i-none" }, text))));
    }
    render() {
        const { className, links } = this.props;
        return (React.createElement(Container, { className: classnames('cp-c-row', className) }, links.map(this.getLinkJsx)));
    }
}
Navbar.defaultProps = {
    spacing: '10px'
};
export default Navbar;
