import { Component } from 'react';
class TabCloseConfirmation extends Component {
    constructor() {
        super(...arguments);
        this.updateUnloadHandler = (e) => {
            if (this.props.dirty) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
            else {
                null;
            }
        };
    }
    componentDidMount() {
        window.addEventListener('beforeunload', this.updateUnloadHandler);
    }
    componentDidUpdate() {
        window.addEventListener('beforeunload', this.updateUnloadHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.updateUnloadHandler);
    }
    render() {
        return null;
    }
}
export default TabCloseConfirmation;
