import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { getDisplayComponentForType, getEditorComponentForType } from '../../../../../cms';
import ConfirmationButtonModal from '../../../../../common/components/ConfirmationButtonModal';
import DimensionsListener from '../../../../../common/components/DimensionsListener/DimensionsListener';
import { InlineEditor } from '../../../../../common/components/InlineEditor';
import { fetchFilesData } from '../../../../../common/storage';
import { DOCUMENTS_PATH, DOCUMENT_TEMPLATES_PATH } from '../../../../constants';
import { useDeleteDocumentTemplate } from '../../../../hooks/useDeleteDocumentTemplate';
import { useSaveDocumentTemplate } from '../../../../hooks/useSaveDocumentTemplate';
import { useTemplateVersions } from '../../../../hooks/useTemplateVersions';
import FileUploader from '../FileUploader';
import PDFViewer from '../PDFViewer';
import TemplateVersionList from '../TemplateVersionList';
import styles from './DocumentTemplateEditor.module.scss';
const heightSelectors = ['#header', '.breadcrumbs', '.editor-toolbar'];
const DocumentTemplateEditor = ({ templateId, fetchFilesData, success, error, push }) => {
    const { t } = useI18n();
    const { mutateAsync: saveTemplate } = useSaveDocumentTemplate();
    const { mutateAsync: deleteTemplate } = useDeleteDocumentTemplate();
    const [activeVersion, setActiveVersion] = useState();
    const [title, setTitle] = useState(undefined);
    const { data: { results: versions } = {}, isLoading, isError } = useTemplateVersions({
        templateId,
        onSuccess: (data) => {
            setActiveVersion(data.results[0]);
            setTitle(data.results[0]?.title);
        }
    });
    const [submitting, setSubmitting] = useState(false);
    const [fileData, setFileData] = useState(undefined);
    const onFileChange = async (promise) => {
        try {
            setSubmitting(true);
            const filesData = await promise;
            setFileData(filesData[0]);
            await saveTemplateVersion(undefined, filesData[0]);
        }
        catch (e) {
            error({ title: t('ERROR'), message: t('UPLOAD_FAILED') });
        }
        setSubmitting(false);
    };
    const saveTemplateVersion = async (newTitle, newFileData) => {
        setSubmitting(true);
        try {
            let docxFileId;
            if (newFileData) {
                docxFileId = newFileData.id;
            }
            else if (fileData) {
                docxFileId = fileData.id;
            }
            else if (activeVersion) {
                docxFileId = activeVersion.docxFileId;
            }
            if (!docxFileId) {
                throw new Error(t('TEMPLATE_SAVE_FAIL'));
            }
            const newTemplate = await saveTemplate({
                docxFileId,
                id: templateId,
                title: newTitle || title || 'New template'
            });
            if (!templateId) {
                push(`${DOCUMENTS_PATH}${DOCUMENT_TEMPLATES_PATH}/${newTemplate.id}`);
            }
        }
        catch (e) {
            error({ title: t('ERROR'), message: t('TEMPLATE_SAVE_FAIL') });
        }
        setSubmitting(false);
    };
    const deleteThisTemplate = async () => {
        if (templateId) {
            try {
                await deleteTemplate(templateId);
                success({
                    title: t('SUCCESS'),
                    message: t('DELETE_TEMPLATE__SUCCESS_MESSAGE')
                });
                push(`${DOCUMENTS_PATH}${DOCUMENT_TEMPLATES_PATH}`);
            }
            catch (e) {
                error({
                    title: t('ERROR'),
                    message: t('DELETE_TEMPLATE__ERROR_MESSAGE')
                });
            }
        }
    };
    const getSidebarJsx = (templateId) => (React.createElement(React.Fragment, null,
        React.createElement(FileUploader, { className: "cp-i-none", onChange: onFileChange, disabled: isLoading || submitting, dropFileText: t('TEMPLATE_DROP_NEW_FILE_TEXT'), entityId: templateId }),
        React.createElement("div", { className: `${styles.versionHistory} cp-i-flex cp-c-column` },
            React.createElement("div", { className: "list-header cp-i-none" }, t('TEMPLATE_VERSION_HISTORY')),
            React.createElement(TemplateVersionList, { className: "cp-i-flex", versions: versions || [], activeVersion: activeVersion, onVersionChange: (version) => setActiveVersion(version), onVersionDownloadRequest: async (version) => {
                    const filesData = await fetchFilesData([version.docxFileId]);
                    window.open(filesData[0].downloadUrl, '_blank');
                } }))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('TEMPLATES_NAV_LINK'), to: DOCUMENTS_PATH },
                {
                    text: `${t('DOCUMENTS_TEMPLATES_NAV_LINK')}`,
                    to: `${DOCUMENTS_PATH}${DOCUMENT_TEMPLATES_PATH}`
                }
            ], currentBreadcrumbText: templateId ? title || '' : t('CREATE') }),
        React.createElement("div", { className: styles.wrapper },
            isError && React.createElement(NotFound, null),
            templateId && !isError && (React.createElement("div", { className: "editor-toolbar d-flex justify-content-between" },
                title ? (React.createElement(InlineEditor, { className: "inline-editor-extended", displayComponent: getDisplayComponentForType('text'), editorComponent: getEditorComponentForType('text'), value: title || '', placeholder: '...', canEdit: true, hideActions: false, allowClear: false, 
                    // @ts-expect-error
                    onSave: (newTitle) => saveTemplateVersion(newTitle) })) : (React.createElement(Skeleton, { isLoading: true },
                    React.createElement(TextPlaceholder, { className: "title-placeholder", heightInPx: 23, widthInPx: 200 }))),
                React.createElement(ConfirmationButtonModal, { className: "action-button delete-btn", tooltip: t('DELETE_TEMPLATE'), confirmationTitle: t('DELETE_TEMPLATE'), confirmationMessage: t('DELETE_TEMPLATE_CONFIRMATION'), danger: true, onClick: deleteThisTemplate, disabled: isLoading },
                    React.createElement(FaTrash, null)))),
            templateId && !isError && (React.createElement(DimensionsListener, { elementSelectors: heightSelectors, trackHeight: true }, (width, height) => (React.createElement("div", { className: `${styles.columnWrapper} cp-c-row`, style: { height: `calc(100vh - ${height}px - 1.5rem - 2px)` } },
                React.createElement("div", { className: `${styles.sidebar} cp-i-none cp-c-column` }, getSidebarJsx(templateId)),
                React.createElement("div", { className: "cp-i-flex" }, activeVersion ? React.createElement(PDFViewer, { fileId: activeVersion.pdfFileId }) : null))))),
            !templateId && (React.createElement(FileUploader, { className: "create-file-uploader cp-i-none", onChange: onFileChange, dropFileText: t('TEMPLATE_DROP_FILE_TEXT') })))));
};
export default connect(null, {
    fetchFilesData,
    success,
    error,
    push
    // @ts-expect-error
})(DocumentTemplateEditor);
