import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { compact } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { useViews } from '../../../../views';
import { SystemSectionsVisibilityProvider } from '../../../common/context/SystemSectionsVisibilityProvider';
import { CMS_PATH, TITLE_PROPERTY_ID } from '../../../common/utils/constants';
import { getPropertyById } from '../../../common/utils/utils';
import useDisplayData from '../../../hooks/useDisplayData';
import EditableItemPage from './EditableItemPage';
const blacklistedPropertyIds = [TITLE_PROPERTY_ID];
const hiddenSections = ['system'];
const EditableItemPageContainer = ({ contentHeight, definitionId, viewId, entityId, entityTitle, setLinks, setCurrentBreadcrumbText, baseUrl = CMS_PATH, canDeleteItem }) => {
    const { t } = useI18n();
    const { getViewBreadcrumbLink } = useViews();
    const { data: displayDataList, isLoading } = useDisplayData({ definitionId, entityId });
    const titleProperty = useMemo(() => getPropertyById(displayDataList, TITLE_PROPERTY_ID), [displayDataList]);
    useEffect(() => {
        if (setCurrentBreadcrumbText && setLinks && baseUrl) {
            setCurrentBreadcrumbText(titleProperty?.value || (isLoading ? '...' : t('NO_TITLE')));
            setLinks(compact([
                { text: t('CMS_NAV_LINK'), to: CMS_PATH },
                { text: entityTitle, to: `${baseUrl}/${definitionId}${BROWSE_PATH}` },
                getViewBreadcrumbLink(`${baseUrl}/${definitionId}`, entityTitle, viewId)
            ]));
        }
    }, [
        baseUrl,
        definitionId,
        entityTitle,
        getViewBreadcrumbLink,
        isLoading,
        setCurrentBreadcrumbText,
        setLinks,
        titleProperty,
        t,
        viewId
    ]);
    return (React.createElement(SystemSectionsVisibilityProvider, { id: definitionId, hiddenSections: hiddenSections },
        React.createElement(EditableItemPage, { baseUrl: baseUrl, blacklistedPropertyIds: blacklistedPropertyIds, canDeleteItem: canDeleteItem, contentHeight: contentHeight, definitionId: definitionId, entityId: entityId, entityTitle: entityTitle, loading: isLoading })));
};
export default EditableItemPageContainer;
