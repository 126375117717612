import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../auth';
import { useView } from '../../../views/components/ViewContext';
import { ActionWhen } from './types';
const ActionButtons = ({ className, actions, itemsIds }) => {
    const { isAllItemsSelected, selectedItems, setSelectedItems, changePage, setIsAllItemsSelected, filterRequest, page, totalItemsCount, pageSize } = useView();
    const unselectedIds = useMemo(() => itemsIds.filter((id) => !selectedItems.includes(id)), [itemsIds, selectedItems]);
    const reloadDataTable = useCallback((resetPage) => {
        const newMaxPage = isAllItemsSelected
            ? 1
            : Math.max(Math.ceil((totalItemsCount - selectedItems.length) / pageSize), 1);
        if (resetPage && page > newMaxPage) {
            changePage(newMaxPage);
            setIsAllItemsSelected(false);
        }
    }, [
        changePage,
        isAllItemsSelected,
        page,
        pageSize,
        selectedItems.length,
        setIsAllItemsSelected,
        totalItemsCount
    ]);
    const hasPermission = useSelector((s) => (permission) => hasTenantPermission(s, permission));
    const filteredActions = useMemo(() => actions
        .filter(({ permission }) => !permission || hasPermission(permission))
        .filter((action) => {
        if (action.when === ActionWhen.selected) {
            return selectedItems.length || isAllItemsSelected;
        }
        if (action.when === ActionWhen.noSelected) {
            return !selectedItems.length && !isAllItemsSelected;
        }
        return true;
    }), [actions, hasPermission, isAllItemsSelected, selectedItems.length]);
    const clearSelection = useCallback((action) => {
        if (action.clearSelection) {
            setSelectedItems([]);
        }
    }, [setSelectedItems]);
    const onClick = useCallback(async (action, ...actionArgs) => {
        if (action.action) {
            await action.action({
                selectedIds: isAllItemsSelected ? undefined : selectedItems,
                allSelected: isAllItemsSelected,
                query: isAllItemsSelected ? filterRequest : undefined,
                totalCount: totalItemsCount,
                allIds: itemsIds,
                unselectedIds,
                page,
                pageSize
            }, ...actionArgs);
        }
        reloadDataTable(action.resetPage);
        clearSelection(action);
    }, [
        clearSelection,
        filterRequest,
        isAllItemsSelected,
        itemsIds,
        page,
        pageSize,
        reloadDataTable,
        selectedItems,
        totalItemsCount,
        unselectedIds
    ]);
    const additionalProps = {
        selectedIds: isAllItemsSelected ? undefined : selectedItems,
        filterRequest: isAllItemsSelected ? filterRequest : undefined
    };
    return (React.createElement("div", { className: `${className || ''}` }, filteredActions.map((action) => {
        const ActionButton = action.buttonComponent || Button;
        return (React.createElement(ActionButton, { key: action.key, ...action.buttonProps, ...(ActionButton !== Button && { ...additionalProps }), className: `actionButton ${action.buttonProps?.className || ''}`, onClick: (...args) => onClick(action, ...args) }, action.buttonContent));
    })));
};
export default ActionButtons;
