import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { INITIAL_PAGE_SIZE } from 'config';
import { forEach, map, pick } from 'lodash';
import queryString from 'query-string';
import { combineReducers } from 'redux';
export const initialListState = {
    pending: true,
    failed: false,
    idsByPage: {},
    totalCount: 0,
    page: 1,
    pageSize: INITIAL_PAGE_SIZE,
    lastFetch: null // TODO: find a use for it
};
export const getParamsKey = (params) => queryString.stringify(pick(params, ['showDeleted', 'showOpened']));
const groupedLists = createReducer({}, {
    NOTIFICATIONS__FETCH_USER_NOTIFICATIONS: (s, a) => {
        const { params, page, pageSize } = a.meta.http.actionContext;
        const paramsKey = getParamsKey(params);
        let gState = s[paramsKey];
        if (!gState) {
            gState = { ...initialListState };
            s[paramsKey] = gState;
        }
        if (pageSize !== gState.pageSize) {
            gState.idsByPage = {};
        }
        gState.pending = true;
        gState.failed = false;
        gState.page = page;
        gState.pageSize = pageSize;
    },
    NOTIFICATIONS__FETCH_USER_NOTIFICATIONS_FAIL: (s, a) => {
        const paramsKey = getParamsKey(a.meta.actionContext.params);
        const gState = s[paramsKey];
        gState.pending = false;
        gState.failed = true;
    },
    NOTIFICATIONS__FETCH_USER_NOTIFICATIONS_SUCCESS: (s, a) => {
        const { params, page } = a.meta.actionContext;
        const { results, totalCount } = a.payload;
        const paramsKey = getParamsKey(params);
        const gState = s[paramsKey];
        gState.idsByPage[page] = map(results, 'id');
        gState.totalCount = totalCount;
        gState.pending = false;
        gState.failed = false;
        gState.lastFetch = new Date();
    }
});
const byId = createReducer({}, {
    NOTIFICATIONS__FETCH_USER_NOTIFICATIONS_SUCCESS: (s, a) => {
        a.payload.results.forEach((userNotification) => {
            s[userNotification.id] = userNotification;
        });
    },
    NOTIFICATIONS__UPDATE_USER_NOTIFICATION_SUCCESS: (s, a) => {
        const { id, action } = a.meta.actionContext;
        const notification = s[id];
        const now = new Date().getTime();
        if (notification) {
            switch (action) {
                case 'OPEN':
                    notification.opened = true;
                    notification.openedAt = now;
                    break;
                case 'DELETE':
                    notification['deleted'] = true;
                    notification.deletedAt = now;
                    break;
            }
        }
    },
    NOTIFICATIONS__MARK_ALL_AS_READ_SUCCESS: (s, a) => {
        const now = new Date().getTime();
        forEach(s, (notification) => {
            if (!notification.opened) {
                notification.opened = true;
                notification.openedAt = now;
            }
        });
    }
});
const reducer = combineReducers({
    groupedLists,
    byId
});
// ////////////////////
// combined state
// ////////////////////
export default reducer;
