import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchEntityDataProperty } from '../data/actions';
const HISTORY_QUERY_KEY = 'display-data-history';
export const invalidateHistoryDataQuery = async ({ definitionId, entityIds }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => {
            return (query.queryKey.includes(HISTORY_QUERY_KEY) &&
                query.queryKey.includes(definitionId) &&
                (entityIds ? entityIds.some((entityId) => query.queryKey.includes(entityId)) : true));
        }
    });
};
const LIMIT = 20;
const queryFn = async (dispatch, definitionId, entityId, propertyId, languageCode, limit, offset, propertyIdsExcluded) => {
    return dispatch(fetchEntityDataProperty(definitionId, entityId, propertyId, languageCode, limit, offset, propertyIdsExcluded));
};
const excludedProperties = ['updatedAt', 'processStatusUpdatedAt', 'processActivity'];
export const useHistoryData = ({ definitionId, entityId, propertyId }, options) => {
    const fetchDisplayDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const { t, language } = useI18n();
    return useInfiniteQuery([HISTORY_QUERY_KEY, definitionId, entityId, propertyId], ({ pageParam }) => queryFn(fetchDisplayDispatch, definitionId, entityId, propertyId, language, LIMIT, pageParam, propertyId ? undefined : excludedProperties), {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage && lastPage?.length < LIMIT) {
                return;
            }
            return pages.length * LIMIT;
        },
        enabled: options?.enabled && !!definitionId && !!entityId,
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        onError: (err) => {
            notificationDispatch(error({ title: t('ERROR'), message: t('SOMETHING_WENT_WRONG') }));
            options?.onError?.(err);
        }
    });
};
