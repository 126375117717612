import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Input } from 'antd';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { DrawerContent, DrawerFooter } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../common/components/Drawer/DrawerFormFooter';
import { MultiLanguageInput, MultiLanguageValidationSchema } from '../../../../../common/components/MultiLanguageField';
import FormikField from '../../../../../common/components/formik/FormikField';
const validationSchema = Yup.object({
    titles: MultiLanguageValidationSchema,
    values: Yup.array().of(Yup.string())
});
const FeaturedDefinitionIdFolderForm = ({ loading, initValue, submitText, onSave, onClose }) => {
    const { t } = useI18n();
    const initialValues = useMemo(() => initValue || {
        id: generateId(),
        titles: {},
        values: []
    }, [initValue]);
    const validate = useCallback((value) => {
        try {
            void validateYupSchema(value, validationSchema, true, value);
        }
        catch (err) {
            // for rendering validation errors
            return yupToFormErrors(err);
        }
        return {};
    }, []);
    const onSubmit = useCallback((values) => {
        onSave(values);
        onClose();
    }, [onSave, onClose]);
    return (React.createElement(Formik, { initialValues: initialValues, validate: validate, onSubmit: onSubmit }, ({ handleSubmit }) => (React.createElement(Form, { className: "d-flex flex-column flex-fill", noValidate: true, onSubmit: handleSubmit },
        React.createElement(DrawerContent, { loading: loading },
            React.createElement(DrawerContentBlock, null,
                React.createElement(FormikField, { name: "titles", component: MultiLanguageInput, maxLength: 60, fieldComponent: Input }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { submitText: submitText || t('ADD'), onSubmit: () => handleSubmit(), onCancel: onClose }))))));
};
export default FeaturedDefinitionIdFolderForm;
