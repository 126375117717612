import { Tooltip } from 'antd';
import { range } from 'lodash';
import React, { Children, cloneElement } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useI18n } from '../../i18n';
import { media } from '../../styling';
import ModalConfirmationButton from '../confirmation-button';
import { Skeleton, TextPlaceholder } from '../skeletons';
export { default as MobileHorizontalScrollTip } from './MobileHorizontalScrollTip';
const StyledTable = styled.table `
  box-shadow: ${({ theme }) => theme.cardBoxShadow};
  border-radius: ${({ theme }) => theme.cardBorderRadius};
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;

  font-size: 0.9rem;

  th.center,
  td.center {
    text-align: center;
  }

  .actions {
    pointer-events: none;
    padding: 0 15px;

    ${media.sm} {
      width: 10rem;
    }

    white-space: nowrap;
    word-break: normal;
    overflow-wrap: normal;
    word-wrap: normal;

    & > * {
      pointer-events: auto;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      min-height: 24px; // makes it so that the delete confirmation wont jump if in a very tall row
    }
  }
`;
export const Table = ({ className, children }) => (React.createElement(StyledTable, { className: `data-table ${className || ''}` }, children));
// border-collapse: collapse;
export const BaseHeader = styled.thead `
  border-top-left-radius: ${({ theme }) => theme.cardBorderRadius};
  border-top-right-radius: ${({ theme }) => theme.cardBorderRadius};
  background-color: #fff;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);

  > tr {
    border-top-left-radius: ${({ theme }) => theme.cardBorderRadius};
    border-top-right-radius: ${({ theme }) => theme.cardBorderRadius};

    > :first-child {
      border-top-left-radius: ${({ theme }) => theme.cardBorderRadius};
    }

    > :last-child {
      border-top-right-radius: ${({ theme }) => theme.cardBorderRadius};
    }
  }
`;
export const Header = ({ children }) => (React.createElement(BaseHeader, null,
    React.createElement("tr", null, children)));
export const HeaderCell = styled.th `
  padding: 0.8rem 15px;
  vertical-align: middle;
  border-bottom: 1px solid #d3dce0;
  font-weight: normal;
  text-align: left;
`;
export const ActualRow = styled.tr `
  background-color: #fff;
  color: ${({ theme }) => theme.textColor} !important;

  > td {
    border-bottom: 1px solid #e5ebed;

    &:empty::after {
      content: ' '; /* empty cells are not well processed by some browsers */
    }
  }

  &:last-child,
  &:last-child > td {
    border-bottom: 0;
    border-bottom-left-radius: ${({ theme }) => theme.cardBorderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.cardBorderRadius};
  }

  &:hover {
    background-color: ${({ theme }) => theme.mainColorSoft};
  }
  position: relative;

  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'inherit')};
`;
export const Row = ({ to, children, className }) => {
    const history = useHistory();
    return (React.createElement(ActualRow, { isLink: !!to, className: className, onClick: () => to && history && history.push(to) }, children));
};
export const Cell = styled.td `
  padding: 0.8rem 15px;
  vertical-align: middle;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.5rem;
`;
const InfoRowWrapper = styled.tr `
  > td {
    background-color: #fff;
    padding: 2rem 0;
    text-align: center;
    color: ${({ theme }) => theme.textColor};
    border-bottom: 1px solid #e5ebed;
  }
`;
export const InformationRow = ({ children, cellLength, className }) => (React.createElement(InfoRowWrapper, { className: className },
    React.createElement("td", { colSpan: cellLength }, children)));
export const ActualBody = styled.tbody ``;
const getCells = (cellLength = 0) => range(cellLength).map((i) => (React.createElement(Cell, { key: 'cell' + i },
    React.createElement(Skeleton, { isLoading: true },
        React.createElement(TextPlaceholder, { heightInPx: 24.4 })))));
export const Body = ({ children, loading, loadingRows = 5, cellLength, ...otherProps }) => (React.createElement(ActualBody, { ...otherProps }, loading
    ? range(loadingRows).map((i) => React.createElement(Row, { key: `row-${i}` }, getCells(cellLength)))
    : Children.map(children, (child) => child && cloneElement(child, { cellLength: cellLength }))));
const DeleteButton = styled(FaRegTrashAlt) `
  fill: ${({ theme }) => theme.textColor};

  :hover {
    fill: #e00000;
  }
`;
export const DeleteConfirmationButton = ({ onClick, className, confirmText, originalStyle, confirmStyle, children, deleteIconSize = 24, ...otherProps }) => {
    const { t } = useI18n();
    return (React.createElement(ModalConfirmationButton, { onClick: onClick, confirmText: confirmText, originalStyle: {
            backgroundColor: 'transparent',
            border: '0',
            ...originalStyle
        }, confirmStyle: {
            borderColor: 'transparent',
            padding: '0.25rem 0.5rem',
            marginRight: '0.5rem',
            ...confirmStyle
        }, className: className, ...otherProps }, children || (React.createElement(Tooltip, { placement: "top", title: t('COMMON__DELETE') },
        React.createElement(DeleteButton, { size: deleteIconSize })))));
};
