import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import accepts from 'attr-accept';
import cn from 'classnames';
import { isObject, isString, pick } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { IoMdCreate } from 'react-icons/io';
import { MdBackup, MdHistory, MdViewCarousel } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { FieldLabel, FieldWrapper } from '../../../../cms/data/components/item-property/FieldElements';
import { CancelIcon } from '../../../../icons';
import SortableFileWrapper, { SortableFile, SortableFileListContainer, SortableHandle } from '../../../components/SortableFileWrapper';
import { DELAY_CAPTURE } from '../../../components/SortableFileWrapper/constants';
import { getParamsFromEvent, resetSortStyles } from '../../../components/SortableFileWrapper/utils';
import { TooltipTruncate } from '../../../components/TooltipTruncate';
import { getDataTransferItems, isFileUrlDataT, uploadFiles } from '../../utils';
import FileCarousel from '../FileCarousel/FileCarousel';
import styles from './FileField.module.scss';
const acceptImage = 'image/*';
const propertiesForFilter = ['id', 'downloadUrl', 'name', 'extension', 'type', 'size'];
const FileItem = ({ file, isImage, canRemove, onRemove, sortable }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "cursor-pointer file-outer" },
        sortable && React.createElement(SortableHandle, null),
        isImage && (React.createElement("div", { className: "image-preview" },
            React.createElement("img", { src: file.downloadUrl, alt: file.name }))),
        React.createElement("div", { className: "label-wrapper" },
            React.createElement("a", { href: file.downloadUrl, target: "_blank", rel: "noopener noreferrer", draggable: "false" },
                React.createElement(TooltipTruncate, null, file.name)))),
    canRemove && (React.createElement("div", { className: "file-edit-actions" },
        React.createElement(DeleteConfirmationButton, { className: "delete-button", onClick: () => onRemove(file.id), originalStyle: { padding: 0 }, confirmStyle: { margin: 0 } },
            React.createElement("div", { role: "button", className: "safe-space" },
                React.createElement(CancelIcon, null)))))));
const FileField = ({ canEdit, children, className, displayActions = false, error, files, label, meta, showIcon = false, fieldStyle, layout = 'horizontal', onSave, onChange, onRemove, onFilesMoved, onHistoryOpened, loading: loadingProp, entityId, allowRemove, carouselHeight, propertyId, isDraggableFileCarousel, definitionId }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const [isDroppingAcceptable, setIsDroppingAcceptable] = useState(false);
    const [draggableTarget, setDraggableTarget] = useState(null);
    const [editingImages, setEditingImages] = useState(false);
    const input = useRef(null);
    const sortableListNode = useRef(null);
    const hasValue = (files &&
        files?.length > 0 &&
        files.reduce((result, file) => isObject(file) && !!file.id && result, true)) ||
        false;
    const isImage = meta.accept == acceptImage;
    const accept = meta.accept ? meta.accept.replace(/\|/g, ',') : undefined;
    const showSpinner = uploading || ((loading || loadingProp) && !hasValue);
    const isSlider = hasValue && meta.carousel && !editingImages;
    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    const handleDragEnter = useCallback((e) => {
        const files = getDataTransferItems(e);
        e.preventDefault();
        const acceptable = canEdit &&
            files.every((file) => (meta.accept ? accepts(file, meta.accept.split(/[,|]/)) : true)) &&
            (meta.list || (!meta.list && files.length == 1));
        setIsDraggedOver(true);
        setIsDroppingAcceptable(acceptable);
        setDraggableTarget(e.target);
    }, [canEdit, meta]);
    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        if (draggableTarget == e.target) {
            setIsDraggedOver(false);
            setIsDroppingAcceptable(false);
        }
    }, [draggableTarget]);
    const handleFileUpload = useCallback((e) => {
        e.preventDefault();
        const files = getDataTransferItems(e);
        if (!files.length) {
            return;
        }
        const acceptable = canEdit &&
            files.every((file) => (meta.accept ? accepts(file, meta.accept.split(/[,|]/)) : true)) &&
            (meta.list || (!meta.list && files.length === 1));
        setLoading(acceptable);
        setUploading(acceptable);
        setIsDraggedOver(false);
        setIsDroppingAcceptable(false);
        setDraggableTarget(null);
        if (acceptable) {
            if (onSave) {
                onSave(files).finally(() => {
                    setUploading(false);
                    setLoading(false);
                });
            }
            else {
                if (dispatch) {
                    uploadFiles(dispatch, meta.list && files ? [...files, ...files] : files, entityId || 'withou-groupId')
                        .then((uploadedFiles) => {
                        onChange && onChange(uploadedFiles);
                    })
                        .finally(() => {
                        setUploading(false);
                        setLoading(false);
                    });
                }
            }
        }
    }, [canEdit, dispatch, entityId, meta, onChange, onSave]);
    const openFileBrowser = useCallback((e) => {
        e.preventDefault();
        if (input.current) {
            input.current.click();
        }
    }, []);
    const removeFile = useCallback(async (fileId) => {
        setLoading(true);
        if (onRemove) {
            await onRemove(fileId);
            setLoading(false);
        }
        else {
            const filteredFiles = files?.filter(isFileUrlDataT).filter((file) => file.id != fileId) || [];
            onChange && onChange(filteredFiles);
            setLoading(false);
        }
    }, [files, onChange, onRemove]);
    const onSortEnd = useCallback(({ oldIndex, newIndex, isCarousel }, event) => {
        if (!canEdit) {
            return;
        }
        if (onChange) {
            const newFiles = (files && arrayMove(files.filter(isFileUrlDataT), oldIndex, newIndex)) || [];
            onChange(newFiles);
        }
        if (onFilesMoved && isString(propertyId)) {
            const { destinationDefinitionId, destinationEntityId, destinationPropertyId } = getParamsFromEvent(event);
            if (destinationPropertyId) {
                onFilesMoved({
                    oldIndex,
                    newIndex,
                    definitionIdFromList: definitionId,
                    entityIdFromList: entityId,
                    propertyIdFromList: propertyId,
                    definitionIdToList: destinationDefinitionId,
                    entityIdToList: destinationEntityId,
                    propertyIdToList: destinationPropertyId,
                    isCarousel
                });
                resetSortStyles(sortableListNode);
            }
            else {
                console.error('destinationPropertyId not found');
            }
        }
    }, [canEdit, definitionId, entityId, files, onChange, onFilesMoved, propertyId]);
    const filesWithFilteredProperties = useMemo(() => files?.map((file) => pick(file, propertiesForFilter)), [files]);
    return (React.createElement(SortableFileWrapper, { className: `${styles.container} ${className || ''}`, definitionId: definitionId, entityId: entityId, propertyId: propertyId, onDragEnter: handleDragEnter, onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: canEdit ? handleFileUpload : undefined },
        label && (React.createElement(FieldLabel, { className: `${layout === 'vertical' ? 'w-100' : ''}`, label: label, icon: showIcon ? React.createElement(AiOutlineFile, null) : null })),
        React.createElement(FieldWrapper, { style: fieldStyle, disabled: !canEdit, className: `editor-field ${layout === 'vertical' ? 'w-100' : ''} ${hasValue ? 'has-value' : ''} ${isSlider ? 'slider' : ''}`, error: error },
            React.createElement("div", { className: "edit-actions" },
                canEdit && (React.createElement(Tooltip, { title: t('UPLOAD_FILES') },
                    React.createElement("div", { role: "button", onClick: openFileBrowser, className: "safe-space" },
                        React.createElement(MdBackup, { className: "upload-button" })))),
                displayActions &&
                    (showSpinner ? (React.createElement("div", { className: "loader" })) : (React.createElement(React.Fragment, null,
                        canEdit &&
                            meta.carousel &&
                            hasValue &&
                            (editingImages ? (React.createElement(Tooltip, { title: t('VIEW_IMAGE_GALLERY') },
                                React.createElement(MdViewCarousel, { className: "carousel-button", onClick: () => setEditingImages(false) }))) : (React.createElement(Tooltip, { title: t('EDIT_IMAGES') },
                                React.createElement(IoMdCreate, { className: "edit-images-button", onClick: () => setEditingImages(true) })))),
                        onHistoryOpened && (React.createElement(Tooltip, { title: t('VIEW_VALUE_HISTORY') },
                            React.createElement("div", { role: "button", onClick: () => {
                                    onHistoryOpened();
                                }, className: "safe-space" },
                                React.createElement(MdHistory, { className: "view-history-button" })))))))),
            canEdit && (React.createElement("input", { className: "file-upload-input", type: "file", accept: accept, tabIndex: -1, onChange: (e) => void handleFileUpload(e), ref: input, multiple: meta.list, onClick: () => {
                    if (input.current) {
                        input.current.value = '';
                    }
                } })),
            hasValue && (React.createElement("div", { className: `files-container ${isSlider ? 'slider' : ''}` },
                React.createElement(SortableFileListContainer, { ...(isSlider ? { pressDelay: DELAY_CAPTURE } : {}), useDragHandle: !isSlider, ref: sortableListNode, onSortEnd: (sort, event) => onSortEnd({ ...sort, isCarousel: isSlider }, event) }, isSlider ? (React.createElement(FileCarousel, { height: carouselHeight, files: filesWithFilteredProperties, imageCover: meta.imageCover, propertyId: propertyId, isDraggableFile: isDraggableFileCarousel })) : (React.createElement(React.Fragment, null,
                    canEdit && (React.createElement("div", { className: "label-values ps-3", onClick: (!uploading && openFileBrowser) || undefined }, t('UPLOAD_FILES'))),
                    files?.map((f, i) => {
                        if (!isObject(f)) {
                            return null;
                        }
                        return (React.createElement(SortableFile, { key: f.id, index: i, className: cn('sortable', { image: isImage }, styles.fileItem) },
                            React.createElement(FileItem, { file: f, canRemove: !!(canEdit && (onRemove || allowRemove)), onRemove: removeFile, isImage: isImage, sortable: canEdit })));
                    })))))),
            !hasValue && canEdit && (React.createElement("div", { className: "upload-placeholder", onClick: (!uploading && openFileBrowser) || undefined }, children || (uploading ? React.createElement("div", { className: "loader" }) : t('UPLOAD_FILES')))))));
};
export default FileField;
