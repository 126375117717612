import React, { useMemo } from 'react';
import { GoDatabase } from 'react-icons/go';
import { IoMdGitNetwork } from 'react-icons/io';
import useDisplayItemMeta from '../../../../../../../cms/hooks/useDisplayItemMeta';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import SideMenuIcon from '../../../../../../../common/components/side-menu/SideMenuIcon/SideMenuIcon';
import { PROCESS_META_HANDLER } from '../../../../../../../common/utils/constants';
import { useViews } from '../../../../../../../views';
import FeaturedDefinitionIdRowActions from '../FeaturedDefinitionIdRowActions/FeaturedDefinitionIdRowActions';
const FeaturedDefinitionId = ({ featuredDefinitionId, className = '', onDelete }) => {
    const { views } = useViews();
    const view = views.find((view) => view.entityId === featuredDefinitionId);
    const definitionId = useMemo(() => {
        if (view) {
            return view.referenceDefinitionId;
        }
        return featuredDefinitionId;
    }, [featuredDefinitionId, view]);
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const { title, style, icon } = useMemo(() => {
        const icon = displayItemMeta?.handler === PROCESS_META_HANDLER ? IoMdGitNetwork : GoDatabase;
        const style = displayItemMeta?.style;
        return {
            title: view?.title || displayItemMeta?.title || displayItemMeta?.definitionId,
            icon,
            style
        };
    }, [displayItemMeta, view]);
    return (React.createElement("div", { className: `${className} py-2 d-flex align-items-center justify-content-between` },
        React.createElement("div", { className: "d-flex align-items-center flex-truncate" },
            React.createElement(SideMenuIcon, { style: style, itemIcon: icon }),
            React.createElement("div", { className: "ms-1 d-flex align-items-center flex-truncate" },
                React.createElement(TooltipTruncate, null, title))),
        React.createElement("div", { className: "d-flex align-items-center" },
            React.createElement(FeaturedDefinitionIdRowActions, { onDelete: onDelete }))));
};
export default FeaturedDefinitionId;
