import styled from 'styled-components';
export const Header = styled.div `
  z-index: 1000;
  background-color: var(--grayscaleColor-1);
  color: var(--primaryColor-6);
  padding: 0 1rem;
  height: 3rem;

  @media print {
    display: none;
  }
`;
export const LogOut = styled.div `
  cursor: pointer;
  transition: color 200ms ease;

  &:hover {
    color: var(--primaryColor-4);
  }
`;
