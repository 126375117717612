import { compact, isObject } from 'lodash';
import { fetchUploadUrl, uploadFile } from './actions';
function getOrientation(file, callback) {
    const reader = new FileReader();
    reader.onload = function (e) {
        // @ts-expect-error
        const view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xffd8) {
            return callback(-2);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
            if (view.getUint16(offset + 2, false) <= 8) {
                return callback(-1);
            }
            const marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xffe1) {
                if (view.getUint32((offset += 2), false) != 0x45786966) {
                    return callback(-1);
                }
                const little = view.getUint16((offset += 6), false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                const tags = view.getUint16(offset, little);
                offset += 2;
                for (let i = 0; i < tags; i++) {
                    if (view.getUint16(offset + i * 12, little) == 0x0112) {
                        // @ts-expect-error
                        return callback(view.getUint16(offset + i * 12 + 8, little), e.target.result);
                    }
                }
            }
            else if ((marker & 0xff00) != 0xff00) {
                break;
            }
            else {
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file);
}
function encode(input) {
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;
    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        }
        else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
}
function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}
const base64Prefix = 'data:image/jpeg;base64,';
export const uploadFiles = (dispatch, files, groupId) => Promise.all(compact(files).map((f) => {
    // if it is already persisted we don't actually need to upload it
    if ('id' in f) {
        return Promise.resolve(f);
    }
    return new Promise((resolve, reject) => {
        dispatch(fetchUploadUrl(f, groupId))
            .then((data) => {
            const { uploadUrl } = data;
            if (!uploadUrl) {
                reject(`uploadUrl not found in data`);
                return;
            }
            getOrientation(f, (res, content) => {
                let fileToUpload;
                if (res == 6 || res == 8) {
                    const img = document.createElement('img');
                    const bytes = new Uint8Array(content);
                    img.src = base64Prefix + encode(bytes);
                    img.onload = function () {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.height;
                        canvas.height = img.width;
                        if (res == 6) {
                            ctx?.rotate(0.5 * Math.PI);
                            ctx?.translate(0, -canvas.width);
                        }
                        else if (res == 8) {
                            ctx?.rotate(-0.5 * Math.PI);
                            ctx?.translate(-canvas.height, 0);
                        }
                        ctx?.drawImage(img, 0, 0);
                        const dataUrl = canvas.toDataURL('image/jpeg');
                        const binaryArray = base64ToArrayBuffer(dataUrl.split(base64Prefix)[1]);
                        const blob = new File([binaryArray], f.name, { type: f.type });
                        fileToUpload = blob;
                        dispatch(uploadFile(fileToUpload, data, uploadUrl))
                            .then(() => resolve(data))
                            .catch(reject);
                    };
                }
                else {
                    fileToUpload = f;
                    dispatch(uploadFile(fileToUpload, data, uploadUrl))
                        .then(() => resolve(data))
                        .catch(reject);
                }
            });
        })
            .catch(reject);
    });
}));
export const getDataTransferItems = (e) => {
    const dataTransferItemsList = [];
    if ('dataTransfer' in e) {
        const dt = e.dataTransfer;
        if (dt?.files) {
            for (let i = 0; i < dt.files.length; i++) {
                dataTransferItemsList.push(dt.files[i]);
            }
        }
        else if (dt?.items) {
            for (let i = 0; i < dt.items.length; i++) {
                if (dt.items[i].kind === 'file') {
                    const file = dt.items[i].getAsFile();
                    if (file) {
                        dataTransferItemsList.push(file);
                    }
                }
            }
        }
    }
    else {
        const dt = e.target;
        if (dt?.files) {
            for (let i = 0; i < dt.files.length; i++) {
                dataTransferItemsList.push(dt.files[i]);
            }
        }
    }
    return dataTransferItemsList;
};
export const isFileUrlDataT = (obj) => {
    return isObject(obj) && 'id' in obj && 's3Location' in obj;
};
