import { BasicInput, email as validateEmail } from '@hypercharge/hyper-react-base/lib/form';
import React, { useCallback, useMemo, useState } from 'react';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './EmailEditor.module.scss';
const EmailEditor = ({ onChange, value, disabled, onKeyDown, autoFocus = true, editing = true, editingInline, errorMessage }) => {
    const [initialValue] = useState(value);
    const [temporaryValue, setTemporaryValue] = useState(value);
    const error = useMemo(() => (editingInline ? validateEmail(temporaryValue) : undefined) || errorMessage, [editingInline, temporaryValue, errorMessage]);
    const onChangeField = useCallback((e) => {
        const target = e.target;
        const newValue = target.value ? target.value.toLowerCase().trim() : null;
        const validationError = validateEmail(newValue);
        onChange && onChange(!validationError || !editingInline ? newValue : initialValue);
        setTemporaryValue(newValue);
    }, [onChange, initialValue, editingInline]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!error },
            React.createElement(BasicInput
            // @ts-expect-error
            , { 
                // @ts-expect-error
                input: { value: !temporaryValue ? '' : temporaryValue }, autoFocus: autoFocus, onChange: onChangeField, disabled: disabled, onKeyDown: onKeyDown })),
        React.createElement(FieldError, { error: error })));
};
export default EmailEditor;
