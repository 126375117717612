import React, { useMemo } from 'react';
import { IconWrapper, StyledFontAwesomeIcon } from '../../../components/side-menu/SideMenuIcon/styles';
const ActivityIcon = ({ activityType, className }) => {
    const icon = useMemo(() => {
        return (React.createElement(IconWrapper, null, activityType ? (React.createElement(StyledFontAwesomeIcon, { icon: activityType.icon, className: `rounded text-white ${className}`, style: {
                padding: '4px',
                backgroundColor: activityType.color
            } })) : null));
    }, [className, activityType]);
    return icon;
};
export default ActivityIcon;
