import { SESEventType } from '../../../../../common/utils/types';
const getAggregatedEmailsOfMail = ({ mail }) => [
    ...new Set([
        ...mail.destination,
        ...(mail.commonHeaders?.cc || []),
        ...(mail.commonHeaders?.bcc || [])
    ])
];
export class EmailSendingDetailsAggregation {
    constructor(messageId, mailBodies) {
        this.messageId = '';
        this.statuses = [];
        this.successes = 0;
        this.fails = 0;
        this.recipients = null;
        this.cc = null;
        this.bcc = null;
        this.bodyId = null;
        this.timestamp = 0;
        this.from = null;
        this.subject = null;
        this.clicks = 0;
        this.opens = 0;
        this.emails = {};
        this.commonSuccessStatuses = [];
        this.mailBodies = [];
        this.messageId = messageId;
        this.mailBodies = mailBodies;
    }
    applyStatus(status) {
        this.statuses.push(status);
        switch (status.status) {
            case 'Send':
                this.processSendStatus(status);
                break;
            case 'Delivery':
                this.processDeliveryStatus(status);
                break;
            case 'Bounce':
                this.processBounceStatus(status);
                break;
            case 'Complaint':
                this.processComplaintStatus(status);
                break;
            case 'DeliveryDelay':
                this.processDeliveryDelayStatus(status);
                break;
            case 'Reject':
            case 'Rendering Failure':
                this.processRejectStatus(status);
                break;
            case 'Open':
                this.processOpenStatus(status);
                break;
            case 'Click':
                this.processClickStatus(status);
                break;
        }
        this.recipients = this.recipients || status.mail.commonHeaders?.to || status.mail.destination;
        this.cc = this.cc || status.mail.commonHeaders?.cc || null;
        this.bcc = this.bcc || status.mail.commonHeaders?.bcc || null;
        this.from = this.from || status.mail.source;
        this.subject = this.subject || status.mail.commonHeaders?.subject || null;
        this.timestamp = status.timestamp;
        if (!this.bodyId && status.mail.tags.itemIndex?.length) {
            this.bodyId = this.mailBodies[parseInt(status.mail.tags.itemIndex[0])];
        }
    }
    processSendStatus(status) {
        const emails = getAggregatedEmailsOfMail(status);
        emails.forEach((email) => {
            const emailItem = this.getEmailsItemForEmail(email);
            if (!emailItem.hasSend) {
                emailItem.hasSend = true;
                emailItem.statuses.push(status);
            }
        });
        const alreadyPushed = !!this.commonSuccessStatuses.find((status) => status.status === SESEventType.SEND);
        if (!alreadyPushed) {
            this.commonSuccessStatuses.push(status);
        }
    }
    processDeliveryStatus(status) {
        this.successes++;
        (status.description?.recipients || []).forEach((email) => {
            const emailItem = this.getEmailsItemForEmail(email);
            emailItem.isSuccess = true;
            emailItem.statuses.push(status);
        });
        const alreadyPushed = !!this.commonSuccessStatuses.find((status) => status.status === SESEventType.DELIVERY);
        if (!alreadyPushed) {
            this.commonSuccessStatuses.push(status);
        }
    }
    processBounceStatus(status) {
        this.fails++;
        if (status.description && 'bouncedRecipients' in status.description) {
            this.processErrorRecipientsList(status.description.bouncedRecipients, status);
        }
    }
    processComplaintStatus(status) {
        this.fails++;
        if (status.description && 'complainedRecipients' in status.description) {
            this.processErrorRecipientsList(status.description.complainedRecipients, status);
        }
    }
    processDeliveryDelayStatus(status) {
        this.fails++;
        if (status.description && 'delayedRecipients' in status.description) {
            this.processErrorRecipientsList(status.description.delayedRecipients, status);
        }
    }
    processRejectStatus(status) {
        this.fails++;
        this.processEmailsAsError(getAggregatedEmailsOfMail(status), status);
    }
    processOpenStatus(status) {
        this.opens++;
        this.commonSuccessStatuses.push(status);
    }
    processClickStatus(status) {
        this.clicks++;
        this.commonSuccessStatuses.push(status);
    }
    getEmailsItemForEmail(email) {
        this.emails[email] = this.emails[email] || {
            hasSend: false,
            isError: false,
            isSuccess: false,
            statuses: []
        };
        return this.emails[email];
    }
    processErrorRecipientsList(recipients, status) {
        const emails = recipients.map((recipient) => recipient.emailAddress);
        this.processEmailsAsError(emails, status);
    }
    processEmailsAsError(emails, status) {
        emails.forEach((email) => {
            const emailItem = this.getEmailsItemForEmail(email);
            emailItem.isError = true;
            emailItem.statuses.push(status);
        });
    }
}
