import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isNaN } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FieldLabel } from '../../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../../common/components/AntInput';
import { ComputedInput } from '../../../../../../../common/components/ComputedExpressionEditor';
import { Select } from '../../../../../../../common/components/Select';
const DeadlineInput = ({ value, onChange, className, disabled, label }) => {
    const { t } = useI18n();
    const unitOptions = useMemo(() => {
        return ['minutes', 'hours', 'days', 'weeks', 'months'].map((type) => ({
            value: type,
            label: t(`DEADLINE_${type.toUpperCase()}`)
        }));
    }, [t]);
    const onChangeNumber = useCallback((numberValue) => {
        if (numberValue == null ||
            typeof numberValue === 'number' ||
            typeof numberValue === 'string') {
            onChange?.({
                ...value,
                value: numberValue
            });
        }
    }, [onChange, value]);
    const onChangeUnit = useCallback((unit) => {
        if (unit == null || typeof unit === 'string') {
            onChange?.({
                ...value,
                unit: unit
            });
        }
    }, [onChange, value]);
    const onNumberComputedModeChange = useCallback((isComputeMode) => {
        if (value?.value !== undefined && !isComputeMode && !isNumeric(value?.value)) {
            onChangeNumber(undefined);
        }
    }, [onChangeNumber, value?.value]);
    return (React.createElement("div", { className: className },
        label && React.createElement(FieldLabel, { label: label, className: "ps-0" }),
        React.createElement("div", { className: "d-flex align-items-center" },
            React.createElement("div", { className: "w-100" },
                React.createElement(ComputedInput, { component: AntInput, type: "number", disabled: disabled, onChange: onChangeNumber, value: value?.value, onComputedModeChange: onNumberComputedModeChange, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('COMMON__DEADLINE')}` })),
            React.createElement("div", { className: "ms-2 w-25" },
                React.createElement(ComputedInput, { component: Select, className: "w-100", options: unitOptions, disabled: disabled, onChange: onChangeUnit, value: value?.unit, placeholder: `${t('SELECT_INPUT')} ${t('COMMON__DEADLINE')}` })))));
};
const isNumeric = (num) => {
    return (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num);
};
export default DeadlineInput;
