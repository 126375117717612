import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchDisplayData } from '../data/actions';
export const DISPLAY_DATA_QUERY_KEY = 'display-data';
export const invalidateDisplayDataQuery = async ({ definitionId, entityIds }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => {
            return (query.queryKey.includes(DISPLAY_DATA_QUERY_KEY) &&
                query.queryKey.includes(definitionId) &&
                (entityIds ? entityIds.some((entityId) => query.queryKey.includes(entityId)) : true));
        }
    });
};
const queryFn = async (fetchDisplayDispatch, definitionId, entityId) => {
    if (!definitionId || !entityId) {
        return;
    }
    return fetchDisplayDispatch(fetchDisplayData(definitionId, entityId));
};
const useDisplayData = ({ definitionId, entityId }, options) => {
    const fetchDisplayDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const { t } = useI18n();
    return useQuery([DISPLAY_DATA_QUERY_KEY, definitionId, entityId], () => queryFn(fetchDisplayDispatch, definitionId, entityId), {
        enabled: options?.enabled && !!definitionId && !!entityId,
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        onError: (err) => {
            notificationDispatch(error({ title: t('ERROR'), message: t('PERMISSION_DENIED') }));
            options?.onError?.(err);
        }
    });
};
export const useHandleGetDisplayData = () => {
    const fetchDisplayDispatch = useDispatch();
    const handleFetch = async ({ definitionId, entityId }) => {
        return await queryClient.fetchQuery({
            queryKey: [DISPLAY_DATA_QUERY_KEY, definitionId, entityId],
            queryFn: () => queryFn(fetchDisplayDispatch, definitionId, entityId),
            cacheTime: oneHourInMs,
            staleTime: oneMinuteInMs
        });
    };
    return {
        handleFetch
    };
};
export default useDisplayData;
