import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Button, Collapse, Tooltip } from 'antd';
import { FieldArray } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { IoEye, IoEyeOff, IoReorderThree } from 'react-icons/io5';
import * as Yup from 'yup';
import FieldLabel from '../../../../../../cms/data/components/item-property/FieldElements/FieldLabel';
import { AntInput } from '../../../../../../common/components/AntInput';
import { MultiLanguageInput } from '../../../../../../common/components/MultiLanguageField';
import { Select } from '../../../../../../common/components/Select';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { InspectionSteps } from '../../types';
import { CommonStepTypeSchema } from './CommonStepDetailsSettingsForm';
import { GalleryStepTypeSchema } from './GalleryStepDetailsSettingsForm';
import { ListStepTypeSchema } from './ListStepDetailsSettingsForm';
import StepDetailsSettingsForm from './StepDetailsSettingsForm';
import { TableStepTypeSchema } from './TableStepDetailsSettingsForm';
const generateDynamicSchema = (step) => {
    switch (step.type) {
        case InspectionSteps.common:
            return CommonStepTypeSchema;
        case InspectionSteps.gallery:
            return GalleryStepTypeSchema;
        case InspectionSteps.list:
            return ListStepTypeSchema;
        case InspectionSteps.table:
            return TableStepTypeSchema;
        default:
            return CommonStepTypeSchema;
    }
};
export const getInspectionSettingsFormSchema = () => {
    return Yup.object({
        // @ts-expect-error - This is a valid schema
        steps: Yup.array().of(Yup.lazy((step) => generateDynamicSchema(step)))
    });
};
const InspectionSettingsForm = ({ inspection, setFieldValue, disabled }) => {
    const { t, language } = useI18n();
    const [openedCollapses, setOpenedCollapse] = useState(new Map());
    const onCollapseChange = useCallback(({ stepId, sectionId }) => {
        const newMap = new Map(openedCollapses);
        if (!stepId && !sectionId) {
            for (const key of newMap.keys()) {
                newMap.delete(key);
            }
        }
        if (stepId && !sectionId) {
            newMap.has(stepId) ? newMap.delete(stepId) : newMap.set(stepId, new Set());
        }
        if (stepId && sectionId) {
            const currentStep = newMap.get(stepId);
            if (!currentStep) {
                return;
            }
            currentStep.has(sectionId)
                ? newMap.set(stepId, new Set(currentStep).add(sectionId))
                : newMap.set(stepId, currentStep.add(sectionId));
        }
        setOpenedCollapse(newMap);
    }, [openedCollapses]);
    const onDragEnd = useCallback((result) => {
        if (!result.destination || !inspection?.steps) {
            return;
        }
        const newValue = [...inspection.steps];
        const [removed] = newValue.splice(result.source.index, 1);
        newValue.splice(result.destination.index, 0, removed);
        setFieldValue(`configuration.inspection.steps`, newValue);
    }, [inspection?.steps, setFieldValue]);
    return (React.createElement("div", { className: "row gy-3" },
        React.createElement(FieldArray, { name: "configuration.inspection.steps", render: (arrayHelpers) => {
                return (React.createElement("div", null,
                    React.createElement("div", { className: "d-flex align-items-center" },
                        React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS__HEADER') }),
                        React.createElement(Tooltip, { title: t('APPS_HYPER_INSPECTION_STEPS__ADD') },
                            React.createElement(Button, { type: "text", onClick: () => arrayHelpers.push({
                                    id: generateId(),
                                    titles: {
                                        en: 'New Step',
                                        pl: 'Nowy Krok',
                                        fr: 'Nouvelle Étape',
                                        nl: 'Nieuwe Stap',
                                        de: 'Neuer Schritt',
                                        it: 'Nuovo Passo'
                                    }
                                }), icon: React.createElement(FaPlus, null) }))),
                    isEmpty(inspection?.steps) && (React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS__EMPTY') })),
                    React.createElement(DragDropContext, { onDragEnd: onDragEnd },
                        React.createElement(Droppable, { droppableId: "steps", type: "STEPS" }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: `sections-list droppable-item ${droppableSnapshot.isDraggingOver ? 'dragging-over' : ''}`, ref: droppableProvided.innerRef },
                            inspection?.steps?.map((step, stepIndex) => {
                                const stepPatch = `steps.[${stepIndex}]`;
                                return (React.createElement(Draggable, { draggableId: stepPatch, index: stepIndex, key: stepPatch }, (draggableProvided, draggableSnapshot) => (React.createElement("div", { ref: draggableProvided.innerRef, className: `meta-section-item drag-item ms-3 ${draggableSnapshot.isDragging ? 'dragging-item' : ''}`, ...draggableProvided.draggableProps },
                                    React.createElement(Collapse, { bordered: false, activeKey: openedCollapses.has(step.id) ? step.id : undefined, onChange: () => onCollapseChange({ stepId: step.id }), className: "collapse-outer", items: [
                                            {
                                                label: (React.createElement("div", { className: "d-flex align-items-center justify-content-between" },
                                                    React.createElement(TooltipTruncate, null, step.titles?.[language]),
                                                    React.createElement("div", { className: "d-flex align-items-center" },
                                                        React.createElement(Tooltip, { placement: "topRight", title: t('APPS_HYPER_INSPECTION_STEP__HIDDEN_TOOLTIP_TEXT') },
                                                            React.createElement(Button, { type: "text", icon: step.hidden ? (React.createElement(IoEyeOff, { size: 24 })) : (React.createElement(IoEye, { size: 24 })), onClick: (event) => {
                                                                    event.stopPropagation();
                                                                    setFieldValue(`configuration.inspection.steps[${stepIndex}].hidden`, !step.hidden);
                                                                } })),
                                                        React.createElement(Button, { danger: true, className: "ms-2 bg-transparent", onClick: (event) => {
                                                                event.stopPropagation();
                                                                arrayHelpers.remove(stepIndex);
                                                            }, icon: React.createElement(FaTrash, null) }),
                                                        React.createElement(Button, { className: `ms-2 bg-transparent`, onClick: (event) => {
                                                                event.stopPropagation();
                                                            }, icon: React.createElement(IoReorderThree, { size: "1.25rem" }), ...draggableProvided.dragHandleProps })))),
                                                key: step.id,
                                                children: (React.createElement("div", null,
                                                    React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG__STEP_ID') }),
                                                    React.createElement(AntInput, { value: step.id, disabled: true }),
                                                    React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS__TITLES') }),
                                                    React.createElement(FormikField, { name: `configuration.inspection.steps[${stepIndex}].titles`, component: MultiLanguageInput, disabled: disabled, fieldComponent: AntInput }),
                                                    React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS__TYPE') }),
                                                    React.createElement(FormikField, { name: `configuration.inspection.steps[${stepIndex}].type`, component: Select, disabled: disabled, optionLabelProp: "label", options: Object.values(InspectionSteps).map((type) => ({
                                                            label: t(`APPS_HYPER_INSPECTION_STEPS__TYPE-${type}`),
                                                            value: type
                                                        })) }),
                                                    React.createElement(StepDetailsSettingsForm, { disabled: disabled, step: step, setFieldValue: setFieldValue, basicPath: `configuration.inspection.steps[${stepIndex}]`, openedCollapses: openedCollapses, onCollapseChange: onCollapseChange })))
                                            }
                                        ] })))));
                            }),
                            React.createElement(React.Fragment, null, droppableProvided.placeholder)))))));
            } })));
};
export default InspectionSettingsForm;
