import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { datePattern, timePattern } from '@hypercharge/hyper-react-base/lib/utils';
import { Collapse } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../../auth';
import { RichTextDisplay } from '../../../../../cms';
import { invalidateActivityCache } from '../../../../../common/activity/useActivities';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { APP_ADMIN_PERMISSION } from '../../../../../common/utils/constants';
import CountdownTimer from '../../../../common/components/CountdownTimer/CountdownTimer';
import { TASK_META_OVERRIDE_PERMISSION } from '../../../../common/utils/constants';
import { getManualTaskProperties, getTaskTitle, isManualTask } from '../../../../tasks/utils';
import AssigneeSelector from '../AssigneeSelector';
import { useRequiredFields } from '../RequiredFieldsProvider';
import TaskFieldsContainer from '../TaskFieldsContainer';
import TaskInlineEditor from '../TaskInlineEditor';
import styles from './AvailableTask.module.scss';
import AvailableTaskActivityForm from './AvailableTaskActivityForm';
import IframeUrl from '../IframeUrl';
const AvailableTask = ({ className, task, loading, onSubmit, onAssignTask, submitting, onTaskToggle, isActive, canEdit, requiredFieldsConfig, permissionFieldsConfig, contactId: myContactId, onChangePriority, onChangeDeadline }) => {
    const { taskRunId, description, assignedTo, taskMetaId, processRunId, processMetaId, canExecute, resolveTime, taskTarget, parameters } = task;
    const { selectedTaskActivityMap: { [taskRunId]: currentActivity }, onChangeTaskActivity } = useRequiredFields();
    const { t } = useI18n();
    const canEditTasks = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION) ||
        hasTenantPermission(s, TASK_META_OVERRIDE_PERMISSION));
    const [followUp, setFollowUp] = useState();
    const isManual = isManualTask(task);
    const { iframe } = parameters || {};
    const assignedToMeTask = assignedTo === myContactId;
    const isMarkAsDoneDisabled = !assignedTo || !assignedToMeTask || loading || submitting;
    const optionalProperties = useMemo(() => {
        return getManualTaskProperties(task, 'optional', currentActivity);
    }, [currentActivity, task]);
    const taskDescription = useMemo(() => {
        if (!description) {
            return t('NONE');
        }
        return React.createElement(RichTextDisplay, { value: description });
    }, [description, t]);
    const onChangeAssign = useCallback((contactId) => {
        return onAssignTask(taskMetaId, taskRunId, contactId);
    }, [onAssignTask, taskMetaId, taskRunId]);
    const onChangeActivity = useCallback((val) => {
        onChangeTaskActivity(taskRunId, val);
        if (!assignedTo) {
            void onChangeAssign(myContactId);
        }
    }, [assignedTo, myContactId, onChangeAssign, onChangeTaskActivity, taskRunId]);
    const onChangeProperty = useCallback(async () => {
        if (!assignedTo) {
            return onChangeAssign(myContactId);
        }
    }, [assignedTo, myContactId, onChangeAssign]);
    const onMarkAsDone = useCallback(async () => {
        await onSubmit(task, {
            activityItem: currentActivity,
            followUp
        });
        if (currentActivity) {
            await invalidateActivityCache(processMetaId, processRunId);
        }
    }, [currentActivity, followUp, onSubmit, processMetaId, processRunId, task]);
    return (React.createElement("div", { className: `${styles.task} ${className || ''}` },
        React.createElement(Collapse, { bordered: false, activeKey: isActive ? [taskRunId] : [], items: [
                {
                    key: taskRunId,
                    collapsible: isManual ? 'header' : 'disabled',
                    label: (React.createElement("div", { className: styles.taskTitleWrapper },
                        isManual && (React.createElement("button", { className: styles.taskArrow, type: "button", onClick: () => onTaskToggle(taskRunId) },
                            React.createElement(FontAwesomeIcon, { icon: "angle-down" }))),
                        React.createElement("div", { className: `${styles.taskTitle} ${!isManual ? styles.taskTitle__notCollapsible : ''}` },
                            React.createElement("span", null,
                                React.createElement(TooltipTruncate, null, getTaskTitle(task)),
                                !!resolveTime && taskTarget === 'DELAY' && (React.createElement(CountdownTimer, { resolveTime: resolveTime })))),
                        !!resolveTime && taskTarget === 'DECISION' && resolveTime > Date.now() && (React.createElement("div", { className: styles.taskContentTopBlock }, t('TASK_AWAITING_EVENT', {
                            remainingDelayTime: moment(resolveTime).format(datePattern + ' ' + timePattern),
                            interpolation: { escapeValue: false }
                        }))),
                        isManual && (React.createElement(React.Fragment, null,
                            React.createElement(AssigneeSelector, { popupClassName: styles.assigneeDropdown, inlineEditorClassName: styles.assigneeInlineEditor, value: assignedTo, assignTo: onChangeAssign, taskMetaId: taskMetaId, taskRunId: taskRunId, processRunId: processRunId, processMetaId: processMetaId, canEdit: canExecute }),
                            React.createElement(Button, { className: `${styles.markDoneBtn}
                       ${!assignedToMeTask ? styles.disabledMarkDone : ''}
                      `, onClick: onMarkAsDone, disabled: isMarkAsDoneDisabled },
                                React.createElement("span", { className: "d-none d-sm-inline-block" }, t('TASK__MARK_AS_DONE')),
                                React.createElement(FontAwesomeIcon, { icon: "check-circle", className: "d-inline-block d-sm-none" })))))),
                    showArrow: false,
                    children: (React.createElement("div", { className: "d-flex flex-column w-100 1" },
                        React.createElement("div", { className: styles.taskContentTopBlock },
                            React.createElement("div", { className: "task-description" },
                                React.createElement("div", { className: "fw-500" },
                                    t('TASK_DESCRIPTION'),
                                    ":"),
                                React.createElement("div", { className: "task-description-text" }, taskDescription),
                                currentActivity && (React.createElement(AvailableTaskActivityForm, { className: "w-100 mt-2", task: task, value: currentActivity, onChange: onChangeActivity }))),
                            React.createElement("div", { className: "task-metadata w-100" },
                                React.createElement(TaskInlineEditor, { task: task, canEdit: canEditTasks, onChangeFollowUp: setFollowUp, onChangePriority: onChangePriority, onChangeDeadline: onChangeDeadline }))),
                        React.createElement(TaskFieldsContainer, { canEdit: canEdit, processMetaId: processMetaId, processRunId: processRunId, taskMetaId: taskMetaId, taskRunId: taskRunId, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, onChange: onChangeProperty, optionalFields: optionalProperties }),
                        iframe?.url && React.createElement(IframeUrl, { url: iframe.url, height: iframe?.height })))
                }
            ] })));
};
export default AvailableTask;
