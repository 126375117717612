import { MultiSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchOptionGroup, getOptions } from '@hypercharge/hyper-react-base/lib/metadata';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { Field, change } from 'redux-form';
import { getMetadataState } from '../../../../common/reducers/rootSelector';
import { optionFormName } from '../../../utils/constants';
const fieldName = 'dependantValues';
class DependantOptionsField extends Component {
    componentDidMount() {
        const { groupId, options, fetchOptions } = this.props;
        if (groupId != null && options.length === 0) {
            fetchOptions(groupId);
        }
    }
    componentDidUpdate(prevProps) {
        const nextGroupId = this.props.groupId;
        if (nextGroupId != null &&
            nextGroupId !== prevProps.groupId &&
            this.props.options.length === 0) {
            this.props.fetchOptions(nextGroupId);
        }
        if (nextGroupId != null && prevProps.groupId != null && nextGroupId !== prevProps.groupId) {
            this.props.resetDependantOptions();
        }
    }
    render() {
        const { options, t, language } = this.props;
        return (React.createElement(Field, { name: fieldName, label: t('ADD_VALUE_DEPENDANT_VALUES'), component: MultiSelect, options: options, language: language }));
    }
}
function mapStateToProps(state, { groupId }) {
    return {
        options: groupId ? getOptions(getMetadataState(state), groupId, null) : []
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchOptions: (groupId) => {
            // @ts-expect-error
            void dispatch(fetchOptionGroup(groupId));
        },
        resetDependantOptions: () => {
            void dispatch(change(optionFormName, fieldName, [], false, false));
        }
    };
}
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(DependantOptionsField);
