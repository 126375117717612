import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import { AceEditor, AceEditorMode } from '../../../../../common/components/AceEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import Toggle from '../../../../../common/components/Toggle';
export const getKioskFormSchema = () => {
    return Yup.object().shape({
        splashEnable: Yup.boolean(),
        splashBodyEn: Yup.string(),
        splashBodyNl: Yup.string(),
        splashBodyFr: Yup.string(),
        splashActivationTime: Yup.number()
    });
};
const htmlEditorOptions = {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: true,
    showLineNumbers: true,
    tabSize: 2
};
const KioskForm = ({ disabled, instance }) => {
    const { t } = useI18n();
    const splashEnable = instance.configuration.website?.kiosk?.splashEnable;
    return (React.createElement("div", { className: "pb-2" },
        React.createElement(FormikField, { name: "configuration.website.kiosk.splashEnable", component: Toggle, label: t('APPS_HYPER_DEALER__ENABLE_SPLASH_SCREEN'), disabled: disabled }),
        splashEnable && (React.createElement(React.Fragment, null,
            React.createElement(FormikField, { name: "configuration.website.kiosk.splashActivationTime", component: BasicInput, type: "number", className: "mt-3 w-25", label: t('APPS_HYPER_DEALER__ACTIVATION_TIME'), disabled: disabled }),
            React.createElement(LabelText, { className: "mt-3" }, t('APPS_HYPER_DEALER__SPLASH_BODY_EN')),
            React.createElement(FormikField, { name: "configuration.website.kiosk.splashBodyEn", component: AceEditor, theme: "textmate", mode: AceEditorMode.html, options: htmlEditorOptions }),
            React.createElement(LabelText, { className: "mt-3" }, t('APPS_HYPER_DEALER__SPLASH_BODY_NL')),
            React.createElement(FormikField, { name: "configuration.website.kiosk.splashBodyNl", component: AceEditor, theme: "textmate", mode: AceEditorMode.html, options: htmlEditorOptions }),
            React.createElement(LabelText, { className: "mt-3" }, t('APPS_HYPER_DEALER__SPLASH_BODY_FR')),
            React.createElement(FormikField, { name: "configuration.website.kiosk.splashBodyFr", component: AceEditor, theme: "textmate", mode: AceEditorMode.html, options: htmlEditorOptions })))));
};
export default KioskForm;
