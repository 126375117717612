import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio, Select } from 'antd';
import { toString } from 'lodash-es';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import FormikError from '../../../../../common/components/formik/FormikError';
import FormikField from '../../../../../common/components/formik/FormikField';
import { SelectPropertyField } from '../../item-property/SelectProperty';
import CreateIfNotExists from '../common/CreateIfNotExistsConfig';
import { numberFormatOptions, unsupportedPropertyTypes } from '../constants';
import { getEntityTitle } from '../utils';
const Option = Select.Option;
const PropertyConfig = ({ propertyConfig, property, name, entities, columns, values }) => {
    const { t } = useI18n();
    const csvPropertyOption = columns.map((columnName, i) => ({
        value: toString(i),
        label: columnName
    }));
    return (React.createElement(Fragment, null,
        React.createElement(LabelText, null, t('CMS_IMPORTER__COLUMN')),
        React.createElement(FormikField, { name: `${name}.csvColumnIndex`, component: Select, style: fullWidthStyle, mode: "default", showSearch: true, allowClear: true, clearValue: (value) => {
                return value === undefined ? null : value;
            }, optionFilterProp: "label", popupMatchSelectWidth: true, placeholder: t('CMS_IMPORTER__COLUMN_PLACEHOLDER'), defaultActiveFirstOption: false, options: csvPropertyOption }),
        React.createElement(FormikError, { name: `${name}.csvColumnIndex` }),
        React.createElement(LabelText, null, t('PROPERTY_COMPUTED_EXPRESSION')),
        React.createElement(FormikField, { name: `${name}.computedProperty`, component: BasicInput, style: fullWidthStyle, value: propertyConfig && propertyConfig.computedProperty
                ? toString(propertyConfig.computedProperty)
                : '' }),
        property.type === 'number' && (React.createElement(Fragment, null,
            React.createElement(ScLabelText, null, t('CMS_IMPORTER__NUMBER_FORMAT')),
            React.createElement(FormikField, { name: `${name}.numberFormat`, component: ({ input }) => (React.createElement(Radio.Group, { value: propertyConfig && toString(propertyConfig.numberFormat), onChange: (e) => {
                        input.onChange(e.target.value);
                    } }, numberFormatOptions.map(({ id, display }) => (React.createElement(Radio, { key: id, style: numberFormatRadioStyle, value: id }, display))))) }))),
        property.type === 'entity' && (React.createElement(Fragment, null,
            React.createElement(FormikField, { name: `${name}.propertyId`, placeholder: t('SELECT_PROPERTY'), label: t('CMS_IMPORTER__CMS_LOOKUP_PROPERTY_LABEL', {
                    entityTitle: getEntityTitle(property.meta.definitionId, entities)
                }), value: propertyConfig && toString(propertyConfig.propertyId), component: ScSelectPropertyField, definitionId: property.meta.definitionId, includeProperty: ({ type }) => !unsupportedPropertyTypes.includes(type), includeNestedProperties: false }),
            property.meta.list && (React.createElement(FormikField, { name: `${name}.listSeparator`, style: listSeparatorStyle, placeholder: t('CMS_IMPORTER__LIST_SEPARATOR_PLACEHOLDER'), label: t('CMS_IMPORTER__LIST_SEPARATOR'), component: ScBasicInput, value: propertyConfig && toString(propertyConfig.listSeparator) })),
            React.createElement(CreateIfNotExists, { propertyConfig: propertyConfig, property: property, name: name, entities: entities, columns: columns, values: values }))),
        property.type === 'multitext' && (React.createElement(FormikField, { name: `${name}.listSeparator`, style: listSeparatorStyle, placeholder: t('CMS_IMPORTER__LIST_SEPARATOR_PLACEHOLDER'), label: t('CMS_IMPORTER__LIST_SEPARATOR'), component: ScBasicInput, value: propertyConfig && toString(propertyConfig.listSeparator) })),
        property.type === 'file' && property.meta.list && (React.createElement(FormikField, { name: `${name}.listSeparator`, style: listSeparatorStyle, placeholder: t('CMS_IMPORTER__LIST_SEPARATOR_PLACEHOLDER'), label: t('CMS_IMPORTER__LIST_SEPARATOR'), component: ScBasicInput, value: propertyConfig && toString(propertyConfig.listSeparator) })),
        property.type === 'date' && (React.createElement(Fragment, null,
            React.createElement(FormikField, { name: `${name}.dateFormat`, placeholder: t('CMS_IMPORTER__DATE_FORMAT_PLACEHOLDER'), label: t('CMS_IMPORTER__DATE_FORMAT'), component: ScBasicInput, value: propertyConfig && toString(propertyConfig.dateFormat) }),
            React.createElement(FormikField, { name: `${name}.timezone`, placeholder: t('CMS_IMPORTER__TIMEZONE_PLACEHOLDER'), label: t('CMS_IMPORTER__TIMEZONE'), component: BasicInput, value: propertyConfig && toString(propertyConfig.timezone) })))));
};
//
// Utils
//
const fullWidthStyle = { width: '100%' };
const listSeparatorStyle = { width: '60px' };
const numberFormatRadioStyle = {
    display: 'block',
    height: '2rem',
    lineHeight: '2rem'
};
const ScLabelText = styled(LabelText) `
  margin-top: 1rem;
`;
const ScSelectPropertyField = styled(SelectPropertyField) `
  margin-top: 1rem;
`;
const ScBasicInput = styled(BasicInput) `
  margin-top: 1rem;
`;
export default PropertyConfig;
