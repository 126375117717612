import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdContentPaste } from 'react-icons/md';
import { useDispatch } from 'react-redux';
const CHECK_INTERVAL = 500; // ms
const userAgent = navigator.userAgent.toLowerCase();
const isUnsupportedBrowser = !userAgent.includes('chrome');
const ClipboardPasteButton = ({ onClick, isValid, disabled, ...otherProps }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const interval = useRef();
    const [clipboardText, setClipboardText] = useState('');
    const syncClipBoard = useCallback(() => {
        if (!document.hasFocus()) {
            return;
        }
        void navigator.permissions
            // @ts-expect-error
            .query({ name: 'clipboard-read' })
            .then(async (readPermissions) => {
            if (readPermissions.state === 'granted' || readPermissions.state === 'prompt') {
                try {
                    await navigator.clipboard.readText().then(setClipboardText);
                }
                catch (error) {
                    console.error('Failed to read from clipboard:', error);
                }
            }
        })
            .catch((error) => {
            console.error('Permission query for clipboard-read failed:', error);
        });
    }, []);
    useEffect(() => {
        if (isUnsupportedBrowser) {
            return;
        }
        // the event is currently not supported, so we implemented polling
        // https://bugs.chromium.org/p/chromium/issues/detail?id=933608
        if (document.hasFocus()) {
            syncClipBoard();
            interval.current = setInterval(syncClipBoard, CHECK_INTERVAL);
        }
        const onDocumentFocus = () => {
            syncClipBoard();
            interval.current = setInterval(syncClipBoard, CHECK_INTERVAL);
        };
        const onDocumentBlur = () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = undefined;
            }
        };
        window.addEventListener('focus', onDocumentFocus);
        window.addEventListener('blur', onDocumentBlur);
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = undefined;
            }
            window.removeEventListener('focus', onDocumentFocus);
            window.removeEventListener('blur', onDocumentBlur);
        };
    }, [syncClipBoard]);
    const isValidClipBoard = useMemo(() => {
        if (isValid) {
            if (!clipboardText) {
                return false;
            }
            try {
                return isValid(clipboardText);
            }
            catch (error) {
                // we don't care what error is here just validation
                console.error('clipboardText validation error:', error);
            }
            return false;
        }
        return true;
    }, [clipboardText, isValid]);
    const _onClick = useCallback(async () => {
        try {
            const clipText = await navigator.clipboard.readText();
            if (!clipText) {
                return;
            }
            if (isValid) {
                const isValidClippedText = isValid(clipText);
                if (!isValidClippedText) {
                    dispatch(error({
                        title: t('ERROR'),
                        message: t('INVALID_DATA_FORMAT')
                    }));
                    return;
                }
            }
            onClick?.(clipText);
        }
        catch (error) {
            console.error('Failed to read text from clipboard:', error);
        }
    }, [dispatch, isValid, onClick, t]);
    return (React.createElement(Tooltip, { title: t('PASTE') },
        React.createElement(Button, { ...otherProps, onClick: _onClick, disabled: disabled || (!isValidClipBoard && !isUnsupportedBrowser) },
            React.createElement(MdContentPaste, null))));
};
export default ClipboardPasteButton;
