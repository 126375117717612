import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import { Drawer, DrawerContent } from '../../../../../common/components/Drawer';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import { useHistoryData } from '../../../../hooks/useHistoryData';
import { getFlattenedDisplayDataList } from '../../../utils';
import { DataHistoryList } from '../DataHistoryList';
import styles from './ValueHistoryDrawer.module.scss';
const ValueHistoryDrawer = ({ definitionId, entityId, propertyId, open, onSave, onClose }) => {
    const { t, language } = useI18n();
    const { isLoading } = useHistoryData({
        definitionId,
        entityId,
        propertyId
    });
    const { displayData } = useEntityDisplayData(definitionId);
    const propertyData = getFlattenedDisplayDataList(displayData).find((property) => property.propertyId === propertyId);
    const titleJsx = useMemo(() => {
        if (isLoading && !propertyData) {
            return (React.createElement(Skeleton, { isLoading: true },
                React.createElement(TextPlaceholder, { heightInPx: 26, widthInPx: 150 })));
        }
        return propertyData ? getTranslation(propertyData.labels, language) : t('VALUE_HISTORY');
    }, [isLoading, language, propertyData, t]);
    const handleSave = useCallback(async (propertyId, value) => {
        try {
            await onSave(propertyId, value);
        }
        catch (e) {
            console.error(e);
        }
        onClose();
    }, [onSave, onClose]);
    return (React.createElement(Drawer, { closable: true, title: titleJsx, placement: "right", onClose: onClose, open: open, destroyOnClose: true },
        React.createElement(DrawerContent, { className: styles.container, loading: isLoading },
            React.createElement(DataHistoryList, { definitionId: definitionId, entityId: entityId, propertyId: propertyId, onSave: handleSave }))));
};
export default ValueHistoryDrawer;
