import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback } from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import { usePropertiesTree } from '../../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import { ConditionQueryField } from '../../../../../../common/components/ConditionQuery';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField';
import { ComputedExpressionsDocumentationLink } from '../../../../../../common/components/documentation-link';
import { ItemMetaSelector } from '../../../../../../common/components/entity-selectors';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import { isProcess } from '../../../../utils';
import NodeSettingsSection from '../NodeSettingsSection';
const CancelProcessForm = ({ disabled, metaDefinitionId, parentViewGroups, changeFieldValue, node }) => {
    const { t } = useI18n();
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    const handleDefinitionIdFieldChange = useCallback((_, newValue, previousValue, name) => {
        if (newValue != previousValue) {
            // Reset the conditionQuery that were based on the previously selected process
            const entityItemsFilterFieldName = name?.replace('definitionId', 'conditionQuery') || '';
            changeFieldValue(entityItemsFilterFieldName, undefined);
        }
    }, [changeFieldValue]);
    const isAvailableProcess = useCallback((itemMeta) => {
        if (itemMeta.definitionId === metaDefinitionId) {
            return false;
        }
        return isProcess(itemMeta);
    }, [metaDefinitionId]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: disabled, placeholder: t('COMMON__TITLE') }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('PROCESS'), className: "ps-0" }),
            React.createElement(Field, { name: "definitionId", className: "mt-3 w-100", component: ItemMetaSelector, includeItemMeta: isAvailableProcess, disabled: disabled, validate: required, autoFocus: false, onChange: handleDefinitionIdFieldChange }),
            node.parameters?.definitionId ? (React.createElement(React.Fragment, null,
                React.createElement(FieldLabel, { label: t('TASK_SET_ENTITY_VALUES_FILTER'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "ps-0" }),
                React.createElement(Field, { name: "conditionQuery", component: ConditionQueryField, validate: required, definitionId: node.parameters.definitionId, allowComputed: true }))) : null),
        React.createElement(Field, { name: "settings", component: NodeSettingsSection, propertiesTree: propertiesTree, disabled: disabled }),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default CancelProcessForm;
