import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import { has, isEmpty, isString, omit } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { Field, FormSection } from 'redux-form';
import { useProperties } from '../../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyButton } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { SelectField } from '../../../../../../common/components/Select';
import { getIsFormHasError } from '../../../../../../common/utils/formUtils';
import styles from './MilestoneListForm.module.scss';
import { previousActivityList } from './utils';
const MilestoneListForm = ({ input, metaDefinitionId, activity, activeNodeId, disabled, meta }) => {
    const { t, language } = useI18n();
    const { getFieldLabel } = useProperties(metaDefinitionId);
    const isSupportedMilestoneProperty = useCallback(({ type, propertyId }) => type === 'number' && !has(input.value, propertyId), [input.value]);
    const addPropertyOutput = useCallback((field) => {
        if (field != null) {
            input.onChange({ ...input.value, [field]: {} });
        }
    }, [input]);
    const removePropertyOutput = useCallback((field) => {
        input.onChange(omit(input.value, [field]));
    }, [input]);
    const nodeOptions = useMemo(() => previousActivityList(activity, activeNodeId, language), [activity, activeNodeId, language]);
    const showError = useMemo(() => isString(meta?.error) && getIsFormHasError(meta), [meta]);
    return (React.createElement(React.Fragment, null,
        !isEmpty(input.value) && (React.createElement("div", { className: styles.wrapper },
            React.createElement(FormSection, { name: "milestones" }, Object.keys(input.value).map((field) => (React.createElement("div", { key: field, className: "mt-3" },
                React.createElement(FieldLabel, { label: getFieldLabel(field) }),
                React.createElement("div", { className: "d-flex align-items-center justify-content-between" },
                    React.createElement("div", { className: "w-75" },
                        React.createElement(Field, { name: `${field}[startTaskId]`, component: SelectField, options: nodeOptions, disabled: disabled, autoFocus: false })),
                    React.createElement("div", { className: "w-25 ms-2" },
                        React.createElement(Field, { name: `${field}[unit]`, component: SelectField, options: ['seconds', 'minutes', 'hours'].map((type) => ({
                                value: type,
                                label: type
                            })), disabled: disabled })),
                    React.createElement(Button, { type: "text", className: "remove-btn-outer rounded d-flex align-items-center justify-content-center p-0", onClick: () => {
                            removePropertyOutput(field);
                        } },
                        React.createElement(IoMdClose, { className: "remove-btn" }))))))))),
        React.createElement(FieldLabel, { className: "p-0 mt-2", label: t('SELECT_PROPERTY_TO_OUTPUT') }),
        React.createElement(SelectPropertyButton, { definitionId: metaDefinitionId, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('SELECT_PROPERTY_TO_OUTPUT')), onChange: addPropertyOutput, disabled: disabled, includeProperty: isSupportedMilestoneProperty, includeNestedProperties: false, error: showError ? meta?.error : undefined })));
};
export default MilestoneListForm;
