import ReactDOM from 'react-dom';
export const getParamsFromEvent = (event) => {
    const targetElement = event.changedTouches
        ? document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY)
        : event.target;
    const parentElement = targetElement.closest('.FileFieldOuter');
    const destinationPropertyId = parentElement?.getAttribute('data-propertyId');
    const destinationEntityId = parentElement?.getAttribute('data-entityId');
    const destinationDefinitionId = parentElement?.getAttribute('data-definitionId');
    return {
        destinationDefinitionId,
        destinationEntityId,
        destinationPropertyId
    };
};
export const resetSortStyles = (sortableListNode) => {
    // there is a bug somewhere in react-sortable-hoc
    // while dragging and dropping if the redux store gets updated in
    // specific cases the library gets confused and loses track of the
    // ghost node that its dragging and doesn't reset the styles
    setTimeout(() => {
        /* eslint-disable-next-line react/no-find-dom-node */
        const element = ReactDOM.findDOMNode(sortableListNode.current);
        if (element) {
            const sortables = 
            /* eslint-disable-next-line react/no-find-dom-node */
            element.querySelectorAll('.sortable');
            sortables.forEach((sort) => {
                if (sort.getAttribute('style')) {
                    sort.removeAttribute('style');
                }
            });
        }
    }, 100);
};
