const XDMS_API_PATH = '/v2-xdms';
const muduleMapping = {
    xdmsContactModule: 'contacts',
    xdmsCustomerModule: 'customers',
    xdmsVehicleModule: 'mach',
    xdmsOfferModule: 'offer',
    xdmsSalesDossierModule: 'salesDossier',
    xdmsLeadModule: 'leads',
    xdmsOrderModule: 'order',
    xdmsSalesPersonModule: 'vendor',
    xdmsEcommOrderModule: 'ecommOrder'
};
export const fetchXdmsSchemaByModuleId = (moduleId) => ({
    type: 'XDMS__GET_DEFINITION_DATA',
    meta: {
        http: {
            path: `${XDMS_API_PATH}/schema/${muduleMapping[moduleId]}`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
export const createDefinitionByModuleId = (moduleId) => ({
    type: 'XDMS__CREATE_DEFINITION',
    meta: {
        http: {
            path: `${XDMS_API_PATH}/bootstrap/${moduleId}`,
            method: 'POST',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
export const fetchPreviewByModuleId = (definitionId, moduleId, mapping) => ({
    type: 'XDMS__GET_CONTACT_PREVIEW',
    payload: { definitionId, mapping },
    meta: {
        http: {
            path: `${XDMS_API_PATH}/mapping/${muduleMapping[moduleId]}/toXdms`,
            method: 'POST',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
export const enableIntegration = (config) => {
    return toggleIntegration('INTEGRATIONS__XDMS_ENABLE', true, config);
};
export const disableIntegration = (config) => {
    return toggleIntegration('INTEGRATIONS__XDMS_DISABLE', false, config);
};
const toggleIntegration = (type, enable, config) => {
    return {
        type,
        payload: {
            action: enable ? 'enable' : 'disable',
            configuration: config
        },
        meta: {
            http: {
                path: `${XDMS_API_PATH}`,
                method: 'PUT',
                allowPromiseReject: true,
                actionContext: {}
            }
        }
    };
};
export const enableIntegrationModuleById = (moduleId, configuration) => {
    return toggleIntegrationModule('INTEGRATIONS__MODULE_XDMS_ENABLE', moduleId, true, configuration);
};
export const disableIntegrationModuleById = (moduleId) => {
    return toggleIntegrationModule('INTEGRATIONS__MODULE_XDMS_DISABLE', moduleId, false);
};
const toggleIntegrationModule = (type, moduleId, enable, configuration) => ({
    type,
    payload: {
        action: enable ? 'enable' : 'disable',
        configuration
    },
    meta: {
        http: {
            path: `${XDMS_API_PATH}/modules/${moduleId}`,
            method: 'PUT',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
