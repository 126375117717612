import { datePattern, dateTimePattern, getMilliseconds, timePattern } from '@hypercharge/hyper-react-base/lib/utils';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from '../../../../../../common/components/DatePicker';
import { getScrollableAreaOrBody } from '../../../../../../common/utils/common';
import { ClearIcon, DateIcon } from '../../../../../../icons';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './DateEditor.module.scss';
const DateEditor = ({ value, onChange, disabled, onKeyDown, autoFocus = true, meta, errorMessage, editing = true, disabledDate, disabledTime, renderCalendarInParent }) => {
    const [open, setOpen] = useState(autoFocus);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!errorMessage, onClick: (e) => e.stopPropagation(), onMouseDown: (e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
            } },
            React.createElement(DatePicker, { autoFocus: autoFocus, open: open, format: meta.withTime ? dateTimePattern : datePattern, value: value ? dayjs(value) : null, onChange: (value) => onChange(value ? getMilliseconds(moment(value.valueOf()), !!meta.withTime) : null), onOk: () => setOpen(false), onClick: () => setOpen(true), onFocus: () => setOpen(true), onBlur: () => setOpen(false), onSelect: (value) => meta.withTime && onChange(value ? getMilliseconds(moment(value.valueOf()), true) : null), disabled: disabled, onKeyDown: (e) => onKeyDown && onKeyDown(e), showTime: meta.withTime ? { format: timePattern, minuteStep: 15 } : false, getPopupContainer: getScrollableAreaOrBody, suffixIcon: React.createElement(DateIcon, null), clearIcon: React.createElement(ClearIcon, null), disabledDate: disabledDate, disabledTime: disabledTime, renderCalendarInParent: renderCalendarInParent })),
        React.createElement(FieldError, { error: errorMessage })));
};
export default DateEditor;
