import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useRouteMatch } from 'react-router';
import { invalidateItemsQuery } from '../../../cms/hooks/invalidateCache';
import { useSearchItems } from '../../../cms/hooks/useSearchItems';
import { FieldsContainer } from '../../../common/utils/formUtils';
import { INTEGRATIONS_PATH, SETTINGS_PATH } from '../../constants';
import { Integration, IntegrationListSkeleton, ListContainer, Subtitle, Title } from './Integrations';
import { disableIntegrationModuleById } from './actions';
import { HYPER_INTEGRATION_MODULE } from './constants';
import { getConfigurationFormFor, getDisableActionFor, getFormSchemaFor, getHasModules } from './utils';
const IntegrationModules = ({ integration, enableIntegration }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const history = useHistory();
    const toggleIntegrationDispatch = useDispatch();
    const integrationId = (integration && integration.entityId) || '';
    const loading = !integrationId;
    const { data: { results: modules } = {}, isPending: isPendingModules } = useSearchItems({
        definitionId: HYPER_INTEGRATION_MODULE,
        filterRequest: {
            query: {
                condition: ConditionType.and,
                filters: [
                    {
                        field: 'integration.entityId',
                        operator: FilterOperatorTypes.is,
                        data: integrationId
                    }
                ]
            },
            responseFields: ['title', 'enabled', 'description', 'logo'],
            limit: 20
        },
        enabled: !!integrationId
    });
    const disableModule = async (moduleId) => {
        const customAction = getDisableActionFor(moduleId);
        try {
            if (customAction) {
                await dispatch(customAction());
            }
            else {
                await toggleIntegrationDispatch(disableIntegrationModuleById(moduleId));
            }
            await invalidateItemsQuery({
                definitionId: HYPER_INTEGRATION_MODULE,
                ids: [moduleId],
                waitNewData: true
            });
            dispatch(success({
                title: t('SUCCESS'),
                message: t('INTEGRATIONS_MODULE_SUCCESS_UPDATE')
            }));
        }
        catch (e) {
            error({
                title: t('ERROR'),
                message: t('INTEGRATIONS_MODULE_FAILED_UPDATE')
            });
        }
    };
    const onSubmit = async (values) => {
        await enableIntegration(integrationId, values);
    };
    const ConfigurationForm = getConfigurationFormFor(integrationId);
    const formSchema = getFormSchemaFor(integrationId);
    const hasModules = getHasModules(integrationId);
    const translatedIntegrationDescription = t(integration.entityId);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "m-3", inversed: true, to: `${SETTINGS_PATH}${INTEGRATIONS_PATH}` },
            "< ",
            t('INTEGRATIONS_BACK')),
        integration ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "cp-i-flex" },
                React.createElement("div", { className: "cp-c-col" },
                    React.createElement("div", { className: "cp-i-noshrink cp-c-column cp-c-align-start-center cp-c-padding-1", style: { borderBottom: '1px solid var(--grayscaleColor-5)' } },
                        React.createElement(Title, null, integration.title),
                        React.createElement(Subtitle, null, translatedIntegrationDescription === integration.entityId
                            ? integration.description
                            : translatedIntegrationDescription)))),
            (ConfigurationForm || !integration.enabled) && (React.createElement(FieldsContainer, { className: "w-50 my-3 mx-auto cp-c-padding-2" },
                React.createElement(Formik, { initialValues: (integration && integration.configuration) || {}, onSubmit: onSubmit, validateOnBlur: true, validateOnChange: false, validationSchema: formSchema && formSchema(t) }, ({ handleSubmit, isSubmitting, dirty, values, handleChange }) => (React.createElement("form", { noValidate: true, onSubmit: handleSubmit },
                    ConfigurationForm && (React.createElement(ConfigurationForm, { configuration: values, disabled: loading || isSubmitting, handleChange: handleChange })),
                    React.createElement(Button, { className: "mt-4 w-100", loading: isSubmitting, onClick: () => handleSubmit(), type: "button" }, t(integration.enabled ? 'FORM__SAVE' : 'ENABLE_INTEGRATION')),
                    React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') })))))))) : (React.createElement(LoadingRectangles, null)),
        hasModules && (React.createElement("div", { className: "mt-4" },
            React.createElement("div", { className: "cp-i-flex" },
                React.createElement("div", { className: "cp-c-col" },
                    React.createElement("div", { className: "cp-i-noshrink cp-c-column cp-c-align-start-center cp-c-padding-1" },
                        React.createElement(Title, { className: "mb-4" }, t('INTEGRATIONS_AVAILABLE_MODULES'))))),
            isPendingModules ? (React.createElement(IntegrationListSkeleton, null)) : (React.createElement(React.Fragment, null, modules ? (React.createElement(ListContainer, null, modules.map((module, index) => (React.createElement(Integration, { key: index, integration: module, add: () => {
                    history.push(`${match.url}/${module.entityId}`);
                }, disable: () => disableModule(module.entityId), disabled: !integration.enabled }))))) : (React.createElement("div", { style: { textAlign: 'center' } },
                React.createElement("span", { className: "cp-c-align-center-center" }, t('INTEGRATIONS_FOUND_NOTHING'))))))))));
};
export default IntegrationModules;
