import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { Tooltip } from 'antd';
import { pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { useDispatch } from 'react-redux';
function ClipboardCopyButton({ value, disabled, onClick, clipboardProperties, ...otherProps }) {
    const { t } = useI18n();
    const valueToClipboard = useMemo(() => {
        return value && clipboardProperties ? pick(value, clipboardProperties) : value;
    }, [clipboardProperties, value]);
    const dispatch = useDispatch();
    const _onClick = useCallback(async () => {
        if (!value) {
            return;
        }
        try {
            await navigator.clipboard.writeText(JSON.stringify(valueToClipboard ? valueToClipboard : value, undefined, 2));
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('COPY')
            }));
            return;
        }
        dispatch(success({
            title: t('SUCCESS'),
            message: t('COPIED')
        }));
        onClick?.(value);
    }, [dispatch, onClick, t, value, valueToClipboard]);
    return (React.createElement(Tooltip, { title: t('COPY') },
        React.createElement(Button, { ...otherProps, onClick: _onClick, disabled: disabled || !value },
            React.createElement(MdContentCopy, null))));
}
export default ClipboardCopyButton;
