import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import GdprSettingsForm from './GdprSettingsForm';
import useGdprSettingsForm from './useGdprSettingsForm';
import { formName } from './utils';
const GdprSettingsFormWrapper = compose(reduxForm({
    form: formName,
    enableReinitialize: true // due to https://github.com/erikras/redux-form/issues/561
}))(GdprSettingsForm);
const GdprSettingsFormContainer = ({ setLinks, setCurrentBreadcrumbText, ...props }) => {
    const settings = useGdprSettingsForm({
        setLinks,
        setCurrentBreadcrumbText
    });
    return (React.createElement(GdprSettingsFormWrapper, { ...props, ...settings, 
        // @ts-expect-error
        formValues: settings.config.formValues, initialValues: settings.config.initialValues }));
};
export default GdprSettingsFormContainer;
