import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Typography } from 'antd';
import React from 'react';
import { isCommonStepType, isGalleryStepType, isListStepType, isTableStepType } from '../../types';
import CommonStepDetailsSettingsForm from './CommonStepDetailsSettingsForm';
import GalleryStepDetailsSettingsForm from './GalleryStepDetailsSettingsForm';
import ListStepDetailsSettingsForm from './ListStepDetailsSettingsForm';
import TableStepDetailsSettingsForm from './TableStepDetailsSettingsForm';
const StepDetailsSettingsForm = ({ step, ...otherProps }) => {
    const { t } = useI18n();
    if (isGalleryStepType(step)) {
        return React.createElement(GalleryStepDetailsSettingsForm, { ...otherProps, step: step });
    }
    if (isListStepType(step)) {
        return React.createElement(ListStepDetailsSettingsForm, { ...otherProps, step: step });
    }
    if (isTableStepType(step)) {
        return React.createElement(TableStepDetailsSettingsForm, { ...otherProps, step: step });
    }
    if (isCommonStepType(step)) {
        return React.createElement(CommonStepDetailsSettingsForm, { ...otherProps, step: step });
    }
    return (React.createElement(Typography.Text, { type: "warning", className: "p-1 d-block" }, t('APPS_HYPER_INSPECTION_STEPS__NOT_TYPE')));
};
export default StepDetailsSettingsForm;
