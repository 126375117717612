import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import styles from './DeadlineDisplay.module.scss';
const DeadlineDisplay = ({ deadline, emphasizeOverdue = true }) => {
    const { t } = useI18n();
    if (!deadline) {
        return React.createElement("div", { className: `${styles.noDeadline} ps-3` }, t('NO_DEADLINE'));
    }
    if (emphasizeOverdue && new Date(deadline).getTime() < Date.now()) {
        return (React.createElement(Tooltip, { title: formatDateTime(deadline), placement: "right" },
            React.createElement("div", { className: styles.deadlineText },
                React.createElement("span", { className: styles.deadlineIcon },
                    React.createElement(FontAwesomeIcon, { icon: "clock" })),
                `${capitalize(moment(deadline).fromNow(true))} ${t('LATE')}`)));
    }
    return React.createElement("div", { className: `${styles.noDeadline} ps-3` }, formatDateTime(deadline));
};
export default DeadlineDisplay;
