import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Input } from 'antd';
import { delay } from 'lodash';
import React, { useEffect, useRef } from 'react';
export const SearchInput = ({ onSearch, placeholder = '', searchValue }) => {
    const { t } = useI18n();
    const inputTagRef = useRef(null);
    useEffect(() => {
        if (inputTagRef.current) {
            const timerId = delay(() => {
                inputTagRef.current?.focus();
            }, 300);
            return () => {
                clearTimeout(timerId);
            };
        }
    });
    if (!onSearch) {
        return null;
    }
    return (React.createElement(Input, { ref: inputTagRef, onKeyDown: (event) => {
            if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
                event.preventDefault();
            }
            if (event.code === 'Backspace') {
                event.stopPropagation();
            }
        }, placeholder: `${t('SEARCH')} ${placeholder}`, value: searchValue, onChange: (event) => {
            onSearch(event.target.value);
        }, allowClear: true }));
};
