import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { ACTIVITY_DEFINITION_ID } from '../../../../../../../common/activity';
import { ACTIVITY_PRIORITY_DEFINITION_ID } from '../../../../../../../common/activity/constants';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { DisplayStatusTag } from '../../../../../../../workflows/common/components/DisplayStatusTag';
import { TASK_PRIORITY_DEFINITION_ID } from '../../../../../../../workflows/common/utils/constants';
import EntityDisplay from '../../../../item-property/display/EntityDisplay';
import styles from '../KanbanCard.module.scss';
import { CardAvatar } from './CardAvatar';
import { CardTaskDeadline } from './CardTaskDeadline';
import { CardTaskPriority } from './CardTaskPriority';
export const CardHeader = ({ card, isProcess }) => {
    const HeaderComponent = useMemo(() => {
        if (isProcess) {
            const isTask = card.definitionId?.indexOf('task__') !== -1;
            if (isTask) {
                return TaskHeader;
            }
            return ProcessHeader;
        }
        if (card.definitionId === ACTIVITY_DEFINITION_ID) {
            return ActivityTaskHeader;
        }
        return CmsHeader;
    }, [card.definitionId, isProcess]);
    return React.createElement(HeaderComponent, { card: card });
};
const CmsHeader = ({ card }) => {
    return (React.createElement("div", { className: `${styles.cardHeader} w-100 d-flex flex-column` },
        React.createElement("div", { className: `${styles.cardHeader__top} d-flex justify-content-start align-items-center` },
            React.createElement("div", { className: `${styles.cardHeader__createDate} fw-500 ms-auto` }, !!card.createdAt && formatDateTime(card.createdAt)),
            React.createElement(CardAvatar, { entityOwner: card.entityOwner, assignedTo: card.taskAssignedTo || undefined, isTask: false }))));
};
const ProcessHeader = ({ card }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.cardHeader} w-100 d-flex flex-column` },
        React.createElement("div", { className: `${styles.cardHeader__top} d-flex justify-content-start align-items-center` },
            React.createElement("div", { className: "d-flex align-items-center" },
                card.processStatus && (React.createElement(Tooltip, { title: t(card.processStatus), placement: "top" },
                    React.createElement("div", { className: `${styles.cardHeader__processStatus} ${card.processStatus}` }))),
                React.createElement("div", { className: `${styles.cardHeader__processId} process-entity fw-600 text-uppercase` },
                    React.createElement(TooltipTruncate, null, card.entityId))),
            React.createElement("div", { className: "d-flex align-items-center ms-auto" },
                !!card.createdAt && (React.createElement("div", { className: `${styles.cardHeader__createDate} fw-500` }, formatDateTime(card.createdAt))),
                React.createElement(CardAvatar, { entityOwner: card.entityOwner, assignedTo: card.taskAssignedTo || undefined, isTask: false })))));
};
const TaskHeader = ({ card }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.cardHeader} w-100 d-flex flex-column` },
        React.createElement("div", { className: `${styles.cardHeader__top} d-flex justify-content-start align-items-center` },
            card.taskPriority && (React.createElement(CardTaskPriority, { priorityDefinitionId: TASK_PRIORITY_DEFINITION_ID, taskPriority: card.taskPriority })),
            React.createElement("div", { className: `${styles.cardHeader__processId} task-entity me-auto fw-600 text-uppercase flex-truncate` },
                React.createElement(TooltipTruncate, null, card.taskProcessRunId)),
            React.createElement(CardAvatar, { entityOwner: card.entityOwner, assignedTo: card.taskAssignedTo, isTask: true })),
        card.taskStatus && (React.createElement("div", { className: `${styles.cardHeader__middle} d-flex align-items-center justify-content-between` },
            React.createElement(DisplayStatusTag, { status: card.taskStatus }),
            React.createElement(CardTaskDeadline, { deadline: card.taskDeadline }))),
        React.createElement("div", { className: `${styles.cardHeader__bottom} d-flex justify-content-start align-items-center` }, !!card.createdAt && (React.createElement(Tooltip, { placement: "top", title: t('TASK_CREATED_AT') },
            React.createElement("div", { className: `${styles.cardHeader__createDate} fw-500 w-auto` }, formatDateTime(card.createdAt)))))));
};
const ActivityTaskHeader = ({ card }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.cardHeader} w-100 d-flex flex-column` },
        React.createElement("div", { className: `${styles.cardHeader__top} d-flex justify-content-start align-items-center` },
            card.priority && (React.createElement(CardTaskPriority, { priorityDefinitionId: ACTIVITY_PRIORITY_DEFINITION_ID, taskPriority: card.priority })),
            React.createElement("div", { className: "me-auto d-flex flex-truncate" },
                React.createElement("div", { className: "mw-100", onClick: (event) => {
                        event.stopPropagation();
                    } }, card.relatedToEntityId && card.relatedToDefinitionId && (React.createElement(EntityDisplay, { value: card.relatedToEntityId, meta: { definitionId: card.relatedToDefinitionId } })))),
            React.createElement("div", { onClick: (event) => {
                    event.stopPropagation();
                } },
                React.createElement(CardAvatar, { entityOwner: card.contact || card.entityOwner, assignedTo: card.assignedTo, isTask: true }))),
        card.dueDate && (React.createElement("div", { className: `${styles.cardHeader__middle} d-flex align-items-center justify-content-end` },
            React.createElement(CardTaskDeadline, { deadline: card.dueDate }))),
        React.createElement("div", { className: `${styles.cardHeader__bottom} d-flex justify-content-start align-items-center` }, !!card.createdAt && (React.createElement(Tooltip, { placement: "top", title: t('TASK_CREATED_AT') },
            React.createElement("div", { className: `${styles.cardHeader__createDate} fw-500 w-auto` }, formatDateTime(card.createdAt)))))));
};
