import { get } from 'lodash';
import { APP_CUSTOMER_PORTAL_DEFINITION_ID, APP_HYPER_BUILDER_DEFINITION_ID, APP_HYPER_DEALER_DEFINITION_ID, APP_HYPER_EVENT_DEFINITION_ID, APP_HYPER_INSPECTION_DEFINITION_ID, APP_HYPER_LANDING_DEFINITION_ID } from './constants';
import CustomerPortalInstanceForm, { getFormSchema as customerPortalGetInstancesFormSchema, initialValues as customerPortalInstancesInitialValues } from './customer-portal/InstanceForm';
import DefaultAppInstancesForm, { getFormSchema as defaultAppGetInstancesFormSchema, initialValues as defaultAppInstancesInitialValues } from './default-app/InstanceForm';
import HyperBuilderInstancesForm, { getFormSchema as hyperBuilderGetInstancesFormSchema, initialValues as hyperBuilderInstancesInitialValues } from './hyper-builder/InstanceForm';
import HyperDealerInstancesForm, { getFormSchema as hyperDealerGetInstancesFormSchema, initialValues as hyperDealerInstancesInitialValues } from './hyper-dealer/InstancesForm';
import HyperEventInstancesForm, { getFormSchema as hyperEventGetInstancesFormSchema, initialValues as hyperEventInstancesInitialValues } from './hyper-event/InstanceForm';
import HyperInspectionInstancesForm, { getFormSchema as hyperInspectionGetInstancesFormSchema, initialValues as hyperInspectionInstancesInitialValues } from './hyper-inspection/InstanceForm';
import HyperLandingInstancesForm, { getFormSchema as hyperLandingGetInstancesFormSchema, initialValues as hyperLandingInstancesInitialValues } from './hyper-landing/InstanceForm';
// Put your custom forms for configurations here
const FORM_MAPPINGS = {
    [APP_HYPER_DEALER_DEFINITION_ID]: {
        form: HyperDealerInstancesForm,
        schema: hyperDealerGetInstancesFormSchema,
        initialValues: hyperDealerInstancesInitialValues
    },
    [APP_CUSTOMER_PORTAL_DEFINITION_ID]: {
        form: CustomerPortalInstanceForm,
        schema: customerPortalGetInstancesFormSchema,
        initialValues: customerPortalInstancesInitialValues
    },
    [APP_HYPER_LANDING_DEFINITION_ID]: {
        form: HyperLandingInstancesForm,
        schema: hyperLandingGetInstancesFormSchema,
        initialValues: hyperLandingInstancesInitialValues
    },
    [APP_HYPER_EVENT_DEFINITION_ID]: {
        form: HyperEventInstancesForm,
        schema: hyperEventGetInstancesFormSchema,
        initialValues: hyperEventInstancesInitialValues
    },
    [APP_HYPER_BUILDER_DEFINITION_ID]: {
        form: HyperBuilderInstancesForm,
        schema: hyperBuilderGetInstancesFormSchema,
        initialValues: hyperBuilderInstancesInitialValues
    },
    [APP_HYPER_INSPECTION_DEFINITION_ID]: {
        form: HyperInspectionInstancesForm,
        schema: hyperInspectionGetInstancesFormSchema,
        initialValues: hyperInspectionInstancesInitialValues
    }
};
export const getConfigurationFormFor = (entityId) => {
    return get(FORM_MAPPINGS, [entityId, 'form'], DefaultAppInstancesForm);
};
export const getFormInitialValuesFor = (entityId) => {
    return get(FORM_MAPPINGS, [entityId, 'initialValues'], defaultAppInstancesInitialValues);
};
export const getFormSchemaFor = (entityId) => {
    return get(FORM_MAPPINGS, [entityId, 'schema'], defaultAppGetInstancesFormSchema);
};
