import { createReducer } from '../utils';
const initialState = {
    permissionsByDefinition: {},
    loading: false,
    failed: false
};
const reducer = createReducer(initialState, {
    PERMISSIONS__FETCH: (s) => {
        s.loading = true;
        s.failed = false;
    },
    PERMISSIONS__FETCH_SUCCESS: (s, a) => {
        const { definitionId, id } = a.meta.actionContext;
        if (!s.permissionsByDefinition[definitionId]) {
            s.permissionsByDefinition[definitionId] = {
                [id]: a.payload
            };
        }
        else {
            s.permissionsByDefinition[definitionId][id] = a.payload;
        }
    },
    PERMISSIONS__FETCH_FAIL: (s) => {
        s.loading = false;
        s.failed = true;
    },
    PERMISSIONS__UPDATE_SUCCESS: (s, a) => {
        const { definitionId, id, permissions } = a.meta.actionContext;
        if (!s.permissionsByDefinition[definitionId]) {
            s.permissionsByDefinition[definitionId] = {};
        }
        s.permissionsByDefinition[definitionId][id] = permissions;
    }
});
export const getPermissions = (s, definitionId, id) => s.permissionsByDefinition[definitionId] ? s.permissionsByDefinition[definitionId][id] || {} : {};
export default reducer;
