import Select from 'antd/lib/select';
import React, { useMemo } from 'react';
import { DEFAULT_LOCALE_EN } from '../locale';
import { classNames } from '../utils';
export default function Minutes(props) {
    const { value, setValue, locale, className, disabled, readOnly, leadingZero, clockFormat, period } = props;
    const internalClassName = useMemo(() => classNames({
        'react-js-cron-field': true,
        'react-js-cron-minutes': true,
        [`${className}-field`]: !!className,
        [`${className}-minutes`]: !!className
    }), [className]);
    const options = [
        {
            value: '0',
            label: '0-15'
        },
        {
            value: '15',
            label: '15-30'
        },
        {
            value: '30',
            label: '30-45'
        },
        {
            value: '45',
            label: '45-59'
        }
    ];
    return (React.createElement("div", { className: internalClassName },
        period === 'hour'
            ? locale.prefixMinutesForHourPeriod !== '' && (React.createElement("span", null, locale.prefixMinutesForHourPeriod || DEFAULT_LOCALE_EN.prefixMinutesForHourPeriod))
            : locale.prefixMinutes !== '' && (React.createElement("span", null, locale.prefixMinutes || DEFAULT_LOCALE_EN.prefixMinutes)),
        React.createElement(Select, { placeholder: period === 'hour'
                ? locale.emptyMinutesForHourPeriod || DEFAULT_LOCALE_EN.emptyMinutesForHourPeriod
                : locale.emptyMinutes || DEFAULT_LOCALE_EN.emptyMinutes, value: value && value.length ? value[0].toString() : '0', onChange: (newValue) => {
                if (newValue === undefined) {
                    setValue([0]);
                }
                else {
                    setValue([newValue]);
                }
            }, showArrow: true, showSearch: false, allowClear: true, className: className, disabled: disabled, 
            // @ts-expect-error
            mode: "default" }, options.map((option) => (React.createElement(Select.Option, { key: option.value }, option.label)))),
        period === 'hour' && locale.suffixMinutesForHourPeriod !== '' && (React.createElement("span", null, locale.suffixMinutesForHourPeriod || DEFAULT_LOCALE_EN.suffixMinutesForHourPeriod))));
}
