import { includes } from 'lodash';
const getPropertyState = (state) => state.properties;
export const getProperty = (state, definitionId, propertyId) => {
    const definitionProperties = getPropertyState(state).byPropertyId[definitionId];
    return (definitionProperties && definitionProperties[propertyId]) || null;
};
export const getPropertiesByDefinitionId = (state, definitionId) => {
    const propertyState = getPropertyState(state);
    const definitionPropertyIds = propertyState.byDefinitionId[definitionId] || [];
    return definitionPropertyIds
        .map((propertyId) => getProperty(state, definitionId, propertyId))
        .filter((p) => !!p);
};
export const getPropertiesByGroupId = (state, definitionId, groupId) => {
    const properties = getPropertiesByDefinitionId(state, definitionId);
    return properties.filter(({ layoutIds }) => includes(layoutIds, groupId));
};
export const getPropertiesByGroupIds = (state, definitionId, groupIds) => 
// @ts-ignore
groupIds.reduce((acc, groupId) => ({
    ...acc,
    [groupId]: getPropertiesByGroupId(state, definitionId, groupId)
}), {});
export const getIsDefinitionPropertiesAvailable = (state, definitionId) => {
    const definitionStatus = getPropertyState(state).definitionStatus[definitionId];
    return (definitionStatus != null && (definitionStatus.lastFetch != null || definitionStatus.pending));
};
export const getIsFetchPropertiesPending = (state, definitionId) => {
    const definitionStatus = getPropertyState(state).definitionStatus[definitionId];
    return definitionStatus != null && definitionStatus.pending;
};
export const didFetchPropertiesFail = (state, definitionId) => {
    const definitionStatus = getPropertyState(state).definitionStatus[definitionId];
    return definitionStatus != null && definitionStatus.failed;
};
