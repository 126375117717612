import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { saveConfig } from '../../../configs';
import { GDPR_KEY, SETTINGS_KEY } from '../../constants';
export const formName = [SETTINGS_KEY, GDPR_KEY].join('_');
export const GDPR_CHANGE_REQUEST_PROCESS_META_ID = 'gdprChangeRequestDef';
const triggerGdprSettingsUpdateAction = {
    type: 'UPDATE_GDPR_SETTINGS',
    payload: {},
    meta: {
        http: {
            path: '/v2-gdpr/settings/update',
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
};
export const onGdprSettingsFormSubmit = (formValues, dispatch, { gdprConfig, t }) => new Promise((resolve, reject) => {
    dispatch(saveConfig({
        ...gdprConfig,
        configType: SETTINGS_KEY,
        configId: GDPR_KEY,
        data: formValues
    }))
        // @ts-expect-error
        .then(() => dispatch(triggerGdprSettingsUpdateAction))
        .then(() => {
        dispatch(success({
            title: t('SUCCESS'),
            message: t('SAVE_SETTINGS_SUCCESS')
        }));
        resolve(undefined);
    })
        .catch((e) => {
        dispatch(error({
            title: t('ERROR'),
            message: t('SAVE_SETTINGS_FAIL')
        }));
        reject();
    });
});
