import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Select } from '../../../../../common/components/Select';
import { ErrorLabel, getIsFormHasError } from '../../../../../common/utils/formUtils';
import { useDocumentTemplate } from '../../../../hooks/useDocumentTemplate';
import { useSearchDocuments } from '../../../../hooks/useSearchDocuments';
export default ({ input, disabled, meta, className = '' }) => {
    const { t } = useI18n();
    const [search, setSearch] = useState('');
    const [debouncedSearch] = useDebounce(search, 300);
    const { data: { results } = {}, isFetching } = useSearchDocuments({
        filterRequest: { limit: 5, offset: 0, fullText: debouncedSearch }
    });
    const { data: selectedTemplate } = useDocumentTemplate({ templateId: input.value });
    const options = useMemo(() => {
        const templates = [];
        if (selectedTemplate) {
            templates.push(selectedTemplate);
        }
        if (results) {
            templates.push(...results);
        }
        return uniqBy(templates, 'id')?.map((document) => ({
            value: document.id,
            label: document.title
        }));
    }, [results, selectedTemplate]);
    const showError = useMemo(() => getIsFormHasError(meta), [meta]);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "w-100 d-flex flex-column align-items-stretch" },
            React.createElement(Select, { disabled: disabled, value: input.value ? input.value : undefined, onChange: input.onChange, placeholder: t('SEARCH_TEMPLATE'), options: options, showSearch: true, filterOption: false, searchValue: search, onSearch: setSearch, loading: isFetching, status: showError ? 'warning' : undefined }),
            React.createElement(ErrorLabel, { position: 'absolute', error: meta?.error, show: !!showError }))));
};
