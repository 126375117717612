import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { SortOrder } from '@hypercharge/portal-utils';
import { Radio } from 'antd';
import { find, flatMap, isEmpty, map, pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { SelectPropertyButton } from '../../cms/data/components/item-property/SelectProperty';
import { PropertyTypes } from '../../common/types';
import { DEFAULT_SORT_BY_FIELDS_VIEW } from '../constants';
import SortablePropertyList, { ExtendableSortableItem } from './SortablePropertyList';
export const supportedSortByPropertyTypes = [
    PropertyTypes.checkbox,
    PropertyTypes.date,
    PropertyTypes.email,
    PropertyTypes.entity,
    // 'file',
    // 'json',
    PropertyTypes.link,
    PropertyTypes.multitext,
    PropertyTypes.number,
    PropertyTypes.phoneNumber,
    // 'richtext',
    PropertyTypes.select,
    PropertyTypes.text
];
const SortByField = ({ className, disabled, input, displayData }) => {
    const { t } = useI18n();
    const selectedSortByArray = useMemo(() => {
        if (isEmpty(input.value) || !Array.isArray(input.value)) {
            return DEFAULT_SORT_BY_FIELDS_VIEW;
        }
        // Strip out the deleted properties
        return input.value.filter(({ field }) => !!find(flatMap(displayData.data, 'values'), { propertyId: field })); // Strip out the deleted properties
    }, [displayData.data, input.value]);
    const addSortBy = useCallback((propertyId) => {
        input.onChange([...selectedSortByArray, { field: propertyId, order: SortOrder.asc }]);
    }, [selectedSortByArray, input]);
    return (React.createElement("div", { className: className },
        !isEmpty(selectedSortByArray) && (React.createElement(SortablePropertyList, { disabled: disabled, displayData: displayData, itemComponent: SortableSortByItem, selection: selectedSortByArray, propertyIdPath: "field", handleChange: input.onChange })),
        React.createElement(SelectPropertyButton, { className: "my-3 w-50", disabled: disabled, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('ADD_SORT_BY_PROPERTY')), onChange: addSortBy, definitionId: displayData.definitionId, includeProperty: (displayData) => supportedSortByPropertyTypes.includes(displayData.type), isFieldDisabled: (propertyId) => map(selectedSortByArray, 'field').includes(propertyId), includeNestedProperties: false })));
};
export default SortByField;
//
// Specific item component for the SortablePropertyList
//
const SortableSortByItem = (props) => {
    const { item, isDisabled, selection, handleChange } = props;
    const { t } = useI18n();
    const handleSortOrderChange = useCallback((e) => {
        handleChange(selection.map((sortBy) => (sortBy === item ? { ...sortBy, order: e.target.value } : sortBy)));
    }, [handleChange, selection, item]);
    return (React.createElement(ExtendableSortableItem, { ...pick(props, [
            'position',
            'item',
            'iconComponent',
            'label',
            'sectionTitle',
            'isDisabled',
            'selection',
            'handleChange',
            'handleRemove'
        ]) },
        React.createElement(Radio.Group, { value: item.order, buttonStyle: "solid", size: "small", disabled: isDisabled, onChange: handleSortOrderChange },
            React.createElement(Radio.Button, { value: SortOrder.asc }, t('SORT_BY_ORDER_ASC')),
            React.createElement(Radio.Button, { value: SortOrder.desc }, t('SORT_BY_ORDER_DESC')))));
};
