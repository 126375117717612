import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React from 'react';
import DrawerFooterBlock from '../DrawerFooterBlock/DrawerFooterBlock';
const DrawerFormFooter = ({ className = '', submitting, disabled, onCancel, onSubmit, submitText }) => {
    const { t } = useI18n();
    return (React.createElement(DrawerFooterBlock, { className: className },
        onCancel && (React.createElement(Button, { type: "text", className: "form-action-button fw-600 cancel", onClick: onCancel, disabled: submitting }, t('FORM__CANCEL'))),
        onSubmit && (React.createElement(Button, { className: "form-action-button fw-600", type: "primary", loading: submitting, onClick: onSubmit, disabled: disabled }, submitText ? t(submitText) : t('FORM__SAVE')))));
};
export default DrawerFormFooter;
