import React, { useCallback, useMemo } from 'react';
import { Resizable } from 'react-resizable';
import useStorage from '../../hooks/useStorage';
import styles from './WithResizable.module.scss';
const CustomResizeHandle = (_, ref) => {
    return React.createElement("div", { className: `${styles.resizer} col-resize`, ref: ref });
};
const WITH_RESIZABLE_WIDTH_KEY = 'WITH_RESIZABLE_WIDTH_KEY';
const VERSION = 'v2';
const getMinMaxWidth = (width, maxWidth, minWidth) => Math.min(Math.max(Math.ceil(width), minWidth), maxWidth);
const WithResizable = ({ children, enabled, uniqKey, parentWidth, minWidth }) => {
    const localStorageKey = useMemo(() => `${WITH_RESIZABLE_WIDTH_KEY}-${uniqKey}-${VERSION}`, [uniqKey]);
    const [widthPercentage, setWidthPercentage] = useStorage(localStorageKey, 0);
    const widthToPercentage = useCallback((width, parentWidth) => {
        return (width * 100) / parentWidth;
    }, []);
    const onResize = useCallback((_, { size }) => setWidthPercentage(widthToPercentage(size.width, parentWidth)), [parentWidth, setWidthPercentage, widthToPercentage]);
    const width = useMemo(() => parentWidth && widthPercentage
        ? getMinMaxWidth(Math.floor((parentWidth / 100) * widthPercentage), parentWidth, minWidth)
        : minWidth, [minWidth, parentWidth, widthPercentage]);
    return (React.createElement(Resizable, { className: "ResizableOuter", width: enabled ? width : Infinity, height: Infinity, onResize: onResize, minConstraints: [minWidth, Infinity], maxConstraints: [Math.floor((parentWidth || 0) / 2), Infinity], draggableOpts: { grid: [1, 1] }, resizeHandles: ['w'], handle: enabled ? CustomResizeHandle : React.createElement("div", null) },
        React.createElement("div", { style: enabled ? { width, minWidth: width, maxWidth: width } : undefined }, children)));
};
export default WithResizable;
