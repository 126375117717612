export const CMS_API_PATH = '/v2-custom-data';
export const CMS_PATH = '/cms';
export const FEATURED_ENTITIES_PATH = '/featured-cms';
export const CMS_IMPORTER_PATH = '/importer';
export const CMS_HIGHLIGHTED_REFERRALS_PATH = '/highlighted-referrals';
export const CMS_HIGHLIGHTED_PROPERTIES_PATH = '/highlighted-properties';
export const REQUIRED_CMS_PERMISSION = 'CMS';
export const REQUIRED_CMS_EXPORT_PERMISSION = 'CMS_EXPORT';
export const CMS_ITEM_CONVERSATION_NAMESPACE = 'cms';
export const TITLE_PROPERTY_ID = 'title';
export const ENTITY_ID_PROPERTY_ID = 'entityId';
export const UPDATED_AT_ID_PROPERTY_ID = 'updatedAt';
export const CREATED_AT_ID_PROPERTY_ID = 'createdAt';
export const CONTACT_GROUPS_PROPERTY_ID = 'groups';
export const ENTITY_OWNER_PROPERTY_ID = 'entityOwner';
export const PERMISSIONS_PROPERTY_ID = 'rowPermissions';
export const MY_USER_COMPUTED = '=contactId';
