import { createReducer } from '@hypercharge/hyper-react-base/lib/utils/reducerUtils';
import jwtDecode from 'jwt-decode';
import { isObject } from 'lodash';
import { isDecodedTokenValid } from './selectors';
export const isStateT = (data) => {
    return (isObject(data) &&
        'accountId' in data &&
        'refreshToken' in data &&
        'refreshTokenExpiry' in data &&
        'jwt' in data &&
        'jwtExpiry' in data &&
        'email' in data);
};
const initialState = {
    accountId: null,
    refreshToken: null,
    jwt: null,
    jwtExpiry: null,
    refreshTokenExpiry: null,
    email: null
};
const authReducer = createReducer(initialState, {
    AUTH__LOG_IN_SUCCESS: (s, a) => {
        const { idToken, refreshToken, refreshTokenExpiry } = a.payload;
        const decodedToken = jwtDecode(idToken);
        if (!isDecodedTokenValid(decodedToken)) {
            return;
        }
        s.accountId = decodedToken.sub;
        s.jwt = idToken;
        s.jwtExpiry = new Date(decodedToken.exp * 1000);
        s.refreshToken = refreshToken;
        s.refreshTokenExpiry = new Date(refreshTokenExpiry).getTime();
        s.email = decodedToken.email;
    },
    AUTH__SWITCH_ACCOUNT_SUCCESS: (s, a) => {
        const { idToken, refreshToken, refreshTokenExpiry } = a.payload;
        const decodedToken = jwtDecode(idToken);
        if (!isDecodedTokenValid(decodedToken)) {
            return;
        }
        s.accountId = decodedToken.sub;
        s.jwt = idToken;
        s.jwtExpiry = new Date(decodedToken.exp * 1000);
        s.refreshToken = refreshToken;
        s.refreshTokenExpiry = new Date(refreshTokenExpiry).getTime();
        s.email = decodedToken.email;
    },
    AUTH__REFRESH_AUTH_TOKEN_SUCCESS: (s, a) => {
        const decodedToken = jwtDecode(a.payload.idToken);
        if (!isDecodedTokenValid(decodedToken)) {
            return;
        }
        s.accountId = decodedToken.sub;
        s.jwt = a.payload.idToken;
        s.jwtExpiry = new Date(decodedToken.exp * 1000);
    }
});
export default authReducer;
