import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { REQUIRED_PORTAL_PERMISSION } from '../../common/utils/constants';
import { ACCOUNTS_PATH, GET_STARTED_BY_EMAIL_PATH, GET_STARTED_CODE_PATH, OAUTH2_PATH } from '../constants';
import { ChooseAccountPage } from './ChooseAccountPage';
import CodeFormPage from './CodeFormPage';
import { EmailFormPage } from './EmailFormPage';
import { OAuth2FormPage } from './OAuth2FormPage';
import { AuthContainer } from './common/AuthContainer';
const AuthRoutes = ({ location, match: { url } }) => {
    const redirectLocation = useMemo(() => {
        return {
            ...location,
            pathname: `${url}${GET_STARTED_BY_EMAIL_PATH}`
        };
    }, [location, url]);
    return (React.createElement(AuthContainer, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${url}${OAUTH2_PATH}`, render: (props) => React.createElement(OAuth2FormPage, { permission: REQUIRED_PORTAL_PERMISSION, ...props }) }),
            React.createElement(Route, { exact: true, path: `${url}${GET_STARTED_BY_EMAIL_PATH}`, component: EmailFormPage }),
            React.createElement(Route, { exact: true, path: `${url}${GET_STARTED_CODE_PATH}`, component: CodeFormPage }),
            React.createElement(Route, { exact: true, path: `${url}${ACCOUNTS_PATH}`, component: ChooseAccountPage }),
            React.createElement(Redirect, { to: redirectLocation }))));
};
export default AuthRoutes;
