import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import FormikField from '../../../../common/components/formik/FormikField';
import { FieldsContainer } from '../../../../common/utils/formUtils';
export const getFormSchema = () => {
    return Yup.object().shape({
        username: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        password: Yup.string().required(t('VALIDATIONS__REQUIRED'))
    });
};
const HexonIntegrationForm = ({ configuration: { username = '', password = '' }, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
        React.createElement(FormikField, { name: "username", component: BasicInput, label: `${t('HEXON_USER')} *`, value: username, disabled: disabled }),
        React.createElement(FormikField, { name: "password", component: BasicInput, label: `${t('HEXON_PASSWORD')} *`, value: password, type: "password", disabled: disabled })));
};
export default HexonIntegrationForm;
