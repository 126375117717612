import cn from 'classnames';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import styles from './CollapsibleContainer.module.scss';
import SideMenu from './SideMenu';
const CollapsibleContainerContext = createContext({
    isDesktopMenuCollapsed: false
});
export const CollapsibleContainer = ({ sectionName, title, createPath, headerIcon, items, sectionSortOrderIndexMap, children, isFailed, isLoading, withSearch, withFeaturedEntitiesSwitch, containerClassName, sideMenuClassName, className, containerStyles = {} }) => {
    const localStorageKey = `desktop-left-drawer-collapsed-${sectionName}`;
    const [isDesktopMenuCollapsed, setIsDesktopMenuCollapsed] = useState(localStorage.getItem(localStorageKey) === '1');
    const contextValue = useMemo(() => ({
        isDesktopMenuCollapsed
    }), [isDesktopMenuCollapsed]);
    const onDesktopMenuCollapseChange = useCallback((collapsed) => {
        setIsDesktopMenuCollapsed(collapsed);
        localStorage.setItem(localStorageKey, collapsed ? '1' : '0');
    }, [localStorageKey]);
    return (React.createElement(CollapsibleContainerContext.Provider, { value: contextValue },
        React.createElement("div", { className: cn(styles.contentRow, className, { collapsed: isDesktopMenuCollapsed }) },
            React.createElement("div", null,
                React.createElement(SideMenu, { className: sideMenuClassName, title: title, createPath: createPath, headerIcon: headerIcon, items: items, sectionSortOrderIndexMap: sectionSortOrderIndexMap, isFailed: isFailed, isLoading: isLoading, withSearch: withSearch, withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, isDesktopMenuCollapsed: isDesktopMenuCollapsed, onDesktopMenuCollapseChange: onDesktopMenuCollapseChange })),
            React.createElement("div", { className: cn(containerClassName, 'w-100 overflow-hidden'), style: containerStyles }, children))));
};
export const useCollapsibleContainer = () => {
    const context = useContext(CollapsibleContainerContext);
    if (context == null) {
        throw new Error('useCollapsibleContainer must be used within an CollapsibleContainer');
    }
    return context;
};
