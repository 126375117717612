import { createContext, useContext } from 'react';
const HeightContext = createContext({
    contentHeight: 'auto'
});
export const useHeight = () => {
    const context = useContext(HeightContext);
    if (context == null) {
        throw new Error('useHeight must be used within an HeightContextProvider');
    }
    return context;
};
export default HeightContext;
