import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import RequiredFieldInfoPopover from '../../../workflows/processes/components/process/RequiredFieldInfoPopover';
const TableFieldLabel = ({ field, requiredFieldsConfig }) => {
    const { language } = useI18n();
    const units = 'units' in field.meta ? getTranslation(field.meta.units, language) : null;
    return (React.createElement(React.Fragment, null,
        getTranslation(field.labels, language),
        units && ` (${units})`,
        requiredFieldsConfig?.isRequiredField(field.propertyId) && (React.createElement(RequiredFieldInfoPopover, { propertyId: field.propertyId, requiredFieldsConfig: requiredFieldsConfig }))));
};
export { TableFieldLabel };
