import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { ConditionType } from '@hypercharge/portal-utils';
import * as NodeHelpers from 'n8n-workflow/dist/NodeHelpers';
import { ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP, OWNER_GROUP_PLACEHOLDER } from '../../../../../groups';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../../../../common/utils/types';
import { HP3N_NODE_PREFIX } from '../node-form/Hp3nNodeForm/Hp3nNodeForm';
export const defaultDeadlineReadGroups = [
    ADMINS_PERMISSION_GROUP,
    ALL_USERS_PERMISSION_GROUP,
    OWNER_GROUP_PLACEHOLDER
].sort();
export const defaultDeadlineWriteGroups = [ADMINS_PERMISSION_GROUP, OWNER_GROUP_PLACEHOLDER].sort();
export function isOwnNodeDescription(node) {
    return Object.prototype.hasOwnProperty.call(node, 'sections');
}
export function isTriggerNode(node) {
    if (isOwnNodeDescription(node)) {
        return (node.type == 'START_MANUAL' ||
            node.type == 'START_EMAIL_FEEDBACK_TRIGGER' ||
            node.type == 'START_ENTITY_EVENT_TRIGGER' ||
            node.type == 'START_CRON_TRIGGER');
    }
    return !!(node.webhooks && node.webhooks.length);
}
export function isStepNode(node) {
    return isOwnNodeDescription(node) && node.type == 'ACTION_AUTOMATED_STEP';
}
export function isHp3ActivityNode(node) {
    if (node.type === ActivityNodeTypes.ACTION &&
        node.subtype === ActivityActionNodeSubtypes.AUTOMATED &&
        node.target?.startsWith(HP3N_NODE_PREFIX)) {
        return true;
    }
    return false;
}
export function isDecisionNode(node) {
    return (node.type === ActivityNodeTypes.ACTION &&
        node.subtype === ActivityActionNodeSubtypes.AUTOMATED &&
        node.target === 'DECISION');
}
export const ACTIVITY_NODES = [
    // Automated trigger node
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__START_EMAIL_FEEDBACK_TRIGGER_TASK',
        type: 'START_EMAIL_FEEDBACK_TRIGGER'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__START_ENTITY_EVENT_TRIGGER_TASK',
        type: 'START_ENTITY_EVENT_TRIGGER'
    },
    // Automated trigger node
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__START_CRON_TRIGGER_TASK',
        type: 'START_CRON_TRIGGER'
    },
    // Manual trigger node
    {
        sections: ['PROCESS_META__MANUAL_TASKS'],
        displayName: 'PROCESS_META__START_MANUAL_TASK',
        type: 'START_MANUAL'
    },
    // Manual nodes
    {
        sections: ['PROCESS_META__MANUAL_TASKS'],
        displayName: 'PROCESS_META__MANUAL_TASK',
        type: 'ACTION_MANUAL'
    },
    {
        sections: ['PROCESS_META__MANUAL_TASKS'],
        displayName: 'PROCESS_META__MANUAL_ACTIVITY_TASK',
        type: 'ACTION_MANUAL_ACTIVITY'
    },
    // Automated nodes
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__EMAIL_TASK',
        type: 'ACTION_AUTOMATED_EMAIL'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__SEND_SMS_TASK',
        type: 'ACTION_AUTOMATED_SMS'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'TASKS_START_PROCESS',
        type: 'ACTION_AUTOMATED_START_PROCESS'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__SET_PROCESS_VALUES_TASK',
        type: 'ACTION_AUTOMATED_SET_PROCESS_VALUES'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__SET_ENTITY_VALUES_TASK',
        type: 'ACTION_AUTOMATED_SET_ENTITY_VALUES'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'WORKFLOWS__CANCEL_PROCESS',
        type: 'ACTION_AUTOMATED_CANCEL_PROCESS'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__GET_ENTITY_VALUES_TASK',
        type: 'ACTION_AUTOMATED_GET_ENTITY_VALUES'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__CREATE_ENTITY_TASK',
        type: 'ACTION_AUTOMATED_CREATE_ENTITY'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__GENERATE_FORM_URL',
        type: 'ACTION_AUTOMATED_GENERATE_FORM_URL'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__GENERATE_DOCUMENT_TASK',
        type: 'ACTION_AUTOMATED_GENERATE_DOCUMENT'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__GENERATE_QR_CODE_TASK',
        type: 'ACTION_AUTOMATED_GENERATE_QR_CODE'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__MILESTONE_TASK',
        type: 'ACTION_AUTOMATED_MILESTONE'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__STEP_TASK',
        type: 'ACTION_AUTOMATED_STEP'
    },
    {
        sections: ['PROCESS_META__AUTOMATED_TASKS'],
        displayName: 'PROCESS_META__DELAY',
        type: 'ACTION_SEMIAUTOMATED_DELAY'
    },
    // Control nodes
    {
        sections: ['PROCESS_META__CONTROL_NODES'],
        displayName: 'PROCESS_META__DECISION',
        type: 'ACTION_DECISION'
    },
    {
        sections: ['PROCESS_META__CONTROL_NODES'],
        displayName: 'PROCESS_META__LOOP',
        type: 'LOOP_WHILE'
    },
    {
        sections: ['PROCESS_META__CONTROL_NODES'],
        displayName: 'PROCESS_META__PARALLEL',
        type: 'FORK'
    }
];
export const getNodeTypeKey = (node) => {
    if (node.type === ActivityNodeTypes.ACTION) {
        return [node.type, node.subtype, node.target].filter((t) => t).join('_');
    }
    return [node.type].filter((t) => t).join('_');
};
export const getIdForNode = (type) => {
    const uuid = generateId();
    if (type.startsWith(HP3N_NODE_PREFIX)) {
        return `${type}_${uuid}`;
    }
    switch (type) {
        case 'ACTION':
        case 'ACTION_MANUAL':
            return `action_${uuid}`;
        case 'ACTION_MANUAL_ACTIVITY':
            return `action_activity_${uuid}`;
        case 'ACTION_AUTOMATED_EMAIL':
            return `email_${uuid}`;
        case 'ACTION_AUTOMATED_SMS':
            return `sms_${uuid}`;
        case 'ACTION_AUTOMATED_START_PROCESS':
            return `process_run_${uuid}`;
        case 'ACTION_AUTOMATED_SET_PROCESS_VALUES':
            return `set_process_values_${uuid}`;
        case 'ACTION_AUTOMATED_SET_ENTITY_VALUES':
            return `set_entity_values_${uuid}`;
        case 'ACTION_AUTOMATED_CANCEL_PROCESS':
            return `cancel_process_${uuid}`;
        case 'ACTION_AUTOMATED_GENERATE_FORM_URL':
            return `generate_form_url_${uuid}`;
        case 'ACTION_AUTOMATED_GET_ENTITY_VALUES':
            return `get_entity_values_${uuid}`;
        case 'ACTION_AUTOMATED_CREATE_ENTITY':
            return `create_entity_${uuid}`;
        case 'ACTION_AUTOMATED_GENERATE_DOCUMENT':
            return `generate_document_${uuid}`;
        case 'ACTION_AUTOMATED_GENERATE_QR_CODE':
            return `generate_qr_code_${uuid}`;
        case 'ACTION_AUTOMATED_MILESTONE':
            return `milestone_${uuid}`;
        case 'ACTION_AUTOMATED_STEP':
            return `step_${uuid}`;
        case 'ACTION_SEMIAUTOMATED_DELAY':
            return `delay_${uuid}`;
        case 'ACTION_DECISION':
            return `decision_${uuid}`;
        case 'MERGE':
            return `merge_${uuid}`;
        case 'LOOP_DO':
            return `loop_do_${uuid}`;
        case 'LOOP_WHILE':
            return `loop_while_${uuid}`;
        case 'FORK':
            return `fork_${uuid}`;
        case 'JOIN':
            return `join_${uuid}`;
        default:
            return uuid;
    }
};
const nodeFactoryByType = {
    START_EMAIL_FEEDBACK_TRIGGER: (selectedNode, decisionBranch) => [
        {
            id: 'Start',
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.START,
            target: 'EMAIL_FEEDBACK_TRIGGER',
            parameters: {
                active: true,
                statuses: ['Bounce'],
                bounceTypes: []
            },
            titles: {
                en: 'Email feedback',
                fr: 'Email feedback',
                nl: 'Email feedback',
                de: 'Email feedback',
                it: 'Email feedback',
                pl: 'Email feedback'
            }
        }
    ],
    START_ENTITY_EVENT_TRIGGER: (selectedNode, decisionBranch) => [
        {
            id: 'Start',
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.START,
            target: 'ENTITY_EVENT_TRIGGER',
            parameters: {
                active: true,
                events: ['CREATE']
            },
            titles: {
                en: 'Entity event',
                fr: "Événement d'entité",
                nl: 'Entiteitsgebeurtenis',
                de: 'Entitätsereignis',
                it: 'Evento di entità',
                pl: 'Wydarzenie encji'
            }
        }
    ],
    START_CRON_TRIGGER: (selectedNode, decisionBranch) => [
        {
            id: 'Start',
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.START,
            target: 'CRON_TRIGGER',
            parameters: {
                active: true,
                cronExpression: '0 0 * * *'
            },
            titles: {
                en: 'Cron trigger',
                fr: 'Cron trigger',
                nl: 'Cron trigger',
                de: 'Cron trigger',
                it: 'Cron trigger',
                pl: 'Cron trigger'
            }
        }
    ],
    START_MANUAL: (selectedNode, decisionBranch) => [
        {
            id: 'Start',
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.START,
            target: undefined
        }
    ],
    ACTION_MANUAL: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.MANUAL,
            titles: {},
            descriptions: {},
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [ALL_USERS_PERMISSION_GROUP],
            timeLimitPermissions: {
                viewGroups: defaultDeadlineReadGroups,
                executeGroups: defaultDeadlineWriteGroups
            },
            priority: '4-NORMAL',
            id: getIdForNode('ACTION_MANUAL'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_MANUAL_ACTIVITY: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.MANUAL,
            target: 'ACTIVITY',
            titles: {},
            descriptions: {},
            parameters: {
                timeLimitPermissions: {
                    viewGroups: defaultDeadlineReadGroups,
                    executeGroups: defaultDeadlineWriteGroups
                },
                priority: '4-NORMAL'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [ALL_USERS_PERMISSION_GROUP],
            id: getIdForNode('ACTION_MANUAL_ACTIVITY'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_EMAIL: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'EMAIL',
            titles: {
                en: 'Send email',
                fr: 'Envoyer un e-mail',
                nl: 'Verzend e-mail',
                de: 'E-Mail senden',
                it: 'Invia e-mail',
                pl: 'Wiadomość e-mailowa'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_EMAIL'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_SMS: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'SEND_SMS',
            titles: {
                en: 'Send SMS',
                fr: 'Envoyer un SMS',
                nl: 'Verzend SMS',
                de: 'SMS senden',
                it: 'Inviare SMS',
                pl: 'Wyślij SMS'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_SMS'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_START_PROCESS: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'CREATE_PROCESS_RUN',
            titles: {
                en: 'Start process',
                fr: 'Lancer un processus',
                nl: 'Start een proces',
                de: 'Prozess starten',
                it: 'Avviare un processo',
                pl: 'Rozpocznij proces'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_START_PROCESS'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_SET_PROCESS_VALUES: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'SET_PROCESS_VALUES',
            titles: {
                en: 'Set process values',
                fr: 'Remplir des valeurs du processus',
                nl: 'Vul proceswaarden in',
                de: 'Prozesswerte eintragen',
                it: 'Inserire i valori di processo',
                pl: 'Ustaw wartości procesu'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_SET_PROCESS_VALUES'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_SET_ENTITY_VALUES: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'SET_ENTITY_VALUES',
            titles: {
                en: 'Set entity values',
                fr: "Définir les valeurs d'entité",
                nl: 'Stel entiteitswaarden in',
                de: 'Entitätswerte einstellen',
                it: 'Imposta valori di entità',
                pl: 'Ustaw wartości encji'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_SET_ENTITY_VALUES'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_CANCEL_PROCESS: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'CANCEL_PROCESS',
            titles: {
                en: 'Cancel process',
                de: 'Vorgang abbrechen',
                nl: 'Proces annuleren',
                fr: 'Annuler le processus',
                it: 'Annulla il processo',
                pl: 'Anuluj proces'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_CANCEL_PROCESS'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_GET_ENTITY_VALUES: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'GET_ENTITY_VALUES',
            titles: {
                en: 'Get entity values',
                fr: "Obtenir les valeurs d'entité",
                nl: 'Entiteitswaarden ophalen',
                de: 'Entitätswerte abrufen',
                it: 'Ottieni valori di entità',
                pl: 'Pobierz wartości encji'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_GET_ENTITY_VALUES'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_CREATE_ENTITY: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'CREATE_ENTITY',
            titles: {
                en: 'Create entity',
                fr: 'Créer une entité',
                nl: 'Entiteit maken',
                de: 'Entität erstellen',
                it: 'Crea entità',
                pl: 'Utwórz encję'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_CREATE_ENTITY'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_GENERATE_FORM_URL: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'GENERATE_FORM_URL',
            titles: {
                en: 'Generate form URL',
                fr: "Générer l'URL du formulaire",
                nl: 'Formulier-URL genereren',
                de: 'Formular-URL generieren',
                it: 'Genera URL modulo',
                pl: 'Generuj adres URL formularza'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_GENERATE_FORM_URL'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_GENERATE_DOCUMENT: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'GENERATE_DOCUMENT',
            titles: {
                en: 'Generate document',
                fr: 'Générer un document',
                nl: 'Genereer document',
                de: 'Dokument generieren',
                it: 'Genera documento',
                pl: 'Generuj dokument'
            },
            parameters: { fileType: 'pdf' },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_GENERATE_DOCUMENT'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_GENERATE_QR_CODE: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'GENERATE_QR_CODE',
            titles: {
                en: 'Generate QR code',
                fr: 'Générer un code QR',
                nl: 'QR-code genereren',
                de: 'QR-Code generieren',
                it: 'Genera codice QR',
                pl: 'Generuj kod QR'
            },
            parameters: {
                binaryProperty: 'data'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_GENERATE_QR_CODE'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_MILESTONE: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'MILESTONE',
            titles: {
                en: 'Milestone',
                fr: 'Jalon',
                nl: 'Mijlpaal',
                de: 'Meilenstein',
                it: 'Pietra miliare',
                pl: 'Kamień milowy'
            },
            parameters: {},
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_MILESTONE'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_AUTOMATED_STEP: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'STEP',
            titles: {
                en: 'Step',
                fr: 'Pas',
                nl: 'Stap',
                de: 'Schritt',
                it: 'Passo',
                pl: 'Krok'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_AUTOMATED_STEP'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_SEMIAUTOMATED_DELAY: (selectedNode, decisionBranch) => [
        {
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.SEMIAUTOMATED,
            target: 'DELAY',
            titles: {
                en: 'Delay',
                fr: 'Retard',
                nl: 'Vertraging',
                de: 'Verzögern',
                it: 'Ritardo',
                pl: 'Opóźnienie'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_SEMIAUTOMATED_DELAY'),
            nextId: getNextNodeId(selectedNode, decisionBranch)
        }
    ],
    ACTION_DECISION: (selectedNode, decisionBranch) => {
        const mergeNode = {
            id: getIdForNode('MERGE'),
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.MERGE
        };
        const decisionNode = {
            query: { condition: ConditionType.and, filters: [] },
            type: ActivityNodeTypes.ACTION,
            subtype: ActivityActionNodeSubtypes.AUTOMATED,
            target: 'DECISION',
            titles: {
                en: 'Title',
                fr: 'Titre',
                nl: 'Titel',
                de: 'Titel',
                it: 'Titolo',
                pl: 'Tytuł'
            },
            viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
            executeGroups: [],
            id: getIdForNode('ACTION_DECISION'),
            parameters: {
                wait: false,
                delay: {
                    value: 1,
                    unit: 'days'
                }
            },
            endId: mergeNode.id,
            queryTrueId: mergeNode.id,
            queryFalseId: mergeNode.id
        };
        return [decisionNode, mergeNode];
    },
    LOOP_WHILE: (selectedNode, decisionBranch) => {
        const loopId = getIdForNode('LOOP_WHILE');
        const doNode = {
            type: ActivityNodeTypes.LOOP_DO,
            id: getIdForNode('LOOP_DO'),
            nextId: loopId,
            endId: loopId
        };
        const loopNode = {
            query: { condition: ConditionType.and, filters: [] },
            type: ActivityNodeTypes.LOOP_WHILE,
            titles: {},
            id: loopId,
            nextId: getNextNodeId(selectedNode, decisionBranch),
            startId: doNode.id
        };
        return [doNode, loopNode];
    },
    FORK: (selectedNode, decisionBranch) => {
        const joinNode = {
            id: getIdForNode('JOIN'),
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.JOIN
        };
        const forkNode = {
            id: getIdForNode('FORK'),
            nextIds: [joinNode.id],
            endId: joinNode.id,
            type: ActivityNodeTypes.FORK
        };
        return [forkNode, joinNode];
    }
};
const hp3nNodeFactory = (node, selectedNode, decisionBranch) => {
    let parameters = null;
    if (node.properties) {
        parameters = NodeHelpers.getNodeParameters(node.properties, {}, true, false, null);
    }
    if (isTriggerNode(node)) {
        return [
            {
                type: ActivityNodeTypes.START,
                target: node.name,
                titles: {
                    en: node.displayName,
                    fr: node.displayName,
                    nl: node.displayName,
                    de: node.displayName,
                    it: node.displayName
                },
                credentials: {},
                active: true,
                parameters: parameters || {},
                id: 'Start',
                nextId: getNextNodeId(selectedNode, decisionBranch),
                webhookId: generateId(undefined, 32)
            }
        ];
    }
    const mainNode = {
        type: ActivityNodeTypes.ACTION,
        subtype: ActivityActionNodeSubtypes.AUTOMATED,
        target: node.name,
        titles: {
            en: node.displayName,
            fr: node.displayName,
            nl: node.displayName,
            de: node.displayName,
            it: node.displayName
        },
        credentials: {},
        parameters: parameters || {},
        viewGroups: [ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP],
        id: getIdForNode(node.name),
        nextId: getNextNodeId(selectedNode, decisionBranch)
    };
    if (node.outputs.length > 1) {
        const mergeNode = {
            id: getIdForNode('MERGE'),
            nextId: getNextNodeId(selectedNode, decisionBranch),
            type: ActivityNodeTypes.MERGE
        };
        return [
            {
                ...mainNode,
                nextId: mergeNode.id,
                nextIds: node.outputs.map(() => mergeNode.id)
            },
            mergeNode
        ];
    }
    return [mainNode];
};
export const buildNode = (node, selectedNode, decisionBranch) => {
    if (isOwnNodeDescription(node)) {
        const nodeFactory = nodeFactoryByType[node.type];
        if (nodeFactory) {
            return nodeFactory(selectedNode, decisionBranch);
        }
    }
    else {
        if (node.properties && node.properties.length) {
            return hp3nNodeFactory(node, selectedNode, decisionBranch);
        }
    }
    throw new Error('Node factory not found');
};
export const getNextNodeId = (node, decisionBranch) => {
    switch (node.type) {
        case ActivityNodeTypes.ACTION:
            if (isDecisionNode(node)) {
                switch (decisionBranch) {
                    case 'TRUE':
                        return node.queryTrueId;
                    case 'FALSE':
                        return node.queryFalseId;
                    default:
                        return node.endId;
                }
            }
            if (typeof decisionBranch === 'number' &&
                'nextIds' in node &&
                node.nextIds &&
                node.nextIds?.length > decisionBranch) {
                return node.nextIds[decisionBranch];
            }
            return node.nextId;
        case ActivityNodeTypes.MERGE:
        case ActivityNodeTypes.LOOP_DO:
        case ActivityNodeTypes.JOIN:
        case ActivityNodeTypes.START:
        case ActivityNodeTypes.LOOP_WHILE:
            return node.nextId;
        case ActivityNodeTypes.FORK:
            return node.endId;
        default:
            throw new Error('Could not obtain next node id');
    }
};
