import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { INTEGRATION_MODULE_MICROSOFT_ACTIVITY_MAX_USERS } from '@hypercharge/portal-utils';
import { keyBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../../common/components/Select';
import SortableList from '../../../../../common/components/SortableList';
import { oneHourInMs } from '../../../../../common/utils/react-query-client';
import { fetchMicrosoftUsers } from '../actions';
const MicrosoftUserSelector = ({ value, onChange, microsoftTenantId, label, className }) => {
    const { t } = useI18n();
    const dispatchFetchMicrosoftUsers = useDispatch();
    const { data, isError, isFetching } = useQuery(['MICROSOFT_USERS', microsoftTenantId], () => {
        return dispatchFetchMicrosoftUsers(fetchMicrosoftUsers(microsoftTenantId));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    const users = data?.results;
    const usersMapById = useMemo(() => {
        return keyBy(users, 'id');
    }, [users]);
    const draggableItems = useMemo(() => {
        if (!value?.length) {
            return [];
        }
        return value?.map((item) => {
            const user = usersMapById[item];
            return {
                id: item,
                label: user ? getLabelFromUser(user) : item,
                item
            };
        });
    }, [usersMapById, value]);
    const _onChange = useCallback((newDraggableItems) => {
        onChange(newDraggableItems.map((valueItem) => valueItem.item));
    }, [onChange]);
    const usersOptions = useMemo(() => {
        if (!users?.length) {
            return [];
        }
        return users
            ?.filter((user) => {
            return !value?.includes(user.id);
        })
            .map((user) => {
            return {
                value: user.id,
                label: getLabelFromUser(user),
                item: user
            };
        });
    }, [users, value]);
    const onSelectNewUser = useCallback((newValue) => {
        onChange([...(value || []), newValue]);
    }, [onChange, value]);
    return (React.createElement("div", { className: className },
        label && React.createElement(LabelText, null, label),
        React.createElement(SortableList, { value: draggableItems, onChange: _onChange, sortable: true, disabled: isFetching }),
        React.createElement(Select, { showSearch: true, value: null, placeholder: t('INTEGRATION_MICROSOFT_SELECT_USERS_PLACEHOLDER'), options: usersOptions, optionFilterProp: "label", disabled: isFetching ||
                isError ||
                draggableItems.length >= INTEGRATION_MODULE_MICROSOFT_ACTIVITY_MAX_USERS, onChange: onSelectNewUser })));
};
export default MicrosoftUserSelector;
const getLabelFromUser = (user) => {
    let label = user.name || user.principalName || user.id;
    if (user.jobTitle) {
        label = `${label} (${user.jobTitle})`;
    }
    return label;
};
