import createNextState from 'immer';
/*
A utility function to create reducers that handle specific action types.
Takes an initial state value and an object that maps action types to case reducer functions.
Internally, it uses the immer library, so you can write code in your case reducers that mutates the existing state value,
and it will correctly generate immutably-updated state values instead.

Documentation:
https://github.com/markerikson/redux-starter-kit#createreducer
https://github.com/mweststrate/immer
*/
export const createReducer = (initialState, actionsMap) => (state = initialState, action) => createNextState(state, (draft) => {
    const caseReducer = actionsMap[action.type];
    if (caseReducer) {
        // it is not mandatory for the caseReducer to return anything, it can simply mutate the draft
        return caseReducer(draft, action);
    }
    return draft;
});
export default {
    createReducer
};
