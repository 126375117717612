import { isEmpty, map, without } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import PropertyFieldInlineEditor from '../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import { isFileArrayValue, isFileDisplayData } from '../../../cms/data/types';
import { useFileMovingContext } from '../../context/FileMovingContext';
import { FileField } from '../../storage';
import { TableFieldLabel } from './utils';
const TablePropertyField = ({ canEdit, entityId, definitionId, showIcon, field, style, requiredFieldsConfig, permissionFieldsConfig, selectProperty, fromExpandedView, updatePropertyData, handleSetShowExpandedViews, showExpandedView, updateNestedPropertyData, bulkUpdateNestedItems, additionalColumns, uploadFiles, fileCarouselHeight }) => {
    const { onFilesMoved } = useFileMovingContext();
    const nestedRequiredFieldsConfig = requiredFieldsConfig?.getNestedRequiredFieldsConfig?.(field);
    const nestedPermissionFieldsConfig = permissionFieldsConfig?.getNestedPermissionFieldsConfig?.(field);
    const updateTableFieldProperty = useCallback(async (entityId, propertyId, value) => {
        if (!updateNestedPropertyData) {
            return;
        }
        return updateNestedPropertyData(entityId, `${field.propertyId}.${propertyId}`, value);
    }, [field.propertyId, updateNestedPropertyData]);
    const bulkUpdateTableFieldProperty = useCallback(async (item, entityIds) => {
        if (!bulkUpdateNestedItems) {
            return;
        }
        return bulkUpdateNestedItems(field.propertyId, item, entityIds);
    }, [bulkUpdateNestedItems, field.propertyId]);
    const extraProps = useMemo(() => nestedRequiredFieldsConfig
        ? {
            extraDisplayComponentProps: {
                requiredFieldsConfig: nestedRequiredFieldsConfig,
                permissionFieldsConfig: nestedPermissionFieldsConfig,
                updateNestedPropertyData: updateTableFieldProperty
            },
            extraEditorComponentProps: {
                requiredFieldsConfig: nestedRequiredFieldsConfig,
                permissionFieldsConfig: nestedPermissionFieldsConfig
            }
        }
        : {}, [nestedPermissionFieldsConfig, nestedRequiredFieldsConfig, updateTableFieldProperty]);
    const label = useMemo(() => React.createElement(TableFieldLabel, { field: field, requiredFieldsConfig: requiredFieldsConfig }), [field, requiredFieldsConfig]);
    const onHistoryOpened = useCallback((propertyId) => selectProperty(propertyId || field.propertyId), [field, selectProperty]);
    const onSave = useCallback((value, propertyId) => updatePropertyData(propertyId || field.propertyId, value), [field, updatePropertyData]);
    const onFileSave = useCallback(async (files) => isFileDisplayData(field)
        ? await uploadFiles(field.propertyId, field.meta.list && isFileArrayValue(field.value) ? [...field.value, ...files] : files)
        : undefined, [field, uploadFiles]);
    const onFileRemove = useCallback(async (id) => {
        if (isFileDisplayData(field)) {
            const filteredFileIds = without(map(field?.value || [], 'id'), id);
            return await updatePropertyData(field.propertyId, isEmpty(filteredFileIds) ? null : filteredFileIds);
        }
    }, [field, updatePropertyData]);
    if (isFileDisplayData(field)) {
        return (React.createElement(FileField, { showIcon: showIcon, displayActions: true, label: label, files: field.value, meta: field.meta, fieldStyle: style, error: requiredFieldsConfig ? requiredFieldsConfig.isErrorField(field.propertyId) : false, success: requiredFieldsConfig ? requiredFieldsConfig.isSuccessField(field.propertyId) : false, canEdit: !!canEdit && field.canEdit, loading: field.pending, carouselHeight: fileCarouselHeight, onSave: onFileSave, onRemove: onFileRemove, onHistoryOpened: onHistoryOpened, propertyId: field.propertyId, onFilesMoved: onFilesMoved, definitionId: definitionId, entityId: entityId, isDraggableFileCarousel: true }));
    }
    return (React.createElement(PropertyFieldInlineEditor, { fromExpandedView: fromExpandedView, onShowExpandedViews: () => handleSetShowExpandedViews && handleSetShowExpandedViews(field.propertyId), showExpandedView: showExpandedView, canEdit: !!canEdit && (field.canEdit || !!permissionFieldsConfig?.isCanEditField(field)), showIcon: showIcon, error: requiredFieldsConfig ? requiredFieldsConfig.isErrorField(field.propertyId) : false, label: label, meta: field.meta, propertyId: field.propertyId, style: style, onHistoryOpened: onHistoryOpened, onSave: onSave, computed: field.computed, type: field.type, 
        // @ts-expect-error
        value: field.value, updateNestedPropertyData: !updateNestedPropertyData ? undefined : updateTableFieldProperty, bulkUpdateNestedItems: !bulkUpdateNestedItems ? undefined : bulkUpdateTableFieldProperty, entityId: entityId, definitionId: definitionId, additionalColumns: additionalColumns, ...extraProps }));
};
export { TablePropertyField };
