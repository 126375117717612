import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Collapse } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { PropertyTypes } from '../../../../../../common/types';
import { useSystemSectionsVisibility } from '../../../../../common/context/SystemSectionsVisibilityProvider';
import PropertyFieldPreview from './PropertyFieldPreview';
const SectionListPreview = ({ definitionId, sections, entity }) => {
    const { language } = useI18n();
    const [activeKey, setActiveKey] = useState('');
    const { isSystemSectionsVisible, hiddenSections } = useSystemSectionsVisibility();
    const [activeKeyInitialized, setActiveKeyInitialized] = useState(false);
    const pinnedSection = useMemo(() => {
        return sections.find((section) => section.pinned);
    }, [sections]);
    useEffect(() => {
        if (activeKey === '' && sections.length) {
            const allDefaultOpenSections = sections
                .map((section) => section.sectionId)
                .filter((sectionId) => !hiddenSections.includes(sectionId));
            setActiveKey([
                ...(pinnedSection?.sectionId ? [pinnedSection.sectionId] : []),
                ...allDefaultOpenSections
            ]);
        }
    }, [activeKey, sections, hiddenSections, pinnedSection?.sectionId]);
    useEffect(() => {
        if (isSystemSectionsVisible && Array.isArray(activeKey) && !activeKeyInitialized) {
            setActiveKeyInitialized(true);
            setActiveKey(Array.from(new Set([
                ...(pinnedSection?.sectionId ? [pinnedSection.sectionId] : []),
                ...activeKey,
                ...hiddenSections
            ])));
        }
    }, [
        isSystemSectionsVisible,
        hiddenSections,
        activeKey,
        activeKeyInitialized,
        pinnedSection?.sectionId
    ]);
    return (React.createElement("div", { className: "section overflow-hidden" },
        React.createElement(Collapse, { activeKey: activeKey, className: "collapse-outer", expandIconPosition: "end", onChange: setActiveKey, items: sections.map((section) => {
                return {
                    key: section.sectionId,
                    label: (React.createElement("span", { className: "collapse-panel-header" }, getTranslation(section.titles, language))),
                    children: [
                        React.createElement("div", { className: "row", key: "fields" }, section.values
                            .filter((field) => field.type !== PropertyTypes.file)
                            .map((field) => (React.createElement(PropertyFieldPreview, { key: field.propertyId, field: field, entity: entity }))))
                    ]
                };
            }) })));
};
export default SectionListPreview;
