import moment from 'moment';
import { DEFAULT_RELATIVE_VALUE } from './constants';
export const getRelativeOptions = (value) => {
    const [relativeValue, relativeUnit] = value.offset.split(' ');
    return {
        relativeValue,
        relativeUnit
    };
};
export const getRelativeDateInAbsoluteValue = (rangePicker) => {
    const filter = {
        startTime: 0,
        endTime: 0
    };
    if ('offset' in rangePicker) {
        const { relativeValue, relativeUnit } = getRelativeOptions(rangePicker) || rangePicker.offset.split(' ');
        filter.endTime = moment().valueOf();
        let startTime = moment();
        if (rangePicker?.fromStart) {
            startTime = startTime.subtract(Number(relativeValue) - 1, relativeUnit).startOf(relativeUnit);
        }
        else {
            startTime = startTime.subtract(relativeValue, relativeUnit);
        }
        filter.startTime = startTime.valueOf();
    }
    else {
        if (rangePicker.start) {
            filter.startTime = rangePicker.start;
        }
        if (rangePicker.end) {
            filter.endTime = rangePicker.end;
        }
    }
    return [filter.startTime, filter.endTime];
};
export const getDateRangeSelectorValue = (rangePicker) => {
    if (!rangePicker) {
        return DEFAULT_RELATIVE_VALUE;
    }
    if ('offset' in rangePicker && rangePicker.offset) {
        // relative
        return {
            ...rangePicker
        };
    }
    else if ('start' in rangePicker || 'end' in rangePicker) {
        // absolute
        return {
            time: [rangePicker.start, rangePicker.end]
        };
    }
    return DEFAULT_RELATIVE_VALUE;
};
