import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { AceEditor, AceEditorMode } from '../../../../../common/components/AceEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import styles from './ScriptsForm.module.scss';
const ScriptForm = () => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.wrapper} pb-2` },
        React.createElement(LabelText, null, `${t('APPS_HYPER_DEALER__SNIPPET')} ${t('APPS_HYPER_DEALER__HEAD')}`),
        React.createElement(FormikField, { name: "configuration.website.snippetHead", component: AceEditor, theme: "textmate", mode: AceEditorMode.html }),
        React.createElement(LabelText, { className: 'mt-4' }, `${t('APPS_HYPER_DEALER__SNIPPET')} ${t('APPS_HYPER_DEALER__BODY')}`),
        React.createElement(FormikField, { name: "configuration.website.snippet", component: AceEditor, theme: "textmate", mode: AceEditorMode.html }),
        React.createElement(LabelText, { className: 'mt-4' }, `${t('APPS_HYPER_DEALER__SNIPPET')} ${t('APPS_HYPER_DEALER__ROOT')}`),
        React.createElement(FormikField, { name: "configuration.website.snippetRoot", component: AceEditor, theme: "textmate", mode: AceEditorMode.html })));
};
export default ScriptForm;
