import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Input } from 'antd';
import { isObject, toString } from 'lodash';
import React, { useCallback } from 'react';
import { isComputedExpression } from '../../../utils/computedExpression';
import { ComputedInput } from '../ComputedExpressionEditor';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.is },
    { value: FilterOperatorTypes.isNot },
    { value: FilterOperatorTypes.contains },
    { value: FilterOperatorTypes.notContains },
    { value: FilterOperatorTypes.startsWith },
    { value: FilterOperatorTypes.endsWith }
];
const TextFilter = ({ className, filter, onChange, showSelectOperator, disabledSelectOperator, disabled, allowComputed, propertiesTree, autoFocus }) => {
    const data = 'data' in filter ? toString(filter.data) : undefined;
    const onChangeInput = useCallback((value) => {
        const newValue = isObject(value) && 'target' in value ? value.target.value : value;
        const newFilter = buildFilter(filter.field, filter.operator, newValue);
        onChange(newFilter);
    }, [filter.field, filter.operator, onChange]);
    return (React.createElement(BaseFilter, { onChange: onChange, operators: availableOperators, filter: filter, showSelectOperator: showSelectOperator, disabledSelectOperator: disabledSelectOperator, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { component: Input, type: "text", value: data, onChange: onChangeInput, disabled: disabled, propertiesTree: propertiesTree, ref: (ref) => autoFocus && ref?.focus({ preventScroll: true }) })) : (React.createElement(Input, { type: "text", value: data, onChange: onChangeInput, disabled: disabled, ref: (ref) => autoFocus && ref?.focus({ preventScroll: true }) }))));
};
export default TextFilter;
