import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { DEFAULT_LANGUAGE } from 'config';
import { head } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { Select } from '../../../common/components/Select';
import { PropertyTypes } from '../../../common/types';
import { LoadingBlock } from '../../../common/utils/formUtils';
export const GroupingPropertyList = ({ input: { value, onChange }, changeField, definitionId, availablePropertyType = [PropertyTypes.entity], customOptionsGroupBy }) => {
    const { language, t } = useI18n();
    const { displayData, displayDataStatus } = useEntityDisplayData(definitionId);
    const { availableProperties, defaultProperty } = useMemo(() => {
        const availableProperties = (displayData?.data || [])
            .flatMap((data) => data.values)
            .filter((value) => availablePropertyType.includes(value.type))
            .map(({ propertyId, labels }) => ({ propertyId, labels }));
        let defaultProperty = '';
        if (availableProperties && !value) {
            const firstProperty = head(availableProperties);
            if (firstProperty) {
                defaultProperty = firstProperty.propertyId;
            }
        }
        return { availableProperties, defaultProperty };
    }, [availablePropertyType, displayData?.data, value]);
    const { options, displayValue } = useMemo(() => {
        const options = customOptionsGroupBy ||
            availableProperties.map(({ labels, propertyId }) => ({
                value: propertyId,
                label: labels[language] || labels[DEFAULT_LANGUAGE]
            }));
        const isValueExisting = options.some((option) => {
            return option.value === value;
        });
        return {
            options,
            displayValue: value && !isValueExisting ? `${value} (${t('STATUS_DELETED')})` : value
        };
    }, [availableProperties, customOptionsGroupBy, language, t, value]);
    useEffect(() => {
        if (defaultProperty) {
            onChange(defaultProperty);
        }
    }, [defaultProperty, onChange]);
    if (displayDataStatus.isPending) {
        return React.createElement(LoadingBlock, null);
    }
    if (!availableProperties.length) {
        return null;
    }
    return (React.createElement(Select, { value: displayValue || defaultProperty, onChange: (value) => {
            changeField('kanban.columns', []);
            onChange(value);
        }, optionFilterProp: "label", showSearch: true, options: options }));
};
