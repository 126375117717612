import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Typography } from 'antd';
import { TENANT_ID } from 'config';
import React, { useCallback, useMemo } from 'react';
import { IoMdColorFill } from 'react-icons/io';
import { error } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropertyFieldInlineEditor from '../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import { SelectColorButton } from '../../../../../common/components/Color/SelectColorButton';
import FormikField from '../../../../../common/components/formik/FormikField';
import { MultiLanguageInput } from '../../../../../common/components/MultiLanguageField';
import { MultiLanguageOptionalValidationSchema } from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { FileField, uploadFiles } from '../../../../../common/storage';
import useFiles from '../../../../../common/storage/useFiles';
import FormsSelectorField from '../../../../../forms/components/common/FormsSelector';
export const getWhitelabelFormSchema = () => {
    return {
        whiteLabel: Yup.object({
            address: MultiLanguageOptionalValidationSchema,
            colors: Yup.object({
                actionActive: Yup.string().nullable(),
                actionDefault: Yup.string().nullable(),
                actionHover: Yup.string().nullable(),
                brand: Yup.string().nullable()
            }),
            companyName: MultiLanguageOptionalValidationSchema,
            contactEmail: Yup.string().nullable(),
            copyrightCompanyName: MultiLanguageOptionalValidationSchema,
            defaultLanguage: Yup.string().nullable(),
            favicon: Yup.string().nullable(),
            legalTerms: MultiLanguageOptionalValidationSchema,
            logo: Yup.string().nullable(),
            orderFormId: Yup.string().nullable(),
            privacyPolicy: MultiLanguageOptionalValidationSchema
        })
    };
};
const whiteLabelColors = [
    {
        translationKey: 'APPS_HYPER_EVENT__ACTIVE_COLOR',
        name: 'actionActive'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__DEFAULT_COLOR',
        name: 'actionDefault'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__HOVER_COLOR',
        name: 'actionHover'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__BRAND_COLOR',
        name: 'brand'
    }
];
const WhiteLabelOptionsForm = ({ configuration, setFieldValue, disabled }) => {
    const { t } = useI18n();
    const notificationDispatch = useDispatch();
    const dispatch = useDispatch();
    const logo = useMemo(() => (configuration?.logo ? [configuration?.logo] : []), [configuration?.logo]);
    const logoFile = useFiles(logo);
    const favicon = useMemo(() => (configuration?.favicon ? [configuration?.favicon] : []), [configuration?.favicon]);
    const faviconFile = useFiles(favicon);
    const uploadFilesHandler = useCallback(async (files, path) => {
        try {
            const uploadData = await uploadFiles(dispatch, files, TENANT_ID);
            uploadData.map((file) => setFieldValue(path, file.id));
        }
        catch (e) {
            notificationDispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    }, [dispatch, notificationDispatch, setFieldValue, t]);
    const onRemoveFile = useCallback((path) => {
        return new Promise((resolve, reject) => {
            try {
                setFieldValue(path, null);
            }
            catch (e) {
                reject(e);
            }
            finally {
                resolve(undefined);
            }
        });
    }, [setFieldValue]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-6 col-12" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__FAVICON')),
                React.createElement(FormikField, { name: `configuration.whiteLabel.favicon`, component: FileField, files: faviconFile, meta: { accept: 'image/*', list: false, carousel: false }, error: false, loading: false, onSave: (files) => uploadFilesHandler(files, `configuration.whiteLabel.favicon`), onRemove: () => onRemoveFile(`configuration.whiteLabel.favicon`), canEdit: !disabled })),
            React.createElement("div", { className: "col-md-6 col-12" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__LOGO')),
                React.createElement(FormikField, { name: `configuration.whiteLabel.logo`, component: FileField, files: logoFile, meta: { accept: 'image/*', list: false, carousel: false }, error: false, loading: false, onSave: (files) => uploadFilesHandler(files, `configuration.whiteLabel.logo`), onRemove: () => onRemoveFile(`configuration.whiteLabel.logo`), canEdit: !disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__CONTACT_EMAIL')),
                React.createElement(FormikField, { name: `configuration.whiteLabel.contactEmail`, component: BasicInput, type: "email", meta: { multiline: false, multilanguage: false }, canEdit: !disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__DEFAULT_LANGUAGE')),
                React.createElement(FormikField, { name: `configuration.whiteLabel.defaultLanguage`, component: PropertyFieldInlineEditor, type: "entity", meta: { definitionId: 'language', list: false }, canEdit: !disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__ORDER_FORM')),
                React.createElement(FormikField, { className: "mt-2", name: `configuration.whiteLabel.orderFormId`, component: FormsSelectorField, canEdit: true, disabled: disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__ADDRESS')),
                React.createElement(FormikField, { className: "mt-2", name: `configuration.whiteLabel.address`, component: MultiLanguageInput, fieldComponent: BasicInput, canEdit: true, disabled: disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__COMPANY_NAME')),
                React.createElement(FormikField, { className: "mt-2", name: `configuration.whiteLabel.companyName`, component: MultiLanguageInput, fieldComponent: BasicInput, canEdit: true, disabled: disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__COPYRIGHT_COMPANY_NAME')),
                React.createElement(FormikField, { className: "mt-2", name: `configuration.whiteLabel.copyrightCompanyName`, component: MultiLanguageInput, fieldComponent: BasicInput, canEdit: true, disabled: disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__LEGAL_TERMS')),
                React.createElement(FormikField, { className: "mt-2", name: `configuration.whiteLabel.legalTerms`, component: MultiLanguageInput, fieldComponent: BasicInput, canEdit: true, disabled: disabled })),
            React.createElement("div", { className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__PRIVACY_POLICY')),
                React.createElement(FormikField, { className: "mt-2", name: `configuration.whiteLabel.privacyPolicy`, component: MultiLanguageInput, fieldComponent: BasicInput, canEdit: true, disabled: disabled }))),
        React.createElement("div", { className: "row mt-3" },
            React.createElement(Typography.Title, { className: "mb-0", level: 4 }, t('APPS_HYPER_EVENT__COLORS')),
            whiteLabelColors.map((whiteLabelColor) => (React.createElement("div", { key: whiteLabelColor.translationKey, className: "col-md-6 col-12 mt-3" },
                React.createElement("span", null, t(whiteLabelColor.translationKey)),
                React.createElement("div", { className: "mt-2" },
                    React.createElement(SelectColorButton, { icon: IoMdColorFill, value: configuration?.colors?.[whiteLabelColor.name], onChange: (color) => setFieldValue(`configuration.whiteLabel.colors.${whiteLabelColor.name}`, color) }))))))));
};
export default WhiteLabelOptionsForm;
