import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import * as Yup from 'yup';
import FormikField from '../../../../common/components/formik/FormikField';
import { FieldsContainer } from '../../../../common/utils/formUtils';
import MicrosoftLoginInput from './components/MicrosoftLoginInput';
import MicrosoftUserSelector from './components/MicrosoftUserSelector';
export const getFormSchema = () => {
    return Yup.object().shape({
        users: Yup.array().of(Yup.string()).required(t('VALIDATIONS__REQUIRED'))
    });
};
export const initialValues = {};
const Form = ({ configuration }) => {
    const { t } = useI18n();
    return (React.createElement(FieldsContainer, null,
        React.createElement(FormikField, { name: "organizationId", component: MicrosoftLoginInput, className: "mt-3" }),
        configuration.organizationId && (React.createElement(FormikField, { label: React.createElement(Divider, null, t('COMMON_USERS')), name: "users", component: MicrosoftUserSelector, className: "mt-3", microsoftTenantId: configuration.organizationId }))));
};
export default Form;
