import { TENANT_ID } from 'config';
export const fetchAllDefinitions = () => ({
    type: 'METADATA__FETCH_DEFINITIONS',
    payload: {},
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/definitions/`,
            method: 'GET'
        }
    }
});
export const fetchDefinition = (id) => ({
    type: 'METADATA__FETCH_DEFINITION',
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/definitions/${id}`,
            method: 'GET'
        }
    }
});
export const deleteDefinition = (id) => ({
    type: 'METADATA__DEFINITION_DELETE',
    payload: {
        id
    },
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/definitions/${id}`,
            method: 'DELETE',
            actionContext: {
                id
            },
            allowPromiseReject: true
        },
        auth: true
    }
});
export const saveDefinition = (id, values) => ({
    type: 'METADATA__DEFINITION_SAVE',
    payload: {
        ...values,
        tenantId: TENANT_ID
    },
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/definitions/${id || ''}`,
            method: id ? 'PUT' : 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
