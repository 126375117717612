import React, { useCallback } from 'react';
import SelectActivityType from './SelectActivityType';
const SelectActivityTypeButton = ({ className = '', onSelect, placeholder, disabled, isOptionDisabled, isOptionHidden, showArrow = false, error }) => {
    const onChange = useCallback((value) => {
        if (value && onSelect) {
            onSelect(Array.isArray(value) ? value[0] : value);
        }
    }, [onSelect]);
    return (React.createElement(SelectActivityType, { className: `antd-tree-select-btn ${className}`, showSearch: true, value: null, placeholder: placeholder, multiple: false, disabled: disabled, onChange: onChange, popupMatchSelectWidth: false, isOptionDisabled: isOptionDisabled, isOptionHidden: isOptionHidden, showArrow: showArrow, error: error }));
};
export default SelectActivityTypeButton;
