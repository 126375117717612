import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AppNotifications } from '@hypercharge/hyper-react-base/lib/notifications';
import { COMPANY_NAME } from 'config';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ADMIN_PATH } from '../../admin';
import AdminRoutes from '../../admin/components/Routes';
import { AUTH_PATH, AuthRoutes } from '../../auth';
import { CMS_IMPORTER_PATH, CMS_PATH, EntitiesRoutes, FEATURED_ENTITIES_PATH, FeaturedEntitiesRoutes, ImporterRoutes, REQUIRED_CMS_PERMISSION } from '../../cms';
import { useMyContact } from '../../crm/components/use-my-contact';
import { DOCUMENTS_PATH, Routes as DocumentsRoutes } from '../../documents';
import { FORMS_PATH, FormsRoutes, HYPER_FORMS_PERMISSION } from '../../forms';
import { GROUPS_PATH, GroupsRoutes } from '../../groups';
import AppHeader, { FeaturedEntities, LogoutHeader } from '../../header';
import { FULL_HOME_PATH, HOME_PATH } from '../../home';
import HomePage from '../../home/HomePage';
import { HYPER_BI_PATH, HYPER_BI_PERMISSION, HyperBiRoutes } from '../../hyperbi';
import { MARKETING_PATH, RestrictedMarketingRoutes } from '../../marketing';
import { SETTINGS_PATH, SettingsRoutes } from '../../settings';
import { useDisplayTenant } from '../../tenant/useDisplayTenant';
import { FEATURED_PROCESSES_PATH, REQUIRED_WORKFLOWS_PERMISSION, WORKFLOWS_PATH } from '../../workflows';
import FeaturedProcessesRoutes from '../../workflows/common/components/FeaturedProcessesRoutes';
import RestrictedWorkflowsRoutes from '../../workflows/common/components/Routes';
import StickyTabs from '../components/StickyTabs';
import VersionMessage from '../components/version-message/VersionMessage';
import useExternalScripts from '../hooks/useExternalScripts';
import { getNotificationsState } from '../reducers/rootSelector';
import { APP_ADMIN_PERMISSION, NO_PERMISSIONS_LANDING_PAGE_PATH, PLATFORM_ADMIN_PERMISSION, REQUIRED_MAIL_TEMPLATES_PERMISSION, REQUIRED_PORTAL_PERMISSION } from '../utils/constants';
import styles from './App.module.scss';
import NoPermissionsLandingPage from './NoPermissionsLandingPage';
const App = () => {
    const { language } = useI18n();
    useExternalScripts();
    const { isAuthenticated } = useMyContact();
    const notifications = useSelector(getNotificationsState);
    const { tenantTheme } = useDisplayTenant();
    const rootVariablesString = useMemo(() => {
        if (!tenantTheme.variables) {
            return '';
        }
        return Object.entries(tenantTheme.variables)
            .map(([varName, color]) => `--${varName}: ${color};`)
            .join('');
    }, [tenantTheme.variables]);
    useEffect(() => {
        moment.updateLocale(language, {
            week: {
                dow: 1
            }
        });
    }, [language]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { title: COMPANY_NAME }, !!rootVariablesString && (React.createElement("style", { type: "text/css", id: "theme-variables" }, `:root { ${rootVariablesString} }`))),
        React.createElement(ErrorBoundary, null,
            React.createElement("div", { id: "header", className: styles.header },
                React.createElement(VersionMessage, null),
                React.createElement(Switch, { key: "header" },
                    React.createElement(Route, { path: NO_PERMISSIONS_LANDING_PAGE_PATH, component: LogoutHeader }),
                    React.createElement(Route, { path: AUTH_PATH, render: () => null }),
                    isAuthenticated && React.createElement(Route, { component: AppHeader }))),
            React.createElement(Switch, { key: "featured-entities" },
                React.createElement(Route, { path: NO_PERMISSIONS_LANDING_PAGE_PATH, component: () => null }),
                React.createElement(Route, { path: AUTH_PATH, render: () => null }),
                React.createElement(Route, { component: FeaturedEntities }))),
        React.createElement(ErrorBoundary, null,
            React.createElement(Switch, { key: "content" },
                React.createElement(Route, { path: AUTH_PATH, component: AuthRoutes }),
                React.createElement(Route, { path: HOME_PATH, exact: true, render: () => React.createElement(Redirect, { to: FULL_HOME_PATH }) }),
                React.createElement(Route, { path: FULL_HOME_PATH, render: (props) => React.createElement(HomePage, { permission: REQUIRED_PORTAL_PERMISSION, ...props }) }),
                React.createElement(Route, { path: NO_PERMISSIONS_LANDING_PAGE_PATH, component: NoPermissionsLandingPage }),
                React.createElement(Route, { path: ADMIN_PATH, render: (props) => React.createElement(AdminRoutes, { permission: APP_ADMIN_PERMISSION, ...props }) }),
                React.createElement(Route, { path: `${CMS_PATH}/:definitionId${CMS_IMPORTER_PATH}`, render: (props) => React.createElement(ImporterRoutes, { permission: PLATFORM_ADMIN_PERMISSION, ...props }) }),
                React.createElement(Route, { path: FEATURED_ENTITIES_PATH, render: (props) => (React.createElement(FeaturedEntitiesRoutes, { permission: REQUIRED_CMS_PERMISSION, ...props })) }),
                React.createElement(Route, { path: CMS_PATH, render: (props) => React.createElement(EntitiesRoutes, { permission: REQUIRED_CMS_PERMISSION, ...props }) }),
                React.createElement(Route, { path: GROUPS_PATH, render: (props) => React.createElement(GroupsRoutes, { permission: REQUIRED_CMS_PERMISSION, ...props }) }),
                React.createElement(Route, { path: MARKETING_PATH, render: (props) => (React.createElement(RestrictedMarketingRoutes, { permission: REQUIRED_MAIL_TEMPLATES_PERMISSION, ...props })) }),
                React.createElement(Route, { path: WORKFLOWS_PATH, render: (props) => (React.createElement(RestrictedWorkflowsRoutes, { permission: REQUIRED_WORKFLOWS_PERMISSION, ...props })) }),
                React.createElement(Route, { path: FEATURED_PROCESSES_PATH, render: (props) => (React.createElement(FeaturedProcessesRoutes, { permission: REQUIRED_WORKFLOWS_PERMISSION, ...props })) }),
                React.createElement(Route, { path: DOCUMENTS_PATH, render: (props) => React.createElement(DocumentsRoutes, { permission: APP_ADMIN_PERMISSION, ...props }) }),
                React.createElement(Route, { path: SETTINGS_PATH, render: (props) => React.createElement(SettingsRoutes, { ...props }) }),
                React.createElement(Route, { path: `${HYPER_BI_PATH}`, render: (props) => React.createElement(HyperBiRoutes, { permission: HYPER_BI_PERMISSION, ...props }) }),
                React.createElement(Route, { path: `${FORMS_PATH}`, render: (props) => React.createElement(FormsRoutes, { permission: HYPER_FORMS_PERMISSION, ...props }) }),
                React.createElement(Route, { component: NotFound }))),
        React.createElement(StickyTabs, null),
        React.createElement(AppNotifications, { notifications: notifications, position: "tr" })));
};
export default App;
