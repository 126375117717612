import { Input, InputNumber } from 'antd';
import React, { useMemo } from 'react';
import { ErrorLabel, getIsFormHasError } from '../../utils/formUtils';
const AntInput = ({ className, ...otherProps }) => {
    const error = useMemo(() => getIsFormHasError(otherProps?.meta) ? otherProps?.meta?.error : otherProps.error, [otherProps.error, otherProps.meta]);
    const Component = otherProps.type === 'number' ? InputNumber : Input;
    return (React.createElement("div", { className: `${className ?? ''} w-100 d-flex flex-column align-items-stretch position-relative` },
        React.createElement(Component, { status: error ? 'warning' : undefined, ...otherProps }),
        React.createElement(ErrorLabel, { show: !!error, position: 'absolute', error: error })));
};
export default AntInput;
