import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { combineReducers } from 'redux';
import { GDPR_CHANGE_REQUEST_PROCESS_META_ID } from '../../settings/components/gdpr/utils';
const initialListState = {
    pending: true,
    failed: false,
    lastFetch: null,
    ids: [],
    page: 1,
    limit: 0,
    totalCount: 0
};
// ////////////////////
// ProcessMeta
// ////////////////////
const processesList = createReducer(initialListState, {
    PROCESS_META__DELETE_SUCCESS: (s, a) => {
        const deletedId = a.meta.actionContext.id;
        s.ids = s.ids.filter((id) => id != deletedId);
    },
    PROCESS_META__CREATE_SUCCESS: (s) => {
        s.ids = [];
        s.totalCount = 0;
    }
});
const processesById = createReducer({}, {
    PROCESS_META__FETCH_SINGLE_SUCCESS: (s, a) => {
        s[a.payload.id] = a.payload;
    },
    PROCESS_META__DELETE_SUCCESS: (s, a) => {
        delete s[a.meta.actionContext.id];
    },
    PROCESS_META__SAVE_SUCCESS: (s, a) => {
        s[a.payload.id] = a.payload;
    },
    PROCESS_META__CREATE_SUCCESS: (s, a) => {
        const process = a.meta.actionContext.process;
        s[process.id] = process;
    }
});
const processStatus = createReducer({}, {
    PROCESS_META__FETCH_SINGLE: (s, a) => {
        const { id } = a.meta.http.actionContext;
        const status = s[id] || {};
        status.pending = true;
        status.failed = false;
        s[id] = status;
    },
    PROCESS_META__FETCH_SINGLE_FAIL: (s, a) => {
        const { id } = a.meta.actionContext;
        const status = s[id] || {};
        status.pending = false;
        status.failed = true;
        s[id] = status;
    },
    PROCESS_META__FETCH_SINGLE_SUCCESS: (s, a) => {
        const status = s[a.payload.id] || {};
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[a.payload.id] = status;
    },
    PROCESS_META__DELETE_SUCCESS: (s, a) => {
        delete s[a.meta.actionContext.id];
    }
});
const processesMetaReducer = combineReducers({
    list: processesList,
    byId: processesById,
    status: processStatus
});
const definitionsById = createReducer({}, {
    META_DEFINITION__FETCH_SUCCESS: (s, a) => {
        s[a.meta.actionContext.metaDefinitionId] = a.payload;
    },
    META_DEFINITION__SAVE_SUCCESS: (s, a) => {
        s[a.meta.actionContext.metaDefinitionId] = a.payload;
    },
    UPDATE_GDPR_SETTINGS: (s) => {
        delete s[GDPR_CHANGE_REQUEST_PROCESS_META_ID];
    }
});
const definitionsStatus = createReducer({}, {
    META_DEFINITION__FETCH: (s, a) => {
        const { metaDefinitionId } = a.meta.http.actionContext;
        const status = s[metaDefinitionId] || {};
        status.pending = true;
        status.failed = false;
        s[metaDefinitionId] = status;
    },
    META_DEFINITION__FETCH_FAIL: (s, a) => {
        const { metaDefinitionId } = a.meta.actionContext;
        const status = s[metaDefinitionId] || {};
        status.pending = false;
        status.failed = true;
        s[metaDefinitionId] = status;
    },
    META_DEFINITION__FETCH_SUCCESS: (s, a) => {
        const status = s[a.meta.actionContext.metaDefinitionId] || {};
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[a.meta.actionContext.metaDefinitionId] = status;
    },
    META_DEFINITION__SAVE_SUCCESS: (s, a) => {
        const status = s[a.meta.actionContext.metaDefinitionId] || {};
        status.pending = false;
        status.failed = false;
        s[a.meta.actionContext.metaDefinitionId] = status;
    },
    UPDATE_GDPR_SETTINGS: (s) => {
        delete s[GDPR_CHANGE_REQUEST_PROCESS_META_ID];
    }
});
const definitionsReducer = combineReducers({
    byId: definitionsById,
    status: definitionsStatus
});
export default combineReducers({
    processes: processesMetaReducer,
    definitions: definitionsReducer
});
