import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput, BasicTextArea, ErrorMessage, LabelText, email as validateEmail } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { MAIL_MAX_PRE_HEADER_LENGTH, MAIL_MAX_TITLE_LENGTH } from '@hypercharge/portal-utils';
import { Input, Modal, Tooltip } from 'antd';
import { DEFAULT_LANGUAGE } from 'config';
import { get, isEqual, isObject, isString, pick, set } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdCode, MdImportantDevices, MdSend } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useLocation } from 'react-router';
import ActionsButton from '../../../../common/components/ActionsButton';
import { ClipboardPasteButton } from '../../../../common/components/ClipboardButtons';
import ClipboardCopyButton from '../../../../common/components/ClipboardButtons/ClipboardCopyButton';
import { Drawer, DrawerContent } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import LanguageSelectorInput from '../../../../common/components/LanguageSelectorInput/LanguageSelectorInput';
import { AVAILABLE_EMAIL_LANGUAGES } from '../../../../common/utils/constants';
import { Title } from '../../../../common/utils/stylingUtils';
import { fetchTenant, getTenant } from '../../../../tenant';
import StripoEditor, { STRIPO_VERSION, compileHtml, updateHtmlAndCss } from './StripoEditor';
import styles from './TemplateForm.module.scss';
import TemplatePreview from './TemplatePreview';
const MAX_SUBJECT_LENGTH = 800;
function getLang(search, defaultLang = DEFAULT_LANGUAGE) {
    const lang = get(queryString.parse(search), 'language');
    if (!lang) {
        return defaultLang;
    }
    if (Array.isArray(lang)) {
        return lang[0] ?? defaultLang;
    }
    return lang;
}
const getClipDataFromRaw = (clipText) => {
    const clipValue = JSON.parse(clipText);
    if (isObject(clipValue) &&
        'html' in clipValue &&
        isString(clipValue.html) &&
        'css' in clipValue &&
        isString(clipValue.css)) {
        return {
            html: clipValue.html || '',
            css: clipValue?.css || ''
        };
    }
    return null;
};
const TemplateForm = ({ template, isNew, hasAdminPermission, hasCrossClientPreviewPermission, deleteTemplate, sendTestEmail, emitErrorNotification, save, generateCrossClientPreview, authDataStripo, isPendingLoginStripo, isFailedLoginStripo }) => {
    const { t } = useI18n();
    const AVAILABLE_LANGUAGES = AVAILABLE_EMAIL_LANGUAGES.map(({ value }) => value);
    const location = useLocation();
    const dispatch = useDispatch();
    const [currentLang, setCurrentLang] = useState(() => getLang(location.search));
    const tenant = useSelector(getTenant);
    const templateId = useMemo(() => template.entityId || generateId(), [template.entityId]);
    const [templateDraft, setTemplateDraft] = useState({
        entityId: templateId,
        title: get(template, 'title', t('UNTITLED_TEMPLATE_NAME')),
        configuration: {
            editor: `Stripo v${STRIPO_VERSION}`,
            system: !!template.configuration?.system,
            translations: AVAILABLE_LANGUAGES.reduce((acc, lang) => {
                acc[lang] = {
                    subject: get(template.configuration?.translations, [lang, 'subject'], ''),
                    preHeader: get(template.configuration?.translations, [lang, 'preHeader'], ''),
                    defaultParams: get(template.configuration?.translations, [lang, 'defaultParams'], {}),
                    compiledHtml: get(template.configuration?.translations, [lang, 'compiledHtml'], ''),
                    css: get(template.configuration?.translations, [lang, 'css'], ''),
                    html: get(template.configuration?.translations, [lang, 'html'], ''),
                    modules: get(template.configuration?.translations, [lang, 'modules'], '')
                };
                return acc;
            }, {})
        }
    });
    const hasUnsavedChanges = useMemo(() => {
        const templateCompare = pick(template, ['title', 'configuration']);
        const templateDraftCompare = pick(templateDraft, ['title', 'configuration']);
        return !isEqual(templateCompare, templateDraftCompare);
    }, [template, templateDraft]);
    const [defaultParamsState, setDefaultParamsState] = useState({
        defaultParamsError: undefined,
        defaultParams: undefined
    });
    const [savingState, setSavingState] = useState({
        isSaving: false,
        isSavingFailed: false
    });
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [testEmailState, setTestEmailState] = useState({
        isTestEmailVisible: false,
        isTestEmailSending: false,
        testEmailError: undefined,
        testEmail: ''
    });
    const changeLang = useCallback((lang) => {
        const queryParams = queryString.parse(location.search);
        dispatch(push({
            ...location,
            search: queryString.stringify({ ...queryParams, language: lang || DEFAULT_LANGUAGE })
        }));
    }, [dispatch, location]);
    const saveTemplate = useCallback(async () => {
        if (!templateDraft.title || !templateDraft.configuration.translations[currentLang]?.subject) {
            setSavingState({
                isSaving: false,
                isSavingFailed: true
            });
            emitErrorNotification({
                title: t('COMMON__FAILURE'),
                message: t('SAVE_TEMPLATE__ERROR_MESSAGE_NAME_SUBJECT')
            });
            throw new Error(t('SAVE_TEMPLATE__ERROR_MESSAGE_NAME_SUBJECT'));
        }
        setSavingState({
            isSaving: true,
            isSavingFailed: false
        });
        try {
            const compiledHtml = await compileHtml();
            set(templateDraft.configuration.translations, [currentLang, 'compiledHtml'], compiledHtml);
            await save(templateDraft);
            setSavingState({
                isSaving: false,
                isSavingFailed: false
            });
        }
        catch (error) {
            setSavingState({
                isSaving: false,
                isSavingFailed: true
            });
        }
    }, [currentLang, emitErrorNotification, save, t, templateDraft]);
    const openSettingsDrawer = useCallback(() => {
        setIsSettingsVisible(true);
    }, []);
    const closeSettingsDrawer = useCallback(() => {
        const { preHeader } = templateDraft.configuration.translations[currentLang];
        if (!defaultParamsState.defaultParamsError) {
            setIsSettingsVisible(false);
            const preHeaderWithWhitespace = preHeader +
                '&nbsp;&zwnj;'.repeat(MAIL_MAX_PRE_HEADER_LENGTH - Math.round(preHeader.length / 2));
            window.StripoApi.setHiddenPreHeader(preHeader ? preHeaderWithWhitespace : null);
        }
    }, [
        currentLang,
        defaultParamsState.defaultParamsError,
        templateDraft.configuration.translations
    ]);
    const handleDeleteTemplate = useCallback(async () => {
        setIsDeleting(true);
        try {
            await deleteTemplate(templateId);
        }
        finally {
            setIsDeleting(false);
        }
    }, [deleteTemplate, templateId]);
    const handleGenerateCrossClientsPreview = useCallback(async () => {
        setIsPreviewLoading(true);
        const { id } = await generateCrossClientPreview(templateId, currentLang);
        dispatch(push(`${location.pathname}/${currentLang}/previews/${id}`));
        setIsPreviewLoading(false);
    }, [currentLang, dispatch, generateCrossClientPreview, location.pathname, templateId]);
    const handleHistoryCrossClientsPreview = useCallback(() => {
        dispatch(push(`${location.pathname}/${currentLang}/previews`));
    }, [currentLang, dispatch, location.pathname]);
    const closePreview = useCallback(() => {
        setIsPreviewVisible(false);
    }, []);
    const openPreview = useCallback(async () => {
        setIsPreviewLoading(true);
        try {
            const returnedCompiledHtml = await compileHtml();
            setTemplateDraft((prev) => {
                return {
                    ...prev,
                    configuration: {
                        ...prev.configuration,
                        translations: {
                            ...prev.configuration.translations,
                            [currentLang]: {
                                ...prev.configuration.translations[currentLang],
                                compiledHtml: returnedCompiledHtml
                            }
                        }
                    }
                };
            });
            setIsPreviewVisible(true);
        }
        finally {
            setIsPreviewLoading(false);
        }
    }, [currentLang]);
    const onTitleChanged = useCallback((e) => {
        const value = e.target.value;
        setTemplateDraft((prev) => {
            return {
                ...prev,
                title: value
            };
        });
    }, []);
    const onSubjectChanged = useCallback((e) => {
        const value = e.target.value;
        setTemplateDraft((prev) => {
            return {
                ...prev,
                configuration: {
                    ...prev.configuration,
                    translations: {
                        ...prev.configuration.translations,
                        [currentLang]: {
                            ...prev.configuration.translations[currentLang],
                            subject: value
                        }
                    }
                }
            };
        });
        window.StripoApi.setTitle(value);
    }, [currentLang]);
    const openTestEmail = useCallback(() => {
        setTestEmailState((prev) => {
            return {
                ...prev,
                isTestEmailVisible: true
            };
        });
    }, []);
    const closeTestEmail = useCallback(() => {
        setTestEmailState((prev) => {
            return {
                ...prev,
                isTestEmailVisible: false,
                isTestEmailSending: false
            };
        });
    }, []);
    const onTestEmailChanged = useCallback((e) => {
        const value = e.target.value;
        setTestEmailState((prev) => {
            return {
                ...prev,
                testEmail: value
            };
        });
    }, []);
    const onSendTestEmail = useCallback(() => {
        if (!testEmailState.testEmail) {
            setTestEmailState((prev) => {
                return {
                    ...prev,
                    testEmailError: t('VALIDATIONS__REQUIRED')
                };
            });
        }
        else if (validateEmail(testEmailState.testEmail)) {
            setTestEmailState((prev) => {
                return {
                    ...prev,
                    testEmailError: validateEmail(testEmailState.testEmail)
                };
            });
        }
        else {
            setTestEmailState((prev) => {
                return {
                    ...prev,
                    isTestEmailSending: true,
                    testEmailError: undefined
                };
            });
            sendTestEmail(templateId, testEmailState.testEmail, currentLang)
                .then(() => {
                setTestEmailState((prev) => {
                    return {
                        ...prev,
                        isTestEmailVisible: false
                    };
                });
            })
                .finally(() => {
                setTestEmailState((prev) => {
                    return {
                        ...prev,
                        isTestEmailSending: false
                    };
                });
            });
        }
    }, [testEmailState.testEmail, t, sendTestEmail, templateId, currentLang]);
    const onPreHeaderChanged = useCallback((e) => {
        const value = e.target.value;
        setTemplateDraft((prev) => {
            return {
                ...prev,
                configuration: {
                    ...prev.configuration,
                    translations: {
                        ...prev.configuration.translations,
                        [currentLang]: {
                            ...prev.configuration.translations[currentLang],
                            preHeader: value
                        }
                    }
                }
            };
        });
    }, [currentLang]);
    const onDefaultParamChanged = useCallback((e) => {
        const value = e.target.value;
        try {
            const parsedDefaultParams = JSON.parse(value);
            setDefaultParamsState((prev) => {
                const newState = {
                    defaultParamsError: undefined,
                    defaultParams: undefined
                };
                if (!isEqual(prev, newState)) {
                    return newState;
                }
                return prev;
            });
            setTemplateDraft((prev) => {
                return {
                    ...prev,
                    configuration: {
                        ...prev.configuration,
                        translations: {
                            ...prev.configuration.translations,
                            [currentLang]: {
                                ...prev.configuration.translations[currentLang],
                                defaultParams: parsedDefaultParams
                            }
                        }
                    }
                };
            });
        }
        catch (err) {
            setDefaultParamsState((prev) => {
                const newState = {
                    defaultParamsError: t('INVALID_JSON_FORMAT_ERROR'),
                    defaultParams: value
                };
                if (!isEqual(prev, newState)) {
                    return newState;
                }
                return prev;
            });
        }
    }, [currentLang, t]);
    const onEditorReady = useCallback(() => setIsEditorReady(true), []);
    const onStripoEditorChanged = useCallback((val) => {
        setTemplateDraft((prev) => {
            return {
                ...prev,
                configuration: {
                    ...prev.configuration,
                    translations: {
                        ...prev.configuration.translations,
                        [currentLang]: {
                            ...prev.configuration.translations[currentLang],
                            html: val?.html || '',
                            css: val?.css || '',
                            modules: val?.modules
                        }
                    }
                }
            };
        });
    }, [currentLang]);
    const { isTestEmailSending, testEmailError, testEmail } = testEmailState;
    const { title } = templateDraft;
    const { subject, compiledHtml } = templateDraft.configuration.translations[currentLang] || {};
    const isDisabled = savingState.isSaving || isDeleting || isTestEmailSending;
    const isSystemAndNonAdmin = !!template.configuration?.system && !hasAdminPermission;
    useEffect(() => {
        const lang = getLang(location.search, '');
        if (lang) {
            setCurrentLang(lang);
        }
        if (!lang && tenant?.defaultLanguage) {
            setCurrentLang(tenant.defaultLanguage);
        }
    }, [location, tenant]);
    useEffect(() => {
        dispatch(fetchTenant());
    }, [dispatch]);
    return (React.createElement(React.Fragment, null,
        isPreviewVisible && compiledHtml && (React.createElement(TemplatePreview, { compiledHtml: compiledHtml, subject: subject, closePreview: closePreview })),
        React.createElement(Title, { className: `${styles.header} row px-3 pb-3` },
            React.createElement("div", { className: "col-3" },
                React.createElement(LabelText, null, t('TEMPLATE_NAME')),
                React.createElement(Input, { value: title, type: "text", onChange: onTitleChanged, maxLength: MAIL_MAX_TITLE_LENGTH, disabled: !isEditorReady || isDisabled || isSystemAndNonAdmin }),
                savingState.isSavingFailed && !title && (React.createElement(ErrorMessage, null, t('VALIDATIONS__REQUIRED')))),
            React.createElement("div", { className: "col" },
                React.createElement(LabelText, null, t('EMAIL_SUBJECT')),
                React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement(Input, { type: "text", value: subject, onChange: onSubjectChanged, disabled: !isEditorReady || isDisabled || isSystemAndNonAdmin, maxLength: MAX_SUBJECT_LENGTH }),
                    React.createElement("div", { className: `${styles.subjectCounter} d-flex align-items-center justify-content-end ps-2` },
                        React.createElement("span", null, subject.length),
                        React.createElement("span", null, "/"),
                        React.createElement("span", null, MAX_SUBJECT_LENGTH))),
                savingState.isSavingFailed && !subject && (React.createElement(ErrorMessage, null, t('VALIDATIONS__REQUIRED')))),
            React.createElement("div", { className: "col-auto" },
                React.createElement("div", { className: "actions d-flex flex-row flex-nowrap align-self-center ps-0" },
                    React.createElement(LanguageSelectorInput, { value: currentLang, disabled: !isEditorReady || isDisabled || !window.StripoApi, allowClear: false, onChange: changeLang, languages: AVAILABLE_EMAIL_LANGUAGES }),
                    React.createElement("div", { className: "ms-2" },
                        React.createElement(Tooltip, { title: t('PREVIEW') },
                            React.createElement(ActionsButton, { className: styles.iconButton, buttonAction: {
                                    action: openPreview,
                                    canRun: true,
                                    buttonJsx: React.createElement(MdImportantDevices, null)
                                }, menuActions: [
                                    {
                                        action: handleGenerateCrossClientsPreview,
                                        canRun: hasCrossClientPreviewPermission,
                                        disabled: hasUnsavedChanges,
                                        disabledReasonLabel: t('DISABLED_REASON_UNSAVED_CHANGES'),
                                        buttonLabel: t('CROSS_CLIENT_PREVIEW')
                                    },
                                    {
                                        action: handleHistoryCrossClientsPreview,
                                        canRun: hasCrossClientPreviewPermission,
                                        buttonLabel: t('CROSS_CLIENT_PREVIEW_HISTORY')
                                    }
                                ], submitting: isPreviewLoading, disabled: !isEditorReady || isDisabled || !window.StripoApi, inversed: true }))),
                    !isNew && (React.createElement(Tooltip, { title: hasUnsavedChanges ? t('DISABLED_REASON_UNSAVED_CHANGES') : t('TEST_EMAIL') },
                        React.createElement("span", { className: "ms-2" },
                            React.createElement(Button, { className: styles.iconButton, inversed: true, disabled: !isEditorReady || isDisabled || !window.StripoApi || hasUnsavedChanges, onClick: openTestEmail },
                                React.createElement(MdSend, null))))),
                    React.createElement(Tooltip, { title: t('EDIT_CODE') },
                        React.createElement("div", { id: "codeEditor", className: "ms-2" },
                            React.createElement(Button, { className: styles.iconButton, inversed: true, disabled: !isEditorReady || isDisabled || !window.StripoApi },
                                React.createElement(MdCode, null)))),
                    React.createElement("div", { className: "ms-2" },
                        React.createElement(ClipboardCopyButton, { className: "h-100", inversed: true, disabled: !isEditorReady || isDisabled || !window.StripoApi, value: {
                                html: templateDraft.configuration?.translations[currentLang]?.html || '',
                                css: templateDraft.configuration?.translations[currentLang]?.css || ''
                            } })),
                    React.createElement("div", { className: "ms-2" },
                        React.createElement(ClipboardPasteButton, { className: "h-100", inversed: true, disabled: !isEditorReady || isDisabled || !window.StripoApi, isValid: (clipText) => !!getClipDataFromRaw(clipText), onClick: (clipText) => {
                                const newValue = getClipDataFromRaw(clipText);
                                if (newValue) {
                                    onStripoEditorChanged(newValue);
                                }
                                updateHtmlAndCss(newValue?.html || '', newValue?.css || '');
                            } })),
                    React.createElement("div", { className: "ms-2" },
                        React.createElement(Tooltip, { title: t('SETTINGS') },
                            React.createElement(Button, { className: styles.iconButton, inversed: true, onClick: openSettingsDrawer, disabled: !isEditorReady || isDisabled || !window.StripoApi },
                                React.createElement(IoSettingsOutline, null)))),
                    React.createElement("div", { className: "ms-2" },
                        React.createElement(ActionsButton, { buttonAction: {
                                action: saveTemplate,
                                canRun: isNew || hasUnsavedChanges,
                                buttonLabel: 'FORM__SAVE'
                            }, menuActions: [
                                {
                                    action: handleDeleteTemplate,
                                    canRun: !template.configuration?.system && !isNew,
                                    buttonLabel: 'DELETE_TEMPLATE',
                                    confirmationLabel: 'DELETE_TEMPLATE',
                                    delete: true
                                }
                            ], submitting: savingState.isSaving, disabled: isDisabled || !window.StripoApi || isSystemAndNonAdmin || !isEditorReady, inversed: false }))))),
        React.createElement(Drawer, { title: t('SETTINGS'), placement: "right", onClose: closeSettingsDrawer, open: isSettingsVisible, destroyOnClose: true },
            React.createElement(DrawerContent, null,
                React.createElement(DrawerContentBlock, { title: React.createElement(React.Fragment, null,
                        t('MAILINGS__PREHEADER'),
                        React.createElement(Tooltip, { title: t('MAILINGS__PREHEADER_INFO') },
                            React.createElement(IoMdInformationCircle, { className: styles.infoIcon }))) },
                    React.createElement(BasicTextArea, { maxLength: MAIL_MAX_PRE_HEADER_LENGTH, value: templateDraft.configuration.translations[currentLang]?.preHeader, onChange: onPreHeaderChanged })),
                React.createElement(DrawerContentBlock, { title: React.createElement(React.Fragment, null,
                        t('TEMPLATE_DEFAULT_PARAMS'),
                        React.createElement(Tooltip, { title: t('TEMPLATE_DEFAULT_PARAMS_INFO') },
                            React.createElement(IoMdInformationCircle, { className: styles.infoIcon }))) },
                    React.createElement(BasicTextArea, { rows: 16, value: defaultParamsState.defaultParams ||
                            JSON.stringify(templateDraft.configuration.translations[currentLang]?.defaultParams), onChange: onDefaultParamChanged, error: defaultParamsState.defaultParamsError })))),
        React.createElement(Modal, { centered: true, title: t('TEST_EMAIL'), open: testEmailState.isTestEmailVisible, onOk: onSendTestEmail, onCancel: closeTestEmail, footer: [
                React.createElement(Button, { key: "cancel", inversed: true, onClick: closeTestEmail }, t('FORM__CANCEL')),
                React.createElement(Button, { key: "send", loading: testEmailState.isTestEmailSending, onClick: onSendTestEmail }, t('SEND_TEST_EMAIL'))
            ] },
            React.createElement(BasicInput, { className: "pb-0", label: t('EMAIL_ADDRESS'), value: testEmail, type: "text", onChange: onTestEmailChanged, error: testEmailError, disabled: isDisabled })),
        React.createElement(Prompt, { when: hasUnsavedChanges && !isDisabled, message: t('UNSAVED_DATA_CONFIRM') }),
        React.createElement(StripoEditor, { key: `${templateId}-${currentLang}`, id: `${templateId}-${currentLang}`, hidden: isPreviewVisible, value: {
                html: templateDraft.configuration?.translations[currentLang]?.html,
                css: templateDraft.configuration?.translations[currentLang]?.css,
                modules: templateDraft.configuration?.translations[currentLang]?.modules
            }, onChange: onStripoEditorChanged, onReady: onEditorReady, authDataStripo: authDataStripo, isPendingLoginStripo: isPendingLoginStripo, isFailedLoginStripo: isFailedLoginStripo })));
};
export default TemplateForm;
