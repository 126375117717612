import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { AggregationType, TermsAggregationFilterOrderBy } from '../../../cms/hooks/useAggregation';
import { PropertyTypes } from '../../../common/types';
export function getDefaultAggregation() {
    return {
        id: generateId(),
        type: AggregationType.term,
        field: 'title',
        orderBy: TermsAggregationFilterOrderBy.count,
        size: 10
    };
}
export const AggregationsOptions = [
    {
        labels: {
            en: 'Terms',
            nl: 'Termen',
            de: 'Begriffe',
            fr: 'Termes',
            it: 'Termini',
            pl: 'Warunki'
        },
        value: AggregationType.term
    },
    {
        labels: {
            en: 'Date Histogram',
            nl: 'Datumhistogram',
            de: 'Datums-Histogramm',
            fr: 'Histogramme de dates',
            it: 'Istogramma di date',
            pl: 'Histogram Dat'
        },
        value: AggregationType.dateHistogram,
        propertyTypes: [PropertyTypes.date]
    }
];
export function isAvailableAggregationField(aggregationType, displayData) {
    const aggregationOption = AggregationsOptions.find((item) => item.value === aggregationType);
    if (!aggregationOption?.propertyTypes) {
        return true;
    }
    if (aggregationOption.propertyTypes.includes(displayData.type)) {
        return true;
    }
    return false;
}
export function getSubAggregation(aggregation) {
    if (aggregation && 'aggregations' in aggregation) {
        return aggregation?.aggregations?.[0];
    }
}
export function hasSubAggregation(aggregation) {
    if (aggregation && 'aggregations' in aggregation && aggregation.aggregations?.length) {
        return true;
    }
    return false;
}
