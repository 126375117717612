import { keyBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { FEATURED_ENTITIES_PATH } from '../../cms';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../crm';
export const mentionRegex = /@\[(.*?)\]\((.*?)\)\((.*?)\)/g;
export const convertMessagesToTree = (messages) => {
    const messageMap = keyBy(messages.map((m) => ({ ...m, author: null, authorId: m.author, replies: [] })), 'id');
    const messageTree = [];
    messages.forEach((m) => {
        if (m.replyToMessageId) {
            // @ts-expect-error
            messageMap[m.replyToMessageId].replies.push(messageMap[m.id]);
        }
        else {
            // @ts-expect-error
            messageTree.push(messageMap[m.id]);
        }
    });
    return messageTree;
};
export const getMessageContentJsx = (content, notLink) => {
    const config = [
        {
            regex: mentionRegex,
            fn: (key, result) => {
                if (result[3] == 'user') {
                    const id = result[2].split('__')[1];
                    return notLink ? (`@${result[1]}`) : (React.createElement(Link, { key: `${result[2]}_${result.index}`, to: [FEATURED_ENTITIES_PATH, CONTACT_CMS_DEFINITION_ID, id].join('/'), className: "d-flex align-items-center flex-nowrap mw-100" },
                        React.createElement("span", null, "@"),
                        React.createElement("span", { className: "flex-truncate" },
                            React.createElement(ContactDisplayName, { contactId: id }))));
                }
                return React.createElement(Link, { to: "#" },
                    "#",
                    result[1]);
            }
        },
        {
            regex: /\\n/g,
            fn: () => React.createElement("br", null)
        }
    ];
    // @ts-expect-error
    return processString(config)(content || '');
};
// from
// https://github.com/EfogDev/react-process-string/blob/master/index.js
export const processString = (options) => {
    let key = 0;
    function processInputWithRegex(option, input) {
        if (!option.fn || typeof option.fn !== 'function') {
            return input;
        }
        if (!option.regex || !(option.regex instanceof RegExp)) {
            return input;
        }
        if (typeof input === 'string') {
            const regex = option.regex;
            let result = null;
            const output = [];
            while ((result = regex.exec(input)) !== null) {
                // @ts-expect-error
                const index = result.index;
                const match = result[0];
                // @ts-expect-error
                output.push(input.substring(0, index));
                // @ts-expect-error
                output.push(option.fn(++key, result));
                // @ts-expect-error
                input = input.substring(index + match.length, input.length + 1);
                regex.lastIndex = 0;
            }
            // @ts-expect-error
            output.push(input);
            return output;
        }
        if (Array.isArray(input)) {
            return input.map((chunk) => processInputWithRegex(option, chunk));
        }
        return input;
    }
    // @ts-expect-error
    return function (input) {
        if (!options || !Array.isArray(options) || !options.length) {
            return input;
        }
        options.forEach((option) => (input = processInputWithRegex(option, input)));
        return input;
    };
};
