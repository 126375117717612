// organize-imports-ignore
/**
 * The order for bootstrap and _theme is important
 * they are connected from above so that _theme variables are available everywhere
 * and ant design could override bootstrap styles
 */
import '_theme.scss';
import 'bootstrap.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { configureStore, history } from './common/root/store';
import Root from './common/root/Root';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'styles.scss';
library.add(far, fas);
const { store, persistor } = configureStore();
const container = document.getElementById('react-app');
const root = ReactDOM.createRoot(container);
root.render(React.createElement(Root, { store: store, persistor: persistor, history: history }));
if (process.env.NODE_ENV === 'development') {
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (import.meta.webpackHot.accept) {
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        import.meta.webpackHot.accept('./common/root/Root', () => {
            root.render(React.createElement(Root, { store: store, persistor: persistor, history: history }));
        });
    }
}
else {
    console.log = () => {
        /* noop */
    };
}
