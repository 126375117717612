import { DEFAULT_LANGUAGE } from 'config';
import { filter, find, isArray, isEqual, without } from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { ArrowIcon, Select, SelectContainer } from './BasicSelect';
import ListItem from './ListItem';
import { InputContainer, LabelText } from './utils';
const ListItems = styled.div `
  margin-top: 1rem;
`;
class MultiSelect extends Component {
    constructor() {
        super(...arguments);
        this.addToList = (value) => {
            this.props.input.onChange([value, ...this.props.input.value]);
        };
        this.handleRemove = (value) => {
            this.props.input.onChange(without(this.props.input.value, value));
        };
        this.handleNewSelectedValueChange = (event) => {
            if (event.target instanceof HTMLSelectElement) {
                this.addToList(event.target.value);
            }
        };
    }
    componentDidMount() {
        const { input } = this.props;
        if (!isArray(input.value)) {
            input.onChange([]);
        }
    }
    shouldComponentUpdate(nextProps) {
        return (nextProps.input.value !== this.props.input.value ||
            !isEqual(nextProps.options, this.props.options) ||
            nextProps.disabled !== this.props.disabled);
    }
    render() {
        const { className, input, label, options, language, disabled, t } = this.props;
        let selectedOptions;
        let restingOptions;
        if (!isArray(input.value)) {
            selectedOptions = [];
            restingOptions = options;
        }
        else {
            selectedOptions = input.value.map((value) => find(options, { value })).filter((x) => x);
            restingOptions = filter(options, (o) => !input.value.includes(o.value));
        }
        return (React.createElement(InputContainer, { className: className },
            label && React.createElement(LabelText, null, label),
            React.createElement(SelectContainer, null,
                React.createElement(Select, { value: "", onChange: this.handleNewSelectedValueChange, disabled: restingOptions.length === 0 || disabled }, restingOptions && [
                    React.createElement("option", { key: "empty", disabled: true, value: undefined }),
                    ...restingOptions.map((o) => (React.createElement("option", { key: o.value, value: o.value }, o.labels[language] || o.labels[DEFAULT_LANGUAGE])))
                ]),
                React.createElement(ArrowIcon, null)),
            selectedOptions.length > 0 && (React.createElement(ListItems, null, selectedOptions.map((o) => (React.createElement(ListItem, { key: o.value, value: o.value, display: o.labels[language] || o.labels[DEFAULT_LANGUAGE], disabled: disabled, onRemove: this.handleRemove })))))));
    }
}
export default MultiSelect;
