import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { DEFAULT_LANGUAGE } from 'config';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Script from 'react-load-script';
import { useDisplayTenant } from '../../../../tenant/useDisplayTenant';
import styles from './StripoEditor.module.scss';
import { defaultTemplate } from './utils';
const getHtmlAndCss = () => {
    return new Promise((resolve, reject) => {
        try {
            window.StripoApi.getTemplate((html, css, width, height, modules) => {
                resolve({ html, css, modules });
            });
        }
        catch (err) {
            reject(err);
        }
    });
};
export const compileHtml = () => new Promise((resolve, reject) => {
    window.StripoApi.compileEmail((error, compiledHtml) => {
        if (error) {
            reject(error);
        }
        resolve(compiledHtml);
    }, false);
});
export const updateHtmlAndCss = (html, css) => {
    window?.StripoApi?.updateHtmlAndCss(html, css);
};
export const STRIPO_VERSION = '1.102.0';
const DEFAULT_HTML = defaultTemplate.configuration?.translations[DEFAULT_LANGUAGE].html;
const DEFAULT_CSS = defaultTemplate.configuration?.translations[DEFAULT_LANGUAGE].css;
const StripoEditor = ({ id, hidden, value, onChange, onReady, authDataStripo, isPendingLoginStripo, isFailedLoginStripo, onError }) => {
    const { t, language } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const [pendingStripoAuth, setPendingStripoAuth] = useState(true);
    const unmounted = useRef(false);
    const checkChangesInterval = useRef();
    const isLoading = useMemo(() => pendingStripoAuth || isPendingLoginStripo, [pendingStripoAuth, isPendingLoginStripo]);
    const checkUnsavedChanges = useCallback(() => {
        if (!unmounted.current && window.StripoApi && !isLoading) {
            getHtmlAndCss()
                .then(({ html, css, modules }) => {
                if (html !== value?.html || css !== value?.css || !isEqual(modules, value?.modules)) {
                    onChange?.({ html, css, modules });
                }
            })
                .catch((err) => {
                onError?.(err);
            });
        }
    }, [isLoading, value?.html, value?.css, value?.modules, onChange, onError]);
    useEffect(() => {
        unmounted.current = false;
        if (!window.Stripo) {
            window.Stripo = { skipResources: ['bootstrap.css'] };
        }
        checkChangesInterval.current = setInterval(checkUnsavedChanges, 1000);
        return () => {
            unmounted.current = true;
            if (checkChangesInterval.current) {
                clearInterval(checkChangesInterval.current);
                checkChangesInterval.current = undefined;
            }
        };
    }, [checkUnsavedChanges]);
    useEffect(() => {
        return () => {
            window.StripoApi?.stop();
        };
    }, []);
    const stripoReady = useCallback(() => {
        if (!window.Stripo?.init || !authDataStripo) {
            return;
        }
        window.Stripo.init({
            apiRequestData: {
                emailId: id,
                tenantId: authDataStripo?.apiRequestData.tenantId,
                sharedModulesFolder: authDataStripo.apiRequestData.sharedModulesFolder
            },
            codeEditorButtonId: 'codeEditor',
            css: value?.css || DEFAULT_CSS,
            editorFonts: {
                showDefaultStandardFonts: true,
                showDefaultNotStandardFonts: true,
                customFonts: displayTenant?.id
                    ? [
                        {
                            fontFamily: "'Volvo Novum', arial, sans-serif",
                            name: 'Volvo Novum',
                            url: `https://${displayTenant.id}.hyperportal.org/assets/email-template-custom-fonts/volvo-novum/volvo-novum-fonts.css`
                        }
                    ]
                    : []
            },
            getAuthToken: (callback) => {
                callback(authDataStripo.token);
                setPendingStripoAuth(false);
            },
            onTemplateLoaded: onReady,
            modules: {
                syncModulesEnabled: true
            },
            html: value?.html || DEFAULT_HTML,
            locale: ['en', 'es', 'fr', 'de', 'it', 'sl', 'ru', 'uk'].includes(language) ? language : 'en',
            previewId: 'stripoPreviewContainer',
            settingsId: 'stripoSettingsContainer'
        });
    }, [authDataStripo, displayTenant?.id, id, language, onReady, value?.css, value?.html]);
    if (isFailedLoginStripo) {
        return React.createElement(React.Fragment, null, t('FAILED_LOADING_EDITOR'));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${styles.wrapper} ${hidden ? 'invisible' : ''}`, hidden: hidden },
            isLoading && (React.createElement("div", { className: "mt-5" },
                React.createElement(LoadingRectangles, null))),
            React.createElement("div", { id: "stripoSettingsContainer" }),
            React.createElement("div", { id: "stripoPreviewContainer" })),
        authDataStripo?.token && (React.createElement(Script, { url: `https://plugins.stripo.email/static/rev/${STRIPO_VERSION}/stripo.js`, onLoad: stripoReady }))));
};
export default StripoEditor;
