import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { fetchDisplayProcess } from './actions';
export const useDisplayProcess = (processMetaId, processRunId, language) => {
    const fetchProcessDispatch = useDispatch();
    const cacheKey = useMemo(() => {
        return ['display-process', processMetaId, processRunId, language];
    }, [language, processMetaId, processRunId]);
    const { data, refetch, isLoading } = useQuery(cacheKey, async () => {
        const result = await fetchProcessDispatch(fetchDisplayProcess(processMetaId, processRunId, language));
        return result;
    });
    const _refetch = useCallback(async () => {
        await refetch();
    }, [refetch]);
    return useMemo(() => {
        return {
            process: data,
            refetch: _refetch,
            isLoading
        };
    }, [_refetch, data, isLoading]);
};
