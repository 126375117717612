import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { compact, trim } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import { SYSTEM_USER, SYSTEM_USERS } from '../../constants';
import { useContact } from '../use-contact';
const ContactDisplayName = ({ contact, contactId, link, justFirstLetter, isPending }) => {
    const { t } = useI18n();
    if (contactId && SYSTEM_USERS.includes(contactId)) {
        return (React.createElement(React.Fragment, null, t(contactId === SYSTEM_USER
            ? 'COMMON__SYSTEM'
            : `COMMON__SYSTEM_USER_${contactId.toUpperCase()}`)));
    }
    if (contactId && !contact) {
        return React.createElement(ContactById, { contactId, justFirstLetter, isPending, link });
    }
    if (!isPending && contact) {
        const displayName = trim(compact([contact.firstName, contact.lastName]).join(' ')) ||
            contact.title ||
            contact.emailWork ||
            contact.entityId;
        const DisplayName = (React.createElement(TooltipTruncate, null, justFirstLetter ? displayName.charAt(0) : displayName));
        if (link) {
            return React.createElement(Link, { to: link }, DisplayName);
        }
        return DisplayName;
    }
    if (isPending === false && !contact) {
        return React.createElement(React.Fragment, null, "-");
    }
    return React.createElement(TooltipTruncate, null, justFirstLetter ? '...' : t('COMMON_LOADING'));
};
const ContactById = ({ contactId, ...props }) => {
    const contact = useContact(contactId);
    return React.createElement(ContactDisplayName, { contact: contact?.contact, ...props });
};
export default ContactDisplayName;
