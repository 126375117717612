import { get } from 'lodash';
import { getMetaState } from '../common/reducers/selectors';
// ////////////////////
// ProcessMeta list
// ////////////////////
export const getProcessesMetaTotalCount = (s) => get(getMetaState(s), 'processes.list.totalCount');
export const getProcessesMetaIds = (s) => get(getMetaState(s), ['processes', 'list', 'ids'], []);
export const getProcessesMetaList = (s) => getProcessesMetaIds(s).map((id) => get(getMetaState(s), ['processes', 'byId', id]));
export const isFetchProcessesMetaPending = (s) => get(getMetaState(s), 'processes.list.pending');
export const hasFetchProcessesMetaFailed = (s) => get(getMetaState(s), 'processes.list.failed');
// ////////////////////
// ProcessMeta single
// ////////////////////
export const getProcessMeta = (s, id) => get(getMetaState(s), ['processes', 'byId', id]);
export const isProcessMetaAvailable = (s, id) => {
    const status = getMetaState(s).processes.status[id];
    return status != null && (status.lastFetch != null || status.pending);
};
export const hasFetchProcessMetaFailed = (s, id) => get(getMetaState(s), ['processes', 'status', id, 'failed']);
// ////////////////////
// MetaDefinition
// ////////////////////
export const getMetaDefinition = (s, definitionId) => get(getMetaState(s), ['definitions', 'byId', definitionId]);
export const isMetaDefinitionAvailable = (s, definitionId) => {
    const status = getMetaState(s).definitions.status[definitionId];
    return status != null && (status.lastFetch != null || status.pending);
};
export const isFetchMetaDefinitionPending = (s, definitionId) => {
    const status = getMetaState(s).definitions.status[definitionId];
    return status == null || status.pending;
};
export const hasFetchMetaDefinitionFailed = (s, definitionId) => get(getMetaState(s), ['definitions', 'status', definitionId, 'failed']);
export const getMetaDefinitionStatus = (s, definitionId) => ({
    isPending: isFetchMetaDefinitionPending(s, definitionId),
    isAvailable: isMetaDefinitionAvailable(s, definitionId),
    isFailed: hasFetchMetaDefinitionFailed(s, definitionId)
});
