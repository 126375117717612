import { DownOutlined } from '@ant-design/icons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Dropdown } from 'antd';
import { cloneDeep, isArray, isEmpty, isObject, omit, set } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import ParameterList from './ParameterList';
function getOptionProperties(optionName, parameter) {
    if (!parameter.options) {
        return;
    }
    for (const option of parameter.options) {
        if (option.name === optionName) {
            return option;
        }
    }
    return;
}
function makeNewDefaultValue(option) {
    const newParameterValue = {};
    for (const optionParameter of option.values) {
        if (optionParameter.type === 'fixedCollection' &&
            optionParameter.typeOptions !== undefined &&
            optionParameter.typeOptions.multipleValues === true) {
            newParameterValue[optionParameter.name] = {};
        }
        else if (optionParameter.typeOptions !== undefined &&
            optionParameter.typeOptions.multipleValues === true) {
            // Multiple values are allowed so append option to array
            newParameterValue[optionParameter.name] = [];
            if (Array.isArray(optionParameter.default)) {
                newParameterValue[optionParameter.name].push(...JSON.parse(JSON.stringify(optionParameter.default)));
            }
            else if (optionParameter.default !== '' && typeof optionParameter.default !== 'object') {
                newParameterValue[optionParameter.name].push(JSON.parse(JSON.stringify(optionParameter.default)));
            }
        }
        else {
            // Add a new option
            newParameterValue[optionParameter.name] = JSON.parse(JSON.stringify(optionParameter.default));
        }
    }
    return newParameterValue;
}
const FixedCollectionField = ({ className, disabled, value, path, onChange, parameter, node, propertiesTree }) => {
    const { t } = useI18n();
    const isMultipleValues = parameter.typeOptions && parameter.typeOptions.multipleValues;
    const optionsParameter = useMemo(() => {
        return (isObject(value) && !isArray(value) ? value : {});
    }, [value]);
    const availableOptions = useMemo(() => {
        return (parameter.options || []);
    }, [parameter.options]);
    const showAddButton = isMultipleValues || isEmpty(optionsParameter);
    const addOption = useCallback((option) => {
        const newParameterValue = makeNewDefaultValue(option);
        let newValue = {};
        if (isMultipleValues === true) {
            const oldValue = (optionsParameter[option.name] || []);
            newValue = {
                ...optionsParameter,
                [option.name]: [...oldValue, newParameterValue]
            };
        }
        else {
            newValue = {
                ...optionsParameter,
                [option.name]: newParameterValue
            };
        }
        onChange && onChange(newValue);
    }, [optionsParameter, onChange, isMultipleValues]);
    const onRemove = useCallback((optionParameterName, index) => {
        let newValue = {};
        if (isMultipleValues === true && index !== undefined) {
            const oldValue = [...optionsParameter[optionParameterName]];
            oldValue.splice(index, 1);
            newValue = {
                ...optionsParameter,
                [optionParameterName]: oldValue
            };
        }
        else {
            newValue = omit(optionsParameter, [optionParameterName]);
            onChange && onChange(newValue);
        }
        onChange && onChange(newValue);
    }, [isMultipleValues, optionsParameter, onChange]);
    const menu = useMemo(() => {
        return {
            items: availableOptions.map((option, index) => ({
                key: index,
                onClick: () => addOption(option),
                label: React.createElement("span", null, option.displayName)
            }))
        };
    }, [availableOptions, addOption]);
    return (React.createElement("div", { className: `${className || ''}` },
        Object.entries(optionsParameter).map(([optionParameterName]) => {
            const option = getOptionProperties(optionParameterName, parameter);
            if (!option) {
                return null;
            }
            if (isMultipleValues) {
                const valuesOfParameter = value[optionParameterName];
                return valuesOfParameter.map((parameterValue, index) => {
                    return (React.createElement("div", { key: index, className: "row array-item pt-2" },
                        onRemove && (React.createElement("div", { className: "col-1" },
                            React.createElement(FaRegTrashAlt, { onClick: () => onRemove(optionParameterName, index), className: "remove-icon" }))),
                        React.createElement("div", { className: "col" },
                            React.createElement(ParameterList, { path: `${path}.${optionParameterName}.[${index}]`, parameters: option.values, disabled: disabled, node: node, onChange: (parameter, newValue) => {
                                    onChange &&
                                        onChange(set(cloneDeep(value), `${optionParameterName}.[${index}].${parameter.name}`, newValue));
                                }, propertiesTree: propertiesTree }))));
                });
            }
            else {
                return (React.createElement("div", { className: "row" },
                    onRemove && (React.createElement("div", { className: "col-1" },
                        React.createElement(FaRegTrashAlt, { onClick: () => onRemove(optionParameterName), className: "remove-icon" }))),
                    React.createElement("div", { className: "col" },
                        React.createElement(ParameterList, { path: `${path}.${optionParameterName}`, parameters: option.values, disabled: disabled, node: node, onChange: (parameter, newValue) => {
                                onChange &&
                                    onChange(set(cloneDeep(value), `${optionParameterName}.${parameter.name}`, newValue));
                            } }))));
            }
        }),
        showAddButton ? (availableOptions.length > 1 ? (React.createElement(Dropdown, { className: "mb-3", arrow: false, menu: menu, trigger: ['click'] },
            React.createElement(Button, null,
                React.createElement("span", null, `+ ${t('ADD')} ${parameter.displayName}`),
                React.createElement(DownOutlined, null)))) : (React.createElement(Button, { className: "mb-3", onClick: () => {
                addOption(parameter.options?.[0]);
            } },
            React.createElement("span", null, `+ ${t('ADD')} ${parameter.displayName}`)))) : null));
};
export default FixedCollectionField;
