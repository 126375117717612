export const getUsageStatistic = (type, from, to, queryId) => {
    const path = ['/v2-statistics/usage', type];
    const searchParams = new URLSearchParams();
    if (queryId) {
        searchParams.set('queryId', queryId);
    }
    if (from !== undefined) {
        searchParams.set('from', from.toString());
    }
    if (to !== undefined) {
        searchParams.set('to', to.toString());
    }
    let strSearchParams = searchParams.toString();
    if (strSearchParams) {
        strSearchParams = `?${strSearchParams}`;
    }
    return {
        type: 'STATISTIC__WITH_TYPE',
        meta: {
            http: {
                path: `${path.join('/')}${strSearchParams}`,
                method: 'GET',
                allowPromiseReject: true
            }
        }
    };
};
