export function isConfiguredRowBasedPermission(groupId, allowedRowPermissions, rowPermissions) {
    if (!allowedRowPermissions?.length || !rowPermissions) {
        return false;
    }
    for (const permissionType of allowedRowPermissions) {
        if (permissionType in rowPermissions &&
            rowPermissions[permissionType]?.[groupId]?.restrictions?.filters.length) {
            return true;
        }
    }
    return false;
}
