import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchTemplate } from '../templates/actions';
export const DOCUMENT_TEMPLATE_QUERY_KEY = 'document-template';
export const invalidateDocumentTemplateQuery = async (templateId) => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(DOCUMENT_TEMPLATE_QUERY_KEY) && query.queryKey.includes(templateId)
    });
};
export const useDocumentTemplate = ({ templateId }) => {
    const dispatchTemplate = useDispatch();
    return useQuery([DOCUMENT_TEMPLATE_QUERY_KEY, templateId], () => {
        if (!templateId) {
            return Promise.reject(new Error('Template ID is required'));
        }
        return dispatchTemplate(fetchTemplate(templateId));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        enabled: !!templateId
    });
};
