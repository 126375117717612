import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Button, Collapse, Tooltip } from 'antd';
import { FieldArray } from 'formik';
import { isEmpty, isNumber } from 'lodash';
import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaTrash } from 'react-icons/fa';
import { IoEye, IoEyeOff, IoReorderThree } from 'react-icons/io5';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import { MultiLanguageInput } from '../../../../../../common/components/MultiLanguageField';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import FormikField from '../../../../../../common/components/formik/FormikField';
export const SectionStepDetailsSettingsForm = ({ basicPath, step, setFieldValue, disabled, sectionConfig: SectionConfig, maxSectionCount, openedCollapses, onCollapseChange }) => {
    const { t, language } = useI18n();
    const onDragEnd = useCallback((result) => {
        if (!result.destination || !step?.sections) {
            return;
        }
        const newValue = [...step.sections];
        const [removed] = newValue.splice(result.source.index, 1);
        newValue.splice(result.destination.index, 0, removed);
        setFieldValue(`${basicPath}.sections`, newValue);
    }, [step.sections, setFieldValue, basicPath]);
    if (isNumber(maxSectionCount) && maxSectionCount === 0) {
        return React.createElement(React.Fragment, null, "Invalid count parameter");
    }
    return (React.createElement("div", { className: "mt-3" },
        React.createElement(FieldArray, { name: `${basicPath}.sections`, render: (sectionArrayHelpers) => (React.createElement("div", null,
                isEmpty(step?.sections) && (React.createElement(FieldLabel, { className: "text-warning", label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_SECTIONS__EMPTY') })),
                React.createElement(DragDropContext, { onDragEnd: onDragEnd },
                    React.createElement(Droppable, { droppableId: "sections", type: "SECTIONS" }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: `sections-list droppable-item ${droppableSnapshot.isDraggingOver ? 'dragging-over' : ''}`, ref: droppableProvided.innerRef },
                        step?.sections?.map((section, sectionIndex) => {
                            const stepPatch = `section.[${sectionIndex}]`;
                            const currentOpenedStep = openedCollapses.get(step.id);
                            return (React.createElement(Draggable, { draggableId: stepPatch, index: sectionIndex, key: stepPatch }, (draggableProvided, draggableSnapshot) => (React.createElement("div", { ref: draggableProvided.innerRef, className: `meta-section-item drag-item ms-3 ${draggableSnapshot.isDragging ? 'dragging-item' : ''}`, ...draggableProvided.draggableProps },
                                React.createElement(Collapse, { bordered: false, className: "collapse-outer", activeKey: currentOpenedStep?.has(section.id) ? section.id : undefined, onChange: () => onCollapseChange({
                                        stepId: step.id,
                                        sectionId: section.id
                                    }), items: [
                                        {
                                            label: (React.createElement("div", { className: "d-flex align-items-center justify-content-between" },
                                                React.createElement(TooltipTruncate, null, section.titles?.[language]),
                                                React.createElement("div", { className: "d-flex align-items-center" },
                                                    React.createElement(Tooltip, { placement: "topRight", title: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_SECTION__HIDDEN_TOOLTIP_TEXT') },
                                                        React.createElement(Button, { type: "text", icon: section.hidden ? (React.createElement(IoEyeOff, { size: 24 })) : (React.createElement(IoEye, { size: 24 })), onClick: (event) => {
                                                                event.stopPropagation();
                                                                setFieldValue(`${basicPath}.sections[${sectionIndex}].hidden`, !section.hidden);
                                                            } })),
                                                    React.createElement(Button, { disabled: disabled, danger: true, className: "ms-2 bg-transparent", onClick: (event) => {
                                                            event.stopPropagation();
                                                            sectionArrayHelpers.remove(sectionIndex);
                                                        }, icon: React.createElement(FaTrash, null) }),
                                                    React.createElement(Button, { className: `ms-2 bg-transparent`, onClick: (event) => {
                                                            event.stopPropagation();
                                                        }, icon: React.createElement(IoReorderThree, { size: "1.25rem" }), ...draggableProvided.dragHandleProps })))),
                                            key: section.id,
                                            children: (React.createElement("div", { className: "row gy-3" },
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG__SECTION_ID') }),
                                                    React.createElement(AntInput, { value: section.id, disabled: true })),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_SECTION__TITLES') }),
                                                    React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].titles`, component: MultiLanguageInput, disabled: disabled, fieldComponent: AntInput })),
                                                React.createElement("div", { className: "col-12 mt-0" },
                                                    React.createElement(SectionConfig, { section: section, sectionIndex: sectionIndex }))))
                                        }
                                    ] })))));
                        }),
                        React.createElement(React.Fragment, null, droppableProvided.placeholder))))),
                (!isNumber(maxSectionCount) ||
                    !step?.sections ||
                    step.sections.length < maxSectionCount) && (React.createElement(Button, { className: "mt-3", onClick: () => sectionArrayHelpers.push({
                        id: generateId(),
                        titles: {
                            en: 'New Section',
                            pl: 'Nowy Dział',
                            fr: 'Nouvelle Section',
                            nl: 'Nieuwe Sectie',
                            de: 'Neue Sektion',
                            it: 'Nuova Sezione'
                        }
                    }) },
                    React.createElement("span", null, t('ADD')))))) })));
};
