import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { deletePaymentMethod } from '../actions';
import { invalidateSearchPaymentCardsQuery } from './useSearchPaymentCards';
export const useDeletePaymentMethod = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: async (paymentMethodId) => {
            return dispatch(deletePaymentMethod(paymentMethodId));
        },
        onSettled: async () => {
            await invalidateSearchPaymentCardsQuery();
        }
    });
};
