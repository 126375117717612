import { Tooltip } from 'antd';
import cn from 'classnames';
import { debounce, isString } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InlineImageGallery from '../../../../../../common/components/InlineImageGallery/InlineImageGallery';
import { PreviewTypesT } from '../../../../../../common/components/InlineImageGallery/types';
import SortableFileWrapper, { SortableFile, SortableFileListContainer } from '../../../../../../common/components/SortableFileWrapper';
import { SortableHandleSm } from '../../../../../../common/components/SortableFileWrapper/SortableFileWrapper';
import { getParamsFromEvent, resetSortStyles } from '../../../../../../common/components/SortableFileWrapper/utils';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { fetchFilesData, getFileData, isFileDataAvailable } from '../../../../../../common/storage';
import { DisplaySizesT } from '../types';
import styles from './FileDisplay.module.scss';
const acceptImage = 'image/*';
const filesToFetch = new Set();
const fetchFromQueue = (fetchFilesData) => {
    fetchFilesData([...filesToFetch]);
    filesToFetch.clear();
};
const debouncedFetchFilesData = debounce(fetchFromQueue);
const addToQueueAndFetch = (fileId, fetchFilesData) => {
    filesToFetch.add(fileId);
    debouncedFetchFilesData(fetchFilesData);
};
const DISPLAY_SIZE_TO_GALLERY_TYPES = {
    [DisplaySizesT.SMALL]: PreviewTypesT.AVATAR,
    [DisplaySizesT.MEDIUM]: PreviewTypesT.BIG_AVATAR,
    [DisplaySizesT.LARGE]: PreviewTypesT.CAROUSEL
};
const FileDisplay = ({ value, meta, displaySize, className, propertyId, definitionId, entityId, onFilesMoved }) => {
    const sortableListNode = useRef(null);
    const isImage = meta?.accept == acceptImage;
    const dispatch = useDispatch();
    const getFileDataSelector = useSelector((s) => (fileId) => getFileData(s, fileId));
    const isFileDataAvailableSelector = useSelector((s) => (fileId) => isFileDataAvailable(s, fileId));
    const filesWithData = useMemo(() => {
        const fileIds = Array.isArray(value) ? value : [value];
        return fileIds?.reduce((acc, fileId) => {
            const data = getFileDataSelector(fileId);
            return data ? [...acc, { ...data }] : acc;
        }, []);
    }, [getFileDataSelector, value]);
    useEffect(() => {
        if (value) {
            const fileIds = Array.isArray(value) ? value : [value];
            fileIds
                .filter((fileId) => !isFileDataAvailableSelector(fileId))
                .forEach((fileId) => addToQueueAndFetch(fileId, (ids) => dispatch(fetchFilesData(ids))));
        }
    }, [dispatch, isFileDataAvailableSelector, value]);
    const onSortEnd = useCallback(({ oldIndex, newIndex, isCarousel }, event) => {
        if (onFilesMoved && isString(propertyId)) {
            const { destinationDefinitionId, destinationEntityId, destinationPropertyId } = getParamsFromEvent(event);
            if (destinationPropertyId) {
                onFilesMoved({
                    oldIndex,
                    newIndex,
                    definitionIdFromList: definitionId,
                    entityIdFromList: entityId,
                    propertyIdFromList: propertyId,
                    definitionIdToList: destinationDefinitionId,
                    entityIdToList: destinationEntityId,
                    propertyIdToList: destinationPropertyId,
                    isCarousel
                });
            }
            resetSortStyles(sortableListNode);
        }
        else {
            console.error('destinationPropertyId not found');
        }
    }, [definitionId, entityId, onFilesMoved, propertyId]);
    const fileIds = useMemo(() => {
        if (!value) {
            return;
        }
        return Array.isArray(value) ? value : [value];
    }, [value]);
    if (fileIds?.length && !filesWithData?.length) {
        return React.createElement(React.Fragment, null, '...');
    }
    if (meta?.carousel && fileIds?.length) {
        return (React.createElement(InlineImageGallery, { images: filesWithData, previewType: DISPLAY_SIZE_TO_GALLERY_TYPES[displaySize], imageCover: meta?.imageCover, propertyId: propertyId, definitionId: definitionId, entityId: entityId, onSortEnd: onSortEnd, sortableRef: sortableListNode }));
    }
    return (React.createElement(SortableFileWrapper, { className: `${styles.fileWrapper} FileWrapper ${className ?? ''}`, definitionId: definitionId, entityId: entityId, propertyId: propertyId }, fileIds?.length && filesWithData?.length ? (React.createElement(SortableFileListContainer, { useDragHandle: true, ref: sortableListNode, onSortEnd: onSortEnd },
        React.createElement("div", null, filesWithData?.map((fileData, index) => (React.createElement(SortableFile, { disabled: !propertyId, key: fileData.id, index: index, className: cn('sortable', { image: isImage }) },
            React.createElement(Tooltip, { key: fileData.id, title: fileData.name },
                React.createElement("div", { className: "d-flex alight-items-center" },
                    React.createElement(SortableHandleSm, null),
                    React.createElement("a", { href: fileData.downloadUrl, target: "_blank", className: "d-block overflow-auto", rel: "noopener noreferrer", draggable: "false", onClick: (e) => e.stopPropagation() },
                        React.createElement(TooltipTruncate, null, fileData.name)))))))))) : (React.createElement("div", { className: `${styles.fileWrapper} ${className ?? ''}` }, '\u00a0'))));
};
export default FileDisplay;
