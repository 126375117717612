import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { useStore } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import LanguageSelectorInput from '../../../../../../common/components/LanguageSelectorInput/LanguageSelectorInput';
import { AVAILABLE_EMAIL_LANGUAGES } from '../../../../../../common/utils/constants';
import FormsSelectorField from '../../../../../../forms/components/common/FormsSelector';
import { getForm } from '../../../../../../forms/selectors';
import FormPropertySection from './FormPropertySection';
import styles from './FormsArrayField.module.scss';
const FormsArrayField = ({ fields, disabled, changeFieldValue, metaDefinitionId }) => {
    const { t } = useI18n();
    const store = useStore();
    return (React.createElement("div", { className: styles.wrapper },
        fields.map((member, i) => {
            return (React.createElement("div", { key: member, className: "mt-3" },
                React.createElement(FormSection, { name: member },
                    React.createElement(FieldLabel, { label: t('FORM'), className: "ps-0" }),
                    React.createElement("div", { className: "d-flex flex-row" },
                        React.createElement("div", { className: "col-7 me-2" },
                            React.createElement(Field, { name: "formId", component: FormsSelectorField, disabled: disabled, validate: required, autoFocus: false, onChange: (e, newValue, previousValue, name) => {
                                    if (newValue != previousValue && name) {
                                        const form = newValue ? getForm(store.getState(), newValue) : null;
                                        const defaultValues = {};
                                        if (form) {
                                            (form.form.schema.required || []).forEach((field) => (defaultValues[field] = ''));
                                            Object.values(form.form.schema.properties).forEach((field) => {
                                                (field.required || []).forEach((field) => (defaultValues[field] = ''));
                                            });
                                        }
                                        changeFieldValue(name.replace('formId', 'propertyMappings'), defaultValues);
                                        changeFieldValue('formId', newValue);
                                    }
                                } })),
                        React.createElement("div", { className: "col-4 ps-0" },
                            React.createElement(Field, { name: "formLanguage", component: ComputedInputField, popupMatchSelectWidth: false, computedComponent: LanguageSelectorInput, disabled: disabled, languages: AVAILABLE_EMAIL_LANGUAGES })),
                        React.createElement("div", { className: "col-auto ps-0 d-flex align-items-center" },
                            React.createElement(IoMdClose, { className: "remove-btn", onClick: () => fields.remove(i) })))),
                React.createElement(FormSection, { name: member },
                    React.createElement(Field, { name: "formId", component: FormPropertySection, metaDefinitionId: metaDefinitionId }))));
        }),
        React.createElement("a", { href: "#", className: "d-block mt-3", onClick: (e) => {
                e.preventDefault();
                fields.push({});
            } }, `${t('PROCESS_META__ADD_TASK')}  ${t('FORM')}`)));
};
export default FormsArrayField;
