import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useEntityDisplayData } from '../../../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../../../cms/common/utils/utils';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import PropertyFieldInlineEditor from '../../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import { SelectPropertyButton } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { isEntityListProperty } from '../../../../../../cms/data/components/item-property/SelectProperty/utils';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { PropertyTypes } from '../../../../../../common/types';
import { INSPECTION_DEFINITION_ID } from '../../constants';
import { InspectionSteps } from '../../types';
import { BaseSectionSchema, BaseStepSchema } from '../utils/schema';
import { SectionStepDetailsSettingsForm } from './SectionStepDetailsSettingsForm';
const ListStepSectionSchema = Yup.object().shape({
    ...BaseSectionSchema.fields,
    items: Yup.array().of(Yup.string()),
    propertyId: Yup.string().required()
    // view: getViewSchema()
});
export const ListStepTypeSchema = Yup.object().shape({
    ...BaseStepSchema.fields,
    sections: Yup.array().of(ListStepSectionSchema).required(),
    type: Yup.string().required().oneOf([InspectionSteps.list])
});
const SectionConfig = ({ section, sectionIndex, basicPath, disabled }) => {
    const { t } = useI18n();
    const { displayData } = useEntityDisplayData(INSPECTION_DEFINITION_ID);
    const inspectionProperty = useMemo(() => {
        if (!section.propertyId) {
            return;
        }
        return getPropertyById(displayData, section.propertyId);
    }, [displayData, section.propertyId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_LIST__PROPERTY') }),
        React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].propertyId`, component: SelectPropertyButton, definitionId: INSPECTION_DEFINITION_ID, includeProperty: isEntityListProperty, includeNestedProperties: false, disabled: disabled, error: section.propertyId
                ? undefined
                : t('APPS_HYPER_INSPECTION_STEPS_CONFIG_LIST__PROPERTY_ERROR') }),
        React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_LIST__AVAILABLE_ITEMS') }),
        React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].items`, component: PropertyFieldInlineEditor, type: PropertyTypes.entity, definitionId: inspectionProperty?.meta.definitionId, meta: { definitionId: inspectionProperty?.meta.definitionId, list: true }, canEdit: !disabled || !inspectionProperty?.meta })));
};
const ListStepDetailsSettingsForm = (props) => {
    const { basicPath, disabled } = props;
    const sectionConfig = useCallback(({ section, sectionIndex }) => (React.createElement(SectionConfig, { section: section, sectionIndex: sectionIndex, disabled: disabled, basicPath: basicPath })), [basicPath, disabled]);
    return (React.createElement("div", { className: "mt-3" },
        React.createElement(SectionStepDetailsSettingsForm, { ...props, sectionConfig: sectionConfig })));
};
export default ListStepDetailsSettingsForm;
