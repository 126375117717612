import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HeightContextProvider } from '../../../common/components/height-context';
import { BROWSE_PATH, VIEW_PATH } from '../../../common/utils/constants';
import { getEntityBrowseUrl } from '../../../common/utils/url';
import { GROUPS_PATH, GROUP_CMS_DEFINITION_ID } from '../../../groups';
import { CMS_PATH } from '../../common/utils/constants';
import EditableItemPageContainer from './item/EditableItemPageContainer';
import ItemsContainer from './items/ItemsContainer';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const Routes = ({ contentHeight, definitionId, canCreateItems, canDeleteItems, entityTitle, match: { url }, isFailed, isLoading, setLinks, setCurrentBreadcrumbText, baseUrl, setExtraTextForBreadcrumbsComponent }) => {
    if (isFailed) {
        return React.createElement(NotFound, null);
    }
    if (isLoading) {
        return null;
    }
    return (React.createElement(Switch, null,
        React.createElement(Redirect, { exact: true, from: getEntityBrowseUrl(GROUP_CMS_DEFINITION_ID), to: GROUPS_PATH }),
        React.createElement(Redirect, { exact: true, from: `${getEntityBrowseUrl(GROUP_CMS_DEFINITION_ID)}/:entityId`, to: `${GROUPS_PATH}/:entityId` }),
        React.createElement(Redirect, { from: `${CMS_PATH}/${GROUP_CMS_DEFINITION_ID}`, to: GROUPS_PATH }),
        React.createElement(Route, { exact: true, path: [`${url}${BROWSE_PATH}/:entityId`, `${url}${VIEW_PATH}/:viewId/:entityId`], render: (props) => (React.createElement(EditableItemPageContainer, { key: [definitionId, props.match.params.entityId].join('-'), definitionId: definitionId, viewId: props.match.params.viewId, entityId: props.match.params.entityId, entityTitle: entityTitle, contentHeight: contentHeight, setCurrentBreadcrumbText: setCurrentBreadcrumbText, setLinks: setLinks, baseUrl: baseUrl })) }),
        React.createElement(Route, { path: [`${url}${BROWSE_PATH}`, `${url}${VIEW_PATH}/:viewId`], render: (props) => (React.createElement(HeightContextProvider, { key: url, otherHeightInPx: 0, otherElementSelectors: otherElementSelectors },
                React.createElement(ItemsContainer, { key: url, definitionId: definitionId, canCreate: canCreateItems, canDelete: canDeleteItems, entityTitle: entityTitle, baseUrl: url, setCurrentBreadcrumbText: setCurrentBreadcrumbText, setLinks: setLinks, contentHeight: contentHeight, setExtraTextForBreadcrumbsComponent: setExtraTextForBreadcrumbsComponent, ...props }))) }),
        React.createElement(Redirect, { from: `${url}/:entityId`, to: `${url}${BROWSE_PATH}/:entityId` }),
        React.createElement(Redirect, { to: `${url}${BROWSE_PATH}` })));
};
export default Routes;
