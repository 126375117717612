export const SESSION_SYNC_SERVICE = 'session-sync';
export const addSubscription = (subscription) => ({
    type: 'SESSION__ADD_SUBSCRIPTION',
    payload: {
        subscription
    }
});
export const removeSubscription = (subscription) => ({
    type: 'SESSION__REMOVE_SUBSCRIPTION',
    payload: {
        subscription
    }
});
