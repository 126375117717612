import { useEffect } from 'react';
const useExternalScripts = () => {
    useEffect(() => {
        window.help_company_key = '04edf5295d9d7ce77863d27f';
        window.helppier_app_id = 'JuNSqZvWx9pvujspv';
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('id', 'helppierEmbed');
        scriptTag.setAttribute('defer', '');
        scriptTag.setAttribute('src', `https://km.helppier.com/widget/js/start.js?help_company_key=${window.help_company_key}`);
        document.head.appendChild(scriptTag);
        return () => {
            document.head.removeChild(scriptTag);
        };
    }, []);
};
export default useExternalScripts;
