import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { FileField } from '../../../../../common/storage';
import { getPropertyTypeComponent } from '../../../../../workflows/meta/components/meta-definition-form/utils';
import { FieldLabel } from '../FieldElements';
import { getEditorComponentForType } from '../utils';
import styles from './PropertyFieldEditor.module.scss';
const PropertyFieldEditor = ({ property, label, value, className, error, disabled, showIcon = true, allowClear = true, onChange }) => {
    const { language } = useI18n();
    const Icon = getPropertyTypeComponent(property.type);
    const Editor = getEditorComponentForType(property.type);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''} ${disabled ? 'disabled' : ''} ${error ? styles.error : ''}` },
        React.createElement("div", { className: "editor" },
            React.createElement(FieldLabel, { icon: showIcon && Icon ? React.createElement(Icon, null) : null, label: label || getTranslation(property.labels, language) }),
            property.type === 'file' ? (React.createElement(FileField, { canEdit: !disabled, error: false, success: false, showIcon: false, label: false, meta: property.meta, files: value || [], loading: false, allowRemove: true, onChange: onChange })) : (React.createElement(React.Fragment, null,
                React.createElement(Editor, { meta: property.meta, disabled: disabled, allowClear: allowClear, onChange: onChange, value: value, autoFocus: false, inlineMode: true, ...(property.type === 'entity' ? { ignoreOnEnterKeyDown: true } : {}) }),
                error && React.createElement(ErrorMessage, null, error))))));
};
export default PropertyFieldEditor;
