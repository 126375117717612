import ReactGA from 'react-ga';
export default (config) => {
    ReactGA.initialize(config.id, {
        titleCase: false,
        debug: false,
        gaOptions: {
            cookieDomain: 'auto'
        }
    });
    return {
        pageView: ReactGA.pageview,
        event: ReactGA.event
    };
};
