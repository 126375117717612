import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import React, { useMemo } from 'react';
import { useViews } from '../..';
import { Select } from '../../../common/components/Select';
const ViewSelector = ({ className, label, disabled, value, onChange, definitionId, availableOn }) => {
    const { views } = useViews();
    const filteredViews = useMemo(() => {
        if (definitionId) {
            return views.filter((item) => {
                if (item.configuration?.availableOn?.length && !availableOn) {
                    return;
                }
                if (availableOn && !item.configuration?.availableOn?.includes(availableOn)) {
                    return;
                }
                return item.referenceDefinitionId === definitionId;
            });
        }
        return views;
    }, [availableOn, definitionId, views]);
    return (React.createElement("div", { className: `view-selector w-100 ${className || ''}` },
        label && React.createElement(LabelText, null, label),
        React.createElement(Select, { className: "w-100", value: value, onChange: onChange, popupMatchSelectWidth: false, disabled: disabled, defaultActiveFirstOption: true, allowClear: true, options: filteredViews.map((view) => ({ value: view.entityId, label: view.title })) })));
};
export default ViewSelector;
