import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
const arrowAnimation = keyframes ` 
  0%,
  33% {
    transform: translate(0%, -50%);
  }

  33%,
  66% {
    transform: translate(25%, -50%);
  }

  66%,
  100% {
    transform: translate(0%, -50%);
  }
`;
const StyledRightArrow = styled(IoIosArrowForward) `
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: #d9e3ed;
  font-size: 2rem;
  transition: color 500ms ease;
`;
const Container = styled.div `
  position: relative;
  cursor: pointer;

  &:hover ${StyledRightArrow} {
    color: ${({ theme }) => theme.mainColor};
    animation: ${arrowAnimation} 1s ease-out infinite;
  }
`;
const DisabledContainer = styled.div `
  position: relative;
`;
const StyledAnchor = Container.withComponent('a');
const StyledLink = Container.withComponent(Link);
const getComponent = (props) => {
    if (props.disabled) {
        return DisabledContainer;
    }
    else if (props.href) {
        return StyledAnchor;
    }
    else if (props.to) {
        return StyledLink;
    }
    return Container;
};
const ArrowSection = ({ className, children, ...otherProps }) => {
    const Component = getComponent(otherProps);
    const { disabled } = otherProps;
    return (React.createElement(Component, { className: className, ...otherProps },
        !disabled && React.createElement(StyledRightArrow, null),
        children));
};
export default ArrowSection;
