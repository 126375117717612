import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider, Modal, Radio } from 'antd';
import React, { Fragment, useMemo, useState } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { buildNode, isDecisionNode, isOwnNodeDescription, isStepNode, isTriggerNode } from '../nodes';
import styles from './AddNodeDialog.module.scss';
const AddNodeDialog = ({ open, mode, selectedNode, canAddStepNode, nodes: nodesDescription, onCancel, onSelected }) => {
    let selectedNodeDescription;
    if (selectedNode.target) {
        selectedNodeDescription = nodesDescription[selectedNode.target];
    }
    const { t } = useI18n();
    const [decisionBranch, setDecisionBranch] = useState(selectedNodeDescription ? 0 : 'TRUE');
    const nodesBySections = useMemo(() => {
        const nodes = {
            PROCESS_META__MANUAL_TASKS: [],
            PROCESS_META__AUTOMATED_TASKS: [],
            PROCESS_META__CONTROL_NODES: []
        };
        Object.values(nodesDescription).reduce((acc, node) => {
            const isTrigger = isTriggerNode(node);
            if (!isTrigger && mode == 'replace') {
                return acc;
            }
            else if (isTrigger && mode !== 'replace') {
                return acc;
            }
            else if (isStepNode(node) && !canAddStepNode) {
                return acc;
            }
            if (isOwnNodeDescription(node)) {
                for (const section of node.sections) {
                    if (!acc[section]) {
                        acc[section] = [];
                    }
                    acc[section].push(node);
                }
            }
            else {
                if (node.outputs.length > 1) {
                    acc['PROCESS_META__CONTROL_NODES'].push(node);
                }
                else {
                    acc['PROCESS_META__AUTOMATED_TASKS'].push(node);
                }
            }
            return acc;
        }, nodes);
        return Object.entries(nodes);
    }, [mode, nodesDescription, canAddStepNode]);
    let title = '';
    switch (mode) {
        case 'add':
            title = t('PROCESS_META__ADD_TASK');
            break;
        case 'paste':
            title = t('PASTE');
            break;
        default:
            title = t('REPLACE');
            break;
    }
    return (React.createElement(Modal, { centered: true, destroyOnClose: true, width: "40%", open: open, title: title, onCancel: () => onCancel && onCancel(), footer: null, className: `${styles.wrapper}` },
        React.createElement(React.Fragment, null,
            isDecisionNode(selectedNode) && (React.createElement(React.Fragment, null,
                React.createElement("strong", { className: "section d-block mt-3" }, t('PROCESS_META__MEETS_CRITERIA')),
                React.createElement("div", { className: "my-3" },
                    React.createElement(Radio.Group, { buttonStyle: "solid", value: decisionBranch, onChange: (e) => {
                            setDecisionBranch(e.target.value);
                        } },
                        React.createElement(Radio.Button, { value: "TRUE" }, t('YES')),
                        React.createElement(Radio.Button, { value: "FALSE" }, t('NO')))))),
            selectedNodeDescription &&
                selectedNodeDescription.outputs &&
                selectedNodeDescription.outputs.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("strong", { className: "section d-block mt-3" }, "Branch"),
                React.createElement("div", { className: "my-3" },
                    React.createElement(Radio.Group, { buttonStyle: "solid", value: decisionBranch, onChange: (e) => {
                            setDecisionBranch(e.target.value);
                        } }, selectedNodeDescription.outputs.map((item, index) => {
                        return (React.createElement(Radio.Button, { value: index, key: index }, t(selectedNodeDescription?.outputNames?.[index] || item)));
                    }))))),
            mode === 'paste' ? (React.createElement(React.Fragment, null,
                React.createElement(Divider, null),
                React.createElement("div", { className: "d-flex flex-row justify-content-end align-items-center" },
                    React.createElement(Button, { onClick: () => {
                            onSelected(decisionBranch);
                        } }, t('PASTE'))))) : (nodesBySections.map(([sectionDisplayName, nodes], index) => nodes.length ? (React.createElement(Fragment, { key: index },
                React.createElement("strong", { className: "section d-block mt-3" }, t(sectionDisplayName)),
                nodes.map((node, i) => (React.createElement("div", { key: i, onClick: () => {
                        onSelected(decisionBranch, buildNode(node, selectedNode, decisionBranch));
                    }, className: "task-item d-flex flex-row justify-content-start align-items-center py-2 fw-bold" },
                    React.createElement(MdAddCircleOutline, { className: "task-item-icon me-2" }),
                    React.createElement("div", { className: "task-item-title" }, t(node.displayName))))))) : null)))));
};
export default AddNodeDialog;
