import { CONVERSATIONS_BASE_API_PATH, CONVERSATIONS_PATHNAME } from './common/constants';
export const fetchMessages = (conversationNamespace, conversationId) => ({
    type: 'CONVERSATIONS__FETCH_MESSAGES',
    meta: {
        http: {
            path: [
                CONVERSATIONS_BASE_API_PATH,
                CONVERSATIONS_PATHNAME,
                conversationNamespace,
                conversationId,
                'messages'
            ].join('/'),
            method: 'GET',
            actionContext: { conversationNamespace, conversationId },
            allowPromiseReject: true
        }
    }
});
export const createMessage = (conversationNamespace, conversationId, author, content, mentions, replyToMessageId) => {
    const newMessage = {
        author,
        conversationNamespace,
        conversationId,
        content,
        replyToMessageId,
        mentions
    };
    return {
        type: 'CONVERSATIONS__CREATE_MESSAGE',
        payload: newMessage,
        meta: {
            http: {
                path: [
                    CONVERSATIONS_BASE_API_PATH,
                    CONVERSATIONS_PATHNAME,
                    conversationNamespace,
                    conversationId,
                    'messages'
                ].join('/'),
                method: 'POST',
                allowPromiseReject: true
            }
        }
    };
};
export const deleteMessage = (conversationNamespace, conversationId, id) => ({
    type: 'CONVERSATIONS__DELETE_MESSAGE',
    meta: {
        http: {
            path: [
                CONVERSATIONS_BASE_API_PATH,
                CONVERSATIONS_PATHNAME,
                conversationNamespace,
                conversationId,
                'messages',
                id
            ].join('/'),
            method: 'DELETE',
            actionContext: { conversationNamespace, conversationId, id },
            allowPromiseReject: true
        }
    }
});
