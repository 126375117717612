import { MobileHorizontalScrollTip } from '@hypercharge/hyper-react-base/lib/common/table';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { SortOrder } from '@hypercharge/portal-utils';
import { Input } from 'antd';
import cn from 'classnames';
import { get, isEmpty, isObject, isString, map, union, without, xor } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaAngleDown, FaLayerGroup } from 'react-icons/fa';
import { ENTITY_ID_PROPERTY_ID, getFlattenedDisplayDataList } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { isPropertyList } from '../../../cms/common/context/PropertiesProvider';
import { AVAILABLE_GROUPING_ROWS } from '../../../cms/data/components/items/utils';
import { MediumRowSizeIcon, SmallRowSizeIcon, TallRowSizeIcon } from '../../../icons';
import { MetricsDisplayContainer } from '../../../views';
import { useView } from '../../../views/components/ViewContext';
import { RowHeightT } from '../../../views/types';
import { DataActionsContainer } from '../DataActionsContainer';
import DropdownButton from '../DropdownButton';
import { TooltipTruncate } from '../TooltipTruncate';
import HeightContext, { HeightContextProvider } from '../height-context';
import LoadingIndicator from '../loading-indicator';
import TablePagination from '../table-pagination';
import styles from './DataTable2.module.scss';
import ReactTable from './ReactTable';
import { useTableData } from './TableProvider';
import { ROW_HEIGHT_TYPES_LIST, ROW_HEIGHT_TYPES_TO_PIXELS, storeTableCustomizations } from './utils';
const HEIGHT_EMPTY_TABLE = '110px';
const rowHeightSizeIcons = {
    [RowHeightT.SMALL]: React.createElement(SmallRowSizeIcon, { color: "var(--neutralColor-6)" }),
    [RowHeightT.MEDIUM]: React.createElement(MediumRowSizeIcon, { color: "var(--neutralColor-6)" }),
    [RowHeightT.TALL]: React.createElement(TallRowSizeIcon, { color: "var(--neutralColor-6)" })
};
const PAGINATION_BLOCK_HEIGHT = 40;
const TABLE_BOTTOM_PADDING = 8;
const DataTable2 = ({ title, columns, totalCount, isPending, isFailed, idField = DEFAULT_ID_FIELD, singleMode, actions = [], leftActions = [], noFilters = false, noSearch = false, noViewActions = false, noHotkeySearch = false, noRowHeightAction = false, noKanban, withCmsImport = false, showStartProcessSelector = true, changeTableSortBy, contentHeight, contentMaxHeight, showViewsSelector, baseUrl, selectable, showFilterSelected, showItemsLimitError, maxItemsCount, setExpandedMap }) => {
    const otherHeightForTableSize = totalCount > 0 ? PAGINATION_BLOCK_HEIGHT + TABLE_BOTTOM_PADDING : TABLE_BOTTOM_PADDING;
    const { t, language } = useI18n();
    const tableContainerScrollerRef = useRef(null);
    const { filterRequest, page, pageSize, rowHeightType, changePage, changePageSize, changeRowHeightType, isAllItemsSelected, setIsAllItemsSelected, selectedItems, setSelectedItems, setIsAllItemsSelectedOnPage, rowGrouping, changeRowGrouping, definitionId, enableGrouping } = useView();
    const { items } = useTableData();
    const { displayData } = useEntityDisplayData(definitionId);
    const viewableEntityProperties = useMemo(() => (displayData ? getFlattenedDisplayDataList(displayData) : []), [displayData]);
    const toggleSelection = useCallback((id) => {
        const selected = new Set(selectedItems);
        const exists = selected.has(id);
        let newSelectedItems;
        setIsAllItemsSelected(false);
        if (singleMode) {
            newSelectedItems = exists ? [] : [id];
        }
        else {
            if (exists) {
                selected.delete(id);
            }
            else {
                selected.add(id);
            }
            newSelectedItems = Array.from(selected);
        }
        setSelectedItems(newSelectedItems);
    }, [selectedItems, singleMode, setIsAllItemsSelected, setSelectedItems]);
    const isAllSelectedOnPage = useMemo(() => {
        const pageResultIds = map(items, idField);
        return (!isEmpty(pageResultIds) && isEmpty(xor(pageResultIds, selectedItems)));
    }, [idField, items, selectedItems]);
    useEffect(() => {
        setIsAllItemsSelectedOnPage(isAllSelectedOnPage);
    }, [setIsAllItemsSelectedOnPage, isAllSelectedOnPage]);
    const toggleSelectAll = useCallback(() => {
        if (rowGrouping && !isAllItemsSelected) {
            setIsAllItemsSelected(true);
            setSelectedItems([]);
            return;
        }
        const pageResultIds = map(items, idField).filter(isString);
        let selected;
        setIsAllItemsSelected(false);
        if (isAllSelectedOnPage) {
            selected = singleMode ? [] : without(selectedItems, ...pageResultIds);
        }
        else if (singleMode) {
            selected = pageResultIds[0] ? [pageResultIds[0]] : [];
        }
        else {
            selected = union(selectedItems, pageResultIds);
        }
        setSelectedItems(selected);
    }, [
        rowGrouping,
        isAllItemsSelected,
        items,
        idField,
        setIsAllItemsSelected,
        isAllSelectedOnPage,
        singleMode,
        setSelectedItems,
        selectedItems
    ]);
    const isSelected = useCallback((key) => {
        return selectedItems.includes(key) || isAllItemsSelected;
    }, [isAllItemsSelected, selectedItems]);
    const handleChangePageSize = useCallback((pageSize) => {
        storeTableCustomizations({ pageSize });
        changePageSize(pageSize);
    }, [changePageSize]);
    const handleChangePage = useCallback((newPageIndex) => {
        changePage(newPageIndex);
    }, [changePage]);
    const sorted = useMemo(() => (filterRequest.sortBy || []).map(({ field, order }) => ({
        id: field,
        desc: order === SortOrder.desc
    })), [filterRequest?.sortBy]);
    const heightRowAction = useMemo(() => ({
        key: 'height',
        buttonComponent: DropdownButton,
        buttonProps: {
            menu: {
                className: 'rowMenu',
                items: ROW_HEIGHT_TYPES_LIST.map((sizeType) => ({
                    key: sizeType,
                    onClick: () => changeRowHeightType(sizeType),
                    className: cn({ 'ant-dropdown-menu-item-selected': rowHeightType === sizeType }),
                    label: (React.createElement(React.Fragment, null,
                        rowHeightSizeIcons[sizeType],
                        t(`DATA_TABLE__ROW_HEIGHT_TYPE_${sizeType}`)))
                }))
            },
            placement: 'bottomLeft',
            className: 'ActionsButtonOuter',
            inversed: true,
            menuIcon: rowHeightSizeIcons[rowHeightType]
        }
    }), [changeRowHeightType, rowHeightType, t]);
    const [groupingActionSearch, setGroupingActionSearch] = useState('');
    const groupingAction = useMemo(() => ({
        key: 'grouping',
        buttonComponent: DropdownButton,
        buttonProps: {
            menu: {
                className: 'rowMenu',
                items: [
                    {
                        className: 'p-0 bg-transparent search-item',
                        label: (React.createElement(Input, { className: "h-auto border-0 rounded-0", value: groupingActionSearch, onChange: (e) => setGroupingActionSearch(e.target.value), onClick: (event) => {
                                event.stopPropagation();
                            }, placeholder: t('SEARCH') }))
                    },
                    ...viewableEntityProperties
                        .filter((field) => {
                        return (field.propertyId !== ENTITY_ID_PROPERTY_ID &&
                            !isPropertyList(field) &&
                            AVAILABLE_GROUPING_ROWS.includes(field.type) &&
                            (groupingActionSearch
                                ? getTranslation(field.labels, language)
                                    .toLowerCase()
                                    .includes(groupingActionSearch.toLowerCase())
                                : true));
                    })
                        .map((groupingItem) => ({
                        key: groupingItem.propertyId,
                        onClick: () => {
                            setGroupingActionSearch('');
                            changeRowGrouping?.(rowGrouping === groupingItem.propertyId ? undefined : groupingItem.propertyId);
                        },
                        className: cn({
                            'ant-dropdown-menu-item-selected': groupingItem.propertyId === rowGrouping
                        }),
                        label: (React.createElement(TooltipTruncate, null, getTranslation(viewableEntityProperties.find((prop) => prop.propertyId === groupingItem.propertyId)?.labels || {}, language)))
                    })),
                    ...(rowGrouping === undefined
                        ? []
                        : [
                            {
                                type: 'group',
                                className: 'ungroup position-sticky bg-light',
                                children: [
                                    { type: 'divider' },
                                    {
                                        label: t('UN_GROUP_BY_THIS_PROPERTY'),
                                        key: 'ungroup',
                                        className: 'text-center text-primary',
                                        onClick: () => {
                                            setGroupingActionSearch('');
                                            changeRowGrouping?.(undefined);
                                        }
                                    }
                                ]
                            }
                        ])
                ]
            },
            placement: 'bottomLeft',
            className: cn('ActionsButtonOuter actionButton groupingButton', { hasValue: rowGrouping }),
            dropdownOverlayClassName: cn(styles.groupingDropdownOverlay, 'overflow-auto'),
            inversed: true,
            menuText: rowGrouping && (React.createElement("div", { className: "d-flex align-items-center safe-space" },
                React.createElement(TooltipTruncate, { className: "fw-600" }, getTranslation(viewableEntityProperties.find((prop) => prop.propertyId === rowGrouping)?.labels ||
                    {}, language)),
                React.createElement("span", { className: "ms-2" },
                    React.createElement(FaAngleDown, null)))),
            menuIcon: React.createElement(FaLayerGroup, { color: "var(--neutralColor-6)" })
        }
    }), [groupingActionSearch, viewableEntityProperties, rowGrouping, t, language, changeRowGrouping]);
    const NoDataComponent = useMemo(() => getNoDataComponent(isFailed, t), [isFailed, t]);
    const data = useMemo(() => items.map((item) => ({ _id: get(item, idField), ...item })), [idField, items]);
    const tableColumns = useMemo(() => (columns.length > 0 ? columns : loadingColumns), [columns]);
    const tableStyles = useCallback((computedContentHeight) => {
        if (!data.length && contentMaxHeight) {
            return { height: HEIGHT_EMPTY_TABLE };
        }
        return { maxHeight: contentMaxHeight ? contentMaxHeight : computedContentHeight };
    }, [contentMaxHeight, data.length]);
    const containerActions = useMemo(() => {
        const additionalActions = [];
        if (enableGrouping &&
            groupingAction.buttonProps &&
            isObject(groupingAction.buttonProps.menu) &&
            'items' in groupingAction.buttonProps.menu &&
            Array.isArray(groupingAction.buttonProps.menu.items) &&
            groupingAction.buttonProps.menu.items.length > 0) {
            additionalActions.push(groupingAction);
        }
        if (!noRowHeightAction) {
            additionalActions.push(heightRowAction);
        }
        return [...additionalActions, ...actions];
    }, [actions, enableGrouping, groupingAction, heightRowAction, noRowHeightAction]);
    return (React.createElement(React.Fragment, null,
        (!noFilters || !noSearch || actions.length > 0 || heightRowAction) && (React.createElement(DataActionsContainer, { title: title, className: "actions-container ", leftActions: leftActions, actions: containerActions, isPending: isPending, items: items, showItemsLimitError: showItemsLimitError, maxItemsCount: maxItemsCount, baseUrl: baseUrl, idField: idField, noFilters: noFilters, noKanban: noKanban, noSearch: noSearch, noHotkeySearch: noHotkeySearch, noViewActions: noViewActions, showViewsSelector: showViewsSelector, withCmsImport: withCmsImport, showStartProcessSelector: showStartProcessSelector, showFilterSelected: showFilterSelected })),
        React.createElement(MetricsDisplayContainer, { className: "overflow-auto position-sticky start-0" }),
        React.createElement(HeightContextProvider, { otherHeightInPx: otherHeightForTableSize, otherElementSelectors: ['#items-data-action-block', '#ViewTitle', '#aggregation-content'], parentContentHeight: contentHeight },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight: computedContentHeight }) => (React.createElement("div", { className: cn('custom-scroll-styling table-vertical-scroll-wrapper position-relative overflow-auto rounded-4', styles.tableContainer), style: tableStyles(computedContentHeight), ref: tableContainerScrollerRef },
                React.createElement(MobileHorizontalScrollTip, { mediaBoundary: "md", className: "position-sticky start-0" }),
                React.createElement(ReactTable, { className: cn('sticky-table-header', {
                        selectable: actions && actions.length > 0
                    }), tableContainerScrollerRef: tableContainerScrollerRef, setExpandedMap: setExpandedMap, data: data, columns: tableColumns, LoadingComponent: LoadingIndicator, NoDataComponent: NoDataComponent, showPagination: false, windowScroller: !contentHeight, rowHeight: ROW_HEIGHT_TYPES_TO_PIXELS[rowHeightType], 
                    // server-side
                    onSortedChange: changeTableSortBy, loading: isPending, sorted: sorted, 
                    // checkbox
                    selectAll: isAllItemsSelected || isAllSelectedOnPage, isSelected: isSelected, toggleSelection: toggleSelection, toggleAll: toggleSelectAll, selectable: selectable, selectWidth: 45, pageSize: pageSize, pageIndex: 0, rowGrouping: rowGrouping }))))),
        totalCount > 0 && !rowGrouping && (React.createElement(TablePagination, { totalCount: totalCount, pageSize: pageSize, onPageSizeChange: handleChangePageSize, page: page, onPageChange: handleChangePage }))));
};
export default DataTable2;
//
// Utils
//
const loadingColumns = [
    {
        id: 'loading',
        Header: React.createElement("span", null, "\u00A0")
    }
];
const DEFAULT_ID_FIELD = 'id';
export const getNoDataComponent = (isFailed, t) => () => (React.createElement("div", { className: "table-no-data text-sm-center p-3" }, isFailed ? t('DATA_TABLE__FETCH_FAILED') : t('DATA_TABLE__NO_RESULTS')));
