import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import FormikField from '../../../../../common/components/formik/FormikField';
import { VEHICLE_CMS_DEFINITION_ID } from '../../../../../crm';
import { ViewTypes } from '../../../../../views/types';
import FacetsAggregationSelector from './components/FacetsAggregationSelector';
export const getFacetsFormSchema = () => {
    return Yup.array().of(Yup.object().shape({
        agg: Yup.object().shape({
            type: Yup.string(),
            field: Yup.string(),
            orderBy: Yup.string()
        }),
        propertyId: Yup.string(),
        labels: Yup.object().shape({
            en: Yup.string(),
            nl: Yup.string(),
            fr: Yup.string()
        }),
        step: Yup.number(),
        min: Yup.number(),
        max: Yup.number(),
        meta: Yup.object()
            .shape({
            locale: Yup.string().optional(),
            widget: Yup.string().optional()
        })
            .optional()
    }));
};
const Form = () => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "pb-2" },
        React.createElement(LabelText, null, t('APPS_HYPER_DEALER__STOCK_FILTERS')),
        React.createElement(FormikField, { name: "configuration.website.stockFilters", component: FacetsAggregationSelector, definitionId: VEHICLE_CMS_DEFINITION_ID, viewType: ViewTypes.table })));
};
export default Form;
