import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ItemMetaSelector } from '../../../../../common/components/entity-selectors';
import FormikField from '../../../../../common/components/formik/FormikField';
import AvailableEntityViews from './AvailableEntitiesForm/AvailableEntityViews';
const AvailableEntities = ({ entityGroup, entityGroupPath, disabled, setFieldValue, entityGroupIdx, onViewCreate, onViewOpen, arrayHelpersEntities }) => {
    const { t } = useI18n();
    return (React.createElement("div", null,
        entityGroup.entities.map((entity, entityIdx) => {
            const entityPath = `${entityGroupPath}.entities.${entityIdx}`;
            return (React.createElement("div", { key: entityIdx, className: "d-flex flex-row align-items-top mb-4" },
                React.createElement("div", { className: "col-6 me-4" },
                    React.createElement("span", null,
                        t('ENTITY'),
                        ": "),
                    React.createElement(FormikField, { name: `${entityPath}.entityId`, component: ItemMetaSelector, className: "mt-2", onChange: (value) => {
                            setFieldValue(`${entityPath}.views`, []);
                            setFieldValue(`${entityPath}.entityId`, value);
                        }, disabled: disabled })),
                React.createElement(AvailableEntityViews, { entity: entity, entityPath: entityPath, disabled: disabled, entityIdx: entityIdx, entityGroupIdx: entityGroupIdx, onViewCreate: onViewCreate, onViewOpen: onViewOpen }),
                React.createElement("div", { className: "col-1 my-auto" },
                    React.createElement(Tooltip, { title: t('APPS_CUSTOMER_PORTAL__REMOVE_ENTITY_OPTION') },
                        React.createElement(Button, { className: "action-button", onClick: () => arrayHelpersEntities.remove(entityIdx), inversed: true, disabled: disabled },
                            React.createElement(FaTrash, { preserveAspectRatio: "none" }))))));
        }),
        React.createElement(Button, { className: "action-button", onClick: () => arrayHelpersEntities.push({
                views: []
            }), disabled: disabled, inversed: true },
            React.createElement(FaPlus, { className: "me-2" }),
            t('APPS_CUSTOMER_PORTAL__ADD_ENTITY_OPTION'))));
};
export default AvailableEntities;
