import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import React from 'react';
import * as Yup from 'yup';
import FormikField from '../../../../common/components/formik/FormikField';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import Toggle from '../../../../common/components/Toggle';
export const FastbackInitialValues = {
    enabled: false,
    url: '',
    advancedEnabled: false,
    advancedUrl: ''
};
export const getFastbackFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.bool(),
        url: Yup.string().when('enabled', {
            is: true,
            then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
        }),
        advancedEnabled: Yup.bool(),
        advancedUrl: Yup.string().when('advancedEnabled', {
            is: true,
            then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
        })
    });
};
const Form = ({ instance, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            t('INTEGRATIONS_BASIC_IMPORT'),
            ":"),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.import.fastback.enabled", component: Toggle, label: `${t('ENABLE_INTEGRATION')}`, value: instance.configuration?.import?.fastback?.enabled, disabled: disabled })),
        React.createElement(FormikField, { name: "configuration.import.fastback.url", component: BasicInput, label: `${t('INTEGRATIONS_FASTBACK_URL')} *`, value: instance.configuration?.import?.fastback?.url, disabled: disabled || !instance.configuration?.import?.fastback?.enabled }),
        React.createElement("p", { className: "pt-3 mt-3 border-top" },
            t('INTEGRATIONS_ADVANCED_IMPORT'),
            ":"),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.import.fastback.advancedEnabled", component: Toggle, label: `${t('ENABLE_INTEGRATION')}`, value: instance.configuration?.import?.fastback?.advancedEnabled, disabled: disabled })),
        React.createElement(FormikField, { name: "configuration.import.fastback.advancedUrl", component: BasicInput, label: `${t('INTEGRATIONS_FASTBACK_URL')} *`, value: instance.configuration?.import?.fastback?.advancedUrl, disabled: disabled || !instance.configuration?.import?.fastback?.advancedEnabled })));
};
export default Form;
