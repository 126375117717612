import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Switch } from 'antd';
import React, { Fragment } from 'react';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { CMS_PATH } from '../../../cms';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { GROUP_CMS_DEFINITION_ID, PERMISSION_GROUP_TRANSLATION_KEYS } from '../../constants';
import styles from '../GroupsField/GroupsField.module.scss';
import ParentViewPermissionWarningIcon from '../ParentViewPermissionWarningIcon/ParentViewPermissionWarningIcon';
import ToggleDrawerButton from '../ToggleDrawerButton/ToggleDrawerButton';
import { isConfiguredRowBasedPermission } from '../utils';
const GroupsList = ({ disabled, groupListNames, toggleGroup, isGroupChecked, groups, config, rowPermissions, allowedRowPermissions, setSelectedGroupId, isPermissionToggleDisabled, parentViewGroups, onRemove }) => {
    const { isMobile } = useBrowserBreakpoints();
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null, groups.map(({ entityId: groupEntityId, title }) => (React.createElement("div", { key: groupEntityId, className: `${styles.fieldsContainer} cp-c-row flex-wrap py-sm-3 px-2` },
        React.createElement("div", { className: `${styles.text} ${styles.groupName} cp-i-100 cp-i-sm-flex cp-c-row cp-c-align-start-center py-sm-3 px-2` },
            React.createElement("div", { className: "d-flex" },
                config?.asLink ? (React.createElement(Link, { to: [CMS_PATH, GROUP_CMS_DEFINITION_ID, groupEntityId].join('/') }, title)) : (title),
                React.createElement(ParentViewPermissionWarningIcon, { groupId: groupEntityId, groupListNames: groupListNames, isGroupChecked: isGroupChecked, parentViewGroups: parentViewGroups })),
            onRemove && !disabled && isMobile && (React.createElement(IoMdClose, { className: `${styles.styledRemoveIcon}`, onClick: () => {
                    onRemove(groupEntityId);
                } })),
            allowedRowPermissions?.length && (React.createElement(ToggleDrawerButton, { active: isConfiguredRowBasedPermission(groupEntityId, allowedRowPermissions, rowPermissions), onClick: () => {
                    setSelectedGroupId(groupEntityId);
                } }))),
        groupListNames.map((name) => (React.createElement(Fragment, { key: name },
            React.createElement("div", { className: `${styles.text} cp-i-80 d-sm-none py-sm-3 px-2` }, t(PERMISSION_GROUP_TRANSLATION_KEYS[name])),
            React.createElement("div", { className: "py-sm-3 px-2 justify-content-start justify-content-sm-center cp-i-20 cp-i-sm-flex cp-c-row cp-c-align-start-start cp-c-sm-align-start-center" },
                React.createElement(Switch, { checked: isGroupChecked(name, groupEntityId), checkedChildren: React.createElement(IoMdCheckmark, { className: styles.smallText }), unCheckedChildren: React.createElement(IoClose, { className: styles.smallText }), onChange: () => toggleGroup(name, groupEntityId), disabled: disabled || isPermissionToggleDisabled(groupEntityId, name) }))))),
        React.createElement("div", { className: "cp-i-10 cp-c-row cp-c-align-end-center py-sm-3 px-2" }, onRemove && !disabled && !isMobile && (React.createElement(IoMdClose, { className: `${styles.styledRemoveIcon} cp-i-none`, onClick: () => {
                onRemove(groupEntityId);
            } }))))))));
};
export default GroupsList;
