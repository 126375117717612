import React from 'react';
import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';
const Container = styled.div `
  min-height: 3rem;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
  margin-top: -1px;
`;
const Dot = styled.div `
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.2rem;
  margin: 0 1.75rem;
  background-color: #edefed;
`;
const Text = styled.div `
  padding: 0.5rem 0;
  font-size: 0.9rem;
  word-break: break-word;
`;
const RemoveButton = styled(IoMdClose) `
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 1.5rem;
  margin: 0 1.75rem;

  &:hover {
    color: ${({ disabled }) => (disabled ? null : 'red')};
  }
`;
const ListItem = ({ className, display, disabled, onRemove, value }) => {
    const handleRemove = () => {
        if (!disabled) {
            onRemove(value);
        }
    };
    return (React.createElement(Container, { className: `cp-c-row cp-c-align-start-center ${className || ''}` },
        React.createElement(Dot, { className: "cp-i-none" }),
        React.createElement(Text, { className: "cp-i-flex" }, display),
        React.createElement(RemoveButton, { className: "cp-i-none", onClick: handleRemove })));
};
export default ListItem;
