import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { FaAsterisk } from 'react-icons/fa';
import { IoLanguageOutline } from 'react-icons/io5';
import { Tooltip } from '../../../../../common/components/Tooltip';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import styles from './FieldLabel.module.scss';
const FieldLabel = ({ label, labelRight, computed, icon, hasTranslations, help, required, className = '' }) => {
    const { t } = useI18n();
    return label ? (React.createElement("div", { className: `${styles.label} fw-500 d-flex align-items-center FieldLabelOuter ${className}` },
        icon && (React.createElement("span", { className: "icon position-relative me-2" },
            icon,
            hasTranslations && (React.createElement(IoLanguageOutline, { size: 12, className: "top-0 start-100 translate-middle position-absolute w-auto rounded bg-white" })))),
        React.createElement(TooltipTruncate, { className: "label-text" }, label),
        required && (React.createElement("sup", { className: "ms-1 required-flag" },
            React.createElement(FaAsterisk, { size: "1.2em", color: "var(--errorColor-6)" }))),
        help && (React.createElement(Tooltip, { className: `${styles.additionalIcon} ms-1`, title: help },
            React.createElement(BsQuestionCircle, null))),
        computed && (React.createElement(Tooltip, { className: `${styles.additionalIcon} ms-1`, title: t('PROPERTY_COMPUTED') }, "\u26A1\uFE0F")),
        labelRight && React.createElement("span", { className: "ms-auto" }, labelRight))) : null;
};
export default FieldLabel;
