import React, { useMemo } from 'react';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { FieldWrapper } from '../../FieldElements';
const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/i;
const MailToLinkWrapper = ({ children }) => {
    const isValidEmail = useMemo(() => EMAIL_REGEX.test(children), [children]);
    if (!isValidEmail) {
        return React.createElement(TooltipTruncate, null, children);
    }
    return (React.createElement("a", { tabIndex: -1, href: `mailto:${children}`, onClick: (e) => e.stopPropagation() },
        React.createElement(TooltipTruncate, null, children)));
};
const EmailDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, className: className ?? '', disabled: disabled, simple: !asField, style: style },
    React.createElement("div", { className: "w-100" },
        React.createElement(MailToLinkWrapper, null, value || '\u00a0'))));
export default EmailDisplay;
