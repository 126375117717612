import { INITIAL_PAGE_SIZE } from 'config';
const templatesPrefix = '/v2-docs/templates';
export const searchTemplates = (filterRequest) => ({
    type: 'DOCUMENTS__SEARCH_TEMPLATES',
    meta: {
        http: {
            path: `${templatesPrefix}?limit=${filterRequest.limit || 100}&offset=${filterRequest.offset || 0}${filterRequest.fullText ? `&query=${encodeURIComponent(filterRequest.fullText)}` : ''}`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: {
                page: Math.ceil(((filterRequest.offset || 0) + INITIAL_PAGE_SIZE) / INITIAL_PAGE_SIZE),
                pageSize: INITIAL_PAGE_SIZE,
                query: filterRequest.fullText
            }
        }
    }
});
export const fetchTemplate = (templateId) => ({
    type: 'DOCUMENTS__FETCH_TEMPLATE',
    meta: {
        http: {
            path: `${templatesPrefix}/${templateId}`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: { templateId }
        }
    }
});
export const saveTemplate = (template) => ({
    type: 'DOCUMENTS__SAVE_TEMPLATE',
    payload: template,
    meta: {
        http: {
            path: `${templatesPrefix}`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const deleteTemplate = (templateId) => ({
    type: 'DOCUMENTS__DELETE_TEMPLATE',
    meta: {
        http: {
            path: `${templatesPrefix}/${templateId}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: { templateId }
        }
    }
});
export const fetchTemplateVersions = (templateId) => ({
    type: 'DOCUMENTS__FETCH_TEMPLATE_VERSIONS',
    meta: {
        http: {
            path: `${templatesPrefix}/${templateId}/versions`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
