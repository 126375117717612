import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { Option } from '../../../../../../common/components/Select';
import FormikField from '../../../../../../common/components/formik/FormikField';
import TabCloseConfirmation from '../../../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import { isProcessMeta } from '../../../../../../workflows/common/utils/utils';
import { saveMetaDefinition } from '../../../../../../workflows/meta/actions';
import { useDefinition } from '../../../../../../workflows/meta/components/common/withMetaDefinition';
import { onProcessMetaFormSubmit } from '../../../../../../workflows/meta/components/process-meta-form/utils';
import { permissionGroupFieldNames } from '../../../../../../workflows/meta/utils';
import EntityActions from '../../entity-actions/EntityActions';
import { onItemMetaFormSubmit } from '../../meta-entity-form/utils';
import styles from '../HighlightedItems.module.scss';
import HighlightedListField from '../components/HighlightedListField';
import HighlightedSelectField from '../components/HighlightedSelectField';
const HighlightedPropertiesForm = ({ itemMetaId, extraActionButtons, itemMeta, isSystem }) => {
    const { t, language } = useI18n();
    const dispatch = useDispatch();
    const saveDefinitionDispatch = useDispatch();
    const { definition, definitionStatus: { isPending }, allProperties, allPropertiesById } = useDefinition(itemMetaId, {
        needAllProperties: true,
        needAllPropertiesById: true
    });
    const initialValues = useMemo(() => isProcessMeta(itemMeta)
        ? {
            id: itemMeta.id,
            titles: itemMeta.titles,
            idPrefix: itemMeta.idPrefix,
            viewGroups: itemMeta.viewGroups,
            executeGroups: itemMeta.executeGroups,
            deleteGroups: itemMeta.deleteGroups,
            color: itemMeta.color,
            custom: {
                highlightedProperties: itemMeta.custom?.highlightedProperties || []
            }
        }
        : {
            titles: itemMeta.titles,
            viewGroups: itemMeta.viewGroups,
            custom: {
                highlightedProperties: itemMeta.custom?.highlightedProperties || []
            }
        }, [itemMeta]);
    const handleSubmit = useCallback(async (values) => {
        if (!definition) {
            return;
        }
        const updatedDefinition = {
            ...definition,
            sections: definition.sections.map((section) => ({
                ...section,
                properties: section.properties.map((property) => {
                    const { propertyId } = property;
                    const found = values?.custom?.highlightedProperties?.find((highlighted) => highlighted.propertyId === propertyId);
                    return {
                        ...property,
                        meta: {
                            ...property.meta,
                            highlighted: !!found
                        }
                    };
                })
            }))
        };
        await saveDefinitionDispatch(saveMetaDefinition(updatedDefinition));
        if (isProcessMeta(itemMeta)) {
            await onProcessMetaFormSubmit(values, dispatch, {
                isNew: false,
                permissionGroupFieldNames: itemMeta?.trigger
                    ? [AvailableSystemGroups.viewGroups]
                    : permissionGroupFieldNames
            });
        }
        else {
            await onItemMetaFormSubmit(values, dispatch, {
                itemMeta,
                t: t
            });
        }
    }, [definition, dispatch, itemMeta, language, saveDefinitionDispatch, t]);
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: handleSubmit, enableReinitialize: true }, ({ dirty, isSubmitting, resetForm, setFieldValue, submitForm, values }) => (React.createElement("div", { className: `${styles.container} py-4 px-3` },
        isPending ? (React.createElement(LoadingRectangles, null)) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "form-buttons-container" },
                dirty && (React.createElement(Button, { className: "reset-btn", inversed: true, onClick: () => resetForm(), disabled: isSubmitting }, t('FORM__RESET'))),
                React.createElement(EntityActions, { id: itemMetaId, save: submitForm, saving: isSubmitting, canSave: dirty, canDelete: !isSystem }),
                extraActionButtons),
            React.createElement("form", { noValidate: true },
                React.createElement("div", null,
                    React.createElement("div", { className: "fw-500 mb-2" }, t('HIGHLIGHTED_PROPERTIES_SETTINGS_SELECT_LABEL')),
                    React.createElement(FormikField, { name: "custom.highlightedProperties", component: HighlightedSelectField, data: allProperties, getValue: (value) => value.map(({ propertyId }) => propertyId), onSelectHandler: (selected) => {
                            void setFieldValue('custom.highlightedProperties', selected.map((propertyId) => {
                                const value = values?.custom?.highlightedProperties || [];
                                const existed = value.find((item) => item.propertyId === propertyId);
                                return {
                                    propertyId,
                                    active: existed ? existed.active : true
                                };
                            }));
                        }, getOptions: (data) => data
                            .filter(({ meta: { hidden } }) => !hidden)
                            .map(({ propertyId, labels }) => {
                            const label = getTranslation(labels, language);
                            return (React.createElement(Option, { key: propertyId, value: propertyId, disabled: false, label: label }, label));
                        }) }),
                    React.createElement("div", { className: "fw-500 mb-2 mt-4" }, t('HIGHLIGHTED_PROPERTIES_SETTINGS_LIST_LABEL')),
                    React.createElement(FormikField, { name: "custom.highlightedProperties", component: HighlightedListField, dataById: allPropertiesById }))))),
        React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
        React.createElement(TabCloseConfirmation, { dirty: dirty })))));
};
export default HighlightedPropertiesForm;
