import { INITIAL_PAGE_SIZE } from 'config';
import { WORKFLOWS_API_PATH } from '../common/utils/constants';
import { TaskStatus } from '../common/utils/types';
export const fetchDisplayTaskList = ({ processRunId, statuses, page, pageSize = INITIAL_PAGE_SIZE, assignedTo, executeGroups, language, sortBy, ignoreHierarchy }) => ({
    type: 'WORKFLOWS__FETCH_DISPLAY_TASK_LIST',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/tasks/display`,
            method: 'GET',
            params: {
                offset: (page - 1) * pageSize,
                limit: pageSize,
                procRunId: processRunId,
                statuses: statuses && statuses.join(','),
                assignedTo,
                executeGroups: executeGroups ? executeGroups.join(',') : undefined,
                languageCode: language,
                sortBy,
                ignoreHierarchy: ignoreHierarchy == null ? undefined : ignoreHierarchy
            }
        }
    }
});
export const assignTask = (processMetaId, processRunId, taskMetaId, taskRunId, contactId) => {
    const status = contactId ? TaskStatus.RUNNING : TaskStatus.WAITING;
    return {
        type: 'WORKFLOWS__ASSIGN_TASK',
        payload: {
            assignedTo: contactId,
            status
        },
        meta: {
            http: {
                path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/task/${taskMetaId}/run/${taskRunId}`,
                method: 'PUT',
                allowPromiseReject: true
            }
        }
    };
};
export const getTaskOutput = (processMetaId, processRunId, taskMetaId) => {
    return {
        type: 'WORKFLOWS__GET_TASK_OUTPUT',
        meta: {
            http: {
                path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/task/${taskMetaId}/output`,
                method: 'GET',
                actionContext: { processMetaId, processRunId, taskMetaId },
                allowPromiseReject: true
            }
        }
    };
};
export const completeDisplayTask = (processMetaId, processRunId, taskMetaId, taskRunId, contactId, additionalData) => ({
    type: 'WORKFLOWS__COMPLETE_TASK',
    payload: {
        ...(additionalData || {}),
        assignedTo: contactId,
        status: 'SUCCEEDED'
    },
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/process/${processMetaId}/run/${processRunId}/task/${taskMetaId}/run/${taskRunId}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
export const updateDisplayTaskPriority = (taskRunId, priority) => ({
    type: 'WORKFLOWS__UPDATE_DISPLAY_TASK_PRIORITY',
    payload: {
        taskRunId,
        priority
    }
});
export const updateDisplayTaskDeadline = (taskRunId, deadline) => ({
    type: 'WORKFLOWS__UPDATE_DISPLAY_TASK_DEADLINE',
    payload: {
        taskRunId,
        deadline
    }
});
export const searchTasks = (filterRequest) => {
    return {
        type: 'WORKFLOWS__SEARCH_TASKS',
        payload: filterRequest,
        meta: {
            http: {
                path: `${WORKFLOWS_API_PATH}/tasks/search`,
                method: 'POST',
                allowPromiseReject: true
            }
        }
    };
};
