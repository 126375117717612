import * as Ace from 'ace-builds';

Ace.define('ace/snippets/computed_expression', [], function (_, exports) {
  'use strict';

  exports.snippetText =
    // eslint-disable-next-line no-multi-str
    '# AND return inverse logical value\n\
snippet AND\n\
	AND(${1:value1}, ${2:value2}, ${3:...})\n\
# IF Returns one value if a logical expression is TRUE and another if it is FALSE.\n\
snippet IF\n\
	IF(${1:logical_expression}, ${2:value_if_true}, ${3:value_if_false})\n\
# IFS Evaluates multiple conditions and returns a value that corresponds to the first true condition.\n\
snippet IFS\n\
	IFS(${1:logical_expression}, ${2:value_if_true}, ${3:logical_expression2}, ${4:value_if_true2})\n\
# COALESCE evaluates its arguments in order and returns the first value that isn\'t blank..\n\
snippet COALESCE\n\
	COALESCE(${1:value1}, ${2:value2}, ${3:...})\n\
# NOT return inverse logical value\n\
snippet NOT\n\
	NOT(${1:logical_expression})\n\
# OR returns TRUE if any of the provided arguments are logically TRUE, and FALSE if all of the provided arguments are logically FALSE.\n\
snippet OR\n\
	OR(${1:value1}, ${2:value2}, ${3:...})\n\
# ISBLANK Checks whether the referenced property is empty.\n\
snippet ISBLANK\n\
	ISBLANK(${1:value})\n\
# SWITCH\n\
snippet SWITCH\n\
	SWITCH(${1:expression}, ${2:case1}, ${3:value1}, ${4:case2}, ${5:value2}, ${6:default})\n\
# CONCATENATE Appends strings to one another.\n\
snippet CONCATENATE\n\
	CONCATENATE(${1:string1}, ${2:string2}, ${3:...})\n\
# FIND Returns the position at which a string is first found within text, case-sensitive.\n\
snippet FIND\n\
	FIND(${1:search_for}, ${2:text_to_search}, ${3:starting_at})\n\
# JOIN Concatenate several elements (values or list of values) using a specified delimiter.\n\
snippet JOIN\n\
	JOIN(${1:delimiter}, ${2:value1}, ${3:value2}, ${4:...})\n\
# LEN Returns the length of a string.\n\
snippet LEN\n\
	LEN(${1:text})\n\
# SPLIT Divides text around a specified character or string, and puts each fragment into a separate cell in the row.\n\
snippet SPLIT\n\
	SPLIT(${1:text}, "${2:delimiter}")\n\
# LOWER Converts a specified string to lowercase.\n\
snippet LOWER\n\
	LOWER(${1:text})\n\
# REGEXEXTRACT Extracts matching substrings according to a regular expression.\n\
snippet REGEXEXTRACT\n\
	REGEXEXTRACT(${1:text}, "${2:regular_expression}")\n\
# REGEXMATCH Whether a piece of text matches a regular expression.\n\
snippet REGEXMATCH\n\
	REGEXMATCH(${1:text}, "${2:regular_expression}")\n\
# REGEXREPLACE Replaces part of a text string with a different text string using regular expressions.\n\
snippet REGEXREPLACE\n\
	REGEXREPLACE(${1:text}, "${2:regular_expression}", "${3:replacement}")\n\
# SUBSTITUTE Replaces existing text with new text in a string.\n\
snippet SUBSTITUTE\n\
	SUBSTITUTE(${1:text_to_search}, "${2:search_for}", "${3:replace_with}")\n\
# TRIM Removes leading, trailing, and repeated spaces in text.\n\
snippet TRIM\n\
	TRIM(${1:text})\n\
# UPPER Converts a specified string to uppercase.\n\
snippet UPPER\n\
	UPPER(${1:text})\n\
# TEXT Converts a number into text according to a specified format.\n\
snippet TEXT\n\
	TEXT(${1:number}, "${2:format}")\n\
# SLUG Slugifies a specified string (lowercased, hyphenated).\n\
snippet SLUG\n\
	SLUG(${1:text})\n\
# ABS Returns the absolute value of a number.\n\
snippet ABS\n\
	ABS(${1:value})\n\
# EXP Returns Euler\'s number, e (~2.718) raised to a power.\n\
snippet EXP\n\
	EXP(${1:value})\n\
# POWER or POW Returns a number raised to a power. Equivalent to the "^" operator.\n\
snippet POWER or POW\n\
	POWER(${1:base}, ${2:exponent})\n\
# MINUS Returns the difference of two numbers. Equivalent to the "-" operator.\n\
snippet MINUS\n\
	MINUS(${1:value1}, ${2:value2})\n\
# PRODUCT Returns the result of multiplying a series of numbers together.\n\
snippet PRODUCT\n\
	PRODUCT(${1:factor1}, ${2:factor2}, ${4:...})\n\
# SUM Returns the sum of a series of numbers and/or numbers.\n\
snippet SUM\n\
	SUM(${1:value1}, ${2:value2}, ${4:...})\n\
# DIVIDE Returns one number divided by another. Equivalent to the "/" operator.\n\
snippet DIVIDE\n\
	DIVIDE(${1:dividend}, ${2:divisor})\n\
# N Returns the argument provided as a number.\n\
snippet N\n\
	N(${1:value})\n\
# DATE Converts a year, month, and day into a date.\n\
snippet DATE\n\
	DATE(${1:year}, ${2:month}, ${3:day})\n\
# NOW Returns the current date and time as a date value.\n\
snippet NOW\n\
	NOW()\n\
# TODAY Returns the current date as a date value.\n\
snippet TODAY\n\
	TODAY()\n\
# SODATE Mutates the original moment by setting it to the start of a unit of time.\n\
snippet SODATE\n\
	SODATE(${1:date}, "${2:unit}")\n\
# ADDDATE Mutates the original moment by adding time.\n\
snippet ADDDATE\n\
	ADDDATE(${1:date}, "${2:value}", "${3:unit}")\n\
# SOWEEK Returns a date representing the first day of a week.\n\
snippet SOWEEK\n\
	SOWEEK(${1:date})\n\
# EOWEEK Returns a date representing the last day of a week.\n\
snippet EOWEEK\n\
	EOWEEK(${1:date})\n\
# SOMONTH Returns a date representing the first day of a month.\n\
snippet SOMONTH\n\
	SOMONTH(${1:date})\n\
# EOMONTH Returns a date representing the last day of a month.\n\
snippet EOMONTH\n\
	EOMONTH(${1:date})\n\
# YEAR Returns the year specified by a given date.\n\
snippet YEAR\n\
	YEAR(${1:date})\n\
# MONTH The output is an integer value representing the number of the month, of the year.\n\
snippet MONTH\n\
	MONTH(${1:date})\n\
# DAY Returns the day of the month that a specific date falls on.\n\
snippet DAY\n\
	DAY(${1:date})\n\
# TZ Returns a date converted to the selected timezone.\n\
snippet TZ\n\
	TZ(${1:date}, "${2:Europe/Brussels}")\n\
# FORMATPHONE Returns the phone number converted to international format (with spaces).\n\
snippet FORMATPHONE\n\
	FORMATPHONE(${1:phone})\n\
';
  exports.scope = 'computed_expression';
});
