import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import { Loader } from '../../../common/components/InlineEditor/styled';
const CopyTemplateButton = ({ handleCopy }) => {
    const [isCopying, setIsCopying] = useState(false);
    const { t } = useI18n();
    const handleClick = useCallback(async () => {
        try {
            setIsCopying(true);
            await handleCopy();
        }
        finally {
            setIsCopying(false);
        }
    }, [handleCopy]);
    if (isCopying) {
        return React.createElement(Loader, null);
    }
    return (React.createElement("div", { role: "button", className: "copy-btn mx-1", onClick: handleClick },
        React.createElement(Tooltip, { placement: "top", title: t('COPY') },
            React.createElement(FaCopy, { size: 24 }))));
};
export default CopyTemplateButton;
