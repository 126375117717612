import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Select } from 'antd';
import { isEmpty, keys, map, omit, xor } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MappingField from '../../../common/components/mapping/MappingField';
const Container = styled.div `
  margin-bottom: ${(props) => (props.isMobile ? '4rem' : '0')};
`;
const MappingsContainer = styled.div `
  margin-top: 1rem;
  margin-left: 1rem;
`;
const newMappingAnimationDurationInMs = 2500;
const delayToCloseMappingsSelectorInMs = 500;
const MappingsField = ({ className, label, mappingOptions, input: { onChange, value }, disabled }) => {
    const { t } = useI18n();
    const fieldSelector = useRef(null);
    const highlightNewMappingTimeout = useRef(null);
    const [isLastMappingNew, setIsLastMappingNew] = useState(false);
    const prevActiveMappingFieldIds = useRef([]);
    const currentActiveMappingFieldIds = keys(value);
    const clearHighlightNewFilterTimeout = () => {
        if (highlightNewMappingTimeout.current) {
            window.clearTimeout(highlightNewMappingTimeout.current);
        }
    };
    useEffect(() => {
        if (currentActiveMappingFieldIds.length > prevActiveMappingFieldIds.current.length &&
            prevActiveMappingFieldIds.current.length !== 0) {
            setIsLastMappingNew(true);
        }
        else if (!isEmpty(xor(currentActiveMappingFieldIds, prevActiveMappingFieldIds.current))) {
            clearHighlightNewFilterTimeout();
            setIsLastMappingNew(false);
        }
        else if (isLastMappingNew) {
            clearHighlightNewFilterTimeout();
            highlightNewMappingTimeout.current = window.setTimeout(() => {
                setIsLastMappingNew(false);
            }, newMappingAnimationDurationInMs);
        }
    }, [currentActiveMappingFieldIds, prevActiveMappingFieldIds, isLastMappingNew]);
    useEffect(() => {
        prevActiveMappingFieldIds.current = currentActiveMappingFieldIds;
    }, [currentActiveMappingFieldIds]);
    const getMappingOptionLabel = useCallback((mappingFieldId) => {
        let fieldLabel;
        mappingOptions.forEach(({ id, label, children }) => {
            if (children) {
                children.forEach((option) => {
                    if (option.id === mappingFieldId) {
                        fieldLabel = option.label;
                    }
                });
            }
            else if (id === mappingFieldId) {
                fieldLabel = label;
            }
        });
        return fieldLabel;
    }, [mappingOptions]);
    const selectFieldsOptions = mappingOptions
        .filter(({ id }) => !currentActiveMappingFieldIds.includes(id))
        .map(({ id, label, children }) => {
        const optionHasChildren = Array.isArray(children);
        if (optionHasChildren) {
            return {
                label: label,
                options: children
                    .filter((option) => !currentActiveMappingFieldIds.includes(option.id))
                    .map((option) => ({
                    value: option.id,
                    label: option.label
                }))
            };
        }
        return { value: id, label: label };
    });
    return (React.createElement(Container, { className: className, hasMappings: !isEmpty(currentActiveMappingFieldIds) },
        label && React.createElement(LabelText, null, label),
        React.createElement(Select, { ref: fieldSelector, style: { width: '100%' }, mode: "multiple", value: [], placeholder: t('XDMS_SELECT_FIELD_TO_MAP'), onSelect: (id) => {
                onChange({ ...value, [id]: '' });
                fieldSelector?.current?.blur();
            }, disabled: disabled, options: selectFieldsOptions, optionFilterProp: "label" }),
        !isEmpty(currentActiveMappingFieldIds) && (React.createElement(MappingsContainer, null, map(currentActiveMappingFieldIds, (mappedField, index) => {
            return (React.createElement(MappingField, { key: mappedField, value: value[mappedField], fieldLabel: getMappingOptionLabel(mappedField) || mappedField, isNew: index === currentActiveMappingFieldIds.length - 1 && isLastMappingNew, disabled: disabled, placeholder: t('FIELD_MAPPING_PLACEHOLDER'), newMappingAnimationDurationInMs: newMappingAnimationDurationInMs, scrollToViewDelayInMs: delayToCloseMappingsSelectorInMs, onChange: (newValue) => {
                    onChange({ ...value, [mappedField]: newValue });
                }, onRemove: () => {
                    onChange(omit(value, mappedField));
                } }));
        })))));
};
export default MappingsField;
