import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { colord } from 'colord';
import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { TooltipTruncate } from '../TooltipTruncate';
import styles from './ShowMore.module.scss';
const ShowMore = ({ className, collapsed, itemsCount, onClick, theme }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.outer} ShowMoreOuter ${className || ''}` },
        collapsed && (React.createElement("span", { className: 'text-overlay', style: theme?.primary?.length
                ? {
                    background: `linear-gradient(${colord(theme.primary)
                        .alpha(0.8)
                        .toRgbString()}, ${colord(theme.primary).toRgbString()})`
                }
                : undefined })),
        React.createElement("div", { className: "textBlock mw-100", onClick: onClick, style: {
                borderColor: theme?.secondary,
                color: theme?.secondary
            } },
            React.createElement("span", { className: "me-1" }, collapsed ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null)),
            React.createElement(TooltipTruncate, null, collapsed
                ? t(itemsCount ? 'SHOW_ALL_ITEMS' : 'SHOW_MORE', { itemsCount })
                : t('SHOW_LESS')))));
};
export default ShowMore;
