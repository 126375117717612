import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import { colord } from 'colord';
import React, { useCallback, useEffect, useState } from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { useDisplayTenant } from '../../../../tenant/useDisplayTenant';
import { ColorInput } from './ColorInput';
import styles from './ColorPicker.module.scss';
import { Colors } from './Colors';
import { defaultColors } from './constants';
const inputsFormat = ['r', 'g', 'b', 'a'];
const SESSION_STORAGE_CUSTOM_COLORS_KEY = 'CUSTOM_COLORS';
export const ColorPicker = ({ value = defaultColors[0], onSave, onCancel }) => {
    const { t } = useI18n();
    const { tenantTheme: { variables } } = useDisplayTenant();
    const customColorsData = sessionStorage.getItem(SESSION_STORAGE_CUSTOM_COLORS_KEY);
    const [customColors, setCustomColors] = useState(() => customColorsData ? JSON.parse(customColorsData) : []);
    const [color, setColor] = useState(value);
    const handleChange = useCallback((newColor) => {
        setColor(newColor);
    }, []);
    const handleAddCustomColor = useCallback(() => {
        setCustomColors((customColors) => {
            if (customColors.includes(color)) {
                return customColors;
            }
            const newCustomColors = [...customColors, color];
            sessionStorage.setItem(SESSION_STORAGE_CUSTOM_COLORS_KEY, JSON.stringify(newCustomColors));
            return newCustomColors;
        });
    }, [color]);
    const handleSave = useCallback(() => {
        onSave && onSave(color);
    }, [color, onSave]);
    useEffect(() => {
        setColor(value);
    }, [value]);
    return (React.createElement("div", { className: `${styles.container} border rounded p-3` },
        React.createElement(HexAlphaColorPicker, { color: color, onChange: handleChange }),
        React.createElement("div", { className: `${styles.inputs} row mt-4` },
            React.createElement("div", { className: "col-4" },
                React.createElement("p", { className: "title mb-2 fw-600" }, "Hex"),
                React.createElement(HexColorInput, { className: "rounded w-100 ant-input", alpha: true, prefixed: true, color: value, onChange: handleChange })),
            inputsFormat.map((key) => (React.createElement("div", { key: key, className: "col-2" },
                React.createElement("p", { className: "title mb-2 fw-600" }, key.toUpperCase()),
                React.createElement(ColorInput, { name: key, color: color, onChange: handleChange }))))),
        React.createElement(Colors, { title: t('DEFAULT_COLORS'), value: color, onChange: handleChange }),
        variables && (React.createElement(Colors, { title: t('TENANT_COLORS'), value: color, onChange: handleChange, colors: Object.values(variables).filter((variable) => colord(variable).isValid()) })),
        React.createElement(Colors, { title: t('CUSTOM_COLORS'), value: color, onChange: handleChange, colors: customColors, addCustomColor: handleAddCustomColor }),
        React.createElement("div", { className: "footer-buttons mt-3 d-flex justify-content-end" },
            React.createElement(Button, { className: "rounded cancel fw-600", onClick: onCancel }, t('CANCEL')),
            React.createElement(Button, { type: "primary", className: "ms-3 rounded fw-600", onClick: handleSave }, t('SAVE')))));
};
