import { getId } from './optionsReducer';
const getOptionsState = (state) => state.options;
// ////////////////////////////////////////
// Option groups
// ////////////////////////////////////////
export const getAllOptionGroups = (state) => getOptionsState(state).allGroups.groups;
export const getIsFetchAllOptionGroupsPending = (state) => getOptionsState(state).allGroups.pending;
export const didFetchAllOptionGroupsFail = (state) => getOptionsState(state).allGroups.failed;
// ////////////////////////////////////////
// Options
// ////////////////////////////////////////
export const getOptions = (state, optionGroupId, dependantIdValue) => {
    const optState = getOptionsState(state);
    const normalizedOptionGroupId = getId(optionGroupId, dependantIdValue);
    const optionIds = optState.byOptionGroupId[normalizedOptionGroupId] || [];
    return optionIds.map((oid) => optState.byOptionId[normalizedOptionGroupId][oid]);
};
export const getOption = (state, optionGroupId, value) => {
    const groupOptions = getOptionsState(state).byOptionId[optionGroupId];
    return groupOptions ? groupOptions[value] : null;
};
export const getIsGroupOptionsAvailable = (state, optionGroupId, dependantIdValue) => {
    const normalizedOptionGroupId = getId(optionGroupId, dependantIdValue);
    const groupStatus = getOptionsState(state).groupStatus[normalizedOptionGroupId];
    return groupStatus != null && (groupStatus.lastFetch != null || groupStatus.pending);
};
export const getIsFetchOptionGroupPending = (state, optionGroupId, dependantIdValue) => {
    const normalizedOptionGroupId = getId(optionGroupId, dependantIdValue);
    const groupStatus = getOptionsState(state).groupStatus[normalizedOptionGroupId];
    return groupStatus != null && groupStatus.pending;
};
export const didFetchOptionGroupFail = (state, optionGroupId, dependantIdValue) => {
    const normalizedOptionGroupId = getId(optionGroupId, dependantIdValue);
    const groupStatus = getOptionsState(state).groupStatus[normalizedOptionGroupId];
    return groupStatus != null && groupStatus.failed;
};
