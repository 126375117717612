import { generate } from '@ant-design/colors';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConfigProvider, Spin } from 'antd';
import deDE from 'antd/locale/de_DE';
import enUS from 'antd/locale/en_US';
import feFR from 'antd/locale/fr_FR';
import itIT from 'antd/locale/it_IT';
import nlNL from 'antd/locale/nl_NL';
import plPL from 'antd/locale/pl_PL';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { antTheme } from '../common/utils/antTheme';
import { theme } from '../common/utils/styledComponentsUtils';
import { useDisplayTenant } from '../tenant/useDisplayTenant';
const hex2rgb = (hex) => {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) {
        return normal
            .slice(1)
            .reduce((acc, cur) => {
            acc.push(parseInt(cur, 16));
            return acc;
        }, [])
            .join(',');
    }
    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) {
        return shorthand
            .slice(1)
            .reduce((a, e) => {
            a.push(0x11 * parseInt(e, 16));
            return a;
        }, [])
            .join(',');
    }
    return null;
};
const createCssVariables = (variables) => {
    if (!variables) {
        return '';
    }
    const themeColors = ['primary', 'neutral', 'info', 'warning', 'success', 'error'];
    let root = '';
    for (const themeColor of themeColors) {
        if (!variables[themeColor]) {
            continue;
        }
        const colorPalette = generate(variables[themeColor]);
        for (const [index, value] of colorPalette.entries()) {
            root += `--${themeColor}Color-${index + 1}: ${value};`;
            const rgb = hex2rgb(value);
            if (rgb) {
                root += `--${themeColor}ColorRGB-${index + 1}: ${rgb};`;
            }
        }
    }
    return root ? `:root{${root}}` : '';
};
Spin.setDefaultIndicator(React.createElement("div", { className: "position-absolute" },
    React.createElement(LoadingRectangles, null)));
const basicTheme = {
    ...antTheme,
    components: {
        Menu: {}
    }
};
const ThemeProvider = ({ children }) => {
    const { displayTenant } = useDisplayTenant();
    const { language } = useI18n();
    const updatedAntTheme = useMemo(() => {
        if (!displayTenant?.theme?.variables) {
            return basicTheme;
        }
        const themeToken = {
            ...antTheme.token,
            colorPrimary: displayTenant.theme.variables.primary || antTheme.token?.colorPrimary,
            colorInfo: displayTenant.theme.variables.info || antTheme.token?.colorInfo,
            colorSuccess: displayTenant.theme.variables.success || antTheme.token?.colorSuccess,
            colorWarning: displayTenant.theme.variables.warning || antTheme.token?.colorWarning,
            colorError: displayTenant.theme.variables.error || antTheme.token?.colorError,
            colorTextBase: displayTenant.theme.variables.neutral || antTheme.token?.colorTextBase
        };
        Object.keys(themeToken).forEach((key) => {
            if (!themeToken[key]) {
                delete themeToken[key];
            }
        });
        return { ...basicTheme, token: themeToken };
    }, [displayTenant?.theme?.variables]);
    const cssVariables = useMemo(() => createCssVariables(displayTenant?.theme?.variables), [displayTenant?.theme?.variables]);
    const locale = useMemo(() => {
        switch (language) {
            case 'de':
                return deDE;
            case 'en':
                return enUS;
            case 'nl':
                return nlNL;
            case 'fr':
                return feFR;
            case 'it':
                return itIT;
            case 'pl':
                return plPL;
        }
    }, [language]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null, !!cssVariables && (React.createElement("style", { type: "text/css", id: "theme-variables" }, cssVariables))),
        React.createElement(StyledComponentThemeProvider, { theme: theme },
            React.createElement(ConfigProvider, { locale: locale, theme: updatedAntTheme }, children))));
};
export default ThemeProvider;
