import { emptyToNull } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import styles from './ConditionalMessagesField.module.scss';
import MessageMappingsField from './MessageMappingsField';
import { messageMappingsFieldName, parametersSectionName } from './constants';
const messageMappingsName = 'messageMappings';
const messageMappingPropertyTypes = ['text', 'entity', 'select', 'number', 'checkbox'];
const isSupportedMappingProperty = ({ type }) => messageMappingPropertyTypes.includes(type);
const ConditionalMessagesField = ({ input: { value: messageMappingField, name }, changeFieldValue, disabled, definitionId }) => {
    const [showConditionalMessages, setShowConditionalMessages] = useState(!!messageMappingField);
    const { t } = useI18n();
    const parametersFieldPath = useMemo(() => `${name.split(`.${parametersSectionName}`)[0]}.${parametersSectionName}`, [name]);
    const toggleConditionalMessages = useCallback((e) => {
        if (showConditionalMessages) {
            // When the fields become hidden, we clear the fields
            changeFieldValue([parametersFieldPath, messageMappingsFieldName].join('.'), null);
            changeFieldValue([parametersFieldPath, messageMappingsName].join('.'), null);
        }
        setShowConditionalMessages((s) => !s);
        e.preventDefault();
    }, [showConditionalMessages, changeFieldValue, parametersFieldPath]);
    return (React.createElement("div", { className: `${styles.wrapper} mt-3` },
        React.createElement("a", { className: "mb-2 d-block", href: "", onClick: toggleConditionalMessages }, showConditionalMessages
            ? t('TASK_SMS_HIDE_MAPPING_OPTIONS')
            : t('TASK_SMS_SHOW_MAPPING_OPTIONS')),
        showConditionalMessages && (React.createElement("div", { className: "message-mapping-field-wrapper p-2" },
            React.createElement("div", { className: "flew-row flex-wrap" },
                React.createElement(FieldLabel, { label: t('TASK_SMS_MAPPING_PROPERTY'), className: "ps-0" }),
                React.createElement(Field, { className: "w-100", name: messageMappingsFieldName, placeholder: t('SELECT_PROPERTY'), component: SelectPropertyField, normalize: emptyToNull, definitionId: definitionId, includeProperty: isSupportedMappingProperty, disabled: disabled })),
            messageMappingField && (React.createElement("div", { className: "flew-row flex-wrap mt-3" },
                React.createElement(FieldLabel, { label: t('TASK_SMS_MAPPINGS'), className: "ps-0" }),
                React.createElement(FieldArray, { className: "w-100", name: messageMappingsName, component: MessageMappingsField, definitionId: definitionId, messageMappingField: messageMappingField, disabled: disabled })))))));
};
export default ConditionalMessagesField;
