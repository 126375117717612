import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Form } from 'formik';
import { isString } from 'lodash';
import React, { useCallback } from 'react';
import { Prompt } from 'react-router';
import { filterFeaturedDefinitionIds } from '../../../cms/data/utils';
import { Content } from '../../../common/utils/stylingUtils';
import { FormButtons } from '../common/components/FormButtons';
import { NotificationBlock } from '../common/components/NotificationBlock';
import { StyledSectionCard, styledSectionCardHeaderStyle } from '../common/stylingUtils';
import { AddFeaturedDefinitionId } from './components/AddFeaturedDefinitionId';
import { AddFeaturedDefinitionIdFolder } from './components/AddFeaturedDefinitionIdFolder';
import { FeaturedDefinitionIds } from './components/FeaturedDefinitionIds';
const CmsSettingsForm = ({ contentHeight, hasDataFetchFailed, isDataPending, cmsEntities, isSubmitting, dirty, values, setFieldValue, submitForm, resetForm }) => {
    const { t } = useI18n();
    const addFeaturedDefinitionId = useCallback((newFeaturedDefinitionId) => {
        void setFieldValue('featuredDefinitionIds', [
            ...(values.featuredDefinitionIds || []),
            newFeaturedDefinitionId
        ]);
    }, [setFieldValue, values]);
    const onDeleteFeaturedDefinitionId = useCallback((featuredDefinitionId) => {
        void setFieldValue('featuredDefinitionIds', filterFeaturedDefinitionIds(values.featuredDefinitionIds || [], featuredDefinitionId));
    }, [setFieldValue, values.featuredDefinitionIds]);
    const onChangeFeaturedDefinitionId = useCallback((idToUpdate, newTitles) => {
        void setFieldValue('featuredDefinitionIds', (values.featuredDefinitionIds || []).map((featuredDefinitionId) => {
            if (isString(featuredDefinitionId)) {
                return featuredDefinitionId;
            }
            if (featuredDefinitionId.id === idToUpdate) {
                return { ...featuredDefinitionId, titles: newTitles };
            }
            return featuredDefinitionId;
        }));
    }, [setFieldValue, values.featuredDefinitionIds]);
    const onSortFeaturedDefinitionIds = useCallback((featuredDefinitionIds) => {
        void setFieldValue('featuredDefinitionIds', featuredDefinitionIds);
    }, [setFieldValue]);
    return (React.createElement(StyledSectionCard, { title: t('FAVOURITES_SETTINGS'), secondaryTitle: !hasDataFetchFailed &&
            !isDataPending && (React.createElement(FormButtons, { dirty: dirty, onSubmit: submitForm, onReset: resetForm, submitting: isSubmitting, disabled: isSubmitting || isDataPending })), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true, loading: isDataPending },
        React.createElement(Content, { height: contentHeight }, hasDataFetchFailed ? (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })) : (React.createElement(Form, { onSubmit: submitForm, noValidate: true },
            React.createElement("div", { className: "p-3 h-100" },
                React.createElement("div", { className: "d-flex align-items-center gap-3 justify-content-end" },
                    React.createElement(AddFeaturedDefinitionId, { cmsEntities: cmsEntities, isDataPending: isDataPending, addFeaturedDefinitionId: addFeaturedDefinitionId, values: values }),
                    React.createElement(AddFeaturedDefinitionIdFolder, { isDataPending: isDataPending, addFeaturedDefinitionId: addFeaturedDefinitionId })),
                React.createElement("div", { className: "p-2" },
                    React.createElement(FeaturedDefinitionIds, { isDataPending: isDataPending, isSubmitting: isSubmitting, featuredDefinitionIds: values.featuredDefinitionIds, onDelete: onDeleteFeaturedDefinitionId, onChange: onChangeFeaturedDefinitionId, onSort: onSortFeaturedDefinitionIds }))),
            React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }))))));
};
export default CmsSettingsForm;
