import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tag } from 'antd';
import React, { useMemo } from 'react';
import { TITLE_PROPERTY_ID } from '../../../../cms';
import { useEntityDisplayData } from '../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../cms/common/utils/utils';
import { TASK_STATUS_DEFINITION_ID } from '../../../../cms/constants';
import { useEntityItems } from '../../../../cms/hooks/useEntityItems';
const tagStyle = { marginRight: '0', cursor: 'default' };
const statusDisplayMap = {
    SUCCEEDED: {
        translationKey: 'COMPLETED',
        color: 'var(--successColor-6)'
    },
    WAITING: {
        translationKey: 'AVAILABLE',
        color: 'var(--warningColor-6)'
    },
    RUNNING: {
        translationKey: 'ONGOING',
        color: 'var(--infoColor-6)'
    },
    FAILED: {
        translationKey: 'FAILED',
        color: 'var(--errorColor-6)'
    },
    CANCELLED: {
        translationKey: 'CANCELLED',
        color: 'var(--errorColor-6)'
    },
    UNKNOWN: {
        translationKey: 'UNKNOWN',
        color: 'var(--errorColor-6)'
    }
};
export const DisplayStatusTag = ({ className, status }) => {
    const { t, language } = useI18n();
    const { displayData: taskStatusDisplayData } = useEntityDisplayData(TASK_STATUS_DEFINITION_ID);
    const titleTaskStatusDisplayData = getPropertyById(taskStatusDisplayData, TITLE_PROPERTY_ID);
    const titleTaskStatusTranslationKey = titleTaskStatusDisplayData?.meta?.translations?.[language];
    const responseFieldsTaskStatus = useMemo(() => {
        const responseFields = [];
        if (titleTaskStatusTranslationKey) {
            responseFields.push(titleTaskStatusTranslationKey);
        }
        return responseFields;
    }, [titleTaskStatusTranslationKey]);
    const { data } = useEntityItems({
        definitionId: TASK_STATUS_DEFINITION_ID,
        ids: [status],
        extraPayloadProps: {
            responseFields: responseFieldsTaskStatus
        },
        enabled: !!titleTaskStatusTranslationKey
    });
    const statusDisplay = statusDisplayMap[status] || statusDisplayMap['UNKNOWN'];
    const dataTaskStatus = useMemo(() => {
        return data?.find((item) => item.entityId === status);
    }, [data, status]);
    return (React.createElement(Tag, { className: className, style: tagStyle, color: statusDisplay.color }, (titleTaskStatusTranslationKey && dataTaskStatus?.[titleTaskStatusTranslationKey]) ||
        t(statusDisplay.translationKey)));
};
