import { cloneDeep, findIndex } from 'lodash';
import queryClient from '../utils/react-query-client';
export function findCacheIndex(data, activity) {
    for (let pageIndex = 0; pageIndex < data.pages.length; pageIndex++) {
        const page = data.pages[pageIndex];
        const itemIndex = findIndex(page.results, {
            entityId: activity.entityId
        });
        if (itemIndex >= 0) {
            return { pageIndex: pageIndex, itemIndex: itemIndex };
        }
    }
    return { pageIndex: -1, itemIndex: -1 };
}
export function deleteFromCache(cacheKey, activity) {
    queryClient.setQueryData(cacheKey, (prevValue) => {
        if (!prevValue) {
            return prevValue;
        }
        const index = findCacheIndex(prevValue, activity);
        if (index.pageIndex === -1) {
            return prevValue;
        }
        const newValue = cloneDeep(prevValue);
        newValue.pages[index.pageIndex].results.splice(index.itemIndex, 1);
        return newValue;
    });
}
export function updateCache(cacheKey, activity) {
    queryClient.setQueryData(cacheKey, (prevValue) => {
        if (!prevValue) {
            return prevValue;
        }
        const index = findCacheIndex(prevValue, activity);
        if (index.pageIndex === -1) {
            return prevValue;
        }
        const newValue = cloneDeep(prevValue);
        newValue.pages[index.pageIndex].results[index.itemIndex] = {
            ...newValue.pages[index.pageIndex].results[index.itemIndex],
            ...activity
        };
        return newValue;
    });
}
export function addToCache(cacheKey, activity) {
    queryClient.setQueryData(cacheKey, (prevValue) => {
        if (!prevValue) {
            return {
                pageParams: [undefined],
                pages: [
                    {
                        results: [activity],
                        totalCount: 1
                    }
                ]
            };
        }
        const newValue = cloneDeep(prevValue);
        newValue.pages[0].results = [activity].concat(newValue.pages[0].results);
        newValue.pages[0].totalCount = newValue.pages[0].totalCount + 1;
        return newValue;
    });
}
