import React from 'react';
import { MdAccountCircle } from 'react-icons/md';
import FullScreenPreview from '../../../../common/components/full-screen-preview';
import styles from './TemplatePreview.module.scss';
const TemplatePreview = ({ compiledHtml, subject, closePreview }) => (React.createElement(FullScreenPreview, { className: styles.preview, closePreview: closePreview },
    React.createElement("div", { className: "desktop-preview cp-i-60" },
        React.createElement("div", { className: "desktop-preview__header cp-c-row" },
            React.createElement(MdAccountCircle, { className: "user-icon cp-i-none" }),
            React.createElement("div", { className: "cp-i-flex cp-c-column cp-c-padding-h-1" },
                React.createElement("div", { className: "placeholders" },
                    React.createElement("div", { className: "placeholder placeholder__first" }),
                    React.createElement("div", { className: "placeholder placeholder__second" })),
                React.createElement("div", null, subject))),
        React.createElement("iframe", { className: "preview-frame preview-frame__desktop", srcDoc: compiledHtml })),
    React.createElement("div", { className: "mobile-preview cp-i-none" },
        React.createElement("div", { className: "mobile-preview__screen" },
            React.createElement("img", { src: "/assets/images/mobile-template-preview-top-bar.png", alt: "Top bar preview" }),
            React.createElement("iframe", { className: "preview-frame preview-frame__mobile", srcDoc: compiledHtml, height: "459px" }),
            React.createElement("img", { className: "mobile-preview__footer-img", src: "/assets/images/mobile-template-preview-bottom-bar.png", alt: "Bottom bar preview" })))));
export default TemplatePreview;
