import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import AceEditor from 'react-ace';
import * as Yup from 'yup';
import MultiTextEditor from '../../../../../cms/data/components/item-property/editors/MultiTextEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import styles from './GeneralOptionsForm.module.scss';
const stageSchema = Yup.object({
    hosts: Yup.array().of(Yup.string())
});
export const getGeneralFormSchema = () => {
    return {
        customHosts: Yup.array().of(Yup.string()),
        live: stageSchema,
        test: stageSchema,
        snippet: Yup.string(),
        snippetHead: Yup.string()
    };
};
const GeneralOptionsForm = ({ configuration, disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `row ${styles.generalOptionsForm}` },
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("span", null,
                t('APPS_HYPER_LANDING__LIVE'),
                ":"),
            React.createElement(FormikField, { name: "configuration.live.hosts", component: MultiTextEditor, value: configuration.live.hosts, disabled: disabled })),
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("span", null,
                t('APPS_HYPER_LANDING__TEST'),
                ":"),
            React.createElement(FormikField, { name: "configuration.test.hosts", component: MultiTextEditor, value: configuration.test.hosts, disabled: disabled })),
        React.createElement("div", { className: "mt-3" },
            React.createElement("span", null,
                t('APPS_HYPER_LANDING__CUSTOM_HOSTS'),
                ": "),
            React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, value: configuration.customHosts, disabled: disabled })),
        React.createElement("div", { className: "mt-3" },
            React.createElement("span", null,
                `${t('APPS_HYPER_LANDING__SNIPPET')} ${t('APPS_HYPER_LANDING__HEAD')}`,
                ": "),
            React.createElement(FormikField, { name: "configuration.snippetHead", component: AceEditor, mode: "html", theme: "textmate", fontSize: 14, showPrintMargin: true, showGutter: true, highlightActiveLine: true, className: "snippet-editing" })),
        React.createElement("div", { className: "mt-3" },
            React.createElement("span", null,
                `${t('APPS_HYPER_LANDING__SNIPPET')} ${t('APPS_HYPER_LANDING__BODY')}`,
                ": "),
            React.createElement(FormikField, { name: "configuration.snippet", component: AceEditor, mode: "html", theme: "textmate", fontSize: 14, showPrintMargin: true, showGutter: true, highlightActiveLine: true, className: "snippet-editing" }))));
};
export default GeneralOptionsForm;
