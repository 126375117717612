const getTenantState = (s) => s.tenant;
export const getTenant = (s) => getTenantState(s).tenant;
export const getTenantStatus = (s) => {
    const tenantState = getTenantState(s);
    return {
        isAvailable: !!tenantState.tenant,
        isPending: tenantState.tenantStatus.pending,
        isFailed: tenantState.tenantStatus.failed
    };
};
