import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Badge, Tooltip } from 'antd';
import { INITIAL_PAGE_SIZE } from 'config';
import React, { useCallback, useEffect, useState } from 'react';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '../../components/Drawer';
import SessionSubscription from '../../session/components/SessionSubscription';
import { fetchUserNotifications } from '../actions';
import { getUserNotificationsListStatus } from '../selectors';
import styles from './NotificationsButton.module.scss';
import NotificationsContainer from './NotificationsContainer';
const unreadNotificationsParams = {
    showDeleted: false,
    showOpened: false
};
const NotificationsButton = ({ className, featuredDefinitionIds }) => {
    const { t } = useI18n();
    const [showDrawer, setShowDrawer] = useState(false);
    const dispatchList = useDispatch();
    const { totalCount } = useSelector((s) => getUserNotificationsListStatus(s, unreadNotificationsParams));
    const fetchNotifications = useCallback(async (page, pageSize, params) => {
        return dispatchList(fetchUserNotifications(page, pageSize, params));
    }, [dispatchList]);
    const fetchUnreadNotifications = useCallback(async () => {
        return fetchNotifications(1, INITIAL_PAGE_SIZE, unreadNotificationsParams);
    }, [fetchNotifications]);
    const fetchListNotifications = useCallback(async (page, showOpened) => {
        return fetchNotifications(page, INITIAL_PAGE_SIZE, {
            showDeleted: false,
            showOpened
        });
    }, [fetchNotifications]);
    const toggleShowDrawer = useCallback(() => {
        setShowDrawer(!showDrawer);
    }, [showDrawer]);
    useEffect(() => {
        void fetchUnreadNotifications();
    }, [fetchUnreadNotifications]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SessionSubscription, { subscriptionKey: `notifications` }),
        React.createElement(Drawer, { onClose: toggleShowDrawer, open: showDrawer, destroyOnClose: true, className: styles.notificationsDrawer },
            React.createElement(NotificationsContainer, { onClose: toggleShowDrawer, unreadNotificationsCount: totalCount, fetchListNotifications: fetchListNotifications, featuredDefinitionIds: featuredDefinitionIds, className: styles.drawerContent })),
        React.createElement(Tooltip, { placement: "bottom", title: t('NOTIFICATIONS') },
            React.createElement("div", { className: `${className || ''} ${styles.iconContainer} safe-space`, onClick: toggleShowDrawer },
                React.createElement(Badge, { count: totalCount, "data-test-id": "button-header-notifications" },
                    React.createElement(NotificationsIcon, { className: "headerNotificationIcon" }))))));
};
const NotificationsIcon = ({ className }) => {
    return React.createElement(IoMdNotificationsOutline, { size: 20, className: `${className || ''}` });
};
export default NotificationsButton;
