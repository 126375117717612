import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { Button } from 'antd';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Prompt } from 'react-router';
import styled from 'styled-components';
import { AceEditor, AceEditorMode } from '../../../common/components/AceEditor';
import FormikError from '../../../common/components/formik/FormikError';
import FormikField from '../../../common/components/formik/FormikField';
import { FORM_PATH } from '../../constants';
const EditForm = ({ form, loading, save }) => {
    const { t } = useI18n();
    const onSubmit = useCallback(async (form) => {
        save && (await save(JSON.parse(form.form)));
    }, [save]);
    const initialValues = useMemo(() => {
        return { form: JSON.stringify(form, null, 2) };
    }, [form]);
    const onPreview = useCallback(() => {
        const url = `${FORM_PATH}/${form.id}`;
        window.open(url, '_blank')?.focus();
    }, [form]);
    return (React.createElement(Formik, { validate: (values) => {
            try {
                JSON.parse(values.form);
            }
            catch (error) {
                // @ts-expect-error
                return { form: error.message };
            }
        }, initialValues: initialValues, onSubmit: onSubmit, validateOnBlur: true, validateOnChange: false }, ({ handleSubmit, isSubmitting, dirty }) => {
        return (React.createElement("form", { noValidate: true },
            React.createElement(ActionsContainer, { className: "d-flex justify-content-end align-items-center gap-2" },
                React.createElement(Button, { type: "text", disabled: loading, loading: isSubmitting, onClick: onPreview }, t('PREVIEW')),
                React.createElement(Button, { onClick: () => handleSubmit(), loading: isSubmitting, disabled: loading, type: "primary" }, t('FORM__SAVE'))),
            React.createElement(FormikError, { name: 'form' }),
            React.createElement(FormikField, { name: "form", component: AceEditor, disabled: loading, mode: AceEditorMode.json }),
            React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') })));
    }));
};
const ActionsContainer = styled.div `
  margin-bottom: 1rem;
`;
export default EditForm;
