import { maxLength, required } from '@hypercharge/hyper-react-base/lib/form';
import { t } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push as routerPush } from '@hypercharge/hyper-react-base/lib/router';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils/idGenerator';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import flatten from 'flat';
import { isEmpty, map, pickBy, reduce } from 'lodash';
import { SubmissionError, touch } from 'redux-form';
import { invalidateItemsQuery } from '../../../../cms/hooks/invalidateCache';
import { invalidateDisplayItemMetaListCache } from '../../../../cms/hooks/useDisplayItemMetaList';
import { invalidateProcessMetaListCache } from '../../../../cms/hooks/useDisplayProcessMetaList';
import { SETTINGS_PATH } from '../../../../common/utils/constants';
import { prepareGroupPermissionsForSubmit } from '../../../../common/utils/formUtils';
import { validateGroups } from '../../../../groups';
import { VIEW_DEFINITION_ID } from '../../../../views';
import { PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import { createProcessMeta, saveProcessMeta } from '../../actions';
export const formName = 'processMeta';
export const onProcessMetaFormSubmit = async (formValues, dispatch, { isNew, permissionGroupFieldNames }) => {
    const promiseDispatch = dispatch;
    const errors = {
        ...validateLabels(formValues),
        ...validatePrefixId(formValues),
        ...validateGroups(permissionGroupFieldNames, formValues)
    };
    if (!isEmpty(errors)) {
        dispatch(touch(formName, ...map(flatten(errors), (_, key) => key)));
        throw new SubmissionError(errors);
    }
    const postProcessedFormValues = prepareGroupPermissionsForSubmit(formValues);
    let newValues = postProcessedFormValues;
    if (isNew) {
        const newId = generateId();
        newValues = {
            ...postProcessedFormValues,
            id: newId
        };
    }
    try {
        const { id } = await promiseDispatch(isNew ? createProcessMeta(newValues) : saveProcessMeta(postProcessedFormValues));
        invalidateProcessMetaListCache(); // to reflect new processes or updated names / permissions in the sidebar
        await invalidateDisplayItemMetaListCache(); // to update potential title changes to the entity property selector components
        await invalidateItemsQuery({ definitionId: VIEW_DEFINITION_ID, waitNewData: true }); // A view is created for that workflow in the backend, so we have to re-fetch the list of views
        if (isNew) {
            dispatch(routerPush(`${WORKFLOWS_PATH}${PROCESSES_PATH}/${id}${SETTINGS_PATH}`));
        }
        dispatch(success({
            title: t('SUCCESS'),
            message: t('SAVE_SETTINGS_SUCCESS')
        }));
    }
    catch (_) {
        dispatch(error({
            title: t('ERROR'),
            message: t('SAVE_SETTINGS_FAIL')
        }));
    }
};
const validateLabels = (formValues) => pickBy(reduce(AVAILABLE_LANGUAGES, (acc, language) => {
    const value = formValues?.titles?.[language];
    const requiredMsg = language === DEFAULT_LANGUAGE ? required(value) : undefined;
    if (!requiredMsg) {
        return acc;
    }
    else {
        return {
            ...acc,
            titles: {
                ...acc.titles,
                [language]: requiredMsg
            }
        };
    }
}, {}));
const MAX_ID_PREFIX_LENGTH = 5;
const getMaxLengthMsg = maxLength(MAX_ID_PREFIX_LENGTH);
const validatePrefixId = (formValues) => {
    const value = formValues.idPrefix;
    const maxLengthMsg = getMaxLengthMsg(value, {});
    const requiredMsg = required(value);
    return maxLengthMsg || requiredMsg ? { idPrefix: maxLengthMsg || requiredMsg } : {};
};
