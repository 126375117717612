import { getCurrentLanguage, i18nChangeLanguage, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AVAILABLE_LANGUAGES } from 'config';
import React from 'react';
import { MdLanguage } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getI18nState } from '../../../../common/reducers/rootSelector';
import styles from './LanguageSelector.module.scss';
const LanguageSelector = ({ icon, desktopHeader }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const currentLanguage = useSelector((s) => getCurrentLanguage(getI18nState(s)));
    const onLanguageChange = (selectedLanguage) => {
        dispatch(i18nChangeLanguage(selectedLanguage));
    };
    return AVAILABLE_LANGUAGES.length > 1 ? (React.createElement("form", { className: "py-3" },
        React.createElement("label", { className: `${styles.label} d-flex align-items-center justify-content-center` },
            icon && React.createElement(MdLanguage, { className: `${styles.globeIcon} cp-i-none` }),
            React.createElement("div", { className: `${styles.selectContainer} cp-i-none` },
                React.createElement("select", { className: styles.select, style: desktopHeader ? { fontSize: '1rem' } : undefined, value: currentLanguage, onChange: ({ target }) => onLanguageChange(target.value), "data-test-id": "mobile-menu-user-dropdown-languages" }, AVAILABLE_LANGUAGES.map((language) => (React.createElement("option", { key: language, value: language, "data-test-id": `mobile-menu-user-dropdown-language-${language}` }, t(language))))))))) : (React.createElement("div", null));
};
export default LanguageSelector;
