import { flatten, isArray } from 'lodash';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
export const isObject = (obj) => obj != null && obj.constructor.name === 'Object';
export const getStrings = (item) => {
    if (typeof item === 'string') {
        return [item];
    }
    if (isArray(item)) {
        return flatten(item.map(getStrings));
    }
    return [];
};
export const isPermissionToggleDisabled = (permission, groupId, rowPermissions) => {
    if (!rowPermissions) {
        return false;
    }
    if (permission === AvailableSystemGroups.viewGroups &&
        rowPermissions.view?.[groupId]?.restrictions?.filters?.length) {
        return true;
    }
    else if (permission === AvailableSystemGroups.executeGroups &&
        rowPermissions.write?.[groupId]?.restrictions?.filters?.length) {
        return true;
    }
    return false;
};
