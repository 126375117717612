import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
export const IconWrapper = styled.div `
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  border-radius: 7px;
  border: 1px solid ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon) `
  ${({ color }) => color && `color: ${color}`};
`;
