import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio } from 'antd';
import React, { useCallback, useMemo } from 'react';
import styles from './UpdateMethodInput.module.scss';
export var UpdateMethods;
(function (UpdateMethods) {
    UpdateMethods["REPLACE"] = "replace";
    UpdateMethods["ADD"] = "add";
})(UpdateMethods || (UpdateMethods = {}));
const UpdateMethodInput = ({ value, onChange }) => {
    const { t } = useI18n();
    const updateMethod = useMemo(() => value || UpdateMethods.REPLACE, [value]);
    const handleChange = useCallback((e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    }, [onChange]);
    return (React.createElement("div", { className: `${styles.outer} UpdateMethodInputOuter` },
        React.createElement("span", { className: "label" },
            t('UPDATE_METHOD_INPUT_LABEL'),
            ":"),
        React.createElement(Radio.Group, { value: updateMethod, size: "small", onChange: handleChange },
            React.createElement(Radio.Button, { value: UpdateMethods.REPLACE }, t('UPDATE_METHOD_INPUT_REPLACE')),
            React.createElement(Radio.Button, { value: UpdateMethods.ADD }, t('UPDATE_METHOD_INPUT_ADD')))));
};
export default UpdateMethodInput;
