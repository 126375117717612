import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import { flatten, isArray } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { MdUndo } from 'react-icons/md';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { ContactDisplayName } from '../../../../../crm';
import { useDisplayTenant } from '../../../../../tenant/useDisplayTenant';
import { getPropertyTypeComponent } from '../../../../../workflows/meta/components/meta-definition-form/utils';
import useDisplayData from '../../../../hooks/useDisplayData';
import { invalidateHistoryDataQuery, useHistoryData } from '../../../../hooks/useHistoryData';
import { useUpdateProperty } from '../../../../hooks/useUpdateProperty';
import { getDisplayComponentForType } from '../utils';
import styles from './DataHistoryList.module.scss';
import { readableDateTime } from './utils';
export const DataHistoryList = ({ definitionId, entityId, propertyId, onSave }) => {
    const { t } = useI18n();
    const { tenantTheme: { hidePropertyIcon } } = useDisplayTenant();
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useHistoryData({
        definitionId,
        entityId,
        propertyId
    });
    const propertyValues = useMemo(() => {
        return flatten(data?.pages || []);
    }, [data]);
    const { data: displayDataList } = useDisplayData({ definitionId, entityId });
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const propertiesCanEditMap = useMemo(() => {
        if (displayDataList?.data) {
            return displayDataList.data.reduce((acc, section) => {
                section.values.forEach((displayData) => {
                    acc[displayData.propertyId] = displayData.canEdit;
                });
                return acc;
            }, {});
        }
        return {};
    }, [displayDataList?.data]);
    const latestUpdates = useMemo(() => {
        const list = {};
        propertyValues.forEach((value) => {
            const propertyId = value.propertyId;
            const assignedAt = value.values[0].assignedAt;
            list[propertyId] = list[propertyId] || assignedAt;
            if (list[propertyId] < assignedAt) {
                list[propertyId] = assignedAt;
            }
        });
        return list;
    }, [propertyValues]);
    const valuesGroups = useMemo(() => {
        const groups = {};
        propertyValues.forEach((value) => {
            const time = readableDateTime(value.values[0].assignedAt);
            groups[time] = groups[time] || [];
            groups[time].push(value);
        });
        return groups;
    }, [propertyValues]);
    const restoreValue = useCallback(async (item) => {
        try {
            if (onSave) {
                await onSave(item.propertyId, item.values[0].value);
            }
            else {
                await updateProperty({
                    entityId,
                    propertyId: item.propertyId,
                    value: item.values[0].value
                });
            }
            await invalidateHistoryDataQuery({ definitionId, entityIds: [entityId] });
        }
        catch (e) {
            console.error(e);
        }
    }, [onSave, definitionId, entityId, updateProperty]);
    const getValueDisplayJsx = useCallback((type, value, meta) => {
        if (value === null || value === '' || (isArray(value) && value.length === 0)) {
            return React.createElement("span", { className: "empty-value" }, `(${t('HISTORY_DELETED_VALUE')})`);
        }
        const DisplayComp = getDisplayComponentForType(type);
        return React.createElement(DisplayComp, { value: value, meta: meta, asField: true });
    }, [t]);
    const handleLoadMore = useCallback(() => {
        void fetchNextPage();
    }, [fetchNextPage]);
    return !isLoading && !propertyValues.length ? (React.createElement(DrawerContentBlock, null, t('NO_VALUE_HISTORY'))) : (React.createElement(React.Fragment, null,
        Object.entries(valuesGroups).map(([date, valuesOfGroup]) => (React.createElement(DrawerContentBlock, { key: date, title: date }, valuesOfGroup.map((value) => {
            const Icon = getPropertyTypeComponent(value.type);
            const propertyId = value.propertyId;
            const assignedAt = value.values[0].assignedAt;
            const isCurrentValue = latestUpdates[propertyId] === assignedAt;
            return (React.createElement("div", { className: styles.valueItem, key: `${propertyId}_${assignedAt}` },
                React.createElement("div", { className: styles.valueItem__heading },
                    React.createElement("div", { className: styles.valueItem__headingField },
                        !hidePropertyIcon && Icon ? React.createElement(Icon, null) : null,
                        React.createElement(TooltipTruncate, { className: styles.valueItem__headingFieldLabel }, value.label),
                        React.createElement(TooltipTruncate, { className: styles.valueItem__headingFieldPropertyId }, propertyId)),
                    React.createElement("div", { className: styles.valueItem__headingDate },
                        React.createElement("span", { className: styles.valueItem__headingTime }, moment(assignedAt).format('HH:mm')),
                        propertiesCanEditMap[propertyId] && !isCurrentValue && (React.createElement(Tooltip, { title: t('RESTORE_HISTORY_VALUE'), placement: "topRight" },
                            React.createElement("div", { className: `${styles.valueItem__headingUndo} safe-space` },
                                React.createElement(MdUndo, { className: styles.resetHistoryButton, onClick: () => {
                                        void restoreValue(value);
                                    } })))),
                        isCurrentValue && (React.createElement(Tooltip, { title: t('CURRENT_HISTORY_VALUE'), placement: "topRight" },
                            React.createElement("div", { className: styles.valueItem__headingCurrent },
                                React.createElement("div", { className: styles.currentHistoryButton })))))),
                React.createElement("div", { className: styles.valueItem__author },
                    React.createElement(ContactDisplayName, { contactId: value.values[0].assignedBy })),
                React.createElement("div", { className: styles.valueItem__value }, getValueDisplayJsx(value.type, value.values[0].value, value.meta))));
        })))),
        hasNextPage && (React.createElement(DrawerContentBlock, null,
            React.createElement("div", { className: cn(styles.loadMore, 'd-flex justify-content-center align-items-center') },
                React.createElement(Button, { loading: isFetchingNextPage, onClick: handleLoadMore }, t('HISTORY_LOAD_MORE')))))));
};
