import React from 'react';
import { PLUGIN_THUMBNAILS, createModule, useLightboxState } from 'yet-another-react-lightbox';
import styles from './FileLightBox.module.scss';
function CounterComponent({ children }) {
    const { slides, currentIndex } = useLightboxState();
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement("div", { className: styles.counter },
            currentIndex + 1,
            " / ",
            slides.length)));
}
export default function Counter({ addParent }) {
    addParent(PLUGIN_THUMBNAILS, createModule('counter', CounterComponent));
}
