import DynamicImport from '@hypercharge/hyper-react-base/lib/common/dynamic-import';
import { email, required } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Collapse } from 'antd';
import produce from 'immer';
import { compact, flatMap, get, isEmpty, map, reduce, without } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DrawerContent, DrawerFooter } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../common/components/Drawer/DrawerFormFooter';
import Toggle from '../../../../../common/components/Toggle';
import Message from '../../../../../common/conversation/components/Message/Message';
import { FileField } from '../../../../../common/storage';
import RequiredFieldInfoPopover from '../../../../../workflows/processes/components/process/RequiredFieldInfoPopover';
import useAddItem from '../../../hooks/useAddItem';
import { isPropertyItemMeta } from '../../../types';
import { FieldLabel } from '../../item-property/FieldElements';
import { getEditorComponentForType, getFilePropertyValueList } from '../../item-property/utils';
import styles from './QuickAddItem.module.scss';
import { getFilterRequestWithContextFromComputedFilters } from './utils';
const FieldLabelWrapper = ({ field, language, aggregatedRequiredFieldsConfig }) => {
    const unitsTranslation = getTranslation(get(field, 'meta.units'), language);
    return (React.createElement(React.Fragment, null,
        getTranslation(field.labels, language),
        unitsTranslation && ` (${unitsTranslation})`,
        aggregatedRequiredFieldsConfig.isRequiredField(field.propertyId) && (React.createElement(RequiredFieldInfoPopover, { propertyId: field.propertyId, requiredFieldsConfig: aggregatedRequiredFieldsConfig }))));
};
const QuickAddItem = ({ onCancel, requiredFieldsConfig, isValidPhoneNumber, definitionId, clonedItemEntityId, onFinish }) => {
    const { t, language } = useI18n();
    const { saveComment, createItem, initialItem, propertySections, getFileData, uploadFiles, accountId, displayDataStatus } = useAddItem({ definitionId, clonedItemEntityId });
    const [newItem, setNewItem] = useState({
        entityId: generateId(),
        ...initialItem
    });
    const [comment, setComment] = useState({
        content: '',
        mentions: []
    });
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [onlyRequiredFields, setOnlyRequiredFields] = useState(false);
    const firstErrorPropertyId = useMemo(() => reduce(map(compact(flatMap(propertySections, 'values')), 'propertyId'), (res, propertyId) => (res == null && errors[propertyId] ? propertyId : res), null), [errors, propertySections]);
    const hasRequiredFieldsByCmsSettings = useMemo(() => reduce(flatMap(propertySections, 'values'), (res, field) => get(field, 'meta.validations.required', false) || res, false), [propertySections]);
    const hasRequiredFields = hasRequiredFieldsByCmsSettings || get(requiredFieldsConfig, 'hasRequiredFields', false);
    const validate = useCallback(() => {
        const newErrors = {};
        propertySections.forEach(({ values }) => {
            values.forEach(({ type, propertyId, meta }) => {
                const propertyValue = get(newItem, propertyId);
                const isRequiredByCmsSettings = get(meta, 'validations.required', false);
                const isRequiredProperty = isRequiredByCmsSettings ||
                    (requiredFieldsConfig != null && requiredFieldsConfig.isRequiredField(propertyId));
                if (isRequiredProperty) {
                    const requiredMsg = required(propertyValue);
                    if (requiredMsg) {
                        newErrors[propertyId] = requiredMsg;
                    }
                }
                // Would be good to have a more generic way of doing this
                let error = null;
                if (type == 'email') {
                    error = email(propertyValue);
                }
                else if (type == 'phoneNumber' && propertyValue) {
                    error =
                        (!isValidPhoneNumber(propertyValue) && t('VALIDATIONS__INVALID_PHONE_NUMBER')) || null;
                }
                if (error) {
                    newErrors[propertyId] = error;
                }
            });
        });
        return newErrors;
    }, [newItem, propertySections, requiredFieldsConfig, isValidPhoneNumber, t]);
    const updateComment = useCallback((content, mentions) => setComment({ content, mentions }), []);
    const handleSubmit = useCallback((e) => {
        e?.preventDefault();
        const errors = validate();
        if (!isEmpty(errors)) {
            setSaving(false);
            setErrors(errors);
        }
        else {
            setSaving(true);
            setErrors({});
            createItem(newItem).then((returnedItem) => {
                const { content, mentions } = comment;
                if (content) {
                    saveComment(returnedItem.entityId, content, mentions, accountId).finally(() => {
                        onFinish(returnedItem.entityId);
                    });
                }
                else {
                    onFinish(returnedItem.entityId);
                }
            }, (e) => {
                setSaving(false);
                setErrors(e.response.data);
            });
        }
    }, [validate, createItem, newItem, comment, saveComment, accountId, onFinish]);
    const updatePropertyData = useCallback((propertyId, value) => setNewItem(produce(newItem, (draft) => {
        draft[propertyId] = value;
    })), [newItem]);
    const getSectionTitle = useCallback((titles, isFirstSection) => {
        const title = getTranslation(titles, language);
        if (!title && isFirstSection) {
            return null;
        }
        return title;
    }, [language]);
    const checkKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit]);
    const getAggregatedRequiredFieldsConfig = useCallback((field) => ({
        // This combines the 'required' information of the CMS entity property settings with the passed requiredFieldsConfig argument, that comes from the process page
        isRequiredField: (propertyId) => get(field, 'meta.validations.required', false) ||
            (requiredFieldsConfig != null && requiredFieldsConfig.isRequiredField(propertyId)),
        isErrorField: (propertyId) => !!errors[propertyId],
        isSuccessField: (propertyId) => newItem[propertyId] && !errors[propertyId]
    }), [errors, requiredFieldsConfig, newItem]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, { className: styles.container, loading: displayDataStatus.isPending && isEmpty(propertySections) },
            React.createElement(DrawerContentBlock, null,
                React.createElement(Toggle, { checked: onlyRequiredFields, onChange: (value) => setOnlyRequiredFields(value), disabled: !hasRequiredFields, label: t('CMS_ONLY_SHOW_REQUIRED') })),
            propertySections.map(({ sectionId, titles, values }, index) => {
                const filteredValues = hasRequiredFields && onlyRequiredFields
                    ? values.filter((field) => {
                        const aggregatedRequiredFieldsConfig = getAggregatedRequiredFieldsConfig(field);
                        return aggregatedRequiredFieldsConfig.isRequiredField(field.propertyId);
                    })
                    : values;
                const sectionTitle = getSectionTitle(titles, index === 0);
                if (isEmpty(filteredValues)) {
                    return null;
                }
                return (React.createElement(DrawerContentBlock, { key: sectionId, title: sectionTitle }, filteredValues.map((field, propertyIndex) => {
                    const { propertyId, type, meta } = field;
                    const EditorComponent = getEditorComponentForType(type);
                    const isTouchDevice = 'ontouchstart' in document.documentElement;
                    const errorMessage = errors[propertyId];
                    return (React.createElement(PropertyContainerComponent, { key: propertyId, needsScroll: propertyId === firstErrorPropertyId }, type === 'file' ? (React.createElement(FileField, { label: React.createElement(FieldLabelWrapper, { field: field, language: language, aggregatedRequiredFieldsConfig: getAggregatedRequiredFieldsConfig(field) }), files: compact(getFilePropertyValueList(newItem[propertyId]).map(getFileData)), meta: meta, error: !!errorMessage, canEdit: !saving, displayActions: true, loading: false, onSave: (files) => uploadFiles(newItem.entityId, propertyId, files).then((data) => {
                            updatePropertyData(propertyId, [
                                ...((meta.list && get(newItem, propertyId, [])) || []),
                                ...map(data, 'id')
                            ]);
                        }), onRemove: (id) => {
                            const filteredFileIds = without(get(newItem, propertyId, []), id);
                            updatePropertyData(propertyId, isEmpty(filteredFileIds) ? null : filteredFileIds);
                            return Promise.resolve();
                        }, onChange: (files) => {
                            updatePropertyData(propertyId, map(files, 'id'));
                        } })) : (React.createElement(React.Fragment, null,
                        React.createElement(FieldLabel, { label: React.createElement(FieldLabelWrapper, { field: field, language: language, aggregatedRequiredFieldsConfig: getAggregatedRequiredFieldsConfig(field) }) }),
                        React.createElement(EditorComponent, { onChange: (value) => updatePropertyData(propertyId, value), value: get(newItem, propertyId), meta: meta, disabled: saving, allowClear: true, inlineMode: true, editing: false, filterRequest: isPropertyItemMeta(meta)
                                ? getFilterRequestWithContextFromComputedFilters(meta?.selector?.filtering?.filterRequest, newItem, definitionId)
                                : undefined, entityId: newItem.entityId, autoFocus: index === 0 && propertyIndex === 0 && !['entity', 'date'].includes(type), isTouchDevice: isTouchDevice, onKeyDown: checkKeyDown, errorMessage: errorMessage, ...(type === 'entity' ? { ignoreOnEnterKeyDown: true } : {}) })))));
                })));
            }),
            React.createElement(DrawerContentBlock, null,
                React.createElement(Collapse, { className: styles.collapse, bordered: false, defaultActiveKey: ['COMMENTS'], items: [
                        {
                            key: 'COMMENTS',
                            label: React.createElement("div", { className: "panel-header-title" }, t('COMMENTS')),
                            children: (React.createElement(Message, { contactId: accountId, disabled: saving, editable: true, placeholder: t('COMMENTS_PLACEHOLDER'), collapsable: true, mainInput: true, onChange: updateComment, hideFooter: true }))
                        }
                    ] }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onSubmit: handleSubmit, onCancel: onCancel, submitting: saving }))));
};
const QuickAddItemWrapper = (props) => (
// @ts-expect-error
React.createElement(DynamicImport, { load: () => import('react-phone-number-input/min'), loadAllExports: true }, (component) => React.createElement(QuickAddItem, { ...props, isValidPhoneNumber: component.isValidPhoneNumber })));
export default QuickAddItemWrapper;
export const PropertyContainerComponent = ({ needsScroll, children }) => {
    const containerRef = useRef(null);
    useLayoutEffect(() => {
        if (needsScroll) {
            containerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [needsScroll]);
    return (React.createElement("div", { className: styles.propertyContainer, ref: containerRef }, children));
};
