import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { LabelText, MultitextInput } from '@hypercharge/hyper-react-base/lib/form';
import { getCurrentLanguage, getTranslation, i18nChangeAvailableLanguages, i18nChangeLanguage, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { SUPPORTED_LANGUAGES } from '@hypercharge/portal-utils';
import { Input } from 'antd';
import Config from 'config';
import { Formik } from 'formik';
import { isEmpty, isEqual, pick, pickBy, set } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { Select } from '../../../common/components/Select';
import Toggle from '../../../common/components/Toggle';
import FormikField from '../../../common/components/formik/FormikField';
import { PropertyTypes } from '../../../common/types';
import { Content } from '../../../common/utils/stylingUtils';
import { CONTACT_CMS_DEFINITION_ID } from '../../../crm';
import { getTenant, getTenantStatus, updateTenant } from '../../../tenant';
import { SETTINGS_PATH } from '../../constants';
import { FormButtons } from '../common/components/FormButtons';
import { NotificationBlock } from '../common/components/NotificationBlock';
import { styledSectionCardHeaderStyle } from '../common/stylingUtils';
import styles from './TenantSettingsFormContainer.module.scss';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { HyperFormThemeForm } from './components/HyperFormThemeForm';
import { TenantSettingUploadFileField } from './components/TenantCompanyLogoField';
import { TenantLoginSettings } from './components/TenantLoginSettings';
import { disabledTenantSettingsFormFields, editableTenantSettingsFormFields, tenantSettingsFormFields } from './utils';
const supportLanguageOptions = SUPPORTED_LANGUAGES.map((language) => ({
    label: language,
    value: language
}));
const TenantSettingsFormContainer = ({ setLinks, setCurrentBreadcrumbText, contentHeight }) => {
    const { t, language } = useI18n();
    const { displayData, displayDataStatus } = useEntityDisplayData(CONTACT_CMS_DEFINITION_ID);
    const dispatch = useDispatch();
    const dispatchUpdateTenant = useDispatch();
    const currentLanguage = useSelector((s) => getCurrentLanguage(s.i18n));
    const tenant = useSelector(getTenant);
    const { isFailed, isPending } = useSelector(getTenantStatus);
    const initialValues = useMemo(() => {
        if (!tenant) {
            return;
        }
        const tenantWithInitialValues = {
            ...tenant,
            customHosts: tenant?.customHosts ?? []
        };
        return pick(tenantWithInitialValues, tenantSettingsFormFields);
    }, [tenant]);
    const handleUpdateTenant = useCallback(async (updatedTenant) => await dispatchUpdateTenant(updateTenant(updatedTenant)), [dispatchUpdateTenant]);
    const moveLanguageItemForward = (values) => {
        if (!values?.availableLanguages && !values?.defaultLanguage) {
            return values;
        }
        const newValues = { ...values };
        const itemToMove = newValues.defaultLanguage;
        if (itemToMove && newValues.availableLanguages) {
            const updatedList = [
                itemToMove,
                ...newValues.availableLanguages.filter((item) => item !== itemToMove)
            ];
            newValues.availableLanguages = updatedList;
        }
        return newValues;
    };
    const handleSubmit = useCallback(async (values) => {
        const updatedValues = moveLanguageItemForward(values);
        const updatedTenantValues = pickBy(updatedValues, (value, key) => editableTenantSettingsFormFields.includes(key) && !isEqual(value, initialValues?.[key]));
        if (isEmpty(updatedTenantValues)) {
            return;
        }
        try {
            await handleUpdateTenant(updatedTenantValues);
            if (updatedTenantValues?.availableLanguages) {
                dispatch(i18nChangeAvailableLanguages(updatedTenantValues.availableLanguages));
                Config.AVAILABLE_LANGUAGES = updatedTenantValues.availableLanguages;
            }
            if (updatedTenantValues.defaultLanguage) {
                Config.DEFAULT_LANGUAGE = updatedTenantValues.defaultLanguage;
            }
            if (!updatedTenantValues.availableLanguages?.includes(currentLanguage) &&
                values.defaultLanguage) {
                dispatch(i18nChangeLanguage(values.defaultLanguage));
            }
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
        }
        catch (err) {
            console.error('Submit failed - ', err);
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
        }
    }, [initialValues, handleUpdateTenant, currentLanguage, dispatch, t]);
    const handleValidate = useCallback((values) => {
        const hyperFormCustomFonts = values?.hyperFormTheme?.fonts;
        if (!hyperFormCustomFonts) {
            return;
        }
        const errors = {};
        for (const [index, hyperFormCustomFont] of hyperFormCustomFonts.entries()) {
            if (!hyperFormCustomFont.fileId) {
                set(errors, `hyperFormTheme.fonts[${index}].fileId`, t('PROPERTY_REQUIRED'));
            }
        }
        return errors;
    }, [t]);
    const getDisabledField = useCallback(() => disabledTenantSettingsFormFields.map(({ id, label }) => (React.createElement("div", { key: id, className: "mb-3" },
        React.createElement(LabelText, null, t(label)),
        React.createElement(FormikField, { key: id, className: "p-2", name: id, disabled: true, component: Input })))), [t]);
    const getLanguageSettingsFields = useCallback((values, isSubmitting) => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mb-2" },
            React.createElement(LabelText, null, t('TENANT_DEFAULT_LANGUAGE')),
            React.createElement(FormikField, { component: Select, className: "w-100", value: values?.defaultLanguage, name: "defaultLanguage", disabled: isSubmitting || isPending || displayDataStatus.isPending, options: supportLanguageOptions })),
        React.createElement("div", { className: "mb-2" },
            React.createElement(LabelText, null, t('TENANT_AVAILABLE_LANGUAGES')),
            React.createElement(FormikField, { component: Select, className: "w-100", mode: "multiple", value: values?.defaultLanguage
                    ? !values.availableLanguages?.includes(values.defaultLanguage)
                        ? [...(values.availableLanguages || []), values.defaultLanguage]
                        : values.availableLanguages
                    : values.availableLanguages, name: "availableLanguages", disabled: isSubmitting || isPending || displayDataStatus.isPending, options: supportLanguageOptions })))), [t, isPending, displayDataStatus.isPending]);
    const options = displayData?.data.map(({ titles, values }) => ({
        label: getTranslation(titles, language),
        title: getTranslation(titles, language),
        options: values
            .filter(({ type }) => type === PropertyTypes.email)
            .map(({ propertyId, labels }) => ({
            value: propertyId,
            label: getTranslation(labels, language)
        }))
    }));
    const getFormBody = useCallback(({ errors, values, setFieldValue, isSubmitting, dirty }) => (React.createElement("div", { className: `${styles.formSection} h-100 position-relative my-0 mx-auto` },
        React.createElement("div", { className: "h-100" },
            React.createElement("div", { className: "p-2" },
                getDisabledField(),
                getLanguageSettingsFields(values, isSubmitting),
                React.createElement("div", { className: "mb-2" },
                    React.createElement(LabelText, null, t('TENANT_LOGIN_EMAIL_FIELDS')),
                    React.createElement(FormikField, { component: Select, className: "w-100", mode: "multiple", name: "auth.properties.EMAIL", disabled: isSubmitting || isPending || displayDataStatus.isPending, options: options, optionFilterProp: "label" })),
                React.createElement("div", { className: "d-flex align-items-center mb-2" },
                    React.createElement(FormikField, { component: Toggle, name: "auth.properties.SSO.microsoft" }),
                    React.createElement("div", { className: "ms-3" }, t('TENANT_LOGIN_SSO_MICROSOFT'))),
                React.createElement("div", { className: "d-flex align-items-center mb-2" },
                    React.createElement(FormikField, { component: Toggle, name: "auth.properties.SSO.google" }),
                    React.createElement("div", { className: "ms-3" }, t('TENANT_LOGIN_SSO_GOOGLE'))),
                React.createElement(TenantSettingUploadFileField, { value: values.logoUrl, name: "logoUrl", label: t('TENANT_COMPANY_LOGO'), buttonAddTitle: t('TENANT_UPLOAD_LOGO'), removeButtonTitle: t('TENANT_REMOVE_LOGO'), disabled: isSubmitting || isPending }),
                React.createElement("div", { className: "d-flex align-items-center mb-2" },
                    React.createElement(FormikField, { component: Toggle, name: "overrideTenantLogo" }),
                    React.createElement("div", { className: "ms-3" }, t('TENANT_OVERRIDE_LOGO'))),
                React.createElement("div", { className: "d-flex align-items-center mb-2" },
                    React.createElement(FormikField, { component: Toggle, name: "theme.hidePropertyIcon" }),
                    React.createElement("div", { className: "ms-3" }, t('HIDE_PROPERTY_ICON'))),
                React.createElement(FormikField, { name: "customHosts", label: t('TENANT_APPLICATION_HOSTS'), placeholder: t('TENANT_APPLICATION_HOSTS_PLACEHOLDER'), disabled: isSubmitting || isPending, component: MultitextInput }),
                React.createElement(HyperFormThemeForm, { errors: errors, setFieldValue: setFieldValue, values: values, isSubmitting: isSubmitting }),
                React.createElement(TenantLoginSettings, { values: values, isSubmitting: isSubmitting }))),
        React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }))), [
        displayData?.data,
        displayDataStatus.isPending,
        getDisabledField,
        getLanguageSettingsFields,
        isPending,
        language,
        t
    ]);
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('TENANT_CONFIG'));
    }, [setCurrentBreadcrumbText, setLinks, t]);
    return !initialValues ? (React.createElement(LoadingIndicator, null)) : (React.createElement(Formik, { enableReinitialize: true, onSubmit: handleSubmit, initialValues: initialValues, validate: handleValidate, validateOnBlur: true, validateOnChange: false }, ({ dirty, isSubmitting, resetForm, submitForm, values, setFieldValue, errors }) => (React.createElement(SectionCard, { className: `${styles.card} mt-0 mx-2 mb-3`, title: t('TENANT_CONFIG'), secondaryTitle: React.createElement(FormButtons, { dirty: dirty, disabled: isSubmitting || isPending, submitting: isSubmitting, onReset: resetForm, onSubmit: submitForm }), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true, loading: displayDataStatus.isPending },
        React.createElement(Content, { height: contentHeight }, isFailed ? (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })) : (!displayDataStatus.isPending &&
            (displayData?.data ? (getFormBody({ errors, values, dirty, setFieldValue, isSubmitting })) : (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })))))))));
};
export default TenantSettingsFormContainer;
