import { combineReducers } from 'redux';
import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { FORMS__FETCH_LIST_SUCCESS_ACTION, FORMS__FETCH_SINGLE_SUCCESS_ACTION } from './constants';
const initialFormsListState = {
    list: []
};
const formsList = createReducer(initialFormsListState, {
    [FORMS__FETCH_LIST_SUCCESS_ACTION]: (s, a) => {
        s.list = a.payload.results;
    },
    [FORMS__FETCH_SINGLE_SUCCESS_ACTION]: (s, a) => {
        const newForm = a.payload;
        s.list = s.list.filter(f => f.id !== newForm.id);
        s.list.push(newForm);
    }
});
export default combineReducers({
    formsList
});
