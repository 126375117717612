import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { FaRegWindowMinimize } from 'react-icons/fa';
import { success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import TabCloseConfirmation from '../tab-close-confirmation/TabCloseConfirmation';
import ConfirmationCloseButton from './ConfirmationCloseButton';
import { useStickyTabs } from './StickyTabsProvider';
const ItemStickyTab = ({ id, activityType, isOpen, isDirty, title, defaultTitle, Icon, index, component: BodyTabComponent }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const { changeActiveTabs, filteredTab, setTabLabel, setTabDirty, maxCountActiveTabs } = useStickyTabs();
    const isOverflowItemTab = index >= maxCountActiveTabs;
    const handleChangeTabLabel = useCallback((title) => {
        setTabLabel(id, title, defaultTitle);
    }, [defaultTitle, id, setTabLabel]);
    const handleChangeTabDirty = useCallback((isDirty) => {
        setTabDirty(id, isDirty);
    }, [id, setTabDirty]);
    const handleSuccessfulSave = useCallback(() => {
        filteredTab(id);
        dispatch(success({
            title: t('COMMON__SUCCESS'),
            message: t('ENTITIES_CREATE_SUCCESS', { type: defaultTitle, title })
        }));
    }, [filteredTab, id, dispatch, t, defaultTitle, title]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `item-tab flex-grow-0 flex-shrink-0 d-flex flex-column rounded-top ms-3 ${!isOpen ? 'minimized' : ''} ${isOverflowItemTab ? 'd-none' : ''} ` },
            React.createElement("div", { className: "tab-header border-bottom p-3 d-flex align-items-center justify-content-between cursor-pointer", onClick: () => changeActiveTabs(id) },
                React.createElement("div", { className: "d-flex align-items-center overflow-hidden me-2" },
                    React.createElement("div", { className: "position-relative me-2 my-1" },
                        Icon,
                        isDirty && (React.createElement("span", { className: "dirty-indicator rounded-circle border border-2 border-white position-absolute top-0 start-100 translate-middle" }))),
                    React.createElement("div", { className: "text-nowrap overflow-hidden text-ellipsis" }, title)),
                React.createElement("div", { className: "d-flex" },
                    React.createElement("div", { className: `d-flex py-1 me-2` },
                        React.createElement(FaRegWindowMinimize, { size: 18, className: `${!isOpen ? 'collapsed' : ''}` })),
                    React.createElement(ConfirmationCloseButton, { isShowModal: isDirty, onClose: () => filteredTab(id) }))),
            React.createElement("div", { className: "main-content overflow-auto overscroll-behavior-contain" },
                React.createElement(BodyTabComponent, { activityType: activityType, handleChangeTabLabel: handleChangeTabLabel, handleChangeTabDirty: handleChangeTabDirty, onSubmitted: handleSuccessfulSave }))),
        React.createElement(TabCloseConfirmation, { dirty: isDirty })));
};
export default ItemStickyTab;
