import { ErrorMessage, InputContainer, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Select } from 'antd';
import React, { useCallback } from 'react';
import Toggle from '../../../../../../common/components/Toggle';
import { DocumentationLink } from '../../../../../../common/components/documentation-link';
const fileTypes = {
    pdf: {
        type: 'application/pdf',
        label: 'FILE_TYPE_PDF'
    },
    word: {
        type: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        label: 'FILE_TYPE_WORD'
    },
    excel: {
        type: '.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
        label: 'FILE_TYPE_EXCEL'
    },
    image: {
        type: 'image/*',
        label: 'FILE_TYPE_IMAGE'
    },
    audio: {
        type: 'audio/*',
        label: 'FILE_TYPE_AUDIO'
    },
    video: {
        type: 'video/*',
        label: 'FILE_TYPE_VIDEO'
    }
};
const FileField = ({ disabled, onChange, value, error }) => {
    const { t } = useI18n();
    const onChangeAccept = useCallback((values) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                accept: values.join('|'),
                // Carousel list should be set to false whenever the file type is not only an image
                carousel: values.length === 1 && values[0] === fileTypes.image.type
            }
        });
    }, [onChange, value]);
    const onChangeToggleBy = useCallback((property) => (checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                [property]: checked
            }
        });
    }, [onChange, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputContainer, { className: "col-12 col-md-4 py-2" },
            React.createElement(LabelText, null,
                t('FILE_TYPE_ACCEPT'),
                ' (',
                React.createElement(DocumentationLink, { href: "https://www.w3schools.com/tags/att_input_accept.asp" }),
                ')'),
            React.createElement(Select, { mode: "tags", className: "w-100", placeholder: t('FILE_TYPE_PLACEHOLDER'), disabled: disabled, onInputKeyDown: (e) => (e.key == ',' || e.key == '|') && e.preventDefault(), onChange: onChangeAccept, value: value.meta.accept?.split('|') || [] }, Object.values(fileTypes).map((type) => (React.createElement(Select.Option, { key: type.type, value: type.type }, t(type.label)))))),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('OPTIONS_LIST'), disabled: disabled || !value.isNew, onChange: onChangeToggleBy('list'), checked: value.meta.list }),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('OPTIONS_USE_CAROUSEL'), disabled: disabled, onChange: onChangeToggleBy('carousel'), checked: value.meta.carousel }),
        value.meta.carousel && (React.createElement(Toggle, { className: "w-100 py-2", label: t('USE_COVER_ON_IMAGES'), disabled: disabled, onChange: onChangeToggleBy('imageCover'), checked: value.meta.imageCover })),
        !!error && React.createElement(ErrorMessage, null, error)));
};
export default FileField;
