import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import Navbar from '@hypercharge/hyper-react-base/lib/common/navbar';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { MdFormatListBulleted } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useStickyTabs } from '../../../../../common/components/StickyTabs/StickyTabsProvider';
import { BROWSE_PATH } from '../../../../../common/utils/constants';
import { LoadingOverlay } from '../../../../../common/utils/stylingUtils';
import { ParentOwnerTranslationKeyContext } from '../../../../../groups';
import { META_DEFINITION_PATH, MetaDefinitionForm, PROCESS_META_METADATA } from '../../../../../workflows';
import { CMS_HIGHLIGHTED_PROPERTIES_PATH, CMS_HIGHLIGHTED_REFERRALS_PATH, CMS_PATH } from '../../../../common/utils/constants';
import { fetchItemMeta } from '../../../actions';
import { getItemMeta, hasFetchItemMetaFailed, isItemMetaAvailable } from '../../../selectors';
import { HighlightedPropertiesForm, HighlightedReferralsForm } from '../HighlightedItems';
import EntityActions from '../entity-actions/EntityActions';
import ItemMetaFormContainer from '../meta-entity-form/ItemMetaFormContainer';
import styles from './EntityRoutes.module.scss';
const EntityRoutes = ({ entityMetaId, entityTitle, setLinks, setCurrentBreadcrumbText, baseUrl, match: { url }, contentHeight }) => {
    const { t, language } = useI18n();
    const dispatch = useDispatch();
    const { isActiveStickySection } = useStickyTabs();
    const entityMeta = useSelector((s) => getItemMeta(s, entityMetaId));
    const isAvailable = useSelector((s) => isItemMetaAvailable(s, entityMetaId));
    const isFailed = useSelector((s) => hasFetchItemMetaFailed(s, entityMetaId));
    const isSystem = entityMeta?.system || false;
    const navLinks = useMemo(() => [
        {
            to: url + PROCESS_META_METADATA,
            text: t('BASIC_INFO')
        },
        {
            to: url + META_DEFINITION_PATH,
            text: t('WORKFLOWS_SETTINGS_PROPERTIES_NAV_LINK')
        },
        {
            to: url + CMS_HIGHLIGHTED_REFERRALS_PATH,
            text: t('HIGHLIGHTED_REFERRALS_NAV_LINK')
        },
        {
            to: url + CMS_HIGHLIGHTED_PROPERTIES_PATH,
            text: t('HIGHLIGHTED_PROPERTIES_NAV_LINK')
        }
    ], [url, t]);
    const showItemsButtonJsx = useMemo(() => (React.createElement(Button, { inversed: true, to: `${baseUrl}/${entityMetaId}${BROWSE_PATH}`, className: "ms-3" },
        React.createElement(MdFormatListBulleted, { className: "me-1" }),
        t('CMS_ITEMS'))), [baseUrl, entityMetaId, t]);
    const getMainButtonJsx = useCallback(({ metaDefinitionId, handleSubmit, submitting, disabled, dirty }) => (React.createElement(EntityActions, { id: metaDefinitionId, save: handleSubmit, saving: submitting, disabled: disabled, canSave: dirty, canDelete: !isSystem })), [isSystem]);
    useEffect(() => {
        if (!isAvailable) {
            dispatch(fetchItemMeta(entityMetaId));
        }
    }, []);
    useEffect(() => {
        setLinks([
            { text: t('CMS_NAV_LINK'), to: CMS_PATH },
            { text: entityTitle, to: `${baseUrl}/${entityMetaId}${BROWSE_PATH}` }
        ]);
        setCurrentBreadcrumbText(t('SETTINGS'));
    }, [setLinks, setCurrentBreadcrumbText, baseUrl, entityMetaId, entityTitle, t]);
    const contentStyle = useMemo(() => {
        if (!contentHeight) {
            return;
        }
        return {
            height: contentHeight
        };
    }, [contentHeight]);
    if (isFailed) {
        return React.createElement(NotFound, null);
    }
    return (React.createElement(SectionCard, { withoutPadding: true, withoutMargin: true, className: `${styles.wrapper} ${isActiveStickySection ? 'pb-5' : ''}` },
        React.createElement(Navbar, { className: "nav-bar flex-wrap pt-sm-0 pt-7", links: navLinks, showOnlyIcons: false, exactMatching: true, spacing: "0.5rem" }),
        React.createElement("div", { style: contentStyle }, !entityMeta ? (React.createElement("div", { className: styles.loadingOuter },
            React.createElement(LoadingOverlay, null,
                React.createElement(LoadingRectangles, null)))) : (React.createElement(ParentOwnerTranslationKeyContext.Provider, { value: "CMS_ITEM_OWNER" },
            React.createElement(Switch, null,
                React.createElement(Route, { path: `${url}${PROCESS_META_METADATA}`, render: (props) => (
                    // @ts-expect-error
                    React.createElement(ItemMetaFormContainer, { key: entityMetaId, entityMeta: entityMeta, entityMetaId: entityMetaId, extraActionButtons: showItemsButtonJsx, isSystem: isSystem, ...props })) }),
                React.createElement(Route, { path: `${url}${META_DEFINITION_PATH}`, render: (props) => (React.createElement(MetaDefinitionForm, { key: entityMetaId, metaDefinitionId: entityMeta.definitionId, parentViewGroups: get(entityMeta, 'viewGroups', []), itemMeta: entityMeta, mainActionButtonComponent: getMainButtonJsx, extraActionButtons: showItemsButtonJsx, ...props })) }),
                React.createElement(Route, { exact: true, path: `${url}${CMS_HIGHLIGHTED_REFERRALS_PATH}` },
                    React.createElement(HighlightedReferralsForm, { entityMeta: entityMeta, entityMetaId: entityMetaId, isSystem: isSystem, extraActionButtons: showItemsButtonJsx, key: entityMetaId })),
                React.createElement(Route, { exact: true, path: `${url}${CMS_HIGHLIGHTED_PROPERTIES_PATH}` },
                    React.createElement(HighlightedPropertiesForm, { itemMeta: entityMeta, itemMetaId: entityMetaId, isSystem: isSystem, extraActionButtons: showItemsButtonJsx, key: entityMetaId })),
                React.createElement(Redirect, { to: `${url}${PROCESS_META_METADATA}` })))))));
};
export default EntityRoutes;
