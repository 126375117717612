import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Divider } from 'antd';
import React, { useMemo } from 'react';
import { useStore } from 'react-redux';
import { Field, Fields, FormSection, formValueSelector } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { CustomEntitySelector } from '../../../../../../common/components/entity-selectors';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import { getUsedStepEntityIds } from './utils';
const StepForm = ({ disabled, parentViewGroups }) => {
    const { t } = useI18n();
    const store = useStore();
    const activity = formValueSelector('activitySettings')(store.getState(), 'activity');
    const extraPayloadProps = useMemo(() => {
        const usedIds = getUsedStepEntityIds(activity);
        return {
            query: {
                condition: ConditionType.and,
                filters: usedIds.map((id) => ({
                    field: 'entityId',
                    operator: FilterOperatorTypes.isNot,
                    data: id
                }))
            }
        };
    }, [activity]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", name: "titles", component: AntInput, placeholder: t('COMMON__TITLE'), disabled: disabled })),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('PROCESS_META__STEP_TASK'), className: "ps-0" }),
            React.createElement(Field, { className: "mt-3", name: "step", component: CustomEntitySelector, definitionId: "processStep", disabled: disabled, validate: required, autoFocus: false, popupMatchSelectWidth: false, extraPayloadProps: extraPayloadProps })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default StepForm;
