import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import Select from 'antd/lib/select';
import { findIndex } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { media } from '../../../common/utils/styledComponentsUtils';
import { HYPER_BI_PATH } from '../../constants';
import { getDashboards } from './actions';
import { default as Dashboard } from './Dashboard';
//
// Variables for set div height to full display height
const otherElementSelectors = [
    '#header',
    '#featured-entities',
    '#breadcrumbs',
    '#footer',
    '#selectDashboard'
];
const otherHeightInPx = 32; // padding
const DashboardsPage = ({ match: { params: { id: dashboardId } } }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [dashboards, setDashboards] = useState([]);
    const [loading, setLoading] = useState(true);
    //
    // Get dashboard from QuickSight
    useEffect(() => {
        setLoading(true);
        dispatch(getDashboards())
            // @ts-expect-error
            .then((dashboards) => setDashboards(dashboards))
            .catch((e) => {
            console.error(e);
            dispatch(error({
                title: t('ERROR'),
                message: e.message
            }));
        })
            .finally(() => setLoading(false));
    }, []);
    // Set active board when pathname or dashboard changed
    const activeDashboard = useMemo(() => {
        if (dashboardId) {
            // set active by path
            return findIndex(dashboards, ['dashboardId', dashboardId]);
        }
        return -1;
    }, [dashboardId, dashboards]);
    //
    // Sete default / first dashboard if url don't contain dashboardId
    useEffect(() => {
        if (!dashboardId && dashboards.length) {
            dispatch(push(`${HYPER_BI_PATH}/${dashboards[0].dashboardId}`));
        }
    }, [dashboardId, dashboards, dispatch]);
    //
    // Set active board via callback of select
    const onChangeDashboard = useCallback((newValue) => {
        if (dashboards[newValue]) {
            dispatch(push(`${HYPER_BI_PATH}/${dashboards[newValue].dashboardId}`));
        }
    }, [dashboards, dispatch]);
    const mainPath = useMemo(() => `${HYPER_BI_PATH}` + (dashboards[0] ? `/${dashboards[0].dashboardId}` : ''), [dashboards]);
    //
    // JSX of component
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "breadcrumbs" },
            React.createElement(Breadcrumbs, { links: [{ text: t('HYPER_BI_NAV_LINK'), to: mainPath }], currentBreadcrumbText: dashboards[activeDashboard] ? dashboards[activeDashboard].name : '' })),
        dashboards.length > 1 && (React.createElement(ScSelectContainer, null,
            React.createElement(Select, { value: activeDashboard, onChange: onChangeDashboard, showArrow: true, showSearch: false, allowClear: false, 
                // @ts-expect-error
                mode: "default", id: "selectDashboard" },
                dashboards.map((option, index) => (React.createElement(Select.Option, { key: index }, option.name))),
                activeDashboard === -1 ? React.createElement(Select.Option, { key: -1 }, " ") : null))),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement(ScContainer, { height: contentHeight }, loading === true ? (React.createElement(LoadingIndicator, null)) : dashboards.length === 0 ? (React.createElement(ErrorContainer, null, t('HYPERBI_DASHBOARDS__NO_RESULTS'))) : !dashboards[activeDashboard]?.dashboardId ? (React.createElement(ErrorContainer, null, t('DATA_TABLE__NO_RESULTS'))) : (React.createElement(Dashboard, { id: "activeDashboard", height: contentHeight, dashboardId: dashboards[activeDashboard].dashboardId, dispatch, t }))))))));
};
//
// Export component
export default DashboardsPage;
//
// Styles
const ScSelectContainer = styled.div `
  margin: 1rem 0.5rem;
  margin-top: 0;
  padding: 0;
  position: relative;
  #selectDashboard {
    min-width: 200px;
  }
`;
const ErrorContainer = styled.div `
  padding: 20px;
  color: var(--grayscaleColor-7);
  width: calc(100% - 3px);

  ${media.sm} {
    text-align: center;
  }
`;
const ScContainer = styled.div `
  margin: 1rem 0.5rem;
  padding: 0;
  user-select: none;
  background: var(--grayscaleColor-1);
  box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow-y: auto;
  height: ${(props) => props.height};
`;
