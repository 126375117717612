import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { IoMdPeople } from 'react-icons/io';
import styles from './GroupsInfoPage.module.scss';
export const GroupsInfoPage = ({ contentHeight }) => {
    const { t } = useI18n();
    const contentJsx = useMemo(() => (React.createElement("div", { className: styles.groups__container },
        React.createElement("div", { className: "cp-c-lg-row cp-c-align-spacebetween-center cp-c-padding-2" },
            React.createElement("div", { className: "cp-i-lg-70" },
                React.createElement("div", { className: styles.groups__title }, t('GROUPS_PAGE_TITLE')),
                React.createElement("p", null, t('GROUPS_PAGE_TEXT_1')),
                React.createElement("p", null, t('GROUPS_PAGE_TEXT_2')),
                React.createElement("p", null, t('GROUPS_PAGE_TEXT_3'))),
            React.createElement("div", { className: "cp-hide cp-lg-show-block cp-i-lg-30" },
                React.createElement(IoMdPeople, { className: styles.groups__groupsIcon }))))), [t]);
    const heightStyles = useMemo(() => (contentHeight ? { height: contentHeight } : null), [contentHeight]);
    return heightStyles ? (React.createElement("div", { className: styles.groups__content, style: heightStyles }, contentJsx)) : (contentJsx);
};
