import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback } from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { useProperties } from '../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../common/components/AntInput';
import { usePropertiesTree } from '../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import { ConditionQueryField } from '../../../../../common/components/ConditionQuery';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { ComputedExpressionsDocumentationLink } from '../../../../../common/components/documentation-link';
import { ItemMetaSelector } from '../../../../../common/components/entity-selectors';
import GroupsField from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
import NodeSettingsSection from './NodeSettingsSection';
import ProcessPropertyMappingsField from './ProcessPropertyMappingsField';
import ProcessPropertyMappingsUpdateMethodField from './ProcessPropertyMappingsUpdateMethodField';
const SetEntityValuesForm = ({ disabled, metaDefinitionId, parentViewGroups, changeFieldValue }) => {
    const { t } = useI18n();
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    const handleDefinitionIdFieldChange = useCallback((_, newValue, previousValue, name) => {
        if (newValue != previousValue) {
            // Reset the conditionQuery and property mappings that were based on the previously selected process
            const entityItemsFilterFieldName = name?.replace('definitionId', 'conditionQuery') || '';
            changeFieldValue(entityItemsFilterFieldName, {});
            const propertyMappingsFieldName = name?.replace('definitionId', 'propertyMappings') || '';
            changeFieldValue(propertyMappingsFieldName, {});
        }
    }, [changeFieldValue]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "w-100", placeholder: t('COMMON__TITLE'), name: "titles", component: AntInput, disabled: disabled })),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('ENTITY'), className: "ps-0" }),
            React.createElement(Field, { name: "definitionId", className: "w-100", component: ItemMetaSelector, disabled: disabled, validate: required, autoFocus: false, onChange: handleDefinitionIdFieldChange }),
            React.createElement(Field, { name: "definitionId", component: DefinitionSection })),
        React.createElement(Field, { name: "settings", component: NodeSettingsSection, propertiesTree: propertiesTree, disabled: disabled }),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
const DefinitionSection = ({ input }) => {
    const { t } = useI18n();
    const { isPropertyList } = useProperties(input.value);
    return input.value ? (React.createElement(React.Fragment, null,
        React.createElement(FieldLabel, { label: t('TASK_SET_ENTITY_VALUES_FILTER'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "mt-2 ps-0" }),
        React.createElement(Field, { name: "conditionQuery", component: ConditionQueryField, definitionId: input.value, allowComputed: true }),
        React.createElement(FieldLabel, { label: t('TASK_SET_ENTITY_VALUES_PROPERTY_MAPPINGS'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "mt-2 ps-0" }),
        React.createElement(Field, { className: "cp-i-100", name: "propertyMappings", component: ProcessPropertyMappingsField, definitionId: input.value, includeNestedProperties: false, additionLabelContent: (field) => isPropertyList(field) ? (React.createElement(Field, { className: "cp-i-100", name: "propertyMappingsUpdateMethods", component: ProcessPropertyMappingsUpdateMethodField, field: field })) : null }))) : null;
};
export default SetEntityValuesForm;
