import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FieldArray } from 'formik';
import React from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import PropertyFieldInlineEditor from '../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import FormsSelectorField from '../../../../../forms/components/common/FormsSelector';
export const getFormsOptionsFormSchema = () => {
    return Yup.array(Yup.object({
        groupIds: Yup.array().of(Yup.string()),
        forms: Yup.array().of(Yup.string())
    }));
};
const FormsOptionsForm = ({ availableForms, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(FieldArray, { name: "configuration.availableForms", render: (arrayHelpers) => (React.createElement(React.Fragment, null,
            availableForms?.map((availableForms, index) => (React.createElement("div", { className: "row mb-4", key: index },
                React.createElement("div", { className: "col-md-5 col-12" },
                    React.createElement("span", null,
                        t('GROUP__NAV_LINK'),
                        ": "),
                    React.createElement(FormikField, { name: `configuration.availableForms.${index}.groupIds`, component: PropertyFieldInlineEditor, type: "entity", definitionId: "group", meta: { definitionId: 'group', list: true }, canEdit: !disabled })),
                React.createElement("div", { className: "col-md-4 col-12" },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            t('FORMS_NAV_LINK'),
                            ": "),
                        React.createElement(FormikField, { className: "mt-2", name: `configuration.availableForms.${index}.forms`, component: FormsSelectorField, multiple: true, canEdit: true, disabled: disabled }))),
                React.createElement("div", { className: "col-2 my-auto" },
                    React.createElement(Button, { className: "button", inversed: true, disabled: disabled, onClick: () => arrayHelpers.remove(index) },
                        React.createElement(FaTrash, { preserveAspectRatio: "none" })))))),
            React.createElement(Button, { className: "action-button", onClick: () => arrayHelpers.push({
                    forms: [],
                    groupIds: []
                }), inversed: true, disabled: disabled },
                React.createElement(FaPlus, { className: "me-2" }),
                t('APPS_CUSTOMER_PORTAL__ADD_GROUP_OPTION')))) }));
};
export default FormsOptionsForm;
