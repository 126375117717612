import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { TENANT_ID } from 'config';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import PropertyFieldInlineEditor from '../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import { FileField, uploadFiles } from '../../../../../common/storage';
import useFiles from '../../../../../common/storage/useFiles';
export const getWhitelabelSettingsFormSchema = () => {
    return Yup.object({
        logo: Yup.string().nullable(),
        favicon: Yup.string().nullable(),
        primaryColor: Yup.string().nullable()
    });
};
const WhiteLabelSettingsForm = ({ whiteLabel, disabled, setFieldValue }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const logo = useMemo(() => (whiteLabel?.logo ? [whiteLabel?.logo] : []), [whiteLabel?.logo]);
    const logoFile = useFiles(logo);
    const favicon = useMemo(() => (whiteLabel?.favicon ? [whiteLabel?.favicon] : []), [whiteLabel?.favicon]);
    const faviconFile = useFiles(favicon);
    const uploadFilesHandler = useCallback(async (files, path) => {
        try {
            const uploadData = await uploadFiles(dispatch, files, TENANT_ID);
            uploadData.map((file) => setFieldValue(path, file.id));
        }
        catch (e) {
            notificationDispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    }, [dispatch, notificationDispatch, setFieldValue, t]);
    const onRemoveFile = useCallback((path) => {
        return new Promise((resolve, reject) => {
            try {
                setFieldValue(path, null);
            }
            catch (e) {
                reject(e);
            }
            finally {
                resolve(undefined);
            }
        });
    }, [setFieldValue]);
    return (React.createElement("div", { className: "row gy-3" },
        React.createElement("div", { className: "col-12 col-md-6" },
            React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_WHITELABEL__LOGO') }),
            React.createElement(FormikField, { name: "configuration.whiteLabel.logo", component: FileField, files: logoFile, meta: { accept: 'image/*', list: false, carousel: false }, error: false, loading: false, onSave: (files) => uploadFilesHandler(files, `configuration.whiteLabel.logo`), onRemove: () => onRemoveFile(`configuration.whiteLabel.logo`), canEdit: !disabled, value: whiteLabel?.logo, disabled: disabled })),
        React.createElement("div", { className: "col-12 col-md-6" },
            React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_WHITELABEL__FAVICON') }),
            React.createElement(FormikField, { name: `configuration.whiteLabel.favicon`, component: FileField, files: faviconFile, meta: { accept: 'image/*', list: false, carousel: false }, error: false, loading: false, onSave: (files) => uploadFilesHandler(files, `configuration.whiteLabel.favicon`), onRemove: () => onRemoveFile(`configuration.whiteLabel.favicon`), canEdit: !disabled })),
        React.createElement("div", { className: "col-12 col-md-6" },
            React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_WHITELABEL__PRIMARY_COLOR') }),
            React.createElement(FormikField, { name: "configuration.whiteLabel.primaryColor", component: PropertyFieldInlineEditor, type: "text", meta: { multiline: false, multilanguage: true }, canEdit: !disabled }))));
};
export default WhiteLabelSettingsForm;
