import { addOptionGroup } from '@hypercharge/hyper-react-base/lib/metadata';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { reduxForm } from 'redux-form';
import { ADMIN_PATH } from '../../../utils/constants';
import OptionGroupForm from './OptionGroupForm';
export const form = 'optionGroup';
const onSubmit = (values, dispatch, props) => Promise.resolve().then(() => {
    dispatch(addOptionGroup(values.groupId));
    dispatch(push(`${ADMIN_PATH}/option-groups/${values.groupId}/options`));
});
export default reduxForm({
    form,
    onSubmit
})(OptionGroupForm);
