import React, { Component } from 'react';
import { omit } from 'lodash';
import ReactTestUtils from 'react-dom/test-utils';
const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
class NumberInput extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: this.props.value === '' || this.props.value === null || isNaN(this.props.value)
                ? ''
                : Number(this.props.value),
            inputSelection: {
                start: 0,
                end: 0
            },
            updateSelection: this.props.preventUpdateSelection
                ? null
                : {
                    start: 0,
                    end: 0
                }
        };
        this.inputRef = null;
        // https://github.com/facebook/react/issues/955#issuecomment-411558217
        this.saveCursorPosition = () => {
            if (this.inputRef) {
                this.setState({
                    inputSelection: {
                        start: this.inputRef.selectionStart,
                        end: this.inputRef.selectionEnd
                    }
                });
            }
        };
        this.isValidInputValue = (value) => {
            const { min, max } = this.props;
            const numberValue = Number(value);
            if (value && value == '-') {
                return true;
            }
            else {
                return (!isNaN(numberValue) &&
                    value.indexOf(' ') == -1 &&
                    (max === undefined || numberValue <= max) &&
                    (min === undefined || numberValue >= min) &&
                    isFinite(numberValue));
            }
        };
        this.handleInputFocus = (args) => {
            const { onFocus } = this.props;
            // Save the initial cursor position when the user focuses the input.
            this.saveCursorPosition();
            onFocus && onFocus(args);
        };
        this.handleOnClick = () => {
            if (this.inputRef) {
                const start = this.inputRef.selectionStart;
                const end = this.inputRef.selectionEnd;
                this.setState({
                    inputSelection: { start, end },
                    updateSelection: { start, end }
                });
            }
        };
        this.handleInputKeyDown = (e) => {
            const { step = 0, onKeyDown } = this.props;
            if (step) {
                const value = Number(this.state.value);
                let newValue = value;
                if (e.key == 'ArrowUp') {
                    newValue = value + step;
                    e.preventDefault();
                }
                else if (e.key == 'ArrowDown') {
                    newValue = value - step;
                    e.preventDefault();
                }
                if (newValue != value && this.isValidInputValue(`${newValue}`)) {
                    this.setState({ value: newValue }, () => {
                        ReactTestUtils.Simulate.change(this.inputRef);
                    });
                }
            }
            // Save the updated cursor position as the user interacts with the input.
            this.saveCursorPosition();
            onKeyDown && onKeyDown(e);
        };
        this.handleInputBlur = (args) => {
            const { onBlur } = this.props;
            if (isNaN(this.state.value)) {
                this.setState({ value: '' });
            }
            onBlur && onBlur(args);
        };
        this.validateInput = (e) => {
            const { onChange } = this.props;
            e.persist();
            if (e.target.value.includes(',')) {
                e.target.value = e.target.value.replace(/,/g, '.');
            }
            // use target value and not val as it is converted to 0
            if (this.isValidInputValue(e.target.value)) {
                this.setState({ value: e.target.value }, () => {
                    onChange && onChange(e);
                });
            }
            else {
                this.setState({
                    updateSelection: this.state.inputSelection ? { ...this.state.inputSelection } : null
                });
            }
        };
    }
    componentDidUpdate(prevProps) {
        // If there was a request to update the selection via setState...
        if (this.state.updateSelection && this.inputRef) {
            // Update the selection.
            const selection = this.state.updateSelection;
            this.inputRef.setSelectionRange(selection.start, selection.end);
            // Important! Clear out the update request, otherwise you will end up with an infinite loop.
            this.setState({ updateSelection: null });
        }
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value === null ? undefined : this.props.value });
        }
    }
    render() {
        const { ...otherProps } = this.props;
        return (React.createElement("input", { onFocus: this.handleInputFocus, onKeyDown: this.handleInputKeyDown, onChange: this.validateInput, onBlur: this.handleInputBlur, onClick: this.handleOnClick, 
            // @ts-expect-error
            value: this.state.value, ref: (r) => (this.inputRef = r), type: "text", pattern: !iOS ? '[0-9]*' : undefined, inputMode: "decimal", ...omit(otherProps, [
                'type',
                'invalid',
                'value',
                'onFocus',
                'onKeyDown',
                'onChange',
                'onBlur',
                'onClick'
            ]) }));
    }
}
export default NumberInput;
