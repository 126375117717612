import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchTemplateVersions } from '../templates/actions';
export const TEMPLATE_VERSIONS_QUERY_KEY = 'template-versions';
export const invalidateTemplateVersionsQuery = async (templateId) => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(TEMPLATE_VERSIONS_QUERY_KEY) && query.queryKey.includes(templateId)
    });
};
export const useTemplateVersions = ({ templateId, onSuccess }) => {
    const dispatchTemplateVersions = useDispatch();
    return useQuery([TEMPLATE_VERSIONS_QUERY_KEY, templateId], () => {
        if (!templateId) {
            return Promise.reject(new Error('Template ID is required'));
        }
        return dispatchTemplateVersions(fetchTemplateVersions(templateId));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        onSuccess
    });
};
