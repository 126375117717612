import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/webpack-resolver';
import { css as cssBeautify, html as htmlBeautify, js as jsBeautify } from 'js-beautify';
import React, { forwardRef, useCallback } from 'react';
import BaseAceEditor from 'react-ace';
const defaultAceOptions = {
    enableSnippets: true,
    highlightGutterLine: true,
    highlightSelectedWord: true,
    showFoldWidgets: true,
    showLineNumbers: true,
    useWorker: false
};
export var AceEditorMode;
(function (AceEditorMode) {
    AceEditorMode["computed_expression"] = "computed_expression";
    AceEditorMode["css"] = "css";
    AceEditorMode["html"] = "html";
    AceEditorMode["js"] = "js";
    AceEditorMode["json"] = "json";
})(AceEditorMode || (AceEditorMode = {}));
export const AceEditor = forwardRef(({ beautify = false, className = '', disabled, mode, onBlur, onChange, options, theme = 'github', ...otherProps }, ref) => {
    const handleOnBlur = useCallback((blurEvent, editor) => {
        if (!editor) {
            return;
        }
        if (beautify) {
            const value = editor.getValue();
            if (!value) {
                return;
            }
            let prettyValue;
            switch (mode) {
                case 'css':
                    prettyValue = cssBeautify(value);
                    break;
                case 'html':
                    prettyValue = htmlBeautify(value);
                    break;
                case 'js':
                    prettyValue = jsBeautify(value);
                    break;
                case 'json':
                    prettyValue = jsBeautify(value, {
                        indent_size: 2
                    });
                    break;
                default:
                    prettyValue = value;
            }
            if (onChange) {
                onChange(prettyValue);
            }
        }
        if (onBlur) {
            onBlur(blurEvent);
        }
    }, [beautify, mode, onBlur, onChange]);
    return (React.createElement(BaseAceEditor, { ...otherProps, ref: ref, className: className, enableBasicAutocompletion: true, enableLiveAutocompletion: true, enableSnippets: false, fontSize: 14, highlightActiveLine: true, mode: mode, readOnly: disabled, setOptions: {
            ...defaultAceOptions,
            ...options
        }, showGutter: true, showPrintMargin: false, style: { border: '1px solid var(--grayscaleColor-5)' }, tabSize: 2, theme: theme, width: "100%", wrapEnabled: true, onBlur: handleOnBlur, onChange: onChange }));
});
