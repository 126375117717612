import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { FaBars } from 'react-icons/fa';
import { FEATURED_ENTITIES_PATH } from '../../../../../../cms';
import useDisplayItemMetaList from '../../../../../../cms/hooks/useDisplayItemMetaList';
import { ACTIVITY_DEFINITION_ID } from '../../../../../../common/activity';
import { replaceConditionFromContextDataInFilters } from '../../../../../../common/components/ConditionQuery/utils';
import FullTextSearch from '../../../../../../common/components/data-table/FullTextSearch';
import { BROWSE_PATH } from '../../../../../../common/utils/constants';
import { useView } from '../../../../../../views/components/ViewContext';
import { ViewTypes } from '../../../../../../views/types';
import { getSearchForUrl } from '../../../../../../views/utils';
import { TaskDashboardPageFilters } from '../../../../../../workflows/dashboard/components/dashboard/TaskDashboardPageFilters';
import { TasksKanban } from '../../../../../../workflows/dashboard/components/dashboard/TasksKanban';
import ActivityTasksTableList from '../../../../../../workflows/dashboard/components/dashboard/TasksTableList/ActivityTasksTableList';
import { TasksTableWrapper } from '../../../../../../workflows/dashboard/components/dashboard/TasksTableWrapper';
import styles from './ActivityTasksWidget.module.scss';
import { getCustomRouteHandleTaskClickBase } from './utils';
const baseUrl = `${FEATURED_ENTITIES_PATH}/${ACTIVITY_DEFINITION_ID}`;
const ActivityTasksWidget = ({ className, settings }) => {
    const { t, language } = useI18n();
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const getCustomRouteHandleTaskClick = useCallback((card) => getCustomRouteHandleTaskClickBase(displayItemMetaList, card), [displayItemMetaList]);
    const { filterRequest, metrics, viewType } = useView();
    const filterRequestOpenInNewTab = useMemo(() => ({
        ...filterRequest,
        query: replaceConditionFromContextDataInFilters(filterRequest.query, filterRequest.context)
    }), [filterRequest]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: "widget-title drag-handler" }, settings.title?.[language] || t('ACTIVITES_NAV_LINK')),
        React.createElement("div", { className: "widget-body" },
            React.createElement("div", { id: "TaskDashboardWidgetHeader", className: "d-flex align-items-center mb-1 justify-content-between" },
                React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement(FullTextSearch, { hotkeyFocusSearch: true })),
                React.createElement("div", { className: "right-buttons" },
                    React.createElement(Tooltip, { title: t('HYPER_BI_WIDGET_VIEW_ALL_BTN_TOOLTIP') },
                        React.createElement(Button, { type: "button", target: "_blank", className: "ms-2 action-button", inversed: true, to: {
                                pathname: `${FEATURED_ENTITIES_PATH}/${ACTIVITY_DEFINITION_ID}${BROWSE_PATH}`,
                                search: getSearchForUrl({
                                    newFilters: filterRequestOpenInNewTab,
                                    newMetrics: metrics,
                                    viewType,
                                    queryParams: {}
                                })
                            } },
                            React.createElement(FaBars, null))))),
            React.createElement(TaskDashboardPageFilters, { relatedToTitleKey: "ACTIVITY_RELATED_TO_DEFINITION" }),
            React.createElement("div", { className: "widget-tasks custom-scroll-styling h-100" }, viewType === ViewTypes.kanban ? (React.createElement(TasksKanban, { baseUrl: baseUrl, getCustomRouteHandleCardClick: getCustomRouteHandleTaskClick })) : (React.createElement(TasksTableWrapper, null,
                React.createElement(ActivityTasksTableList, { getCustomRouteHandleTaskClick: getCustomRouteHandleTaskClick, emptyText: t('EMPTY_AVAILABLE_TASKS_TEXT') })))))));
};
export default ActivityTasksWidget;
