import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
const DimensionsListener = ({ children, elementSelectors, dependencies, trackWidth = false, trackHeight = false, parentNode }) => {
    const [totalWidth, setTotalWidth] = useState(0);
    const [totalHeight, setTotalHeight] = useState(0);
    const timeoutHandler = useRef(null);
    const updateTotals = useCallback(debounce(() => {
        let newTotalWidth = 0;
        let newTotalHeight = 0;
        elementSelectors.forEach((selector) => {
            const element = (parentNode || document).querySelector(selector);
            if (element) {
                newTotalWidth += element.offsetWidth;
                newTotalHeight += element.offsetHeight;
            }
        });
        if (newTotalWidth != totalWidth && trackWidth) {
            setTotalWidth(newTotalWidth);
        }
        if (newTotalHeight != totalHeight && trackHeight) {
            setTotalHeight(newTotalHeight);
        }
    }, 50, { leading: true, trailing: true }), [elementSelectors, parentNode, totalHeight, totalWidth, trackHeight, trackWidth]);
    // if the dependecies change trigger an updateTotals
    useEffect(() => {
        updateTotals();
        // if we are tracking something that hasn't been
        // added to the dom yet just wait and recheck
        timeoutHandler.current = setTimeout(() => {
            updateTotals();
        }, 500);
    }, [elementSelectors, dependencies, updateTotals]);
    useEffect(() => {
        window.addEventListener('resize', updateTotals);
        return () => {
            window.removeEventListener('resize', updateTotals);
            if (timeoutHandler.current) {
                clearTimeout(timeoutHandler.current);
                timeoutHandler.current = null;
            }
        };
    }, [updateTotals]);
    return children(totalWidth, totalHeight);
};
export default DimensionsListener;
