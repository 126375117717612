import { isEmpty, isString, uniq } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import { isPropertyItemMeta } from '../../../types';
import { ExpandedView } from '../ExpandedView';
import { TableField } from '../TableField';
import { isDisplayEntityAsTable, isExpandedView, isPropertyList } from '../utils';
import styles from './TableFields.module.scss';
const sortValuesAsDoubleColunmValues = (filteredValues, doubleCol, isMobile) => {
    if (!doubleCol || isMobile) {
        return filteredValues;
    }
    const values = [];
    const half = Math.ceil(filteredValues.length / 2);
    for (let i = 0; i < half; ++i) {
        values.push(filteredValues[i]);
        const secondHalfIndex = i + half;
        if (secondHalfIndex < filteredValues.length) {
            values.push(filteredValues[secondHalfIndex]);
        }
    }
    return values;
};
const needFullWidthForProperties = (doubleCol, field, additionalColumns) => !doubleCol ||
    isDisplayEntityAsTable(field.meta) ||
    (isPropertyList(field.meta) && !!additionalColumns?.length);
const TableFields = ({ fieldsData, doubleCol, definitionId, entityId, canEdit = true, updatePropertyData, updateNestedPropertyData, bulkUpdateNestedItems, uploadFiles, requiredFieldsConfig, permissionFieldsConfig, fromExpandedView, additionalFields, fileCarouselHeight }) => {
    const { isMobile } = useBrowserBreakpoints();
    const [showExpandedViews, setShowExpandedViews] = useState(() => {
        const metaExpandedView = fieldsData
            .filter((field) => isExpandedView(field.meta) && field.meta.selector?.expandedView?.showDefaultValue)
            .map(({ propertyId }) => propertyId);
        const nestedFields = !isEmpty(additionalFields) ? Object.keys(additionalFields) : [];
        return uniq([...metaExpandedView, ...nestedFields]);
    });
    const handleSetShowExpandedViews = (propertyId) => {
        setShowExpandedViews((prevShowExpandedViews) => {
            if (prevShowExpandedViews.includes(propertyId)) {
                return prevShowExpandedViews.filter((item) => item !== propertyId);
            }
            return [...prevShowExpandedViews, propertyId];
        });
    };
    const getProperties = useCallback((field, additionalProperties) => {
        const metaProperties = isPropertyItemMeta(field.meta) &&
            field.meta.selector?.expandedView?.properties &&
            isExpandedView(field.meta)
            ? field.meta.selector?.expandedView?.properties
            : [];
        if (additionalProperties) {
            const filteredAdditionalProperties = additionalProperties.filter((item) => !metaProperties?.includes(item));
            return [...filteredAdditionalProperties, ...metaProperties];
        }
        return metaProperties;
    }, []);
    const getOrderForExpandedView = (field, index, additionalPropertiesForNext, doubleCol, nextFieldInRow, currentFieldOnTheLeftSide) => {
        const currentField = field;
        const order = index + 1;
        if (currentField?.propertyId && showExpandedViews.includes(currentField?.propertyId)) {
            if (index === 0 && nextFieldInRow === undefined) {
                return order;
            }
            if (currentFieldOnTheLeftSide &&
                !isDisplayEntityAsTable(nextFieldInRow?.meta) &&
                nextFieldInRow &&
                doubleCol &&
                !needFullWidthForProperties(doubleCol, nextFieldInRow, additionalPropertiesForNext)) {
                return order + 2;
            }
            else {
                return order + 1;
            }
        }
        return 0;
    };
    let countAfterTable = 0;
    return (React.createElement("div", { className: `${styles.wrapper} row` }, sortValuesAsDoubleColunmValues(fieldsData, doubleCol, isMobile).map((field, index, fields) => {
        const order = index + 1;
        const additionalProperties = additionalFields ? additionalFields[field.propertyId] : [];
        const additionalPropertiesForNext = additionalFields
            ? additionalFields[fields[index + 1]?.propertyId]
            : [];
        const properties = getProperties(field, additionalProperties);
        if (isDisplayEntityAsTable(field.meta) ||
            (doubleCol && needFullWidthForProperties(doubleCol, field, additionalProperties))) {
            countAfterTable = 0;
        }
        else {
            countAfterTable += 1;
        }
        const currentFieldOnTheLeftSide = countAfterTable % 2 !== 0;
        const nextFieldInRow = doubleCol &&
            !isDisplayEntityAsTable(field.meta) &&
            index + 1 <= fields.length &&
            !isDisplayEntityAsTable(fields[index + 1]?.meta)
            ? fields[index + 1]
            : undefined;
        const prevFieldInRow = doubleCol &&
            !isDisplayEntityAsTable(field.meta) &&
            index - 1 >= 0 &&
            !isDisplayEntityAsTable(fields[index - 1]?.meta)
            ? fields[index - 1]
            : undefined;
        const openExpandedViewNextField = currentFieldOnTheLeftSide && // and current field on left side
            nextFieldInRow?.value &&
            showExpandedViews.includes(nextFieldInRow.propertyId);
        const openExpandedViewPrevField = !currentFieldOnTheLeftSide && // if current field not on left side
            prevFieldInRow?.value &&
            showExpandedViews.includes(prevFieldInRow.propertyId);
        const orderForExpanded = getOrderForExpandedView(field, index, additionalPropertiesForNext, doubleCol, nextFieldInRow, currentFieldOnTheLeftSide);
        return (React.createElement(React.Fragment, null,
            React.createElement(TableField, { fromExpandedView: fromExpandedView, order: order, isFullWidth: doubleCol
                    ? needFullWidthForProperties(doubleCol, field, additionalProperties)
                    : true, showExpandedView: showExpandedViews.includes(field.propertyId), handleSetShowExpandedViews: handleSetShowExpandedViews, doubleCol: doubleCol, key: field.propertyId, canEdit: canEdit, field: field, definitionId: definitionId, entityId: entityId, updatePropertyData: updatePropertyData, updateNestedPropertyData: updateNestedPropertyData, bulkUpdateNestedItems: bulkUpdateNestedItems, uploadFiles: uploadFiles, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, additionalColumns: additionalProperties, fileCarouselHeight: fileCarouselHeight }),
            !fromExpandedView &&
                showExpandedViews.includes(field.propertyId) &&
                isPropertyItemMeta(field.meta) &&
                !!properties?.length &&
                isString(field.value) && (React.createElement(ExpandedView, { field: field, key: `${field.propertyId}-expanded-view`, requiredFieldsConfig: requiredFieldsConfig?.getNestedRequiredFieldsConfig?.(field), permissionFieldsConfig: permissionFieldsConfig?.getNestedPermissionFieldsConfig?.(field), updateNestedPropertyData: updateNestedPropertyData, className: openExpandedViewNextField || openExpandedViewPrevField
                    ? 'col-md-6 col-sm-12 col-xs-12'
                    : 'col-12', fieldOnLeftSide: currentFieldOnTheLeftSide, order: orderForExpanded, doubleCol: !(properties?.length && properties?.length === 1) &&
                    doubleCol &&
                    !openExpandedViewNextField &&
                    !openExpandedViewPrevField, definitionId: field.meta.definitionId, properties: properties, entityId: field.value }))));
    })));
};
export default TableFields;
