import { isEqual } from 'lodash';
import * as quillDeltaToHtml from 'quill-delta-to-html';
import React, { Component } from 'react';
import styled from 'styled-components';
import { FieldWrapper } from '../../FieldElements';
import styles from './RichTextDisplay.module.scss';
const Container = styled.div `
  &.ql-container.ql-container {
    border: 0;
    width: 100%;

    .ql-editor {
      padding: 6px;
    }
  }
`;
class RichTextEditorDisplay extends Component {
    constructor(props) {
        super(props);
        this.getHtml = (props) => {
            const { QuillDeltaToHtmlConverter } = props.quillDeltaToHtml;
            const converter = new QuillDeltaToHtmlConverter(props.value?.ops, {});
            return converter.convert();
        };
        this.state = {
            html: this.getHtml(props)
        };
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.value, this.props.value)) {
            this.setState({ html: this.getHtml(this.props) });
        }
    }
    render() {
        return (React.createElement(Container, { className: "ql-container ql-snow" },
            React.createElement("div", { className: "ql-editor" },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: this.state.html } }))));
    }
}
const RichTextDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, simple: !asField, disabled: disabled, className: `${styles.outer} ${className ?? ''}`, style: style }, value ? React.createElement(RichTextEditorDisplay, { quillDeltaToHtml: quillDeltaToHtml, value: value }) : '\u00a0'));
export default RichTextDisplay;
