import { compact, find, flatMap, flatMapDeep, get, isString } from 'lodash';
import { CMS_ITEM_META_HANDLER } from '../../common/utils/constants';
import { isFeaturedDefinitionIdT } from '../../configs/types';
import { isPropertyItemMeta } from './types';
export const getItemRepresentation = (item, key) => (key && item[key]) || item.title || item.entityId;
export function getFlattenedDisplayDataList(displayDataList) {
    return compact(flatMap(get(displayDataList, 'data', []), 'values'));
}
export const getView = (views, definitionId, viewId) => find(views, (view) => {
    if (viewId) {
        return view.entityId === viewId;
    }
    return (view.referenceDefinitionId === definitionId &&
        view.isDefault &&
        !view.configuration?.availableOn);
});
export const canCreateEntity = (meta) => {
    return !!(isPropertyItemMeta(meta) &&
        get(meta, 'canCreate', false) &&
        get(meta, 'handler') === CMS_ITEM_META_HANDLER);
};
export const getFeaturedDefinitionIdsOneLevel = (featuredDefinitionIds) => flatMapDeep(featuredDefinitionIds, (value) => (isString(value) ? value : value.values));
export const filterFeaturedDefinitionIds = (featuredDefinitionIds, featuredDefinitionId) => {
    return featuredDefinitionIds
        .map((id) => {
        if (isString(id)) {
            return id !== featuredDefinitionId ? id : null;
        }
        if (id.id === featuredDefinitionId) {
            return null;
        }
        const updatedValues = id.values.filter((value) => value !== featuredDefinitionId);
        return { ...id, values: updatedValues };
    })
        .filter(isFeaturedDefinitionIdT);
};
