import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs } from '../../common/utils/react-query-client';
import { CONTACT_CMS_DEFINITION_ID } from '../../crm';
import { retrieveAccounts } from '../actions';
const RETRIEVE_ACCOUNTS_QUERY_KEY = 'retrieve-accounts';
export const invalidateRetrieveAccountsQuery = async ({ definitionId }) => {
    if (definitionId === CONTACT_CMS_DEFINITION_ID) {
        await queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes(RETRIEVE_ACCOUNTS_QUERY_KEY)
        });
    }
};
export const useRetrieveAccounts = () => {
    const dispatch = useDispatch();
    const { data, isError, isLoading } = useQuery([RETRIEVE_ACCOUNTS_QUERY_KEY], () => {
        return dispatch(retrieveAccounts());
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    return useMemo(() => {
        return {
            data,
            isAvailable: !!data,
            isPending: isLoading,
            isFailed: isError
        };
    }, [data, isError, isLoading]);
};
