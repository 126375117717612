import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import { ConditionQueryField } from '../../../../../../common/components/ConditionQuery';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import { DeadlineField } from '../components/DeadlineField';
import styles from './DecisionForm.module.scss';
const DecisionForm = ({ metaDefinitionId, disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", name: "titles", component: AntInput, disabled: disabled, placeholder: t('COMMON__TITLE') })),
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_DECISION_CRITERIA'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap mb-3" },
            React.createElement(Field, { className: "cp-i-100", name: "query", component: ConditionQueryField, definitionId: metaDefinitionId, disabled: disabled, allowComputed: true })),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_DECISION_WAIT'), help: t('WORKFLOWS_SETTINGS_DECISION_WAIT_INFO'), className: "ps-0" }),
            React.createElement("div", { className: "cp-c-row cp-c-wrap" },
                React.createElement(Field, { name: "wait", component: Toggle, disabled: disabled })),
            React.createElement(Field, { name: "wait", component: DefinitionSection })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled })));
};
const DefinitionSection = ({ input, disabled }) => {
    const { t } = useI18n();
    return input.value ? (React.createElement(React.Fragment, null,
        React.createElement(Field, { name: "delay", className: "mt-3", component: DeadlineField, disabled: disabled, label: t('PROCESS_META__DELAY') }))) : null;
};
export default DecisionForm;
