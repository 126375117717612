import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import SortableList from '../../../components/SortableList';
import { ActivityType } from '../../types';
import { useLoadActivityTypes } from '../../useLoadActivityTypes';
import ActivityIcon from '../ActivityList/ActivityIcon';
import SelectActivityTypeButton from './SelectActivityTypeButton';
const SortableActivityTypeList = ({ value, onChange, disabled, error }) => {
    const { t } = useI18n();
    const { activityTypes, isPending } = useLoadActivityTypes();
    const draggableItems = useMemo(() => {
        if (!value || isPending) {
            return;
        }
        return value.map((item) => {
            const activityType = activityTypes.find((type) => type.entityId === item);
            return {
                id: item,
                item,
                label: (React.createElement("div", { className: "d-flex flex-nowrap align-items-center" },
                    React.createElement(ActivityIcon, { activityType: activityType, className: "DraggableItem_icon" }),
                    React.createElement("div", null, activityType?.title || item)))
            };
        });
    }, [value, activityTypes, isPending]);
    const _onChange = useCallback((newDraggableItems) => {
        if (!onChange) {
            return;
        }
        onChange(newDraggableItems.map((item) => item.item));
    }, [onChange]);
    const addActivityType = useCallback((val) => {
        if (!val) {
            return;
        }
        const newValue = [...(value || [])];
        newValue.push(val);
        onChange?.(newValue);
    }, [onChange, value]);
    const isOptionDisabled = useCallback((val) => {
        return !!value?.includes(val);
    }, [value]);
    const isOptionHidden = useCallback((val) => {
        return val === ActivityType.TASK;
    }, []);
    return (React.createElement(React.Fragment, null,
        draggableItems && (React.createElement(SortableList, { value: draggableItems, onChange: _onChange, disabled: disabled })),
        React.createElement(SelectActivityTypeButton, { className: "w-50", placeholder: `+ ${t('ACTIVITY_ADD_BTN_TITLE')}`, onSelect: addActivityType, disabled: disabled, isOptionDisabled: isOptionDisabled, isOptionHidden: isOptionHidden, error: error }),
        error && React.createElement(ErrorMessage, null, error)));
};
export default SortableActivityTypeList;
