import React from 'react';
import * as Yup from 'yup';
import MultiTextEditor from '../../../../cms/data/components/item-property/editors/MultiTextEditor';
import FormikError from '../../../../common/components/formik/FormikError';
import FormikField from '../../../../common/components/formik/FormikField';
import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import Toggle from '../../../../common/components/Toggle';
export const StefiInitialValues = {
    enabled: false,
    filesLanguage: '',
    username: '',
    password: '',
    makes: []
};
export const getStefiFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.bool(),
        filesLanguage: Yup.string().when('enabled', {
            is: true,
            then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
        }),
        username: Yup.string().when('enabled', {
            is: true,
            then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
        }),
        password: Yup.string().when('enabled', {
            is: true,
            then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
        }),
        makes: Yup.array()
            .of(Yup.string())
            .when('enabled', {
            is: true,
            then: Yup.array().required(t('VALIDATIONS__REQUIRED'))
        })
    });
};
const Form = ({ instance, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.import.stefi.enabled", component: Toggle, label: `${t('ENABLE_INTEGRATION')}`, value: instance.configuration?.import?.stefi?.enabled, disabled: disabled })),
        React.createElement(FormikField, { name: "configuration.import.stefi.filesLanguage", component: BasicInput, label: `${t('INTEGRATIONS_STEFI_LANGUAGE')} *`, value: instance.configuration?.import?.stefi?.filesLanguage, disabled: disabled || !instance.configuration?.import?.stefi?.enabled }),
        React.createElement(FormikField, { name: "configuration.import.stefi.username", component: BasicInput, label: `${t('INTEGRATIONS_STEFI_USER')} *`, value: instance.configuration?.import?.stefi?.username, disabled: disabled || !instance.configuration?.import?.stefi?.enabled }),
        React.createElement(FormikField, { name: "configuration.import.stefi.password", component: BasicInput, label: `${t('INTEGRATIONS_STEFI_PASSWORD')} *`, value: instance.configuration?.import?.stefi?.password, type: "password", disabled: disabled || !instance.configuration?.import?.stefi?.enabled }),
        React.createElement("div", null,
            React.createElement(LabelText, null,
                t('INTEGRATION_VISUEL_BRANDS'),
                " *"),
            React.createElement(FormikField, { name: "configuration.import.stefi.makes", component: MultiTextEditor, value: instance.configuration?.import?.stefi?.makes, disabled: disabled || !instance.configuration?.import?.stefi?.enabled }),
            React.createElement(FormikError, { name: "configuration.import.stefi.makes" }))));
};
export default Form;
