import { Button, Popover } from 'antd';
import React, { useCallback, useState } from 'react';
import { BiColorFill } from 'react-icons/bi';
import { ColorPicker } from '../ColorPicker';
import styles from './SelectColorButton.module.scss';
export const SelectColorButton = ({ onChange, value, icon: Icon = BiColorFill, className = '', preview = true }) => {
    const [visible, setVisible] = useState(false);
    const handleVisibleChang = useCallback(() => {
        setVisible((prevState) => !prevState);
    }, []);
    const handleOnChange = useCallback((newColor) => {
        onChange && onChange(newColor);
        setVisible(false);
    }, [onChange]);
    return (React.createElement(Popover, { trigger: "click", content: React.createElement(ColorPicker, { value: value, onSave: handleOnChange, onCancel: handleVisibleChang }), open: visible, onOpenChange: handleVisibleChang, overlayClassName: styles.popover },
        React.createElement(Button, { style: preview ? { backgroundColor: value } : {}, className: `${styles.button} rounded p-1 ${visible ? 'visible' : ''}  ${className}` },
            React.createElement(Icon, { size: 22, className: `${preview ? styles.icon : ''}` }))));
};
