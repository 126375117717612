import React from 'react';
import styled, { keyframes } from 'styled-components';
const StretchDelayAnimation = keyframes `
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
`;
const Container = styled.div `
  margin: auto;
  width: 70px;
  height: 40px;
  text-align: center;
  font-size: 10px;
`;
const Rectangle = styled.div `
  background-color: ${({ theme }) => theme.mainColorActive};
  height: 100%;
  width: 10px;
  display: inline-block;
  margin-right: 3px;
  animation: ${StretchDelayAnimation} 1.2s infinite ease-in-out;
  animation-delay: ${({ animationDelay }) => animationDelay};
`;
const LoadingRectangles = ({ className }) => (React.createElement(Container, { className: className },
    React.createElement(Rectangle, { animationDelay: "0" }),
    React.createElement(Rectangle, { animationDelay: "-1.1s" }),
    React.createElement(Rectangle, { animationDelay: "-1s" }),
    React.createElement(Rectangle, { animationDelay: "-0.9s" }),
    React.createElement(Rectangle, { animationDelay: "-0.8s" })));
export default LoadingRectangles;
