import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { hasTenantPermission } from '../../auth';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { REQUIRED_CMS_PERMISSION } from '../common/utils/constants';
import { fetchDisplayItemMetaList } from '../data/actions';
const DISPLAY_ITEM_META_LIST_KEY = 'DISPLAY_ITEM_META_LIST_KEY';
export const invalidateDisplayItemMetaListCache = async () => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(DISPLAY_ITEM_META_LIST_KEY)
    });
};
const initialData = [];
const useDisplayItemMetaList = () => {
    const { language } = useI18n();
    const hasRequiredCmsPermission = useSelector((s) => hasTenantPermission(s, REQUIRED_CMS_PERMISSION));
    const dispatch = useDispatch();
    const query = useQuery([DISPLAY_ITEM_META_LIST_KEY, language], () => {
        return dispatch(fetchDisplayItemMetaList(language));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        enabled: hasRequiredCmsPermission
    });
    return {
        ...query,
        data: query.data || initialData
    };
};
export default useDisplayItemMetaList;
