import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import { arrayMoveImmutable } from 'array-move';
import cn from 'classnames';
import { isEmpty, sortBy, without } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { SortableContainer as getSortableContainer, SortableElement as getSortableElement } from 'react-sortable-hoc';
import { useEntityItems } from '../../../cms/hooks/useEntityItems';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import { LoadingBlock } from '../../../common/utils/formUtils';
import { DragHandle } from '../SortablePropertyList/ExtendableSortableItem';
import styles from './SortableKanbanColumnList.module.scss';
export const SortableKanbanColumnList = ({ definitionId, customSelectedColumns, selection, handleChange }) => {
    const { data: results = [], isPending } = useEntityItems({
        definitionId: definitionId || '',
        ids: selection,
        enabled: !!definitionId && !isEmpty(selection) && !customSelectedColumns,
        keepPreviousData: true
    });
    const sortedResults = useMemo(() => sortBy(results, (item) => selection.indexOf(item.entityId)), [results, selection]);
    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        if (customSelectedColumns) {
            return handleChange(arrayMoveImmutable(customSelectedColumns.map(({ value }) => value), oldIndex, newIndex));
        }
        handleChange(arrayMoveImmutable(sortedResults.map(({ entityId }) => entityId), oldIndex, newIndex));
    }, [customSelectedColumns, handleChange, sortedResults]);
    if (isPending) {
        return React.createElement(LoadingBlock, null);
    }
    return (React.createElement(SortableList, { useDragHandle: true, lockAxis: "y", lockToContainerEdges: true, lockOffset: ['0%', '100%'], onSortEnd: onSortEnd, selectedValues: customSelectedColumns ||
            sortedResults.map(({ entityId, title }) => ({
                value: entityId,
                label: title || entityId
            })), handleChange: handleChange }));
};
export const SortableItem = getSortableElement(({ item, label, removeItem }) => {
    const handleRemove = useCallback(() => {
        removeItem(item);
    }, [removeItem, item]);
    return (React.createElement("div", { className: cn(styles.item, 'sortableItem d-flex align-items-center gap-2 justify-content-between') },
        React.createElement("div", { className: "d-flex align-items-center flex-truncate" },
            React.createElement(DragHandle, null),
            React.createElement(TooltipTruncate, null, label)),
        React.createElement(DeleteConfirmationButton, { onClick: handleRemove })));
});
const SortableList = getSortableContainer(({ selectedValues, handleChange }) => {
    const removeItem = useCallback((item) => {
        handleChange(without(selectedValues.map(({ value }) => value), item));
    }, [handleChange, selectedValues]);
    return (React.createElement("ul", { className: cn(styles.wrapper, 'p-0 m-0 overflow-hidden') }, selectedValues.map(({ value, label }, index) => (React.createElement(SortableItem, { key: value, index: index, item: value, label: label, removeItem: removeItem })))));
});
