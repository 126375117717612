import { maxLength, required } from '@hypercharge/hyper-react-base/lib/form';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push as routerPush } from '@hypercharge/hyper-react-base/lib/router';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import flatten from 'flat';
import { isEmpty, map, pickBy, reduce } from 'lodash';
import { touch } from 'redux-form';
import { MAX_DEFINITION_LABEL_LENGTH, SETTINGS_PATH } from '../../../../../common/utils/constants';
import { prepareGroupPermissionsForSubmit } from '../../../../../common/utils/formUtils';
import { validateGroups } from '../../../../../groups';
import { CMS_PATH } from '../../../../common/utils/constants';
import { invalidateDisplayItemMetaListCache } from '../../../../hooks/useDisplayItemMetaList';
import { createItemMeta, fetchMetaDefinition, updateItemMeta } from '../../../actions';
export const formName = 'entityMetaForm';
export const onItemMetaFormSubmit = async (formValues, dispatch, { itemMeta, t }) => {
    const promiseDispatch = dispatch;
    const isNew = !itemMeta;
    const errors = {
        ...validateLabels(formValues),
        ...validateGroups(['viewGroups'], formValues)
    };
    if (!isEmpty(errors)) {
        dispatch(touch(formName, ...map(flatten(errors), (_, key) => key)));
        return errors;
    }
    const postProcessedFormValues = prepareGroupPermissionsForSubmit(formValues);
    try {
        const { definitionId } = await promiseDispatch(isNew
            ? createItemMeta(postProcessedFormValues)
            : updateItemMeta({ ...itemMeta, ...postProcessedFormValues }));
        if (isNew) {
            dispatch(routerPush(`${CMS_PATH}/${definitionId}${SETTINGS_PATH}`));
        }
        dispatch(success({
            title: t('SUCCESS'),
            message: t('SAVE_SETTINGS_SUCCESS')
        }));
        dispatch(fetchMetaDefinition(definitionId));
        void invalidateDisplayItemMetaListCache();
    }
    catch (_) {
        dispatch(error({
            title: t('ERROR'),
            message: t('SAVE_SETTINGS_FAIL')
        }));
    }
};
const getMaxLengthMsg = maxLength(MAX_DEFINITION_LABEL_LENGTH);
const validateLabels = (formValues) => pickBy(reduce(AVAILABLE_LANGUAGES, (acc, language) => {
    const value = formValues?.titles?.[language];
    const requiredMsg = language === DEFAULT_LANGUAGE ? required(value) : undefined;
    const maxLengthMsg = getMaxLengthMsg(value, {});
    if (!requiredMsg && !maxLengthMsg) {
        return acc;
    }
    else {
        return {
            ...acc,
            titles: {
                ...acc.titles,
                [language]: maxLengthMsg || requiredMsg
            }
        };
    }
}, {}));
export const convertToItemMeta = (values) => {
    if (!values) {
        return values;
    }
    const { itemMetaGroups, ...rest } = values;
    const formValue = {
        ...rest,
        ...itemMetaGroups
    };
    return formValue;
};
