import { invalidateItemsQuery } from '../hooks/invalidateCache';
const module = {
    processMessage: (store, message) => {
        switch (message.type) {
            case 'UPDATED_CUSTOM_DATA':
                void invalidateItemsQuery({
                    definitionId: message.payload.definitionId,
                    ids: [message.payload.entityId]
                });
                break;
        }
        return true;
    }
};
export default module;
