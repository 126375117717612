import { compact, map, uniq, values } from 'lodash';
import { TreeUtils } from 'simple-tree-utils';
import { ENTITY_ID_PROPERTY_ID } from '../../../cms';
import { PARENT_GROUP_PROPERTY_ID } from '../../constants';
export const mapTree = (node) => ({
    key: node.entityId,
    title: node.title,
    children: node.children.map(mapTree)
});
export const getParentGroupMap = (groups) => {
    const uniqueGroupIds = uniq(compact(map(groups, ENTITY_ID_PROPERTY_ID)));
    return groups.reduce((acc, group) => ({
        ...acc,
        [group.entityId]: group[PARENT_GROUP_PROPERTY_ID] === group.entityId ||
            !uniqueGroupIds.includes(group[PARENT_GROUP_PROPERTY_ID])
            ? null
            : group[PARENT_GROUP_PROPERTY_ID]
    }), {});
};
export const getParentGroupIds = (parentGroupMap) => uniq(compact(values(parentGroupMap)));
const treeBuilder = new TreeUtils({
    idProp: ENTITY_ID_PROPERTY_ID,
    parentIdProp: PARENT_GROUP_PROPERTY_ID
});
export const getTreeData = (groups, parentGroupMap) => treeBuilder
    .list2Tree(groups.map((group) => ({
    ...group,
    [PARENT_GROUP_PROPERTY_ID]: parentGroupMap[group.entityId]
})))
    .map(mapTree);
export const findTreeItem = (tree, entityId) => {
    for (const treeItem of tree) {
        if (treeItem.key === entityId) {
            return treeItem;
        }
        if (treeItem.children) {
            const nextTreeItem = findTreeItem(treeItem.children, entityId);
            if (nextTreeItem) {
                return nextTreeItem;
            }
        }
    }
    return null;
};
