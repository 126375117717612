import { Drawer as DrawerBase } from 'antd';
import { random } from 'lodash';
import React, { useMemo } from 'react';
import DimensionsListener from '../../DimensionsListener/DimensionsListener';
import styles from './Drawer.module.scss';
const Drawer = ({ children, open, className, closable = false, onClose, onClick, ...otherProps }) => {
    const randomId = useMemo(() => `drawer_${random(1000)}`, []);
    const elementSelectors = useMemo(() => [`.${randomId} .ant-drawer-header`, `.${randomId} .ant-drawer-footer`], [randomId]);
    // ant drawers behaviour is that by default closable is true
    // so unless you explicitly pass false the close button will appear
    const drawerClassName = [className, randomId, closable ? 'ant-drawer-closable' : '']
        .filter((c) => c)
        .join(' ');
    return (React.createElement(DimensionsListener, { elementSelectors: elementSelectors, dependencies: open, trackHeight: true }, (_, height) => {
        return (React.createElement(DrawerBase, { className: `${styles.wrapper} ${drawerClassName}`, onClick: onClick, bodyStyle: { height: `calc(100% - ${height}px)` }, width: "clamp(800px, 40%, 100%)", open: open, closable: closable, onClose: onClose, ...otherProps }, children));
    }));
};
export default Drawer;
