import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ActivityType } from '@hypercharge/portal-utils';
import { Divider, Tooltip } from 'antd';
import { difference, pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SortablePropertyListField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import RichTextEditor from '../../../../../../cms/data/components/item-property/editors/RichTextEditor';
import { SelectActivityTypeField, SortableActivityTypeListField } from '../../../../../../common/activity/components/SelectActivityType';
import { AntInput } from '../../../../../../common/components/AntInput';
import { ConditionQueryField } from '../../../../../../common/components/ConditionQuery';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import TaskPriorityField from '../../../../../common/components/TaskPriorityField/TaskPriorityField';
import { DeadlineField } from '../components/DeadlineField';
import DeadlinePermissionField from '../components/DeadlinePermissionField/DeadlinePermissionField';
import { isPermissionToggleDisabled } from '../utils';
import styles from './ManualActivityForm.module.scss';
const ALLOWED_ROW_PERMISSIONS = ['view', 'write'];
const ManualActivityForm = ({ disabled, metaDefinitionId, parentViewGroups, node, changeFieldValue }) => {
    const { t } = useI18n();
    const isActivityOptionHidden = useCallback((id) => {
        if (id === ActivityType.TASK || !node.parameters?.available?.includes(id)) {
            return true;
        }
    }, [node.parameters?.available]);
    const getRequiredExtraAction = useCallback(({ item }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(Field, { name: `propertyMapping.required['${item.id}']`, component: SelectActivityTypeField, multiple: true, withIcons: false, style: { width: '120px' }, disabled: disabled, isOptionHidden: isActivityOptionHidden }),
            React.createElement(Tooltip, { title: t('OVERRIDE_EDIT_PERMISSION') },
                React.createElement(Field, { name: `propertySettings.required['${item.id}'].canEdit`, component: Toggle, disabled: disabled, className: "ms-2" }))));
    }, [disabled, isActivityOptionHidden, t]);
    const getOptionalExtraAction = useCallback(({ item }) => {
        return (React.createElement(React.Fragment, null,
            React.createElement(Field, { name: `propertyMapping.optional['${item.id}']`, component: SelectActivityTypeField, multiple: true, withIcons: false, style: { width: '100px' }, isOptionHidden: isActivityOptionHidden, disabled: disabled }),
            React.createElement(Tooltip, { title: t('OVERRIDE_EDIT_PERMISSION') },
                React.createElement(Field, { name: `propertySettings.optional['${item.id}'].canEdit`, component: Toggle, disabled: disabled, className: "ms-2" }))));
    }, [disabled, isActivityOptionHidden, t]);
    const onChangeProperties = (newValue = [], prevValue = [], paramName, type, originName) => {
        if (!originName) {
            return;
        }
        const added = difference(newValue, prevValue);
        const removed = difference(prevValue, newValue);
        const newPropertyMapping = pick(newValue.reduce((acc, item) => ({ ...acc, [item]: acc[item] || [] }), node.parameters?.propertyMapping?.[type] || {}), newValue || []);
        changeFieldValue(originName.replace(paramName, `propertyMapping.${type}`), newPropertyMapping);
        for (const property of added) {
            changeFieldValue(originName.replace(paramName, `propertySettings.${type}['${property}'].canEdit`), true);
        }
        for (const property of removed) {
            changeFieldValue(originName.replace(paramName, `propertySettings.${type}['${property}'].canEdit`), undefined);
        }
    };
    const onChangeRowPermissions = useCallback((newRowPermissions) => {
        changeFieldValue(`activity.${node.id}.permissions`, newRowPermissions);
    }, [changeFieldValue, node.id]);
    const forceSelect = useMemo(() => {
        return {
            viewGroups: Object.keys(node?.permissions?.view || {}).reduce((acc, groupId) => {
                if (node?.permissions?.view?.[groupId]?.restrictions?.filters?.length) {
                    acc[groupId] = false;
                }
                return acc;
            }, {}),
            executeGroups: Object.keys(node?.permissions?.write || {}).reduce((acc, groupId) => {
                if (node?.permissions?.write?.[groupId]?.restrictions?.filters?.length) {
                    acc[groupId] = false;
                }
                return acc;
            }, {})
        };
    }, [node]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(MultiLanguageField, { name: "titles", placeholder: t('COMMON__TITLE'), component: AntInput, disabled: disabled, maxLength: 60 })),
        React.createElement(FieldLabel, { label: t('PROPERTY_DESCRIPTION'), className: "ps-0" }),
        React.createElement("div", { className: "flex-row flex-wrap" },
            React.createElement(MultiLanguageField, { name: "descriptions", placeholder: t('COMMON__DESCRIPTION'), component: RichTextEditor, disabled: disabled })),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('DEADLINE'), className: "ps-0" }),
            React.createElement("div", { className: "d-flex align-items-center time-limit" },
                React.createElement(Field, { name: "timeLimit", className: "w-100", component: DeadlineField, disabled: disabled }),
                React.createElement("div", { className: "ms-2 permission-btn" },
                    React.createElement(DeadlinePermissionField, { parentViewGroups: parentViewGroups, timeLimitPermissions: node.parameters?.timeLimitPermissions, disabled: disabled }))),
            React.createElement(FieldLabel, { label: t('PRIORITY'), className: "ps-0" }),
            React.createElement(TaskPriorityField, { disabled: disabled }),
            React.createElement(FieldLabel, { label: t('ACTIVITY_TYPES_LABEL'), className: "ps-0" }),
            React.createElement("div", { className: "flex-row flex-wrap" },
                React.createElement(Field, { name: "available", component: SortableActivityTypeListField, disabled: disabled })),
            React.createElement(FieldLabel, { label: t('TASK_REQUIRED_PROPERTIES'), className: "ps-0" }),
            React.createElement("div", { className: "flex-row flex-wrap" },
                React.createElement(Field, { name: "requiredProperties", component: SortablePropertyListField, definitionId: metaDefinitionId, extraAction: getRequiredExtraAction, disabled: disabled, onChange: (_, newValue, prevValue, name) => {
                        onChangeProperties(newValue, prevValue, 'requiredProperties', 'required', name);
                    } })),
            React.createElement(FieldLabel, { label: t('TASK_OPTIONAL_PROPERTIES'), className: "ps-0" }),
            React.createElement("div", { className: "flex-row flex-wrap" },
                React.createElement(Field, { name: "optionalProperties", component: SortablePropertyListField, definitionId: metaDefinitionId, extraAction: getOptionalExtraAction, disabled: disabled, onChange: (_, newValue, prevValue, name) => {
                        onChangeProperties(newValue, prevValue, 'optionalProperties', 'optional', name);
                    } })),
            React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_REPEAT_CRITERIA'), className: "ps-0" }),
            React.createElement("div", { className: "flex-row flex-wrap mb-3" },
                React.createElement(Field, { name: "conditionQuery", component: ConditionQueryField, definitionId: metaDefinitionId, disabled: disabled, allowComputed: true, withHiddenProperties: true }))),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups, AvailableSystemGroups.executeGroups], component: GroupsField, disabled: disabled, withValidation: true, parentViewGroups: parentViewGroups, itemMetaDefinitionId: metaDefinitionId, rowPermissions: node.permissions, allowedRowPermissions: ALLOWED_ROW_PERMISSIONS, onChangeRowPermissions: onChangeRowPermissions, forceSelect: forceSelect, isPermissionToggleDisabled: (groupId, permission) => {
                return isPermissionToggleDisabled(permission, groupId, node.permissions);
            }, isDynamicPermissionSettings: true })));
};
export default ManualActivityForm;
