import React from 'react';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import styles from './UnsupportedBrowser.module.scss';
const UnsupportedBrowser = () => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.wrapper} container-fluid` },
        React.createElement("div", { className: "row h-100 justify-content-center align-items-center" },
            React.createElement("div", { className: "text-container col col-sm-9 col-md-7 col-lg-5 col-xl-4 p-5 text-center" },
                React.createElement("img", { src: "/assets/images/notSupported.png", height: "65", className: "mx-auto" }),
                React.createElement("h4", { className: "title m-0 py-3" }, t('BROWSER_NOT_SUPPORTED_TITLE')),
                React.createElement("div", null,
                    t('BROWSER_NOT_SUPPORTED_TEXT'),
                    ' ',
                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.google.com/chrome/" }, "Google Chrome"),
                    ",",
                    ' ',
                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.mozilla.org/en-US/firefox/new/" }, "Firefox"),
                    ' ',
                    "or",
                    ' ',
                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://microsoft-edge.en.softonic.com/download" }, "Microsoft Edge"),
                    ".",
                    React.createElement("div", null, t('BROWSER_NOT_SUPPORTED_THANKS')))))));
};
export default UnsupportedBrowser;
