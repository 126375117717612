import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { find } from 'lodash';
import { combineReducers } from 'redux';
const byId = createReducer({}, {
    STORAGE__FETCH_FILES_DATA_SUCCESS: (s, a) => {
        a.payload.forEach((fileData) => {
            s[fileData.id] = fileData;
        });
    },
    STORAGE__UPLOAD_FILE: (s, a) => {
        const data = a.meta.http.actionContext;
        s[data.id] = data;
    }
});
const status = createReducer({}, {
    STORAGE__FETCH_FILES_DATA: (s, a) => {
        const { ids } = a.meta.http.actionContext;
        ids.forEach((id) => {
            s[id] = {
                pending: true,
                failed: false
            };
        });
    },
    STORAGE__FETCH_FILES_DATA_FAIL: (s, a) => {
        const { ids } = a.meta.actionContext;
        ids.forEach((id) => {
            const status = s[id];
            status.pending = false;
            status.failed = true;
        });
    },
    STORAGE__FETCH_FILES_DATA_SUCCESS: (s, a) => {
        const { ids } = a.meta.actionContext;
        ids.forEach((id) => {
            const status = s[id];
            status.pending = false;
            status.failed = !find(a.payload, { id });
        });
    },
    STORAGE__UPLOAD_FILE: (s, a) => {
        const data = a.meta.http.actionContext;
        s[data.id] = {
            pending: true,
            failed: false
        };
    },
    STORAGE__UPLOAD_FILE_SUCCESS: (s, a) => {
        const data = a.meta.actionContext;
        s[data.id] = {
            pending: false,
            failed: false
        };
    },
    STORAGE__UPLOAD_FILE_FAIL: (s, a) => {
        const data = a.meta.actionContext;
        s[data.id] = {
            pending: false,
            failed: true
        };
    }
});
const reducer = combineReducers({
    byId,
    status
});
export default reducer;
