import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import FormikField from '../../../../../common/components/formik/FormikField';
import { Select } from '../../../../../common/components/Select';
import styles from './ImagesForm.module.scss';
import { AntInputField } from '../../../../../common/components/AntInput';
const footerHeaderTemplateList = [
    { value: 'default', label: 'Default' },
    { value: 'custom_theme_1', label: 'Custom theme 1' }
];
const FooterHeaderForm = ({ instance }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: styles.imagesForm },
        React.createElement("div", null,
            React.createElement(LabelText, null, t('APPS_HYPER_DEALER__FOOTER_HEADER_TEMPLATE')),
            React.createElement(FormikField, { name: "configuration.website.footerHeaderTemplate", className: "w-100 overlayImageSelector", component: Select, value: instance.configuration.website.footerHeaderTemplate, options: footerHeaderTemplateList })),
        React.createElement("div", null,
            React.createElement(LabelText, null, t('APPS_HYPER_DEALER__COPYRIGHT_ADDITIONAL_TEXT')),
            React.createElement(FormikField, { name: "configuration.website.copyrightAdditionalText", component: AntInputField, value: instance.configuration.website.copyrightAdditionalText }))));
};
export default FooterHeaderForm;
