import { omit, pick } from 'lodash';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { Loader } from '../utils/utils';
const disabledCss = css `
  cursor: default;
  box-shadow: none;
  opacity: 0.5;
  pointer-events: none;

  > * {
    pointer-events: none;
  }

  &.active,
  &:active,
  &:hover,
  &:focus {
    opacity: 0.5;
    box-shadow: inherit;
  }
`;
const inversedCss = css `
  color: inherit !important;
  background: #fff;
  border-color: ${({ raised, disabled }) => (raised && !disabled ? '#fff' : '#d9d9d9')};
  font-weight: 500;

  &.active,
  &:active,
  &:hover,
  &:focus {
    background-color: #fff;
    color: ${({ disabled, raised, alternative, theme }) => disabled || raised
    ? 'inherit'
    : alternative
        ? theme.alternativeColor
        : theme.mainColor} !important;
    border-color: ${({ alternative, theme }) => alternative ? theme.alternativeColor : theme.mainColor} !important;
  }
`;
const padding = '0.5rem 1rem';
const borderWidth = '1px';
const noBorderCss = css `
  border-width: 0;
  padding: calc(${padding} + ${borderWidth});
`;
const raisedCss = css `
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  ${({ disabled }) => !disabled && noBorderCss};

  &.active,
  &:active,
  &:hover,
  &:focus {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
`;
const enabledPseudoCss = css `
  &.active,
  &:active,
  &:hover,
  &:focus {
    background-color: ${({ alternative, theme }) => alternative ? theme.alternativeColorActive : theme.mainColorActive};

    border-color: ${({ alternative, theme }) => alternative ? theme.alternativeColorActive : theme.mainColorActive};

    color: ${({ alternative, inversed, disabled, theme }) => inversed && disabled
    ? alternative
        ? theme.alternativeColorActive
        : theme.mainColorActive
    : '#fff'} !important;
  }
`;
const Ripple = styled.div ``;
const ButtonEffectAnimation = keyframes `
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
`;
const rippleCss = css `
  ${Ripple} {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    transform: scale(0);
  }

  &.btn-animate ${Ripple} {
    animation: ${ButtonEffectAnimation} 0.5s linear;
  }
`;
export const buttonCss = css `
  user-select: none;
  color: #fff !important;
  background: ${({ alternative, theme }) => alternative ? theme.alternativeColor : theme.mainColor};
  border-width: ${borderWidth};
  border-style: solid;
  border-color: ${({ alternative, theme }) => alternative ? theme.alternativeColor : theme.mainColor};
  border-radius: 4px;
  position: relative;
  margin: 0;
  display: inline-block;
  font-weight: 600;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  line-height: 1;
  letter-spacing: 0;
  transition-duration: 0.3s;
  transition-property: box-shadow, opacity, background, border, color, max-width;
  will-change: box-shadow, opacity, background, border, color, max-width;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  padding: ${padding};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  font-size: 0.9rem;

  &,
  &:active,
  &:focus {
    outline: 0;
  }

  ${({ disabled }) => !disabled && enabledPseudoCss};

  ${({ raised }) => raised && raisedCss};

  ${({ inversed }) => inversed && inversedCss};

  ${({ disabled }) => disabled && disabledCss};

  ${rippleCss};
`;
const StyledButton = styled.button.attrs(({ alternative, theme }) => ({
    alternative: alternative == 'true' || (theme.alternativeByDefault && alternative != 'false') ? 'true' : null
})) `
  ${buttonCss};
`;
const StyledAnchor = StyledButton.withComponent('a');
const StyledLink = StyledButton.withComponent(Link);
const propsToOmit = [
    'onClick',
    'disabled',
    'children',
    'type',
    'raised',
    'loading',
    'inversed',
    'alternative',
    'uppercase'
];
export class Button extends PureComponent {
    constructor() {
        super(...arguments);
        this.ripple = null;
        this.unmounted = false;
        this.state = {
            loading: false
        };
        this.handleContactEnd = (e) => {
            if (!this.props.onClick) {
                return;
            }
            const parent = ReactDOM.findDOMNode(this);
            if (parent) {
                parent.classList.remove('btn-animate');
                const ripple = this.ripple || { style: {} };
                const d = Math.max(parent.getBoundingClientRect().width, parent.getBoundingClientRect().height);
                ripple.style.height = d + 'px';
                ripple.style.width = d + 'px';
                const x = e.nativeEvent.offsetX - d / 2;
                const y = e.nativeEvent.offsetY - d / 2;
                ripple.style.left = x + 'px';
                ripple.style.top = y + 'px';
                parent.classList.add('btn-animate');
                setTimeout(() => {
                    if (!this.unmounted) {
                        parent.classList.remove('btn-animate');
                    }
                }, 600);
                this.handleOnButtonClick(e);
            }
        };
        this.handleOnButtonClick = (e) => {
            const { onClick } = this.props;
            if (onClick) {
                e.preventDefault();
                const result = onClick(e);
                if (result && result.finally) {
                    !this.unmounted && this.setState({ loading: true });
                    result.finally(() => {
                        if (!this.unmounted) {
                            this.setState({ loading: false });
                        }
                    });
                }
            }
        };
        this.onSubmit = (e) => {
            if (e.key === 'Enter' || e.code === 'Space') {
                this.handleOnButtonClick(e);
            }
        };
        this.getButtonComponent = () => {
            if (this.props.href) {
                return StyledAnchor;
            }
            else if (this.props.to) {
                return StyledLink;
            }
            return StyledButton;
        };
        this.getStylingProps = (originalObject, properties) => properties.reduce((acc, property) => {
            if (originalObject[property]) {
                acc[property] = 'true';
            }
            else if (originalObject[property] === false) {
                acc[property] = 'false';
            }
            return acc;
        }, {});
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    render() {
        const { inversed, disabled, loading, active, className, type, children, ...otherProps } = this.props;
        const isLoading = this.state.loading || loading;
        const ButtonComponent = this.getButtonComponent();
        return (React.createElement(ButtonComponent, { ...omit(this.props, propsToOmit), className: `${active ? 'active ' + (className || '') : className || ''}`, type: otherProps.href || otherProps.to ? undefined : type || 'button', onMouseUp: this.handleContactEnd, disabled: disabled || isLoading, onKeyPress: this.onSubmit, onClick: (e) => e.stopPropagation(), ...this.getStylingProps(this.props, [
                'raised',
                'loading',
                'inversed',
                'alternative',
                'uppercase'
            ]) },
            children,
            React.createElement(Ripple, { ref: (ripple) => (this.ripple = ripple ? pick(ripple, ['style']) : null) }),
            isLoading && React.createElement(Loader, { inversed: inversed })));
    }
}
export default {
    Button,
    buttonCss
};
