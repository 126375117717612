import { browserReducer as browser } from '@hypercharge/hyper-react-base/lib/browser';
import { i18nReducerFactory } from '@hypercharge/hyper-react-base/lib/i18n';
import { metadataReducer as metadata } from '@hypercharge/hyper-react-base/lib/metadata';
import { reducer as notifications } from '@hypercharge/hyper-react-base/lib/notifications';
import { reducer as permissions } from '@hypercharge/hyper-react-base/lib/permissions';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import { reducer as form } from 'redux-form';
import { authReducer as auth } from '../../auth';
import { reducer as cms } from '../../cms';
import { reducer as configs } from '../../configs';
import { reducer as forms } from '../../forms';
import { tenantReducer as tenant } from '../../tenant';
import { reducer as workflows } from '../../workflows';
import { default as messages } from '../conversation/reducer';
import { reducer as userNotifications } from '../notifications';
import { reducer as storage } from '../storage';
const reducers = {
    i18n: i18nReducerFactory(DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES),
    form,
    metadata,
    // @ts-expect-error The reducer type for `notification` don't match the one in `GlobalStateT`
    notifications,
    // @ts-expect-error The reducer type for `browser` don't match the one in `GlobalStateT`
    browser,
    auth,
    tenant,
    workflows,
    cms,
    permissions,
    configs,
    messages,
    userNotifications,
    storage,
    forms
};
export default reducers;
