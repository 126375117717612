import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import { some } from 'lodash';
import React, { useMemo } from 'react';
import { IoMdAlert } from 'react-icons/io';
import { ALL_USERS_PERMISSION_GROUP } from '../../constants';
import styles from './ParentViewPermissionWarningIcon.module.scss';
const ParentViewPermissionWarningIcon = ({ groupId, isGroupChecked, parentViewGroups, groupListNames }) => {
    const { t } = useI18n();
    const isParentContainsRequiredGroups = useMemo(() => parentViewGroups &&
        !parentViewGroups.includes(ALL_USERS_PERMISSION_GROUP) &&
        !parentViewGroups.includes(groupId), [groupId, parentViewGroups]);
    const isSomeGroupsChecked = useMemo(() => some(groupListNames, (name) => isGroupChecked(name, groupId)), [groupId, groupListNames, isGroupChecked]);
    return isParentContainsRequiredGroups && isSomeGroupsChecked ? (React.createElement(Tooltip, { title: t('GROUP_VISIBILITY_WARNING') },
        React.createElement(IoMdAlert, { className: styles.styledAlert }))) : null;
};
export default ParentViewPermissionWarningIcon;
