import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GroupsField from '../../../groups';
import { AvailableSystemGroups } from '../../../groups/constants';
import { Drawer, DrawerContent, DrawerFooter } from '../Drawer';
import { DrawerContentBlock } from '../Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../Drawer/DrawerFormFooter';
const PERMISSIONS = [AvailableSystemGroups.readGroups, AvailableSystemGroups.writeGroups];
const ShareDrawer = ({ title, open, onClose, className, permissions, permissionForProperties, onSave }) => {
    const [value, setValue] = useState(undefined);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setValue(permissions);
    }, [permissions]);
    const onSubmit = useCallback(async () => {
        setLoading(true);
        await onSave(value);
        setLoading(false);
    }, [onSave, value]);
    const onChangeReadGroups = useCallback((val) => {
        const view = val.reduce((res, group) => {
            res[group] = { isEnabled: true };
            return res;
        }, {});
        setValue({ ...value, view });
    }, [value]);
    const onChangeWriteGroups = useCallback((val) => {
        const write = val.reduce((res, group) => {
            res[group] = { isEnabled: true, properties: permissionForProperties };
            return res;
        }, {});
        setValue({ ...value, write });
    }, [value, permissionForProperties]);
    const readGroups = useMemo(() => {
        const groups = Object.keys(value?.view || {}).filter((group) => !!value?.view?.[group].isEnabled);
        return {
            input: {
                value: groups,
                onChange: onChangeReadGroups
            },
            meta: {
                error: undefined,
                submitFailed: false
            }
        };
    }, [onChangeReadGroups, value?.view]);
    const writeGroups = useMemo(() => {
        const groups = Object.keys(value?.write || {}).filter((group) => !!value?.write?.[group].isEnabled);
        return {
            input: {
                value: groups,
                onChange: onChangeWriteGroups
            },
            meta: {
                error: undefined,
                submitFailed: false
            }
        };
    }, [onChangeWriteGroups, value?.write]);
    return (React.createElement(Drawer, { title: title, onClose: onClose, open: open, className: className },
        React.createElement(DrawerContent, null,
            React.createElement(DrawerContentBlock, null,
                React.createElement(GroupsField, { names: PERMISSIONS, readGroups: readGroups, writeGroups: writeGroups, disabled: loading, withOwner: false }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onCancel: onClose, onSubmit: onSubmit, disabled: loading }))));
};
export default ShareDrawer;
