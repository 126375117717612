import { BasicInput, BasicSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Divider } from 'antd';
import { Field } from 'formik';
import React, { useCallback, useState } from 'react';
import { GoGear, GoPlus, GoX } from 'react-icons/go';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { AggregationType } from '../../../../../../cms/hooks/useAggregation';
import { Drawer } from '../../../../../../common/components/Drawer';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { AggregationsOptions, getDefaultAggregation, isAvailableAggregationField } from '../../../aggregations';
import CustomOrderTerms from './CustomOrderTerms';
import { DateHistogramIntervalOptions, TermsOrderByOptions } from './utils';
const AggregationField = ({ allowSubAggregations, field: { name, value }, form: { values, setFieldValue } }) => {
    const { t, language } = useI18n();
    const [showCustomOrder, setShowCustomOrder] = useState(false);
    const onCloseCustomOrderForm = useCallback(() => {
        setShowCustomOrder(false);
    }, []);
    const onOpenCustomOrderForm = useCallback(() => {
        setShowCustomOrder(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(FormikField, { name: `${name}.type`, component: BasicSelect, options: AggregationsOptions, label: t('HYPER_BI_AGGREGATION'), language: language }),
        values.definitionId && (React.createElement(React.Fragment, null,
            React.createElement(FormikField, { name: `${name}.field`, asFilter: true, component: SelectPropertyField, label: t('HYPER_BI_PROPERTY'), definitionId: values.definitionId, placeholder: t('SELECT_PROPERTY'), includeProperty: (displayData) => {
                    return isAvailableAggregationField(value.type, displayData);
                }, includeNestedProperties: true }))),
        value.type === AggregationType.term && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col" },
                    React.createElement(FormikField, { name: `${name}.orderBy`, component: BasicSelect, options: TermsOrderByOptions, label: t('HYPER_BI_ORDER_BY_LABEL'), emptyDisabled: false, language: language })),
                value.type === AggregationType.term && values.chart.type === 'funnel' && (React.createElement("div", { className: "col-auto ps-0 pt-1" },
                    React.createElement(Button, { className: "mt-4", type: "text", icon: React.createElement(GoGear, null), onClick: onOpenCustomOrderForm })))),
            React.createElement(FormikField, { name: `${name}.size`, component: BasicInput, min: 1, type: "number", label: t('HYPER_BI_SIZE_LABEL'), language: language }))),
        value.type !== AggregationType.term && (React.createElement(React.Fragment, null,
            React.createElement(FormikField, { name: `${name}.interval`, component: BasicSelect, options: DateHistogramIntervalOptions, label: t('HYPER_BI_INTERVAL_LABEL'), language: language, emptyDisabled: false }))),
        'aggregations' in value && value.aggregations && value.aggregations.length ? (React.createElement(React.Fragment, null,
            React.createElement(Divider, null, t('HYPER_BI_SUB_AGGREGATION_LABEL')),
            React.createElement("div", null,
                React.createElement(Field, { name: `${name}.aggregations[0]`, component: AggregationField }),
                React.createElement(Button, { className: "mt-2", type: "primary", danger: true, icon: React.createElement(GoX, { size: "1.2em", className: "me-2" }), onClick: () => {
                        setFieldValue(`${name}.aggregations`, undefined);
                    } }, t('HYPER_BI_REMOVE_SUB_AGGREGATION_LABEL'))))) : null,
        allowSubAggregations ? (React.createElement(Button, { className: "mt-2", type: "primary", icon: React.createElement(GoPlus, { size: "1.2em", className: "me-2" }), onClick: () => {
                setFieldValue(`${name}.aggregations`, [getDefaultAggregation()]);
            } }, t('HYPER_BI_ADD_SUB_AGGREGATION_LABEL'))) : null,
        React.createElement(Drawer, { title: t('HYPER_BI_CUSTOM_ORDER_FORM_TITLE'), placement: "right", open: showCustomOrder, onClose: onCloseCustomOrderForm, destroyOnClose: true },
            React.createElement(FormikField, { name: `${name}.customOrder`, component: CustomOrderTerms, aggregation: value, definitionId: values.definitionId }))));
};
export default AggregationField;
