import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useEntityDisplayData, useNestedDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { getPropertiesTree } from './utils';
export const usePropertiesTree = (definitionId) => {
    const { displayData } = useEntityDisplayData(definitionId);
    const { displayData: nestedDisplayData } = useNestedDisplayData(displayData);
    return useMemo(() => {
        if (displayData && nestedDisplayData) {
            const nestedDisplayDataMap = keyBy(nestedDisplayData, 'definitionId');
            return getPropertiesTree(displayData, nestedDisplayDataMap);
        }
        return undefined;
    }, [displayData, nestedDisplayData]);
};
