import { BasicInput, createValidator, required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
import FormikField from '../../../../common/components/formik/FormikField';
import { useAddPaymentMethod } from '../hooks/useAddPaymentMethod';
import { invalidateSearchPaymentCardsQuery } from '../hooks/useSearchPaymentCards';
import styles from './PaymentContainer.module.scss';
const initialValues = {
    cardHolderName: ''
};
const formValidator = createValidator({ cardHolderName: [required] });
const cardElementOptions = {
    style: {
        base: {
            fontSize: '18px',
            lineHeight: '40px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#9e2146'
        }
    }
};
const PaymentCardForm = ({ onClose, setLoading, loading, onError, onLoad }) => {
    const { t } = useI18n();
    const stripe = useStripe();
    const elements = useElements();
    const { mutateAsync: addPaymentMethod } = useAddPaymentMethod();
    const handleSubmit = useCallback(async (formValues) => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);
        if (!cardElement) {
            return;
        }
        setLoading(true);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: formValues?.cardHolderName
            }
        });
        if (error) {
            onError(error);
            setLoading(false);
            return;
        }
        try {
            const { clientSecret } = await addPaymentMethod(paymentMethod.id);
            if (clientSecret) {
                const { error } = await stripe.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: formValues?.cardHolderName
                        }
                    }
                });
                if (error) {
                    onError(error);
                    return;
                }
            }
            onClose();
            void invalidateSearchPaymentCardsQuery();
        }
        catch (e) {
            onError(e);
        }
        finally {
            setLoading(false);
        }
    }, [addPaymentMethod, elements, onClose, onError, setLoading, stripe]);
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: handleSubmit, validate: formValidator }, ({ submitForm }) => (React.createElement(Form, { noValidate: true, className: styles.cardForm },
        React.createElement(DrawerContent, null,
            React.createElement(DrawerContentBlock, { title: t('PAYMENT_CARD_NUMBER'), className: "cardField" },
                React.createElement(CardNumberElement, { options: cardElementOptions, onReady: onLoad })),
            React.createElement(DrawerContentBlock, { title: t('PAYMENT_CARD_EXPIRATION'), className: "cardField cardExpiration" },
                React.createElement(CardExpiryElement, { options: cardElementOptions }),
                React.createElement("label", null,
                    "CVC",
                    React.createElement(CardCvcElement, { options: cardElementOptions }))),
            React.createElement(DrawerContentBlock, { title: t('PAYMENT_CARD_CARDHOLDER_NAME'), className: "cardField" },
                React.createElement(FormikField, { type: "text", className: "StripeElement", name: "cardHolderName", component: BasicInput, placeholder: "John Johnson" }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onCancel: onClose, submitting: loading, onSubmit: submitForm }))))));
};
export default PaymentCardForm;
