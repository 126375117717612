import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ActionsButton from '../../../../../common/components/ActionsButton';
import { CMS_PATH } from '../../../../common/utils/constants';
import { invalidateDisplayItemMetaListCache } from '../../../../hooks/useDisplayItemMetaList';
import { deleteItemMeta } from '../../../actions';
const EntityActions = ({ className, canDelete = true, canSave = true, save, saving, disabled, id }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const deleteItemMetaDispatch = useDispatch();
    const deleteAction = useCallback(async () => {
        try {
            await deleteItemMetaDispatch(deleteItemMeta(id));
            await invalidateDisplayItemMetaListCache();
            dispatch(replace(CMS_PATH));
            dispatch(success({
                title: t('SUCCESS'),
                message: t('ENTITY_DELETE_CONFIRMATION')
            }));
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('ENTITY_DELETE_FAIL')
            }));
            throw err;
        }
    }, [deleteItemMetaDispatch, dispatch, id, t]);
    return (React.createElement(ActionsButton, { className: `d-inline-block ${className || ''}`, buttonAction: {
            action: save,
            canRun: canSave,
            buttonLabel: 'FORM__SAVE'
        }, menuActions: [
            {
                action: deleteAction,
                canRun: canDelete,
                buttonLabel: 'DELETE_ENTITY',
                confirmationLabel: 'DELETE_ENTITY',
                delete: true
            }
        ], submitting: saving, disabled: disabled, inversed: false }));
};
export default EntityActions;
