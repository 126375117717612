import React from 'react';
import { generateId } from '../utils';
import { error as notificationError, info as notificationInfo, success as notificationSuccess, warning as notificationWarning } from 'react-notification-system-redux';
import { MdCheck, MdClose, MdInfo } from 'react-icons/md';
import { notificationsPosition } from './components/AppNotifications';
const addDefaultConfig = (config) => ({
    uid: generateId(),
    position: notificationsPosition || 'tc',
    autoDismiss: 2,
    ...config
});
const iconProps = {
    color: 'white',
    size: 24
};
export const success = (config) => {
    if (config.title) {
        config.title = [React.createElement(MdCheck, { key: "icon", ...iconProps }), config.title];
    }
    return notificationSuccess(addDefaultConfig(config));
};
export const info = (config) => {
    if (config.title) {
        config.title = [React.createElement(MdInfo, { key: "icon", ...iconProps }), config.title];
    }
    return notificationInfo(addDefaultConfig(config));
};
export const error = (config) => {
    if (config.title) {
        config.title = [React.createElement(MdClose, { key: "icon", ...iconProps }), config.title];
    }
    return notificationError(addDefaultConfig(config));
};
export const warning = (config) => {
    if (config.title) {
        config.title = [React.createElement(MdInfo, { key: "icon", ...iconProps }), config.title];
    }
    return notificationWarning(addDefaultConfig(config));
};
export default {
    success,
    error,
    warning,
    info
};
