import { DEFAULT_LANGUAGE, INITIAL_PAGE, INITIAL_PAGE_SIZE } from 'config';
import { createContext, useContext } from 'react';
import { DEFAULT_FILTERS_CONDITION } from '../constants';
import { RowHeightT } from '../types';
export const initialFilterRequest = {
    query: {
        condition: DEFAULT_FILTERS_CONDITION,
        filters: []
    },
    sortBy: [],
    fullText: '',
    limit: INITIAL_PAGE_SIZE,
    offset: 0,
    languageCode: DEFAULT_LANGUAGE
};
export const ViewContext = createContext({
    definitionId: undefined,
    filterRequest: initialFilterRequest,
    filterRequestForSearch: initialFilterRequest,
    page: INITIAL_PAGE,
    pageSize: INITIAL_PAGE_SIZE,
    rowHeightType: RowHeightT.SMALL,
    changePage: () => undefined,
    changePageSize: () => undefined,
    changeRowHeightType: () => undefined,
    changeSortBy: () => undefined,
    changeFullTextSearch: () => undefined,
    updateRouteWithView: () => undefined,
    view: undefined,
    metrics: [],
    viewType: undefined,
    kanban: undefined,
    hasViewCustomizations: false,
    canSelectAll: false,
    changedVirtualView: false,
    virtualViewMode: false,
    displayDataStatus: {
        isAvailable: false,
        isFailed: false,
        isPending: false
    },
    searchItems: {
        data: undefined,
        isFailed: false,
        isPending: false,
        isAvailable: false
    },
    isAllItemsSelected: false,
    isAllItemsSelectedOnPage: false,
    resetVirtualView: () => undefined,
    resetView: () => undefined,
    initialSelectedItems: [],
    selectedItems: [],
    setIsAllItemsSelected: () => undefined,
    setIsAllItemsSelectedOnPage: () => undefined,
    setSelectedItems: () => undefined,
    filterSelected: undefined,
    setFilterSelected: () => undefined,
    totalItemsCount: 0
});
export const useView = () => {
    const context = useContext(ViewContext);
    if (context == null) {
        throw new Error('useView must be used within an ViewProvider');
    }
    return context;
};
