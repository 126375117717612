import { PORTAL_LOGO_SRC, TENANT_LOGO_SRC } from 'config';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDisplayTenant } from '../../../../tenant/useDisplayTenant';
import { getPositionClassName } from '../utils';
import styles from './Logo.module.scss';
const Logo = () => {
    const { displayTenant } = useDisplayTenant();
    const positionClassName = useMemo(() => getPositionClassName(displayTenant?.loginPage?.logoPosition), [displayTenant?.loginPage?.logoPosition]);
    return (React.createElement(Link, { className: `${styles.logoWrapper} d-flex align-items-center ${positionClassName}`, to: "/" },
        React.createElement("img", { src: displayTenant?.loginPage?.logoUrl || TENANT_LOGO_SRC || PORTAL_LOGO_SRC, alt: "logo", className: `${styles.logo} m-0` })));
};
export default Logo;
