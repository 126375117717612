import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchProperty, getProperty, saveProperty } from '@hypercharge/hyper-react-base/lib/metadata';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { goBack } from '@hypercharge/hyper-react-base/lib/router';
import { TENANT_ID } from 'config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { getMetadataState } from '../../../common/reducers/rootSelector';
import { propertyFormName } from '../../utils/constants';
import PropertyForm from './PropertyForm';
const onSubmit = (formValues, dispatch, props) => props
    .saveProperty(props.definitionId, props.propertyId, {
    ...props.property,
    tenantId: TENANT_ID,
    definitionId: props.definitionId,
    ...formValues,
    typeData: formValues.typeData || {},
    groupPermissions: {
        employees: { readable: true, writable: true },
        everyone: { readable: true, writable: false }
    }
})
    .then(props.goBack)
    .catch((e) => {
    props.error({
        title: props.t('ERROR'),
        message: props.t('ADD_PROPERTY_FAILED')
    });
});
class PropertyFormContainer extends Component {
    componentDidMount() {
        const { propertyId, definitionId } = this.props;
        if (propertyId) {
            this.props.fetchProperty(definitionId, propertyId);
        }
    }
    render() {
        // @ts-expect-error
        return React.createElement(PropertyForm, { ...this.props });
    }
}
function mapStateToProps(state, { match }) {
    const metadataState = getMetadataState(state);
    const definitionId = match.params.definitionId;
    const propertyId = match.params.propertyId;
    let property;
    let initialValues;
    if (propertyId) {
        property = getProperty(metadataState, definitionId, propertyId);
        initialValues = {
            ...property
        };
    }
    return {
        property,
        definitionId,
        propertyId,
        // @ts-expect-error
        initialValues,
        formValues: getFormValues(propertyFormName)(state),
        showDependantValues: (propertyId) => {
            if (!propertyId) {
                return false;
            }
            const prop = getProperty(metadataState, definitionId, propertyId);
            if (prop && prop.fieldType == 'select') {
                return true;
            }
            return false;
        }
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(withProps(() => {
    const { t } = useI18n();
    return { t };
}), connect(mapStateToProps, { fetchProperty, goBack, error, saveProperty }), reduxForm({
    form: propertyFormName,
    enableReinitialize: true,
    onSubmit
}))(PropertyFormContainer);
