import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEmpty, times } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { invalidateItemsQuery } from '../../../cms/hooks/invalidateCache';
import { useEntityItem } from '../../../cms/hooks/useEntityItem';
import { useSearchItems } from '../../../cms/hooks/useSearchItems';
import { APPS_PATH, SETTINGS_PATH } from '../../constants';
import { saveInstanceConfig } from './actions';
import AppInstance from './components/AppInstance';
import { HYPER_APPS_DEFINITION_ID } from './constants';
const AppPage = ({ setLinks, setCurrentBreadcrumbText, match: { params: { appId } } }) => {
    const { t } = useI18n();
    const mockNewApp = useMemo(() => ({
        entityId: 'new',
        definitionId: appId,
        title: t('APPS__ADD_NEW'),
        enabled: true,
        configuration: {}
    }), [appId, t]);
    const saveAppInstanceConfigDispatch = useDispatch();
    const { data: { results: appInstances = [] } = {}, isPending: isPendingAppInstances, isAvailable: isAvailableAppInstances } = useSearchItems({
        definitionId: appId,
        filterRequest: {
            responseFields: ['title', 'enabled', 'logo'],
            limit: 500
        }
    });
    const { data: app, isFetching: isPendingApp } = useEntityItem(HYPER_APPS_DEFINITION_ID, appId, ['*']);
    useEffect(() => {
        const links = [
            { text: t('SETTINGS'), to: SETTINGS_PATH },
            { text: t('APPS'), to: `${SETTINGS_PATH}${APPS_PATH}` }
        ];
        setLinks(links);
        if (app) {
            setCurrentBreadcrumbText(app.title);
        }
    }, [app, setCurrentBreadcrumbText, setLinks, t]);
    const toggleInstance = useCallback(async (appInstance) => {
        if (app) {
            await saveAppInstanceConfigDispatch(saveInstanceConfig(app.entityId, appInstance.entityId, {
                entityId: appInstance.entityId,
                enabled: !appInstance.enabled
            }));
            await invalidateItemsQuery({
                definitionId: appId,
                waitNewData: true,
                ids: [appInstance.entityId],
                changedPropertyIds: ['enabled']
            });
        }
        appInstance.enabled = !appInstance.enabled;
    }, [app, appId, saveAppInstanceConfigDispatch]);
    const instances = useMemo(() => {
        let items = [];
        if (!isEmpty(appInstances)) {
            items = [...appInstances];
        }
        if (app && (app.configuration.isMultiInstance || appInstances.length === 0)) {
            items.push(mockNewApp);
        }
        return items;
    }, [app, appInstances, mockNewApp]);
    if ((isAvailableAppInstances && isPendingAppInstances) || isPendingApp) {
        return (React.createElement("div", { className: "m-2 p-4 with-background" },
            React.createElement(Skeleton, { isLoading: true },
                React.createElement("div", { className: "row" }, times(4, (idx) => (React.createElement("div", { className: "col-md-4 col-sm-6 col-xs-12 pb-2", key: idx },
                    React.createElement(TextPlaceholder, { key: idx, heightInPx: 170, radiusInPx: 4 }))))))));
    }
    return (React.createElement("div", { className: "m-2 p-4 with-background" },
        React.createElement("div", { className: "row" }, instances.map((appInstance, index) => (React.createElement("div", { className: "col-md-4 col-sm-6 col-xs-12 pb-2", key: index },
            React.createElement(AppInstance, { appInstance: appInstance, toggleApp: toggleInstance })))))));
};
export default AppPage;
