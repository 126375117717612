import { ConditionType, FilterOperatorTypes, isConditionPassed } from '@hypercharge/portal-utils';
import { isEmpty } from 'lodash';
import { TITLE_PROPERTY_ID } from '../../../../cms';
import { isPropertyItemMeta } from '../../../../cms/data/types';
import { DEFAULT_SORT_BY_FIELDS_VIEW } from '../../../../views/constants';
export const getPropertyStyles = (meta, item) => {
    if (!isEmpty(meta.conditionalFormatting)) {
        const theme = meta.conditionalFormatting?.find((conditionalFormatting) => conditionalFormatting.filter && isConditionPassed(item, conditionalFormatting.filter))?.theme;
        return {
            backgroundColor: theme?.primary,
            color: theme?.secondary
        };
    }
    return undefined;
};
export const getInitialFiltersQuery = (meta) => {
    const defaultRequest = {
        fullTextFields: [TITLE_PROPERTY_ID],
        query: { condition: ConditionType.or, filters: [] },
        responseFields: [TITLE_PROPERTY_ID],
        sortBy: []
    };
    const filterRequest = isPropertyItemMeta(meta)
        ? { ...(meta?.selector?.filtering?.filterRequest || defaultRequest) }
        : defaultRequest;
    if (isEmpty(filterRequest.sortBy)) {
        filterRequest.sortBy = DEFAULT_SORT_BY_FIELDS_VIEW;
    }
    return filterRequest;
};
export const getDefaultValueForOnlySelectedSwitch = (meta) => (isPropertyItemMeta(meta) &&
    meta?.selector?.onlySelectedSwitch?.defaultValue &&
    FilterOperatorTypes.in) ||
    undefined;
