export const WORKFLOWS_PATH = '/workflows';
export const PROCESSES_PATH = '/processes';
export const FEATURED_PROCESSES_PATH = '/featured-processes';
export const PROCESS_DETAILS_PATH = '/details';
export const WORKFLOWS_YOUR_DASHBOARD_PATH = '/workflows-your-dashboard';
export const ACTIVITY_YOUR_DASHBOARD_PATH = '/activity-your-dashboard';
export const ALL_PROCESSES_DEFINITION_ID = 'allProcesses';
export const ALL_TASKS_DEFINITION_ID = 'allTasks';
export const REQUIRED_WORKFLOWS_PERMISSION = 'WORKFLOWS';
export const TASK_META_OVERRIDE_PERMISSION = 'TASK_META_OVERRIDE';
export const WORKFLOWS_API_PATH = '/v2-workflows';
export const WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE = 'workflows'; // Requires the definitionId behind it: `workflows__someDefinitionId` TODO: migrate old ones that just have 'workflows', and also note that this requires the merge of processMetaId and definitionId
export const WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE_DEFINITION_ID_REGEXP = new RegExp(`${WORKFLOWS_PROCESS_CONVERSATION_NAMESPACE}__(.+)`);
export const PROCESS_DEADLINE_PROPERTY_ID = 'processDeadline';
export const PROCESS_LINK_PROPERTY_ID = 'processLink';
export const PROCESS_ACTIVITY_PROPERTY_ID = 'processActivity';
export const TASK_PRIORITY_DEFINITION_ID = 'taskPriority';
export const TASK_DEADLINE_PROPERTY_ID = 'taskDeadline';
export const TASK_PRIORITY_PROPERTY_ID = 'taskPriority';
export const TASK_STATUS_PROPERTY_ID = 'taskStatus';
export const TASK_ASSIGNED_TO_PROPERTY_ID = 'taskAssignedTo';
export const TASK_PROCESS_LINK_PROPERTY_ID = 'taskProcessLink';
export const TASK_PROCESS_META_ID_PROPERTY_ID = 'taskProcessMetaId';
