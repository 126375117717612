export const getErrorMessage = (e) => {
    if (e && typeof e === 'object') {
        // @ts-expect-error
        if (e?.response?.data?.message) {
            // @ts-expect-error
            return e.response.data.message;
            // @ts-expect-error
        }
        else if (e?.message) {
            // @ts-expect-error
            return e.message;
        }
    }
    return 'Unknown error';
};
