import React from 'react';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { ScExternalLink, ScExternalLinkIcon } from '../../../../../../common/components/data-table/utils';
import { FieldWrapper } from '../../FieldElements';
const ensureWebsiteProtocol = (url) => url.match(/(^https?:\/\/)|(^\/.+)/g) ? url : `//${url}`;
const isRelativeUrl = (url) => !!url.match(/^\/.+/g);
const getContent = (value) => {
    if (value?.link) {
        const isExternalLink = !isRelativeUrl(value.link);
        return (React.createElement(ScExternalLink, { className: "mw-100 d-flex", href: ensureWebsiteProtocol(value.link), title: value.link, onClick: (e) => e.stopPropagation(), ...(isExternalLink ? { target: '_blank' } : {}) },
            React.createElement(TooltipTruncate, null, value.text || value.link),
            isExternalLink && React.createElement(ScExternalLinkIcon, { size: 14 })));
    }
    return React.createElement(TooltipTruncate, null, value?.text || '\u00a0');
};
const LinkDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, className: `${className ?? ''}`, simple: !asField, disabled: disabled, style: style }, getContent(value)));
export default LinkDisplay;
