import { getTranslation } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { QuickFilter } from '../../../../../common/components/ConditionQuery';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { RequiredFieldIcon } from '../../../../../workflows/processes/components/process/RequiredFieldInfoPopover';
export const GetColumnHeader = ({ labels, language, type, propertyId, meta, showQuickFilter = true, required = false, isUnfilledRequiredField = false }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(TooltipTruncate, { className: "me-1" }, getTranslation(labels, language)),
        required && React.createElement(RequiredFieldIcon, { invalid: isUnfilledRequiredField }),
        React.createElement("div", { className: "quick-filters" }, showQuickFilter && React.createElement(QuickFilter, { type: type, propertyId: propertyId, meta: meta }))));
};
