import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils/idGenerator';
import { Button } from 'antd';
import { isNumber } from 'lodash';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useEntityDisplayData } from '../../../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../../../cms/common/utils/utils';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyButton } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { isEntityListProperty } from '../../../../../../cms/data/components/item-property/SelectProperty/utils';
import { Drawer } from '../../../../../../common/components/Drawer';
import FormikField from '../../../../../../common/components/formik/FormikField';
import EditViewForm from '../../../../../../views/components/EditViewForm/EditViewForm';
import { initialFilterRequest } from '../../../../../../views/components/ViewContext';
import { getViewSchema } from '../../../schema';
import { INSPECTION_DEFINITION_ID } from '../../constants';
import { InspectionSteps } from '../../types';
import { BaseSectionSchema, BaseStepSchema } from '../utils/schema';
import { SectionStepDetailsSettingsForm } from './SectionStepDetailsSettingsForm';
export const TableStepSectionSchema = Yup.object().shape({
    ...BaseSectionSchema.fields,
    propertyId: Yup.string().required('Required'),
    view: getViewSchema()
});
export const TableStepTypeSchema = Yup.object().shape({
    ...BaseStepSchema.fields,
    sections: Yup.array().of(TableStepSectionSchema).required().length(1),
    type: Yup.string().required().oneOf([InspectionSteps.table])
});
const TableStepDetailsSettingsForm = ({ disabled, basicPath, setFieldValue, ...otherProps }) => {
    const { t } = useI18n();
    const { displayData } = useEntityDisplayData(INSPECTION_DEFINITION_ID);
    const [editView, setEditView] = useState();
    const [editViewIndex, setEditViewIndex] = useState();
    const [editViewDefinitionId, setEditViewDefinitionId] = useState('');
    const [openEditViewDrawer, setOpenEditViewDrawer] = useState(false);
    const startEditingView = useCallback((section, sectionIndex, definitionId) => {
        setEditView(section.view);
        setEditViewIndex(sectionIndex);
        setEditViewDefinitionId(definitionId);
        setOpenEditViewDrawer(true);
    }, []);
    const handleEditView = useCallback((formValues, sectionIndex) => {
        if (!formValues || !isNumber(sectionIndex)) {
            return;
        }
        const selectedProperty = getPropertyById(displayData, editView?.propertyId);
        setFieldValue(`${basicPath}.sections[${sectionIndex}].view`, {
            title: formValues.title || '',
            isDefault: formValues.isDefault || true,
            referenceDefinitionId: selectedProperty?.meta.definitionId,
            metrics: [],
            filters: {
                sortBy: formValues.sortBy || initialFilterRequest.sortBy,
                query: {
                    condition: formValues.conditionQuery?.condition || initialFilterRequest.query.condition,
                    filters: formValues.conditionQuery?.filters || initialFilterRequest.query.filters
                },
                responseFields: formValues.columns || initialFilterRequest.responseFields,
                fullTextFields: formValues.columns || initialFilterRequest.responseFields
            },
            entityId: editView?.entityId || generateId()
        });
        setOpenEditViewDrawer(false);
    }, [basicPath, displayData, editView?.entityId, editView?.propertyId, setFieldValue]);
    const sectionConfig = useCallback(({ section, sectionIndex }) => {
        const selectedProperty = section.propertyId
            ? getPropertyById(displayData, section.propertyId)
            : undefined;
        return (React.createElement(React.Fragment, null,
            React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_TABLE__PROPERTY_ID') }),
            React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].propertyId`, component: SelectPropertyButton, definitionId: INSPECTION_DEFINITION_ID, includeProperty: isEntityListProperty, includeNestedProperties: false, disabled: disabled }),
            selectedProperty && (React.createElement(React.Fragment, null,
                React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_TABLE__VIEW') }),
                React.createElement(Button, { disabled: disabled, onClick: () => {
                        startEditingView(section, sectionIndex, selectedProperty?.meta.definitionId);
                    } }, t('VIEWS__EDIT_VIEW'))))));
    }, [basicPath, disabled, displayData, startEditingView, t]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionStepDetailsSettingsForm, { ...otherProps, setFieldValue: setFieldValue, disabled: disabled, basicPath: basicPath, sectionConfig: sectionConfig, maxSectionCount: 1 }),
        React.createElement(Drawer, { title: t('VIEWS__EDIT_VIEW'), open: openEditViewDrawer, closable: false },
            React.createElement(EditViewForm, { onCloseCallback: (e) => {
                    handleEditView(e, editViewIndex);
                }, definitionId: editViewDefinitionId, filterRequest: editView?.filters || initialFilterRequest, noKanban: true, showTitle: true, title: editView?.title, canEditFilters: false, canEditGroupingRow: false, canEditMetrics: false, canEditRowHeight: false }))));
};
export default TableStepDetailsSettingsForm;
