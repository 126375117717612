import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import ComputedInputField from '../../../../../../common/components/ComputedExpressionEditor/ComputedInputField/ComputedInputField';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField';
import { ComputedExpressionsDocumentationLink } from '../../../../../../common/components/documentation-link';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
const GenerateQrCodeForm = ({ disabled, parentViewGroups }) => {
    const { t } = useI18n();
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, placeholder: t('COMMON__TITLE'), disabled: disabled }),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement("div", { className: "mb-3" },
                React.createElement(ComputedExpressionsDocumentationLink, { className: "d-inline-flex align-items-center" })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { label: t('TASK_AUTOMATED_INPUT_DATA') }),
                React.createElement(Field, { component: ComputedInputField, computedComponent: AntInput, disabled: disabled, validate: required, name: "data", placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('COMMON__DATA')}` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { label: t('TASK_AUTOMATED_FILE_NAME'), help: t('TASK_AUTOMATED_FILE_NAME_INFO') }),
                React.createElement(Field, { component: ComputedInputField, computedComponent: AntInput, disabled: disabled, name: "fileName", placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_AUTOMATED_FILE_NAME')}` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { label: t('TASK_AUTOMATED_BINARY_PROPERTY'), help: t('TASK_AUTOMATED_BINARY_PROPERTY_INFO') }),
                React.createElement(Field, { component: ComputedInputField, computedComponent: AntInput, disabled: disabled, name: "binaryProperty", validate: required, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('COMMON__PROPERTY')}` }))),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default GenerateQrCodeForm;
