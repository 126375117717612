import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { TENANT_ID } from 'config';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropertyFieldInlineEditor from '../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import { fetchFilesData, getFileData, uploadFiles } from '../../../../../common/storage';
import FileField from '../../../../../common/storage/components/FileField';
export const getWhitelabelFormSchema = () => {
    return Yup.object({
        groupIds: Yup.array().of(Yup.string()),
        whitelabelSettings: Yup.object({
            logo: Yup.string().nullable(),
            favicon: Yup.string().nullable(),
            headerColor: Yup.string().nullable(),
            primaryColor: Yup.string().nullable(),
            secondaryColor: Yup.string().nullable()
        })
    });
};
const WhiteLabelOptionsForm = ({ whitelabel, setFieldValue, disabled }) => {
    const { t } = useI18n();
    const notificationDispatch = useDispatch();
    const dispatch = useDispatch();
    const fetchFileData = useCallback((fileId) => dispatch(fetchFilesData([fileId])), [dispatch]);
    const whitelabelSettings = whitelabel?.whitelabelSettings;
    const logoId = whitelabelSettings?.logo;
    const logoFileData = useSelector((s) => logoId && getFileData(s, logoId));
    const faviconId = whitelabelSettings?.favicon;
    const faviconFileData = useSelector((s) => faviconId && getFileData(s, faviconId));
    const pathToWhiteLabel = 'configuration.whitelabel.whitelabelSettings';
    const logo = useMemo(() => {
        return logoFileData ? [logoFileData] : [];
    }, [logoFileData]);
    const favicon = useMemo(() => {
        return faviconFileData ? [faviconFileData] : [];
    }, [faviconFileData]);
    useEffect(() => {
        if (faviconId) {
            void fetchFileData(faviconId);
        }
    }, [faviconId, fetchFileData]);
    useEffect(() => {
        if (logoId) {
            void fetchFileData(logoId);
        }
    }, [fetchFileData, logoId]);
    const uploadFilesHandler = useCallback(async (files, path) => {
        try {
            const uploadData = await uploadFiles(dispatch, files, TENANT_ID);
            uploadData.map((file) => setFieldValue(path, file.id));
        }
        catch (e) {
            notificationDispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    }, [dispatch, notificationDispatch, setFieldValue, t]);
    const onRemoveFile = useCallback((path) => {
        return new Promise((resolve, reject) => {
            try {
                setFieldValue(path, null);
            }
            catch (e) {
                reject(e);
            }
            finally {
                resolve(undefined);
            }
        });
    }, [setFieldValue]);
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("span", null,
                React.createElement("span", null,
                    t('GROUP__NAV_LINK'),
                    ": "),
                React.createElement(FormikField, { name: `configuration.whitelabel.groupIds`, component: PropertyFieldInlineEditor, type: "entity", definitionId: "group", meta: { definitionId: 'group', list: true }, canEdit: !disabled }))),
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("div", null,
                React.createElement("span", null,
                    t('APPS_CUSTOMER_PORTAL_WHITELABEL__LOGO'),
                    ": "),
                React.createElement(FormikField, { name: `${pathToWhiteLabel}.logo`, component: FileField, files: logo, meta: { accept: 'image/*', list: false, carousel: false }, error: false, loading: false, onSave: (files) => uploadFilesHandler(files, `${pathToWhiteLabel}.logo`), onRemove: () => onRemoveFile(`${pathToWhiteLabel}.logo`), canEdit: !disabled })),
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null,
                    t('APPS_CUSTOMER_PORTAL_WHITELABEL__FAVICON'),
                    ": "),
                React.createElement(FormikField, { name: `${pathToWhiteLabel}.favicon`, component: FileField, files: favicon, meta: { accept: 'image/*', list: false, carousel: false }, error: false, loading: false, onSave: (files) => uploadFilesHandler(files, `${pathToWhiteLabel}.favicon`), onRemove: () => onRemoveFile(`${pathToWhiteLabel}.favicon`), canEdit: !disabled })),
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null,
                    t('APPS_CUSTOMER_PORTAL_WHITELABEL__HEADER_COLOR'),
                    ": "),
                React.createElement(FormikField, { name: `${pathToWhiteLabel}.headerColor`, component: PropertyFieldInlineEditor, type: "text", meta: { multiline: false, multilanguage: false }, canEdit: !disabled })),
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null,
                    t('APPS_CUSTOMER_PORTAL_WHITELABEL__PRIMARY_COLOR'),
                    ": "),
                React.createElement(FormikField, { name: `${pathToWhiteLabel}.primaryColor`, component: PropertyFieldInlineEditor, type: "text", meta: { multiline: false, multilanguage: false }, canEdit: !disabled })),
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null,
                    t('APPS_CUSTOMER_PORTAL_WHITELABEL__SECONDARY_COLOR'),
                    ": "),
                React.createElement(FormikField, { name: `${pathToWhiteLabel}.secondaryColor`, component: PropertyFieldInlineEditor, type: "text", meta: { multiline: false, multilanguage: false }, canEdit: !disabled })))));
};
export default WhiteLabelOptionsForm;
