import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React, { useRef } from 'react';
import { ClearIcon } from '../../../../../../icons';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './CheckboxEditor.module.scss';
const CheckboxEditor = ({ onChange, allowClear, disabled, value, meta, errorMessage, editing = true }) => {
    const { t } = useI18n();
    const yesRef = useRef(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement("div", { className: "buttons-wrapper" },
                React.createElement(Button, { ref: yesRef, autoFocus: !!value || value === null, className: `btn-left ${(value === true && 'checked') || ''}`, disabled: disabled, onClick: () => onChange(true) }, t('YES')),
                React.createElement(Button, { autoFocus: value === false, className: `btn-right ${(value === false && 'checked') || ''}`, disabled: disabled, onClick: () => onChange(false) }, t('NO')),
                allowClear && !disabled && !meta?.validations?.required && value != null && (React.createElement(Button, { className: "clear-btn", disabled: disabled, onClick: () => {
                        onChange(null);
                        yesRef.current?.focus();
                    } },
                    React.createElement(ClearIcon, null))))),
        React.createElement(FieldError, { error: errorMessage })));
};
export default CheckboxEditor;
