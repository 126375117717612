import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { Select } from 'antd';
import { TENANT_ID } from 'config';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from '../../../../../common/components/file-input';
import FormikField from '../../../../../common/components/formik/FormikField';
import { fetchFilesData, fetchUploadUrl, getFileData, isFileDataAvailable, isFileDataPending, uploadFile } from '../../../../../common/storage';
import styles from './ImagesForm.module.scss';
const overlayImagePositionValues = [
    { value: '', label: 'APPS_HYPER_DEALER__DISABLED' },
    { value: 'top-left', label: 'APPS_HYPER_DEALER__TOP_LEFT' },
    { value: 'top-right', label: 'APPS_HYPER_DEALER__TOP_RIGHT' },
    { value: 'bottom-left', label: 'APPS_HYPER_DEALER__BOTTOM_LEFT' },
    { value: 'bottom-right', label: 'APPS_HYPER_DEALER__BOTTOM_RIGHT' },
    { value: 'cover', label: 'APPS_HYPER_DEALER__COVER' }
];
const ImagesForm = ({ instance }) => {
    const { t } = useI18n();
    const fetchFileDataDispatch = useDispatch();
    const uploadFileDispatch = useDispatch();
    const fetchUploadUrlDispatch = useDispatch();
    const errorDispatch = useDispatch();
    const fetchFileData = useCallback((fileId) => fetchFileDataDispatch(fetchFilesData(fileId)), [fetchFileDataDispatch]);
    const showError = useCallback((config) => errorDispatch(error(config)), [errorDispatch]);
    const isDefaultImagePending = useSelector((state) => isFileDataPending(state, instance.configuration.website.defaultImage?.[0]));
    const isDefaultImageAvailable = useSelector((state) => isFileDataAvailable(state, instance.configuration.website.defaultImage?.[0]));
    const defaultImage = useSelector((state) => getFileData(state, instance.configuration.website.defaultImage?.[0]));
    const isOverlayImagePending = useSelector((state) => isFileDataPending(state, instance.configuration.website.overlayImage?.[0]));
    const isOverlayImageAvailable = useSelector((state) => isFileDataAvailable(state, instance.configuration.website.overlayImage?.[0]));
    const overlayImage = useSelector((state) => getFileData(state, instance.configuration.website.overlayImage?.[0]));
    useEffect(() => {
        if (instance.configuration.website.defaultImage &&
            instance.configuration.website.defaultImage.length) {
            if (!isDefaultImageAvailable) {
                fetchFileData(instance.configuration.website.defaultImage);
            }
        }
    }, [fetchFileData, instance.configuration.website.defaultImage, isDefaultImageAvailable]);
    useEffect(() => {
        if (instance.configuration.website.overlayImage &&
            instance.configuration.website.overlayImage.length) {
            if (!isOverlayImageAvailable) {
                fetchFileData(instance.configuration.website.overlayImage);
            }
        }
    }, [fetchFileData, instance.configuration.website.overlayImage, isOverlayImageAvailable]);
    const upload = useCallback(async (file) => {
        try {
            const data = await fetchUploadUrlDispatch(fetchUploadUrl(file, TENANT_ID, true));
            if (data.uploadUrl) {
                await uploadFileDispatch(uploadFile(file, data, data.uploadUrl));
            }
            return data;
        }
        catch (e) {
            showError({
                title: t('ERROR'),
                message: t('UPLOAD_FAILED')
            });
        }
    }, [uploadFileDispatch, fetchUploadUrlDispatch, showError]);
    const overlayImagePositions = useMemo(() => overlayImagePositionValues.map(({ value, label }) => ({
        value,
        label: t(label)
    })), [overlayImagePositionValues, t]);
    const uploadFileClearValue = useCallback((uploadedFile) => [uploadedFile.id], []);
    return (React.createElement("div", { className: styles.imagesForm },
        React.createElement(FormikField, { name: "configuration.website.defaultImage", component: FileInput, label: t('APPS_HYPER_DEALER__DEFAULT_IMAGE'), buttonTitle: React.createElement(Fragment, null,
                React.createElement(MdAdd, null),
                t('APPS_HYPER_DEALER__UPLOAD_BUTTON')), value: defaultImage, loading: isDefaultImagePending, uploadFn: upload, clearValue: uploadFileClearValue }),
        React.createElement(FormikField, { name: "configuration.website.overlayImage", label: t('APPS_HYPER_DEALER__OVERLAY_IMAGE'), buttonTitle: React.createElement(Fragment, null,
                React.createElement(MdAdd, null),
                t('APPS_HYPER_DEALER__UPLOAD_BUTTON')), value: overlayImage, loading: isOverlayImagePending, uploadFn: upload, component: FileInput, clearValue: uploadFileClearValue }),
        React.createElement("div", null,
            React.createElement(LabelText, null, t('APPS_HYPER_DEALER__OVERLAY_IMAGE_POSITION')),
            React.createElement(FormikField, { name: "configuration.website.overlayImagePosition", className: "w-100 overlayImageSelector", component: Select, value: instance.configuration.website.overlayImagePosition, options: overlayImagePositions })),
        React.createElement("div", null,
            React.createElement(LabelText, null, t('APPS_HYPER_DEALER__IMAGE_ASPECT_RATIO')),
            React.createElement(FormikField, { name: "configuration.website.imageAspectRatio", className: "w-100", component: BasicInput, value: instance.configuration.website.imageAspectRatio }))));
};
export default ImagesForm;
