import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { hasTenantPermission } from '../../auth';
import { useMyContact } from '../../crm/components/use-my-contact';
import { HOME_PATH } from '../../home';
import { REQUIRED_PORTAL_PERMISSION } from '../utils/constants';
const NoPermissionsLandingPage = () => {
    const { t } = useI18n();
    const { contact } = useMyContact();
    const hasAccessToApp = useSelector((s) => {
        return hasTenantPermission(s, REQUIRED_PORTAL_PERMISSION);
    });
    return hasAccessToApp ? (React.createElement(Redirect, { to: HOME_PATH })) : (React.createElement(NotFound, { title: compact([t('NO_PERMISSION_SORRY'), (contact && contact.firstName) || null]).join(' '), content: t('NO_PERMISSIONS_DESCRIPTION') }));
};
export default NoPermissionsLandingPage;
