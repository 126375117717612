import React from 'react';
import { CustomEntitySelector } from '../../../../../../common/components/entity-selectors';
import { FieldWrapper } from '../../FieldElements';
import styles from './EntityEditor.module.scss';
const EntityEditor = ({ className, meta, value, onChange, disabled, onKeyDown, onDrawerStateChange, onShowFilter, showFilter, filterRequest, autoFocus = true, style, withoutCreateNew = false, withoutSearchResults = false, forceFetch = false, ignoreOnEnterKeyDown = true, requiredFieldsConfig, permissionFieldsConfig, input, errorMessage, simpleClear, ...otherProps }) => (React.createElement(React.Fragment, null,
    React.createElement(FieldWrapper, { className: `${styles.editor} ${className || ''} ant-select-styled-outer`, disabled: disabled, disableWrapperHover: true, editing: false, error: !!errorMessage },
        React.createElement(CustomEntitySelector, { popupClassName: "antd-dropdown-popup-styled", multiple: meta.list, 
            // @ts-expect-error
            input: input ? input : { value, onChange }, definitionId: meta.definitionId, disabled: disabled, onKeyDown: onKeyDown, autoFocus: autoFocus, onDrawerStateChange: onDrawerStateChange, onShowFilter: onShowFilter, showFilter: showFilter, filtersQuery: filterRequest?.query, sortBy: filterRequest?.sortBy, style: style, extraPayloadProps: { limit: meta?.selector?.limit, context: filterRequest?.context }, withoutCreateNew: withoutCreateNew, withoutSearchResults: withoutSearchResults || meta?.selector?.limit === 0, forceFetch: forceFetch, ignoreOnEnterKeyDown: ignoreOnEnterKeyDown, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, simpleClear: simpleClear, error: errorMessage, ...otherProps }))));
export default EntityEditor;
