import { isGTE } from '@hypercharge/hyper-react-base/lib/browser';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withProps } from 'recompose';
import { getBrowserState } from '../../reducers/rootSelector';
export const useBrowserBreakpoints = () => {
    const browserState = useSelector((s) => getBrowserState(s));
    return useMemo(() => ({
        isMobile: !isGTE(browserState, 'sm'),
        isTablet: !isGTE(browserState, 'md'),
        isDesktop: isGTE(browserState, 'xl')
    }), [browserState]);
};
const BreakpointWrapper = ({ Component, ...propsToPass }) => {
    const breakpoints = useBrowserBreakpoints();
    return React.createElement(Component, { ...breakpoints, ...propsToPass });
};
/**
 * @deprecated This is deprecated and should be replaced with hook `useBrowserBreakpoints`.
 *
 * @param {Component} Component where to add breakpoints info.
 * @return {ComponentType} with breakpoints info
 */
const withBrowserBreakpoints = (Component) => withProps({ Component })(BreakpointWrapper);
export default withBrowserBreakpoints;
