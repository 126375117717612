import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchOptionGroup, getOption, getOptions } from '@hypercharge/hyper-react-base/lib/metadata';
import { Select } from 'antd';
import { noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMetadataState } from '../../../../../../common/reducers/rootSelector';
import { getScrollableAreaOrBody } from '../../../../../../common/utils/common';
import { ClearIcon } from '../../../../../../icons';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './SelectEditor.module.scss';
const SelectEditor = ({ meta, value, onChange, disabled, onKeyDown = noop, autoFocus = true, allowClear = true, errorMessage }) => {
    const { language } = useI18n();
    const dispatch = useDispatch();
    const [focused, setFocused] = useState(false);
    const metadataState = useSelector((s) => getMetadataState(s));
    const selectedOptions = useMemo(() => (Array.isArray(value) ? value : [value])
        // @ts-expect-error
        .map((val) => getOption(metadataState, meta.source, val))
        .filter((v) => v), [value, metadataState, meta]);
    const options = useMemo(() => getOptions(metadataState, meta.source), [metadataState, meta]);
    useEffect(() => {
        if (options.length == 0) {
            // @ts-expect-error
            dispatch(fetchOptionGroup(meta.source));
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: styles.editor, onClick: (e) => e.stopPropagation() },
            React.createElement(Select, { autoFocus: autoFocus, showAction: ['focus'], getPopupContainer: getScrollableAreaOrBody, showSearch: true, allowClear: allowClear && !!value, style: { width: '100%' }, popupMatchSelectWidth: true, popupClassName: "antd-dropdown-popup-styled", notFoundContent: null, disabled: disabled, value: value ? value : undefined, onFocus: () => setFocused(true), onChange: (value) => (onChange ? onChange(value || null) : undefined), mode: meta.list ? 'multiple' : undefined, onInputKeyDown: onKeyDown, clearIcon: React.createElement(ClearIcon, null) },
                options.map((opt) => (React.createElement(Select.Option, { value: opt.value, key: opt.value }, getTranslation(opt.labels, language)))),
                options.length == 0 &&
                    selectedOptions &&
                    selectedOptions.map((opt) => opt && (React.createElement(Select.Option, { value: opt.value, key: opt.value }, getTranslation(opt.labels, language)))))),
        React.createElement(FieldError, { error: errorMessage })));
};
export default SelectEditor;
