import moment from 'moment';
import { PureComponent } from 'react';
export const datePattern = 'DD/MM/YYYY';
export const timePattern = 'HH:mm';
export const dateTimePattern = `${datePattern} ${timePattern}`;
export const getMilliseconds = (value, withTime) => {
    if (value) {
        return withTime
            ? value.utc().seconds(0).milliseconds(0).valueOf()
            : value.utc().hours(0).minutes(0).seconds(0).milliseconds(0).valueOf();
    }
    return null;
};
export const formatDate = (value) => moment(value).format(datePattern);
export const formatDateTime = (value) => moment(value).format(dateTimePattern);
export const readableDateTime = (dateTime, hideDayOfWeek) => {
    const now = moment();
    const dt = moment(dateTime);
    const isThisYear = dt.year() === now.year();
    const baseDateString = `${hideDayOfWeek ? '' : 'dddd, '}D MMM${isThisYear ? '' : " 'YY"}`;
    if (Math.abs(dt.diff(now, 'hours')) < 24) {
        return dt.fromNow();
    }
    return dt.format(baseDateString);
};
export class ReadableDateTime extends PureComponent {
    constructor() {
        super(...arguments);
        this.unmounted = false;
        this.state = {
            dateTimeRepresentation: readableDateTime(this.props.dateTime, this.props.hideDayOfWeek)
        };
        this.initRepresentationUpdates = () => {
            const now = moment();
            const dt = moment(this.props.dateTime);
            this.resetInterval();
            if (Math.abs(dt.diff(now, 'hours')) < 24) {
                this.interval = window.setInterval(this.updateDateTimeRepresentation, 60 * 1000); // rerender every minute
            }
        };
        this.resetInterval = () => {
            if (this.interval) {
                window.clearInterval(this.interval);
            }
        };
        this.updateDateTimeRepresentation = () => {
            if (!this.unmounted) {
                this.setState({
                    dateTimeRepresentation: readableDateTime(this.props.dateTime, this.props.hideDayOfWeek)
                });
            }
        };
    }
    componentDidMount() {
        this.initRepresentationUpdates();
    }
    componentDidUpdate(prevProps) {
        if (this.props.dateTime !== prevProps.dateTime) {
            this.updateDateTimeRepresentation();
            this.initRepresentationUpdates();
        }
    }
    componentWillUnmount() {
        this.unmounted = true;
        this.resetInterval();
    }
    render() {
        return this.state.dateTimeRepresentation;
    }
}
