import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { deleteTemplate } from '../templates/actions';
import { invalidateDocumentTemplateQuery } from './useDocumentTemplate';
import { invalidateSearchDocumentsQuery } from './useSearchDocuments';
import { invalidateTemplateVersionsQuery } from './useTemplateVersions';
export const useDeleteDocumentTemplate = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: async (templateId) => {
            return dispatch(deleteTemplate(templateId));
        },
        onSettled: async (_, __, templateId) => {
            await invalidateSearchDocumentsQuery();
            await invalidateTemplateVersionsQuery(templateId);
            await invalidateDocumentTemplateQuery(templateId);
        }
    });
};
