import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getAccountId } from '../../../../../auth';
import { EntityDisplay } from '../../../../../cms';
import DropdownButton from '../../../../../common/components/DropdownButton';
import { InlineEditor } from '../../../../../common/components/InlineEditor';
import { ContactDisplayName } from '../../../../../crm';
import { searchTaskExecutors } from '../../../actions';
import AssigneeSearchComponent from '../AssigneeSearchComponent';
import styles from './AssigneeSelector.module.scss';
class AssigneeSelector extends Component {
    constructor() {
        super(...arguments);
        this.unmounted = false;
        this.state = {
            searching: false,
            saving: false,
            contacts: [],
            totalContacts: 0
        };
        this.assignTo = (contactId) => {
            this.setState({ saving: true });
            return this.props.assignTo(contactId).finally(() => {
                if (!this.unmounted) {
                    this.setState({ saving: false });
                }
            });
        };
        this.assignToMe = () => {
            if (this.props.myContactId) {
                this.assignTo(this.props.myContactId);
            }
        };
        this.assignAndSave = (contactId) => {
            const promise = this.assignTo(contactId);
            promise.finally(() => {
                if (!this.unmounted) {
                    this.setState({ searching: false });
                }
            });
            return promise;
        };
        this.cancelSearch = () => {
            this.setState({ searching: false });
        };
        this.handleMenuClick = ({ key }) => {
            if (key == 'release') {
                this.assignTo();
            }
            else if (key == 'other') {
                this.setState({ searching: true });
            }
            else {
                this.assignTo(key);
            }
        };
        this.getMenu = () => {
            const { value, myContactId, t } = this.props;
            const { contacts, totalContacts } = this.state;
            const items = [];
            if (value) {
                items.push({
                    key: 'release',
                    label: React.createElement("div", { className: "fw-500" }, t('RELEASE_TASK'))
                });
            }
            if (value && contacts.length) {
                items.push({ type: 'divider' });
            }
            if (totalContacts) {
                const typeGroup = 'group';
                const assignTo = {
                    key: 'assign-to',
                    type: typeGroup,
                    label: `${t('ASSIGN_TO')}:`,
                    children: contacts
                        .filter(({ entityId }) => !value || value !== entityId)
                        .sort(({ entityId }) => (entityId === myContactId ? -1 : 0))
                        .map(({ entityId, title }) => ({
                        key: entityId,
                        label: entityId === myContactId ? t('ASSIGN_TO_ME') : title
                    }))
                };
                if (totalContacts > contacts.length) {
                    assignTo.children?.push({
                        key: 'other',
                        label: (React.createElement("div", { className: "fw-500" },
                            t('SEARCH_OTHERS'),
                            " (",
                            totalContacts - contacts.length,
                            ")"))
                    });
                }
                items.push(assignTo);
            }
            else {
                if (!value) {
                    items.push({
                        key: 'no-contacts',
                        disabled: true,
                        label: (React.createElement("div", { style: { maxWidth: '20rem', color: 'var(--neutralColor-6)' }, className: "text-center" }, t('NO_CONTACTS_TO_ASSIGN_TASK_TO')))
                    });
                }
            }
            return {
                onClick: this.handleMenuClick,
                items
            };
        };
    }
    componentDidMount() {
        this.props.searchTaskExecutors().then(({ results, totalCount }) => {
            if (!this.unmounted) {
                this.setState({
                    contacts: results,
                    totalContacts: totalCount
                });
            }
        }, console.error);
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    render() {
        const { value, disabled, myContactId, t, canEdit, inlineEditorClassName, popupClassName } = this.props;
        const { searching, saving } = this.state;
        return searching ? (React.createElement(InlineEditor, { className: `w-100 ${inlineEditorClassName ? inlineEditorClassName : ''}`, 
            // @ts-expect-error
            displayComponent: EntityDisplay, 
            // @ts-expect-error
            editorComponent: AssigneeSearchComponent, value: '', autoFocus: true, canEdit: canEdit, expand: true, invalid: false, 
            // @ts-expect-error
            onSave: this.assignAndSave, onCancel: this.cancelSearch, immediatelySaveOnChange: true, extraEditorComponentProps: {
                taskMetaId: this.props.taskMetaId,
                taskRunId: this.props.taskRunId,
                processRunId: this.props.processRunId,
                processMetaId: this.props.processMetaId
            } })) : (React.createElement(DropdownButton, { className: `${popupClassName ? popupClassName : ''} ${value ? '' : styles.disabledAssigneeSelector}`, inversed: true, onClick: this.assignToMe, disabled: disabled || !!value || saving || !myContactId, menuDisabled: disabled, loading: saving, menu: this.getMenu() }, value ? React.createElement(ContactDisplayName, { contactId: value }) : React.createElement("span", null, t('TAKE_TASK'))));
    }
}
const mapStateToProps = (s) => ({
    myContactId: getAccountId(s)
});
const mapDispatchToProps = (dispatch, { taskMetaId, taskRunId, processRunId, processMetaId }) => ({
    searchTaskExecutors: () => 
    // @ts-expect-error
    dispatch(searchTaskExecutors({
        taskMetaId,
        taskRunId,
        processRunId,
        processMetaId
    }))
});
export default compose(withProps(() => {
    const { t } = useI18n();
    return { t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(AssigneeSelector);
