import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push, replace } from '@hypercharge/hyper-react-base/lib/router';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from 'config';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getQueryParams, getUpdatedLocation } from '../../../views';
import { deleteForm } from '../../actions';
import { useSearchForms } from '../../hooks/useSearchForms';
import FormsList from './FormsList';
const FormsListContainer = () => {
    const { t } = useI18n();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const queryParams = useMemo(() => getQueryParams(search, { parseNumbers: true }), [search]);
    const { fullText, page = INITIAL_PAGE, pageSize = INITIAL_PAGE_SIZE } = queryParams;
    const { data, isFetching } = useSearchForms({ page, pageSize, fullText });
    const totalCount = data?.totalCount ?? 0;
    const results = data?.results ?? [];
    const onPageChange = useCallback((page) => {
        const newFilterRequest = {
            ...queryParams,
            page
        };
        const locationDescriptor = getUpdatedLocation(newFilterRequest);
        dispatch(push(locationDescriptor));
    }, [dispatch, queryParams]);
    const onPageSizeChange = useCallback((pageSize) => {
        const pageLimit = Math.ceil(totalCount / pageSize);
        const newSearch = {
            ...queryParams,
            page: page > pageLimit ? pageLimit : page,
            pageSize
        };
        const locationDescriptor = getUpdatedLocation(newSearch);
        dispatch(push(locationDescriptor));
    }, [totalCount, page, dispatch, queryParams]);
    const onClickDelete = useCallback(async (id) => {
        try {
            await dispatch(deleteForm(id));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('DELETE_FORM__SUCCESS_MESSAGE')
            }));
        }
        catch (err) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('DELETE_FORM__ERROR_MESSAGE')
            }));
        }
    }, [dispatch, t]);
    const onChangeSearch = useCallback((newValues) => {
        const newFilterRequest = {
            fullText: fullText || '',
            page,
            pageSize,
            ...newValues
        };
        const locationDescriptor = getUpdatedLocation(newFilterRequest);
        dispatch(replace(locationDescriptor));
    }, [dispatch, fullText, page, pageSize]);
    return (React.createElement(FormsList, { loading: isFetching, forms: results, totalCount: totalCount, fullText: fullText, page: page, pageSize: pageSize, onClickDelete: onClickDelete, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onChangeSearch: onChangeSearch }));
};
export default FormsListContainer;
