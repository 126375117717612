import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ADMIN_PATH } from '../../utils/constants';
import OptionGroupFormContainer from './option-group/OptionGroupFormContainer';
import OptionGroupsContainer from './option-groups/OptionGroupsContainer';
import OptionFormContainer from './option/OptionFormContainer';
import OptionsContainer from './options/OptionsContainer';
const Routes = () => (React.createElement(Switch, null,
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/option-groups`, component: OptionGroupsContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/option-groups/add`, component: OptionGroupFormContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/option-groups/:groupId/options`, component: OptionsContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/option-groups/:groupId/options/add`, component: OptionFormContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/option-groups/:groupId/options/:value`, component: OptionFormContainer })));
export default Routes;
