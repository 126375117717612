import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FEATURED_ENTITIES_PATH } from '../../../cms';
import { Content } from '../../../common/utils/stylingUtils';
import { DOMAIN_CMS_DEFINITION_ID } from '../../../crm';
import { InfoContainer, InfoText, StyledSectionCard, styledSectionCardHeaderStyle } from '../common/stylingUtils';
const Container = styled.div `
  height: 100%;
  position: relative;
  box-sizing: border-box;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
`;
const StyledLink = styled(Link) `
  font-weight: inherit !important;
  border: 0 !important;
  transition: color 200ms ease;
  word-break: break-word;

  &:hover {
    color: var(--primaryColor-4);
  }
`;
const StyledInfoIcon = styled(IoMdInformationCircle) `
  margin-left: 0.3rem;
  color: var(--grayscaleColor-7);
`;
const DomainsSettingsForm = ({ contentHeight, domainsNeedsActivation, isDataPending, hasDataFetchFailed, enableDomains }) => {
    const [enablingDomains, setEnablingDomains] = useState(false);
    const { t } = useI18n();
    const unmounted = useRef(false);
    const toggleEnablingDomains = () => {
        if (!unmounted.current) {
            setEnablingDomains(!enablingDomains);
        }
    };
    const _enableDomains = () => {
        toggleEnablingDomains();
        enableDomains().finally(toggleEnablingDomains);
    };
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    return (React.createElement(StyledSectionCard, { title: t('DOMAINS'), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true, loading: isDataPending },
        React.createElement(Content, { height: contentHeight },
            React.createElement(Container, null,
                React.createElement(InfoContainer, null,
                    React.createElement(InfoText, null, domainsNeedsActivation || enablingDomains ? (React.createElement(Fragment, null,
                        t('DOMAINS_NEED_ACTIVATION_TEXT'),
                        ' ',
                        React.createElement(Tooltip, { title: t('DOMAINS_ACTIVATION_IS_PERMANENT') },
                            React.createElement(StyledInfoIcon, null)))) : hasDataFetchFailed ? (t('SETTINGS_FAILED_LOADING')) : (React.createElement(Fragment, null,
                        t('DOMAINS_ACTIVATED_INFO_INFO_PART1'),
                        React.createElement(StyledLink, { to: [FEATURED_ENTITIES_PATH, DOMAIN_CMS_DEFINITION_ID].join('/') }, t('DOMAINS_LINK_TEXT')),
                        t('DOMAINS_ACTIVATED_INFO_INFO_PART2')))),
                    (domainsNeedsActivation || enablingDomains) && (React.createElement("div", { className: "cp-c-row cp-c-align-center-center" },
                        React.createElement(Button, { className: "cp-i-none", onClick: _enableDomains, loading: enablingDomains }, t('DOMAINS_ACTIVATE_BUTTON')))))))));
};
export default DomainsSettingsForm;
