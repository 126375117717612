import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { EMAIL_TEMPLATES_DEFINITION_ID, SortOrder } from '@hypercharge/portal-utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { hasTenantPermission } from '../../../../auth';
import useCreateItem from '../../../../cms/hooks/useCreateItem';
import useDeleteItems from '../../../../cms/hooks/useDeleteItems';
import { useHandleEntityItems } from '../../../../cms/hooks/useEntityItems';
import { fetchFilesData, getFileData, isFileDataAvailable } from '../../../../common/storage';
import { APP_ADMIN_PERMISSION, MAIL_TEMPLATES_PREVIEW_ON_CLIENTS_PERMISSION } from '../../../../common/utils/constants';
import { VIEW_DEFINITION_ID, ViewProvider } from '../../../../views';
import { initialFilterRequest, useView } from '../../../../views/components/ViewContext';
import TemplatesList from './TemplatesList';
const TemplatesListContainer = () => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { handleFetch } = useHandleEntityItems();
    const history = useHistory();
    const getEmailTemplateByIds = useCallback(async (entityId) => {
        const entityCmsListResponse = await handleFetch({
            ids: [entityId],
            definitionId: EMAIL_TEMPLATES_DEFINITION_ID,
            extraPayloadProps: {
                responseFields: ['configuration']
            }
        });
        if (!entityCmsListResponse) {
            return;
        }
        return entityCmsListResponse?.results?.[0];
    }, [handleFetch]);
    const { mutateAsync: emailTemplatesCreateItem } = useCreateItem({
        definitionId: EMAIL_TEMPLATES_DEFINITION_ID
    });
    const { mutateAsync: emailTemplatesDeleteItems } = useDeleteItems({
        definitionId: EMAIL_TEMPLATES_DEFINITION_ID
    });
    const { searchItems: { data: { results: templates } = {}, isPending }, changePage, changePageSize, totalItemsCount, page, pageSize } = useView();
    const hasAdminPermission = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION));
    const hasCrossClientPreviewPermission = useSelector((s) => hasTenantPermission(s, MAIL_TEMPLATES_PREVIEW_ON_CLIENTS_PERMISSION));
    const isFileNotFetched = useSelector((s) => (fileId) => !isFileDataAvailable(s, fileId));
    const _getFileData = useSelector((s) => (fileId) => getFileData(s, fileId));
    useEffect(() => {
        if (!templates) {
            return;
        }
        const images = templates.map((template) => template.image);
        let filesToFetch = images.filter((fileId) => !!fileId);
        filesToFetch = filesToFetch.filter(isFileNotFetched);
        if (filesToFetch.length > 0) {
            void dispatch(fetchFilesData(filesToFetch));
        }
    }, [dispatch, isFileNotFetched, templates]);
    const _deleteTemplate = useCallback(async (id) => {
        try {
            const entityItem = await getEmailTemplateByIds(id);
            if (!entityItem) {
                throw new Error();
            }
            if (entityItem?.configuration?.system) {
                throw new Error(t('CAN_NOT_DELETE_SYSTEM_TEMPLATE'));
            }
            await emailTemplatesDeleteItems({
                ids: [id]
            });
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('DELETE_TEMPLATE__SUCCESS_MESSAGE')
            }));
        }
        catch (err) {
            let errorMessage = t('DELETE_TEMPLATE__ERROR_MESSAGE');
            if (err instanceof Error) {
                errorMessage = err.message;
            }
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: errorMessage
            }));
        }
    }, [dispatch, emailTemplatesDeleteItems, getEmailTemplateByIds, t]);
    const _editTemplate = useCallback(async ({ entityId, to }) => {
        try {
            const entityItem = await getEmailTemplateByIds(entityId);
            if (!entityItem) {
                throw new Error();
            }
            if (entityItem?.configuration?.system && !hasAdminPermission) {
                throw new Error(t('CAN_NOT_EDIT_SYSTEM_TEMPLATE'));
            }
            history && history.push(to);
        }
        catch (err) {
            let errorMessage = t('OPEN_TEMPLATE__ERROR_MESSAGE');
            if (err instanceof Error) {
                errorMessage = err.message;
            }
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: errorMessage
            }));
        }
    }, [dispatch, getEmailTemplateByIds, hasAdminPermission, history, t]);
    const copyTemplate = useCallback(async (template) => {
        try {
            const { entityId } = template;
            const entityItem = await getEmailTemplateByIds(entityId);
            if (!entityItem || !('configuration' in entityItem && entityItem.configuration)) {
                throw new Error();
            }
            if (entityItem?.configuration?.system && !hasAdminPermission) {
                throw new Error(t('CAN_NOT_COPIED_SYSTEM_TEMPLATE'));
            }
            await emailTemplatesCreateItem({
                ...template,
                title: `Copy ${template?.title || t('UNTITLED_TEMPLATE_NAME')}`,
                entityId: generateId(),
                configuration: {
                    ...entityItem.configuration,
                    system: false
                }
            });
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('SAVE_TEMPLATE__SUCCESS_MESSAGE')
            }));
        }
        catch (err) {
            let errorMessage = t('SAVE_TEMPLATE__ERROR_MESSAGE');
            if (err instanceof Error) {
                errorMessage = err.message;
            }
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: errorMessage
            }));
        }
    }, [dispatch, emailTemplatesCreateItem, getEmailTemplateByIds, hasAdminPermission, t]);
    return (React.createElement(TemplatesList, { isPending: isPending, templates: templates || [], hasCrossClientPreviewPermission: hasCrossClientPreviewPermission, deleteTemplate: _deleteTemplate, editTemplate: _editTemplate, copyTemplate: copyTemplate, getFileData: _getFileData, totalCount: totalItemsCount, page: page, pageSize: pageSize, updatePage: changePage, updatePageSize: changePageSize }));
};
const TemplateListContainerWrapped = (props) => {
    const view = useMemo(() => ({
        definitionId: VIEW_DEFINITION_ID,
        referenceDefinitionId: EMAIL_TEMPLATES_DEFINITION_ID,
        title: 'Templates',
        entityId: 'dummy',
        isDefault: true,
        filters: {
            ...initialFilterRequest,
            sortBy: [{ field: 'createdAt', order: SortOrder.desc }],
            responseFields: ['title', 'image'],
            fullTextFields: ['title', 'image']
        },
        metrics: []
    }), []);
    return (React.createElement(ViewProvider, { definitionId: EMAIL_TEMPLATES_DEFINITION_ID, view: view },
        React.createElement(TemplatesListContainer, { ...props })));
};
export default TemplateListContainerWrapped;
