const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

let domain = 'hyperportal.org';
let tenantId = null;
let wsSessionSyncUrl = `prod-ws.${domain}`;
if (isBrowser) {
  const splittedHostname = window.location.hostname.split('.');
  if (splittedHostname.length > 0) {
    tenantId = splittedHostname[0]?.split('---')[0];
    if (splittedHostname[1] == 'hyper1') {
      domain = 'hyper1.net';
      wsSessionSyncUrl = `staging-ws.${domain}`;
    }
  }
}

module.exports = {
  DOMAIN: domain,
  DEFAULT_LANGUAGE: 'en', // this will be overwritten if the DisplayTenantProvider is used
  AVAILABLE_LANGUAGES: ['en', 'nl', 'fr', 'it', 'de', 'pl'], // this will be overwritten if the DisplayTenantProvider is used
  COMPANY_NAME: 'HyperPortal',
  INITIAL_PAGE: 1,
  INITIAL_PAGE_SIZE: 10,
  PAGE_SIZES: [10, 25, 50, 100],
  DEFAULT_SELECT_OPTIONS_SIZE: 6,
  MAX_CMS_ITEMS_LIMIT: 500,
  TENANT_ID: tenantId,
  PUBLIC_IMAGES_SERVICE: `https://images.${domain}`,
  IP_INFO_URI: 'https://ipinfo.io/json',
  PORTAL_LOGO_SRC: '/assets/images/logo-light.svg',
  IMAGES_HOST: 'https://assets.hypercharge.org', // still necessary for gefco images used in hyper react base
  WS_SESSION_SYNC_URL: wsSessionSyncUrl,
  SENTRY_URL: 'https://ef64f721fc8f4074ab0dc7a4a73c9ded@sentry.io/1398658',
  DOCUMENTATION_URL: 'https://docs.hyperportal.org',
  ROADMAP_URL: 'https://app.minimaps.io/map/hyperportal-1596193440105x598369077200683000'
};
