import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { capitalize } from '../../../../../../../workflows/processes/components/process/StepsOverview';
import styles from '../KanbanCard.module.scss';
export const CardTaskDeadline = ({ deadline }) => {
    const { t } = useI18n();
    const content = useMemo(() => {
        if (!deadline) {
            return React.createElement("div", { className: styles.deadline }, t('NO_DEADLINE'));
        }
        if (new Date(deadline).getTime() < Date.now()) {
            return (React.createElement("div", { className: `${styles.deadline} ${styles.missedDeadline} fw-500` },
                React.createElement("span", { className: "me-2" }, `${capitalize(moment(deadline).fromNow(true))} ${t('LATE')}`),
                React.createElement(FontAwesomeIcon, { icon: "exclamation-triangle" })));
        }
        return React.createElement("div", { className: `${styles.deadline} fw-500` }, formatDateTime(deadline));
    }, [deadline, t]);
    return (React.createElement(Tooltip, { placement: "top", title: t('DEADLINE') }, content));
};
