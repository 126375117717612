import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { Body, Cell, DeleteConfirmationButton, InformationRow, Row, Table } from '@hypercharge/hyper-react-base/lib/common/table';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { INITIAL_PAGE_SIZE } from 'config';
import React, { Fragment, useCallback, useState } from 'react';
import { IoAdd, IoSettingsOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { EditIcon } from '../../../../common/components/EditIcon';
import actionsStyles from '../../../../common/components/data-table/DataTable2.module.scss';
import FullTextSearch from '../../../../common/components/data-table/FullTextSearch';
import TablePagination from '../../../../common/components/table-pagination';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import { StyledWrapper } from '../../../../common/utils/stylingUtils';
import { MARKETING_PATH } from '../../../common/utils/constants';
import { EMAIL_TEMPLATES_PATH, TEMPLATE_CREATE_PATH } from '../../constants';
import CopyTemplateButton from '../CopyTemplateButton';
import styles from './TemplatesList.module.scss';
import TemplatesSettingsDrawer from './TemplatesSettingsDrawer';
const TemplatesList = ({ isPending, templates, editTemplate, hasCrossClientPreviewPermission, deleteTemplate, copyTemplate, getFileData, totalCount, page, pageSize, updatePage, updatePageSize }) => {
    const { isMobile } = useBrowserBreakpoints();
    const { t } = useI18n();
    const [showDrawer, setShowDrawer] = useState(false);
    const toggleShowDrawer = useCallback(() => {
        setShowDrawer(!showDrawer);
    }, [showDrawer]);
    return (React.createElement(Fragment, null,
        React.createElement(StyledWrapper, { className: `${styles.wrapper} pt-3` },
            React.createElement("div", { className: `${actionsStyles.actionsContainer} my-3 d-flex flex-row flex-wrap align-items-start align-items-center align-content-start align-content-center mw-100 justify-content-between actions-container` },
                React.createElement(FullTextSearch, null),
                hasCrossClientPreviewPermission && (React.createElement(Button, { className: "SettingsButton ms-auto me-3 p-0 d-flex align-items-center justify-content-center", inversed: true, onClick: toggleShowDrawer },
                    React.createElement(IoSettingsOutline, { preserveAspectRatio: "none" }))),
                React.createElement(Button, { className: "actionButton QuickAddItemButton", to: [MARKETING_PATH, EMAIL_TEMPLATES_PATH, TEMPLATE_CREATE_PATH].join('') },
                    React.createElement(IoAdd, { className: "me-1" }),
                    React.createElement("span", { className: "d-none d-sm-inline" }, t('DATA_TABLE__ADD') + ': ' + t('EMAIL_TEMPLATE')))),
            React.createElement(Table, null,
                React.createElement(Body, { loading: isPending, loadingRows: INITIAL_PAGE_SIZE, cellLength: 1 }, templates.length === 0 && !isPending ? (React.createElement(InformationRow, { cellLength: 1 }, t('NO_TEMPLATES'))) : (templates.map(({ entityId, title, image }, indx) => {
                    const fileData = image ? getFileData(image) : null;
                    const getThumbnailJsx = () => image && (React.createElement("div", { className: "thumbnail mb-2" }, fileData && (React.createElement(Link, { to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}/${entityId}` },
                        React.createElement("img", { width: "100%", src: fileData.downloadUrl, alt: "email-template-thumbnail" })))));
                    return (React.createElement(Row, { key: entityId },
                        !isMobile && React.createElement(Cell, { width: "85" }, getThumbnailJsx()),
                        React.createElement(Cell, { className: "align-center" },
                            isMobile && getThumbnailJsx(),
                            React.createElement("div", { className: "title fw-600" }, title)),
                        React.createElement(Cell, { width: "140", className: "actions" },
                            React.createElement("div", { className: "align-items-center" },
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: "edit-btn mx-1", role: "button", onClick: () => editTemplate({
                                            entityId,
                                            to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}/${entityId}`
                                        }) },
                                        React.createElement(EditIcon, null)),
                                    React.createElement(CopyTemplateButton, { handleCopy: async () => await copyTemplate({
                                            entityId,
                                            title,
                                            image
                                        }) })),
                                React.createElement(DeleteConfirmationButton, { onClick: () => deleteTemplate(entityId) })))));
                })))),
            !isPending && totalCount > 0 && (React.createElement(TablePagination, { totalCount: totalCount, pageSize: pageSize, page: page, onPageChange: updatePage, onPageSizeChange: updatePageSize }))),
        React.createElement(TemplatesSettingsDrawer, { open: showDrawer, onClose: toggleShowDrawer })));
};
export default TemplatesList;
