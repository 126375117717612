import { Avatar, Popover } from 'antd';
import classNames from 'classnames';
import React from 'react';
const AvatarGroup = ({ className, maxCount, size, shape, maxPopoverTrigger = 'hover', children, getPopupContainer }) => {
    const cls = classNames('d-flex align-items-center', className);
    const childrenArray = React.Children.toArray(children);
    const numOfChildren = childrenArray.length;
    if (maxCount && maxCount < numOfChildren) {
        const childrenShow = childrenArray.slice(0, maxCount);
        const childrenHidden = childrenArray.slice(maxCount);
        childrenShow.push(React.createElement(Popover, { content: React.createElement("div", { className: "avatar-popover overflow-auto d-flex align-items-center gap-1" }, childrenHidden), trigger: maxPopoverTrigger, placement: "right", getPopupContainer: getPopupContainer },
            React.createElement(Avatar, { className: "avatar-count", shape: shape, size: size },
                "+",
                numOfChildren - maxCount)));
        return React.createElement("div", { className: cls }, childrenShow);
    }
    return React.createElement("div", { className: cls }, childrenArray);
};
export default AvatarGroup;
