import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../auth';
import AwsDashboardsRoutes from './components/aws/DashboardsPage';
const Routes = ({ match: { url } }) => (React.createElement(ErrorBoundary, null,
    React.createElement(Switch, null,
        React.createElement(Route, { path: `${url}/:id`, component: AwsDashboardsRoutes }),
        React.createElement(Route, { path: url, component: AwsDashboardsRoutes }),
        React.createElement(Redirect, { to: url }))));
export default redirectIfNoPermission(Routes);
