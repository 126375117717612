import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ADMIN_PATH } from '../../utils/constants';
import PropertiesListContainer from '../properties/PropertiesListContainer';
import PropertyFormContainer from '../properties/PropertyFormContainer';
import DefinitionFormContainer from './DefinitionFormContainer';
import DefinitionsListContainer from './DefinitionsListContainer';
const Routes = () => (React.createElement(Switch, null,
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/definitions/:definitionId/edit`, component: DefinitionFormContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/definitions/add`, component: DefinitionFormContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/definitions`, component: DefinitionsListContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/definitions/:definitionId/properties`, component: PropertiesListContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/definitions/:definitionId/properties/add`, component: PropertyFormContainer }),
    React.createElement(Route, { exact: true, path: `${ADMIN_PATH}/definitions/:definitionId/properties/:propertyId/edit`, component: PropertyFormContainer })));
export default Routes;
