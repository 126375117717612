import { ConditionType } from '@hypercharge/portal-utils';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../../common/utils/react-query-client';
import { getAggregations } from '../../../hyperbi/components/dashboards/actions';
import { DEFAULT_SORT_BY_FIELDS_VIEW } from '../../../views/constants';
import { AggregationType } from './types';
import { getAggregationId } from './utils';
export const MISSED_VALUE_PLACEHOLDER_STRING = '-empty-';
export const DEFAULT_FILTER = {
    languageCode: 'en',
    sortBy: DEFAULT_SORT_BY_FIELDS_VIEW,
    query: { condition: ConditionType.and, filters: [] }
};
const AGGREGATION_QUERY_KEY = 'aggregation';
export const invalidateAggregationQuery = async ({ definitionId }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => [AGGREGATION_QUERY_KEY, definitionId].every((key) => query.queryKey.includes(key))
    });
};
export const useAggregation = ({ definitionId, aggregations, metrics, filter, globalFilter, enabled = true }) => {
    const dispatchAggregations = useDispatch();
    const mergedFilter = useMemo(() => {
        const oldFilter = cloneDeep(filter || DEFAULT_FILTER);
        const newFilter = [];
        if (globalFilter && globalFilter.filters.length) {
            newFilter.push(globalFilter);
        }
        if (filter?.query && filter.query.filters.length) {
            newFilter.push(filter.query);
        }
        if (!newFilter.length) {
            return oldFilter;
        }
        if (newFilter.length === 1) {
            oldFilter.query = newFilter[0];
        }
        else {
            oldFilter.query = {
                condition: ConditionType.and,
                filters: newFilter
            };
        }
        return oldFilter;
    }, [globalFilter, filter]);
    const fetchData = useCallback(async () => {
        if (!definitionId) {
            return;
        }
        const requestedAggregations = prepareAggregations(aggregations, metrics);
        const trackTotal = !!metrics?.find((metric) => 'inPercents' in metric && metric.inPercents);
        let aggregationFilter = DEFAULT_FILTER;
        let facetsQuery;
        // @TODO the filter affects the total as it is applied to the entire selection and total will always equal the metric
        // in this situation, you need to impose internal aggregation, which is what facetsQuery does
        // in general facetsQuery is wrong but in this case it solves the problem
        // it is necessary to implement on the backend the ability to set a filter for a specific aggregation and not for all aggregations together
        if (trackTotal && !aggregations.length) {
            // if metric chart
            facetsQuery = filter?.query;
            if (globalFilter) {
                aggregationFilter = { ...DEFAULT_FILTER, query: globalFilter };
            }
        }
        else {
            aggregationFilter = mergedFilter;
            facetsQuery = undefined;
        }
        const response = await dispatchAggregations(getAggregations(definitionId, {
            aggregations: {
                aggregations: requestedAggregations
            },
            filter: aggregationFilter,
            facetsQuery: facetsQuery,
            trackTotal
        }));
        return sortAggregationResult(requestedAggregations, response);
    }, [
        aggregations,
        globalFilter,
        definitionId,
        dispatchAggregations,
        filter?.query,
        metrics,
        mergedFilter
    ]);
    const { data, isLoading, refetch } = useQuery([AGGREGATION_QUERY_KEY, definitionId, { mergedFilter, metrics, aggregations, globalFilter }], fetchData, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        enabled
    });
    return useMemo(() => {
        return {
            isLoading,
            data,
            refetch
        };
    }, [data, isLoading, refetch]);
};
function prepareAggregations(aggregations, metrics = []) {
    const newMetrics = metrics?.filter((metric) => metric.type !== AggregationType.count);
    const newAggregations = aggregations.map((aggregation) => {
        if (!newMetrics.length) {
            return aggregation;
        }
        if ('aggregations' in aggregation &&
            aggregation.aggregations &&
            aggregation.aggregations.length) {
            return {
                ...aggregation,
                aggregations: prepareAggregations(aggregation.aggregations, newMetrics)
            };
        }
        return { ...aggregation, aggregations: newMetrics.length ? newMetrics : undefined };
    });
    if (newAggregations.length) {
        return newAggregations;
    }
    if (newMetrics.length) {
        return newMetrics;
    }
    return metrics;
}
function sortAggregationResult(aggregations, response) {
    for (const aggregationItem of aggregations) {
        if (aggregationItem.type === AggregationType.term &&
            aggregationItem.customOrder &&
            aggregationItem.customOrder.enabled &&
            aggregationItem.customOrder.values &&
            aggregationItem.customOrder.values.length) {
            const aggregationItemId = getAggregationId(aggregationItem);
            const aggregationResponse = response.aggregations[aggregationItemId];
            if (Array.isArray(aggregationResponse)) {
                aggregationResponse.sort((a, b) => {
                    let aSortPosition = aggregationItem.customOrder?.values?.indexOf(a.value) ?? -1;
                    let bSortPosition = aggregationItem.customOrder?.values?.indexOf(b.value) ?? -1;
                    aSortPosition = aSortPosition === -1 ? 99999 : aSortPosition;
                    bSortPosition = bSortPosition === -1 ? 99999 : bSortPosition;
                    return aSortPosition - bSortPosition;
                });
            }
        }
    }
    return response;
}
