import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FieldArray } from 'formik';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import { MdAdd } from 'react-icons/md';
import * as Yup from 'yup';
import PropertyFieldInlineEditor from '../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import { MultiLanguageInput } from '../../../../../common/components/MultiLanguageField';
import { MultiLanguageOptionalValidationSchema } from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../common/components/Toggle';
const FeaureFlagSchema = Yup.object({
    enabled: Yup.bool().default(false)
});
export const getFeatureFlagsOptionsFormSchema = () => {
    return {
        featureFlags: Yup.object({
            availableItemIcon: FeaureFlagSchema,
            countdownToEventStart: FeaureFlagSchema,
            dateTag: FeaureFlagSchema,
            descriptionTitle: FeaureFlagSchema,
            eventLanguages: Yup.object({
                enabled: Yup.bool().default(false),
                languages: Yup.array().of(Yup.string())
            }),
            customTranslations: Yup.object({
                enabled: Yup.bool().default(false),
                translations: Yup.array(Yup.object({
                    key: Yup.string(),
                    values: MultiLanguageOptionalValidationSchema
                }))
            }),
            footerAddress: FeaureFlagSchema,
            orderAgainButton: FeaureFlagSchema,
            shareButton: FeaureFlagSchema
        })
    };
};
const features = [
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_AVAILABLE_ITEM_ICONS',
        name: 'availableItemIcon'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_COUNTDOWN_TO_EVENT_START',
        name: 'countdownToEventStart'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_DATE_TAG',
        name: 'dateTag'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_DESCRIPTION_TITLE',
        name: 'descriptionTitle'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_FOOTER_ADDRESS',
        name: 'footerAddress'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_ORDER_AGAIN_BUTTON',
        name: 'orderAgainButton'
    },
    {
        translationKey: 'APPS_HYPER_EVENT__SHOW_SHARE_BUTTON',
        name: 'shareButton'
    }
];
const FeatureFlagsOptionsForm = ({ configuration, setFieldValue, disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "row" },
        features.map((feature) => (React.createElement("div", { key: feature.translationKey, className: "col-md-6 col-12" },
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null, t(feature.translationKey)),
                React.createElement(FormikField, { name: `configuration.featureFlags.${feature.name}.enabled`, component: Toggle, disabled: disabled }))))),
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__CUSTOM_EVENT_LANGUAGES')),
                React.createElement(FormikField, { name: "configuration.featureFlags.eventLanguages.enabled", component: Toggle, disabled: disabled }),
                React.createElement(FormikField, { name: "configuration.featureFlags.eventLanguages.languages", component: PropertyFieldInlineEditor, type: "entity", meta: { definitionId: 'language', list: true }, canEdit: configuration?.eventLanguages?.enabled && !disabled }))),
        React.createElement("div", { className: "col-md-6 col-12" },
            React.createElement("div", { className: "mt-3" },
                React.createElement("span", null, t('APPS_HYPER_EVENT__CUSTOM_TRANSLATIONS')),
                React.createElement(FormikField, { name: "configuration.featureFlags.customTranslations.enabled", component: Toggle, disabled: disabled }),
                React.createElement(FieldArray, { name: "configuration.featureFlags.customTranslations.translations", render: (cusstomTranslations) => (React.createElement("div", null,
                        configuration?.customTranslations?.translations?.map((translation, index) => (React.createElement("div", { key: index },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col d-flex justify-content-end mb-2 mt-1" },
                                    React.createElement(Button, { inversed: true, disabled: !configuration?.customTranslations?.enabled || disabled, onClick: () => cusstomTranslations.remove(index) },
                                        React.createElement(IoClose, null),
                                        React.createElement("span", { className: "ms-2" }, t('APPS_HYPER_EVENT__DELETE_TRANSLATION'))))),
                            React.createElement("span", null,
                                t('APPS_HYPER_EVENT__TRANSLATION_KEY'),
                                " "),
                            React.createElement(FormikField, { className: "mt-2", name: `configuration.featureFlags.customTranslations.translations.${index}.key`, component: BasicInput, canEdit: true, disabled: !configuration?.customTranslations?.enabled || disabled }),
                            React.createElement(FormikField, { className: "mt-2", name: `configuration.featureFlags.customTranslations.translations.${index}.values`, component: MultiLanguageInput, fieldComponent: BasicInput, canEdit: true, disabled: !configuration?.customTranslations?.enabled || disabled })))),
                        React.createElement("div", { className: "row mt-3" },
                            React.createElement("div", { className: "col" },
                                React.createElement(Button, { disabled: !configuration?.customTranslations?.enabled || disabled, onClick: () => cusstomTranslations.push({ key: '', values: {} }) },
                                    React.createElement(MdAdd, null),
                                    React.createElement("span", { className: "ms-2" }, t('APPS_HYPER_EVENT__ADD_CUSTOM_TRANSLATION'))))))) })))));
};
export default FeatureFlagsOptionsForm;
