import { replace } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAccountId } from '../../../auth';
import { getMessagesState } from '../../reducers/rootSelector';
import SessionSubscription from '../../session/components/SessionSubscription';
import { createMessage, deleteMessage, fetchMessages } from '../actions';
import { getList as getMessages, isFetchListPending } from '../reducer';
import { convertMessagesToTree } from '../utils';
import Conversation from './Conversation';
const ConversationContainer = ({ conversationNamespace, conversationId, className, messagePlaceholder }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const mState = useSelector((s) => getMessagesState(s));
    const accountId = useSelector((s) => getAccountId(s));
    const loading = useMemo(() => isFetchListPending(mState, conversationNamespace, conversationId), [mState, conversationNamespace, conversationId]);
    const messages = useMemo(() => convertMessagesToTree(getMessages(mState, conversationNamespace, conversationId)), [mState, conversationNamespace, conversationId]);
    const activeMessageId = useMemo(() => location.hash && location.hash.slice(1), [location]);
    const fetchMessagesHandler = useCallback(() => dispatch(fetchMessages(conversationNamespace, conversationId)), [dispatch, conversationNamespace, conversationId]);
    const clearMessageHash = useCallback(() => {
        dispatch(replace({ ...location, hash: '' }));
    }, [dispatch, location]);
    const deleteMessageHandler = useCallback((id) => dispatch(deleteMessage(conversationNamespace, conversationId, id)), [dispatch, conversationNamespace, conversationId]);
    const createMessageHandler = useCallback((author, content, mentions, replyToMessageId) => author
        ? dispatch(createMessage(conversationNamespace, conversationId, author, content, mentions, replyToMessageId))
        : Promise.reject(new Error()), [conversationNamespace, conversationId, dispatch]);
    useEffect(() => {
        fetchMessagesHandler();
    }, [fetchMessagesHandler]);
    useEffect(() => {
        if (activeMessageId) {
            const elem = document.getElementById(activeMessageId);
            if (elem) {
                elem.scrollIntoView();
                clearMessageHash();
            }
        }
    }, [activeMessageId, clearMessageHash]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SessionSubscription, { subscriptionKey: `messages--${conversationNamespace}--${conversationId}` }),
        React.createElement(Conversation, { className: className, accountId: accountId || '', createMessage: createMessageHandler, deleteMessage: deleteMessageHandler, loading: loading, messages: messages, messagePlaceholder: messagePlaceholder })));
};
export default ConversationContainer;
