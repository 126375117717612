import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { WS_SESSION_SYNC_URL } from 'config';
import { debounce } from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { getAuthJwt, getIsUiAuthenticated } from '../../auth';
import { SESSION_SYNC_SERVICE } from './actions';
const PING_INTERVAL = 1 * 60 * 1000; // 1min
let webSocketConnection = null;
let activeSubscriptions = [];
let pingTimer = null;
const dataChangedCallbacks = new Map();
const createWebSocketConnection = (store, modules) => {
    const urlProvider = () => {
        const jwt = getAuthJwt(store.getState());
        if (!jwt) {
            return '';
        }
        return `wss://${WS_SESSION_SYNC_URL}/ws-v2-session-sync?token=${jwt}`;
    };
    webSocketConnection = new ReconnectingWebSocket(urlProvider, undefined, {
        debug: false,
        reconnectionDelayGrowFactor: 1,
        connectionTimeout: 1000
    });
    webSocketConnection.addEventListener('open', () => {
        delayPing();
        wsUpdateSubscriptions();
    });
    webSocketConnection.addEventListener('message', (event) => {
        delayPing();
        const message = JSON.parse(event.data);
        if (message.type != 'event' || message.service !== SESSION_SYNC_SERVICE) {
            return;
        }
        const callbacks = getDataChangedCallbacks(message.data?.type);
        callbacks.forEach((callback) => {
            try {
                void callback(message.data?.payload);
            }
            catch (err) {
                console.error(err);
            }
        });
        modules.forEach((module) => {
            try {
                module.processMessage(store, message.data);
            }
            catch (err) {
                console.error(err);
            }
        });
    });
    webSocketConnection.addEventListener('error', (event) => {
        console.warn('webSocketConnection error', event);
        stopPing();
    });
    webSocketConnection.addEventListener('close', () => {
        stopPing();
    });
};
function send(service, msg) {
    if (!webSocketConnection) {
        return;
    }
    if (webSocketConnection.readyState !== ReconnectingWebSocket.OPEN) {
        return;
    }
    webSocketConnection.send(JSON.stringify({
        type: 'request',
        service: service,
        id: generateId('1234567890abcdefghijklmnopqrstuvwxyz', 10),
        data: msg
    }));
    delayPing();
}
function stopPing() {
    if (pingTimer) {
        clearTimeout(pingTimer);
        pingTimer = null;
    }
}
function delayPing() {
    stopPing();
    pingTimer = setTimeout(() => {
        send('ping');
    }, PING_INTERVAL);
}
function wsUpdateSubscriptions() {
    const subscriptions = getActiveSubscriptions();
    send(SESSION_SYNC_SERVICE, {
        action: 'subscriptions',
        subscriptions
    });
}
const addSubscription = (subscription) => {
    removeSubscription(subscription);
    activeSubscriptions.push(subscription);
};
const removeSubscription = (subscription) => {
    activeSubscriptions = activeSubscriptions.filter((sub) => sub != subscription);
};
const getActiveSubscriptions = () => {
    return activeSubscriptions;
};
export const addDataChangedCallback = (messageKey, callback) => {
    const newValue = [...getDataChangedCallbacks(messageKey), callback];
    dataChangedCallbacks.set(messageKey, newValue);
};
export const removeDataChangedCallback = (messageKey, callback) => {
    if (dataChangedCallbacks.has(messageKey)) {
        const callbacks = getDataChangedCallbacks(messageKey);
        const removeIndex = callbacks.findIndex((value) => value === callback);
        if (removeIndex >= 0) {
            callbacks.splice(removeIndex, 1);
            dataChangedCallbacks.set(messageKey, callbacks);
        }
    }
};
const getDataChangedCallbacks = (messageKey) => {
    return dataChangedCallbacks.get(messageKey) || [];
};
const immediateUpdateSession = (store, modules) => {
    if (!webSocketConnection) {
        createWebSocketConnection(store, modules);
    }
    wsUpdateSubscriptions();
};
const updateSession = debounce(immediateUpdateSession, 500);
const sessionMiddlewareFactory = (modules) => {
    const sessionMiddleware = (store) => (next) => (action) => {
        const isAuthenticated = getIsUiAuthenticated(store.getState());
        if (isAuthenticated) {
            switch (action.type) {
                case 'SESSION__ADD_SUBSCRIPTION':
                    addSubscription(action.payload.subscription);
                    updateSession(store, modules);
                    break;
                case 'SESSION__REMOVE_SUBSCRIPTION':
                    removeSubscription(action.payload.subscription);
                    updateSession(store, modules);
                    break;
            }
        }
        return next(action);
    };
    return sessionMiddleware;
};
export default sessionMiddlewareFactory;
