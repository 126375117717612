import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { searchTemplates } from '../templates/actions';
export const SEARCH_DOCUMENTS_QUERY_KEY = 'search-documents';
export const invalidateSearchDocumentsQuery = async () => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(SEARCH_DOCUMENTS_QUERY_KEY)
    });
};
export const useSearchDocuments = ({ filterRequest }) => {
    const dispatchSearchDocuments = useDispatch();
    return useQuery([SEARCH_DOCUMENTS_QUERY_KEY, { filterRequest }], () => {
        return dispatchSearchDocuments(searchTemplates(filterRequest));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
};
