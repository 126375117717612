import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback } from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { SortablePropertyListField } from '../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { ComputedExpressionsDocumentationLink } from '../../../../../common/components/documentation-link';
import { ItemMetaSelector } from '../../../../../common/components/entity-selectors';
import { PropertyTypes } from '../../../../../common/types';
import GroupsField from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
import ProcessPropertyMappingsField from './ProcessPropertyMappingsField';
const isSupportedEntityProperty = ({ type, meta }, node) => type === PropertyTypes.entity && meta.definitionId === node?.parameters?.definitionId;
const CreateEntityForm = ({ disabled, parentViewGroups, metaDefinitionId, changeFieldValue, node }) => {
    const { t } = useI18n();
    const handleChangeDefinitionId = useCallback((_, newValue, previousValue, name) => {
        if (newValue != previousValue) {
            // Reset the property mappings that were based on the previously selected process
            const propertyMappingsFieldName = name?.replace('processMetaId', 'propertyMappings');
            if (propertyMappingsFieldName) {
                changeFieldValue(propertyMappingsFieldName, {});
            }
        }
    }, [changeFieldValue]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", name: "titles", component: AntInput, disabled: disabled, placeholder: t('COMMON__TITLE') })),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('ENTITY'), className: "ps-0" }),
            React.createElement(Field, { name: "definitionId", className: "cp-i-100", component: ItemMetaSelector, disabled: disabled, validate: required, autoFocus: false, onChange: handleChangeDefinitionId }),
            node.parameters?.definitionId ? (React.createElement(React.Fragment, null,
                React.createElement(FieldLabel, { label: t('TASK_SET_ENTITY_VALUES_PROPERTY_MAPPINGS'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "ps-0" }),
                React.createElement(Field, { className: "cp-i-100", name: "propertyMappings", component: ProcessPropertyMappingsField, definitionId: node.parameters.definitionId, includeNestedProperties: false }))) : null,
            React.createElement(FieldLabel, { label: t('TASK_CREATE_ENTITY_OUTPUT_PROPERTY_ID'), className: "ps-0" }),
            React.createElement(Field, { name: "processPropertyId", disabled: disabled || !!node?.parameters?.processPropertyId, component: SortablePropertyListField, definitionId: metaDefinitionId, includeNestedProperties: false, includeProperty: (displayData) => isSupportedEntityProperty(displayData, node), sortable: false, parse: (newValue) => (newValue.length ? newValue[0] : null), format: (value) => (value ? [value] : []) })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default CreateEntityForm;
