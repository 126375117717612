import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { BlockPosition } from '@hypercharge/portal-utils';
import { Radio } from 'antd';
import React from 'react';
import FormikField from '../../../../../../../common/components/formik/FormikField';
const LoginPosition = ({ name, label, isSubmitting }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "p-2" },
        React.createElement(LabelText, null, label),
        React.createElement(FormikField, { name: name, component: ({ input }) => (React.createElement(Radio.Group, { defaultValue: BlockPosition.center, disabled: isSubmitting, value: input.value, onChange: (e) => {
                    input.onChange(e.target.value);
                }, optionType: "button", buttonStyle: "solid" }, Object.values(BlockPosition).map((position) => (React.createElement(Radio, { key: position, value: position }, t(`TENANT_LOGIN_PAGE_POSITION_${position}`)))))) })));
};
export default LoginPosition;
