import { isEmpty, uniq } from 'lodash';
import { isDecisionNode } from '../../activity-diagram/nodes';
const previousActivityNodeIdList = (activity, currentNodeId) => {
    const result = [];
    const previousNodeIds = Object.keys(activity).filter((key) => {
        const node = activity[key];
        if ('nextId' in node && !node['nextIds']) {
            return node['nextId'] === currentNodeId;
        }
        if ('nextIds' in node) {
            // IF node
            return uniq(node['nextIds']).includes(currentNodeId);
        }
        if (isDecisionNode(node)) {
            // Decition node
            return uniq([node.queryTrueId, node.queryFalseId]).includes(currentNodeId);
        }
        return false;
    });
    for (const nodeId of previousNodeIds) {
        result.push(nodeId, ...previousActivityNodeIdList(activity, nodeId));
    }
    return uniq(result);
};
export const previousActivityList = (activity, currentNodeId, language) => {
    const result = [];
    const ids = previousActivityNodeIdList(activity, currentNodeId);
    for (const id of ids) {
        const node = activity[id];
        if ('titles' in node && !isEmpty(node['titles'])) {
            result.push({
                value: node.id,
                label: node.titles[language]
            });
        }
    }
    return result;
};
