import React from 'react';
import AceEditor from 'react-ace';
import * as Yup from 'yup';
import JsonEditor from '../../../../../cms/data/components/item-property/editors/JsonEditor';
import MultiTextEditor from '../../../../../cms/data/components/item-property/editors/MultiTextEditor';
import PlainTextEditor from '../../../../../cms/data/components/item-property/editors/PlainTextEditor';
import Toggle from '../../../../../common/components/Toggle';
import FormikField from '../../../../../common/components/formik/FormikField';
import styles from './GeneralOptionsForm.module.scss';
const stageSchema = Yup.object({
    hosts: Yup.array().of(Yup.string())
});
export const getGeneralFormSchema = () => {
    return {
        customHosts: Yup.array().of(Yup.string()),
        live: stageSchema,
        test: stageSchema,
        draft: stageSchema,
        snippet: Yup.string(),
        snippetHead: Yup.string(),
        frontendSettings: Yup.object({
            logoUrl: Yup.string().nullable(),
            faviconUrl: Yup.string().nullable(),
            issueLink: Yup.string().nullable(),
            feedbackLink: Yup.string().nullable(),
            requestFileLink: Yup.string().nullable(),
            passQueryThruLink: Yup.boolean().nullable()
        }),
        blocksSets: Yup.array()
            .of(Yup.object({
            id: Yup.string().required(),
            enabled: Yup.boolean().required()
        }))
            .nullable(),
        defaultBlocks: Yup.object({
            header: Yup.string().nullable(),
            footer: Yup.string().nullable()
        }).nullable(),
        propertyConfiguration: Yup.mixed().nullable(),
        robotsObject: Yup.object()
            .shape({
            rules: Yup.lazy((value) => Array.isArray(value)
                ? Yup.array()
                    .of(Yup.object({
                    userAgent: Yup.mixed().nullable(),
                    allow: Yup.mixed().nullable(),
                    disallow: Yup.mixed().nullable(),
                    crawlDelay: Yup.number().nullable()
                }))
                    .nullable()
                : Yup.object({
                    userAgent: Yup.mixed().nullable(),
                    allow: Yup.mixed().nullable(),
                    disallow: Yup.mixed().nullable(),
                    crawlDelay: Yup.number().nullable()
                }).nullable())
        })
            .nullable()
    };
};
const frontendSettingsTextFields = [
    { id: 'faviconUrl', label: 'Favicon URL' },
    { id: 'logoUrl', label: 'Logo URL' },
    { id: 'issueLink', label: 'Issue Link' },
    { id: 'feedbackLink', label: 'Feedback Link' },
    { id: 'requestFileLink', label: 'Request File Link' }
];
const GeneralOptionsForm = ({ configuration, disabled }) => {
    return (React.createElement("div", { className: `row ${styles.generalOptionsForm}` },
        React.createElement("div", { className: "col-md-4 col-12" },
            React.createElement("span", null, "Live:"),
            React.createElement(FormikField, { name: "configuration.live.hosts", component: MultiTextEditor, value: configuration.live.hosts, disabled: disabled })),
        React.createElement("div", { className: "col-md-4 col-12" },
            React.createElement("span", null, "Test:"),
            React.createElement(FormikField, { name: "configuration.test.hosts", component: MultiTextEditor, value: configuration.test.hosts, disabled: disabled })),
        React.createElement("div", { className: "col-md-4 col-12" },
            React.createElement("span", null, "Draft:"),
            React.createElement(FormikField, { name: "configuration.draft.hosts", component: MultiTextEditor, value: configuration.draft.hosts, disabled: disabled })),
        React.createElement("div", { className: "mt-3" },
            React.createElement("span", null, "Custom hosts:"),
            React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, value: configuration.customHosts, disabled: disabled })),
        frontendSettingsTextFields.map((field) => (React.createElement("div", { className: "col-md-3 col-12 mt-3", key: field.id },
            React.createElement("span", null,
                field.label,
                ":"),
            React.createElement(FormikField, { name: `configuration.frontendSettings.${field.id}`, component: PlainTextEditor, value: configuration.frontendSettings?.[field.id], disabled: disabled })))),
        React.createElement("div", { className: "col-md-3 col-12 mt-3" },
            React.createElement("span", null, "Default Header block:"),
            React.createElement(FormikField, { name: `configuration.defaultBlocks.header`, component: PlainTextEditor, value: configuration.defaultBlocks?.header, disabled: disabled })),
        React.createElement("div", { className: "col-md-3 col-12 mt-3" },
            React.createElement("span", null, "Default Footer block:"),
            React.createElement(FormikField, { name: `configuration.defaultBlocks.footer`, component: PlainTextEditor, value: configuration.defaultBlocks?.footer, disabled: disabled })),
        React.createElement("div", { className: "mt-3" },
            React.createElement("span", null, "Pass query thru link:"),
            React.createElement(FormikField, { name: "configuration.frontendSettings.passQueryThruLink", component: Toggle })),
        React.createElement("div", { className: `row` },
            React.createElement("div", { className: "mt-3 col-6" },
                React.createElement("span", null, "Snippet head:"),
                React.createElement(FormikField, { name: "configuration.snippetHead", component: AceEditor, mode: "html", theme: "textmate", fontSize: 14, showPrintMargin: true, showGutter: true, highlightActiveLine: true, className: "snippet-editing" })),
            React.createElement("div", { className: "mt-3 col-6" },
                React.createElement("span", null, "Snippet body: "),
                React.createElement(FormikField, { name: "configuration.snippet", component: AceEditor, mode: "html", theme: "textmate", fontSize: 14, showPrintMargin: true, showGutter: true, highlightActiveLine: true, className: "snippet-editing" }))),
        React.createElement("div", { className: "mt-3 col-6" },
            React.createElement("span", null, "Robots object: "),
            React.createElement(FormikField, { name: "configuration.robotsObject", component: JsonEditor, value: configuration.robotsObject, disabled: disabled }))));
};
export default GeneralOptionsForm;
