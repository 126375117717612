import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './LinkEditor.module.scss';
const LinkEditor = ({ value, onChange, disabled, onKeyDown, autoFocus = true, editing = true, inlineMode = false, errorMessage, invalid }) => {
    const { t } = useI18n();
    const { text, link } = value || { text: '', link: '' };
    return (React.createElement("div", { className: `${styles.editor} ${inlineMode ? 'inline' : ''} ${disabled ? 'disabled' : ''} ${invalid ? 'invalid' : ''}` },
        React.createElement(FieldWrapper, { className: "text-input", disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(BasicInput
            // @ts-expect-error
            , { 
                // @ts-expect-error
                input: { value: text }, onChange: (e) => {
                    const target = e.target;
                    onChange && onChange(target.value || link ? { text: target.value, link } : null);
                }, disabled: disabled, placeholder: t('PROPERTY_FIELD_TYPE_link_TEXT_PLACEHOLDER'), onKeyDown: onKeyDown })),
        React.createElement(FieldWrapper, { className: "link-input", disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(BasicInput
            // @ts-expect-error
            , { 
                // @ts-expect-error
                input: { value: link }, autoFocus: autoFocus, onChange: (e) => {
                    const target = e.target;
                    onChange && onChange(target.value || text ? { text, link: target.value } : null);
                }, disabled: disabled, placeholder: t('PROPERTY_FIELD_TYPE_link_LINK_PLACEHOLDER'), onKeyDown: onKeyDown })),
        React.createElement(FieldError, { error: errorMessage })));
};
export default LinkEditor;
