import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import React from 'react';
import PropertyFieldInlineEditor from '../../../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import FormikField from '../../../../../../../common/components/formik/FormikField';
import { PropertyTypes } from '../../../../../../../common/types';
const LoginRichText = ({ isSubmitting, name, label }) => {
    return (React.createElement("div", { className: "p-2" },
        React.createElement(LabelText, null, label),
        React.createElement(FormikField, { name: name, component: PropertyFieldInlineEditor, disabled: isSubmitting, type: PropertyTypes.richtext, canEdit: true })));
};
export default LoginRichText;
