import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { FormFooter } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { map } from 'lodash-es';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { uploadFiles } from '../../../../common/storage';
import { ENTITY_ID_PROPERTY_ID } from '../../../common/utils/constants';
import useCreateItem from '../../../hooks/useCreateItem';
import { validateUrl } from './actions';
import ScheduledList from './components/ScheduledList';
import UploadForm, { UploadStatus } from './components/UploadForm';
import UrlForm, { CheckStatus } from './components/UrlForm';
import { CONFIGURATION_PROPERTY_ID, IMPORT_DEFINITION_ID, IMPORT_TYPE_PROPERTY_ID, ORIGINAL_DATA_PROPERTY_ID, ORIGINAL_URL_PROPERTY_ID, REFERENCE_DEFINITION_ID_PROPERTY_ID } from './constants';
import { ImportTypes } from './types';
const scheduledListColumns = ['createdAt', 'cronExpression', 'originalUrl', 'importStatus'];
const UploadStep = ({ definitionId, handleCancel, goToNextStep }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const [uploadStatus, setUploadStatus] = useState(UploadStatus.idle);
    const [checkStatus, setCheckStatus] = useState(CheckStatus.idle);
    const { mutateAsync: importCreateItem } = useCreateItem({
        definitionId: IMPORT_DEFINITION_ID
    });
    const importEntityId = useRef(generateId());
    const handleUploadFile = useCallback(async (files) => {
        setUploadStatus(UploadStatus.uploading);
        try {
            const uploadedData = await uploadFiles(dispatch, files, importEntityId.current);
            await importCreateItem({
                [ENTITY_ID_PROPERTY_ID]: importEntityId.current,
                [IMPORT_TYPE_PROPERTY_ID]: ImportTypes.manual,
                definitionId: IMPORT_DEFINITION_ID,
                [REFERENCE_DEFINITION_ID_PROPERTY_ID]: definitionId,
                [ORIGINAL_DATA_PROPERTY_ID]: map(uploadedData, 'id') || [],
                [CONFIGURATION_PROPERTY_ID]: {
                    type: uploadedData[0].extension
                }
            });
            setUploadStatus(UploadStatus.success);
        }
        catch (e) {
            setUploadStatus(UploadStatus.fail);
            dispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    }, [dispatch, importCreateItem, definitionId, t]);
    const handleSaveUrl = useCallback(async (data) => {
        setCheckStatus(CheckStatus.uploading);
        try {
            await dispatch(validateUrl(data));
            await importCreateItem({
                [ENTITY_ID_PROPERTY_ID]: importEntityId.current,
                definitionId: IMPORT_DEFINITION_ID,
                [REFERENCE_DEFINITION_ID_PROPERTY_ID]: definitionId,
                [ORIGINAL_URL_PROPERTY_ID]: data.url,
                [CONFIGURATION_PROPERTY_ID]: {
                    type: data.type
                }
            });
            setCheckStatus(CheckStatus.success);
        }
        catch (e) {
            setCheckStatus(CheckStatus.fail);
            dispatch(error({
                title: t('ERROR'),
                message: t('CMS_IMPORTER__FILE_URL_CHECK_FAILED')
            }));
        }
    }, [dispatch, importCreateItem, definitionId, t]);
    const handleGoToNextStep = useCallback(() => {
        goToNextStep(importEntityId.current);
    }, [goToNextStep, importEntityId.current]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "cp-c-row cp-c-align-start-center" },
            React.createElement("div", { className: "cp-i-flex", style: { marginRight: '1rem' } },
                React.createElement(ScSectionCard, null,
                    React.createElement(UploadForm, { uploadStatus: uploadStatus, onSave: handleUploadFile }))),
            React.createElement("div", { className: "cp-i-flex", style: { marginLeft: '1rem' } },
                React.createElement(ScSectionCard, null,
                    React.createElement(UrlForm, { checkStatus: checkStatus, onSave: handleSaveUrl })))),
        React.createElement(FormFooter, { showCancel: true, disabled: [UploadStatus.idle, UploadStatus.uploading, UploadStatus.fail].includes(uploadStatus) &&
                [CheckStatus.idle, CheckStatus.uploading, CheckStatus.fail].includes(checkStatus), submitText: t('CMS_IMPORTER_NEXT'), onSubmit: handleGoToNextStep, onCancel: handleCancel }),
        React.createElement(ScheduledList, { referenceDefinitionId: definitionId, definitionId: IMPORT_DEFINITION_ID, columnPropertyIds: scheduledListColumns })));
};
export default UploadStep;
const ScSectionCard = styled(SectionCard) `
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 100%;
  }
`;
