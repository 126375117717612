import { omit, union } from 'lodash';
import { combineReducers } from 'redux';
export const getId = (optionGroupId, dependantIdValue) => dependantIdValue == null ? optionGroupId : `${optionGroupId}_${dependantIdValue}`;
function groupStatus(state = {}, action) {
    let groupId;
    switch (action.type) {
        case 'METADATA__FETCH_OPTION_GROUP': {
            const { optionGroupIdOrPath, dependantIdValue } = action.meta.http.actionContext;
            groupId = getId(optionGroupIdOrPath, dependantIdValue);
            return {
                ...state,
                [groupId]: {
                    ...state[groupId],
                    pending: true,
                    failed: false
                }
            };
        }
        case 'METADATA__FETCH_OPTION_GROUP_FAIL': {
            const { optionGroupIdOrPath, dependantIdValue } = action.meta.actionContext;
            groupId = getId(optionGroupIdOrPath, dependantIdValue);
            return {
                ...state,
                [groupId]: {
                    ...state[groupId],
                    pending: false,
                    failed: true
                }
            };
        }
        case 'METADATA__FETCH_OPTION_GROUP_SUCCESS': {
            const { optionGroupIdOrPath, dependantIdValue } = action.meta.actionContext;
            groupId = getId(optionGroupIdOrPath, dependantIdValue);
            return {
                ...state,
                [groupId]: {
                    ...state[groupId],
                    pending: false,
                    failed: false,
                    lastFetch: new Date()
                }
            };
        }
        default:
            return state;
    }
}
function byOptionGroupId(state = {}, action) {
    let optionGroupIdOrPath;
    switch (action.type) {
        case 'METADATA__FETCH_OPTION_GROUP_SUCCESS':
            optionGroupIdOrPath = action.meta.actionContext.optionGroupIdOrPath;
            // if we request a list of options without dependantValue
            // we get all the options and yet we cannot access them if we
            // then ask for it with a dependant id. This implementation although
            // more complex is better than depending on the dependantValue of the
            // request
            return action.payload.reduce((newState, option) => {
                let tmpState = { ...newState };
                if (option.dependantValues.length > 0) {
                    option.dependantValues.forEach((val) => {
                        const subId = getId(option.groupId, val);
                        tmpState = {
                            ...tmpState,
                            [subId]: tmpState[subId] ? union(tmpState[subId], [option.value]) : [option.value]
                        };
                    });
                }
                tmpState = {
                    ...tmpState,
                    [optionGroupIdOrPath]: tmpState[optionGroupIdOrPath]
                        ? union(tmpState[optionGroupIdOrPath], [option.value])
                        : [option.value]
                };
                return tmpState;
            }, state);
        case 'METADATA__DELETE_OPTION_SUCCESS':
            return {
                ...state,
                [action.meta.actionContext.optionGroupId]: state[action.meta.actionContext.optionGroupId].filter((value) => value != action.meta.actionContext.value)
            };
        case 'METADATA__SAVE_OPTION':
        case 'METADATA__SAVE_OPTION_SUCCESS':
        case 'METADATA__UPDATE_OPTION':
        case 'METADATA__UPDATE_OPTION_SUCCESS':
            return {
                ...state,
                [action.payload.groupId]: union(state[action.payload.groupId], [action.payload.value])
            };
        case 'METADATA__MIDDLEWARE_OPTIONS':
            return action.payload.reduce((newState, option) => {
                return {
                    ...newState,
                    [option.groupId]: union(newState[option.groupId], [option.value])
                };
            }, state);
        default:
            return state;
    }
}
function byOptionId(state = {}, action) {
    let optionGroupIdOrPath;
    switch (action.type) {
        case 'METADATA__SAVE_OPTION':
        case 'METADATA__SAVE_OPTION_SUCCESS':
        case 'METADATA__UPDATE_OPTION':
        case 'METADATA__UPDATE_OPTION_SUCCESS':
            return {
                ...state,
                [action.payload.groupId]: {
                    ...state[action.payload.groupId],
                    [action.payload.value]: action.payload
                }
            };
        case 'METADATA__FETCH_OPTION_SUCCESS':
            return {
                ...state,
                [action.meta.actionContext.groupId]: {
                    ...(state[action.payload.groupId] || {}),
                    [action.payload.value]: action.payload
                }
            };
        case 'METADATA__DELETE_OPTION_SUCCESS':
            return {
                ...state,
                [action.meta.actionContext.optionGroupId]: omit(state[action.meta.actionContext.optionGroupId], action.meta.actionContext.value)
            };
        case 'METADATA__FETCH_OPTION_GROUP_SUCCESS':
            optionGroupIdOrPath = action.meta.actionContext.optionGroupIdOrPath;
            return action.payload.reduce((newState, option) => {
                let tmpState = { ...newState };
                if (option.dependantValues.length > 0) {
                    option.dependantValues.forEach((val) => {
                        const subId = getId(option.groupId, val);
                        tmpState = {
                            ...tmpState,
                            [subId]: {
                                ...tmpState[subId],
                                [option.value]: option
                            }
                        };
                    });
                }
                tmpState = {
                    ...tmpState,
                    [optionGroupIdOrPath]: {
                        ...tmpState[optionGroupIdOrPath],
                        [option.value]: option
                    }
                };
                return tmpState;
            }, state);
        case 'METADATA__MIDDLEWARE_OPTIONS':
            return action.payload.reduce((newState, option) => ({
                ...newState,
                [option.groupId]: {
                    ...newState[option.groupId],
                    [option.value]: {
                        ...option
                    }
                }
            }), state);
        default:
            return state;
    }
}
function allGroups(state = { pending: false, failed: false, groups: [], lastFetch: undefined }, action) {
    switch (action.type) {
        case 'METADATA__ADD_OPTION_GROUP':
            return {
                ...state,
                groups: [
                    ...state.groups,
                    {
                        groupId: action.payload.groupId,
                        size: 0,
                        modifiedAt: new Date()
                    }
                ]
            };
        case 'METADATA__FETCH_ALL_OPTION_GROUPS':
            return {
                ...state,
                pending: true,
                failed: false
            };
        case 'METADATA__FETCH_ALL_OPTION_GROUPS_FAIL':
            return {
                ...state,
                pending: false,
                failed: true
            };
        case 'METADATA__FETCH_ALL_OPTION_GROUPS_SUCCESS':
            return {
                ...state,
                groups: action.payload,
                pending: false,
                failed: false,
                lastFetch: new Date()
            };
        case 'METADATA__DELETE_OPTION_GROUP_SUCCESS':
            return {
                ...state,
                groups: state.groups.filter((group) => group.groupId != action.meta.actionContext.optionGroupId)
            };
        default:
            return state;
    }
}
export default combineReducers({
    groupStatus,
    byOptionGroupId,
    byOptionId,
    allGroups
});
