import { TENANT_ID } from 'config';
export const saveOption = (option) => ({
    type: 'METADATA__SAVE_OPTION',
    payload: option,
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/options/${option.groupId}`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const updateOption = (option) => ({
    type: 'METADATA__UPDATE_OPTION',
    payload: option,
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/options/${option.groupId}/${option.value}`,
            method: 'PUT',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const deleteOption = (optionGroupId, value) => ({
    type: 'METADATA__DELETE_OPTION',
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/options/${optionGroupId}/${value}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: {
                optionGroupId,
                value
            }
        }
    }
});
export const addOptionGroup = (groupId) => ({
    type: 'METADATA__ADD_OPTION_GROUP',
    payload: {
        groupId
    }
});
export const deleteOptionGroup = (optionGroupId) => ({
    type: 'METADATA__DELETE_OPTION_GROUP',
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/options/${optionGroupId}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: {
                optionGroupId
            }
        }
    }
});
export const fetchOption = (groupId, value) => ({
    type: 'METADATA__FETCH_OPTION',
    payload: {
        groupId,
        value
    },
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/options/${groupId}/${value}`,
            method: 'GET',
            actionContext: {
                groupId,
                value
            }
        },
        auth: true
    }
});
export const fetchAllOptionGroups = () => ({
    type: 'METADATA__FETCH_ALL_OPTION_GROUPS',
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/options`,
            method: 'GET',
            actionContext: {}
        }
    }
});
export const fetchOptionGroup = (optionGroupIdOrPath, dependantIdValue) => ({
    type: 'METADATA__FETCH_OPTION_GROUP',
    meta: {
        http: {
            path: optionGroupIdOrPath.startsWith('/')
                ? optionGroupIdOrPath
                : `/api/metadata/${TENANT_ID}/options/${optionGroupIdOrPath}`,
            params: !dependantIdValue
                ? {}
                : {
                    dependantValue: dependantIdValue
                },
            method: 'GET',
            actionContext: {
                optionGroupIdOrPath,
                dependantIdValue
            }
        },
        auth: true
    }
});
