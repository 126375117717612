import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { find, first, flatten, isArray, last, uniqBy } from 'lodash';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../../../../cms';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { MY_USER_COMPUTED } from '../../../../../cms/common/utils/constants';
import { getPropertyById } from '../../../../../cms/common/utils/utils';
import { isTermsAggregationResult, useAggregation } from '../../../../../cms/hooks/useAggregation';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import { useEntityItems } from '../../../../../cms/hooks/useEntityItems';
import { isConditionQuery } from '../../../../../common/components/ConditionQuery/utils';
import LoadingIndicator from '../../../../../common/components/loading-indicator';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../../crm';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { useView } from '../../../../../views/components/ViewContext';
import { ViewTypes } from '../../../../../views/types';
import { TasksFilterType } from '../../../types';
import { aggregations, getAggregationResult, getFiltersWithoutField, getSearchFilters, getTaskSelectedFilters } from '../../../utils';
const TaskDashboardContext = createContext(undefined);
export const TaskDashboardProvider = ({ mapProperty, getDefaultFilterQueryTaskDashboard, getNewFilterForTaskDashboard, getTaskFilterTypeConfig, ...otherProps }) => {
    const { language, t } = useI18n();
    const { contactId } = useMyContact();
    const { definitionId, updateRouteWithView, filterRequest, metrics, kanban, viewType, rowGrouping } = useView();
    const { displayData } = useEntityDisplayData(definitionId);
    const titleProperty = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const titleTranslatedKey = titleProperty?.meta.translations?.[language];
    const selectedFilters = useMemo(() => getTaskSelectedFilters(filterRequest), [filterRequest]);
    const [searchValue, setSearchValue] = useState();
    const handleChangeSearchValue = (key) => (value) => {
        setSearchValue((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const searchFilterProcessName = useMemo(() => getSearchFilters(searchValue?.relatedTo, TasksFilterType.relatedTo, mapProperty, displayItemMetaList), [displayItemMetaList, mapProperty, searchValue?.relatedTo]);
    const [debouncedSearchFilterProcessName] = useDebounce(searchFilterProcessName, 300);
    const aggregationsProcessConfig = useMemo(() => aggregations({
        field: mapProperty[TasksFilterType.relatedTo],
        filterQuery: getDefaultFilterQueryTaskDashboard({
            filters: [
                ...getFiltersWithoutField(filterRequest, TasksFilterType.relatedTo),
                ...debouncedSearchFilterProcessName
            ]
        }),
        language,
        contactId: contactId || ''
    }), [
        contactId,
        debouncedSearchFilterProcessName,
        filterRequest,
        getDefaultFilterQueryTaskDashboard,
        language,
        mapProperty
    ]);
    const { data: relatedTo, isLoading: isLoadingRelatedTo } = useAggregation({
        definitionId,
        aggregations: aggregationsProcessConfig.aggregations,
        metrics: aggregationsProcessConfig.metrics,
        filter: aggregationsProcessConfig.filter
    });
    const searchFilterTasksTitle = useMemo(() => getSearchFilters(searchValue?.taskTitle, TasksFilterType.taskTitles, mapProperty, undefined, titleTranslatedKey), [mapProperty, searchValue?.taskTitle, titleTranslatedKey]);
    const [debouncedSearchFilterTasksTitle] = useDebounce(searchFilterTasksTitle, 300);
    const aggregationsTasksConfig = useMemo(() => aggregations({
        field: mapProperty[TasksFilterType.taskTitles],
        filterQuery: getDefaultFilterQueryTaskDashboard({
            filters: [
                ...getFiltersWithoutField(filterRequest, TasksFilterType.taskTitles),
                ...debouncedSearchFilterTasksTitle
            ]
        }),
        addAggregationField: titleTranslatedKey,
        language,
        contactId: contactId || ''
    }), [
        contactId,
        debouncedSearchFilterTasksTitle,
        filterRequest,
        getDefaultFilterQueryTaskDashboard,
        language,
        mapProperty,
        titleTranslatedKey
    ]);
    const aggregationsSelectedTasksConfig = useMemo(() => aggregations({
        field: mapProperty[TasksFilterType.taskTitles],
        filterQuery: {
            condition: ConditionType.or,
            filters: selectedFilters[TasksFilterType.taskTitles].map((taskTitle) => ({
                field: mapProperty[TasksFilterType.taskTitles],
                operator: FilterOperatorTypes.is,
                data: taskTitle
            }))
        },
        addAggregationField: titleTranslatedKey,
        language,
        contactId: contactId || '',
        needTotalCount: false,
        size: selectedFilters[TasksFilterType.taskTitles].length
    }), [contactId, language, mapProperty, selectedFilters, titleTranslatedKey]);
    const { data: tasks, isLoading: isLoadingTasks } = useAggregation({
        definitionId,
        aggregations: aggregationsTasksConfig.aggregations,
        metrics: aggregationsTasksConfig.metrics,
        filter: aggregationsTasksConfig.filter
    });
    const { data: selectedTasks } = useAggregation({
        definitionId,
        aggregations: aggregationsSelectedTasksConfig.aggregations,
        metrics: aggregationsSelectedTasksConfig.metrics,
        filter: aggregationsSelectedTasksConfig.filter,
        enabled: !!selectedFilters[TasksFilterType.taskTitles].length && !!titleTranslatedKey
    });
    const searchFilterAssignee = useMemo(() => getSearchFilters(searchValue?.assignee, TasksFilterType.assignees, mapProperty), [mapProperty, searchValue?.assignee]);
    const [debouncedSearchFilterAssignee] = useDebounce(searchFilterAssignee, 300);
    const aggregationsAssigneeConfig = useMemo(() => aggregations({
        field: `${mapProperty[TasksFilterType.assignees]}.${ENTITY_ID_PROPERTY_ID}`,
        filterQuery: getDefaultFilterQueryTaskDashboard({
            filters: [
                ...getFiltersWithoutField(filterRequest, TasksFilterType.assignees),
                ...debouncedSearchFilterAssignee
            ]
        }),
        language,
        addAggregationField: `${mapProperty[TasksFilterType.assignees]}.${TITLE_PROPERTY_ID}`,
        contactId: contactId || ''
    }), [
        contactId,
        debouncedSearchFilterAssignee,
        filterRequest,
        getDefaultFilterQueryTaskDashboard,
        language,
        mapProperty
    ]);
    const { data: assignee, isLoading: isLoadingAssignee } = useAggregation({
        definitionId,
        aggregations: aggregationsAssigneeConfig.aggregations,
        metrics: aggregationsAssigneeConfig.metrics,
        filter: aggregationsAssigneeConfig.filter
    });
    const aggregationsRelatedTo = useMemo(() => {
        const selectedOptions = selectedFilters[TasksFilterType.relatedTo].map((relatedToId) => {
            const relatedTo = find(displayItemMetaList, { definitionId: relatedToId });
            return {
                value: relatedTo?.definitionId || relatedToId,
                label: relatedTo?.title || relatedToId
            };
        });
        if (searchValue?.relatedTo?.length &&
            !flatten(debouncedSearchFilterProcessName?.filter(isConditionQuery).map(({ filters }) => filters)).length) {
            return {
                totalCount: 0,
                options: selectedOptions
            };
        }
        const { aggregationResult, totalCount } = getAggregationResult(aggregationsProcessConfig, relatedTo);
        return {
            totalCount,
            options: uniqBy([
                ...flatten(aggregationResult).map(({ label }) => {
                    const relatedTo = find(displayItemMetaList, { definitionId: label.toString() });
                    return {
                        label: relatedTo?.title || label,
                        value: relatedTo?.definitionId || label
                    };
                }),
                ...selectedOptions
            ], 'value')
        };
    }, [
        aggregationsProcessConfig,
        debouncedSearchFilterProcessName,
        displayItemMetaList,
        relatedTo,
        searchValue?.relatedTo?.length,
        selectedFilters
    ]);
    const aggregationsTask = useMemo(() => {
        const { aggregationResult, totalCount } = getAggregationResult(aggregationsTasksConfig, tasks);
        const { aggregationResult: aggregationSelectedResult } = getAggregationResult(aggregationsSelectedTasksConfig, selectedTasks);
        return {
            totalCount,
            options: uniqBy([
                ...flatten(aggregationResult)
                    .filter(isTermsAggregationResult)
                    .map((aggregation) => {
                    const title = titleTranslatedKey &&
                        titleTranslatedKey in aggregation &&
                        isArray(aggregation[titleTranslatedKey]) &&
                        isTermsAggregationResult(first(aggregation[titleTranslatedKey])) &&
                        last(aggregation[titleTranslatedKey])?.label;
                    return {
                        value: aggregation.label,
                        label: title || aggregation.label
                    };
                }),
                ...selectedFilters.taskTitles.map((taskTitle) => {
                    return {
                        label: (titleTranslatedKey &&
                            last(find(aggregationSelectedResult, (item) => item.label === taskTitle)?.[titleTranslatedKey])?.label) ||
                            taskTitle,
                        value: taskTitle
                    };
                })
            ], 'value')
        };
    }, [
        aggregationsSelectedTasksConfig,
        aggregationsTasksConfig,
        selectedFilters.taskTitles,
        selectedTasks,
        tasks,
        titleTranslatedKey
    ]);
    const { data: selectedAssignee = [] } = useEntityItems({
        definitionId: CONTACT_CMS_DEFINITION_ID,
        ids: selectedFilters.assignees
    });
    const aggregationsAssignee = useMemo(() => {
        const { aggregationResult, totalCount } = getAggregationResult(aggregationsAssigneeConfig, assignee);
        return {
            totalCount,
            options: uniqBy([
                {
                    label: t('NOT_ASSIGNED'),
                    value: FilterOperatorTypes.empty
                },
                {
                    value: MY_USER_COMPUTED,
                    label: `${t('ASSIGN_TO_ME')}`
                },
                ...flatten(aggregationResult)
                    .filter(isTermsAggregationResult)
                    .map((aggregation) => {
                    const titleKey = `${mapProperty[TasksFilterType.assignees]}.${TITLE_PROPERTY_ID}`;
                    const title = titleKey in aggregation &&
                        isArray(aggregation[titleKey]) &&
                        isTermsAggregationResult(aggregation[titleKey]?.[0]) &&
                        aggregation[titleKey]?.[0].label;
                    return {
                        value: aggregation.label,
                        label: title || aggregation.label
                    };
                }),
                ...selectedAssignee.map(({ entityId, title }) => ({ value: entityId, label: title }))
            ], 'value')
        };
    }, [aggregationsAssigneeConfig, assignee, mapProperty, selectedAssignee, t]);
    const handleChangeFilters = useCallback((filterName, field) => (value) => {
        const newFilterRequest = getNewFilterForTaskDashboard({
            filterRequest,
            filterName,
            value,
            field
        });
        const newKanbanColumns = viewType === ViewTypes.kanban &&
            kanban?.groupBy === mapProperty[filterName] &&
            isArray(value)
            ? value
            : kanban?.columns;
        updateRouteWithView({
            newFilters: newFilterRequest,
            newMetrics: metrics,
            shouldReplacePath: true,
            viewType,
            kanban: kanban && newKanbanColumns
                ? {
                    ...kanban,
                    groupBy: kanban?.groupBy,
                    columns: newKanbanColumns
                }
                : undefined,
            rowGrouping
        });
    }, [
        filterRequest,
        getNewFilterForTaskDashboard,
        kanban,
        mapProperty,
        metrics,
        rowGrouping,
        updateRouteWithView,
        viewType
    ]);
    return (React.createElement(React.Fragment, null,
        !displayData && React.createElement(LoadingIndicator, null),
        React.createElement(TaskDashboardContext.Provider, { value: {
                mapProperty,
                getDefaultFilterQueryTaskDashboard,
                getNewFilterForTaskDashboard,
                getTaskFilterTypeConfig,
                selectedFilters,
                availableFilters: {
                    [TasksFilterType.relatedTo]: {
                        value: aggregationsRelatedTo,
                        loading: isLoadingRelatedTo
                    },
                    [TasksFilterType.taskTitles]: {
                        value: aggregationsTask,
                        loading: isLoadingTasks
                    },
                    [TasksFilterType.assignees]: {
                        value: aggregationsAssignee,
                        loading: isLoadingAssignee
                    }
                },
                handleChangeFilters,
                handleChangeSearchValue,
                searchValue
            }, ...otherProps })));
};
export const useTaskDashboard = () => {
    const context = useContext(TaskDashboardContext);
    if (context == null) {
        throw new Error('useTaskDashboard must be used within an TaskDashboardProvider');
    }
    return context;
};
