import { CMS_API_PATH } from '../../../cms';
export const getAggregations = (definitionId, payload) => {
    return {
        type: 'CMS__GET_AGGREGATIONS',
        payload: payload,
        meta: {
            http: {
                path: `${CMS_API_PATH}/aggregate/${definitionId}`,
                method: 'POST',
                allowPromiseReject: true
            }
        }
    };
};
