import axios from 'axios';
export const HTTP_SUFFIX_SUCCESS = '_SUCCESS';
export const HTTP_SUFFIX_FAIL = '_FAIL';
export const httpMiddleware = (store) => (next) => (action) => {
    if (action.meta != null && action.meta.http != null) {
        const requestConfig = action.meta.http;
        const method = requestConfig.method;
        const axiosConfig = {
            method: method,
            url: requestConfig.path,
            data: action.payload,
            headers: requestConfig.headers || {},
            params: requestConfig.params || {}
        };
        next(action);
        const dispatchFail = (error) => {
            store.dispatch({
                type: `${action.type}${HTTP_SUFFIX_FAIL}`,
                payload: error,
                meta: {
                    actionContext: requestConfig.actionContext
                },
                error: true
            });
            if (window.Sentry) {
                window.Sentry.captureException(error);
            }
        };
        const promise = axios(axiosConfig).then((response) => {
            if (method == 'GET' && response.status == 204) {
                dispatchFail(response);
            }
            else {
                store.dispatch({
                    type: `${action.type}${HTTP_SUFFIX_SUCCESS}`,
                    payload: response.data,
                    meta: {
                        actionContext: requestConfig.actionContext
                    }
                });
            }
            return response.data;
        }, (error) => {
            dispatchFail(error);
            return Promise.reject(error);
        });
        // return a promise to the caller of the dispatch so that
        // it can wait for the operation to finish before continuing,
        // if not explicitly requested, swallow errors.
        return action.meta.http.allowPromiseReject ? promise : promise.catch(console.error);
    }
    return next(action);
};
export default httpMiddleware;
