import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Button, Collapse, Tooltip } from 'antd';
import React from 'react';
import { IoReload } from 'react-icons/io5';
import { PoseGroup } from 'react-pose';
import { Item } from '../..';
import { FEATURED_ENTITIES_PATH } from '../../../../../../../cms';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../../../../../../crm';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../../../../../../common/utils/types';
const FinalStatusStepDescription = ({ processDetails, tasks, activeKeys, onPanelChange, onClickRestartTask }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        processDetails.status === 'CANCELLED' && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "me-1" }, t('BY')),
            React.createElement("span", { className: "me-1" },
                React.createElement(ContactDisplayName, { link: [
                        FEATURED_ENTITIES_PATH,
                        CONTACT_CMS_DEFINITION_ID,
                        processDetails.statusUpdatedBy
                    ].join('/'), contactId: processDetails.statusUpdatedBy })),
            React.createElement(ReadableDateTime, { dateTime: processDetails.statusUpdatedAt }))),
        tasks.length > 0 && (React.createElement(PoseGroup, null,
            React.createElement(Collapse, { key: "FinalStatusStepDescription", ghost: true, onChange: onPanelChange, activeKey: activeKeys, items: tasks.map(({ taskTitle, taskRunId, statusUpdatedAt, initiatedAt, taskType, taskSubType, status, restartedBy }) => {
                    const isAllowRestartTask = !!onClickRestartTask &&
                        taskType === ActivityNodeTypes.ACTION &&
                        taskSubType &&
                        ![
                            ActivityActionNodeSubtypes.MANUAL,
                            ActivityActionNodeSubtypes.SEMIAUTOMATED
                        ].includes(taskSubType);
                    return {
                        key: taskRunId,
                        label: React.createElement(TooltipTruncate, null, taskTitle),
                        extra: isAllowRestartTask ? (React.createElement(Tooltip, { title: t('RERUN_TASK') },
                            React.createElement(Button, { icon: React.createElement(IoReload, null), type: "text", onClick: (event) => {
                                    event.stopPropagation();
                                    onClickRestartTask(taskRunId);
                                }, className: "safe-space" }))) : undefined,
                        children: (React.createElement(Item, { key: taskRunId },
                            restartedBy && (React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('RESTARTED_BY')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ContactDisplayName, { contactId: restartedBy })))),
                            React.createElement("div", { key: `initiatedAt_${taskRunId}`, className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('INITIATED_AT')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ReadableDateTime, { dateTime: initiatedAt }))),
                            React.createElement("div", { key: `statusUpdatedAt_${taskRunId}`, className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t(`${status}_AT`)),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ReadableDateTime, { dateTime: statusUpdatedAt }))))),
                        className: 'CustomCollapsePanel'
                    };
                }) })))));
};
export default FinalStatusStepDescription;
