import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput, createValidator, email, required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { COUNTRY_DEFINITION_ID } from '../../../../cms/constants';
import { CustomEntitySelector } from '../../../../common/components/entity-selectors';
import FormikField from '../../../../common/components/formik/FormikField';
const ContactInfoForm = ({ contactInfo, onSubmit }) => {
    const { t } = useI18n();
    const fields = useMemo(() => [
        {
            label: t('CONTACT_INFO_COMPANY_NAME'),
            name: 'name',
            validate: [required]
        },
        {
            label: t('CONTRACT_VAT_NUMBER'),
            name: 'vat',
            validate: [required]
        },
        {
            label: t('CONTACT_INFO_CONTACT_NAME'),
            name: 'contactName',
            validate: [required]
        },
        {
            label: t('CONTACT__EMAIL'),
            name: 'email',
            validate: [required, email]
        },
        {
            label: t('CONTACT_INFO_PHONE_NUMBER'),
            name: 'phone',
            validate: [required]
        },
        {
            label: t('CONTACT_INFO_COUNTRY'),
            name: 'country',
            validate: [required]
        },
        {
            label: `${t('CONTACT_INFO_ADDRESS_LINE')} 1`,
            name: 'addressLine1',
            validate: [required]
        },
        {
            label: `${t('CONTACT_INFO_ADDRESS_LINE')} 2`,
            name: 'addressLine2'
        },
        {
            label: t('ADDRESS__POSTAL_CODE'),
            name: 'postalCode',
            validate: [required]
        },
        {
            label: t('ADDRESS__CITY'),
            name: 'city',
            validate: [required]
        }
    ], [t]);
    const formValidator = useMemo(() => {
        const rules = fields.reduce((result, field) => {
            if (field.validate) {
                result[field.name] = field.validate;
            }
            return result;
        }, {});
        return createValidator(rules);
    }, [fields]);
    return (React.createElement("div", { className: "contactInfo" },
        React.createElement(Formik, { initialValues: contactInfo, onSubmit: onSubmit, validate: formValidator },
            React.createElement(Form, { noValidate: true },
                React.createElement("div", { className: "titleContactInfo" },
                    t('CONTACT_INFORMATION_TITLE'),
                    React.createElement(Button, { className: "actionButton", type: "submit" }, t('FORM__SAVE'))),
                React.createElement("div", { className: "row" }, fields.map((field, index) => (React.createElement("div", { className: "col-xl-3 col-lg-4 col-sm-6 col-xs-12", key: index }, field.name == 'country' ? (React.createElement(FormikField, { name: field.name, label: `${field.label} ${field.validate ? '*' : ''}`, component: CustomEntitySelector, definitionId: COUNTRY_DEFINITION_ID, autoFocus: false, popupMatchSelectWidth: false, withoutCreateNew: true })) : (React.createElement(FormikField, { name: field.name, label: `${field.label} ${field.validate ? '*' : ''}`, component: BasicInput, type: "text" }))))))))));
};
export default ContactInfoForm;
