import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tree } from 'antd';
import { find, isString } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FaRegFolder, FaRegFolderOpen } from 'react-icons/fa';
import { IoReorderThree } from 'react-icons/io5';
import { getFeaturedDefinitionIdsOneLevel } from '../../../../../cms/data/utils';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import SideMenuIcon from '../../../../../common/components/side-menu/SideMenuIcon/SideMenuIcon';
import { isFeaturedDefinitionIdT } from '../../../../../configs/types';
import { useViews } from '../../../../../views';
import { EditFeaturedDefinitionIdFolder } from '../EditFeaturedDefinitionIdFolder';
import styles from './FeaturedDefinitionIds.module.scss';
import { FeaturedDefinitionId } from './components/FeaturedDefinitionId';
import { FeaturedDefinitionIdRowActions } from './components/FeaturedDefinitionIdRowActions';
import { handleDrop } from './utils';
export const filterRemovedDefinition = (featuredDefinitionId, views, displayItemMetaList) => {
    if (!isString(featuredDefinitionId)) {
        return true;
    }
    const view = find(views, { entityId: featuredDefinitionId });
    const definitionId = view?.referenceDefinitionId || featuredDefinitionId;
    return (displayItemMetaList.findIndex((displayItemMeta) => displayItemMeta.definitionId === definitionId) !== -1);
};
const FeaturedDefinitionIds = ({ isDataPending, isSubmitting, featuredDefinitionIds, onDelete, onChange, onSort }) => {
    const { language } = useI18n();
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const { views } = useViews();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const treeData = useMemo(() => {
        return (featuredDefinitionIds
            ?.filter((featuredDefinitionId) => filterRemovedDefinition(featuredDefinitionId, views, displayItemMetaList))
            .map((featuredDefinitionId) => {
            if (isString(featuredDefinitionId)) {
                return {
                    key: featuredDefinitionId,
                    title: (React.createElement(FeaturedDefinitionId, { featuredDefinitionId: featuredDefinitionId, onDelete: () => onDelete(featuredDefinitionId) }))
                };
            }
            return {
                key: featuredDefinitionId.id,
                title: (React.createElement("div", { className: "py-2 d-flex align-items-center justify-content-between" },
                    React.createElement("div", { className: "d-flex align-items-center flex-truncate" },
                        React.createElement(SideMenuIcon, { itemIcon: expandedKeys.includes(featuredDefinitionId.id) ? FaRegFolderOpen : FaRegFolder }),
                        React.createElement("div", { className: "flex-truncate ms-1" },
                            React.createElement(TooltipTruncate, null, getTranslation(featuredDefinitionId.titles, language)))),
                    React.createElement(FeaturedDefinitionIdRowActions, { startAdditionalAction: React.createElement(EditFeaturedDefinitionIdFolder, { featuredDefinitionId: featuredDefinitionId, onSave: onChange }), onDelete: () => onDelete(featuredDefinitionId.id) }))),
                children: featuredDefinitionId.values
                    .filter((featuredDefinitionId) => filterRemovedDefinition(featuredDefinitionId, views, displayItemMetaList))
                    .map((featuredDefinitionId) => {
                    return {
                        key: featuredDefinitionId,
                        title: (React.createElement(FeaturedDefinitionId, { featuredDefinitionId: featuredDefinitionId, onDelete: () => onDelete(featuredDefinitionId) }))
                    };
                })
            };
        }) || []);
    }, [
        displayItemMetaList,
        expandedKeys,
        featuredDefinitionIds,
        language,
        onChange,
        onDelete,
        views
    ]);
    const onDrop = useCallback((info) => {
        const newTreeData = handleDrop(info, treeData);
        const newFeaturedDefinitionIds = newTreeData
            .map((item) => {
            if (item.children) {
                const featuredDefinitionId = find(featuredDefinitionIds, (featuredDefinitionId) => isString(featuredDefinitionId)
                    ? featuredDefinitionId === item.key
                    : featuredDefinitionId.id === item.key);
                return {
                    id: item.key,
                    titles: isString(featuredDefinitionId)
                        ? featuredDefinitionId
                        : featuredDefinitionId?.titles,
                    values: item.children.map((item) => item.key)
                };
            }
            return item.key;
        })
            .filter(isFeaturedDefinitionIdT);
        onSort(newFeaturedDefinitionIds);
    }, [featuredDefinitionIds, onSort, treeData]);
    const featuredDefinitionIdsInFolders = useMemo(() => {
        const featuredDefinitionIdsOneLevel = getFeaturedDefinitionIdsOneLevel(featuredDefinitionIds || []);
        return featuredDefinitionIdsOneLevel.filter((featuredDefinitionId) => {
            return !featuredDefinitionIds?.includes(featuredDefinitionId);
        });
    }, [featuredDefinitionIds]);
    const allowDrop = useCallback((options) => {
        if (options.dragNode.children) {
            if (options.dropPosition === -1) {
                return true;
            }
            return (!!options.dropPosition &&
                !featuredDefinitionIdsInFolders.includes(options.dropNode.key.toString()));
        }
        return !!options.dropNode?.children || options.dropPosition !== 0;
    }, [featuredDefinitionIdsInFolders]);
    const onClick = useCallback((_, node) => {
        if (node.expanded) {
            setExpandedKeys(expandedKeys.filter((key) => key !== node.key));
        }
        else {
            setExpandedKeys([...expandedKeys, node.key]);
        }
    }, [expandedKeys]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(Tree, { onClick: onClick, disabled: isSubmitting || isDataPending, expandedKeys: expandedKeys, onExpand: (expandedKeys) => setExpandedKeys(expandedKeys), className: "draggable-tree", draggable: {
                icon: React.createElement(IoReorderThree, { size: "1.5rem", color: "var(--neutralColor-6)" })
            }, itemHeight: 46, blockNode: true, allowDrop: allowDrop, onDrop: onDrop, treeData: treeData })));
};
export default FeaturedDefinitionIds;
