import LoadingIndicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { isProcessMeta } from '../../../workflows/common/utils/utils';
import formUtilsStyles from './formUtils.module.scss';
export const FieldsContainerTitle = styled.label `
  display: block;
  font-weight: 500;
  margin: ${({ small }) => (small ? '1rem 0 0.5rem' : '1.5rem 0 1rem')};
`;
export const FieldsContainer = styled.div `
  margin: -0.5rem;
  padding: 0 !important;
`;
const LoadingContainer = styled.div `
  height: 98px;
  width: 100%;
`;
export const LoadingBlock = () => (React.createElement(LoadingContainer, { className: "cp-c-row cp-c-align-center-center" },
    React.createElement(LoadingIndicator, null)));
export const prepareGroupPermissionsForSubmit = (formValues) => {
    const updateFormValues = {};
    if (formValues?.permissions?.view) {
        updateFormValues.permissions = {
            ...formValues.permissions,
            view: Object.entries(formValues.permissions.view).reduce((permissions, [groupId, permission]) => {
                if (permission.restrictions?.filters.length && formValues.viewGroups) {
                    permissions[groupId] = {
                        ...permission,
                        isEnabled: formValues.viewGroups.includes(groupId)
                    };
                }
                return permissions;
            }, {})
        };
    }
    if (isProcessMeta(formValues)) {
        updateFormValues.activity = Object.entries(formValues.activity).reduce((activity, [nodeId, node]) => {
            if ('permissions' in node && (node.permissions?.view || node.permissions?.write)) {
                const newNode = cloneDeep(node);
                for (const permissionType in newNode.permissions) {
                    const groupsPermissions = newNode.permissions[permissionType];
                    if (!groupsPermissions || isEmpty(groupsPermissions)) {
                        continue;
                    }
                    for (const groupId in groupsPermissions) {
                        const groupPermission = groupsPermissions[groupId];
                        if (!groupPermission) {
                            continue;
                        }
                        if (!groupPermission.restrictions?.filters.length) {
                            delete groupsPermissions[groupId];
                        }
                        else {
                            groupPermission.isEnabled = true;
                            if (permissionType === 'write') {
                                groupPermission.properties = ['taskStatus', 'taskAssignedTo'];
                            }
                        }
                    }
                    if (permissionType === 'view' && newNode.viewGroups.length) {
                        newNode.viewGroups = newNode.viewGroups.filter((groupId) => !(groupId in (groupsPermissions ?? {})));
                    }
                    else if (permissionType === 'write' && newNode.executeGroups?.length) {
                        newNode.executeGroups = newNode.executeGroups.filter((groupId) => !(groupId in (groupsPermissions ?? {})));
                    }
                }
                activity[nodeId] = newNode;
            }
            else {
                activity[nodeId] = node;
            }
            return activity;
        }, {});
    }
    return {
        ...formValues,
        ...updateFormValues
    };
};
export const getIsFormHasError = (meta) => !!meta && (meta?.touched || ('submitFailed' in meta && meta?.submitFailed)) && !!meta?.error;
export const ErrorLabel = ({ position, error, show }) => show ? React.createElement("div", { className: `${formUtilsStyles.errorLabel} ${position}-error` }, error) : null;
