import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success, warning } from '@hypercharge/hyper-react-base/lib/notifications';
import React, { useCallback, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { Prompt } from 'react-router';
import { compose } from 'redux';
import { Field, FormSection, formValueSelector, getFormMeta, getFormSyncErrors, reduxForm } from 'redux-form';
import { PropertiesProvider } from '../../../../../cms/common/context/PropertiesProvider';
import { Drawer, DrawerContent } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { prepareGroupPermissionsForSubmit } from '../../../../../common/utils/formUtils';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../../../../common/utils/types';
import { saveProcessMeta } from '../../../actions';
import ActivityDiagramField from '../activity-diagram/ActivityDiagramField';
import { isDecisionNode, isHp3ActivityNode } from '../activity-diagram/nodes';
import { nodeHasProperties } from '../activity-diagram/utils';
import { validateDiagram } from '../activity-diagram/validation';
import ActionForm from '../node-form/ActionForm';
import { CancelProcessForm } from '../node-form/CancelProcessForm';
import CreateEntityForm from '../node-form/CreateEntityForm';
import CronTriggerForm from '../node-form/CronTriggerForm/CronTriggerForm';
import DecisionForm from '../node-form/DecisionForm';
import DelayForm from '../node-form/DelayForm';
import EmailFeedbackTriggerForm from '../node-form/EmailFeedbackTriggerForm';
import EmailForm from '../node-form/EmailForm';
import EntityTriggerForm from '../node-form/EntityTriggerForm/EntityTriggerForm';
import GenerateDocumentForm from '../node-form/GenerateDocumentForm';
import GenerateFormUrlForm from '../node-form/GenerateFormUrlForm';
import GenerateQrCodeForm from '../node-form/GenerateQrCodeForm';
import GetEntityValuesForm from '../node-form/GetEntityValuesForm';
import Hp3nNodeForm, { HP3N_NODE_PREFIX } from '../node-form/Hp3nNodeForm/Hp3nNodeForm';
import LoopForm from '../node-form/LoopForm';
import ManualActivityForm from '../node-form/ManualActivityForm';
import MilestoneForm from '../node-form/MilestoneForm';
import SetEntityValuesForm from '../node-form/SetEntityValuesForm';
import SetProcessValuesForm from '../node-form/SetProcessValuesForm';
import SmsForm from '../node-form/SmsForm';
import StartProcessForm from '../node-form/StartProcessForm';
import StepForm from '../node-form/StepForm';
import UndefinedActionForm from '../node-form/UndefinedActionForm';
import styles from './ActivityContainer.module.scss';
const ActivityContainer = ({ processMeta, parentViewGroups, reset, handleSubmit, className, submitting, change, dirty, nodesWithErrors, contentHeight, extraActionButtons, isSystem, disabled }) => {
    const { t, language } = useI18n();
    const [selectedNodeId, setSelectedNodeId] = useState('Start');
    const [propertyDrawerVisible, setPropertyDrawerVisible] = useState(false);
    const formSectionName = `activity.${selectedNodeId || ''}`;
    const store = useStore();
    const activeNode = formValueSelector('activitySettings')(store.getState(), formSectionName);
    const getTriggerForm = useCallback((node, definitionId, disabled, change) => {
        switch (node.target) {
            case 'EMAIL_FEEDBACK_TRIGGER':
                return React.createElement(EmailFeedbackTriggerForm, { disabled: disabled, node: node });
            case 'ENTITY_EVENT_TRIGGER':
                return (React.createElement(EntityTriggerForm, { disabled: disabled, metaDefinitionId: definitionId, changeFieldValue: change, node: node }));
            case 'CRON_TRIGGER':
                return React.createElement(CronTriggerForm, { disabled: disabled });
            default:
                if ('webhookId' in node) {
                    return (React.createElement(Hp3nNodeForm, { key: node.id, metaDefinitionId: definitionId, disabled: disabled, node: node }));
                }
                else {
                    return null;
                }
        }
    }, []);
    const getActionForm = useCallback((node, definitionId, disabled, change) => {
        if (node.type === ActivityNodeTypes.ACTION &&
            node.subtype === ActivityActionNodeSubtypes.AUTOMATED) {
            if (isHp3ActivityNode(node)) {
                return (React.createElement(Hp3nNodeForm, { key: node.id, metaDefinitionId: definitionId, disabled: disabled, node: node }));
            }
            if (isDecisionNode(node)) {
                return React.createElement(DecisionForm, { key: node.id, metaDefinitionId: definitionId, disabled: disabled });
            }
            switch (node.target) {
                case 'EMAIL':
                    return (React.createElement(EmailForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change }));
                case 'SEND_SMS':
                    return (React.createElement(SmsForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change }));
                case 'LOG':
                    return null;
                case 'CREATE_PROCESS_RUN':
                    return (React.createElement(StartProcessForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change, node: node }));
                case 'SET_PROCESS_VALUES':
                    return (React.createElement(SetProcessValuesForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled }));
                case 'SET_ENTITY_VALUES':
                    return (React.createElement(SetEntityValuesForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change }));
                case 'CANCEL_PROCESS':
                    return (React.createElement(CancelProcessForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change, node: node }));
                case 'GET_ENTITY_VALUES':
                    return (React.createElement(GetEntityValuesForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change, node: node }));
                case 'GENERATE_FORM_URL':
                    return (React.createElement(GenerateFormUrlForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change, node: node }));
                case 'CREATE_ENTITY':
                    return (React.createElement(CreateEntityForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change, node: node }));
                case 'GENERATE_DOCUMENT':
                    return (React.createElement(GenerateDocumentForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, node: node }));
                case 'GENERATE_QR_CODE':
                    return (React.createElement(GenerateQrCodeForm, { key: node.id, parentViewGroups: parentViewGroups, disabled: disabled }));
                case 'MILESTONE':
                    return (React.createElement(MilestoneForm, { key: node.id, metaDefinitionId: definitionId, activeNodeId: node.id, parentViewGroups: parentViewGroups, disabled: disabled }));
                case 'STEP':
                    return (React.createElement(StepForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled }));
                default:
                    return (React.createElement(UndefinedActionForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled }));
            }
        }
        else if (node.type === ActivityNodeTypes.ACTION &&
            node.subtype === ActivityActionNodeSubtypes.SEMIAUTOMATED) {
            switch (node?.target) {
                case 'DELAY':
                    return (React.createElement(DelayForm, { key: node.id, parentViewGroups: parentViewGroups, metaDefinitionId: definitionId, disabled: disabled }));
            }
        }
        else if (node.type === ActivityNodeTypes.ACTION &&
            node.subtype === ActivityActionNodeSubtypes.MANUAL &&
            !isDecisionNode(node)) {
            switch (node.target) {
                case 'ACTIVITY':
                    return (React.createElement(ManualActivityForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, node: node, changeFieldValue: change }));
                default:
                    return (React.createElement(ActionForm, { key: node.id, metaDefinitionId: definitionId, parentViewGroups: parentViewGroups, disabled: disabled, changeFieldValue: change, node: node }));
            }
        }
    }, [parentViewGroups]);
    const getNodeForm = useCallback((node, disabled, change) => {
        const definitionId = processMeta.id;
        switch (node.type) {
            case ActivityNodeTypes.START:
                if (node.target) {
                    return getTriggerForm(node, definitionId, disabled, change);
                }
                return null;
            case ActivityNodeTypes.ACTION:
                return getActionForm(node, definitionId, disabled, change);
            case ActivityNodeTypes.LOOP_WHILE:
                return React.createElement(LoopForm, { key: node.id, metaDefinitionId: definitionId, disabled: disabled });
            default:
                return null;
        }
    }, [getActionForm, getTriggerForm, processMeta.id]);
    const getPropertiesDrawerTitle = useCallback(() => {
        let title = 'Edit';
        if (activeNode.type === ActivityNodeTypes.ACTION) {
            if (activeNode.subtype === ActivityActionNodeSubtypes.AUTOMATED) {
                title = `${t('PROCESS_META__AUTOMATED_TASKS')} ${t('PROCESS_META__MANUAL_TASK')}: `;
                switch (activeNode.target) {
                    case 'CREATE_PROCESS_RUN':
                        title += t('TASKS_START_PROCESS');
                        break;
                    case 'CANCEL_PROCESS':
                        title += t('WORKFLOWS__CANCEL_PROCESS');
                        break;
                    case 'GENERATE_QR_CODE':
                        title += t('PROCESS_META__GENERATE_QR_CODE_TASK');
                        break;
                    case 'SET_PROCESS_VALUES':
                        title += t('PROCESS_META__SET_PROCESS_VALUES_TASK');
                        break;
                    case 'SET_ENTITY_VALUES':
                        title += t('PROCESS_META__SET_ENTITY_VALUES_TASK');
                        break;
                    case 'GET_ENTITY_VALUES':
                        title += t('PROCESS_META__GET_ENTITY_VALUES_TASK');
                        break;
                    case 'CREATE_ENTITY':
                        title += t('PROCESS_META__CREATE_ENTITY_TASK');
                        break;
                    case 'GENERATE_FORM_URL':
                        title += t('PROCESS_META__GENERATE_FORM_URL');
                        break;
                    case 'EMAIL':
                        title += t('PROCESS_META__EMAIL_TASK');
                        break;
                    case 'SEND_SMS':
                        title += t('PROCESS_META__SEND_SMS_TASK');
                        break;
                    case 'GENERATE_DOCUMENT':
                        title += t('PROCESS_META__GENERATE_DOCUMENT_TASK');
                        break;
                    case 'MILESTONE':
                        title += t('PROCESS_META__MILESTONE_TASK');
                        break;
                    case 'STEP':
                        title += t('PROCESS_META__STEP_TASK');
                        break;
                    case 'DECISION':
                        title = `${t('PROCESS_META__CONTROL_NODES')}: ${t('PROCESS_META__DECISION')}`;
                        break;
                }
                if (activeNode.target?.startsWith(HP3N_NODE_PREFIX)) {
                    title += activeNode.titles[language];
                }
            }
            else if (activeNode.subtype === ActivityActionNodeSubtypes.MANUAL) {
                if (activeNode.target === 'ACTIVITY') {
                    title = `${t('PROCESS_META__MANUAL_TASKS')} ${t('PROCESS_META__MANUAL_ACTIVITY_TASK')}`;
                }
                else {
                    title = `${t('PROCESS_META__MANUAL_TASKS')} ${t('PROCESS_META__MANUAL_TASK')}`;
                }
            }
            else if (activeNode.subtype === ActivityActionNodeSubtypes.SEMIAUTOMATED) {
                if (activeNode.target === 'DELAY') {
                    title = `${t('PROCESS_META__CONTROL_NODES')}: ${t('PROCESS_META__DELAY')}`;
                }
            }
        }
        else if (activeNode.type === ActivityNodeTypes.LOOP_WHILE) {
            title = `${t('PROCESS_META__CONTROL_NODES')}: ${t('PROCESS_META__LOOP')}`;
        }
        else if (activeNode.type === ActivityNodeTypes.START) {
            switch (activeNode.target) {
                case 'EMAIL_FEEDBACK_TRIGGER':
                    title = `${t('PROCESS_META__TRIGGER_NODES')}: ${t('PROCESS_META__START_EMAIL_FEEDBACK_TRIGGER_TASK')}`;
                    break;
                case 'ENTITY_EVENT_TRIGGER':
                    title = `${t('PROCESS_META__TRIGGER_NODES')}: ${t('PROCESS_META__START_ENTITY_EVENT_TRIGGER_TASK')}`;
                    break;
                case 'CRON_TRIGGER':
                    title = `${t('PROCESS_META__TRIGGER_NODES')}: ${t('PROCESS_META__START_CRON_TRIGGER_TASK')}`;
                    break;
                default:
                    break;
            }
        }
        return title;
    }, [activeNode, t]);
    const openPropertiesDrawer = useCallback(() => {
        setPropertyDrawerVisible(true);
    }, []);
    const closePropertiesDrawer = useCallback(() => {
        setPropertyDrawerVisible(false);
    }, []);
    const selectNode = useCallback((node, openProperties = true) => {
        setSelectedNodeId(node?.id || 'Start');
        if (openProperties && nodeHasProperties(node)) {
            openPropertiesDrawer();
        }
    }, [openPropertiesDrawer]);
    const onReset = useCallback(() => {
        reset();
        setSelectedNodeId('Start');
    }, [reset]);
    return (React.createElement("form", { noValidate: true, onSubmit: handleSubmit, className: `h-100 ${className || ''}` },
        React.createElement(Field, { name: "activity", contentHeight: contentHeight, component: ActivityDiagramField, selectedNode: activeNode, onSelectNode: selectNode, isSubmitting: submitting, disabled: disabled, isDirty: dirty, handleSubmit: handleSubmit, reset: onReset, nodesWithErrors: nodesWithErrors, processMeta: processMeta, closePropertiesDrawer: closePropertiesDrawer, extraActionButtons: extraActionButtons, isSystem: isSystem }),
        React.createElement(PropertiesProvider, null,
            React.createElement(Drawer, { title: getPropertiesDrawerTitle(), placement: "right", onClose: closePropertiesDrawer, open: propertyDrawerVisible, destroyOnClose: true, rootClassName: styles.wrapper },
                React.createElement(DrawerContent, null,
                    React.createElement(DrawerContentBlock, null,
                        React.createElement(FormSection, { name: formSectionName }, getNodeForm(activeNode, disabled || submitting, change)))))),
        React.createElement(Prompt, { when: dirty && !submitting, message: () => t('UNSAVED_DATA_CONFIRM') })));
};
const formName = 'activitySettings';
const mapStateToProps = (s, ownProps) => {
    const formErrors = getFormSyncErrors(formName)(s);
    const meta = getFormMeta(formName)(s);
    return {
        initialValues: ownProps.processMeta,
        nodesWithErrors: 
        // @ts-expect-error
        formErrors && formErrors?.activity && meta?.activity?.touched ? formErrors.activity : {}
    };
};
const shouldError = () => true;
const onSubmitFail = (errors, dispatch, submitError, props) => dispatch(warning({
    title: props.t('TASKS'),
    message: props.t('CORRECT_INVALID_FIELDS')
}));
const onSubmitDiagram = (formValues, dispatch, { t }) => {
    const postProcessedFormValues = prepareGroupPermissionsForSubmit(formValues);
    return dispatch(saveProcessMeta(postProcessedFormValues))
        .then(() => dispatch(success({
        title: t('COMMON__SUCCESS'),
        message: t('SAVE_SETTINGS_SUCCESS')
    })))
        .catch(() => dispatch(error({
        title: t('COMMON__FAILURE'),
        message: t('SAVE_SETTINGS_FAIL')
    })));
};
export default compose(connect(mapStateToProps), reduxForm({
    form: formName,
    onSubmit: onSubmitDiagram,
    // @ts-expect-error
    validate: validateDiagram,
    shouldError,
    onSubmitFail,
    enableReinitialize: true
}))(ActivityContainer);
