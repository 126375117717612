import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { addPaymentMethod } from '../actions';
export const useAddPaymentMethod = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: async (paymentMethodId) => {
            return dispatch(addPaymentMethod(paymentMethodId));
        }
    });
};
