import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../../../../common/components/loading-indicator';
import { changeSubscriptionPlan, getSubscriptionPlanCurrent } from '../actions';
import { PLAN_IMAGES_PATH } from '../constants';
import ChangeSubscriptionPlan from './SubscriptionPlanChoose';
const SubscriptionPlan = ({ onError, onSuccess }) => {
    const { t } = useI18n();
    const [plan, setPlan] = useState();
    const [imageSrc, setImageSrc] = useState(`${PLAN_IMAGES_PATH}/basic.svg`);
    const [changePlanVisible, setChangePlanVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const httpDispatch = useDispatch();
    const httpDispatchChange = useDispatch();
    const loadCurrentPlan = useCallback(async () => {
        setLoading(true);
        try {
            const data = await httpDispatch(getSubscriptionPlanCurrent());
            setPlan(data || undefined);
            if (data?.images?.[0]) {
                setImageSrc(data.images[0]);
            }
            else {
                let image;
                switch (data.name) {
                    case 'Pro plan':
                        image = 'pro.svg';
                        break;
                    case 'Enterprise plan':
                        image = 'enterprise.svg';
                        break;
                    default:
                        image = 'basic.svg';
                        break;
                }
                setImageSrc(`${PLAN_IMAGES_PATH}/${image}`);
            }
        }
        catch (e) {
            onError(e);
        }
        finally {
            setLoading(false);
        }
    }, [httpDispatch, onError]);
    const toggleChangePlan = useCallback(() => {
        setChangePlanVisible(!changePlanVisible);
    }, [changePlanVisible]);
    const onChangePlan = useCallback(async (planName) => {
        setLoading(true);
        try {
            toggleChangePlan();
            await httpDispatchChange(changeSubscriptionPlan(planName));
            await loadCurrentPlan();
        }
        catch (e) {
            onError(e);
            setLoading(false);
        }
    }, [httpDispatchChange, toggleChangePlan, loadCurrentPlan, onError]);
    useEffect(() => {
        void loadCurrentPlan();
    }, [loadCurrentPlan]);
    const nextDate = useMemo(() => {
        if (!plan) {
            return '';
        }
        const date = new Date(plan.nextInvoiceDate * 1000);
        return date.toLocaleDateString().replace(/\//g, '.');
    }, [plan]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subscriptionPlan" },
            loading ? React.createElement(LoadingIndicator, null) : null,
            React.createElement("div", { className: "subscriptionPlanDescription" },
                plan && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "nextDate" },
                        t('SUBSCRIPTION_NEXT_PAYMENT_DATE'),
                        " ",
                        React.createElement("strong", null, nextDate)),
                    React.createElement("div", { className: "planTitle" }, plan.name))),
                React.createElement(Button, { className: "actionButton", onClick: toggleChangePlan }, t('SUBSCRIPTION_CHANGE_PLAN_BUTTON'))),
            React.createElement("img", { src: imageSrc })),
        changePlanVisible && (React.createElement(ChangeSubscriptionPlan, { currentPlan: plan, onChange: onChangePlan, onClose: toggleChangePlan, onError: onError, onSuccess: onSuccess }))));
};
export default SubscriptionPlan;
