import cn from 'classnames';
import React from 'react';
import { SortableContainer as asSortableContainer, SortableElement as asSortableElement, SortableHandle as asSortableHandle } from 'react-sortable-hoc';
import styles from './SortableFileWrapper.module.scss';
export const SortableFile = asSortableElement(({ children, className }) => (React.createElement("div", { className: cn(className, 'sortableItem') }, children)));
export const SortableFileListContainer = asSortableContainer(({ children }) => {
    return React.createElement("div", null, children);
});
export const SortableHandle = asSortableHandle(() => (React.createElement("div", { className: cn(styles.dragHandle, 'drag-handle px-2') },
    React.createElement("svg", { width: "15", height: "18", viewBox: "0 0 6 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { y: "0.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "15.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "0.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "15.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "8", y: "0.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "8", y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "8", y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "8", y: "15.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" })))));
export const SortableHandleSm = asSortableHandle(() => (React.createElement("div", { className: "drag-handle" },
    React.createElement("svg", { width: "12", height: "14", viewBox: "0 0 6 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" })))));
const SortableFileWrapper = ({ className, children, definitionId, entityId, propertyId, ...rest }) => (React.createElement("div", { className: `FileFieldOuter ${className || ''}`, "data-definitionId": definitionId, "data-entityId": entityId, "data-propertyId": propertyId, ...rest }, children));
export default SortableFileWrapper;
