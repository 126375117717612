import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { IoBulbOutline } from 'react-icons/io5';
import styles from './WhatsNewButton.module.scss';
// configs `loopedin` (former `productstash`)
const loopedin = {
    workspaceId: '4fb24728-f562-4c95-85dd-9b3073278437',
    htmlSelectorId: 'productstashSelector',
    linkJsScript: 'https://cdn.loopedin.io/js/sidebar.min.js?v=0.1'
};
export const WhatsNewButton = ({ className = '' }) => {
    useEffect(() => {
        window['li_sidebar'] = { workspace_id: loopedin.workspaceId };
        let script = document.querySelector(`script[src="${loopedin.linkJsScript}"]`);
        if (!script) {
            // Create script
            script = document.createElement('script');
            script.src = loopedin.linkJsScript;
            script.async = true;
            // Add script to document body
            document.body.appendChild(script);
        }
        return () => {
            if (script) {
                script.remove();
            }
            const modal = document.querySelector('#productstash-modal');
            if (modal) {
                modal.remove();
            }
        };
    }, []);
    const { t } = useI18n();
    return (React.createElement(Tooltip, { placement: "bottom", title: t('PRODUCT_UPDATES') },
        React.createElement("div", { className: `${styles.container} ${className} safe-space p-2 lh-1` },
            React.createElement("div", { "data-test-id": "button-header-whats-new", id: loopedin.htmlSelectorId },
                React.createElement(IoBulbOutline, { size: 20 })))));
};
