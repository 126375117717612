import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { FormButtonsContainer, ResetButton } from '../../stylingUtils';
export const FormButtons = ({ dirty, disabled, submitting, onReset, onSubmit }) => {
    const { t } = useI18n();
    return (React.createElement(FormButtonsContainer, null,
        dirty && (React.createElement(ResetButton, { inversed: true, onClick: onReset, disabled: disabled }, t('FORM__RESET'))),
        React.createElement(Button, { onClick: onSubmit, loading: submitting, disabled: !dirty || disabled }, t('FORM__SAVE'))));
};
