import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { createRequest } from '../../utils/request';
import { VERSION_CHECK_DELAY } from './constants';
const reloadPage = () => {
    location.reload();
};
const Message = styled.div `
  padding: 1rem;
  text-align: center;
  background-color: var(--primaryColor-6);
  color: var(--grayscaleColor-1);

  > a {
    color: var(--grayscaleColor-1);
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      color: var(--grayscaleColor-1);
      text-decoration: none;
    }
  }
`;
const VersionMessage = () => {
    const [message, setMessage] = useState(false);
    const [currentVersion, setCurrentVersion] = useState();
    const versionInterval = useRef(null);
    const { t } = useI18n();
    useEffect(() => {
        if (versionInterval.current) {
            clearInterval(versionInterval.current);
            versionInterval.current = null;
        }
        versionInterval.current = setInterval(() => {
            const { response } = createRequest({
                method: 'GET',
                url: '/version.json'
            });
            response
                .then((data) => {
                if (!data) {
                    throw new Error('Invalid data from "version.json"');
                }
                if (typeof data.version !== 'number') {
                    throw new Error('"version" should have type "number"');
                }
                if (!currentVersion) {
                    setCurrentVersion(data.version);
                }
                else if (data.version !== currentVersion) {
                    setMessage(true);
                }
            })
                .catch(console.error);
        }, VERSION_CHECK_DELAY);
        return () => {
            if (versionInterval.current) {
                clearInterval(versionInterval.current);
                versionInterval.current = null;
            }
        };
    }, [currentVersion, t]);
    if (message) {
        return (React.createElement(Message, null,
            React.createElement(React.Fragment, null,
                t('GLOBAL_MESSAGE_NEW_VERSION'),
                " ",
                t('GLOBAL_MESSAGE_PLEASE'),
                ' ',
                React.createElement("a", { onClick: reloadPage }, t('GLOBAL_MESSAGE_REFRESH')),
                ".")));
    }
    return null;
};
export default VersionMessage;
