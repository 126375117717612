import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { ScExternalLink, ScExternalLinkIcon } from '../data-table/utils';
const DocumentationLink = ({ className, href }) => {
    const { t } = useI18n();
    return (React.createElement(ScExternalLink, { className: className, href: href, title: t('DOCUMENTATION'), onClick: (e) => e.stopPropagation(), target: "_blank", rel: "noopener noreferrer" },
        t('DOCUMENTATION'),
        React.createElement(ScExternalLinkIcon, { className: "ms-1" })));
};
export default DocumentationLink;
