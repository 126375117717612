import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { bulkUpdateTaskRunField } from '../actions';
const MUTATION_KEY = 'UpdateTaskRunField';
export const useBulkUpdateTaskRunField = ({ processMetaId, processRunId, taskMetaId, taskRunId }) => {
    const { language } = useI18n();
    const updatePropertyDispatch = useDispatch();
    return useMutation([MUTATION_KEY], ({ propertyId, entityIds, item }) => {
        return updatePropertyDispatch(bulkUpdateTaskRunField(processMetaId, processRunId, taskMetaId, taskRunId, {
            propertyId,
            entityIds,
            item
        }, language));
    });
};
