export const getUsedStepEntityIds = (activity) => {
    const stepNodes = Object.values(activity).filter((node) => {
        if (node.target === 'STEP') {
            const { parameters } = node;
            if (parameters?.step) {
                return true;
            }
        }
        return false;
    });
    return stepNodes.map((node) => node.parameters?.step);
};
