import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Select } from 'antd';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
import FormikError from '../../../../common/components/formik/FormikError';
import FormikField from '../../../../common/components/formik/FormikField';
import { AuthType, Events } from './types';
const { Option } = Select;
const availableEvents = [Events.CREATE, Events.UPDATE, Events.DELETE];
const AddWebhookDrawer = ({ index, validationSchema, hook, entities = [], open, onSave, onClose }) => {
    const { t, language } = useI18n();
    const definitionIdSelectOptions = entities.map((entity) => ({
        value: entity.definitionId,
        label: entity.title
    }));
    const authTypes = useMemo(() => {
        return [
            {
                value: AuthType.NONE,
                labels: { [language]: 'None' }
            },
            {
                value: AuthType.BASIC,
                labels: { [language]: 'Basic' }
            },
            {
                value: AuthType.HEADER,
                labels: { [language]: 'Header' }
            }
        ];
    }, [language]);
    return (React.createElement(Drawer, { title: index == -1 ? `Add` : `Edit`, placement: "right", closable: true, onClose: onClose, open: open, destroyOnClose: true },
        React.createElement(Formik, { initialValues: hook, onSubmit: onSave, validateOnBlur: true, validateOnChange: false, validationSchema: validationSchema && validationSchema(t) }, ({ handleSubmit, values }) => (React.createElement(Form, null,
            React.createElement(DrawerContent, null,
                React.createElement(DrawerContentBlock, { title: `${t('INTEGRATIONS_WEBHOOKS_ENTITY')} *` },
                    React.createElement(FormikField, { className: "d-block px-2 py-1", showSearch: true, label: `${t('INTEGRATIONS_WEBHOOKS_ENTITY')} *`, name: "definitionId", component: Select, options: definitionIdSelectOptions, optionFilterProp: "label" })),
                React.createElement(DrawerContentBlock, { title: `${t('INTEGRATIONS_WEBHOOKS_EVENTS')} *` },
                    React.createElement(FormikError, { name: "definitionId" }),
                    React.createElement(FormikField, { className: "d-block px-2 py-1", placeholder: t('INTEGRATIONS_WEBHOOKS_EVENTS'), name: "events", mode: "multiple", component: Select }, availableEvents.map((eventName) => (React.createElement(Option, { key: eventName, value: eventName }, eventName)))),
                    React.createElement(FormikError, { name: "events" })),
                React.createElement(DrawerContentBlock, { title: `${t('INTEGRATIONS_WEBHOOKS_URL')} *` },
                    React.createElement(FormikField, { placeholder: 'https://example.com/postreceive', name: "url", component: BasicInput })),
                React.createElement(DrawerContentBlock, { title: `${t('INTEGRATIONS_WEBHOOKS_AUTH_TYPE')} *` },
                    React.createElement(FormikField, { className: "d-block px-2 py-1", placeholder: t('INTEGRATIONS_WEBHOOKS_AUTH_TYPE'), name: "auth.type", component: Select }, authTypes.map((authType) => (React.createElement(Option, { key: authType.value, value: authType.value }, authType.labels[language])))),
                    React.createElement(FormikError, { name: "auth.type" })),
                values.auth.type !== 'none' && (React.createElement(React.Fragment, null,
                    React.createElement(DrawerContentBlock, { title: t(values.auth.type == 'basic'
                            ? 'INTEGRATIONS_WEBHOOKS_AUTH_LOGIN'
                            : 'INTEGRATIONS_WEBHOOKS_AUTH_HEADER') },
                        React.createElement(FormikField, { placeholder: t(values.auth.type == 'basic'
                                ? 'INTEGRATIONS_WEBHOOKS_AUTH_LOGIN'
                                : 'INTEGRATIONS_WEBHOOKS_AUTH_HEADER'), name: "auth.name", component: BasicInput })),
                    React.createElement(DrawerContentBlock, { title: `${t(values.auth.type == 'basic'
                            ? 'INTEGRATIONS_WEBHOOKS_AUTH_PASSWORD'
                            : 'INTEGRATIONS_WEBHOOKS_AUTH_VALUE')}` },
                        React.createElement(FormikField, { placeholder: `${t(values.auth.type == 'basic'
                                ? 'INTEGRATIONS_WEBHOOKS_AUTH_PASSWORD'
                                : 'INTEGRATIONS_WEBHOOKS_AUTH_VALUE')}`, name: "auth.pass", type: "password", component: BasicInput }))))),
            React.createElement(DrawerFooter, null,
                React.createElement(DrawerFormFooter, { submitText: t(index == -1
                        ? 'INTEGRATIONS_WEBHOOKS_ADD_BUTTON'
                        : 'INTEGRATIONS_WEBHOOKS_UPDATE_BUTTON'), onSubmit: () => handleSubmit(), onCancel: onClose })))))));
};
export default AddWebhookDrawer;
