import { INTEGRATION_MICROSOFT_ENTITY_ID, INTEGRATION_MODULE_MICROSOFT_ACTIVITY_ENTITY_ID } from '@hypercharge/portal-utils';
import { get } from 'lodash';
import { HEXON_INTEGRATION_ENTITY_ID, INTEGRATION_MODULE_HEXON_VEHICLE_ENTITY_ID, INTEGRATION_MODULE_WEBHOOKS_ENTITY_ID, WEBHOOKS_INTEGRATION_ENTITY_ID, XDMS_CONTACT_MODULE_ENTITY_ID, XDMS_CUSTOMER_MODULE_ENTITY_ID, XDMS_ECOMM_ORDER_MODULE_ENTITY_ID, XDMS_INTEGRATION_ENTITY_ID, XDMS_LANGUAGE_MODULE_ENTITY_ID, XDMS_LEAD_MODULE_ENTITY_ID, XDMS_LOCATION_MODULE_ENTITY_ID, XDMS_OFFER_MODULE_ENTITY_ID, XDMS_ORDER_MODULE_ENTITY_ID, XDMS_SALES_DOSSIER_MODULE_ENTITY_ID, XDMS_SALES_PERSON_MODULE_ENTITY_ID, XDMS_VEHICLE_MODULE_ENTITY_ID } from './constants';
import { disableIntegration as disableHexonIntegration, enableIntegration as enableHexonIntegration } from './hexon/actions';
import HexonIntegrationForm, { getFormSchema as getHexonFormSchema } from './hexon/HexonIntegrationForm';
import HexonModuleVehicleForm, { getFormSchema as getHexonModuleVehicleFormSchema, initialValues as hexonVehicleInitialValues } from './hexon/HexonModuleVehicleForm';
import MicrosoftActivityForm, { getFormSchema as getMicrosoftActivityModuleFormSchema, initialValues as microsoftActivityModuleInitialValues } from './microsoft/OutlookActivityModuleForm';
import WebhooksEntityForm, { getFormSchema as getWebhooksEntityFormSchema, initialValues as webhooksEntityInitialValues } from './webhooks/WebhooksEntityModuleForm';
import { disableIntegration as disableXdmsIntegration, disableIntegrationModuleById as disableXdmsIntegrationModuleById, enableIntegration as enableXdmsIntegration, enableIntegrationModuleById as enableXdmsIntegrationModuleById } from './xdms/actions';
import XDMSIntegrationForm, { getFormSchema as getXDMSFormSchema } from './xdms/XDMSIntegrationForm';
import XDMSModuleForm, { getFormSchema as getModuleFormSchema } from './xdms/XDMSModuleForm';
const formMappings = {
    [XDMS_INTEGRATION_ENTITY_ID]: {
        form: XDMSIntegrationForm,
        schema: getXDMSFormSchema,
        hasModules: true
    },
    [XDMS_CONTACT_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_CUSTOMER_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_VEHICLE_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_OFFER_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_SALES_DOSSIER_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_LEAD_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_ORDER_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_SALES_PERSON_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_LANGUAGE_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_LOCATION_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [XDMS_ECOMM_ORDER_MODULE_ENTITY_ID]: {
        form: XDMSModuleForm,
        schema: getModuleFormSchema
    },
    [WEBHOOKS_INTEGRATION_ENTITY_ID]: {
        hasModules: true
    },
    [INTEGRATION_MODULE_WEBHOOKS_ENTITY_ID]: {
        form: WebhooksEntityForm,
        schema: getWebhooksEntityFormSchema,
        initialValues: webhooksEntityInitialValues,
        hasModules: true
    },
    [HEXON_INTEGRATION_ENTITY_ID]: {
        form: HexonIntegrationForm,
        schema: getHexonFormSchema,
        hasModules: true
    },
    [INTEGRATION_MODULE_HEXON_VEHICLE_ENTITY_ID]: {
        form: HexonModuleVehicleForm,
        schema: getHexonModuleVehicleFormSchema,
        initialValues: hexonVehicleInitialValues
    },
    [INTEGRATION_MICROSOFT_ENTITY_ID]: {
        hasModules: true
    },
    [INTEGRATION_MODULE_MICROSOFT_ACTIVITY_ENTITY_ID]: {
        form: MicrosoftActivityForm,
        schema: getMicrosoftActivityModuleFormSchema,
        initialValues: microsoftActivityModuleInitialValues,
        hasModules: true
    }
};
// Put custom actions for enable/disable here
const actionMappings = {
    [XDMS_INTEGRATION_ENTITY_ID]: {
        enable: enableXdmsIntegration,
        disable: disableXdmsIntegration
    },
    [XDMS_CONTACT_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_CONTACT_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_CONTACT_MODULE_ENTITY_ID)
    },
    [XDMS_CUSTOMER_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_CUSTOMER_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_CUSTOMER_MODULE_ENTITY_ID)
    },
    [XDMS_VEHICLE_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_VEHICLE_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_VEHICLE_MODULE_ENTITY_ID)
    },
    [XDMS_OFFER_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_OFFER_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_OFFER_MODULE_ENTITY_ID)
    },
    [XDMS_SALES_DOSSIER_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_SALES_DOSSIER_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_SALES_DOSSIER_MODULE_ENTITY_ID)
    },
    [XDMS_SALES_PERSON_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_SALES_PERSON_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_SALES_PERSON_MODULE_ENTITY_ID)
    },
    [XDMS_LEAD_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_LEAD_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_LEAD_MODULE_ENTITY_ID)
    },
    [XDMS_ORDER_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_ORDER_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_ORDER_MODULE_ENTITY_ID)
    },
    [XDMS_LANGUAGE_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_LANGUAGE_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_LEAD_MODULE_ENTITY_ID)
    },
    [XDMS_LOCATION_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_LOCATION_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_LEAD_MODULE_ENTITY_ID)
    },
    [XDMS_ECOMM_ORDER_MODULE_ENTITY_ID]: {
        enable: (newConfig) => enableXdmsIntegrationModuleById(XDMS_ECOMM_ORDER_MODULE_ENTITY_ID, newConfig),
        disable: () => disableXdmsIntegrationModuleById(XDMS_ECOMM_ORDER_MODULE_ENTITY_ID)
    },
    [HEXON_INTEGRATION_ENTITY_ID]: {
        enable: enableHexonIntegration,
        disable: disableHexonIntegration
    }
};
export const getConfigurationFormFor = (entityId) => formMappings[entityId]?.form;
export const getFormInitialValuesFor = (entityId) => formMappings[entityId]?.initialValues ?? {};
export const getFormSchemaFor = (entityId) => formMappings[entityId]?.schema;
export const getHasModules = (entityId) => formMappings[entityId]?.hasModules;
export const getEnableActionFor = (entityId) => getActionFor(entityId, 'enable');
export const getDisableActionFor = (entityId) => getActionFor(entityId, 'disable');
const getActionFor = (entityId, type) => get(actionMappings, [entityId, type]);
