import { PAYMENT_API_PATH } from './constants';
export const getSubscriptionPlanCurrent = () => ({
    type: 'PAYMENT_SUBSCRIPTION_CURRENT_GET',
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/subscriptions/current`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const getSubscriptionPlans = () => ({
    type: 'PAYMENT_SUBSCRIPTION_PLANS_GET',
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/subscriptions`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const changeSubscriptionPlan = (planName) => ({
    type: 'PAYMENT_SUBSCRIPTION_PLAN_PUT',
    payload: { planName },
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/subscription`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
export const getCustomer = () => ({
    type: 'PAYMENT_CUSTOMER_GET',
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/customer`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const changeCustomerData = (data) => ({
    type: 'PAYMENT_CUSTOMER_PUT',
    payload: data,
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/customer`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
export const addPaymentMethod = (paymentMethodId) => ({
    type: 'PAYMENT_METHODS_POST',
    payload: { paymentMethodId },
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/paymentMethods`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const deletePaymentMethod = (paymentMethodId) => ({
    type: 'PAYMENT_METHODS_DELETE',
    payload: { paymentMethodId },
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/paymentMethods/${paymentMethodId}`,
            method: 'DELETE',
            allowPromiseReject: true
        }
    }
});
export const getPaymentCards = () => ({
    type: 'PAYMENT_METHODS_GET',
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/paymentMethods/cards`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const getPaymentConfig = () => ({
    type: 'PAYMENT_CONFIG_GET',
    meta: {
        http: {
            path: `${PAYMENT_API_PATH}/config`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
