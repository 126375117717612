import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Avatar } from 'antd';
import { head, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaBan } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch } from 'react-redux';
import { searchItems } from '../../../../cms';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../../../crm';
import { useContact } from '../../../../crm/components/use-contact';
import { ALL_USERS_PERMISSION_GROUP } from '../../../../groups';
import { getMessageContentJsx } from '../../utils';
import styles from './Message.module.scss';
const colors = ['#a3d4ff', '#98ff6d', '#ffb47b', '#a89ff9', '#ffbf00', '#00a2ae'];
const avatarColors = {};
const getColorForAvatar = (id) => {
    if (!avatarColors[id]) {
        avatarColors[id] = head(colors) || '#ccc';
        colors.shift();
    }
    return avatarColors[id];
};
const ConversationMessage = ({ className, collapsable, contactId, disabled, editable, hideCancelIfEmptyContent, hideFooter, mainInput, message, onCancel, onChange, onSave, placeholder, replying }) => {
    const { t, language } = useI18n();
    const { contact } = useContact(contactId);
    const dispatch = useDispatch();
    const [content, setContent] = useState(message?.content || '');
    const [mentions, setMentions] = useState([]);
    const [unmounted, setUnmounted] = useState(false);
    useEffect(() => {
        if (!message?.content) {
            setContent('');
        }
    }, [message]);
    useEffect(() => () => {
        setUnmounted(true);
    }, []);
    const fetchContacts = useCallback(async (fullText) => await dispatch(searchItems(CONTACT_CMS_DEFINITION_ID, {
        query: {
            condition: ConditionType.or,
            filters: [
                {
                    field: 'groups.entityId',
                    operator: FilterOperatorTypes.is,
                    data: ALL_USERS_PERMISSION_GROUP
                }
            ]
        },
        sortBy: [],
        fullText,
        limit: 10,
        offset: 0,
        languageCode: language
    })), [dispatch, language]);
    const getUserSuggestions = useCallback(async (search, callback) => {
        const { results } = await fetchContacts(search);
        callback(results.map(({ entityId, title }) => ({
            id: ['account', entityId].join('__'),
            display: title
        })));
    }, [fetchContacts]);
    const handleChange = useCallback((_1, newValue, _2, mentions) => {
        const uniqMentions = uniq(mentions.map((m) => m.id));
        setContent(newValue);
        setMentions(uniqMentions);
        if (onChange) {
            onChange(newValue, uniqMentions);
        }
    }, [onChange]);
    const displayTransform = useCallback((_, display) => {
        return `@${display}`;
    }, []);
    const renderUserSuggestion = useCallback((_1, _2, highlightedDisplay) => (React.createElement(React.Fragment, null,
        React.createElement(MdPerson, { className: styles.userSuggestionIcon }),
        highlightedDisplay)), []);
    const onCancelHandler = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
        setContent('');
        setMentions([]);
    }, [onCancel]);
    const onSaveHandler = useCallback(async () => {
        if (onSave) {
            await onSave(content, mentions);
            if (!unmounted) {
                setContent('');
                setMentions([]);
            }
        }
    }, [content, mentions, onSave, unmounted]);
    const avatarStyles = useMemo(() => ({
        backgroundColor: contactId ? getColorForAvatar(contactId) : '#ccc',
        verticalAlign: 'middle'
    }), [contactId]);
    // TODO: Find a way so that the details card of a cms item will scroll to the mention message if hash is present
    return (React.createElement("div", { id: `message_${(message && message.id) || ''}`, className: `${styles.messageWrapper} ${editable && !collapsable ? 'flush' : ''} MessageWrapperOuter ${className || ''}` },
        !editable && (React.createElement("div", { className: "d-flex align-items-center" },
            React.createElement(Avatar, { style: avatarStyles, size: "small" },
                React.createElement(ContactDisplayName, { contact: contact, justFirstLetter: true })),
            React.createElement("span", { className: `${styles.userName} flex-truncate` },
                React.createElement(ContactDisplayName, { contact: contact })),
            React.createElement("span", { className: styles.creationDate }, message && React.createElement(ReadableDateTime, { dateTime: message.createdAt })))),
        React.createElement("div", { className: `${styles.messageContent} ${mainInput ? styles.mainInput : ''} MessageContentOuter d-flex flex-wrap` }, editable ? (React.createElement(MentionsInput, { value: content, onChange: handleChange, className: "editor w-100", placeholder: placeholder, autoFocus: !mainInput, disabled: disabled },
            React.createElement(Mention, { trigger: "@", markup: '@[__display__](__id__)(user)', displayTransform: displayTransform, data: getUserSuggestions, renderSuggestion: renderUserSuggestion, appendSpaceOnAdd: true }))) : message?.hidden ? (React.createElement("span", { className: `${styles.deletedMessage} d-flex flex-row align-items-center justify-content-start` },
            React.createElement(FaBan, { className: styles.deletedIcon }),
            React.createElement("span", null, t('MESSAGE_DELETED')))) : (getMessageContentJsx(message?.content || undefined))),
        editable && !hideFooter && (React.createElement("div", { className: `${styles.messageFooter} ${mainInput ? styles.mainInput : ''} ${replying ? 'pe-0' : ''} MessageFooterOuter` },
            ((hideCancelIfEmptyContent && !!content) || !hideCancelIfEmptyContent) && (React.createElement("button", { className: `${styles.actionLink} ${styles.cancelButton}`, onClick: onCancelHandler }, t('FORM__CANCEL'))),
            React.createElement("button", { className: `${styles.actionLink} ${styles.saveButton}`, type: "submit", onClick: onSaveHandler, disabled: !content }, t('FORM__SAVE'))))));
};
export default ConversationMessage;
