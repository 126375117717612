import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import MultiTextEditor from '../../../../../cms/data/components/item-property/editors/MultiTextEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
export const getGeneralFormSchema = () => {
    return {
        customHosts: Yup.array().of(Yup.string())
    };
};
const GeneralOptionsForm = ({ configuration, setFieldValue, disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-12" },
            React.createElement("span", null, t('APPS_HYPER_EVENT__CUSTOM_HOSTS')),
            React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, value: configuration.customHosts, disabled: disabled }))));
};
export default GeneralOptionsForm;
