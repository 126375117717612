import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { ComputedInputField } from '../../../../../common/components/ComputedExpressionEditor';
import Toggle from '../../../../../common/components/Toggle';
import styles from './EmailForm/EmailForm.module.scss';
const NodeSettingsSection = ({ disabled, propertiesTree, input: { value } }) => {
    const { t } = useI18n();
    return (React.createElement(FormSection, { name: "settings", className: styles.wrapper },
        React.createElement(Divider, null, t('SETTINGS')),
        React.createElement("div", { className: "mb-3" },
            React.createElement(FieldLabel, { label: t('CONTINUE_ON_FAIL'), help: t('CONTINUE_ON_FAIL_INFO'), className: "ps-0" }),
            React.createElement(Field, { name: "continueOnFail", component: ComputedInputField, computedComponent: Toggle, propertiesTree: propertiesTree, disabled: disabled })),
        value?.continueOnFail ? (React.createElement("div", { className: "mb-3" },
            React.createElement(FieldLabel, { label: t('SKIP_OUTPUT_ON_FAIL'), className: "ps-0" }),
            React.createElement(Field, { name: "skipOutputOnFail", component: ComputedInputField, computedComponent: Toggle, propertiesTree: propertiesTree, disabled: disabled }))) : ('')));
};
export default NodeSettingsSection;
