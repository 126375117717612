import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useMemo } from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { AntInput, AntInputField } from '../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { SelectField } from '../../../../../common/components/Select';
import { ComputedExpressionsDocumentationLink } from '../../../../../common/components/documentation-link';
import GroupsField from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
const DelayForm = ({ disabled, parentViewGroups }) => {
    const { t } = useI18n();
    const unitOptions = useMemo(() => ['minutes', 'hours', 'days', 'weeks', 'months'].map((type) => ({
        value: type,
        label: t(`DEADLINE_${type.toUpperCase()}`)
    })), [t]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: disabled, placeholder: t('COMMON__TITLE') }),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FieldLabel, { label: t('PROCESS_META__DELAY'), className: "ps-0" }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement("div", { className: "mb-1" },
                React.createElement("span", null, t('PROPERTY_COMPUTED_EXPRESSION')),
                React.createElement(ComputedExpressionsDocumentationLink, { className: "d-inline-block ms-1" })),
            React.createElement("div", { className: "d-flex align-items-center" },
                React.createElement("div", { className: "w-75" },
                    React.createElement(Field, { name: "value", component: AntInputField, disabled: disabled, validate: required, placeholder: t('PROPERTY_COMPUTED_EXPRESSION_PLACEHOLDER') })),
                React.createElement("div", { className: "w-25 ms-2" },
                    React.createElement(Field, { name: "unit", component: SelectField, options: unitOptions, disabled: disabled, validate: required, placeholder: `${t('SELECT_INPUT')} ${t('COMMON__UNIT')}` })))),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default DelayForm;
