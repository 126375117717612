import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Badge } from 'antd';
import cn from 'classnames';
import { PAGE_SIZES } from 'config';
import { isEqual, toString } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { useView } from '../../../../../views/components/ViewContext';
import { TooltipTruncate } from '../../../TooltipTruncate';
import LoadingIndicator from '../../../loading-indicator';
import styles from './Group.module.scss';
import { GroupPagination } from './GroupPagination';
const Group = ({ row, setExpandedPagination, width, setExpandedMap, rowGrouping }) => {
    const { t } = useI18n();
    const { searchItems: { data: searchItemsData, isPending: isSearchItemsPending } } = useView();
    const rowIsExpanded = row?.getIsExpanded();
    useEffect(() => {
        setExpandedMap?.((prevState) => {
            if (row?.id &&
                searchItemsData?.results &&
                !isEqual(prevState[row?.id], searchItemsData?.results)) {
                return { ...prevState, [row?.id]: searchItemsData?.results };
            }
            return prevState;
        });
        // rowIsExpanded is needed here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchItemsData, setExpandedMap, rowIsExpanded, row?.id]);
    const countInGroup = row?.original?.['_countInGroup'];
    const value = useMemo(() => {
        if (!row) {
            return '';
        }
        const value = row.getValue(rowGrouping);
        if (value === undefined) {
            return t('EMPTY');
        }
        return toString(value);
    }, [row, rowGrouping, t]);
    return (React.createElement("div", { className: cn(styles.wrapper, 'group', 'd-flex h-100 align-items-center justify-content-between position-sticky start-0 z-1', { 'border-0': rowIsExpanded }), style: {
            width
        } },
        React.createElement("div", { className: "d-flex align-items-center mw-100 pe-3 flex-truncate" },
            React.createElement("span", { className: "mx-2" },
                React.createElement(FaAngleRight, { style: {
                        transition: 'transform 0.3s ease',
                        fontSize: '1rem',
                        ...(rowIsExpanded && {
                            transform: 'rotate(90deg)'
                        })
                    } })),
            React.createElement(TooltipTruncate, { className: "fw-semibold" }, value),
            React.createElement(Badge, { className: "ms-2", count: countInGroup, overflowCount: Infinity }),
            isSearchItemsPending && React.createElement(LoadingIndicator, null)),
        searchItemsData?.totalCount &&
            searchItemsData?.totalCount > PAGE_SIZES[0] &&
            row?.getIsExpanded() ? (React.createElement(GroupPagination, { row: row, setExpandedPagination: setExpandedPagination })) : null));
};
export default Group;
