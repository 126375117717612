import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { without } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useFiles from '../../useFiles';
import { isFileUrlDataT, uploadFiles } from '../../utils';
import FileField from './FileField';
const FileInput = ({ value = [], onChange, disabled, groupId, ...otherProps }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const files = useFiles(value);
    const onUploadFile = useCallback(async (files) => {
        try {
            const result = await uploadFiles(dispatch, files, groupId);
            if (onChange) {
                await onChange([...value, ...result.map((file) => file.id)]);
            }
        }
        catch (err) {
            dispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    }, [dispatch, groupId, onChange, t, value]);
    const onRemove = useCallback(async (id) => {
        const filteredFileIds = without(value, id);
        await onChange?.(filteredFileIds.length ? filteredFileIds : undefined);
    }, [onChange, value]);
    const onSorted = useCallback((files) => {
        void onChange?.(files?.filter(isFileUrlDataT).map((file) => {
            return file.id;
        }));
    }, [onChange]);
    return (React.createElement(FileField, { ...otherProps, files: files, canEdit: !disabled, onSave: onChange ? onUploadFile : undefined, onRemove: onChange ? onRemove : undefined, onChange: onChange ? onSorted : undefined }));
};
export default FileInput;
