import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { AntInput } from '../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField';
import { FieldsContainerTitle } from '../../../../../common/utils/formUtils';
const ActionForm = ({ disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", null,
        React.createElement(FieldsContainerTitle, { small: true, className: "mt-0" }, t('WORKFLOWS_SETTINGS_TITLE')),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", placeholder: t('COMMON__TITLE'), name: "titles", component: AntInput, disabled: disabled }))));
};
export default ActionForm;
