import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { deleteItems } from '../data/actions';
import { invalidateItemsQuery } from './invalidateCache';
const useDeleteItems = ({ definitionId }) => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: async (payload) => {
            return dispatch(deleteItems(definitionId, payload));
        },
        onSettled: async (_, __, { ids }) => {
            await invalidateItemsQuery({ definitionId, ids: ids, waitNewData: true });
        }
    });
};
export default useDeleteItems;
