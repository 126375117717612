import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput, BasicSelect } from '@hypercharge/hyper-react-base/lib/form';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import ErrorFocus from '../../../../../common/components/formik/ErrorFocus';
import FormikField from '../../../../../common/components/formik/FormikField';
export var UrlTypes;
(function (UrlTypes) {
    UrlTypes["csv"] = "csv";
    UrlTypes["xml"] = "xml";
    UrlTypes["json"] = "json";
    UrlTypes["txt"] = "txt";
})(UrlTypes || (UrlTypes = {}));
export var CheckStatus;
(function (CheckStatus) {
    CheckStatus["idle"] = "idle";
    CheckStatus["uploading"] = "uploading";
    CheckStatus["success"] = "success";
    CheckStatus["fail"] = "fail";
})(CheckStatus || (CheckStatus = {}));
const initialValues = {
    url: '',
    type: UrlTypes.xml
};
const getSchema = () => {
    return Yup.object().shape({
        url: Yup.string()
            // Yup regex which is used in url() does not match all urls for importer.
            // For example https://xml.autodo.eu/amo_request.php?3b047f058de50b884103c5e763879e11%1162255506%201%en%GetVeList
            // This URL is out of RFC 3986 standard and it requires to change regex to cover this case
            // So %[\da-f] was replaced on %[\da-z]
            .matches(/^(((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-z]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-z]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-z]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-z]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-z]{2})?|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?)|(s3:\/\/[\d\w\-\.\/]*)$/i, 'url must be a valid URL')
            .required(t('VALIDATIONS__REQUIRED')),
        type: Yup.string().oneOf(Object.values(UrlTypes)).required(t('VALIDATIONS__REQUIRED'))
    });
};
const UrlForm = ({ checkStatus, onSave }) => {
    const { t, language } = useI18n();
    return (React.createElement(Formik, { initialValues: initialValues, validateOnBlur: true, validateOnChange: false, validationSchema: getSchema(), onSubmit: (data) => onSave(data) }, ({ handleSubmit, isSubmitting, values }) => {
        return (React.createElement("div", { style: { padding: '1rem' } },
            React.createElement("form", { noValidate: true, onSubmit: handleSubmit },
                React.createElement(ScInputContainer, null,
                    React.createElement(FormikField, { name: "url", component: BasicInput, value: values.url || '', label: t('CMS_IMPORTER__FILE_URL'), placeholder: t('CMS_IMPORTER__FILE_URL_PLACEHOLDER'), type: "text" })),
                React.createElement(ScInputContainer, null,
                    React.createElement(FormikField, { name: "type", label: t('CMS_IMPORTER__FILE_URL_TYPE'), component: BasicSelect, options: Object.values(UrlTypes).map((type) => ({
                            value: type,
                            labels: { [language]: type.toUpperCase() }
                        })), language: language, value: values.type })),
                React.createElement(Button, { loading: isSubmitting, onClick: () => handleSubmit(), type: "button" }, t('CMS_IMPORTER__FILE_URL_CHECK_BUTTON_TITLE')),
                React.createElement(ErrorFocus, null))));
    }));
};
export default compose(connect())(UrlForm);
const ScInputContainer = styled.div `
  font-size: 0.9rem;
`;
