import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TITLE_PROPERTY_ID } from '../../../../../cms';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../../cms/common/utils/utils';
import { useHeight } from '../../../../../common/components/height-context/HeightContext';
import { useView } from '../../../../../views/components/ViewContext';
import { Assignee } from '../../../../common/components/Assignee';
import { DisplayStatusTag } from '../../../../common/components/DisplayStatusTag';
import { DisplayTaskDeadline } from '../../../../common/components/DisplayTaskDeadline';
import DisplayPriorityTag from '../../../../common/components/display-priority-tag';
import { TaskStatus } from '../../../../common/utils/types';
import { getProcessUrl } from '../../../../common/utils/utils';
import styles from '../TasksTableWrapper/TasksTableWrapper.module.scss';
var TasksTableSize;
(function (TasksTableSize) {
    TasksTableSize["large"] = "large";
    TasksTableSize["small"] = "small";
})(TasksTableSize || (TasksTableSize = {}));
const WorkflowsTasksTableList = ({ emptyText, size = TasksTableSize.large }) => {
    const { contentHeight } = useHeight();
    const { t, language } = useI18n();
    const { definitionId, filterRequest, searchItems: { data: { results: tasks = [], totalCount = 0 } = {}, isPending, isFailed: isError } } = useView();
    const { displayData } = useEntityDisplayData(definitionId);
    const entityIds = useMemo(() => tasks.map((task) => task.taskProcessRunId), [tasks]);
    const definitionIds = useMemo(() => tasks.map((task) => task.taskProcessMetaId), [tasks]);
    const titleProperty = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const titleTranslatedKey = titleProperty?.meta.translations?.[language];
    return (React.createElement("div", { className: `${styles.container} overflow-y-auto custom-scroll-styling`, style: { height: contentHeight } }, isError ? (React.createElement("div", { className: `${styles.simpleRow} p-4` },
        React.createElement("i", null, t('SOMETHING_WENT_WRONG')))) : isPending ? (React.createElement("div", { className: styles.loader },
        React.createElement("div", { className: styles.loader__container },
            React.createElement(LoadingRectangles, null)))) : tasks.length > 0 ? (React.createElement(React.Fragment, null, tasks.map(({ entityId, taskDeadline, taskPriority, taskProcessCustomTitle, taskProcessMetaId, taskProcessRunId, taskStatus, title, taskAssignedTo, ...task }) => (React.createElement("div", { key: entityId, className: styles.task__item },
        React.createElement(Link, { to: {
                pathname: getProcessUrl(taskProcessMetaId, taskProcessRunId),
                state: {
                    data: { entityIds, definitionIds, totalCount },
                    filterRequest,
                    isTaskDefinition: true
                }
            }, className: `${styles.task__link} ${size === TasksTableSize.small ? styles.task__link_smallSize : ''}` },
            size === TasksTableSize.large && (React.createElement("div", { className: styles.task__status },
                taskStatus !== TaskStatus.SUCCEEDED && (React.createElement(DisplayPriorityTag, { priority: taskPriority, className: "me-2" })),
                React.createElement(DisplayStatusTag, { className: styles.task__tag, status: taskStatus }))),
            React.createElement("div", { className: styles.task__info },
                React.createElement("div", { className: `${styles.task__infoTop} ${size === TasksTableSize.small ? styles.task__infoTop_smallSize : ''}` },
                    React.createElement("div", { className: styles.task__title }, (titleTranslatedKey && task[titleTranslatedKey]) || title),
                    size === TasksTableSize.small && (React.createElement("div", { className: styles.task__status },
                        React.createElement(DisplayPriorityTag, { priority: taskPriority, tooltipPlacement: "left", className: "me-1" }),
                        React.createElement(DisplayStatusTag, { status: taskStatus }))),
                    taskAssignedTo && (React.createElement(Assignee, { className: size === TasksTableSize.small ? styles.task__assignee : '', contactId: taskAssignedTo }))),
                React.createElement("div", { className: styles.task__infoBottom },
                    React.createElement("div", { className: styles.task__processInfo },
                        React.createElement("span", { className: styles.task__processRunId },
                            taskProcessRunId,
                            " "),
                        taskProcessCustomTitle ? `- ${taskProcessCustomTitle}` : null),
                    React.createElement("div", { className: styles.task__deadline },
                        React.createElement(DisplayTaskDeadline, { taskDeadline: taskDeadline })))))))))) : (React.createElement("div", { className: `${styles.simpleRow} p-4` },
        React.createElement("i", null, emptyText)))));
};
export default WorkflowsTasksTableList;
