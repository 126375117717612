import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { FieldWrapper } from '../../FieldElements';
const CheckboxDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => {
    const { t } = useI18n();
    let content = '\u00a0';
    switch (value) {
        case true:
            content = t('YES');
            break;
        case false:
            content = t('NO');
            break;
    }
    return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, className: className ?? '', simple: !asField, disabled: disabled, style: style }, content));
};
export default CheckboxDisplay;
