import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
const CountdownTimer = ({ resolveTime }) => {
    const { t } = useI18n();
    const [timeLeft, setTimeLeft] = useState(resolveTime - Date.now());
    const intervalRef = useRef(null);
    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        if (resolveTime - Date.now() > 0) {
            intervalRef.current = setInterval(() => {
                setTimeLeft(resolveTime - Date.now());
            }, 5000);
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [resolveTime]);
    return useMemo(() => {
        if (timeLeft <= 0 && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return React.createElement("div", { className: "small" }, t('DELAY_FINISHED'));
        }
        return (React.createElement("div", { className: "small" },
            t('DELAY_ONGOING'),
            ":",
            ' ',
            React.createElement("span", { className: "text-nowrap" }, moment(resolveTime).fromNow(true))));
    }, [resolveTime, t, timeLeft]);
};
export default CountdownTimer;
