import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { BasicInput, FormFooter, required, Section, SubTitle, Title } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import React from 'react';
import { Field } from 'redux-form';
import { ADMIN_PATH } from '../../../utils/constants';
import styles from './DefinitionForm.module.scss';
const DefinitionForm = ({ definitionId, handleSubmit, submitting }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` },
                { text: t('SETTINGS_DEFINITIONS_NAV_LINK'), to: `${ADMIN_PATH}/definitions` }
            ], currentBreadcrumbText: !definitionId
                ? t('SETTINGS_DEFINITIONS_ADD_DEFINITION')
                : t('EDIT_DEFINITION', { definition: definitionId }) }),
        React.createElement(Section, null,
            React.createElement(Title, null, !definitionId
                ? t('SETTINGS_DEFINITIONS_ADD_DEFINITION')
                : t('EDIT_DEFINITION', { definition: definitionId })),
            React.createElement(SubTitle, null, !definitionId ? t('ADD_DEFINITION_SUBTITLE') : ''),
            React.createElement("form", { className: styles.form, onSubmit: handleSubmit, noValidate: true },
                !definitionId && (React.createElement(SectionCard, { withoutPadding: true },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(Field, { name: "id", label: t('DEFINITION_ID'), component: BasicInput, type: "text", validate: required })))),
                React.createElement(SectionCard, { title: t('DEFINITION_LABEL'), withoutPadding: true },
                    React.createElement("div", { className: "cp-c-padding-2" }, AVAILABLE_LANGUAGES.map((language) => (React.createElement("div", { key: language },
                        React.createElement(Field, { name: `labels[${language}]`, label: t(language), component: BasicInput, type: "text", validate: language === DEFAULT_LANGUAGE ? required : [] })))))),
                React.createElement(FormFooter, { showCancel: true, submitting: submitting })))));
};
export default DefinitionForm;
