import Button from 'antd/lib/button';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCronStringFromValues, setValuesFromCronString } from './converter';
import Hours from './fields/Hours';
import Minutes from './fields/Minutes';
import MonthDays from './fields/MonthDays';
import Months from './fields/Months';
import Period from './fields/Period';
import WeekDays from './fields/WeekDays';
import { DEFAULT_LOCALE_EN } from './locale';
import './styles.css';
import { classNames, setError, usePrevious } from './utils';
export default function Cron(props) {
    const { clearButton = true, clearButtonProps = {}, clearButtonAction = 'fill-with-every', locale = DEFAULT_LOCALE_EN, value = '', setValue, displayError = true, onError, className, defaultPeriod = 'day', allowEmpty = 'for-default-value', humanizeLabels = true, humanizeValue = false, disabled = false, readOnly = false, leadingZero = false, shortcuts = ['@yearly', '@annually', '@monthly', '@weekly', '@daily', '@midnight', '@hourly'], clockFormat } = props;
    const internalValueRef = useRef(value);
    const defaultPeriodRef = useRef(defaultPeriod);
    const [period, setPeriod] = useState();
    const [monthDays, setMonthDays] = useState();
    const [months, setMonths] = useState();
    const [weekDays, setWeekDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [error, setInternalError] = useState(false);
    const [valueCleared, setValueCleared] = useState(false);
    const previousValueCleared = usePrevious(valueCleared);
    const localeJSON = JSON.stringify(locale);
    useEffect(() => {
        setValuesFromCronString(value, setInternalError, onError, allowEmpty, internalValueRef, true, locale, shortcuts, setMinutes, setHours, setMonthDays, setMonths, setWeekDays, 
        // @ts-expect-error
        setPeriod);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    useEffect(() => {
        if (value !== internalValueRef.current) {
            setValuesFromCronString(value, setInternalError, onError, allowEmpty, internalValueRef, false, locale, shortcuts, setMinutes, setHours, setMonthDays, setMonths, setWeekDays, 
            // @ts-expect-error
            setPeriod);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, internalValueRef, localeJSON, allowEmpty, shortcuts]);
    useEffect(() => {
        // Only change the value if a user touched a field
        // and if the user didn't use the clear button
        if (period === 'month') {
            setWeekDays(undefined);
        }
        if (!hours || !hours.length || hours.length > 3) {
            setInternalError(true);
            setError(onError, locale);
        }
        else if ((period || minutes || months || monthDays || weekDays || hours || minutes) &&
            !valueCleared &&
            !previousValueCleared) {
            const cron = getCronStringFromValues(period || defaultPeriodRef.current, months, monthDays, weekDays, hours, minutes, humanizeValue);
            setValue(cron);
            internalValueRef.current = cron;
            onError && onError(undefined);
            setInternalError(false);
        }
        else if (valueCleared) {
            setValueCleared(false);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [period, monthDays, months, weekDays, hours, minutes, humanizeValue, valueCleared]);
    const handleClear = useCallback(() => {
        setMonthDays(undefined);
        setMonths(undefined);
        setWeekDays(undefined);
        setHours(undefined);
        setMinutes(undefined);
        // When clearButtonAction is 'empty'
        let newValue = '';
        const newPeriod = period !== 'reboot' && period ? period : defaultPeriodRef.current;
        if (newPeriod !== period) {
            setPeriod(newPeriod);
        }
        // When clearButtonAction is 'fill-with-every'
        if (clearButtonAction === 'fill-with-every') {
            const cron = getCronStringFromValues(newPeriod, undefined, undefined, undefined, undefined, undefined);
            newValue = cron;
        }
        setValue(newValue);
        internalValueRef.current = newValue;
        setValueCleared(true);
        if (allowEmpty === 'never' && clearButtonAction === 'empty') {
            setInternalError(true);
            setError(onError, locale);
        }
        else {
            onError && onError(undefined);
            setInternalError(false);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [period, setValue, onError, clearButtonAction]);
    const internalClassName = useMemo(() => classNames({
        'react-js-cron': true,
        'react-js-cron-error': error && displayError,
        'react-js-cron-disabled': disabled,
        'react-js-cron-read-only': readOnly,
        [`${className}`]: !!className,
        [`${className}-error`]: error && displayError && !!className,
        [`${className}-disabled`]: disabled && !!className,
        [`${className}-read-only`]: readOnly && !!className
    }), [className, error, displayError, disabled, readOnly]);
    const { className: clearButtonClassNameProp, ...otherClearButtonProps } = clearButtonProps;
    const clearButtonClassName = useMemo(() => classNames({
        'react-js-cron-clear-button': true,
        [`${className}-clear-button`]: !!className,
        [`${clearButtonClassNameProp}`]: !!clearButtonClassNameProp
    }), [className, clearButtonClassNameProp]);
    const otherClearButtonPropsJSON = JSON.stringify(otherClearButtonProps);
    const clearButtonNode = useMemo(() => {
        if (clearButton && !readOnly) {
            return (React.createElement(Button, { className: clearButtonClassName, 
                // @ts-expect-error
                type: "danger", disabled: disabled, ...otherClearButtonProps, onClick: handleClear }, locale.clearButtonText || DEFAULT_LOCALE_EN.clearButtonText));
        }
        return null;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        clearButton,
        readOnly,
        localeJSON,
        clearButtonClassName,
        disabled,
        otherClearButtonPropsJSON,
        handleClear
    ]);
    const periodForRender = period || defaultPeriodRef.current;
    return (React.createElement("div", { className: internalClassName },
        React.createElement(Period, { value: periodForRender, 
            // @ts-expect-error
            setValue: setPeriod, locale: locale, className: className, disabled: disabled, readOnly: readOnly, shortcuts: shortcuts }),
        periodForRender === 'reboot' ? (clearButtonNode) : (React.createElement(React.Fragment, null,
            periodForRender === 'year' && (React.createElement(Months, { value: months, setValue: setMonths, locale: locale, className: className, humanizeLabels: humanizeLabels, disabled: disabled, readOnly: readOnly, period: periodForRender })),
            (periodForRender === 'year' || periodForRender === 'month') && (React.createElement(MonthDays, { value: monthDays, setValue: setMonthDays, locale: locale, className: className, weekDays: weekDays, disabled: disabled, readOnly: readOnly, leadingZero: leadingZero, period: periodForRender })),
            (periodForRender === 'year' || periodForRender === 'week') && (React.createElement(WeekDays, { value: weekDays, setValue: setWeekDays, locale: locale, className: className, humanizeLabels: humanizeLabels, monthDays: monthDays, disabled: disabled, readOnly: readOnly, period: periodForRender })),
            periodForRender !== 'minute' && periodForRender !== 'hour' && (React.createElement(Hours, { value: hours, setValue: setHours, locale: locale, className: className, disabled: disabled, readOnly: readOnly, leadingZero: leadingZero, clockFormat: clockFormat, period: periodForRender })),
            periodForRender !== 'minute' && (React.createElement(Minutes, { value: minutes && minutes.length ? minutes : [0], setValue: setMinutes, locale: locale, period: periodForRender, className: className, disabled: disabled, readOnly: readOnly, leadingZero: leadingZero, clockFormat: clockFormat })),
            clearButtonNode))));
}
