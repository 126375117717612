import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import { keyBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { getFlattenedDisplayDataList } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { FieldLabel } from '../../../cms/data/components/item-property/FieldElements';
import PropertyFieldInlineEditor from '../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import { ItemMetaSelector } from '../../components/entity-selectors';
import FormikError from '../../components/formik/FormikError';
import FormikField from '../../components/formik/FormikField';
import FileInput from '../../storage/components/FileField/FileInput';
import { PropertyTypes } from '../../types';
import { ACTIVITY_DEFINITION_ID, ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID } from '../constants';
import { ActivityType } from '../types';
import styles from './ActivityForm.module.scss';
import CollapsibleFields from './CollapsibleFields';
const validationSchema = Yup.object({
    entityId: Yup.string().nullable().required('Required'),
    title: Yup.string().nullable().required('Required'),
    assignedTo: Yup.string().nullable().required('Required'),
    comments: Yup.object({
        ops: Yup.array(Yup.object())
    }).nullable(),
    dueDate: Yup.number().nullable(),
    statusItem: Yup.string().nullable().required('Required'),
    priority: Yup.string().nullable().required('Required'),
    contact: Yup.string().nullable(),
    relatedToDefinitionId: Yup.string().nullable(),
    relatedToEntityId: Yup.string().nullable(),
    typeText: Yup.string().nullable().required('Required')
});
const ActivityForm = ({ value, onSubmit, isAvailableCancelButton = true, hideOnSubmit = true, initialShowForm = false, onChange }) => {
    const { t } = useI18n();
    const [showForm, setShowForm] = useState(initialShowForm);
    const activityDisplayData = useEntityDisplayData(ACTIVITY_DEFINITION_ID);
    const propertyMap = useMemo(() => {
        if (!activityDisplayData.displayData) {
            return {};
        }
        return keyBy(getFlattenedDisplayDataList(activityDisplayData.displayData), 'propertyId');
    }, [activityDisplayData.displayData]);
    const initialValues = useMemo(() => {
        return value || {};
    }, [value]);
    const _onSubmit = useCallback(async (value) => {
        onSubmit && (await onSubmit(value));
        hideOnSubmit && setShowForm(false);
    }, [hideOnSubmit, onSubmit]);
    const onClickShowForm = useCallback(() => {
        setShowForm(true);
    }, []);
    const onClickCancelForm = useCallback(() => {
        setShowForm(false);
    }, []);
    const disableDate = useCallback((date) => {
        return !date || date.isBefore(moment(Date.now()).subtract(1, 'd'));
    }, []);
    return (React.createElement("div", { className: `${styles.wrapper}` },
        !showForm && (React.createElement("div", { className: "px-5 mt-3" },
            React.createElement(Button, { type: "button", className: "w-100", onClick: onClickShowForm }, `+ ${t('ADD')}`))),
        showForm && (React.createElement(Formik, { initialValues: initialValues, onSubmit: _onSubmit, validationSchema: validationSchema }, ({ values, dirty, handleSubmit, setFieldValue, isSubmitting }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const relatedToEntityIdMeta = useMemo(() => {
                return {
                    definitionId: values.relatedToDefinitionId
                };
            }, [values.relatedToDefinitionId]);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
                !!onChange && onChange(values, dirty);
            }, [values, dirty]);
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "p-3" },
                    propertyMap.title && (React.createElement(FormikField, { name: "title", label: t('ACTIVITY_TITLE'), help: t('ACTIVITY_TITLE_INFO'), component: PropertyFieldInlineEditor, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, canEdit: propertyMap.title.canEdit, type: propertyMap.title.type, meta: propertyMap.title.meta, computed: propertyMap.title.computed, layout: "vertical" })),
                    React.createElement(FormikError, { name: "title" }),
                    propertyMap.comments && (React.createElement(FormikField, { name: "comments", label: t('COMMENTS'), help: t('ACTIVITY_COMMENTS_INFO'), component: PropertyFieldInlineEditor, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, canEdit: propertyMap.comments.canEdit, type: propertyMap.comments.type, meta: propertyMap.comments.meta, computed: propertyMap.comments.computed, layout: "vertical" })),
                    React.createElement(FormikError, { name: "comments" }),
                    propertyMap.files && propertyMap.files.type == PropertyTypes.file && (React.createElement(FormikField, { name: "files", layout: "vertical", label: t('COMMON_FILES'), component: FileInput, meta: propertyMap.files.meta, error: false, groupId: values.entityId, disabled: !propertyMap.comments.canEdit })),
                    React.createElement(FormikError, { name: "files" }),
                    values.typeText === ActivityType.TASK && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "d-flex" },
                            propertyMap.dueDate && (React.createElement("div", { className: "w-50" },
                                React.createElement(FormikField, { name: "dueDate", label: t('ACTIVITY_DUE_DATE'), component: PropertyFieldInlineEditor, className: "mt-2 me-3", extraEditorComponentProps: {
                                        disabledDate: disableDate,
                                        renderCalendarInParent: true
                                    }, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, canEdit: propertyMap.dueDate.canEdit, type: propertyMap.dueDate.type, meta: propertyMap.dueDate.meta, computed: propertyMap.dueDate.computed, layout: "vertical" }))),
                            React.createElement(FormikError, { name: "dueDate" }),
                            propertyMap.reminder && (React.createElement("div", { className: "w-50" },
                                React.createElement(FormikField, { name: "reminder", label: t('ACTIVITY_REMINDER_SET'), component: PropertyFieldInlineEditor, extraEditorComponentProps: {
                                        disabledDate: disableDate
                                    }, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, canEdit: propertyMap.reminder.canEdit, type: propertyMap.reminder.type, meta: propertyMap.reminder.meta, computed: propertyMap.reminder.computed, layout: "vertical" }),
                                React.createElement(FormikError, { name: "reminder" })))))),
                    React.createElement(FormikError, { name: "contact" }),
                    propertyMap.contact && (React.createElement(FormikField, { name: "contact", label: t('ACTIVITY_NAME'), help: t('ACTIVITY_NAME_INFO'), component: PropertyFieldInlineEditor, extraEditorComponentProps: { renderOptionsInParent: true }, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, canEdit: propertyMap.contact.canEdit, type: propertyMap.contact.type, meta: propertyMap.contact.meta, computed: propertyMap.contact.computed, layout: "vertical" })),
                    React.createElement(CollapsibleFields, { title: t('ACTIVITY__ADVANCED_SETTINGS') },
                        values.typeText === ActivityType.TASK && propertyMap.assignedTo && (React.createElement(React.Fragment, null,
                            React.createElement(FormikField, { name: "assignedTo", label: t('ACTIVITY_ASSIGNED_TO'), component: PropertyFieldInlineEditor, extraEditorComponentProps: { renderOptionsInParent: true }, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, canEdit: propertyMap.assignedTo.canEdit, type: propertyMap.assignedTo.type, meta: propertyMap.assignedTo.meta, computed: propertyMap.assignedTo.computed, layout: "vertical" }),
                            React.createElement(FormikError, { name: "assignedTo" }))),
                        React.createElement("div", { className: "d-flex" },
                            React.createElement("div", { className: "w-50" },
                                React.createElement(FormikField, { name: "relatedToDefinitionId", label: React.createElement(FieldLabel, { label: t('ACTIVITY_RELATED_TO_DEFINITION'), help: t('ACTIVITY_RELATED_TO_INFO') }), component: ItemMetaSelector, className: "ItemMetaSelector mt-2 me-3", allowClear: true, onChange: () => {
                                        setFieldValue('relatedToEntityId', null);
                                    }, getPopupContainer: (trigger) => trigger?.parentElement }),
                                React.createElement(FormikError, { name: ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID })),
                            React.createElement("div", { className: "w-50" },
                                React.createElement(FormikField, { name: "relatedToEntityId", label: t('ACTIVITY_RELATED_TO_ENTITY_ITEM'), component: PropertyFieldInlineEditor, extraEditorComponentProps: { renderOptionsInParent: true }, canEdit: !!values.relatedToDefinitionId &&
                                        !!propertyMap.relatedToEntityId &&
                                        propertyMap.relatedToEntityId.canEdit, definitionId: ACTIVITY_DEFINITION_ID, entityId: values.entityId, type: "entity", computed: false, meta: relatedToEntityIdMeta, layout: "vertical" }),
                                React.createElement(FormikError, { name: "relatedToEntityId" }))))),
                React.createElement("div", { className: `p-3 d-flex
                  ${initialShowForm ? 'sticky-bottom bg-white border-top' : ''}
                  ${isAvailableCancelButton ? 'justify-content-between' : 'justify-content-end'}` },
                    isAvailableCancelButton && (React.createElement(Button, { type: "button", inversed: true, onClick: onClickCancelForm }, t('FORM__CANCEL'))),
                    React.createElement(Button, { type: "submit", onClick: () => handleSubmit(), loading: isSubmitting }, t('FORM__SAVE')))));
        }))));
};
export default ActivityForm;
