import { get } from 'lodash';
import { getSettingsState } from '../common/reducers/selectors';
// ////////////////////
// ItemMeta list
// ////////////////////
export const getEntitiesMetaTotalCount = (s) => get(getSettingsState(s), 'entities.list.totalCount');
export const getEntitiesMetaIdsByPage = (s, page) => get(getSettingsState(s), ['entities', 'list', 'idsByPage', page.toString()], []);
export const getEntitiesMetaList = (s, page) => getEntitiesMetaIdsByPage(s, page).map((id) => get(getSettingsState(s), ['entities', 'byId', id]));
export const isFetchEntitiesMetaPending = (s) => get(getSettingsState(s), 'entities.list.pending');
export const hasFetchEntitiesMetaFailed = (s) => get(getSettingsState(s), 'entities.list.failed');
// ////////////////////
// ItemMeta single
// ////////////////////
export const getItemMeta = (s, id) => get(getSettingsState(s), ['entities', 'byId', id]);
export const isItemMetaAvailable = (s, id) => {
    const status = getSettingsState(s).entities.status[id];
    return status != null && (status.lastFetch != null || status.pending || status.failed);
};
export const isFetchItemMetaPending = (s, id) => {
    const status = getSettingsState(s).entities.status[id];
    return status == null || status.pending;
};
export const hasFetchItemMetaFailed = (s, id) => get(getSettingsState(s), ['entities', 'status', id, 'failed']);
// ////////////////////
// MetaDefinition
// ////////////////////
export const getMetaDefinition = (s, definitionId) => get(getSettingsState(s), ['definitions', 'byId', definitionId]);
export const isMetaDefinitionAvailable = (s, definitionId) => {
    const status = getSettingsState(s).definitions.status[definitionId];
    return status != null && (status.lastFetch != null || status.pending);
};
export const isFetchMetaDefinitionPending = (s, definitionId) => {
    const status = getSettingsState(s).definitions.status[definitionId];
    return status == null || status.pending;
};
