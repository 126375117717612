import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../../auth';
import { DEFINITIONS_PATH, OPTIONS_PATH } from '../utils/constants';
import DefinitionsRoutes from './definitions/Routes';
import OptionsRoutes from './options/Routes';
const AdminRoutes = ({ match: { url } }) => {
    return (React.createElement(ErrorBoundary, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: `${url}${OPTIONS_PATH}`, component: OptionsRoutes }),
            React.createElement(Route, { path: `${url}${DEFINITIONS_PATH}`, component: DefinitionsRoutes }),
            React.createElement(Redirect, { to: `${url}${DEFINITIONS_PATH}` }))));
};
export default redirectIfNoPermission(AdminRoutes);
