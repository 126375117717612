import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Input } from 'antd';
import { isObject, isString, toString } from 'lodash';
import React, { useCallback } from 'react';
import { isComputedExpression } from '../../../utils/computedExpression';
import { ComputedInput } from '../ComputedExpressionEditor';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.equals },
    { value: FilterOperatorTypes.notEquals },
    { value: FilterOperatorTypes.greaterThan },
    { value: FilterOperatorTypes.lessThan },
    { value: FilterOperatorTypes.greaterThanOrEqual },
    { value: FilterOperatorTypes.lessThanEqual }
];
const NumberFilter = ({ className, filter, onChange, onClear, disabled, showSelectOperator, disabledSelectOperator, allowComputed, propertiesTree, autoFocus }) => {
    const data = 'data' in filter ? toString(filter.data) : undefined;
    const onChangeInput = useCallback((value) => {
        let newValue = isObject(value) && 'target' in value ? value.target.value : value;
        if (!newValue) {
            onClear?.(filter);
            return;
        }
        if (isString(newValue) &&
            !newValue.startsWith('=') &&
            isNaN(parseFloat(toString(newValue)))) {
            newValue = 0;
        }
        const newFilter = buildFilter(filter.field, filter.operator, newValue);
        onChange(newFilter);
    }, [filter, onChange, onClear]);
    return (React.createElement(BaseFilter, { onChange: onChange, operators: availableOperators, filter: filter, showSelectOperator: showSelectOperator, disabledSelectOperator: disabledSelectOperator, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { component: Input, type: "number", value: data, onChange: onChangeInput, disabled: disabled, propertiesTree: propertiesTree, ref: (ref) => autoFocus && ref.focus({ preventScroll: true }) })) : (React.createElement(Input, { type: "number", value: data, onChange: onChangeInput, disabled: disabled, ref: (ref) => autoFocus && ref?.focus({ preventScroll: true }) }))));
};
export default NumberFilter;
