import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Button } from 'antd';
import cn from 'classnames';
import { find, findIndex, flatMap, get, head, isEmpty, omit, pick, without } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdEdit, MdTimer10 } from 'react-icons/md';
import { arrayMove, SortableContainer as getSortableContainer } from 'react-sortable-hoc';
import { Drawer } from '../../../common/components/Drawer';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import { ExtendableSortableItem, SortableItem } from '../SortablePropertyList';
import EditMetricFormContainer from './EditMetricFormContainer';
import styles from './MetricsField.module.scss';
import { AggregationTypes } from './types';
const getNewAggregation = (aggregation) => {
    let newAggregation = {
        ...aggregation,
        inPercents: ['value_count', 'cardinality'].includes(aggregation.metric || '')
            ? aggregation.inPercents
            : undefined
    };
    if (aggregation?.rowGrouping) {
        newAggregation.aggregationId = aggregation?.aggregationId || generateId();
        newAggregation.size = aggregation?.size ? Number(aggregation?.size) : undefined;
        newAggregation.inPercents = undefined;
        newAggregation.units = undefined;
        newAggregation.field =
            aggregation.metric === AggregationTypes.value_count || !newAggregation.field
                ? 'entityId'
                : newAggregation.field;
    }
    else {
        newAggregation = omit(newAggregation, ['rowGrouping', 'rowGroupingOrderBy', 'size']);
    }
    return newAggregation;
};
const MetricsField = ({ className, disabled, input, displayData, definitionId }) => {
    const { t } = useI18n();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [activeAggregation, setActiveAggregation] = useState(undefined);
    const toggleDrawer = useCallback(() => {
        setIsDrawerVisible((x) => !x);
    }, []);
    const toggleDrawerNewMetric = useCallback(() => {
        setActiveAggregation(undefined);
        setIsDrawerVisible((x) => !x);
    }, []);
    const metrics = useMemo(() => {
        if (!Array.isArray(input.value)) {
            return [];
        }
        // Strip out the deleted properties
        return input.value
            .map(({ titles, field, metric, query, units, inPercents, id, rowGrouping, rowGroupingOrderBy, size, aggregationId, inactiveGroupingElements }) => ({
            id: id || generateId(),
            titles,
            field,
            metric,
            query,
            units,
            inPercents,
            rowGrouping,
            rowGroupingOrderBy,
            size,
            aggregationId,
            inactiveGroupingElements
        }))
            .filter(({ field }) => {
            const dividedPropertyIds = field.split('.');
            return !!find(flatMap(displayData.data, 'values'), {
                propertyId: dividedPropertyIds.length > 1 ? head(dividedPropertyIds) : field
            });
        });
    }, [displayData.data, input.value]);
    const updateAggregation = useCallback((aggregation) => {
        const newAggregation = getNewAggregation(aggregation);
        const aggregationIndex = findIndex(metrics, { id: newAggregation.id });
        let updatedAggregations = [...metrics];
        if (aggregationIndex > -1) {
            updatedAggregations[aggregationIndex] = newAggregation;
        }
        else {
            updatedAggregations = [...updatedAggregations, newAggregation];
        }
        input.onChange(updatedAggregations);
        setActiveAggregation(undefined);
        toggleDrawer();
    }, [metrics, input, toggleDrawer]);
    const SortableMetricItem = useCallback((props) => (React.createElement(ExtendableSortableItem, { key: props.item.id, ...pick(props, [
            'position',
            'item',
            'iconComponent',
            'label',
            'isDisabled',
            'selection',
            'handleChange',
            'handleRemove'
        ]) },
        React.createElement(MdEdit, { className: cn('edit-btn', { disabled: props.isDisabled }), size: 24, onClick: () => {
                if (!props.isDisabled) {
                    setActiveAggregation(props.item);
                    toggleDrawer();
                }
            } }))), [toggleDrawer]);
    return (React.createElement("div", { className: cn(styles.wrapper, className, 'd-flex flex-column row-gap-3') },
        !isEmpty(metrics) && (React.createElement(SortableMetricsList, { disabled: disabled, itemComponent: SortableMetricItem, metrics: metrics, handleChange: input.onChange })),
        React.createElement(Drawer, { onClose: toggleDrawer, open: isDrawerVisible, destroyOnClose: true },
            React.createElement(EditMetricFormContainer, { title: activeAggregation ? t('VIEWS__EDIT_METRIC') : t('VIEWS__ADD_METRIC'), aggregation: activeAggregation, onCancel: toggleDrawer, onSave: updateAggregation, displayData: displayData, definitionId: definitionId })),
        React.createElement(Button, { className: "add-metric-btn rounded-5 fw-600 d-flex gap-2 align-items-center", onClick: toggleDrawerNewMetric },
            React.createElement(FaPlus, { size: 18 }),
            React.createElement(TooltipTruncate, { className: "flex-truncate text-start" }, t('VIEWS__ADD_METRIC')))));
};
export default MetricsField;
const SortableList = getSortableContainer(({ disabled, metrics, handleChange, itemComponent }) => {
    const { language } = useI18n();
    const removeMetric = useCallback((metric) => {
        handleChange(without(metrics, metric));
    }, [handleChange, metrics]);
    return (React.createElement("ul", { className: "sortable-list p-0 m-0" }, metrics.map((metric, index) => (React.createElement(SortableItem, { key: metric.id, index: index, position: index, item: metric, label: getTranslation(get(metric, 'titles'), language), iconComponent: MdTimer10, isDisabled: !!disabled, selection: metrics, handleChange: handleChange, removeItem: removeMetric, itemComponent: itemComponent })))));
});
const SortableMetricsList = ({ disabled, metrics, handleChange, itemComponent }) => {
    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        handleChange(arrayMove(metrics, oldIndex, newIndex));
    }, [metrics, handleChange]);
    return (React.createElement(SortableList, { useDragHandle: true, lockAxis: "y", lockToContainerEdges: true, lockOffset: ['0%', '100%'], onSortEnd: onSortEnd, disabled: !!disabled, metrics: metrics, handleChange: handleChange, itemComponent: itemComponent }));
};
