import { compact, flatMap } from 'lodash';
import { isWorkflowDefinition } from '../../common/utils/url';
import { AvailableSystemGroups } from '../../groups/constants';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../common/utils/constants';
export const getProperties = (metaDefinition) => compact(flatMap(metaDefinition?.sections || [], 'properties'));
export const permissionGroupFieldNames = [
    AvailableSystemGroups.viewGroups,
    AvailableSystemGroups.executeGroups,
    AvailableSystemGroups.deleteGroups
];
export const isProcess = (itemMeta) => ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(itemMeta.definitionId) &&
    isWorkflowDefinition(itemMeta);
