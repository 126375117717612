import { random, range } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useI18n } from '../../i18n';
import { Cell, Header, HeaderCell, ListContainer, Row } from './listComponents';
const RowPlaceholder = styled(Row) `
  cursor: default;
`.withComponent('div');
const ListPlaceholder = ({ className }) => {
    const { t } = useI18n();
    return (React.createElement(ListContainer, { className: className },
        React.createElement(Header, null,
            React.createElement(HeaderCell, null, t('COMMON_LOADING'))),
        range(random(6, 14)).map((i) => (React.createElement(RowPlaceholder, { key: `row-${i}` },
            React.createElement(Cell, null, "..."))))));
};
export default ListPlaceholder;
