import React, { useCallback } from 'react';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import ActivityForm from '../../../../activity/components/ActivityForm';
import { useActivityForm } from '../../../../activity/useActivityForm';
import FormEntityAdapter from './FormEntityAdapter';
const ActivityStickyContainer = ({ definitionId, entityId, handleChangeTabLabel, handleChangeTabDirty, onSubmitted, activityType }) => {
    const myContact = useMyContact();
    const { initialActivity, onSubmit } = useActivityForm({
        definitionId,
        entityId,
        onSubmitted
    });
    const onChangeForm = useCallback((values, dirty) => {
        handleChangeTabDirty(dirty);
        handleChangeTabLabel(values.title);
    }, [handleChangeTabDirty, handleChangeTabLabel]);
    const initialValues = initialActivity(activityType);
    if (!myContact.contactId || !initialValues) {
        return null;
    }
    return (React.createElement(ActivityForm, { initialShowForm: true, value: initialValues, onSubmit: onSubmit, isAvailableCancelButton: false, hideOnSubmit: false, onChange: onChangeForm }));
};
export default (props) => (React.createElement(FormEntityAdapter, null,
    React.createElement(ActivityStickyContainer, { ...props })));
