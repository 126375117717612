import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchOptionGroup, getOptions } from '@hypercharge/hyper-react-base/lib/metadata';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Select } from 'antd';
import { toString } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isComputedExpression } from '../../../utils/computedExpression';
import { getMetadataState } from '../../reducers/rootSelector';
import { ComputedInput } from '../ComputedExpressionEditor';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty }
];
const availableOperatorsNonList = [
    ...availableOperators,
    { value: FilterOperatorTypes.is },
    { value: FilterOperatorTypes.isNot }
];
const availableOperatorsForList = [
    ...availableOperators,
    { value: FilterOperatorTypes.contains },
    { value: FilterOperatorTypes.notContains }
];
const convertFilterOptions = (options, language) => options.map(({ value, labels }) => ({
    id: value,
    value: getTranslation(labels, language)
}));
const SelectFilter = ({ className, filter, onChange, disabled, meta, allowComputed, propertiesTree, autoFocus }) => {
    const { language } = useI18n();
    const dispatchFetchOptionGroup = useDispatch();
    const data = 'data' in filter ? toString(filter.data) : undefined;
    const options = useSelector((s) => {
        // @ts-expect-error
        return convertFilterOptions(getOptions(getMetadataState(s), meta.source), language);
    });
    const selectOptions = options
        .sort((a, b) => (a.value || '').localeCompare(b.value || ''))
        .map(({ id, value }) => ({
        value: id,
        label: value
    }));
    useEffect(() => {
        if (options.length == 0) {
            // @ts-expect-error
            void dispatchFetchOptionGroup(fetchOptionGroup(meta.source));
        }
        // @ts-expect-error
    }, [dispatchFetchOptionGroup, meta.source, options.length]);
    const onChangeInput = useCallback((value) => {
        value = Array.isArray(value) ? value[0] : value;
        const newFilter = buildFilter(filter.field, filter.operator, value);
        onChange(newFilter);
    }, [filter.field, filter.operator, onChange]);
    const operators = 'list' in meta && meta.list ? availableOperatorsForList : availableOperatorsNonList;
    return (React.createElement(BaseFilter, { onChange: onChange, operators: operators, filter: filter, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { component: Select, value: data, onChange: onChangeInput, disabled: disabled, propertiesTree: propertiesTree, ref: (ref) => autoFocus && ref?.focus({ preventScroll: true }), options: selectOptions, optionFilterProp: "label" })) : (React.createElement(Select, { value: data, onChange: onChangeInput, disabled: disabled, ref: (ref) => autoFocus && ref?.focus(), options: selectOptions, optionFilterProp: "label" }))));
};
export default SelectFilter;
