import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils/timeUtils';
import { Button, Table } from 'antd';
import { INITIAL_PAGE_SIZE } from 'config';
import React, { useCallback, useMemo } from 'react';
import { IoAdd } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FEATURED_ENTITIES_PATH } from '../../../../cms';
import { TooltipTruncate } from '../../../../common/components/TooltipTruncate';
import { FullTextSearchBase } from '../../../../common/components/data-table/FullTextSearch';
import TablePagination from '../../../../common/components/table-pagination';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../../../crm';
import { getQueryParams, getUpdatedLocation } from '../../../../views';
import { DOCUMENTS_PATH, DOCUMENT_TEMPLATES_PATH } from '../../../constants';
import { useSearchDocuments } from '../../../hooks/useSearchDocuments';
import styles from './DocumentTemplateList.module.scss';
const DocumentTemplateList = () => {
    const { search } = useLocation();
    const { t } = useI18n();
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            title: t('SETTINGS__PAGES_TITLE'),
            dataIndex: 'title',
            key: 'title',
            width: 150,
            ellipsis: true,
            render: (text, record) => (React.createElement(Button, { className: "p-0 w-100 text-start", type: "link", href: `${DOCUMENTS_PATH}${DOCUMENT_TEMPLATES_PATH}/${record.id}` },
                React.createElement(TooltipTruncate, null, text)))
        },
        {
            title: t('COMMON__LAST_UPDATED'),
            dataIndex: 'modifiedAt',
            key: 'modifiedAt',
            render: (modifiedAt) => formatDateTime(Number(modifiedAt))
        },
        {
            title: t('COMMON__UPDATED_BY'),
            dataIndex: 'modifiedBy',
            key: 'modifiedBy',
            render: (modifiedBy) => (React.createElement(ContactDisplayName, { contactId: modifiedBy, link: [FEATURED_ENTITIES_PATH, CONTACT_CMS_DEFINITION_ID, modifiedBy].join('/') }))
        }
    ], [t]);
    const queryParams = useMemo(() => getQueryParams(search), [search]);
    const { fullText = '', page = 1, pageSize = INITIAL_PAGE_SIZE } = queryParams;
    const onChangeSearch = useCallback((newValues) => {
        const newFilterRequest = {
            fullText,
            page,
            pageSize,
            ...newValues
        };
        dispatch(replace(getUpdatedLocation(newFilterRequest)));
    }, [dispatch, fullText, page, pageSize]);
    const { data: { totalCount, results } = {}, isFetching } = useSearchDocuments({
        filterRequest: {
            fullText,
            limit: pageSize,
            offset: (page - 1) * pageSize
        }
    });
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: "pb-3" },
            React.createElement("div", { className: "p-2 rounded border bg-white d-flex flex-wrap flex-row align-items-center align-content-center justify-content-between gap-2 actions-container" },
                React.createElement(FullTextSearchBase, { value: fullText, onChange: (fullText) => onChangeSearch({ fullText, page: 1 }), localStorageKey: 'document-templates' }),
                React.createElement(Button, { size: "large", type: "primary", href: `${DOCUMENTS_PATH}${DOCUMENT_TEMPLATES_PATH}/create` },
                    React.createElement(IoAdd, { className: "add-icon", preserveAspectRatio: "none" }),
                    t('DOCUMENTS_ADD_TEMPLATE')))),
        React.createElement(Table, { scroll: { x: '100vw' }, className: "w-100", columns: columns, dataSource: results, loading: isFetching, pagination: false }),
        React.createElement(TablePagination, { page: page, pageSize: Number(pageSize), totalCount: Number(totalCount), onPageChange: (newPage) => {
                onChangeSearch({ pageSize, page: newPage });
            }, onPageSizeChange: (newPageSize) => {
                onChangeSearch({ pageSize: newPageSize, page });
            } })));
};
export default DocumentTemplateList;
