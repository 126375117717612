import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { DEFAULT_LANGUAGE } from 'config';
import { PropertyTypes } from '../../types';
const getIdFromSplit = (complexString) => complexString.split('.')[1];
export const getParentPropertyId = (propertyId) => `parent.${propertyId}`;
export const getPropertyId = (selectedProperty, splitParent) => typeof selectedProperty === 'string'
    ? !splitParent
        ? selectedProperty
        : getIdFromSplit(selectedProperty)
    : selectedProperty?.propertyId;
export const getReferenceDefinitionId = (selectedPropertySource = '') => typeof selectedPropertySource === 'object'
    ? selectedPropertySource?.meta?.definitionId
    : getIdFromSplit(selectedPropertySource);
export const extractAvailableProperties = (displayData, entityMetaDefinitionId, isMultiple = false) => (displayData?.data ?? []).reduce((acc, section) => {
    if (['system', 'process'].includes(section.sectionId)) {
        return acc;
    }
    section.values.forEach((property) => {
        const metaDefinition = 'definitionId' in property.meta && property.meta.definitionId;
        const listProperty = !!('list' in property.meta && property.meta.list);
        if (property.type === PropertyTypes.entity &&
            property.canEdit &&
            (!entityMetaDefinitionId ? true : metaDefinition === entityMetaDefinitionId) &&
            !property.meta.hidden &&
            (!isMultiple ? true : listProperty)) {
            acc.push(property);
        }
    });
    return acc;
}, []);
export const getPropertyLabel = (property, language) => property.labels[language] || property.labels[DEFAULT_LANGUAGE];
export const getProcessRunDataArguments = (selectedPropertySource, entityMetaDefinitionId, selectedPropertyIdDestination, isAllItemsSelected, filterRequest, selectedItemIds, availableProcessPropertiesMap) => {
    const isNestProperty = selectedPropertySource !== getParentPropertyId(entityMetaDefinitionId);
    const selectedPropertyIdSource = getPropertyId(selectedPropertySource, !isNestProperty);
    let filter;
    let processRunData;
    if (!selectedPropertyIdDestination) {
        return {};
    }
    if (isAllItemsSelected) {
        filter = {
            [selectedPropertyIdDestination]: {
                definitionId: entityMetaDefinitionId,
                request: filterRequest,
                srcPropertyId: isNestProperty ? selectedPropertyIdSource : undefined
            }
        };
    }
    else {
        if (isNestProperty) {
            filter = {
                [selectedPropertyIdDestination]: {
                    definitionId: entityMetaDefinitionId,
                    request: {
                        ...filterRequest,
                        ids: {
                            operator: FilterOperatorTypes.in,
                            data: selectedItemIds
                        }
                    },
                    srcPropertyId: selectedPropertyIdSource
                }
            };
        }
        else {
            const property = availableProcessPropertiesMap[selectedPropertyIdDestination];
            processRunData = {
                [selectedPropertyIdDestination]: property.meta.list ? selectedItemIds : selectedItemIds[0]
            };
        }
    }
    return {
        processRunData,
        processRunDataFromFilter: filter
    };
};
