import { ErrorMessage, InputContainer, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import useDisplayItemMetaList from '../../../../../../cms/hooks/useDisplayItemMetaList';
import Toggle from '../../../../../../common/components/Toggle';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../../common/utils/constants';
const Option = Select.Option;
const EntityField = ({ disabled, onChange, value, error }) => {
    const { t } = useI18n();
    const errorMessage = useMemo(() => (typeof error !== 'string' ? error?.meta?.definitionId : error), [error]);
    const { data: entities = [] } = useDisplayItemMetaList();
    const entitiesOptions = entities
        .filter(({ definitionId }) => ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(definitionId))
        .map(({ definitionId, title }) => ({
        value: definitionId,
        label: title != null ? title : definitionId
    }));
    const onChangeDefinitionId = useCallback((val) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                definitionId: val
            }
        });
    }, [onChange, value]);
    const onChangeList = useCallback((checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                list: checked
            }
        });
    }, [onChange, value]);
    const onChangeNonExpandable = useCallback((checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                nonExpandable: checked
            }
        });
    }, [onChange, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputContainer, { className: "col-12 col-md-4 py-2" },
            React.createElement(LabelText, null, t('PROPERTY_FIELD_TYPE_entity')),
            React.createElement(Select, { className: "w-100", showSearch: true, disabled: disabled || !value.isNew, onChange: onChangeDefinitionId, value: value.meta.definitionId, options: entitiesOptions, optionFilterProp: "label" }),
            !!errorMessage && React.createElement(ErrorMessage, null, errorMessage)),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('OPTIONS_LIST'), disabled: disabled || !value.isNew, onChange: onChangeList, checked: value.meta.list }),
        value.meta.list && (React.createElement(Toggle, { className: "w-100 py-2", label: t('NON_EXPANDABLE'), disabled: disabled, onChange: onChangeNonExpandable, checked: value.meta.nonExpandable }))));
};
export default EntityField;
