import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../../../common/utils/react-query-client';
import { getPaymentCards } from '../actions';
export const SEARCH_PAYMENT_CARDS_QUERY_KEY = 'search-payment-cards';
export const invalidateSearchPaymentCardsQuery = async () => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(SEARCH_PAYMENT_CARDS_QUERY_KEY)
    });
};
export const useSearchPaymentCards = () => {
    const dispatchSearchPaymentCards = useDispatch();
    return useQuery([SEARCH_PAYMENT_CARDS_QUERY_KEY], () => {
        return dispatchSearchPaymentCards(getPaymentCards());
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
};
