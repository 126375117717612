import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Button, Collapse, Tooltip } from 'antd';
import { orderBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoReload } from 'react-icons/io5';
import { PoseGroup } from 'react-pose';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EmailSendingDetailsModal, Item, LoadingCommonBar } from '../..';
import { CMS_PATH } from '../../../../../../../cms';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { fetchFilesData } from '../../../../../../../common/storage';
import { ContactDisplayName } from '../../../../../../../crm';
import { ExpandIcon } from '../../../../../../../icons';
import TemplatePreview from '../../../../../../../marketing/templates/components/TemplateForm/TemplatePreview';
import { getSearchForUrl } from '../../../../../../../views/utils';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../../../../../../common/utils/types';
import { getEmailSendingResult } from '../../utils/utils';
const DoneTasksStepDescription = ({ activeKeys, tasks, loading, onPanelChange, onClickRestartTask }) => {
    const dispatch = useDispatch();
    const dispatchFilesData = useDispatch();
    const { t } = useI18n();
    const [mailStatusForModal, setMailStatusForModal] = useState(null);
    const [mailBodiesForModal, setMailBodiesForModal] = useState(undefined);
    const [isMailPreviewLoading, setMailPreviewLoading] = useState({});
    const [isMailPreviewVisible, setMailPreviewVisible] = useState(false);
    const [taskMailHTML, setTaskMailHTML] = useState('');
    const showPreview = useCallback(async (id) => {
        setMailPreviewLoading({ ...isMailPreviewLoading, [id]: true });
        try {
            const file = await dispatchFilesData(fetchFilesData([id]));
            const response = await fetch(file[0]?.downloadUrl || '');
            const html = await response.text();
            setTaskMailHTML(html);
            setMailPreviewVisible(true);
        }
        catch (e) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('FETCH_FORM__ERROR_MESSAGE')
            }));
        }
        finally {
            setMailPreviewLoading({ ...isMailPreviewLoading, [id]: false });
        }
    }, [isMailPreviewLoading, dispatchFilesData, dispatch, t]);
    return tasks?.length ? (React.createElement(React.Fragment, null,
        isMailPreviewVisible && taskMailHTML && (React.createElement(TemplatePreview, { compiledHtml: taskMailHTML, subject: "", closePreview: () => {
                setMailPreviewVisible(false);
            } })),
        React.createElement(PoseGroup, null,
            React.createElement(Collapse, { key: "DoneTasksStepDescription", ghost: true, onChange: onPanelChange, activeKey: activeKeys, items: orderBy(tasks, ['statusUpdatedAt'], ['asc']).map((task) => {
                    const { taskType, taskSubType, taskRunId, processRunId, taskMetaId, taskTitle, statusUpdatedAt, initiatedAt, assignedBy, assignedTo, restartedBy, mailBodies, mailStatuses, taskTarget } = task;
                    const isAllowRestartTask = !!onClickRestartTask &&
                        taskType === ActivityNodeTypes.ACTION &&
                        taskSubType &&
                        ![
                            ActivityActionNodeSubtypes.MANUAL,
                            ActivityActionNodeSubtypes.SEMIAUTOMATED
                        ].includes(taskSubType);
                    const emailSendingResult = getEmailSendingResult(mailStatuses);
                    return {
                        key: taskRunId,
                        extra: isAllowRestartTask ? (React.createElement(Tooltip, { title: t('RERUN_TASK') },
                            React.createElement(Button, { icon: React.createElement(IoReload, null), type: "text", onClick: (event) => {
                                    event.stopPropagation();
                                    onClickRestartTask(taskRunId);
                                }, className: "safe-space" }))) : undefined,
                        label: (React.createElement(React.Fragment, null,
                            React.createElement(TooltipTruncate, null, taskTitle),
                            React.createElement("div", { className: "d-flex aling-items-baseline" },
                                emailSendingResult && (React.createElement("div", { className: "d-flex align-items-center" },
                                    React.createElement("div", { className: "task-nums-success" }, emailSendingResult.success),
                                    React.createElement("div", { className: "task-nums-warning" }, emailSendingResult.warn),
                                    React.createElement("div", { className: "task-nums-fail" }, emailSendingResult.fail),
                                    React.createElement(Button, { className: "task-expand-mail-button p-0 d-flex ms-1", type: "text", onClick: (event) => {
                                            event.stopPropagation();
                                            setMailBodiesForModal(mailBodies);
                                            setMailStatusForModal(mailStatuses || null);
                                        } },
                                        React.createElement(ExpandIcon, { className: "task-expand-mail" })))),
                                !emailSendingResult && taskTarget === 'EMAIL' && (React.createElement(Link, { className: "ms-1", target: "_blank", to: {
                                        pathname: `${CMS_PATH}/emailLogs/browse`,
                                        search: getSearchForUrl({
                                            newFilters: {
                                                query: {
                                                    condition: ConditionType.and,
                                                    filters: [
                                                        {
                                                            field: 'processRunId',
                                                            operator: FilterOperatorTypes.is,
                                                            data: processRunId
                                                        },
                                                        {
                                                            field: 'taskMetaId',
                                                            operator: FilterOperatorTypes.is,
                                                            data: taskMetaId
                                                        }
                                                    ]
                                                },
                                                sortBy: []
                                            },
                                            newMetrics: [],
                                            queryParams: {}
                                        })
                                    } },
                                    t('EMAIL_SENDING_DETAILS_LOG_LINK'),
                                    React.createElement(FaExternalLinkAlt, { className: "ms-1 mb-1" })))))),
                        children: (React.createElement(Item, { key: task.taskRunId },
                            restartedBy && (React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('RESTARTED_BY')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ContactDisplayName, { contactId: restartedBy })))),
                            assignedBy && assignedBy !== assignedTo && (React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('ASSIGNED_BY')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ContactDisplayName, { contactId: assignedBy })))),
                            assignedTo && (React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('ASSIGNED_TO')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ContactDisplayName, { contactId: assignedBy })))),
                            React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('INITIATED_AT')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ReadableDateTime, { dateTime: initiatedAt }))),
                            React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('COMPLETED_AT')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ReadableDateTime, { dateTime: statusUpdatedAt }))))),
                        className: 'CustomCollapsePanel'
                    };
                }) })),
        React.createElement(EmailSendingDetailsModal, { mailStatusForModal: mailStatusForModal, setMailStatusForModal: setMailStatusForModal, isMailPreviewLoading: isMailPreviewLoading, showPreview: showPreview, mailBodiesForModal: mailBodiesForModal }))) : (React.createElement(LoadingCommonBar, { loading: loading && !tasks.length }));
};
export default DoneTasksStepDescription;
