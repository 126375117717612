import React from 'react';
const SmallRowSizeIcon = ({ color = 'currentColor' }) => (React.createElement("svg", { width: "20", height: "18", viewBox: "0 0 20 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { id: "Group 1310" },
        React.createElement("g", { id: "Vector" },
            React.createElement("path", { d: "M16.9988 13.8C16.9988 13.9105 17.0884 14 17.1988 14H19.516C19.6941 14 19.7834 14.2155 19.6574 14.3415L16.1402 17.8586C16.0621 17.9367 15.9355 17.9367 15.8574 17.8586L12.3402 14.3415C12.2142 14.2155 12.3035 14 12.4817 14H14.7988C14.9093 14 14.9988 13.9105 14.9988 13.8V11.2C14.9988 11.0896 15.0884 11 15.1988 11H16.7988C16.9093 11 16.9988 11.0896 16.9988 11.2V13.8Z", fill: color }),
            React.createElement("path", { d: "M16.9988 4.20004C16.9988 4.08959 17.0884 4.00004 17.1988 4.00004H19.516C19.6941 4.00004 19.7834 3.78461 19.6574 3.65862L16.1402 0.141464C16.0621 0.0633594 15.9355 0.0633596 15.8574 0.141465L12.3402 3.65862C12.2142 3.78461 12.3035 4.00004 12.4817 4.00004H14.7988C14.9093 4.00004 14.9988 4.08959 14.9988 4.20004V11.8C14.9988 11.9105 15.0884 12 15.1988 12H16.7988C16.9093 12 16.9988 11.9105 16.9988 11.8V4.20004Z", fill: color })),
        React.createElement("path", { id: "Vector_2", d: "M0 2C0 1.44772 0.447715 1 1 1H10C10.5523 1 11 1.44772 11 2V6C11 6.55228 10.5523 7 10 7H1C0.447715 7 0 6.55228 0 6V2Z", fill: color }),
        React.createElement("path", { id: "Vector_3", d: "M0 10C0 9.44772 0.447715 9 1 9H10C10.5523 9 11 9.44772 11 10C11 10.5523 10.5523 11 10 11H1C0.447715 11 0 10.5523 0 10Z", fill: color }),
        React.createElement("path", { id: "Vector_4", d: "M0 13C0 12.4477 0.447715 12 1 12H10C10.5523 12 11 12.4477 11 13C11 13.5523 10.5523 14 10 14H1C0.447715 14 0 13.5523 0 13Z", fill: color }),
        React.createElement("path", { id: "Vector_5", d: "M0 16C0 15.4477 0.447715 15 1 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H1C0.447715 17 0 16.5523 0 16Z", fill: color }))));
export default SmallRowSizeIcon;
