import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Switch } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, DrawerContent, DrawerFooter } from '../../components/Drawer';
import { DrawerContentBlock } from '../../components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../components/Drawer/DrawerFormFooter';
import SettingsButton from '../../components/SettingsButton';
const NotificationsSetEmailFields = ({ fieldList, value, onChange }) => {
    const { t } = useI18n();
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedFields, setSelectedFields] = useState(new Set());
    const intervalId = useRef(null);
    useEffect(() => {
        if (!isEmpty(value)) {
            setSelectedFields(new Set(value));
        }
        else if (fieldList[0]) {
            onSwitchField(fieldList[0].id);
            setShowDrawer(true);
        }
    }, [value]);
    const sendChanges = useCallback(() => {
        const newValue = [...selectedFields.values()];
        if (!isEqual(newValue, value)) {
            onChange(newValue);
        }
    }, [onChange, selectedFields, value]);
    const toggleShowDrawer = useCallback(() => {
        if (showDrawer == true) {
            // window will be closed
            if (intervalId.current) {
                clearTimeout(intervalId.current);
                intervalId.current = null;
            }
            sendChanges();
        }
        setShowDrawer(!showDrawer);
    }, [sendChanges, showDrawer]);
    const onSwitchField = useCallback((id) => {
        if (selectedFields.has(id)) {
            if (selectedFields.size < 2) {
                return;
            }
            selectedFields.delete(id);
        }
        else {
            selectedFields.add(id);
        }
        setSelectedFields(new Set(selectedFields.values()));
        if (intervalId.current) {
            clearTimeout(intervalId.current);
            intervalId.current = null;
        }
        const interval = setTimeout(sendChanges, 1000);
        intervalId.current = interval;
    }, [selectedFields, sendChanges]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SettingsButton, { onClick: toggleShowDrawer }),
        React.createElement(Drawer, { title: t('TENANT_NOTIFICATION_EMAIL_FIELDS'), onClose: toggleShowDrawer, open: showDrawer },
            React.createElement(DrawerContent, null,
                React.createElement(DrawerContentBlock, null, fieldList.map((field) => (React.createElement("div", { className: "d-flex align-items-center gap-3", key: field.id },
                    React.createElement(Switch, { size: "small", onChange: () => onSwitchField(field.id), checked: selectedFields.has(field.id), disabled: selectedFields.has(field.id) && selectedFields.size == 1 }),
                    React.createElement("label", { onClick: () => onSwitchField(field.id) }, field.label)))))),
            React.createElement(DrawerFooter, null,
                React.createElement(DrawerFormFooter, { submitText: t('COMMON__MODAL_CLOSE'), onSubmit: toggleShowDrawer })))));
};
export default NotificationsSetEmailFields;
