import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { APPS_KEY, SETTINGS_PATH } from '../../../constants';
const AppInstance = ({ appInstance, toggleApp }) => {
    const { t } = useI18n();
    const notificationDispatch = useDispatch();
    const toggle = useCallback(async () => {
        try {
            await toggleApp(appInstance);
        }
        catch (err) {
            console.error(err);
            void notificationDispatch(error({
                title: t('ERROR'),
                message: t('COMMON__UPDATE_FAILURE')
            }));
        }
    }, [appInstance, notificationDispatch, t, toggleApp]);
    return (React.createElement("div", { className: "d-flex card-item h-100" },
        React.createElement("div", { className: "d-flex p-3 col-4 justify-content-center logo" }),
        React.createElement("div", { className: "col-8 p-3" },
            React.createElement("h5", { className: "fw-bold title" }, appInstance.title),
            React.createElement("div", { className: "d-flex flex-wrap" },
                React.createElement(Button, { className: `mt-2 me-3`, to: [SETTINGS_PATH, APPS_KEY, appInstance.definitionId, appInstance.entityId].join('/') }, appInstance.entityId !== 'new' ? t('SETTINGS') : t('APPS_ADD_BUTTON')),
                appInstance.entityId !== 'new' && (React.createElement(Button, { className: "mt-2 me-3", onClick: toggle, inversed: true }, appInstance.enabled ? t('APPS__DISABLE') : t('APPS__ENABLE')))))));
};
export default AppInstance;
