import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType } from '@hypercharge/portal-utils';
import { Button, Radio } from 'antd';
import cn from 'classnames';
import { find, forEach, isEmpty, keyBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaFolderPlus, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { ENTITY_ID_PROPERTY_ID, getFlattenedDisplayDataList } from '../../../../cms';
import { useEntityDisplayData, useNestedDisplayData } from '../../../../cms/common/components/withEntityDisplayData';
import { getField } from '../../../../cms/common/utils/utils';
import { SelectPropertyButton } from '../../../../cms/data/components/item-property/SelectProperty';
import { isComputedExpression } from '../../../../utils/computedExpression';
import { DEFAULT_FILTERS_CONDITION } from '../../../../views';
import { isConditionT } from '../../../../views/types';
import { PropertyTypes } from '../../../types';
import { LoadingBlock, getIsFormHasError } from '../../../utils/formUtils';
import ConditionQueryLabel from '../ConditionQueryLabel';
import { QuickFilterId } from '../constants';
import { buildStartFilter, getFieldRootLevel, getFilterComponentByType, isFilterType, isOperatorWithoutData } from '../utils';
import styles from './ConditionQueryField.module.scss';
//
// Utils
//
const getFilteredFlattenedDisplayDataList = (displayDataList, propertyIdBlacklist = []) => {
    return getFlattenedDisplayDataList(displayDataList).filter(({ propertyId, type }) => supportedFilterPropertyTypes.includes(type) && !propertyIdBlacklist.includes(propertyId));
};
const generateDisplayDataMap = (displayDataList, parentPropertyId) => {
    return keyBy(getFilteredFlattenedDisplayDataList(displayDataList, parentPropertyId ? [ENTITY_ID_PROPERTY_ID] : []), (displayData) => getField(displayData, parentPropertyId, true));
};
export const supportedFilterPropertyTypes = [
    PropertyTypes.text,
    PropertyTypes.date,
    PropertyTypes.number,
    PropertyTypes.select,
    PropertyTypes.multitext,
    PropertyTypes.checkbox,
    PropertyTypes.entity,
    PropertyTypes.richtext,
    PropertyTypes.file,
    PropertyTypes.link,
    PropertyTypes.phoneNumber,
    PropertyTypes.email
    // 'json' // Not indexed at the moment
];
const ConditionQueryField = ({ value, onChange, className = '', disabled, input, meta, allowComputed = false, propertiesTree, definitionId, includeNestedProperties = true, onDelete }) => {
    const { t } = useI18n();
    const currentValue = useMemo(() => {
        const val = input?.value || value;
        return {
            condition: val?.condition || DEFAULT_FILTERS_CONDITION,
            filters: val?.filters || []
        };
    }, [input?.value, value]);
    const activeFilters = useMemo(() => {
        return currentValue.filters || [];
    }, [currentValue.filters]);
    const { displayData: rootDisplayDataList, displayDataWithHidden: rootDisplayDataWithHidden, displayDataStatus: rootDisplayDataListStatus } = useEntityDisplayData(definitionId);
    const { displayData: nestedDisplayDataLists, displayDataWithHidden: nestedDisplayDataWithHidden, displayDataStatus: nestedDisplayDataListsStatus } = useNestedDisplayData(includeNestedProperties ? rootDisplayDataWithHidden : undefined);
    const loading = rootDisplayDataListStatus.isPending || nestedDisplayDataListsStatus.isPending;
    const loadingFailed = rootDisplayDataListStatus.isFailed || nestedDisplayDataListsStatus.isFailed;
    const isQuickFilter = useMemo(() => value?.id === QuickFilterId, [value?.id]);
    const { displayDataMap } = useMemo(() => {
        if (!rootDisplayDataList || !rootDisplayDataWithHidden) {
            return {
                displayDataMap: {}
            };
        }
        // First construct the filter options for the properties of the CMS entity that we're filtering on
        let newDisplayDataMap = generateDisplayDataMap(rootDisplayDataWithHidden);
        if (nestedDisplayDataWithHidden && nestedDisplayDataLists) {
            // Get info of the properties of type entity
            const entityProperties = [];
            forEach(newDisplayDataMap, (displayData, propertyId) => {
                if (displayData.type === PropertyTypes.entity) {
                    const propertyDefinitionId = 'definitionId' in displayData.meta ? displayData.meta.definitionId : undefined;
                    if (propertyDefinitionId) {
                        entityProperties.push({
                            propertyId,
                            propertyDefinitionId,
                            nonExpandable: displayData.meta.nonExpandable
                        });
                    }
                }
            });
            // Use the fetched DisplayDataList objects to complete the filterOptions by adding the nested properties
            forEach(entityProperties, ({ propertyId, propertyDefinitionId, nonExpandable }) => {
                if (nonExpandable) {
                    return;
                }
                const displayDataListWithHidden = find([...nestedDisplayDataWithHidden, rootDisplayDataWithHidden], {
                    definitionId: propertyDefinitionId
                });
                if (displayDataListWithHidden) {
                    newDisplayDataMap = {
                        ...newDisplayDataMap,
                        ...generateDisplayDataMap(displayDataListWithHidden, propertyId.replace('.entityId', ''))
                    };
                }
            });
        }
        return { displayDataMap: newDisplayDataMap };
    }, [
        rootDisplayDataList,
        rootDisplayDataWithHidden,
        nestedDisplayDataWithHidden,
        nestedDisplayDataLists
    ]);
    const handleConditionChange = useCallback((event) => {
        if (isConditionT(event.target.value)) {
            const newValue = {
                condition: event.target.value,
                filters: activeFilters
            };
            input?.onChange && input.onChange(newValue);
            onChange && onChange(newValue);
        }
    }, [activeFilters, input, onChange]);
    const handleFiltersChange = useCallback((filters) => {
        const newValue = {
            condition: currentValue.condition,
            filters
        };
        input?.onChange && input.onChange(newValue);
        onChange && onChange(newValue);
    }, [currentValue.condition, input, onChange]);
    const removeActiveFilter = useCallback((activeFilterIndex) => {
        const newActiveFilters = [
            ...activeFilters.slice(0, activeFilterIndex),
            ...activeFilters.slice(activeFilterIndex + 1)
        ];
        handleFiltersChange(newActiveFilters);
    }, [activeFilters, handleFiltersChange]);
    const updateActiveFilter = useCallback((activeFilterIndex, filter) => {
        const newActiveFilters = activeFilterIndex == null
            ? [...activeFilters, filter]
            : [
                ...activeFilters.slice(0, activeFilterIndex),
                filter,
                ...activeFilters.slice(activeFilterIndex + 1)
            ];
        handleFiltersChange(newActiveFilters);
    }, [activeFilters, handleFiltersChange]);
    const addActiveFilter = useCallback((field) => {
        if (!field) {
            return;
        }
        const displayData = displayDataMap[field];
        const newFilter = buildStartFilter(field, displayData.type, displayData.meta);
        updateActiveFilter(undefined, newFilter);
    }, [displayDataMap, updateActiveFilter]);
    const addConditionalQueryGroup = useCallback(() => {
        const newFilter = [
            ...currentValue.filters,
            {
                condition: DEFAULT_FILTERS_CONDITION,
                filters: []
            }
        ];
        handleFiltersChange(newFilter);
    }, [currentValue.filters, handleFiltersChange]);
    const showError = useMemo(() => getIsFormHasError(meta), [meta]);
    if (loadingFailed) {
        return React.createElement("div", { className: styles.errorLabel }, t('CONDITIONS_FAILED_TO_LOAD'));
    }
    else if (loading) {
        return React.createElement(LoadingBlock, null);
    }
    return (React.createElement("div", { className: cn(styles.wrapper, className, 'd-flex flex-column row-gap-3') },
        React.createElement("div", { className: "d-flex align-items-center" },
            React.createElement("div", { className: "flex-grow-1 d-flex flex-column row-gap-2" },
                isQuickFilter && React.createElement("div", { className: "mb-2" }, t('IS_QUICK_FILTER_GROUP')),
                React.createElement("div", { className: "d-flex flex-wrap column-gap-2 align-items-center" },
                    React.createElement("span", null, t('CONDITIONS_MEET_X_OF_FOLLOWING_CONDITIONS_1')),
                    React.createElement(Radio.Group, { value: currentValue.condition, size: "small", disabled: isQuickFilter, onChange: handleConditionChange, buttonStyle: "solid" },
                        React.createElement(Radio.Button, { value: ConditionType.and, disabled: disabled }, t('CONDITIONS_ALL')),
                        React.createElement(Radio.Button, { value: ConditionType.or, disabled: disabled }, t('CONDITIONS_ANY'))),
                    React.createElement("span", null, t('CONDITIONS_MEET_X_OF_FOLLOWING_CONDITIONS_2')))),
            onDelete && (React.createElement(Button, { type: "text", className: "rounded-circle d-flex align-items-center justify-content-center delete-btn", onClick: onDelete },
                React.createElement(FaRegTrashAlt, { size: 24, color: "var(--neutralColor-6)" })))),
        !isEmpty(activeFilters) &&
            activeFilters
                .filter((filterItem) => {
                if ('condition' in filterItem) {
                    return true;
                }
                return !!displayDataMap[filterItem.field];
            })
                .map((filter, index) => {
                if ('condition' in filter) {
                    return (React.createElement(ConditionQueryField, { key: index, className: "border-start group border-4 ps-3", definitionId: definitionId, allowComputed: allowComputed, propertiesTree: propertiesTree, value: filter, includeNestedProperties: includeNestedProperties, disabled: disabled, onDelete: () => {
                            removeActiveFilter(index);
                        }, onChange: (conditionQuery) => {
                            updateActiveFilter(index, { ...filter, ...conditionQuery });
                        } }));
                }
                const isNestedProperty = filter.field.replace(/\.(entityId|text)/g, '').split('.').length > 1;
                const filterDisplayData = displayDataMap[filter.field];
                const parentDisplayData = isNestedProperty
                    ? displayDataMap[`${filter.field.substring(0, filter.field.indexOf('.'))}.entityId`]
                    : undefined;
                const FilterComponent = getFilterComponentByType(filterDisplayData.type);
                const isComputed = 'data' in filter && isComputedExpression(filter.data);
                const isDisabled = disabled || (isComputed && !allowComputed) || filterDisplayData.meta.hidden;
                return (React.createElement("div", { key: `${filter.field}-${index}`, className: "row align-items-center " },
                    React.createElement(ConditionQueryLabel, { className: "col-md-4 col-xs-12", displayData: filterDisplayData, parentDisplayData: parentDisplayData }),
                    React.createElement("div", { className: "d-flex gap-2 flex-row col-md-8 col-xs-12 align-items-center" },
                        React.createElement(FilterComponent, { className: `property-filter ${filterDisplayData.type}-filter`, allowComputed: !isQuickFilter && allowComputed, propertiesTree: propertiesTree, disabledSelectOperator: isQuickFilter && !isOperatorWithoutData(filter.operator), filter: filter, onChange: (filter) => {
                                isFilterType(filter) &&
                                    updateActiveFilter(index, {
                                        ...filter,
                                        field: getFieldRootLevel(filter.field, filterDisplayData.type)
                                    });
                            }, meta: filterDisplayData.meta, disabled: isDisabled }),
                        React.createElement(Button, { type: "text", className: "rounded-circle d-flex align-items-center justify-content-center delete-btn", onClick: () => {
                                removeActiveFilter(index);
                            } },
                            React.createElement(FaRegTrashAlt, { size: 24, color: "var(--neutralColor-6)" })))));
            }),
        !isQuickFilter && (React.createElement("div", { className: "d-flex flex-wrap" },
            React.createElement(SelectPropertyButton, { className: "antd-tree-select-btn fw-600 me-2 w-50", disabled: disabled, label: React.createElement(React.Fragment, null,
                    React.createElement(FaPlus, { size: 18, className: "me-2" }),
                    t('ADD_CONDITION')), onChange: addActiveFilter, definitionId: definitionId, includeNestedProperties: includeNestedProperties, includeProperty: ({ type }) => ![PropertyTypes.json].includes(type), asFilter: true, error: showError ? meta?.error : undefined }),
            React.createElement(Button, { className: "add-condition-group-btn rounded-5 fw-600 d-flex gap-2 align-items-center", onClick: addConditionalQueryGroup },
                React.createElement(FaFolderPlus, { size: 18 }),
                t('ADD_CONDITION_GROUP'))))));
};
export default ConditionQueryField;
