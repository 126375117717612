import { InputContainer, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { DateDisplay, DateEditor, ValueHistoryDrawer, getEditorComponentForType } from '../../../../../cms';
import useDisplayData from '../../../../../cms/hooks/useDisplayData';
import { useUpdateProperty } from '../../../../../cms/hooks/useUpdateProperty';
import { InlineEditor } from '../../../../../common/components/InlineEditor';
import DisplayPriorityTag from '../../../../common/components/display-priority-tag';
import { TASK_DEADLINE_PROPERTY_ID, TASK_PRIORITY_DEFINITION_ID, TASK_PRIORITY_PROPERTY_ID } from '../../../../common/utils/constants';
import { isTaskPriority } from '../../../../common/utils/types';
import DeadlineEditor from '../DeadlineEditor';
import styles from './TaskInlineEditor.module.scss';
const FOLLOW_UP_EXTRA_EDITOR_PROPS = {
    disabledDate: (current) => {
        return !current || current.isBefore(moment().startOf('day'));
    },
    disabledTime: (current) => {
        if (current == null) {
            return null;
        }
        const getRange = (start, end) => [...Array(end - start + 1).keys()].map((i) => i + start);
        const minDate = moment();
        if (current.isSame(minDate, 'day')) {
            return {
                disabledHours: () => {
                    return minDate.hour() > 0 ? getRange(0, minDate.hour() - 1) : [];
                },
                disabledMinutes: (hour) => {
                    return hour === minDate.hour() && minDate.minutes() > 0
                        ? getRange(0, minDate.minutes() - 1)
                        : [];
                }
            };
        }
    }
};
const TaskInlineEditor = ({ task, canEdit, onChangePriority, onChangeDeadline, onChangeFollowUp }) => {
    const { priority, deadline, taskDefinitionId, taskRunId } = task;
    const { t } = useI18n();
    const { data: displayDataList } = useDisplayData({
        definitionId: taskDefinitionId,
        entityId: taskRunId
    });
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId: taskDefinitionId
    });
    const [canViewDeadline, canEditDeadline] = useMemo(() => {
        const values = displayDataList?.data?.reduce((acc, item) => [...acc, ...item.values], []) ||
            [];
        for (const taskPropertyDisplayData of values) {
            if (taskPropertyDisplayData?.propertyId === TASK_DEADLINE_PROPERTY_ID) {
                return [true, taskPropertyDisplayData.canEdit];
            }
        }
        return [false, false];
    }, [displayDataList?.data]);
    const [priorityHistoryDrawerOpen, setPriorityHistoryDrawerOpen] = useState(false);
    const updatePriority = useCallback(async (newPriority) => {
        if (typeof newPriority !== 'string') {
            return;
        }
        await updateProperty({
            entityId: taskRunId,
            propertyId: TASK_PRIORITY_PROPERTY_ID,
            value: newPriority
        });
        await onChangePriority(newPriority);
    }, [updateProperty, taskRunId, onChangePriority]);
    const togglePriorityHistoryDrawer = useCallback(() => {
        setPriorityHistoryDrawerOpen((prev) => !prev);
    }, []);
    const _onChangeFollowUp = useCallback(async (value) => {
        if (value == null || typeof value === 'number') {
            return onChangeFollowUp(value);
        }
    }, [onChangeFollowUp]);
    return (React.createElement("div", { className: styles.editor },
        React.createElement(InputContainer, { className: "d-flex w-100 align-items-center justify-content-between" },
            React.createElement(LabelText, { className: "p-0 label-text" }, t('PRIORITY')),
            React.createElement(InlineEditor, { className: styles.priorityField, displayComponent: DisplayPriority, editorComponent: getEditorComponentForType('entity'), expand: true, value: priority, meta: { definitionId: TASK_PRIORITY_DEFINITION_ID }, canEdit: canEdit, hideActions: false, allowClear: false, onSave: updatePriority, onHistoryOpened: togglePriorityHistoryDrawer }),
            React.createElement(ValueHistoryDrawer, { open: priorityHistoryDrawerOpen, definitionId: taskDefinitionId, entityId: taskRunId, propertyId: TASK_PRIORITY_PROPERTY_ID, onSave: (propId, value) => updatePriority(value), onClose: togglePriorityHistoryDrawer })),
        canViewDeadline && (React.createElement(InputContainer, { className: "d-flex w-100 align-items-center justify-content-between" },
            React.createElement(LabelText, { className: "p-0 label-text" }, t('DEADLINE')),
            React.createElement(DeadlineEditor, { className: `${styles.deadlineField}`, definitionId: taskDefinitionId, entityId: taskRunId, propertyId: TASK_DEADLINE_PROPERTY_ID, canEdit: canEditDeadline, value: deadline, onChange: onChangeDeadline }))),
        task.taskTarget === 'ACTIVITY' && (React.createElement(InputContainer, { className: "d-flex w-100 align-items-center justify-content-between" },
            React.createElement(LabelText, { className: "p-0 label-text" }, t('FOLLOW_UP')),
            React.createElement(InlineEditor, { className: styles.followUpField, 
                // @ts-expect-error
                displayComponent: DateDisplay, 
                // @ts-expect-error
                editorComponent: DateEditor, meta: { withTime: true }, canEdit: true, onSave: _onChangeFollowUp, extraEditorComponentProps: FOLLOW_UP_EXTRA_EDITOR_PROPS })))));
};
export default TaskInlineEditor;
const DisplayPriority = ({ value }) => (React.createElement(React.Fragment, null, value && isTaskPriority(value) ? React.createElement(DisplayPriorityTag, { priority: value, showLabel: true }) : '-'));
