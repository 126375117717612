import { push } from '@hypercharge/hyper-react-base/lib/router/routerActions';
import { TENANT_ID } from 'config';
import produce from 'immer';
import { set } from 'lodash';
import queryString from 'query-string';
import { refreshAuthToken, requestAuthToken } from './actions';
import { AUTH_PATH } from './constants';
import { getAccountId, getAuthJwt, getAuthJwtExpiry, getAuthRefreshToken, getIsApiAuthenticated, getIsUiAuthenticated } from './selectors';
let tokenRefreshTimer;
let timerActive = false;
const stopTokenRefresh = () => {
    clearTimeout(tokenRefreshTimer);
    timerActive = false;
};
const scheduleTokenRefresh = (getState, dispatch, force) => {
    if (!timerActive || force) {
        const expiry = getAuthJwtExpiry(getState());
        if (expiry) {
            const timeout = new Date(expiry).getTime() - Date.now() - 60000;
            if (timeout > 0) {
                clearTimeout(tokenRefreshTimer);
                tokenRefreshTimer = setTimeout(() => {
                    dispatch(refreshAuthToken());
                }, timeout);
                timerActive = true;
            }
        }
    }
};
const authMiddlewareFactory = (config) => {
    let isAuthenticating = false;
    const pendingActions = [];
    const nextWithAuthHeader = (getState, dispatch, next, action) => {
        const s = getState();
        const jwt = getAuthJwt(s);
        const isAuthenticated = getIsApiAuthenticated(s);
        const isUiAuthenticated = getIsUiAuthenticated(s);
        const refreshToken = getAuthRefreshToken(s);
        const accountId = getAccountId(s);
        if (accountId != null && (!isUiAuthenticated || !isAuthenticated)) {
            if (!isUiAuthenticated) {
                // Invalid refresh token
                const { pathname, search, hash } = window.location;
                const queryParams = { redirect: `${pathname}${search}${hash}` };
                dispatch(push({
                    pathname: AUTH_PATH,
                    search: queryString.stringify(queryParams)
                }));
            }
            else {
                // current accesss token has expired
                const promise = new Promise((resolve, reject) => {
                    // @ts-expect-error
                    pendingActions.push({ action, resolve });
                });
                if (!isAuthenticating) {
                    isAuthenticating = true;
                    dispatch(requestAuthToken(TENANT_ID, accountId, refreshToken));
                }
                return promise;
            }
        }
        else if (jwt) {
            const newAction = produce(action, (draft) => {
                set(draft, 'meta.http.headers.Authorization', `Bearer ${jwt}`);
            });
            return next(newAction);
        }
        return next(action);
    };
    const resumeHttpActions = (getState, dispatch, next) => {
        pendingActions.forEach(({ action, resolve }) => {
            // @ts-expect-error
            resolve(nextWithAuthHeader(getState, dispatch, next, action));
        });
        pendingActions.length = 0;
    };
    // @ts-expect-error
    const authMiddleware = ({ getState, dispatch }) => (next) => (action) => {
        scheduleTokenRefresh(getState, dispatch);
        if (action.type === 'AUTH__LOG_OUT') {
            // TODO: should this be on AUTH__LOG_OUT_SUCCESS instead?
            const nextReturn = next(action);
            dispatch(push(config.redirectPathOnLogout || '/')); // TODO: check if it conflicts with redirectIfNotAuthenticated
            stopTokenRefresh();
            return nextReturn;
        }
        else if (action.type == 'AUTH__FORCE_REFRESH_AUTH_TOKEN') {
            const s = getState();
            const accountId = getAccountId(s);
            // TODO need remove if after testing https://hypercharge.hyperportal.org/featured-cms/devTicket/browse/pkMXqCnceDoKMKgDGuTgI
            // dispatch(requestAuthToken(TENANT_ID, accountId, getAuthRefreshToken(s)));
            if (accountId) {
                dispatch(requestAuthToken(TENANT_ID, accountId, getAuthRefreshToken(s)));
            }
            else {
                window.Sentry.captureException({
                    message: 'No account id found, cannot refresh token',
                    state: s,
                    action
                });
            }
        }
        else if (['AUTH__REFRESH_AUTH_TOKEN_SUCCESS', 'AUTH__VERIFY_CODE_SUCCESS'].includes(action.type)) {
            const nextReturn = next(action);
            // IMPORTANT: next(action) dispatched the action, resulting in the auth reducer including the authentication details in the state.
            // Therefore calling getState() will give you a state containing jwt info.
            isAuthenticating = false;
            resumeHttpActions(getState, dispatch, next);
            scheduleTokenRefresh(getState, dispatch, true);
            return nextReturn;
        }
        else if (action.type == 'AUTH__REFRESH_AUTH_TOKEN_FAIL') {
            setTimeout(() => {
                scheduleTokenRefresh(getState, dispatch, true);
            }, 2000);
        }
        else if (action.meta && action.meta.auth !== false && action.meta.http) {
            return nextWithAuthHeader(getState, dispatch, next, action);
        }
        return next(action);
    };
    return authMiddleware;
};
export default authMiddlewareFactory;
