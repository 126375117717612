import React from 'react';
const DOWN_ARROW_PATH = 'm16.29 4.3a1 1 0 1 1 1.41 1.42l-8 8a1 1 0 0 1 -1.41 0l-8-8a1 1 0 1 1 1.41-1.42l7.29 7.29z';
const LEFT_ARROW_PATH = 'M13.703 16.293a1 1 0 1 1-1.415 1.414l-7.995-8a1 1 0 0 1 0-1.414l7.995-8a1 1 0 1 1 1.415 1.414L6.413 9l7.29 7.293z';
const RIGHT_ARROW_PATH = 'M4.293 1.707A1 1 0 1 1 5.708.293l7.995 8a1 1 0 0 1 0 1.414l-7.995 8a1 1 0 1 1-1.415-1.414L11.583 9l-7.29-7.293z';
const getArrow = (svgArrowPath) => ({ className, ...otherProps }) => (React.createElement("svg", { className: className, role: "presentation", "aria-hidden": true, focusable: "false", viewBox: "0 0 18 18", ...otherProps },
    React.createElement("path", { d: svgArrowPath, fillRule: "evenodd" })));
export const DownArrow = getArrow(DOWN_ARROW_PATH);
export const LeftArrow = getArrow(LEFT_ARROW_PATH);
export const RightArrow = getArrow(RIGHT_ARROW_PATH);
export default {
    DownArrow,
    LeftArrow,
    RightArrow
};
