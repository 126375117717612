import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { FormFooter } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getEntityDetailsUrl } from '../../../../common/utils/url';
import { IMPORT_DEFINITION_ID } from './constants';
const ImportingStep = ({ importEntityId }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const handleGoToImportDetails = useCallback(() => {
        dispatch(push(getEntityDetailsUrl(IMPORT_DEFINITION_ID, importEntityId)));
    }, [dispatch, importEntityId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionCard, { contentStyle: sectionCardContentStyle },
            React.createElement(ScSuccessIcon, null),
            React.createElement("p", null, t('CMS_IMPORTER__IMPORTING_TEXT_1')),
            React.createElement("p", null, t('CMS_IMPORTER__IMPORTING_TEXT_2')),
            React.createElement("p", null, t('CMS_IMPORTER__IMPORTING_TEXT_3'))),
        React.createElement(FormFooter, { submitText: t('CMS_IMPORTER__VIEW_DETAILS'), onSubmit: handleGoToImportDetails })));
};
export default ImportingStep;
//
// Utils
//
const sectionCardContentStyle = { textAlign: 'center', padding: '6rem 1rem' };
const ScSuccessIcon = styled(MdCheckCircle) `
  color: var(--successColor-7);
  font-size: 5rem;
  margin: 0 0 1rem 0 !important;
`;
