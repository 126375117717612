import { fetchMessages } from '../actions';
const module = {
    processMessage: (store, message) => {
        switch (message.type) {
            case 'UPDATED_MESSAGES':
                store.dispatch(fetchMessages(message.payload.namespace, message.payload.id));
                break;
        }
        return true;
    }
};
export default module;
