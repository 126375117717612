import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { createItem } from '../data/actions';
import { invalidateItemsQuery } from './invalidateCache';
const useCreateItem = ({ definitionId }) => {
    const dispatch = useDispatch();
    const { language } = useI18n();
    return useMutation({
        mutationFn: async (item) => {
            return dispatch(createItem(definitionId, item, language));
        },
        onSettled: async (_, __, { entityId }) => {
            await invalidateItemsQuery({
                definitionId,
                waitNewData: true,
                ids: [entityId]
            });
        }
    });
};
export default useCreateItem;
