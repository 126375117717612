import { IP_INFO_URI } from 'config';
export const i18nChangeLanguage = (newLanguage) => ({
    type: 'I18N__CHANGE_LANGUAGE',
    payload: {
        language: newLanguage
    }
});
export const i18nChangeLanguageBySystem = (newLanguage) => ({
    type: 'I18N__CHANGE_LANGUAGE_BY_SYSTEM',
    payload: {
        language: newLanguage
    }
});
export const i18nChangeAvailableLanguages = (newLanguages) => ({
    type: 'I18N__CHANGE_AVAILABLE_LANGUAGES',
    payload: {
        languages: newLanguages
    }
});
export const retrieveCountryCode = () => ({
    type: 'I18N__RETRIEVE_COUNTRY_CODE',
    meta: {
        http: {
            path: IP_INFO_URI,
            method: 'GET'
        },
        auth: false
    }
});
