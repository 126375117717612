import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFilesData } from '../../../common/storage/actions';
import { getFileData, isFileDataAvailable, isFileDataPending } from '../../../common/storage/selectors';
import IntegrationLogo from './IntegrationLogo';
const IntegrationLogoContainer = ({ logoFileId, isFileDataAvailable, isFileDataPending, logoUrl, dispatch }) => {
    const fetchFileData = useCallback((fileId) => dispatch(fetchFilesData([fileId])), [dispatch]);
    useEffect(() => {
        if (!isFileDataAvailable) {
            fetchFileData(logoFileId);
        }
    }, [fetchFileData, isFileDataAvailable, logoFileId]);
    return React.createElement(IntegrationLogo, { loading: isFileDataPending, logoUrl: logoUrl });
};
function mapStateToProps(state, { logoFileId }) {
    return {
        isFileDataPending: isFileDataPending(state, logoFileId),
        isFileDataAvailable: isFileDataAvailable(state, logoFileId),
        logoUrl: get(getFileData(state, logoFileId), 'downloadUrl') || ''
    };
}
// @ts-expect-error
export default connect(mapStateToProps)(IntegrationLogoContainer);
