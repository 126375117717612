import { MultiSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchAllDefinitions, getAllDefinitions } from '@hypercharge/hyper-react-base/lib/metadata';
import { sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { getMetadataState } from '../../../common/reducers/rootSelector';
class DependantDefinitionsField extends Component {
    componentDidMount() {
        if (this.props.definitions.length === 0) {
            this.props.fetchAllDefinitions();
        }
    }
    render() {
        const { definitions, t, language, fieldName } = this.props;
        return (React.createElement(Field, { name: fieldName, label: t('DEFINITION_DEPENDANT_DEFINITIONS'), component: MultiSelect, options: definitions.map((prop) => ({
                value: prop.id,
                labels: prop.labels
            })), disabled: definitions.length == 0, emptyOption: true, emptyDisabled: false, language: language }));
    }
}
function mapStateToProps(state, { definitionId, propertyId, language }) {
    return {
        // $FlowFixMe
        definitions: sortBy(getAllDefinitions(getMetadataState(state)), (d) => d.labels[language])
    };
}
function mapDispatchToProps(dispatch, { definitionId }) {
    return {
        // @ts-expect-error
        fetchAllDefinitions: () => dispatch(fetchAllDefinitions(definitionId)) // Mgo, 18/05/2018: Should this argument be passed?
    };
}
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(DependantDefinitionsField);
