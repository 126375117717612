import { useMemo } from 'react';
import { useEntityItems } from './useEntityItems';
export const useEntityItem = (definitionId, entityId, responseFields, enabled) => {
    const { isAvailable, isPending, isFailed, data: items } = useEntityItems({
        definitionId,
        ids: entityId ? [entityId] : [],
        extraPayloadProps: {
            responseFields
        },
        enabled: !!entityId && enabled
    });
    return useMemo(() => ({
        data: items?.[0],
        isAvailable,
        isFetching: isPending,
        isError: isFailed
    }), [isAvailable, isFailed, isPending, items]);
};
