import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Table } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa';
import { IoCard, IoRadioButtonOff } from 'react-icons/io5';
import { useDeletePaymentMethod } from '../hooks/useDeletePaymentMethod';
import { useSearchPaymentCards } from '../hooks/useSearchPaymentCards';
import PaymentCardAddButton from './PaymentCardAddButton';
const PaymentCards = ({ defaultPaymentMethod, onChangeCustomer, onError, onSuccess }) => {
    const { t } = useI18n();
    const { mutateAsync: deletePaymentMethod, isLoading: isLoadingDelete } = useDeletePaymentMethod();
    const actionCardDelete = useCallback(async (paymentMethodId) => {
        try {
            await deletePaymentMethod(paymentMethodId);
            onSuccess(t('PAYMENT_CARD_DELETED_MESSAGE'));
        }
        catch (e) {
            onError(e);
        }
    }, [deletePaymentMethod, onSuccess, t, onError]);
    const actionCardDefault = useCallback((paymentMethodId) => {
        onChangeCustomer({ defaultPaymentMethod: paymentMethodId });
    }, [onChangeCustomer]);
    const columns = useMemo(() => [
        {
            dataIndex: 'last4',
            key: 'card',
            render: (last4) => (React.createElement(React.Fragment, null,
                React.createElement(IoCard, null),
                React.createElement(React.Fragment, null,
                    "**** **** **** ",
                    last4))),
            title: t('PAYMENT_CARD')
        },
        {
            key: 'brand',
            title: t('PAYMENT_CARD_SYSTEM'),
            dataIndex: 'brand'
        },
        {
            key: 'name',
            title: t('PAYMENT_CARD_CARDHOLDER_NAME'),
            dataIndex: 'name'
        },
        {
            key: 'expiration',
            title: t('PAYMENT_CARD_EXPIRATION'),
            render: (_, record) => `${record.expMonth}/${record.expYear}`
        },
        {
            key: 'actions',
            width: 150,
            render: (_, record) => (React.createElement("div", { className: "d-flex justify-content-around" },
                React.createElement(Button, { type: "text", disabled: record.id == defaultPaymentMethod, onClick: () => void actionCardDelete(record.id), loading: isLoadingDelete },
                    React.createElement(FaTrash, null)),
                React.createElement(Button, { type: "text", disabled: record.id == defaultPaymentMethod, onClick: () => actionCardDefault(record.id) },
                    React.createElement(IoRadioButtonOff, null))))
        }
    ], [actionCardDefault, actionCardDelete, defaultPaymentMethod, isLoadingDelete, t]);
    const { data: { results } = {}, isFetching } = useSearchPaymentCards();
    return (React.createElement("div", { className: "paymentCards" },
        React.createElement("div", { className: "titlePaymentCards" },
            t('PAYMENT_CARDS'),
            React.createElement(PaymentCardAddButton, { onError: onError, onSuccess: onSuccess })),
        React.createElement("div", { className: "tablePaymentCards" },
            React.createElement(Table, { columns: columns, dataSource: results, loading: isFetching, pagination: false }))));
};
export default PaymentCards;
