import { flatMap, reduce } from 'lodash-es';
import { ImportModes } from '../types';
import { getNumberFormatName } from '../utils';
export const fillDefaultValue = (settings, definition) => {
    const newPropertyMapping = reduce(flatMap(definition.data, 'values'), (result, { propertyId, type, meta }) => {
        const newPropertyMapping = (settings.propertyMappings &&
            settings.propertyMappings[propertyId]) || {
            csvColumnIndex: null
        };
        const mappingExists = settings.propertyMappings && settings.propertyMappings[propertyId];
        switch (type) {
            case 'date':
                newPropertyMapping.dateFormat = mappingExists
                    ? // @ts-expect-error
                        settings.propertyMappings[propertyId].dateFormat
                    : 'YYYY/MM/DD GMT';
                newPropertyMapping.timezone = mappingExists
                    ? // @ts-expect-error
                        settings.propertyMappings[propertyId].timezone
                    : 'Europe/London';
                break;
            case 'number':
                newPropertyMapping.numberFormat = mappingExists
                    ? getNumberFormatName(settings.propertyMappings[propertyId])
                    : 'dot-comma';
                break;
            case 'entity':
                if (meta && meta.list) {
                    newPropertyMapping.listSeparator = mappingExists
                        ? // @ts-expect-error
                            settings.propertyMappings[propertyId].listSeparator
                        : '|';
                }
                break;
            case 'file':
                if (meta && meta.list) {
                    newPropertyMapping.listSeparator = mappingExists
                        ? // @ts-expect-error
                            settings.propertyMappings[propertyId].listSeparator
                        : '|';
                }
                break;
            case 'multitext':
                newPropertyMapping.listSeparator = mappingExists
                    ? // @ts-expect-error
                        settings.propertyMappings[propertyId].listSeparator
                    : '|';
                break;
        }
        return { ...result, [propertyId]: newPropertyMapping };
    }, {});
    return {
        changeAllMatchingItems: true,
        // @ts-expect-error
        importMode: ImportModes.CREATE_UPDATE,
        ...settings,
        propertyMappings: newPropertyMapping
    };
};
