import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../../auth';
import FormPage from './form/FormPage';
import FormsListPage from './forms/FormsListPage';
const Routes = ({ match: { url } }) => (React.createElement(ErrorBoundary, null,
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: url, component: FormsListPage }),
        React.createElement(Route, { exact: true, path: `${url}/:formId`, component: FormPage }),
        React.createElement(Redirect, { to: url }))));
export default redirectIfNoPermission(Routes);
