import React, { useMemo } from 'react';
import styled from 'styled-components';
import NumberInput from './NumberInput';
import { ErrorMessage, InputContainer, LabelText, fieldCss } from './utils';
// need to research why we have the overflow
// hidden in the fieldCss
export const TextArea = styled.textarea `
  ${fieldCss};
  overflow: visible;
  min-width: 100%;
  max-width: 100%;
  min-height: 4rem;
  line-height: 1.3;
`;
export const Input = styled.input `
  ${fieldCss};
`;
export const StyledNumberInput = styled((props) => React.createElement(NumberInput, { ...props })) `
  ${fieldCss};
`;
export const BasicInput = ({ className, input, error, label, meta, onBlur, onChange, onFocus, type, value, ...otherProps }) => {
    const inputError = useMemo(() => error || (meta && (meta.touched || meta.submitFailed) && meta.error), [error, meta]);
    return (React.createElement(InputContainer, { className: className },
        label && React.createElement(LabelText, null, label),
        type == 'number' ? (React.createElement(StyledNumberInput, { ...input, ...otherProps, value: value !== undefined ? value : input?.value, invalid: !!inputError, onBlur: onBlur || input?.onBlur, onChange: onChange || input?.onChange, onFocus: onFocus || input?.onFocus })) : (React.createElement(Input, { ...input, ...otherProps, value: value !== undefined ? value : input?.value, invalid: !!inputError, onBlur: onBlur || input?.onBlur, onChange: onChange || input?.onChange, onFocus: onFocus || input?.onFocus, type: type })),
        !!inputError && React.createElement(ErrorMessage, null, inputError)));
};
export const BasicTextArea = ({ className, input, error, label, meta, value, ...otherProps }) => {
    const textAreaError = useMemo(() => error || (meta && (meta.touched || meta.submitFailed) && meta.error), [error, meta]);
    return (React.createElement(InputContainer, { className: className },
        label && React.createElement(LabelText, null, label),
        React.createElement(TextArea, { ...input, ...otherProps, value: value !== undefined ? value : input?.value, invalid: !!textAreaError }),
        !!textAreaError && React.createElement(ErrorMessage, null, textAreaError)));
};
export default BasicInput;
