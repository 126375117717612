import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { isEmpty, without } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CONTACT_GROUPS_PROPERTY_ID, ENTITY_ID_PROPERTY_ID, EntityEditor } from '../../../cms';
import { useHandleEntityItems } from '../../../cms/hooks/useEntityItems';
import { useUpdateProperty } from '../../../cms/hooks/useUpdateProperty';
import { DrawerContent, DrawerFooter } from '../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../common/components/Drawer/DrawerFormFooter';
import { CONTACT_CMS_DEFINITION_ID } from '../../../crm';
import { initialFilterRequest } from '../../../views/components/ViewContext';
export const UpdateGroupMembers = ({ updateType, group, onFinish, onCancel }) => {
    const { t } = useI18n();
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [saving, setSaving] = useState(false);
    const { handleFetch } = useHandleEntityItems();
    const dispatchNotification = useDispatch();
    const { mutateAsync: updateContactProperty } = useUpdateProperty({
        definitionId: CONTACT_CMS_DEFINITION_ID
    });
    const updateContactGroups = useCallback(async (contactId, value) => await updateContactProperty({
        entityId: contactId,
        propertyId: CONTACT_GROUPS_PROPERTY_ID,
        value
    }), [updateContactProperty]);
    const emitNotification = useCallback((type, config) => {
        const notification = type === 'success' ? success : error;
        dispatchNotification(notification(config));
    }, [dispatchNotification]);
    const handleSubmit = useCallback(async () => {
        if (isEmpty(selectedContactIds)) {
            onCancel();
        }
        else {
            const addMembers = updateType === 'add';
            setSaving(true);
            try {
                const response = await handleFetch({
                    ids: selectedContactIds,
                    definitionId: CONTACT_CMS_DEFINITION_ID,
                    extraPayloadProps: {
                        responseFields: [CONTACT_GROUPS_PROPERTY_ID]
                    }
                });
                if (!response) {
                    return;
                }
                await Promise.all(response.results.map(({ entityId, groups }) => updateContactGroups(entityId, addMembers
                    ? [...(groups ? groups : []), ...(group ? [group.entityId] : [])]
                    : without(groups, group.entityId))));
                emitNotification('success', {
                    title: t('COMMON__SUCCESS'),
                    message: t(addMembers ? 'GROUP__ADD_MEMBERS_SUCCESS' : 'GROUP__REMOVE_MEMBERS_SUCCESS')
                });
            }
            catch (e) {
                emitNotification('error', {
                    title: t('COMMON__FAILURE'),
                    message: t(addMembers ? 'GROUP__ADD_MEMBERS_ERROR' : 'GROUP__REMOVE_MEMBERS_ERROR')
                });
            }
            finally {
                setSaving(false);
                if (group) {
                    onFinish(group.entityId);
                }
            }
        }
    }, [
        emitNotification,
        group,
        handleFetch,
        onCancel,
        onFinish,
        selectedContactIds,
        t,
        updateContactGroups,
        updateType
    ]);
    const onSubmit = useCallback(async (e) => {
        e && e.preventDefault();
        await handleSubmit();
    }, [handleSubmit]);
    const checkKeyDown = useCallback(async (event) => {
        if (event.key === 'Enter') {
            await handleSubmit();
        }
    }, [handleSubmit]);
    const addMembers = updateType === 'add';
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, null,
            React.createElement(DrawerContentBlock, { title: t(addMembers
                    ? 'GROUP__ADD_MEMBERS_SELECT_CONTACTS_LABEL'
                    : 'GROUP__REMOVE_MEMBERS_SELECT_CONTACTS_LABEL') },
                React.createElement(EntityEditor, { onChange: (value) => setSelectedContactIds(value), value: selectedContactIds, meta: {
                        list: true,
                        definitionId: CONTACT_CMS_DEFINITION_ID
                    }, filterRequest: {
                        ...initialFilterRequest,
                        sortBy: [],
                        query: {
                            condition: ConditionType.and,
                            filters: [
                                {
                                    field: [CONTACT_GROUPS_PROPERTY_ID, ENTITY_ID_PROPERTY_ID].join('.'),
                                    operator: addMembers
                                        ? FilterOperatorTypes.notContains
                                        : FilterOperatorTypes.contains,
                                    data: group.entityId
                                }
                            ]
                        }
                    }, disabled: saving, onKeyDown: checkKeyDown, autoFocus: false }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onSubmit: onSubmit, onCancel: onCancel, submitting: saving, submitText: addMembers ? 'GROUP__ADD_MEMBERS' : 'GROUP__REMOVE_MEMBERS' }))));
};
