import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const ListContainer = styled.div `
  box-shadow: ${({ theme }) => theme.cardBoxShadow};
  border-radius: ${({ theme }) => theme.cardBorderRadius};
`;
export const Header = styled.div `
  border-top-left-radius: ${({ theme }) => theme.cardBorderRadius};
  border-top-right-radius: ${({ theme }) => theme.cardBorderRadius};
  border-bottom: 1px solid #d3dce0;
  background-color: ${({ theme }) => theme.mainColorSoft};
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
`;
export const HeaderCell = styled.div `
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 20px;
  vertical-align: middle;
`;
export const Row = styled(Link) `
  background-color: #fff;
  border-bottom: 1px solid #e5ebed;
  color: ${({ theme }) => theme.textColor} !important;

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: ${({ theme }) => theme.cardBorderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.cardBorderRadius};
  }

  &:hover {
    background-color: ${({ theme }) => theme.mainColorSoft};
  }
`;
export const Cell = styled.div `
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 20px;
  vertical-align: middle;
`;
export const InformationRow = styled.div `
  background-color: #fff;
  padding: 2rem 0;
  text-align: center;
  border-bottom: 1px solid #e5ebed;
  color: ${({ theme }) => theme.textColor};

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: ${({ theme }) => theme.cardBorderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.cardBorderRadius};
  }
`;
