import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio, Tooltip } from 'antd';
import React from 'react';
import { StorageIcon } from '../../../../../icons';
import { getPropertyId, getPropertyLabel, getReferenceDefinitionId } from '../../utils';
import styles from './RelatedProcessesRadio.module.scss';
export const RelatedProcessesRadio = ({ property, selectedProperty, setSelectedProperty, isLimitReached, parentGroupId, radioTitle, selectedPropertySource, groupSource }) => {
    const { language, t } = useI18n();
    const propertyId = property?.propertyId ?? parentGroupId;
    if (!propertyId) {
        return null;
    }
    const selectedPropertyId = getPropertyId(selectedProperty, false);
    const isPropertyList = groupSource === 'from' && property?.meta?.list;
    const disabledByInvalidReference = groupSource === 'to' &&
        getReferenceDefinitionId(selectedPropertySource) !== property?.meta?.definitionId;
    return (React.createElement("div", { className: `${styles.radioRow} mb-1`, key: propertyId },
        React.createElement(Radio, { checked: selectedPropertyId === propertyId, onChange: () => setSelectedProperty(property), disabled: isLimitReached || isPropertyList || disabledByInvalidReference },
            React.createElement(Tooltip, { title: isPropertyList ? t('RELATED_PROCESS_MODAL__LIST_WARNING') : '', placement: "right" },
                React.createElement("span", { className: `${styles.radioRow__label} lh-sm fw-500 d-flex align-content-center` },
                    React.createElement(StorageIcon, null),
                    React.createElement("span", null, property ? getPropertyLabel(property, language) : radioTitle || ''))))));
};
