import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import SortableList from '../../../../../common/components/SortableList';
import { FaPlus } from 'react-icons/fa';
import { useProperties } from '../../../../common/context/PropertiesProvider';
import SelectPropertyButton from './SelectPropertyButton';
const SortablePropertyList = ({ value, onChange, definitionId, disabled, includeProperty = defaultIncludeProperty, includeNestedProperties, sortable, extraAction, error }) => {
    const { t } = useI18n();
    const { getFieldLabel } = useProperties(definitionId);
    const draggableItems = useMemo(() => {
        if (!value) {
            return;
        }
        return value.map((item) => {
            return {
                id: item,
                item,
                label: getFieldLabel(item, true)
            };
        });
    }, [getFieldLabel, value]);
    const _onChange = useCallback((newDraggableItems) => {
        if (!onChange) {
            return;
        }
        onChange(newDraggableItems.map((item) => item.item));
    }, [onChange]);
    const addProperty = useCallback((val) => {
        if (!val || Array.isArray(val)) {
            return;
        }
        const newValue = [...(value || [])];
        newValue.push(val);
        onChange?.(newValue);
    }, [onChange, value]);
    const isOptionDisabled = useCallback((fieldPath) => {
        return !!value?.includes(fieldPath);
    }, [value]);
    return (React.createElement(React.Fragment, null,
        draggableItems && (React.createElement(SortableList, { value: draggableItems, onChange: _onChange, disabled: disabled, extraAction: extraAction, sortable: sortable })),
        React.createElement(SelectPropertyButton, { definitionId: definitionId, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('ADD_PROPERTY_TITLE')), onChange: addProperty, disabled: disabled, isFieldDisabled: isOptionDisabled, includeProperty: includeProperty, includeNestedProperties: includeNestedProperties, error: error })));
};
//
// Utils
//
const defaultIncludeProperty = (displayData) => supportedPropertyTypes.includes(displayData.type);
const supportedPropertyTypes = [
    'text',
    'date',
    'number',
    'select',
    'multitext',
    'checkbox',
    'entity',
    'richtext',
    'file',
    'link',
    'phoneNumber',
    'email',
    'json'
];
export default SortablePropertyList;
