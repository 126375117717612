import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { ShowItemsLimit } from '../../../../../common/components/ShowItemsLimit';
import FullTextSearch from '../../../../../common/components/data-table/FullTextSearch';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import { useView } from '../../../../../views/components/ViewContext';
import { MAX_KANBAN_ITEMS } from '../../../../../views/components/ViewProvider';
import MetricsDisplayContainer from '../../../../../views/components/metrics/MetricsDisplayContainer';
import { ViewTypes } from '../../../../../views/types';
import { SelectTaskType } from '../SelectTaskType';
import { TaskDashboardPageFilters } from '../TaskDashboardPageFilters';
import { TaskViewActions } from '../TaskViewActions';
import { TasksKanban } from '../TasksKanban';
import { TasksTableSize, TasksTableWrapper } from '../TasksTableWrapper';
const TaskDashboardContent = ({ viewActionsBaseUrl, kanbanBaseUrl, relatedToTitleKey, getCustomRouteHandleTaskClick, availableOn, addEmptyAssignedOption, headerRightBlock: HeaderRightBlock, componentTasksTableList: ComponentTasksTableList }) => {
    const { t } = useI18n();
    const { viewType, searchItems: { data: { totalCount = 0 } = {} } } = useView();
    const { isMobile } = useBrowserBreakpoints();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "DashboardContentHeader", className: "pb-3" },
            React.createElement("div", { className: "mx-3 p-2 rounded border bg-white d-flex align-items-center justify-content-between gap-3 flex-wrap" },
                React.createElement("div", { className: "d-flex align-items-center flex-wrap gap-2" },
                    React.createElement(TaskViewActions, { baseUrl: viewActionsBaseUrl, relatedToTitleKey: relatedToTitleKey, availableOn: availableOn, addEmptyAssignedOption: addEmptyAssignedOption }),
                    React.createElement(SelectTaskType, null),
                    React.createElement(FullTextSearch, { hotkeyFocusSearch: true }),
                    React.createElement(ShowItemsLimit, { show: totalCount > MAX_KANBAN_ITEMS, maxItemsCount: MAX_KANBAN_ITEMS })),
                HeaderRightBlock)),
        viewType === ViewTypes.table ? (React.createElement("div", { className: "container-lg p-0 mb-2 border rounded" },
            React.createElement(TaskDashboardPageFilters, { relatedToTitleKey: relatedToTitleKey, className: "pt-3 pb-2 px-3" }),
            React.createElement(MetricsDisplayContainer, { className: "px-3" }),
            React.createElement(TasksTableWrapper, null,
                React.createElement(ComponentTasksTableList, { getCustomRouteHandleTaskClick: getCustomRouteHandleTaskClick, emptyText: t('EMPTY_AVAILABLE_TASKS_TEXT'), size: isMobile ? TasksTableSize.small : TasksTableSize.large })))) : (React.createElement("div", { className: "px-3" },
            React.createElement(TaskDashboardPageFilters, { relatedToTitleKey: relatedToTitleKey }),
            React.createElement(TasksKanban, { getCustomRouteHandleCardClick: getCustomRouteHandleTaskClick, baseUrl: kanbanBaseUrl })))));
};
export default TaskDashboardContent;
