import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert, Modal, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
const modalBodyStyle = {
    padding: 0
};
const ConfirmationButtonModal = ({ className, tooltip, danger, disabled, confirmationTitle, confirmationMessage, confirmationWarningMessage, onClick, children }) => {
    const { t } = useI18n();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const _onClick = useCallback(() => {
        setShowConfirmation(true);
    }, []);
    const onCloseConfirmation = useCallback(() => {
        setShowConfirmation(false);
    }, []);
    const onClickConfirm = useCallback(async () => {
        onClick && (await onClick());
        setShowConfirmation(false);
    }, [onClick]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: tooltip, placement: "topRight" },
            React.createElement(Button, { className: `${className || ''} ${danger ? 'danger' : ''}`, inversed: danger ? undefined : true, onClick: _onClick, disabled: disabled }, children)),
        React.createElement(Modal, { centered: true, open: showConfirmation, title: confirmationTitle, onOk: onClickConfirm, onCancel: onCloseConfirmation, destroyOnClose: true, bodyStyle: modalBodyStyle, footer: [
                React.createElement(Button, { key: "no", inversed: true, onClick: onCloseConfirmation, className: "me-2" }, t('COMMON__NO')),
                React.createElement(Button, { key: "yes", onClick: onClickConfirm }, t('COMMON__YES'))
            ] },
            confirmationWarningMessage && (React.createElement(Alert, { message: confirmationWarningMessage, type: "warning", className: "px-4 border-top-0 border-end-0 border-start-0 rounded-0" })),
            React.createElement("div", { className: "p-4" }, confirmationMessage))));
};
export default ConfirmationButtonModal;
