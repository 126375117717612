import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FullScreenPreview from '../../../../common/components/full-screen-preview';
import LoadingIndicator from '../../../../common/components/loading-indicator';
import { getSubscriptionPlans } from '../actions';
const ChangeSubscriptionPlan = ({ currentPlan, onChange, onError, onClose }) => {
    const { t } = useI18n();
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const httpDispatch = useDispatch();
    useEffect(() => {
        httpDispatch(getSubscriptionPlans())
            .then((data) => {
            setPlans(data.sort((a, b) => (a.price?.amount || 0) - (b.price?.amount || 0)) || []);
        })
            .catch((e) => {
            onError(e);
        })
            .finally(() => {
            setLoading(false);
        });
    }, [httpDispatch, onError]);
    return (React.createElement(FullScreenPreview, { closePreview: onClose },
        loading ? React.createElement(LoadingIndicator, null) : null,
        React.createElement("div", { className: "changePlanContainer" }, plans.map((plan, index) => (React.createElement("div", { key: index, className: "changePlan" },
            React.createElement("div", { className: "titlePlan" }, plan.name),
            React.createElement("ul", { className: "descPlan" }, plan.description.split(',').map((line, index) => (React.createElement("li", { key: index }, line)))),
            React.createElement("div", { className: "pricePlan" },
                React.createElement("span", { className: "currency" }, "\u20AC"),
                React.createElement("span", { className: "amount" }, plan.price?.amount || 0),
                React.createElement("span", { className: "period" },
                    " /",
                    t('DATE_MONTH'))),
            currentPlan?.name == plan.name ? (React.createElement(Button, { className: "actionButton disabled" }, t('ACTIVE'))) : (React.createElement(Button, { className: "actionButton", onClick: () => onChange(plan.name) }, t('SUBSCRIPTION_PLAN_CHOOSE')))))))));
};
export default ChangeSubscriptionPlan;
