import React, { useMemo } from 'react';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { FieldWrapper } from '../../FieldElements';
import styles from './PhoneNumberDisplay.module.scss';
const PhoneNumberDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => {
    const content = useMemo(() => {
        let content = '\u00a0';
        if (value) {
            content = (React.createElement("span", { className: "invalid mw-100" },
                React.createElement(TooltipTruncate, null, value)));
            try {
                if (isValidPhoneNumber(value)) {
                    content = React.createElement(TooltipTruncate, null, formatPhoneNumberIntl(value));
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        return content;
    }, [value]);
    return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, className: `${styles.outer} ${className ?? ''}`, disabled: disabled, simple: !asField, style: style }, content));
};
export default PhoneNumberDisplay;
