import { Alert, Modal } from 'antd';
import React, { Component } from 'react';
import { withProps } from 'recompose';
import { compose } from 'redux';
import styled from 'styled-components';
import { useI18n } from '../../i18n';
import { Button } from '../button';
class ModalConfirmationButton extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            resolve: null,
            fail: null
        };
        this.resetPromise = () => {
            this.setState({
                resolve: null,
                fail: null
            });
        };
        this.handleConfirmed = () => {
            const { resolve, fail } = this.state;
            this.resetPromise();
            return this.props.onClick().then(() => {
                // @ts-ignore
                resolve?.();
            }, () => {
                // @ts-ignore
                fail?.();
            });
        };
        this.closeModal = () => {
            // @ts-ignore
            this.state.resolve?.();
            this.resetPromise();
        };
        this.openModal = () => new Promise((resolve, fail) => {
            this.setState({
                resolve,
                fail
            });
        });
    }
    render() {
        const { className, disabled, children, confirmationTitle, warningMessage, confirmationMessage, modalProps = {}, destructive, t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(ScButton, { className: className, destructive: destructive, onClick: this.openModal, loading: this.props.loading, disabled: disabled }, children),
            React.createElement(Modal, { open: !!this.state.resolve, title: confirmationTitle, onOk: this.handleConfirmed, onCancel: this.closeModal, bodyStyle: {
                    display: confirmationMessage || warningMessage ? 'block' : 'none',
                    padding: '0'
                }, footer: [
                    React.createElement(Button, { key: "no", inversed: true, onClick: this.closeModal }, t('COMMON__NO')),
                    React.createElement(Button, { key: "yes", onClick: this.handleConfirmed }, t('COMMON__YES'))
                ], ...modalProps },
                warningMessage && React.createElement(ScAlert, { message: warningMessage, type: "warning" }),
                React.createElement(ScConfirmationMessage, null, confirmationMessage))));
    }
}
export default compose(withProps(() => {
    const { t } = useI18n();
    return { t };
}))(ModalConfirmationButton);
//
// Utils
//
const ScButton = styled((props) => React.createElement(Button, { ...props })) `
  background: ${({ destructive }) => destructive && '#e00000'};
  border-color: ${({ destructive }) => destructive && '#e00000'};

  &:hover {
    background: ${({ destructive }) => destructive && 'red'};
    border-color: ${({ destructive }) => destructive && 'red'};
  }
`;
const ScAlert = styled(Alert) `
  &&& {
    padding: 0.5rem 1.5rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
`;
const ScConfirmationMessage = styled.div `
  padding: 1.5rem;
`;
