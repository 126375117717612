import { BasicSelect, ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchAllOptionGroups, getAllOptionGroups } from '@hypercharge/hyper-react-base/lib/metadata';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '../../../../../../common/components/Toggle';
import { getMetadataState } from '../../../../../../common/reducers/rootSelector';
const SelectField = ({ disabled, value, error, onChange }) => {
    const { t, language } = useI18n();
    const dispatchFetchAllOptionGroups = useDispatch();
    const optionGroups = useSelector((state) => getAllOptionGroups(getMetadataState(state)).map((option) => ({
        value: option.groupId,
        labels: { [language]: option.groupId }
    })));
    useEffect(() => {
        if (optionGroups.length === 0) {
            void dispatchFetchAllOptionGroups(fetchAllOptionGroups());
        }
    }, [dispatchFetchAllOptionGroups, optionGroups.length]);
    const onChangeOptionId = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                source: event.target.value
            }
        });
    }, [onChange, value]);
    const onChangeList = useCallback((checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                list: checked
            }
        });
    }, [onChange, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BasicSelect, { label: t('SOURCE'), options: optionGroups, language: language, disabled: disabled || !value.isNew, value: value?.meta.source, onChange: onChangeOptionId }),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('OPTIONS_LIST'), disabled: disabled || !value.isNew, onChange: onChangeList, checked: value?.meta.list }),
        !!error && React.createElement(ErrorMessage, null, error)));
};
export default SelectField;
