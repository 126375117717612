import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { get } from 'lodash';
import React from 'react';
import { Prompt } from 'react-router';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { ComputedExpressionsDocumentationLink } from '../../../common/components/documentation-link';
import { FieldsContainer } from '../../../common/utils/formUtils';
import { Content } from '../../../common/utils/stylingUtils';
import { FormButtons } from '../common/components/FormButtons';
import { NotificationBlock } from '../common/components/NotificationBlock';
import { Form, FormContent, FormSectionTitle, StyledSectionCard, styledSectionCardHeaderStyle } from '../common/stylingUtils';
import MappingsField from './MappingsField';
import { xdmsFields } from './utils';
const ComputedExpressionsLinkContainer = styled.span `
  font-size: 0.9rem;
  font-weight: 400;
`;
const ComputedExpressionsDocumentation = () => (React.createElement(ComputedExpressionsLinkContainer, null,
    ' ',
    "(",
    React.createElement(ComputedExpressionsDocumentationLink, null),
    ")"));
const SettingsForm = ({ contactMetaDefinition, organizationMetaDefinition, vehicleMetaDefinition, isDataPending, submitting, dirty, handleSubmit }) => {
    const { t, language } = useI18n();
    return (React.createElement(Form, { onSubmit: void handleSubmit, noValidate: true },
        React.createElement(FormContent, { className: "cp-c-padding-3" },
            React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                React.createElement(Field, { name: "host", label: t('XDMS_HOST'), disabled: submitting || isDataPending, component: BasicInput }),
                React.createElement(Field, { name: "user", label: t('XDMS_USER'), disabled: submitting || isDataPending, component: BasicInput }),
                React.createElement(Field, { name: "pass", label: t('XDMS_PASSWORD'), disabled: submitting || isDataPending, type: "password", component: BasicInput }),
                React.createElement(Field, { name: "exportFolderPath", label: t('XDMS_EXPORT_FOLDER_PATH'), disabled: submitting || isDataPending, component: BasicInput }),
                React.createElement(FormSectionTitle, null,
                    t('XDMS_CONTACT'),
                    React.createElement(ComputedExpressionsDocumentation, null)),
                React.createElement(Field, { name: "mappings.contact.toXdms", mappingOptions: xdmsFields.contact.map((id) => ({ id, label: id })), component: MappingsField, disabled: submitting || isDataPending }),
                React.createElement(FormSectionTitle, null,
                    t('CMS_CONTACT'),
                    React.createElement(ComputedExpressionsDocumentation, null)),
                React.createElement(Field, { name: "mappings.contact.fromXdms", mappingOptions: get(contactMetaDefinition, 'sections', []).map(({ id, titles, properties }) => ({
                        id,
                        label: getTranslation(titles, language),
                        children: properties.map(({ propertyId, labels }) => ({
                            id: propertyId,
                            label: getTranslation(labels, language)
                        }))
                    })), component: MappingsField, disabled: submitting || isDataPending }),
                React.createElement(FormSectionTitle, null,
                    t('XDMS_ORGANIZATION'),
                    React.createElement(ComputedExpressionsDocumentation, null)),
                React.createElement(Field, { name: "mappings.organization.toXdms", mappingOptions: xdmsFields.organization.map((id) => ({ id, label: id })), component: MappingsField, disabled: submitting || isDataPending }),
                React.createElement(FormSectionTitle, null,
                    t('CMS_ORGANIZATION'),
                    React.createElement(ComputedExpressionsDocumentation, null)),
                React.createElement(Field, { name: "mappings.organization.fromXdms", mappingOptions: get(organizationMetaDefinition, 'sections', []).map(({ id, titles, properties }) => ({
                        id,
                        label: getTranslation(titles, language),
                        children: properties.map(({ propertyId, labels }) => ({
                            id: propertyId,
                            label: getTranslation(labels, language)
                        }))
                    })), component: MappingsField, disabled: submitting || isDataPending }),
                React.createElement(FormSectionTitle, null,
                    t('CMS_VEHICLE'),
                    React.createElement(ComputedExpressionsDocumentation, null)),
                React.createElement(Field, { name: "mappings.vehicle.fromXdms", mappingOptions: get(vehicleMetaDefinition, 'sections', []).map(({ id, titles, properties }) => ({
                        id,
                        label: getTranslation(titles, language),
                        children: properties.map(({ propertyId, labels }) => ({
                            id: propertyId,
                            label: getTranslation(labels, language)
                        }))
                    })), component: MappingsField, disabled: submitting || isDataPending }))),
        React.createElement(Prompt, { when: dirty && !submitting, message: t('UNSAVED_DATA_CONFIRM') })));
};
const XdmsSettingsForm = ({ contentHeight, dirty, handleSubmit, reset, submitting, config, onFormSubmit }) => {
    const isDataPending = config.isConfigPending ||
        config.contactMetaDefinitionStatus.isPending ||
        config.organizationMetaDefinitionStatus.isPending ||
        config.vehicleMetaDefinitionStatus.isPending;
    const hasDataFetchFailed = config.isConfigFailed ||
        config.contactMetaDefinitionStatus.isFailed ||
        config.organizationMetaDefinitionStatus.isFailed;
    const { contactMetaDefinition, organizationMetaDefinition, vehicleMetaDefinition } = config;
    return (React.createElement(StyledSectionCard, { title: "XDMS", secondaryTitle: !hasDataFetchFailed &&
            !isDataPending && (React.createElement(FormButtons, { dirty: dirty, 
            // Remove ignoring error when component is rewritten with formik instead of redux-form.
            // @ts-expect-error
            onSubmit: handleSubmit(onFormSubmit), onReset: reset, submitting: submitting, disabled: submitting || isDataPending })), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true, loading: isDataPending },
        React.createElement(Content, { height: contentHeight }, hasDataFetchFailed ? (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })) : (!isDataPending &&
            (get(contactMetaDefinition, 'sections') && get(organizationMetaDefinition, 'sections') ? (React.createElement(SettingsForm, { isDataPending: isDataPending, submitting: submitting, dirty: dirty, 
                // @ts-expect-error
                handleSubmit: handleSubmit(onFormSubmit), contactMetaDefinition: contactMetaDefinition, organizationMetaDefinition: organizationMetaDefinition, vehicleMetaDefinition: vehicleMetaDefinition })) : (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })))))));
};
export default XdmsSettingsForm;
