import { isObject } from 'lodash';
import { PERMISSIONS_PROPERTY_ID } from '../cms/common/utils/constants';
export const isPersistStateT = (data) => {
    return isObject(data) && 'i18n' in data && 'auth' in data && '_persist' in data;
};
export var PropertyTypes;
(function (PropertyTypes) {
    PropertyTypes["checkbox"] = "checkbox";
    PropertyTypes["date"] = "date";
    PropertyTypes["email"] = "email";
    PropertyTypes["entity"] = "entity";
    PropertyTypes["file"] = "file";
    PropertyTypes["json"] = "json";
    PropertyTypes["link"] = "link";
    PropertyTypes["multitext"] = "multitext";
    PropertyTypes["number"] = "number";
    PropertyTypes["phoneNumber"] = "phoneNumber";
    PropertyTypes["richtext"] = "richtext";
    PropertyTypes["select"] = "select";
    PropertyTypes["text"] = "text";
})(PropertyTypes || (PropertyTypes = {}));
export var AvailableHandlers;
(function (AvailableHandlers) {
    AvailableHandlers["cms"] = "cms";
    AvailableHandlers["workflows"] = "workflows";
})(AvailableHandlers || (AvailableHandlers = {}));
export function isMetaDefinition(data) {
    if (data && 'sections' in data && data.sections && Array.isArray(data.sections)) {
        return true;
    }
    return false;
}
export function isRichTextValue(value) {
    return value && typeof value === 'object' && 'ops' in value;
}
