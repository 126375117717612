import { useEffect, useRef, useState } from 'react';
const useElementSize = () => {
    const ref = useRef(null);
    const [size, setSize] = useState({
        width: 0,
        height: 0
    });
    useEffect(() => {
        const element = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            if (!entries?.length) {
                return;
            }
            const entry = entries[0];
            if (size.width !== entry.contentRect.width || size.height !== entry.contentRect.height) {
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });
        if (element) {
            resizeObserver.observe(element);
        }
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, [ref, size.height, size.width]);
    return {
        size,
        ref
    };
};
export default useElementSize;
