import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { oneHourInMs } from '../common/utils/react-query-client';
import { fetchDisplayTenant } from './actions';
const DISPLAY_TENANT_KEY = 'display-tenant';
export const useDisplayTenant = () => {
    const dispatch = useDispatch();
    const { data: displayTenant, isError, isLoading } = useQuery([DISPLAY_TENANT_KEY], () => {
        return dispatch(fetchDisplayTenant());
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    return {
        displayTenant,
        tenantTheme: displayTenant?.theme || {},
        isAvailable: !!displayTenant,
        isPending: isLoading,
        isFailed: isError
    };
};
