import { ConditionType, SortOrder } from '@hypercharge/portal-utils';
export const DEFAULT_FILTERS_CONDITION = ConditionType.and;
export const CONDITION_QUERY_KEY = 'conditionQuery';
export const URL_STRINGIFY_SEPARATOR = '--';
export const SORT_BY_KEY = 'sortBy';
export const ROW_HEIGHT = 'rowHeight';
export const ROW_GROUPING = 'rowGrouping';
export const ROW_GROUPING_ORDER_BY = 'rowGroupingOrderBy';
export const LIMIT_BY_KEY = 'limit';
export const FULL_TEXT = 'fullText';
export const LANGUAGE_CODE = 'languageCode';
export const CONTEXT_CODE = 'context';
export const COLUMNS = 'columns';
export const VIEW_TYPE_BY_KEY = 'viewType';
export const METRICS_KEY = 'metrics';
export const KANBAN_KEY = 'kanban';
export const DEFAULT_SORT_BY_FIELDS_VIEW = [{ field: 'title', order: SortOrder.asc }];
export const DEFAULT_COLUMNS_VIEW = ['title'];
export const VIEW_DEFINITION_ID = 'view';
export const VIEW_RESPONSE_FIELDS = [
    'entityOwner',
    'title',
    'isDefault',
    'referenceDefinitionId',
    'filters',
    'metrics',
    'viewType',
    'configuration',
    KANBAN_KEY
];
