import { Input } from 'antd';
import React, { useMemo } from 'react';
import { ErrorLabel, getIsFormHasError } from '../../utils/formUtils';
const { TextArea } = Input;
const AntTextArea = (props) => {
    const error = useMemo(() => (getIsFormHasError(props?.meta) ? props?.meta?.error : props.error), [props.error, props.meta]);
    return (React.createElement("div", { className: "w-100 d-flex flex-column align-items-stretch position-relative" },
        React.createElement(TextArea, { autoSize: { minRows: 3 }, status: error ? 'warning' : undefined, ...props }),
        React.createElement(ErrorLabel, { show: !!error, position: 'absolute', error: error })));
};
export default AntTextArea;
