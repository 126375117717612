import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import FormikField from '../../../../common/components/formik/FormikField';
import { VEHICLE_CMS_DEFINITION_ID } from '../../../../crm';
import { ViewTypes } from '../../../../views/types';
import { PropertySelector } from '../components/PropertySelector';
import { getFacetsFormSchema } from './website/FacetsForm';
import FacetsAggregationSelector from './website/components/FacetsAggregationSelector';
export const IntranetInitialValues = {
    reservationDuration: 2,
    stockFilters: [],
    specificationList: []
};
export const getIntranetFormSchema = () => {
    return Yup.object().shape({
        reservationDuration: Yup.number().integer().min(1),
        stockFilters: getFacetsFormSchema(),
        specificationList: Yup.array()
            .of(Yup.object().shape({ propertyId: Yup.string() }))
            .optional()
    });
};
const Form = ({ instance, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(FormikField, { name: "configuration.intranet.reservationDuration", component: BasicInput, label: `${t('INTEGRATIONS_INTRANET_RESERVATION_DURATION')} *`, value: instance.intranet && instance.intranet.reservationDuration, disabled: disabled }),
        React.createElement(LabelText, null, t('APPS_HYPER_DEALER__STOCK_FILTERS')),
        React.createElement(FormikField, { name: "configuration.intranet.stockFilters", component: FacetsAggregationSelector, definitionId: VEHICLE_CMS_DEFINITION_ID, viewType: ViewTypes.table }),
        React.createElement(LabelText, null, t('APPS_HYPER_DEALER__SPECIFICATION_LIST')),
        React.createElement(FormikField, { name: "configuration.intranet.specificationList", component: PropertySelector, definitionId: VEHICLE_CMS_DEFINITION_ID, viewType: ViewTypes.table, valueAdapter: (property) => ({ propertyId: property.propertyId }) })));
};
export default Form;
