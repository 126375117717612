import { getTranslation } from '@hypercharge/hyper-react-base/lib/i18n';
import memoize from 'fast-memoize';
import { compact, find, isEmpty, uniq } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import TableInlineEditor from '../../../../common/components/data-table/TableInlineEditor';
import { StyledLink } from '../../../../common/components/data-table/utils';
import { PropertyTypes } from '../../../../common/types';
import { supportedColumnPropertyTypes, supportedSortByPropertyTypes } from '../../../../views';
import { RowHeightT } from '../../../../views/types';
import { isTextDisplayData } from '../../types';
import { getFlattenedDisplayDataList } from '../../utils';
import { GetColumnHeader } from './GetColumnHeader';
export const EditLink = styled(StyledLink) `
  padding-right: 0.5rem;

  svg {
    width: 16px;
    height: 16px;
  }
`;
const renderCell = (row, meta, type, rowHeightType, canEdit, invalid, onChangeProperty) => {
    if (supportedColumnPropertyTypes.includes(type)) {
        const value = row.getValue();
        return (React.createElement(TableInlineEditor, { meta: meta, value: value, rowHeightType: rowHeightType, cell: row, type: type, canEdit: canEdit, invalid: !(value || value === 0) && invalid, onChangeProperty: onChangeProperty }));
    }
    return React.createElement(React.Fragment, null, row.getValue());
};
export const AVAILABLE_GROUPING_ROWS = [
    PropertyTypes.text,
    PropertyTypes.entity,
    PropertyTypes.email
];
export const getItemsTableColumns = (key, // Added to prevent memoize from caching between standard and featured tables of same entity
columnPropertyIds, viewableEntityProperties, language, itemLinkColumn, rowHeightType = RowHeightT.SMALL, nestedRequiredFieldsConfig, onChangeProperty, permissionFieldsConfig) => {
    const columns = compact(columnPropertyIds.map((propertyId) => find(viewableEntityProperties, { propertyId }))).map((field) => {
        const { propertyId, labels, meta, type, canEdit = false } = field;
        const isErrorField = nestedRequiredFieldsConfig
            ? nestedRequiredFieldsConfig?.isErrorField?.(propertyId)
            : false;
        return {
            id: propertyId,
            accessorKey: propertyId,
            header: () => (React.createElement(GetColumnHeader, { labels: labels, language: language, type: type, propertyId: propertyId, meta: meta, required: nestedRequiredFieldsConfig
                    ? nestedRequiredFieldsConfig?.isRequiredField?.(propertyId)
                    : false, isUnfilledRequiredField: isErrorField })),
            maxWidth: 400,
            minSize: 150,
            sortable: supportedSortByPropertyTypes.includes(type),
            cell: (cell) => {
                return renderCell(cell, meta, type, rowHeightType, canEdit || !!permissionFieldsConfig?.isCanEditField(field), isErrorField, onChangeProperty);
            }
        };
    });
    return columns.length > 0 ? [itemLinkColumn, ...columns] : [];
};
export const memoizedGetItemsTableColumns = memoize(getItemsTableColumns);
const getItemsKanbanFields = (key, // Added to prevent memoize from caching between standard and featured tables of same entity
fieldPropertyIds, viewableEntityProperties, language) => {
    const fields = compact(fieldPropertyIds.map((propertyId) => find(viewableEntityProperties, { propertyId }))).map(({ propertyId, labels, type, meta }) => ({
        id: propertyId,
        label: getTranslation(labels, language),
        type: supportedColumnPropertyTypes.includes(type) ? type : undefined,
        meta
    }));
    return fields.length ? fields : [];
};
export const getPropertyIdsForConditionalFormatting = (displayDataWithHidden, responseFields) => {
    const ids = [];
    getFlattenedDisplayDataList(displayDataWithHidden).forEach((property) => {
        if (responseFields?.includes(property.propertyId) &&
            !isEmpty(property.meta.conditionalFormatting)) {
            property.meta.conditionalFormatting?.forEach((conditional) => {
                ids.push(conditional?.filter?.field.replace('.entityId', ''));
            });
        }
    });
    return uniq(ids);
};
export const getPropertyIdsForTranslations = (displayDataWithHidden, responseFields, language) => {
    const ids = responseFields || [];
    getFlattenedDisplayDataList(displayDataWithHidden).forEach((property) => {
        if (responseFields?.includes(property.propertyId) &&
            isTextDisplayData(property) &&
            property.meta.translations?.[language]) {
            ids.push(property.meta.translations[language]);
        }
    });
    return uniq(ids);
};
export const memoizedGetItemsKanbanFields = memoize(getItemsKanbanFields);
