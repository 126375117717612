import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Input, Modal } from 'antd';
import { DEFAULT_LANGUAGE } from 'config';
import memoize from 'fast-memoize';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { hasTenantPermission } from '../../../../../auth';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import { ACTIVITY_DEFINITION_ID } from '../../../../../common/activity';
import { MultiLanguageInput } from '../../../../../common/components/MultiLanguageField';
import { MultiLanguageOptionalValidationSchema } from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { Select } from '../../../../../common/components/Select';
import FormikError from '../../../../../common/components/formik/FormikError';
import FormikField from '../../../../../common/components/formik/FormikField';
import ViewSelector from '../../../../../views/components/ViewSelector';
import { ALL_TASKS_DEFINITION_ID, REQUIRED_WORKFLOWS_PERMISSION } from '../../../../../workflows';
import { uniqViewIdActivityTaskDashboard, uniqViewIdWorkflowsTaskDashboard } from '../../../../../workflows/dashboard/constants';
import { Widget } from '../Widget';
import styles from './EditYourTasksForm.module.scss';
import YourTasksWidget from './YourTasksWidget';
const validationSchema = Yup.object({
    type: Yup.string().default('your-tasks').required('Required'),
    id: Yup.string().required('Required'),
    title: MultiLanguageOptionalValidationSchema,
    layout: Yup.object({
        i: Yup.string().required('Required'),
        x: Yup.number().default(0).required('Required'),
        y: Yup.number().default(0).required('Required'),
        w: Yup.number().default(3).required('Required'),
        h: Yup.number().default(6).required('Required')
    }),
    definitionId: Yup.string().required('Required'),
    viewId: Yup.string().nullable()
});
export function getDefaultYourTasksSettings() {
    const id = generateId();
    return {
        type: 'your-tasks',
        id: id,
        definitionId: '',
        title: { [DEFAULT_LANGUAGE]: '' },
        layout: {
            i: id,
            x: 0,
            y: 0,
            w: 5,
            h: 6
        }
    };
}
const memoizedCastSettings = memoize((settings) => {
    return validationSchema.cast(settings, {
        stripUnknown: true,
        context: settings
    });
});
const EditYourTasksForm = ({ title, value, open, onCancel, onOk }) => {
    const { t } = useI18n();
    const hasWorkflowsPermission = useSelector((s) => hasTenantPermission(s, REQUIRED_WORKFLOWS_PERMISSION));
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const _onCancel = useCallback(() => {
        onCancel?.();
    }, [onCancel]);
    const onSave = useCallback((data) => {
        const stripedData = memoizedCastSettings(data);
        onOk(stripedData);
    }, [onOk]);
    const validate = useCallback((value) => {
        try {
            void validateYupSchema(value, validationSchema, true, value);
        }
        catch (err) {
            // for rendering validation errors
            return yupToFormErrors(err);
        }
        return {};
    }, []);
    const optionsTaskType = useMemo(() => {
        const optionsTaskType = [];
        if (displayItemMetaList.findIndex((item) => item.definitionId === ACTIVITY_DEFINITION_ID) > -1) {
            optionsTaskType.push({
                value: ACTIVITY_DEFINITION_ID,
                label: t('ACTIVITES_NAV_LINK')
            });
        }
        if (hasWorkflowsPermission) {
            optionsTaskType.push({
                value: ALL_TASKS_DEFINITION_ID,
                label: t('WORKFLOWS_NAV_LINK')
            });
        }
        return optionsTaskType;
    }, [displayItemMetaList, hasWorkflowsPermission, t]);
    return (React.createElement(Formik, { initialValues: value || getDefaultYourTasksSettings(), validateOnBlur: true, validateOnChange: false, onSubmit: onSave, validate: validate }, ({ values, handleSubmit, setFieldValue }) => {
        return (React.createElement(Modal, { destroyOnClose: true, width: "80%", centered: true, open: open, title: title, onCancel: _onCancel, onOk: () => handleSubmit() },
            React.createElement("div", { className: `${styles.wrapper} row w-100` },
                React.createElement("div", { className: "col-8" },
                    React.createElement(Widget, { className: "widget-wrapper", showControlButtons: false },
                        React.createElement(YourTasksWidget, { settings: values }))),
                React.createElement("div", { className: "col-4" },
                    React.createElement(FormikField, { name: "title", label: t('HYPER_BI_TITLES'), component: MultiLanguageInput, fieldComponent: Input }),
                    React.createElement(LabelText, { className: "pt-2" }, t('YOUR_TASKS_TASK_TYPE')),
                    React.createElement(FormikField, { name: "definitionId", component: Select, options: optionsTaskType, onChange: (value) => {
                            void setFieldValue('definitionId', value);
                            void setFieldValue('viewId', undefined);
                        } }),
                    React.createElement(FormikField, { name: "viewId", label: t('HYPER_BI_VIEW'), component: ViewSelector, availableOn: values.definitionId === ALL_TASKS_DEFINITION_ID
                            ? uniqViewIdWorkflowsTaskDashboard
                            : values.definitionId === ACTIVITY_DEFINITION_ID
                                ? uniqViewIdActivityTaskDashboard
                                : undefined, definitionId: values.definitionId, disabled: !values.definitionId, className: "pt-2" }),
                    React.createElement(FormikError, { name: "viewId" })))));
    }));
};
export default EditYourTasksForm;
