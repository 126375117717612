import * as d3 from 'd3';
import { get, maxBy, minBy, pick, set, toNumber, uniqBy } from 'lodash';
import moment from 'moment';
import { AggregationType, getAggregationId } from '../../../../../cms/hooks/useAggregation';
import { getSubAggregation } from '../../aggregations';
import { MetricsOptions } from '../../metrics';
import { aggregationToFilters, getAggregationValuesMap, getAllSubAggregationsValuesMap, getDateHistogramTimeFormat, getDateHistogramTimeMomentFormat, getLegendWidth, getMetricValuePath } from './chartUtils';
function toPieData(aggregationResult, chartSettings) {
    const result = { data: [], names: {}, keys: { value: [] } };
    if (!Array.isArray(aggregationResult)) {
        return result;
    }
    const index = 0;
    const aggregation = chartSettings.aggregations[index];
    const valuePath = getMetricValuePath(chartSettings.metrics[index]);
    result.data = aggregationResult.map((aggregationResultItem) => {
        if (aggregation.type === AggregationType.dateHistogram) {
            return {
                ...aggregationResultItem,
                label: moment(aggregationResultItem.value).format(getDateHistogramTimeMomentFormat(aggregation))
            };
        }
        return aggregationResultItem;
    });
    result.keys.value = [valuePath];
    result.names[valuePath] = 'label';
    return result;
}
function toLineData(aggregationResult, chartSettings, language) {
    const result = {
        data: [],
        names: {},
        keys: {
            x: undefined,
            value: []
        }
    };
    if (!Array.isArray(aggregationResult)) {
        return result;
    }
    const index = 0;
    const aggregation = chartSettings.aggregations[index];
    if (aggregation.type === AggregationType.dateHistogram) {
        result.keys.x = 'value';
    }
    else {
        result.keys.x = 'label';
    }
    const subAggregation = getSubAggregation(aggregation);
    if (subAggregation) {
        const subAggregationId = getAggregationId(subAggregation);
        // get all sub aggregations values
        const allUniqValuesMap = getAllSubAggregationsValuesMap(aggregationResult, subAggregationId);
        const allUniqValues = Object.keys(allUniqValuesMap);
        result.data = aggregationResult.map((aggregationResultItem) => {
            const subAggregationValuesMap = getAggregationValuesMap(aggregationResultItem[subAggregationId]);
            /**
             * sometimes in one bucket there may not be all terms, this code pads such terms with zeros
             */
            for (const termValue of allUniqValues) {
                if (!subAggregationValuesMap[termValue]) {
                    subAggregationValuesMap[termValue] = {
                        value: allUniqValuesMap[termValue].value,
                        label: allUniqValuesMap[termValue].label,
                        count: 0
                    };
                    for (const metricSettings of chartSettings.metrics) {
                        const valuePath = getMetricValuePath(metricSettings);
                        set(subAggregationValuesMap[termValue], valuePath, 0);
                    }
                }
            }
            return {
                ...aggregationResultItem,
                [subAggregationId]: subAggregationValuesMap
            };
        });
        for (const metricSettings of chartSettings.metrics) {
            const metricDescription = MetricsOptions.find((item) => item.value === metricSettings.type);
            const metricName = metricDescription?.labels[language] || metricSettings.type;
            const valuePath = getMetricValuePath(metricSettings);
            for (const termKey of allUniqValues) {
                const dataKey = `${subAggregationId}.${termKey}.${valuePath}`;
                result.keys.value.push(dataKey);
                let formattedValue = '';
                if (subAggregation.type === AggregationType.dateHistogram) {
                    formattedValue = moment(allUniqValuesMap[termKey].value).format(getDateHistogramTimeMomentFormat(subAggregation));
                }
                else {
                    formattedValue = allUniqValuesMap[termKey].label.toString();
                }
                result.names[dataKey] = `${formattedValue}: ${metricName}`;
            }
        }
    }
    else {
        result.data = aggregationResult;
        for (const metricSettings of chartSettings.metrics) {
            const metricDescription = MetricsOptions.find((item) => item.value === metricSettings.type);
            const metricName = metricDescription?.labels[language] || metricSettings.type;
            const valuePath = getMetricValuePath(metricSettings);
            result.keys.value.push(valuePath);
            result.names[valuePath] = metricName;
        }
    }
    return result;
}
function toHeatMapData(aggregationResult, chartSettings, language) {
    const result = {
        data: [],
        names: {},
        keys: {
            x: undefined,
            value: []
        }
    };
    if (!Array.isArray(aggregationResult)) {
        return result;
    }
    const index = 0;
    const aggregation = chartSettings.aggregations[index];
    const subAggregation = getSubAggregation(aggregation);
    if (!subAggregation) {
        return result;
    }
    const subAggregationId = getAggregationId(subAggregation);
    const valuePath = getMetricValuePath(chartSettings.metrics[index]);
    result.data = aggregationResult.map((aggregationResultItem) => {
        let newAggregationResultItem = { ...aggregationResultItem };
        let subAggregationData = aggregationResultItem[subAggregationId]?.length
            ? [...aggregationResultItem[subAggregationId]]
            : [];
        if (aggregation.type === AggregationType.dateHistogram) {
            newAggregationResultItem = {
                ...newAggregationResultItem,
                label: moment(aggregationResultItem.value).format(getDateHistogramTimeMomentFormat(aggregation))
            };
        }
        if (subAggregation.type === AggregationType.dateHistogram) {
            subAggregationData = subAggregationData.map((item) => ({
                ...item,
                label: moment(item.value).format(getDateHistogramTimeMomentFormat(subAggregation))
            }));
        }
        return {
            ...newAggregationResultItem,
            [subAggregationId]: subAggregationData
        };
    });
    result.keys.value = [valuePath];
    result.names[valuePath] = 'label';
    return result;
}
export function getPieProps(chartSettings, data, language) {
    if (!(chartSettings.chart.type === 'pie' || chartSettings.chart.type === 'donut')) {
        return;
    }
    if (!chartSettings.aggregations.length) {
        return;
    }
    const aggregation = chartSettings.aggregations[0];
    const aggregationId = getAggregationId(aggregation);
    const aggregationResult = data.aggregations[aggregationId];
    const pieData = toPieData(aggregationResult, chartSettings);
    if (!pieData.data.length) {
        return;
    }
    const valuePath = pieData.keys.value[0];
    const labelPath = pieData.names[valuePath];
    const legendWidth = getLegendWidth(pieData.data.map((item) => item.label.toString()));
    const props = {
        data: pieData.data,
        colors: { scheme: 'nivo' },
        id: labelPath,
        value: valuePath,
        margin: { top: 10, right: legendWidth, bottom: 10, left: 10 },
        innerRadius: chartSettings.chart.type === 'donut' ? 0.6 : 0,
        padAngle: 2,
        cornerRadius: 0,
        activeOuterRadiusOffset: 8,
        enableArcLinkLabels: false,
        arcLinkLabelsSkipAngle: 10,
        arcLinkLabelsTextColor: '#333333',
        arcLinkLabelsThickness: 2,
        arcLabelsSkipAngle: 10,
        legends: [
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: legendWidth + 10,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: legendWidth,
                itemHeight: 20,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                symbolSize: 16,
                symbolShape: 'square',
                toggleSerie: true,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]
    };
    return props;
}
export function getFunnelProps(chartSettings, data, language) {
    if (!(chartSettings.chart.type === 'funnel')) {
        return;
    }
    if (!chartSettings.aggregations.length) {
        return;
    }
    const aggregation = chartSettings.aggregations[0];
    const aggregationId = getAggregationId(aggregation);
    const aggregationResult = data.aggregations[aggregationId];
    const pieData = toPieData(aggregationResult, chartSettings);
    if (!pieData.data.length) {
        return;
    }
    const valuePath = pieData.keys.value[0];
    const labelPath = pieData.names[valuePath];
    const newLineData = pieData.data.map((dataItem) => {
        return {
            id: dataItem.value,
            value: Number(get(dataItem, valuePath)),
            label: String(get(dataItem, labelPath))
        };
    });
    const props = {
        // @ts-expect-error
        data: newLineData,
        colors: { scheme: 'nivo' },
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        shapeBlending: 0.64,
        borderWidth: 6,
        beforeSeparatorLength: 50,
        beforeSeparatorOffset: 20,
        afterSeparatorLength: 50,
        afterSeparatorOffset: 20,
        currentPartSizeExtension: 10,
        currentBorderWidth: 10,
        motionConfig: 'wobbly',
        enableLabel: true,
        labelColor: 'black',
        ariaLabel: labelPath
    };
    return props;
}
export function getMetricProps(chartSettings, data, language) {
    if (!(chartSettings.chart.type === 'metric')) {
        return;
    }
    if (!chartSettings.metrics.length) {
        return;
    }
    const metric = chartSettings.metrics[0];
    const metricAggregationId = getAggregationId(metric);
    const aggregationResult = data.aggregations[metricAggregationId];
    const valuePath = getMetricValuePath(metric);
    const metricDescription = MetricsOptions.find((item) => item.value === metric.type);
    const metricName = metricDescription?.labels[language] || metric.type;
    let value = toNumber(get(aggregationResult, valuePath) || get(aggregationResult, 'value'));
    let unit = '';
    if ('inPercents' in metric &&
        metric.inPercents &&
        data.hits.total &&
        typeof data.hits.total !== 'number') {
        value = data.hits.total.value ? (value * 100) / data.hits.total.value : 0;
        unit = ' %';
    }
    else if ('units' in metric && metric.units && metric.units[language]) {
        unit = ` ${metric.units[language]}`;
    }
    const result = {
        id: metricAggregationId,
        value: value.toLocaleString(language, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            useGrouping: true
        }) + unit,
        label: metricName
    };
    const props = {
        data: [result]
    };
    return props;
}
export function getHeatMapProps(chartSettings, data, language, chartWidth) {
    if (chartSettings.chart.type !== 'heatmap') {
        return;
    }
    const aggregation = chartSettings.aggregations[0];
    if (!aggregation) {
        return;
    }
    const aggregationId = getAggregationId(aggregation);
    const aggregationResult = data.aggregations[aggregationId];
    const subAggregation = getSubAggregation(aggregation);
    const subAggregationId = subAggregation?.id;
    if (!subAggregationId) {
        return;
    }
    const heatMapData = toHeatMapData(aggregationResult, chartSettings, language);
    if (!heatMapData.data.length) {
        return;
    }
    const valuePath = heatMapData.keys.value[0];
    const labelPath = heatMapData.names[valuePath];
    const allUniqValuesMap = getAllSubAggregationsValuesMap(heatMapData.data, subAggregationId);
    const allUniqLabels = uniqBy(Object.values(allUniqValuesMap), labelPath);
    if (subAggregation.type === AggregationType.dateHistogram) {
        allUniqLabels.sort((a, b) => {
            const dateA = moment(a.label, 'DD/MM/YYYY');
            const dateB = moment(b.label, 'DD/MM/YYYY');
            if (dateA.isValid() && dateB.isValid()) {
                return dateA.unix() > dateB.unix() ? 1 : dateA.unix() < dateB.unix() ? -1 : 0;
            }
            return a[labelPath].localeCompare(b[labelPath]);
        });
    }
    const newHeatMapData = heatMapData.data.map((aggregationItem) => {
        return {
            id: aggregationItem[labelPath],
            // Heatmap lib requires to have the same length data for all groups, so we fill with 0 if item doesn't exist
            data: allUniqLabels.map(({ label }) => {
                const subAggregationItemByLabel = Object.values(aggregationItem[subAggregationId]).find((subAggregationItem) => subAggregationItem[labelPath] === label);
                return {
                    x: label,
                    y: subAggregationItemByLabel?.[valuePath] || 0
                };
            })
        };
    });
    return {
        data: newHeatMapData,
        margin: { top: 90, right: 90, bottom: 60, left: 90 },
        axisTop: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: '',
            legendOffset: 46
        },
        axisRight: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 70
        },
        colors: { type: 'diverging', scheme: 'orange_red', divergeAt: 1 },
        axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -72
        },
        emptyColor: '#555555'
    };
}
export function getLineProps(chartSettings, data, language, chartWidth) {
    if (!(chartSettings.chart.type === 'line' ||
        chartSettings.chart.type === 'spline' ||
        chartSettings.chart.type === 'step' ||
        chartSettings.chart.type === 'area-step' ||
        chartSettings.chart.type === 'area' ||
        chartSettings.chart.type === 'area-spline')) {
        return;
    }
    if (!chartSettings.aggregations.length) {
        return;
    }
    const aggregation = chartSettings.aggregations[0];
    const aggregationId = getAggregationId(aggregation);
    const aggregationResult = data.aggregations[aggregationId];
    const lineData = toLineData(aggregationResult, chartSettings, language);
    if (!lineData.data.length) {
        return;
    }
    const newLineData = lineData.keys.value.map((valuePatch) => {
        return {
            id: lineData.names[valuePatch],
            data: lineData.data.map((dataItem) => {
                return {
                    x: dataItem.value,
                    y: get(dataItem, valuePatch)
                };
            })
        };
    });
    let curve = 'linear';
    switch (chartSettings.chart.type) {
        case 'step':
        case 'area-step':
            curve = 'step';
            break;
        case 'spline':
        case 'area-spline':
            curve = 'monotoneX';
            break;
    }
    const legendWidth = getLegendWidth(Object.values(lineData.names));
    let xScale;
    let axisBottom;
    if (aggregation.type === AggregationType.dateHistogram) {
        xScale = {
            type: 'time',
            format: '%Q'
            // useUTC: false,
            // nice: true,
            // precision: 'day'
        };
        const min = minBy(newLineData[0]?.data, (o) => o.x);
        const max = maxBy(newLineData[0]?.data, (o) => o.x);
        const tickLabelWidth = 60;
        const maxTicks = Math.ceil(chartWidth ? (chartWidth - legendWidth) / tickLabelWidth : 7) || 1;
        const diffDays = moment(max?.x).diff(moment(min?.x), 'days');
        const days = Math.ceil(diffDays < maxTicks ? 1 : diffDays / maxTicks);
        axisBottom = {
            format: '%b %d',
            tickValues: `every ${days} days`
            // legend: 'time scale',
            // legendOffset: 0
        };
    }
    const props = {
        // @ts-expect-error
        data: newLineData,
        colors: { scheme: 'nivo' },
        margin: { top: 10, right: legendWidth, bottom: 25, left: 40 },
        animate: true,
        enableSlices: 'x',
        xScale: xScale,
        axisBottom: axisBottom,
        enableArea: ['area-step', 'area', 'area-spline'].includes(chartSettings.chart.type),
        enableGridX: false,
        yScale: {
            type: 'linear',
            stacked: false
        },
        curve: curve,
        legends: [
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: legendWidth + 10,
                // translateY: 56,
                itemsSpacing: 0,
                itemWidth: legendWidth,
                itemHeight: 20,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                // itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'square',
                toggleSerie: true,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]
    };
    return props;
}
export function getBarProps(chartSettings, data, language) {
    if (!(chartSettings.chart.type === 'bar')) {
        return;
    }
    if (!chartSettings.aggregations.length) {
        return;
    }
    const aggregation = chartSettings.aggregations[0];
    const aggregationId = getAggregationId(aggregation);
    const aggregationResult = data.aggregations[aggregationId];
    const lineData = toLineData(aggregationResult, chartSettings, language);
    if (!lineData.data.length) {
        return;
    }
    const keyNames = Object.values(lineData.names);
    const newLineData = lineData.data.map((item) => {
        const newItem = lineData.keys.value.reduce((acc, valuePatch) => {
            acc[lineData.names[valuePatch]] = get(item, valuePatch);
            const filter = [];
            const value = item[lineData.keys.x || 'value'];
            if (typeof value === 'string' || typeof value === 'number') {
                filter.push(...aggregationToFilters(aggregation, value));
            }
            acc.filter = acc.filter || {};
            acc.filter[lineData.names[valuePatch]] = [...filter];
            const subAggregation = getSubAggregation(aggregation);
            if (subAggregation) {
                const labelPath = valuePatch.split('.').slice(0, -1);
                if (subAggregation.type === AggregationType.term) {
                    labelPath.push('label');
                }
                else {
                    labelPath.push('value');
                }
                acc.filter[lineData.names[valuePatch]].push(...aggregationToFilters(subAggregation, get(item, labelPath)));
            }
            return acc;
        }, item);
        return pick(newItem, [...keyNames, 'value', 'label', 'filter']);
    });
    let axisBottom;
    if (aggregation.type === AggregationType.dateHistogram) {
        axisBottom = {
            format: d3.timeFormat('%b %d'),
            legendOffset: 0
        };
    }
    const legendWidth = getLegendWidth(keyNames);
    const props = {
        // @ts-expect-error
        data: newLineData,
        colors: { scheme: 'nivo' },
        margin: { top: 10, right: legendWidth, bottom: 25, left: 40 },
        animate: true,
        keys: keyNames,
        indexBy: aggregation.type === AggregationType.dateHistogram ? 'value' : 'label',
        valueScale: { type: 'linear' },
        indexScale: { type: 'band', round: true },
        axisBottom: axisBottom,
        tooltipLabel: (item) => {
            if (aggregation.type === AggregationType.dateHistogram) {
                return `${d3.timeFormat(getDateHistogramTimeFormat(aggregation))(new Date(item.indexValue))} - ${item.id}`;
            }
            return `${item.indexValue} - ${item.id}`;
        },
        labelSkipHeight: 13,
        legends: [
            {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: legendWidth + 10,
                itemsSpacing: 0,
                itemWidth: legendWidth,
                itemHeight: 20,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                // itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'square',
                toggleSerie: true,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1,
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]
    };
    return props;
}
