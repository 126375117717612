import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Collapse, Input, Tooltip, Typography } from 'antd';
import { FieldArray, getIn } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Select } from '../../../../../common/components/Select';
import FormikError from '../../../../../common/components/formik/FormikError';
import FormikField from '../../../../../common/components/formik/FormikField';
import FileInput from '../../../../../common/storage/components/FileField/FileInput';
import useFiles from '../../../../../common/storage/useFiles';
import { getTenant } from '../../../../../tenant';
import { fontFormats, fontStyles, fontWeights } from '../../utils';
import styles from './HyperFormCustomFontsFields.module.scss';
export const HyperFormCustomFontsFields = ({ isSubmitting, values, setFieldValue, errors }) => {
    const { t } = useI18n();
    const options = useMemo(() => {
        return fontStyles.map((font) => {
            return {
                value: font.value,
                label: t(`TRANSLATION_KEY_FOR_${font.label}`)
            };
        });
    }, [t]);
    const tenant = useSelector(getTenant);
    const customFontIds = useMemo(() => (values?.hyperFormTheme?.fonts || []).map(({ fileId }) => fileId), [values?.hyperFormTheme?.fonts]);
    const fontFiles = useFiles(customFontIds);
    const handleFontOnChange = useCallback((fontIndex, fontIds) => {
        if (!fontIds) {
            return;
        }
        let fontId;
        if (fontIds.length === 1) {
            fontId = fontIds[0];
        }
        else {
            const fontValue = values?.hyperFormTheme?.fonts?.[fontIndex]?.fileId;
            fontId = fontIds.filter((id) => id !== fontValue)[0];
        }
        void setFieldValue(`hyperFormTheme.fonts[${fontIndex}].fileId`, fontId);
        if (!fontId) {
            void setFieldValue(`hyperFormTheme.fonts[${fontIndex}].format`, null);
        }
    }, [setFieldValue, values?.hyperFormTheme?.fonts]);
    const updateFontFormats = useCallback(() => {
        const fonts = values?.hyperFormTheme?.fonts;
        if (!fonts) {
            return;
        }
        for (const [fontIndex, { fileId, format }] of fonts.entries()) {
            const found = fontFiles.find(({ id }) => id === fileId);
            if (!found) {
                continue;
            }
            const fontFormat = found.extension === 'otf'
                ? 'opentype'
                : found.extension === 'ttf'
                    ? 'truetype'
                    : found.extension;
            if (format !== fontFormat) {
                void setFieldValue(`hyperFormTheme.fonts[${fontIndex}].format`, fontFormat);
            }
        }
    }, [fontFiles, setFieldValue, values?.hyperFormTheme?.fonts]);
    const getFontForm = useCallback((fontIndex, remove, fileId) => (React.createElement("div", { className: "border-bottom pt-2", key: `${fontIndex}-${fileId ?? ''}` },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col d-flex justify-content-end mb-2 mt-1" },
                React.createElement(Button, { inversed: true, disabled: isSubmitting, onClick: () => remove(fontIndex) },
                    React.createElement(IoClose, null),
                    React.createElement("span", { className: "ms-2" }, t('TENANT_HYPER_FORM_THEME_DELETE_FONT'))))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement(FormikField, { name: `hyperFormTheme.fonts[${fontIndex}].fileId`, className: styles.fileField, layout: "horizontal", label: t('TENANT_HYPER_FORM_THEME_FONT_LABEL') + '*', component: FileInput, meta: {
                        list: false,
                        accept: fontFormats.map(({ value }) => `.${value}`).join(','),
                        carousel: false
                    }, error: !!getIn(errors, ['hyperFormTheme', 'fonts', `${fontIndex}`, 'fileId']), groupId: `${tenant?.id ?? ''}-form-custom-font`, value: fileId ? [fileId] : undefined, onChange: (value) => handleFontOnChange(fontIndex, value) }),
                React.createElement(FormikError, { name: `hyperFormTheme.fonts[${fontIndex}].fileId`, className: "d-flex justify-content-end align-items-center" }))),
        React.createElement(Collapse, { className: styles.collapse, items: [
                {
                    key: 'advanced-options',
                    label: t('TENANT_HYPER_FORM_THEME_FONT_ADVANCED_OPTIONS'),
                    children: (React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-6" },
                            React.createElement(LabelText, { className: "d-flex align-items-center fw-500" },
                                React.createElement("span", { className: "me-1" }, t('TENANT_HYPER_FORM_THEME_FONT_STYLE')),
                                React.createElement(Tooltip, { placement: "right", title: t('TENANT_HYPER_FORM_THEME_FONT_STYLE_TOOLTIP') },
                                    React.createElement(IoMdInformationCircle, { size: "16" }))),
                            React.createElement(FormikField, { name: `hyperFormTheme.fonts[${fontIndex}].style`, allowClear: true, component: Select, value: values.hyperFormTheme?.fonts?.[fontIndex].style, options: options, className: "w-100", placeholder: t('TENANT_HYPER_FORM_THEME_FONT_STYLE_PLACEHOLDER'), status: getIn(errors.hyperFormTheme, [
                                    'hyperFormTheme',
                                    'fonts',
                                    `${fontIndex}`,
                                    'style'
                                ])
                                    ? 'warning'
                                    : undefined }),
                            React.createElement(FormikError, { name: `hyperFormTheme.fonts[${fontIndex}].style` })),
                        React.createElement("div", { className: "col-6" },
                            React.createElement(LabelText, { className: "d-flex align-items-center fw-500" },
                                React.createElement("span", { className: "me-1" }, t('TENANT_HYPER_FORM_THEME_FONT_WEIGHT')),
                                React.createElement(Tooltip, { placement: "right", title: t('TENANT_HYPER_FORM_THEME_FONT_WEIGHT_TOOLTIP') },
                                    React.createElement(IoMdInformationCircle, { size: "16" }))),
                            React.createElement(FormikField, { name: `hyperFormTheme.fonts[${fontIndex}].weight`, component: Select, allowClear: true, value: values.hyperFormTheme?.fonts?.[fontIndex].weight, options: fontWeights, className: "w-100", placeholder: t('TENANT_HYPER_FORM_THEME_FONT_WEIGHT_PLACEHOLDER'), status: getIn(errors, ['hyperFormTheme', 'fonts', `${fontIndex}`, 'weight'])
                                    ? 'warning'
                                    : undefined }),
                            React.createElement(FormikError, { name: `hyperFormTheme.fonts[${fontIndex}].weight` })),
                        React.createElement("div", { className: "col mt-2" },
                            React.createElement(LabelText, { className: "d-flex align-items-center fw-500" },
                                React.createElement("span", { className: "me-1" }, t('TENANT_HYPER_FORM_THEME_FONT_FAMILY_NAME')),
                                React.createElement(Tooltip, { placement: "right", title: t('TENANT_HYPER_FORM_THEME_FONT_FAMILY_TOOLTIP') },
                                    React.createElement(IoMdInformationCircle, { size: "16" }))),
                            React.createElement(FormikField, { name: `hyperFormTheme.fonts[${fontIndex}].family`, placeholder: t('TENANT_HYPER_FORM_THEME_FONT_FAMILY_NAME_PLACEHOLDER'), component: Input }),
                            React.createElement(FormikField, { name: `hyperFormTheme.fonts[${fontIndex}].format`, component: Input, type: "hidden" }))))
                }
            ] }))), [errors, handleFontOnChange, isSubmitting, options, t, tenant?.id, values.hyperFormTheme?.fonts]);
    useEffect(() => {
        updateFontFormats();
    }, [updateFontFormats]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography.Title, { level: 5 }, t('TENANT_HYPER_FORM_THEME_CUSTOM_FONTS')),
        React.createElement(FieldArray, { name: "hyperFormTheme.fonts" }, ({ push, remove }) => (React.createElement(React.Fragment, null,
            !values.hyperFormTheme?.fonts?.length
                ? null
                : values.hyperFormTheme.fonts.map((font, index) => getFontForm(index, remove, font.fileId)),
            React.createElement("div", { className: "row mt-3" },
                React.createElement("div", { className: "col" },
                    React.createElement(Button, { disabled: isSubmitting, onClick: () => push({}) },
                        React.createElement(MdAdd, null),
                        React.createElement("span", { className: "ms-2" }, t('TENANT_HYPER_FORM_THEME_ADD_FONT'))))))))));
};
