export const antTheme = {
    token: {
        fontFamily: 'var(--fontFamily)'
    },
    components: {
        Button: {
            borderRadius: 4,
            borderRadiusLG: 4,
            fontSizeLG: 14
        },
        Radio: {
            fontSize: 14,
            borderRadiusLG: 4
        }
    }
};
