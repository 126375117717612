import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { Drawer } from '../../../../common/components/Drawer';
import LoadingIndicator from '../../../../common/components/loading-indicator';
import { getPaymentConfig } from '../actions';
import PaymentCardForm from './PaymentCardForm';
const PaymentCardAddButton = ({ onError, onSuccess }) => {
    const { t } = useI18n();
    const [loading, setLoading] = useState(true);
    const [stripePromise, setStripePromise] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const httpDispatch = useDispatch();
    useEffect(() => {
        httpDispatch(getPaymentConfig())
            .then((data) => {
            setStripePromise(loadStripe(data.stripeKey));
        })
            .catch((e) => {
            onError(e);
            setLoading(false);
        });
    }, [httpDispatch, onError]);
    const onLoadForm = useCallback(() => setLoading(false), [setLoading]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "actionButton", onClick: () => setShowForm(true) },
            React.createElement(IoAdd, { className: "add-icon", preserveAspectRatio: "none" }),
            t('PAYMENT_ADD_NEW_CARD')),
        React.createElement(Drawer, { title: t('PAYMENT_ADD_NEW_CARD'), closable: false, onClose: () => setShowForm(false), open: showForm, destroyOnClose: true },
            loading ? React.createElement(LoadingIndicator, null) : null,
            React.createElement(Elements, { stripe: stripePromise },
                React.createElement(PaymentCardForm, { onClose: () => setShowForm(false), setLoading: setLoading, loading: loading, onError: onError, onSuccess: onSuccess, onLoad: onLoadForm })))));
};
export default PaymentCardAddButton;
