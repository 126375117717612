export const Events = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
};
export const AuthType = {
    NONE: 'none',
    BASIC: 'basic',
    HEADER: 'header'
};
