import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { BROWSE_PATH, PROCESS_META_HANDLER } from '../../../../../common/utils/constants';
import { PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import { CMS_PATH } from '../../../../common/utils/constants';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { useEntityItem } from '../../../../hooks/useEntityItem';
import { getItemRepresentation } from '../../../utils';
import QuickEditItem from '../QuickEditItem';
const ItemLink = ({ definitionId, entityId, onClick, className }) => {
    const [showQuickEditItem, setShowQuickEditItem] = useState(false);
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const { data: item } = useEntityItem(definitionId, entityId);
    const isProcess = displayItemMeta?.handler === PROCESS_META_HANDLER;
    const itemName = useMemo(() => {
        return item ? getItemRepresentation(item) : entityId;
    }, [entityId, item]);
    const url = useMemo(() => {
        if (isProcess) {
            return `${WORKFLOWS_PATH}${PROCESSES_PATH}/${definitionId}${BROWSE_PATH}/${entityId}`;
        }
        else {
            return `${CMS_PATH}/${definitionId}${BROWSE_PATH}/${entityId}`;
        }
    }, [definitionId, entityId, isProcess]);
    const onDrawerClose = useCallback(() => {
        setShowQuickEditItem(false);
    }, []);
    const onClickLink = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowQuickEditItem(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { to: url, title: itemName, onClick: onClick ?? onClickLink, className: className },
            React.createElement(TooltipTruncate, null, itemName)),
        !onClick && (React.createElement(QuickEditItem, { definitionId: definitionId, entityId: entityId, open: showQuickEditItem, onClose: onDrawerClose }))));
};
export default ItemLink;
