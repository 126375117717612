import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { EMAIL_TEMPLATES_DEFINITION_ID } from '@hypercharge/portal-utils';
import { Alert, Switch } from 'antd';
import { groupBy, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
import { oneHourInMs } from '../../../../common/utils/react-query-client';
import { saveConfig } from '../../../../configs';
import { useConfig } from '../../../../configs/useConfig';
import { SETTINGS_KEY } from '../../../../settings';
import { getEmailClients } from '../../actions';
const TemplatesSettingsDrawer = ({ open, onClose, className }) => {
    const { t } = useI18n();
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(false);
    const { data: templatesConfig, isFetching: isTemplatesConfigPending } = useConfig(SETTINGS_KEY, EMAIL_TEMPLATES_DEFINITION_ID);
    const dispatch = useDispatch();
    const dispatchClients = useDispatch();
    const dispatchSaveConfig = useDispatch();
    const { data: clients, isFetching: isFetchingClients, isError } = useQuery(['getEmailClients'], () => {
        return dispatchClients(getEmailClients());
    }, {
        staleTime: oneHourInMs,
        cacheTime: oneHourInMs
    });
    const groupClients = useMemo(() => {
        if (!clients) {
            return {};
        }
        const sortClient = sortBy(clients.results, ['category', 'client', 'os']);
        return groupBy(sortClient, 'category');
    }, [clients]);
    const toggleClient = useCallback((id) => {
        if (value?.has(id)) {
            value.delete(id);
            setValue(new Set([...value]));
            return;
        }
        setValue(new Set([...(value || []), id]));
    }, [value]);
    const onSubmit = useCallback(async () => {
        const data = { ...templatesConfig?.data, previewClients: [...(value || [])] };
        setLoading(true);
        try {
            await dispatchSaveConfig(saveConfig({
                ...templatesConfig,
                configType: SETTINGS_KEY,
                configId: EMAIL_TEMPLATES_DEFINITION_ID,
                data
            }));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
            onClose();
        }
        catch (e) {
            dispatch(error({
                title: t('ERROR'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
        }
        setLoading(false);
    }, [dispatch, dispatchSaveConfig, onClose, t, templatesConfig, value]);
    useEffect(() => {
        if (templatesConfig) {
            const data = templatesConfig?.data;
            if (data) {
                setValue(new Set(data.previewClients || []));
            }
        }
        else if (clients && !value) {
            const defClients = clients.results
                .filter((client) => client.default)
                .map((client) => client.id);
            setValue(new Set(defClients));
        }
    }, [templatesConfig, clients]);
    return (React.createElement(Drawer, { title: t('SETTINGS'), onClose: onClose, open: open, className: className },
        React.createElement(DrawerContent, { loading: clients?.results.length == 0 && isFetchingClients },
            React.createElement(DrawerContentBlock, { title: t('CROSS_CLIENT_PREVIEW_SETTINGS') },
                React.createElement(Alert, { message: t('CROSS_CLIENT_PREVIEW_SETTINGS_INFO'), type: "info", closable: false })),
            isError ? (React.createElement(DrawerContentBlock, null,
                React.createElement("i", null, t('SOMETHING_WENT_WRONG')))) : (Object.keys(groupClients).map((group, index) => (React.createElement(DrawerContentBlock, { key: index, title: group }, groupClients[group].map((client) => (React.createElement("div", { className: "cp-i-none", key: client.id },
                React.createElement("label", null,
                    React.createElement(Switch, { className: "me-1", size: "small", onChange: () => toggleClient(client.id), disabled: loading || isTemplatesConfigPending || isFetchingClients, checked: value?.has(client.id) }),
                    React.createElement("span", null, `${client.client}${client.browser ? ` ${client.browser}` : ''} - ${client.os}`)))))))))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { submitText: t('FORM__SAVE'), onCancel: onClose, onSubmit: onSubmit, disabled: loading || isTemplatesConfigPending || isFetchingClients }))));
};
export default TemplatesSettingsDrawer;
