import { createReducer } from '@hypercharge/hyper-react-base/lib/utils/reducerUtils';
const initialFetchStatus = { pending: false, failed: false };
const initialState = {
    tenant: null,
    tenantStatus: initialFetchStatus
};
const authReducer = createReducer(initialState, {
    TENANT__FETCH_TENANT: (s) => {
        s.tenantStatus.pending = true;
        s.tenantStatus.failed = false;
    },
    TENANT__FETCH_TENANT_SUCCESS: (s, a) => {
        s.tenant = a.payload;
        s.tenantStatus.pending = false;
    },
    TENANT__FETCH_TENANT_FAIL: (s) => {
        s.tenantStatus.pending = false;
        s.tenantStatus.failed = true;
    },
    TENANT__UPDATE_TENANT_SUCCESS: (s, a) => {
        s.tenant = a.payload;
    }
});
export default authReducer;
