import React, { useEffect, useMemo } from 'react';
import { getParentPropertyId, getPropertyId, getReferenceDefinitionId } from '../../utils';
import { RelatedProcessesRadio } from '../RelatedProcessesRadio';
import styles from './RelatedProcessesRadioColumn.module.scss';
export const RelatedProcessesRadioColumn = ({ availableProperties, className, groupTitle, isLimitReached, parentDefinition, parentGroupId, selectedProperty, setSelectedProperty, selectedPropertySource, groupSource }) => {
    const selectedPropertyId = useMemo(() => getPropertyId(selectedProperty, true), [selectedProperty]);
    useEffect(() => {
        if (groupSource === 'to' && !!selectedProperty) {
            const found = availableProperties.find(({ propertyId }) => propertyId === selectedPropertyId);
            const referenceDefinitionId = getReferenceDefinitionId(selectedPropertySource);
            if (found?.meta?.definitionId !== referenceDefinitionId) {
                setSelectedProperty(undefined);
            }
        }
        if (groupSource === 'to' && selectedPropertySource && availableProperties.length === 1) {
            setSelectedProperty(availableProperties[0]);
        }
    }, [
        availableProperties,
        groupSource,
        selectedProperty,
        selectedPropertyId,
        selectedPropertySource,
        setSelectedProperty
    ]);
    return (React.createElement("div", { className: `${className ?? ''} w-100 w-md-50 d-flex flex-column` },
        React.createElement("div", { className: `${styles.title} d-flex align-items-center mb-3 fw-500 lh-1` }, groupTitle),
        React.createElement("div", null,
            parentGroupId && (React.createElement(RelatedProcessesRadio, { isLimitReached: isLimitReached, parentGroupId: getParentPropertyId(parentGroupId), radioTitle: parentDefinition?.title, setSelectedProperty: setSelectedProperty, selectedProperty: selectedProperty, selectedPropertySource: selectedPropertySource, groupSource: groupSource })),
            React.createElement("div", { className: parentGroupId ? 'ps-4' : '' }, availableProperties.map((property, index) => (React.createElement(RelatedProcessesRadio, { key: `${property.propertyId}__${index}`, isLimitReached: isLimitReached, property: property, setSelectedProperty: setSelectedProperty, selectedProperty: selectedProperty, selectedPropertySource: selectedPropertySource, groupSource: groupSource })))))));
};
