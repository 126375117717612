import React from 'react';
import { IoMdClose } from 'react-icons/io';
import ActionsButton from '../../../../../../common/components/ActionsButton';
const AvailableEntityView = ({ entity, view, viewIdx, entityIdx, entityGroupIdx, onViewOpen, arrayHelpersEntitiesViews, disabled }) => {
    return (React.createElement("div", { className: "d-flex flex-row align-items-top mt-2", key: viewIdx },
        React.createElement("div", { className: "col" },
            React.createElement(ActionsButton, { buttonAction: {
                    action: () => {
                        onViewOpen(entityGroupIdx, entityIdx, viewIdx, entity.entityId);
                    },
                    buttonLabel: `${view.title} ${view.isDefault ? '(default)' : ''}`,
                    canRun: true
                }, inversed: true, disabled: disabled })),
        React.createElement("div", { className: "col ms-1" },
            React.createElement(IoMdClose, { className: "remove-btn", onClick: () => arrayHelpersEntitiesViews.remove(viewIdx) }))));
};
export default AvailableEntityView;
