import * as Yup from 'yup';
import { AggregationTypes } from '../../../../views/components/metrics/types';
export const getViewSchema = () => Yup.object({
    entityId: Yup.string().required('Required'),
    isDefault: Yup.bool(),
    title: Yup.string().required('Required'),
    metrics: Yup.array(Yup.object({
        id: Yup.string().required('Required'),
        titles: Yup.mixed(),
        metric: Yup.string().oneOf(Object.values(AggregationTypes)),
        field: Yup.string().required('Required'),
        query: Yup.object()
            .shape({
            condition: Yup.string().required('Required'),
            filters: Yup.array()
        })
            .default(undefined),
        units: Yup.mixed(),
        inPercents: Yup.bool()
    })),
    filters: Yup.object({
        languageCode: Yup.string(),
        fullTextFields: Yup.array().of(Yup.string()),
        sortBy: Yup.array(Yup.object().shape({
            field: Yup.string().required('Required'),
            order: Yup.string().required('Required')
        })).required('Required'),
        query: Yup.object().shape({
            condition: Yup.string().required('Required'),
            filters: Yup.array()
        }),
        responseFields: Yup.array().of(Yup.string())
    }).required('Required')
});
