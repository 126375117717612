import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const languageSelection = {
    en: 'English',
    nl: 'Nederlands',
    fr: 'Français',
    pt: 'Português',
    es: 'Español',
    it: 'Italiano',
    de: 'Deutsch',
    pl: 'Polski',
    ro: 'Românesc',
    ru: 'Pусский'
};
i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
        en: {
            translation: {
                ...languageSelection
            }
        }
    }
});
export const t = i18n.t.bind(i18n);
export default i18n;
