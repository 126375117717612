import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import * as d3 from 'd3';
import * as dagreD3 from 'dagre-d3';
import React, { useCallback, useEffect, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GiCardExchange } from 'react-icons/gi';
import { MdAddCircleOutline, MdContentCopy, MdContentPaste, MdSettings } from 'react-icons/md';
import { useCollapsibleContainer } from '../../../../../common/components/side-menu';
import styles from './ActivityDiagramRenderer.module.scss';
import { canAddNode, canDeleteNode, canReplaceNode, nodeHasProperties } from './utils';
function isChromeVersionBroken() {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        const raw = /Chrom(e|ium)\/([0-9]+)\./.exec(ua);
        return raw ? parseInt(raw[2], 10) >= 71 : false;
    }
    return false;
}
function getPosition(elm) {
    let xPos = 0;
    let yPos = 0;
    while (elm) {
        xPos += elm.offsetLeft - elm.scrollLeft + elm.clientLeft;
        yPos += elm.offsetTop - elm.scrollTop + elm.clientTop;
        elm = elm.offsetParent;
    }
    return { left: xPos, top: yPos };
}
const nodeSelector = '.dagre-d3 .node';
const getSelectedNode = () => {
    return document.querySelector(`${nodeSelector}.selected`);
};
const brokenChrome = isChromeVersionBroken();
const selectedClass = 'selected';
let renderTimeout;
const ActivityDiagramRenderer = ({ className, nodes, edges, readonly, onNodeClick, onNodeDblClick, selectedNode, addAfterSelectedNode, editSelectedNode, deleteSelectedNode, copySelectedNode, pasteAfterSelectedNode, replaceSelectedNode, contentHeight, svgRef, disabled }) => {
    const { isDesktopMenuCollapsed } = useCollapsibleContainer();
    const [nodeTree, setNodeTree] = useState(null);
    const [nodeTreeGroup, setNodeTreeGroup] = useState(null);
    const [svgContainer, setSvgContainer] = useState(null);
    const [svgScrolled, setSvgScrolled] = useState(false);
    const [stateSize, setStateSize] = useState({
        gWidth: 0,
        gHeight: 0
    });
    const [statePosition, setStatePosition] = useState({
        actionsTop: 0,
        actionsLeft: 0
    });
    const getCoords = useCallback(() => {
        const svgDoc = nodeTree;
        if (!svgDoc) {
            return;
        }
        const domNode = getSelectedNode();
        const offsetWindowScroll = !contentHeight;
        if (domNode) {
            const { x, y, width, height } = domNode.getBBox();
            const offset = getPosition(svgDoc.parentElement);
            const matrix = domNode.getScreenCTM();
            const nodeXAdjust = 1;
            const nodeYAdjust = 5;
            const doc = document.documentElement;
            const windowScrollLeft = offsetWindowScroll
                ? (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
                : 0;
            const windowScrollTop = offsetWindowScroll
                ? (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
                : 0;
            if (!matrix) {
                return null;
            }
            return {
                x: matrix.a * x +
                    matrix.c * y +
                    matrix.e -
                    offset.left +
                    width / 2 +
                    nodeXAdjust +
                    windowScrollLeft,
                y: matrix.b * x +
                    matrix.d * y +
                    matrix.f -
                    offset.top +
                    height +
                    nodeYAdjust +
                    windowScrollTop
            };
        }
        else {
            return null;
        }
    }, [nodeTree, contentHeight]);
    const positionActions = useCallback(() => {
        const coord = getCoords();
        if (coord) {
            setStatePosition({
                actionsTop: coord.y,
                actionsLeft: coord.x
            });
        }
    }, [getCoords]);
    const renderDag = useCallback(() => {
        const g = new dagreD3.graphlib.Graph().setGraph({});
        for (const [id, node] of Object.entries(nodes)) {
            g.setNode(id, node);
        }
        for (const edge of edges) {
            // from, to, props
            g.setEdge(edge[0], edge[1], edge[2]);
        }
        // Set up an SVG group so that we can translate the final graph.
        const svg = d3.select(nodeTree);
        const inner = d3.select(nodeTreeGroup);
        // Create the renderer
        const render = new dagreD3.render(); // eslint-disable-line  new-cap
        // Run the renderer. This is what draws the final graph.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        render(inner, g);
        const padding = 5;
        const graph = g.graph();
        // used to inform the callback about the height of the graph
        const gWidth = graph.width + 2 * padding;
        const gHeight = graph.height + 2 * padding;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inner.attr('transform', d3.zoomIdentity.translate(5, 5));
        setStateSize({
            gWidth,
            gHeight
        });
        if (!svgScrolled) {
            const { x } = g.node('Start');
            if (svgContainer) {
                svgContainer.scrollLeft = x - svgContainer.clientWidth / 2;
                setSvgScrolled(true);
            }
        }
        if (onNodeClick) {
            svg.selectAll(nodeSelector).on('click', (event, id) => {
                // simulate the selection happening immediately so the user
                // doesn't notice a delay
                const selectedNode = svg.select(`${nodeSelector}.${selectedClass}`);
                const node = g.node(id);
                onNodeClick(id, node.elem.classList.contains(selectedClass));
                selectedNode.classed(selectedClass, false);
                node.elem.classList.add(selectedClass);
                positionActions();
            });
            setTimeout(() => {
                positionActions();
            }, 100);
        }
        if (onNodeDblClick) {
            svg.selectAll(nodeSelector).on('dblclick', (event, id) => {
                onNodeDblClick(id);
            });
        }
    }, [
        edges,
        nodeTree,
        nodeTreeGroup,
        nodes,
        onNodeClick,
        onNodeDblClick,
        positionActions,
        svgContainer,
        svgScrolled
    ]);
    useEffect(() => {
        renderTimeout = setTimeout(() => renderDag(), 700);
        return () => clearTimeout(renderTimeout);
    }, [renderDag]);
    useEffect(() => () => clearTimeout(renderTimeout), []);
    useEffect(() => {
        window.addEventListener('resize', positionActions);
        return () => {
            window.removeEventListener('resize', positionActions);
        };
    }, [positionActions]);
    useEffect(() => {
        renderDag();
    }, [isDesktopMenuCollapsed]);
    const { t } = useI18n();
    const { actionsTop, actionsLeft } = statePosition;
    const hasProperties = selectedNode ? nodeHasProperties(selectedNode) : false;
    const canAdd = !disabled && selectedNode ? canAddNode(selectedNode) : false;
    const canDelete = !disabled && selectedNode ? canDeleteNode(selectedNode) : false;
    const canReplace = !disabled && selectedNode ? canReplaceNode(selectedNode) : false;
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''} ${brokenChrome ? 'broken-chrome' : ''} svg-container ${readonly ? 'readonly' : ''}`, ref: setSvgContainer },
        React.createElement("div", { style: { position: 'relative' } }, !readonly && (React.createElement("div", { className: "node-actions cp-c-row cp-c-padding-h-1", style: {
                top: `${actionsTop}px`,
                left: `${actionsLeft}px`,
                display: actionsTop && actionsLeft && (hasProperties || canDelete || canAdd)
                    ? 'flex'
                    : 'none'
            } },
            hasProperties && (React.createElement("div", null,
                React.createElement(Tooltip, { title: t('COMMON__EDIT') },
                    React.createElement(MdSettings, { className: "btn-settings", size: "20", onClick: editSelectedNode })))),
            canAdd && (React.createElement("div", null,
                React.createElement(Tooltip, { title: t('PROCESS_META__ADD_TASK') },
                    React.createElement(MdAddCircleOutline, { className: "btn-add", size: "23", onClick: addAfterSelectedNode })))),
            canDelete && copySelectedNode && (React.createElement("div", null,
                React.createElement(Tooltip, { title: t('COPY') },
                    React.createElement(MdContentCopy, { className: "btn-copy", size: "18", onClick: copySelectedNode })))),
            canAdd && pasteAfterSelectedNode && (React.createElement("div", null,
                React.createElement(Tooltip, { title: t('PASTE') },
                    React.createElement(MdContentPaste, { className: "btn-paste", size: "20", onClick: pasteAfterSelectedNode })))),
            canDelete && (React.createElement("div", null,
                React.createElement(Tooltip, { title: t('COMMON__DELETE') },
                    React.createElement(FaRegTrashAlt, { className: "btn-delete", size: "20", onClick: deleteSelectedNode })))),
            canReplace && (React.createElement("div", null,
                React.createElement(Tooltip, { title: t('REPLACE') },
                    React.createElement(GiCardExchange, { size: "20", className: "btn-replace", onClick: replaceSelectedNode }))))))),
        React.createElement("svg", { id: "diagram", className: "dagre-d3", ref: (ref) => {
                setNodeTree(ref);
                svgRef && svgRef(ref);
            }, width: stateSize.gWidth || 1, height: stateSize.gHeight || 1 },
            React.createElement("g", { ref: setNodeTreeGroup }))));
};
export default ActivityDiagramRenderer;
