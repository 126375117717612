import { emptyToNull, required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../../common/components/AntInput';
import { AntTextAreaField } from '../../../../../../common/components/AntTextArea';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import ConditionalMessagesField from './ConditionalMessagesField';
import styles from './SmsForm.module.scss';
import { messageMappingsFieldName, parametersSectionName } from './constants';
const isSupportedPhoneProperty = ({ type }) => type === 'phoneNumber';
const SmsForm = ({ disabled, metaDefinitionId, parentViewGroups, changeFieldValue }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "d-flex flex-row flex-wrap" },
            React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: disabled, maxLength: 60, placeholder: t('COMMON__TITLE') })),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FormSection, { name: parametersSectionName },
            React.createElement("div", { className: "d-flex flex-column align-items-stretch" },
                React.createElement(FieldLabel, { label: t('TASK_SMS_PHONE_NUMBER') }),
                React.createElement(Field, { name: "phoneNumberField", component: SelectPropertyField, definitionId: metaDefinitionId, placeholder: t('SELECT_PROPERTY'), includeProperty: isSupportedPhoneProperty, expandAll: true, validate: required, disabled: disabled, normalize: emptyToNull })),
            React.createElement("div", { className: "d-flex flex-column align-items-stretch" },
                React.createElement(FieldLabel, { label: t('TASK_SMS_DEFAULT_MESSAGE'), help: t('TASK_SMS_DEFAULT_MESSAGE_INFO') }),
                React.createElement(Field, { name: "defaultMessage", component: AntTextAreaField, validate: required, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_SMS_DEFAULT_MESSAGE')}`, disabled: disabled, maxLength: 160 })),
            React.createElement(Field, { className: "mt-2", name: messageMappingsFieldName, component: ConditionalMessagesField, definitionId: metaDefinitionId, changeFieldValue: changeFieldValue })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default SmsForm;
