import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Table } from 'antd';
import { Field } from 'formik';
import React, { useState } from 'react';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import * as Yup from 'yup';
import useDisplayItemMetaList from '../../../../cms/hooks/useDisplayItemMetaList';
import { FieldsContainer } from '../../../../common/utils/formUtils';
import AddWebhookDrawer from './AddWebhookDrawer';
import styles from './WebhooksEntityModuleForm.module.scss';
import { AuthType, Events } from './types';
const getHookSchema = () => {
    return Yup.object({
        auth: Yup.object({
            type: Yup.string()
                .oneOf([AuthType.NONE, AuthType.BASIC, AuthType.HEADER])
                .required(t('VALIDATIONS__REQUIRED')),
            name: Yup.string(),
            pass: Yup.string()
        }).optional(),
        definitionId: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        events: Yup.array()
            .of(Yup.string().oneOf([Events.CREATE, Events.UPDATE, Events.DELETE]))
            .required(t('VALIDATIONS__REQUIRED')),
        url: Yup.string().url().required(t('VALIDATIONS__REQUIRED'))
    });
};
export const getFormSchema = () => {
    return Yup.object().shape({
        hooks: Yup.array().of(getHookSchema()).required(t('VALIDATIONS__REQUIRED'))
    });
};
const defaultRow = {
    auth: {
        type: 'none',
        name: '',
        pass: ''
    },
    definitionId: '',
    events: [],
    url: ''
};
export const initialValues = {
    hooks: []
};
const getDefinitionName = (definitionId, entities) => {
    if (entities && entities.length) {
        const result = entities.find((item) => {
            return item.definitionId == definitionId;
        });
        if (result && result.title) {
            return result.title;
        }
    }
    return definitionId;
};
const Form = ({ configuration: { hooks = [] } }) => {
    const { t } = useI18n();
    const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
    const [selectedHook, setSelectedHook] = useState(-1);
    const { data: entities, isLoading } = useDisplayItemMetaList();
    const tableData = hooks.map(({ definitionId, events, url, auth }, index) => {
        return {
            key: index,
            definition: React.createElement(LabelText, null, getDefinitionName(definitionId, entities)),
            events: React.createElement(LabelText, null, events.join(', ')),
            hook: React.createElement(LabelText, null, url),
            auth: React.createElement(LabelText, null, auth.type),
            add: (React.createElement(Field, { name: "add" }, ({ field, form }) => (React.createElement(React.Fragment, null,
                React.createElement(Button, { disabled: isLoading, onClick: () => {
                        setHistoryDrawerOpen(true);
                        setSelectedHook(index);
                    } },
                    React.createElement(MdEdit, null)),
                React.createElement(Button, { className: "delete-btn", onClick: () => {
                        form.setFieldValue('hooks', form.values['hooks'].filter((row, rowIndex) => index !== rowIndex));
                    } },
                    React.createElement(MdDelete, null))))))
        };
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldsContainer, { className: styles.wrapper },
            React.createElement("div", { className: "cp-c-row cp-c-align-end-center" },
                React.createElement(Button, { disabled: isLoading, onClick: () => {
                        setSelectedHook(-1);
                        setHistoryDrawerOpen(true);
                    } },
                    React.createElement(MdAdd, null))),
            React.createElement(Table, { className: "webhooks-table", loading: isLoading, pagination: {
                    total: 100,
                    pageSize: 100,
                    hideOnSinglePage: true
                }, dataSource: tableData, columns: [
                    {
                        title: `${t('INTEGRATIONS_WEBHOOKS_ENTITY')}`,
                        dataIndex: 'definition'
                    },
                    {
                        title: `${t('INTEGRATIONS_WEBHOOKS_EVENTS')}`,
                        dataIndex: 'events'
                    },
                    {
                        title: `${t('INTEGRATIONS_WEBHOOKS_URL')}`,
                        dataIndex: 'hook'
                    },
                    {
                        title: `${t('INTEGRATIONS_WEBHOOKS_AUTH_TYPE')}`,
                        dataIndex: 'auth'
                    },
                    {
                        title: '',
                        dataIndex: 'add',
                        width: 150
                    }
                ], style: {
                    marginTop: 20
                } })),
        React.createElement(AddWebhookDrawer, { open: historyDrawerOpen, entities: entities, index: selectedHook, hook: hooks[selectedHook] || defaultRow, validationSchema: getHookSchema, onSave: (hook) => {
                if (selectedHook == -1) {
                    hooks.push(hook);
                }
                else {
                    hooks[selectedHook] = hook;
                }
                setHistoryDrawerOpen(false);
            }, onClose: () => setHistoryDrawerOpen(false) })));
};
export default Form;
