import { fieldCss } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Tag } from 'antd';
import { get, sortBy, toLower } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useDisplayProcessMetaList from '../../../../cms/hooks/useDisplayProcessMetaList';
import { DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
import { startProcess as startProcessAction } from '../../../dashboard/actions';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../utils/constants';
import { getProcessUrl } from '../../utils/utils';
const useStartProcess = ({ onClose }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const { data: displayProcessMetaList } = useDisplayProcessMetaList();
    const runnableDisplayProcessMetaList = useMemo(() => displayProcessMetaList?.filter(({ canExecute, processMetaId }) => !!canExecute &&
        ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(processMetaId)) || [], [displayProcessMetaList]);
    const startProcess = useCallback(async (processMetaId) => {
        try {
            const data = await dispatch(startProcessAction(processMetaId));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('TASKS_START_PROCESS_SUCCESS')
            }));
            dispatch(push(getProcessUrl(data.processMetaId, data.id)));
            onClose();
        }
        catch (e) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('TASKS_START_PROCESS_FAIL')
            }));
        }
    }, [dispatch, onClose, t]);
    return {
        startProcess,
        runnableDisplayProcessMetaList
    };
};
const StartProcess = ({ onClose }) => {
    const [selectedProcessMetaId, setSelectedProcessMetaId] = useState(null);
    const [startingProcess, setStartingProcess] = useState(false);
    const [searchText, setSearchText] = useState('');
    const unmounted = useRef(false);
    const { t } = useI18n();
    const { startProcess, runnableDisplayProcessMetaList } = useStartProcess({ onClose });
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    const submitSearchForm = (event) => {
        // prevent the form from reloading the entire page
        event.preventDefault();
        event.stopPropagation();
    };
    const handleSearchChange = (event) => {
        if (event.target instanceof HTMLInputElement) {
            const searchText = get(event, 'target.value', '');
            setSearchText(searchText);
        }
    };
    const clearSearch = () => {
        setSearchText('');
    };
    const toggleStartingProcess = () => {
        if (!unmounted.current) {
            setStartingProcess(!startingProcess);
        }
    };
    const handleSubmit = (e) => {
        e?.preventDefault();
        if (selectedProcessMetaId) {
            toggleStartingProcess();
            startProcess(selectedProcessMetaId).finally(toggleStartingProcess);
        }
    };
    const upperSearchText = searchText.toUpperCase();
    const filteredProcesses = searchText
        ? runnableDisplayProcessMetaList.filter(({ title, idPrefix }) => (title && title.toUpperCase().indexOf(upperSearchText) > -1) ||
            idPrefix.toUpperCase().indexOf(upperSearchText) > -1)
        : runnableDisplayProcessMetaList;
    const minLabelLength = Math.max(...runnableDisplayProcessMetaList.map((p) => p.idPrefix.length));
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, null,
            React.createElement(DrawerContentBlock, null,
                React.createElement(SearchForm, { onSubmit: submitSearchForm, noValidate: true },
                    React.createElement(SearchInput, { onChange: handleSearchChange, value: searchText, type: "text", placeholder: t('SEARCH') }),
                    searchText && React.createElement(ClearSearchButton, { onClick: clearSearch })),
                sortBy(filteredProcesses, [({ title }) => toLower(title)]).map(({ processMetaId, title, idPrefix, color }) => (React.createElement(Process, { onClick: () => setSelectedProcessMetaId(processMetaId), key: processMetaId, active: selectedProcessMetaId == processMetaId },
                    React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center" },
                        React.createElement("div", { className: "cp-c-row cp-c-align-start-center" },
                            React.createElement(Tag, { style: {
                                    color: 'var(--grayscaleColor-1)',
                                    backgroundColor: color ? color : 'var(--grayscaleColor-3)',
                                    borderColor: color ? color : 'var(--grayscaleColor-3)',
                                    marginRight: '1rem',
                                    height: '1.8rem',
                                    lineHeight: '1.8rem',
                                    textAlign: 'center',
                                    minWidth: `${minLabelLength * 1.7}ch`
                                } },
                                React.createElement("b", null, idPrefix)),
                            React.createElement("div", null, title)),
                        React.createElement(StyledCheck, { active: (selectedProcessMetaId == processMetaId).toString() }))))))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { disabled: !selectedProcessMetaId, onSubmit: handleSubmit, onCancel: onClose, submitText: "TASKS_START_PROCESS", submitting: startingProcess }))));
};
export default StartProcess;
//
// Utils
//
const Process = styled.div `
  padding: 0.5rem;
  border: 1px solid transparent;
  border-color: ${(props) => props.active && 'var(--primaryColor-6)'};
  margin-bottom: 0.5rem;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.active ? 'var(--neutralColor-6)' : 'var(--grayscaleColor-7)')};
  cursor: pointer;
  min-height: 1.8rem;

  :hover {
    color: var(--neutralColor-6);

    .ant-tag {
      opacity: ${(props) => !props.active && '0.7'};
    }
  }
}`;
const StyledCheck = styled(IoIosCheckmarkCircleOutline) `
  transition-duration: 0.3s;
  transition-property: all;
  font-size:  ${(props) => (props.active == 'true' ? '1.8rem' : '0')};
  color: var(--primaryColor-6);
  min-width: 1.8rem;
}`;
const SearchForm = styled.form `
  position: relative;
`;
const SearchInput = styled.input `
  ${fieldCss};
  height: 3rem;
  padding-left: 1.5rem;
  border-radius: 0;
  border-bottom-color: var(--grayscaleColor-2);
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
`;
const ClearSearchButton = styled(MdClose) `
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 1.3rem;
  transition: color 0.2s ease;

  &:focus,
  &:hover {
    color: var(--primaryColor-6);
  }
`;
