// organize-imports-ignore
import { createStore, applyMiddleware } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { createBrowserHistory } from 'history';
import { persistStore, persistCombineReducers, createMigrate } from 'redux-persist';
import { httpMiddleware } from '@hypercharge/hyper-react-base/lib/http';
import { metadataMiddleware } from '@hypercharge/hyper-react-base/lib/metadata';
import { analyticsMiddlewareFactory } from '@hypercharge/hyper-react-base/lib/analytics';
import { pageViewAnalyzer, productsAnalyzer, searchAnalyzer, searchSubscriptionAnalyzer } from '@hypercharge/hyper-react-base/lib/analytics/analyzers';
import { bindLocationChange, routerMiddlewareFactory } from '@hypercharge/hyper-react-base/lib/router';
import { authMiddlewareFactory, authEnhanceRootReducer } from '../../auth';
import '../../workflows'; // TODO need resolve circular deps https://trello.com/c/xNKoWEka/1921-need-remove-circular-imports
import { cmsSessionModule } from '../../cms';
import sessionMiddlewareFactory from '../session/sessionMiddlewareFactory';
import { conversationsSessionModule } from '../conversation';
import { notificationsSessionModule } from '../notifications';
import reducers from '../reducers/reducers';
import storage from 'redux-persist/lib/storage';
import { HOME_PATH } from '../../home';
import { composeWithDevTools } from 'redux-devtools-extension';
export const history = createBrowserHistory();
const middlewares = [
    authMiddlewareFactory({ redirectPathOnLogout: HOME_PATH }),
    httpMiddleware,
    metadataMiddleware,
    sessionMiddlewareFactory([
        conversationsSessionModule,
        notificationsSessionModule,
        cmsSessionModule
    ]),
    routerMiddlewareFactory(history),
    analyticsMiddlewareFactory([
        pageViewAnalyzer,
        productsAnalyzer,
        searchAnalyzer,
        searchSubscriptionAnalyzer
    ])
];
const finalCreateStore = composeWithDevTools(
// @ts-expect-error
applyMiddleware(...middlewares), responsiveStoreEnhancer)(createStore);
const migrations = {
    // @ts-expect-error
    0: (state) => ({ _persist: state._persist }),
    // @ts-expect-error
    2: (state) => ({ _persist: state._persist })
};
const persistConfig = {
    version: 2,
    key: 'app',
    storage,
    whitelist: ['i18n', 'auth', '_persist'],
    debug: false,
    migrate: createMigrate(migrations, { debug: false })
};
export function configureStore() {
    let rootReducer = persistCombineReducers(persistConfig, reducers);
    // @ts-expect-error
    rootReducer = authEnhanceRootReducer(rootReducer, persistConfig);
    const store = finalCreateStore(rootReducer);
    const persistor = persistStore(store);
    bindLocationChange(store, history);
    return { persistor, store };
}
