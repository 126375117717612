import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import { isEmpty, omit } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useProperties } from '../../../../../cms/common/context/PropertiesProvider';
import { SelectPropertyButton } from '../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../common/components/AntInput';
import { ComputedInput } from '../../../../../common/components/ComputedExpressionEditor';
import { usePropertiesTree } from '../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import styles from './ProcessPropertyMappingsField.module.scss';
const ProcessPropertyMappingsField = ({ className, disabled, input, definitionId, definitionIdForComputedExpression, additionLabelContent, includeNestedProperties = true }) => {
    const { t } = useI18n();
    const { getFieldLabel } = useProperties(definitionId);
    const propertiesTree = usePropertiesTree(definitionIdForComputedExpression || definitionId);
    const activePropertyMappings = useMemo(() => (isObject(input.value) ? input.value : {}), [input]);
    const removeActivePropertyMapping = useCallback((field) => {
        input.onChange(omit(activePropertyMappings, [field]));
    }, [activePropertyMappings, input]);
    const addActivePropertyMapping = useCallback((field) => {
        if (field != null) {
            input.onChange({ ...activePropertyMappings, [field]: '' });
        }
    }, [activePropertyMappings, input]);
    const isFieldDisabled = useCallback((fieldPath) => fieldPath in activePropertyMappings, [activePropertyMappings]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        !isEmpty(activePropertyMappings) && (React.createElement("div", null, Object.keys(activePropertyMappings).map((field) => (React.createElement(Fragment, { key: field },
            React.createElement("div", { className: "input-label mb-1" },
                getFieldLabel(field, true, true),
                additionLabelContent ? additionLabelContent(field) : null),
            React.createElement("div", { className: "d-flex align-items-center mb-3" },
                React.createElement(ComputedInput, { propertiesTree: propertiesTree, component: AntInput, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} `, onChange: (value) => {
                        input.onChange({
                            ...activePropertyMappings,
                            [field]: value
                        });
                    }, value: activePropertyMappings[field], disabled: disabled }),
                React.createElement(Button, { type: "text", className: "remove-btn-outer rounded d-flex align-items-center justify-content-center p-0", onClick: () => {
                        removeActivePropertyMapping(field);
                    } },
                    React.createElement(IoMdClose, { className: "remove-btn" })))))))),
        React.createElement(SelectPropertyButton, { definitionId: definitionId, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('SELECT_PROPERTY_TO_MAP')), onChange: addActivePropertyMapping, disabled: disabled, isFieldDisabled: isFieldDisabled, includeProperty: includeProperty, includeNestedProperties: includeNestedProperties })));
};
export default ProcessPropertyMappingsField;
//
// Utils
//
const includeProperty = (displayData) => supportedPropertyTypes.includes(displayData.type) && !displayData.computed && displayData.canEdit;
const supportedPropertyTypes = [
    'text',
    'date',
    'number',
    'select',
    'multitext',
    'checkbox',
    'entity',
    'richtext',
    'file',
    'link',
    'phoneNumber',
    'email',
    'json'
];
const isObject = (obj) => obj != null && obj.constructor.name === 'Object';
