export const FORMS_PATH = '/forms';
export const FORM_PATH = '/form';
export const FORMS_NEW_PATH = '/new';
export const HYPER_FORMS_PERMISSION = 'HYPER_FORMS';
export const FORMS__FETCH_LIST_ACTION = 'FORMS__FETCH_LIST';
export const FORMS__FETCH_LIST_SUCCESS_ACTION = 'FORMS__FETCH_LIST_SUCCESS';
export const FORMS__FETCH_SINGLE_ACTION = 'FORMS__FETCH_SINGLE';
export const FORMS__FETCH_SINGLE_SUCCESS_ACTION = 'FORMS__FETCH_SINGLE_SUCCESS';
export const FORMS__SAVE_ACTION = 'FORMS__SAVE';
export const FORMS__CREATE_ACTION = 'FORMS__CREATE';
export const FORMS__DELETE_ACTION = 'FORMS__DELETE';
