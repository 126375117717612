const baseUrl = `/v2-storage/files`;
export const fetchUploadUrl = ({ name, type }, groupId, isPublic) => {
    // https://github.com/hypercharge-code/hyper-cloud/issues/40
    const payload = {
        name
    };
    if (groupId) {
        payload.groupId = groupId;
    }
    if (type) {
        payload.type = type;
    }
    if (isPublic) {
        payload.public = true;
    }
    return {
        type: 'STORAGE__FETCH_URL',
        payload,
        meta: {
            http: {
                path: `${baseUrl}/upload`,
                method: 'POST',
                allowPromiseReject: true
            }
        }
    };
};
export const uploadFile = (file, data, uploadUrl) => {
    return {
        type: 'STORAGE__UPLOAD_FILE',
        payload: file,
        meta: {
            http: {
                headers: {
                    'Content-Type': file.type
                },
                path: uploadUrl,
                method: 'PUT',
                actionContext: data,
                allowPromiseReject: true
            },
            auth: false
        }
    };
};
export const fetchFilesData = (ids) => ({
    type: 'STORAGE__FETCH_FILES_DATA',
    payload: ids,
    meta: {
        http: {
            path: baseUrl,
            method: 'POST',
            actionContext: { ids },
            allowPromiseReject: true
        }
    }
});
