import { createResponsiveStateReducer } from 'redux-responsive';
import { transform } from 'lodash';
export const sizes = {
    sm: 568,
    md: 768,
    lg: 1024,
    wrapper: 1120,
    xl: 1280
};
export const browserReducer = createResponsiveStateReducer(sizes, {
    extraFields: ({ greaterThan, breakpoints }) => ({
        // this enhances greaterThan to include the breakpoint boundary size
        greaterThanOrEqual: transform(greaterThan, (result, value, key) => {
            result[key] = value || window.innerWidth === breakpoints[key];
        }, {})
    })
});
// Selectors
export const isGTE = (s, breakpoint) => s.greaterThanOrEqual[breakpoint];
export const getActiveMediaQuery = (s) => {
    if (isGTE(s, 'xl')) {
        return 'xl';
    }
    if (isGTE(s, 'lg')) {
        return 'lg';
    }
    if (isGTE(s, 'md')) {
        return 'md';
    }
    if (isGTE(s, 'sm')) {
        return 'sm';
    }
    return 'xs';
};
