import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { Formik } from 'formik';
import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDisplayItemMetaList from '../../../cms/hooks/useDisplayItemMetaList';
import { getConfigsState } from '../../../common/reducers/rootSelector';
import { fetchConfig, getConfig, hasFetchConfigFailed, isFetchConfigPending, saveConfig } from '../../../configs';
import { CMS_KEY, SETTINGS_KEY, SETTINGS_PATH } from '../../constants';
import CmsSettingsForm from './CmsSettingsForm';
const CmsSettingsFormContainer = ({ setLinks, setCurrentBreadcrumbText, contentHeight }) => {
    const dispatch = useDispatch();
    const dispatchSaveConfig = useDispatch();
    const { initialValues, cmsConfig, isCmsConfigPending, isCmsConfigFailed } = useSelector((s) => {
        const configsState = getConfigsState(s);
        const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
        return {
            initialValues: get(cmsConfig, 'data') || {},
            cmsConfig,
            isCmsConfigPending: isFetchConfigPending(configsState, SETTINGS_KEY, CMS_KEY),
            isCmsConfigFailed: hasFetchConfigFailed(configsState, SETTINGS_KEY, CMS_KEY)
        };
    });
    const { t } = useI18n();
    const { data: cmsEntities, isLoading: isCmsEntitiesPending, isError: isCmsEntitiesFailed } = useDisplayItemMetaList();
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('FAVOURITES_SETTINGS'));
    }, [setCurrentBreadcrumbText, setLinks, t]);
    useEffect(() => {
        if (!isCmsConfigPending && !cmsConfig) {
            dispatch(fetchConfig(SETTINGS_KEY, CMS_KEY));
        }
    }, [cmsConfig, dispatch, isCmsConfigPending]);
    const onSubmit = useCallback(async (formValues) => {
        try {
            await dispatchSaveConfig(saveConfig({
                ...cmsConfig,
                configType: SETTINGS_KEY,
                configId: CMS_KEY,
                data: formValues
            }));
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
        }
        catch (e) {
            dispatch(error({
                title: t('ERROR'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
        }
    }, [cmsConfig, dispatch, dispatchSaveConfig, t]);
    return (React.createElement(Formik, { onSubmit: onSubmit, initialValues: initialValues, enableReinitialize: true }, ({ submitForm, values, setFieldValue, isSubmitting, dirty, resetForm }) => (React.createElement(CmsSettingsForm, { isDataPending: isCmsEntitiesPending || isCmsConfigPending, hasDataFetchFailed: isCmsEntitiesFailed || isCmsConfigFailed, cmsEntities: cmsEntities, contentHeight: contentHeight, isSubmitting: isSubmitting, dirty: dirty, values: values, setFieldValue: setFieldValue, resetForm: resetForm, submitForm: submitForm }))));
};
export default CmsSettingsFormContainer;
