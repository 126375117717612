import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { removeFilterById, replaceConditionFromContextDataInFilters } from '../../../../../../common/components/ConditionQuery/utils';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import { PropertyTypes } from '../../../../../../common/types';
import { BROWSE_PATH } from '../../../../../../common/utils/constants';
import queryClient from '../../../../../../common/utils/react-query-client';
import { useView } from '../../../../../../views/components/ViewContext';
import { KANBAN_KEY } from '../../../../../../views/constants';
import { ViewTypes } from '../../../../../../views/types';
import { getSearchForUrl } from '../../../../../../views/utils';
import { useEntityDisplayData } from '../../../../../common/components/withEntityDisplayData';
import { useSearchItems } from '../../../../../hooks/useSearchItems';
import { getFlattenedDisplayDataList } from '../../../../utils';
import styles from './KanbanListHeader.module.scss';
export const FETCH_COLUMNS_COUNT_QUERY_KEY = 'FETCH_COLUMNS_COUNT_QUERY_KEY';
export const invalidateColumnsCountQuery = async ({ definitionId }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(FETCH_COLUMNS_COUNT_QUERY_KEY) &&
            query.queryKey.includes(definitionId)
    });
};
const KanbanListHeader = ({ baseUrl, definitionId, propertyId, onToggleList, title, hidden, theme, getCustomColumnCountFilters }) => {
    const { filterRequestForSearch, metrics, kanban, viewType } = useView();
    const { displayData } = useEntityDisplayData(definitionId);
    const groupByEntityProperty = useMemo(() => {
        const propertyGroupBy = find(getFlattenedDisplayDataList(displayData), {
            propertyId: kanban?.groupBy
        });
        return propertyGroupBy?.type === PropertyTypes.entity;
    }, [displayData, kanban?.groupBy]);
    const countFilterRequestQuery = useMemo(() => {
        if (kanban?.groupBy) {
            const field = groupByEntityProperty ? `${kanban.groupBy}.entityId` : kanban.groupBy;
            const emptyOrIsFilter = propertyId === FilterOperatorTypes.empty
                ? {
                    field,
                    operator: FilterOperatorTypes.empty
                }
                : {
                    field,
                    operator: FilterOperatorTypes.is,
                    data: propertyId
                };
            return {
                condition: ConditionType.and,
                filters: [
                    removeFilterById(filterRequestForSearch.query, KANBAN_KEY),
                    {
                        condition: ConditionType.and,
                        filters: getCustomColumnCountFilters?.(propertyId) || [emptyOrIsFilter]
                    }
                ]
            };
        }
        return filterRequestForSearch.query;
    }, [
        filterRequestForSearch.query,
        getCustomColumnCountFilters,
        groupByEntityProperty,
        kanban?.groupBy,
        propertyId
    ]);
    const filterRequestTotalCount = useMemo(() => ({
        query: countFilterRequestQuery,
        context: filterRequestForSearch.context,
        limit: 0,
        offset: 0,
        responseFields: [],
        fullTextFields: [],
        sortBy: []
    }), [countFilterRequestQuery, filterRequestForSearch.context]);
    const { data: { totalCount: totalCountColumn = 0 } = {} } = useSearchItems({
        definitionId,
        filterRequest: filterRequestTotalCount,
        enabled: viewType === ViewTypes.kanban,
        queryKey: FETCH_COLUMNS_COUNT_QUERY_KEY
    });
    const filterRequestOpenInNewTab = useMemo(() => ({
        ...filterRequestTotalCount,
        query: replaceConditionFromContextDataInFilters(filterRequestTotalCount.query, filterRequestTotalCount.context)
    }), [filterRequestTotalCount]);
    const _onToggleList = useCallback(() => onToggleList(propertyId), [propertyId, onToggleList]);
    return (React.createElement("div", { className: `${styles.list_header} ${hidden ? styles.list_header__hidden : ''}`, style: { backgroundColor: theme?.primary, color: theme?.secondary } },
        React.createElement("div", { className: styles.list_headerLeft },
            React.createElement("button", { className: styles.list_toggleButton, type: "button", onClick: _onToggleList },
                React.createElement(MdOutlineKeyboardDoubleArrowLeft, { className: styles.list_icon }),
                React.createElement("div", { className: styles.list_title },
                    React.createElement("span", { className: "mw-100" },
                        React.createElement(TooltipTruncate, null, title))))),
        React.createElement("div", { className: styles.list_headerRight },
            React.createElement(Link, { to: {
                    pathname: baseUrl + BROWSE_PATH,
                    search: getSearchForUrl({
                        newFilters: filterRequestOpenInNewTab,
                        newMetrics: metrics,
                        viewType: ViewTypes.table,
                        queryParams: {}
                    })
                }, target: "_blank", role: "button", className: styles.list_counter, style: {
                    backgroundColor: theme?.primary,
                    color: theme?.secondary,
                    borderColor: theme?.secondary
                } },
                React.createElement("span", null, totalCountColumn)))));
};
export default KanbanListHeader;
