import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import { formatDateTime } from '@hypercharge/hyper-react-base/lib/utils/timeUtils';
import { Select } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { oneMinuteInMs } from '../../../../common/utils/react-query-client';
import ContactDisplayName from '../../../../crm/components/contact-display-name';
import { MARKETING_PATH } from '../../../common/utils/constants';
import { getCrossClientsPreview } from '../../actions';
import { EMAIL_TEMPLATES_PATH } from '../../constants';
import { StatusCrossClientsPreview } from '../../types';
import PreviewResult from './PreviewResult';
import styles from './PreviewResultContainer.module.scss';
const timerWhileProcessing = 20000;
const timerWhilePending = 5000;
const PreviewResultContainer = ({ templateId, language, contentHeight, listPreview, onChangeActivePreview }) => {
    const { t } = useI18n();
    const { previewId } = useParams();
    const { url } = useRouteMatch();
    const dispatch = useDispatch();
    const dispatchPreviewResult = useDispatch();
    const { data: previewResult, isFetching, refetch } = useQuery(['previewResult', templateId, language, previewId], () => {
        return dispatchPreviewResult(getCrossClientsPreview(templateId, language, previewId));
    }, {
        enabled: !!previewId,
        staleTime: oneMinuteInMs,
        cacheTime: oneMinuteInMs
    });
    useEffect(() => {
        if (!isFetching &&
            (previewResult?.status == StatusCrossClientsPreview.PENDING ||
                previewResult?.status == StatusCrossClientsPreview.PROCESSING)) {
            const timeout = setTimeout(() => void refetch(), previewResult?.status == StatusCrossClientsPreview.PENDING
                ? timerWhilePending
                : timerWhileProcessing);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isFetching, previewResult?.status, refetch]);
    const onChangeClient = useCallback((id) => {
        dispatch(replace(`${url}/${id}/`));
    }, [dispatch, url]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${styles.controlPanel} d-flex flex-row me-3` },
            React.createElement("div", { className: "previewSelector ps-3 w-25 pe-2" },
                React.createElement(Select, { className: "w-100 ant-select-styled2", popupClassName: "antd-dropdown-popup-styled2", placeholder: t('PREVIEW'), onChange: onChangeActivePreview, value: previewId, size: "large" }, listPreview.map((preview) => (React.createElement(Select.Option, { value: preview.id, key: preview.id },
                    React.createElement("span", { className: "me-1" }, formatDateTime(preview.createdAt)),
                    React.createElement(ContactDisplayName, { contactId: preview.contactId })))))),
            React.createElement(Button, { className: "ms-auto  action-button", inversed: true, to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}/${templateId}?language=${language}` },
                React.createElement(IoIosArrowBack, { preserveAspectRatio: "none", className: "me-2" }),
                t('EDIT_TEMPLATE__TITLE'))),
        React.createElement(Switch, null,
            React.createElement(Route, { path: `${url}/:clientId` },
                React.createElement(PreviewResult, { className: styles.previewResults, contentHeight: contentHeight, results: previewResult?.results, isLoading: isFetching, basicUrl: url, onChange: onChangeClient })),
            React.createElement(Route, { path: `${url}` },
                React.createElement(PreviewResult, { className: styles.previewResults, contentHeight: contentHeight, results: previewResult?.results, isLoading: isFetching, basicUrl: url, onChange: onChangeClient })))));
};
export default PreviewResultContainer;
