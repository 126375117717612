import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchList } from '../actions';
const SEARCH_FORMS_QUERY_KEY = 'searchForms';
export const useSearchForms = ({ page, pageSize, fullText }) => {
    const dispatchSearchForms = useDispatch();
    return useQuery([SEARCH_FORMS_QUERY_KEY, { page, pageSize, fullText }], async () => {
        return dispatchSearchForms(fetchList(page, pageSize, fullText));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
};
