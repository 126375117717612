import { DownOutlined } from '@ant-design/icons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Dropdown } from 'antd';
import { isObject, omit } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { displayNodeParameter } from '../utils';
import ParameterList from './ParameterList';
const CollectionField = ({ disabled, value, path, onChange, parameter, node, propertiesTree }) => {
    const { t } = useI18n();
    const optionsParameter = useMemo(() => {
        return (isObject(value) ? value : {});
    }, [value]);
    const selectedOptions = useMemo(() => {
        if (!parameter.options || !parameter.options.length) {
            return [];
        }
        const filteredOptions = parameter.options.filter((option) => {
            return Object.prototype.hasOwnProperty.call(optionsParameter, option.name);
        });
        return filteredOptions;
    }, [optionsParameter, parameter.options]);
    const availableOptions = useMemo(() => {
        if (!parameter.options || !parameter.options.length) {
            return [];
        }
        let filteredOptions = parameter.options.filter((option) => {
            return !Object.prototype.hasOwnProperty.call(optionsParameter, option.name);
        });
        filteredOptions = filteredOptions.filter((option) => {
            return displayNodeParameter(option, node);
        });
        return filteredOptions;
    }, [node, optionsParameter, parameter.options]);
    const addOption = useCallback((option) => {
        const newValue = { ...optionsParameter, [option.name]: option.default || '' };
        onChange && onChange(newValue);
    }, [optionsParameter, onChange]);
    const onRemove = useCallback((parameter) => {
        const newValue = omit(optionsParameter, [parameter.name]);
        onChange && onChange(newValue);
    }, [optionsParameter, onChange]);
    const menu = useMemo(() => {
        return {
            items: availableOptions.map((option, index) => ({
                key: index,
                onClick: () => addOption(option),
                label: React.createElement("span", null, option.displayName)
            }))
        };
    }, [availableOptions, addOption]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ParameterList, { path: path, parameters: selectedOptions, disabled: disabled, node: node, className: "ms-2", onRemove: onRemove, propertiesTree: propertiesTree, onChange: (parameter, newValue) => {
                onChange && onChange({ ...value, [parameter.name]: newValue });
            } }),
        availableOptions.length ? (React.createElement(Dropdown, { className: "mb-3", arrow: false, menu: menu, trigger: ['click'] },
            React.createElement(Button, null,
                React.createElement("span", null, `+ ${t('ADD')} ${parameter.displayName}`),
                React.createElement(DownOutlined, null)))) : null));
};
export default CollectionField;
