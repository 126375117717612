import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { flatten, isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { SelectPropertyButton } from '../../../../../cms/data/components/item-property/SelectProperty';
import SortablePropertyList from '../../../../../views/components/SortablePropertyList';
export const supportedColumnPropertyTypes = ['entity', 'number', 'text'];
const PropertySelector = ({ className, disabled, value, onChange, itemComponent, definitionId, valueAdapter, customSupportedColumnPropertyTypes }) => {
    const { t } = useI18n();
    const { displayData, displayDataStatus } = useEntityDisplayData(definitionId);
    const availableOptions = useMemo(() => {
        const list = flatten(displayData?.data?.map((section) => section.values) || []);
        return list.reduce((acc, prop) => {
            acc[prop.propertyId] = prop;
            return acc;
        }, {});
    }, [displayData?.data]);
    const selectedPropertyIds = useMemo(() => {
        if (isEmpty(value) || !Array.isArray(value)) {
            return [];
        }
        // Strip out the deleted properties
        return value
            .filter((facet) => !!availableOptions[facet?.propertyId])
            .map((facet) => facet.propertyId);
    }, [value, availableOptions]);
    const addColumn = useCallback((propertyId) => {
        if (!propertyId) {
            return;
        }
        const property = availableOptions[propertyId];
        onChange([
            ...(Array.isArray(value) ? value : []),
            valueAdapter ? valueAdapter(property) : property
        ]);
    }, [availableOptions, onChange, value, valueAdapter]);
    const handleChange = useCallback((propertyIds) => {
        onChange(propertyIds.map((id) => {
            const existingValue = value.find(({ propertyId }) => propertyId === id);
            if (existingValue) {
                return existingValue;
            }
            return valueAdapter ? valueAdapter(availableOptions[id]) : availableOptions[id];
        }));
    }, [onChange, value, valueAdapter, availableOptions]);
    if (!definitionId) {
        return null;
    }
    return (React.createElement("div", { className: className },
        displayDataStatus?.isPending && React.createElement(LoadingRectangles, null),
        !isEmpty(selectedPropertyIds) && displayData && (React.createElement(SortablePropertyList, { disabled: disabled, displayData: displayData, itemComponent: itemComponent, selection: selectedPropertyIds, handleChange: handleChange, className: "z-1" })),
        React.createElement(SelectPropertyButton, { className: "my-3 w-25", disabled: disabled, label: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { size: 18, className: "me-2" }),
                t('APPS_ADD_BUTTON')), onChange: addColumn, definitionId: definitionId, includeProperty: (displayData) => (customSupportedColumnPropertyTypes || supportedColumnPropertyTypes).includes(displayData.type), isFieldDisabled: (propertyId) => selectedPropertyIds.includes(propertyId), includeNestedProperties: false })));
};
export default PropertySelector;
