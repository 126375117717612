import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes, SortOrder } from '@hypercharge/portal-utils';
import { sumBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { searchItems } from '../../cms';
import { CONTACT_CMS_DEFINITION_ID } from '../../crm';
import { useMyContact } from '../../crm/components/use-my-contact';
import queryClient from '../utils/react-query-client';
import { ACTIVITY_DEFINITION_ID, ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID } from './constants';
import { ActivityStatus } from './types';
const FETCH_UPCOMING_PAGE_SIZE = 3;
const FETCH_COMPLETED_PAGE_SIZE = 10;
const CACHE_TIME = 5000;
export const ACTIVITY_QUERY_CACHE_KEY = ['activity'];
export function invalidateActivityCache(definitionId, entityId) {
    return queryClient.invalidateQueries([...ACTIVITY_QUERY_CACHE_KEY, definitionId, entityId]);
}
function isLastPage(lastPage, pages, pageSize) {
    if (lastPage.results.length < pageSize) {
        // no more pages
        return true;
    }
    const loadedCountItems = sumBy(pages, 'results.length');
    return loadedCountItems === lastPage.totalCount;
}
export function useActivity({ definitionId, entityId, onlyMy, filterRequest, onError }) {
    const myContact = useMyContact();
    const dispatchSearchItems = useDispatch();
    const queryCacheKey = useMemo(() => {
        return [
            ...ACTIVITY_QUERY_CACHE_KEY,
            definitionId || '',
            entityId || '',
            onlyMy ? 'onlyMy' : '',
            JSON.stringify(filterRequest)
        ];
    }, [definitionId, entityId, onlyMy, filterRequest]);
    const queryRelatedTo = useMemo(() => {
        const query = {
            condition: ConditionType.or,
            filters: []
        };
        if (definitionId && entityId) {
            query.filters.push({
                condition: ConditionType.and,
                filters: [
                    {
                        field: ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID,
                        operator: FilterOperatorTypes.is,
                        data: definitionId
                    },
                    {
                        field: 'relatedToEntityId',
                        operator: FilterOperatorTypes.is,
                        data: entityId
                    }
                ]
            });
        }
        if (definitionId === CONTACT_CMS_DEFINITION_ID && entityId) {
            query.filters.push({
                field: 'contact.entityId',
                operator: FilterOperatorTypes.is,
                data: entityId
            });
        }
        return query;
    }, [definitionId, entityId]);
    const filter = useMemo(() => {
        const query = {
            condition: ConditionType.and,
            filters: []
        };
        if (queryRelatedTo.filters.length) {
            query.filters.push(queryRelatedTo);
        }
        if (filterRequest?.query.filters.length) {
            query.filters.push(filterRequest.query);
        }
        if (onlyMy && myContact.contactId) {
            query.filters.push({
                field: 'assignedTo.entityId',
                operator: FilterOperatorTypes.is,
                data: myContact.contactId
            });
        }
        return {
            sortBy: [],
            limit: FETCH_COMPLETED_PAGE_SIZE,
            responseFields: ['*'],
            languageCode: 'en',
            ...filterRequest,
            query: query
        };
    }, [filterRequest, myContact.contactId, onlyMy, queryRelatedTo]);
    const fetchItems = useCallback(async ({ pageParam = 0 }) => {
        const result = await dispatchSearchItems(searchItems(ACTIVITY_DEFINITION_ID, {
            ...filter,
            offset: pageParam
        }));
        return result;
    }, [dispatchSearchItems, filter]);
    const result = useInfiniteQuery(queryCacheKey, fetchItems, {
        staleTime: CACHE_TIME,
        cacheTime: CACHE_TIME,
        getNextPageParam: (lastPage, pages) => {
            const pageSize = filterRequest?.limit || FETCH_UPCOMING_PAGE_SIZE;
            if (isLastPage(lastPage, pages, pageSize)) {
                return;
            }
            const nextOffset = pages.length * pageSize;
            return nextOffset;
        },
        onError
    });
    return useMemo(() => {
        return [result, queryCacheKey];
    }, [result, queryCacheKey]);
}
export const useUpcomingActivity = ({ definitionId, entityId, onlyMy, conditionalQuery, searchText, limit, onError }) => {
    const { language } = useI18n();
    const activitiesFilterRequest = useMemo(() => {
        const query = {
            condition: ConditionType.and,
            filters: [
                {
                    field: 'statusItem.entityId',
                    operator: FilterOperatorTypes.isNot,
                    data: ActivityStatus.Completed
                }
            ]
        };
        if (conditionalQuery && conditionalQuery.filters.length) {
            query.filters.push(conditionalQuery);
        }
        return {
            query: query,
            sortBy: [
                {
                    field: 'dueDate',
                    order: SortOrder.asc
                },
                {
                    field: 'createdAt',
                    order: SortOrder.asc
                }
            ],
            limit: limit != undefined && limit >= 0 ? limit : FETCH_UPCOMING_PAGE_SIZE,
            fullText: searchText,
            fullTextFields: ['title', 'assignedTo.title', 'comments', 'contact.title'],
            responseFields: ['*'],
            languageCode: language
        };
    }, [conditionalQuery, language, limit, searchText]);
    return useActivity({
        definitionId,
        entityId,
        onlyMy,
        filterRequest: activitiesFilterRequest,
        onError
    });
};
export const useCompletedActivity = ({ definitionId, entityId, onlyMy, conditionalQuery, searchText, limit, onError }) => {
    const { language } = useI18n();
    const activitiesFilterRequest = useMemo(() => {
        const query = {
            condition: ConditionType.and,
            filters: [
                {
                    field: 'statusItem.entityId',
                    operator: FilterOperatorTypes.is,
                    data: ActivityStatus.Completed
                }
            ]
        };
        if (conditionalQuery && conditionalQuery.filters.length) {
            query.filters.push(conditionalQuery);
        }
        return {
            query: query,
            sortBy: [
                {
                    field: 'statusUpdatedAt',
                    order: SortOrder.desc
                }
            ],
            limit: limit != undefined && limit >= 0 ? limit : FETCH_COMPLETED_PAGE_SIZE,
            fullText: searchText,
            fullTextFields: ['title', 'assignedTo.title', 'comments', 'contact.title'],
            responseFields: ['*'],
            languageCode: language
        };
    }, [conditionalQuery, language, limit, searchText]);
    return useActivity({
        definitionId,
        entityId,
        onlyMy,
        filterRequest: activitiesFilterRequest,
        onError
    });
};
