import React from 'react';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import styles from './ActionsBlock.module.scss';
import { Loader } from './styled';
const ActionsBlock = ({ saving, position, onAccept, onCancel, changed }) => (React.createElement("div", { className: `${styles.actions} editor-actions-block ${position === 'top' ? 'pos-top' : 'pos-bottom'}` }, !saving ? (React.createElement(React.Fragment, null,
    React.createElement("button", { tabIndex: -1, disabled: !changed, className: `action-button ok ${!changed ? 'disabled' : ''}`, onClick: onAccept },
        React.createElement(FaCheckCircle, null)),
    React.createElement("button", { tabIndex: -1, className: "action-button cancel", onClick: onCancel },
        React.createElement(FaMinusCircle, null)))) : (React.createElement(Loader, null))));
export default ActionsBlock;
