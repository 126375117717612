import { isArray, isBoolean, isNumber, isObject, isString } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ShowMore from '../../../../../../common/components/show-more';
import { FieldWrapper } from '../../FieldElements';
import stylesLocal from './JsonDisplay.module.scss';
const JsonDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => {
    const [data, setData] = useState({ root: value });
    const [collapsed, setCollapsed] = useState(true);
    useEffect(() => {
        setData({ root: value });
    }, [value]);
    const toggleShowMore = useCallback((e) => {
        e.stopPropagation();
        setCollapsed((prevValue) => !prevValue);
    }, []);
    const theme = useMemo(() => ({
        primary: style?.backgroundColor,
        secondary: style?.color
    }), [style]);
    const basicClasses = `${style?.color ? 'with-color' : ''} ${style?.backgroundColor ? 'with-background-color' : ''}`;
    const isSimple = !asField;
    const wrapperClassName = `${basicClasses} ${stylesLocal.outerSimple} ${!isSimple ? stylesLocal.outer : ''} JsonDisplayOuter${isSimple ? 'Simple' : ''} p-3`;
    if (data.root == null) {
        return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, disabled: disabled, className: `${className ?? ''} ${wrapperClassName}`, simple: isSimple, style: style }, '\u00a0'));
    }
    const elems = [];
    recursiveParseData('root', '', data, elems, 0, true);
    const numLines = elems.filter((e) => e.type === 'br').length;
    if (numLines >= maxCollapsedLines && collapsed) {
        let visibleLines = 0;
        const visibleElems = [];
        elems.forEach((e) => {
            if (visibleLines < maxCollapsedLines) {
                // @ts-expect-error
                visibleElems.push(e);
                if (e.type === 'br') {
                    visibleLines += 1;
                }
            }
        });
        return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, disabled: disabled, className: `${className ?? ''} ${wrapperClassName}`, simple: isSimple, style: style },
            React.createElement("div", { className: "code-outer text-ellipsis" }, visibleElems),
            React.createElement(ShowMore, { collapsed: collapsed, onClick: toggleShowMore, theme: theme })));
    }
    return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, disabled: disabled, className: `${className ?? ''} ${wrapperClassName}`, simple: isSimple, style: style },
        elems,
        React.createElement(ShowMore, { collapsed: collapsed, onClick: toggleShowMore, theme: theme })));
};
export default JsonDisplay;
const marginLeftStep = 2;
const maxCollapsedLines = 4;
const styles = {
    builtin: {
        color: 'var(--infoColor-8)'
    },
    text: {
        color: 'var(--successColor-8)'
    },
    number: {
        color: 'var(--neutralColor-6)'
    },
    property: {
        color: 'var(--errorColor-5)'
    },
    collapseIcon: {
        cursor: 'pointer'
    }
};
const printSpaces = (marginLeft) => {
    // we would have used css to set a margin left
    // but that makes the json lose its formatting when copied
    const spaces = [];
    for (let x = 0; x < marginLeft; x++) {
        spaces.push(React.createElement("span", { key: x }, "\u00A0"));
    }
    return React.createElement("span", null, spaces);
};
const getDataType = (data) => {
    if (isArray(data)) {
        return 'array';
    }
    if (isObject(data)) {
        return 'object';
    }
    if (isNumber(data)) {
        return 'number';
    }
    if (isString(data)) {
        return 'string';
    }
    if (isBoolean(data)) {
        return 'boolean';
    }
    return 'builtin';
};
const getKey = (prefix, currentKey, parentKeyPath, marginLeft) => `${prefix}_${parentKeyPath}_${currentKey}_${marginLeft}`;
const Label = ({ marginLeft, value, type, isLastSibling }) => {
    let style;
    switch (type) {
        case 'number':
            style = styles.number;
            if (!isLastSibling) {
                value = value + ',';
            }
            break;
        case 'boolean':
            style = styles.builtin;
            value = value + ''; // coerce boolean to string, seems you cant return booleans in react elements
            if (!isLastSibling) {
                value = value + ',';
            }
            break;
        case 'property':
            style = styles.property;
            value = '"' + value + '":'; // add quotes to string
            break;
        case 'builtin':
            style = styles.builtin;
            value = value + '';
            if (!isLastSibling) {
                value = value + ',';
            }
            break;
        default:
            style = styles.text;
            if (isLastSibling) {
                value = '"' + value + '"';
            }
            else {
                value = '"' + value + '",';
            }
    }
    return (React.createElement("span", { style: style },
        printSpaces(marginLeft),
        value));
};
const getLabelJsx = (value, type, marginLeft, isLastSibling, currentKey, parentKeyPath) => (React.createElement(Label, { key: getKey('label', currentKey + value, parentKeyPath, marginLeft), value: value, type: type, marginLeft: marginLeft, isLastSibling: isLastSibling }));
const LabelAndValue = ({ currentKey, marginLeft, value, type, isLastSibling }) => (React.createElement("span", { key: `label_and_value_${currentKey}` },
    React.createElement(Label, { value: currentKey, type: "property", isLastSibling: isLastSibling, marginLeft: marginLeft }),
    React.createElement(Label, { value: value, type: type, isLastSibling: isLastSibling, marginLeft: 1 })));
const getLabelAndValueJsx = (currentKey, parentKeyPath, value, parent, type, marginLeft, isLastSibling) => {
    if (isArray(parent)) {
        // for arrays we dont show keys
        return getLabelJsx(value, type, marginLeft, isLastSibling, currentKey, parentKeyPath);
    }
    return (React.createElement(LabelAndValue, { key: getKey('label_and_value', currentKey, parentKeyPath, marginLeft), currentKey: currentKey, value: value, type: type, marginLeft: marginLeft, isLastSibling: isLastSibling }));
};
const recursiveParseData = (currentKey, parentKeyPath, parent, elems, marginLeft, isLastSibling) => {
    const data = parent[currentKey];
    switch (getDataType(data)) {
        case 'array':
            parseArray(currentKey, parentKeyPath, data, parent, elems, marginLeft, isLastSibling);
            break;
        case 'object':
            parseObject(currentKey, parentKeyPath, data, parent, elems, marginLeft, isLastSibling);
            break;
        case 'number':
            elems.push(getLabelAndValueJsx(currentKey, parentKeyPath, data, parent, 'number', marginLeft, isLastSibling));
            break;
        case 'string':
            elems.push(getLabelAndValueJsx(currentKey, parentKeyPath, data, parent, 'text', marginLeft, isLastSibling));
            break;
        case 'boolean':
            elems.push(getLabelAndValueJsx(currentKey, parentKeyPath, data, parent, 'boolean', marginLeft, isLastSibling));
            break;
        default:
            elems.push(getLabelAndValueJsx(currentKey, parentKeyPath, data, parent, 'builtin', marginLeft, isLastSibling));
    }
};
const parseObject = (currentKey, parentKeyPath, data, parent, elems, marginLeft, isLastSibling) => {
    parentKeyPath = parentKeyPath + '_' + currentKey;
    if (marginLeft > 0) {
        // special case to avoid showing root
        elems.push(getLabelAndValueJsx(currentKey, parentKeyPath, '{', parent, 'builtin', marginLeft, true) // opening object tag
        );
    }
    else {
        elems.push(getLabelJsx('{', 'builtin', marginLeft, true, currentKey, parentKeyPath) // opening object tag
        );
    }
    const keys = Object.keys(data);
    let count = 0;
    const prevIsLastSibling = isLastSibling;
    keys.forEach((key) => {
        isLastSibling = ++count === keys.length;
        elems.push(React.createElement("br", { key: getKey('break', key, parentKeyPath, marginLeft) }));
        recursiveParseData(key, parentKeyPath, data, elems, marginLeft + marginLeftStep, isLastSibling);
    });
    elems.push(React.createElement("br", { key: getKey('break', currentKey, parentKeyPath, marginLeft) }));
    elems.push(getLabelJsx('}', 'builtin', marginLeft, prevIsLastSibling, currentKey, parentKeyPath)); // closing object tag
};
const parseArray = (currentKey, parentKeyPath, data, parent, elems, marginLeft, isLastSibling) => {
    parentKeyPath = parentKeyPath + '_' + currentKey;
    if (marginLeft > 0) {
        elems.push(getLabelAndValueJsx(currentKey, parentKeyPath, '[', parent, 'builtin', marginLeft, true) // opening array tag
        );
    }
    else {
        elems.push(getLabelJsx('[', 'builtin', marginLeft, true, currentKey, parentKeyPath) // opening array tag
        );
    }
    const prevIsLastSibling = isLastSibling;
    for (let key = 0; key < data.length; key++) {
        isLastSibling = key === data.length - 1;
        elems.push(React.createElement("br", { key: getKey('break', key.toString(), parentKeyPath, marginLeft) }));
        recursiveParseData(key.toString(), parentKeyPath, data, elems, marginLeft + marginLeftStep, isLastSibling);
    }
    elems.push(React.createElement("br", { key: getKey('break', currentKey, parentKeyPath, marginLeft) }));
    elems.push(getLabelJsx(']', 'builtin', marginLeft, prevIsLastSibling, currentKey, parentKeyPath)); // closing array tag
};
