import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import { values } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../../../auth';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { Option, Select } from '../../../../../../common/components/Select';
import { PropertyTypes } from '../../../../../../common/types';
import { getScrollableAreaOrBody } from '../../../../../../common/utils/common';
import { MULTI_LANGUAGE_PROPERTY_PERMISSION } from '../../../../../../common/utils/constants';
import { dropdownStyle } from '../../../../../../common/utils/stylingUtils';
import { getProperties } from '../../../../utils';
import { getMetaDefinitionFromFormValue, getPropertyTypeComponent, isFormValueMetaDefinitionPropertyT } from '../../utils';
import styles from './TranslationsSelectorTab.module.scss';
export const useTranslationsSelectorTab = (value, formValues) => {
    const metaDefinition = useMemo(() => getMetaDefinitionFromFormValue(formValues), [formValues]);
    const multiLanguagePermission = useSelector((s) => hasTenantPermission(s, MULTI_LANGUAGE_PROPERTY_PERMISSION));
    const isPropertyType = isFormValueMetaDefinitionPropertyT(value);
    const propertyId = isPropertyType ? value.propertyId : undefined;
    const isPropertyTranslation = useMemo(() => propertyId &&
        isPropertyType &&
        metaDefinition.sections.some((section) => section.properties.some((property) => values(property?.meta?.translations || {}).includes(propertyId))), [isPropertyType, metaDefinition.sections, propertyId]);
    const isVisible = isPropertyType && value.type === PropertyTypes.text;
    return { isVisible: isVisible && !isPropertyTranslation && multiLanguagePermission };
};
const TranslationsSelectorTab = ({ disabled, languages = AVAILABLE_LANGUAGES, defaultLanguage = DEFAULT_LANGUAGE, value, onChange, values }) => {
    const { t, language: selectedLanguage } = useI18n();
    const filteredProperties = useMemo(() => getProperties(getMetaDefinitionFromFormValue(values)).filter((prop) => prop.type === value.type), [value.type, values]);
    const _onChange = useCallback((newValue, selectLanguage) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                translations: {
                    ...value.meta.translations,
                    [selectLanguage]: newValue
                }
            }
        });
    }, [onChange, value]);
    return (React.createElement("div", { className: `${styles.container} w-100 overflow-hidden` }, languages.map((language) => (React.createElement("div", { key: language, className: "d-flex align-items-center justify-content-between mb-3" },
        React.createElement(FieldLabel, { label: t(language), className: "ms-3" }),
        React.createElement("div", { className: "w-50" },
            React.createElement(Select, { showSearch: true, allowClear: true, defaultValue: value.meta?.translations && value.meta.translations[language], disabled: disabled || language === defaultLanguage, getPopupContainer: getScrollableAreaOrBody, dropdownStyle: dropdownStyle, value: defaultLanguage === language
                    ? value.propertyId
                    : value.meta.translations && value.meta.translations[language], onChange: (newValue) => _onChange(newValue, language) }, filteredProperties.map((prop) => {
                const Icon = getPropertyTypeComponent(prop.type);
                return (React.createElement(Option, { key: prop.propertyId, value: prop.propertyId, disabled: prop.propertyId === value.propertyId },
                    Icon && React.createElement(Icon, { className: "me-2" }),
                    getTranslation(prop.labels, selectedLanguage) || t('NO_TITLE')));
            }))))))));
};
export default TranslationsSelectorTab;
