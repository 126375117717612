import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { times } from 'lodash';
import React, { useEffect } from 'react';
import { useSearchItems } from '../../../cms/hooks/useSearchItems';
import { SETTINGS_PATH } from '../../constants';
import App from './components/App';
import { HYPER_APPS_DEFINITION_ID } from './constants';
const AppsPage = ({ setLinks, setCurrentBreadcrumbText }) => {
    const { t } = useI18n();
    const { data: { results: apps } = {}, isPending: isLoading } = useSearchItems({
        definitionId: HYPER_APPS_DEFINITION_ID,
        filterRequest: {
            responseFields: ['title', 'configuration'],
            limit: 500
        }
    });
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('APPS'));
    }, [setLinks, setCurrentBreadcrumbText, t]);
    return (React.createElement("div", { className: "p-4 with-background" },
        React.createElement("p", { className: "fw-bold fs-4 mb-2" }, t('APPS_TITLE')),
        React.createElement("p", { className: "fs-6 mb-4" }, t('APPS_SUBTITLE')),
        React.createElement("div", { className: "row" }, isLoading ? (React.createElement(Skeleton, { isLoading: true },
            React.createElement("div", { className: "row" }, times(4, (idx) => (React.createElement("div", { className: "col-md-4 col-sm-6 col-xs-12 pb-2", key: idx },
                React.createElement(TextPlaceholder, { key: idx, heightInPx: 170, radiusInPx: 4 }))))))) : (apps?.map((app, index) => (React.createElement("div", { className: "col-md-4 col-sm-6 col-xs-12 pb-2", key: index },
            React.createElement(App, { app: app }))))))));
};
export default AppsPage;
