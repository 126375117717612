import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { find, get, map, partition, sortBy, toLower, toUpper } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdGitNetwork } from 'react-icons/io';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import { getAuthJwt, getUserTenantPermissions, hasPermission } from '../../../../auth';
import { TITLE_PROPERTY_ID } from '../../../../cms';
import ItemsContainer from '../../../../cms/data/components/items/ItemsContainer';
import useDisplayProcessMetaList from '../../../../cms/hooks/useDisplayProcessMetaList';
import HeightContext, { HeightContextProvider } from '../../../../common/components/height-context';
import { CollapsibleContainer } from '../../../../common/components/side-menu';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import { getConfigsState } from '../../../../common/reducers/rootSelector';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, SETTINGS_PATH, VIEW_PATH } from '../../../../common/utils/constants';
import { getEntityBrowseUrl } from '../../../../common/utils/url';
import { fetchConfig, getConfig, isFetchConfigPending } from '../../../../configs';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { ParentOwnerTranslationKeyContext } from '../../../../groups';
import { getMenu, getNestedMenuCmsReferences } from '../../../../header';
import { CMS_KEY, SETTINGS_KEY } from '../../../../settings';
import { useViews } from '../../../../views';
import EditableViewTitleComponent from '../../../../views/components/EditableViewTitleComponent';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../common/utils/constants';
import ProcessMetaFormContainer from '../process-meta-form/ProcessMetaFormContainer';
import ProcessMetaRoutes from './ProcessMetaRoutes';
import styles from './Routes.module.scss';
const Routes = ({ currentUrl, createProcessPath, cmsConfig, isCmsConfigPending, userPermissions, dispatch }) => {
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const { contactId } = useMyContact();
    const { data: displayProcessMetaList, isPending, isFailed, isAvailable: isAvailableProcessMetaList } = useDisplayProcessMetaList();
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const [extraTextForBreadcrumbsComponent, setExtraTextForBreadcrumbsComponent] = useState();
    const getProcessTitle = useCallback((processMetaId) => get(find(displayProcessMetaList, { processMetaId }), TITLE_PROPERTY_ID) ||
        (isPending ? '...' : t('NO_TITLE')), [displayProcessMetaList, isPending, t]);
    const { views, loadingViews } = useViews();
    const hasAdminPermission = hasPermission(userPermissions, APP_ADMIN_PERMISSION);
    useEffect(() => {
        if (!cmsConfig && !isCmsConfigPending) {
            dispatch(fetchConfig(SETTINGS_KEY, CMS_KEY));
        }
    }, [cmsConfig, isCmsConfigPending, dispatch]);
    const getSideMenuSectionTitle = useCallback((definitionId) => {
        const headerMenu = getMenu((permission) => hasPermission(userPermissions, permission), map(displayProcessMetaList, 'processMetaId'));
        const headerMenuReference = find(getNestedMenuCmsReferences(headerMenu), {
            definitionId
        });
        if (headerMenuReference) {
            return headerMenuReference.menuTitle;
        }
        if ((cmsConfig?.data?.featuredDefinitionIds || []).includes(definitionId)) {
            return t('SIDE_MENU__FEATURED_ITEMS');
        }
        return null;
    }, [cmsConfig, displayProcessMetaList, t, userPermissions]);
    const [sideMenuItems, sectionSortOrderIndexMap] = useMemo(() => {
        let items = [];
        const sectionSortOrderIndexMap = {};
        if (!isAvailableProcessMetaList || isCmsConfigPending || loadingViews) {
            return [items, sectionSortOrderIndexMap];
        }
        const [aggregates, otherProcesses] = partition(displayProcessMetaList, ({ processMetaId }) => [ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(processMetaId));
        items = aggregates.map(({ processMetaId, title = '' }) => ({
            key: processMetaId,
            exact: false,
            to: `${currentUrl}/${processMetaId}${BROWSE_PATH}`,
            title,
            sectionTitle: t('ALL_PROCESSES'),
            icon: IoMdGitNetwork
        }));
        items = [
            ...items,
            ...otherProcesses.map(({ processMetaId, idPrefix, title = '', style }) => {
                let sideMenuItem = {
                    key: processMetaId,
                    exact: false,
                    to: `${currentUrl}/${processMetaId}${BROWSE_PATH}`,
                    title,
                    subTitle: toUpper(idPrefix),
                    icon: IoMdGitNetwork,
                    style
                };
                if (hasAdminPermission) {
                    sideMenuItem = {
                        ...sideMenuItem,
                        toSettings: `${currentUrl}/${processMetaId}${SETTINGS_PATH}`
                    };
                }
                const sectionTitle = getSideMenuSectionTitle(processMetaId);
                if (sectionTitle) {
                    sideMenuItem = { ...sideMenuItem, sectionTitle };
                }
                return sideMenuItem;
            }),
            ...views
                .filter(({ referenceDefinitionId, entityOwner, isDefault }) => !!find(displayProcessMetaList, { processMetaId: referenceDefinitionId }) &&
                entityOwner === contactId &&
                !isDefault)
                .map(({ title, referenceDefinitionId, entityId }) => {
                const baseEntity = find(displayProcessMetaList, {
                    processMetaId: referenceDefinitionId
                });
                return {
                    key: entityId,
                    exact: false,
                    to: `${currentUrl}/${referenceDefinitionId}${VIEW_PATH}/${entityId}`,
                    title,
                    subTitle: get(baseEntity, TITLE_PROPERTY_ID),
                    icon: IoMdGitNetwork,
                    sectionTitle: t('VIEWS__SIDE_MENU_SECTION_TITLE')
                };
            })
        ];
        sectionSortOrderIndexMap[t('ALL_PROCESSES')] = 1;
        sectionSortOrderIndexMap[t('SIDE_MENU__FEATURED_ITEMS')] = 2;
        sectionSortOrderIndexMap[t('VIEWS__SIDE_MENU_SECTION_TITLE')] = 3;
        return [sortBy(items, [(o) => toLower(o.title)]), sectionSortOrderIndexMap];
    }, [
        isAvailableProcessMetaList,
        isCmsConfigPending,
        loadingViews,
        currentUrl,
        t,
        displayProcessMetaList,
        views,
        hasAdminPermission,
        getSideMenuSectionTitle,
        contactId
    ]);
    const getContentJsx = useCallback((contentHeight) => (React.createElement(CollapsibleContainer, { sectionName: "workflows", title: t('WORKFLOWS_PROCESSES_NAV_LINK'), createPath: hasAdminPermission ? createProcessPath : undefined, headerIcon: IoMdGitNetwork, items: sideMenuItems, sectionSortOrderIndexMap: sectionSortOrderIndexMap, isFailed: isFailed, isLoading: isPending || isCmsConfigPending || loadingViews, withSearch: true, withFeaturedEntitiesSwitch: true },
        React.createElement(ParentOwnerTranslationKeyContext.Provider, { value: "WORKFLOWS_SETTINGS_PROCESS_OWNER" },
            React.createElement(Container, null,
                React.createElement(Switch, null,
                    hasAdminPermission && (React.createElement(Route, { exact: true, path: createProcessPath, render: () => (React.createElement(SectionCard, { className: styles.sectionCard, title: t('BASIC_INFO'), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true },
                            React.createElement(Content, { className: styles.sectionCard__content, height: contentHeight },
                                React.createElement(ProcessMetaFormContainer, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText })))) })),
                    React.createElement(Route, { exact: true, path: [
                            `${currentUrl}/:processMetaId${BROWSE_PATH}`,
                            `${currentUrl}/:processMetaId${VIEW_PATH}/:viewId`
                        ], render: (props) => {
                            const { processMetaId } = props.match.params;
                            return (React.createElement(ItemsContainer, { baseUrl: `${currentUrl}/${processMetaId}`, canDelete: !!find(displayProcessMetaList, { processMetaId, canDelete: true }), canRun: !!find(displayProcessMetaList, { processMetaId, canExecute: true }), definitionId: processMetaId, key: processMetaId, processTitle: get(find(displayProcessMetaList, { processMetaId }), 'title'), setCurrentBreadcrumbText: setCurrentBreadcrumbText, setExtraTextForBreadcrumbsComponent: setExtraTextForBreadcrumbsComponent, setLinks: setLinks, ...props }));
                        } }),
                    hasAdminPermission && (React.createElement(Route, { path: `${currentUrl}/:processMetaId${SETTINGS_PATH}`, render: (props) => {
                            const { processMetaId } = props.match.params;
                            return (React.createElement(ProcessMetaRoutes, { key: processMetaId, contentHeight: contentHeight, processMetaId: processMetaId, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, baseUrl: `${currentUrl}/${processMetaId}`, ...props }));
                        } })),
                    React.createElement(Redirect, { to: getEntityBrowseUrl(ALL_PROCESSES_DEFINITION_ID, currentUrl) })))))), [
        t,
        hasAdminPermission,
        createProcessPath,
        sideMenuItems,
        sectionSortOrderIndexMap,
        isFailed,
        isPending,
        isCmsConfigPending,
        loadingViews,
        currentUrl,
        displayProcessMetaList
    ]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
            React.createElement(Switch, null,
                React.createElement(Route, { path: [`${currentUrl}/:definitionId${SETTINGS_PATH}`, `${currentUrl}/create`], render: () => (React.createElement(Breadcrumbs, { links: links, currentBreadcrumbText: currentBreadcrumbText })) }),
                React.createElement(Route, { path: [
                        `${currentUrl}/:processMetaId${BROWSE_PATH}`,
                        `${currentUrl}/:processMetaId${VIEW_PATH}/:viewId`
                    ], render: (props) => {
                        const { processMetaId, viewId } = props.match.params;
                        return (React.createElement(Breadcrumbs, { currentBreadcrumbComponent: React.createElement(EditableViewTitleComponent, { definitionId: processMetaId, viewId: viewId, totalItemsCount: extraTextForBreadcrumbsComponent, processTitle: getProcessTitle(processMetaId) }), links: links }));
                    } }))),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => getContentJsx(isDesktop ? contentHeight : undefined)))));
};
const mapStateToProps = (s, { match: { url } }) => {
    const configsState = getConfigsState(s);
    return {
        currentUrl: url,
        createProcessPath: `${url}/create`,
        cmsConfig: getConfig(configsState, SETTINGS_KEY, CMS_KEY),
        isCmsConfigPending: isFetchConfigPending(configsState, SETTINGS_KEY, CMS_KEY),
        userPermissions: getUserTenantPermissions(getAuthJwt(s))
    };
};
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(connect(mapStateToProps))(Routes);
//
// Utils
//
const Container = styled.div `
  position: relative;

  ${media.xl} {
    margin-right: 0.5rem;
  }
`;
const Content = styled.div `
  height: ${(props) => props.height || null};
`;
const styledSectionCardHeaderStyle = { padding: '11px 16px 13px' };
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 0;
