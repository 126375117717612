import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ReadableDateTime } from '@hypercharge/hyper-react-base/lib/utils';
import { Collapse } from 'antd';
import React from 'react';
import { PoseGroup } from 'react-pose';
import { Item, LoadingCommonBar } from '../..';
import { TooltipTruncate } from '../../../../../../../common/components/TooltipTruncate';
import { ContactDisplayName } from '../../../../../../../crm';
import CountdownTimer from '../../../../../../common/components/CountdownTimer/CountdownTimer';
const OngoingTasksStepDescription = ({ ongoingTasks, loading, activeKeys, handlePanelChange }) => {
    const { t } = useI18n();
    return ongoingTasks.length > 0 ? (React.createElement(PoseGroup, null,
        React.createElement(Collapse, { key: "OngoingTasksStepDescription", ghost: true, onChange: handlePanelChange, activeKey: activeKeys, items: ongoingTasks.map(({ taskTitle, statusUpdatedAt, assignedBy, assignedTo, resolveTime, taskRunId }) => ({
                key: taskRunId,
                label: React.createElement(TooltipTruncate, null, taskTitle),
                children: (React.createElement(Item, { key: taskRunId },
                    React.createElement("div", { key: `initiatedAt_${taskRunId}` },
                        React.createElement("div", null, resolveTime && React.createElement(CountdownTimer, { resolveTime: resolveTime })),
                        React.createElement("div", null, assignedBy && assignedBy !== assignedTo && (React.createElement("div", { className: "SmallText" },
                            React.createElement("span", { className: "me-2" }, t('ASSIGNED_BY')),
                            React.createElement("span", { className: "fw-bold" },
                                React.createElement(ContactDisplayName, { contactId: assignedBy }))))),
                        React.createElement("div", null, assignedTo && (React.createElement("div", { className: "SmallText" },
                            React.createElement("span", { className: "me-2" }, t('ASSIGNED_TO')),
                            React.createElement("span", { className: "fw-bold" },
                                React.createElement(ContactDisplayName, { contactId: assignedBy }))))),
                        React.createElement("div", null,
                            React.createElement("div", { className: "SmallText" },
                                React.createElement("span", { className: "me-2" }, t('INITIATED_AT')),
                                React.createElement("span", { className: "fw-bold" },
                                    React.createElement(ReadableDateTime, { dateTime: statusUpdatedAt }))))))),
                className: 'CustomCollapsePanel'
            })) }))) : (React.createElement(LoadingCommonBar, { loading: loading && !ongoingTasks.length }));
};
export default OngoingTasksStepDescription;
