import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { SortablePropertyListField } from '../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../common/components/AntInput';
import { ComputedInputField } from '../../../../../common/components/ComputedExpressionEditor';
import { usePropertiesTree } from '../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import { ConditionQueryField } from '../../../../../common/components/ConditionQuery';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../common/components/Toggle';
import { ComputedExpressionsDocumentationLink } from '../../../../../common/components/documentation-link';
import { ItemMetaSelector } from '../../../../../common/components/entity-selectors';
import { PropertyTypes } from '../../../../../common/types';
import GroupsField from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
import NodeSettingsSection from './NodeSettingsSection';
const isSupportedEntityProperty = ({ type, meta }, node) => type === PropertyTypes.entity && meta.definitionId === node.parameters?.definitionId;
const GetEntityValuesForm = ({ disabled, metaDefinitionId, parentViewGroups, changeFieldValue, node }) => {
    const { t } = useI18n();
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", name: "titles", placeholder: t('COMMON__TITLE'), component: AntInput, disabled: disabled })),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('ENTITY'), className: "ps-0" }),
            React.createElement(Field, { name: "definitionId", className: "w-100", component: ItemMetaSelector, disabled: disabled, validate: required, autoFocus: false, onChange: (_, newValue, previousValue, name) => {
                    if (!name) {
                        return;
                    }
                    if (newValue != previousValue) {
                        // Reset the conditionQuery and property mappings that were based on the previously selected process
                        const entityItemsFilterFieldName = name?.replace('definitionId', 'conditionQuery') || '';
                        changeFieldValue(entityItemsFilterFieldName, {});
                        changeFieldValue(name?.replace('definitionId', 'responseFields'), []);
                        changeFieldValue(name?.replace('definitionId', 'processPropertyId'), null);
                    }
                } }),
            node.parameters?.definitionId ? (React.createElement(React.Fragment, null,
                React.createElement(FieldLabel, { label: t('TASK_SET_ENTITY_VALUES_FILTER'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "ps-0 mt-2" }),
                React.createElement(Field, { name: "conditionQuery", component: ConditionQueryField, definitionId: node.parameters.definitionId, allowComputed: true, propertiesTree: propertiesTree }),
                React.createElement(FieldLabel, { label: t('TASK_GET_ENTITY_OUTPUT_PROPERTY_ID'), className: "ps-0" }),
                React.createElement(Field, { name: "processPropertyId", disabled: disabled || !!node?.parameters?.processPropertyId, component: SortablePropertyListField, definitionId: metaDefinitionId, includeNestedProperties: false, includeProperty: (displayData) => isSupportedEntityProperty(displayData, node), sortable: false, parse: (newValue) => (newValue.length ? newValue[0] : null), format: (value) => (value ? [value] : []) }))) : null,
            node.parameters && !node.parameters.processPropertyId && node.parameters.definitionId ? (React.createElement(React.Fragment, null,
                React.createElement(FieldLabel, { label: t('TASK_GET_ENTITY_RESPONSE_FIELDS'), className: "ps-0" }),
                React.createElement(Field, { name: "responseFields", disabled: disabled, component: SortablePropertyListField, includeNestedProperties: false, sortable: false, definitionId: node.parameters.definitionId }),
                React.createElement(FieldLabel, { label: t('TASK_GET_ENTITY_IS_EXPANDED_ENTITY'), className: "ps-0" }),
                React.createElement(Field, { name: "expanded", component: ComputedInputField, computedComponent: Toggle, disabled: disabled, propertiesTree: propertiesTree }))) : null),
        React.createElement(Field, { name: "settings", component: NodeSettingsSection, propertiesTree: propertiesTree, disabled: disabled }),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default GetEntityValuesForm;
