import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import { assign, get, reduce } from 'lodash';
import { locale } from 'moment';
import { func, string } from 'prop-types';
import { parse } from 'query-string';
import React, { Component, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import i18n from './i18n';
import { i18nChangeLanguageBySystem } from './i18nActions';
import { getCurrentLanguage, getManuallyChanged } from './i18nReducerFactory';
const determineLanguage = (stateLanguage, stateLanguageManuallyChanged, location, defaultLanguage) => parse(location.search).lang ||
    (stateLanguageManuallyChanged && stateLanguage) ||
    defaultLanguage;
const getMessagesByLang = (messages, lang) => assign({}, ...messages.map((o) => get(o, lang, {})));
let projectMessages;
const getMessageLoaders = (availableLanguages, extendedMessages) => reduce(availableLanguages, (acc, lang) => ({
    ...acc,
    [lang]: async () => {
        return assign(getMessagesByLang(extendedMessages, lang));
    }
}), {});
const MOMENT_BUILT_IN_LOCALE = DEFAULT_LANGUAGE;
const I18nContext = createContext(undefined);
export const useI18n = () => {
    const context = useContext(I18nContext);
    if (context === undefined) {
        throw new Error('useI18n must be used within an I18nProvider');
    }
    return context;
};
class I18nProviderInternal extends Component {
    constructor(props) {
        super(props);
        this.unmounted = false;
        this.toggleBootstrapped = () => {
            if (!this.unmounted) {
                this.setState({ bootstrapped: !this.state.bootstrapped });
            }
        };
        this.getMomentLocaleLoaders = (availableLanguages = AVAILABLE_LANGUAGES) => reduce(availableLanguages, (acc, lang) => ({
            ...acc,
            [lang]: async () => {
                try {
                    if (lang !== MOMENT_BUILT_IN_LOCALE) {
                        require(`moment/locale/${lang}`);
                    }
                }
                catch (e) {
                    /* eslint no-console: 0*/
                    console.error(e);
                }
                locale(lang);
            }
        }), {});
        this.getChildContext = () => ({
            t: i18n.t,
            language: this.props.language
        });
        this.loadLocales = async () => {
            const { messageLoaders, language } = this.props;
            const getMessageLoader = messageLoaders[language];
            const langMessages = await getMessageLoader();
            if (language) {
                i18n.addResources(language, 'translation', langMessages);
                i18n.changeLanguage(language);
            }
            await this.momentLocaleLoaders[language]();
            this.toggleBootstrapped();
        };
        this.state = { bootstrapped: false };
        this.momentLocaleLoaders = this.getMomentLocaleLoaders(props.availableLanguages);
    }
    componentDidMount() {
        this.loadLocales();
        if (this.props.language !== this.props.stateLanguage) {
            this.props.i18nChangeLanguageBySystem(this.props.language);
        }
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    render() {
        return (this.state.bootstrapped && (React.createElement(I18nContext.Provider, { value: this.getChildContext(), ...this.props })));
    }
}
I18nProviderInternal.childContextTypes = {
    t: func.isRequired,
    language: string.isRequired
};
function mapStateToProps(state, { defaultLanguage = DEFAULT_LANGUAGE, availableLanguages = AVAILABLE_LANGUAGES, getI18nState, extendedMessages = [], location }) {
    const i18nState = getI18nState(state);
    const stateLanguage = getCurrentLanguage(i18nState);
    const stateLanguageManuallyChanged = getManuallyChanged(i18nState);
    const currentLanguage = determineLanguage(stateLanguage, stateLanguageManuallyChanged, location, defaultLanguage);
    return {
        messageLoaders: getMessageLoaders(availableLanguages, extendedMessages),
        stateLanguage,
        language: currentLanguage,
        key: currentLanguage // hack to force react to reload everything inside this component, see https://github.com/yahoo/react-intl/issues/234
    };
}
export const I18nProvider = compose(withRouter, connect(mapStateToProps, { i18nChangeLanguageBySystem }))(I18nProviderInternal);
