import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import cn from 'classnames';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdDragIndicator } from 'react-icons/md';
import { SortableHandle as getSortableHandle } from 'react-sortable-hoc';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import styles from './SortablePropertyList.module.scss';
const iconStyle = { width: '1rem', height: '1rem' };
export const DragHandle = getSortableHandle(({ disabled }) => (React.createElement("div", { className: cn(styles.dragHandle, { disabled }) },
    React.createElement(MdDragIndicator, { size: 18 }))));
export const ExtendableSortableItem = ({ iconComponent: Icon, label, sectionTitle, isDisabled, handleRemove, children, className }) => {
    return (React.createElement("div", { className: cn(styles.item, 'sortableItem d-flex align-items-center justify-content-between', className) },
        React.createElement(DragHandle, { disabled: isDisabled }),
        React.createElement("div", { className: "flex-truncate d-flex align-items-center justify-content-between flex-basis-100 gap-2" },
            React.createElement("div", { className: "flex-truncate d-flex align-items-center justify-content-between flex-basis-100 gap-2" },
                React.createElement("div", { className: "flex-truncate d-flex align-items-center justify-content-between flex-basis-100 gap-2" },
                    React.createElement("div", { className: "flex-truncate d-flex align-items-center gap-2" },
                        Icon && React.createElement(Icon, { className: "icon", style: iconStyle }),
                        React.createElement(TooltipTruncate, { className: "flex-truncate title" }, label)),
                    sectionTitle && (React.createElement("div", { className: "d-flex flex-truncate mw-100" },
                        React.createElement(TooltipTruncate, { className: "flex-truncate subtitle text-end" }, sectionTitle)))),
                children),
            isDisabled ? (React.createElement("div", { className: "px-2" },
                React.createElement(FaRegTrashAlt, { color: "var(--grayscaleColor-4)", size: 24 }))) : handleRemove ? (React.createElement(DeleteConfirmationButton, { className: "removeButton", onClick: handleRemove })) : null)));
};
export default ExtendableSortableItem;
