import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { createValidator, integer, maxLength, minLength, required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Form, Formik } from 'formik';
import { padStart } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoMdCreate } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';
import { AntInputField } from '../../../common/components/AntInput';
import FormikField from '../../../common/components/formik/FormikField';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { AUTH_PATH, GET_STARTED_BY_EMAIL_PATH } from '../../constants';
import authStyles from '../AuthRoutes.module.scss';
import { Logo } from '../common/Logo';
import styles from './CodeForm.module.scss';
const formFields = {
    code: [required, integer, minLength(6), maxLength(6)]
};
const validate = createValidator(formFields);
const SEND_CODE_TIME_INTERVAL = 60;
const initialValue = { code: '' };
const CodeForm = ({ onClickSendNewCode, displayTenant, email, onSubmit, loading }) => {
    const { t } = useI18n();
    const location = useLocation();
    const intervalId = useRef(null);
    const [secondsUntilEnableResendCode, setSecondsUntilEnableResendCode] = useState(SEND_CODE_TIME_INTERVAL);
    const countDown = useCallback(() => {
        const newCount = secondsUntilEnableResendCode - 1;
        if (newCount >= 0) {
            setSecondsUntilEnableResendCode(newCount);
        }
        if (newCount === 0 && intervalId.current) {
            clearTimeout(intervalId.current);
            intervalId.current = null;
        }
    }, [secondsUntilEnableResendCode]);
    useEffect(() => {
        const interval = setTimeout(countDown, 1000);
        intervalId.current = interval;
        return () => {
            if (interval) {
                clearTimeout(interval);
                intervalId.current = null;
            }
        };
    }, [countDown]);
    const _onClickSendNewCode = useCallback(() => {
        if (secondsUntilEnableResendCode === 0) {
            void onClickSendNewCode(true);
            setSecondsUntilEnableResendCode(SEND_CODE_TIME_INTERVAL);
        }
    }, [onClickSendNewCode, secondsUntilEnableResendCode]);
    const remainingTime = secondsUntilEnableResendCode > 0
        ? `00:${padStart(secondsUntilEnableResendCode.toString(), 2, '0')}`
        : '';
    const editEmailLink = useMemo(() => {
        return {
            ...location,
            pathname: `${AUTH_PATH}${GET_STARTED_BY_EMAIL_PATH}`
        };
    }, [location]);
    return (React.createElement("div", { className: `${authStyles.authContainer} ${styles.wrapper} position-relative overflow-hidden` },
        React.createElement(Helmet, { title: `${t('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant.name}` }),
        React.createElement(Logo, null),
        React.createElement("div", { className: "mt-5" },
            React.createElement("div", { className: `${authStyles.title} fw-600 mb-2` }, t('AUTH_GET_STARTED')),
            loading ? (React.createElement(LoadingIndicator, null)) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: `${authStyles.subtitle} mb-3` },
                    React.createElement("div", null, t('AUTH_AN_AUTHENTICATION_CODE_WAS_SENT_TO')),
                    React.createElement("span", { className: "fw-600 me-1" }, email),
                    React.createElement(Link, { className: "edit-link", to: editEmailLink, "data-test-id": "email-link-login-code" },
                        React.createElement(IoMdCreate, null))),
                React.createElement(Formik, { onSubmit: onSubmit, initialValues: initialValue, validate: validate }, ({ isSubmitting }) => (React.createElement(Form, { noValidate: true },
                    React.createElement(FormikField, { name: "code", autoFocus: true, label: t('AUTH_CODE'), component: AntInputField, maxLength: 6, autoComplete: "off", placeholder: t('AUTH_CODE_PLACEHOLDER'), "data-test-id": "input-login-code" }),
                    React.createElement("div", null,
                        React.createElement(Button, { className: `${authStyles.submitButton} w-100 mt-4 p-2`, type: "submit", loading: isSubmitting, "data-test-id": "submit-login-code" }, t('COMMON__CONFIRM'))),
                    React.createElement("div", { className: "text-center mt-2" },
                        React.createElement("div", { className: `send-new-code ${secondsUntilEnableResendCode > 0 ? 'disabled' : ''}`, onClick: _onClickSendNewCode }, `${t('AUTH_SEND_NEW_CODE')} ${remainingTime}`))))))))));
};
export default CodeForm;
