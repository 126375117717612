import { compact, flatMap, isString, pick } from 'lodash';
import { isConditionQuery } from '../../../../../common/components/ConditionQuery/utils';
import { isComputedExpression } from '../../../../../utils/computedExpression';
const getValueKeysForContextFromComputedFilter = (filter) => {
    if (!filter) {
        return [];
    }
    if (isConditionQuery(filter)) {
        return flatMap(filter.filters, getValueKeysForContextFromComputedFilter);
    }
    if ('data' in filter && isString(filter.data) && isComputedExpression(filter.data)) {
        return filter.data.split('.')[0].replace('=', '');
    }
    return [];
};
export const getFilterRequestWithContextFromComputedFilters = (filterRequest, item, definitionId) => {
    if (filterRequest) {
        const valuesKeys = compact(getValueKeysForContextFromComputedFilter(filterRequest.query));
        const pickValues = pick(item, valuesKeys);
        if (Object.values(pickValues).length) {
            return {
                ...filterRequest,
                query: filterRequest.query,
                context: { definitionId, ...pickValues }
            };
        }
    }
    return filterRequest;
};
