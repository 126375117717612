import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getAccountId, getAuthJwt, getIsUiAuthenticated } from '../../../auth';
import { getUserGroups } from '../../../auth/selectors';
import { useEntityItem } from '../../../cms/hooks/useEntityItem';
import { CONTACT_CMS_DEFINITION_ID, FETCH_CONTACT_RESPONSE_FIELDS } from '../../constants';
export const useMyContact = () => {
    const contactId = useSelector(getAccountId);
    const contactGroups = useSelector((s) => getUserGroups(getAuthJwt(s)), shallowEqual);
    const isAuthenticated = useSelector(getIsUiAuthenticated);
    const { data: contact, isAvailable, isError: isFailed, isFetching: isPending } = useEntityItem(CONTACT_CMS_DEFINITION_ID, contactId ? contactId : undefined, FETCH_CONTACT_RESPONSE_FIELDS, isAuthenticated);
    return useMemo(() => {
        return {
            contactId: contactId || undefined,
            contactGroups,
            contactStatus: {
                isAvailable,
                isFailed,
                isPending
            },
            isAuthenticated,
            contact
        };
    }, [contact, contactGroups, contactId, isAuthenticated, isAvailable, isFailed, isPending]);
};
