import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AppInstancePage from './AppInstancePage';
import AppPage from './AppPage';
import styles from './Apps.module.scss';
import AppsPage from './AppsPage';
const Apps = ({ setLinks, setCurrentBreadcrumbText }) => {
    const match = useRouteMatch();
    return (React.createElement("div", { className: `${styles.container} ` },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: `${match.path}/:appId`, render: (props) => {
                    return (React.createElement(AppPage, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, ...props }));
                } }),
            React.createElement(Route, { path: [`${match.path}/:appId/:appInstanceId`], render: (props) => {
                    return (React.createElement(AppInstancePage, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, ...props }));
                } }),
            React.createElement(Route, { exact: true, path: match.path, render: (props) => (React.createElement(AppsPage, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, ...props })) }),
            React.createElement(Redirect, { to: match.path }))));
};
export default Apps;
