import { get, set } from 'lodash';
import * as NodeHelpers from 'n8n-workflow/dist/NodeHelpers';
export const displayNodeParameter = (property, node, path) => {
    if (!path) {
        path = 'parameters';
    }
    if (property.type === 'hidden') {
        return false;
    }
    if (property.displayOptions === undefined) {
        // If it is not defined no need to do a proper check
        return true;
    }
    const nodeValues = {};
    let rawValues = node;
    if (path) {
        // @ts-expect-error
        rawValues = get(node, path);
    }
    if (rawValues) {
        // Resolve expressions
        const resolveKeys = Object.keys(rawValues);
        let key;
        let i = 0;
        let parameterGotResolved = false;
        do {
            key = resolveKeys.shift();
            const val = rawValues[key];
            if (typeof val === 'string' && val.charAt(0) === '=') {
                // Contains an expression that
                if (val.includes('$parameter') &&
                    resolveKeys.some((parameterName) => val.includes(parameterName))) {
                    // Contains probably an expression of a missing parameter so skip
                    resolveKeys.push(key);
                    continue;
                }
                else {
                    // Contains probably no expression with a missing parameter so resolve
                    try {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        nodeValues[key] = resolveExpression(rawValues[key], nodeValues);
                    }
                    catch (e) {
                        // If expression is invalid ignore
                        nodeValues[key] = '';
                        throw e;
                    }
                    parameterGotResolved = true;
                }
            }
            else {
                // Does not contain an expression, add directly
                nodeValues[key] = rawValues[key];
            }
            // TODO: Think about how to calculate this best
            if (i++ > 50) {
                // Make sure we do not get caught
                break;
            }
        } while (resolveKeys.length !== 0);
        if (parameterGotResolved) {
            if (path) {
                rawValues = JSON.parse(JSON.stringify(node));
                set(rawValues, path, nodeValues);
                return NodeHelpers.displayParameterPath(rawValues, property, path, null);
            }
            else {
                return NodeHelpers.displayParameterPath(nodeValues, property, '', null);
            }
        }
    }
    return NodeHelpers.displayParameterPath(node, property, path, null);
};
function resolveExpression(expression) {
    // throw new Error('Not implemented');
    if (expression.startsWith('=')) {
        return expression.substr(1);
    }
    return expression;
}
// Returns all the credential-issues of the node
export const getNodeCredentialIssues = (node, nodeDescription, credentialTypes) => {
    if (node.disabled === true) {
        // Node is disabled
        return null;
    }
    if (!nodeDescription || !nodeDescription.credentials) {
        // Node does not need any credentials or nodeType could not be found
        return null;
    }
    const foundIssues = {};
    let credentialType;
    let credentialDisplayName;
    for (const credentialTypeDescription of nodeDescription.credentials) {
        // Check if credentials should be displayed else ignore
        if (!NodeHelpers.displayParameterPath(node.parameters, credentialTypeDescription, '', null)) {
            continue;
        }
        // Get the display name of the credential type
        credentialType = credentialTypes[credentialTypeDescription.name];
        if (!credentialType) {
            credentialDisplayName = credentialTypeDescription.name;
        }
        else {
            credentialDisplayName = credentialType.displayName;
        }
        if (!node.credentials || !node.credentials[credentialTypeDescription.name]) {
            // Credentials are not set
            if (credentialTypeDescription.required === true) {
                foundIssues[credentialTypeDescription.name] = [
                    `Credentials for "${credentialDisplayName}" are not set.`
                ];
            }
        }
    }
    // TODO: Could later check also if the node has access to the credentials
    if (Object.keys(foundIssues).length === 0) {
        return null;
    }
    return {
        credentials: foundIssues
    };
};
