import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useHeight } from '../../../../../common/components/height-context/HeightContext';
import { isCmsDefinition } from '../../../../../common/utils/url';
import { getColumnPropertyIdsFromParams, getQueryParams, useViews } from '../../../../../views';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { ENTITY_ID_PROPERTY_ID, getFlattenedDisplayDataList } from '../../../../index';
import KanbanContainer from '../Kanban/KanbanContainer';
import { memoizedGetItemsKanbanFields } from '../utils';
export const ItemsViewKanban = ({ actions, baseUrl, contentHeight: parentContentHeight, definitionId, view, noFilters, noSearch, noViewActions, viewId, customColumnsTitles, getCustomItemsGroupBy, getCustomColumnCountFilters, getCustomRouteHandleCardClick }) => {
    const { contentHeight = parentContentHeight } = useHeight();
    const { t, language } = useI18n();
    const { loadingViews } = useViews();
    const { search } = useLocation();
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const { displayData, displayDataStatus: { isPending, isAvailable } } = useEntityDisplayData(definitionId);
    const fieldPropertyIds = useMemo(() => getColumnPropertyIdsFromParams(getQueryParams(search), view || undefined), [view, search]);
    const viewableEntityProperties = useMemo(() => (displayData ? getFlattenedDisplayDataList(displayData) : []), [displayData]);
    const fields = useMemo(() => memoizedGetItemsKanbanFields(baseUrl, fieldPropertyIds, viewableEntityProperties, language), [baseUrl, fieldPropertyIds, viewableEntityProperties, language]);
    const errorMessageWhenNoColumns = useMemo(() => {
        if (isEmpty(fieldPropertyIds)) {
            return t('DATA_TABLE_NO_COLUMNS_TEXT');
        }
        if (isEmpty(viewableEntityProperties)) {
            return t('DATA_TABLE_NO_PERMISSION_TEXT');
        }
        return t('DATA_TABLE_NO_PROPERTIES_FOR_COLUMNS');
    }, [fieldPropertyIds, t, viewableEntityProperties]);
    return (React.createElement(React.Fragment, null, !isAvailable ? (React.createElement(SectionCard, { className: "section-card" },
        React.createElement("i", null, errorMessageWhenNoColumns))) : viewId && loadingViews ? null : (React.createElement(KanbanContainer, { actions: actions, baseUrl: baseUrl, contentHeight: contentHeight, definitionId: definitionId, displayData: displayData, fields: fields, noFilters: noFilters, noViewActions: noViewActions, noSearch: noSearch, idField: ENTITY_ID_PROPERTY_ID, isPending: isPending, showViewsSelector: true, viewId: viewId, customColumnsTitles: customColumnsTitles, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, getCustomItemsGroupBy: getCustomItemsGroupBy, withCmsImport: isCmsDefinition(displayItemMeta) }))));
};
