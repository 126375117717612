import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { FaFolderPlus } from 'react-icons/fa';
import { Drawer } from '../../../../../common/components/Drawer';
import { FeaturedDefinitionIdFolderForm } from '../FeaturedDefinitionIdFolderForm';
import styles from './AddFeaturedDefinitionIdFolder.module.scss';
const AddFeaturedDefinitionIdFolder = ({ isDataPending, addFeaturedDefinitionId }) => {
    const { t } = useI18n();
    const [openAddFolder, setOpenAddFolder] = useState(false);
    const onOpenAddFolderDrawerClose = useCallback(() => {
        setOpenAddFolder(false);
    }, []);
    const onOpenAddFolderDrawerOpen = useCallback(() => {
        setOpenAddFolder(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: styles.addButtonContent, onClick: onOpenAddFolderDrawerOpen, icon: React.createElement(FaFolderPlus, null) }, t('ADD_FOLDER')),
        React.createElement(Drawer, { title: t('ADD_FOLDER'), onClose: onOpenAddFolderDrawerClose, open: openAddFolder, destroyOnClose: true },
            React.createElement(FeaturedDefinitionIdFolderForm, { loading: isDataPending, onSave: addFeaturedDefinitionId, onClose: onOpenAddFolderDrawerClose }))));
};
export default AddFeaturedDefinitionIdFolder;
