import React from 'react';
import { HeightContextProvider } from '../../../../../common/components/height-context';
import TablePagination from '../../../../../common/components/table-pagination';
import { useView } from '../../../../../views/components/ViewContext';
export var TasksTableSize;
(function (TasksTableSize) {
    TasksTableSize["large"] = "large";
    TasksTableSize["small"] = "small";
})(TasksTableSize || (TasksTableSize = {}));
const otherElementSelectors = [
    '#breadcrumbs',
    '#DashboardContentHeader',
    '#TaskDashboardPageFilters',
    '#aggregation-content',
    '#TablePagination'
];
export const TasksTableWrapper = ({ children }) => {
    const { page, pageSize, changePage, changePageSize, searchItems: { data: { totalCount = 0 } = {} } } = useView();
    return (React.createElement(React.Fragment, null,
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: 0 }, children),
        !!totalCount && (React.createElement("div", { id: "TablePagination", className: "pb-3" },
            React.createElement(TablePagination, { className: "px-3", totalCount: totalCount, page: page || 1, pageSize: pageSize, onPageChange: changePage, onPageSizeChange: changePageSize })))));
};
