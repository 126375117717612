import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { fetchItemsById, getItem, getItemRepresentation, isItemAvailable, isItemPending } from '../../../../cms';
import { CustomEntitySelector } from '../../../../common/components/entity-selectors';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../crm';
import { searchTaskExecutors } from '../../actions';
const AssigneeSearchComponent = ({ value, onChange, taskMetaId, taskRunId, processRunId, processMetaId, ...otherProps }) => {
    const { t } = useI18n();
    return (React.createElement(CustomEntitySelector, { ...otherProps, className: "pb-0 AssigneeSearchComponentOuter", definitionId: CONTACT_CMS_DEFINITION_ID, 
        // @ts-expect-error
        input: { value, onChange }, autoFocus: true, placeholder: t('SEARCH'), getOptionId: (e) => (`entityId` in e && !('configuration' in e) ? e.entityId : ''), getOptionContent: getItemRepresentation, getEntity: (s, id) => getItem(s, CONTACT_CMS_DEFINITION_ID, id), isEntityAvailable: (s, id) => isItemAvailable(s, CONTACT_CMS_DEFINITION_ID, id), isEntityPending: (s, id) => isItemPending(s, CONTACT_CMS_DEFINITION_ID, id), searchEntities: ({ textSearchQuery }) => searchTaskExecutors({
            taskMetaId,
            taskRunId,
            processRunId,
            processMetaId,
            textQuery: textSearchQuery || ''
        }), fetchEntity: (id) => fetchItemsById(CONTACT_CMS_DEFINITION_ID, [id]), withoutCreateNew: true }));
};
export default AssigneeSearchComponent;
