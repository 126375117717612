import { DownOutlined } from '@ant-design/icons';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { keyBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { IoMdGitNetwork } from 'react-icons/io';
import { VscDebugStart } from 'react-icons/vsc';
import { useEntitiesDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import DisableAntAnimationProvider from '../../../../../header/Header/components/DisableAntAnimationProvider/DisableAntAnimationProvider';
import DropdownMenu from '../../../DropdownMenu/DropdownMenu';
import { TooltipTruncate } from '../../../TooltipTruncate';
import SideMenuIcon from '../../../side-menu/SideMenuIcon/SideMenuIcon';
import { RelatedProcessesSelectorModal } from '../RelatedProcessesSelectorModal';
import styles from './RelatedProcessesSelector.module.scss';
const RELATED_PROCESS_SELECTOR_MAX_SELECTED_ITEMS = 12000;
export const RelatedProcessesSelector = ({ metaDefinitionId, selectedIds }) => {
    const { t } = useI18n();
    const [selectedProcessDefinitionId, setSelectedProcessDefinitionId] = useState('');
    const { data: entities } = useDisplayItemMetaList();
    const availableProcessesDisplayMeta = useMemo(() => entities.filter((itemMeta) => itemMeta.handler === 'workflows' &&
        (itemMeta?.custom?.processRunFromEntities || []).some((entity) => entity.definitionId === metaDefinitionId && entity.active)), [entities, metaDefinitionId]);
    const availableProcessesDisplayMetaIds = useMemo(() => availableProcessesDisplayMeta.map(({ definitionId }) => definitionId), [availableProcessesDisplayMeta]);
    const { displayData } = useEntitiesDisplayData(availableProcessesDisplayMetaIds);
    const displayDataMap = useMemo(() => keyBy(displayData, 'definitionId'), [displayData]);
    const filteredProcessDisplayMeta = useMemo(() => availableProcessesDisplayMeta.filter(({ canCreate, definitionId }) => displayDataMap[definitionId] && canCreate), [availableProcessesDisplayMeta, displayDataMap]);
    const dropdownMenuItemsNew = useMemo(() => filteredProcessDisplayMeta.map(({ definitionId, title, custom, style }) => ({
        key: definitionId,
        label: title,
        icon: React.createElement(SideMenuIcon, { itemIcon: IoMdGitNetwork, style: custom?.style || style || {} })
    })), [filteredProcessDisplayMeta]);
    const handleModalClose = useCallback(() => {
        setSelectedProcessDefinitionId('');
    }, []);
    if (!filteredProcessDisplayMeta.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        filteredProcessDisplayMeta.length === 1 ? (React.createElement(Button, { key: filteredProcessDisplayMeta[0].definitionId, className: `${styles.button} fw-600 actionButton`, onClick: () => {
                setSelectedProcessDefinitionId(filteredProcessDisplayMeta[0].definitionId);
            } },
            React.createElement(TooltipTruncate, null, filteredProcessDisplayMeta[0].title))) : (React.createElement(DisableAntAnimationProvider, null,
            React.createElement(DropdownMenu, { searchPlaceholder: t('RELATED_PROCESS_SELECTOR_SEARCH_PLACEHOLDER'), menuItems: dropdownMenuItemsNew, onMenuItemClick: setSelectedProcessDefinitionId },
                React.createElement(Button, { className: `${styles.button} fw-600 me-2 actionButton` },
                    React.createElement("span", { className: "d-inline d-md-none" },
                        React.createElement(VscDebugStart, { size: "1.5em" })),
                    React.createElement("span", { className: "d-none d-md-flex" },
                        `${t('RELATED_PROCESS_SELECTOR_LABEL')}`,
                        React.createElement(DownOutlined, { className: "ps-2 align-items-end" })))))),
        !!selectedProcessDefinitionId && !!displayDataMap[selectedProcessDefinitionId] && (React.createElement(RelatedProcessesSelectorModal, { processMetaDefinitionId: selectedProcessDefinitionId, processMetaDefinition: displayDataMap[selectedProcessDefinitionId], entityMetaDefinitionId: metaDefinitionId, selectedItemIds: selectedIds, onClose: handleModalClose, maxSelectedItems: RELATED_PROCESS_SELECTOR_MAX_SELECTED_ITEMS }))));
};
