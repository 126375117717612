import { MdInfoOutline } from 'react-icons/md';
import styled from 'styled-components';
export const Info = styled(MdInfoOutline) `
  margin-left: 0.3rem;
  color: var(--primaryColor-6);
  font-size: 1.1rem;
  opacity: 0.6;
`;
export const ResetLink = styled.span `
  cursor: pointer;
  color: var(--primaryColor-6);
  transition: color 200ms ease;

  &:hover {
    color: var(--primaryColor-5);
  }
`;
