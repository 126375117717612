import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import React from 'react';
import * as Yup from 'yup';
import MultiTextEditor from '../../../../cms/data/components/item-property/editors/MultiTextEditor';
import FormikField from '../../../../common/components/formik/FormikField';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import Toggle from '../../../../common/components/Toggle';
import FormikError from '../../../../common/components/formik/FormikError';
export const Visuel3dInitialValues = {
    enabled: false,
    makes: []
};
export const getVisuel3dFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.bool(),
        makes: Yup.array()
            .of(Yup.string())
            .when('enabled', {
            is: true,
            then: Yup.array().required(t('VALIDATIONS__REQUIRED'))
        })
    });
};
const Form = ({ instance, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.import.visuel3d.enabled", component: Toggle, label: `${t('ENABLE_INTEGRATION')}`, value: instance.configuration?.import?.visuel3d &&
                    instance.configuration.import.visuel3d.enabled, disabled: disabled })),
        React.createElement("div", null,
            React.createElement(LabelText, null,
                t('INTEGRATION_VISUEL_BRANDS'),
                " *"),
            React.createElement(FormikField, { name: "configuration.import.visuel3d.makes", component: MultiTextEditor, value: instance.configuration.import?.visuel3d?.makes, disabled: disabled || !instance.configuration.import?.visuel3d?.enabled }),
            React.createElement(FormikError, { name: "configuration.import.visuel3d.makes" }))));
};
export default Form;
