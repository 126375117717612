import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TEMPLATE_CREATE_PATH } from '../constants';
import TemplateCrossClientsPreview from './TemplateCrossClientsPreview/TemplateCrossClientsPreview';
import TemplateFormContainer from './TemplateForm/TemplateFormContainer';
import TemplatesListContainer from './Templates/TemplatesListContainer';
const Routes = ({ match: { url } }) => (React.createElement(Switch, null,
    React.createElement(Route, { path: `${url}/:id/:lang/previews`, render: (props) => (React.createElement(TemplateCrossClientsPreview, { key: props.match.params.id, templateId: props.match.params.id, language: props.match.params.lang, ...props })) }),
    React.createElement(Route, { exact: true, path: `${url}${TEMPLATE_CREATE_PATH}`, component: TemplateFormContainer }),
    React.createElement(Route, { exact: true, path: `${url}/:id`, render: (props) => (React.createElement(TemplateFormContainer, { key: props.match.params.id, templateId: props.match.params.id, ...props })) }),
    React.createElement(Route, { exact: true, path: url, component: TemplatesListContainer }),
    React.createElement(Redirect, { to: url })));
export default Routes;
