import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { TITLE_PROPERTY_ID, getDisplayComponentForType, getEditorComponentForType, titleValidations } from '../../../../../cms';
import { InlineEditor } from '../../../../../common/components/InlineEditor';
import { useTranslation } from '../../../../../common/components/InlineEditor/SelectLanguage/SelectLanguage';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { isRichTextValue } from '../../../../../common/types';
import ProcessActionsButton from '../ProcessActionsButton/ProcessActionsButton';
import ProcessStatusBanner from '../ProcessStatusBanner';
import ProcessSteps from '../ProcessSteps';
import styles from './ProcessHeader.module.scss';
const ProcessHeader = ({ processDetails, processRunId, definitionId, titleProperty, updateProcessTitle, isProcessPending, onCancelProcess, onDeleteProcess }) => {
    const { t } = useI18n();
    const { translationOptions, selectedLanguage, multiLanguagePermission, setSelectedLanguage } = useTranslation({
        meta: titleProperty?.meta,
        definitionId,
        entityId: processRunId,
        propertyId: titleProperty?.propertyId || TITLE_PROPERTY_ID
    });
    const onSave = useCallback(async (value, propertyId) => {
        if (isRichTextValue(value)) {
            return;
        }
        return updateProcessTitle(propertyId || TITLE_PROPERTY_ID, value);
    }, [updateProcessTitle]);
    const propertyValue = useMemo(() => {
        const translationValue = translationOptions?.[selectedLanguage]?.value;
        const spaceRegex = /\s+/g;
        if (translationValue) {
            return translationValue?.replace(spaceRegex, ' ');
        }
        if (typeof titleProperty?.value === 'string') {
            return titleProperty?.value.replace(spaceRegex, ' ');
        }
        return titleProperty?.value || '';
    }, [selectedLanguage, titleProperty?.value, translationOptions]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${styles.processHeader} px-3 mb-3` }, processDetails && titleProperty ? (React.createElement(React.Fragment, null,
            React.createElement(ProcessStatusBanner, { className: styles.processStatusBanner, status: processDetails.status }),
            React.createElement("div", { className: styles.titleWrapper },
                React.createElement(TooltipTruncate, { className: styles.processId }, processRunId),
                React.createElement("div", { className: styles.title },
                    React.createElement(InlineEditor, { translationOptions: multiLanguagePermission ? translationOptions : undefined, setSelectedLanguageMultiLanguage: setSelectedLanguage, selectedLanguageMultiLanguage: selectedLanguage, propertyId: translationOptions && translationOptions[selectedLanguage]?.propertyId, className: cn('w-100', styles.inlineEditor, { empty: !titleProperty?.value }), displayComponent: getDisplayComponentForType('text'), editorComponent: getEditorComponentForType('text'), 
                        // @ts-expect-error
                        value: propertyValue, valueFromTranslations: translationOptions && translationOptions[selectedLanguage]?.value, placeholder: t('NO_TITLE'), canEdit: titleProperty?.canEdit ?? false, hideActions: false, allowClear: false, onSave: onSave, meta: { validations: titleValidations }, extraDisplayComponentProps: { asField: false } })),
                React.createElement(ProcessActionsButton, { className: styles.processActionButton, processRunId: processRunId, definitionId: definitionId, isProcessPending: isProcessPending, processDetails: processDetails, onCancelProcess: onCancelProcess, onDeleteProcess: onDeleteProcess })),
            processDetails.steps && !isEmpty(processDetails.steps) && (React.createElement(ProcessSteps, { steps: processDetails.steps, className: styles.steps })))) : (React.createElement(Skeleton, { isLoading: isProcessPending, className: "w-100" },
            React.createElement(TextPlaceholder, { heightInPx: 56, widthInPx: 0, radiusInPx: 6 }),
            React.createElement(TextPlaceholder, { heightInPx: 24, widthInPx: 135, radiusInPx: 6, className: "mt-2" }))))));
};
export default ProcessHeader;
