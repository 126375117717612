import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { find, first, get, map, sortBy, toLower } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoDatabase } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { getAuthJwt, getUserTenantPermissions, hasPermission, redirectIfNoPermission } from '../../../auth';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import { CollapsibleContainer } from '../../../common/components/side-menu';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { getConfigsState } from '../../../common/reducers/rootSelector';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, CMS_ITEM_META_HANDLER, SETTINGS_PATH, VIEW_PATH } from '../../../common/utils/constants';
import { getEntityBrowseUrl } from '../../../common/utils/url';
import { fetchConfig, getConfig, isFetchConfigPending } from '../../../configs';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { getMenu, getNestedMenuCmsReferences } from '../../../header';
import { CMS_KEY, SETTINGS_KEY } from '../../../settings';
import { useViews } from '../../../views';
import EditableViewTitleComponent from '../../../views/components/EditableViewTitleComponent';
import CmsDataRoutes from '../../data/components/Routes';
import useDisplayItemMetaList from '../../hooks/useDisplayItemMetaList';
import CmsSettingsRoutes from '../../settings/components/entity/EntityRoutes';
import ItemMetaFormContainer from '../../settings/components/entity/meta-entity-form/ItemMetaFormContainer';
import { TITLE_PROPERTY_ID } from '../utils/constants';
import styles from './Routes.module.scss';
import CmsPage from './cms-page/CmsPage';
const RestrictedEntitiesRoutes = ({ match: { url: currentUrl } }) => {
    const { t, language } = useI18n();
    const { contactId } = useMyContact();
    const { isDesktop } = useBrowserBreakpoints();
    const [extraTextForBreadcrumbsComponent, setExtraTextForBreadcrumbsComponent] = useState();
    const dispatch = useDispatch();
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const { views, loadingViews } = useViews();
    const cmsConfig = useSelector((s) => getConfig(getConfigsState(s), SETTINGS_KEY, CMS_KEY));
    const isCmsConfigPending = useSelector((s) => isFetchConfigPending(getConfigsState(s), SETTINGS_KEY, CMS_KEY));
    const { data: entities, isError: isFailed, isLoading } = useDisplayItemMetaList();
    const userPermissions = useSelector((s) => getUserTenantPermissions(getAuthJwt(s)));
    const createEntityPath = `${currentUrl}/create`;
    const hasAdminPermission = hasPermission(userPermissions, APP_ADMIN_PERMISSION);
    const cmsEntities = useMemo(() => entities.filter(({ handler }) => handler == null || handler === CMS_ITEM_META_HANDLER), [entities]);
    const singleInactiveCrumb = useMemo(() => {
        const isSingleBreadcrumb = links?.length === 1 && !currentBreadcrumbText;
        return isSingleBreadcrumb ? first(links)?.text : '';
    }, [currentBreadcrumbText, links]);
    useEffect(() => {
        if (!cmsConfig && !isCmsConfigPending) {
            dispatch(fetchConfig(SETTINGS_KEY, CMS_KEY));
        }
    }, [cmsConfig, isCmsConfigPending, dispatch]);
    const getEntityTitle = useCallback((definitionId) => get(find(cmsEntities, { definitionId }), TITLE_PROPERTY_ID) ||
        (isLoading ? '...' : t('NO_TITLE')), [cmsEntities, isLoading, t]);
    const getSideMenuSectionTitle = useCallback((definitionId) => {
        const headerMenu = getMenu((permission) => hasPermission(userPermissions, permission), map(cmsEntities, 'definitionId'));
        const headerMenuReference = find(getNestedMenuCmsReferences(headerMenu), { definitionId });
        if (headerMenuReference) {
            return headerMenuReference.menuTitle;
        }
        if ((cmsConfig?.data?.featuredDefinitionIds || []).includes(definitionId)) {
            return t('SIDE_MENU__FEATURED_ITEMS');
        }
        return null;
    }, [cmsConfig, cmsEntities, userPermissions, t]);
    const [sideMenuItems, sectionSortOrderIndexMap] = useMemo(() => {
        let items = [];
        const sectionSortOrderIndexMap = {};
        if (isLoading || isCmsConfigPending || loadingViews) {
            return [items, sectionSortOrderIndexMap];
        }
        items = [
            ...items,
            ...cmsEntities.map(({ title, definitionId, style }) => {
                let sideMenuItem = {
                    key: definitionId,
                    exact: false,
                    to: getEntityBrowseUrl(definitionId, currentUrl),
                    title: title || definitionId,
                    icon: GoDatabase,
                    style
                };
                if (hasAdminPermission) {
                    sideMenuItem = {
                        ...sideMenuItem,
                        toSettings: `${currentUrl}/${definitionId}${SETTINGS_PATH}`
                    };
                }
                const sectionTitle = getSideMenuSectionTitle(definitionId);
                if (sectionTitle) {
                    sideMenuItem = { ...sideMenuItem, sectionTitle };
                }
                return sideMenuItem;
            })
        ];
        items = [
            ...items,
            ...views
                .filter(({ referenceDefinitionId, entityOwner, isDefault }) => !!find(cmsEntities, { definitionId: referenceDefinitionId }) &&
                entityOwner === contactId &&
                !isDefault)
                .map(({ title, referenceDefinitionId, entityId, style }) => {
                const baseEntity = find(cmsEntities, { definitionId: referenceDefinitionId });
                return {
                    key: entityId,
                    exact: false,
                    to: `${currentUrl}/${referenceDefinitionId}${VIEW_PATH}/${entityId}`,
                    title,
                    subTitle: baseEntity?.[TITLE_PROPERTY_ID] || '',
                    icon: GoDatabase,
                    sectionTitle: t('VIEWS__SIDE_MENU_SECTION_TITLE'),
                    style
                };
            })
        ];
        sectionSortOrderIndexMap[t('SIDE_MENU__FEATURED_ITEMS')] = 1;
        sectionSortOrderIndexMap[t('VIEWS__SIDE_MENU_SECTION_TITLE')] = 2;
        return [sortBy(items, [(o) => toLower(o.title)]), sectionSortOrderIndexMap];
    }, [
        isLoading,
        isCmsConfigPending,
        loadingViews,
        cmsEntities,
        views,
        currentUrl,
        hasAdminPermission,
        getSideMenuSectionTitle,
        contactId,
        t
    ]);
    const getContentJsx = useCallback((contentHeight) => (React.createElement(CollapsibleContainer, { sectionName: "cms", title: t('ENTITIES'), 
        // if you cant create don't pass the path in and it will
        // act as a "canCreate"
        createPath: hasAdminPermission ? createEntityPath : undefined, headerIcon: GoDatabase, items: sideMenuItems, sectionSortOrderIndexMap: sectionSortOrderIndexMap, isFailed: isFailed, isLoading: isLoading || isCmsConfigPending || loadingViews, withSearch: true, withFeaturedEntitiesSwitch: true },
        React.createElement(Container, null,
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: currentUrl, render: () => (React.createElement(CmsPage, { contentHeight: contentHeight || undefined, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText })) }),
                hasAdminPermission && (React.createElement(Route, { path: createEntityPath, render: (props) => (React.createElement(SectionCard, { className: styles.sectionCard, title: t('BASIC_INFO'), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true },
                        React.createElement(Content, { height: contentHeight },
                            React.createElement(ItemMetaFormContainer, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, create: true, isSystem: false, 
                                // @ts-expect-error
                                entityMetaId: props.entityMetaId, ...props })))) })),
                hasAdminPermission && (React.createElement(Route, { path: `${currentUrl}/:definitionId${SETTINGS_PATH}`, render: (props) => {
                        const { definitionId } = props.match.params;
                        return (React.createElement(CmsSettingsRoutes, { key: definitionId, entityMetaId: definitionId, entityTitle: getEntityTitle(definitionId), setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, contentHeight: contentHeight, baseUrl: currentUrl, ...props }));
                    } })),
                React.createElement(Route, { path: `${currentUrl}/:definitionId`, render: (props) => {
                        const { definitionId } = props.match.params;
                        const displayItemMeta = find(cmsEntities, { definitionId });
                        return (React.createElement(CmsDataRoutes, { definitionId: definitionId, entityTitle: getEntityTitle(definitionId), canCreateItems: displayItemMeta?.canCreate || false, canDeleteItems: displayItemMeta?.canDelete || false, setCurrentBreadcrumbText: setCurrentBreadcrumbText, setLinks: setLinks, contentHeight: contentHeight, isFailed: isFailed, isLoading: isLoading, baseUrl: currentUrl, setExtraTextForBreadcrumbsComponent: setExtraTextForBreadcrumbsComponent, ...props }));
                    } }),
                React.createElement(Redirect, { to: currentUrl }))))), [
        t,
        hasAdminPermission,
        createEntityPath,
        sideMenuItems,
        sectionSortOrderIndexMap,
        isFailed,
        isLoading,
        isCmsConfigPending,
        loadingViews,
        currentUrl,
        getEntityTitle,
        cmsEntities
    ]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: `${currentUrl}`, render: () => React.createElement(Breadcrumbs, { links: [], currentBreadcrumbText: singleInactiveCrumb }) }),
                React.createElement(Route, { path: [`${currentUrl}/:definitionId${SETTINGS_PATH}`, `${currentUrl}/create`], render: () => (React.createElement(Breadcrumbs, { links: links, currentBreadcrumbText: currentBreadcrumbText })) }),
                React.createElement(Route, { path: [
                        `${currentUrl}/:definitionId${BROWSE_PATH}`,
                        `${currentUrl}/:definitionId${VIEW_PATH}/:viewId`
                    ], render: (props) => {
                        const { definitionId } = props.match.params;
                        const { viewId } = props.match.params;
                        return (React.createElement(Breadcrumbs, { currentBreadcrumbComponent: React.createElement(EditableViewTitleComponent, { definitionId: definitionId, viewId: viewId, totalItemsCount: extraTextForBreadcrumbsComponent, entityTitle: getEntityTitle(definitionId) }), links: links }));
                    } }))),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => getContentJsx(isDesktop ? contentHeight : undefined)))));
};
export default redirectIfNoPermission(RestrictedEntitiesRoutes);
//
// Utils
//
const Content = styled.div `
  height: ${({ height }) => height || null};
`;
const Container = styled.div `
  position: relative;
  margin-bottom: 1rem;

  ${media.xl} {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
`;
const styledSectionCardHeaderStyle = { padding: '11px 16px 13px' };
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 0;
