import { get } from 'lodash';
const getKey = (configType, configId) => `${configType}_${configId}`;
export const getConfig = (s, configType, configId) => s.byKey[`${configType}_${configId}`];
export const isConfigAvailable = (s, configType, configId) => {
    const status = s.status[getKey(configType, configId)];
    return status != null && (status.lastFetch != null || status.pending);
};
export const isFetchConfigPending = (s, configType, configId) => {
    const status = s.status[getKey(configType, configId)];
    return status == null ? false : status.pending;
};
export const hasFetchConfigFailed = (s, configType, configId) => get(s, ['status', getKey(configType, configId), 'failed'], false);
export const getConfigStatus = (s, configType, configId) => ({
    isAvailable: isConfigAvailable(s, configType, configId),
    isPending: isFetchConfigPending(s, configType, configId),
    isFailed: hasFetchConfigFailed(s, configType, configId)
});
