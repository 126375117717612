import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tabs } from 'antd';
import React, { useCallback } from 'react';
import BasicInfoTab from './BasicInfoTab';
import ConditionalFormattingSelectorTab from './ConditionalFormattingSelectorTab';
import PermissionsTab, { isVisible as isPermissionsTabVisible } from './PermissionsTab';
import SearchSelectorTab, { isVisible as isSearchSelectorTabVisible } from './SearchSelectorTab';
import TranslationsSelectorTab, { useTranslationsSelectorTab } from './TranslationsSelectorTab';
import ValidationsTab, { isVisible as isValidationsTabVisible } from './ValidationsTab';
import LabelsField from './fields/LabelsField';
const PropertySettingsPanel = ({ parentViewGroups = [], value, values, onChange, disabled, metaDefinitionId, error }) => {
    const { t } = useI18n();
    const { isVisible: isTranslationsVisible } = useTranslationsSelectorTab(value, values);
    const isSection = 'properties' in value;
    const isTab = 'sections' in value;
    const type = isSection ? 'section' : isTab ? 'tab' : value.type;
    const id = 'propertyId' in value ? value.propertyId : value.id;
    const onChangeTitles = useCallback((val) => {
        if (isSection || isTab) {
            onChange({
                ...value,
                titles: val
            });
        }
    }, [isSection, isTab, onChange, value]);
    const onChangeProperty = useCallback((val) => {
        onChange(val);
    }, [onChange]);
    return isSection || isTab ? (React.createElement("div", { className: `px-3 pb-3` },
        React.createElement(LabelsField, { disabled: disabled, onChange: onChangeTitles, value: value.titles, error: error?.titles }))) : (React.createElement(Tabs, { defaultActiveKey: "LABELS", className: `px-3 pb-3`, destroyInactiveTabPane: true, items: [
            {
                key: 'LABELS',
                className: 'px-3',
                label: t('BASIC_INFO'),
                children: (React.createElement(BasicInfoTab, { onChange: onChangeProperty, disabled: disabled, value: value, metaDefinitionId: metaDefinitionId, error: error }))
            },
            ...((isPermissionsTabVisible(id) && [
                {
                    key: 'PERMISSIONS',
                    className: 'px-3',
                    label: t('PERMISSIONS'),
                    children: (React.createElement(PermissionsTab, { onChange: onChangeProperty, parentViewGroups: parentViewGroups, disabled: disabled, value: value }))
                }
            ]) ||
                []),
            ...((isValidationsTabVisible(id) && [
                {
                    key: 'VALIDATIONS',
                    className: 'px-3',
                    label: t('VALIDATIONS'),
                    disabled: !!value.computedValue,
                    children: (React.createElement(ValidationsTab, { disabled: disabled, onChange: onChangeProperty, value: value }))
                }
            ]) ||
                []),
            ...((isSearchSelectorTabVisible(type) &&
                value && [
                {
                    key: 'SEARCH_SELECTOR',
                    className: 'px-3',
                    label: t('SEARCH_SELECTOR'),
                    disabled: !!value.computedValue,
                    children: (React.createElement(SearchSelectorTab, { disabled: disabled, value: value, onChange: onChangeProperty, metaDefinitionId: metaDefinitionId }))
                }
            ]) ||
                []),
            ...((isTranslationsVisible && [
                {
                    key: 'PROPERTY_TRANSLATIONS',
                    className: 'px-3',
                    label: t('PROPERTY_TRANSLATIONS'),
                    children: (React.createElement(TranslationsSelectorTab, { disabled: disabled, value: value, onChange: onChangeProperty, values: values }))
                }
            ]) ||
                []),
            {
                key: 'CONDITIONAL_FORMATTING',
                className: 'px-3',
                label: t('CONDITIONAL_FORMATTING'),
                disabled,
                children: (React.createElement(ConditionalFormattingSelectorTab, { value: value, values: values, onChange: onChangeProperty, disabled: disabled, metaDefinitionId: metaDefinitionId }))
            }
        ] }));
};
export default PropertySettingsPanel;
