import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import cn from 'classnames';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../../auth';
import HeightContext, { HeightContextProvider } from '../../common/components/height-context';
import { useBrowserBreakpoints } from '../../common/components/with-browser-breakpoints';
import { GroupItemRoute } from './GroupItemRoute';
import { HierarchyContainer } from './Hierarchy';
import styles from './Routes.module.scss';
import { GroupsInfoPage } from './groups-info-page';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 72 /* ? sidebar search block */ +
    2 /* ? sidebar border */ +
    48 /* ? sidebar title block */ +
    16; /* ? padding bottom after sidebar */
const localStorageKeyDrawerCollapse = `desktop-left-drawer-collapsed-groups`;
const RestrictedEntitiesRoutes = ({ match }) => {
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const [isLeftDrawerCollapsed, setIsLeftDrawerCollapsed] = useState(false);
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const currentUrl = match.url;
    const onCollapseChange = (isLeftDrawerCollapsed) => {
        localStorage.setItem(localStorageKeyDrawerCollapse, isLeftDrawerCollapsed ? '1' : '0');
        setIsLeftDrawerCollapsed(!!isLeftDrawerCollapsed);
    };
    return (React.createElement(ErrorBoundary, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: currentUrl, render: () => (
                // ? An empty block to calculate the dynamic height of the sidebar 'content' block.
                React.createElement("div", { className: "pb-3", id: "breadcrumbs" })) }),
            React.createElement(Route, { exact: true, path: `${currentUrl}/:entityId`, render: () => (React.createElement("div", { className: styles.container__breadcrumbs, id: "breadcrumbs" },
                    React.createElement(Breadcrumbs, { links: links, currentBreadcrumbText: currentBreadcrumbText }))) })),
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight: calculatedContentHeight }) => {
                const contentHeight = isDesktop ? calculatedContentHeight : null;
                return (React.createElement("div", { className: cn(styles.contentRow, 'd-flex', {
                        collapsed: isLeftDrawerCollapsed,
                        'flex-row': isDesktop,
                        'flex-column is-mobile': !isDesktop
                    }) },
                    React.createElement("div", { className: "sidebar" },
                        React.createElement(Switch, null,
                            React.createElement(Route, { exact: true, path: currentUrl, render: ({ match: { params: { entityId } } }) => (React.createElement(HierarchyContainer, { contentHeight: contentHeight, activeGroupEntityId: entityId, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, isCollapsed: isLeftDrawerCollapsed, onCollapseChange: onCollapseChange })) }),
                            React.createElement(Route, { exact: true, path: `${currentUrl}/:entityId`, render: ({ match: { params: { entityId } } }) => (React.createElement(HierarchyContainer, { contentHeight: contentHeight, activeGroupEntityId: entityId, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, isCollapsed: isLeftDrawerCollapsed, onCollapseChange: onCollapseChange })) }),
                            React.createElement(Redirect, { to: currentUrl }))),
                    React.createElement("div", { className: cn('main-content w-100', { 'is-mobile': !isDesktop }) },
                        React.createElement("div", { className: cn(styles.container, 'RoutesContainer') },
                            React.createElement(Switch, null,
                                React.createElement(Route, { exact: true, path: currentUrl, render: () => React.createElement(GroupsInfoPage, { contentHeight: contentHeight }) }),
                                React.createElement(Route, { exact: true, path: `${currentUrl}/:entityId`, render: ({ match: { params: { entityId } } }) => (React.createElement(GroupItemRoute, { key: entityId, entityId: entityId, entityTitle: t('GROUP__NAV_LINK'), contentHeight: contentHeight })) }),
                                React.createElement(Redirect, { to: currentUrl }))))));
            }))));
};
export default redirectIfNoPermission(RestrictedEntitiesRoutes);
