import { isArray, isEmpty, isObject } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { fetchItemsById } from '../data/actions';
const ENTITY_ITEMS_QUERY_KEY = 'entity-items-by-ids';
const getQueryKey = ({ definitionId, ids, extraPayloadProps }) => [ENTITY_ITEMS_QUERY_KEY, { extraPayloadProps, ids }, definitionId];
export const invalidateEntityItemsQuery = async ({ definitionId, changedPropertyIds, entityIds }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => {
            if (!isArray(query.queryKey)) {
                return false;
            }
            if (!(query.queryKey.includes(ENTITY_ITEMS_QUERY_KEY) && query.queryKey.includes(definitionId))) {
                return false;
            }
            const passed = [];
            if (entityIds) {
                const idsKey = query.queryKey.find((key) => isObject(key) && !isArray(key) && 'extraPayloadProps' in key);
                const ids = idsKey?.ids;
                if (ids) {
                    passed.push(ids.some((id) => entityIds.includes(id)));
                }
            }
            if (changedPropertyIds && isArray(query.queryKey)) {
                const extraPayloadPropsKey = query.queryKey.find((key) => isObject(key) && !isArray(key) && 'extraPayloadProps' in key);
                const responseFields = extraPayloadPropsKey?.extraPayloadProps?.responseFields;
                if (responseFields) {
                    if (responseFields.includes('*')) {
                        passed.push(true);
                    }
                    else {
                        passed.push(changedPropertyIds.some((changedPropertyId) => responseFields.includes(changedPropertyId)));
                    }
                }
            }
            return passed.every((item) => item);
        }
    });
};
export const fetchItems = ({ definitionId, dispatch, ids, extraPayloadProps }) => {
    if (!definitionId || isEmpty(ids)) {
        return Promise.resolve(undefined);
    }
    return dispatch(fetchItemsById(definitionId, ids, extraPayloadProps));
};
export const useEntityItems = ({ definitionId, ids, extraPayloadProps, ...options }) => {
    const dispatch = useDispatch();
    const { data, isError, isLoading } = useQuery(getQueryKey({ definitionId, ids, extraPayloadProps }), () => fetchItems({
        definitionId,
        ids,
        extraPayloadProps,
        dispatch
    }), {
        ...options,
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
    return useMemo(() => ({
        data: data?.results || [],
        isAvailable: !!data,
        isPending: isLoading,
        isFailed: isError
    }), [data, isError, isLoading]);
};
export const useHandleEntityItems = () => {
    const dispatch = useDispatch();
    const handleFetch = async ({ definitionId, ids, extraPayloadProps, ...options }) => await queryClient.fetchQuery({
        queryKey: getQueryKey({ definitionId, ids, extraPayloadProps }),
        queryFn: () => fetchItems({
            definitionId,
            ids,
            extraPayloadProps,
            dispatch
        }),
        ...options,
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs
    });
    return {
        handleFetch
    };
};
