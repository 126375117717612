import { BROWSE_PATH } from '../../../common/utils/constants';
import { PROCESSES_PATH, WORKFLOWS_PATH } from './constants';
// TODO: soon processMetaId/definitionId will be the same
export const getProcessUrl = (processMetaId, processRunId) => `${WORKFLOWS_PATH}${PROCESSES_PATH}/${processMetaId}${BROWSE_PATH}/${processRunId}`;
export function isProcessMeta(data) {
    if (data && 'idPrefix' in data && 'color' in data && 'titles' in data && 'activity' in data) {
        return true;
    }
    return false;
}
