import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { INTEGRATION_LIMIT_API_KEYS } from '@hypercharge/portal-utils';
import { Alert } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import FilterModal from '../../../common/components/InlineEditor/FilterModal';
import { oneHourInMs } from '../../../common/utils/react-query-client';
import { getErrorMessage } from '../../../common/utils/request';
import { Content } from '../../../common/utils/stylingUtils';
import { CONTACT_CMS_DEFINITION_ID } from '../../../crm';
import { ViewProvider } from '../../../views';
import { SETTINGS_PATH } from '../../constants';
import { InfoContainer, StyledSectionCard, styledSectionCardHeaderStyle } from '../common/stylingUtils';
import ApiKeyItem from './ApiKeyItem';
import { createApiKeyAction, deleteApiKeyAction, getApiKeysAction, updateApiKeyAction } from './actions';
const ApiKeysContainer = ({ contentHeight, setLinks, setCurrentBreadcrumbText }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const getApiKeysDispatch = useDispatch();
    const createApiKeyDispatch = useDispatch();
    const deleteApiKeyDispatch = useDispatch();
    const updateApiKeyDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const { data: apiKeys, status, refetch } = useQuery(['apiKeys'], () => {
        return getApiKeysDispatch(getApiKeysAction());
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    const addApiKey = useCallback(async (contactId) => {
        setLoading(true);
        try {
            await createApiKeyDispatch(createApiKeyAction(contactId));
            await refetch();
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t)
            }));
        }
        setLoading(false);
    }, [createApiKeyDispatch, refetch, dispatch, t]);
    const onChange = useCallback(async (id, newApiKey) => {
        setLoading(true);
        try {
            await updateApiKeyDispatch(updateApiKeyAction(id, newApiKey));
            await refetch();
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t)
            }));
        }
        setLoading(false);
    }, [dispatch, refetch, t, updateApiKeyDispatch]);
    const deleteApiKey = useCallback(async (id) => {
        try {
            await deleteApiKeyDispatch(deleteApiKeyAction(id));
            await refetch();
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: getErrorMessage(err, t)
            }));
        }
    }, [deleteApiKeyDispatch, refetch, dispatch, t]);
    const handleOk = useCallback(([contactId]) => {
        void addApiKey(contactId);
        setShowFilterModal(false);
    }, [addApiKey]);
    const handleCancel = useCallback(() => {
        setShowFilterModal(false);
    }, []);
    const showModal = useCallback(() => {
        setShowFilterModal(true);
    }, []);
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('APIKEY'));
    }, [setCurrentBreadcrumbText, setLinks, t]);
    const isLoading = loading || status == 'loading';
    return (React.createElement(StyledSectionCard, { withoutPadding: true, withoutMargin: true, title: t('APIKEY'), headerStyle: styledSectionCardHeaderStyle }, !apiKeys && isLoading ? (React.createElement(InfoContainer, null,
        React.createElement(LoadingRectangles, null))) : (React.createElement(Content, { height: contentHeight, className: "w-auto " },
        React.createElement("div", { className: "w-75 d-block mx-auto p-5" },
            apiKeys && apiKeys.length >= INTEGRATION_LIMIT_API_KEYS && (React.createElement(Alert, { message: t('APIKEY_LIMIT_REACHED_TEXT') })),
            apiKeys?.map((apiKey) => (React.createElement(ApiKeyItem, { key: apiKey.id, apiKey: apiKey, loading: isLoading, onChange: (newApiKey) => {
                    return onChange(apiKey.id, newApiKey);
                }, onDelete: () => {
                    return deleteApiKey(apiKey.id);
                } }))),
            React.createElement("div", { className: "mt-4 text-center" },
                !apiKeys?.length && t('APIKEY_EMPTY_TEXT'),
                apiKeys && apiKeys.length < INTEGRATION_LIMIT_API_KEYS && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "mt-4 text-center" },
                        React.createElement(Button, { onClick: showModal, loading: isLoading }, t('APIKEY_CREATE'))),
                    showFilterModal && (React.createElement(ViewProvider, { definitionId: CONTACT_CMS_DEFINITION_ID },
                        React.createElement(FilterModal, { open: true, showFootter: true, meta: { definitionId: CONTACT_CMS_DEFINITION_ID }, definitionId: CONTACT_CMS_DEFINITION_ID, handleOk: handleOk, handleCancel: handleCancel })))))))))));
};
export default ApiKeysContainer;
