import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { useGroups } from '../../hooks/useGroups';
import styles from './Hierarchy.module.scss';
import { getParentGroupIds } from './utils';
export const CollapseExpand = ({ expandedKeys, parentGroupMap, searchText, setExpandedKeys }) => {
    const { t } = useI18n();
    const { groupsStatus } = useGroups();
    const parentGroupIds = getParentGroupIds(parentGroupMap);
    const canCollapse = !isEmpty(expandedKeys);
    const disabled = groupsStatus.isPending || searchText;
    const toggleCollapseGroups = () => {
        if (!disabled) {
            setExpandedKeys(canCollapse ? [] : parentGroupIds);
        }
    };
    return (React.createElement("span", { className: cn(styles.collapseExpandButton, 'CollapseExpandButton', {
            disabled,
            expanded: isEmpty(parentGroupIds) || canCollapse
        }), onClick: toggleCollapseGroups },
        React.createElement(Tooltip, { placement: "top", title: canCollapse ? t('COMMON__COLLAPSE_ALL') : t('COMMON__EXPAND_ALL') }, canCollapse ? React.createElement(BsArrowsCollapse, null) : React.createElement(BsArrowsExpand, null))));
};
