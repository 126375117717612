import { get } from 'lodash';
import React from 'react';
import { FieldLabel } from '../../../../../../../cms/data/components/item-property/FieldElements';
import { useNodes } from '../../../activity-diagram/useNode';
import CredentialSelector from './CredentialSelector';
const CredentialList = ({ credentialsDescription, disabled, errors, node, onChange, path }) => {
    const { data: nodes } = useNodes();
    return (React.createElement(React.Fragment, null, credentialsDescription.map((credential, index) => {
        const value = get(node, `${path}.${credential.name}`, '');
        return (React.createElement("div", { key: index, className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement(FieldLabel, { label: nodes?.credentials[credential.name]?.displayName || credential.name, className: "ps-0" }),
                React.createElement(CredentialSelector, { value: value, disabled: disabled, onChange: (newValue) => {
                        onChange && onChange(credential, newValue);
                    }, credential: credential, invalid: !!errors?.[credential.name] }))));
    })));
};
export default CredentialList;
