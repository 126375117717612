import { compact, head, isEmpty, uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
const isNestedFieldOf = (propertyId) => (field) => {
    const dividedPropertyIds = field.split('.');
    return dividedPropertyIds.length > 1 && dividedPropertyIds[0] === propertyId;
};
const isEditableField = (field) => {
    return !field.computed && !['entityId', 'updatedAt'].includes(field.propertyId);
};
const usePermissionFieldsConfig = (tasks) => {
    const myPermissionFields = useMemo(() => {
        const fields = [];
        for (const task of tasks) {
            for (const value of Object.values(task.propertySettings ?? {})) {
                for (const [propertyId, settings] of Object.entries(value)) {
                    if (settings.canEdit) {
                        fields.push(propertyId);
                    }
                }
            }
        }
        return compact(uniq(fields));
    }, [tasks]);
    const getNestedPermissionFields = useCallback((propertyId) => {
        return myPermissionFields.filter(isNestedFieldOf(propertyId));
    }, [myPermissionFields]);
    const permissionFieldsConfig = useMemo(() => {
        return {
            isCanEditField: (fieldDisplayData) => {
                const hasPermission = !!myPermissionFields.find((field) => head(field.split('.')) === fieldDisplayData.propertyId);
                return hasPermission && isEditableField(fieldDisplayData);
            },
            getNestedPermissionFieldsConfig: (field) => {
                if (field.type !== 'entity') {
                    return;
                }
                const nestedPermissionFields = getNestedPermissionFields(field.propertyId);
                if (isEmpty(nestedPermissionFields)) {
                    return;
                }
                return {
                    isCanEditField: (fieldDisplayData) => {
                        const hasPermission = !!nestedPermissionFields.find((field) => field.split('.')[1] === fieldDisplayData.propertyId);
                        return hasPermission && isEditableField(fieldDisplayData);
                    }
                };
            }
        };
    }, [getNestedPermissionFields, myPermissionFields]);
    return permissionFieldsConfig;
};
export { usePermissionFieldsConfig };
