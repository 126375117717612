export const XLS = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MS_EXCEL = 'application/vnd.ms-excel';
export const WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MS_WORD = 'application/msword';
export const PDF = 'application/pdf';
export const AUDIO = 'audio';
export const VIDEO = 'video';
export const IMAGE = 'image';
const documentFormats = [PDF, XLS, MS_EXCEL, WORD, MS_WORD];
export default documentFormats;
