import { CMS_API_PATH } from '../../../../common/utils/constants';
export const getFetchCsvHeadersAction = (importEntityId) => ({
    type: 'CMS_IMPORTER_CSV_HEADERS_FETCH',
    meta: {
        http: {
            path: `${CMS_API_PATH}/import/${importEntityId}/read-csv`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
