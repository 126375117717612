import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { get, map } from 'lodash';
import { combineReducers } from 'redux';
const initialListState = {
    pending: true,
    failed: false,
    lastFetch: null,
    ids: []
};
const groupedLists = createReducer({}, {
    CONVERSATIONS__FETCH_MESSAGES: (s, a) => {
        const { conversationNamespace, conversationId } = a.meta.http.actionContext;
        if (!s[conversationNamespace]) {
            s[conversationNamespace] = {};
        }
        let gState = s[conversationNamespace][conversationId];
        if (!gState) {
            gState = { ...initialListState };
            s[conversationNamespace][conversationId] = gState;
        }
        gState.pending = true;
        gState.failed = false;
    },
    CONVERSATIONS__FETCH_MESSAGES_FAIL: (s, a) => {
        const { conversationNamespace, conversationId } = a.meta.actionContext;
        if (!s[conversationNamespace]) {
            s[conversationNamespace] = {};
        }
        const gState = s[conversationNamespace][conversationId];
        gState.pending = false;
        gState.failed = true;
    },
    CONVERSATIONS__FETCH_MESSAGES_SUCCESS: (s, a) => {
        const { conversationNamespace, conversationId } = a.meta.actionContext;
        if (!s[conversationNamespace]) {
            s[conversationNamespace] = {};
        }
        const gState = s[conversationNamespace][conversationId];
        gState.ids = map(a.payload, 'id');
        gState.pending = false;
        gState.failed = false;
        gState.lastFetch = new Date();
    },
    CONVERSATIONS__CREATE_MESSAGE_SUCCESS: (s, a) => {
        const { conversationNamespace, conversationId, id } = a.payload;
        if (!s[conversationNamespace]) {
            s[conversationNamespace] = {};
        }
        let gState = s[conversationNamespace][conversationId];
        if (!gState) {
            gState = { ...initialListState };
            s[conversationNamespace][conversationId] = gState;
        }
        gState.ids.push(id);
    }
});
const byId = createReducer({}, {
    CONVERSATIONS__FETCH_MESSAGES_SUCCESS: (s, a) => {
        a.payload.forEach((message) => {
            s[message.id] = { ...message, hidden: !message.content };
        });
    },
    CONVERSATIONS__DELETE_MESSAGE_SUCCESS: (s, a) => {
        s[a.meta.actionContext.id].content = null;
        s[a.meta.actionContext.id].hidden = true;
    },
    CONVERSATIONS__CREATE_MESSAGE_SUCCESS: (s, a) => {
        s[a.payload.id] = a.payload;
    }
});
const reducer = combineReducers({
    groupedLists,
    byId
});
// ////////////////////
// combined state
// ////////////////////
export default reducer;
// selectors
const getMessagesIds = (s, conversationNamespace, conversationId) => get(s, ['groupedLists', conversationNamespace, conversationId, 'ids'], []);
export const getList = (s, conversationNamespace, conversationId) => getMessagesIds(s, conversationNamespace, conversationId).map((id) => get(s, ['byId', id]));
export const isFetchListPending = (s, conversationNamespace, conversationId) => get(s, ['groupedLists', conversationNamespace, conversationId, 'pending'], false);
export const hasFetchListFailed = (s, conversationNamespace, conversationId) => get(s, ['groupedLists', conversationNamespace, conversationId, 'failed'], false);
