import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Typography } from 'antd';
import React from 'react';
import { TenantSettingUploadFileField } from '../TenantCompanyLogoField';
import LoginPosition from './components/LoginPosition/LoginPosition';
import { LoginRichText } from './components/LoginTopText';
export const TenantLoginSettings = ({ values, isSubmitting }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "p-2 pt-3 border-top mt-3" },
            React.createElement(Typography.Title, { level: 4 }, t('TENANT_LOGIN_SETTINGS_TITLE')),
            React.createElement(TenantSettingUploadFileField, { value: values.loginPage?.logoUrl, name: "loginPage.logoUrl", label: t('TENANT_COMPANY_LOGO_LOGIN_PAGE'), buttonAddTitle: t('TENANT_UPLOAD_LOGO'), removeButtonTitle: t('TENANT_REMOVE_LOGO'), disabled: isSubmitting }),
            React.createElement(LoginPosition, { isSubmitting: isSubmitting, name: "loginPage.logoPosition", label: t('TENANT_LOGIN_LOGO_POSITION') }),
            React.createElement(TenantSettingUploadFileField, { disabled: isSubmitting, value: values?.loginPage?.backgroundImageUrl, name: "loginPage.backgroundImageUrl", label: t('TENANT_LOGIN_PAGE_BACKGROUND_IMAGE'), subTitle: t('TENANT_LOGIN_PAGE_BACKGROUND_IMAGE_SIZE'), buttonAddTitle: t('TENANT_LOGIN_PAGE_UPLOAD_BACKGROUND_IMAGE'), removeButtonTitle: t('TENANT_LOGIN_PAGE_REMOVE_BACKGROUND_IMAGE') }),
            React.createElement(LoginRichText, { isSubmitting: isSubmitting, label: t('TENANT_LOGIN_PAGE_TOP_TEXT'), name: "loginPage.topText" }),
            React.createElement(LoginRichText, { isSubmitting: isSubmitting, label: t('TENANT_LOGIN_PAGE_BOTTOM_TEXT'), name: "loginPage.bottomText" }),
            React.createElement(LoginPosition, { isSubmitting: isSubmitting, name: "loginPage.position", label: t('TENANT_LOGIN_PAGE_POSITION') }))));
};
