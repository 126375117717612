import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useLocation, useParams } from 'react-router-dom';
import { PropertiesProvider } from '../../../cms/common/context/PropertiesProvider';
import { useDashboards } from './DashboardsProvider';
import DashboardView from './components/DashboardView';
import EditYourTasksForm, { getDefaultYourTasksSettings } from './components/YourTasks/EditYourTasksForm';
import EditActivityForm, { getDefaultActivitySettings } from './components/activity/EditActivityForm';
import EditChartForm, { getDefaultChartSettings } from './components/chart/ChartForm/EditChartForm';
import EditTableForm, { getDefaultTableSettings } from './components/table/EditTableForm';
import { useDashboardPermissions } from './useDashboardPermissions';
function getNewDashboard() {
    return {
        entityId: generateId(),
        title: 'New dashboard',
        configuration: {
            charts: []
        }
    };
}
const DashboardPage = ({ contentHeight, action = 'view', canCreate, canShare }) => {
    const location = useLocation();
    const { t } = useI18n();
    const { dashboardId } = useParams();
    const dispatch = useDispatch();
    const { getDashboard, saveDashboard, deleteDashboard, loading } = useDashboards();
    const [editWidgetDialogState, setEditWidgetDialogState] = useState();
    const [defaultDashboard, setDefaultDashboard] = useState(() => {
        return dashboardId ? getDashboard(dashboardId) : undefined;
    });
    const [currentDashboard, setCurrentDashboard] = useState();
    const [isLocationChanged, setIsLocationChanged] = useState(false);
    const isActionCreate = action === 'create';
    const { canDelete: canDashboardDelete, canEdit: canDashboardEdit, canShare: canDashboardShare } = useDashboardPermissions(isActionCreate ? undefined : currentDashboard?.entityId);
    useEffect(() => {
        const dashboard = dashboardId ? getDashboard(dashboardId) : undefined;
        setDefaultDashboard(dashboard);
    }, [dashboardId, getDashboard]);
    useEffect(() => {
        if (isActionCreate) {
            setCurrentDashboard(getNewDashboard());
        }
    }, [isActionCreate]);
    const isDirtyDashboard = useMemo(() => !isEqual(defaultDashboard, currentDashboard), [currentDashboard, defaultDashboard]);
    const onReset = useCallback(() => {
        if (!defaultDashboard) {
            return;
        }
        const oldDashboard = cloneDeep(defaultDashboard);
        setCurrentDashboard({ ...oldDashboard });
    }, [defaultDashboard]);
    useEffect(() => {
        onReset();
    }, [onReset]);
    const handleLocationChange = useCallback((location) => {
        if (!isLocationChanged) {
            setIsLocationChanged(true);
            setTimeout(() => {
                dispatch(push(location));
                setIsLocationChanged(false);
            }, 50);
        }
    }, [dispatch, isLocationChanged]);
    const onSaveDashboard = useCallback(async (dashboard) => {
        try {
            const result = await saveDashboard(dashboard);
            setCurrentDashboard(result);
            setDefaultDashboard(result);
            const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
            handleLocationChange({
                ...location,
                pathname: `${baseUrl}/${dashboard.entityId}`
            });
            return result;
        }
        catch (e) {
            if (e instanceof Error && e.message) {
                dispatch(error({
                    title: t('ERROR'),
                    message: e.message
                }));
            }
            else {
                console.error('onSaveDashboard error', e);
            }
        }
    }, [saveDashboard, dispatch, location, t, handleLocationChange]);
    const onDelete = useCallback(async (dashboard) => {
        try {
            await deleteDashboard(dashboard);
            const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
            dispatch(push({
                ...location,
                pathname: baseUrl
            }));
        }
        catch (error) {
            console.error('error');
        }
    }, [deleteDashboard, dispatch, location]);
    const onAddWidget = useCallback((type) => {
        let widget;
        switch (type) {
            case 'activity':
                widget = getDefaultActivitySettings();
                break;
            case 'table':
                widget = getDefaultTableSettings();
                break;
            case 'your-tasks':
                widget = getDefaultYourTasksSettings();
                break;
            default:
                widget = getDefaultChartSettings();
                break;
        }
        setEditWidgetDialogState({ action: 'add', widget });
    }, []);
    const onCloseEditWidgetDialog = useCallback(() => {
        setEditWidgetDialogState(undefined);
    }, []);
    const onSaveWidget = useCallback((widget) => {
        if (!currentDashboard) {
            return;
        }
        if (editWidgetDialogState?.action === 'add') {
            currentDashboard.configuration = currentDashboard.configuration || {};
            currentDashboard.configuration.charts = currentDashboard.configuration.charts || [];
            currentDashboard.configuration.charts.push(widget);
            setCurrentDashboard({ ...currentDashboard });
            setEditWidgetDialogState(undefined);
        }
        else if (editWidgetDialogState?.action === 'edit') {
            if (!currentDashboard.configuration?.charts?.length) {
                return;
            }
            currentDashboard.configuration.charts = currentDashboard.configuration.charts.map((item) => {
                if (item.id === widget.id) {
                    return widget;
                }
                return item;
            });
            setCurrentDashboard({ ...currentDashboard });
            setEditWidgetDialogState(undefined);
        }
    }, [currentDashboard, editWidgetDialogState?.action]);
    const onEditWidget = useCallback((dashboard, widget) => {
        setEditWidgetDialogState({ action: 'edit', widget: widget });
    }, []);
    const onDeleteWidget = useCallback((dashboard, widget) => {
        if (!dashboard.configuration?.charts) {
            return;
        }
        dashboard.configuration.charts = dashboard.configuration.charts.filter((item) => item.id !== widget.id);
        setCurrentDashboard({ ...dashboard });
    }, []);
    return (React.createElement(PropertiesProvider, null,
        currentDashboard ? (React.createElement(DashboardView, { className: "mt-3", onSave: onSaveDashboard, onChange: setCurrentDashboard, onDelete: canDashboardDelete ? onDelete : undefined, onAddWidget: onAddWidget, onEditWidget: onEditWidget, onDeleteWidget: onDeleteWidget, dashboard: currentDashboard, isDirty: isDirtyDashboard, isActionCreate: isActionCreate, contentHeight: contentHeight, canCreate: canCreate, canEdit: canDashboardEdit || isActionCreate, canDelete: canDashboardDelete, canShare: canShare && canDashboardShare, onLocationChange: handleLocationChange })) : (React.createElement("div", null, t('HYPER_BI_DASHBOARD_NOT_FOUND'))),
        !loading && !!editWidgetDialogState?.action ? (React.createElement(React.Fragment, null,
            (!editWidgetDialogState.widget?.type ||
                editWidgetDialogState.widget?.type === 'chart') && (React.createElement(EditChartForm, { open: !!editWidgetDialogState.action, title: t('HYPER_BI_WIDGET_SETTINGS'), onCancel: onCloseEditWidgetDialog, onOk: onSaveWidget, value: editWidgetDialogState.widget })),
            editWidgetDialogState.widget?.type === 'activity' && (React.createElement(EditActivityForm, { open: !!editWidgetDialogState.action, title: t('HYPER_BI_WIDGET_SETTINGS'), onCancel: onCloseEditWidgetDialog, onOk: onSaveWidget, value: editWidgetDialogState.widget })),
            editWidgetDialogState.widget?.type === 'table' && (React.createElement(EditTableForm, { open: !!editWidgetDialogState.action, title: t('HYPER_BI_WIDGET_SETTINGS'), onCancel: onCloseEditWidgetDialog, onOk: onSaveWidget, value: editWidgetDialogState.widget })),
            editWidgetDialogState.widget?.type === 'your-tasks' && (React.createElement(EditYourTasksForm, { open: !!editWidgetDialogState.action, title: t('HYPER_BI_WIDGET_SETTINGS'), onCancel: onCloseEditWidgetDialog, onOk: onSaveWidget, value: editWidgetDialogState.widget })))) : null,
        React.createElement(Prompt, { when: isDirtyDashboard && !isLocationChanged, message: t('UNSAVED_DATA_CONFIRM') })));
};
export default DashboardPage;
