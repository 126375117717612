import { BsCheckLg } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import styled, { css } from 'styled-components';
export const ColorContainer = styled.div `
  ${({ disabled }) => disabled && `opacity: .45; pointer-events: none;`}

  position: relative;
  width: fit-content;
  margin: 0.1rem;
  transition: opacity 0.25s ease;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
export const Color = styled.div `
  width: 2.5rem;
  height: 1.4rem;
  background: ${(props) => props.color};
  border-radius: 4px;
`;
export const ResetColor = styled.div `
  width: 2.5rem;
  height: 1.4rem;
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--primaryColor-6);
`;
export const StyledIcon = css `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: var(--grayscaleColor-1);
  stroke: var(--grayscaleColor-1);
  pointer-events: none;
`;
export const StyledCheck = styled(BsCheckLg) `
  ${StyledIcon};
`;
export const StyledClose = styled(IoMdClose) `
  ${StyledIcon};
  stroke-width: 16px;
  fill: var(--primaryColor-6);
  stroke: var(--primaryColor-6);
`;
