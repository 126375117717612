export const disabledTenantSettingsFormFields = [
    {
        id: 'id',
        label: 'ID'
    },
    {
        id: 'name',
        label: 'TENANT_NAME'
    },
    {
        id: 'country',
        label: 'COMMON__COUNTRY'
    }
];
export const editableTenantSettingsFormFields = [
    'logoUrl',
    'customHosts',
    'auth',
    'overrideTenantLogo',
    'theme',
    'hyperFormTheme',
    'defaultLanguage',
    'availableLanguages',
    'loginPage'
];
export const tenantSettingsFormFields = [
    ...disabledTenantSettingsFormFields.map(({ id }) => id),
    ...editableTenantSettingsFormFields
];
export const fontFormats = [
    { value: 'ttf', label: 'ttf' },
    { value: 'otf', label: 'otf' },
    { value: 'woff', label: 'woff' },
    { value: 'woff2', label: 'woff2' }
];
export const fontStyles = [
    { value: 'normal', label: 'normal' },
    { value: 'italic', label: 'italic' }
];
export const fontWeights = [
    { value: 100, label: 100 },
    { value: 200, label: 200 },
    { value: 300, label: 300 },
    { value: 400, label: 400 },
    { value: 500, label: 500 },
    { value: 600, label: 600 },
    { value: 700, label: 700 },
    { value: 800, label: 800 },
    { value: 900, label: 900 }
];
