import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import useDisplayItemMetaList from '../../../cms/hooks/useDisplayItemMetaList';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../workflows';
import { ErrorLabel, getIsFormHasError } from '../../utils/formUtils';
import { Select } from '../Select';
const ItemMetaSelector = ({ label, value, disabled, className, onChange, includeItemMeta, input, meta, ...otherProps }) => {
    const { t } = useI18n();
    const { data: entities } = useDisplayItemMetaList();
    const showError = useMemo(() => getIsFormHasError(meta), [meta]);
    const filteredEntities = useMemo(() => entities.filter((entity) => includeItemMeta
        ? includeItemMeta(entity)
        : ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(entity.definitionId)), [entities, includeItemMeta]);
    const _onChange = useCallback((value) => {
        input?.onChange && input.onChange(value);
        onChange && onChange(value);
    }, [input, onChange]);
    return (React.createElement("div", { className: className },
        label && React.createElement(LabelText, { className: "fw-500" }, label),
        React.createElement(Select, { className: "w-100", showSearch: true, disabled: disabled, onChange: _onChange, value: input?.value || value, optionFilterProp: "label", status: showError ? 'warning' : undefined, placeholder: `${t('SELECT_INPUT')}${label && typeof label === 'string' ? ` ${label}` : ''}`, options: filteredEntities.map((entity) => ({
                value: entity.definitionId,
                label: entity.title || entity.definitionId
            })), ...otherProps }),
        React.createElement(ErrorLabel, { show: !!showError, position: 'relative', error: meta?.error })));
};
export default ItemMetaSelector;
