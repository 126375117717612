import { NOTIFICATIONS_BASE_API_PATH } from './constants';
export const fetchUserNotifications = (page, pageSize, params) => ({
    type: 'NOTIFICATIONS__FETCH_USER_NOTIFICATIONS',
    meta: {
        http: {
            path: NOTIFICATIONS_BASE_API_PATH,
            method: 'GET',
            params: {
                offset: (page - 1) * pageSize,
                limit: pageSize,
                ...params
            },
            actionContext: { page, pageSize, params },
            allowPromiseReject: true
        }
    }
});
export const updateUserNotification = (id, action) => ({
    type: 'NOTIFICATIONS__UPDATE_USER_NOTIFICATION',
    meta: {
        http: {
            path: `${NOTIFICATIONS_BASE_API_PATH}/${id}/action/${action}`,
            method: 'PUT',
            actionContext: { id, action },
            allowPromiseReject: true
        }
    }
});
export const markAllNotificationsAsRead = () => ({
    type: 'NOTIFICATIONS__MARK_ALL_AS_READ',
    meta: {
        http: {
            path: `${NOTIFICATIONS_BASE_API_PATH}/action/OPEN`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
