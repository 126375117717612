const routerMiddlewareFactory = (history) => {
    const routerMiddleware = () => (next) => (action) => {
        switch (action.type) {
            case 'ROUTER__LOCATION_PUSH':
                history.push(action.payload);
                break;
            case 'ROUTER__LOCATION_REPLACE':
                history.replace(action.payload);
                break;
            case 'ROUTER__LOCATION_BACK':
                history.goBack();
                break;
        }
        return next(action);
    };
    return routerMiddleware;
};
export default routerMiddlewareFactory;
