import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import { AVAILABLE_LANGUAGES } from 'config';
import React, { useCallback } from 'react';
import Toggle from '../../../../../../common/components/Toggle';
import ComputedExpressionField from '../../ComputedExpressionField';
import { normalizeNumber } from '../../utils';
import { Info } from '../styled';
const NumberField = ({ disabled, metaDefinitionId, onChange, value, error }) => {
    const { t } = useI18n();
    const onChangeComputedExpression = useCallback((val) => {
        onChange({ ...value, computedValue: val });
    }, [onChange, value]);
    const onChangePrecision = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                precision: normalizeNumber(event.target.value)
            }
        });
    }, [onChange, value]);
    const onChangeUseGrouping = useCallback((checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                useGrouping: checked
            }
        });
    }, [onChange, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ComputedExpressionField, { className: "w-100 py-2", disabled: disabled, metaDefinitionId: metaDefinitionId, onChange: onChangeComputedExpression, value: value.computedValue, error: typeof error === 'string' ? error : undefined }),
        React.createElement(BasicInput, { className: "col-12 col-md-4 py-2", type: "number", label: React.createElement(Tooltip, { placement: "right", title: t('PROPERTY_PRECISION_TOOLTIP') },
                React.createElement("span", null,
                    t('PROPERTY_PRECISION'),
                    React.createElement(Info, null))), disabled: disabled, value: value.meta.precision, onChange: onChangePrecision, error: typeof error !== 'string' ? error?.meta?.precision?.toString() : undefined }),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('OPTIONS_USE_GROUPING'), disabled: disabled, onChange: onChangeUseGrouping, checked: value.meta.useGrouping }),
        React.createElement("label", { className: "py-2 fw-500" }, t('PROPERTY_UNITS')),
        React.createElement("div", { className: "row py-2" }, AVAILABLE_LANGUAGES.map((lang) => (React.createElement("div", { className: "col-12 col-sm-4", key: lang },
            React.createElement(BasicInput, { type: "text", label: t(lang), disabled: disabled, value: value.meta.units?.[lang], onChange: (event) => {
                    onChange({
                        ...value,
                        meta: {
                            ...value.meta,
                            units: {
                                ...value.meta.units,
                                [lang]: event.target.value
                            }
                        }
                    });
                } })))))));
};
export default NumberField;
