import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { combineReducers } from 'redux';
const configByKey = createReducer({}, {
    CONFIGS__FETCH_SUCCESS: (s, a) => {
        const { meta, payload } = a;
        s[meta.actionContext.key] = payload;
    },
    CONFIGS__SAVE_SUCCESS: (s, a) => {
        const { meta, payload } = a;
        s[meta.actionContext.key] = payload;
    }
});
const R = {
    CONFIGS__FETCH: (s, a) => {
        const { key } = a.meta.http.actionContext;
        const status = s[key] || {};
        status.pending = true;
        s[key] = status;
    },
    CONFIGS__FETCH_SUCCESS: (s, a) => {
        const { key } = a.meta.actionContext;
        const status = s[key] || {};
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[key] = status;
    },
    CONFIGS__FETCH_FAIL: (s, a) => { }
};
R.CONFIGS__FETCH_FAIL = (s, a) => {
    if (a.payload.status === 204) {
        R.CONFIGS__FETCH_SUCCESS(s, a);
    }
    else {
        const { key } = a.meta.actionContext;
        const status = s[key] || {};
        status.pending = false;
        status.failed = true;
        s[key] = status;
    }
};
const configStatus = createReducer({}, R);
export default combineReducers({
    byKey: configByKey,
    status: configStatus
});
