import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { useCallback, useMemo, useRef } from 'react';
import { error } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { getPropertyById } from '../../cms/common/utils/utils';
import useCreateItem from '../../cms/hooks/useCreateItem';
import useDisplayData from '../../cms/hooks/useDisplayData';
import { CONTACT_CMS_DEFINITION_ID, ORGANIZATION_CMS_DEFINITION_ID } from '../../crm';
import { useMyContact } from '../../crm/components/use-my-contact';
import queryClient from '../utils/react-query-client';
import { ACTIVITY_DEFINITION_ID } from './constants';
import { ActivityPriority, ActivityStatus, ActivityType } from './types';
import { ACTIVITY_QUERY_CACHE_KEY } from './useActivities';
import { useContactFromEntityItem } from './useContactFromEntityItem';
export function useActivityForm({ definitionId, entityId, onSubmitted }) {
    const { t } = useI18n();
    const myContact = useMyContact();
    const refreshTimer = useRef();
    const dispatch = useDispatch();
    const { mutateAsync: activityCreateItem } = useCreateItem({
        definitionId: ACTIVITY_DEFINITION_ID
    });
    const { data: displayDataList } = useDisplayData({ definitionId, entityId }, {
        enabled: definitionId === CONTACT_CMS_DEFINITION_ID
    });
    const currentItemOrganization = useMemo(() => {
        if (!definitionId || !entityId) {
            return;
        }
        if (definitionId !== CONTACT_CMS_DEFINITION_ID) {
            return;
        }
        return getPropertyById(displayDataList, ORGANIZATION_CMS_DEFINITION_ID);
    }, [definitionId, displayDataList, entityId]);
    const foundedContactInEntityItem = useContactFromEntityItem({ definitionId, entityId });
    const onRefresh = useCallback(async () => {
        await queryClient.refetchQueries([
            ...ACTIVITY_QUERY_CACHE_KEY,
            definitionId || '',
            entityId || ''
        ]);
    }, [definitionId, entityId]);
    const refreshWithDelay = useCallback(() => {
        if (refreshTimer.current) {
            clearTimeout(refreshTimer.current);
            refreshTimer.current = undefined;
        }
        refreshTimer.current = setTimeout(() => {
            void onRefresh();
        }, 1000);
    }, [onRefresh]);
    const onSubmit = useCallback(async (activity) => {
        const newActivity = {
            ...activity,
            entityId: generateId()
        };
        if (newActivity.dueDate) {
            newActivity.dueDate = newActivity.dueDate.valueOf();
        }
        try {
            await activityCreateItem(newActivity);
            !!onSubmitted && onSubmitted(newActivity);
            refreshWithDelay();
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('ENTITIES_CREATE_FAIL')
            }));
            throw err;
        }
    }, [activityCreateItem, onSubmitted, refreshWithDelay, dispatch, t]);
    const initialActivity = (activityType) => {
        const val = {
            entityId: generateId(),
            title: '',
            assignedTo: myContact.contactId || '',
            priority: ActivityPriority.NORMAL,
            statusItem: ActivityStatus.Completed,
            typeText: activityType.entityId
        };
        if (definitionId === CONTACT_CMS_DEFINITION_ID) {
            val.contact = entityId;
            val.relatedToDefinitionId = ORGANIZATION_CMS_DEFINITION_ID;
            if (currentItemOrganization?.value) {
                val.relatedToEntityId = currentItemOrganization.value.toString();
            }
        }
        else {
            if (foundedContactInEntityItem && typeof foundedContactInEntityItem === 'string') {
                val.contact = foundedContactInEntityItem;
            }
            val.relatedToDefinitionId = definitionId;
            val.relatedToEntityId = entityId;
        }
        if (activityType.entityId === ActivityType.TASK) {
            val.statusItem = ActivityStatus.NotStarted;
        }
        return val;
    };
    return {
        initialActivity,
        onSubmit
    };
}
