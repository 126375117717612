import { CMS_API_PATH } from '../common/utils/constants';
// ////////////////////
// Entities Meta list
// ////////////////////
export const fetchEntitiesMetaList = (page, pageSize) => ({
    type: 'ENTITY_META__FETCH_LIST',
    meta: {
        http: {
            path: `${CMS_API_PATH}/item-meta`,
            method: 'GET',
            params: {
                offset: (page - 1) * pageSize,
                limit: pageSize
            },
            actionContext: { page, pageSize },
            allowPromiseReject: true
        }
    }
});
// ////////////////////
// Entity Meta single
// ////////////////////
export const fetchItemMeta = (definitionId) => ({
    type: 'ENTITY_META__FETCH_SINGLE',
    meta: {
        http: {
            path: `${CMS_API_PATH}/item-meta/${definitionId}`,
            method: 'GET',
            actionContext: { definitionId },
            allowPromiseReject: true
        }
    }
});
export const createItemMeta = (formValues) => ({
    type: 'ENTITY_META__CREATE',
    payload: formValues,
    meta: {
        http: {
            path: `${CMS_API_PATH}/item-meta`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const updateItemMeta = (entityMeta) => ({
    type: 'ENTITY_META__UPDATE',
    payload: entityMeta,
    meta: {
        http: {
            path: `${CMS_API_PATH}/item-meta/${entityMeta.definitionId}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
export const deleteItemMeta = (definitionId) => ({
    type: 'ENTITY_META__DELETE',
    meta: {
        http: {
            path: `${CMS_API_PATH}/item-meta/${definitionId}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: { definitionId }
        }
    }
});
// // ////////////////////
// // MetaDefinition
// // ////////////////////
const metaDefinitionUrl = `${CMS_API_PATH}/definition`;
export const fetchMetaDefinition = (metaDefinitionId) => ({
    type: 'ENTITY_META_DEFINITION__FETCH',
    meta: {
        http: {
            path: `${metaDefinitionUrl}/${metaDefinitionId}`,
            method: 'GET',
            actionContext: { metaDefinitionId },
            allowPromiseReject: true
        }
    }
});
