import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
const StyledLink = styled(NavLink) `
  display: flex;
  align-items: center;
  font-weight: inherit !important;
  border: 0 !important;
  transition: color 200ms ease;

  &:hover {
    color: ${(props) => props.theme.mainColorVibrant};
  }
`;
const CurrentBreadcrumb = styled.span `
  font-weight: 600;

  * {
    display: inline-block;
  }
`;
const Container = styled.div `
  font-size: 0.85rem;
  padding: 0.75rem 1rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  ${StyledLink} ~ ${StyledLink}::before, ${CurrentBreadcrumb}::before {
    content: '›';
    padding: 0 0.5rem;
    margin-top: -5px;
    font-size: 1.4rem;
    user-select: none;
    cursor: default;
    font-weight: 500;
    line-height: 1;
    color: var(--neutralColor-2);
  }
`;
const Breadcrumb = styled.span `
  font-weight: 600;
`;
export class Breadcrumbs extends PureComponent {
    constructor() {
        super(...arguments);
        this.getBreadcrumbJsx = ({ to, text, ...otherProps }, index) => (React.createElement(StyledLink, { key: `${text}-${index}`, to: to, ...otherProps }, text));
    }
    render() {
        const { links, currentBreadcrumbText, currentBreadcrumbComponent } = this.props;
        return (React.createElement(Container, { className: "breadcrumbs" },
            React.createElement(Helmet, { title: [...links.map((l) => l.text), currentBreadcrumbText].filter((t) => t).join(' › ') }),
            links.length > 0 ? (React.createElement(React.Fragment, null,
                links.map(this.getBreadcrumbJsx),
                (currentBreadcrumbText || currentBreadcrumbComponent) && (React.createElement(CurrentBreadcrumb, null, currentBreadcrumbText || currentBreadcrumbComponent)))) : (React.createElement(Breadcrumb, null, currentBreadcrumbText || currentBreadcrumbComponent))));
    }
}
export default {
    Breadcrumbs
};
