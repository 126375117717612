import React, { Component } from 'react';
import styled from 'styled-components';
import LoadingRectangles from '../loading-rectangles';
const Wrapper = styled.div `
  padding: 2rem;
`;
class DynamicImport extends Component {
    constructor() {
        super(...arguments);
        this.unmounted = false;
        this.state = {
            component: null
        };
    }
    componentDidMount() {
        this.props.load().then(component => {
            if (!this.unmounted) {
                this.setState(() => ({
                    component: component.default && !this.props.loadAllExports ? component.default : component
                }));
            }
        });
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    render() {
        if (!this.state.component) {
            return this.props.inline ? ('...') : (React.createElement(Wrapper, null,
                React.createElement(LoadingRectangles, null)));
        }
        else {
            return this.props.children(this.state.component);
        }
    }
}
export default DynamicImport;
