import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { debounce } from 'lodash';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TITLE_PROPERTY_ID } from '../../../cms';
import { getPropertyById } from '../../../cms/common/utils/utils';
import { isEntityDisplayData } from '../../../cms/data/types';
import { RowHeightT } from '../../../views/types';
import { addToLocalStorage, getFromLocalStorage } from '../../utils/localStorageUtils';
export const StyledLink = styled(Link) `
  font-weight: inherit !important;
  border: 0 !important;
  transition: color 200ms ease;
  word-break: break-word;
  font-size: 14px;
  line-height: 16px;
  color: var(--primaryColor-6);

  &:hover {
    color: var(--primaryColor-5);
  }
`;
export const ScExternalLink = StyledLink.withComponent('a');
export const ScExternalLinkIcon = styled(FaExternalLinkAlt) `
  margin-left: 0.125rem;
`;
const storageKeyPrefix = 'DataTable:';
const defaultCustomizations = { columns: [] };
const getTableStorageKey = () => `${storageKeyPrefix}${window.location.pathname}`;
const actualStoreTableCustomizations = ({ columns, pageSize }) => {
    const storageKey = getTableStorageKey();
    const existingValues = getFromLocalStorage(storageKey, defaultCustomizations);
    addToLocalStorage(storageKey, {
        // @ts-expect-error
        columns: columns || (existingValues && existingValues.columns) || undefined,
        // @ts-expect-error
        pageSize: pageSize || (existingValues && existingValues.pageSize) || undefined // This stores the pageSize of the data tables
    });
};
export const storeTableCustomizations = debounce(actualStoreTableCustomizations, 200);
export const getStoredTableCustomizations = () => getFromLocalStorage(getTableStorageKey(), defaultCustomizations);
export const actionButtonIconCss = css `
  ${media.sm} {
    margin-right: 0.3rem;
  }
`;
export const TABLE_EXTRA_COLS = ['select', 'open-item'];
export const ROW_HEIGHT_TYPES_LIST = [RowHeightT.SMALL, RowHeightT.MEDIUM, RowHeightT.TALL];
export const ROW_HEIGHT_TYPES_TO_PIXELS = {
    [RowHeightT.SMALL]: 48,
    [RowHeightT.MEDIUM]: 72,
    [RowHeightT.TALL]: 96
};
export const getFieldGroupingRow = (rowGrouping, displayData) => {
    if (!rowGrouping) {
        return;
    }
    const groupByProperty = getPropertyById(displayData, rowGrouping);
    return isEntityDisplayData(groupByProperty)
        ? `${groupByProperty.propertyId}.${TITLE_PROPERTY_ID}`
        : rowGrouping;
};
