import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { isUndefined } from 'lodash';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useBrowserBreakpoints } from '../with-browser-breakpoints';
import ActivityStickyContainer from './tabs/Activity/ActivityStickyContainer';
import ActivityIcon from '../../activity/components/ActivityList/ActivityIcon';
const StickyTabsContext = createContext(undefined);
const closeIfNeededOpenedTab = ({ newActiveTabs, availableTabCount, maxCountOpenTabs, additionalCondition }) => {
    const countCurrentOpenTabs = newActiveTabs
        .slice(0, availableTabCount)
        .filter((tab) => tab.isOpen).length;
    const conditions = (!isUndefined(additionalCondition) &&
        additionalCondition &&
        countCurrentOpenTabs >= maxCountOpenTabs) ||
        (isUndefined(additionalCondition) && countCurrentOpenTabs >= maxCountOpenTabs);
    if (conditions) {
        let isOpenFirstFound = true;
        return newActiveTabs.map((tab) => {
            if (tab.isOpen && isOpenFirstFound) {
                isOpenFirstFound = false;
                return { ...tab, isOpen: false };
            }
            return tab;
        });
    }
    return newActiveTabs;
};
export const StickyTabsProvider = ({ children }) => {
    const [activeTabs, setActiveTabs] = useState([]);
    const isActiveStickySection = !!activeTabs.length;
    const { isTablet, isDesktop } = useBrowserBreakpoints();
    const maxCountActiveTabs = useMemo(() => {
        switch (true) {
            case isDesktop:
                return 3;
            case isTablet:
                return 1;
            default:
                return 2;
        }
    }, [isDesktop, isTablet]);
    const maxCountOpenTabs = maxCountActiveTabs - 1;
    const addNewTab = useCallback((activityType) => {
        const newActiveTabs = [...activeTabs];
        if (!activityType) {
            return;
        }
        const newTab = {
            title: activityType.title,
            Icon: (React.createElement(ActivityIcon, { activityType: activityType })),
            component: ActivityStickyContainer,
            defaultTitle: activityType.title,
            id: generateId(),
            isOpen: true,
            isDirty: false,
            activityType
        };
        const tabs = closeIfNeededOpenedTab({
            newActiveTabs,
            availableTabCount: maxCountOpenTabs,
            maxCountOpenTabs
        });
        setActiveTabs([newTab, ...tabs]);
    }, [activeTabs, maxCountOpenTabs]);
    const changeActiveTabs = useCallback((currentTabId) => {
        const newActiveTabs = [...activeTabs];
        const isOpenedCurrentTab = newActiveTabs.find((el) => el.id === currentTabId)?.isOpen;
        const tabs = closeIfNeededOpenedTab({
            newActiveTabs,
            availableTabCount: maxCountActiveTabs,
            maxCountOpenTabs,
            additionalCondition: !isOpenedCurrentTab
        });
        setActiveTabs(tabs.map((el) => el.id === currentTabId
            ? {
                ...el,
                isOpen: !el.isOpen
            }
            : el));
    }, [activeTabs, maxCountActiveTabs, maxCountOpenTabs]);
    const changeOverflowTabs = useCallback((currentTabId) => {
        const newActiveTabs = activeTabs.filter((tab) => tab.id !== currentTabId);
        const currentActiveTab = activeTabs.find((tab) => tab.id === currentTabId);
        if (currentActiveTab) {
            const tabs = closeIfNeededOpenedTab({
                newActiveTabs,
                availableTabCount: maxCountOpenTabs,
                maxCountOpenTabs
            });
            setActiveTabs([{ ...currentActiveTab, isOpen: true }, ...tabs]);
        }
    }, [activeTabs, maxCountOpenTabs]);
    const setTabLabel = useCallback((currentTabId, newTitle, defaultTitle) => {
        const title = newTitle || defaultTitle;
        setActiveTabs((activeTabs) => {
            return activeTabs.map((tab) => {
                if (tab.id === currentTabId) {
                    return { ...tab, title };
                }
                return tab;
            });
        });
    }, []);
    const setTabDirty = useCallback((currentTabId, isDirty) => {
        setActiveTabs((activeTabs) => {
            return activeTabs.map((tab) => {
                if (tab.id === currentTabId) {
                    return { ...tab, isDirty };
                }
                return tab;
            });
        });
    }, []);
    const filteredTab = useCallback((currentTabId) => {
        setActiveTabs((activeTabs) => activeTabs.filter((tab) => tab.id !== currentTabId));
    }, []);
    const value = useMemo(() => ({
        activeTabs,
        isActiveStickySection,
        changeActiveTabs,
        changeOverflowTabs,
        filteredTab,
        addNewTab,
        setTabDirty,
        setTabLabel,
        maxCountActiveTabs
    }), [
        activeTabs,
        isActiveStickySection,
        changeActiveTabs,
        changeOverflowTabs,
        filteredTab,
        addNewTab,
        setTabDirty,
        setTabLabel,
        maxCountActiveTabs
    ]);
    return React.createElement(StickyTabsContext.Provider, { value: value }, children);
};
export const useStickyTabs = () => {
    const context = useContext(StickyTabsContext);
    if (context == null) {
        throw new Error('useStickyTabs must be used within an StickyTabsProvider');
    }
    return context;
};
