import { FastField, Field } from 'formik';
import { get } from 'lodash';
import React from 'react';
export default ({ component: Component, validate, name, optimized = false, clearValue, fast = false, ...otherProps }) => {
    const onChange = (
    // event in case of antd components is actually the value and
    // not the native event
    originalOnChange, setFieldValue, fieldName, currentValue) => (event) => {
        event = clearValue ? clearValue(event) : event;
        if (!event || !event.target) {
            setFieldValue(fieldName, event);
        }
        else {
            originalOnChange(event);
        }
    };
    const onBlur = (originalOnBlur, name) => {
        // this is going to call setFieldTouched and manually update touched.topcis
        return () => {
            originalOnBlur(name, true);
        };
    };
    const FieldComponent = fast ? FastField : Field;
    return (React.createElement(FieldComponent, { name: name }, ({ field, form }) => (React.createElement(Component, { ...field, onChange: onChange(field.onChange, form.setFieldValue, name, field.value), onBlur: onBlur(field.onBlur, field.name), name: name, error: get(form.errors, name), meta: {
            touched: get(form.touched, name),
            error: get(form.errors, name),
            submitFailed: !form.isValid && form.submitCount > 0
        }, input: {
            value: field.value,
            onChange: onChange(field.onChange, form.setFieldValue, name, field.value)
        }, ...otherProps }))));
};
