import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Modal, Tooltip } from 'antd';
import { get, noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import DropdownButton from '../DropdownButton';
import styles from './ActionsButton.module.scss';
const ActionsButton = ({ className, buttonAction, menuActions = [], menuIcon, submitting, inversed, disabled }) => {
    const { t } = useI18n();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [activeActionLabel, setActiveActionLabel] = useState('');
    const activeActionRef = useRef(() => Promise.resolve());
    const unmounted = useRef(false);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    const canRunAnyRestActions = useMemo(() => menuActions.reduce((acc, action) => acc || action.canRun, false), [menuActions]);
    const runAction = useCallback(() => {
        const promise = activeActionRef?.current?.();
        if (promise?.then) {
            void promise.then(() => {
                if (!unmounted.current) {
                    setShowConfirmation(false);
                    setActiveActionLabel('');
                    activeActionRef.current = () => Promise.resolve();
                }
            });
        }
        else if (!unmounted.current) {
            setShowConfirmation(false);
            setActiveActionLabel('');
            activeActionRef.current = () => Promise.resolve();
        }
        return promise;
    }, []);
    return (React.createElement("div", { className: `${styles.outer} ActionsButtonOuter ${className || ''}` },
        React.createElement(DropdownButton, { inversed: inversed, loading: submitting, menuDisabled: submitting || disabled, menuIcon: menuIcon, onClick: get(buttonAction, 'action', noop), disabled: (buttonAction && !buttonAction.canRun) || submitting || disabled, dropdownOverlayClassName: styles.dropdownOverlay, menu: canRunAnyRestActions
                ? {
                    items: menuActions
                        .filter((action) => action.canRun)
                        .map((action) => ({
                        className: `dropdown-item ${action.delete ? 'danger' : ''} ${action.disabled ? 'disabled' : ''}`,
                        disabled: !!action.disabled,
                        key: action.key || action.buttonLabel || action.toString(),
                        onClick: (event) => {
                            action?.domEventHandler?.(event.domEvent);
                            if (action.confirmationLabel) {
                                setShowConfirmation(!!action.confirmationLabel);
                                activeActionRef.current = action.action;
                                setActiveActionLabel(action.confirmationLabel);
                            }
                            else {
                                void action.action();
                            }
                        },
                        label: (React.createElement(React.Fragment, null,
                            action.buttonJsx ? action.buttonJsx : t(action.buttonLabel || ''),
                            action.disabled && action.disabledReasonLabel && (React.createElement(Tooltip, { placement: "right", title: t(action.disabledReasonLabel) },
                                React.createElement(IoMdInformationCircle, { className: "info-icon" })))))
                    }))
                }
                : undefined }, buttonAction &&
            (buttonAction.buttonJsx ? buttonAction.buttonJsx : t(buttonAction.buttonLabel || ''))),
        showConfirmation && (React.createElement("div", { onClick: (e) => e.stopPropagation() },
            React.createElement(Modal, { centered: true, open: showConfirmation, title: t(activeActionLabel), onOk: runAction, onCancel: () => setShowConfirmation(false), footer: [
                    React.createElement(Button, { key: "no", inversed: true, onClick: () => setShowConfirmation(false) }, t('COMMON__NO')),
                    React.createElement(Button, { key: "yes", onClick: runAction }, t('COMMON__YES'))
                ] }, t(`${activeActionLabel}_CONFIRMATION`))))));
};
export default ActionsButton;
