import React, { useMemo } from 'react';
import LoadingIndicator from '../../../../../../common/components/loading-indicator';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../../../crm';
import { useMyContact } from '../../../../../../crm/components/use-my-contact';
import { ViewProvider, useViews } from '../../../../../../views';
import { ALL_TASKS_DEFINITION_ID } from '../../../../../../workflows';
import { TaskDashboardProvider } from '../../../../../../workflows/dashboard/components/dashboard/TaskDashboardContext/TaskDashboardProvider';
import { getWorkflowsTaskFilterTypeConfig, mapPropertyWorkflowsTaskDashboardGroupBy } from '../../../../../../workflows/dashboard/constants';
import { getCustomWorkflowsKanbanColumnsFilters, getDefaultFilterQueryWorkflowsTaskDashboardBase, getNewFilterForWorkflowsTaskDashboard, getPermissionsUpdateFilters, getWorkflowTaskDashboardDesignFields, getWorkflowsTaskDashboardView } from '../../../../../../workflows/dashboard/utils';
import WorkflowsTasksWidget from './WorkflowsTasksWidget';
const WorkflowsTasksWidgetWrapper = ({ className, settings, defaultFilter }) => {
    const { contactId, contactGroups } = useMyContact();
    const { views } = useViews();
    const viewTaskDashboard = useMemo(() => getWorkflowsTaskDashboardView(views, settings.viewId), [settings.viewId, views]);
    const getDefaultFilterQueryWorkflowsTaskDashboard = useMemo(() => getDefaultFilterQueryWorkflowsTaskDashboardBase(contactGroups), [contactGroups]);
    return (React.createElement("div", { className: className }, viewTaskDashboard ? (React.createElement(ViewProvider, { enableGrouping: false, additionalFiltersForSearch: getPermissionsUpdateFilters(contactGroups), getCustomKanbanColumnsFilters: getCustomWorkflowsKanbanColumnsFilters, serializeToUrl: false, context: {
            definitionId: CONTACT_CMS_DEFINITION_ID,
            contactId
        }, definitionId: ALL_TASKS_DEFINITION_ID, view: viewTaskDashboard, getDesignFields: getWorkflowTaskDashboardDesignFields },
        React.createElement(TaskDashboardProvider, { getTaskFilterTypeConfig: getWorkflowsTaskFilterTypeConfig, mapProperty: mapPropertyWorkflowsTaskDashboardGroupBy, getDefaultFilterQueryTaskDashboard: getDefaultFilterQueryWorkflowsTaskDashboard, getNewFilterForTaskDashboard: getNewFilterForWorkflowsTaskDashboard },
            React.createElement(WorkflowsTasksWidget, { className: "w-100 h-100", defaultFilter: defaultFilter, settings: settings })))) : (React.createElement(LoadingIndicator, null))));
};
export default WorkflowsTasksWidgetWrapper;
