import React, { Component, createRef } from 'react';
import { withProps } from 'recompose';
import { compose } from 'redux';
import styled from 'styled-components';
import { useI18n } from '../../i18n';
import { Button } from '../button';
const RemoveConfirmationButton = styled((props) => React.createElement(Button, { ...props })) `
  background: ${({ inversed }) => !inversed && '#e00000'};
  border-color: ${({ inversed }) => !inversed && '#e00000'};
  word-break: normal;
  overflow: unset;

  &:hover {
    background: ${({ inversed }) => !inversed && 'red'};
    border-color: ${({ inversed }) => !inversed && 'red'};
  }

  &:focus,
  &:active {
    color: white !important;
    background: #e00000;
    border-color: #e00000 !important;
  }
`;
class ConfirmationButtonComponent extends Component {
    constructor() {
        super(...arguments);
        this.timeout = null;
        this.node = createRef();
        this.unmounted = false;
        this.state = {
            showConfirmation: false,
            loading: false
        };
        this.handleConfirmed = () => {
            const result = this.props.onClick?.();
            if (result && result.finally) {
                result.finally(() => {
                    if (!this.unmounted) {
                        this.setState({ showConfirmation: false });
                    }
                });
            }
            else {
                if (this.props.loading == null) {
                    this.setState({ loading: true });
                    // this timeout is in case the delete fails
                    this.timeout = setTimeout(() => {
                        this.setState({ showConfirmation: false, loading: false });
                    }, 1000);
                }
            }
            document.removeEventListener('click', this.handleOutsideClick, false);
            return result;
        };
        this.handleOutsideClick = (e) => {
            if (this.node.current && this.node.current?.contains(e.target)) {
                return;
            }
            this.setState({ showConfirmation: false });
            document.removeEventListener('click', this.handleOutsideClick, false);
        };
        this.showConfirmation = () => {
            this.setState({ showConfirmation: true });
            document.addEventListener('click', this.handleOutsideClick, false);
        };
        this.handleOnClick = (e) => {
            const handleOnClick = this.state.showConfirmation
                ? this.handleConfirmed
                : this.showConfirmation;
            e.preventDefault();
            e.stopPropagation();
            return handleOnClick();
        };
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.loading === true && newProps.loading === false) {
            this.setState({ showConfirmation: false });
        }
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
        this.unmounted = true;
        if (this.timeout != null) {
            clearTimeout(this.timeout);
        }
    }
    getButtonStyle() {
        const { originalStyle, confirmStyle } = this.props;
        const baseConfirmStyle = Object.assign({
            maxWidth: '100%'
        }, confirmStyle || {});
        const baseButtonStyle = Object.assign({
            padding: '0 0.2rem',
            minWidth: '40px'
        }, originalStyle || {});
        return (this.state.showConfirmation ? baseConfirmStyle : baseButtonStyle) || {};
    }
    render() {
        const { disabled, children, className, inversed, confirmText, t } = this.props;
        const { showConfirmation } = this.state;
        return (React.createElement(RemoveConfirmationButton, { onClick: this.handleOnClick, loading: this.props.loading || this.state.loading, disabled: disabled, style: this.getButtonStyle(), ref: this.node, className: className, inversed: inversed }, showConfirmation ? (confirmText ? confirmText : t?.('COMMON__DELETE')) : children));
    }
}
export default compose(withProps(() => {
    const { t } = useI18n();
    return { t };
}))(ConfirmationButtonComponent);
