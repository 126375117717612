import { Input } from 'antd';
import { colord } from 'colord';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
export const ColorInput = ({ color = '', onChange, name }) => {
    const colorRgba = useMemo(() => colord(color).toRgb(), [color]);
    const isAlfa = name === 'a';
    const [value, setValue] = useState(colorRgba[name]);
    const handleChange = useCallback((e) => {
        const inputValue = +e.target.value;
        if (isAlfa && inputValue <= 0) {
            setValue(0);
        }
        else if (isAlfa && inputValue >= 100) {
            setValue(100);
        }
        else {
            setValue(inputValue);
        }
        const newColor = colord({
            ...colorRgba,
            [name]: isAlfa ? inputValue / 100 : inputValue
        });
        if (newColor.isValid() && onChange) {
            onChange(newColor.toHex());
        }
    }, [colorRgba, isAlfa, name, onChange]);
    const handleBlur = useCallback((e) => {
        const inputValue = +e.target.value;
        const newColor = colord({
            ...colorRgba,
            [name]: isAlfa ? inputValue / 100 : inputValue
        });
        if (!colord(newColor).isValid()) {
            setValue(colorRgba[name]);
        }
    }, [colorRgba, isAlfa, name]);
    useEffect(() => {
        setValue(colorRgba[name]);
    }, [colorRgba, name]);
    return (React.createElement(Input, { type: "number", className: "rounded", value: isAlfa ? Math.round(value * 100) : value, onChange: handleChange, onBlur: handleBlur }));
};
