import { getCurrentLanguage } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TITLE_PROPERTY_ID } from '../../cms';
import { useEntityDisplayData } from '../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../cms/common/utils/utils';
import { isTextDisplayData } from '../../cms/data/types';
import { useSearchItems } from '../../cms/hooks/useSearchItems';
import { ACTIVITY_TYPE_CMS_DEFINITION_ID, ACTIVITY_TYPE_COLOR_PROPERTY_ID, ACTIVITY_TYPE_ICON_PROPERTY_ID, ACTIVITY_TYPE_PRESENT_IN_QUICK_MENU_PROPERTY_ID } from '../../crm/constants';
import { getI18nState } from '../reducers/rootSelector';
export const useLoadActivityTypes = ({ onlyPresentInQuickMenu } = {}) => {
    const languageCode = useSelector((s) => getCurrentLanguage(getI18nState(s)));
    const { displayData } = useEntityDisplayData(ACTIVITY_TYPE_CMS_DEFINITION_ID);
    const titleDisplayData = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const additionalTitle = useMemo(() => isTextDisplayData(titleDisplayData) && titleDisplayData.meta.translations?.[languageCode], [languageCode, titleDisplayData]);
    const presentInQuickMenuQuery = {
        condition: ConditionType.and,
        filters: [
            {
                field: ACTIVITY_TYPE_PRESENT_IN_QUICK_MENU_PROPERTY_ID,
                operator: FilterOperatorTypes.equals,
                data: true
            }
        ]
    };
    const { data, isPending } = useSearchItems({
        definitionId: ACTIVITY_TYPE_CMS_DEFINITION_ID,
        filterRequest: {
            ...(onlyPresentInQuickMenu ? { query: presentInQuickMenuQuery } : {}),
            languageCode,
            responseFields: [
                TITLE_PROPERTY_ID,
                ACTIVITY_TYPE_COLOR_PROPERTY_ID,
                ACTIVITY_TYPE_ICON_PROPERTY_ID,
                ...((additionalTitle && [additionalTitle]) || [])
            ]
        }
    });
    const activityTypes = useMemo(() => additionalTitle && data?.results
        ? data?.results.map((el) => ({
            ...el,
            title: el[additionalTitle] || el.title
        }))
        : data?.results || [], [additionalTitle, data]);
    return {
        isPending,
        activityTypes
    };
};
