import { isObject } from 'lodash';
import { getAceInstance } from 'react-ace/lib/editorOptions';
import { getFlattenedDisplayDataList } from '../../../cms';
import { PropertyTypes } from '../../types';
export function getAce() {
    return getAceInstance();
}
export function getAutocompleteProperties(propertiesTree, path) {
    if (propertiesTree) {
        const variants = Object.entries(propertiesTree).reduce((acc, [key, value]) => {
            const property = [path, key].filter((item) => item).join('.');
            if (!isObject(value)) {
                acc.push({ meta: 'property', caption: property, value: property, score: 1 });
            }
            else {
                acc.push({ meta: 'property type entity', caption: property, value: property, score: 1 });
            }
            return acc;
        }, []);
        return variants;
    }
    return [];
}
export function getPropertiesTree(displayData, displayDataMap, maxLevel = 2, level = 1) {
    const displayDataList = getFlattenedDisplayDataList(displayData);
    const result = displayDataList.reduce((acc, item) => {
        if (item.type === PropertyTypes.entity && maxLevel > level && !item.meta.nonExpandable) {
            if (displayDataMap[item.meta.definitionId]) {
                acc[item.propertyId] = getPropertiesTree(displayDataMap[item.meta.definitionId], displayDataMap, maxLevel, level + 1);
            }
            else {
                acc[item.propertyId] = true;
            }
        }
        else {
            acc[item.propertyId] = true;
        }
        return acc;
    }, {});
    return result;
}
