import React, { useCallback, useState } from 'react';
import FileLightBox from '../../storage/components/FileLightBox';
import { PreviewTypesT } from './types';
import SortableFileWrapper, { SortableFileListContainer } from '../SortableFileWrapper';
import { DELAY_CAPTURE } from '../SortableFileWrapper/constants';
import AvatarPreview from './previews/AvatarPreview';
import BigAvatarPreview from './previews/BigAvatarPreview';
import CarouselPreview from './previews/CarouselPreview';
const PREVIEW_COMPONENTS = {
    [PreviewTypesT.CAROUSEL]: CarouselPreview,
    [PreviewTypesT.AVATAR]: AvatarPreview,
    [PreviewTypesT.BIG_AVATAR]: BigAvatarPreview
};
const InlineImageGallery = ({ images, previewType = PreviewTypesT.AVATAR, imageCover, propertyId, definitionId, entityId, onSortEnd, sortableRef }) => {
    const [lightboxData, setLightboxData] = useState({ opened: false, index: 0 });
    const PreviewComponent = PREVIEW_COMPONENTS[previewType];
    const onImageClick = useCallback((index) => {
        setLightboxData({ opened: true, index });
    }, [setLightboxData]);
    const onLightboxClose = useCallback(() => {
        setLightboxData({ opened: false, index: 0 });
    }, [setLightboxData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SortableFileWrapper, { className: "h-100 position-relative", definitionId: definitionId, entityId: entityId, propertyId: propertyId },
            React.createElement(SortableFileListContainer, { axis: 'x', transitionDuration: 0, pressDelay: DELAY_CAPTURE, ref: sortableRef, onSortEnd: onSortEnd },
                React.createElement(PreviewComponent, { images: images, onImageClick: onImageClick, imageCover: imageCover }))),
        lightboxData.opened && (React.createElement(FileLightBox, { files: images, previewIndex: lightboxData.index, closeImagePreview: onLightboxClose }))));
};
export default InlineImageGallery;
