import { isEmpty } from 'lodash';
import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import ActionsButton from '../../../common/components/ActionsButton';
import { SYSTEM_GROUPS } from '../../constants';
import { useGroups } from '../../hooks/useGroups';
import styles from './Hierarchy.module.scss';
export const GroupActionsButton = ({ canCreateAndDeleteGroup, canUpdateGroupMembers, handleDelete, saving, toggleMembersUpdateDrawer, treeItem }) => {
    const { groups } = useGroups();
    const group = groups.find((group) => group.entityId === treeItem.key);
    const isSystemGroup = SYSTEM_GROUPS.includes(treeItem.key);
    return (React.createElement(ActionsButton, { className: styles.container__groupActionsButton, menuActions: [
            {
                action: () => toggleMembersUpdateDrawer(group, 'add'),
                canRun: canUpdateGroupMembers,
                buttonLabel: 'GROUP__ADD_MEMBERS',
                domEventHandler: (event) => {
                    event.stopPropagation();
                }
            },
            {
                action: () => toggleMembersUpdateDrawer(group, 'remove'),
                canRun: canUpdateGroupMembers,
                buttonLabel: 'GROUP__REMOVE_MEMBERS',
                domEventHandler: (event) => {
                    event.stopPropagation();
                }
            },
            {
                action: () => handleDelete(treeItem.key),
                domEventHandler: (event) => {
                    event.stopPropagation();
                },
                canRun: canCreateAndDeleteGroup,
                disabled: !isEmpty(treeItem.children) || isSystemGroup,
                disabledReasonLabel: isSystemGroup
                    ? 'GROUP__CAN_NOT_DELETE_SYSTEM_GROUP'
                    : 'GROUP__CAN_NOT_DELETE_PARENT_GROUP',
                buttonLabel: 'GROUP__DELETE',
                confirmationLabel: 'GROUP__DELETE',
                delete: true
            }
        ], disabled: saving, inversed: true, menuIcon: React.createElement(FaEllipsisV, null) }));
};
