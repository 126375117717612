import { omit } from 'lodash';
import { CREDENTIALS_API_PATH } from './constants';
export async function createCredential(dispatch, credential) {
    const response = await dispatch({
        type: 'HP3N_NODE_CREATE_CREDENTIAL',
        payload: credential,
        meta: {
            http: {
                path: `${CREDENTIALS_API_PATH}`,
                method: 'POST',
                allowPromiseReject: true
            }
        }
    });
    return response;
}
export async function getCredential(dispatch, entityId) {
    const response = await dispatch({
        type: 'HP3N_NODE_GET_CREDENTIAL',
        meta: {
            http: {
                path: `${CREDENTIALS_API_PATH}/${entityId}`,
                method: 'GET',
                allowPromiseReject: true
            }
        }
    });
    return response;
}
export async function updateCredential(dispatch, credential) {
    const response = await dispatch({
        type: 'HP3N_NODE_UPDATE_CREDENTIAL',
        payload: omit(credential, 'entityId'),
        meta: {
            http: {
                path: `${CREDENTIALS_API_PATH}/${credential.entityId}/`,
                method: 'PUT',
                allowPromiseReject: true
            }
        }
    });
    return response;
}
