import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { INITIAL_PAGE_SIZE } from 'config';
import { map } from 'lodash';
import { combineReducers } from 'redux';
const initialListState = {
    pending: false,
    failed: false,
    lastFetch: null,
    idsByPage: {},
    pageSize: INITIAL_PAGE_SIZE,
    totalCount: 0
};
// ////////////////////
// ItemMeta
// ////////////////////
const entitiesList = createReducer(initialListState, {
    ENTITY_META__FETCH_LIST: (s, a) => {
        const { pageSize } = a.meta.http.actionContext;
        if (s.pageSize !== pageSize) {
            s.idsByPage = {};
            s.totalCount = 0;
        }
        s.pending = true;
        s.failed = false;
        s.pageSize = pageSize;
    },
    ENTITY_META__FETCH_LIST_FAIL: (s, a) => {
        s.pending = false;
        s.failed = true;
    },
    ENTITY_META__FETCH_LIST_SUCCESS: (s, a) => {
        const { page } = a.meta.actionContext;
        const { results, totalCount } = a.payload;
        s.idsByPage[page.toString()] = map(results, 'definitionId');
        s.totalCount = totalCount;
        s.pending = false;
        s.failed = false;
        s.lastFetch = new Date();
    },
    ENTITY_META__DELETE_SUCCESS: (s, a) => {
        s.idsByPage = {};
        s.totalCount--;
    },
    ENTITY_META__CREATE_SUCCESS: (s, a) => {
        s.idsByPage = {};
        s.totalCount++;
    }
});
const entitiesById = createReducer({}, {
    ENTITY_META__FETCH_LIST_SUCCESS: (s, a) => {
        a.payload.results.forEach((entity) => {
            s[entity.definitionId] = entity;
        });
    },
    ENTITY_META__FETCH_SINGLE_SUCCESS: (s, a) => {
        s[a.payload.definitionId] = a.payload;
    },
    ENTITY_META__DELETE_SUCCESS: (s, a) => {
        delete s[a.meta.actionContext.definitionId];
    },
    ENTITY_META__UPDATE_SUCCESS: (s, a) => {
        s[a.payload.definitionId] = a.payload;
    },
    ENTITY_META__CREATE_SUCCESS: (s, a) => {
        s[a.payload.definitionId] = a.payload;
    }
});
const entityStatus = createReducer({}, {
    ENTITY_META__FETCH_SINGLE: (s, a) => {
        const { definitionId } = a.meta.http.actionContext;
        const status = s[definitionId] || {};
        status.pending = true;
        status.failed = false;
        s[definitionId] = status;
    },
    ENTITY_META__FETCH_SINGLE_FAIL: (s, a) => {
        const { definitionId } = a.meta.actionContext;
        const status = s[definitionId] || {};
        status.pending = false;
        status.failed = true;
        s[definitionId] = status;
    },
    ENTITY_META__FETCH_SINGLE_SUCCESS: (s, a) => {
        const status = s[a.payload.definitionId] || {};
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[a.payload.definitionId] = status;
    },
    ENTITY_META__DELETE_SUCCESS: (s, a) => {
        delete s[a.meta.actionContext.definitionId];
    }
});
const enititiesMetaReducer = combineReducers({
    list: entitiesList,
    byId: entitiesById,
    status: entityStatus
});
const definitionsById = createReducer({}, {
    ENTITY_META_DEFINITION__FETCH_SUCCESS: (s, a) => {
        s[a.meta.actionContext.metaDefinitionId] = a.payload;
    }
});
const definitionsStatus = createReducer({}, {
    ENTITY_META_DEFINITION__FETCH: (s, a) => {
        const { metaDefinitionId } = a.meta.http.actionContext;
        const status = s[metaDefinitionId] || {};
        status.pending = true;
        status.failed = false;
        s[metaDefinitionId] = status;
    },
    ENTITY_META_DEFINITION__FETCH_FAIL: (s, a) => {
        const { metaDefinitionId } = a.meta.actionContext;
        const status = s[metaDefinitionId] || {};
        status.pending = false;
        status.failed = true;
        s[metaDefinitionId] = status;
    },
    ENTITY_META_DEFINITION__FETCH_SUCCESS: (s, a) => {
        const status = s[a.meta.actionContext.metaDefinitionId] || {};
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[a.meta.actionContext.metaDefinitionId] = status;
    }
});
const definitionsReducer = combineReducers({
    byId: definitionsById,
    status: definitionsStatus
});
export default combineReducers({
    entities: enititiesMetaReducer,
    definitions: definitionsReducer
});
