import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { deleteProperty, didFetchPropertiesFail, fetchProperties, getIsFetchPropertiesPending, getPropertiesByDefinitionId } from '@hypercharge/hyper-react-base/lib/metadata';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getMetadataState } from '../../../common/reducers/rootSelector';
import PropertiesList from './PropertiesList';
class PropertiesListContainer extends Component {
    componentDidMount() {
        if (this.props.properties.length === 0) {
            this.props.fetchAllProperties();
        }
    }
    render() {
        return React.createElement(PropertiesList, { ...this.props });
    }
}
function mapStateToProps(state, { match }) {
    const metadataState = getMetadataState(state);
    const definitionId = match.params.definitionId;
    return {
        definitionId,
        properties: getPropertiesByDefinitionId(metadataState, definitionId),
        isPending: getIsFetchPropertiesPending(metadataState, definitionId),
        failed: didFetchPropertiesFail(metadataState, definitionId)
    };
}
function mapDispatchToProps(dispatch, { definitionId }) {
    return {
        fetchAllProperties: () => {
            dispatch(fetchProperties(definitionId));
        },
        deleteProperty: (id) => {
            return dispatch(deleteProperty(definitionId, id));
        }
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(withProps(() => {
    const { t } = useI18n();
    return { t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(PropertiesListContainer);
