import Indicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import React from 'react';
import styled from 'styled-components';
// Use this inside a component that has position: relative
const Container = styled.div `
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(var(--grayscaleColorRGB-1), 0.8);
  transition: all 0.3s ease;
  z-index: 2;
`;
const Wrapper = styled.div `
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 35px);
`;
const LoadingIndicator = () => (React.createElement(Container, null,
    React.createElement(Wrapper, null,
        React.createElement(Indicator, null))));
export default LoadingIndicator;
