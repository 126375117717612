import React from 'react';
import styles from './Metric.module.scss';
const MetricChart = ({ data, onClick }) => {
    return (React.createElement("div", { className: `${styles.wrapper} d-flex flex-row flex-wrap align-items-center justify-content-center w-100 h-100` }, data.map((item) => {
        return (React.createElement("div", { key: item.id, className: "metric-item p-3 text-center", onClick: () => {
                onClick && onClick(item);
            } },
            React.createElement("div", { className: "value" }, item.value)));
    })));
};
export default MetricChart;
