import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { WORKFLOWS_API_PATH } from '../../../../common/utils/constants';
import { ACTIVITY_NODES } from './nodes';
export const fetchNodes = () => ({
    type: 'HP3N_NODE_PARAMETER_OPTIONS_FETCH',
    meta: {
        http: {
            path: `${WORKFLOWS_API_PATH}/nodes`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export function useNodes() {
    const httpDispatch = useDispatch();
    return useQuery(['nodes'], async () => {
        const response = await httpDispatch(fetchNodes());
        return {
            nodes: {
                ...ACTIVITY_NODES.reduce((acc, item) => {
                    acc[item.type] = item;
                    return acc;
                }, {}),
                ...response.result.nodes
            },
            credentials: response.result.credentials
        };
    }, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false
    });
}
