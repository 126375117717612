import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useMemo } from 'react';
import { Field, FormSection } from 'redux-form';
import { AntInput } from '../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { SelectField } from '../../../../../common/components/Select';
import Toggle from '../../../../../common/components/Toggle';
import { FieldsContainerTitle } from '../../../../../common/utils/formUtils';
const STATUSES = [
    'Rendering Failure',
    'Send',
    'Delivery',
    'Open',
    'Click',
    'Bounce',
    'Complaint',
    'Reject',
    'DeliveryDelay'
];
const BOUNCE_TYPES = [
    {
        value: 'Permanent',
        labelTranslationKey: 'TASK_EMAIL_FEEDBACK_TRIGGER_BOUNCE_TYPES_HARD'
    },
    {
        value: 'Transient',
        labelTranslationKey: 'TASK_EMAIL_FEEDBACK_TRIGGER_BOUNCE_TYPES_SOFT'
    },
    {
        value: 'Undetermined',
        labelTranslationKey: 'TASK_EMAIL_FEEDBACK_TRIGGER_BOUNCE_TYPES_UNDERTERMINED'
    }
];
const EmailFeedbackTriggerForm = ({ node, disabled }) => {
    const { t } = useI18n();
    const showBounceTypes = Array.isArray(node.parameters.statuses) && node.parameters.statuses.includes('Bounce');
    const bounceTypesOptions = useMemo(() => {
        return BOUNCE_TYPES.map(({ value, labelTranslationKey }) => ({
            value,
            label: t(labelTranslationKey)
        }));
    }, [t]);
    const statusesOptions = useMemo(() => {
        return STATUSES.map((status) => ({
            value: status,
            label: status
        }));
    }, []);
    return (React.createElement("div", null,
        React.createElement(FieldsContainerTitle, { small: true, className: "mt-0" }, t('WORKFLOWS_SETTINGS_TITLE')),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: disabled, placeholder: t('COMMON__TITLE') }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldsContainerTitle, { small: true }, t('ACTIVE')),
            React.createElement(Field, { name: "active", component: Toggle, disabled: disabled }),
            React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
            React.createElement(FieldsContainerTitle, { small: true }, t('TASK_EMAIL_FEEDBACK_TRIGGER_STATUSES')),
            React.createElement(Field, { name: "statuses", mode: "multiple", component: SelectField, disabled: disabled, validate: required, options: statusesOptions }),
            showBounceTypes && (React.createElement(React.Fragment, null,
                React.createElement(FieldsContainerTitle, { small: true }, t('TASK_EMAIL_FEEDBACK_TRIGGER_BOUNCE_TYPES')),
                React.createElement(Field, { name: "bounceTypes", mode: "multiple", options: bounceTypesOptions, component: SelectField, disabled: disabled }))))));
};
export default EmailFeedbackTriggerForm;
