import axios from 'axios';
import { isError, isObject } from 'lodash';
export function createRequest(requestConfig) {
    const cancel = axios.CancelToken.source();
    const axiosConfig = {
        ...requestConfig,
        cancelToken: cancel.token
    };
    const response = axios(axiosConfig).then((response) => {
        return response.data;
    });
    return {
        response,
        abort: () => cancel.cancel()
    };
}
export function isAxiosError(error) {
    if (isError(error) && 'isAxiosError' in error && error.isAxiosError) {
        return true;
    }
    return false;
}
export function isCustomError(error) {
    if (error &&
        isObject(error) &&
        'code' in error &&
        typeof error.code === 'string' &&
        'message' in error &&
        typeof error.message === 'string') {
        return true;
    }
    return false;
}
export function getErrorMessage(err, t) {
    let message;
    if (isAxiosError(err) && err.response && isCustomError(err.response.data)) {
        const errorKey = `ERROR__${err.response.data.code}`;
        message = t(errorKey);
        if (!message || message === errorKey) {
            console.warn(`No translation for key "${errorKey}"`);
            message = err.response.data.message;
        }
    }
    else if (isError(err)) {
        message = err.message;
    }
    return message || 'Unknown error';
}
