import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CMS_PATH, FEATURED_ENTITIES_PATH } from '../../../../../cms';
import { GROUPS_PATH, GROUP_CMS_DEFINITION_ID } from '../../../../../groups';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID, FEATURED_PROCESSES_PATH, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import { BROWSE_PATH, VIEW_PATH } from '../../../../utils/constants';
const FormEntityAdapter = ({ children }) => (React.createElement(Switch, null,
    React.createElement(Route, { path: [
            `${CMS_PATH}/:definitionId${BROWSE_PATH}/:entityId`,
            `${CMS_PATH}/:definitionId${VIEW_PATH}/:viewId/:entityId`,
            `${CMS_PATH}/:definitionId`,
            `${FEATURED_ENTITIES_PATH}/:definitionId${BROWSE_PATH}/:entityId`,
            `${FEATURED_ENTITIES_PATH}/:definitionId${VIEW_PATH}/:viewId/:entityId`,
            `${FEATURED_ENTITIES_PATH}/:definitionId`,
            `${FEATURED_PROCESSES_PATH}/:definitionId${BROWSE_PATH}/:entityId`,
            `${FEATURED_PROCESSES_PATH}/:definitionId`,
            `${FEATURED_PROCESSES_PATH}/:definitionId${VIEW_PATH}/:viewId/:entityId`,
            `${WORKFLOWS_PATH}${PROCESSES_PATH}/:definitionId${BROWSE_PATH}/:entityId`,
            `${WORKFLOWS_PATH}${PROCESSES_PATH}/:definitionId${VIEW_PATH}/:viewId/:entityId`,
            `${WORKFLOWS_PATH}${PROCESSES_PATH}/:definitionId`
        ], render: (routerProps) => {
            let entityId = routerProps.match.params.entityId ?? '';
            let definitionId = routerProps.match.params.definitionId ?? '';
            if ([ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(definitionId)) {
                definitionId = '';
                entityId = '';
            }
            return React.cloneElement(children, { entityId, definitionId });
        } }),
    React.createElement(Route, { exact: true, path: [`${GROUPS_PATH}/:entityId`, `${GROUPS_PATH}`], render: (routerProps) => {
            const entityId = routerProps.match.params.entityId ?? '';
            return React.cloneElement(children, { entityId, definitionId: GROUP_CMS_DEFINITION_ID });
        } }),
    React.createElement(Route, null, children)));
export default FormEntityAdapter;
