import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { isString, toNumber } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { isComputedExpression } from '../../../utils/computedExpression';
import { ComputedInput } from '../ComputedExpressionEditor';
import DateField from '../DateField/DateField';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty, label: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty, label: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.equals, label: FilterOperatorTypes.is },
    { value: FilterOperatorTypes.notEquals, label: FilterOperatorTypes.isNot },
    { value: FilterOperatorTypes.greaterThan, label: 'IS_AFTER' },
    { value: FilterOperatorTypes.lessThan, label: 'IS_BEFORE' },
    { value: FilterOperatorTypes.greaterThanOrEqual, label: 'IS_ON_OR_AFTER' },
    { value: FilterOperatorTypes.lessThanEqual, label: 'IS_ON_OR_BEFORE' }
];
const DateFilter = ({ className, filter, meta, onChange, onClear, showSelectOperator, disabledSelectOperator, disabled, allowComputed, propertiesTree, autoFocus }) => {
    const withTime = 'withTime' in meta ? !!meta.withTime : false;
    const data = useMemo(() => {
        if ('data' in filter) {
            if ((isString(filter.data) && isComputedExpression(filter.data)) ||
                typeof filter.data === 'number') {
                return filter.data;
            }
        }
        return;
    }, [filter]);
    const onChangeInput = useCallback((value) => {
        let newValue = value;
        if (!value) {
            onClear?.(filter);
            return;
        }
        if (isString(value)) {
            if (isComputedExpression(value)) {
                newValue = value;
            }
            else {
                newValue = Date.now();
            }
        }
        const newFilter = buildFilter(filter.field, filter.operator, newValue);
        onChange(newFilter);
    }, [filter, onChange, onClear]);
    return (React.createElement(BaseFilter, { onChange: onChange, operators: availableOperators, filter: filter, showSelectOperator: showSelectOperator, disabledSelectOperator: disabledSelectOperator, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { className: "mt-0", component: DateField, withTime: withTime, value: data, onChange: onChangeInput, disabled: disabled, propertiesTree: propertiesTree, autoFocus: autoFocus })) : (React.createElement(DateField, { allowClear: !!onClear, withTime: withTime, value: toNumber(data) || undefined, onChange: onChangeInput, disabled: disabled, className: "mt-0", autoFocus: autoFocus }))));
};
export default DateFilter;
