import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { ENTITY_ID_PROPERTY_ID, UPDATED_AT_ID_PROPERTY_ID } from '../../../../../cms';
import Toggle from '../../../../../common/components/Toggle';
import { PropertyTypes } from '../../../../../common/types';
import NumberValidationFields from './fields/NumberValidationFields';
import TextValidationFields from './fields/TextValidationFields';
export const isVisible = (propertyId) => {
    return propertyId !== ENTITY_ID_PROPERTY_ID && propertyId !== UPDATED_AT_ID_PROPERTY_ID;
};
const ValidationsTab = ({ value, onChange, disabled }) => {
    const { t } = useI18n();
    const onChangeRequired = useCallback((checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                validations: {
                    ...value.meta.validations,
                    required: checked
                }
            }
        });
    }, [onChange, value]);
    if (value.propertyId === ENTITY_ID_PROPERTY_ID) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        value.type === PropertyTypes.text ? (React.createElement(TextValidationFields, { value: value, onChange: onChange, disabled: disabled })) : null,
        value.type === PropertyTypes.number ? (React.createElement(NumberValidationFields, { value: value, onChange: onChange, disabled: disabled })) : null,
        React.createElement(Toggle, { className: "w-100 py-2", label: t('PROPERTY_REQUIRED'), disabled: disabled, onChange: onChangeRequired, checked: value.meta.validations?.required })));
};
export default ValidationsTab;
