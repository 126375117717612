import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { EditableItemTitleContainer, EditableItemTitleStyles } from '../../../../../cms';
import { invalidateItemsQuery } from '../../../../../cms/hooks/invalidateCache';
import { HeightContextProvider } from '../../../../../common/components/height-context';
import LoadingIndicator from '../../../../../common/components/loading-indicator';
import SessionSubscription, { useSessionSubscriptionCallback } from '../../../../../common/session/components/SessionSubscription';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../../crm';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { VIEW_DEFINITION_ID, ViewProvider, useViews } from '../../../../../views';
import StartProcessButton from '../../../../common/components/start-process-button';
import { ALL_TASKS_DEFINITION_ID, FEATURED_PROCESSES_PATH, WORKFLOWS_PATH, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../../../../common/utils/constants';
import { getWorkflowsTaskFilterTypeConfig, mapPropertyWorkflowsTaskDashboardGroupBy, uniqViewIdWorkflowsTaskDashboard } from '../../../constants';
import { getCustomWorkflowsKanbanColumnsFilters, getDefaultFilterQueryWorkflowsTaskDashboardBase, getNewFilterForWorkflowsTaskDashboard, getPermissionsUpdateFilters, getWorkflowTaskDashboardDesignFields, getWorkflowsTaskDashboardView } from '../../../utils';
import { TaskDashboardContent } from '../TaskDashboardContent';
import { TaskDashboardProvider } from '../TaskDashboardContext/TaskDashboardProvider';
import { WorkflowsTasksTableList } from '../TasksTableList';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const WorkflowsTaskDashboardPage = () => {
    const { t } = useI18n();
    const { contactGroups, contactId } = useMyContact();
    const { viewId } = useParams();
    const { views } = useViews();
    const view = useMemo(() => getWorkflowsTaskDashboardView(views, viewId), [viewId, views]);
    const getDefaultFilterQueryWorkflowsTaskDashboard = useMemo(() => getDefaultFilterQueryWorkflowsTaskDashboardBase(contactGroups), [contactGroups]);
    const changedDataCallback = useCallback(() => {
        void invalidateItemsQuery({ definitionId: ALL_TASKS_DEFINITION_ID, waitNewData: true });
    }, []);
    useSessionSubscriptionCallback({
        messageKey: 'UPDATED_GROUP_TASKS',
        callback: changedDataCallback
    });
    return (React.createElement(React.Fragment, null,
        contactId &&
            contactGroups.map((group) => (React.createElement(SessionSubscription, { key: group, subscriptionKey: `tasks--group--${group}` }))),
        React.createElement("div", { id: "breadcrumbs" },
            React.createElement(Breadcrumbs, { links: [
                    {
                        text: t('YOUR_DASHBOARD_NAV_LINK'),
                        to: `${WORKFLOWS_PATH}`
                    },
                    ...((viewId && [
                        {
                            text: t('YOUR_DASHBOARD_NAV_LINK'),
                            to: `${WORKFLOWS_PATH}${WORKFLOWS_YOUR_DASHBOARD_PATH}`
                        }
                    ]) ||
                        [])
                ], currentBreadcrumbText: (!viewId && t('WORKFLOWS_NAV_LINK')) || undefined, currentBreadcrumbComponent: (viewId && (React.createElement(EditableItemTitleContainer, { className: `${EditableItemTitleStyles.breadcrumbsViewTitle} flex-truncate`, definitionId: VIEW_DEFINITION_ID, entityId: viewId }))) ||
                    undefined })),
        view ? (React.createElement(ViewProvider, { enableGrouping: false, additionalFiltersForSearch: getPermissionsUpdateFilters(contactGroups), getCustomKanbanColumnsFilters: getCustomWorkflowsKanbanColumnsFilters, definitionId: ALL_TASKS_DEFINITION_ID, context: {
                definitionId: CONTACT_CMS_DEFINITION_ID,
                contactId
            }, view: view, getDesignFields: getWorkflowTaskDashboardDesignFields },
            React.createElement(TaskDashboardProvider, { getTaskFilterTypeConfig: getWorkflowsTaskFilterTypeConfig, mapProperty: mapPropertyWorkflowsTaskDashboardGroupBy, getDefaultFilterQueryTaskDashboard: getDefaultFilterQueryWorkflowsTaskDashboard, getNewFilterForTaskDashboard: getNewFilterForWorkflowsTaskDashboard },
                React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: 0 },
                    React.createElement(TaskDashboardContent, { addEmptyAssignedOption: true, viewActionsBaseUrl: `${WORKFLOWS_PATH}${WORKFLOWS_YOUR_DASHBOARD_PATH}`, kanbanBaseUrl: `${FEATURED_PROCESSES_PATH}/${ALL_TASKS_DEFINITION_ID}`, availableOn: uniqViewIdWorkflowsTaskDashboard, headerRightBlock: React.createElement(StartProcessButton, null), componentTasksTableList: WorkflowsTasksTableList, relatedToTitleKey: 'PROCESS_NAME' }))))) : (React.createElement(LoadingIndicator, null))));
};
export default WorkflowsTaskDashboardPage;
