import React from 'react';
import styled from 'styled-components';
import { Wrapper } from '../../styling';
import { ErrorBoundary } from '../error-boundary';
import LoadingRectangles from '../loading-rectangles';
const Container = styled(Wrapper) `
  background: #fff;
  box-shadow: ${({ theme }) => theme.cardBoxShadow};
  border-radius: ${({ theme, withoutRounding }) => withoutRounding ? '0' : theme.cardBorderRadius};
  margin-bottom: ${({ withoutMargin }) => (withoutMargin ? '0' : '2rem')};

  @media print {
    box-shadow: none;
    margin-bottom: 0;
  }
`;
const TitleContainer = styled.div `
  padding: 1rem;
  border-bottom: 1px solid #dcdcdc;
  color: #666;
`;
const Content = styled('div').withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'withoutPadding' && prop !== 'loading' && defaultValidatorFn(prop)
}) `
  padding: ${({ withoutPadding }) => (withoutPadding ? '0' : '1rem')};
  font-size: 1.05rem;
  position: relative;
  ${({ loading }) => (loading ? 'min-height: 5rem;' : '')};
`;
const Overlay = styled.div `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SectionCard = ({ className, children, title, headerStyle = {}, contentStyle = {}, secondaryTitle, withoutPadding, withoutMargin, withoutRounding, loading = false, ...otherProps }) => (React.createElement(ErrorBoundary, null,
    React.createElement(Container, { className: className, withoutRounding: !!withoutRounding, withoutMargin: !!withoutMargin, ...otherProps },
        (title || secondaryTitle) && (React.createElement(TitleContainer, { className: "cp-c-row cp-c-align-start-center", style: headerStyle },
            React.createElement("div", { className: "cp-i-flex cp-c-row cp-c-align-start-center" }, title),
            secondaryTitle && (React.createElement("div", { className: "cp-i-none cp-c-row cp-c-align-end-center" }, secondaryTitle)))),
        React.createElement(Content, { className: "content", withoutPadding: !!withoutPadding, loading: loading, style: contentStyle },
            children,
            loading && (React.createElement(Overlay, null,
                React.createElement(LoadingRectangles, null)))))));
export default {
    SectionCard
};
