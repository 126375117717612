import { CONTACT_GROUPS_PROPERTY_ID, TITLE_PROPERTY_ID } from '../cms/common/utils/constants';
export const CONTACT_CMS_DEFINITION_ID = 'contact';
export const CONTACT_FIRST_NAME_PROPERTY_ID = 'firstName';
export const CONTACT_LAST_NAME_PROPERTY_ID = 'lastName';
export const CONTACT_EMAIL_WORK_PROPERTY_ID = 'emailWork';
export const ORGANIZATION_CMS_DEFINITION_ID = 'organization';
export const VEHICLE_CMS_DEFINITION_ID = 'vehicle';
export const DOMAIN_CMS_DEFINITION_ID = 'domain';
export const ACTIVITY_TYPE_CMS_DEFINITION_ID = 'activityType';
export const ACTIVITY_TYPE_COLOR_PROPERTY_ID = 'color';
export const ACTIVITY_TYPE_ICON_PROPERTY_ID = 'icon';
export const ACTIVITY_TYPE_PRESENT_IN_QUICK_MENU_PROPERTY_ID = 'presentInQuickMenu';
export const FETCH_CONTACT_RESPONSE_FIELDS = [
    TITLE_PROPERTY_ID,
    CONTACT_FIRST_NAME_PROPERTY_ID,
    CONTACT_LAST_NAME_PROPERTY_ID,
    CONTACT_GROUPS_PROPERTY_ID,
    CONTACT_EMAIL_WORK_PROPERTY_ID
];
export const SYSTEM_USER = 'system';
export const SYSTEM_USERS = [
    'system',
    'scheduled-import',
    'workflows-set-entity-values-node',
    'workflows-cancel-process-node',
    'workflows-get-entity-values-node',
    'workflows-create-entity-node',
    'workflows-generate-form-link-node',
    'workflows-set-process-values-node',
    'workflows-generate-document-node',
    'workflows-webhook-node',
    'workflows-milestone-node',
    'workflows-step-node',
    'forms',
    'daf-data-lake',
    'hyper-dealer-import',
    'hyper-dealer-import-fastback',
    'hyper-dealer-import-cronos',
    'hyper-dealer-import-stefi',
    'hyper-dealer-import-ireach',
    'hyper-dealer-import-apollo',
    'hyper-dealer-import-visuel3d'
];
