import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getItemsByDefinitionId } from '../../../cms';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { SYSTEM_USER } from '../../../crm/constants';
import { VIEW_DEFINITION_ID } from '../../../views';
import { useView } from '../../../views/components/ViewContext';
import { ViewTypes } from '../../../views/types';
import { BROWSE_PATH, VIEW_PATH } from '../../utils/constants';
import { getEntityBrowseUrl, getEntityViewUrl } from '../../utils/url';
import { Select } from '../Select';
import styles from './ViewSelector.module.scss';
var ViewGroups;
(function (ViewGroups) {
    ViewGroups["owner"] = "owner";
    ViewGroups["system"] = "system";
    ViewGroups["shared"] = "shared";
})(ViewGroups || (ViewGroups = {}));
const ViewSelector = ({ baseUrl, className, availableOn, suffixIcon }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { definitionId, view } = useView();
    const location = useLocation();
    const params = useParams();
    const { contactId } = useMyContact();
    const defaultViewId = `${definitionId || ''}__default`;
    const { views, viewsMap } = useSelector((s) => {
        let hasDefault = false;
        const viewsMap = {};
        getItemsByDefinitionId(s, VIEW_DEFINITION_ID).forEach((item) => {
            if (item.referenceDefinitionId === definitionId) {
                if (item.configuration?.availableOn?.length && !availableOn) {
                    return;
                }
                if (availableOn && !item.configuration?.availableOn?.includes(availableOn)) {
                    return;
                }
                if (item.isDefault) {
                    hasDefault = true;
                }
                viewsMap[item.entityId] = {
                    ...item,
                    viewType: item.viewType === ViewTypes.kanban && item.configuration?.kanban
                        ? ViewTypes.kanban
                        : ViewTypes.table
                };
            }
        });
        if (!hasDefault && definitionId) {
            viewsMap[defaultViewId] = {
                entityOwner: SYSTEM_USER,
                entityId: defaultViewId,
                title: t('DEFAULT_VIEW__COMMON'),
                isDefault: true,
                viewType: ViewTypes.table,
                referenceDefinitionId: definitionId
            };
        }
        const views = Object.values(viewsMap).sort((viewA) => (viewA.isDefault ? -1 : 1));
        return { views, viewsMap };
    });
    const options = useMemo(() => {
        const viewGroups = groupBy(views, (view) => {
            if (view.entityOwner == contactId) {
                return ViewGroups.owner;
            }
            else if (view.entityOwner == SYSTEM_USER) {
                return ViewGroups.system;
            }
            return ViewGroups.shared;
        });
        const groupTexts = {
            [ViewGroups.system]: t('VIEWS__GROUP_SYSTEM'),
            [ViewGroups.owner]: t('VIEWS__GROUP_ME'),
            [ViewGroups.shared]: t('VIEWS__GROUP_SHARED')
        };
        return Object.entries(viewGroups).map(([group, value]) => ({
            label: groupTexts[group],
            key: group,
            options: value.map(({ entityId, viewType, title, isDefault }) => ({
                value: entityId,
                key: entityId,
                label: (React.createElement(React.Fragment, null,
                    React.createElement(FontAwesomeIcon, { icon: viewType === ViewTypes.kanban ? 'columns' : 'bars', className: "me-1" }),
                    isDefault ? t('DEFAULT_VIEW__COMMON') : title))
            }))
        }));
    }, [contactId, t, views]);
    const handleChange = useCallback((viewId, search) => {
        const view = viewsMap[viewId];
        if (view && definitionId) {
            let viewUrl;
            if (baseUrl) {
                viewUrl = view.isDefault
                    ? `${baseUrl}${BROWSE_PATH}`
                    : `${baseUrl}${VIEW_PATH}/${viewId}`;
            }
            else {
                viewUrl = view.isDefault
                    ? getEntityBrowseUrl(definitionId)
                    : getEntityViewUrl(definitionId, view.entityId);
            }
            dispatch(push({ pathname: viewUrl, search }));
        }
    }, [viewsMap, definitionId, baseUrl, dispatch]);
    const onChange = useCallback((viewId) => {
        handleChange(viewId);
    }, [handleChange]);
    useEffect(() => {
        if (!view && params.viewId) {
            const found = views.find((view) => view.isDefault);
            handleChange(found?.entityId || defaultViewId, location.search);
        }
    }, [defaultViewId, handleChange, location.search, params.viewId, view, views]);
    return views.length > 1 ? (React.createElement(Select, { className: `${styles.outer} view-selector ${className || ''}`, value: view?.entityId || defaultViewId, onChange: onChange, popupMatchSelectWidth: false, popupClassName: "antd-dropdown-popup-styled2", size: "large", suffixIcon: suffixIcon, options: options })) : null;
};
export default ViewSelector;
