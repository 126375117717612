import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
const childFactoryCreator = (classNames) => (child) => React.cloneElement(child, { classNames });
export const SlideTransition = ({ depth, children }) => {
    const [direction, setDirection] = useState('right');
    const [prevDepth, setPrevDepth] = useState(depth);
    useEffect(() => {
        setDirection(depth > prevDepth ? 'right' : 'left');
        setPrevDepth(depth);
    }, [depth]);
    return (React.createElement("div", { className: "slider-outer-wrapper items-container" },
        React.createElement(TransitionGroup, { component: "div", className: "slider-transition-group", childFactory: childFactoryCreator(`slider-inner-wrapper slider-${direction}`) },
            React.createElement(CSSTransition, { key: depth, timeout: 300, classNames: `slider-inner-wrapper slider-${direction}` }, children))));
};
