import { INITIAL_PAGE } from 'config';
import React, { useCallback } from 'react';
import { useView } from '../../../../../../views/components/ViewContext';
import TablePagination from '../../../../table-pagination';
import styles from './GroupPagination.module.scss';
const GroupPagination = ({ setExpandedPagination, row }) => {
    const { searchItems: searchItemsData, page, pageSize, setIsAllItemsSelected, changePageSize, changePage } = useView();
    const handleChangePageSize = useCallback((pageSize) => {
        changePageSize(pageSize);
        setIsAllItemsSelected(false);
        setExpandedPagination((prevState) => {
            if (!row?.id) {
                return prevState;
            }
            return {
                ...prevState,
                [row.id]: {
                    limit: pageSize,
                    page: INITIAL_PAGE
                }
            };
        });
    }, [changePageSize, row?.id, setExpandedPagination, setIsAllItemsSelected]);
    const handleChangePage = useCallback((newPageIndex) => {
        changePage(newPageIndex);
        setIsAllItemsSelected(false);
        setExpandedPagination((prevState) => {
            if (!row?.id) {
                return prevState;
            }
            return {
                ...prevState,
                [row.id]: {
                    limit: pageSize,
                    page: newPageIndex
                }
            };
        });
    }, [changePage, pageSize, row?.id, setExpandedPagination, setIsAllItemsSelected]);
    return (React.createElement("div", { onClick: (event) => event.stopPropagation() },
        React.createElement(TablePagination, { className: `${styles.wrapper} px-3`, totalCount: searchItemsData.data?.totalCount || 0, pageSize: pageSize, onPageSizeChange: handleChangePageSize, page: page, onPageChange: handleChangePage })));
};
export default GroupPagination;
