import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { TreeSelect } from 'antd';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { getScrollableAreaOrBody } from '../../../../../common/utils/common';
import { ErrorLabel } from '../../../../../common/utils/formUtils';
import { dropdownStyle } from '../../../../../common/utils/stylingUtils';
import { useProperties } from '../../../../common/context/PropertiesProvider';
const { TreeNode } = TreeSelect;
const defaultIncludeProperty = () => true;
const SelectProperty = ({ className, value, onChange, disabled, label, placeholder, definitionId, includeProperty, includeNestedProperties = true, isFieldDisabled = () => false, expandAll = false, asFilter = false, clearOnMount = true, multiple = false, error, showArrow }) => {
    const { t } = useI18n();
    const { propertiesStatus, getPropertyOptions } = useProperties(definitionId);
    const onClear = useCallback(() => onChange?.(multiple ? [] : null), [onChange, multiple]);
    const propertyOptions = getPropertyOptions(includeProperty || defaultIncludeProperty, includeNestedProperties, asFilter);
    const filterUnavailableOptionValue = useCallback((optionValue) => {
        let isAvailable = false;
        if (Array.isArray(optionValue)) {
            const result = optionValue.filter((val) => {
                return !!propertyOptions.find(({ value }) => value === val);
            });
            return result;
        }
        else {
            propertyOptions.forEach((rootOption) => {
                if (rootOption.value === optionValue) {
                    isAvailable = true;
                }
                if (!isEmpty(rootOption.children)) {
                    rootOption.children?.forEach((childOption) => {
                        if (childOption.value === optionValue) {
                            isAvailable = true;
                        }
                    });
                }
            });
        }
        return isAvailable ? optionValue : null;
    }, [propertyOptions]);
    useEffect(() => {
        if (clearOnMount && !propertiesStatus.isFailed && !propertiesStatus.isPending && value) {
            // The field no longer exists or the user has insufficient permissions.
            // Since this is used in the settings, the user is assumed to have sufficient permissions so for simplicity we clear the field so that the user gets asked to fill in the field when he saves the form
            const filteredValue = filterUnavailableOptionValue(value);
            if (!isEqual(filteredValue, value)) {
                onChange?.(filteredValue);
            }
        }
    }, [
        propertiesStatus.isFailed,
        propertiesStatus.isPending,
        filterUnavailableOptionValue,
        clearOnMount,
        value,
        onChange
    ]);
    const getPropertyOptionsJsx = useCallback(({ value, label, disabled, title, children }) => {
        return (React.createElement(TreeNode, { key: value, value: value, title: label, label: title, disabled: isFieldDisabled(value) || disabled }, children && children.map(getPropertyOptionsJsx)));
    }, [isFieldDisabled]);
    const _onChange = useCallback((val) => {
        if (val && onChange) {
            onChange(val);
        }
    }, [onChange]);
    let message;
    if (propertiesStatus.isFailed) {
        message = t('PROPERTIES_FAILED_TO_LOAD');
    }
    else if (!propertiesStatus.isPending && isEmpty(propertyOptions)) {
        message = t('NO_SUPPORTED_PROPERTIES_AVAILABLE');
    }
    return (React.createElement("div", { className: className },
        label && React.createElement(LabelText, null, label),
        message ? (React.createElement("div", { className: "py-1" }, message)) : (React.createElement(TreeSelect, { className: "w-100", showSearch: true, treeDefaultExpandAll: expandAll, popupMatchSelectWidth: false, value: value || null, disabled: disabled || propertiesStatus.isPending, getPopupContainer: getScrollableAreaOrBody, dropdownStyle: dropdownStyle, placeholder: placeholder, allowClear: true, multiple: multiple, onClear: onClear, onChange: _onChange, filterTreeNode: filterTreeNode, suffixIcon: showArrow, status: error ? 'warning' : undefined }, propertyOptions.map(getPropertyOptionsJsx))),
        React.createElement(ErrorLabel, { show: !!error, position: 'relative', error: error })));
};
export default SelectProperty;
//
// Utilities
//
const filterTreeNode = (inputValue, treeNode) => {
    const isMatch = (text) => {
        if (text.toLowerCase()) {
            return text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }
        return false;
    };
    if (typeof treeNode.title === 'string') {
        return isMatch(treeNode.title);
    }
    else if (typeof treeNode.label === 'string') {
        return isMatch(treeNode.label);
    }
    else {
        return false;
    }
};
