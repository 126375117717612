import React, { Component } from 'react';
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error, info) {
        if (window.Sentry) {
            window.Sentry.captureException(error);
        }
        this.setState({ hasError: true });
    }
    render() {
        const { className, children } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return (React.createElement("div", { className: className },
                React.createElement("p", null, "We are sorry \u2014 something has gone wrong.")));
        }
        return children;
    }
}
export default {
    ErrorBoundary
};
