import memoize from 'fast-memoize';
import React from 'react';
import Notifications from 'react-notification-system-redux';
import styled, { useTheme } from 'styled-components';
import { ErrorBoundary } from '../../common/error-boundary';
export let notificationsPosition = 'tc';
const colors = {
    success: '#5cd1a5',
    error: '#ec3d3d',
    warning: '#ff9b20',
    info: '#4da0ff'
};
const NotificationWrapper = styled.div `
  > .notifications-wrapper > div {
    max-width: 100%;
    margin: 0 !important;

    > * {
      max-width: 100%;
    }

    .notification:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 40px;
    }

    .notification-success:before {
      background: ${colors.success};
    }

    .notification-error:before {
      background: ${colors.error};
    }

    .notification-warning:before {
      background: ${colors.warning};
    }

    .notification-info:before {
      background: ${colors.info};
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
    }
  }
`;
const getStyle = memoize((theme) => ({
    Containers: {
        tc: {
            top: '4rem',
            width: '400'
        },
        tr: {
            top: '3rem',
            width: '400'
        }
    },
    Title: {
        DefaultStyle: { fontWeight: '600' },
        success: { color: colors.success },
        error: { color: colors.error },
        warning: { color: colors.warning },
        info: { color: colors.info }
    },
    NotificationItem: {
        DefaultStyle: {
            background: '#fff',
            boxShadow: theme.cardBoxShadow,
            borderRadius: theme.cardBorderRadius,
            borderWidth: '0',
            width: '400px',
            overflow: 'hidden',
            paddingLeft: '50px'
        }
    },
    Dismiss: {
        DefaultStyle: {
            fontFamily: 'Arial',
            fontSize: '20px',
            position: 'absolute',
            top: '8px',
            right: '8px',
            lineHeight: '15px',
            color: '#ccc',
            backgroundColor: 'transparent',
            fontWeight: 'bold'
        }
    }
}));
const AppNotifications = ({ notifications, position }) => {
    const theme = useTheme();
    notificationsPosition = position;
    return (React.createElement(ErrorBoundary, null,
        React.createElement(NotificationWrapper, null,
            React.createElement(Notifications, { notifications: notifications, style: getStyle(theme), allowHTML: true }))));
};
export default AppNotifications;
