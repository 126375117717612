import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { BasicInput, BasicSelect, BasicTextArea, FormFooter, required, Section, SubTitle, Title } from '@hypercharge/hyper-react-base/lib/form';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { propertyFieldTypes } from '@hypercharge/hyper-react-base/lib/metadata';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import React from 'react';
import { Field, FormSection } from 'redux-form';
import styled from 'styled-components';
import { ADMIN_PATH } from '../../utils/constants';
import DependantDefinitionsField from './DependantDefinitionsField';
import DependantPropertyField from './DependantPropertyField';
import DependantPropertyFieldValues from './DependantPropertyFieldValues';
const InlineCheckbox = styled(BasicInput) `
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  input {
    width: auto;
    margin-right: 0.5rem;
  }
  label {
    padding: 0;
  }
`;
const FieldSet = styled.fieldset `
  margin: 1rem;
`;
const StyledForm = styled.form `
  padding: 0 0.5rem;
`;
const textFieldsJsx = () => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "minLength", label: t('PROPERTY_MIN_LENGTH'), component: BasicInput, type: "number" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "maxLength", label: t('PROPERTY_MAX_LENGTH'), component: BasicInput, type: "number" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "textPattern", label: t('PROPERTY_TEXT_PATTERN'), component: BasicInput, type: "text" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "multilanguage", id: "multilanguage", label: t('PROPERTY_MULTI_LANGUAGE'), component: InlineCheckbox, type: "checkbox" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "multiLine", id: "multiLine", label: t('PROPERTY_MULTI_LINE'), component: InlineCheckbox, type: "checkbox" }))));
const numberFieldsJsx = () => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "minValue", label: t('PROPERTY_MIN_VALUE'), component: BasicInput, type: "number" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "minValue", label: t('PROPERTY_MAX_VALUE'), component: BasicInput, type: "number" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "precision", label: t('PROPERTY_PRECISION'), component: BasicInput, type: "number" })),
    React.createElement(FieldSet, null,
        React.createElement("legend", null, t('PROPERTY_UNITS')),
        React.createElement("div", { className: "cp-c-padding-2" }, AVAILABLE_LANGUAGES.map((language) => (React.createElement("div", { key: language },
            React.createElement(Field, { name: `units[${language}]`, label: t(language), component: BasicInput, type: "text" }))))))));
const dateFieldsJsx = () => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "minDate", label: t('PROPERTY_MIN_DATE'), component: BasicInput, type: "date" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "maxDate", label: t('PROPERTY_MAX_DATE'), component: BasicInput, type: "date" })),
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement(Field, { name: "format", label: t('PROPERTY_FORMAT'), component: BasicInput, type: "text" }))));
const complexFieldsJsx = () => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "cp-c-padding-2" },
        React.createElement("div", null,
            React.createElement(DependantDefinitionsField, { fieldName: "definitionIds" })))));
const specificFieldTypes = {
    text: textFieldsJsx,
    number: numberFieldsJsx,
    date: dateFieldsJsx,
    complex: complexFieldsJsx
};
const PropertyForm = ({ definitionId, propertyId, handleSubmit, submitting, formValues, showDependantValues }) => {
    const { t, language } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` },
                { text: t('SETTINGS_DEFINITIONS_NAV_LINK'), to: `${ADMIN_PATH}/definitions` },
                { text: definitionId, to: `${ADMIN_PATH}/definitions/${definitionId}/properties` }
            ], currentBreadcrumbText: !propertyId ? t('ADD_PROPERTY_TITLE') : t('EDIT_PROPERTY', { property: propertyId }) }),
        React.createElement(Section, null,
            React.createElement(Title, null, !propertyId ? t('ADD_PROPERTY_TITLE') : t('EDIT_PROPERTY', { property: propertyId })),
            React.createElement(SubTitle, null, !propertyId ? t('ADD_PROPERTY_SUBTITLE') : ''),
            React.createElement(StyledForm, { onSubmit: handleSubmit, noValidate: true },
                !propertyId && (React.createElement(SectionCard, { withoutPadding: true, title: t('DEFINITION_ID') },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(Field, { name: "id", component: BasicInput, type: "text", validate: required })))),
                React.createElement(SectionCard, { withoutPadding: true, title: t('PROPERTY_LABEL') },
                    React.createElement("div", { className: "cp-c-padding-2" }, AVAILABLE_LANGUAGES.map((language) => (React.createElement("div", { key: language },
                        React.createElement(Field, { name: `labels[${language}]`, label: t(language), component: BasicInput, type: "text", validate: language === DEFAULT_LANGUAGE ? required : [] })))))),
                React.createElement(SectionCard, { withoutPadding: true, title: t('PROPERTY_DETAILS'), style: { overflow: 'hidden' } },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(Field, { name: "required", id: "required", label: t('PROPERTY_REQUIRED'), component: InlineCheckbox, type: "checkbox" })),
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(Field, { name: "description", label: t('PROPERTY_DESCRIPTION'), component: BasicTextArea, type: "text" })),
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(Field, { name: "fieldType", label: t('PROPERTY_FIELD_TYPE'), component: BasicSelect, options: propertyFieldTypes.map((type) => ({
                                value: type,
                                labels: { [language]: t(`PROPERTY_FIELD_TYPE_${type}`) }
                            })), language: language, validate: required })),
                    formValues && specificFieldTypes[formValues.fieldType] && (React.createElement(FormSection, { name: "typeData" },
                        React.createElement(FieldSet, null, specificFieldTypes[formValues.fieldType](t))))),
                React.createElement(SectionCard, { withoutPadding: true, title: t('PROPERTY_DEPENDENCIES') },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement("div", null,
                            React.createElement(DependantPropertyField, { fieldName: "dependantId", definitionId: definitionId, propertyId: propertyId }))),
                    formValues && showDependantValues(formValues.dependantId) && (React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(DependantPropertyFieldValues, { fieldName: "dependantValues", definitionId: definitionId, propertyId: formValues.dependantId })))),
                React.createElement(FormFooter, { showCancel: true, submitting: submitting })))));
};
export default PropertyForm;
