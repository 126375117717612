import React from 'react';
import { EditableItemTitleContainer, titleValidations } from '../../../cms';
import { TooltipTruncate } from '../../../common/components/TooltipTruncate';
import SelectionRow from '../../../common/components/data-table/SelectionRow';
import { VIEW_DEFINITION_ID } from '../../constants';
import RedirectOnMissingView from '../RedirectOnMissingView';
import { useView } from '../ViewContext';
import styles from './ViewTitle.module.scss';
export const VIEW_TITLE_HEIGHT = '55px';
export const ViewTitle = ({ view, viewId, title, isShowTitle = true }) => {
    const { totalItemsCount, selectedItems, isAllItemsSelected } = useView();
    return (React.createElement("div", { id: "ViewTitle", className: `${styles.outer} ${isShowTitle || isAllItemsSelected || !!selectedItems.length ? 'pb-2' : ''}` },
        React.createElement("div", { className: "ViewTitleContainer" }, isShowTitle && (React.createElement(React.Fragment, null,
            viewId && React.createElement(RedirectOnMissingView, { viewId: viewId }),
            !!totalItemsCount && (React.createElement("div", { className: "ViewTitleContainer__count" }, totalItemsCount)),
            React.createElement("div", { className: "ViewTitleContainer__title" }, view && !view.isDefault ? (React.createElement(EditableItemTitleContainer, { definitionId: VIEW_DEFINITION_ID, entityId: view.entityId, validations: {
                    ...titleValidations,
                    required: true
                } })) : (React.createElement(TooltipTruncate, null, title)))))),
        React.createElement(SelectionRow, { withFirstDivider: isShowTitle })));
};
