import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Select } from '../../../../../common/components/Select';
import { BROWSE_PATH } from '../../../../../common/utils/constants';
import { ACTIVITY_YOUR_DASHBOARD_PATH, WORKFLOWS_PATH, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../../../../common/utils/constants';
import styles from './SelectTaskType.module.scss';
var TaskType;
(function (TaskType) {
    TaskType["workflows"] = "workflows";
    TaskType["activity"] = "activity";
})(TaskType || (TaskType = {}));
const SelectTaskType = () => {
    const { t } = useI18n();
    const location = useLocation();
    const value = useMemo(() => {
        if (location.pathname.includes(WORKFLOWS_YOUR_DASHBOARD_PATH)) {
            return TaskType.workflows;
        }
        if (location.pathname.includes(ACTIVITY_YOUR_DASHBOARD_PATH)) {
            return TaskType.activity;
        }
        return TaskType.workflows;
    }, [location.pathname]);
    const dispatch = useDispatch();
    const options = useMemo(() => [
        {
            value: TaskType.workflows,
            label: t('WORKFLOWS_NAV_LINK')
        },
        { value: TaskType.activity, label: t('ACTIVITY') }
    ], [t]);
    const handleChange = (value) => {
        const path = value === TaskType.workflows ? WORKFLOWS_YOUR_DASHBOARD_PATH : ACTIVITY_YOUR_DASHBOARD_PATH;
        dispatch(replace(`${WORKFLOWS_PATH}${path}${BROWSE_PATH}`));
    };
    return (React.createElement("div", { className: styles.selectWrapper },
        React.createElement(Select, { className: `${styles.select} w-100`, options: options, value: value, onChange: handleChange })));
};
export default SelectTaskType;
