import ModalConfirmationButton from '@hypercharge/hyper-react-base/lib/common/modal-confirmation-button';
import { ScrollToTopOnMount } from '@hypercharge/hyper-react-base/lib/common/scroll-to-top';
import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import React, { useEffect, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa';
import { IoIosPlay } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useStickyTabs } from '../../../../../common/components/StickyTabs/StickyTabsProvider';
import { TooltipTruncate } from '../../../../../common/components/TooltipTruncate';
import { ActionWhen } from '../../../../../common/components/data-table/types';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, BULK_EDIT_PERMISSION, SETTINGS_PATH } from '../../../../../common/utils/constants';
import { isCmsDefinition, isWorkflowDefinition } from '../../../../../common/utils/url';
import { ViewProvider, ViewTitle, useViews } from '../../../../../views';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import { startProcess } from '../../../../../workflows/dashboard/actions';
import { deleteProcesses } from '../../../../../workflows/processes/actions';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import { CMS_PATH } from '../../../../common/utils/constants';
import { invalidateItemsQuery } from '../../../../hooks/invalidateCache';
import useDeleteItems from '../../../../hooks/useDeleteItems';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { getView } from '../../../utils';
import { QuickAddItemButton } from '../../item/QuickAddItemButton';
import BulkEditButton from '../BulkEditButton/BulkEditButton';
import BulkEditIcon from '../BulkEditButton/BulkEditIcon';
import { ItemsView } from '../ItemsView';
import styles from './ItemsContainer.module.scss';
const ItemsContainer = ({ baseUrl, definitionId, entityTitle, setLinks, setCurrentBreadcrumbText, match, canCreate, canDelete, canRun, processTitle, setExtraTextForBreadcrumbsComponent }) => {
    const { t } = useI18n();
    const { getViewBreadcrumbLink, views, loadingViews } = useViews();
    const { isActiveStickySection } = useStickyTabs();
    const isLoading = loadingViews && !views.length;
    const { isMobile } = useBrowserBreakpoints();
    const { displayDataStatus: { isPending: displayDataStatusIsPending } } = useEntityDisplayData(definitionId);
    const { mutateAsync: asyncDeleteItems } = useDeleteItems({
        definitionId
    });
    const dispatch = useDispatch();
    const startProcessDispatch = useDispatch();
    const deleteProcessesDispatch = useDispatch();
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const viewId = match?.params?.viewId;
    const view = useMemo(() => getView(views, definitionId, viewId), [views, viewId, definitionId]);
    const actions = useMemo(() => {
        const actionsList = [];
        const isCms = isCmsDefinition(displayItemMeta);
        if (isCms) {
            actionsList.push({
                key: 'settings',
                when: ActionWhen.noSelected,
                buttonContent: React.createElement(IoSettingsOutline, { preserveAspectRatio: "none" }),
                buttonProps: {
                    className: 'SettingsButton',
                    inversed: true,
                    to: `${baseUrl}${SETTINGS_PATH}`
                },
                permission: APP_ADMIN_PERMISSION
            });
        }
        if (isCms && canCreate) {
            actionsList.push({
                key: 'add',
                when: ActionWhen.noSelected,
                // @ts-expect-error
                buttonComponent: QuickAddItemButton,
                buttonProps: {
                    definitionId,
                    entityTitle,
                    className: 'QuickAddItemButton'
                },
                action: () => {
                    dispatch(success({
                        title: t('COMMON__SUCCESS'),
                        message: t('CMS__CREATE_ITEM_SUCCESS')
                    }));
                }
            });
            if (canCreate) {
                actionsList.push({
                    key: 'bulkedit',
                    when: ActionWhen.selected,
                    // @ts-expect-error
                    buttonComponent: BulkEditButton,
                    buttonContent: (React.createElement(React.Fragment, null,
                        React.createElement(BulkEditIcon, null),
                        !isMobile && React.createElement("span", { className: "ms-1" },
                            " ",
                            t('BULK_EDIT_TITLE')))),
                    buttonProps: {
                        drawerTitle: t('BULK_EDIT_TITLE'),
                        definitionId
                    },
                    clearSelection: true,
                    permission: BULK_EDIT_PERMISSION
                });
            }
            if (canDelete) {
                actionsList.push({
                    key: 'delete',
                    when: ActionWhen.selected,
                    buttonComponent: ModalConfirmationButton,
                    buttonContent: (React.createElement(React.Fragment, null,
                        React.createElement(FaTrash, { preserveAspectRatio: "none" }),
                        !isMobile && React.createElement("span", { className: "ms-1" },
                            " ",
                            t('COMMON__DELETE')))),
                    buttonProps: {
                        confirmationTitle: t('ENTITIES_DELETE_CONFIRMATION_TITLE'),
                        warningMessage: t('DELETE_WARNING_CONCERNING_REFERRALS'),
                        confirmationMessage: t('ENTITIES_DELETE_CONFIRMATION_MESSAGE'),
                        destructive: true
                    },
                    clearSelection: true,
                    resetPage: true,
                    action: async ({ selectedIds, query }) => {
                        try {
                            await asyncDeleteItems({
                                ids: selectedIds,
                                filterRequest: query
                            });
                            dispatch(success({
                                title: t('COMMON__SUCCESS'),
                                message: t('GENERIC_ASYNC_CHANGES_WARNING')
                            }));
                        }
                        catch (e) {
                            dispatch(error({
                                title: t('COMMON__FAILURE'),
                                message: `${t('ENTITIES_DELETE_FAIL')} ${selectedIds !== undefined ? selectedIds.length : undefined}`
                            }));
                        }
                    }
                });
            }
        }
        if (isWorkflowDefinition(displayItemMeta)) {
            if (![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(definitionId)) {
                actionsList.push({
                    key: 'settings',
                    when: ActionWhen.noSelected,
                    buttonContent: React.createElement(IoSettingsOutline, { preserveAspectRatio: "none" }),
                    buttonProps: {
                        className: 'SettingsButton',
                        inversed: true,
                        to: `${baseUrl}${SETTINGS_PATH}`
                    },
                    permission: APP_ADMIN_PERMISSION
                });
                if (canRun) {
                    actionsList.push({
                        key: 'start',
                        when: ActionWhen.noSelected,
                        buttonContent: (React.createElement("div", { className: "cp-c-row cp-c-align-start-center" },
                            React.createElement(IoIosPlay, { className: "me-sm-1", preserveAspectRatio: "none" }),
                            React.createElement(TooltipTruncate, { className: styles.buttonText }, !isMobile && `${t('START')} ${processTitle || ''}`))),
                        buttonProps: {
                            className: 'QuickAddItemButton'
                        },
                        action: async () => {
                            try {
                                const data = await startProcessDispatch(startProcess(definitionId));
                                void invalidateItemsQuery({
                                    definitionId,
                                    waitNewData: true
                                });
                                dispatch(success({
                                    title: t('COMMON__SUCCESS'),
                                    message: t('TASKS_START_PROCESS_SUCCESS')
                                }));
                                dispatch(push(`${baseUrl}${BROWSE_PATH}/${data.id}`));
                            }
                            catch (e) {
                                dispatch(error({
                                    title: t('COMMON__FAILURE'),
                                    message: t('TASKS_START_PROCESS_FAIL')
                                }));
                            }
                        }
                    });
                }
                if (canDelete) {
                    actionsList.push({
                        key: 'delete',
                        when: ActionWhen.selected,
                        buttonComponent: ModalConfirmationButton,
                        buttonContent: (React.createElement(React.Fragment, null,
                            React.createElement(FaTrash, { preserveAspectRatio: "none" }),
                            !isMobile && React.createElement("span", { className: "ms-1" },
                                " ",
                                t('COMMON__DELETE')))),
                        buttonProps: {
                            confirmationTitle: t('WORKFLOWS__PROCESSES_DELETE_CONFIRMATION_TITLE'),
                            warningMessage: t('DELETE_WARNING_CONCERNING_REFERRALS'),
                            confirmationMessage: t('WORKFLOWS__PROCESSES_DELETE_CONFIRMATION_MESSAGE'),
                            destructive: true
                        },
                        resetPage: true,
                        clearSelection: true,
                        action: async ({ selectedIds = [] }) => {
                            try {
                                await deleteProcessesDispatch(deleteProcesses(definitionId, selectedIds));
                                await invalidateItemsQuery({
                                    definitionId,
                                    ids: selectedIds,
                                    waitNewData: true
                                });
                                dispatch(success({
                                    title: t('COMMON__SUCCESS'),
                                    message: t('GENERIC_ASYNC_CHANGES_WARNING')
                                }));
                            }
                            catch (e) {
                                dispatch(error({
                                    title: t('COMMON__FAILURE'),
                                    message: `${t('WORKFLOWS__PROCESSES_DELETE_FAIL')} ${selectedIds.length}`
                                }));
                            }
                        }
                    });
                }
            }
        }
        return actionsList;
    }, [
        asyncDeleteItems,
        baseUrl,
        canCreate,
        canDelete,
        canRun,
        definitionId,
        displayItemMeta,
        deleteProcessesDispatch,
        dispatch,
        entityTitle,
        isMobile,
        processTitle,
        startProcessDispatch,
        t
    ]);
    useEffect(() => {
        const title = isCmsDefinition(displayItemMeta)
            ? entityTitle || ''
            : isWorkflowDefinition(displayItemMeta)
                ? processTitle || ''
                : '';
        const viewBreadCrumbLink = getViewBreadcrumbLink(baseUrl, title, viewId);
        let links = [];
        if (viewBreadCrumbLink) {
            setCurrentBreadcrumbText(viewBreadCrumbLink.text);
            if (isCmsDefinition(displayItemMeta)) {
                links = [
                    { text: t('CMS_NAV_LINK'), to: CMS_PATH },
                    { text: entityTitle || '', to: `${baseUrl}${BROWSE_PATH}` }
                ];
            }
            if (isWorkflowDefinition(displayItemMeta)) {
                links = [
                    { text: t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
                    {
                        text: t('WORKFLOWS_PROCESSES_NAV_LINK'),
                        to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
                    },
                    { text: processTitle || '', to: `${baseUrl}${BROWSE_PATH}` }
                ];
            }
        }
        else {
            setCurrentBreadcrumbText(title);
            if (isCmsDefinition(displayItemMeta)) {
                links = [{ text: t('CMS_NAV_LINK'), to: CMS_PATH }];
            }
            if (isWorkflowDefinition(displayItemMeta)) {
                links = [
                    { text: t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
                    {
                        text: t('WORKFLOWS_PROCESSES_NAV_LINK'),
                        to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
                    }
                ];
            }
        }
        setLinks(links);
    }, [
        baseUrl,
        displayItemMeta,
        entityTitle,
        getViewBreadcrumbLink,
        processTitle,
        setCurrentBreadcrumbText,
        setLinks,
        t,
        viewId
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollToTopOnMount, null),
        React.createElement("div", { className: `${styles.container} ${isActiveStickySection ? 'pb-5' : 'pb-2'}` }, !isLoading ? (React.createElement(ViewProvider, { definitionId: definitionId, view: view, canSelectAll: true, enableGrouping: true },
            displayDataStatusIsPending ? (React.createElement(Skeleton, { isLoading: true, className: "mb-1" },
                React.createElement(TextPlaceholder, { heightInPx: 24, widthInPx: 250 }))) : (React.createElement(ViewTitle, { isShowTitle: false, view: view, viewId: viewId, title: entityTitle || processTitle || '' })),
            React.createElement(ItemsView, { baseUrl: baseUrl, viewId: viewId, view: view, definitionId: definitionId, actions: actions, getTotalItemsCount: setExtraTextForBreadcrumbsComponent }))) : null)));
};
export default ItemsContainer;
