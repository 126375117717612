import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Select } from 'antd';
import { isEmpty, isString, isUndefined } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { isComputedExpression } from '../../../utils/computedExpression';
import { ComputedInput } from '../ComputedExpressionEditor';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.equals, label: FilterOperatorTypes.is },
    { value: FilterOperatorTypes.notEquals, label: FilterOperatorTypes.isNot },
    { value: FilterOperatorTypes.empty, label: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty, label: FilterOperatorTypes.notEmpty }
];
const valueToKey = (value) => {
    switch (value) {
        case true:
            return 'true';
        case false:
            return 'false';
        default:
            return undefined;
    }
};
const keyToValue = (value) => {
    switch (value) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return null;
    }
};
const CheckboxFilter = ({ className, filter, onChange, onClear, showSelectOperator, disabledSelectOperator, disabled, allowComputed, propertiesTree }) => {
    const { t } = useI18n();
    const data = useMemo(() => {
        if ('data' in filter) {
            if (isString(filter.data) && isComputedExpression(filter.data)) {
                return filter.data;
            }
            return !isUndefined(filter.data) && onClear && isEmpty(filter.data.toString())
                ? filter.data
                : valueToKey(!!filter.data);
        }
        return 'true';
    }, [filter, onClear]);
    const onSelectInput = useCallback((value) => {
        let newValue = null;
        if (isString(value)) {
            if (['true', 'false'].includes(value)) {
                newValue = keyToValue(value);
            }
            else if (isComputedExpression(value)) {
                newValue = value;
            }
            else {
                newValue = !!value;
            }
        }
        else {
            newValue = !!value;
        }
        const newFilter = buildFilter(filter.field, filter.operator, newValue);
        onChange(newFilter);
    }, [filter.field, filter.operator, onChange]);
    return (React.createElement(BaseFilter, { onChange: onChange, operators: availableOperators, filter: filter, showSelectOperator: showSelectOperator, disabledSelectOperator: disabledSelectOperator, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { className: "w-100", component: Select, value: data, onChange: onSelectInput, disabled: disabled, propertiesTree: propertiesTree },
        React.createElement(Select.Option, { value: "true" }, t('YES')),
        React.createElement(Select.Option, { value: "false" }, t('NO')))) : (React.createElement(Select, { value: data, onClear: () => {
            onClear?.(filter);
        }, onSelect: (value) => {
            onSelectInput(value);
        }, disabled: disabled, className: "w-100", allowClear: !!onClear },
        React.createElement(Select.Option, { value: "true" }, t('YES')),
        React.createElement(Select.Option, { value: "false" }, t('NO'))))));
};
export default CheckboxFilter;
