import { includes } from 'lodash';
import React from 'react';
import { IoMdCheckbox, IoMdSquareOutline } from 'react-icons/io';
import styled, { useTheme } from 'styled-components';
const OptionContainer = styled.div `
  font-size: 0.9rem;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
`;
const Count = styled.div `
  position: absolute;
  right: 1rem;
  color: ${({ theme }) => theme.textColor};
`;
const OptionSubContainer = styled.div `
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.mainColor};
  cursor: pointer;
  border-bottom: 1px solid #eee;
  height: 2.7rem;
  padding: 0 2rem 0 0.5rem;

  svg {
    flex-shrink: 0;
  }
`;
const Label = styled.div `
  padding-left: 0.5rem;
`;
const ButtonCheckboxes = ({ className, options, selection, updateSelection }) => {
    const theme = useTheme();
    const handleOnClick = (o) => {
        const i = selection.indexOf(o.id);
        if (i > -1) {
            updateSelection([...selection.slice(0, i), ...selection.slice(i + 1)]);
        }
        else {
            updateSelection([...selection, o.id]);
        }
    };
    return (React.createElement("div", { className: `cp-c-row cp-c-wrap cp-c-align-start-start ${className || ''}` }, options.map((o) => {
        const selected = includes(selection, o.id);
        const CheckIcon = selected ? IoMdCheckbox : IoMdSquareOutline;
        return (React.createElement(OptionContainer, { key: o.id, className: "cp-i-100 cp-i-md-50", disabled: o.count == 0 },
            React.createElement(OptionSubContainer, { className: `cp-c-row cp-c-align-start-center ${selected ? 'selected' : ''}`, onClick: () => {
                    handleOnClick(o);
                } },
                React.createElement(CheckIcon, { size: 25, color: o.count == 0 ? '#eee' : theme.mainColor }),
                React.createElement(Label, null, o.value),
                React.createElement(Count, null, o.count && o.count.toLocaleString()))));
    })));
};
export default ButtonCheckboxes;
