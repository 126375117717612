import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { useProperties } from '../../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import EmailTemplateMappingsField from '../EmailTemplateMappingsField/EmailTemplateMappingsField';
const parametersSectionName = 'parameters';
const templateMappingsFieldName = 'templateMappingPropertyId';
const templateMappingsName = 'templateMappings';
const templateMappingPropertyTypes = ['text', 'entity', 'select', 'number', 'checkbox'];
const isSupportedMappingProperty = ({ type }) => templateMappingPropertyTypes.includes(type);
const ConditionalTemplatesField = ({ input: { value: templateMappingPropertyId, name }, changeFieldValue, disabled, definitionId, propertiesTree }) => {
    const { t } = useI18n();
    const [showConditionalTemplates, setShowConditionalTemplates] = useState(!!templateMappingPropertyId);
    const { getDisplayData, propertiesStatus } = useProperties(definitionId);
    const displayData = useMemo(() => getDisplayData(templateMappingPropertyId), [getDisplayData, templateMappingPropertyId]);
    const [parametersFieldPath] = useState(`${name.split(`.${parametersSectionName}`)[0]}.${parametersSectionName}`);
    const toggleConditionalTemplates = useCallback((e) => {
        if (showConditionalTemplates) {
            // When the fields become hidden, we clear the fields
            changeFieldValue([parametersFieldPath, templateMappingsFieldName].join('.'), null);
            changeFieldValue([parametersFieldPath, templateMappingsName].join('.'), null);
        }
        setShowConditionalTemplates((s) => !s);
        e.preventDefault();
    }, [showConditionalTemplates, changeFieldValue, parametersFieldPath]);
    const onChangeMappingsField = useCallback((_, newValue, previousValue) => {
        const previousDisplayData = displayData;
        const newValueDisplayData = getDisplayData(newValue);
        if (!!newValueDisplayData !== !!previousDisplayData ||
            !isEqual(newValueDisplayData, previousDisplayData) ||
            !newValue) {
            if (newValue != previousValue) {
                changeFieldValue([parametersFieldPath, templateMappingsName].join('.'), []);
            }
        }
    }, [changeFieldValue, displayData, getDisplayData, parametersFieldPath]);
    const [isTemplateMappingComputed, setIsTemplateMappingComputed] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "toggle-btn btn btn-link mt-3", onClick: toggleConditionalTemplates }, showConditionalTemplates
            ? t('TASK_EMAIL_HIDE_TEMPLATE_OPTIONS')
            : t('TASK_EMAIL_SHOW_TEMPLATE_OPTIONS')),
        showConditionalTemplates && (React.createElement("div", { className: "conditional-templates" },
            React.createElement("div", { className: "row mb-3" },
                React.createElement("div", { className: "col" },
                    React.createElement(FieldLabel, { label: t('TASK_EMAIL_TEMPLATE_MAPPING'), className: "ps-0" }),
                    React.createElement(Field, { className: "cp-i-100", name: templateMappingsFieldName, component: ComputedInputField, computedComponent: SelectPropertyField, propertiesTree: propertiesTree, disabled: disabled, definitionId: definitionId, includeProperty: isSupportedMappingProperty, includeNestedProperties: true, onChange: onChangeMappingsField, clearOnMount: false, onComputedModeChange: setIsTemplateMappingComputed, placehodler: `${t('SELECT')} ${t('COMMON__PROPERTY')}` }))),
            templateMappingPropertyId && (React.createElement("div", { className: "cp-c-row cp-c-wrap" },
                React.createElement(FieldArray, { name: templateMappingsName, label: t('TASK_EMAIL_MAPPINGS'), component: EmailTemplateMappingsField, propertiesTree: propertiesTree, propertiesStatus: propertiesStatus, displayData: displayData, disabled: disabled, isComputed: isTemplateMappingComputed })))))));
};
export default ConditionalTemplatesField;
