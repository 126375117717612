import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Wrapper } from '@hypercharge/hyper-react-base/lib/styling';
import { Divider } from 'antd';
import { get, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { Field, Fields, getFormValues } from 'redux-form';
import useDisplayItemMetaList from '../../../../cms/hooks/useDisplayItemMetaList';
import { HighlightedSelectField } from '../../../../cms/settings/components/entity/HighlightedItems';
import { AntInput } from '../../../../common/components/AntInput';
import { ClipboardCopyButton, ClipboardPasteButton } from '../../../../common/components/ClipboardButtons';
import ColorCustomization from '../../../../common/components/Color/ColorCustomization/ColorCustomization';
import ColorField from '../../../../common/components/Color/ColorField/ColorField';
import MultiLanguageField from '../../../../common/components/MultiLanguageField/MultiLanguageField';
import { Option } from '../../../../common/components/Select';
import TabCloseConfirmation from '../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { BROWSE_PATH, CMS_ITEM_META_HANDLER } from '../../../../common/utils/constants';
import { FieldsContainer, FieldsContainerTitle } from '../../../../common/utils/formUtils';
import GroupsField, { ADMINS_PERMISSION_GROUP, OWNER_GROUP_PLACEHOLDER } from '../../../../groups';
import { AvailableSystemGroups } from '../../../../groups/constants';
import { ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import ProcessMetaActions from '../common/ProcessMetaActions';
import styles from './ProcessMetaForm.module.scss';
const ALLOWED_ROW_PERMISSIONS = ['view'];
const normalizeIdPrefix = (value) => value && value.replace(/[^a-z0-9]/gi, '').toUpperCase();
const ProcessMetaForm = ({ cancel, dirty, extraActionButtons, form, handleSubmit, initialValues, invalid, isNew, isPending, isSystem, isTriggerProcess, permissionGroupFieldNames, processMeta, reset, setCurrentBreadcrumbText, setLinks, submitting, change }) => {
    const { t } = useI18n();
    const formValue = useSelector(getFormValues(form));
    const { data: entities } = useDisplayItemMetaList();
    const cmsEntities = useMemo(() => entities.filter((entity) => entity.handler === CMS_ITEM_META_HANDLER), [entities]);
    useEffect(() => {
        if (isNew) {
            setLinks([
                { text: t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
                {
                    text: t('WORKFLOWS_SETTINGS_PROCESS_META_NAV_LINK'),
                    to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
                }
            ]);
            setCurrentBreadcrumbText(t('CREATE_PROCESS_META__TITLE'));
        }
    }, [isNew, setLinks, t, setCurrentBreadcrumbText]);
    useEffect(() => {
        if (invalid) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [invalid]);
    const clipboardProperties = useMemo(() => {
        return ['titles', 'idPrefix', 'color', 'custom', 'permissions', ...permissionGroupFieldNames];
    }, [permissionGroupFieldNames]);
    const processMetaNecessaryProperties = useMemo(() => {
        return ['titles', 'idPrefix', 'color', 'custom'];
    }, []);
    const isProcessMetaClipboard = useCallback((data) => {
        for (const prop of processMetaNecessaryProperties) {
            if (!(prop in data)) {
                return false;
            }
        }
        return true;
    }, [processMetaNecessaryProperties]);
    const valueToClipboard = useMemo(() => {
        return pick(formValue, clipboardProperties);
    }, [clipboardProperties, formValue]);
    const isValidClipboard = useCallback((clipText) => {
        try {
            const clipValue = JSON.parse(clipText);
            if (isProcessMetaClipboard(clipValue)) {
                return true;
            }
        }
        catch (error) {
            return false;
        }
        return false;
    }, [isProcessMetaClipboard]);
    const onPasteClipboard = useCallback((clipText) => {
        const clipValue = JSON.parse(clipText);
        if (!isProcessMetaClipboard(clipValue)) {
            return;
        }
        for (const property of clipboardProperties) {
            change(property, clipValue[property]);
        }
    }, [change, clipboardProperties, isProcessMetaClipboard]);
    const onChangeRowPermissions = useCallback((newRowPermissions) => {
        change('permissions', newRowPermissions);
    }, [change]);
    return (React.createElement("form", { className: "h-100", onSubmit: handleSubmit, noValidate: true },
        React.createElement(Wrapper, { className: "h-100" },
            React.createElement("div", { className: "h-100 cp-c-row cp-c-wrap cp-c-padding-2" },
                React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50" },
                    React.createElement(FieldsContainerTitle, null, t('WORKFLOWS_SETTINGS_PROCESS_NAME')),
                    React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: isPending || submitting || isTriggerProcess || isSystem, maxLength: 60, placeholder: t('COMMON__TITLE') })),
                    React.createElement(FieldsContainerTitle, null, t('PROCESS_META__TECHNICAL_DETAILS')),
                    React.createElement(FieldsContainer, { className: "cp-c-row cp-c-wrap cp-c-padding-2" },
                        React.createElement(Field, { className: "cp-i-100 cp-i-sm-33", name: "idPrefix", type: "text", component: BasicInput, normalize: normalizeIdPrefix, label: t('PROCESS_META__ID_PREFIX'), disabled: isPending || submitting, maxLength: 5 })),
                    React.createElement(LabelText, { className: "pt-3" }, t('PROCESS_META__COLOR')),
                    React.createElement("div", { className: "cp-c-row" },
                        React.createElement(Field, { component: ColorField, name: "color" })),
                    React.createElement(ColorCustomization, { submitting: submitting }),
                    React.createElement(FieldsContainerTitle, null, t('PROCESS_META__QUICKSTART_FROM_CMS')),
                    React.createElement("div", null,
                        React.createElement(Field, { name: "custom.processRunFromEntities", component: HighlightedSelectField, data: cmsEntities, disabled: submitting, getValue: (value) => value.map((referral) => referral.definitionId), onSelectHandler: (selected) => {
                                void change('custom.processRunFromEntities', selected.map((definitionId) => {
                                    const value = get(formValue, 'custom.processRunFromEntities', []);
                                    const existed = value.find((item) => item.definitionId === definitionId);
                                    return {
                                        definitionId,
                                        active: !!existed?.active || true
                                    };
                                }));
                            }, getOptions: (data) => data.map(({ definitionId, title }) => (React.createElement(Option, { key: definitionId, value: definitionId, disabled: false, label: title }, title))) }))),
                React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50" },
                    React.createElement(Divider, null, t('PERMISSIONS')),
                    React.createElement(Fields, { names: permissionGroupFieldNames, component: GroupsField, disabled: submitting, withValidation: true, isPermissionToggleDisabled: (groupId, permission) => groupId === ADMINS_PERMISSION_GROUP ||
                            (groupId === OWNER_GROUP_PLACEHOLDER &&
                                AvailableSystemGroups.executeGroups === permission), itemMetaDefinitionId: processMeta?.id, rowPermissions: formValue.permissions, allowedRowPermissions: ALLOWED_ROW_PERMISSIONS, onChangeRowPermissions: onChangeRowPermissions }))),
            React.createElement("div", { className: `${styles.formButtons} position-absolute top-0 end-0 mt-1 me-3 ${isNew ? 'isNew' : ''}` },
                React.createElement(React.Fragment, null,
                    React.createElement(ClipboardCopyButton, { value: valueToClipboard, inversed: true, className: "me-3" }),
                    React.createElement(ClipboardPasteButton, { className: "me-3", inversed: true, isValid: isValidClipboard, onClick: onPasteClipboard }),
                    isNew ? (React.createElement(Button, { className: "me-3", inversed: true, onClick: cancel, disabled: submitting }, t('FORM_CANCEL'))) : (dirty && (React.createElement(Button, { className: "me-3", inversed: true, onClick: reset, disabled: submitting }, t('FORM__RESET')))),
                    React.createElement(ProcessMetaActions, { disabled: submitting, 
                        // @ts-expect-error
                        id: initialValues.id, 
                        // @ts-expect-error
                        save: handleSubmit, saving: submitting, canSave: dirty, canDelete: !isNew && !isSystem }),
                    extraActionButtons)),
            React.createElement(Prompt, { when: dirty && !submitting, message: () => t('UNSAVED_DATA_CONFIRM') }),
            React.createElement(TabCloseConfirmation, { dirty: dirty }))));
};
export default ProcessMetaForm;
