import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';
import { Field, FormSection, formValueSelector } from 'redux-form';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../../common/components/AntInput';
import { ConditionQueryField } from '../../../../../../common/components/ConditionQuery';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField';
import { SelectField } from '../../../../../../common/components/Select';
import Toggle from '../../../../../../common/components/Toggle';
import { ComputedExpressionsDocumentationLink } from '../../../../../../common/components/documentation-link';
import { ItemMetaSelector } from '../../../../../../common/components/entity-selectors';
import { FieldsContainerTitle } from '../../../../../../common/utils/formUtils';
const EntityTriggerForm = ({ node: { parameters }, disabled, metaDefinitionId, changeFieldValue }) => {
    const { t } = useI18n();
    const store = useStore();
    const enabledExpandedFilter = !!formValueSelector('activitySettings')(store.getState(), 'activity.Start.parameters.expandedFilter');
    const eventOptions = useMemo(() => {
        return ['CREATE', 'UPDATE', 'DELETE'].map((type) => ({
            value: type,
            label: t(`TASK_ENTITY_EVENT_TRIGGER_EVENTS_${type.toUpperCase()}`)
        }));
    }, [t]);
    const isSupportedEntityProperty = useCallback(({ type, meta }) => {
        return type === 'entity' && meta.definitionId === parameters.definitionId && !meta.list;
    }, [parameters.definitionId]);
    return (React.createElement("div", null,
        React.createElement(FieldsContainerTitle, { small: true, className: "mt-0" }, t('WORKFLOWS_SETTINGS_TITLE')),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, placeholder: t('COMMON__TITLE'), disabled: disabled }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldsContainerTitle, { small: true }, t('ACTIVE')),
            React.createElement(Field, { name: "active", component: Toggle, disabled: disabled }),
            React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
            React.createElement(FieldsContainerTitle, { small: true }, t('TASK_ENTITY_EVENT_TRIGGER_EVENTS')),
            React.createElement(Field, { name: "events", component: SelectField, disabled: disabled, validate: required, options: eventOptions, mode: "multiple" }),
            React.createElement(FieldsContainerTitle, null, t('ENTITY')),
            React.createElement(Field, { name: "definitionId", 
                // @ts-expect-error
                component: ItemMetaSelector, disabled: disabled, validate: required, autoFocus: false, onChange: (e, newValue, previousValue, name) => {
                    if (newValue != previousValue) {
                        // Reset the conditionQuery and property mappings that were based on the previously selected process
                        const entityItemsFilterFieldName = name?.replace('definitionId', 'query') || '';
                        changeFieldValue(entityItemsFilterFieldName, null);
                    }
                } }),
            parameters.definitionId && typeof parameters.definitionId === 'string' && (React.createElement(React.Fragment, null,
                Array.isArray(parameters.events) &&
                    (parameters.events.includes('UPDATE') || parameters.events.includes('CREATE')) && (React.createElement(React.Fragment, null,
                    React.createElement(FieldsContainerTitle, null, t('TASK_ENTITY_EVENT_TRIGGER_PROPERTIES')),
                    React.createElement(Field, { name: "properties", disabled: disabled, multiple: true, component: SelectPropertyField, definitionId: parameters.definitionId, includeNestedProperties: false }))),
                React.createElement(FieldsContainerTitle, null, t('TASK_EXPANDED_FILTER')),
                React.createElement(Field, { name: "expandedFilter", 
                    // @ts-expect-error
                    component: Toggle, disabled: disabled, onChange: (e, newValue, previousValue, name) => {
                        if (newValue) {
                            const expandedOutputName = name?.replace('expandedFilter', 'expandedOutput') || '';
                            changeFieldValue(expandedOutputName, true);
                        }
                    } }),
                React.createElement(FieldsContainerTitle, null, t('TASK_EXPANDED_OUTPUT')),
                React.createElement(Field, { name: "expandedOutput", component: Toggle, disabled: disabled || enabledExpandedFilter }),
                React.createElement(FieldsContainerTitle, null, t('SELECT_PROPERTY_TO_OUTPUT')),
                React.createElement(Field, { name: "processPropertyId", disabled: disabled, component: SelectPropertyField, definitionId: metaDefinitionId, includeNestedProperties: false, includeProperty: isSupportedEntityProperty }),
                React.createElement(Divider, null, t('VIEWS__FILTERS')),
                React.createElement("div", { className: "mb-3" },
                    React.createElement(ComputedExpressionsDocumentationLink, null)),
                React.createElement(Field, { name: "query", component: ConditionQueryField, definitionId: parameters.definitionId, includeNestedProperties: enabledExpandedFilter, allowComputed: true }))))));
};
export default EntityTriggerForm;
