import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { INTEGRATION_ADMIN_PERMISSION, MANAGE_FAVOURITES_PERMISSION } from '@hypercharge/portal-utils';
import { DOCUMENTATION_URL, ROADMAP_URL } from 'config';
import React, { useMemo } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { REQUIRED_ADMIN_TENANT_PERMISSION } from '../../../../../../admin';
import { hasTenantPermission } from '../../../../../../auth';
import { PushMenu } from '../../../../../../common/components/push-menu';
import { APP_ADMIN_PERMISSION, PLATFORM_ADMIN_PERMISSION } from '../../../../../../common/utils/constants';
import { ContactDisplayName } from '../../../../../../crm';
import { useMyContact } from '../../../../../../crm/components/use-my-contact';
import { SETTINGS_PATH } from '../../../../../../settings';
import { PAYMENT_SUBSCRIPTION_PERMISSION } from '../../../../../../settings/components/subscriptions/constants';
import LanguageSelector from '../../../LanguageSelector';
import styles from './Modal.module.scss';
const Modal = ({ close, signOut, menu }) => {
    const { t } = useI18n();
    const { contact } = useMyContact();
    const fullName = `${contact?.firstName} ${contact?.lastName}`;
    const { hasRequiredAdminTenantPermission, hasAdminPermission, hasPlatformAdminPermission, hasIntegrationsAdminPermission, hasPaymentAdminPermission, hasManageFavouritesPermission } = useSelector((s) => ({
        hasRequiredAdminTenantPermission: hasTenantPermission(s, REQUIRED_ADMIN_TENANT_PERMISSION),
        hasAdminPermission: hasTenantPermission(s, APP_ADMIN_PERMISSION),
        hasPlatformAdminPermission: hasTenantPermission(s, PLATFORM_ADMIN_PERMISSION),
        hasIntegrationsAdminPermission: hasTenantPermission(s, INTEGRATION_ADMIN_PERMISSION),
        hasPaymentAdminPermission: hasTenantPermission(s, PAYMENT_SUBSCRIPTION_PERMISSION),
        hasManageFavouritesPermission: hasTenantPermission(s, MANAGE_FAVOURITES_PERMISSION)
    }));
    const additionalMenuItems = useMemo(() => {
        const menu = [];
        if (hasRequiredAdminTenantPermission ||
            hasAdminPermission ||
            hasPlatformAdminPermission ||
            hasIntegrationsAdminPermission ||
            hasPaymentAdminPermission ||
            hasManageFavouritesPermission) {
            menu.push({
                key: 'settings',
                title: t('SETTINGS'),
                to: SETTINGS_PATH
            });
        }
        menu.push({
            key: 'help',
            title: t('HELP'),
            to: DOCUMENTATION_URL
        }, {
            key: 'roadmap',
            title: t('FEATURE_WISHLIST_AND_ROADMAP'),
            to: ROADMAP_URL
        });
        return menu;
    }, [
        hasAdminPermission,
        hasIntegrationsAdminPermission,
        hasManageFavouritesPermission,
        hasPaymentAdminPermission,
        hasPlatformAdminPermission,
        hasRequiredAdminTenantPermission,
        t
    ]);
    return (React.createElement("div", { className: "pb-5" },
        React.createElement("div", { className: `${styles.profileContainer} px-0 cp-c-row` },
            React.createElement("div", { className: `${styles.profile} text-center d-flex align-items-center justify-content-center` },
                React.createElement(ContactDisplayName, { contact: contact, justFirstLetter: true })),
            React.createElement("div", { className: "fw-500 ps-3 cp-c-column cp-c-align-spacebetween-start cp-c-row cp-c-wrap" }, fullName)),
        React.createElement("div", { className: "cp-c-column" },
            React.createElement(PushMenu, { items: [...menu, ...additionalMenuItems], onItemClick: close })),
        React.createElement("div", { className: "mt-2 d-flex justify-content-between" },
            React.createElement(LanguageSelector, { icon: true, desktopHeader: false }),
            React.createElement("div", { className: `${styles.signOut} pt-0 cp-c-align-start-center cp-c-row`, onClick: signOut, "data-test-id": "mobile-menu-user-dropdown-logout" },
                React.createElement(MdArrowForward, { className: "fs-2 pe-2" }),
                React.createElement("div", null, t('AUTH_LOG_OUT_NAV_LINK'))))));
};
export default Modal;
