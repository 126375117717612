import React from 'react';
import { DataListContainer } from '../../../../../cms';
import AvailableTasks from '../AvailableTasks';
import { useRequiredFields } from '../RequiredFieldsProvider';
import { usePermissionFieldsConfig } from '../usePermissionFieldsConfig';
const ProcessMainContent = ({ processDetails, assignTask, completeTask, onChangeTaskPriority, onChangeTaskDeadline, dispatchWarning, isProcessPending, processMetaId, blacklistedPropertyIds, canEditData, processRunId, contactId }) => {
    const { requiredFieldsConfig, availableTasks, myAssignedTasks } = useRequiredFields();
    const permissionFieldsConfig = usePermissionFieldsConfig(myAssignedTasks);
    return (React.createElement("div", { className: "overflow-hidden w-100" },
        React.createElement(AvailableTasks, { isProcessPending: isProcessPending, tasks: availableTasks, processStatus: processDetails?.status, completeTask: completeTask, assignTask: assignTask, dispatchWarning: dispatchWarning, canEdit: canEditData, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, contactId: contactId, onChangePriority: onChangeTaskPriority, onChangeDeadline: onChangeTaskDeadline }),
        processMetaId && (React.createElement(DataListContainer, { definitionId: processMetaId, entityId: processRunId, blacklistedPropertyIds: blacklistedPropertyIds, canEdit: canEditData, requiredFieldsConfig: requiredFieldsConfig, showRequiredFieldsSwitcher: false, doubleCol: true }))));
};
export default ProcessMainContent;
