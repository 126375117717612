import { colord } from 'colord';
import React from 'react';
import { BsCheckLg, BsPlus } from 'react-icons/bs';
import styles from './ColorPicker.module.scss';
import { defaultColors } from './constants';
export const Colors = ({ title, value, onChange, colors = defaultColors, addCustomColor }) => (React.createElement("div", { className: `${styles.defaultColors} mt-3 fw-600` },
    title && React.createElement("div", { className: "title mb-3" }, title),
    React.createElement("div", { className: "d-flex flex-wrap" },
        colors.map((color) => {
            const isSelected = value === color;
            const isDark = colord(color).isDark();
            return (React.createElement("div", { key: color, className: "color me-2 mb-2 rounded border", role: "button", style: {
                    backgroundColor: color
                }, onClick: () => onChange && onChange(color) }, isSelected && (React.createElement("div", { style: { backgroundColor: color }, className: `d-flex align-items-center justify-content-center h-100 rounded border-2 border ${isDark ? 'border-white' : 'border-dark border-opacity-50'}` },
                React.createElement(BsCheckLg, { className: `${isDark ? 'text-white' : 'text-black text-opacity-50'}`, size: 10 })))));
        }),
        addCustomColor && (React.createElement("div", { role: "button", onClick: addCustomColor, className: "color rounded border d-flex align-items-center justify-content-center" },
            React.createElement(BsPlus, null))))));
