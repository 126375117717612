import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { getConfigsState } from '../../../common/reducers/rootSelector';
import { fetchConfig, getConfig, hasFetchConfigFailed, isFetchConfigPending, saveConfig } from '../../../configs';
import { CONTACT_CMS_DEFINITION_ID, ORGANIZATION_CMS_DEFINITION_ID, VEHICLE_CMS_DEFINITION_ID } from '../../../crm';
import { fetchMetaDefinition, getMetaDefinition, getMetaDefinitionStatus } from '../../../workflows';
import { SETTINGS_KEY, SETTINGS_PATH, XDMS_KEY } from '../../constants';
import { formName } from './utils';
const defaultMappings = { fromXdms: {} };
const useXdmsSettingsForm = ({ setLinks, setCurrentBreadcrumbText }) => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const config = useSelector((s) => {
        const configsState = getConfigsState(s);
        const xdmsConfig = getConfig(configsState, SETTINGS_KEY, XDMS_KEY);
        const initialValues = xdmsConfig
            ? {
                mappings: {
                    contact: defaultMappings,
                    organization: defaultMappings,
                    vehicle: defaultMappings
                },
                ...xdmsConfig.data
            }
            : undefined;
        return {
            initialValues,
            // @ts-expect-error
            formValues: getFormValues(s, formName),
            xdmsConfig,
            isConfigPending: isFetchConfigPending(configsState, SETTINGS_KEY, XDMS_KEY),
            isConfigFailed: hasFetchConfigFailed(configsState, SETTINGS_KEY, XDMS_KEY),
            contactMetaDefinition: getMetaDefinition(s, CONTACT_CMS_DEFINITION_ID),
            contactMetaDefinitionStatus: getMetaDefinitionStatus(s, CONTACT_CMS_DEFINITION_ID),
            organizationMetaDefinition: getMetaDefinition(s, ORGANIZATION_CMS_DEFINITION_ID),
            organizationMetaDefinitionStatus: getMetaDefinitionStatus(s, ORGANIZATION_CMS_DEFINITION_ID),
            vehicleMetaDefinition: getMetaDefinition(s, VEHICLE_CMS_DEFINITION_ID),
            vehicleMetaDefinitionStatus: getMetaDefinitionStatus(s, VEHICLE_CMS_DEFINITION_ID)
        };
    });
    const fetchXdmsConfig = useCallback(() => dispatch(fetchConfig(SETTINGS_KEY, XDMS_KEY)), [dispatch]);
    const fetchContactMetaDefinition = useCallback(() => dispatch(fetchMetaDefinition(CONTACT_CMS_DEFINITION_ID)), [dispatch]);
    const fetchOrganizationMetaDefinition = useCallback(() => dispatch(fetchMetaDefinition(ORGANIZATION_CMS_DEFINITION_ID)), [dispatch]);
    const fetchVehicleMetaDefinition = useCallback(() => dispatch(fetchMetaDefinition(VEHICLE_CMS_DEFINITION_ID)), [dispatch]);
    const onFormSubmit = useCallback((formValues) => {
        dispatch(saveConfig({
            ...config.xdmsConfig,
            configType: SETTINGS_KEY,
            configId: XDMS_KEY,
            data: formValues
        }))
            // @ts-expect-error
            .then(() => {
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
        })
            .catch((e) => {
            dispatch(error({
                title: t('ERROR'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
        });
    }, [dispatch, t, config]);
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText('XDMS');
        if (!config.isConfigPending) {
            void fetchXdmsConfig();
        }
        if (!config.contactMetaDefinitionStatus.isAvailable &&
            !config.contactMetaDefinitionStatus.isFailed) {
            void fetchContactMetaDefinition();
        }
        if (!config.organizationMetaDefinitionStatus.isAvailable &&
            !config.organizationMetaDefinitionStatus.isFailed) {
            void fetchOrganizationMetaDefinition();
        }
        if (!config.vehicleMetaDefinitionStatus.isAvailable &&
            !config.vehicleMetaDefinitionStatus.isFailed) {
            void fetchVehicleMetaDefinition();
        }
    }, []);
    return {
        fetchXdmsConfig,
        fetchContactMetaDefinition,
        fetchOrganizationMetaDefinition,
        fetchVehicleMetaDefinition,
        onFormSubmit,
        config
    };
};
export default useXdmsSettingsForm;
