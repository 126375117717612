import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { fetchDisplayTaskList } from './actions';
const DEFAULT_TASKS = [];
export const useDisplayTasks = (processMetaId, processRunId, language) => {
    const fetchDisplayTaskDispatch = useDispatch();
    const cacheKey = useMemo(() => {
        return ['display-tasks', processMetaId, processRunId, language];
    }, [language, processMetaId, processRunId]);
    const { data = DEFAULT_TASKS, refetch, isLoading } = useQuery(cacheKey, async () => {
        const result = await fetchDisplayTaskDispatch(fetchDisplayTaskList({ processRunId, page: 1, pageSize: 500, language }));
        return result.results;
    });
    const _refetch = useCallback(async () => {
        await refetch();
    }, [refetch]);
    return useMemo(() => {
        return {
            tasks: data,
            refetch: _refetch,
            isLoading
        };
    }, [_refetch, data, isLoading]);
};
