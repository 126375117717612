import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Switch } from 'antd';
import { INITIAL_PAGE_SIZE } from 'config';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { fetchPersonalConfig, savePersonalConfig } from '../../../configs/actions';
import { CONTACT_CMS_DEFINITION_ID } from '../../../crm';
import { DrawerContent, DrawerFooter } from '../../components/Drawer';
import { DrawerContentBlock } from '../../components/Drawer/DrawerContentBlock';
import { DrawerContentBlockTitle } from '../../components/Drawer/DrawerContentBlockTitle';
import { DrawerFooterBlock } from '../../components/Drawer/DrawerFooterBlock';
import TablePagination from '../../components/table-pagination';
import { markAllNotificationsAsRead, updateUserNotification } from '../actions';
import { getUserNotificationsByPage, getUserNotificationsListStatus } from '../selectors';
import Notification from './Notification';
import NotificationsSetEmailFields from './NotificationsSetEmailFields';
const defaultListNotificationsParams = {
    showDeleted: false,
    showOpened: true
};
const NotificationsContainer = ({ onClose, unreadNotificationsCount, fetchListNotifications, featuredDefinitionIds, className }) => {
    const { t, language } = useI18n();
    const [page, setPage] = useState(1);
    const [showOpened, setShowOpened] = useState(true);
    const [config, setConfig] = useState({});
    const { displayData } = useEntityDisplayData(CONTACT_CMS_DEFINITION_ID);
    const fetchListParams = useMemo(() => {
        return { ...defaultListNotificationsParams, showOpened };
    }, [showOpened]);
    const notifications = useSelector((s) => getUserNotificationsByPage(s, fetchListParams, page));
    const { pending, totalCount: notificationsCount } = useSelector((s) => getUserNotificationsListStatus(s, fetchListParams));
    const dispatchConfig = useDispatch();
    const dispatchAction = useDispatch();
    useEffect(() => {
        let mounted = true;
        dispatchConfig(fetchPersonalConfig())
            .then((data) => {
            if (data && mounted) {
                setConfig(data);
            }
        })
            .catch(() => {
            console.error('Error personal configs');
        });
        return () => {
            mounted = false;
        };
    }, [dispatchConfig]);
    useEffect(() => {
        void fetchListNotifications(page, showOpened);
    }, [fetchListNotifications, page, showOpened]);
    const updateConfig = useCallback(async (subscriptions) => {
        const newConfig = {
            ...config,
            subscriptions: {
                ...config.subscriptions,
                ...subscriptions
            }
        };
        const data = await dispatchConfig(savePersonalConfig(newConfig));
        if (data) {
            setConfig(data);
        }
    }, [config, dispatchConfig]);
    const onChangeEmailFieldsSettings = useCallback(async (newFields) => {
        await updateConfig({
            settings: { EMAIL: newFields }
        });
    }, [updateConfig]);
    const toggleEmailSubscription = useCallback(async () => {
        const value = !config.subscriptions?.taggedNotifications?.EMAIL;
        await updateConfig({
            taggedNotifications: { EMAIL: value },
            activityTaskReminder: { EMAIL: value }
        });
    }, [config.subscriptions?.taggedNotifications?.EMAIL, updateConfig]);
    const onPageChange = useCallback((newPage) => {
        setPage(newPage);
    }, []);
    const toggleShowOpened = useCallback(() => {
        setShowOpened(!showOpened);
        onPageChange(1);
    }, [onPageChange, showOpened]);
    const onMarkAllAsRead = useCallback(async () => {
        await dispatchAction(markAllNotificationsAsRead());
    }, [dispatchAction]);
    const updateNotification = useCallback(async (id, action) => {
        await dispatchAction(updateUserNotification(id, action));
    }, [dispatchAction]);
    const fieldEmailSettings = useMemo(() => {
        return config.subscriptions?.settings?.EMAIL || [];
    }, [config.subscriptions?.settings?.EMAIL]);
    const isEmailSubscription = useMemo(() => {
        return !!config?.subscriptions?.taggedNotifications?.EMAIL;
    }, [config.subscriptions?.taggedNotifications]);
    const contactEmailProperties = useMemo(() => {
        return (displayData?.data || []).reduce((res, section) => {
            res.push(...section.values
                .filter((prop) => prop.type == 'email')
                .map((prop) => ({
                id: prop.propertyId,
                label: getTranslation(prop.labels, language)
            })));
            return res;
        }, []);
    }, [displayData?.data, language]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, { className: className, loading: isEmpty(notifications) && pending },
            React.createElement(DrawerContentBlock, null,
                React.createElement(DrawerContentBlockTitle, { className: "title", title: React.createElement(React.Fragment, null,
                        t('NOTIFICATIONS'),
                        unreadNotificationsCount !== 0 && (React.createElement("span", { className: "unreadCount" }, t('NOTIFICATIONS_UNREAD', { smart_count: unreadNotificationsCount })))) }),
                React.createElement("div", { className: "d-flex justify-content-between align-items-center flex-wrap" },
                    React.createElement("div", { className: "d-flex align-items-center gap-3" },
                        React.createElement(Switch, { size: "small", onChange: toggleShowOpened, checked: !showOpened }),
                        React.createElement("label", { onClick: toggleShowOpened }, t('NOTIFICATIONS_ONLY_UNREAD'))),
                    React.createElement("div", { className: "d-flex align-items-center gap-3" },
                        React.createElement(Switch, { size: "small", onChange: toggleEmailSubscription, checked: isEmailSubscription }),
                        isEmailSubscription && (React.createElement(NotificationsSetEmailFields, { fieldList: contactEmailProperties, onChange: onChangeEmailFieldsSettings, value: fieldEmailSettings })),
                        React.createElement("label", { onClick: toggleEmailSubscription }, t('NOTIFICATIONS_RECEIVE_BY_EMAIL'))),
                    unreadNotificationsCount > 0 && (React.createElement(Button, { type: "text", disabled: pending, onClick: onMarkAllAsRead }, t('NOTIFICATIONS_MARK_ALL_AS_READ'))))),
            React.createElement(DrawerContentBlock, null, isEmpty(notifications) ? (!pending && React.createElement("div", { className: "noNotificationsText" }, t('NO_NOTIFICATIONS'))) : (React.createElement(React.Fragment, null, notifications.map((notification, index) => (React.createElement(Notification, { key: index, notification: notification, featuredDefinitionIds: featuredDefinitionIds, onClick: onClose, onChange: updateNotification }))))))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFooterBlock, null,
                React.createElement(Button, { type: "text", className: "mt-2 form-action-button fw-600 cancel", onClick: onClose }, t('COMMON__MODAL_CLOSE')),
                React.createElement(TablePagination, { totalCount: notificationsCount, pageSize: INITIAL_PAGE_SIZE, page: page, onPageChange: onPageChange })))));
};
export default NotificationsContainer;
