import React from 'react';
import { PropertyTypes } from '../../../../../../common/types';
import { PropertySelector } from '../../../components/PropertySelector';
const propertyToComparisonItem = (property) => {
    const item = {
        field: property.type === PropertyTypes.entity ? `${property.propertyId}.title` : property.propertyId,
        propertyId: property.propertyId,
        labels: property.labels
    };
    return item;
};
const ComparisonPropertiesSelector = (props) => {
    return React.createElement(PropertySelector, { ...props, valueAdapter: propertyToComparisonItem });
};
export default ComparisonPropertiesSelector;
