import React from 'react';
import { FaQuestion } from 'react-icons/fa';
import { useDisplayTenant } from '../../../../../../../tenant/useDisplayTenant';
import { getPropertyTypeComponent } from '../../../../../../../workflows/meta/components/meta-definition-form/utils';
import { getDisplayComponentForType } from '../../../../item-property/utils';
import styles from '../KanbanCard.module.scss';
export const CardFields = ({ fields, card }) => {
    const { tenantTheme: { hidePropertyIcon } } = useDisplayTenant();
    return (React.createElement("div", { className: styles.card_fields }, fields
        .filter(({ id }) => card[id] !== undefined && card[id] !== null)
        .map(({ type, id, label, meta }) => {
        const Component = type ? getDisplayComponentForType(type) : null;
        const Icon = getPropertyTypeComponent(type) || FaQuestion;
        return (React.createElement("div", { className: `${styles.card_fieldWrapper} d-flex flex-column align-items-stretch`, key: id },
            React.createElement("div", { className: `${styles.card_fieldLabelGroup} d-flex align-items-start justify-content-start` },
                hidePropertyIcon ? null : (React.createElement("div", { className: `${styles.card_fieldLabelIcon} d-flex align-items-center justify-content-start` },
                    React.createElement(Icon, null))),
                React.createElement("div", { className: `${styles.card_fieldLabelText} fw-500` }, label)),
            React.createElement("div", { className: `${styles.card_fieldGroup} d-flex align-items-start` }, Component ? (React.createElement(Component, { className: `kanbanField ${hidePropertyIcon ? 'ps-0' : ''}`, value: card[id], meta: meta })) : (React.createElement("div", { className: styles.card_fieldValue }, card[id] ?? '—')))));
    })));
};
