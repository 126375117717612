import { isObject } from 'lodash';
import { PropertyTypes } from '../../common/types';
export var EntityListDisplayView;
(function (EntityListDisplayView) {
    EntityListDisplayView["table"] = "table";
    EntityListDisplayView["selectBox"] = "selectBox";
})(EntityListDisplayView || (EntityListDisplayView = {}));
export var EntitySingleDisplayView;
(function (EntitySingleDisplayView) {
    EntitySingleDisplayView["selectBox"] = "selectBox";
    EntitySingleDisplayView["expandedView"] = "expandedView";
})(EntitySingleDisplayView || (EntitySingleDisplayView = {}));
export const isPropertyItemMeta = (data) => {
    return typeof data === 'object' && data !== null && 'definitionId' in data;
};
export const isTextMeta = (meta) => typeof meta === 'object' && !!meta && 'multilanguage' in meta && 'multiline' in meta;
export const isTextDisplayData = (data) => data &&
    'type' in data &&
    (data.type === PropertyTypes.text || data.type === PropertyTypes.multitext);
export const isFileDisplayData = (field) => field && 'type' in field && field.type === PropertyTypes.file;
export const isFileValue = (value) => isObject(value) && 's3Location' in value;
export const isFileArrayValue = (value) => Array.isArray(value) && value.every(isFileValue);
export const isEntityDisplayData = (data) => {
    if (typeof data !== 'object' || data === null) {
        return false;
    }
    return 'type' in data && data.type === PropertyTypes.entity;
};
