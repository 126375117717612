const doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
const prefix = {
    xmlns: 'http://www.w3.org/2000/xmlns/',
    xlink: 'http://www.w3.org/1999/xlink',
    svg: 'http://www.w3.org/2000/svg'
};
const excludeStyles = ['cursor'];
const setInlineStyles = (svg, emptySvgDeclarationComputed) => {
    function explicitlySetStyle(element) {
        const cSSStyleDeclarationComputed = getComputedStyle(element);
        let i;
        let len;
        let key;
        let value;
        let computedStyleStr = '';
        for (i = 0, len = cSSStyleDeclarationComputed.length; i < len; i++) {
            key = cSSStyleDeclarationComputed[i];
            value = cSSStyleDeclarationComputed.getPropertyValue(key);
            if ((!excludeStyles.includes(key) &&
                value !== emptySvgDeclarationComputed.getPropertyValue(key)) ||
                key === 'font-family') {
                computedStyleStr += key + ':' + value + ';';
            }
        }
        element.setAttribute('style', computedStyleStr + 'white-space:normal;');
    }
    function traverse(obj) {
        const tree = [];
        // @ts-expect-error
        tree.push(obj);
        visit(obj);
        function visit(node) {
            if (node && node.hasChildNodes()) {
                let child = node.firstChild;
                while (child) {
                    if (child.nodeType === 1 && child.nodeName != 'SCRIPT') {
                        // @ts-expect-error
                        tree.push(child);
                        visit(child);
                    }
                    child = child.nextSibling;
                }
            }
        }
        return tree;
    }
    // hardcode computed css styles inside svg
    const allElements = traverse(svg);
    let i = allElements.length;
    while (i--) {
        explicitlySetStyle(allElements[i]);
    }
};
const getSvgInfo = (svg) => {
    const emptySvg = window.document.createElementNS(prefix.svg, 'svg');
    window.document.body.appendChild(emptySvg);
    const emptySvgDeclarationComputed = getComputedStyle(emptySvg);
    svg.setAttribute('version', '1.1');
    // removing attributes so they aren't doubled up
    svg.removeAttribute('xmlns');
    svg.removeAttribute('xlink');
    // These are needed for the svg
    if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns')) {
        svg.setAttributeNS(prefix.xmlns, 'xmlns', prefix.svg);
    }
    if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns:xlink')) {
        svg.setAttributeNS(prefix.xmlns, 'xmlns:xlink', prefix.xlink);
    }
    setInlineStyles(svg, emptySvgDeclarationComputed);
    const source = new XMLSerializer().serializeToString(svg);
    const rect = svg.getBoundingClientRect();
    return {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        class: svg.getAttribute('class'),
        id: svg.getAttribute('id'),
        name: svg.getAttribute('name'),
        childElementCount: svg.childElementCount,
        source: [doctype + source],
        cleanup: () => {
            window.document.body?.removeChild(emptySvg);
        }
    };
};
const download = (source) => {
    let filename = 'untitled';
    if (source.name) {
        filename = source.name;
    }
    else if (source.id) {
        filename = source.id;
    }
    else if (source.class) {
        filename = source.class;
    }
    else if (window.document.title) {
        filename = window.document.title.replace(/[^a-z0-9]/gi, '-').toLowerCase();
    }
    const url = (window.URL || window.webkitURL).createObjectURL(new Blob(source.source, { type: 'text/xml' }));
    const a = window.document.createElement('a');
    window.document.body.appendChild(a);
    a.setAttribute('class', 'svg-crowbar');
    a.setAttribute('download', filename + '.svg');
    a.setAttribute('href', url);
    a.style['display'] = 'none';
    a.click();
    window.URL.revokeObjectURL(url);
    window.document.body?.removeChild(a);
    source.cleanup();
};
export const exportSvg = (svg) => download(getSvgInfo(svg));
