import { TENANT_ID } from 'config';
export const fetchProperty = (definitionId, propertyId) => ({
    type: 'METADATA__FETCH_PROPERTY',
    payload: {
        definitionId,
        propertyId
    },
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/properties/${definitionId}/${propertyId}`,
            method: 'GET',
            actionContext: {
                definitionId,
                propertyId
            }
        },
        auth: true
    }
});
export const fetchProperties = (definitionId) => ({
    type: 'METADATA__FETCH_DEFINITION_PROPERTIES',
    payload: {
        definitionId
    },
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/properties/${definitionId}`,
            method: 'GET',
            actionContext: {
                definitionId
            },
            allowPromiseReject: true
        },
        auth: true
    }
});
export const deleteProperty = (definitionId, id) => ({
    type: 'METADATA__DEFINITION_PROPERTIES_DELETE',
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/properties/${definitionId}/${id}`,
            method: 'DELETE',
            actionContext: {
                definitionId,
                id
            },
            allowPromiseReject: true
        },
        auth: true
    }
});
export const saveProperty = (definitionId, id, values) => ({
    type: 'METADATA__DEFINITION_PROPERTY_SAVE',
    payload: values,
    meta: {
        http: {
            path: `/api/metadata/${TENANT_ID}/properties/${definitionId}/${id || ''}`,
            method: id ? 'PUT' : 'POST',
            actionContext: {
                values
            },
            allowPromiseReject: true
        },
        auth: true
    }
});
