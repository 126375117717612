import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../../../../auth';
import TemplatesRoutes from '../../../templates/components/Routes';
import { EMAIL_TEMPLATES_PATH } from '../../../templates/constants';
const RestrictedMarketingRoutes = ({ match: { url } }) => (React.createElement(ErrorBoundary, null,
    React.createElement(Switch, null,
        React.createElement(Route, { path: `${url}${EMAIL_TEMPLATES_PATH}`, component: TemplatesRoutes }),
        React.createElement(Redirect, { to: `${url}${EMAIL_TEMPLATES_PATH}` }))));
export default redirectIfNoPermission(RestrictedMarketingRoutes);
