import { isGTE } from '@hypercharge/hyper-react-base/lib/browser';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { deleteOptionGroup, didFetchAllOptionGroupsFail, fetchAllOptionGroups, getAllOptionGroups, getIsFetchAllOptionGroupsPending } from '@hypercharge/hyper-react-base/lib/metadata';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getBrowserState, getMetadataState } from '../../../../common/reducers/rootSelector';
import OptionGroups from './OptionGroups';
class OptionGroupsContainer extends Component {
    componentDidMount() {
        this.props.fetchAllOptionGroups();
    }
    render() {
        const { optionGroups, isPending, deleteGroup, isMobile } = this.props;
        const sortedOptionGroups = sortBy(optionGroups, ['groupId']);
        return (React.createElement(OptionGroups, { optionGroups: sortedOptionGroups, deleteGroup: deleteGroup, isPending: isPending, isMobile: isMobile }));
    }
}
function mapStateToProps(state) {
    const metadataState = getMetadataState(state);
    return {
        optionGroups: getAllOptionGroups(metadataState),
        isPending: getIsFetchAllOptionGroupsPending(metadataState),
        failed: didFetchAllOptionGroupsFail(metadataState),
        isMobile: !isGTE(getBrowserState(state), 'sm')
    };
}
function mapDispatchToProps(dispatch, { t }) {
    return {
        fetchAllOptionGroups: () => {
            void dispatch(fetchAllOptionGroups());
        },
        deleteGroup: (optionGroupId) => {
            return (dispatch(deleteOptionGroup(optionGroupId))
                // @ts-expect-error
                .then(() => {
                void dispatch(success({
                    title: t('SUCCESS'),
                    message: t('OPTION_GROUP__DELETE_SUCCESS')
                }));
            })
                .catch((e) => {
                void dispatch(error({
                    title: t('ERROR'),
                    message: t('OPTION_GROUP__DELETE_FAIL')
                }));
            }));
        }
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(withProps(() => {
    const { t } = useI18n();
    return { t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(OptionGroupsContainer);
