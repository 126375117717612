import memoize from 'fast-memoize';
import { difference, find, get, isEmpty, values } from 'lodash';
import { PROCESS_META_HANDLER } from '../../common/utils/constants';
import { getDataState } from '../common/reducers/selectors';
import { TITLE_PROPERTY_ID } from '../common/utils/constants';
import { getItemKey, getPropertyKey } from './reducer';
// ////////////////////
// Items
// ////////////////////
export const getItem = (s, definitionId, id) => {
    // @ts-expect-error
    return getDataState(s)?.items?.byId?.[definitionId]?.[id];
};
export const isItemAvailable = (s, definitionId, id, responseFields = [TITLE_PROPERTY_ID]) => {
    const status = getDataState(s)?.items?.status?.[definitionId]?.[id] || null;
    const currentResponseFields = get(status, 'responseFields', [TITLE_PROPERTY_ID]);
    let allRequiredResponseFieldsAreCovered = false;
    if (currentResponseFields.includes('*')) {
        allRequiredResponseFieldsAreCovered = true;
    }
    else if (isEmpty(difference(responseFields, currentResponseFields))) {
        allRequiredResponseFieldsAreCovered = true;
    }
    return (allRequiredResponseFieldsAreCovered &&
        (!!getItem(s, definitionId, id) ||
            (status != null && (status.lastFetch != null || status.pending))));
};
const getItemsValuesFromMap = memoize((itemsMapForDefinitionId) => itemsMapForDefinitionId ? values(itemsMapForDefinitionId) : []);
export const getItemsByDefinitionId = (s, definitionId) => {
    const itemsMapForDefinitionId = getDataState(s).items.byId[definitionId];
    return getItemsValuesFromMap(itemsMapForDefinitionId);
};
export const isItemPending = (s, definitionId, id) => !!get(getDataState(s), ['items', 'status', definitionId, id, 'pending'], false);
export const isItemFailed = (s, definitionId, id) => !!get(getDataState(s), ['items', 'status', definitionId, id, 'failed'], false);
// ////////////////////
// Item properties
// ////////////////////
const getPropertiesState = (s) => getDataState(s).properties.properties;
// properties
export const getProperty = (s, definitionId, entityId, propertyId) => get(getPropertiesState(s), ['byId', getPropertyKey(definitionId, entityId, propertyId)]);
// ////////////////////
// Item referrals
// ////////////////////
const getItemReferralsState = (s) => getDataState(s).itemReferrals;
export const getItemReferrals = (s, definitionId, entityId, displayItemMetaList) => get(getItemReferralsState(s), ['byId', getItemKey(definitionId, entityId)], []).map((itemReferral) => ({
    ...itemReferral,
    isProcess: get(find(displayItemMetaList, { definitionId: itemReferral.definitionId }), 'handler') ===
        PROCESS_META_HANDLER
}));
