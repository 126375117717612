import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import Indicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Modal, Tooltip } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { FaBars, FaSync } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { CMS_PATH, updateEntityData } from '../../../cms';
import QuickEditItem from '../../../cms/data/components/item/QuickEditItem';
import useDeleteItems from '../../../cms/hooks/useDeleteItems';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { CONDITION_QUERY_KEY, getUpdatedLocation, stringifyConditionQuery } from '../../../views';
import { Option, Select } from '../../components/Select';
import Toggle from '../../components/Toggle';
import { FullTextSearchBase } from '../../components/data-table/FullTextSearch';
import { BROWSE_PATH } from '../../utils/constants';
import queryClient from '../../utils/react-query-client';
import { addToCache, deleteFromCache, findCacheIndex } from '../cacheUtils';
import { ACTIVITY_DEFINITION_ID } from '../constants';
import { ActivityStatus } from '../types';
import { ACTIVITY_QUERY_CACHE_KEY, useCompletedActivity, useUpcomingActivity } from '../useActivities';
import styles from './ActivityContainer.module.scss';
import ActivityList from './ActivityList';
const FETCH_UPCOMING_PAGE_SIZE = 3;
const FETCH_COMPLETED_PAGE_SIZE = 10;
const ActivityContainer = ({ className, definitionId, entityId, extendedFilter = false, fromTime, toTime, noActivityMessage }) => {
    const { t, language } = useI18n();
    const refreshTimer = useRef();
    const [openEntity, setOpenEntity] = useState();
    const [showQuickEditItem, setShowQuickEditItem] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [quickFilter, setQuickFilter] = useState(extendedFilter ? 'my' : undefined);
    const [activitiesActiveKeys, setActivitiesActiveKeys] = useState({
        upcoming: [],
        completed: []
    });
    const { mutateAsync: activityDeleteItems } = useDeleteItems({
        definitionId: ACTIVITY_DEFINITION_ID
    });
    const dispatch = useDispatch();
    const dispatchUpdateProperty = useDispatch();
    const myContact = useMyContact();
    const { data: activityItemMeta } = useDisplayItemMeta({ definitionId: ACTIVITY_DEFINITION_ID });
    const conditionalQuery = useMemo(() => {
        return buildQueryByQuickFilter(quickFilter, fromTime, toTime, myContact.contactId);
    }, [fromTime, myContact.contactId, quickFilter, toTime]);
    const [completedActivities, completedQueryCacheKey] = useCompletedActivity({
        definitionId,
        entityId,
        conditionalQuery,
        searchText,
        limit: FETCH_COMPLETED_PAGE_SIZE,
        onError(err) {
            console.log(err);
            dispatch(error({
                title: t('ERROR'),
                message: t('ACTIVITY_FETCH_COMPLETED_TASKS_FAIL')
            }));
        }
    });
    const [upcomingActivities, upcomingQueryCacheKey] = useUpcomingActivity({
        definitionId,
        entityId,
        conditionalQuery,
        searchText,
        limit: FETCH_UPCOMING_PAGE_SIZE,
        onError(err) {
            console.log(err);
            dispatch(error({
                title: t('ERROR'),
                message: t('ACTIVITY_FETCH_UPCOMING_TASKS_FAIL')
            }));
        }
    });
    const onRefresh = useCallback(async () => {
        await queryClient.refetchQueries([
            ...ACTIVITY_QUERY_CACHE_KEY,
            definitionId || '',
            entityId || ''
        ]);
    }, [definitionId, entityId]);
    const refreshWithDelay = useCallback(() => {
        if (refreshTimer.current) {
            clearTimeout(refreshTimer.current);
            refreshTimer.current = undefined;
        }
        refreshTimer.current = setTimeout(() => {
            void onRefresh();
        }, 1000);
    }, [onRefresh]);
    const onUpdate = useCallback(async (activity) => {
        try {
            await dispatchUpdateProperty(updateEntityData(ACTIVITY_DEFINITION_ID, activity.entityId, {
                definitionId: ACTIVITY_DEFINITION_ID,
                entityId: activity.entityId,
                propertyId: 'statusItem',
                value: activity.statusItem
            }, language));
            /**
             * update status in cache
             * changes may not be immediately available on the backend
             **/
            if (activity.statusItem === ActivityStatus.NotStarted) {
                if (upcomingActivities.data) {
                    const upcomingPos = findCacheIndex(upcomingActivities.data, activity);
                    if (upcomingPos.itemIndex === -1) {
                        // addToCache(upcomingQueryCacheKey, activity);
                        deleteFromCache(completedQueryCacheKey, activity);
                        // void upcomingActivities.refetch();
                    }
                }
            }
            else if (activity.statusItem === ActivityStatus.Completed) {
                if (completedActivities.data) {
                    const completedPos = findCacheIndex(completedActivities.data, activity);
                    if (completedPos.itemIndex === -1) {
                        deleteFromCache(upcomingQueryCacheKey, activity);
                        addToCache(completedQueryCacheKey, activity);
                    }
                }
            }
            refreshWithDelay();
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('ACTIVITY_UPDATE_STATUS_FAIL')
            }));
            throw err;
        }
    }, [
        completedActivities.data,
        completedQueryCacheKey,
        dispatch,
        dispatchUpdateProperty,
        language,
        refreshWithDelay,
        t,
        upcomingActivities.data,
        upcomingQueryCacheKey
    ]);
    const onDelete = useCallback((activity) => {
        Modal.confirm({
            className: 'delete-modal',
            content: t('ACTIVITY_DELETE_MESSAGE'),
            okText: t('MESSAGE_DELETE'),
            cancelText: t('COMMON__NO'),
            onOk: async () => {
                try {
                    await activityDeleteItems({
                        ids: [activity.entityId]
                    });
                    if (activity.statusItem === ActivityStatus.Completed) {
                        deleteFromCache(completedQueryCacheKey, activity);
                    }
                    else {
                        deleteFromCache(upcomingQueryCacheKey, activity);
                    }
                    refreshWithDelay();
                }
                catch (err) {
                    dispatch(error({
                        title: t('ERROR'),
                        message: t('ACTIVITY_DELETE_FAIL')
                    }));
                    throw err;
                }
            },
            width: 400
        });
    }, [
        activityDeleteItems,
        completedQueryCacheKey,
        dispatch,
        refreshWithDelay,
        t,
        upcomingQueryCacheKey
    ]);
    const onEdit = useCallback((activity) => {
        setOpenEntity({
            definitionId: ACTIVITY_DEFINITION_ID,
            entityId: activity.entityId
        });
        setShowQuickEditItem(true);
    }, []);
    const loadNextPage = useCallback(async (section) => {
        if (section === 'completed') {
            await completedActivities.fetchNextPage();
        }
        else {
            await upcomingActivities.fetchNextPage();
        }
    }, [completedActivities, upcomingActivities]);
    const onCloseQuickEditItem = useCallback(() => {
        setShowQuickEditItem(false);
        void onRefresh();
    }, [onRefresh]);
    const onChangeShowOnlyMyTasks = useCallback((checked) => {
        setQuickFilter(checked ? 'my' : undefined);
    }, []);
    const onClickExpandAll = useCallback(() => {
        const activeKeys = {
            upcoming: [],
            completed: []
        };
        if (upcomingActivities.data?.pages.length) {
            for (const page of upcomingActivities.data.pages) {
                for (const activity of page.results) {
                    activeKeys.upcoming.push(activity.entityId);
                }
            }
        }
        if (completedActivities.data?.pages.length) {
            for (const page of completedActivities.data.pages) {
                for (const activity of page.results) {
                    activeKeys.completed.push(activity.entityId);
                }
            }
        }
        setActivitiesActiveKeys(activeKeys);
    }, [completedActivities.data?.pages, upcomingActivities.data?.pages]);
    const onClickCollapseAll = useCallback(() => {
        const activeKeys = {
            upcoming: [],
            completed: []
        };
        setActivitiesActiveKeys(activeKeys);
    }, []);
    const onClickOpenAllTasks = useCallback(() => {
        const viewAllUrl = {
            pathname: `${CMS_PATH}/${ACTIVITY_DEFINITION_ID}${BROWSE_PATH}`,
            ...getUpdatedLocation({
                [CONDITION_QUERY_KEY]: stringifyConditionQuery(conditionalQuery)
            })
        };
        dispatch(push(viewAllUrl));
    }, [conditionalQuery, dispatch]);
    if (!myContact.contactId) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: "d-flex mt-4 mx-3 control-panel" },
            !extendedFilter ? (React.createElement("div", { className: "flex-grow-1 align-self-center" },
                React.createElement(Toggle, { className: "me-2", checked: quickFilter === 'my', onChange: onChangeShowOnlyMyTasks, label: t('SHOW_ONLY_MY_TASKS') }))) : (React.createElement("div", { className: "flex-grow-1 align-self-center" },
                React.createElement("div", { className: "d-flex flex-row" },
                    React.createElement(Select, { onChange: setQuickFilter, value: quickFilter, className: "quick-filter-select me-2", popupMatchSelectWidth: false, size: "large" },
                        React.createElement(Option, { value: "my", key: "my" }, t('ACTIVITY_MY_TASKS')),
                        React.createElement(Option, { value: "overdue", key: "overdue" }, t('ACTIVITY_OVERDUE_TASKS')),
                        React.createElement(Option, { value: "completed", key: "completed" }, t('ACTIVITY_COMPLETED_TASKS')),
                        React.createElement(Option, { value: "delegated", key: "delegated" }, t('ACTIVITY_DELEGATED_TASKS'))),
                    React.createElement(FullTextSearchBase, { value: searchText, onChange: setSearchText })))),
            React.createElement("div", { className: "d-flex flex-row align-self-center right-buttons" },
                React.createElement(Tooltip, { title: t('ACTIVITY_REFRESH_BTN_TOOLTIP') },
                    React.createElement(Button, { type: "button", className: "me-2 action-button", inversed: true, onClick: onRefresh },
                        React.createElement(FaSync, null))),
                React.createElement(Tooltip, { title: t('ACTIVITY_EXPAND_BTN_TOOLTIP') },
                    React.createElement(Button, { type: "button", className: "me-2 action-button", inversed: true, onClick: onClickExpandAll },
                        React.createElement(BsArrowsExpand, null))),
                React.createElement(Tooltip, { title: t('ACTIVITY_COLLAPSE_BTN_TOOLTIP') },
                    React.createElement(Button, { type: "button", className: "action-button", inversed: true, onClick: onClickCollapseAll },
                        React.createElement(BsArrowsCollapse, null))),
                extendedFilter ? (React.createElement(Tooltip, { title: t('ACTIVITY_VIEW_ALL_BTN_TOOLTIP') },
                    React.createElement(Button, { type: "button", className: "ms-2 action-button", inversed: true, onClick: onClickOpenAllTasks },
                        React.createElement(FaBars, null)))) : null)),
        React.createElement("div", { className: "p-3 activity-list" }, upcomingActivities?.status === 'loading' && completedActivities?.status === 'loading' ? (React.createElement(Indicator, { className: "mt-5" })) : (React.createElement(React.Fragment, null, !upcomingActivities?.data?.pages[0]?.results.length &&
            !completedActivities?.data?.pages[0]?.results.length ? (React.createElement(React.Fragment, null, noActivityMessage || (React.createElement("div", { className: "p-3 px-4 text-center no-activity-txt fw-600" }, t('ACTIVITY_NO_ACTIVITIES_SHORT'))))) : (React.createElement(ActivityList, { upcomingActivities: upcomingActivities, completedActivities: completedActivities, definitionId: definitionId, onUpdate: onUpdate, onDelete: activityItemMeta?.canDelete ? onDelete : undefined, loadNextPage: loadNextPage, onEdit: onEdit, onChangeActiveKeys: setActivitiesActiveKeys, activeKeys: activitiesActiveKeys, ownerEntityId: entityId }))))),
        openEntity && (React.createElement(QuickEditItem, { definitionId: openEntity.definitionId, entityId: openEntity.entityId, open: showQuickEditItem, onClose: onCloseQuickEditItem }))));
};
export default ActivityContainer;
export function buildQueryByQuickFilter(quickFilter, fromTime, toTime, myContactId) {
    const query = {
        condition: ConditionType.and,
        filters: []
    };
    let intervalPropertyId = 'createdAt';
    if (quickFilter === 'completed') {
        intervalPropertyId = 'statusUpdatedAt';
        query.filters.push({
            field: 'statusItem.entityId',
            operator: FilterOperatorTypes.is,
            data: ActivityStatus.Completed
        });
    }
    else if (quickFilter === 'delegated') {
        query.filters.push({
            field: 'assignedTo.entityId',
            operator: FilterOperatorTypes.isNot,
            data: myContactId || 'noContactId'
        }, {
            field: 'entityOwner.entityId',
            operator: FilterOperatorTypes.is,
            data: myContactId || 'noContactId'
        });
    }
    else if (quickFilter === 'overdue') {
        query.filters.push({
            field: 'dueDate',
            operator: FilterOperatorTypes.lessThan,
            data: Date.now()
        }, {
            field: 'statusItem.entityId',
            operator: FilterOperatorTypes.isNot,
            data: ActivityStatus.Completed
        });
    }
    else if (quickFilter === 'my') {
        query.filters.push({
            field: 'assignedTo.entityId',
            operator: FilterOperatorTypes.is,
            data: myContactId || 'noContactId'
        });
    }
    if (intervalPropertyId) {
        if (fromTime) {
            query.filters.push({
                field: intervalPropertyId,
                operator: FilterOperatorTypes.greaterThan,
                data: fromTime
            });
        }
        if (toTime) {
            query.filters.push({
                field: intervalPropertyId,
                operator: FilterOperatorTypes.lessThan,
                data: toTime
            });
        }
    }
    return query;
}
