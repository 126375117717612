import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IoReorderThree } from 'react-icons/io5';
import styles from './SortableList.module.scss';
const SortableList = ({ className = '', value, onChange, sortable = true, disabled, extraAction: ExtraAction }) => {
    const onDragEnd = useCallback((result) => {
        if (!onChange || !result.destination) {
            return;
        }
        const newValue = [...value];
        const [removed] = newValue.splice(result.source.index, 1);
        newValue.splice(result.destination.index, 0, removed);
        onChange(newValue);
    }, [onChange, value]);
    const onClickDelete = useCallback((id) => {
        if (!onChange) {
            return;
        }
        const newValue = value.filter((item) => item.id !== id);
        onChange(newValue);
    }, [onChange, value]);
    if (!value.length) {
        return null;
    }
    return (React.createElement(DragDropContext, { onDragEnd: onDragEnd },
        React.createElement(Droppable, { droppableId: "itemsId", isDropDisabled: disabled, type: "ITEMS" }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: `${styles.draggableList} mb-2 droppable-item d-flex align-items-stretch flex-column ${droppableSnapshot.isDraggingOver ? 'dragging-over' : ''} ${className}`, ref: droppableProvided.innerRef },
            value.map((item, itemIndex) => {
                return (React.createElement(Draggable, { draggableId: item.id, index: itemIndex, key: item.id, isDragDisabled: item.disabled }, (draggableProvided, draggableSnapshot) => {
                    return (React.createElement("div", { ref: draggableProvided.innerRef, className: `${styles.draggableItem} w-100 d-flex flex-nowrap flex-row align-items-start align-items-center drag-item my-1 ${draggableSnapshot.isDragging ? 'dragging-item' : ''}`, ...draggableProvided.draggableProps },
                        React.createElement("div", { className: "overflow-hidden label" }, item.label),
                        React.createElement("div", { className: "ms-auto d-flex align-items-center actions" },
                            ExtraAction && React.createElement(ExtraAction, { item: item }),
                            React.createElement("div", { className: "ms-2 d-flex align-items-center" },
                                React.createElement(DeleteConfirmationButton, { onClick: () => onClickDelete(item.id), deleteIconSize: 20 })),
                            React.createElement("div", { className: "drag-handler d-flex align-items-center", ...draggableProvided.dragHandleProps }, sortable && React.createElement(IoReorderThree, { size: "20" })))));
                }));
            }),
            React.createElement(React.Fragment, null, droppableProvided.placeholder))))));
};
export default SortableList;
