import posed from 'react-pose';
const MAIL_STATUS_DELIVERY = 'Delivery';
const MAIL_STATUS_ERRORS = ['Bounce', 'Complaint', 'Reject', 'Rendering Failure', 'DeliveryDelay'];
export const Item = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 }
});
export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
export const getEmailSendingResult = (mailStatuses) => {
    if (!mailStatuses) {
        // ? This task type is NOT email
        return;
    }
    const defaultEmailResult = { success: 0, warn: 0, fail: 0 };
    const totalMailsResult = mailStatuses.reduce((acc, status) => {
        acc[status.messageId] = acc[status.messageId] || { success: 0, fail: 0 };
        if (status.status === MAIL_STATUS_DELIVERY) {
            acc[status.messageId].success++;
        }
        else if (MAIL_STATUS_ERRORS.includes(status.status)) {
            acc[status.messageId].fail++;
        }
        return acc;
    }, {});
    return Object.values(totalMailsResult).reduce((acc, item) => {
        if (item.success && item.fail) {
            acc.warn++;
        }
        else if (item.success) {
            acc.success++;
        }
        else if (item.fail) {
            acc.fail++;
        }
        return acc;
    }, defaultEmailResult);
};
