import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { getDashboardUrl } from './actions';
const Dashboard = ({ dashboardId, height, ...props }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [awsDashboardObject, setAwsDashboardObject] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [loading, setLoading] = useState(true);
    //
    // Get dashboard url from QuickSight
    useEffect(() => {
        if (dashboardId) {
            setLoading(true);
            dispatch(getDashboardUrl(dashboardId))
                // @ts-expect-error
                .then((dashboard) => setDashboard(dashboard))
                .catch((e) => {
                dispatch(error({
                    title: t('ERROR'),
                    message: e.message
                }));
            })
                .finally(() => setLoading(false));
        }
    }, []);
    //
    // Init board by quicksight library when active board changed
    useEffect(() => {
        if (dashboard) {
            const options = {
                container: `#${props.id}`,
                footerPaddingEnabled: false
            };
            if (awsDashboardObject === null) {
                options['url'] = dashboard.url;
                setAwsDashboardObject(embedDashboard(options));
            }
            else {
                options['dashboardId'] = dashboardId;
                awsDashboardObject.navigateToDashboard(options);
            }
        }
    }, [dashboard, dashboardId]);
    return (React.createElement(BoardContainer, { id: props.id, height: height }, loading === true && React.createElement(LoadingIndicator, null)));
};
//
// Export component
export default Dashboard;
//
// Styles
const BoardContainer = styled.div `
  position: relative;
  height: ${(props) => props.height};
`;
