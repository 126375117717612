import { Button, ConfigProvider } from 'antd';
import React from 'react';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import styles from './DropdownDoubleArrow.module.scss';
const DropdownDoubleArrowButton = ({ className = '', onClick }) => {
    return (React.createElement(ConfigProvider, { wave: { disabled: true } },
        React.createElement(Button, { className: `btn-dropdown-double-arrow ${styles.wrapper} ${className}`, onClick: onClick },
            React.createElement(MdKeyboardDoubleArrowRight, null))));
};
export default DropdownDoubleArrowButton;
