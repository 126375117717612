import { BasicSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEqual, omit } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../common/components/Drawer/DrawerFormFooter';
export const moveStateToPath = (value) => {
    const path = [];
    if ('tabIndex' in value) {
        path.push(value.tabIndex, 'sections');
    }
    if ('sectionIndex' in value) {
        path.push(value.sectionIndex, 'properties');
    }
    return path;
};
const MoveDrawer = ({ value, title, open, onChange, onClose, tree }) => {
    const { t, language } = useI18n();
    const [newValue, setNewValue] = useState(() => {
        if (!value) {
            return value;
        }
        return { ...value, position: value.position + 1 };
    });
    useEffect(() => {
        setNewValue(value ? { ...value, position: value.position + 1 } : value);
    }, [value]);
    const onCloseDrawer = useCallback(() => {
        onClose();
    }, [onClose]);
    const onMove = useCallback(() => {
        if (newValue) {
            onChange({ ...newValue, position: newValue.position - 1 });
        }
        onCloseDrawer();
    }, [newValue, onChange, onCloseDrawer]);
    const onChangeTab = useCallback((event) => {
        setNewValue((prev) => {
            if (prev) {
                const newValue = {
                    ...prev,
                    position: 1
                };
                if ('tabIndex' in newValue) {
                    newValue.tabIndex = +event.target.value;
                }
                if ('sectionIndex' in newValue) {
                    let targetSectionPosition = 0;
                    const targetSection = tree[newValue.tabIndex].sections[targetSectionPosition];
                    if (targetSection && targetSection.id === 'system') {
                        targetSectionPosition = 1;
                    }
                    newValue.sectionIndex = targetSectionPosition;
                }
                return newValue;
            }
        });
    }, [tree]);
    const onChangeSection = useCallback((event) => {
        setNewValue((prev) => {
            if (prev) {
                return {
                    ...prev,
                    sectionIndex: +event.target.value,
                    position: 1
                };
            }
        });
    }, []);
    const onChangePosition = useCallback((event) => {
        setNewValue((prev) => {
            if (prev) {
                return {
                    ...prev,
                    position: +event.target.value
                };
            }
        });
    }, []);
    const tabOptions = useMemo(() => {
        return tree.map((tab, index) => {
            return {
                value: index.toString(),
                labels: {
                    [language]: tab.titles[language] || t('NO_TITLE')
                }
            };
        });
    }, [language, t, tree]);
    const sectionOptions = useMemo(() => {
        if (!newValue || !('tabIndex' in newValue) || !tree[newValue.tabIndex]?.sections.length) {
            return [];
        }
        return tree[newValue.tabIndex].sections.reduce((acc, section, index) => {
            if (section.id === 'system') {
                return acc;
            }
            acc.push({
                value: index.toString(),
                labels: {
                    [language]: section.titles[language] || t('NO_TITLE')
                }
            });
            return acc;
        }, []);
    }, [language, newValue, t, tree]);
    const positionOptions = useMemo(() => {
        if (!newValue) {
            return [];
        }
        let maxPositions;
        if ('sectionIndex' in newValue) {
            if (!tree[newValue.tabIndex].sections[newValue.sectionIndex]) {
                return [];
            }
            maxPositions = tree[newValue.tabIndex].sections[newValue.sectionIndex].properties.length;
        }
        else if ('tabIndex' in newValue) {
            maxPositions = tree[newValue.tabIndex].sections.length;
        }
        else {
            maxPositions = tree.length;
        }
        if (!isEqual(omit(value, 'position'), omit(newValue, 'position'))) {
            // if different tab or section
            maxPositions += 1;
        }
        if (maxPositions === 0) {
            maxPositions = 1;
        }
        const result = [];
        for (let index = 1; index <= maxPositions; index++) {
            result.push({
                value: index.toString(),
                labels: {
                    [language]: index.toString()
                }
            });
        }
        return result;
    }, [language, newValue, tree, value]);
    const isAvailableTabSelection = newValue && 'tabIndex' in newValue;
    const isAvailableSectionSelection = newValue && 'sectionIndex' in newValue;
    const isSectionDisabled = isAvailableSectionSelection && !sectionOptions.length;
    const isPositionDisabled = !positionOptions.length;
    const isSubmitDisabled = isSectionDisabled || isPositionDisabled;
    return (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { title: title, onClose: onCloseDrawer, open: open, destroyOnClose: true },
            React.createElement(DrawerContent, null,
                isAvailableTabSelection ? (React.createElement(DrawerContentBlock, { title: t('DEFINITION_FORM__MOVE__SELECT_TAB_LABEL') },
                    React.createElement(BasicSelect, { className: "w-100", value: newValue.tabIndex, onChange: onChangeTab, options: tabOptions, language: language }))) : null,
                isAvailableSectionSelection ? (React.createElement(DrawerContentBlock, { title: t('DEFINITION_FORM__MOVE__SELECT_SECTION_LABEL') },
                    React.createElement(BasicSelect, { className: "w-100", value: newValue.sectionIndex, onChange: onChangeSection, options: sectionOptions, language: language, disabled: isSectionDisabled }))) : null,
                React.createElement(DrawerContentBlock, { title: t('DEFINITION_FORM__MOVE__SELECT_POSITION_LABEL') },
                    React.createElement(BasicSelect, { className: "w-100", value: newValue?.position, onChange: onChangePosition, options: positionOptions, language: language, disabled: isPositionDisabled }))),
            React.createElement(DrawerFooter, null,
                React.createElement(DrawerFormFooter, { onSubmit: onMove, disabled: isSubmitDisabled, submitText: t('DEFINITION_FORM__MOVE__APPLY_BTN'), onCancel: onCloseDrawer })))));
};
export default MoveDrawer;
