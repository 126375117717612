import React, { useCallback } from 'react';
import SelectProperty from './SelectProperty';
const SelectPropertyButton = ({ value = null, className, disabled, label, definitionId, includeProperty, includeNestedProperties = true, isFieldDisabled, asFilter, onChange, error }) => {
    const _onChange = useCallback((value) => {
        if (!value) {
            return onChange(null);
        }
        onChange(Array.isArray(value) ? value[0] : value);
    }, [onChange]);
    return (React.createElement(SelectProperty, { className: `antd-tree-select-btn ${className || ''}`, disabled: disabled, placeholder: label, value: value, asFilter: asFilter, onChange: _onChange, definitionId: definitionId, includeProperty: includeProperty, includeNestedProperties: includeNestedProperties, isFieldDisabled: isFieldDisabled, error: error }));
};
export default SelectPropertyButton;
