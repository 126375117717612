import React from 'react';
import { SortableHandle as asSortableHandle } from 'react-sortable-hoc';
const SortableHandle = asSortableHandle(() => (React.createElement("div", { className: "drag-handle me-2" },
    React.createElement("svg", { width: "6", height: "18", viewBox: "0 0 6 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { y: "0.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { y: "15.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "0.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "5.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "10.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" }),
        React.createElement("rect", { x: "4", y: "15.5", width: "2", height: "2", rx: "0.5", fill: "currentColor" })))));
export default SortableHandle;
