import { Dropdown, Input } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DropdownMenu.module.scss';
const recursiveMenuItemsHandler = (items, search) => items.reduce((acc, item) => {
    if (item) {
        if ('children' in item) {
            const children = item.children?.length
                ? recursiveMenuItemsHandler(item.children, search)
                : [];
            if (children.length) {
                acc.push({ ...item, children });
            }
        }
        else if ('label' in item) {
            if (!search ||
                typeof item.label !== 'string' ||
                item.label.toLowerCase().indexOf(search) !== -1) {
                acc.push(item);
            }
        }
    }
    return acc;
}, []);
const trigger = ['click'];
const DropdownMenu = ({ searchPlaceholder, children, menuItems, onMenuItemClick, defaultOpen = false }) => {
    const [dropdownOpen, setDropdownOpen] = useState(defaultOpen);
    const [search, setSearch] = useState('');
    const handleSearchChange = useCallback((e) => {
        setSearch(e.target.value.toLowerCase());
    }, []);
    const menuItemsProcessed = useMemo(() => {
        const searchUnified = search.replace(/\s+/gm, ' ').trim().toLowerCase();
        const generatedItems = recursiveMenuItemsHandler(menuItems, searchUnified);
        generatedItems.unshift({
            key: 'search',
            className: 'SearchMenuItem',
            label: React.createElement(Input, { value: search, placeholder: searchPlaceholder, onInput: handleSearchChange })
        });
        return generatedItems;
    }, [menuItems, search, handleSearchChange, searchPlaceholder]);
    const handleMenuClick = useCallback((item) => {
        if (item.key !== 'search') {
            setDropdownOpen(false);
            if (onMenuItemClick) {
                onMenuItemClick(item.key);
            }
        }
    }, [onMenuItemClick]);
    useEffect(() => {
        if (dropdownOpen) {
            setSearch('');
        }
    }, [dropdownOpen]);
    return (React.createElement(Dropdown, { trigger: trigger, open: dropdownOpen, overlayClassName: styles.overlay, onOpenChange: setDropdownOpen, menu: {
            onClick: handleMenuClick,
            items: menuItemsProcessed
        } }, children));
};
export default DropdownMenu;
