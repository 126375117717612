import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../../../auth';
import { ENTITY_ID_PROPERTY_ID } from '../../../cms';
import { BROWSE_PATH, VIEW_PATH } from '../../../common/utils/constants';
import { CONDITION_QUERY_KEY, DEFAULT_FILTERS_CONDITION, getUpdatedLocation, stringifyConditionQuery } from '../../../views';
import { ActivityTaskDashboardPage } from '../../dashboard/components/dashboard/ActivityTaskDashboardPage';
import { WorkflowsTaskDashboardPage } from '../../dashboard/components/dashboard/WorkflowsTaskDashboardPage';
import MetaRoutes from '../../meta/components/common/Routes';
import ProcessContainer from '../../processes/components/process/ProcessContainer';
import { ACTIVITY_YOUR_DASHBOARD_PATH, ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, PROCESS_DETAILS_PATH, WORKFLOWS_YOUR_DASHBOARD_PATH } from '../utils/constants';
const RestrictedWorkflowsRoutes = ({ match: { url } }) => (React.createElement(ErrorBoundary, null,
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: [
                `${url}${WORKFLOWS_YOUR_DASHBOARD_PATH}${BROWSE_PATH}`,
                `${url}${WORKFLOWS_YOUR_DASHBOARD_PATH}${VIEW_PATH}/:viewId`
            ], component: WorkflowsTaskDashboardPage }),
        React.createElement(Route, { exact: true, path: [
                `${url}${ACTIVITY_YOUR_DASHBOARD_PATH}${BROWSE_PATH}`,
                `${url}${ACTIVITY_YOUR_DASHBOARD_PATH}${VIEW_PATH}/:viewId`
            ], component: ActivityTaskDashboardPage }),
        React.createElement(Route, { exact: true, path: [
                `${url}${PROCESSES_PATH}/:processMetaId${BROWSE_PATH}/:processRunId`,
                `${url}${PROCESSES_PATH}/:processMetaId${VIEW_PATH}/:viewId/:processRunId`
            ], render: (props) => {
                const { processMetaId, viewId, processRunId } = props.match.params;
                return (React.createElement(ProcessContainer, { baseUrl: `${url}${PROCESSES_PATH}/${processMetaId}`, processMetaId: processMetaId, viewId: viewId, processRunId: processRunId, ...props }));
            } }),
        React.createElement(Route, { path: `${url}${PROCESSES_PATH}/:processRunId${PROCESS_DETAILS_PATH}`, render: (props) => (
            // This is to still support the old route structure
            // TODO: have an in-between route that fetches the process while showing a spinner, and then redirects to the details page
            React.createElement(Redirect, { to: {
                    pathname: `${url}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`,
                    ...getUpdatedLocation({
                        [CONDITION_QUERY_KEY]: [
                            stringifyConditionQuery({
                                condition: DEFAULT_FILTERS_CONDITION,
                                filters: [
                                    {
                                        field: ENTITY_ID_PROPERTY_ID,
                                        operator: FilterOperatorTypes.is,
                                        data: props.match.params.processRunId
                                    }
                                ]
                            })
                        ]
                    })
                } })) }),
        React.createElement(Route, { path: `${url}${PROCESSES_PATH}`, component: MetaRoutes }),
        React.createElement(Redirect, { to: `${url}${WORKFLOWS_YOUR_DASHBOARD_PATH}${BROWSE_PATH}` }))));
export default redirectIfNoPermission(RestrictedWorkflowsRoutes);
