import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tag } from 'antd';
import { isEqual, uniqWith } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AggregationType } from '../../../../cms/hooks/useAggregation';
import { isMetricAggregation, isTermsAggregationResultArray } from '../../../../cms/hooks/useAggregation/types';
import { MISSED_VALUE_PLACEHOLDER_STRING, useAggregation } from '../../../../cms/hooks/useAggregation/useAggregation';
import { Select } from '../../../../common/components/Select';
import { Tooltip } from '../../../../common/components/Tooltip';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import { prepareMetricGroupQuery } from '../MetricsDisplayContainer';
import { AggregationTypes } from '../types';
import styles from './GroupedMetric.module.scss';
const TOTAL_UNIQ_COUNT_ID = 'TOTAL_UNIQ_COUNT_ID';
const CURRENT_NO_SELECTED = 'CURRENT_NO_SELECTED';
const ITEM_EMPTY_COUNT = 1;
const DELAY_SEARCH_AGGREGATIONS_MS = 1000;
const DELAY_CHANGE_AGGREGATIONS_MS = 1500;
const COUNT_TAG_DISPLAYED = 1;
const GroupedMetric = ({ onChangeAggregation, metricQuery, additionalMetricFilter, aggregationMetricFilter, formattedMetrics, definitionId, metricItem }) => {
    const { t } = useI18n();
    const { id, metric, size, aggregationId, rowGroupingOrderBy: orderBy, inactiveGroupingElements, rowGrouping } = metricItem;
    const [searchValue, setSearchValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const { isMobile } = useBrowserBreakpoints();
    const termAggregations = useMemo(() => {
        if (!rowGrouping) {
            return [];
        }
        return [
            {
                id: aggregationId,
                type: AggregationType.term,
                missing: MISSED_VALUE_PLACEHOLDER_STRING,
                field: rowGrouping,
                orderBy,
                size
            }
        ];
    }, [aggregationId, orderBy, rowGrouping, size]);
    const uniqCountAggregations = useMemo(() => {
        if (!rowGrouping) {
            return [];
        }
        return [
            {
                id: TOTAL_UNIQ_COUNT_ID,
                type: AggregationType.uniqCount,
                field: rowGrouping
            }
        ];
    }, [rowGrouping]);
    const aggregationDropdownFilter = useMemo(() => {
        const unSelectedGroupingElements = prepareMetricGroupQuery(rowGrouping, inactiveGroupingElements);
        const filtersToApply = additionalMetricFilter.query.filters.filter((group) => !isEqual(group, unSelectedGroupingElements));
        return {
            ...additionalMetricFilter,
            fullText: searchQuery,
            fullTextFields: [rowGrouping || ''],
            query: {
                ...additionalMetricFilter.query,
                filters: uniqWith(filtersToApply, isEqual)
            }
        };
    }, [additionalMetricFilter, inactiveGroupingElements, rowGrouping, searchQuery]);
    const { isLoading, data: aggregationForDropdown } = useAggregation({
        definitionId: definitionId,
        aggregations: termAggregations,
        metrics: formattedMetrics,
        filter: aggregationDropdownFilter,
        globalFilter: metricQuery
    });
    const { isLoading: isLoadingUniqCount, data: aggregationData } = useAggregation({
        definitionId: definitionId,
        aggregations: uniqCountAggregations,
        filter: aggregationMetricFilter,
        globalFilter: metricQuery
    });
    const [selectedAggregations, setSelectedAggregations] = useState([]);
    const aggregationUniqCount = useMemo(() => {
        const { aggregations: aggregationUniqCountById } = aggregationData || {};
        if (!aggregationUniqCountById) {
            return;
        }
        const uniqCount = aggregationUniqCountById[TOTAL_UNIQ_COUNT_ID];
        if (!isMetricAggregation(uniqCount)) {
            return 0;
        }
        if (selectedAggregations.includes(null)) {
            return uniqCount.value + ITEM_EMPTY_COUNT;
        }
        return uniqCount.value;
    }, [aggregationData, selectedAggregations]);
    const maxTagCount = useMemo(() => aggregationUniqCount && aggregationUniqCount !== 1
        ? aggregationUniqCount - COUNT_TAG_DISPLAYED
        : null, [aggregationUniqCount]);
    const aggregations = useMemo(() => {
        const aggs = aggregationId && aggregationForDropdown?.aggregations?.[aggregationId];
        if (!isTermsAggregationResultArray(aggs)) {
            return;
        }
        return aggs.map((el) => {
            if (el.value === MISSED_VALUE_PLACEHOLDER_STRING) {
                return { ...el, label: null, value: null };
            }
            return el;
        });
    }, [aggregationForDropdown, aggregationId]);
    useEffect(() => {
        if (aggregations) {
            let checkedAggregations = [...aggregations];
            if (inactiveGroupingElements) {
                checkedAggregations = aggregations.filter((aggr) => !inactiveGroupingElements.includes(aggr.label));
            }
            setSelectedAggregations(checkedAggregations.map((el) => el.value));
        }
    }, [aggregations, inactiveGroupingElements]);
    const menuItems = useMemo(() => {
        if (!aggregations?.length) {
            return;
        }
        const isCountMetric = metric === AggregationTypes.value_count;
        return aggregations.map((el) => {
            const value = Number(isCountMetric ? el.count : el[id].value);
            const label = el.label === null ? t('EMPTY') : el.label;
            return {
                label: (React.createElement("p", { className: "d-flex justify-content-between w-100 m-0" },
                    React.createElement("p", { className: "m-0 d-flex align-items-start overflow-hidden text-nowrap" },
                        React.createElement("span", { className: "text-ellipsis label" }, label)),
                    React.createElement("p", { className: "mb-0 ms-2 count" },
                        React.createElement("span", null,
                            " ",
                            value)))),
                key: el.label,
                value: el.value
            };
        });
    }, [aggregations, metric, id, t]);
    const handleDelayedAggregationChange = useCallback((allSelected) => {
        const unCheckedAggregations = aggregations?.filter((aggr) => !allSelected.includes(aggr.value)).map((el) => el.label) ||
            [];
        const aggregationValueItems = aggregations?.map((el) => el.label) || [];
        const unCheckedNotDisplayedItems = metricItem?.inactiveGroupingElements
            ?.length
            ? metricItem?.inactiveGroupingElements.filter((el) => !aggregationValueItems.includes(el))
            : [];
        const newMetric = {
            ...metricItem,
            inactiveGroupingElements: [
                ...unCheckedAggregations,
                ...(unCheckedNotDisplayedItems ? unCheckedNotDisplayedItems : [])
            ]
        };
        onChangeAggregation(newMetric, metricItem?.inactiveGroupingElements || []);
    }, [aggregations, metricItem, onChangeAggregation]);
    const debouncedOnSearch = useDebouncedCallback((value) => setSearchQuery(value), DELAY_SEARCH_AGGREGATIONS_MS);
    const debouncedOnChange = useDebouncedCallback(handleDelayedAggregationChange, DELAY_CHANGE_AGGREGATIONS_MS);
    const handleChangeAggregation = (allSelected) => {
        if (!Array.isArray(allSelected)) {
            return;
        }
        setSelectedAggregations([...allSelected.filter((el) => el !== CURRENT_NO_SELECTED)]);
        debouncedOnChange(allSelected);
    };
    const tagRender = useCallback(({ label, closable, onClose }) => (React.createElement(Tag, { closable: closable, onClose: onClose, className: "ant-select-selection-item" },
        React.createElement(Tooltip, { showTooltip: !isMobile, overlayClassName: "maxTagPlaceholder", title: label },
            React.createElement("p", { className: "d-flex justify-content-between w-100 m-0" },
                React.createElement("p", { className: "m-0 d-flex align-items-start overflow-hidden text-nowrap" },
                    React.createElement("span", { className: "text-ellipsis label" }, label)))))), [isMobile]);
    return (React.createElement("div", { className: `col ${styles.metricsWrp}`, onClick: (event) => event.stopPropagation() },
        React.createElement(Select, { popupClassName: styles.metricsDropdownPopup, checkmarks: true, popupMatchSelectWidth: false, loading: isLoading || isLoadingUniqCount, searchValue: searchValue, tagRender: tagRender, placement: "bottomRight", onSearchDropdown: (value) => {
                setSearchValue(value);
                debouncedOnSearch(value);
            }, maxTagPlaceholder: (omittedValues) => {
                const isEmptyDisplayedResults = omittedValues[0]?.value === CURRENT_NO_SELECTED;
                const allowTopResultMessage = aggregationUniqCount && selectedAggregations.length < aggregationUniqCount;
                return (React.createElement(Tooltip, { showTooltip: !isMobile, overlayClassName: "maxTagPlaceholder", title: React.createElement(React.Fragment, null,
                        allowTopResultMessage && (React.createElement("div", { className: "tooltip-top-results" }, t('SHOWING_TOP_RESULTS', { smart_count: aggregationUniqCount }))),
                        !isEmptyDisplayedResults && (React.createElement("ul", { className: "ps-3 mb-0", onMouseDown: (event) => event.stopPropagation() }, omittedValues.map((value) => (React.createElement("li", { key: value.key },
                            React.createElement("div", null, value.label))))))) }, aggregationUniqCount ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "me-1" }, "+"),
                    React.createElement("span", null, selectedAggregations.length === 1 || isEmptyDisplayedResults
                        ? aggregationUniqCount
                        : maxTagCount))) : null));
            }, value: selectedAggregations.length || aggregationUniqCount === 0
                ? selectedAggregations
                : CURRENT_NO_SELECTED, filterOption: false, onChange: (allSelected) => {
                handleChangeAggregation(allSelected);
            }, options: menuItems, totalCount: aggregationUniqCount, mode: "multiple", maxTagCount: selectedAggregations.length <= 1 ? 0 : COUNT_TAG_DISPLAYED })));
};
export default GroupedMetric;
