import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { ConversationIcon } from '../../../icons';
import styles from './EmptyComments.module.scss';
const EmptyComments = ({ className }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: `${styles.container} ${className || ''} EmptyCommentsOuter` },
        React.createElement(ConversationIcon, null),
        React.createElement("div", { className: "message-body" }, t('MESSAGE_NO_COMMENTS'))));
};
export default EmptyComments;
