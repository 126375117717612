import { ErrorMessage } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import accepts from 'attr-accept';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDataTransferItems, uploadFiles } from '../../../../../common/storage';
import styles from './FileUploader.module.scss';
const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
};
const acceptedFileTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
const acceptedFileExtensions = ['.doc', '.docx'];
const FileUploader = ({ entityId, onChange, disabled, className, validationError, dropFileText, showFileName }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const [fileData, setFileData] = useState(undefined);
    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const fileUploadRef = useRef(null);
    const displayError = useCallback((content) => dispatch(error(content)), [dispatch]);
    const uploadFilesHandler = useCallback((files) => uploadFiles(dispatch, files, entityId), [dispatch, entityId]);
    const handleDragEnter = (e) => {
        e.preventDefault();
        const files = getDataTransferItems(e);
        const acceptable = files.every((file) => accepts(file, file.type === '' ? acceptedFileExtensions : acceptedFileTypes));
        setIsDraggedOver(files.length == 1 && acceptable);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDraggedOver(false);
    };
    const handleFileUpload = async (e) => {
        e.preventDefault();
        const files = getDataTransferItems(e);
        const filesize = files[0].size / 1024 / 1024;
        const acceptable = files.every((file) => accepts(file, file.type === '' ? acceptedFileExtensions : acceptedFileTypes));
        if (filesize <= 10 && acceptable) {
            setPending(true);
            await onChange(uploadFilesHandler(files).then((fileData) => {
                if (showFileName) {
                    setFileData(fileData[0]);
                }
                return fileData;
            }));
            // if the fileUploadRef is not present
            // then this component has been unmounted
            if (fileUploadRef.current != null) {
                setPending(false);
                setIsDraggedOver(false);
            }
        }
        else {
            if (!acceptable) {
                displayError({ title: t('ERROR'), message: t('TEMPLATE_FILE_WRONG_TYPE') });
            }
            else {
                displayError({ title: t('ERROR'), message: t('TEMPLATE_FILE_TOO_BIG') });
            }
            setIsDraggedOver(false);
        }
    };
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: `drop-area ${pending || disabled ? 'disabled' : ''} ${validationError ? 'validation-error' : ''} ${isDraggedOver ? 'drag-over' : ''} cp-c-row`, onDragEnter: handleDragEnter, onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleFileUpload },
            React.createElement("div", { className: "drop-text cp-c-row cp-c-align-center-center" },
                pending && (React.createElement("div", { className: "loader-wrapper" },
                    React.createElement("div", { className: "loader-inner" }))),
                !fileData ? (React.createElement("div", null,
                    React.createElement("span", null, dropFileText),
                    React.createElement("br", null),
                    React.createElement("span", null, t('TEMPLATE_DROP_FILE_MAX')))) : (fileData.name)),
            React.createElement("input", { className: `file-upload-input ${pending || disabled ? 'disabled' : ''}`, disabled: pending || disabled, type: "file", accept: acceptedFileTypes.join(','), tabIndex: -1, onChange: handleFileUpload, ref: fileUploadRef, multiple: false, onClick: () => {
                    if (fileUploadRef.current) {
                        fileUploadRef.current.value = '';
                    }
                } })),
        validationError && React.createElement(ErrorMessage, { className: "error-message" }, validationError)));
};
export default FileUploader;
