import { t } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { BlockPosition } from '@hypercharge/portal-utils';
export const handleFailure = (dispatch, customTranslationKey) => {
    dispatch(error({
        title: t('COMMON__FAILURE'),
        message: t(customTranslationKey || 'AUTH_SOMETHING_WENT_WRONG')
    }));
};
export const getPositionClassName = (position) => {
    switch (position) {
        case BlockPosition.left:
            return 'justify-content-start';
        case BlockPosition.right:
            return 'justify-content-end';
        default:
            return 'justify-content-center';
    }
};
