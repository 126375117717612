import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import MultiTextEditor from '../../../../cms/data/components/item-property/editors/MultiTextEditor';
import { AceEditor, AceEditorMode } from '../../../../common/components/AceEditor';
import FormikField from '../../../../common/components/formik/FormikField';
export const initialValues = {
    enabled: true,
    title: 'Unnamed',
    configuration: {}
};
const editorOptions = {
    showLineNumbers: true,
    tabSize: 2
};
export const getFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.boolean().default(true),
        title: Yup.string().default('Unnamed'),
        configuration: Yup.mixed().required()
    });
};
const Form = ({ disabled, setFieldValue, instance }) => {
    const [value, setValue] = useState(JSON.stringify(instance.configuration, undefined, 2));
    useEffect(() => {
        setValue((prev) => {
            if (!isEqual(JSON.parse(prev), instance.configuration)) {
                return JSON.stringify(instance.configuration, undefined, 2);
            }
            return prev;
        });
    }, [instance.configuration]);
    const onChange = useCallback((val) => {
        try {
            const newValue = JSON.parse(val);
            if (!isEqual(newValue, value)) {
                setValue(val);
                setFieldValue('configuration', JSON.parse(val));
            }
        }
        catch (error) {
            // skip error
        }
    }, [setFieldValue, value]);
    return (React.createElement("div", { className: "container h-100" },
        React.createElement("div", { className: "row " },
            React.createElement("div", { className: "col-12" },
                React.createElement("span", null, "Configuration:"),
                React.createElement(AceEditor, { value: value, disabled: disabled, mode: AceEditorMode.json, height: "450px", beautify: true, options: editorOptions, onChange: onChange }))),
        React.createElement("div", { className: "row mt-2" },
            React.createElement("div", { className: "col-12" },
                React.createElement("span", null, "Custom hosts: "),
                React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, disabled: disabled })))));
};
export default Form;
