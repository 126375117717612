import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import FormikField from '../../../../../common/components/formik/FormikField';
import Toggle from '../../../../../common/components/Toggle';
import { VEHICLE_CMS_DEFINITION_ID } from '../../../../../crm';
import { ViewTypes } from '../../../../../views/types';
import ComparisonPropertiesSelector from './components/ComparisonPropertiesSelector';
const itemSchema = Yup.array().of(Yup.object().shape({
    propertyId: Yup.string(),
    field: Yup.string(),
    labels: Yup.object().shape({
        en: Yup.string(),
        nl: Yup.string(),
        fr: Yup.string()
    })
}));
export const getComparisonFormSchema = () => {
    return Yup.object().shape({
        general: itemSchema,
        details: itemSchema
    });
};
const Form = ({ instance }) => {
    const { displayData, displayDataStatus } = useEntityDisplayData(VEHICLE_CMS_DEFINITION_ID);
    const { t } = useI18n();
    const isEnabled = instance.configuration.website.enableComparison;
    return (React.createElement("div", { className: "pb-2" }, displayDataStatus && (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.enableComparison", component: Toggle, label: t('APPS_HYPER_DEALER__ENABLE_COMPARISON') })),
        React.createElement(LabelText, null, t('CONTRACT_DETAILS_GENERAL')),
        React.createElement(FormikField, { name: "configuration.website.comparison.general", component: ComparisonPropertiesSelector, displayData: displayData || {}, displayDataStatus: displayDataStatus, viewType: ViewTypes.table, disabled: !isEnabled }),
        React.createElement(LabelText, null, t('PROPERTY_DETAILS')),
        React.createElement(FormikField, { name: "configuration.website.comparison.details", component: ComparisonPropertiesSelector, displayData: displayData || {}, viewType: ViewTypes.table, disabled: !isEnabled })))));
};
export default Form;
