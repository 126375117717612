import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { InlineEditor } from '../../../../../common/components/InlineEditor';
import { useTranslation } from '../../../../../common/components/InlineEditor/SelectLanguage/SelectLanguage';
import SideMenuIcon from '../../../../../common/components/side-menu/SideMenuIcon/SideMenuIcon';
import { isRichTextValue } from '../../../../../common/types';
import { TITLE_PROPERTY_ID } from '../../../../common/utils/constants';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import ValueHistoryDrawer from '../../item-property/ValueHistoryDrawer';
import { getDisplayComponentForType, getEditorComponentForType, titleValidations } from '../../item-property/utils';
import styles from './EditableItemTitle.module.scss';
const EditableItemTitle = ({ className, definitionId, entityId, loading, validations = titleValidations, titleProperty, updateTitle }) => {
    const { translationOptions, selectedLanguage, multiLanguagePermission, setSelectedLanguage } = useTranslation({
        meta: titleProperty?.meta,
        entityId,
        definitionId,
        propertyId: titleProperty?.propertyId || TITLE_PROPERTY_ID
    });
    const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const canEditTitle = !!get(titleProperty, 'canEdit', false);
    const { data: definition } = useDisplayItemMeta({ definitionId });
    const onHistoryOpened = useCallback((propertyId) => {
        setHistoryDrawerOpen(true);
        setSelectedPropertyId(propertyId || TITLE_PROPERTY_ID);
    }, []);
    const onSave = useCallback(async (value, propertyId) => {
        if (isRichTextValue(value)) {
            return;
        }
        return updateTitle(propertyId || TITLE_PROPERTY_ID, value);
    }, [updateTitle]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: `${styles.title} EditableItemTitleOuter d-flex ${className || ''}` }, loading && !titleProperty ? (React.createElement(Skeleton, { isLoading: true, className: "d-flex align-items-center" },
            React.createElement(TextPlaceholder, { heightInPx: 26, widthInPx: 150 }))) : (React.createElement("div", { className: "editor-row" },
            React.createElement("span", { className: 'd-none d-md-inline' },
                React.createElement(SideMenuIcon, { style: definition?.style })),
            React.createElement(InlineEditor, { translationOptions: multiLanguagePermission ? translationOptions : undefined, setSelectedLanguageMultiLanguage: setSelectedLanguage, selectedLanguageMultiLanguage: selectedLanguage, propertyId: translationOptions && translationOptions[selectedLanguage]?.propertyId, className: `title-inline-editor ${canEditTitle ? 'can-edit' : ''} ${!titleProperty?.value ? 'empty-value' : ''}`, displayComponent: getDisplayComponentForType('text'), editorComponent: getEditorComponentForType('text'), 
                // @ts-expect-error
                value: (!!translationOptions && translationOptions[selectedLanguage]?.value) ||
                    titleProperty?.value ||
                    '', valueFromTranslations: translationOptions && translationOptions[selectedLanguage]?.value, actionsPosition: "bottom", canEdit: canEditTitle, hideActions: false, onSave: onSave, onHistoryOpened: onHistoryOpened, meta: { validations }, extraDisplayComponentProps: { asField: false }, invalid: false }),
            selectedPropertyId && (React.createElement(ValueHistoryDrawer, { open: historyDrawerOpen, definitionId: definitionId, entityId: entityId, propertyId: selectedPropertyId, onSave: updateTitle, onClose: () => setHistoryDrawerOpen(false) })))))));
};
export default EditableItemTitle;
