import { get } from 'lodash';
import { getTranslation } from '../i18n';
import { getOption } from './optionsSelectors';
import { getProperty } from './propertySelectors';
export const getEnhancedProperties = (source, metadataState, language, propertyConfigs) => source
    ? propertyConfigs.map((config) => {
        const path = (typeof config !== 'string' && config.path) || config;
        let label = path;
        let value = get(source, path);
        const definitionId = (typeof config !== 'string' && config.definitionId) || source.definitionId;
        const propertyId = typeof config === 'string' ? config : config.propertyId;
        const property = getProperty(metadataState, definitionId, propertyId);
        if (property) {
            label = getTranslation(property.labels, language);
            const optionGroupId = get(property, 'typeData.optionGroupId');
            const option = optionGroupId && getOption(metadataState, optionGroupId, value || '');
            if (option) {
                // @ts-expect-error
                value = getTranslation(option.labels, language);
            }
        }
        return { label, value };
    })
    : [];
export const propertyFieldTypes = [
    'text',
    'multitext',
    'select',
    'treeselect',
    'multiselect',
    'date',
    'file',
    'number',
    'complex'
];
