import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PaymentContainer from './payment/PaymentContainer';
import { getErrorMessage } from './utils';
const SubscriptionsContainer = ({ setLinks, setCurrentBreadcrumbText, contentHeight }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const onError = useCallback((e) => {
        dispatch(error({
            title: t('COMMON__FAILURE'),
            message: getErrorMessage(e)
        }));
    }, [dispatch, t]);
    const onSuccess = useCallback((message) => {
        dispatch(success({
            title: t('COMMON__SUCCESS'),
            message
        }));
    }, [dispatch, t]);
    return (React.createElement(PaymentContainer, { setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, onError: onError, onSuccess: onSuccess, contentHeight: contentHeight }));
};
export default SubscriptionsContainer;
