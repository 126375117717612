import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider, Switch } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { FieldsContainer, FieldsContainerTitle } from '../../../../common/utils/formUtils';
import styles from './ActionButtonsSection.module.scss';
import { propertyIdIconsSet } from './utils';
const ActionButtonsSection = ({ loading, submitting, showHiddenFields, toggleShowHiddenFields, addItem }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldsContainerTitle, null, t('DEFINITION_FORM__ADD_PROPERTY')),
        React.createElement(FieldsContainer, { className: "cp-c-row cp-c-wrap cp-c-padding-2" },
            map(propertyIdIconsSet, (Icon, type) => (React.createElement("div", { key: type, className: "cp-i-100 cp-i-sm-33 cp-i-md-25 cp-i-lg-100 cp-i-xl-50" },
                React.createElement(Button, { className: styles.fullWidthBtn, onClick: () => addItem(type), inversed: true, disabled: loading || submitting },
                    React.createElement("div", { className: "cp-c-row cp-c-align-center-center" },
                        React.createElement("div", { className: "cp-i-none" },
                            React.createElement(Icon, null)),
                        React.createElement("div", { className: "cp-i-flex text" }, t(`PROPERTY_FIELD_TYPE_${type}`))))))),
            React.createElement("div", { className: "cp-i-100" },
                React.createElement(Divider, null),
                React.createElement(Button, { className: `${styles.fullWidthBtn} mb-2`, inversed: true, onClick: () => addItem('tab'), disabled: loading || submitting },
                    "+ ",
                    t('DEFINITION_FORM__ADD_NEW_TAB')),
                React.createElement(Button, { className: styles.fullWidthBtn, inversed: true, onClick: () => addItem('section'), disabled: loading || submitting },
                    "+ ",
                    t('DEFINITION_FORM__ADD_NEW_SECTION')),
                React.createElement(Divider, null),
                React.createElement(Switch, { size: "small", onChange: toggleShowHiddenFields, checked: showHiddenFields, disabled: loading || submitting }),
                React.createElement("span", { className: styles.toggleShowHiddenFieldsLabel, onClick: toggleShowHiddenFields }, t('DEFINITION_FORM__SHOW_HIDDEN_FIELDS')),
                React.createElement(Divider, { className: "cp-lg-hide" })))));
};
export default ActionButtonsSection;
