import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { FaQuestion } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { SortableContainer as asSortableContainer } from 'react-sortable-hoc';
import Toggle from '../../../../../../common/components/Toggle';
import { getPropertyTypeComponent } from '../../../../../../workflows/meta/components/meta-definition-form/utils';
import { getHighlightedItemId, getItemId, getLabel, isProperties, isProperty } from '../utils';
import SortableHandle from './SortableHandle';
import SortableItem from './SortableItem';
const SortableList = asSortableContainer(({ selectedItems, dataById, onActiveChange, onRemove }) => {
    const { language, t } = useI18n();
    const noItemsLabel = isProperties(dataById)
        ? t('HIGHLIGHTED_PROPERTIES_NO_ITEMS')
        : t('HIGHLIGHTED_REFERRALS_NO_ITEMS');
    return (React.createElement("div", null, !selectedItems.length
        ? noItemsLabel
        : selectedItems
            .filter((selectedItem) => dataById[getHighlightedItemId(selectedItem)])
            .map((selectedItem, index) => {
            const item = dataById[getHighlightedItemId(selectedItem)];
            const Icon = isProperty(item)
                ? getPropertyTypeComponent(item.type) || FaQuestion
                : null;
            const HiddenIcon = isProperty(item) ? (item.meta.hidden ? IoEyeOff : IoEye) : null;
            const itemId = getHighlightedItemId(selectedItem);
            return (React.createElement(SortableItem, { key: itemId, index: index },
                React.createElement(SortableHandle, null),
                HiddenIcon && React.createElement(HiddenIcon, { size: 18, className: "ms-1 me-2" }),
                Icon && React.createElement(Icon, null),
                React.createElement("div", { className: "fw-600 ms-2 title" }, getLabel(item, language)),
                React.createElement(IoMdClose, { className: "me-1 ms-auto remove-btn", onClick: () => onRemove(getItemId(item)) }),
                React.createElement(Toggle, { className: "switcher", onChange: (value) => onActiveChange(getItemId(item), value), checked: selectedItem.active })));
        })));
});
export default SortableList;
