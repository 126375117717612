const processFilters = (terms, ranges) => {
    const result = {
        filters: [],
        allFilters: ''
    };
    let str = '';
    if (terms && terms.length > 0) {
        str = terms
            .sort((a, b) => {
            a.propertyPath < b.propertyPath;
        })
            .map((term) => {
            const split = term.propertyPath.split('.');
            const label = split[split.length - 1];
            return (label +
                ': ' +
                term.values
                    .map((val) => {
                    result.filters.push(`${label}: ${val}`);
                    return val;
                })
                    .join(', '));
        })
            .join(' | ');
    }
    if (ranges && ranges.length > 0) {
        str +=
            ' | ' +
                ranges
                    .sort((a, b) => {
                    a.propertyPath < b.propertyPath;
                })
                    .map((range) => {
                    const split = range.propertyPath.split('.');
                    const filter = `${split[split.length - 1]}: ${range.min ? range.min : ''}/${range.max ? range.max : ''}`;
                    result.filters.push(filter);
                    return filter;
                })
                    .join(' | ');
    }
    result.allFilters = str;
    return result;
};
const analyzer = (action, emitter) => {
    switch (action.type) {
        case 'BROWSE__PRODUCTS_FETCH':
            const result = processFilters(action.payload.terms, action.payload.ranges);
            result.filters.forEach((filter) => {
                emitter.event({
                    category: 'Filter',
                    action: filter,
                    label: result.allFilters
                });
            });
            break;
        case 'PRODUCT__PRODUCT_FETCH_SUCCESS':
            if (action.payload.results && action.payload.results.length > 0) {
                const first = action.payload.results[0];
                emitter.event({
                    category: 'Detail',
                    action: `PID: ${first.publicId ? first.publicId : 'N/A'} | ID: ${first.id}`,
                    label: `${first.title.en}`
                });
            }
            break;
    }
};
export default analyzer;
