import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { get } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { getConfigsState } from '../../../common/reducers/rootSelector';
import { SETTINGS_PATH } from '../../../common/utils/constants';
import { fetchConfig, getConfig, hasFetchConfigFailed, isFetchConfigPending, saveConfig } from '../../../configs';
import { fetchTenant as fetchTenantAction, getTenant, getTenantStatus } from '../../../tenant';
import { GDPR_KEY, SETTINGS_KEY } from '../../constants';
import { formName } from './utils';
const triggerGdprSettingsUpdateAction = {
    type: 'UPDATE_GDPR_SETTINGS',
    payload: {},
    meta: {
        http: {
            path: '/v2-gdpr/settings/update',
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
};
const enableGdprPortalAction = {
    type: 'ENABLE_GDPR_PORTAL',
    payload: {},
    meta: {
        http: {
            path: '/v2-gdpr/settings/enable',
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
};
const useGdprSettingsForm = ({ setLinks, setCurrentBreadcrumbText }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const config = useSelector((s) => {
        const configsState = getConfigsState(s);
        const gdprConfig = getConfig(configsState, SETTINGS_KEY, GDPR_KEY);
        const tenantStatus = getTenantStatus(s);
        const tenant = getTenant(s);
        return {
            gdprNeedsActivation: !!tenant && !tenant.gdpr,
            initialValues: get(gdprConfig, 'data'),
            formValues: getFormValues(formName)(s),
            isTenantPending: tenantStatus.isPending,
            isTenantFailed: tenantStatus.isFailed,
            gdprConfig,
            isGdprConfigPending: isFetchConfigPending(configsState, SETTINGS_KEY, GDPR_KEY),
            isGdprConfigFailed: hasFetchConfigFailed(configsState, SETTINGS_KEY, GDPR_KEY)
        };
    });
    const fetchTenant = useCallback(() => dispatch(fetchTenantAction()), [dispatch]);
    const fetchGdprConfig = useCallback(() => dispatch(fetchConfig(SETTINGS_KEY, GDPR_KEY)), [dispatch]);
    const enableGdprPortal = useCallback(async () => {
        try {
            await dispatch(enableGdprPortalAction);
            void Promise.all([
                fetchTenant(),
                fetchConfig(SETTINGS_KEY, GDPR_KEY),
                success({
                    title: t('SUCCESS'),
                    message: t('GDPR_ACTIVATE_SUCCESS')
                })
            ].map(dispatch));
        }
        catch (e) {
            dispatch(error({
                title: t('ERROR'),
                message: t('GDPR_ACTIVATE_FAIL')
            }));
        }
    }, [dispatch, fetchTenant, t]);
    const onFormSubmit = useCallback(async () => new Promise((resolve, reject) => {
        dispatch(saveConfig({
            ...config.gdprConfig,
            configType: SETTINGS_KEY,
            configId: GDPR_KEY,
            data: config.formValues
        }))
            // @ts-expect-error
            .then(() => dispatch(triggerGdprSettingsUpdateAction))
            .then(() => {
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
            resolve(undefined);
        })
            .catch((e) => {
            dispatch(error({
                title: t('ERROR'),
                message: t('SAVE_SETTINGS_FAIL')
            }));
            reject(e);
        });
    }), [dispatch, config.formValues, config.gdprConfig, t]);
    const updateFormValue = useCallback((fieldName, value) => {
        dispatch(change(formName, fieldName, value));
    }, [dispatch]);
    useEffect(() => {
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('GDPR_PORTAL'));
        if (!config.isTenantPending) {
            void fetchTenant();
        }
        if (!config.isGdprConfigPending) {
            void fetchGdprConfig();
        }
    }, []);
    return {
        config,
        fetchTenant,
        fetchGdprConfig,
        enableGdprPortal,
        onFormSubmit,
        updateFormValue
    };
};
export default useGdprSettingsForm;
