import Indicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { hasTenantPermission } from '../../../auth';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import { SHARE_DASHBOARD_PERMISSION } from '../../../common/utils/constants';
import DashboardPage from './DashboardPage';
import { useDashboards } from './DashboardsProvider';
import { DASHBOARDS_ENTITY_ID } from './constants';
const otherElementSelectors = ['#header', '#featured-entities'];
const otherHeightInPx = 24 + 16 + 56 + 2;
const DashboardsPage = () => {
    const { t } = useI18n();
    const { url } = useRouteMatch();
    const { data: dashboardDisplayItemMeta } = useDisplayItemMeta({
        definitionId: DASHBOARDS_ENTITY_ID
    });
    const { dashboards, loading: dashboardsLoading } = useDashboards();
    const canCreate = !!dashboardDisplayItemMeta?.canCreate;
    const canShare = useSelector((s) => hasTenantPermission(s, SHARE_DASHBOARD_PERMISSION));
    if (dashboardsLoading) {
        return React.createElement(Indicator, { className: "mt-5 mx-auto" });
    }
    return (React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
        React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement(React.Fragment, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: `${url}/create` }, canCreate ? (React.createElement(DashboardPage, { contentHeight: contentHeight, action: "create", canCreate: canCreate, canShare: canShare })) : (React.createElement(Redirect, { to: `${url}` }))),
                React.createElement(Route, { path: `${url}/:dashboardId` },
                    React.createElement(DashboardPage, { contentHeight: contentHeight, canCreate: canCreate, canShare: canShare })),
                dashboards.length ? (React.createElement(Redirect, { to: `${url}/defaultDashboard` })) : (React.createElement(Route, { path: `${url}` },
                    canCreate && React.createElement(Redirect, { to: `${url}/create` }),
                    React.createElement("div", { className: "mt-5 text-center" }, t('HYPER_BI_NO_DASHBOARDS'))))))))));
};
export default DashboardsPage;
