import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { BsPlusSquare } from 'react-icons/bs';
import { useLoadActivityTypes } from '../../../activity';
import ActivityIcon from '../../../activity/components/ActivityList/ActivityIcon';
import DropdownButton from '../../DropdownButton';
import { useStickyTabs } from '../StickyTabsProvider';
import styles from './QuickActionButton.module.scss';
const QuickActionButton = ({ className }) => {
    const { addNewTab } = useStickyTabs();
    const { t } = useI18n();
    const { activityTypes, isPending } = useLoadActivityTypes({ onlyPresentInQuickMenu: true });
    const menu = useMemo(() => ({
        items: activityTypes.map((activityType) => ({
            key: activityType.id,
            icon: React.createElement(ActivityIcon, { activityType: activityType }),
            onClick: () => addNewTab(activityType),
            label: activityType.title
        }))
    }), [addNewTab, activityTypes]);
    return (React.createElement(DropdownButton, { loading: isPending, disabled: activityTypes.length === 0, className: `${styles.dropdownButton} ${className ?? ''}`, menuIcon: React.createElement(BsPlusSquare, { size: 18 }), menu: menu, dropdownRootClassName: "app-header-dropdown", tooltipConfig: { placement: 'left', title: t('COMMON__QUICK_ACTIONS') } }));
};
export default QuickActionButton;
