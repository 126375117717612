import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import { Button } from 'antd';
import { DOMAIN, TENANT_ID } from 'config';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MICROSOFT_API_PATH } from '../constants';
const MicrosoftLoginInput = ({ value, onChange, className }) => {
    const { t } = useI18n();
    const { search, pathname } = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const tenant = searchParams.get('tenant');
        if (tenant && tenant != value) {
            onChange(tenant);
        }
        if (tenant) {
            dispatch(replace({
                pathname,
                search: ''
            }));
        }
    }, [dispatch, onChange, pathname, search, value]);
    const loginUrl = useMemo(() => {
        const searchParams = new URLSearchParams({
            client_id: '690776dc-9e0b-473e-a72f-3acf716493d0',
            redirect_uri: `https://auth.${DOMAIN}${MICROSOFT_API_PATH}/callback`,
            state: JSON.stringify({
                tenantId: TENANT_ID,
                redirect: window.location.href
            })
        });
        return `https://login.microsoftonline.com/common/adminconsent?${searchParams.toString()}`;
    }, []);
    const onClickLogOut = useCallback(() => {
        onChange(undefined);
    }, [onChange]);
    return (React.createElement("div", { className: className }, value ? (React.createElement(Button, { type: "primary", onClick: onClickLogOut }, t('LOGOUT'))) : (React.createElement(Button, { type: "primary", href: loginUrl }, t('ADD_ACCOUNT')))));
};
export default MicrosoftLoginInput;
