import React from 'react';
import styled from 'styled-components';
import { range, random } from 'lodash';
import { Skeleton, TextPlaceholder } from './BaseSkeletons';
const SkeletonContainer = styled(Skeleton) `
  padding: 1rem;
`;
const Link = styled(TextPlaceholder) `
  background: ${({ theme }) => theme.mainColor};
`;
const Divider = styled.div `
  &::before {
    content: '›';
    padding: 0 6px;
    font-weight: 300;
    color: ${({ theme }) => theme.textColor};
  }
`;
const BreadCrumbsSkeleton = () => (React.createElement(SkeletonContainer, { className: "cp-c-row" },
    range(4).map((i) => (React.createElement("div", { key: i, className: "cp-c-row" },
        React.createElement(Link, { heightInPx: 18, widthInPx: random(50, 75) }),
        React.createElement(Divider, null)))),
    React.createElement(TextPlaceholder, { heightInPx: 18, widthInPx: random(75, 125) })));
export default BreadCrumbsSkeleton;
