import { CMS_API_PATH } from '../../../../common/utils/constants';
export const bulkUpdateAction = (definitionId, itemData, entityIds, filterRequest) => ({
    type: 'CMS_BULK_EDIT_FETCH',
    payload: {
        entityIds,
        itemData,
        filterRequest
    },
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/entity/`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
