import { ConfigProvider } from 'antd';
import React from 'react';
const DisableAntAnimationProvider = ({ children }) => {
    return (React.createElement(ConfigProvider, { theme: {
            token: {
                motion: false
            }
        } }, children));
};
export default DisableAntAnimationProvider;
