const baseUrl = '/v2-mail';
// ////////////////////
// Authenticate Stripo template editor
// ////////////////////
export const authenticateStripo = () => ({
    type: 'TEMPLATES__AUTHENTICATE_STRIPO',
    payload: {},
    meta: {
        http: {
            path: `${baseUrl}/stripo/auth`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
// ////////////////////
// Send test email
// ////////////////////
export const sendTestEmail = (templateId, email, language) => ({
    type: 'TEMPLATES__TEST_EMAIL',
    payload: {
        templateId,
        data: {},
        destinations: [{ email }],
        language
    },
    meta: {
        http: {
            path: `${baseUrl}/send/template`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const getEmailClients = () => ({
    type: 'TEMPLATES__GET_EMAIL_CLIENTS',
    meta: {
        http: {
            path: `${baseUrl}/template/clients`,
            method: 'GET',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const generateCrossClientsPreview = (templateId, language) => ({
    type: 'TEMPLATES__GENERATE_CROSS_CLIENTS_PREVIEW',
    meta: {
        http: {
            path: `${baseUrl}/template/${templateId}/${language}/preview-on-clients`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const listCrossClientsPreview = (templateId, language) => ({
    type: 'TEMPLATES__LIST_CROSS_CLIENTS_PREVIEW',
    meta: {
        http: {
            path: `${baseUrl}/template/${templateId}/${language}/preview-on-clients`,
            method: 'GET',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const getCrossClientsPreview = (templateId, language, id) => ({
    type: 'TEMPLATES__GET_CROSS_CLIENTS_PREVIEW',
    meta: {
        http: {
            path: `${baseUrl}/template/${templateId}/${language}/preview-on-clients/${id}`,
            method: 'GET',
            allowPromiseReject: true
        },
        auth: true
    }
});
