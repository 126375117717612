import { find } from 'lodash';
import { useMemo } from 'react';
import useDisplayItemMetaList from './useDisplayItemMetaList';
const useDisplayItemMeta = ({ definitionId }) => {
    const { data, isLoading, isError } = useDisplayItemMetaList();
    return useMemo(() => ({
        data: definitionId ? find(data, { definitionId }) : undefined,
        isLoading,
        isError
    }), [definitionId, isError, isLoading, data]);
};
export default useDisplayItemMeta;
