import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { FaPlus, FaUserFriends } from 'react-icons/fa';
import { QuickAddItemButton } from '../../../cms';
import { Drawer } from '../../../common/components/Drawer';
import stylesMobileSideMenu from '../../../common/components/side-menu/MobileSideMenu.module.scss';
import styles from './Hierarchy.module.scss';
import { Title } from '../../../common/components/side-menu/utils';
import { GROUP_CMS_DEFINITION_ID } from '../../constants';
import { CollapseExpand } from './CollapseExpand';
import { Hierarchy } from './Hierarchy';
export const MobileHierarchy = ({ canCreateAndDeleteGroup, entityTitle, saving, handleSelectGroup, expandedKeys, setExpandedKeys, searchText, parentGroupMap, ...otherProps }) => {
    const { t } = useI18n();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = useCallback(() => {
        setIsDrawerOpen((x) => !x);
    }, []);
    return (React.createElement("div", { id: "side-menu-title" },
        React.createElement(Button, { type: "text", className: `${stylesMobileSideMenu.stickyMenuLabel} d-flex align-items-center m-0`, onClick: toggleDrawer },
            React.createElement(FaUserFriends, null),
            React.createElement("div", { className: "MobileSideMenu__sticky-menu-label-text" }, t('GROUP__NAV_LINK'))),
        React.createElement(Drawer, { className: stylesMobileSideMenu.drawer, title: React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center" },
                React.createElement(Title, { className: stylesMobileSideMenu.drawer__title }, t('GROUP__NAV_LINK')),
                canCreateAndDeleteGroup && (React.createElement(QuickAddItemButton, { definitionId: GROUP_CMS_DEFINITION_ID, entityTitle: entityTitle, loading: saving, onClick: (groupId) => {
                        handleSelectGroup([groupId]);
                    }, customButtonComponent: ({ onClick }) => (React.createElement(Button, { type: "text", onClick: onClick, className: `${styles.container__sectionCardHeadingCreate} SectionCardHeadingCreate h-auto ms-auto position-relative p-0 d-flex align-items-center` },
                        React.createElement(Tooltip, { placement: "top", title: t('ADD_CONDITION_GROUP') },
                            React.createElement(FaUserFriends, { className: "team" }),
                            React.createElement("div", { className: "plus" },
                                React.createElement(FaPlus, null))))) })),
                React.createElement(CollapseExpand, { expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, searchText: searchText, parentGroupMap: parentGroupMap })), placement: "left", closable: false, onClose: toggleDrawer, open: isDrawerOpen },
            React.createElement(Hierarchy, { ...otherProps, canCreateAndDeleteGroup: canCreateAndDeleteGroup, entityTitle: entityTitle, saving: saving, handleSelectGroup: handleSelectGroup, expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, searchText: searchText, parentGroupMap: parentGroupMap, toggleMobileDrawer: toggleDrawer }))));
};
