// Dependencies: {translations: ['COMMON__NOT_FOUND_TEXT', 'HOME_NAV_LINK']}
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useI18n } from '../../i18n';
import { Wrapper, media } from '../../styling';
const Container = styled.div `
  color: black;
  height: 100%;
  position: relative;
  min-height: 20rem;
`;
const Message = styled.div `
  color: ${({ theme }) => theme.textColor};
  margin: 0 auto;
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;

  ${media.sm} {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  a {
    color: ${({ theme }) => theme.mainColor};

    &:hover {
      color: ${({ theme }) => theme.mainColorVibrant};
    }
  }
`;
const HeadsUp = styled.div `
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 600;
  color: rgba(0, 83, 139, 0.1);
  margin: 1rem 0;
  ${media.sm} {
    font-size: 6rem;
    line-height: 6rem;
  }
`;
const Content = styled.span `
  max-width: 375px;
`;
const NotFound = ({ className, title, content }) => {
    const { t } = useI18n();
    return (React.createElement(Container, { className: className },
        React.createElement(Wrapper, null,
            React.createElement(Message, null,
                React.createElement(HeadsUp, null, title ? title : 'Oops!'),
                React.createElement(Content, null, content ? (content) : (React.createElement(React.Fragment, null,
                    t('COMMON__NOT_FOUND_TEXT'),
                    React.createElement(Link, { to: "/" },
                        " ",
                        t('HOME_NAV_LINK')))))))));
};
export default NotFound;
