import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import MultiTextEditor from '../../../../../cms/data/components/item-property/editors/MultiTextEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
export const getGeneralFormSchema = () => {
    return {
        customHosts: Yup.array().of(Yup.string())
    };
};
const GeneralOptionsForm = ({ configuration, disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "row gy-3" },
        React.createElement("div", { className: "col-12" },
            React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION__GENERAL__CUSTOM_HOSTS') }),
            React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, value: configuration.customHosts, disabled: disabled }))));
};
export default GeneralOptionsForm;
