import React from 'react';
import { ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP } from '../../../../../groups';
import ItemMetaForm from './ItemMetaForm';
const ItemMetaFormContainer = ({ entityMeta, entityMetaId, setLinks, setCurrentBreadcrumbText, create, isSystem, ...otherProps }) => {
    const formValues = {
        titles: entityMeta?.titles || {},
        custom: entityMeta?.custom || {},
        viewGroups: entityMeta?.viewGroups || [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
        createGroups: entityMeta?.createGroups || [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
        deleteGroups: entityMeta?.deleteGroups || [ADMINS_PERMISSION_GROUP],
        permissions: entityMeta?.permissions || undefined
    };
    return (React.createElement(ItemMetaForm, { formValue: formValues, entityMeta: entityMeta, entityMetaId: entityMetaId, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, create: create, isSystem: isSystem, ...otherProps }));
};
export default ItemMetaFormContainer;
