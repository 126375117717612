import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { saveConfig } from '../../../configs';
import { SETTINGS_KEY, XDMS_KEY } from '../../constants';
export const formName = [SETTINGS_KEY, XDMS_KEY].join('_');
export const xdmsFields = {
    contact: [
        'ADcontact',
        'ADcontact2',
        'ADemail',
        'ADmail-private',
        'CDadres',
        'CDcnty',
        'CDcontact',
        'CDintrest',
        'CDlang',
        'CDmail',
        'CDrel',
        'CDstatus',
        'CDstic',
        'CIcontact',
        'DAchange',
        'DAcreate',
        'INcontact',
        'INcontact2',
        'INcontact3',
        'NMaddr',
        'NMcontact',
        'NMcontact2',
        'NMini',
        'NRcontact',
        'NRfax',
        'NRgsm',
        'NRrel',
        'NRsema',
        'NRtel',
        'NRtel-mob2',
        'NRtel2',
        'NRzip',
        'TIcomm',
        'TIcontact',
        'USchange',
        'UScreate',
        'v-ADurl',
        'v-CDrel-rel',
        'v-INsync',
        'v-NRcust-rel-contact',
        'v-YNemail1',
        'v-YNletter1',
        'v-YNsms1',
        'v-YNtel-mob1',
        'v-YNtel1',
        'YNevents',
        'YNmail-comm',
        'YNmail-news',
        'YNsend-pr',
        'YNskip-mail'
    ],
    organization: [
        'ADaccx',
        'ADcust',
        'ADcust-dlv',
        'ADcust-dlv2',
        'ADcust-inv',
        'ADcust-inv2',
        'ADcust2',
        'ADemail',
        'ADemail2',
        'ADurl',
        'CDacces',
        'CDaccx',
        'CDadres',
        'CDbranch',
        'CDcie',
        'CDcivil',
        'CDcnd-transprt-frn',
        'CDcnty',
        'CDcnty-accx',
        'CDcnty-dlv',
        'CDcnty-inv',
        'CDcnty-orig',
        'CDcontac',
        'CDcontact',
        'CDcontact-service',
        'CDctrl',
        'CDcurr',
        'CDcurr-accx',
        'CDcust',
        'CDcust-com',
        'CDcust-disc',
        'CDcust-frn',
        'CDdecision',
        'CDdepartment',
        'CDdistri',
        'CDexternal',
        'CDfin-list',
        'CDhalt',
        'CDhalt-frn',
        'CDid-exterrnal',
        'CDintra-load',
        'CDintrest',
        'CDjrnl',
        'CDlang',
        'CDlic',
        'CDloc',
        'CDmail',
        'CDmarketing',
        'CDnotify',
        'CDoccupation',
        'CDopt',
        'CDord-type-cust',
        'CDord-type-frn',
        'CDpay-frn',
        'CDpaycon',
        'CDprint-opt',
        'CDprov',
        'CDprov-dlv',
        'CDprov-inv',
        'CDref-pr-ln',
        'CDsex',
        'CDsize-cie',
        'CDsolvability',
        'CDsrc',
        'CDstatus',
        'CDstic',
        'CDtransp',
        'CDtransprt-frn',
        'CDxtra-inv',
        'CIaccx',
        'CIbirth',
        'CIcust',
        'CIcust-dlv',
        'CIcust-inv',
        'CIlic-dlv',
        'CNcar',
        'CNcn-year',
        'CNinv',
        'CNkids',
        'CNmonths-renew',
        'CNstaff',
        'DAbirth',
        'DAchange',
        'DAcreate',
        'DAexpire-kc',
        'DAhalt',
        'DAhalt-frn',
        'DAlast-visit',
        'DAlic-dlv',
        'DAsolvability',
        'DTsync',
        'INaccx',
        'INcomm-histo',
        'INcontact',
        'INcust',
        'INemployer',
        'INfind',
        'INhalt',
        'INhalt-frn',
        'INjob',
        'INloc',
        'INoccupation',
        'INsector',
        'INsolvability',
        'MAcredit',
        'MApr-sub',
        'NMaccx',
        'NMcall',
        'NMcontact',
        'NMcust',
        'NMcust-int',
        'NMcust2',
        'NMfam',
        'NMini',
        'NMsolvablilty',
        'NRacc',
        'NRacc2',
        'NRaccx',
        'NRarchive',
        'NRbox',
        'NRbox-dlv',
        'NRbox-inv',
        'NRbuyer',
        'NRcard',
        'NRcommerce',
        'NRcontact',
        'NRconvert',
        'NRcust',
        'NRcust-create',
        'NRcust-ext',
        'NRcust-frn',
        'NRcust-master',
        'NRdealer',
        'NRdealer-master',
        'NRdom',
        'NRdomicil',
        'NRfax',
        'NRfleet',
        'NRhouse',
        'NRhouse-dlv',
        'NRhouse-inv',
        'NRinfo',
        'NRinv-version',
        'NRkc',
        'NRlic',
        'NRnational',
        'NRperson',
        'NRproject',
        'NRtech',
        'NRtel',
        'NRtel-mob',
        'NRtel-mob2',
        'NRtel2',
        'NRvat',
        'NRvendor',
        'NRwh',
        'NRzip',
        'NRzip-dlv',
        'NRzip-inv',
        'PRmi-ord',
        'PRord-min',
        'TIcomm',
        'TIcust',
        'TIletter',
        'TIoccupation',
        'USchange',
        'UScreate',
        'UShalt',
        'UShalt-frn',
        'USsolvability',
        'VAsolvability',
        'YNcie',
        'YNcontact',
        'YNcontact-yearly',
        'YNctrl',
        'YNcust',
        'YNemail',
        'YNevents',
        'YNfax',
        'YNfrn',
        'YNhalt',
        'YNhalt-frn',
        'YNletter',
        'YNmail-comm',
        'YNmail-news',
        'YNmodel-info',
        'YNprof',
        'YNpros',
        'YNskip-mail',
        'YNskip-mail2',
        'YNsms',
        'YNstat',
        'YNtel',
        'YNtel-mob'
    ]
};
export const onXdmsSettingsFormSubmit = (formValues, dispatch, { xdmsConfig, t }) => dispatch(saveConfig({
    ...xdmsConfig,
    configType: SETTINGS_KEY,
    configId: XDMS_KEY,
    data: formValues
}))
    // @ts-expect-error
    .then(() => {
    dispatch(success({
        title: t('SUCCESS'),
        message: t('SAVE_SETTINGS_SUCCESS')
    }));
})
    .catch((e) => {
    dispatch(error({
        title: t('ERROR'),
        message: t('SAVE_SETTINGS_FAIL')
    }));
});
