import { createReducer } from '../utils';
import { union } from 'lodash';
const initialState = {
    definitions: [],
    definitionById: {},
    loading: false,
    failed: false
};
const reducer = createReducer(initialState, {
    METADATA__FETCH_DEFINITIONS: (s) => {
        s.loading = true;
    },
    METADATA__FETCH_DEFINITIONS_SUCCESS: (s, a) => {
        s.definitions = a.payload.map(d => d.id);
        s.loading = false;
        s.failed = false;
        a.payload.forEach(d => {
            s.definitionById[d.id] = d;
        });
    },
    METADATA__FETCH_DEFINITIONS_FAIL: (s) => {
        s.loading = false;
        s.failed = true;
    },
    METADATA__FETCH_DEFINITION_SUCCESS: (s, a) => {
        s.definitionById[a.payload.id] = a.payload;
        s.loading = false;
    },
    METADATA__DEFINITION_DELETE_SUCCESS: (s, a) => {
        const id = a.meta.actionContext.id;
        s.definitions = s.definitions.filter(d => d != id);
        delete s.definitionById[id];
    },
    METADATA__DEFINITION_SAVE_SUCCESS: (s, a) => {
        const values = a.payload;
        s.definitions = union(s.definitions, [values.id]);
        s.definitionById[values.id] = values;
    }
});
export default reducer;
