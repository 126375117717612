import { isGTE } from '@hypercharge/hyper-react-base/lib/browser';
import memoize from 'fast-memoize';
import { map } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUiAuthenticated, hasTenantPermission } from '../../auth';
import { REQUIRED_CMS_PERMISSION } from '../../cms';
import useDisplayItemMetaList from '../../cms/hooks/useDisplayItemMetaList';
import { getBrowserState, getConfigsState } from '../../common/reducers/rootSelector';
import { fetchConfig, getConfig, isFetchConfigPending } from '../../configs';
import { useDashboards } from '../../hyperbi/components/dashboards/DashboardsProvider';
import { CMS_KEY, SETTINGS_KEY } from '../../settings';
import AppHeader from './components/AppHeader';
import { getMenu, nestedFeaturedCmsDefinitionIds } from './utils';
const getHasPermission = memoize((s) => (permission) => hasTenantPermission(s, permission));
const Header = ({ className }) => {
    const dispatch = useDispatch();
    const { dashboards } = useDashboards();
    const isMobile = useSelector((s) => !isGTE(getBrowserState(s), 'md'));
    const isAuthenticated = useSelector((s) => getIsUiAuthenticated(s));
    const hasPermission = useSelector((s) => getHasPermission(s));
    const hasRequiredCmsPermission = hasPermission(REQUIRED_CMS_PERMISSION);
    const configsState = useSelector((s) => getConfigsState(s));
    const isCmsConfigPending = isFetchConfigPending(configsState, SETTINGS_KEY, CMS_KEY);
    const cmsConfig = getConfig(configsState, SETTINGS_KEY, CMS_KEY);
    const { data: entities } = useDisplayItemMetaList();
    useEffect(() => {
        if (hasRequiredCmsPermission && !cmsConfig && !isCmsConfigPending) {
            dispatch(fetchConfig(SETTINGS_KEY, CMS_KEY));
        }
    }, [dispatch, hasRequiredCmsPermission, cmsConfig, isCmsConfigPending]);
    const menu = useMemo(() => getMenu(hasPermission, map(entities, 'definitionId'), dashboards), [hasPermission, entities, dashboards]);
    const featuredDefinitionIds = useMemo(() => [...(cmsConfig?.data?.featuredDefinitionIds || []), ...nestedFeaturedCmsDefinitionIds], [cmsConfig]);
    return (React.createElement(AppHeader, { className: className, isMobile: isMobile, isAuthenticated: isAuthenticated, menu: menu, featuredDefinitionIds: featuredDefinitionIds }));
};
export default Header;
