import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { isAxiosError } from '../../../common/utils/request';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { useDisplayTenant } from '../../../tenant/useDisplayTenant';
import { requestOAuth2Code } from '../../actions';
import { redirectIfNoPermission } from '../../authRedirectWrappers';
import authStyles from '../AuthRoutes.module.scss';
import { Logo } from '../common/Logo';
const OAuth2FormPage = ({ location }) => {
    const { t } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const { isAuthenticated } = useMyContact();
    const dispatch = useDispatch();
    const dispatchOAuth2Code = useDispatch();
    const oAuth2Params = useMemo(() => {
        const parsedQuerySearch = new URLSearchParams(location.search);
        const clientId = parsedQuerySearch.get('client_id');
        const redirectUri = parsedQuerySearch.get('redirect_uri');
        const responseType = parsedQuerySearch.get('response_type');
        const scope = parsedQuerySearch.get('scope');
        const state = parsedQuerySearch.get('state');
        if (typeof clientId !== 'string') {
            return;
        }
        if (typeof redirectUri !== 'string') {
            return;
        }
        if (responseType !== 'code') {
            return;
        }
        return {
            clientId,
            redirectUri,
            responseType,
            scope: scope ?? undefined,
            state: state ?? undefined
        };
    }, [location.search]);
    const { isLoading, mutateAsync: codeRequest } = useMutation({
        mutationFn: async (params) => {
            return dispatchOAuth2Code(requestOAuth2Code(params.clientId, params.redirectUri, params.responseType, params.scope));
        }
    });
    const fetchOAuth2Code = useCallback(async () => {
        if (!oAuth2Params) {
            return;
        }
        try {
            const result = await codeRequest(oAuth2Params);
            if (result.code) {
                const optionalParams = {};
                if (oAuth2Params.state) {
                    optionalParams.state = oAuth2Params.state;
                }
                if (oAuth2Params.scope) {
                    optionalParams.scope = oAuth2Params.scope;
                }
                const searchParams = new URLSearchParams({ ...optionalParams, code: result.code });
                const redirectUri = `${oAuth2Params.redirectUri}?${searchParams.toString()}`;
                window.location.replace(redirectUri);
            }
        }
        catch (err) {
            if (isAxiosError(err)) {
                dispatch(error({
                    title: t('ERROR'),
                    message: err.response?.data?.message ?? err.message
                }));
            }
            else {
                console.error('fetchOAuth2Code Error', err);
            }
        }
    }, [codeRequest, dispatch, oAuth2Params, t]);
    useEffect(() => {
        if (isAuthenticated) {
            void fetchOAuth2Code();
        }
    }, [isAuthenticated, fetchOAuth2Code]);
    return (React.createElement("div", { className: `${authStyles.authContainer} position-relative overflow-hidden` },
        React.createElement(Helmet, { title: `${t('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant?.name ?? ''}` }),
        React.createElement(Logo, null),
        React.createElement("div", null, isLoading && React.createElement(LoadingIndicator, null))));
};
export default redirectIfNoPermission(OAuth2FormPage);
