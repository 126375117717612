import { useEffect, useMemo, useState } from 'react';
import { TITLE_PROPERTY_ID } from '../../cms';
import { useSearchItems } from '../../cms/hooks/useSearchItems';
import { getParentGroupMap } from '../components/Hierarchy/utils';
import { GROUP_CMS_DEFINITION_ID, PARENT_GROUP_PROPERTY_ID } from '../constants';
export const useGroups = () => {
    const [parentGroupMap, setParentGroupMap] = useState();
    const { data: { results: groups } = {}, isAvailable, isFailed, isPending } = useSearchItems({
        definitionId: GROUP_CMS_DEFINITION_ID,
        filterRequest: {
            limit: 300,
            responseFields: [TITLE_PROPERTY_ID, PARENT_GROUP_PROPERTY_ID]
        }
    });
    useEffect(() => {
        if (groups) {
            setParentGroupMap(getParentGroupMap(groups));
        }
    }, [groups]);
    return useMemo(() => ({
        groups: groups || [],
        groupsStatus: {
            isAvailable,
            isFailed,
            isPending
        },
        parentGroupMap: parentGroupMap || {}
    }), [groups, isAvailable, isFailed, isPending, parentGroupMap]);
};
