import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveFunnel } from '@nivo/funnel';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { random, toString } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CMS_PATH } from '../../../../../cms';
import { AggregationType, useAggregation } from '../../../../../cms/hooks/useAggregation';
import useDisplayItemMeta from '../../../../../cms/hooks/useDisplayItemMeta';
import { BROWSE_PATH, PROCESS_META_HANDLER } from '../../../../../common/utils/constants';
import { LoadingBlock } from '../../../../../common/utils/formUtils';
import { CONDITION_QUERY_KEY, stringifyConditionQuery } from '../../../../../views';
import { PROCESSES_PATH, WORKFLOWS_PATH } from '../../../../../workflows';
import { getSubAggregation } from '../../aggregations';
import { isAllowManyMetrics } from './ChartForm/utils';
import styles from './ChartWidget.module.scss';
import { getBarProps, getFunnelProps, getHeatMapProps, getLineProps, getMetricProps, getPieProps } from './chartPropsUtils';
import { aggregationToFilters, getAutoInterval } from './chartUtils';
import MetricChart from './charts/Metric';
const theme = {};
function prepareAggregations(aggregations, defaultFilter) {
    return aggregations.map((item) => {
        const newItem = { ...item };
        if ('aggregations' in newItem && newItem.aggregations?.length) {
            newItem.aggregations = prepareAggregations(newItem.aggregations, defaultFilter);
        }
        if (newItem.type === AggregationType.dateHistogram && newItem.interval === 'auto') {
            const interval = getAutoInterval(defaultFilter?.startTime, defaultFilter?.endTime);
            const newAgg = {
                ...newItem,
                interval
            };
            return newAgg;
        }
        return newItem;
    });
}
const ChartWidget = ({ settings, defaultFilter, className }) => {
    const { t, language } = useI18n();
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId: settings.definitionId
    });
    const isProcess = useMemo(() => {
        return displayItemMeta?.handler === PROCESS_META_HANDLER;
    }, [displayItemMeta?.handler]);
    const ref = useRef(null);
    const [chartWidth, setChartWidth] = useState();
    const updateChartWidth = useDebouncedCallback(() => {
        setChartWidth(ref.current?.getBoundingClientRect()?.width);
    }, 500); // This prevents the data to be fetched for only the 'title' column, which is what the route starts with and then gets enhanced with the columns based on the view queryParams
    const aggregations = useMemo(() => {
        return prepareAggregations(settings.aggregations, defaultFilter);
    }, [defaultFilter, settings.aggregations]);
    useEffect(() => {
        if (aggregations[0]?.type !== AggregationType.dateHistogram) {
            return;
        }
        window.addEventListener('resize', updateChartWidth);
        updateChartWidth();
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, [ref, aggregations, updateChartWidth, settings.layout?.w]);
    const metrics = useMemo(() => {
        if (!isAllowManyMetrics(settings.chart.type)) {
            return [settings.metrics[0]];
        }
        return settings.metrics;
    }, [settings.chart.type, settings.metrics]);
    const conditionalQuery = useMemo(() => {
        const filters = [];
        if (defaultFilter?.startTime) {
            filters.push({
                field: 'createdAt',
                operator: FilterOperatorTypes.greaterThanOrEqual,
                data: defaultFilter.startTime
            });
        }
        if (defaultFilter?.endTime) {
            filters.push({
                field: 'createdAt',
                operator: FilterOperatorTypes.lessThan,
                data: defaultFilter.endTime
            });
        }
        if (!filters.length) {
            return;
        }
        return {
            condition: ConditionType.and,
            filters: filters
        };
    }, [defaultFilter?.endTime, defaultFilter?.startTime]);
    const { isLoading, data } = useAggregation({
        definitionId: settings.definitionId,
        aggregations,
        metrics,
        filter: settings.filter,
        globalFilter: conditionalQuery
    });
    const onClick = useCallback((datum) => {
        const query = {
            condition: ConditionType.and,
            filters: []
        };
        if (settings.filter.query.filters.length) {
            query.filters.push(settings.filter.query);
        }
        if (conditionalQuery && conditionalQuery.filters.length) {
            query.filters.push(conditionalQuery);
        }
        if (!aggregations.length) {
            // metric chart not have aggregations
        }
        else if ('filter' in datum.data &&
            datum.data.filter &&
            datum.data.filter[datum.id] &&
            Array.isArray(datum.data.filter[datum.id]) &&
            datum.data.filter[datum.id].length) {
            query.filters.push(...datum.data.filter[datum.id]);
        }
        else if ('serieId' in datum) {
            // heatmap chart
            const subAggregation = getSubAggregation(aggregations[0]);
            if (subAggregation) {
                query.filters.push(...aggregationToFilters(aggregations[0], datum.serieId));
                query.filters.push(...aggregationToFilters(subAggregation, datum.data.x));
            }
        }
        else {
            let value = datum.data.value;
            if (aggregations[0].type === AggregationType.term) {
                // funnel chart
                value = datum.data.label;
            }
            query.filters.push(...aggregationToFilters(aggregations[0], value));
        }
        const viewAllUrl = `${isProcess ? `${WORKFLOWS_PATH}${PROCESSES_PATH}` : CMS_PATH}/${settings.definitionId}${BROWSE_PATH}?${CONDITION_QUERY_KEY}=${stringifyConditionQuery(query)}`;
        window.open(viewAllUrl, '_blank');
    }, [aggregations, conditionalQuery, isProcess, settings.definitionId, settings.filter.query]);
    const chartComponent = useMemo(() => {
        if (!isLoading) {
            if (data) {
                const newSettings = { ...settings, aggregations: aggregations };
                const pieProps = getPieProps(newSettings, data, language);
                const funnelProps = getFunnelProps(newSettings, data, language);
                const metricProps = getMetricProps(newSettings, data, language);
                const lineProps = getLineProps(newSettings, data, language, chartWidth);
                const barProps = getBarProps(newSettings, data, language);
                const heatMapProps = getHeatMapProps(newSettings, data, language, chartWidth);
                const key = toString(random(9999999));
                if (pieProps) {
                    return React.createElement(ResponsivePie, { key: key, theme: theme, onClick: onClick, ...pieProps });
                }
                else if (heatMapProps) {
                    return React.createElement(ResponsiveHeatMap, { key: key, onClick: onClick, theme: theme, ...heatMapProps });
                }
                else if (funnelProps) {
                    return React.createElement(ResponsiveFunnel, { key: key, onClick: onClick, theme: theme, ...funnelProps });
                }
                else if (metricProps) {
                    return React.createElement(MetricChart, { key: key, onClick: onClick, ...metricProps });
                }
                else if (lineProps) {
                    return React.createElement(ResponsiveLine, { key: key, theme: theme, ...lineProps });
                }
                else if (barProps) {
                    return React.createElement(ResponsiveBar, { key: key, theme: theme, onClick: onClick, ...barProps });
                }
            }
            return (React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center" }, t('NO_DATA')));
        }
        return null;
    }, [aggregations, chartWidth, data, language, isLoading, onClick, settings, t]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        React.createElement("div", { className: "widget-title drag-handler" }, settings.chart.title?.text?.[language] || ''),
        React.createElement("div", { className: "w-100 widget-body" }, isLoading ? React.createElement(LoadingBlock, null) : chartComponent)));
};
export default ChartWidget;
