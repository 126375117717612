import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import FullScreenPreview from '../../../../common/components/full-screen-preview';
import LoadingIndicator from '../../../../common/components/loading-indicator';
import ActivityDiagramRenderer from '../../../meta/components/activity-settings/activity-diagram/ActivityDiagramRenderer';
import { useNodes } from '../../../meta/components/activity-settings/activity-diagram/useNode';
import { getEdges, getNodes } from '../../../meta/components/activity-settings/activity-diagram/utils';
import { getTaskOutput } from '../../../tasks/actions';
import styles from './ProcessOverview.module.scss';
const ProcessOverview = ({ processActivity, tasks, processDetails, closePreview }) => {
    const { language } = useI18n();
    const [selectedNode, setSelectedNode] = useState();
    const { data: nodesDescription } = useNodes();
    const [outputNodeLoader, setOutputNodeLoader] = useState(false);
    const [outputNode, setOutputNode] = useState();
    const dispatchGetTaskOutput = useDispatch();
    const fetchTaskOutput = useCallback(async () => {
        if (!selectedNode?.id) {
            return;
        }
        setOutputNodeLoader(true);
        try {
            const output = await dispatchGetTaskOutput(getTaskOutput(processDetails.processMetaId, processDetails.processRunId, selectedNode?.id));
            setOutputNode(output || selectedNode?.output);
        }
        catch (err) {
            console.warn('Err', err);
        }
        finally {
            setOutputNodeLoader(false);
        }
    }, [
        dispatchGetTaskOutput,
        processDetails.processMetaId,
        processDetails.processRunId,
        selectedNode?.id,
        selectedNode?.output
    ]);
    useEffect(() => {
        void fetchTaskOutput();
    }, [fetchTaskOutput]);
    const nodes = useMemo(() => {
        const successesNodes = [processActivity.Start];
        const errorNodes = {};
        const availableNodes = {};
        for (const [id, node] of Object.entries(processActivity)) {
            const task = tasks.find((task) => {
                return task.taskMetaId == node.id;
            });
            switch (task?.status) {
                case 'SUCCEEDED':
                    successesNodes.push(node);
                    break;
                case 'FAILED':
                case 'CANCELLED':
                    errorNodes[id] = true;
                    break;
                case 'RUNNING':
                case 'WAITING':
                    availableNodes[id] = true;
                    // For nodes type Delay and in active status - show calculated resolveTime
                    if (processActivity[node.id]['parameters'] && task['resolveTime']) {
                        processActivity[node.id]['parameters'].resolveTime = task['resolveTime'];
                    }
                    break;
                default:
                    break;
            }
        }
        switch (processDetails.status) {
            case 'SUCCEEDED':
                successesNodes.push(processActivity.End);
                break;
            case 'FAILED':
            case 'CANCELLED':
                errorNodes.End = true;
                break;
            default:
                break;
        }
        return getNodes(processActivity, successesNodes, language, errorNodes, availableNodes);
    }, [language, processActivity, processDetails.status, tasks]);
    const onNodeDblClick = useCallback((id) => {
        setSelectedNode(processActivity[id]);
    }, [processActivity]);
    const handleCancel = useCallback(() => {
        setSelectedNode(undefined);
    }, []);
    const dialogTitle = `Output: ${selectedNode?.type || ''}`;
    return (React.createElement(ErrorBoundary, null,
        React.createElement(FullScreenPreview, { closePreview: closePreview },
            React.createElement(ActivityDiagramRenderer, { className: styles.wrapper, nodes: nodes, edges: getEdges(processActivity, nodesDescription?.nodes || {}), readonly: true, onNodeDblClick: onNodeDblClick }),
            React.createElement(Modal, { width: "70%", zIndex: 3000, centered: true, title: dialogTitle, open: !!selectedNode, onOk: handleCancel, onCancel: handleCancel, cancelButtonProps: { style: { display: 'none' } } },
                outputNodeLoader ? React.createElement(LoadingIndicator, null) : null,
                !outputNodeLoader && outputNode ? (React.createElement(ReactJson, { src: outputNode, displayDataTypes: false, displayObjectSize: true, collapsed: 2, enableClipboard: false, indentWidth: 2, sortKeys: true, name: false })) : null))));
};
export default ProcessOverview;
