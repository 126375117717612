import { TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { MdArrowDropDown } from 'react-icons/md';
import styled from 'styled-components';
export const CircleSkeleton = styled(TextPlaceholder) `
  margin-right: 0.5rem;
`;
export const DropDownStyled = styled(MdArrowDropDown) `
  font-size: 2.5rem;
  margin-left: 0.5rem;
  height: 2rem;
  color: var(--neutralColor-6);
`;
