import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Select } from 'antd';
import cn from 'classnames';
import { isUndefined } from 'lodash';
import React, { useCallback } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Tooltip } from '../Tooltip';
import styles from './BasicFilter.module.scss';
import { buildFilter, isOperatorWithoutData } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.notEmpty },
    { value: FilterOperatorTypes.empty }
];
const BaseFilter = ({ className, filter, onChange, onClear, operators, showSelectOperator = true, disabledSelectOperator, disabled, children }) => {
    const { t } = useI18n();
    operators = operators || availableOperators;
    const onChangeOperator = useCallback((newOperator) => {
        const newFilter = buildFilter(filter.field, newOperator, 'data' in filter ? filter.data : undefined);
        onChange(newFilter);
    }, [filter, onChange]);
    return (React.createElement("div", { className: `d-flex flex-row w-100 flex-basis-100 overflow-hidden gap-3 ${className || ''} ${styles.wrapper}` },
        showSelectOperator && (React.createElement(Tooltip, { showTooltip: !!disabledSelectOperator, title: t('OPERATOR_UNAVAILABLE_FOR_QUICK_FILTERS') },
            React.createElement(Select, { allowClear: !!onClear, className: cn(isUndefined(filter.operator) || isOperatorWithoutData(filter.operator)
                    ? 'flex-basis-100'
                    : 'flex-basis-40', 'overflow-hidden'), value: filter.operator, onSelect: onChangeOperator, onClear: () => {
                    onClear?.(filter);
                }, suffixIcon: React.createElement(MdKeyboardArrowDown, { size: 16 }), disabled: disabled || disabledSelectOperator, options: operators.map(({ value, label }) => ({
                    value,
                    label: t(label || value)
                })) }))),
        !isOperatorWithoutData(filter.operator) && children && (React.createElement("div", { className: "flex-fill flex-basis-60 overflow-hidden" }, children))));
};
export default BaseFilter;
