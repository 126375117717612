import { getFlattenedDisplayDataList } from '../../../utils';
export function convertToEntityItem(values, displayData) {
    const propertiesList = getFlattenedDisplayDataList(displayData);
    const properties = propertiesList.reduce((acc, property) => {
        acc[property.propertyId] = property;
        return acc;
    }, {});
    const entityItem = Object.entries(values).reduce((acc, [propertyId, value]) => {
        if (value.enabled) {
            switch (properties[propertyId].type) {
                case 'file':
                    if (Array.isArray(value.value)) {
                        acc[propertyId] = value.value.map((item) => item.id);
                    }
                    else {
                        acc[propertyId] = value.value;
                    }
                    break;
                default:
                    acc[propertyId] = value.value;
                    break;
            }
        }
        return acc;
    }, {});
    return entityItem;
}
