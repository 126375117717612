import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import { useEntityDisplayData } from '../../../../../../cms/common/components/withEntityDisplayData';
import { Drawer } from '../../../../../../common/components/Drawer';
import EditViewForm from '../../../../../../views/components/EditViewForm';
import { buildMetricId } from '../../../../../../views/utils';
const ViewEditorDrawer = ({ entityGroupIdx, viewIdx, entityIdx, definitionId, views, onClose, setFieldValue }) => {
    const { t } = useI18n();
    const { displayData } = useEntityDisplayData(definitionId);
    const pathToViews = `configuration.availableEntities.${entityGroupIdx}.entities.${entityIdx}.views`;
    const path = `${pathToViews}.${viewIdx}`;
    const resetDefaultView = useCallback(() => {
        /**
         * Make default only selected, uncheck default in others
         */
        views.forEach((view, viewIndex) => {
            setFieldValue(`${pathToViews}.${viewIndex}.isDefault`, viewIdx === viewIndex);
        });
    }, [pathToViews, setFieldValue, viewIdx, views]);
    const _onClose = useCallback((formValues) => {
        if (!formValues) {
            return;
        }
        const metrics = formValues.metrics.map((metric) => {
            const { titles, field, query, metric: aggregationType } = metric;
            return {
                ...metric,
                id: buildMetricId({ titles, metric: aggregationType, field, query })
            };
        });
        const view = {
            title: formValues.title || '',
            isDefault: formValues.isDefault || false,
            referenceDefinitionId: '',
            metrics,
            filters: {
                sortBy: formValues.sortBy,
                query: formValues.conditionQuery,
                responseFields: formValues.columns,
                fullTextFields: formValues.columns
            },
            entityId: views[viewIdx].entityId
        };
        setFieldValue(path, view);
        if (view.isDefault) {
            resetDefaultView();
        }
        onClose();
    }, [onClose, resetDefaultView, path, setFieldValue, viewIdx, views]);
    const view = useMemo(() => views[viewIdx], [viewIdx, views]);
    if (!displayData ||
        entityGroupIdx == null ||
        viewIdx == null ||
        entityIdx == null ||
        definitionId == null) {
        return null;
    }
    return (React.createElement(Drawer, { open: true, className: "bg-transparent" },
        React.createElement(EditViewForm, { onCloseCallback: _onClose, definitionId: definitionId, filterRequest: view.filters, metrics: view.metrics, rowHeightType: view.rowHeightType, noKanban: true, title: view.title, isDefault: view.isDefault, showIsDefault: true, showTitle: true, submitText: t('COMMON__MODAL_CLOSE'), canEditGroupingRow: false })));
};
export default ViewEditorDrawer;
