import * as Yup from 'yup';
import { MultiLanguageValidationSchema } from '../../../../../../common/components/MultiLanguageField';
export const BaseStepSchema = Yup.object().shape({
    id: Yup.string().required(),
    titles: MultiLanguageValidationSchema,
    hidden: Yup.boolean()
});
export const BaseSectionSchema = Yup.object().shape({
    id: Yup.string().required(),
    titles: MultiLanguageValidationSchema,
    hidden: Yup.boolean()
});
