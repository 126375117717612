import ArrowSection from '@hypercharge/hyper-react-base/lib/common/arrow-section';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import axios from 'axios';
import { compact } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { useDisplayTenant } from '../../../tenant/useDisplayTenant';
import { logIn } from '../../actions';
import { redirectIfAuthenticated } from '../../authRedirectWrappers';
import { AUTH_PATH } from '../../constants';
import authStyles from '../AuthRoutes.module.scss';
import { Logo } from '../common/Logo';
import { handleFailure } from '../common/utils';
import styles from './ChooseAccountPage.module.scss';
const ChooseAccountPage = ({ location }) => {
    const { t } = useI18n();
    const [loading, setLoading] = useState(false);
    const { displayTenant } = useDisplayTenant();
    const dispatchLogin = useDispatch();
    const dispatch = useDispatch();
    const onFailedLogin = useCallback(() => {
        handleFailure(dispatch);
    }, [dispatch]);
    const onLogIn = useCallback(async (entityId) => {
        if (!displayTenant?.id) {
            return;
        }
        setLoading(true);
        if (!location.state?.email || !location.state?.code) {
            onFailedLogin();
            return;
        }
        try {
            await dispatchLogin(logIn(displayTenant.id, entityId, location.state.email, location.state.code));
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                dispatch(replace({
                    ...location,
                    pathname: AUTH_PATH
                }));
            }
            else {
                onFailedLogin();
            }
        }
        setLoading(false);
        return;
    }, [dispatch, dispatchLogin, displayTenant?.id, location, onFailedLogin]);
    if (!displayTenant) {
        return null;
    }
    const email = location.state?.email;
    const factorAccounts = location.state?.factorAccounts;
    return email && factorAccounts ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${authStyles.authContainer} ${styles.wrapper} position-relative overflow-hidden border-bottom-0` },
            React.createElement(Helmet, { title: `${t('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant.name}` }),
            React.createElement(Logo, null),
            React.createElement("div", { className: "mt-5" },
                React.createElement("div", { className: `${authStyles.title} fw-600 mb-2` }, t('AUTH_CHOOSE_ACCOUNT')),
                React.createElement("div", { className: `${authStyles.subtitle} mb-0` },
                    React.createElement("div", null, t('AUTH_MULTIPLE_ACCOUNTS_ARE_LINKED_TO')),
                    React.createElement("span", { className: "fw-600" }, email)))),
        React.createElement("div", { className: `${styles.accounts} position-relative mt-5` },
            loading && React.createElement(LoadingIndicator, null),
            factorAccounts.map((factorAccount) => (React.createElement(ArrowSection, { key: factorAccount.entityId, className: `${styles.account}`, disabled: loading, "data-test-id": "button-choose-account", onClick: () => {
                    void onLogIn(factorAccount.entityId);
                } },
                React.createElement("div", { className: `${authStyles.subtitle} ${styles.name} fw-600 mb-0 p-3 p-sm-5 py-sm-3` }, compact([factorAccount.firstName, factorAccount.lastName]).join(' ')))))))) : (React.createElement(Redirect, { to: { pathname: AUTH_PATH, search: location.search, hash: location.hash } }));
};
export default redirectIfAuthenticated(ChooseAccountPage);
