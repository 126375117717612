import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput, BasicTextArea, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert, Switch, Tooltip } from 'antd';
import { flatMap, get } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import MultiLanguageField from '../../../common/components/MultiLanguageField/MultiLanguageField';
import { SETTINGS_PATH } from '../../../common/utils/constants';
import { FieldsContainer } from '../../../common/utils/formUtils';
import { Content } from '../../../common/utils/stylingUtils';
import { PROCESSES_PATH, PROCESS_META_METADATA, WORKFLOWS_PATH } from '../../../workflows';
import { FormButtons } from '../common/components/FormButtons';
import { NotificationBlock } from '../common/components/NotificationBlock';
import { Form, FormContent, FormSectionTitle, InfoContainer, InfoText, StyledCheck, StyledCross, StyledSectionCard, styledSectionCardHeaderStyle, SwitchContainer } from '../common/stylingUtils';
import { GDPR_CHANGE_REQUEST_PROCESS_META_ID } from './utils';
const StyledAlert = styled(Alert) `
  margin-bottom: 1.5rem !important;
`;
const StyledLink = styled(Link) `
  font-weight: inherit !important;
  border: 0 !important;
  transition: color 200ms ease;
  word-break: break-word;

  &:hover {
    color: var(--primaryColor-4);
  }
`;
const StyledInfoIcon = styled(IoMdInformationCircle) `
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
  color: var(--primaryColor-4);
`;
const ALLOWED_GDPR_ENTITY_PROPERTY_DEFINITIONIDS = [
    'country',
    'language',
    'contactPrefix',
    'postalCodeCity'
];
const GdprSettingsForm = ({ contentHeight, dirty, handleSubmit, reset, definitionId = 'contact', submitting, config, onFormSubmit, updateFormValue, enableGdprPortal: enableGdprPortalAction }) => {
    const { t, language } = useI18n();
    const { displayData, displayDataStatus } = useEntityDisplayData(definitionId);
    const [enablingGdprPortal, setEnablingGdprPortal] = useState(false);
    const unmounted = useRef(false);
    const isDataPending = config.isTenantPending || config.isGdprConfigPending;
    const hasDataFetchFailed = config.isTenantFailed || config.isGdprConfigFailed;
    const warningsMap = useMemo(() => {
        const warningsMap = {};
        if (displayData) {
            const properties = flatMap(displayData.data.filter(({ sectionId }) => sectionId !== 'system'), 'values');
            properties.forEach((property) => {
                if (property.computed === true) {
                    warningsMap[property.propertyId] = t('GDPR_COMPUTED_FIELD_WARNING');
                }
                else if (['select', 'file', 'json'].includes(property.type) || // These aren't implemented in the end-user-portal
                    (property.type === 'entity' &&
                        property.meta != null &&
                        property.meta.definitionId != null &&
                        !ALLOWED_GDPR_ENTITY_PROPERTY_DEFINITIONIDS.includes(property.meta.definitionId))) {
                    warningsMap[property.propertyId] = t('GDPR_UNSUPPORTED_FIELD_WARNING');
                }
            });
        }
        return warningsMap;
    }, [displayData, t]);
    const toggleProperty = useCallback((propertyId) => {
        const oldSelection = get(config.formValues, 'propertyIds', []);
        let newSelection;
        if (oldSelection.includes(propertyId)) {
            newSelection = oldSelection.filter((id) => id !== propertyId);
        }
        else {
            newSelection = [...oldSelection, propertyId];
        }
        void updateFormValue('propertyIds', newSelection);
    }, [config.formValues, updateFormValue]);
    const toggleEnablingGdprPortal = useCallback(() => {
        setEnablingGdprPortal((prevState) => !prevState);
    }, []);
    const enableGdprPortal = useCallback(() => {
        toggleEnablingGdprPortal();
        enableGdprPortalAction().finally(toggleEnablingGdprPortal);
    }, [enableGdprPortalAction, toggleEnablingGdprPortal]);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    return (React.createElement(StyledSectionCard, { title: t('GDPR_PORTAL'), secondaryTitle: !config.gdprNeedsActivation &&
            !hasDataFetchFailed &&
            !isDataPending && (React.createElement(FormButtons, { dirty: dirty, 
            // Remove ignoring error when component is rewritten with formik instead of redux-form.
            // @ts-expect-error
            onSubmit: handleSubmit(onFormSubmit), onReset: reset, submitting: submitting, disabled: submitting || displayDataStatus.isPending || isDataPending })), headerStyle: styledSectionCardHeaderStyle, withoutPadding: true, withoutMargin: true, loading: displayDataStatus.isPending },
        React.createElement(Content, { height: contentHeight }, config.gdprNeedsActivation || enablingGdprPortal ? (React.createElement(InfoContainer, null,
            React.createElement(InfoText, null, t('GDPR_NEEDS_ACTIVATION_TEXT')),
            React.createElement("div", { className: "cp-c-row cp-c-align-center-center" },
                React.createElement(Button, { className: "cp-i-none", onClick: enableGdprPortal, loading: enablingGdprPortal }, t('GDPR_ACTIVATE'))))) : hasDataFetchFailed ? (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })) : (!displayDataStatus.isPending &&
            (displayData && displayData.data ? (React.createElement(Form
            // @ts-expect-error
            , { 
                // @ts-expect-error
                onSubmit: handleSubmit(onFormSubmit), noValidate: true },
                React.createElement(FormContent, { className: "cp-c-padding-3" },
                    React.createElement(StyledAlert, { message: React.createElement(Fragment, null,
                            t('GDPR_APPROVAL_PROCESS_INFO_PART1'),
                            React.createElement(StyledLink, { to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${GDPR_CHANGE_REQUEST_PROCESS_META_ID}${SETTINGS_PATH}${PROCESS_META_METADATA}` }, t('GDPR_APPROVAL_PROCESS_LINK_TEXT')),
                            t('GDPR_APPROVAL_PROCESS_INFO_PART2')), type: "info" }),
                    React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                        React.createElement(LabelText, null, t('GDPR_COMPANY_WEBSITE_URL')),
                        React.createElement(MultiLanguageField, { name: "publicWebsiteUrl", type: "text", disabled: submitting || isDataPending, component: BasicInput }),
                        React.createElement(Field, { name: "publicWebsiteUrlTitle", label: t('GDPR_COMPANY_WEBSITE_URL_TITLE'), disabled: submitting || isDataPending, component: BasicInput }),
                        React.createElement(LabelText, null, t('GDPR_PRIVACY_POLICY_URL')),
                        React.createElement(MultiLanguageField, { name: "privacyPolicyUrl", type: "text", disabled: submitting || isDataPending, component: BasicInput }),
                        React.createElement(LabelText, null, t('GDPR_PRIVACY_POLICY_FOOTER_TEXT')),
                        React.createElement(MultiLanguageField, { name: "privacyPolicyFooter", type: "text", disabled: submitting || isDataPending, component: BasicTextArea })),
                    React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                        React.createElement(LabelText, null, t('GDPR_AVAILABLE_CONTACT_PROPERTIES')),
                        displayData &&
                            displayData.data
                                .filter(({ sectionId }) => sectionId !== 'system')
                                .map(({ sectionId, titles, values }) => (React.createElement("div", { key: sectionId },
                                getTranslation(titles, language) && (React.createElement(Fragment, null,
                                    React.createElement(FormSectionTitle, null, getTranslation(titles, language)),
                                    React.createElement(MultiLanguageField, { name: `sectionSubtitles.${sectionId}`, type: "text", disabled: submitting || isDataPending, component: BasicTextArea }))),
                                React.createElement("div", { className: "cp-c-padding-v-1" }, values.map(({ propertyId, labels, computed }) => (React.createElement("div", { key: propertyId, className: "cp-c-row cp-c-align-start-center" },
                                    React.createElement(SwitchContainer, { className: "cp-i-none" },
                                        React.createElement(Switch, { checked: (config.formValues?.propertyIds || []).includes(propertyId), checkedChildren: React.createElement(StyledCheck, null), unCheckedChildren: React.createElement(StyledCross, null), onChange: () => toggleProperty(propertyId), disabled: submitting ||
                                                displayDataStatus.isPending ||
                                                isDataPending ||
                                                warningsMap.hasOwnProperty(propertyId) })),
                                    React.createElement("div", { className: "cp-i-flex" },
                                        getTranslation(labels, language),
                                        warningsMap.hasOwnProperty(propertyId) && (React.createElement(Tooltip, { title: warningsMap[propertyId] },
                                            React.createElement(StyledInfoIcon, null))))))))))))),
                React.createElement(Prompt, { when: dirty && !submitting, message: t('UNSAVED_DATA_CONFIRM') }))) : (React.createElement(NotificationBlock, { message: "SETTINGS_FAILED_LOADING" })))))));
};
export default GdprSettingsForm;
