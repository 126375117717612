import { isGTE } from '@hypercharge/hyper-react-base/lib/browser';
import { getCurrentLanguage, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { deleteOption, didFetchOptionGroupFail, fetchOptionGroup, getIsFetchOptionGroupPending, getOptions } from '@hypercharge/hyper-react-base/lib/metadata';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getBrowserState, getI18nState, getMetadataState } from '../../../../common/reducers/rootSelector';
import Options from './Options';
class OptionsContainer extends Component {
    componentDidMount() {
        this.props.fetchOptionGroup(this.props.groupId, null);
    }
    render() {
        const { language, groupId, options, isPending, deleteOption, isMobile } = this.props;
        const sortedOptions = sortBy(options, [`labels.${language}`, 'value']);
        return (React.createElement(Options, { groupId: groupId, options: sortedOptions, deleteOption: deleteOption, isPending: isPending, isMobile: isMobile }));
    }
}
function mapStateToProps(state, { match }) {
    const groupId = match.params.groupId;
    const metadataState = getMetadataState(state);
    return {
        language: getCurrentLanguage(getI18nState(state)),
        groupId,
        options: getOptions(metadataState, groupId, null),
        isPending: getIsFetchOptionGroupPending(metadataState, groupId, null),
        failed: didFetchOptionGroupFail(metadataState, groupId, null),
        isMobile: !isGTE(getBrowserState(state), 'sm')
    };
}
function mapDispatchToProps(dispatch, { t }) {
    return {
        fetchOptionGroup: (optionGroupId, dependantIdValue) => {
            void dispatch(fetchOptionGroup(optionGroupId, dependantIdValue));
        },
        deleteOption: (groupId, value) => {
            return (dispatch(deleteOption(groupId, value))
                // @ts-expect-error
                .then(() => {
                dispatch(success({
                    title: t('SUCCESS'),
                    message: t('OPTION_DELETE_SUCCESS')
                }));
            })
                .catch((e) => {
                dispatch(error({
                    title: t('ERROR'),
                    message: t('OPTION_DELETE_FAIL')
                }));
            }));
        }
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(OptionsContainer);
