import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, info, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Alert, Button, Modal } from 'antd';
import { keyBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import useDisplayItemMeta from '../../../../../cms/hooks/useDisplayItemMeta';
import { WarningIcon } from '../../../../../icons';
import { useView } from '../../../../../views/components/ViewContext';
import { startProcess } from '../../../../../workflows/dashboard/actions';
import { BROWSE_PATH } from '../../../../utils/constants';
import LoadingScreenBlocker from '../../../LoadingScreenBlocker/LoadingScreenBlocker';
import { extractAvailableProperties, getParentPropertyId, getProcessRunDataArguments, getReferenceDefinitionId } from '../../utils';
import { RelatedProcessesRadioColumn } from '../RelatedProcessesRadioColumn';
import styles from './RelatedProcessesSelectorModal.module.scss';
export const RelatedProcessesSelectorModal = ({ entityMetaDefinitionId, processMetaDefinitionId, processMetaDefinition, selectedItemIds = [], maxSelectedItems = 0, onClose }) => {
    const dispatch = useDispatch();
    const dispatchStartProcess = useDispatch();
    const { t } = useI18n();
    const { filterRequest, isAllItemsSelected, totalItemsCount } = useView();
    const { displayData: entityMetaDefinition } = useEntityDisplayData(entityMetaDefinitionId);
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId: entityMetaDefinitionId });
    const isParentEmpty = useMemo(() => extractAvailableProperties(processMetaDefinition, getReferenceDefinitionId(getParentPropertyId(entityMetaDefinitionId)), selectedItemIds.length > 1).length === 0, [entityMetaDefinitionId, processMetaDefinition, selectedItemIds.length]);
    const [selectedPropertyDestination, setSelectedPropertyDestination] = useState();
    const [isRunning, setIsRunning] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const onSetSelectedPropertySource = useCallback((property) => {
        setSelectedPropertySource(property || getParentPropertyId(entityMetaDefinitionId));
    }, [entityMetaDefinitionId]);
    const isLimitReached = useMemo(() => !!(maxSelectedItems && totalItemsCount > maxSelectedItems && isAllItemsSelected), [maxSelectedItems, totalItemsCount, isAllItemsSelected]);
    const availableEntityProperties = useMemo(() => extractAvailableProperties(entityMetaDefinition).filter((entity) => !entity.meta.list &&
        extractAvailableProperties(processMetaDefinition, getReferenceDefinitionId(entity), selectedItemIds.length > 1 || isAllItemsSelected).length > 0), [entityMetaDefinition, isAllItemsSelected, processMetaDefinition, selectedItemIds.length]);
    const [selectedPropertySource, setSelectedPropertySource] = useState(isParentEmpty ? availableEntityProperties[0] : getParentPropertyId(entityMetaDefinitionId));
    const availableProcessProperties = useMemo(() => extractAvailableProperties(processMetaDefinition, getReferenceDefinitionId(selectedPropertySource), selectedItemIds.length > 1 || isAllItemsSelected), [isAllItemsSelected, processMetaDefinition, selectedItemIds.length, selectedPropertySource]);
    const availableProcessPropertiesMap = useMemo(() => keyBy(availableProcessProperties, 'propertyId'), [availableProcessProperties]);
    const handleModalStart = useCallback(() => {
        if (!selectedItemIds.length && !isAllItemsSelected) {
            return;
        }
        setIsRunning(true);
        setIsFailed(false);
        setIsLoaderVisible(true);
        const { processRunDataFromFilter, processRunData } = getProcessRunDataArguments(selectedPropertySource, entityMetaDefinitionId, selectedPropertyDestination?.propertyId ?? availableProcessProperties[0]?.propertyId, isAllItemsSelected, filterRequest, selectedItemIds, availableProcessPropertiesMap);
        dispatch(info({
            title: t('COMMON__INFO'),
            message: t('TASKS_STARTING_PROCESS')
        }));
        dispatchStartProcess(startProcess(processMetaDefinitionId, processRunData, processRunDataFromFilter))
            .then((data) => {
            const url = `/workflows/processes/${processMetaDefinitionId}${BROWSE_PATH}/${data.entityId}`;
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('TASKS_START_PROCESS_SUCCESS')
            }));
            setIsLoaderVisible(false);
            setTimeout(() => {
                dispatch(push(url));
                onClose();
            }, 250);
        })
            .catch(() => {
            setIsFailed(true);
            setIsRunning(false);
            setIsLoaderVisible(false);
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('TASKS_START_PROCESS_FAIL')
            }));
        });
    }, [
        selectedItemIds,
        isAllItemsSelected,
        selectedPropertySource,
        entityMetaDefinitionId,
        selectedPropertyDestination?.propertyId,
        availableProcessProperties,
        filterRequest,
        availableProcessPropertiesMap,
        dispatch,
        t,
        dispatchStartProcess,
        processMetaDefinitionId,
        onClose
    ]);
    const handleModalClose = useCallback(() => {
        if (!isRunning) {
            onClose();
        }
    }, [onClose, isRunning]);
    useEffect(() => {
        if (((isParentEmpty && availableEntityProperties.length === 1) ||
            availableEntityProperties.length === 0) &&
            availableProcessProperties.length === 1 &&
            !isRunning &&
            !isFailed &&
            !isLimitReached) {
            handleModalStart();
        }
    }, [
        availableProcessProperties,
        handleModalStart,
        isRunning,
        isFailed,
        isLimitReached,
        availableEntityProperties,
        isParentEmpty
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingScreenBlocker, { visible: isLoaderVisible, message: t('TASKS_STARTING_PROCESS') }),
        React.createElement(Modal, { title: t('RELATED_PROCESS_SELECTOR_LABEL'), wrapClassName: styles.modal, centered: true, open: !!processMetaDefinitionId, footer: [
                React.createElement(Button, { size: "large", className: "ms-auto", key: "cancel", onClick: onClose, disabled: isRunning }, t('RELATED_PROCESS_SELECTOR_CANCEL')),
                React.createElement(Button, { size: "large", className: "ms-3", key: "start", type: "primary", onClick: handleModalStart, disabled: isRunning || isLimitReached || !selectedPropertyDestination }, t('RELATED_PROCESS_SELECTOR_START'))
            ], onCancel: handleModalClose },
            isLimitReached && (React.createElement(Alert, { message: t('RELATED_PROCESS_SELECTOR_WARN_TITLE'), description: t('RELATED_PROCESS_SELECTOR_WARN_DESCRIPTION', { max: maxSelectedItems }), type: "warning", showIcon: true, icon: React.createElement(WarningIcon, null) })),
            React.createElement("div", { className: "d-flex flex-column flex-md-row" },
                React.createElement(RelatedProcessesRadioColumn, { availableProperties: availableEntityProperties, groupTitle: t('RELATED_PROCESS_SELECTOR_FROM_TITLE'), isLimitReached: isLimitReached, selectedProperty: selectedPropertySource, setSelectedProperty: onSetSelectedPropertySource, parentDefinition: displayItemMeta, parentGroupId: isParentEmpty ? undefined : entityMetaDefinitionId, groupSource: "from" }),
                React.createElement(RelatedProcessesRadioColumn, { availableProperties: availableProcessProperties, className: "mt-2 mt-md-0", groupTitle: t('RELATED_PROCESS_SELECTOR_TO_TITLE'), isLimitReached: isLimitReached, selectedProperty: selectedPropertyDestination, selectedPropertySource: selectedPropertySource, setSelectedProperty: setSelectedPropertyDestination, groupSource: "to" })))));
};
