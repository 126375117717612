import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { keyBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { getFlattenedDisplayDataList } from '../../../../../../cms';
import { useEntityDisplayData } from '../../../../../../cms/common/components/withEntityDisplayData';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import PropertyFieldInlineEditor from '../../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import { ACTIVITY_DEFINITION_ID } from '../../../../../../common/activity';
import SelectActivityType from '../../../../../../common/activity/components/SelectActivityType';
import DropdownArrowButton from '../../../../../../common/components/DropdownArrowButton';
import FileInput from '../../../../../../common/storage/components/FileField/FileInput';
import { PropertyTypes, isRichTextValue } from '../../../../../../common/types';
import styles from './AvailableTaskActivityForm.module.scss';
const AvailableTaskActivityForm = ({ value, onChange, task, className }) => {
    const { t } = useI18n();
    const activityDisplayData = useEntityDisplayData(ACTIVITY_DEFINITION_ID);
    const propertyMap = useMemo(() => {
        if (!activityDisplayData.displayData) {
            return {};
        }
        return keyBy(getFlattenedDisplayDataList(activityDisplayData.displayData), 'propertyId');
    }, [activityDisplayData.displayData]);
    const isOptionHidden = useCallback((activityType) => {
        return !task.activity?.available?.includes(activityType);
    }, [task.activity?.available]);
    const onChangeType = useCallback((val) => {
        if (!val || Array.isArray(val)) {
            return;
        }
        onChange?.({
            ...value,
            typeText: val
        });
    }, [onChange, value]);
    const onChangeContact = useCallback((val) => {
        if (Array.isArray(val)) {
            return;
        }
        if (typeof val === 'number') {
            return;
        }
        if (isRichTextValue(val)) {
            return;
        }
        onChange?.({
            ...value,
            contact: val ?? undefined
        });
    }, [onChange, value]);
    const onChangeComments = useCallback((val) => {
        if (Array.isArray(val)) {
            return;
        }
        if (typeof val === 'number') {
            return;
        }
        if (typeof val === 'string') {
            return;
        }
        onChange?.({
            ...value,
            comments: val ?? undefined
        });
    }, [onChange, value]);
    const onChangeFiles = useCallback((val) => {
        onChange?.({
            ...value,
            files: val
        });
    }, [onChange, value]);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12 col-xl-6" },
                React.createElement("div", { className: "d-flex flex-wrap mt-2 align-items-center" },
                    React.createElement(FieldLabel, { className: "col-12 col-sm-4", label: t('ACTIVITY_SHORT_TYPE_LABEL'), required: true }),
                    React.createElement(SelectActivityType, { className: `${styles.activitySelector} col-12 col-sm-8 w-auto`, value: value.typeText, isOptionHidden: isOptionHidden, onChange: onChangeType, showSearch: false, showArrow: true, suffixIcon: React.createElement(DropdownArrowButton, { className: "ms-2" }), bordered: false }))),
            React.createElement("div", { className: "col-12 col-xl-6" }, propertyMap.contact && (React.createElement(PropertyFieldInlineEditor, { propertyId: propertyMap.contact.propertyId, className: "mt-0", label: t('ACTIVITY_NAME'), value: value.contact, help: t('ACTIVITY_NAME_INFO'), definitionId: ACTIVITY_DEFINITION_ID, canEdit: propertyMap.contact.canEdit, type: propertyMap.contact.type, meta: propertyMap.contact.meta, computed: propertyMap.contact.computed, layout: "horizontal", onChange: onChangeContact })))),
        propertyMap.comments && (React.createElement("div", { className: "row" },
            React.createElement("div", null,
                React.createElement(PropertyFieldInlineEditor, { propertyId: propertyMap.comments.propertyId, value: value.comments, label: t('COMMENTS'), help: t('ACTIVITY_COMMENTS_INFO'), definitionId: ACTIVITY_DEFINITION_ID, canEdit: propertyMap.comments.canEdit, type: propertyMap.comments.type, meta: propertyMap.comments.meta, computed: propertyMap.comments.computed, layout: "vertical", onChange: onChangeComments })))),
        propertyMap.files && propertyMap.files.type === PropertyTypes.file && (React.createElement("div", { className: "row" },
            React.createElement("div", null,
                React.createElement(FileInput, { label: t('COMMON_FILES'), disabled: !propertyMap.files.canEdit, groupId: task.taskRunId, loading: false, layout: "vertical", meta: propertyMap.files.meta, value: Array.isArray(value.files) ? value.files : [], onChange: onChangeFiles }))))));
};
export default AvailableTaskActivityForm;
