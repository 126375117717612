import styled from 'styled-components';
export const theme = {
    textColor: 'var(--neutralColor-6)',
    mainColor: 'var(--primaryColor-6)',
    mainColorActive: 'var(--primaryColor-5)',
    mainColorVibrant: 'var(--primaryColor-5)',
    mainColorSoft: 'var(--grayscaleColor-3)',
    alternativeColor: 'var(--primaryColor-6)',
    alternativeColorActive: 'rgba(var(--infoColorRGB-5), 0.86)',
    invalidColor: 'var(--warningColor-6)',
    skeletonColor: 'var(--grayscaleColor-4)',
    cardBorderRadius: '0.25rem',
    highlightedColor: 'var(--grayscaleColor-2)',
    defaultRadius: '2px',
    cardBoxShadow: '0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), .15)',
    fontFamily: 'var(--fontFamily)'
};
export const sizes = {
    sm: 568,
    md: 768,
    lg: 1024,
    wrapper: 1120,
    xl: 1280
};
// Example usage of the media template
// import { media } from '../../common/utils/styledComponentsUtils';
// const Container = styled.div`
//   color: red;
//   ${media.sm} { padding: 0 20px; }
// `
export const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = `@media screen and (min-width: ${sizes[label] / 16}em)`;
    return acc;
}, {});
export const Wrapper = styled.div `
  box-sizing: border-box;
  max-width: ${sizes.wrapper}px;
  margin: 0 auto;
`;
