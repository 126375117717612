import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import cn from 'classnames';
import { colord } from 'colord';
import { isBoolean, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsPlusSquare } from 'react-icons/bs';
import { getEditorComponentForType } from '../../../cms';
import MultiTextEditor from '../../../cms/data/components/item-property/editors/MultiTextEditor';
import { isEntityInSingleMode } from '../../../cms/data/components/item-property/utils';
import { isPropertyItemMeta } from '../../../cms/data/types';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import { ViewProvider } from '../../../views';
import { PropertyTypes } from '../../types';
import { getDataForSave } from '../../utils/common';
import { CopyPropertyButton } from '../data-table/CopyPropertyButton';
import ActionsBlock from './ActionsBlock';
import FilterModal from './FilterModal';
import styles from './InlineEditor.module.scss';
import { SelectLanguage } from './SelectLanguage';
import { EditButton, ViewHistoryButton } from './styled';
import { getDefaultValueForOnlySelectedSwitch, getInitialFiltersQuery } from './utils';
const isTouchDevice = 'ontouchstart' in document.documentElement;
const LIMIT_ITEMS_TO_COPY = 500;
export const InlineEditor = ({ value, valueFromTranslations, meta, style, placeholder, canEdit, entityId, definitionId, propertyId, expand, autoFocus, actionsPosition, onHistoryOpened, immediatelySaveOnChange, hideActions = true, allowClear = true, isWithinFrame = false, disabled = false, extraDisplayComponentProps = {}, extraEditorComponentProps = {}, selectedLanguageMultiLanguage, setSelectedLanguageMultiLanguage, translationOptions, className, editorComponent: EditorComp, displayComponent: DisplayComp, onEditingChanged, onSave, afterSave, onCancel, invalid }) => {
    const { t } = useI18n();
    const node = useRef(null);
    const wrapperNode = useRef(null);
    const [stateEditing, setStateEditing] = useState({ editing: false, showCreateOnInitial: false });
    const [saving, setSaving] = useState(false);
    const [stateValue, setStateValue] = useState(value);
    const [statePrevValue, setStatePrevValue] = useState(value);
    const [disableHandleOutsideClick, setDisableHandleOutsideClick] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [neededSpaceOnBottom, setNeededSpaceOnBottom] = useState(0);
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId: isPropertyItemMeta(meta) ? meta.definitionId : undefined
    });
    const canCreate = useMemo(() => displayItemMeta?.canCreate, [displayItemMeta]);
    const defaultValueForOnlySelectedSwitch = useMemo(() => getDefaultValueForOnlySelectedSwitch(meta), [meta]);
    const [initialFilterSelected, setInitialFilterSelected] = useState(defaultValueForOnlySelectedSwitch);
    const [modalFiltersRequest, setModalFiltersRequest] = useState(getInitialFiltersQuery(meta));
    useEffect(() => {
        setStateValue(value);
        setStatePrevValue(value);
    }, [value]);
    const stopEditing = useCallback(() => {
        setStateEditing({ editing: false, showCreateOnInitial: false });
        if (onEditingChanged) {
            onEditingChanged(false);
        }
    }, [onEditingChanged]);
    const cancel = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
        setStateValue(value);
        stopEditing();
    }, [onCancel, value, stopEditing]);
    const save = useCallback(async (newValue) => {
        if (saving) {
            return;
        }
        if (disableHandleOutsideClick) {
            // Allow creating a new cms entity item by pressing enter on the select option of the CustomEntitySelector instead of clicking
            // Preferably this should be handled in the onClick callback but that doesn't have access to the new state resulting from the onChange callback
            // On the other hand, this function is being debounced, therefore when it's executed it has access to the new state
            return;
        }
        if (!isEqual(value, newValue) &&
            !isEqual(valueFromTranslations, newValue) &&
            (allowClear || (newValue !== '' && newValue !== null && newValue !== undefined))) {
            setSaving(true);
            try {
                if (onSave) {
                    await onSave(newValue, propertyId);
                }
                setStatePrevValue(newValue);
                if (afterSave) {
                    afterSave();
                }
            }
            catch (e) {
                console.error(e);
                setStateValue(statePrevValue);
                if (afterSave) {
                    afterSave(e);
                }
            }
            setSaving(false);
            stopEditing();
        }
        else {
            cancel();
        }
    }, [
        saving,
        disableHandleOutsideClick,
        valueFromTranslations,
        value,
        allowClear,
        stopEditing,
        onSave,
        afterSave,
        propertyId,
        statePrevValue,
        cancel
    ]);
    const handleOutsideClick = useCallback((e) => {
        if (isModalOpen) {
            return;
        }
        const target = e.target;
        const className = target.className || '';
        if (className?.includes?.('rc-virtual-list-scrollbar')) {
            // No need to close editor when trying to scroll
            return;
        }
        if (!saving && stateEditing.editing) {
            if (disableHandleOutsideClick ||
                target.closest('.ant-select-clear') ||
                // did we click inside the field we are editing?
                node.current?.contains(target) ||
                // if the target we are clicking has a className is this inside the
                // calendar that is a div detatched from the editor itself?
                (className.indexOf && className.indexOf('ant-calendar') !== -1) ||
                // if we don't have a className but have a activeNode that is from
                // ant calendar
                (!className && document.activeElement?.className?.indexOf('ant-calendar') != -1) ||
                // Did we press inside the ant-select that uses a detatched div?
                (className.indexOf && className.indexOf('ant-select') !== -1) ||
                target.closest('.FilterModal')) {
                return;
            }
            if (EditorComp === MultiTextEditor) {
                setTimeout(() => {
                    void save(stateValue);
                }, 300);
            }
            else {
                void save(stateValue);
            }
        }
    }, [isModalOpen, saving, stateEditing, disableHandleOutsideClick, EditorComp, save, stateValue]);
    useEffect(() => {
        if (stateEditing) {
            document.addEventListener('mousedown', handleOutsideClick, false);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick, false);
        };
    }, [stateEditing, handleOutsideClick]);
    const startEditing = useCallback((e = null, showCreateOnInitial = false) => {
        if (!canEdit ||
            stateEditing.editing ||
            (wrapperNode.current &&
                e?.target instanceof Node &&
                !wrapperNode.current?.contains(e.target))) {
            return;
        }
        setStateEditing({ editing: true, showCreateOnInitial });
        if (onEditingChanged) {
            onEditingChanged(true);
        }
    }, [canEdit, stateEditing, wrapperNode, onEditingChanged]);
    const toggleEditing = useCallback(() => {
        if (stateEditing.editing) {
            stopEditing();
        }
        else {
            startEditing();
        }
    }, [stateEditing, startEditing, stopEditing]);
    const onEditorCompChange = useCallback((newValue) => {
        setStateValue(newValue);
        if (immediatelySaveOnChange) {
            void save(newValue);
        }
    }, [immediatelySaveOnChange, save]);
    const onEditorCompKeyDown = useCallback((e, newValue) => {
        if (!isEntityInSingleMode(meta) && e.key === 'Enter' && !e.shiftKey) {
            // on enter triggers both the onChange and the onKeyDown
            // even though they are in the correct order the state
            // hasn't finish updating and so the wrong value is sent
            // added a debounce that helps if the user presses the enter
            // more than once and also allows it to wait for setState to finish
            void save(newValue !== undefined ? newValue : stateValue);
        }
        else if (e.key === 'Escape') {
            cancel();
        }
    }, [meta, save, cancel, stateValue]);
    const onAccept = useCallback(() => {
        void save(stateValue);
    }, [save, stateValue]);
    useEffect(() => {
        if (autoFocus) {
            toggleEditing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleFilterModalOk = useCallback((ids) => {
        const newValue = getDataForSave(ids, isEntityInSingleMode(meta));
        setStateValue(newValue);
        setIsModalOpen(false);
        setInitialFilterSelected(defaultValueForOnlySelectedSwitch);
        void save(newValue);
    }, [defaultValueForOnlySelectedSwitch, meta, save]);
    const handleModalCancel = useCallback(() => {
        setIsModalOpen(false);
        setInitialFilterSelected(defaultValueForOnlySelectedSwitch);
    }, [defaultValueForOnlySelectedSwitch]);
    const handleApplyFilters = useCallback((filterRequest) => {
        filterRequest && setModalFiltersRequest(filterRequest);
        setIsModalOpen(false);
        setInitialFilterSelected(defaultValueForOnlySelectedSwitch);
    }, [setModalFiltersRequest, setIsModalOpen, defaultValueForOnlySelectedSwitch]);
    const viewQueryFilterContext = useMemo(() => {
        if (definitionId && entityId) {
            return {
                definitionId,
                entityId
            };
        }
    }, [definitionId, entityId]);
    const filterRequest = useMemo(() => ({
        ...(isPropertyItemMeta(meta) ? meta?.selector?.filtering?.filterRequest || {} : {}),
        ...modalFiltersRequest,
        context: viewQueryFilterContext
    }), [meta, modalFiltersRequest, viewQueryFilterContext]);
    const handleMultipleValuesClick = useCallback(() => {
        setIsModalOpen(true);
        setInitialFilterSelected(FilterOperatorTypes.in);
    }, []);
    const viewSelected = useMemo(() => {
        if (Array.isArray(stateValue)) {
            return stateValue;
        }
        if (typeof stateValue === 'string') {
            return [stateValue];
        }
        if (typeof stateValue === 'number') {
            return [stateValue.toString()];
        }
    }, [stateValue]);
    const onBlur = useCallback((event) => {
        if (isTouchDevice) {
            return;
        }
        if (node.current &&
            event?.target instanceof Node &&
            node.current?.contains(event.relatedTarget || event.target)) {
            return;
        }
        onAccept();
    }, [onAccept]);
    const hasNeededSpacesOnBottom = useMemo(() => {
        const nodeClient = node.current?.getBoundingClientRect();
        if (nodeClient) {
            return nodeClient.y + nodeClient.height + neededSpaceOnBottom < window.innerHeight;
        }
        return true;
    }, [neededSpaceOnBottom]);
    const showFilter = useMemo(() => EditorComp === getEditorComponentForType(PropertyTypes.entity), [EditorComp]);
    const onKeyDown = useCallback((event) => {
        if (event.code.startsWith('Key') || event.code.startsWith('Digit') || event.key === 'Enter') {
            startEditing();
        }
    }, [startEditing]);
    const showAddEntityDrawer = useCallback((event) => {
        event.stopPropagation();
        startEditing(null, true);
    }, [startEditing]);
    const actionPosition = actionsPosition
        ? actionsPosition
        : hasNeededSpacesOnBottom
            ? 'top'
            : 'bottom';
    const shouldRenderCopyButton = useMemo(() => {
        if (!extraDisplayComponentProps?.canCopy || stateEditing.editing) {
            return false;
        }
        const isValueValid = value || isBoolean(value);
        const isEntityType = extraDisplayComponentProps?.type === PropertyTypes.entity;
        const isTooManyItems = Array.isArray(value) && value.length >= LIMIT_ITEMS_TO_COPY;
        return isValueValid && !(isEntityType && isTooManyItems);
    }, [extraDisplayComponentProps, stateEditing.editing, value]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: `${styles.editor} ${invalid ? styles.editor__invalid : ''} InlineEditorOuter inline-editor ${className || ''} ${stateEditing.editing ? 'editing' : ''}`, onClick: startEditing, ref: wrapperNode },
            !stateEditing.editing ? (React.createElement(React.Fragment, null, placeholder && !stateValue ? (React.createElement("span", null, placeholder)) : (React.createElement(DisplayComp, { asField: extraDisplayComponentProps.asField ?? true, 
                // @ts-expect-error
                onKeyDown: onKeyDown, tabIndex: !canEdit ? -1 : 0, invalid: invalid, value: stateValue, meta: meta || {}, style: style, expand: expand, disabled: saving || disabled, onMultipleValuesClick: handleMultipleValuesClick, ...extraDisplayComponentProps })))) : (React.createElement("div", { ref: node, tabIndex: 0, onBlur: onBlur, className: `position-relative ${expand ? 'w-100' : ''}` },
                React.createElement(ActionsBlock, { saving: saving, position: actionPosition, changed: !isEqual(value, stateValue) &&
                        !isEqual(valueFromTranslations, stateValue) &&
                        (allowClear ||
                            (stateValue !== '' && stateValue !== null && stateValue !== undefined)), onAccept: onAccept, onCancel: cancel }),
                translationOptions &&
                    selectedLanguageMultiLanguage &&
                    setSelectedLanguageMultiLanguage &&
                    translationOptions[selectedLanguageMultiLanguage] ? (React.createElement(SelectLanguage, { value: selectedLanguageMultiLanguage, onChange: setSelectedLanguageMultiLanguage, translationOptions: translationOptions, position: actionPosition })) : null,
                React.createElement(EditorComp
                // @ts-expect-error
                , { 
                    // @ts-expect-error
                    onChange: onEditorCompChange, entityId: entityId, editingInline: true, value: stateValue, meta: meta, disabled: saving || disabled, invalid: invalid, allowClear: allowClear, onDrawerStateChange: setDisableHandleOutsideClick, onKeyDown: onEditorCompKeyDown, isWithinFrame: isWithinFrame, showFilter: showFilter, onShowFilter: setIsModalOpen, showCreateOnInitial: stateEditing.showCreateOnInitial, filterRequest: filterRequest, setNeededSpaceOnBottom: setNeededSpaceOnBottom, hasNeededSpacesOnBottom: hasNeededSpacesOnBottom, ...extraEditorComponentProps }))),
            React.createElement("div", { className: cn('edit-actions', { hide: !isTouchDevice && hideActions, isTouchDevice }), style: {
                    background: style?.backgroundColor && colord(style.backgroundColor).alpha(0.7).toRgbString()
                } },
                shouldRenderCopyButton && (React.createElement("div", { title: t('COPY'), className: "cp-c-row cp-c-align-center-center cursor-pointer safe-space" },
                    React.createElement(CopyPropertyButton, { className: "shadow-none m-0 p-0 bg-transparent border-0 h-auto", value: value, meta: meta, ...extraDisplayComponentProps }))),
                canEdit && canCreate && !stateEditing.editing && showFilter && (React.createElement("div", { title: t('ADD'), className: "cp-c-row cp-c-align-center-center cursor-pointer safe-space" },
                    React.createElement(BsPlusSquare, { onClick: showAddEntityDrawer, size: 17 }))),
                canEdit && !stateEditing.editing && (React.createElement("div", { title: t('COMMON__EDIT'), className: "cp-c-row cp-c-align-center-center safe-space" },
                    React.createElement(EditButton, { onClick: toggleEditing, className: "edit-pencil mx-0" }))),
                onHistoryOpened && !stateEditing.editing && (React.createElement("div", { title: t('VALUE_HISTORY'), className: "cp-c-row cp-c-align-center-center safe-space", onClick: (e) => {
                        e.stopPropagation();
                        onHistoryOpened(propertyId);
                    } },
                    React.createElement(ViewHistoryButton, null))))),
        isPropertyItemMeta(meta) && showFilter && isModalOpen ? (React.createElement(ViewProvider, { definitionId: meta.definitionId, filterRequest: filterRequest, metrics: meta.selector?.filtering?.metrics, rowHeightType: meta.selector?.filtering?.rowHeightType, context: viewQueryFilterContext, initialFilterSelected: initialFilterSelected, initialSelectedItems: viewSelected, initialRowGrouping: meta.selector?.filtering?.rowGrouping, initialRowGroupingOrderBy: meta.selector?.filtering?.rowGroupingOrderBy, enableGrouping: true },
            React.createElement(FilterModal, { open: isModalOpen, meta: meta, definitionId: meta.definitionId, handleOk: handleFilterModalOk, handleCancel: handleModalCancel, handleApplyFilters: handleApplyFilters, requiredFieldsConfig: extraDisplayComponentProps?.requiredFieldsConfig }))) : null));
};
