import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
/**
 * @param {string} key - key to store value in storage
 * @param {object} defaultValue - default value to be returned if there is no value in storage
 * @param {Storage} storage - Storage default value is storage
 * @return {array} - array with value and setValue function
 * @example
 * const [value, setValue] = useStorage('key', { foo: 'bar' });
 * setValue({ foo: 'baz' });
 */
const useStorage = (key, defaultValue, storage = localStorage) => {
    const [value, setValue] = useState(() => {
        const storedValue = storage.getItem(key);
        return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    });
    const setStorageDebounced = useDebouncedCallback((key, value) => {
        storage.setItem(key, JSON.stringify(value));
    }, 300);
    useEffect(() => {
        setStorageDebounced(key, value);
    }, [key, setStorageDebounced, value]);
    return [value, setValue];
};
export default useStorage;
