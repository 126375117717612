export const HYPER_INTEGRATION = 'hyperIntegration';
export const HYPER_INTEGRATION_MODULE = 'hyperIntegrationModule';
export const HYPER_INTEGRATION_CATEGORY = 'hyperIntegrationCategory';
// TODO: It should be configurable ?
// Known modules
export const XDMS_INTEGRATION_ENTITY_ID = 'xdmsIntegration';
export const XDMS_CONTACT_MODULE_ENTITY_ID = 'xdmsContactModule';
export const XDMS_CUSTOMER_MODULE_ENTITY_ID = 'xdmsCustomerModule';
export const XDMS_VEHICLE_MODULE_ENTITY_ID = 'xdmsVehicleModule';
export const XDMS_OFFER_MODULE_ENTITY_ID = 'xdmsOfferModule';
export const XDMS_SALES_DOSSIER_MODULE_ENTITY_ID = 'xdmsSalesDossierModule';
export const XDMS_LEAD_MODULE_ENTITY_ID = 'xdmsLeadModule';
export const XDMS_ORDER_MODULE_ENTITY_ID = 'xdmsOrderModule';
export const XDMS_SALES_PERSON_MODULE_ENTITY_ID = 'xdmsSalesPersonModule';
export const XDMS_LANGUAGE_MODULE_ENTITY_ID = 'xdmsLanguageModule';
export const XDMS_LOCATION_MODULE_ENTITY_ID = 'xdmsLocationModule';
export const XDMS_ECOMM_ORDER_MODULE_ENTITY_ID = 'xdmsEcommOrderModule';
export const CONTACTS_MODULE_KEY = 'contacts';
export const CONTACTS_MODULE_PATH = `/${CONTACTS_MODULE_KEY}`;
// webhooks constants
export const WEBHOOKS_INTEGRATION_ENTITY_ID = 'webhooksIntegration';
export const INTEGRATION_MODULE_WEBHOOKS_ENTITY_ID = 'webhooksEntityModule';
// hexon constants
export const HEXON_INTEGRATION_ENTITY_ID = 'hexonIntegration';
export const INTEGRATION_MODULE_HEXON_VEHICLE_ENTITY_ID = 'hexonVehicleModule';
// Leadinfo constants
export const LEADINFO_INTEGRATION_ENTITY_ID = 'leadinfoIntegration';
