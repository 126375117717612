import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { ConditionType, FilterOperatorTypes, SortOrder } from '@hypercharge/portal-utils';
import { findIndex } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaCircle, FaEye, FaPause, FaPlay, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import ConfirmationButtonModal from '../../../../../../common/components/ConfirmationButtonModal';
import DataTable2Container from '../../../../../../common/components/data-table/DataTable2Container';
import { VIEW_DEFINITION_ID, ViewProvider } from '../../../../../../views';
import { useEntityDisplayData } from '../../../../../common/components/withEntityDisplayData';
import { CMS_IMPORTER_PATH, CMS_PATH, ENTITY_ID_PROPERTY_ID } from '../../../../../common/utils/constants';
import { invalidateItemsQuery } from '../../../../../hooks/invalidateCache';
import { getFlattenedDisplayDataList } from '../../../../utils';
import { EditLink, getItemsTableColumns } from '../../../items/utils';
import { changeStatusImportItem, deleteImportItem } from '../../actions';
import { IMPORT_STATUS } from '../../types';
import styles from './ScheduledList.module.scss';
const ScheduledList = ({ definitionId, columnPropertyIds, referenceDefinitionId }) => {
    const { t, language } = useI18n();
    const { displayData } = useEntityDisplayData(definitionId);
    const dispatch = useDispatch();
    const deleteImportItemDispatch = useDispatch();
    const changeStatusImportItemDispatch = useDispatch();
    const view = useMemo(() => {
        return {
            definitionId: VIEW_DEFINITION_ID,
            entityId: 'dummy',
            title: '',
            isDefault: true,
            referenceDefinitionId: definitionId,
            processMetaId: null,
            filters: {
                query: {
                    condition: ConditionType.and,
                    filters: [
                        { field: 'cronExpression', operator: FilterOperatorTypes.notEmpty },
                        {
                            field: 'referenceDefinitionId',
                            operator: FilterOperatorTypes.is,
                            data: referenceDefinitionId
                        }
                    ]
                },
                sortBy: [{ field: 'createdAt', order: SortOrder.desc }],
                fullText: '',
                fullTextFields: columnPropertyIds,
                responseFields: columnPropertyIds,
                limit: 100,
                offset: 0,
                languageCode: language
            },
            metrics: []
        };
    }, [definitionId, language, referenceDefinitionId, columnPropertyIds]);
    const columns = useMemo(() => {
        const viewableEntityProperties = getFlattenedDisplayDataList(displayData);
        const tableColumns = getItemsTableColumns(`${definitionId}-${referenceDefinitionId}`, columnPropertyIds, viewableEntityProperties, language, {
            id: 'open-item',
            accessorKey: 'open-item',
            header: '',
            cell: ({ row }) => (React.createElement(EditLink, { className: "safe-space", to: `${CMS_PATH}/${referenceDefinitionId}${CMS_IMPORTER_PATH}/${row.original.entityId}/mapping`, title: t('CMS_IMPORTER__VIEW_DETAILS') },
                React.createElement(FaEye, null)))
        });
        const importStatusColumnIndex = findIndex(tableColumns, { id: 'importStatus' });
        tableColumns.splice(importStatusColumnIndex, 1, {
            id: 'importStatus',
            accessorKey: 'importStatus',
            header: '',
            cell: ({ row }) => (React.createElement(FaCircle, { className: row?.original?.importStatus === IMPORT_STATUS.PAUSED
                    ? styles.importStatusPause
                    : styles.importStatusActive }))
        });
        return tableColumns;
    }, [displayData, definitionId, referenceDefinitionId, columnPropertyIds, t, language]);
    const handleDelete = useCallback(async ({ selectedIds = [] }) => {
        try {
            await deleteImportItemDispatch(deleteImportItem(selectedIds));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('GENERIC_ASYNC_CHANGES_WARNING')
            }));
            await invalidateItemsQuery({ definitionId, ids: selectedIds, waitNewData: true });
        }
        catch (e) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: `${t('ENTITIES_DELETE_FAIL')} ${selectedIds.length}`
            }));
            throw e;
        }
    }, [definitionId, deleteImportItemDispatch, dispatch, t]);
    const handleChangeStatus = useCallback(async (selectedIds, importStatus) => {
        try {
            await changeStatusImportItemDispatch(changeStatusImportItem(selectedIds, importStatus));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('GENERIC_ASYNC_CHANGES_WARNING')
            }));
            await invalidateItemsQuery({
                definitionId,
                ids: selectedIds,
                waitNewData: true
            });
            return true;
        }
        catch (e) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: `${t('ENTITIES_DELETE_FAIL')} ${selectedIds.length}`
            }));
            throw e;
        }
    }, [changeStatusImportItemDispatch, dispatch, t, definitionId]);
    const handleChangeStatusToRun = useCallback(async ({ selectedIds = [] }) => handleChangeStatus(selectedIds, IMPORT_STATUS.RUNNING), [handleChangeStatus]);
    const handleChangeStatusToPause = useCallback(async ({ selectedIds = [] }) => handleChangeStatus(selectedIds, IMPORT_STATUS.PAUSED), [handleChangeStatus]);
    return (React.createElement("div", null,
        React.createElement(ViewProvider, { definitionId: definitionId, scrollToTopOnPageChange: false, view: view },
            React.createElement(DataTable2Container, { idField: ENTITY_ID_PROPERTY_ID, columns: columns, noViewActions: true, noFilters: true, noRowHeightAction: true, actions: [
                    {
                        key: 'run',
                        buttonContent: React.createElement(FaPlay, null),
                        action: handleChangeStatusToRun,
                        clearSelection: true,
                        buttonProps: {
                            className: 'status'
                        }
                    },
                    {
                        key: 'pause',
                        buttonContent: React.createElement(FaPause, null),
                        action: handleChangeStatusToPause,
                        clearSelection: true,
                        buttonProps: {
                            className: 'status'
                        }
                    },
                    {
                        key: 'delete',
                        buttonComponent: ConfirmationButtonModal,
                        buttonContent: React.createElement(FaTrash, { preserveAspectRatio: "none" }),
                        buttonProps: {
                            className: 'action-button',
                            tooltip: t('ENTITIES_DELETE_CONFIRMATION_TITLE'),
                            confirmationTitle: t('ENTITIES_DELETE_CONFIRMATION_TITLE'),
                            confirmationWarningMessage: t('DELETE_WARNING_CONCERNING_REFERRALS'),
                            confirmationMessage: t('ENTITIES_DELETE_CONFIRMATION_MESSAGE'),
                            danger: true
                        },
                        action: handleDelete,
                        resetPage: true,
                        clearSelection: true
                    }
                ] }))));
};
export default ScheduledList;
