import { useMemo } from 'react';
import { ENTITY_OWNER_PROPERTY_ID, PERMISSIONS_PROPERTY_ID } from '../../../cms/common/utils/constants';
import { getPropertyById } from '../../../cms/common/utils/utils';
import { CONFIGURATION_PROPERTY_ID } from '../../../cms/data/components/importer/constants';
import useDisplayData from '../../../cms/hooks/useDisplayData';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { DASHBOARDS_ENTITY_ID } from './constants';
export const useDashboardPermissions = (dashboardId) => {
    const { contactId } = useMyContact();
    const { data: displayDataList } = useDisplayData({
        definitionId: DASHBOARDS_ENTITY_ID,
        entityId: dashboardId
    });
    const entityOwnerProperty = useMemo(() => getPropertyById(displayDataList, ENTITY_OWNER_PROPERTY_ID), [displayDataList]);
    const permissionsProperty = useMemo(() => getPropertyById(displayDataList, PERMISSIONS_PROPERTY_ID), [displayDataList]);
    const configurationProperty = useMemo(() => getPropertyById(displayDataList, CONFIGURATION_PROPERTY_ID), [displayDataList]);
    return useMemo(() => ({
        canDelete: !!contactId && contactId == entityOwnerProperty?.value,
        canShare: !!permissionsProperty?.canEdit,
        canEdit: !!configurationProperty?.canEdit
    }), [
        configurationProperty?.canEdit,
        contactId,
        entityOwnerProperty?.value,
        permissionsProperty?.canEdit
    ]);
};
