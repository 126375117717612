import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import DefaultCounter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/plugins/counter.css';
import Download from 'yet-another-react-lightbox/plugins/download';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import PDFViewer from '../../../../documents/templates/components/editor/PDFViewer';
import { useBrowserBreakpoints } from '../../../components/with-browser-breakpoints';
import EarmarkFileIcon from '../FileCarousel/EarmarkFileIcon';
import documentFormats, * as FILE_TYPE from '../constants';
import CustomCounter from './CounterPlugin';
import styles from './FileLightBox.module.scss';
const Slides = ({ slide }) => {
    if (slide.fileType === FILE_TYPE.PDF) {
        return (React.createElement("div", { className: "h-100 w-100 pdf-viewer" }, slide.fileId && React.createElement(PDFViewer, { fileId: slide.fileId })));
    }
    if (documentFormats.includes(slide.fileType) ||
        slide.fileType.includes(FILE_TYPE.AUDIO) ||
        slide.fileType.includes(FILE_TYPE.VIDEO)) {
        return React.createElement(EarmarkFileIcon, { text: slide.extension });
    }
    return (React.createElement("div", { className: "wrp-img-slide w-100 h-100 d-flex justify-content-center align-items-center" },
        React.createElement("img", { src: slide.downloadUrl, alt: slide.description })));
};
const FileLightBox = ({ files, className = '', closeImagePreview, previewIndex = 0 }) => {
    const [currentIndex, setCurrentIndex] = useState(previewIndex);
    const { isDesktop } = useBrowserBreakpoints();
    const Counter = isDesktop ? CustomCounter : DefaultCounter;
    const slides = useMemo(() => files.map((file) => ({
        fileId: file.id,
        fileType: file.type || '',
        downloadUrl: file.downloadUrl || '',
        src: file.downloadUrl || '',
        description: file.name || '',
        extension: file.extension || ''
    })), [files]);
    const currentFileType = useMemo(() => slides[currentIndex].fileType, [currentIndex, slides]);
    return (React.createElement("div", { className: className, onClick: (e) => e.stopPropagation() },
        React.createElement(Lightbox, { className: cn(styles.lightbox, {
                'hide-some-toolbar-buttons': currentFileType === FILE_TYPE.PDF
            }), on: {
                view: ({ index }) => setCurrentIndex(index)
            }, plugins: [Thumbnails, Download, Counter, Fullscreen, Zoom, Captions], thumbnails: { position: isDesktop ? 'end' : 'bottom' }, captions: { descriptionTextAlign: 'center' }, open: true, index: currentIndex, close: closeImagePreview, slides: slides, render: {
                // @ts-expect-error render slide isn't Generic
                slide: ({ slide }) => React.createElement(Slides, { slide: slide })
            } })));
};
export default FileLightBox;
