import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { PERMISSIONS_PROPERTY_ID_SYSTEM_VIEW_VALUE } from '@hypercharge/portal-utils';
import memoize from 'fast-memoize';
import { isEqual, omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { BiSlider } from 'react-icons/bi';
import { HiDotsVertical } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useLocation } from 'react-router-dom';
import { hasTenantPermission } from '../../../auth';
import { CMS_IMPORTER_PATH, CMS_PATH, REQUIRED_CMS_EXPORT_PERMISSION, exportItems } from '../../../cms';
import { PERMISSIONS_PROPERTY_ID } from '../../../cms/common/utils/constants';
import { getFiltersCount, getPropertyById } from '../../../cms/common/utils/utils';
import { invalidateItemsQuery } from '../../../cms/hooks/invalidateCache';
import useCreateItem from '../../../cms/hooks/useCreateItem';
import useDeleteItems from '../../../cms/hooks/useDeleteItems';
import useDisplayData from '../../../cms/hooks/useDisplayData';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import useUpdateItem from '../../../cms/hooks/useUpdateItem';
import { useUpdateProperty } from '../../../cms/hooks/useUpdateProperty';
import ActionsButton from '../../../common/components/ActionsButton';
import { Drawer } from '../../../common/components/Drawer';
import ShareDrawer from '../../../common/components/ShareDrawer';
import { BROWSE_PATH, EDIT_DEFAULT_VIEW_PERMISSION, PLATFORM_ADMIN_PERMISSION, SHARE_VIEW_PERMISSION, VIEW_PATH } from '../../../common/utils/constants';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { CONTEXT_CODE, FULL_TEXT, LANGUAGE_CODE, LIMIT_BY_KEY, VIEW_DEFINITION_ID } from '../../constants';
import { getColumnPropertyIdsFromParams, getQueryParams } from '../../utils';
import EditViewForm from '../EditViewForm';
import { useView } from '../ViewContext';
import styles from './ViewActionsButton.module.scss';
const permissionForProperties = ['filters', 'metrics', 'viewType', 'configuration'];
const getHasPermission = memoize((s) => (permission) => hasTenantPermission(s, permission));
const clearingMetricBeforeCompare = (metric) => {
    Object.keys(metric).forEach((key) => {
        if (metric[key] === undefined) {
            delete metric[key];
        }
    });
    if (!metric.inPercents) {
        delete metric.inPercents;
    }
    return omit(metric, ['id']);
};
const clearingMetricsBeforeCompare = (metrics) => metrics.map(clearingMetricBeforeCompare);
const ViewActionsButton = ({ className = '', isMobile = false, disabled = false, noViewActions = false, withCmsImport = false, withExportData = true, customUpdateRouteWithView, noKanban, canEditRowHeight, canEditSorting, canEditFilters, canEditMetrics, customOptionsGroupBy, customAvailableColumns, customSelectedColumns, onChangeCustomSearch, EditViewIcon = BiSlider, noSelectFieldsOnTableView, showFiltersCount = true, availableOn }) => {
    const { t } = useI18n();
    const { search, pathname } = useLocation();
    const { changedVirtualView, definitionId, filterRequest, hasViewCustomizations, metrics, kanban, resetVirtualView, updateRouteWithView, view, viewType, rowHeightType, virtualViewMode, rowGrouping, rowGroupingOrderBy, resetView, enableGrouping } = useView();
    const { contactId } = useMyContact();
    const exportItemsDispatch = useDispatch();
    const routerPushDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const [exportingData, setExportingData] = useState(false);
    const [isEditViewDrawerVisible, setIsEditDrawerDrawerVisible] = useState(false);
    const [isShowShareViewDrawer, setIsShowShareViewDrawer] = useState(false);
    const hasPermission = useSelector((s) => getHasPermission(s));
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const canSaveDefaultView = useSelector((s) => hasTenantPermission(s, EDIT_DEFAULT_VIEW_PERMISSION));
    const shareViewPermission = useSelector((s) => hasTenantPermission(s, SHARE_VIEW_PERMISSION));
    const canImportData = useSelector((s) => hasTenantPermission(s, PLATFORM_ADMIN_PERMISSION));
    const { mutateAsync: viewCreateItem, isLoading: isLoadingCreateView } = useCreateItem({
        definitionId: VIEW_DEFINITION_ID
    });
    const { mutateAsync: viewDeleteItems, isLoading: isLoadingDeleteView } = useDeleteItems({
        definitionId: VIEW_DEFINITION_ID
    });
    const canDeleteView = useMemo(() => !!contactId && contactId == view?.entityOwner, [view?.entityOwner, contactId]);
    const { data: displayDataList } = useDisplayData({
        definitionId: VIEW_DEFINITION_ID,
        entityId: view?.entityId
    });
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId: VIEW_DEFINITION_ID
    });
    const { mutateAsync: updateView, isLoading: isLoadingUpdateView } = useUpdateItem({
        definitionId: VIEW_DEFINITION_ID
    });
    const permissionsProperty = useMemo(() => getPropertyById(displayDataList, PERMISSIONS_PROPERTY_ID), [displayDataList]);
    const canShareView = useMemo(() => {
        return !!permissionsProperty?.canEdit && shareViewPermission;
    }, [permissionsProperty?.canEdit, shareViewPermission]);
    const filtersProperty = useMemo(() => getPropertyById(displayDataList, 'filters'), [displayDataList]);
    const canSaveView = useMemo(() => !!filtersProperty?.canEdit, [filtersProperty]);
    const sanitizedFilterRequest = useMemo(() => omit(filterRequest, [FULL_TEXT, LIMIT_BY_KEY, LANGUAGE_CODE, CONTEXT_CODE]), [filterRequest]);
    const toggleEditViewDrawer = useCallback(() => {
        setIsEditDrawerDrawerVisible((x) => !x);
    }, []);
    const toggleShowShareViewDrawer = useCallback(() => {
        setIsShowShareViewDrawer(!isShowShareViewDrawer);
    }, [isShowShareViewDrawer]);
    const didMetricsChange = useMemo(() => {
        const viewMetricsWithoutId = clearingMetricsBeforeCompare(view?.metrics || []);
        const metricsWithoutId = clearingMetricsBeforeCompare(metrics || []);
        return !isEqual(viewMetricsWithoutId, metricsWithoutId);
    }, [metrics, view]);
    const didFilterRequestChange = useMemo(() => !!view &&
        !isEqual(omit(view.filters, [FULL_TEXT, LIMIT_BY_KEY, LANGUAGE_CODE, CONTEXT_CODE]), sanitizedFilterRequest), [sanitizedFilterRequest, view]);
    const didViewTypeChange = useMemo(() => viewType != view?.viewType, [viewType, view?.viewType]);
    const didKanbanChange = useMemo(() => !isEqual(kanban, view?.configuration?.kanban), [kanban, view?.configuration?.kanban]);
    const didRowHeightTypeChange = useMemo(() => !!view && rowHeightType != view?.configuration?.rowHeightType, [rowHeightType, view]);
    const didGroupingRowChange = useMemo(() => !!view && rowGrouping != view?.configuration?.rowGrouping, [rowGrouping, view]);
    const didGroupingRowOrderByChange = useMemo(() => !!view && rowGroupingOrderBy != view?.configuration?.rowGroupingOrderBy, [rowGroupingOrderBy, view]);
    const filtersCount = useMemo(() => getFiltersCount(filterRequest), [filterRequest]);
    const saveView = useCallback(async () => {
        try {
            if (!view) {
                return;
            }
            const newView = {
                entityId: view.entityId
            };
            if (didMetricsChange) {
                newView.metrics = metrics;
            }
            if (didFilterRequestChange) {
                newView.filters = sanitizedFilterRequest;
            }
            if (didViewTypeChange) {
                newView.viewType = viewType;
            }
            if (didRowHeightTypeChange ||
                didKanbanChange ||
                didGroupingRowChange ||
                didGroupingRowOrderByChange) {
                newView.configuration = {
                    ...view?.configuration,
                    rowHeightType,
                    kanban,
                    rowGrouping,
                    rowGroupingOrderBy
                };
            }
            await updateView(newView);
            notificationDispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('VIEWS__SAVE_VIEW_SUCCESS')
            }));
        }
        catch (e) {
            // Errors for savePropertyData are already being notified
        }
    }, [
        view,
        didMetricsChange,
        didFilterRequestChange,
        didViewTypeChange,
        didRowHeightTypeChange,
        didKanbanChange,
        didGroupingRowChange,
        didGroupingRowOrderByChange,
        updateView,
        notificationDispatch,
        t,
        metrics,
        sanitizedFilterRequest,
        viewType,
        rowHeightType,
        kanban,
        rowGrouping,
        rowGroupingOrderBy
    ]);
    const shareView = useCallback(async (permissions) => {
        try {
            if (!view) {
                return;
            }
            await updateProperty({
                entityId: view.entityId,
                propertyId: PERMISSIONS_PROPERTY_ID,
                value: permissions
            });
            await invalidateItemsQuery({
                definitionId: VIEW_DEFINITION_ID,
                ids: [view.entityId]
            });
            setIsShowShareViewDrawer(false);
            notificationDispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('VIEWS__SAVE_VIEW_SUCCESS')
            }));
        }
        catch (e) {
            notificationDispatch(error({
                title: t('ERROR'),
                message: t('SOMETHING_WENT_WRONG')
            }));
        }
    }, [view, updateProperty, notificationDispatch, t]);
    const createView = useCallback(async (isDefault = false) => {
        if (!definitionId) {
            return;
        }
        const newView = {
            entityId: `${generateId()}${availableOn ? `__${availableOn}` : ''}`,
            referenceDefinitionId: definitionId,
            isDefault,
            metrics,
            filters: sanitizedFilterRequest,
            title: t(isDefault
                ? 'VIEWS__CREATE_DEFAULT_VIEW_TITLE_PLACEHOLDER'
                : 'VIEWS__CREATE_PERSONAL_VIEW_TITLE_PLACEHOLDER', { entityTitle: displayItemMeta?.title }),
            viewType,
            configuration: {
                rowHeightType,
                availableOn: view?.configuration?.availableOn,
                kanban,
                rowGrouping,
                rowGroupingOrderBy
            },
            rowPermissions: isDefault ? PERMISSIONS_PROPERTY_ID_SYSTEM_VIEW_VALUE : undefined
        };
        try {
            await viewCreateItem(newView);
            if (isDefault) {
                notificationDispatch(success({
                    title: t('COMMON__SUCCESS'),
                    message: t('VIEWS__SAVE_VIEW_SUCCESS')
                }));
            }
            else {
                notificationDispatch(success({
                    title: t('COMMON__SUCCESS'),
                    message: t('VIEWS__CREATE_VIEW_SUCCESS')
                }));
                routerPushDispatch(push(pathname.replace(BROWSE_PATH, `${VIEW_PATH}/${newView.entityId}`)));
            }
            const eventCategory = 'Custom';
            if (viewType) {
                gtag('event', `new_${viewType}_view_created`, {
                    event_category: eventCategory
                });
            }
        }
        catch (e) {
            notificationDispatch(error({
                title: t('ERROR'),
                message: t('SOMETHING_WENT_WRONG')
            }));
        }
    }, [
        definitionId,
        availableOn,
        metrics,
        sanitizedFilterRequest,
        t,
        displayItemMeta?.title,
        viewType,
        rowHeightType,
        view?.configuration?.availableOn,
        kanban,
        rowGrouping,
        rowGroupingOrderBy,
        viewCreateItem,
        notificationDispatch,
        routerPushDispatch,
        pathname
    ]);
    const deletePersonalView = useCallback(async () => {
        if (view && !view.isDefault && view.entityId) {
            try {
                await viewDeleteItems({ ids: [view.entityId] });
                notificationDispatch(success({
                    title: t('COMMON__SUCCESS'),
                    message: t('VIEWS__DELETE_VIEW_SUCCESS')
                }));
                routerPushDispatch(push(pathname.replace(`${VIEW_PATH}/${view.entityId}`, BROWSE_PATH)));
            }
            catch (e) {
                notificationDispatch(error({
                    title: t('COMMON__FAILURE'),
                    message: t('VIEWS__DELETE_VIEW_FAIL')
                }));
            }
        }
    }, [view, viewDeleteItems, notificationDispatch, t, routerPushDispatch, pathname]);
    const requestDataExport = useCallback(async (allColumns = true) => {
        if (!definitionId) {
            return;
        }
        setExportingData(true);
        const columnPropertyIds = getColumnPropertyIdsFromParams(getQueryParams(search), view);
        let responseField = ['*'];
        if (!allColumns && columnPropertyIds.length) {
            responseField = columnPropertyIds;
        }
        try {
            await exportItemsDispatch(exportItems(definitionId, {
                ...filterRequest,
                fullTextFields: columnPropertyIds,
                responseFields: responseField
            }));
            notificationDispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('VIEWS__EXPORT_ITEMS_SUCCESS')
            }));
        }
        catch (e) {
            notificationDispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('VIEWS__EXPORT_ITEMS_FAILURE')
            }));
        }
        setExportingData(false);
    }, [definitionId, search, view, exportItemsDispatch, filterRequest, notificationDispatch, t]);
    const menuActions = [];
    if (changedVirtualView) {
        menuActions.push({
            action: resetVirtualView,
            canRun: true,
            buttonLabel: 'FORM__RESET'
        });
    }
    if (view && hasViewCustomizations && !noViewActions) {
        menuActions.push({
            action: resetView,
            canRun: true,
            buttonLabel: 'FORM__RESET'
        });
    }
    if (!noViewActions && !virtualViewMode) {
        if (view && !view.isDefault) {
            menuActions.push({
                action: () => saveView(),
                canRun: canSaveView,
                buttonLabel: 'VIEWS__SAVE_VIEW',
                disabled: !hasViewCustomizations
            }, {
                action: toggleShowShareViewDrawer,
                canRun: canShareView,
                buttonLabel: 'VIEWS__SHARE_VIEW'
            }, {
                action: deletePersonalView,
                canRun: canDeleteView,
                buttonLabel: 'VIEWS__DELETE_VIEW',
                confirmationLabel: 'VIEWS__DELETE_VIEW',
                delete: true
            });
        }
        else {
            menuActions.push({
                action: () => createView(false),
                canRun: true,
                buttonLabel: 'VIEWS__CREATE_VIEW'
            }, {
                action: () => (view != null ? saveView() : createView(true)),
                canRun: canSaveDefaultView,
                buttonLabel: 'VIEWS__SAVE_DEFAULT_VIEW',
                disabled: view != null && !hasViewCustomizations
            });
        }
    }
    if (withCmsImport && canImportData) {
        menuActions.push({
            action: () => {
                routerPushDispatch(push(`${CMS_PATH}/${definitionId}${CMS_IMPORTER_PATH}`));
            },
            canRun: true,
            buttonLabel: 'VIEWS__IMPORT_ITEMS'
        });
    }
    if (withExportData && hasPermission(REQUIRED_CMS_EXPORT_PERMISSION)) {
        menuActions.push({
            action: requestDataExport,
            canRun: true,
            buttonLabel: 'VIEWS__EXPORT_ITEMS'
        });
        menuActions.push({
            action: () => requestDataExport(false),
            canRun: true,
            buttonLabel: 'VIEWS__EXPORT_VIEW_ITEMS'
        });
    }
    const permissions = useMemo(() => {
        if (!permissionsProperty?.value) {
            return;
        }
        return permissionsProperty?.value;
    }, [permissionsProperty?.value]);
    return (React.createElement(ErrorBoundary, null,
        definitionId && (React.createElement(Drawer, { onClose: toggleEditViewDrawer, open: isEditViewDrawerVisible },
            React.createElement(EditViewForm, { onClose: toggleEditViewDrawer, onCloseCallback: toggleEditViewDrawer, definitionId: definitionId, filterRequest: sanitizedFilterRequest, metrics: metrics, kanban: kanban, updateRouteWithView: customUpdateRouteWithView || updateRouteWithView, canEditFilters: canEditFilters, canEditMetrics: canEditMetrics, canEditSorting: canEditSorting, canEditRowHeight: canEditRowHeight, canEditGroupingRow: enableGrouping, customOptionsGroupBy: customOptionsGroupBy, customAvailableColumns: customAvailableColumns, customSelectedColumns: customSelectedColumns, onChangeCustomSearch: onChangeCustomSearch, viewType: viewType, rowHeightType: rowHeightType, rowGrouping: rowGrouping, rowGroupingOrderBy: rowGroupingOrderBy, allowComputedForConditionQuery: true, noKanban: noKanban, noSelectFieldsOnTableView: noSelectFieldsOnTableView }))),
        React.createElement(ShareDrawer, { title: t('VIEWS__SHARE_VIEW'), open: isShowShareViewDrawer, permissions: permissions, onSave: shareView, permissionForProperties: permissionForProperties, onClose: toggleShowShareViewDrawer }),
        React.createElement(ActionsButton, { className: className, menuIcon: React.createElement(HiDotsVertical, { size: 18 }), buttonAction: {
                action: toggleEditViewDrawer,
                buttonJsx: (React.createElement("div", { className: `ViewActionsButtonEditViewButtonTextOuter ${styles.editViewButtonText} ${hasViewCustomizations ? 'bold' : ''} cp-c-row cp-c-align-start-center` },
                    React.createElement(EditViewIcon, { className: "me-2" }),
                    !isMobile && t('VIEWS__EDIT_VIEW'),
                    showFiltersCount && !!filtersCount && (React.createElement("span", { className: "count-badge" }, filtersCount)))),
                canRun: true
            }, menuActions: menuActions, disabled: disabled ||
                isLoadingUpdateView ||
                exportingData ||
                isLoadingCreateView ||
                isLoadingDeleteView, inversed: true }),
        view && !view.isDefault && !noViewActions && canSaveView && (React.createElement(Prompt, { when: hasViewCustomizations && !isLoadingUpdateView, message: (newLocation) => newLocation.pathname === pathname ? true : t('VIEWS__UNSAVED_PERSONAL_VIEW_CONFIRM') }))));
};
export default ViewActionsButton;
