import { isArray, isObject } from 'lodash';
import queryClient from '../../common/utils/react-query-client';
import { getFlattenedDisplayDataList } from '../data/utils';
import { DISPLAY_DATA_QUERY_KEY } from './useDisplayData';
import { useHandleEntityItems } from './useEntityItems';
import { ENTITY_ITEMS_QUERY_KEY } from './useSearchItems';
export const matchesPredicate = (query, definitionId, entityId) => {
    if (!isArray(query.queryKey)) {
        return false;
    }
    return (Array.isArray(query.queryKey) &&
        query.queryKey.includes(ENTITY_ITEMS_QUERY_KEY) &&
        query.queryKey.includes(definitionId) &&
        isObject(query.state.data) &&
        'results' in query.state.data &&
        Array.isArray(query.state.data.results) &&
        query.state.data.results.some((entityItem) => entityItem.entityId === entityId));
};
export const usePropertyEntityItemValue = () => {
    const { handleFetch } = useHandleEntityItems();
    const getPropertyValue = async (definitionId, entityId, propertyId) => {
        const displayDataList = queryClient.getQueryData([
            DISPLAY_DATA_QUERY_KEY,
            definitionId,
            entityId
        ]);
        if (displayDataList) {
            const displayData = getFlattenedDisplayDataList(displayDataList);
            const value = displayData.find((item) => item.propertyId === propertyId)?.value;
            return Promise.resolve(value);
        }
        const entityItemsQueries = await queryClient.getQueriesData({
            predicate: (query) => matchesPredicate(query, definitionId, entityId)
        });
        if (entityItemsQueries.length) {
            let entityItem;
            entityItemsQueries.forEach((entityItemsQuery) => {
                const entityCmsListResponse = entityItemsQuery[1];
                if (entityCmsListResponse) {
                    entityItem = entityCmsListResponse?.results?.find((item) => item.entityId === entityId);
                }
            });
            return Promise.resolve(entityItem?.[propertyId]);
        }
        const entityCmsListResponse = await handleFetch({
            ids: [entityId],
            definitionId,
            extraPayloadProps: {
                responseFields: [propertyId]
            }
        });
        if (!entityCmsListResponse) {
            return;
        }
        const entityItem = entityCmsListResponse?.results?.[0];
        return Promise.resolve(entityItem?.[propertyId]);
    };
    return {
        getPropertyValue
    };
};
