import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdCreate } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemMeta, getItemMeta, isItemMetaAvailable } from '../../../../../../../cms';
import { Drawer } from '../../../../../../../common/components/Drawer';
import { CustomEntitySelector } from '../../../../../../../common/components/entity-selectors';
import styles from './CredentialSelector.module.scss';
import QuickAddCredentialForm from './QuickAddCredentialForm';
import { CREDENTIALS_DEFINITION_ID } from './constants';
const CredentialSelector = ({ credential, disabled, onChange, value, invalid }) => {
    const { t } = useI18n();
    const itemMetaDispatch = useDispatch();
    const isAvailable = useSelector((state) => isItemMetaAvailable(state, CREDENTIALS_DEFINITION_ID));
    const credentialsItemMeta = useSelector((state) => getItemMeta(state, CREDENTIALS_DEFINITION_ID));
    useEffect(() => {
        if (!isAvailable) {
            itemMetaDispatch(fetchItemMeta(CREDENTIALS_DEFINITION_ID));
        }
    }, [itemMetaDispatch, isAvailable]);
    const itemMeta = useMemo(() => {
        if (!credentialsItemMeta) {
            return;
        }
        if (!credential.name) {
            return credentialsItemMeta;
        }
        return {
            ...credentialsItemMeta,
            selector: {
                query: {
                    condition: ConditionType.and,
                    filters: [{ field: 'type', operator: FilterOperatorTypes.is, data: credential.name }]
                }
            }
        };
    }, [credential.name, credentialsItemMeta]);
    const quickAddItemForm = useCallback((props) => {
        return React.createElement(QuickAddCredentialForm, { credential: credential, ...props });
    }, [credential]);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const onClose = useCallback(() => {
        setIsDrawerVisible(false);
    }, []);
    return itemMeta ? (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { title: `${t('EDIT')}`, closable: false, onClose: onClose, open: isDrawerVisible, destroyOnClose: true },
            React.createElement(QuickAddCredentialForm, { credential: credential, entityId: value, onFinish: onClose, onCancel: onClose })),
        React.createElement("div", { className: `row ${styles.wrapper}` },
            React.createElement("div", { className: "col" },
                React.createElement(CustomEntitySelector, { multiple: false, input: {
                        value,
                        // @ts-expect-error
                        onChange
                    }, definitionId: itemMeta.definitionId, disabled: disabled, autoFocus: false, extraPayloadProps: itemMeta.selector, quickAddItemForm: quickAddItemForm, invalid: invalid, forceFetch: true })),
            React.createElement("div", { className: "col-auto ps-0" },
                React.createElement(IoMdCreate, { className: `edit-button d-flex align-items-center h-100 ${value ? '' : 'disabled'}`, size: "20px", onClick: value
                        ? () => {
                            setIsDrawerVisible(true);
                        }
                        : undefined }))))) : null;
};
export default CredentialSelector;
