import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const Title = styled.div `
  font-weight: 600;
`;
export const StyledAdd = styled(MdAdd) `
  position: absolute;
  bottom: -5px;
  right: -5px;
  background: var(--grayscaleColor-1);
  border-radius: 90px;
  color: var(--neutralColor-6);
`;
export const AddItem = styled(Link) `
  position: relative;

  .create-icon {
    font-size: 1.3rem;
    color: var(--neutralColor-6);
    vertical-align: middle;
  }

  :hover {
    .create-icon {
      color: var(--primaryColor-5);
    }
    ${StyledAdd} {
      color: var(--primaryColor-5);
    }
  }
`;
export const Text = styled.div `
  padding-left: 1rem;
  overflow-wrap: break-word;
`;
