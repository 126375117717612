export const GROUP_CMS_DEFINITION_ID = 'group';
export const OWNER_GROUP_PLACEHOLDER = '__owner__';
export const PARENT_GROUP_PROPERTY_ID = 'parentGroup';
export const ADMINS_PERMISSION_GROUP = 'hyperAdmins';
export const ALL_USERS_PERMISSION_GROUP = 'hyperUsers';
export const CUSTOMER_PORTAL_USERS_PERMISSION_GROUP = 'customerPortalUsers';
export const HYPER_BI_USERS_GROUP = 'hyperBIUsers';
export const HYPER_EVENT_USERS_PERMISSION_GROUP = 'hyperEventUsers';
export const INTEGRATION_PERMISSION_GROUP = 'INTEGRATION';
export const INDIVIDUAL_GROUP_PREFIX = 'ownerIs__';
export const DOMAIN_GROUP_PREFIX = '__domain__';
export const SYSTEM_GROUPS = [
    ADMINS_PERMISSION_GROUP,
    ALL_USERS_PERMISSION_GROUP,
    CUSTOMER_PORTAL_USERS_PERMISSION_GROUP,
    HYPER_BI_USERS_GROUP,
    HYPER_EVENT_USERS_PERMISSION_GROUP,
    INTEGRATION_PERMISSION_GROUP
];
export const GROUPS_PATH = '/groups';
export const PERMISSION_GROUP_TRANSLATION_KEYS = {
    viewGroups: 'WORKFLOWS_SETTINGS_VIEW_PERMISSION',
    executeGroups: 'WORKFLOWS_SETTINGS_EXECUTE_PERMISSION',
    createGroups: 'CMS_CREATE_PERMISSION',
    readGroups: 'DEFINITION_FORM__READ_PERMISSION',
    writeGroups: 'DEFINITION_FORM__WRITE_PERMISSION',
    deleteGroups: 'CMS_DELETE_PERMISSION'
};
export var AvailableSystemGroups;
(function (AvailableSystemGroups) {
    AvailableSystemGroups["viewGroups"] = "viewGroups";
    AvailableSystemGroups["executeGroups"] = "executeGroups";
    AvailableSystemGroups["createGroups"] = "createGroups";
    AvailableSystemGroups["readGroups"] = "readGroups";
    AvailableSystemGroups["writeGroups"] = "writeGroups";
    AvailableSystemGroups["deleteGroups"] = "deleteGroups";
})(AvailableSystemGroups || (AvailableSystemGroups = {}));
