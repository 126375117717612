import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { ACTIVITY_ASSIGNED_TO_PROPERTY_ID, ACTIVITY_CONTACT_PROPERTY_ID, ACTIVITY_DUE_DATE_PROPERTY_ID, ACTIVITY_PRIORITY_PROPERTY_ID, ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID, ACTIVITY_RELATED_TO_ENTITY_ID_PROPERTY_ID, ACTIVITY_TYPE_TEXT_PROPERTY_ID } from '../../common/activity/constants';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../cms/common/utils/constants';
import { TASK_ASSIGNED_TO_PROPERTY_ID, TASK_DEADLINE_PROPERTY_ID, TASK_PROCESS_META_ID_PROPERTY_ID } from '../common/utils/constants';
import { TasksFilterType, isTasksFilterType } from './types';
// `=SODATE(ADDDATE(NOW(), -${relativeValue}, '${relativeUnit}'), '${relativeUnitInIso}')`
export const REGEX_START_WITH_SODATE = /=SODATE\(ADDDATE\(NOW\(\),\s*(-?\d+),\s*'([^']+)'\),\s*'([^']+)'\)/;
// `=ADDDATE(NOW(), -${relativeValue}, '${relativeUnit}')`
export const REGEX_START_WITH_ADDDATE = /^=ADDDATE\(NOW\(\),\s*-?(\w+),\s*'(\w+)'\)$/;
export const DESIGN_WORKFLOWS_TASK_DASHBOARD_FIELDS_FOR_TABLE = [
    'taskAssignedTo',
    'taskDeadline',
    'taskEmailStatuses',
    'taskPriority',
    'taskProcessCustomTitle',
    'taskProcessMetaId',
    'taskProcessRunId',
    'taskStatus'
];
export const DESIGN_ACTIVITY_TASK_DASHBOARD_FIELDS_FOR_TABLE = [
    ACTIVITY_ASSIGNED_TO_PROPERTY_ID,
    ACTIVITY_DUE_DATE_PROPERTY_ID,
    ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID,
    ACTIVITY_RELATED_TO_ENTITY_ID_PROPERTY_ID,
    ACTIVITY_TYPE_TEXT_PROPERTY_ID,
    ACTIVITY_CONTACT_PROPERTY_ID,
    ACTIVITY_PRIORITY_PROPERTY_ID
];
export const mapFullRelativeUnit = {
    h: 'hour',
    d: 'day',
    w: 'week',
    M: 'month',
    y: 'year'
};
export const mapRevertFullRelativeUnit = Object.entries(mapFullRelativeUnit).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});
export const mapPropertyWorkflowsTaskDashboardGroupBy = {
    [TasksFilterType.relatedTo]: TASK_PROCESS_META_ID_PROPERTY_ID,
    [TasksFilterType.taskTitles]: TITLE_PROPERTY_ID,
    [TasksFilterType.assignees]: TASK_ASSIGNED_TO_PROPERTY_ID,
    [TasksFilterType.deadline]: TASK_DEADLINE_PROPERTY_ID
};
export const mapPropertyActivityTaskDashboardGroupBy = {
    [TasksFilterType.relatedTo]: ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID,
    [TasksFilterType.taskTitles]: TITLE_PROPERTY_ID,
    [TasksFilterType.assignees]: ACTIVITY_ASSIGNED_TO_PROPERTY_ID,
    [TasksFilterType.deadline]: ACTIVITY_DUE_DATE_PROPERTY_ID
};
export const WorkflowsTasksFilterTypeConfig = {
    [TasksFilterType.relatedTo]: {
        value: TasksFilterType.relatedTo,
        groupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.relatedTo],
        fieldGroupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.relatedTo],
        fieldGroupByTitle: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.relatedTo]
    },
    [TasksFilterType.taskTitles]: {
        value: TasksFilterType.taskTitles,
        groupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.taskTitles],
        fieldGroupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.taskTitles],
        fieldGroupByTitle: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.taskTitles]
    },
    [TasksFilterType.assignees]: {
        value: TasksFilterType.assignees,
        groupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.assignees],
        fieldGroupBy: `${mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.assignees]}.${ENTITY_ID_PROPERTY_ID}`,
        fieldGroupByTitle: `${mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.assignees]}.${TITLE_PROPERTY_ID}`
    },
    [TasksFilterType.deadline]: {
        value: TasksFilterType.deadline,
        groupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.deadline],
        fieldGroupBy: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.deadline],
        fieldGroupByTitle: mapPropertyWorkflowsTaskDashboardGroupBy[TasksFilterType.deadline]
    }
};
export const ActivityTasksFilterTypeConfig = {
    [TasksFilterType.relatedTo]: {
        value: TasksFilterType.relatedTo,
        groupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.relatedTo],
        fieldGroupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.relatedTo],
        fieldGroupByTitle: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.relatedTo]
    },
    [TasksFilterType.taskTitles]: {
        value: TasksFilterType.taskTitles,
        groupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.taskTitles],
        fieldGroupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.taskTitles],
        fieldGroupByTitle: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.taskTitles]
    },
    [TasksFilterType.assignees]: {
        value: TasksFilterType.assignees,
        groupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.assignees],
        fieldGroupBy: `${mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.assignees]}.${ENTITY_ID_PROPERTY_ID}`,
        fieldGroupByTitle: `${mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.assignees]}.${TITLE_PROPERTY_ID}`
    },
    [TasksFilterType.deadline]: {
        value: TasksFilterType.deadline,
        groupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.deadline],
        fieldGroupBy: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.deadline],
        fieldGroupByTitle: mapPropertyActivityTaskDashboardGroupBy[TasksFilterType.deadline]
    }
};
export const getWorkflowsTaskFilterTypeConfig = (type) => {
    if (type && isTasksFilterType(type)) {
        return WorkflowsTasksFilterTypeConfig[type];
    }
    return WorkflowsTasksFilterTypeConfig[TasksFilterType.relatedTo];
};
export const getActivityTaskFilterTypeConfig = (type) => {
    if (type && isTasksFilterType(type)) {
        return ActivityTasksFilterTypeConfig[type];
    }
    return ActivityTasksFilterTypeConfig[TasksFilterType.assignees];
};
export const getLessThenNowFilter = (field) => ({
    data: '=NOW()',
    field,
    operator: FilterOperatorTypes.lessThan
});
export const getFromNowToEndDayFilter = (field) => ({
    condition: ConditionType.and,
    filters: [
        {
            data: '=NOW()',
            field,
            operator: FilterOperatorTypes.greaterThanOrEqual
        },
        {
            data: '=ADDDATE(TODAY(), 1, "day")',
            field,
            operator: FilterOperatorTypes.lessThan
        }
    ]
});
export const getFromTomorrowToEndWeekFilter = (field) => ({
    condition: ConditionType.and,
    filters: [
        {
            data: '=ADDDATE(TODAY(), 1, "day")',
            field,
            operator: FilterOperatorTypes.greaterThanOrEqual
        },
        {
            data: "=SODATE(ADDDATE(TODAY(), 7, 'day'), 'week')",
            field,
            operator: FilterOperatorTypes.lessThan
        }
    ]
});
export const getFromEndWeekToEndMonthFilter = (field) => ({
    condition: ConditionType.and,
    filters: [
        {
            data: "=SODATE(ADDDATE(TODAY(), 7, 'day'), 'week')",
            field,
            operator: FilterOperatorTypes.greaterThanOrEqual
        },
        {
            data: "=SODATE(ADDDATE(TODAY(), 31, 'day'), 'month')",
            field,
            operator: FilterOperatorTypes.lessThan
        }
    ]
});
export const getLaterEndMonthFilter = (field) => ({
    data: "=SODATE(ADDDATE(TODAY(), 31, 'day'), 'month')",
    field,
    operator: FilterOperatorTypes.greaterThanOrEqual
});
export const getEmptyFilter = (field) => ({
    field,
    operator: FilterOperatorTypes.empty
});
export const uniqViewIdWorkflowsTaskDashboard = 'taskDashboard';
export const uniqViewIdActivityTaskDashboard = 'activityTaskDashboard';
