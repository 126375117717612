import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useMemo } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Field } from 'redux-form';
import { useProperties } from '../../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInputField } from '../../../../../../common/components/AntInput';
import { AntTextAreaField } from '../../../../../../common/components/AntTextArea';
import { Option, Select } from '../../../../../../common/components/Select';
import { CustomEntitySelector } from '../../../../../../common/components/entity-selectors';
import styles from './MessageMappingsField.module.scss';
const MessageMappingsField = ({ className, label, fields, disabled, definitionId, messageMappingField }) => {
    const { t } = useI18n();
    const { propertiesStatus, getDisplayData } = useProperties(definitionId);
    const displayData = useMemo(() => getDisplayData(messageMappingField), [getDisplayData, messageMappingField]);
    // TODO: add loading spinner
    return (React.createElement("div", { className: className },
        label && React.createElement(FieldLabel, { label: label }),
        React.createElement("div", null,
            fields.map((member, i) => (React.createElement("div", { key: i, className: `${styles.fieldWrapper} d-flex flex-column w-100` },
                React.createElement("div", { className: "d-flex align-items-center mb-2" },
                    (!displayData || displayData.type === 'text') && (React.createElement(Field, { placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('COMMON__PROPERTY')} ${t('COMMON_NAME')}`, component: AntInputField, name: `${member}.propertyValue`, disabled: disabled || !displayData || propertiesStatus.isPending })),
                    displayData && displayData.type === 'entity' && (React.createElement(Field, { className: "w-100", component: CustomEntitySelector, name: `${member}.propertyValue`, definitionId: displayData.meta.definitionId, disabled: disabled || propertiesStatus.isPending, autoFocus: false, popupMatchSelectWidth: false })),
                    displayData && displayData.type === 'number' && (React.createElement(Field, { placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('COMMON__PROPERTY')} ${t('COMMON_NAME')}`, component: AntInputField, name: `${member}.propertyValue`, type: "number", disabled: disabled || propertiesStatus.isPending })),
                    displayData && displayData.type === 'checkbox' && (React.createElement(Field, { component: CheckBoxMappingField, name: `${member}.propertyValue`, disabled: disabled || propertiesStatus.isPending })),
                    React.createElement(IoMdClose, { className: "remove-btn", onClick: () => fields.remove(i) })),
                React.createElement("div", { className: "d-flex align-items-center mb-2" },
                    React.createElement(Field, { component: AntTextAreaField, className: className, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_SMS_MESSAGE_PLACEHOLDER')}`, name: `${member}.message`, disabled: disabled || propertiesStatus.isPending, autoFocus: false })),
                i < fields.length - 1 && React.createElement(Divider, { className: "mt-2 mb-3" })))),
            React.createElement("a", { href: "#", className: "d-block", onClick: (e) => {
                    e.preventDefault();
                    fields.push({});
                } },
                t('PROCESS_META__ADD_TASK'),
                " ",
                t('TASK_SMS_MESSAGE_MAPPING')))));
};
export default MessageMappingsField;
//
// Utils
//
const valueToKey = (value) => {
    switch (value) {
        case true:
            return 'true';
        case false:
            return 'false';
        default:
            return undefined;
    }
};
const keyToValue = (value) => {
    switch (value) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return null;
    }
};
const CheckBoxMappingField = ({ input: { value, onChange }, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(Select, { value: valueToKey(value), className: "w-100", onChange: (key) => onChange(keyToValue(key)), disabled: disabled },
        React.createElement(Option, { value: "true" }, t('YES')),
        React.createElement(Option, { value: "false" }, t('NO'))));
};
