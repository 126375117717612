import { isArray, isObject, isString } from 'lodash';
export { AggregationType } from '@hypercharge/portal-utils';
export var TermsAggregationFilterOrderBy;
(function (TermsAggregationFilterOrderBy) {
    TermsAggregationFilterOrderBy["field"] = "field";
    TermsAggregationFilterOrderBy["count"] = "count";
})(TermsAggregationFilterOrderBy || (TermsAggregationFilterOrderBy = {}));
export const isTermsAggregationResult = (data) => isObject(data) && 'value' in data && isString(data.value);
export const isTermsAggregationResultArray = (data) => isArray(data) && data.every(isTermsAggregationResult);
export const isMetricAggregation = (data) => data && 'value' in data;
export const isMetricAggregationResult = (data) => !!data && !isArray(data) && 'value' in data;
