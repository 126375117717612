import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
const StyledNavLink = styled(NavLink) `
  position: relative;
  font-size: 1.1rem;
  padding: calc(${({ spacing }) => spacing} + 1px) 0.5rem;
  color: ${({ theme }) => theme.textColor} !important;
  font-weight: 400;
  overflow: hidden;

  &:after {
    background: ${({ theme }) => theme.mainColor};
    bottom: calc(${({ spacing }) => spacing} - 1px);
    content: '';
    display: block;
    height: 2px;
    border-radius: 1px;
    position: relative;
    width: 0;
    transition: width 200ms ease-in-out;
  }

  &.${({ activeClassName }) => activeClassName} {
    color: ${({ theme }) => theme.mainColor} !important;
  }

  &.${({ activeClassName }) => activeClassName}, &:hover {
    &:after {
      width: 100%;
    }
  }
`;
const NavbarLink = ({ className, children, spacing, exact, entity, ...otherProps }) => (React.createElement(StyledNavLink, { className: className, activeClassName: "navbar-link-active", spacing: spacing, exact: exact, entity: entity, ...otherProps }, children));
export default NavbarLink;
