import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Collapse, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Drawer, DrawerContent } from '../Drawer';
import { DrawerContentBlock } from '../Drawer/DrawerContentBlock';
import { PushMenu } from '../push-menu';
import CollapsibleSearchInput from './CollapsibleSearchInput';
import styles from './MobileSideMenu.module.scss';
import { AddItem, StyledAdd, Title } from './utils';
const getExpandIcon = () => React.createElement(MdKeyboardArrowRight, { size: 18 });
const MobileSideMenu = ({ isLoading, title, headerIcon: HeaderIcon, createPath, openedSections, updateOpenedSections, itemsBySection, sectionTitles, handleSearchChange, search, handleFeaturedChange, withFeaturedEntitiesSwitch, items }) => {
    const { t } = useI18n();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = useCallback(() => {
        setIsDrawerOpen((x) => !x);
    }, []);
    const getContentJsx = useCallback(() => {
        if (search !== '' || isEmpty(sectionTitles)) {
            return (React.createElement(PushMenu, { itemClassName: styles.mobileMenuItem, items: items, onItemClick: toggleDrawer, hideHome: true, withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, handleFeaturedChange: handleFeaturedChange }));
        }
        return (React.createElement(Collapse, { className: styles.collapse, activeKey: openedSections, onChange: updateOpenedSections, bordered: false, expandIconPosition: "start", expandIcon: getExpandIcon, items: sectionTitles.map((sectionTitle) => ({
                key: sectionTitle,
                label: sectionTitle,
                className: 'MobileSideMenu__panel',
                children: (React.createElement(PushMenu, { key: `${sectionTitle}-items`, itemClassName: styles.mobileMenuItem, items: itemsBySection[sectionTitle], onItemClick: toggleDrawer, hideHome: true, withFeaturedEntitiesSwitch: withFeaturedEntitiesSwitch, handleFeaturedChange: handleFeaturedChange }))
            })) }));
    }, [
        search,
        sectionTitles,
        openedSections,
        updateOpenedSections,
        items,
        toggleDrawer,
        withFeaturedEntitiesSwitch,
        handleFeaturedChange,
        itemsBySection
    ]);
    return (React.createElement("div", { id: "side-menu-title" },
        React.createElement(Button, { type: "text", className: `${styles.stickyMenuLabel} d-flex align-items-center`, onClick: toggleDrawer },
            !!HeaderIcon && React.createElement(HeaderIcon, null),
            React.createElement("div", { className: "MobileSideMenu__sticky-menu-label-text" }, title)),
        React.createElement(Drawer, { className: styles.drawer, title: React.createElement("div", { className: "cp-c-row cp-c-align-spacebetween-center" },
                React.createElement(Title, { className: styles.drawer__title }, title),
                createPath && !!HeaderIcon && (React.createElement(Tooltip, { placement: "top", title: t('ADD_DEFINITION_SUBTITLE') },
                    React.createElement(AddItem, { to: createPath, onClick: toggleDrawer },
                        React.createElement(HeaderIcon, { className: "create-icon" }),
                        React.createElement(StyledAdd, null))))), placement: "left", closable: false, onClose: toggleDrawer, open: isDrawerOpen },
            React.createElement(DrawerContent, { loading: isLoading },
                React.createElement(DrawerContentBlock, null,
                    React.createElement(CollapsibleSearchInput, { onChange: handleSearchChange, value: search }),
                    getContentJsx())))));
};
export default MobileSideMenu;
