import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import React from 'react';
import { CircleSkeleton, DropDownStyled } from './styled';
const circleStyle = { marginRight: '0.5rem', minWidth: '32px' };
const UserSkeleton = () => (React.createElement(Skeleton, { className: "cp-c-row" },
    React.createElement(CircleSkeleton, { heightInPx: 32, widthInPx: 32, radiusInPx: 50, colored: true, style: circleStyle }),
    React.createElement("div", { className: "cp-c-column cp-c-align-spacebetween-start" },
        React.createElement(TextPlaceholder, { heightInPx: 14, widthInPx: 55 }),
        React.createElement(TextPlaceholder, { heightInPx: 14, widthInPx: 75 })),
    React.createElement(DropDownStyled, null)));
export default UserSkeleton;
