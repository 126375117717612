import React from 'react';
import { Field } from 'redux-form';
import styled, { css } from 'styled-components';
import { SectionCard } from '../../common/section-card';
import { t } from '../../i18n';
const borderWidth = '1px';
export const getFieldCss = (customTheme) => css `
    cursor: text;
    background-color: #fff;
    border-width: ${borderWidth};
    border-style: solid;
    border-color: ${customTheme
    ? '#ccc'
    : ({ theme, invalid }) => (invalid ? theme.invalidColor : '#ccc')};
    border-radius: ${customTheme
    ? customTheme.defaultRadius
    : ({ theme, borderRadius }) => (borderRadius ? borderRadius : theme.defaultRadius)};
    box-shadow: none;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
    width: 100%;
    outline: none;
    font-size: 0.9rem;
    line-height: 1.6;

    &:focus {
      border-color: ${customTheme
    ? customTheme.mainColor
    : ({ theme }) => (theme.alternativeByDefault ? theme.alternativeColor : theme.mainColor)};
    }

    &:disabled {
      background: #f5f5f5;
      color: #919191;
    }

    &::placeholder {
      color: #b3b3b3;
      font-weight: 400;
    }
  `;
export const fieldCss = getFieldCss();
// @deprecated This is deprecated and should be replaced with `FieldLabel`.
export const LabelText = styled.label `
  font-size: 0.9rem;
  padding-bottom: 0.3rem;
  display: block;
  color: ${({ theme }) => theme.textColor};
`;
export const InputContainer = styled.div `
  padding-bottom: 0.7rem !important;
`;
export const ErrorMessage = styled.div `
  color: ${({ theme }) => theme.invalidColor};
  font-size: 0.9rem;
`;
export const Section = styled.section `
  max-width: 40rem;
  margin: auto;
`;
export const Title = styled.div `
  padding: 1rem 1rem 0;
  font-size: 1.3rem;
  font-weight: 600;
`;
export const SubTitle = styled.div `
  padding: 0.25rem 1rem 2rem 1rem;
  font-size: 0.9rem;
`;
export const DescriptionText = styled.div `
  font-size: 0.9rem;
`;
export const Form = styled.form `
  padding: 0 1rem;
`;
export const slug = (value) => value && value.replace(/[^-\w.]+/g, '_');
export const normalizeBoolean = (value) => {
    switch (value) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return value;
    }
};
export const emptyToNull = (value) => {
    switch (value) {
        case '':
            return null;
        default:
            return value;
    }
};
export const buildTwoColumnFormSections = (formSections) => formSections.map(({ titleKey, fields, sectionCardProps = {} }) => (React.createElement(SectionCard, { key: titleKey, title: t(titleKey), withoutPadding: true, ...sectionCardProps },
    React.createElement("div", { className: "cp-c-row cp-c-wrap cp-c-padding-2" }, fields.map(({ fullRow, ...fieldProps }) => (React.createElement("div", { key: fieldProps.name, className: `cp-i-100 ${fullRow ? '' : 'cp-i-sm-50'}` },
        React.createElement(Field, { ...fieldProps }))))))));
