import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Input, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import { getForm } from '../../../../../../forms/selectors';
import ProcessPropertyMappingsField from '../ProcessPropertyMappingsField';
const FormPropertySection = ({ disabled, input }) => {
    const { t } = useI18n();
    const [showFormSettings, setShowFormSettings] = useState(false);
    const form = useSelector((state) => getForm(state, input.value));
    const formDefinitionId = form?.definitionId;
    const onClickToggle = useCallback(() => {
        setShowFormSettings(!showFormSettings);
    }, [showFormSettings]);
    return formDefinitionId ? (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "toggle-btn btn btn-link ms-3", onClick: onClickToggle }, showFormSettings ? t('TASK_EMAIL_HIDE_FORM_OPTIONS') : t('TASK_EMAIL_SHOW_FORM_OPTIONS')),
        showFormSettings && (React.createElement("div", { className: "form-settings" },
            React.createElement(Field, { name: "propertyMappings", component: ProcessPropertyMappingsField, definitionId: formDefinitionId, includeNestedProperties: false, disabled: disabled }),
            React.createElement("div", null,
                React.createElement(LabelText, null,
                    React.createElement("span", null, t('TASK_GENERATE_FORM_URL_EXPIRES')),
                    React.createElement(Tooltip, { overlayStyle: { whiteSpace: 'pre-line' }, title: t('TASK_GENERATE_FORM_URL_EXPIRES_INFO') },
                        React.createElement(IoMdInformationCircle, { className: "infoIcon" }))),
                React.createElement(Field, { name: "linkExpires", component: ComputedInputField, computedComponent: Input, disabled: disabled })),
            React.createElement("div", null,
                React.createElement(LabelText, null,
                    React.createElement("span", null, t('TASK_GENERATE_FORM_URL_LIMIT_AMOUNT_OF_SUBMIT')),
                    React.createElement(Tooltip, { overlayStyle: { whiteSpace: 'pre-line' }, title: t('TASK_GENERATE_FORM_URL_LIMIT_AMOUNT_OF_SUBMIT_INFO') },
                        React.createElement(IoMdInformationCircle, { className: "infoIcon" }))),
                React.createElement(Field, { name: "formSubmissionsLimit", component: ComputedInputField, computedComponent: Input, disabled: disabled })),
            React.createElement("div", null,
                React.createElement(LabelText, null,
                    React.createElement("span", null, t('TASK_EMAIL_TEMPLATE_FORM_VARIABLE_NAME'))),
                React.createElement(Field, { name: "templateVariable", component: ComputedInputField, computedComponent: Input, includeNestedProperties: false, disabled: disabled })))))) : null;
};
export default FormPropertySection;
