import { BasicInput, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { TENANT_ID } from 'config';
import { Field } from 'formik';
import React, { Fragment, useCallback, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import MultiTextEditor from '../../../../cms/data/components/item-property/editors/MultiTextEditor';
import Toggle from '../../../../common/components/Toggle';
import FileInput from '../../../../common/components/file-input';
import FormikField from '../../../../common/components/formik/FormikField';
import { FileField, fetchFilesData, fetchUploadUrl, getFileData, isFileDataAvailable, isFileDataPending, uploadFile } from '../../../../common/storage';
const WebSiteForm = ({ instance, disabled }) => {
    const { t } = useI18n();
    const isLogoPending = useSelector((state) => isFileDataPending(state, instance.configuration.website.logo?.[0]));
    const isLogoAvailable = useSelector((state) => isFileDataAvailable(state, instance.configuration.website.logo?.[0]));
    const logo = useSelector((state) => getFileData(state, instance.configuration.website.logo?.[0]));
    const isFaviconPending = useSelector((state) => isFileDataPending(state, instance.configuration.website.favicon?.[0]));
    const isFaviconAvailable = useSelector((state) => isFileDataAvailable(state, instance.configuration.website.favicon?.[0]));
    const favicon = useSelector((state) => getFileData(state, instance.configuration.website.favicon?.[0]));
    const isFontsPending = useSelector((state) => isFileDataPending(state, instance.configuration.website.fonts?.[0]));
    const isFontsAvailable = useSelector((state) => isFileDataAvailable(state, instance.configuration.website.fonts?.[0]));
    const fonts = useSelector((state) => {
        const urlData = [];
        instance.configuration.website.fonts?.forEach((font) => {
            const data = getFileData(state, font);
            if (data) {
                urlData.push(data);
            }
        });
        return urlData;
    });
    const uploadFileDispatch = useDispatch();
    const fetchUploadUrlDispatch = useDispatch();
    const fetchFileDataDispatch = useDispatch();
    const fetchFileData = useCallback((fileId) => fetchFileDataDispatch(fetchFilesData(fileId)), [fetchFileDataDispatch]);
    useEffect(() => {
        if (instance.configuration.website.logo && instance.configuration.website.logo.length) {
            if (!isLogoAvailable) {
                fetchFileData(instance.configuration.website.logo);
            }
        }
    }, [fetchFileData, instance.configuration.website.logo, isLogoAvailable]);
    useEffect(() => {
        if (instance.configuration.website.favicon && instance.configuration.website.favicon.length) {
            if (!isFaviconAvailable) {
                fetchFileData(instance.configuration.website.favicon);
            }
        }
    }, [fetchFileData, instance.configuration.website.favicon, isFaviconAvailable]);
    useEffect(() => {
        if (instance.configuration.website.fonts && instance.configuration.website.fonts.length) {
            if (!isFontsAvailable) {
                fetchFileData(instance.configuration.website.fonts);
            }
        }
    }, [fetchFileData, instance.configuration.website.fonts, isFontsAvailable]);
    const upload = useCallback(async (file) => {
        const data = await fetchUploadUrlDispatch(fetchUploadUrl(file, TENANT_ID, true));
        if (data.uploadUrl) {
            await uploadFileDispatch(uploadFile(file, data, data.uploadUrl));
        }
        return data;
    }, [uploadFileDispatch, fetchUploadUrlDispatch]);
    const uploadList = (fileList) => Promise.all(fileList.map(upload));
    const onSaveFiles = useCallback((files, form) => {
        return uploadList(files).then((uploadedFiles) => {
            form.setFieldValue('fonts', [
                ...(form.values.fonts || []),
                ...uploadedFiles.map((f) => f.id)
            ]);
        });
    }, []);
    const onRemoveFile = useCallback((fileId, form) => {
        return new Promise((resolve, reject) => {
            try {
                form.setFieldValue('fonts', (fonts || []).filter((f) => f?.id !== fileId).map((f) => f?.id));
            }
            catch (e) {
                reject(e);
            }
            finally {
                resolve(undefined);
            }
        });
    }, [fonts]);
    const onFilesSorted = useCallback((files, form) => form.setFieldValue('fonts', files.map((f) => f.id)), []);
    return (React.createElement("div", { className: "websiteFormContainer" },
        React.createElement(FormikField, { name: "configuration.website.favicon", component: FileInput, label: t('APPS_HYPER_DEALER__FAVICON'), buttonTitle: React.createElement(Fragment, null,
                React.createElement(MdAdd, null),
                t('APPS_HYPER_DEALER__UPLOAD_BUTTON')), value: favicon, loading: isFaviconPending, uploadFn: upload, clearValue: (uploadedFile) => [uploadedFile.id] }),
        React.createElement(FormikField, { name: "configuration.website.logo", component: FileInput, label: t('APPS_HYPER_DEALER__LOGO'), buttonTitle: React.createElement(Fragment, null,
                React.createElement(MdAdd, null),
                t('APPS_HYPER_DEALER__UPLOAD_BUTTON')), value: logo, loading: isLogoPending, uploadFn: upload, clearValue: (uploadedFile) => [uploadedFile.id] }),
        React.createElement(FormikField, { name: "configuration.website.mainColor", component: BasicInput, label: `${t('APPS_HYPER_DEALER__MAIN_COLOR')} *`, value: instance.configuration.website.mainColor, disabled: disabled }),
        React.createElement(FormikField, { name: "configuration.website.accentColor", component: BasicInput, label: `${t('APPS_HYPER_DEALER__ACCENT_COLOR')} *`, value: instance.configuration.website.accentColor, disabled: disabled }),
        React.createElement("div", null,
            React.createElement(Field, { name: "configuration.website.fonts" }, ({ form }) => {
                return (React.createElement(FileField, { label: t('APPS_HYPER_DEALER__CUSTOM_FONT'), files: fonts, meta: { list: true, accept: '.ttf, .woff, .woff2', carousel: false }, error: false, canEdit: !isFontsPending, displayActions: true, loading: false, onSave: (files) => onSaveFiles(files, form), onRemove: (fileId) => onRemoveFile(fileId, form), onChange: (files) => onFilesSorted(files, form) }));
            })),
        React.createElement("div", null,
            React.createElement(LabelText, null, t('APPS_HYPER_DEALER__CUSTOM_HOSTS')),
            React.createElement(FormikField, { name: "configuration.customHosts", component: MultiTextEditor, value: instance.configuration?.customHosts, disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.enableIcons", component: Toggle, label: t('APPS_HYPER_DEALER__ENABLE_ICONS'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.imageCover", component: Toggle, label: t('APPS_HYPER_DEALER__ENABLE_IMAGE_COVER'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.showLeasePrice", component: Toggle, label: t('APPS_HYPER_DEALER__SHOW_LEASE_PRICE'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.showLeaseCalc", component: Toggle, label: t('APPS_HYPER_DEALER__SHOW_LEASE_CALC'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.isVatIncluded", component: Toggle, label: t(instance.configuration.website.isVatIncluded
                    ? 'APPS_HYPER_DEALER__VAT_INCLUDED'
                    : 'APPS_HYPER_DEALER__VAT_EXCLUDED'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.disableBreadcrumbs", component: Toggle, label: t('APPS_HYPER_DEALER__DISABLE_BREADCRUMBS'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.hideCtaWhenSold", component: Toggle, label: t('APPS_HYPER_DEALER__HIDE_CTA_SOLD'), disabled: disabled })),
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FormikField, { name: "configuration.website.reduceAggregationCountInfo", component: Toggle, label: t('APPS_HYPER_DEALER__REDUCE_AGG_COUNT_INFO'), disabled: disabled }))));
};
export default WebSiteForm;
