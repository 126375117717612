import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { find, get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { hasTenantPermission, redirectIfNoPermission } from '../../../auth';
import { TITLE_PROPERTY_ID } from '../../../cms';
import ItemsContainer from '../../../cms/data/components/items/ItemsContainer';
import useDisplayProcessMetaList from '../../../cms/hooks/useDisplayProcessMetaList';
import { HeightContextProvider } from '../../../common/components/height-context';
import { APP_ADMIN_PERMISSION, BROWSE_PATH, SETTINGS_PATH, VIEW_PATH } from '../../../common/utils/constants';
import EditableViewTitleComponent from '../../../views/components/EditableViewTitleComponent';
import ProcessMetaRoutes from '../../meta/components/common/ProcessMetaRoutes';
import ProcessContainer from '../../processes/components/process/ProcessContainer';
import styles from './FeaturedProcessesRoutes.module.scss';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const otherHeightInPx = 10; // data action block;
const BreadcrumbsWrapper = ({ links, currentBreadcrumbText, currentBreadcrumbComponent, children }) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "BreadcrumbsContainer", id: "breadcrumbs" },
        React.createElement(Breadcrumbs, { links: links, currentBreadcrumbText: currentBreadcrumbText, currentBreadcrumbComponent: currentBreadcrumbComponent })),
    React.createElement("div", { className: styles.container }, children)));
const RestrictedWorkflowsRoutes = ({ match: { url } }) => {
    const { t } = useI18n();
    const [links, setLinks] = useState([]);
    const [currentBreadcrumbText, setCurrentBreadcrumbText] = useState('');
    const hasAdminPermission = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION));
    const { data: displayProcessMetaList, isPending } = useDisplayProcessMetaList();
    const getProcessTitle = useCallback((processMetaId) => get(find(displayProcessMetaList, { processMetaId }), TITLE_PROPERTY_ID) ||
        (isPending ? '...' : t('NO_TITLE')), [displayProcessMetaList, isPending, t]);
    const [extraTextForBreadcrumbsComponent, setExtraTextForBreadcrumbsComponent] = useState();
    return (React.createElement(ErrorBoundary, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: [
                    `${url}/:processMetaId${BROWSE_PATH}/:processRunId`,
                    `${url}/:processMetaId${VIEW_PATH}/:viewId/:processRunId`
                ], render: (props) => {
                    const { processMetaId, viewId, processRunId } = props.match.params;
                    return (React.createElement(ProcessContainer, { baseUrl: `${url}/${processMetaId}`, processMetaId: processMetaId, viewId: viewId, processRunId: processRunId, ...props }));
                } }),
            React.createElement(Route, { exact: true, path: [
                    `${url}/:processMetaId${BROWSE_PATH}`,
                    `${url}/:processMetaId${VIEW_PATH}/:viewId`
                ], render: (props) => {
                    const { processMetaId, viewId } = props.match.params;
                    return (React.createElement(BreadcrumbsWrapper, { links: links, currentBreadcrumbComponent: React.createElement(EditableViewTitleComponent, { definitionId: processMetaId, viewId: viewId, totalItemsCount: extraTextForBreadcrumbsComponent, processTitle: getProcessTitle(processMetaId) }) },
                        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
                            React.createElement(ItemsContainer, { baseUrl: `${url}/${processMetaId}`, canDelete: !!find(displayProcessMetaList, { processMetaId, canDelete: true }), canRun: !!find(displayProcessMetaList, { processMetaId, canExecute: true }), definitionId: processMetaId, key: processMetaId, processTitle: getProcessTitle(processMetaId), setCurrentBreadcrumbText: setCurrentBreadcrumbText, setLinks: setLinks, setExtraTextForBreadcrumbsComponent: setExtraTextForBreadcrumbsComponent, ...props }))));
                } }),
            hasAdminPermission && (React.createElement(Route, { path: `${url}/:processMetaId${SETTINGS_PATH}`, render: (props) => {
                    const { processMetaId } = props.match.params;
                    return (React.createElement(BreadcrumbsWrapper, { links: links, currentBreadcrumbText: currentBreadcrumbText },
                        React.createElement("div", { className: styles.settingsContainer },
                            React.createElement(ProcessMetaRoutes, { key: props.match.params.processMetaId, contentHeight: undefined, processMetaId: processMetaId, setLinks: setLinks, setCurrentBreadcrumbText: setCurrentBreadcrumbText, baseUrl: `${url}/${processMetaId}`, ...props }))));
                } })),
            React.createElement(Redirect, { to: url }))));
};
export default redirectIfNoPermission(RestrictedWorkflowsRoutes);
