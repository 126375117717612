import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { find, findKey, flatten, isEmpty, last } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useDebounce } from 'use-debounce';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../../cms';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { MY_USER_COMPUTED } from '../../../cms/common/utils/constants';
import { getPropertyById } from '../../../cms/common/utils/utils';
import { isTermsAggregationResultArray, useAggregation } from '../../../cms/hooks/useAggregation';
import useDisplayItemMetaList from '../../../cms/hooks/useDisplayItemMetaList';
import { useEntityItems } from '../../../cms/hooks/useEntityItems';
import { isConditionQuery } from '../../../common/components/ConditionQuery/utils';
import { useMyContact } from '../../../crm/components/use-my-contact';
import { getColumnDefinitionIdKanbanGroupBy } from '../../../views/utils';
import { useTaskDashboard } from '../components/dashboard/TaskDashboardContext/TaskDashboardProvider';
import { TasksFilterType, isTasksFilterType } from '../types';
import { aggregations, getFiltersWithoutField, getSearchFilters, tasksDashboardDeadlineColumns } from '../utils';
const useCustomLogicTasksKanban = ({ definitionId, filterRequest, mapProperty, addEmptyAssignedOption, relatedToTitleKey }) => {
    const { displayData } = useEntityDisplayData(definitionId);
    const { getDefaultFilterQueryTaskDashboard } = useTaskDashboard();
    const { t, language } = useI18n();
    const { contactId } = useMyContact();
    const [search, setSearch] = useState('');
    const [debouncedSearch] = useDebounce(search, 300);
    const customOptionsGroupBy = useMemo(() => [
        {
            value: mapProperty[TasksFilterType.relatedTo],
            label: t(relatedToTitleKey)
        },
        {
            value: mapProperty[TasksFilterType.taskTitles],
            label: t('TASK_TITLE')
        },
        {
            value: mapProperty[TasksFilterType.assignees],
            label: t('ASSIGNEE')
        },
        {
            value: mapProperty[TasksFilterType.deadline],
            label: t('DEADLINE_DATE')
        }
    ], [mapProperty, relatedToTitleKey, t]);
    const { kanbanForm: { groupBy: groupByForm = mapProperty[TasksFilterType.assignees], columns: columnsForm } = {} } = useSelector((s) => {
        const filtersForm = formValueSelector('filters');
        return {
            kanbanForm: filtersForm(s, 'kanban')
        };
    }, shallowEqual);
    const columnDefinitionIdKanbanGroupBy = useMemo(() => getColumnDefinitionIdKanbanGroupBy(displayData, groupByForm), [displayData, groupByForm]);
    const aggregationField = useMemo(() => columnDefinitionIdKanbanGroupBy ? `${groupByForm}.${ENTITY_ID_PROPERTY_ID}` : groupByForm, [columnDefinitionIdKanbanGroupBy, groupByForm]);
    const titleProperty = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const titleTranslatedKey = titleProperty?.meta.translations?.[language];
    const aggregationsSelectedTasksConfig = useMemo(() => aggregations({
        field: mapProperty[TasksFilterType.taskTitles],
        filterQuery: {
            condition: ConditionType.or,
            filters: columnsForm?.map((taskTitle) => ({
                field: mapProperty[TasksFilterType.taskTitles],
                operator: FilterOperatorTypes.is,
                data: taskTitle
            })) || []
        },
        addAggregationField: titleTranslatedKey,
        language,
        contactId: contactId || '',
        needTotalCount: false,
        size: columnsForm?.length
    }), [columnsForm, contactId, language, mapProperty, titleTranslatedKey]);
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const handleGetSearchFilter = useCallback((groupBy) => getSearchFilters(debouncedSearch, groupBy, mapProperty, displayItemMetaList, titleTranslatedKey), [debouncedSearch, displayItemMetaList, mapProperty, titleTranslatedKey]);
    const aggregationsConfig = useMemo(() => {
        const filters = [];
        if (groupByForm) {
            const keyGroupByForm = findKey(mapProperty, (item) => item === groupByForm);
            if (keyGroupByForm && isTasksFilterType(keyGroupByForm)) {
                filters.push(...getFiltersWithoutField(filterRequest, keyGroupByForm));
                filters.push(...handleGetSearchFilter(keyGroupByForm));
            }
        }
        if (columnsForm) {
            filters.push(...columnsForm.map((column) => ({
                field: aggregationField,
                operator: FilterOperatorTypes.isNot,
                data: column
            })));
        }
        return aggregations({
            field: aggregationField,
            filterQuery: {
                condition: ConditionType.and,
                filters: getDefaultFilterQueryTaskDashboard({ filters }).filters
            },
            addAggregationField: columnDefinitionIdKanbanGroupBy
                ? `${groupByForm}.${TITLE_PROPERTY_ID}`
                : titleTranslatedKey && groupByForm === mapProperty[TasksFilterType.taskTitles]
                    ? titleTranslatedKey
                    : undefined,
            language,
            contactId: contactId || '',
            needTotalCount: false
        });
    }, [
        aggregationField,
        columnDefinitionIdKanbanGroupBy,
        columnsForm,
        contactId,
        filterRequest,
        getDefaultFilterQueryTaskDashboard,
        groupByForm,
        handleGetSearchFilter,
        language,
        mapProperty,
        titleTranslatedKey
    ]);
    const { data: { aggregations: { [aggregationField]: aggregationsResult } = {} } = {} } = useAggregation({
        definitionId,
        aggregations: aggregationsConfig.aggregations,
        metrics: aggregationsConfig.metrics,
        filter: aggregationsConfig.filter,
        enabled: !!displayData && !!groupByForm && groupByForm !== mapProperty[TasksFilterType.deadline]
    });
    const { data: { aggregations: { [TITLE_PROPERTY_ID]: aggregationsSelectedTitleResult = undefined } = {} } = {} } = useAggregation({
        definitionId,
        aggregations: aggregationsSelectedTasksConfig.aggregations,
        metrics: aggregationsSelectedTasksConfig.metrics,
        filter: aggregationsSelectedTasksConfig.filter,
        enabled: !!groupByForm && groupByForm === mapProperty[TasksFilterType.taskTitles]
    });
    const availableOptions = useMemo(() => {
        const availableOptions = [];
        if (groupByForm === mapProperty[TasksFilterType.deadline]) {
            availableOptions.push(...tasksDashboardDeadlineColumns
                .filter((column) => !columnsForm?.includes(column))
                .reduce((acc, column) => [
                ...acc,
                {
                    value: column,
                    label: column === FilterOperatorTypes.empty ? t('NO_DEADLINE') : t(column)
                }
            ], [])
                .filter((column) => column.label.includes(debouncedSearch)));
        }
        if (isTermsAggregationResultArray(aggregationsResult)) {
            if (addEmptyAssignedOption &&
                groupByForm === mapProperty[TasksFilterType.assignees] &&
                !columnsForm?.includes(FilterOperatorTypes.empty)) {
                availableOptions.push({
                    value: FilterOperatorTypes.empty,
                    label: t('NOT_ASSIGNED')
                });
            }
            if (groupByForm === mapProperty[TasksFilterType.assignees] &&
                !columnsForm?.includes(MY_USER_COMPUTED)) {
                availableOptions.push({
                    value: MY_USER_COMPUTED,
                    label: t('ASSIGN_TO_ME')
                });
            }
            if (groupByForm === mapProperty[TasksFilterType.relatedTo] &&
                !isEmpty(debouncedSearch) &&
                !flatten(handleGetSearchFilter(groupByForm)
                    ?.filter(isConditionQuery)
                    .map(({ filters }) => filters)).length) {
                return [];
            }
            availableOptions.push(...aggregationsResult.map((result) => {
                if (columnDefinitionIdKanbanGroupBy) {
                    const labelAggregation = `${groupByForm}.${TITLE_PROPERTY_ID}`;
                    const labelAggregations = result[labelAggregation];
                    if (isTermsAggregationResultArray(labelAggregations)) {
                        return {
                            value: result.label.toString(),
                            label: labelAggregations[0].label.toString()
                        };
                    }
                }
                if (groupByForm === mapProperty[TasksFilterType.relatedTo]) {
                    return {
                        value: result.label.toString(),
                        label: find(displayItemMetaList, { definitionId: result.label.toString() })?.title ||
                            result.label.toString()
                    };
                }
                return {
                    value: result.label.toString(),
                    label: (titleTranslatedKey &&
                        last(result[titleTranslatedKey])?.label?.toString()) ||
                        result.label.toString()
                };
            }));
        }
        return availableOptions;
    }, [
        groupByForm,
        mapProperty,
        aggregationsResult,
        columnsForm,
        t,
        debouncedSearch,
        addEmptyAssignedOption,
        handleGetSearchFilter,
        columnDefinitionIdKanbanGroupBy,
        titleTranslatedKey,
        displayItemMetaList
    ]);
    const { data: results } = useEntityItems({
        definitionId: columnDefinitionIdKanbanGroupBy || '',
        ids: columnsForm || [],
        enabled: !!columnDefinitionIdKanbanGroupBy && !!columnsForm && !isEmpty(columnsForm),
        keepPreviousData: true
    });
    const selectedColumns = useMemo(() => {
        if (!columnsForm) {
            return [];
        }
        if (groupByForm === mapProperty[TasksFilterType.deadline]) {
            return columnsForm.map((column) => ({
                value: column,
                label: column === FilterOperatorTypes.empty ? t('NO_DEADLINE') : t(column)
            }));
        }
        if (groupByForm === mapProperty[TasksFilterType.relatedTo]) {
            return columnsForm.map((column) => ({
                value: column,
                label: find(displayItemMetaList, { definitionId: column })?.title || column
            }));
        }
        if (columnDefinitionIdKanbanGroupBy && results) {
            return columnsForm.map((column) => {
                return {
                    value: column,
                    label: (column === FilterOperatorTypes.empty && t('NOT_ASSIGNED')) ||
                        (column === MY_USER_COMPUTED && t('ASSIGN_TO_ME')) ||
                        find(results, { entityId: column })?.title ||
                        column
                };
            });
        }
        if (groupByForm === mapProperty[TasksFilterType.taskTitles]) {
            return columnsForm.map((column) => {
                const label = (isTermsAggregationResultArray(aggregationsSelectedTitleResult) &&
                    titleTranslatedKey &&
                    last(find(aggregationsSelectedTitleResult, { label: column })?.[titleTranslatedKey])?.label?.toString()) ||
                    column;
                return {
                    value: column,
                    label
                };
            });
        }
        return columnsForm.map((column) => ({
            value: column,
            label: column
        }));
    }, [
        columnsForm,
        groupByForm,
        mapProperty,
        columnDefinitionIdKanbanGroupBy,
        results,
        t,
        displayItemMetaList,
        aggregationsSelectedTitleResult,
        titleTranslatedKey
    ]);
    const onChangeCustomSearch = useCallback((searchValue) => {
        setSearch(searchValue);
    }, []);
    return { availableOptions, selectedColumns, customOptionsGroupBy, onChangeCustomSearch };
};
export default useCustomLogicTasksKanban;
