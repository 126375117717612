import { ConditionType } from '@hypercharge/portal-utils';
import { Button } from 'antd';
import cn from 'classnames';
import { findIndex, isEmpty, isEqual, isString, isUndefined } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useView } from '../../../../views/components/ViewContext';
import { getFilterPropertyTypeComponent } from '../../../../workflows/meta/components/meta-definition-form/utils';
import DropdownButton from '../../DropdownButton';
import ActionsBlock from '../../InlineEditor/ActionsBlock';
import { supportedFilterPropertyTypes } from '../ConditionQueryField';
import { QuickFilterId } from '../constants';
import { buildStartQuickFilter, getFieldRootLevel, getFilterByPropertyId, getFilterComponentByType, getQuickFilters, isConditionQuery, isFilterType, isOperatorWithoutData } from '../utils';
import styles from './QuickFilter.module.scss';
export const QuickFilter = React.memo(({ type, propertyId, meta }) => {
    const { filterRequest, metrics, updateRouteWithView, viewType, rowGrouping } = useView();
    const [quickFilters, setQuickFilters] = useState(getQuickFilters(filterRequest));
    const [openQuickFilters, setOpenQuickFilters] = useState(false);
    const currentFilter = useMemo(() => getFilterByPropertyId(getQuickFilters(filterRequest), propertyId, type), [filterRequest, propertyId, type]);
    const FilterIcon = useMemo(() => getFilterPropertyTypeComponent(type), [type]);
    const startFilter = useMemo(() => buildStartQuickFilter(propertyId, type, meta), [meta, propertyId, type]);
    const filterByPropertyId = useMemo(() => getFilterByPropertyId(quickFilters, propertyId, type), [propertyId, quickFilters, type]);
    const handleChange = useCallback((filter) => {
        const field = getFieldRootLevel(filter.field, type);
        setQuickFilters((prevQuickFilters) => {
            const quickFilterIndex = findIndex(prevQuickFilters, { field });
            const hasQuickFilter = quickFilterIndex !== -1;
            if ('data' in filter && isString(filter.data) && !filter.data && hasQuickFilter) {
                return [
                    ...prevQuickFilters.slice(0, quickFilterIndex),
                    ...prevQuickFilters.slice(quickFilterIndex + 1)
                ];
            }
            const newFilter = { ...filter, field };
            return hasQuickFilter
                ? [
                    ...prevQuickFilters.slice(0, quickFilterIndex),
                    newFilter,
                    ...prevQuickFilters.slice(quickFilterIndex + 1)
                ]
                : [...prevQuickFilters, newFilter];
        });
    }, [type]);
    const handleClear = useCallback((filterToDelete) => {
        setQuickFilters((prevQuickFilters) => {
            const quickFilterIndex = findIndex(prevQuickFilters, filterToDelete);
            const hasQuickFilter = quickFilterIndex !== -1;
            const newFilters = hasQuickFilter
                ? [
                    ...prevQuickFilters.slice(0, quickFilterIndex),
                    ...prevQuickFilters.slice(quickFilterIndex + 1)
                ]
                : prevQuickFilters;
            return newFilters;
        });
    }, []);
    const resetQuickFilters = useCallback(() => {
        setQuickFilters(getQuickFilters(filterRequest));
    }, [filterRequest]);
    const onCancel = useCallback(() => {
        resetQuickFilters();
        setOpenQuickFilters(false);
    }, [resetQuickFilters]);
    useEffect(() => {
        resetQuickFilters();
    }, [resetQuickFilters]);
    const onSave = useCallback(() => {
        const quickFiltersForSave = [...quickFilters];
        const hasOperatorDefined = quickFiltersForSave.filter(isFilterType);
        const newQuickFilters = !isEmpty(quickFiltersForSave) && hasOperatorDefined
            ? [
                {
                    id: QuickFilterId,
                    condition: ConditionType.and,
                    filters: hasOperatorDefined
                }
            ]
            : [];
        updateRouteWithView({
            newFilters: {
                ...filterRequest,
                query: {
                    ...filterRequest.query,
                    filters: [
                        ...filterRequest.query.filters.filter((filter) => isConditionQuery(filter) ? filter.id !== QuickFilterId : true),
                        ...newQuickFilters
                    ]
                }
            },
            newMetrics: metrics,
            shouldReplacePath: false,
            viewType,
            rowGrouping
        });
        setOpenQuickFilters(false);
    }, [quickFilters, updateRouteWithView, filterRequest, metrics, viewType, rowGrouping]);
    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        onSave();
    }, [onSave]);
    const hasFilterChanged = useCallback((presetFilter, currentFilter) => {
        if ((isUndefined(currentFilter) && isUndefined(presetFilter.operator)) ||
            (isUndefined(currentFilter) && 'data' in presetFilter && presetFilter.data === '')) {
            return false;
        }
        else {
            const changed = !isEqual(presetFilter, currentFilter);
            return changed;
        }
    }, []);
    const presetFilter = useMemo(() => filterByPropertyId || startFilter, [filterByPropertyId, startFilter]);
    const FilterComponent = useMemo(() => getFilterComponentByType(type), [type]);
    if (!supportedFilterPropertyTypes.includes(type) || !FilterIcon) {
        return null;
    }
    const isActiveFilter = !!getFilterByPropertyId(getQuickFilters(filterRequest), propertyId, type);
    return (React.createElement(DropdownButton, { renderToParent: true, className: cn(styles.dropdownButton, 'skip-sort', { openQuickFilters, isActiveFilter }), popoverButton: React.createElement(Button, { type: "link", className: "safe-space popover p-1", icon: React.createElement(FilterIcon, { color: isActiveFilter ? 'var(--neutralColor-6)' : 'var(--neutralColor-4)', size: 16 }) }), popover: {
            destroyTooltipOnHide: true,
            open: openQuickFilters,
            onOpenChange: (value) => {
                if (!value) {
                    onSave();
                }
                setOpenQuickFilters(value);
            },
            content: (React.createElement(React.Fragment, null,
                React.createElement("form", { onSubmit: handleSubmit, className: "position-relative", onClick: (event) => event.stopPropagation() },
                    React.createElement(FilterComponent, { showSelectOperator: isUndefined(presetFilter.operator) || isOperatorWithoutData(presetFilter.operator), filter: presetFilter, onChange: handleChange, onClear: handleClear, meta: meta, autoFocus: true }),
                    React.createElement(ActionsBlock, { position: "top", changed: hasFilterChanged(presetFilter, currentFilter), onAccept: onSave, onCancel: onCancel })))),
            placement: 'bottom'
        } }));
});
