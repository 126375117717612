import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { TENANT_ID } from 'config';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId, getRefreshTokenExpiry, signOut } from '../../../../auth';
import { Header, LogOut } from './styled';
const LogoutHeader = ({ className }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const refreshTokenExpiry = useSelector(getRefreshTokenExpiry) || 0;
    const entityId = useSelector(getAccountId) || '';
    return (React.createElement(Header, { className: `cp-c-row cp-c-align-end-center ${className || ''}` },
        React.createElement(LogOut, { onClick: () => dispatch(signOut(TENANT_ID, entityId, refreshTokenExpiry)) }, t('AUTH_LOG_OUT_NAV_LINK'))));
};
export default LogoutHeader;
