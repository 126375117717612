export var InspectionSteps;
(function (InspectionSteps) {
    InspectionSteps["common"] = "common";
    InspectionSteps["gallery"] = "gallery";
    InspectionSteps["list"] = "list";
    InspectionSteps["table"] = "table";
})(InspectionSteps || (InspectionSteps = {}));
export const isCommonStepType = (step) => {
    return step.type === InspectionSteps.common;
};
export const isGalleryStepType = (step) => {
    return step.type === InspectionSteps.gallery;
};
export const isListStepType = (step) => {
    return step.type === InspectionSteps.list;
};
export const isTableStepType = (step) => {
    return step.type === InspectionSteps.table;
};
