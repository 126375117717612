import { pickBy, union } from 'lodash';
import { combineReducers } from 'redux';
function byPropertyId(state = {}, action) {
    let properties;
    let definitionId;
    switch (action.type) {
        case 'METADATA__FETCH_PROPERTY_SUCCESS':
            return {
                ...state,
                [action.payload.definitionId]: {
                    ...(state[action.payload.definitionId] || {}),
                    [action.payload.id]: action.payload
                }
            };
        case 'METADATA__FETCH_DEFINITION_PROPERTIES_SUCCESS':
        case 'METADATA__MIDDLEWARE_PROPERTIES':
            properties =
                action.type === 'METADATA__MIDDLEWARE_PROPERTIES'
                    ? action.payload
                    : Object.values(action.payload);
            return properties.reduce((newState, property) => ({
                ...newState,
                [property.definitionId]: {
                    ...(newState[property.definitionId] || {}),
                    [property.id]: property
                }
            }), state);
        case 'METADATA__DEFINITION_PROPERTIES_DELETE_SUCCESS':
            definitionId = action.meta.actionContext.definitionId;
            return {
                ...state,
                [definitionId]: pickBy(state[definitionId], (prop) => {
                    return prop.id !== action.meta.actionContext.id;
                })
            };
        case 'METADATA__DEFINITION_PROPERTY_SAVE_SUCCESS':
            return {
                ...state,
                [action.payload.definitionId]: {
                    ...(state[action.payload.definitionId] || {}),
                    [action.payload.id]: action.payload
                }
            };
        default:
            return state;
    }
}
function byDefinitionId(state = {}, action) {
    let properties = [];
    let definitionId;
    switch (action.type) {
        case 'METADATA__FETCH_DEFINITION_PROPERTIES_SUCCESS':
        case 'METADATA__MIDDLEWARE_PROPERTIES': {
            properties =
                action.type === 'METADATA__MIDDLEWARE_PROPERTIES'
                    ? action.payload
                    : Object.values(action.payload);
            return properties.reduce((newState, { id, definitionId }) => ({
                ...newState,
                [definitionId]: union(newState[definitionId], [id])
            }), state);
        }
        case 'METADATA__DEFINITION_PROPERTIES_DELETE_SUCCESS':
            definitionId = action.meta.actionContext.definitionId;
            return {
                ...state,
                [definitionId]: state[definitionId].filter((prop) => prop !== action.meta.actionContext.id)
            };
        case 'METADATA__DEFINITION_PROPERTY_SAVE_SUCCESS':
            return {
                ...state,
                [action.payload.definitionId]: union(state[action.payload.definitionId] || [], [
                    action.payload.id
                ])
            };
        default:
            return state;
    }
}
function definitionStatus(state = {}, action) {
    switch (action.type) {
        case 'METADATA__FETCH_DEFINITION_PROPERTIES':
            return {
                ...state,
                [action.payload.definitionId]: {
                    ...(state[action.payload.definitionId] || {}),
                    pending: true,
                    failed: false
                }
            };
        case 'METADATA__FETCH_DEFINITION_PROPERTIES_FAIL':
            return {
                ...state,
                [action.meta.actionContext.definitionId]: {
                    ...state[action.meta.actionContext.definitionId],
                    pending: false,
                    failed: true
                }
            };
        case 'METADATA__FETCH_DEFINITION_PROPERTIES_SUCCESS': {
            if (action.meta) {
                return {
                    ...state,
                    [action.meta.actionContext.definitionId]: {
                        ...(state[action.meta.actionContext.definitionId] || []),
                        pending: false,
                        failed: false,
                        lastFetch: new Date()
                    }
                };
            }
            return state;
        }
        default:
            return state;
    }
}
export default combineReducers({ byPropertyId, byDefinitionId, definitionStatus });
