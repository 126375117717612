import { MICROSOFT_API_PATH } from './constants';
export const fetchMicrosoftUsers = (microsoftTenantId) => ({
    type: 'MICROSOFT_USERS_FETCH',
    meta: {
        http: {
            path: `${MICROSOFT_API_PATH}/${microsoftTenantId}/users`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
