import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { ContactDisplayName } from '../../../../crm';
import { useContact } from '../../../../crm/components/use-contact';
import styles from './Assignee.module.scss';
export const Assignee = ({ className, contactId }) => {
    const { t } = useI18n();
    const { contact, contactStatus: { isPending } } = useContact(contactId);
    return contactId ? (React.createElement("div", { className: `${styles.container} ${className || ''}` },
        React.createElement(ContactDisplayName, { contact: contact, isPending: isPending }))) : (React.createElement("div", { className: `${styles.notAssigned} ${className || ''}` }, t('NOT_ASSIGNED')));
};
