import { fetchDefinition, getDefinition, saveDefinition } from '@hypercharge/hyper-react-base/lib/metadata';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { goBack } from '@hypercharge/hyper-react-base/lib/router';
import { pick } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { getMetadataState } from '../../../common/reducers/rootSelector';
import DefinitionForm from './DefinitionForm';
export const form = 'definition';
const onSubmit = (formValues, dispatch, props) => {
    return dispatch(saveDefinition(props.definitionId, {
        ...props.definition,
        ...formValues
    }))
        .then(() => {
        dispatch(goBack());
    })
        .catch(() => {
        dispatch(error({
            title: props.t('ERROR'),
            message: props.t('ADD_DEFINITION_FAILED')
        }));
    });
};
class DefinitionFormContainer extends Component {
    componentDidMount() {
        const { definitionId, fetchDefinition } = this.props;
        if (definitionId) {
            fetchDefinition(definitionId);
        }
    }
    render() {
        // @ts-expect-error
        return React.createElement(DefinitionForm, { ...this.props });
    }
}
function mapStateToProps(state, { match }) {
    const metadataState = getMetadataState(state);
    const definitionId = match.params.definitionId || '';
    let definition;
    let initialValues;
    if (definitionId) {
        definition = getDefinition(metadataState, definitionId);
        initialValues = pick(definition, ['labels']);
    }
    return {
        definition,
        definitionId,
        // @ts-expect-error
        initialValues,
        formValues: getFormValues(form)(state)
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(connect(mapStateToProps, { fetchDefinition }), reduxForm({
    form,
    enableReinitialize: true,
    onSubmit
}))(DefinitionFormContainer);
