import { REQUIRED_PORTAL_PERMISSION } from '../common/utils/constants';
const basePath = '/v2-auth';
export const requestAuthCodeByEmail = (tenantId, email) => ({
    type: 'AUTH__REQUEST_AUTH_CODE_BY_EMAIL',
    payload: {
        tenantId,
        email,
        permissionId: REQUIRED_PORTAL_PERMISSION
    },
    meta: {
        http: {
            path: `${basePath}/email/request`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: false
    }
});
export const requestOAuth2Code = (clientId, redirectUri, responseType, scope) => ({
    type: 'AUTH__REQUEST_OAUTH2_CODE',
    payload: {
        clientId,
        redirectUri,
        scope
    },
    meta: {
        http: {
            path: `${basePath}/oauth2/${responseType}`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const retrieveFactorAccounts = (tenantId, email, code) => ({
    type: 'AUTH__RETRIEVE_FACTOR_ACCOUNTS',
    payload: {
        tenantId,
        email,
        code,
        permissionId: REQUIRED_PORTAL_PERMISSION
    },
    meta: {
        http: {
            path: `${basePath}/email/accounts`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: false
    }
});
export const logIn = (tenantId, entityId, email, code) => ({
    type: 'AUTH__LOG_IN',
    payload: {
        tenantId,
        entityId,
        email,
        code
    },
    meta: {
        http: {
            path: `${basePath}/email/login`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: false
    }
});
export const signOut = (tenantId, entityId, refreshTokenExpiry) => ({
    type: 'AUTH__LOG_OUT',
    payload: {
        tenantId,
        entityId,
        refreshTokenExpiry
    },
    meta: {
        http: {
            path: `${basePath}/logout`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: false
    }
});
export const requestAuthToken = (tenantId, entityId, refreshToken) => ({
    type: 'AUTH__REFRESH_AUTH_TOKEN',
    payload: {
        tenantId,
        entityId,
        refreshToken
    },
    meta: {
        http: {
            path: `${basePath}/refresh`,
            method: 'POST'
        },
        auth: false
    }
});
export const retrieveAccounts = () => ({
    type: 'AUTH__RETRIEVE_ACCOUNTS',
    payload: {
        permissionId: REQUIRED_PORTAL_PERMISSION
    },
    meta: {
        http: {
            path: `${basePath}/accounts`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const requestSwitchAccount = (entityId, refreshToken) => ({
    type: 'AUTH__SWITCH_ACCOUNT',
    payload: {
        entityId,
        refreshToken
    },
    meta: {
        http: {
            path: `${basePath}/switch-account`,
            method: 'POST'
        },
        auth: true
    }
});
export const refreshAuthToken = () => ({
    type: 'AUTH__FORCE_REFRESH_AUTH_TOKEN'
});
