import React, { useCallback, useEffect, useMemo } from 'react';
import { DataActionsContainer } from '../../../../../../common/components/DataActionsContainer';
import { MetricsDisplayContainer } from '../../../../../../views';
import { MAX_KANBAN_ITEMS } from '../../../../../../views/components/ViewProvider';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../../../workflows';
import { KanbanBoard } from '../KanbanBoard';
const KanbanBoardWrapper = ({ actions, baseUrl, contentHeight, definitionId, showItemsLimitError, fields, idField, isPending, lists, noFilters, noSearch, noViewActions, onChangePosition, showStartProcessSelector, showViewsSelector, viewId, withCmsImport, getCustomColumnCountFilters, getCustomRouteHandleCardClick, isDropDisabled }) => {
    const onDragEnd = useCallback(async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const isOneColumn = source.droppableId === destination.droppableId;
        if (isOneColumn && source.index === destination.index) {
            return;
        }
        const sourceList = lists.find((list) => list.propertyId === source.droppableId);
        if (!sourceList) {
            return;
        }
        const item = sourceList.cards[source.index];
        await onChangePosition(item.entityId, destination.droppableId);
    }, [lists, onChangePosition]);
    useEffect(() => {
        const stopPropagation = (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        };
        // Virtuoso's resize observer can this error,
        // which is caught by DnD and aborts dragging.
        window.addEventListener('error', stopPropagation);
        return () => window.removeEventListener('error', stopPropagation);
    }, []);
    const items = useMemo(() => lists.reduce((res, item) => {
        res.push(...item.cards);
        return res;
    }, []), [lists]);
    return (React.createElement(React.Fragment, null,
        (!noFilters || !noSearch || actions.length > 0) && (React.createElement(DataActionsContainer, { showItemsLimitError: showItemsLimitError, maxItemsCount: MAX_KANBAN_ITEMS, actions: actions, isPending: isPending, items: items, baseUrl: baseUrl, idField: idField, noFilters: noFilters, noSearch: noSearch, noViewActions: noViewActions, showViewsSelector: showViewsSelector, withCmsImport: withCmsImport, showStartProcessSelector: showStartProcessSelector })),
        React.createElement("div", { style: { height: contentHeight }, className: "overflow-y-auto mt-2 custom-scroll-styling" },
            React.createElement(MetricsDisplayContainer, null),
            React.createElement(KanbanBoard, { isDropDisabled: isDropDisabled ||
                    definitionId === ALL_PROCESSES_DEFINITION_ID ||
                    definitionId === ALL_TASKS_DEFINITION_ID, isPending: isPending, onDragEnd: onDragEnd, baseUrl: baseUrl, contentHeight: contentHeight, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, definitionId: definitionId, fields: fields, lists: lists, viewId: viewId }))));
};
export default KanbanBoardWrapper;
