import { t } from '@hypercharge/hyper-react-base/lib/i18n';
import { createContext } from 'react';
import { AvailableSystemGroups } from '../../constants';
export const validateGroups = (requiredNames, formValues) => requiredNames.reduce((acc, name) => {
    const formField = formValues[name];
    return Array.isArray(formField) && formField.length === 0
        ? { ...acc, [name]: t('VALIDATIONS__REQUIRED') }
        : acc;
}, {});
export const ParentOwnerTranslationKeyContext = createContext('OWNER');
export const getRowPermissionGroupLabel = (rowPermissionGroup, writeAsExecute) => {
    let label = rowPermissionGroup;
    switch (rowPermissionGroup) {
        case 'view':
            label = AvailableSystemGroups.viewGroups;
            break;
        case 'write':
            if (writeAsExecute) {
                label = AvailableSystemGroups.executeGroups;
            }
            else {
                label = AvailableSystemGroups.writeGroups;
            }
            break;
    }
    return label;
};
