import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import { getScrollableAreaOrBody } from '../../../../../../common/utils/common';
import { useDisplayTenant } from '../../../../../../tenant/useDisplayTenant';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './PhoneNumberEditor.module.scss';
const PhoneNumberEditor = ({ value, disabled, onKeyDown, autoFocus = false, editing = true, className, editingInline, onChange, errorMessage }) => {
    const { t } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const defaultCountry = useMemo(() => displayTenant?.country?.toUpperCase(), [displayTenant?.country]);
    const [temporaryValue, setTemporaryValue] = useState(value);
    const [initialValue] = useState(value);
    const [invalid, setInvalid] = useState(false);
    const inputRef = useRef(null);
    const onInputChange = useCallback((value) => {
        const valid = 
        // quick add item is only looking at requireds so we disable the validation if its not
        // editingInline
        !editingInline ||
            // if there is no value in the input lets consider it valid so we can clear a phone number
            !value
            ? true
            : isValidPhoneNumber(value);
        // if value is undefined when clearing it will trigger a network error due to
        // required value when saving
        onChange && onChange(valid ? value || null : initialValue);
        setTemporaryValue(value || null);
        setInvalid(!valid);
    }, [editingInline, onChange, initialValue]);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    const isInvalid = invalid && editingInline;
    return (React.createElement("div", null,
        React.createElement(FieldWrapper, { className: `${styles.editor} ${className || ''}`, disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(PhoneInput, { ref: inputRef, flags: flags, defaultCountry: defaultCountry, autoFocus: autoFocus, value: temporaryValue || '', onChange: onInputChange, disabled: disabled, 
                // @ts-expect-error
                onKeyDown: onKeyDown, countrySelectComponent: (props) => {
                    // @ts-expect-error
                    const CountryIcon = props.iconComponent;
                    return (React.createElement("div", { className: "country-selector" },
                        React.createElement(CountryIcon, { country: props.value }),
                        React.createElement(Select, { getPopupContainer: getScrollableAreaOrBody, popupMatchSelectWidth: false, disabled: disabled, value: props.value, onFocus: props.onFocus, onBlur: props.onBlur, onChange: props.onChange, popupClassName: "antd-dropdown-popup-styled" }, props.options?.map((option) => option.value ? (React.createElement(Select.Option, { value: option.value, key: option.value }, option.label)) : null))));
                } })),
        React.createElement(FieldError, { error: isInvalid ? t('VALIDATIONS__INVALID_PHONE_NUMBER') : errorMessage })));
};
export default PhoneNumberEditor;
