import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { getDisplayComponentForType, getEditorComponentForType } from '../../../../cms';
import ValueHistoryDrawer from '../../../../cms/data/components/item-property/ValueHistoryDrawer';
import { DisplaySizesT } from '../../../../cms/data/components/item-property/display/types';
import { useUpdateProperty } from '../../../../cms/hooks/useUpdateProperty';
import { RowHeightT } from '../../../../views/types';
import { useFileMovingContext } from '../../../context/FileMovingContext';
import { InlineEditor } from '../../InlineEditor';
import { getPropertyStyles } from '../../InlineEditor/utils';
import { useTableData } from '../TableProvider';
import styles from './TableInlineEditor.module.scss';
const ROW_HEIGHT_TYPE_TO_DISPLAY_SIZE = {
    [RowHeightT.SMALL]: DisplaySizesT.SMALL,
    [RowHeightT.MEDIUM]: DisplaySizesT.MEDIUM,
    [RowHeightT.TALL]: DisplaySizesT.LARGE
};
const SELECT_DROPDOWN_WIDTH_EASY_TO_READ = 220;
export default function TableInlineEditor({ meta, value, rowHeightType, cell, type, canEdit, invalid, onChangeProperty }) {
    const [expanded, setExpanded] = useState(false);
    const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
    const { onFilesMoved } = useFileMovingContext();
    const { updateItem } = useTableData();
    const style = useMemo(() => getPropertyStyles(meta, { ...cell.row.original, [cell.column.id]: value }), [meta, cell.row.original, cell.column.id, value]);
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId: cell.row.original.definitionId
    });
    const updatePropertyData = useCallback(async (value) => {
        if (!cell.row.original.definitionId) {
            return;
        }
        if (onChangeProperty) {
            await onChangeProperty(cell.row.original.entityId, cell.column.id, value);
        }
        else {
            await updateProperty({
                entityId: cell.row.original.entityId,
                propertyId: cell.column.id,
                value
            });
        }
        updateItem({ entityId: cell.row.original.entityId, property: cell.column.id, value });
    }, [
        cell.row.original.definitionId,
        cell.row.original.entityId,
        cell.column.id,
        onChangeProperty,
        updateItem,
        updateProperty
    ]);
    const isCellWidthLarge = useMemo(() => cell.cell.column.getSize() > SELECT_DROPDOWN_WIDTH_EASY_TO_READ, [cell.cell.column]);
    const additionalFileProps = useMemo(() => ({
        propertyId: cell.column.id,
        definitionId: cell.row.original.definitionId,
        entityId: cell.row.original.entityId,
        onFilesMoved
    }), [cell.column.id, cell.row.original.definitionId, cell.row.original.entityId, onFilesMoved]);
    const extraDisplayComponentProps = useMemo(() => {
        return {
            ...(type === 'file' ? additionalFileProps : { type, canCopy: true }),
            asField: false,
            displaySize: ROW_HEIGHT_TYPE_TO_DISPLAY_SIZE[rowHeightType]
        };
    }, [type, additionalFileProps, rowHeightType]);
    const extraEditorComponentProps = useMemo(() => ({
        popupMatchSelectWidth: isCellWidthLarge
    }), [isCellWidthLarge]);
    const containerClassName = cn(styles.tableInlineEditor, 'w-100 h-100 d-flex align-items-center', {
        'inline-editor-mode': expanded,
        'inline-display-mode': !expanded,
        'with-color': style?.color,
        'with-background-color': style?.backgroundColor
    });
    return (React.createElement("div", { className: containerClassName, style: style },
        React.createElement(InlineEditor, { className: "w-100 position-static", displayComponent: getDisplayComponentForType(type), editorComponent: getEditorComponentForType(type), actionsPosition: "bottom", value: value, meta: meta, style: style, invalid: invalid, canEdit: canEdit && type !== 'file', entityId: cell.row.original.entityId, definitionId: cell.row.original.definitionId, onSave: updatePropertyData, onEditingChanged: setExpanded, onHistoryOpened: () => setHistoryDrawerOpen(true), isWithinFrame: false, extraDisplayComponentProps: extraDisplayComponentProps, extraEditorComponentProps: extraEditorComponentProps }),
        historyDrawerOpen && (React.createElement(ValueHistoryDrawer, { open: true, definitionId: cell.row.original.definitionId || '', entityId: cell.row.original.entityId, propertyId: cell.column.id, onSave: (propId, value) => updatePropertyData(value), onClose: () => setHistoryDrawerOpen(false) }))));
}
