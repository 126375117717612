import { HEXON_API_PATH } from './constants';
export const enableIntegration = (config) => {
    return toggleIntegration('INTEGRATIONS__HEXON_ENABLE', true, config);
};
export const disableIntegration = (config) => {
    return toggleIntegration('INTEGRATIONS__HEXON_DISABLE', false, config);
};
const toggleIntegration = (type, enable, config) => {
    return {
        type,
        payload: {
            action: enable ? 'enable' : 'disable',
            configuration: config
        },
        meta: {
            http: {
                path: `${HEXON_API_PATH}`,
                method: 'PUT',
                allowPromiseReject: true,
                actionContext: {}
            }
        }
    };
};
export const fetchHexonSchemaByModuleId = (moduleId) => ({
    type: 'HEXON__GET_SCHEMA',
    meta: {
        http: {
            path: `${HEXON_API_PATH}/${moduleId}/schema/`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
export const fetchPreviewByModuleId = (definitionId, moduleId, mapping) => ({
    type: 'HEXON__GET_CONTACT_PREVIEW',
    payload: { definitionId, mapping },
    meta: {
        http: {
            path: `${HEXON_API_PATH}/${moduleId}/preview/`,
            method: 'POST',
            allowPromiseReject: true,
            actionContext: {}
        }
    }
});
