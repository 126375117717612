import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { BasicInput, DescriptionText, FormFooter, required, Section, slug, SubTitle, Title } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from 'config';
import React from 'react';
import { Field } from 'redux-form';
import { ADMIN_PATH } from '../../../utils/constants';
import DependantOptionsField from './DependantOptionsField';
import OptionGroupField from './OptionGroupField';
const OptionForm = ({ groupId, formValues, isNew, isLoading, submitting, handleSubmit }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [
                { text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` },
                { text: t('SETTINGS_OPTIONS_NAV_LINK'), to: `${ADMIN_PATH}/option-groups` },
                { text: groupId, to: `${ADMIN_PATH}/option-groups/${groupId}/options` }
            ], currentBreadcrumbText: isNew ? t('ADD_VALUE_TITLE') : t('EDIT_VALUE_TITLE') }),
        React.createElement(Section, null,
            React.createElement(Title, null, isNew ? t('ADD_VALUE_TITLE') : t('EDIT_VALUE_TITLE')),
            React.createElement(SubTitle, null, isNew ? t('ADD_VALUE_SUBTITLE') : t('EDIT_VALUE_SUBTITLE')),
            React.createElement("form", { onSubmit: handleSubmit, noValidate: true },
                React.createElement(SectionCard, { title: t('SETTINGS__OPTION_DESCRIPTION'), withoutPadding: true },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(DescriptionText, null, t('ADD_VALUE_DESCRIPTION_TEXT')),
                        AVAILABLE_LANGUAGES.map((language, index) => (React.createElement("div", { key: language },
                            React.createElement(Field, { name: language, autoFocus: index === 0, label: t(language), component: BasicInput, type: "text", disabled: submitting || isLoading, validate: language === DEFAULT_LANGUAGE ? required : [] })))))),
                React.createElement(SectionCard, { title: t('SETTINGS__OPTION_VALUE'), withoutPadding: true },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(DescriptionText, null, t('ADD_VALUE_FORM_VALUE_TEXT')),
                        React.createElement("div", null,
                            React.createElement(Field, { name: "value", label: t('SETTINGS__OPTION_VALUE'), component: BasicInput, type: "text", validate: required, disabled: !isNew || submitting || isLoading, normalize: slug })))),
                React.createElement(SectionCard, { title: t('SETTINGS__OPTIONS_DEPENDENCY'), withoutPadding: true },
                    React.createElement("div", { className: "cp-c-padding-2" },
                        React.createElement(DescriptionText, null, t('ADD_VALUE_FORM_DEPENDANT_TEXT')),
                        React.createElement("div", null,
                            React.createElement(OptionGroupField, { name: "dependantGroupId", label: t('ADD_VALUE_DEPENDANT_OPTION'), disabled: submitting || isLoading })),
                        React.createElement("div", null,
                            React.createElement(DependantOptionsField, { groupId: formValues && formValues.dependantGroupId })))),
                React.createElement(FormFooter, { showCancel: true, submitting: submitting, disabled: isLoading })))));
};
export default OptionForm;
