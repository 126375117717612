import { APIKEYS_API_PATH } from './constants';
export const createApiKeyAction = (contactId) => ({
    type: 'INTEGRATION_APIKEY_POST',
    payload: { contactId },
    meta: {
        http: {
            path: APIKEYS_API_PATH,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const deleteApiKeyAction = (id) => ({
    type: 'INTEGRATION_APIKEY_DELETE',
    meta: {
        http: {
            path: `${APIKEYS_API_PATH}/${id}`,
            method: 'DELETE',
            allowPromiseReject: true
        }
    }
});
export const getApiKeysAction = () => ({
    type: 'INTEGRATION_APIKEY_GET',
    meta: {
        http: {
            path: APIKEYS_API_PATH,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const updateApiKeyAction = (id, data) => ({
    type: 'INTEGRATION_APIKEY_PUT',
    payload: data,
    meta: {
        http: {
            path: `${APIKEYS_API_PATH}/${id}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
