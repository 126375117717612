import React, { useCallback, useMemo } from 'react';
import { ENTITY_ID_PROPERTY_ID, UPDATED_AT_ID_PROPERTY_ID } from '../../../../../cms';
import GroupsField, { ADMINS_PERMISSION_GROUP } from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
export const isVisible = (itemId) => itemId !== ENTITY_ID_PROPERTY_ID;
const PERMISSIONS = [AvailableSystemGroups.readGroups, AvailableSystemGroups.writeGroups];
const PermissionsTab = ({ parentViewGroups = [], value, disabled, onChange }) => {
    const onChangeReadGroups = useCallback((val) => {
        onChange({ ...value, readGroups: val });
    }, [onChange, value]);
    const onChangeWriteGroups = useCallback((val) => {
        onChange({ ...value, writeGroups: val });
    }, [onChange, value]);
    // @ts-expect-error
    const readGroups = useMemo(() => {
        return {
            input: {
                value: value.readGroups,
                onChange: onChangeReadGroups
            },
            meta: {
                error: undefined,
                submitFailed: false
            }
        };
    }, [onChangeReadGroups, value.readGroups]);
    // @ts-expect-error
    const writeGroups = useMemo(() => {
        return {
            input: {
                value: value.writeGroups,
                onChange: onChangeWriteGroups
            },
            meta: {
                error: undefined,
                submitFailed: false
            }
        };
    }, [onChangeWriteGroups, value.writeGroups]);
    const isPermissionToggleDisabled = useCallback((groupId, permission) => {
        const isComputedProperty = !!value.computedValue;
        const propertyId = value.propertyId;
        return ((isComputedProperty && permission === 'writeGroups') ||
            (propertyId === UPDATED_AT_ID_PROPERTY_ID && permission === 'writeGroups') ||
            groupId === ADMINS_PERMISSION_GROUP);
    }, [value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(GroupsField, { names: PERMISSIONS, readGroups: readGroups, writeGroups: writeGroups, parentViewGroups: parentViewGroups, disabled: disabled, isPermissionToggleDisabled: isPermissionToggleDisabled })));
};
export default PermissionsTab;
