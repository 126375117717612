import styled from 'styled-components';
export const sizes = {
    sm: 568,
    md: 768,
    lg: 1024,
    wrapper: 1120,
    xl: 1280
};
// Example usage of the media template
// import { media } from '../../common/styles';
// const Container = styled.div`
//   color: red;
//   ${media.sm} { padding: 0 20px; }
// `
export const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = `@media screen and (min-width: ${sizes[label] / 16}em)`;
    return acc;
}, {});
export const Wrapper = styled.div `
  box-sizing: border-box;
  max-width: ${sizes.wrapper}px;
  margin: 0 auto;
`;
// padding: 0 1rem;
function rgbaString(rgba, alpha) {
    if (alpha === undefined) {
        alpha = rgba[3] !== undefined ? rgba[3] : 1;
    }
    return 'rgba(' + rgba[0] + ', ' + rgba[1] + ', ' + rgba[2] + ', ' + alpha + ')';
}
function hexToRGB(hex) {
    hex = hex.replace('#', '0x');
    let r = hex >> 16;
    let g = (hex >> 8) & 0xff;
    let b = hex & 0xff;
    return [r, g, b];
}
export const transparentize = (color, opacity) => {
    const alpha = opacity == null ? 0.5 : 1 - opacity;
    return rgbaString(hexToRGB(color), alpha);
};
export default {
    Wrapper
};
export const modalStyleMobile = {
    content: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        padding: '0',
        boxSizing: 'border-box',
        overflow: 'auto',
        outline: 'none',
        borderRadius: '0',
        background: 'rgb(255, 255, 255)',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none'
    },
    overlay: {
        zIndex: '2200000000',
        backgroundColor: 'rgba(0, 0, 0, 0)'
    }
};
export const modalWrapperStyle = {
    overlay: {
        position: 'fixed',
        background: 'rgba(0,0,0,0.7)',
        margin: 'auto',
        zIndex: '500'
    },
    content: {
        border: 'none',
        padding: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-51%,-52%)',
        height: 'fit-content',
        overflow: 'hidden',
        background: 'rgb(255, 255, 255)',
        zIndex: '0',
        boxShadow: '0px 1px 5px rgba(0,0,0,0.3)',
        borderRadius: '3px'
    }
};
