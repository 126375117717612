import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success, warning } from '@hypercharge/hyper-react-base/lib/notifications';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import { find, get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../auth';
import { ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../../../../cms';
import { PropertiesProvider } from '../../../../cms/common/context/PropertiesProvider';
import { SystemSectionsVisibilityProvider } from '../../../../cms/common/context/SystemSectionsVisibilityProvider';
import { getPropertyById } from '../../../../cms/common/utils/utils';
import useDisplayData from '../../../../cms/hooks/useDisplayData';
import useDisplayProcessMetaList from '../../../../cms/hooks/useDisplayProcessMetaList';
import { useUpdateProperty } from '../../../../cms/hooks/useUpdateProperty';
import SessionSubscription, { useSessionSubscriptionCallback } from '../../../../common/session/components/SessionSubscription';
import { APP_ADMIN_PERMISSION, BROWSE_PATH } from '../../../../common/utils/constants';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { sleep } from '../../../../utils/time';
import { PROCESS_ACTIVITY_PROPERTY_ID, PROCESS_DEADLINE_PROPERTY_ID, PROCESS_LINK_PROPERTY_ID } from '../../../common/utils/constants';
import { assignTask, completeDisplayTask } from '../../../tasks/actions';
import { useDisplayTasks } from '../../../tasks/useDisplayTasks';
import { cancelProcess, deleteProcesses } from '../../actions';
import { useDisplayProcess } from '../../useDisplayProcess';
import Process from './Process';
import { RequiredFieldsProvider } from './RequiredFieldsProvider';
const blacklistedPropertyIds = [
    ENTITY_ID_PROPERTY_ID,
    TITLE_PROPERTY_ID,
    PROCESS_DEADLINE_PROPERTY_ID,
    PROCESS_LINK_PROPERTY_ID,
    PROCESS_ACTIVITY_PROPERTY_ID
];
const hiddenSections = ['system', 'process'];
const ProcessContainer = ({ processMetaId, processRunId, baseUrl, location, viewId }) => {
    const { t, language } = useI18n();
    const { contactId = '' } = useMyContact();
    const dispatch = useDispatch();
    const assignTaskDispatch = useDispatch();
    const completeTaskDispatch = useDispatch();
    const cancelProcessDispatch = useDispatch();
    const deleteProcessDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const timerFetchTasks = useRef();
    const { tasks, refetch: refetchTasks, isLoading: isTasksLoading } = useDisplayTasks(processMetaId, processRunId, language);
    const { process: processDetails, refetch: refetchProcess, isLoading: isProcessLoading } = useDisplayProcess(processMetaId, processRunId, language);
    const refetchTasksWithDelay = useCallback(() => {
        /**
         * after completing a task or starting a process,
         * new tasks do not always immediately appear in elasticsearch,
         * so we request them with a delay
         */
        if (timerFetchTasks.current) {
            clearTimeout(timerFetchTasks.current);
            timerFetchTasks.current = undefined;
        }
        timerFetchTasks.current = setTimeout(() => {
            void refetchTasks();
        }, 1500);
    }, [refetchTasks]);
    useEffect(() => {
        /**
         * after creating a process, a detailed process window opens quickly and we download tasks
         * elasticsearch is not always able to get data immediately after write,
         * so if the tasks did not come, we ask again
         */
        if (!tasks.length) {
            refetchTasksWithDelay();
        }
        return () => {
            if (timerFetchTasks.current) {
                clearTimeout(timerFetchTasks.current);
                timerFetchTasks.current = undefined;
            }
        };
    }, [tasks, refetchTasksWithDelay]);
    const isWorkflowAdmin = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION));
    const canAlterProcess = (processDetails && !['CANCELLED', 'SUCCEEDED', 'FAILED'].includes(processDetails.status)) ||
        false;
    const canEditData = canAlterProcess || isWorkflowAdmin;
    const { data: displayDataList } = useDisplayData({
        definitionId: processMetaId,
        entityId: processRunId
    });
    const titleProperty = useMemo(() => getPropertyById(displayDataList, TITLE_PROPERTY_ID), [displayDataList]);
    const processActivity = useMemo(() => {
        const processActivityProperty = getPropertyById(displayDataList, PROCESS_ACTIVITY_PROPERTY_ID);
        if (processActivityProperty?.value) {
            return processActivityProperty.value;
        }
    }, [displayDataList]);
    const { data: displayProcessMetaList, isPending: isDisplayProcessMetaListPending } = useDisplayProcessMetaList();
    useSessionSubscriptionCallback({
        messageKey: 'UPDATED_PROCESS',
        callback: refetchProcess
    });
    useSessionSubscriptionCallback({
        messageKey: 'UPDATED_PROCESS_TASKS',
        callback: refetchTasksWithDelay
    });
    const onCancelProcess = useCallback(async () => {
        try {
            await cancelProcessDispatch(cancelProcess(processMetaId, processRunId));
            await Promise.all([refetchProcess(), refetchTasks()]);
            notificationDispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('WORKFLOWS__CANCEL_PROCESS_SUCCESS')
            }));
        }
        catch (e) {
            console.error(e);
            notificationDispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('WORKFLOWS__CANCEL_PROCESS_FAIL')
            }));
        }
    }, [
        cancelProcessDispatch,
        notificationDispatch,
        processMetaId,
        processRunId,
        refetchProcess,
        refetchTasks,
        t
    ]);
    const onDeleteProcess = useCallback(async () => {
        try {
            await deleteProcessDispatch(deleteProcesses(processMetaId, [processRunId]));
            // ? We wait two seconds to allow ElasticSearch to reindex,
            // ? so that the process will be removed before we redirect to the browse page
            await sleep(2000);
            dispatch(replace(`${baseUrl}${BROWSE_PATH}`));
            notificationDispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('WORKFLOWS__PROCESS_DELETE_SUCCESS')
            }));
        }
        catch (e) {
            console.error(e);
            notificationDispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('WORKFLOWS__PROCESS_DELETE_FAIL')
            }));
        }
    }, [
        baseUrl,
        deleteProcessDispatch,
        dispatch,
        notificationDispatch,
        processMetaId,
        processRunId,
        t
    ]);
    const _assignTask = useCallback(async (taskMetaId, taskRunId, contactId) => {
        try {
            await assignTaskDispatch(assignTask(processMetaId, processRunId, taskMetaId, taskRunId, contactId));
            await refetchTasks();
            notificationDispatch(success({
                title: t('SUCCESS'),
                message: t(contactId ? 'TASK_ASSIGNED_TO_SUCCESS' : 'TASK_ASSIGNED_TO_CLEAR_SUCCESS')
            }));
        }
        catch (e) {
            console.error(e);
            notificationDispatch(error({
                title: t('ERROR'),
                message: t('TASK_ASSIGNED_TO_FAILED')
            }));
        }
    }, [assignTaskDispatch, notificationDispatch, processMetaId, processRunId, refetchTasks, t]);
    const completeTask = useCallback(async (taskMetaId, taskRunId, additionalData) => {
        await completeTaskDispatch(completeDisplayTask(processMetaId, processRunId, taskMetaId, taskRunId, contactId, additionalData));
        await refetchTasks();
    }, [refetchTasks, completeTaskDispatch, processMetaId, processRunId, contactId]);
    const dispatchWarning = useCallback((message) => notificationDispatch(warning({ title: t('WARNING'), message })), [notificationDispatch, t]);
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId: processMetaId
    });
    const updateProcessTitle = useCallback((propertyId, value) => updateProperty({
        entityId: processRunId,
        propertyId,
        value
    }), [processRunId, updateProperty]);
    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location.hash]);
    const processTitle = useMemo(() => {
        return (get(find(displayProcessMetaList, { processMetaId }), TITLE_PROPERTY_ID) ||
            (!processMetaId || isDisplayProcessMetaListPending ? '...' : t('NO_TITLE')));
    }, [displayProcessMetaList, isDisplayProcessMetaListPending, processMetaId, t]);
    if (!isProcessLoading && !processDetails) {
        return React.createElement(NotFound, null);
    }
    return (React.createElement(PropertiesProvider, null,
        React.createElement(RequiredFieldsProvider, { definitionId: processMetaId, processRunId: processRunId, tasks: tasks },
            React.createElement(SystemSectionsVisibilityProvider, { id: processMetaId, hiddenSections: hiddenSections },
                React.createElement(SessionSubscription, { subscriptionKey: `tasks--processRunId--${processRunId}` }),
                React.createElement(Process, { processActivity: processActivity, titleProperty: titleProperty, canEditData: canEditData, isProcessPending: isProcessLoading || isTasksLoading, processDetails: processDetails, processRunId: processRunId, definitionId: processMetaId, viewId: viewId, processMetaId: processMetaId, baseUrl: baseUrl, contactId: contactId, tasks: tasks, updateProcessTitle: updateProcessTitle, dispatchWarning: dispatchWarning, completeTask: completeTask, assignTask: _assignTask, onChangeTaskPriority: refetchTasks, onChangeTaskDeadline: refetchTasks, onCancelProcess: onCancelProcess, onDeleteProcess: onDeleteProcess, blacklistedPropertyIds: blacklistedPropertyIds, processTitle: processTitle })))));
};
export default ProcessContainer;
