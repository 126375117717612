import { remove as removeDiacritics } from 'diacritics';
import { camelCase, cloneDeep, find, isFinite, omit, set, toNumber } from 'lodash';
import { FaAlignRight, FaAt, FaCalendar, FaCheckSquare, FaCube, FaDatabase, FaFile, FaFilter, FaLink, FaListUl, FaMinus, FaPhone } from 'react-icons/fa';
import { MdOutlineSearch, MdTimer10 } from 'react-icons/md';
import { PropertyTypes } from '../../../../common/types';
export const DEFAULT_META_DEFINITION_TAB = {
    id: 'default',
    system: false,
    titles: {
        en: 'Default',
        pl: 'Domyślny',
        de: 'Standard',
        fr: 'Défaut',
        it: 'Predefinita',
        nl: 'Standaard'
    }
};
export const isFormValueMetaDefinitionPropertyT = (data) => {
    return 'propertyId' in data;
};
export const getFormValueFromMetaDefinition = (metaDefinition) => {
    let tabs;
    const sections = metaDefinition.sections;
    if (!metaDefinition.tabs?.length) {
        tabs = [
            {
                ...DEFAULT_META_DEFINITION_TAB,
                isNew: false,
                sections: []
            }
        ];
    }
    else {
        tabs = metaDefinition.tabs.map((tab) => {
            return { ...tab, sections: [] };
        });
    }
    const result = tabs.reduce((acc, tab) => {
        const tabSections = sections.filter((section) => {
            if (tab.id == 'default') {
                return !section.tab || section.tab === tab.id;
            }
            return section.tab === tab.id;
        });
        const newTab = {
            ...tab,
            sections: tabSections
        };
        acc.push(newTab);
        return acc;
    }, []);
    return {
        definitionId: metaDefinition.definitionId,
        tabs: result
    };
};
const getPropertyFromFormValue = (formValue, propertyId) => {
    for (const tab of formValue.tabs) {
        for (const section of tab.sections) {
            for (const property of section.properties) {
                if (property.propertyId === propertyId) {
                    return property;
                }
            }
        }
    }
    return undefined;
};
const getSectionFromFormValue = (formValue, sectionId) => {
    for (const tab of formValue.tabs) {
        for (const section of tab.sections) {
            if (section.id === sectionId) {
                return section;
            }
        }
    }
    return undefined;
};
export const addIsNewToFormValue = (initialFormValue, newFormValue) => {
    const result = cloneDeep(newFormValue);
    for (const tab of result.tabs) {
        const initialTab = find(initialFormValue.tabs, { id: tab.id });
        if (!initialTab) {
            tab.isNew = true;
        }
        for (const section of tab.sections) {
            const initialSection = getSectionFromFormValue(initialFormValue, section.id);
            if (!initialSection) {
                section.isNew = true;
            }
            for (const property of section.properties) {
                const initialProperty = getPropertyFromFormValue(initialFormValue, property.propertyId);
                if (!initialProperty) {
                    property.isNew = true;
                }
            }
        }
    }
    return result;
};
export const getMetaDefinitionFromFormValue = (formValue) => {
    const metaDefinition = {
        tabs: [],
        sections: [],
        definitionId: formValue.definitionId
    };
    formValue.tabs.reduce((acc, tab) => {
        acc.tabs = acc.tabs || [];
        acc.tabs.push(omit(tab, ['sections']));
        const sections = tab.sections.map((item) => ({
            ...item,
            tab: tab.id
        }));
        acc.sections.push(...sections);
        return acc;
    }, metaDefinition);
    return metaDefinition;
};
const getPropertyFromDefinition = (definition, propertyId) => {
    for (const section of definition.sections) {
        for (const property of section.properties) {
            if (property.propertyId === propertyId) {
                return property;
            }
        }
    }
    return undefined;
};
const getSectionFromDefinition = (definition, sectionId) => {
    for (const section of definition.sections) {
        if (section.id === sectionId) {
            return section;
        }
    }
    return undefined;
};
export const mergeMetaDefinitions = (definition, sourceDefinition) => {
    const newDefinition = {
        ...cloneDeep(sourceDefinition),
        definitionId: definition.definitionId
    };
    for (const section of definition.sections) {
        for (const property of section.properties) {
            if (!getPropertyFromDefinition(newDefinition, property.propertyId)) {
                const newSection = getSectionFromDefinition(newDefinition, section.id);
                if (newSection) {
                    newSection.properties.push(property);
                }
                else {
                    newDefinition.sections.push({
                        ...section,
                        properties: [property],
                        tab: undefined
                    });
                }
            }
        }
    }
    return newDefinition;
};
const getPathById = (value, id, basePath = []) => {
    for (const [index, element] of value.entries()) {
        const storedId = 'id' in element ? element.id : element.propertyId;
        if (storedId === id) {
            return [...basePath, index.toString()];
        }
        let childrenType;
        let children = [];
        if ('sections' in element) {
            childrenType = 'sections';
            children = element.sections;
        }
        else if ('properties' in element) {
            childrenType = 'properties';
            children = element.properties;
        }
        if (childrenType && children.length) {
            const patch = getPathById(children, id, [...basePath, index.toString(), childrenType]);
            if (patch) {
                return patch;
            }
        }
    }
    return;
};
export const getErrorsFromFailedRequest = (value, responseErrorData) => {
    const error = {};
    for (const [propertyId, errorMsg] of Object.entries(responseErrorData.data)) {
        const path = getPathById(value.tabs, propertyId);
        if (path && path.length) {
            set(error, ['tabs', ...path], errorMsg);
        }
    }
    return error;
};
export const normalizeNumber = (value) => {
    if (typeof value !== 'number' && [null, undefined, ''].includes(value)) {
        return null;
    }
    const number = toNumber(value);
    return isFinite(number) ? number : null;
};
const stripNonAsciiChars = (str) => {
    let foldedStr = '';
    for (const character of str) {
        if (character.charCodeAt(0) < 128) {
            foldedStr = foldedStr.concat(character);
        }
    }
    return foldedStr;
};
export const generateReadableId = (formTree, type, newLabelValue, currentId) => {
    newLabelValue = newLabelValue || type;
    const propertyIds = formTree.reduce((acc, item) => {
        if (item.id !== currentId) {
            acc.push(item.id);
        }
        const sectionsIds = item.sections?.reduce((acc, item) => {
            if (item.id !== currentId) {
                acc.push(item.id);
            }
            const propertyIds = item.properties.reduce((acc, item) => {
                if (item.propertyId !== currentId) {
                    acc.push(item.propertyId);
                }
                return acc;
            }, []);
            acc.push(...propertyIds);
            return acc;
        }, []);
        acc.push(...(sectionsIds || []));
        return acc;
    }, []);
    const otherIds = new Set(propertyIds.map((id) => id.toLowerCase()));
    let newId = camelCase(stripNonAsciiChars(removeDiacritics(newLabelValue)));
    if (/^\d/.exec(newId)) {
        newId = `n${newId}`;
    }
    let i = 2;
    let uniqueNewId = newId;
    while (otherIds.has(uniqueNewId.toLowerCase())) {
        uniqueNewId = camelCase(`${newId}${i}`);
        i++;
    }
    return uniqueNewId;
};
export const propertyIdIconsSet = {
    text: FaMinus,
    richtext: FaAlignRight,
    multitext: FaListUl,
    date: FaCalendar,
    file: FaFile,
    number: MdTimer10,
    entity: FaDatabase,
    link: FaLink,
    checkbox: FaCheckSquare,
    phoneNumber: FaPhone,
    email: FaAt,
    json: FaCube
};
export const propertyIdFilterIconsSet = {
    [PropertyTypes.text]: MdOutlineSearch,
    [PropertyTypes.multitext]: MdOutlineSearch,
    [PropertyTypes.entity]: FaFilter,
    [PropertyTypes.phoneNumber]: MdOutlineSearch,
    [PropertyTypes.email]: MdOutlineSearch,
    [PropertyTypes.date]: FaFilter,
    [PropertyTypes.checkbox]: FaFilter,
    [PropertyTypes.file]: FaFilter,
    [PropertyTypes.number]: FaFilter,
    [PropertyTypes.link]: FaFilter,
    [PropertyTypes.richtext]: FaFilter,
    [PropertyTypes.select]: FaFilter // deprecated
};
const isPropertyType = (type) => !!PropertyTypes[type];
export const getPropertyTypeComponent = (type) => type && isPropertyType(type) ? propertyIdIconsSet[type] : undefined;
export const getFilterPropertyTypeComponent = (type) => type && isPropertyType(type) ? propertyIdFilterIconsSet[type] : undefined;
export const getHighlightedPropertiesFromDefinition = (itemMeta, newDefinition) => {
    const allHighlightedProperties = newDefinition.sections.flatMap((section) => section.properties.filter((property) => !!property.meta.highlighted));
    if (!allHighlightedProperties.length) {
        return [];
    }
    const oldHighlightedFromItemMeta = itemMeta?.custom?.highlightedProperties || [];
    const oldHighlighted = [];
    const newHighlighted = [];
    if (oldHighlightedFromItemMeta.length) {
        for (const oldProperty of oldHighlightedFromItemMeta) {
            const found = allHighlightedProperties.find((highlighted) => highlighted.propertyId === oldProperty.propertyId);
            if (found) {
                oldHighlighted.push(oldProperty);
            }
        }
    }
    for (const { propertyId } of allHighlightedProperties) {
        const found = oldHighlightedFromItemMeta.find((highlighted) => highlighted.propertyId === propertyId);
        if (!found) {
            newHighlighted.push({
                propertyId,
                active: true
            });
        }
    }
    return [...oldHighlighted, ...newHighlighted];
};
