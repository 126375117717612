import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEntityDisplayData } from '../../../../../../../cms/common/components/withEntityDisplayData';
import PropertyFieldEditor from '../../../../../../../cms/data/components/item-property/PropertyFieldEditor';
import { DrawerContent, DrawerFooter } from '../../../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../../../common/components/Drawer/DrawerFormFooter';
import { isOwnNodeDescription } from '../../../activity-diagram/nodes';
import { useNodes } from '../../../activity-diagram/useNode';
import ParameterList from '../Parameters/ParameterList';
import { createCredential, getCredential, updateCredential } from './actions';
import { CREDENTIALS_DEFINITION_ID } from './constants';
const QuickAddCredentialForm = ({ credential, entityId, disabled, onCancel, onFinish }) => {
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState(undefined);
    const { t } = useI18n();
    const { data: nodes } = useNodes();
    const { displayData } = useEntityDisplayData(CREDENTIALS_DEFINITION_ID);
    const createCredentialDispatch = useDispatch();
    const getCredentialDispatch = useDispatch();
    const updateCredentialDispatch = useDispatch();
    const titleProperty = useMemo(() => {
        if (!displayData) {
            return;
        }
        for (const section of displayData.data) {
            const title = section.values.find((property) => property.propertyId === 'title');
            if (title) {
                return title;
            }
        }
        return;
    }, [displayData]);
    const credentialDescription = useMemo(() => {
        const credentialDescription = nodes?.credentials[credential.name];
        if (!credentialDescription || isOwnNodeDescription(credentialDescription)) {
            return;
        }
        return credentialDescription;
    }, [nodes, credential]);
    const handleSubmit = useCallback(async () => {
        if (!value) {
            return;
        }
        setSubmitting(true);
        try {
            let result;
            if (value.entityId) {
                // @ts-expect-error
                result = await updateCredential(updateCredentialDispatch, value);
            }
            else {
                result = await createCredential(createCredentialDispatch, {
                    ...value,
                    type: credential.name
                });
            }
            onFinish(result.entityId);
        }
        catch (err) {
            console.log('error', err);
        }
        finally {
            setSubmitting(false);
        }
    }, [value, credential, onFinish, updateCredentialDispatch, createCredentialDispatch]);
    useEffect(() => {
        if (entityId) {
            getCredential(getCredentialDispatch, entityId)
                .then((credential) => {
                setValue(credential);
            })
                .catch((err) => {
                console.log('error', err);
            });
        }
    }, [entityId, getCredentialDispatch, setValue]);
    if (!credentialDescription) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, null, (entityId && value) || !entityId ? (React.createElement(React.Fragment, null,
            React.createElement(DrawerContentBlock, null, titleProperty && (React.createElement(PropertyFieldEditor, { property: titleProperty, value: value?.title, error: null, disabled: !!disabled || submitting, showIcon: false, onChange: (newValue) => {
                    setValue({ ...value, title: newValue });
                } }))),
            React.createElement(DrawerContentBlock, { title: t('COMMON__DATA') },
                React.createElement(ParameterList, { path: 'data', parameters: credentialDescription.properties, disabled: !!disabled || submitting, node: (value || {}), onChange: (parameter, newValue) => {
                        const newData = {
                            ...(value?.data || {}),
                            [parameter.name]: newValue
                        };
                        setValue({ ...value, data: newData });
                    } })))) : (React.createElement(LoadingRectangles, null))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onSubmit: handleSubmit, onCancel: onCancel, submitting: submitting }))));
};
export default QuickAddCredentialForm;
