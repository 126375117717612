import React, { Component } from 'react';
import styled from 'styled-components';
import { BasicInput } from './BasicInput';
const Container = styled.div `
  position: relative;
  display: flex;

  input {
    text-align: center;
    border-radius: 0;
  }

  .minus,
  .plus {
    cursor: pointer;
    position: relative;
    width: 20px;
    display: block;
    border: 1px solid #ccc;
    background: #eee;
    flex-shrink: 0;
  }

  .minus {
    border-right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .plus {
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .minus:after,
  .plus:after,
  .plus:before {
    content: '';
    background: #000;
    display: block;
    position: absolute;
  }

  .minus.disabled:after,
  .plus.disabled:after,
  .plus.disabled:before {
    background: #ccc;
  }

  .minus:after {
    top: calc(50% - 1px);
    left: calc(50% - 5px);
    width: 10px;
    height: 2px;
  }

  .plus:after {
    top: calc(50% - 1px);
    left: calc(50% - 5px);
    width: 10px;
    height: 2px;
  }

  .plus:before {
    top: calc(50% - 5px);
    left: calc(50% - 1px);
    width: 2px;
    height: 10px;
  }
`;
const StyledInput = styled(BasicInput) `
  padding-bottom: 0 !important;
`;
class NumericInput extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: 0
        };
        this.updateValue = (val, triggerChange) => {
            if (this.state.value != val && triggerChange) {
                this.props.onChange && this.props.onChange(val);
            }
            this.setState({ value: val });
        };
        this.subtract = () => {
            if (this.canSubtract()) {
                this.updateValue(this.state.value - this.getStep(), true);
            }
        };
        this.add = () => {
            if (this.canAdd()) {
                this.updateValue(this.state.value + this.getStep(), true);
            }
        };
        this.handleChange = (e) => {
            let newVal = e.target.value;
            if (newVal < this.props.min) {
                newVal = this.props.min;
            }
            if (newVal > this.props.max) {
                newVal = this.props.max;
            }
            this.updateValue(newVal, true);
        };
    }
    componentDidMount() {
        this.updateValue(this.props.value || this.props.min);
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if ((newProps.value || newProps.value == 0) && newProps.value != this.state.value) {
            this.updateValue(newProps.value);
        }
    }
    getStep() {
        return this.props.step || 1;
    }
    canSubtract() {
        return this.state.value - this.getStep() >= this.props.min;
    }
    canAdd() {
        return this.state.value + this.getStep() <= this.props.max;
    }
    render() {
        const { input, max, min, step } = this.props;
        const { value } = this.state;
        return (React.createElement(Container, null,
            React.createElement("div", { className: `minus ${this.canSubtract() ? '' : 'disabled'}`, onClick: this.subtract }),
            React.createElement(StyledInput, { ...input, max: max, min: min, step: step, value: value, onChange: this.handleChange, type: "number" }),
            React.createElement("div", { className: `plus ${this.canAdd() ? '' : 'disabled'}`, onClick: this.add })));
    }
}
export default NumericInput;
