import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes, isConditionPassed } from '@hypercharge/portal-utils';
import { find, groupBy, isEmpty, isEqual, last } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isWorkflowDefinition } from '../../../../../common/utils/url';
import { useView } from '../../../../../views/components/ViewContext';
import { error, success } from 'react-notification-system-redux';
import { ACTIVITY_DEFINITION_ID } from '../../../../../common/activity';
import { ACTIVITY_ASSIGNED_TO_PROPERTY_ID, ACTIVITY_CONTACT_PROPERTY_ID, ACTIVITY_DUE_DATE_PROPERTY_ID, ACTIVITY_PRIORITY_PROPERTY_ID, ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID, ACTIVITY_RELATED_TO_ENTITY_ID_PROPERTY_ID, ACTIVITY_TYPE_TEXT_PROPERTY_ID } from '../../../../../common/activity/constants';
import { MAX_KANBAN_ITEMS } from '../../../../../views/components/ViewProvider';
import { getColumnDefinitionIdKanbanGroupBy, getSystemFieldsForKanban } from '../../../../../views/utils';
import { useEntityDisplayData } from '../../../../common/components/withEntityDisplayData';
import { CREATED_AT_ID_PROPERTY_ID, ENTITY_ID_PROPERTY_ID, ENTITY_OWNER_PROPERTY_ID, MY_USER_COMPUTED, TITLE_PROPERTY_ID } from '../../../../common/utils/constants';
import { getPropertyById } from '../../../../common/utils/utils';
import useDisplayItemMeta from '../../../../hooks/useDisplayItemMeta';
import { useEntityItems } from '../../../../hooks/useEntityItems';
import { useUpdateProperty } from '../../../../hooks/useUpdateProperty';
import { getFlattenedDisplayDataList } from '../../../utils';
import { KanbanBoardWrapper } from './KanbanBoardWrapper';
export const DESIGN_FIELDS_FOR_KANBAN = {
    workflow: {
        process: [
            TITLE_PROPERTY_ID,
            CREATED_AT_ID_PROPERTY_ID,
            ENTITY_ID_PROPERTY_ID,
            ENTITY_OWNER_PROPERTY_ID,
            'processStatus'
        ],
        task: [
            TITLE_PROPERTY_ID,
            CREATED_AT_ID_PROPERTY_ID,
            ENTITY_ID_PROPERTY_ID,
            'taskAssignedTo',
            'taskPriority',
            'taskProcessRunId',
            'taskStatus',
            'taskDeadline',
            'taskProcessLink'
        ]
    },
    cms: {
        [ACTIVITY_DEFINITION_ID]: [
            TITLE_PROPERTY_ID,
            CREATED_AT_ID_PROPERTY_ID,
            ENTITY_ID_PROPERTY_ID,
            ENTITY_OWNER_PROPERTY_ID,
            ACTIVITY_ASSIGNED_TO_PROPERTY_ID,
            ACTIVITY_DUE_DATE_PROPERTY_ID,
            ACTIVITY_RELATED_TO_DEFINITION_ID_PROPERTY_ID,
            ACTIVITY_RELATED_TO_ENTITY_ID_PROPERTY_ID,
            ACTIVITY_TYPE_TEXT_PROPERTY_ID,
            ACTIVITY_CONTACT_PROPERTY_ID,
            ACTIVITY_PRIORITY_PROPERTY_ID
        ],
        default: ['title', 'createdAt', 'entityId', 'entityOwner']
    }
};
const KanbanContainer = ({ actions = [], baseUrl, contentHeight, definitionId, displayData, fields, idField, isFailed = false, isPending = false, noFilters, noSearch, noViewActions, showStartProcessSelector = true, showViewsSelector, singleMode = false, viewId, withCmsImport, customColumnsTitles, getCustomItemsGroupBy, getCustomColumnCountFilters, getCustomRouteHandleCardClick }) => {
    const { t, language } = useI18n();
    const dispatch = useDispatch();
    const { kanban, searchItems: { data: { results: fetchedItems = [], totalCount = 0 } = {}, isFailed: isFailedFetch, isPending: isPendingFetch } } = useView();
    const titleProperty = getPropertyById(displayData, TITLE_PROPERTY_ID);
    const titleTranslatedKey = titleProperty?.meta.translations?.[language];
    const [items, setItems] = useState(fetchedItems);
    useEffect(() => {
        setItems((prevItems) => {
            if (!isEqual(prevItems, fetchedItems)) {
                return fetchedItems;
            }
            return prevItems;
        });
    }, [fetchedItems]);
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const { data: displayItemMeta } = useDisplayItemMeta({
        definitionId
    });
    const isProcess = useMemo(() => isWorkflowDefinition(displayItemMeta), [displayItemMeta]);
    const systemFields = useMemo(() => getSystemFieldsForKanban(isProcess, definitionId), [definitionId, isProcess]);
    const columnDefinitionIdKanbanGroupBy = useMemo(() => getColumnDefinitionIdKanbanGroupBy(displayData, kanban?.groupBy), [displayData, kanban?.groupBy]);
    const conditionalFormattingGroupBy = useMemo(() => {
        return find(getFlattenedDisplayDataList(displayData), {
            propertyId: kanban?.groupBy
        });
    }, [displayData, kanban?.groupBy])?.meta.conditionalFormatting;
    const { displayData: displayDataColumnDefinition } = useEntityDisplayData(columnDefinitionIdKanbanGroupBy);
    const titleColumnDefinition = getPropertyById(displayDataColumnDefinition, TITLE_PROPERTY_ID);
    const titleColumnDefinitionTranslatedKey = titleColumnDefinition?.meta.translations?.[language];
    const responseFieldsColumnsItems = useMemo(() => {
        const responseFields = [TITLE_PROPERTY_ID];
        if (titleColumnDefinitionTranslatedKey) {
            responseFields.push(titleColumnDefinitionTranslatedKey);
        }
        return responseFields;
    }, [titleColumnDefinitionTranslatedKey]);
    const { data: columnsItems = [] } = useEntityItems({
        definitionId: columnDefinitionIdKanbanGroupBy || '',
        ids: kanban?.columns.filter((column) => !['EMPTY', MY_USER_COMPUTED].includes(column)) || [],
        extraPayloadProps: {
            responseFields: responseFieldsColumnsItems
        },
        enabled: !!columnDefinitionIdKanbanGroupBy && !isEmpty(kanban?.columns)
    });
    const columnsTitles = useMemo(() => ({
        ...customColumnsTitles,
        ...columnsItems.reduce((acc, item) => ({
            ...acc,
            [item.entityId]: (titleColumnDefinitionTranslatedKey && item[titleColumnDefinitionTranslatedKey]) ||
                item.title ||
                item.entityId
        }), {})
    }), [columnsItems, customColumnsTitles, titleColumnDefinitionTranslatedKey]);
    const lists = useMemo(() => {
        if (!kanban?.columns || isEmpty(kanban.columns)) {
            return [];
        }
        const itemsGroupBy = getCustomItemsGroupBy?.(items, kanban?.groupBy) || groupBy(items, kanban?.groupBy);
        const itemsByFields = Object.entries(itemsGroupBy).reduce((acc, [key, value]) => {
            acc[key === 'undefined' ? FilterOperatorTypes.empty : key] = value.map((item) => ({
                ...item,
                title: (titleTranslatedKey && item[titleTranslatedKey]) || item.title || item.entityId
            }));
            return acc;
        }, {});
        return kanban?.columns.map((value) => ({
            propertyId: value,
            definitionId,
            title: columnsTitles[value] ||
                (itemsByFields[value] && last(Object.values(itemsByFields[value]))?.title) ||
                value,
            theme: find(conditionalFormattingGroupBy, (conditional) => itemsByFields[value] &&
                itemsByFields[value].every((item) => isConditionPassed(item, conditional.filter)))?.theme,
            cards: itemsByFields[value] || []
        }));
    }, [
        columnsTitles,
        conditionalFormattingGroupBy,
        definitionId,
        getCustomItemsGroupBy,
        items,
        kanban?.columns,
        kanban?.groupBy,
        titleTranslatedKey
    ]);
    const onChangePosition = useCallback(async (entityId, columnEntityId) => {
        const item = find(items, { entityId });
        if (!item || !kanban?.groupBy) {
            return;
        }
        const oldValue = item[kanban.groupBy];
        if (oldValue == columnEntityId) {
            return;
        }
        const title = item.title || item.entityId;
        try {
            setItems(items.map((item) => {
                if (item.entityId === entityId && item[kanban.groupBy] === oldValue) {
                    return { ...item, [kanban.groupBy]: columnEntityId };
                }
                return item;
            }));
            await updateProperty({ entityId, propertyId: kanban.groupBy, value: columnEntityId });
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('KANBAN_MOVE_ITEM_SUCCESS', { title })
            }));
        }
        catch (e) {
            const message = e?.response?.status == 403
                ? t('KANBAN_MOVE_ITEM_PERMISSION_DENIED', { title })
                : t('KANBAN_MOVE_ITEM_ERROR', { title });
            dispatch(error({
                title: t('ERROR'),
                message
            }));
        }
    }, [dispatch, items, kanban?.groupBy, t, updateProperty]);
    const displayFields = useMemo(() => fields.filter((field) => !systemFields.includes(field.id)), [fields, systemFields]);
    return (React.createElement(ErrorBoundary, null,
        React.createElement(KanbanBoardWrapper, { isDropDisabled: !columnDefinitionIdKanbanGroupBy, showItemsLimitError: totalCount > MAX_KANBAN_ITEMS, actions: actions, baseUrl: baseUrl, contentHeight: contentHeight, definitionId: definitionId, fields: displayFields, idField: idField, isFailed: isFailed || isFailedFetch, isPending: isPending || isPendingFetch, lists: lists, noFilters: noFilters, noSearch: noSearch, noViewActions: noViewActions, onChangePosition: onChangePosition, showStartProcessSelector: showStartProcessSelector, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, showViewsSelector: showViewsSelector, singleMode: singleMode, viewId: viewId, withCmsImport: withCmsImport })));
};
export default KanbanContainer;
