import React, { Fragment, useMemo } from 'react';
import { TooltipTruncate } from '../../../../common/components/TooltipTruncate';
const onLinkClick = (e) => {
    e.stopPropagation();
};
const linkRegExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/i;
const MaterializedLinks = ({ value }) => {
    const nodes = useMemo(() => {
        const result = [];
        let text = value;
        let match;
        let key = 0;
        while ((match = linkRegExp.exec(text))) {
            const index = match.index || 0;
            if (index > 0) {
                result.push(React.createElement(Fragment, { key: key++ }, text.substring(0, index)));
                text = text.substring(index);
            }
            else {
                const link = match[0];
                text = text.substring(link.length);
                result.push(React.createElement("a", { key: key++, href: link, target: "_blank", rel: "noopener noreferrer", onClick: onLinkClick }, link));
            }
        }
        result.push(text);
        return result;
    }, [value]);
    return React.createElement(TooltipTruncate, null, nodes);
};
export default MaterializedLinks;
