import { ANALYTICS } from 'config';
import googleProvider from './googleAnalyticsProvider';
const providers = {
    ga: googleProvider
};
const emitters = ANALYTICS
    ? Object.keys(ANALYTICS).map((k) => providers[k](ANALYTICS[k]))
    : [];
const analyticsMiddlewareFactory = (analyzers) => () => (next) => (action) => {
    emitters.forEach((emitter) => {
        analyzers.forEach((analyzer) => analyzer(action, emitter));
    });
    return next(action);
};
export default analyticsMiddlewareFactory;
