import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { EditableItemTitleContainer, EditableItemTitleStyles, FEATURED_ENTITIES_PATH } from '../../../../../cms';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import { ACTIVITY_DEFINITION_ID } from '../../../../../common/activity';
import { HeightContextProvider } from '../../../../../common/components/height-context';
import LoadingIndicator from '../../../../../common/components/loading-indicator';
import { CONTACT_CMS_DEFINITION_ID } from '../../../../../crm';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { getCustomRouteHandleTaskClickBase } from '../../../../../hyperbi/components/dashboards/components/YourTasks/ActivityTasks/utils';
import { VIEW_DEFINITION_ID, ViewProvider, useViews } from '../../../../../views';
import { ACTIVITY_YOUR_DASHBOARD_PATH, WORKFLOWS_PATH } from '../../../../common/utils/constants';
import { getActivityTaskFilterTypeConfig, mapPropertyActivityTaskDashboardGroupBy, uniqViewIdActivityTaskDashboard } from '../../../constants';
import { getActivityTaskDashboardDesignFields, getActivityTaskDashboardView, getCustomActivityKanbanColumnsFilters, getDefaultFilterQueryActivityTaskDashboard, getNewFilterForActivityTaskDashboard } from '../../../utils';
import { TaskDashboardContent } from '../TaskDashboardContent';
import { TaskDashboardProvider } from '../TaskDashboardContext/TaskDashboardProvider';
import ActivityTasksTableList from '../TasksTableList/ActivityTasksTableList';
const otherElementSelectors = ['#header', '#featured-entities', '#breadcrumbs'];
const ActivityTaskDashboardPage = () => {
    const { t } = useI18n();
    const { contactId } = useMyContact();
    const { viewId } = useParams();
    const { views } = useViews();
    const view = useMemo(() => getActivityTaskDashboardView(views, viewId), [viewId, views]);
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const getCustomRouteHandleTaskClick = useCallback((card) => getCustomRouteHandleTaskClickBase(displayItemMetaList, card), [displayItemMetaList]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "breadcrumbs" },
            React.createElement(Breadcrumbs, { links: [
                    {
                        text: t('YOUR_DASHBOARD_NAV_LINK'),
                        to: `${WORKFLOWS_PATH}`
                    },
                    ...((viewId && [
                        {
                            text: t('ACTIVITY'),
                            to: `${WORKFLOWS_PATH}${ACTIVITY_YOUR_DASHBOARD_PATH}`
                        }
                    ]) ||
                        [])
                ], currentBreadcrumbText: !viewId ? t('ACTIVITY') : undefined, currentBreadcrumbComponent: viewId ? (React.createElement(EditableItemTitleContainer, { className: `${EditableItemTitleStyles.breadcrumbsViewTitle} flex-truncate`, definitionId: VIEW_DEFINITION_ID, entityId: viewId })) : undefined })),
        view ? (React.createElement(ViewProvider, { enableGrouping: false, getCustomKanbanColumnsFilters: getCustomActivityKanbanColumnsFilters, definitionId: ACTIVITY_DEFINITION_ID, context: {
                definitionId: CONTACT_CMS_DEFINITION_ID,
                contactId
            }, view: view, getDesignFields: getActivityTaskDashboardDesignFields },
            React.createElement(TaskDashboardProvider, { getTaskFilterTypeConfig: getActivityTaskFilterTypeConfig, mapProperty: mapPropertyActivityTaskDashboardGroupBy, getNewFilterForTaskDashboard: getNewFilterForActivityTaskDashboard, getDefaultFilterQueryTaskDashboard: getDefaultFilterQueryActivityTaskDashboard },
                React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: 0 },
                    React.createElement(TaskDashboardContent, { viewActionsBaseUrl: `${WORKFLOWS_PATH}${ACTIVITY_YOUR_DASHBOARD_PATH}`, kanbanBaseUrl: `${FEATURED_ENTITIES_PATH}/${ACTIVITY_DEFINITION_ID}`, componentTasksTableList: ActivityTasksTableList, addEmptyAssignedOption: false, availableOn: uniqViewIdActivityTaskDashboard, relatedToTitleKey: "ACTIVITY_RELATED_TO_DEFINITION", getCustomRouteHandleTaskClick: getCustomRouteHandleTaskClick }))))) : (React.createElement(LoadingIndicator, null))));
};
export default ActivityTaskDashboardPage;
