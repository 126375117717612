import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { pick } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { fetchTenant, getTenant, getTenantStatus } from '../../../tenant';
import { SETTINGS_PATH } from '../../constants';
import DomainsSettingsForm from './DomainsSettingsForm';
class DomainsSettingsFormContainer extends Component {
    componentDidMount() {
        const { setLinks, setCurrentBreadcrumbText, t } = this.props;
        setLinks([{ text: t('SETTINGS'), to: SETTINGS_PATH }]);
        setCurrentBreadcrumbText(t('DOMAINS'));
        if (!this.props.isTenantPending) {
            this.props.fetchTenant();
        }
    }
    render() {
        return (React.createElement(DomainsSettingsForm, { isDataPending: this.props.isTenantPending, hasDataFetchFailed: this.props.isTenantFailed, ...pick(this.props, ['contentHeight', 'domainsNeedsActivation', 'enableDomains']) }));
    }
}
const mapStateToProps = (s) => {
    const tenantStatus = getTenantStatus(s);
    const tenant = getTenant(s);
    return {
        domainsNeedsActivation: !!tenant && !tenant.domain,
        isTenantPending: tenantStatus.isPending,
        isTenantFailed: tenantStatus.isFailed
    };
};
const enableDomainsAction = {
    type: 'ENABLE_DOMAINS',
    payload: {},
    meta: {
        http: {
            path: '/v2-custom-data/settings/domains/enable',
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
};
const mapDispatchToProps = (dispatch, { t }) => ({
    // @ts-expect-error
    fetchTenant: () => dispatch(fetchTenant()),
    enableDomains: () => dispatch(enableDomainsAction)
        // @ts-expect-error
        .then(() => Promise.all([
        fetchTenant(),
        success({
            title: t('COMMON__SUCCESS'),
            message: t('DOMAINS_ACTIVATE_SUCCESS')
        })
    ].map(dispatch)), (e) => {
        dispatch(error({
            title: t('COMMON__FAILURE'),
            message: t('DOMAINS_ACTIVATE_FAILURE')
        }));
    })
});
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(DomainsSettingsFormContainer);
