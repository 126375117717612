import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AutoComplete, Select, Table, Tooltip } from 'antd';
import { Field } from 'formik';
import { findIndex, get, has, isArray, isEmpty, mapKeys, omit, startsWith } from 'lodash';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import useDisplayItemMetaList from '../../../../cms/hooks/useDisplayItemMetaList';
import DocumentationLink from '../../../../common/components/documentation-link/DocumentationLink';
import FormikError from '../../../../common/components/formik/FormikError';
import FormikField from '../../../../common/components/formik/FormikField';
import { FieldsContainer } from '../../../../common/utils/formUtils';
import { getDefinitionData } from '../actions';
import { fetchHexonSchemaByModuleId, fetchPreviewByModuleId } from './actions';
import { ACCESSORY_FIELD, URL_DOCUMENTATION_VEHICLE } from './constants';
export const initialValues = {
    mapping: {
        stocknumber: 'entityId',
        'general.category': '',
        'general.bodystyle': ''
    }
};
export const getFormSchema = () => {
    return Yup.object().shape({
        definitionId: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        mapping: Yup.object({
            stocknumber: Yup.string().required(t('VALIDATIONS__REQUIRED')),
            'general.category': Yup.string().required(t('VALIDATIONS__REQUIRED')),
            'general.bodystyle': Yup.string().required(t('VALIDATIONS__REQUIRED'))
        }).required(t('VALIDATIONS__REQUIRED'))
    });
};
const indexingAccessories = (mapping) => {
    let countAccessories = 0;
    return mapKeys(mapping, (value, index) => {
        if (startsWith(index, ACCESSORY_FIELD)) {
            countAccessories++;
            return `${ACCESSORY_FIELD}.${countAccessories}`;
        }
        else {
            return index;
        }
    });
};
const HexonModuleVehicleForm = ({ dispatch, moduleId, disabled, configuration: { definitionId: selectedEntityDefinitionId = '', mapping = {} } }) => {
    const [loading, setLoading] = useState(false);
    const { t, language } = useI18n();
    const { data: entities, isLoading: isPendingEntities } = useDisplayItemMetaList();
    const [entityProperties, setEntityProperties] = useState([]);
    const [hexonProperties, setHexonProperties] = useState([]);
    const [previews, setPreviews] = useState({});
    const updatePreview = useCallback((definitionId, mapping) => dispatch(fetchPreviewByModuleId(definitionId, moduleId, mapping))
        // @ts-expect-error
        .then(setPreviews), [dispatch, moduleId]);
    const reduceDefinitionData = useCallback((data) => {
        return data.reduce((accumulator, current) => {
            const values = current.values.map((value) => ({
                propertyId: value.propertyId,
                title: value.labels[language]
            }));
            return accumulator.concat(values);
        }, []);
    }, [language]);
    useEffect(() => {
        setLoading(true);
        Promise.all([dispatch(fetchHexonSchemaByModuleId(moduleId))])
            .then(([hexonProperties]) => {
            setHexonProperties(hexonProperties);
        })
            .then(() => setLoading(false));
    }, [dispatch, language, moduleId]);
    useEffect(() => {
        if (selectedEntityDefinitionId) {
            dispatch(getDefinitionData(selectedEntityDefinitionId))
                // @ts-expect-error
                .then((response) => reduceDefinitionData(response.data))
                .then(setEntityProperties);
        }
    }, [dispatch, selectedEntityDefinitionId, reduceDefinitionData]);
    const timer = useRef(null);
    useEffect(() => {
        if (selectedEntityDefinitionId && !isEmpty(mapping)) {
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
            timer.current = setTimeout(() => {
                updatePreview(selectedEntityDefinitionId, mapping);
            }, 5000);
        }
    }, [mapping, selectedEntityDefinitionId, updatePreview]);
    /** *
     * Fill table data
     */
    const propertyOptions = entityProperties.map((property) => ({
        value: property.propertyId,
        label: property.title
    }));
    const getPreview = (field) => {
        const value = get(previews, field, '');
        if (isArray(value)) {
            switch (field) {
                case 'images':
                    return value.map((image, key) => (React.createElement("img", { key: key, src: image.image_url, height: "30", style: { paddingRight: 3, paddingBottom: 3 } })));
            }
            return JSON.stringify(value, null, 2);
        }
        else if (startsWith(field, ACCESSORY_FIELD)) {
            return value?.[language];
        }
        return value;
    };
    const rows = Object.keys(mapping).filter((hexonProp) => findIndex(hexonProperties, (prop) => prop.field == hexonProp) != -1 ||
        startsWith(hexonProp, ACCESSORY_FIELD));
    const tableData = rows.map((hexonProperty, index) => {
        return {
            key: index.toString(),
            cmsProperty: (React.createElement(FormikField, { name: `mapping.['${hexonProperty}']`, value: get(mapping, hexonProperty, ''), backfill: true, component: AutoComplete, disabled: loading || isPendingEntities, style: { width: '100%' }, placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_PROPERTY'), options: propertyOptions })),
            hexonProperty: (
            // @ts-expect-error
            React.createElement(Tooltip, { placement: "topLeft", title: hexonProperty.description }, hexonProperty)),
            preview: getPreview(hexonProperty),
            add: (React.createElement(Field, { name: "add" }, ({ field, form }) => has(initialValues.mapping, hexonProperty) === false ? (React.createElement(Button, { inversed: true, onClick: () => {
                    form.setFieldValue('mapping', indexingAccessories(omit(form.values['mapping'], hexonProperty)));
                } }, "-")) : ('')))
        };
    });
    /**
     *  Add new Hexon property
     */
    const restHexonProperty = hexonProperties.filter((property) => !has(mapping, property.field));
    const restHexonOptions = restHexonProperty.map((property) => ({
        value: property.field,
        label: property.field
    }));
    if (restHexonProperty.length > 0) {
        tableData.push({
            key: 'addNew',
            cmsProperty: (React.createElement(FormikField, { name: "newProperty.portal", backfill: true, component: AutoComplete, disabled: loading || isPendingEntities, style: { width: '100%' }, placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_PROPERTY'), options: propertyOptions })),
            hexonProperty: (React.createElement(FormikField, { name: "newProperty.hexon", backfill: true, component: AutoComplete, disabled: loading || isPendingEntities, style: { width: '100%' }, placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_PROPERTY'), options: restHexonOptions })),
            preview: '',
            add: (React.createElement(Field, { name: "add" }, ({ field, form }) => (React.createElement(Button, { onClick: () => {
                    const prop = form.values.newProperty;
                    if (prop && !isEmpty(prop.portal) && !isEmpty(prop.hexon)) {
                        form.setFieldValue('mapping', indexingAccessories({
                            ...form.values['mapping'],
                            [prop.hexon]: prop.portal
                        }));
                        form.setFieldValue('newProperty', {});
                    }
                } }, "\u2713"))))
        });
    }
    /*
     * Init table
     */
    return (React.createElement(Fragment, null,
        React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
            React.createElement("div", null,
                React.createElement(LabelText, { htmlFor: "definitionId" }, t('INTEGRATIONS_XDMS_MODULE_SELECT_ENTITY')),
                React.createElement(FormikField, { id: "definitionId", name: "definitionId", showSearch: true, optionFilterProp: "label", style: { width: '100%' }, placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_ENTITY'), component: Select, disabled: disabled || loading || isPendingEntities, options: entities.map(({ definitionId, title }) => ({
                        label: title,
                        value: definitionId
                    })) }),
                React.createElement(FormikError, { name: "definitionId" })),
            selectedEntityDefinitionId && hexonProperties.length > 0 && (React.createElement("div", null,
                React.createElement(MappingsLabelText, null,
                    t('INTEGRATIONS_XDMS_MAPPINGS'),
                    React.createElement(DocumentationLink, { href: URL_DOCUMENTATION_VEHICLE })),
                React.createElement(Table, { pagination: {
                        total: tableData.length,
                        pageSize: tableData.length,
                        hideOnSinglePage: true
                    }, dataSource: tableData, columns: [
                        {
                            title: 'CMS Property',
                            dataIndex: 'cmsProperty'
                        },
                        {
                            title: 'Hexon Property',
                            dataIndex: 'hexonProperty',
                            width: 300
                        },
                        {
                            title: 'Preview',
                            dataIndex: 'preview',
                            width: 200
                        },
                        {
                            title: '',
                            dataIndex: 'add',
                            width: 90
                        }
                    ], style: { marginTop: 20 }, indentSize: 0 }))))));
};
const MappingsLabelText = styled(LabelText) `
  border-bottom: 1px solid var(--grayscaleColor-3);
  a {
    float: right;
  }
`;
export default HexonModuleVehicleForm;
