import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React from 'react';
import { FaDatabase } from 'react-icons/fa';
import ConditionalWrapper from '../../../common/components/ConditionalWrapper';
import { GroupActionsButton } from './GroupActionsButton';
import styles from './Hierarchy.module.scss';
export const GroupItemTitle = ({ canCreateAndDeleteGroup, canUpdateGroupMembers, handleDelete, isCollapsed, isSelected, saving, searchText, toggleMembersUpdateDrawer, treeItem }) => {
    const { t } = useI18n();
    const upperSearchText = searchText.toUpperCase();
    const itemTitle = treeItem.title || t('NO_TITLE');
    const index = itemTitle.toUpperCase().indexOf(upperSearchText);
    const beforeStr = itemTitle.substring(0, index);
    const matchedStr = itemTitle.substring(index, index + searchText.length);
    const afterStr = itemTitle.substring(index + searchText.length);
    return (React.createElement("div", { className: cn(styles.container__itemTitle, 'ItemTitle', { selected: isSelected }) },
        React.createElement(ConditionalWrapper, { Wrapper: Tooltip, condition: isCollapsed, title: itemTitle, placement: "right" },
            React.createElement(FaDatabase, null)),
        React.createElement("span", { className: "itemTitle__title" }, index > -1 ? (React.createElement(React.Fragment, null,
            beforeStr,
            React.createElement("b", null, matchedStr),
            afterStr)) : (itemTitle)),
        canCreateAndDeleteGroup && (React.createElement(GroupActionsButton, { canCreateAndDeleteGroup: canCreateAndDeleteGroup, canUpdateGroupMembers: canUpdateGroupMembers, handleDelete: handleDelete, saving: saving, toggleMembersUpdateDrawer: toggleMembersUpdateDrawer, treeItem: treeItem }))));
};
