import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchOptionGroup, getIsFetchOptionGroupPending, getOption } from '@hypercharge/hyper-react-base/lib/metadata';
import { compact, debounce, flatMap } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMetadataState } from '../../../../../../common/reducers/rootSelector';
import { FieldWrapper } from '../../FieldElements';
const optionGroupsToFetch = new Set();
const fetchFromQueue = (fetchOptionGroup) => {
    optionGroupsToFetch.forEach((optionGroupId) => {
        fetchOptionGroup(optionGroupId);
    });
    optionGroupsToFetch.clear();
};
const debouncedFetchOptionGroup = debounce(fetchFromQueue);
const addToQueueAndFetch = (optionGroupId, fetchOptionGroup) => {
    optionGroupsToFetch.add(optionGroupId);
    debouncedFetchOptionGroup(fetchOptionGroup);
};
const SelectDisplay = ({ meta, value, style, tabIndex, onKeyDown }) => {
    const { language } = useI18n();
    const dispatch = useDispatch();
    const metaDataState = useSelector((s) => getMetadataState(s));
    const getOptionSelector = useCallback((val) => getOption(metaDataState, meta.source, val), [metaDataState, meta]);
    const isPending = useMemo(() => getIsFetchOptionGroupPending(metaDataState, meta.source, ''), [metaDataState, meta]);
    const fetchOptionGroupDispatch = useCallback((optionGroupId) => dispatch(fetchOptionGroup(optionGroupId, '')), [dispatch]);
    useEffect(() => {
        if (value) {
            const groupOptionIds = Array.isArray(value) ? value : [value];
            groupOptionIds
                .filter((optionId) => !getOptionSelector(optionId))
                .forEach(() => addToQueueAndFetch(meta.source, fetchOptionGroupDispatch));
        }
    }, []);
    if (!value) {
        return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown }, '\u00a0'));
    }
    const groupOptionIds = Array.isArray(value) ? value : [value];
    return (React.createElement(FieldWrapper, { style: style, tabIndex: tabIndex, onKeyDown: onKeyDown }, flatMap(compact(groupOptionIds).map((groupOptionId) => {
        if (isPending) {
            return React.createElement("span", { key: groupOptionId }, "...");
        }
        const option = getOptionSelector(groupOptionId);
        return (React.createElement("span", { key: groupOptionId }, option ? getTranslation(option.labels, language) : '...'));
    }), (a, i) => (i ? [React.createElement("br", { key: i }), a] : [a]))));
};
export default SelectDisplay;
