import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { oneHourInMs, oneMinuteInMs } from '../../../common/utils/react-query-client';
import { isAxiosError } from '../../../common/utils/request';
import { sleep } from '../../../utils/time';
import styles from './StatisticBox.module.scss';
import { getUsageStatistic } from './actions';
const InfoButton = styled.div `
  background: transparent url('/assets/images/statistics/info.svg') no-repeat center center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;
const STATISTICS_BOX_QUERY_KEY = 'statistics-box';
const StatisticBox = ({ className, type, range, img, title, description, format }) => {
    const { t } = useI18n();
    const fetchStatisticDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const { data, isLoading } = useQuery([STATISTICS_BOX_QUERY_KEY, type, range?.[0], range?.[1]], async () => {
        let result;
        try {
            result = await fetchStatisticDispatch(getUsageStatistic(type ?? '', range?.[0], range?.[1]));
        }
        catch (err) {
            if (isAxiosError(err) && err.response?.status === 504) {
                return { value: -1 };
            }
            throw err;
        }
        if (result && 'value' in result) {
            return result;
        }
        const queryId = result.queryId;
        do {
            await sleep(2000);
            result = await fetchStatisticDispatch(getUsageStatistic(type ?? '', range?.[0], range?.[1], queryId));
        } while ('queryId' in result && result.queryId);
        return result;
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        onError: () => {
            notificationDispatch(error({ title: t('ERROR'), message: t('STATISTICS_USAGE_FAILED') }));
        }
    });
    const value = useMemo(() => {
        let val = -1;
        if (data && 'value' in data) {
            val = data.value;
        }
        if (format) {
            return format(val);
        }
        return val;
    }, [data, format]);
    return (React.createElement("div", { className: cn(styles.wrapper, 'p-3 m-2', className) },
        React.createElement("img", { src: `/assets/images/statistics/${img}`, alt: title }),
        React.createElement(Tooltip, { placement: "bottomRight", title: description, overlayClassName: "statistics-tooltip" },
            React.createElement(InfoButton, null)),
        isLoading ? (React.createElement("div", { className: "mt-2 loading-indicator" },
            React.createElement(LoadingIndicator, null))) : (React.createElement("span", { className: "value" }, value)),
        React.createElement("span", { className: "title" }, title)));
};
export default StatisticBox;
