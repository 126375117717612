import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { saveTemplate } from '../templates/actions';
import { invalidateDocumentTemplateQuery } from './useDocumentTemplate';
import { invalidateSearchDocumentsQuery } from './useSearchDocuments';
import { invalidateTemplateVersionsQuery } from './useTemplateVersions';
export const useSaveDocumentTemplate = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: async (template) => {
            return dispatch(saveTemplate(template));
        },
        onSettled: async (_, __, { id }) => {
            await invalidateSearchDocumentsQuery();
            if (id) {
                await invalidateTemplateVersionsQuery(id);
                await invalidateDocumentTemplateQuery(id);
            }
        }
    });
};
