import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { head } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient from '../../common/utils/react-query-client';
import { useDisplayTenant } from '../../tenant/useDisplayTenant';
import { useEntityDisplayData } from '../common/components/withEntityDisplayData';
import { getPropertyById } from '../common/utils/utils';
import { updateEntityData } from '../data/actions';
import { invalidateItemsQuery } from './invalidateCache';
import { DISPLAY_DATA_QUERY_KEY } from './useDisplayData';
import { matchesPredicate } from './usePropertyEntityItemValue';
const MUTATION_KEY = 'UpdateProperty';
export const onErrorUpdatePropertyHandler = ({ displayDataWithHidden, notificationDispatch, language, displayTenant, t }) => (responseError, { propertyId, definitionId, entityId }) => {
    const errorMessage = responseError.response?.data?.data?.[propertyId];
    if (errorMessage) {
        const property = getPropertyById(displayDataWithHidden, propertyId);
        if (property) {
            const label = property.labels[language] ||
                (displayTenant?.defaultLanguage && property.labels[displayTenant.defaultLanguage]) ||
                head(Object.values(property.labels)) ||
                property.propertyId;
            notificationDispatch(error({
                title: t('ERROR'),
                message: `${label}: ${errorMessage}`
            }));
            return;
        }
    }
    notificationDispatch(error({
        title: t('ERROR'),
        message: `${t('ENTITY_DATA_UPDATE_FAILED')}: ${propertyId}`
    }));
    if (definitionId) {
        void invalidateItemsQuery({
            definitionId,
            ids: [entityId],
            changedPropertyIds: [propertyId],
            waitNewData: true
        });
    }
};
export const onSettledUpdatePropertyHandler = ({ definitionId, entityId, propertyId }) => {
    if (definitionId) {
        void invalidateItemsQuery({
            definitionId,
            ids: [entityId],
            changedPropertyIds: [propertyId],
            waitNewData: true
        });
    }
};
const onMutateUpdatePropertyHandler = async ({ propertyId, entityId, value, definitionId }) => {
    await queryClient.cancelQueries([DISPLAY_DATA_QUERY_KEY, definitionId, entityId]);
    queryClient.setQueryData([DISPLAY_DATA_QUERY_KEY, definitionId, entityId], (prevData) => {
        if (!prevData?.data) {
            return prevData;
        }
        return {
            ...prevData,
            data: prevData.data.map((section) => {
                return {
                    ...section,
                    values: section.values.map((propertyItem) => {
                        if (propertyItem.propertyId === propertyId) {
                            // @ts-expect-error
                            propertyItem.value = value;
                        }
                        return propertyItem;
                    })
                };
            })
        };
    });
    queryClient.cancelQueries({
        predicate: (query) => matchesPredicate(query, definitionId, entityId)
    });
    queryClient.setQueriesData({
        predicate: (query) => matchesPredicate(query, definitionId, entityId)
    }, (prevData) => {
        if (!prevData) {
            return prevData;
        }
        return {
            ...prevData,
            results: prevData.results.map((item) => {
                if (item.entityId === entityId) {
                    return {
                        ...item,
                        [propertyId]: value
                    };
                }
                return item;
            })
        };
    });
};
export const useUpdateProperty = ({ definitionId }) => {
    const { language, t } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const { displayDataWithHidden } = useEntityDisplayData(definitionId);
    const dispatchUpdateData = useDispatch();
    const notificationDispatch = useDispatch();
    return useMutation([MUTATION_KEY], ({ propertyId, entityId, value, definitionId: asyncDefinitionId = '' }) => {
        if (!definitionId && !asyncDefinitionId) {
            return Promise.reject(new Error('Definition ID is missing'));
        }
        if (definitionId && asyncDefinitionId && definitionId !== asyncDefinitionId) {
            return Promise.reject(new Error('definitionId and asyncDefinitionId cannot be used at the same time'));
        }
        return dispatchUpdateData(updateEntityData(definitionId || asyncDefinitionId, entityId, {
            entityId,
            definitionId: definitionId || asyncDefinitionId,
            propertyId,
            value
        }, language));
    }, {
        onError: onErrorUpdatePropertyHandler({
            displayTenant,
            language,
            t,
            notificationDispatch,
            displayDataWithHidden
        }),
        onSettled: (_, __, updatePropertyArgs) => {
            onSettledUpdatePropertyHandler({ definitionId, ...updatePropertyArgs });
        },
        onMutate: async ({ propertyId, entityId, value, definitionId: asyncDefinitionId }) => onMutateUpdatePropertyHandler({
            propertyId,
            entityId,
            value,
            definitionId: definitionId || asyncDefinitionId
        })
    });
};
