import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import Navbar from '@hypercharge/hyper-react-base/lib/common/navbar';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import memoize from 'fast-memoize';
import { get } from 'lodash';
import React, { Component } from 'react';
import { MdFormatListBulleted } from 'react-icons/md';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { HighlightedPropertiesForm } from '../../../../cms/settings/components/entity/HighlightedItems';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import { fetchProcessMeta } from '../../actions';
import { META_DEFINITION_PATH, META_HIGHLIGHTED_PROPERTIES_PATH, META_TASKS_PATH, PROCESS_META_METADATA } from '../../constants';
import { getProcessMeta, hasFetchProcessMetaFailed, isProcessMetaAvailable } from '../../selectors';
import ActivityContainerWrapper from '../activity-settings/ActivityContainerWrapper/ActivityContainerWrapper';
import MetaDefinitionForm from '../meta-definition-form';
import ProcessMetaFormContainer from '../process-meta-form/ProcessMetaFormContainer';
import CenteredLoading from './CenteredLoading';
import ProcessMetaActions from './ProcessMetaActions';
import styles from './ProcessMetaRoutes.module.scss';
class ProcessMetaRoutes extends Component {
    constructor() {
        super(...arguments);
        this.handleSetLinks = () => {
            const { processMeta, baseUrl, language } = this.props;
            if (processMeta) {
                this.props.setLinks([
                    { text: this.props.t('WORKFLOWS_NAV_LINK'), to: WORKFLOWS_PATH },
                    {
                        text: this.props.t('WORKFLOWS_SETTINGS_PROCESS_META_NAV_LINK'),
                        to: `${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`
                    },
                    {
                        text: getTranslation(processMeta.titles, language),
                        to: `${baseUrl}${BROWSE_PATH}`
                    }
                ]);
            }
        };
        this.getNavLinks = (url, t, definitionId) => {
            const navLinks = [
                {
                    to: url + PROCESS_META_METADATA,
                    text: t('BASIC_INFO')
                },
                {
                    to: url + META_DEFINITION_PATH,
                    text: t('WORKFLOWS_SETTINGS_PROPERTIES_NAV_LINK')
                }
            ];
            if (![ALL_TASKS_DEFINITION_ID, ALL_PROCESSES_DEFINITION_ID].includes(definitionId)) {
                navLinks.push({
                    to: url + META_TASKS_PATH,
                    text: t('WORKFLOWS_SETTINGS_TASK_META_NAV_LINK')
                }, {
                    to: url + META_HIGHLIGHTED_PROPERTIES_PATH,
                    text: t('HIGHLIGHTED_PROPERTIES_NAV_LINK')
                });
            }
            return navLinks;
        };
        this.getShowProcessesButtonJsx = memoize((baseUrl) => (React.createElement(Button, { inversed: true, to: baseUrl, className: "ms-3" },
            React.createElement(MdFormatListBulleted, { className: "me-1" }),
            this.props.t('WORKFLOWS_PROCESSES_NAV_LINK'))));
        this.getMetaDefinitionFormRoute = memoize((processMetaId, processMeta, parentViewGroups, isSystem) => (props) => (React.createElement(MetaDefinitionForm, { key: processMetaId, metaDefinitionId: processMetaId, parentViewGroups: parentViewGroups, itemMeta: processMeta, withRequiredProperties: false, mainActionButtonComponent: ({ metaDefinitionId, handleSubmit, submitting, disabled, dirty }) => (React.createElement(ProcessMetaActions, { id: metaDefinitionId, save: handleSubmit, saving: submitting, disabled: disabled, canSave: dirty, canDelete: !isSystem })), extraActionButtons: this.getShowProcessesButtonJsx(`${this.props.baseUrl}${BROWSE_PATH}`), ...props })));
    }
    componentDidMount() {
        const { processMetaId, isAvailable, t } = this.props;
        if (!isAvailable) {
            this.props.fetchProcessMeta(processMetaId);
        }
        this.handleSetLinks();
        this.props.setCurrentBreadcrumbText(t('SETTINGS'));
    }
    componentDidUpdate(prevProps) {
        if (this.props.processMeta && !prevProps.processMeta) {
            this.handleSetLinks();
        }
    }
    render() {
        const { processMetaId, processMeta, isFailed, contentHeight, isSystem, match: { url }, baseUrl, t } = this.props;
        if (isFailed) {
            return React.createElement(NotFound, null);
        }
        if (!processMeta) {
            return React.createElement(CenteredLoading, null);
        }
        const parentViewGroups = get(processMeta, 'viewGroups', []);
        const extraActionButtonsJsx = this.getShowProcessesButtonJsx(`${baseUrl}${BROWSE_PATH}`);
        return (React.createElement(SectionCard, { withoutPadding: true, withoutMargin: true, className: styles.wrapper },
            React.createElement(Navbar, { className: "nav-bar flex-wrap pt-5 pt-md-0", links: this.getNavLinks(url, t, processMetaId), showOnlyIcons: false, exactMatching: true, spacing: "0.5rem" }),
            React.createElement("div", { style: {
                    height: contentHeight
                } },
                React.createElement(ErrorBoundary, null,
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: `${url}${PROCESS_META_METADATA}`, render: (props) => (
                            // @ts-expect-error
                            React.createElement(ProcessMetaFormContainer, { key: processMetaId, processMeta: processMeta, extraActionButtons: extraActionButtonsJsx, isSystem: isSystem, ...props })) }),
                        React.createElement(Route, { path: `${url}${META_DEFINITION_PATH}`, render: this.getMetaDefinitionFormRoute(processMetaId, processMeta, parentViewGroups, isSystem) }),
                        React.createElement(Route, { exact: true, path: `${url}${META_TASKS_PATH}`, render: (props) => (React.createElement(ActivityContainerWrapper, { key: processMetaId, contentHeight: contentHeight, processMeta: processMeta, parentViewGroups: parentViewGroups, extraActionButtons: extraActionButtonsJsx, isSystem: isSystem, ...props })) }),
                        React.createElement(Route, { exact: true, path: `${url}${META_HIGHLIGHTED_PROPERTIES_PATH}` },
                            React.createElement(HighlightedPropertiesForm, { itemMeta: processMeta, itemMetaId: processMetaId, isSystem: isSystem, extraActionButtons: extraActionButtonsJsx, key: processMetaId })),
                        React.createElement(Redirect, { to: `${url}${PROCESS_META_METADATA}` }))))));
    }
}
const mapStateToProps = (s, { processMetaId }) => {
    const processMeta = getProcessMeta(s, processMetaId);
    return {
        processMeta,
        isAvailable: isProcessMetaAvailable(s, processMetaId),
        isFailed: hasFetchProcessMetaFailed(s, processMetaId),
        isSystem: !!get(processMeta, 'system', false)
    };
};
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), connect(mapStateToProps, { fetchProcessMeta }))(ProcessMetaRoutes);
