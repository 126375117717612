const CONFIGS_PATH = '/v2-configs';
// ////////////////////
// Fetch config
// ////////////////////
export const fetchConfig = (configType, configId) => ({
    type: 'CONFIGS__FETCH',
    meta: {
        http: {
            path: `${CONFIGS_PATH}/${configType}/${configId}`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: { key: `${configType}_${configId}` }
        }
    }
});
export const fetchPersonalConfig = () => ({
    type: 'CONFIGS__FETCH',
    meta: {
        http: {
            path: `${CONFIGS_PATH}/personal`,
            method: 'GET',
            allowPromiseReject: true,
            actionContext: { key: `personal` }
        }
    }
});
// ////////////////////
// Save config
// ////////////////////
export const saveConfig = (config) => ({
    type: 'CONFIGS__SAVE',
    payload: config,
    meta: {
        http: {
            path: `${CONFIGS_PATH}/${config.configType}/${config.configId}`,
            method: 'PUT',
            allowPromiseReject: true,
            actionContext: { key: `${config.configType}_${config.configId}` }
        }
    }
});
export const savePersonalConfig = (data) => ({
    type: 'CONFIGS__PERSONAL_SAVE',
    payload: { data },
    meta: {
        http: {
            path: `${CONFIGS_PATH}/personal`,
            method: 'PUT',
            allowPromiseReject: true,
            actionContext: { key: `personal` }
        }
    }
});
