import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tabs } from 'antd';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { PropertiesProvider } from '../../../../cms/common/context/PropertiesProvider';
import GeneralOptionsForm, { getGeneralFormSchema } from './components/GeneralOptionsForm';
import { InspectionSettingsForm, getInspectionSettingsFormSchema } from './components/InspectionSettingsForm';
import WhiteLabelSettingsForm, { getWhitelabelSettingsFormSchema } from './components/WhiteLabelSettingsForm';
export const initialValues = {
    enabled: true,
    configuration: {}
};
var AvailableTabs;
(function (AvailableTabs) {
    AvailableTabs["GENERAL"] = "GENERAL";
    AvailableTabs["WHITELABEL"] = "WHITELABEL";
    AvailableTabs["INSPECTION"] = "INSPECTION";
})(AvailableTabs || (AvailableTabs = {}));
const isTabAvailable = (tabName) => !!AvailableTabs[tabName];
export const getFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.boolean().default(true),
        title: Yup.string().default('Unnamed'),
        configuration: Yup.object()
            .shape({
            ...getGeneralFormSchema(),
            whiteLabel: getWhitelabelSettingsFormSchema(),
            inspection: getInspectionSettingsFormSchema()
        })
            .required()
    });
};
const Form = ({ instance, setFieldValue, disabled }) => {
    const { t } = useI18n();
    const [activeTab, setActiveTab] = useState(AvailableTabs.GENERAL);
    const onChangeTab = useCallback((activeTab) => {
        if (isTabAvailable(activeTab)) {
            setActiveTab(activeTab);
        }
    }, []);
    return (React.createElement(PropertiesProvider, null,
        React.createElement(Tabs, { activeKey: activeTab, onChange: onChangeTab, items: [
                {
                    key: AvailableTabs.GENERAL,
                    label: t('APPS_HYPER_INSPECTION_GENERAL__HEADER'),
                    children: (React.createElement(GeneralOptionsForm, { configuration: instance.configuration, disabled: disabled }))
                },
                {
                    key: AvailableTabs.WHITELABEL,
                    label: t('APPS_HYPER_INSPECTION_WHITELABEL__HEADER'),
                    children: (React.createElement(WhiteLabelSettingsForm, { setFieldValue: setFieldValue, whiteLabel: instance.configuration.whiteLabel, disabled: disabled }))
                },
                {
                    key: AvailableTabs.INSPECTION,
                    label: t('APPS_HYPER_INSPECTION_INSPECTION__HEADER'),
                    children: (React.createElement(InspectionSettingsForm, { inspection: instance.configuration.inspection, setFieldValue: setFieldValue, disabled: disabled }))
                }
            ] })));
};
export default Form;
