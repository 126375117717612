import { push } from '@hypercharge/hyper-react-base/lib/router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { BROWSE_PATH, VIEW_PATH } from '../../common/utils/constants';
import { useView } from './ViewContext';
import { useViews } from './ViewsProvider';
const RedirectOnMissingView = ({ viewId, location, dispatch }) => {
    const { loadingViews } = useViews();
    const { view } = useView();
    useEffect(() => {
        if (viewId && !loadingViews && !view) {
            dispatch(push({
                ...location,
                pathname: location.pathname.replace(`${VIEW_PATH}/${viewId}`, BROWSE_PATH)
            }));
        }
    }, [dispatch, loadingViews, location, location.pathname, view, viewId]);
    return null;
};
export default compose(withRouter, connect())(RedirectOnMissingView);
