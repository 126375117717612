import { ConditionType, SortOrder } from '@hypercharge/portal-utils';
import { INITIAL_PAGE_SIZE } from 'config';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import queryClient, { oneHourInMs, oneMinuteInMs } from '../../common/utils/react-query-client';
import { TITLE_PROPERTY_ID } from '../common/utils/constants';
import { searchItems } from '../data/actions';
export const ENTITY_ITEMS_QUERY_KEY = 'entity-items';
export const invalidateSearchItemsQuery = async ({ definitionId }) => {
    await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(ENTITY_ITEMS_QUERY_KEY) && query.queryKey.includes(definitionId)
    });
};
export const useSearchItems = ({ definitionId, filterRequest, enabled, keepPreviousData, queryKey }) => {
    const dispatchSearchItems = useDispatch();
    const fetchData = useCallback(() => {
        if (!definitionId) {
            return Promise.resolve(undefined);
        }
        return dispatchSearchItems(searchItems(definitionId, {
            sortBy: [{ field: TITLE_PROPERTY_ID, order: SortOrder.asc }],
            query: { condition: ConditionType.or, filters: [] },
            fullText: '',
            offset: 0,
            languageCode: 'en',
            limit: INITIAL_PAGE_SIZE,
            responseFields: [TITLE_PROPERTY_ID],
            fullTextFields: [TITLE_PROPERTY_ID],
            ...filterRequest
        }));
    }, [definitionId, filterRequest, dispatchSearchItems]);
    const { data, isError, isLoading } = useQuery([queryKey, ENTITY_ITEMS_QUERY_KEY, definitionId, { filterRequest }], fetchData, {
        cacheTime: oneHourInMs,
        staleTime: oneMinuteInMs,
        enabled,
        keepPreviousData
    });
    return useMemo(() => ({
        data,
        isAvailable: !!data,
        isPending: isLoading,
        isFailed: isError
    }), [data, isError, isLoading]);
};
