import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { deleteDefinition, fetchAllDefinitions, fetchDefinitionsIsPending, getAllDefinitions } from '@hypercharge/hyper-react-base/lib/metadata';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { getMetadataState } from '../../../common/reducers/rootSelector';
import DefinitionsList from './DefinitionsList';
class DefinitionsListContainer extends Component {
    componentDidMount() {
        if (this.props.definitions.length === 0) {
            this.props.fetchAllDefinitions();
        }
    }
    render() {
        return React.createElement(DefinitionsList, { ...this.props });
    }
}
function mapStateToProps(state) {
    const metadataState = getMetadataState(state);
    return {
        definitions: getAllDefinitions(metadataState),
        isPending: fetchDefinitionsIsPending(metadataState)
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchAllDefinitions: () => {
            dispatch(fetchAllDefinitions());
        },
        deleteDefinition: (id) => {
            return dispatch(deleteDefinition(id));
        }
    };
}
//  TODO: The `FC` component type is specified here (although the component is not functional),
//   because the `react-router` package types cannot accept a multiple types
//   (the `hyper-react-base` package build crashes) for the `component` prop.
//   If a component of the current file has already been converted to `FC` type,
//   this `note` can be deleted
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(DefinitionsListContainer);
