import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { getFeaturedDefinitionIdsOneLevel } from '../../../../../cms/data/utils';
import DropdownButton from '../../../../../common/components/DropdownButton';
import { Select } from '../../../../../common/components/Select';
import styles from './AddFeaturedDefinitionId.module.scss';
const AddFeaturedDefinitionId = ({ cmsEntities, isDataPending, addFeaturedDefinitionId, values }) => {
    const { t } = useI18n();
    const selectWrapperRef = useRef(null);
    const [addSearchValue, setAddSearchValue] = useState('');
    const [addPopoverOpen, setAddPopoverOpen] = useState(false);
    const filteredCmsEntities = cmsEntities.filter(({ definitionId }) => !getFeaturedDefinitionIdsOneLevel(values.featuredDefinitionIds || []).includes(definitionId));
    const onSelect = useCallback((value) => {
        setAddPopoverOpen(false);
        void addFeaturedDefinitionId(value);
    }, [addFeaturedDefinitionId]);
    return (React.createElement(DropdownButton, { popover: {
            open: addPopoverOpen,
            onOpenChange: setAddPopoverOpen,
            content: (React.createElement("div", { ref: selectWrapperRef },
                React.createElement(Select, { open: true, loading: isDataPending, options: filteredCmsEntities.map(({ definitionId, title }) => ({
                        label: title,
                        value: definitionId
                    })), value: '', placeholder: t('SELECT_ENTITY'), 
                    // @ts-expect-error can't be null
                    getPopupContainer: () => selectWrapperRef.current, optionFilterProp: "label", allowClear: true, showSearch: true, searchValue: addSearchValue, onSearch: setAddSearchValue, onSelect: onSelect }))),
            overlayClassName: styles.addButtonContentOverlay,
            placement: 'bottom'
        }, popoverButton: React.createElement(Button, { className: styles.addButtonContent, icon: React.createElement(BsPlus, null) }, t('ADD')) }));
};
export default AddFeaturedDefinitionId;
