import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import EntityEditor from '../../../../cms/data/components/item-property/editors/EntityEditor';
import { TASK_PRIORITY_DEFINITION_ID } from '../../utils/constants';
import styles from './TaskPriorityField.module.scss';
const TaskPriorityField = ({ className, disabled, label }) => {
    const { t } = useI18n();
    const Editor = useCallback(({ input, meta }) => (React.createElement(EntityEditor, { input: input, meta: { ...meta, definitionId: TASK_PRIORITY_DEFINITION_ID }, disabled: disabled, autoFocus: false, withoutCreateNew: true, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('PRIORITY')}`, allowClear: false })), [disabled, t]);
    return (React.createElement("div", { className: className },
        label && React.createElement(LabelText, null, label),
        React.createElement("div", { className: "flex-row align-items-center align-content-center mw-100" },
            React.createElement("div", { className: `${styles.fieldWrapper}` },
                React.createElement(Field, { name: "priority", component: Editor })))));
};
export default TaskPriorityField;
