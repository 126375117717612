import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, FormSection } from 'redux-form';
import Cron from '../../../../../../cms/data/components/importer/components/react-js-cron/src';
import { DEFAULT_LOCALE_EN } from '../../../../../../cms/data/components/importer/components/react-js-cron/src/locale';
import { AntInput } from '../../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import { FieldsContainerTitle } from '../../../../../../common/utils/formUtils';
import styles from './CronTriggerForm.module.scss';
const CronTriggerForm = ({ disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", null,
        React.createElement(FieldsContainerTitle, { small: true, className: "mt-0" }, t('WORKFLOWS_SETTINGS_TITLE')),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, type: "text", disabled: disabled, placeholder: t('COMMON__TITLE') }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldsContainerTitle, { small: true }, t('ACTIVE')),
            React.createElement(Field, { name: "active", component: Toggle, disabled: disabled }),
            React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
            React.createElement(Field, { name: "cronExpression", component: CronField, disabled: disabled, validate: required }))));
};
const CronField = ({ disabled, input: { onChange, value } }) => {
    return (React.createElement(Cron, { className: styles.cronField, disabled: disabled, clearButton: false, locale: DEFAULT_LOCALE_EN, value: value, setValue: onChange, clockFormat: "12-hour-clock" }));
};
export default CronTriggerForm;
