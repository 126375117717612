import { CircularLoader } from '@hypercharge/hyper-react-base/lib/common';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { MdClose } from 'react-icons/md';
const DeleteButton = ({ deletedAt, onDelete }) => {
    const { t } = useI18n();
    const [loading, setLoading] = useState(false);
    const deleteNotification = useCallback(async () => {
        try {
            setLoading(true);
            await onDelete();
        }
        finally {
            setLoading(false);
        }
    }, [onDelete]);
    return deletedAt ? (React.createElement("span", { className: "d-inline-block width20px" })) : (React.createElement(Tooltip, { className: `delete-button ${loading ? 'disabled' : ''} position-relative`, title: t('DISMISS_MENTION'), placement: "topRight" },
        loading && React.createElement(CircularLoader, null),
        React.createElement(MdClose, { onClick: deleteNotification })));
};
export default DeleteButton;
