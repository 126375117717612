import * as Ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-javascript'

import './snipped';

const { define } = Ace;

define('ace/mode/computed_expression_highlight_rules', [
  'ace/lib/oop',
  'ace/mode/javascript',
  'ace/mode/javascript_highlight_rules'
], (acequire, exports) => {
  const oop = acequire('ace/lib/oop');

  const JavaScriptHighlightRules = acequire(
    'ace/mode/javascript_highlight_rules'
  ).JavaScriptHighlightRules;

  const ComputedExpressionHighlightRules = function (options) {
    const keywordMapper = this.createKeywordMapper(
      {
        'constant.language': 'NULL',
        'constant.language.boolean': 'TRUE|FALSE'
      },
      'identifier'
    );

    const identifierRe = '[.a-zA-Z\\$_\u00a1-\uffff][a-zA-Z\\d\\$_\u00a1-\uffff]*';

    /*
      this.$rules = {
        start: [
          {
            token: 'string', // start constant string '
            regex: "'(?=.)",
            next: 'qstring'
          },
          {
            token: 'string', // start constant string "
            regex: '"(?=.)',
            next: 'qqstring'
          },
          {
            token: 'constant.numeric', // hexadecimal, octal and binary
            regex: /(?:\d\d*(?:\.\d*)?|\.\d+)(?:[eE][+-]?\d+\b)?/
          },
          {
            token: ['entity.name.function', 'paren.lparen'],
            regex: '([A-Z]+)(\\()',
            next: 'function_arguments'
          }
        ],
        qqstring: [
          {
            token: 'string',
            regex: '"|$',
            next: 'function_arguments'
          },
          {
            defaultToken: 'string'
          }
        ],
        qstring: [
          {
            token: 'string',
            regex: "'|$",
            next: 'function_arguments'
          },
          {
            defaultToken: 'string'
          }
        ],
        function_arguments: [
          {
            token: 'variable.parameter',
            regex: '[a-zA-Z]+[0-9\\[\\]\\.]*'
          },
          {
            token: 'punctuation.operator',
            regex: '[, ]+'
          },
          {
            token: 'punctuation.operator',
            regex: '$'
          },
          {
            token: 'empty',
            regex: '',
            next: 'start'
          }
        ]
      };*/
    this.$rules = {
      no_regex: [
        {
          token: 'string',
          regex: "'(?=.)",
          next: 'qstring'
        },
        {
          token: 'string',
          regex: '"(?=.)',
          next: 'qqstring'
        },
        {
          token: 'constant.numeric', // decimal integers and floats
          regex: /(?:\d\d*(?:\.\d*)?|\.\d+)(?:[eE][+-]?\d+\b)?/
        },
        {
          // TODAY()
          token: ['entity.name.function', 'paren.lparen'],
          regex: '([A-Z]+)(\\()',
          next: 'start'
        },
        {
          token: keywordMapper,
          regex: identifierRe
        },
        {
          token: 'punctuation.operator',
          regex: /[.](?![.])/,
          next: 'property'
        },
        {
          token: 'keyword.operator',
          regex: /--|\+\+|\.{3}|===|==|=|!=|!==|<+=?|>+=?|!|&&|\|\||\?:|[!$%&*+\-~/^]=?/,
          next: 'start'
        },
        {
          token: 'punctuation.operator',
          regex: /[?:,;.]/,
          next: 'start'
        },
        {
          token: 'paren.lparen',
          regex: /[[({]/,
          next: 'start'
        },
        {
          token: 'paren.rparen',
          regex: /[\])}]/
        }
      ],
      property: [
        {
          token: 'text',
          regex: '\\s+'
        },
        {
          token: 'punctuation.operator',
          regex: /[.](?![.])/
        },
        {
          token: 'identifier',
          regex: identifierRe
        },
        {
          regex: '',
          token: 'empty',
          next: 'no_regex'
        }
      ],
      // regular expressions are only allowed after certain tokens. This
      // makes sure we don't mix up regexps with the divison operator
      start: [
        {
          token: 'text',
          regex: '\\s+|^$',
          next: 'start'
        },
        {
          // immediately return to the start mode without matching
          // anything
          token: 'empty',
          regex: '',
          next: 'no_regex'
        }
      ],

      qqstring: [
        {
          token: 'string',
          regex: '\\\\$',
          consumeLineEnd: true
        },
        {
          token: 'string',
          regex: '"|$',
          next: 'no_regex'
        },
        {
          defaultToken: 'string'
        }
      ],
      qstring: [
        {
          token: 'string',
          regex: '\\\\$',
          consumeLineEnd: true
        },
        {
          token: 'string',
          regex: "'|$",
          next: 'no_regex'
        },
        {
          defaultToken: 'string'
        }
      ]
    };

    this.normalizeRules();
  };

  oop.inherits(ComputedExpressionHighlightRules, JavaScriptHighlightRules);

  exports.ComputedExpressionHighlightRules = ComputedExpressionHighlightRules; // eslint-disable-line no-param-reassign
});

define('ace/mode/computed_expression', [
  'ace/lib/oop',
  'ace/mode/javascript',
  'ace/mode/computed_expression_highlight_rules'
], (acequire, exports) => {
  const oop = acequire('ace/lib/oop');

  const ComputedExpressionHighlightRules = acequire(
    'ace/mode/computed_expression_highlight_rules'
  ).ComputedExpressionHighlightRules;

  const JavascriptMode = acequire('ace/mode/javascript').Mode;

  const MatchingBraceOutdent = acequire('./matching_brace_outdent').MatchingBraceOutdent;
  const CstyleBehaviour = acequire('ace/mode/behaviour/cstyle').CstyleBehaviour;
  const CStyleFoldMode = acequire('ace/mode/folding/cstyle').FoldMode;


  const Mode = function () {
    this.HighlightRules = ComputedExpressionHighlightRules;

    this.$outdent = new MatchingBraceOutdent();
    this.$behaviour = new CstyleBehaviour();
    this.foldingRules = new CStyleFoldMode();
  };

  oop.inherits(Mode, JavascriptMode);

  (function () {
    this.$id = 'ace/mode/computed_expression';
    this.snippetFileId = 'ace/snippets/computed_expression';
  }.call(Mode.prototype));


  exports.Mode = Mode;
});

