import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { MANAGE_FAVOURITES_PERMISSION } from '@hypercharge/portal-utils';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../auth';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../workflows';
import styles from './FeaturedButton.module.scss';
const FeaturedButton = ({ id, withFeaturedEntitiesSwitch, isFeatured, handleFeaturedChange }) => {
    const { t } = useI18n();
    const hasManageFavourites = useSelector((s) => hasTenantPermission(s, MANAGE_FAVOURITES_PERMISSION));
    if (!(![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(id) &&
        hasManageFavourites &&
        handleFeaturedChange &&
        withFeaturedEntitiesSwitch &&
        isFeatured !== undefined)) {
        return null;
    }
    return (React.createElement(Tooltip, { placement: "top", title: t('CMS__FEATURED_ENTITIES') },
        React.createElement(Button, { className: `${styles.featured} FeaturedButton safe-space`, onClick: (event) => {
                event.preventDefault();
                event.stopPropagation();
                handleFeaturedChange(id);
            } },
            isFeatured ? React.createElement(MdOutlineStar, { className: "selected" }) : React.createElement(MdOutlineStarBorder, null),
            React.createElement(MdOutlineStarBorder, { className: "icon" }))));
};
export default FeaturedButton;
