import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { Modal } from 'antd';
import accepts from 'attr-accept';
import { get, isPlainObject, keyBy } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { useHandleGetEntitiesDisplayData } from '../../cms/common/components/withEntityDisplayData';
import { isFileDisplayData } from '../../cms/data/types';
import { getFlattenedDisplayDataList } from '../../cms/data/utils';
import { usePropertyEntityItemValue } from '../../cms/hooks/usePropertyEntityItemValue';
import { useUpdateProperty } from '../../cms/hooks/useUpdateProperty';
import { getFileData } from '../storage';
const FileMovingContext = React.createContext(undefined);
export const FileMovingContextProvider = ({ children }) => {
    const { t } = useI18n();
    const [modal, contextHolder] = Modal.useModal();
    const notificationDispatch = useDispatch();
    const getFileDataSelector = useSelector((s) => (fileId) => getFileData(s, fileId));
    const { handleFetch: handleFetchDisplayData } = useHandleGetEntitiesDisplayData();
    const { getPropertyValue } = usePropertyEntityItemValue();
    const { mutateAsync: updateProperty } = useUpdateProperty({});
    const onFilesMoved = useCallback(async ({ oldIndex, newIndex, definitionIdFromList, entityIdFromList, propertyIdFromList, definitionIdToList, entityIdToList, propertyIdToList, isCarousel }) => {
        const isInternalSorting = propertyIdToList === propertyIdFromList &&
            entityIdToList === entityIdFromList &&
            definitionIdFromList === definitionIdToList;
        if (!definitionIdFromList) {
            return;
        }
        if (isInternalSorting && !isCarousel) {
            const toEntityItemPropertyValue = await getPropertyValue(definitionIdToList, entityIdToList, propertyIdToList);
            if (!Array.isArray(toEntityItemPropertyValue)) {
                return;
            }
            const newArrIds = [
                ...toEntityItemPropertyValue.map((item) => (isPlainObject(item) ? item.id : item))
            ];
            const newFiles = (Array.isArray(newArrIds) && arrayMove(newArrIds, oldIndex, newIndex)) || [];
            await updateProperty({
                definitionId: definitionIdToList,
                entityId: entityIdToList,
                propertyId: propertyIdToList,
                value: newFiles
            });
        }
        if (!isInternalSorting) {
            const requestedDefinitionIds = [definitionIdToList];
            if (definitionIdFromList !== definitionIdToList) {
                requestedDefinitionIds.push(definitionIdFromList);
            }
            const definitionIdList = await handleFetchDisplayData(requestedDefinitionIds);
            const displayDataList = keyBy(definitionIdList.displayData, 'definitionId');
            const toFieldsData = keyBy(getFlattenedDisplayDataList(displayDataList[definitionIdToList]), 'propertyId');
            const fromFieldsData = keyBy(getFlattenedDisplayDataList(displayDataList[definitionIdFromList]), 'propertyId');
            const fromPropertyFieldData = fromFieldsData[propertyIdFromList];
            const toPropertyFieldData = toFieldsData[propertyIdToList];
            if (!fromPropertyFieldData || !toPropertyFieldData || !entityIdFromList) {
                return;
            }
            const fromEntityItemPropertyValue = (await getPropertyValue(definitionIdFromList, entityIdFromList, propertyIdFromList)) ||
                [];
            const toEntityItemPropertyValue = (await getPropertyValue(definitionIdToList, entityIdToList, propertyIdToList)) || [];
            if (!Array.isArray(fromEntityItemPropertyValue) ||
                !Array.isArray(toEntityItemPropertyValue)) {
                return;
            }
            const isRequiredOldProperty = get(fromPropertyFieldData?.meta, 'validations.required');
            if (isRequiredOldProperty && fromEntityItemPropertyValue.length === 1) {
                notificationDispatch(error({
                    title: t('ERROR'),
                    message: `${propertyIdFromList}: ${t('PROPERTY_REQUIRED')}`
                }));
                return;
            }
            const fromFileIds = [
                ...fromEntityItemPropertyValue.map((item) => (isPlainObject(item) ? item.id : item))
            ];
            const toFileIds = [
                ...toEntityItemPropertyValue.map((item) => (isPlainObject(item) ? item.id : item))
            ];
            const currentItemId = fromEntityItemPropertyValue.length ? fromFileIds[oldIndex] : null;
            const currentItem = getFileDataSelector(currentItemId);
            const files = currentItem && typeof currentItem !== 'string' ? [currentItem] : null;
            const meta = toPropertyFieldData && isFileDisplayData(toPropertyFieldData)
                ? toPropertyFieldData?.meta
                : undefined;
            const acceptable = toPropertyFieldData?.canEdit &&
                fromPropertyFieldData?.canEdit &&
                meta &&
                files?.every((file) => (meta.accept ? accepts(file, meta.accept.split(/[,|]/)) : true)) &&
                (meta.list || (!meta.list && files.length === 1));
            if (!acceptable) {
                return;
            }
            if (!meta.list && !!toFileIds?.length) {
                modal.confirm({
                    icon: null,
                    okText: t('YES'),
                    cancelText: t('NO'),
                    centered: true,
                    prefixCls: 'ant-modal',
                    title: t('REPLACE_FILE_CONFIRMATION', { propertyId: propertyIdToList }),
                    onOk: async () => {
                        await Promise.allSettled([
                            updateProperty({
                                definitionId: definitionIdToList,
                                entityId: entityIdToList,
                                propertyId: propertyIdToList,
                                value: [currentItemId]
                            }),
                            updateProperty({
                                definitionId: definitionIdFromList,
                                entityId: entityIdFromList || '',
                                propertyId: propertyIdFromList,
                                value: fromFileIds.filter((_, index) => index !== oldIndex)
                            })
                        ]);
                    }
                });
                return;
            }
            if (entityIdFromList) {
                await Promise.allSettled([
                    updateProperty({
                        definitionId: definitionIdToList,
                        entityId: entityIdToList,
                        propertyId: propertyIdToList,
                        value: [...toFileIds, currentItemId]
                    }),
                    updateProperty({
                        definitionId: definitionIdFromList,
                        entityId: entityIdFromList,
                        propertyId: propertyIdFromList,
                        value: fromFileIds.filter((_, index) => index !== oldIndex)
                    })
                ]);
            }
        }
    }, [
        getFileDataSelector,
        handleFetchDisplayData,
        getPropertyValue,
        modal,
        notificationDispatch,
        t,
        updateProperty
    ]);
    const value = useMemo(() => ({
        onFilesMoved
    }), [onFilesMoved]);
    return (React.createElement(FileMovingContext.Provider, { value: value },
        React.createElement(React.Fragment, null,
            contextHolder,
            children)));
};
export const useFileMovingContext = () => {
    const context = useContext(FileMovingContext);
    if (context == null) {
        throw new Error('useFileMovingContext must be used within an FileMovingContextProvider');
    }
    return context;
};
