import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { SortOrder } from '@hypercharge/portal-utils';
import { Tooltip } from 'antd';
import { FieldArray } from 'formik';
import React, { useCallback, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import PropertyFieldInlineEditor from '../../../../../../cms/data/components/item-property/PropertyFieldInlineEditor';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { DEFAULT_FILTERS_CONDITION } from '../../../../../../views';
import { getViewSchema } from '../../../schema';
import AvailableEntities from '../AvailableEntities';
import ViewEditorDrawer from './ViewEditorDrawer';
export const getAvailableEntitiesFormSchema = () => {
    return Yup.array(Yup.object({
        groupIds: Yup.array().of(Yup.string()),
        entities: Yup.array(Yup.object({
            entityId: Yup.string().required('Required'),
            views: Yup.array(getViewSchema())
        }))
    }));
};
const AvailableEntitiesForm = ({ availableEntities, setFieldValue, disabled }) => {
    const { t } = useI18n();
    const [viewDrawerState, setViewDrawerState] = useState(null);
    const onViewOpen = useCallback((entityGroupIdx, entityIdx, viewIdx, definitionId) => {
        setViewDrawerState({
            entityGroupIdx: entityGroupIdx,
            entityIdx: entityIdx,
            viewIdx: viewIdx,
            definitionId: definitionId,
            onClose: () => setViewDrawerState(null)
        });
    }, []);
    const onViewCreate = useCallback((entityGroupIdx, entityIdx, definitionId, arrayHelpersEntitiesViews) => {
        const viewIdx = availableEntities?.[entityGroupIdx].entities[entityIdx].views.length;
        if (viewIdx == null) {
            return;
        }
        arrayHelpersEntitiesViews.push({
            entityId: generateId(),
            title: t('APPS_CUSTOMER_PORTAL__VIEW_UNNAMED'),
            filters: {
                query: {
                    condition: DEFAULT_FILTERS_CONDITION,
                    filters: []
                },
                sortBy: [{ field: 'title', order: SortOrder.asc }]
            },
            isDefault: viewIdx === 0
        });
        setViewDrawerState({
            entityGroupIdx: entityGroupIdx,
            entityIdx: entityIdx,
            viewIdx: viewIdx,
            definitionId: definitionId,
            onClose: () => setViewDrawerState(null)
        });
    }, [availableEntities, t]);
    return (React.createElement(React.Fragment, null,
        viewDrawerState && (React.createElement(ViewEditorDrawer, { ...viewDrawerState, views: availableEntities?.[viewDrawerState.entityGroupIdx].entities[viewDrawerState.entityIdx]
                .views || [], setFieldValue: setFieldValue })),
        React.createElement(FieldArray, { name: "configuration.availableEntities", render: (arrayHelpersGroupsEntitiesViews) => (React.createElement(React.Fragment, null,
                availableEntities?.map((entityGroup, entityGroupIdx) => {
                    const entityGroupPath = `${arrayHelpersGroupsEntitiesViews.name}.${entityGroupIdx}`;
                    return (React.createElement("div", { className: "d-flex flex-row align-items-top p-2", key: entityGroupIdx },
                        React.createElement("div", { className: "col-3" },
                            React.createElement("span", null,
                                React.createElement("span", null,
                                    t('GROUP__NAV_LINK'),
                                    ": "),
                                React.createElement("b", null,
                                    React.createElement(FormikField, { name: `${entityGroupPath}.groupIds`, component: PropertyFieldInlineEditor, type: "entity", meta: { definitionId: 'group', list: true }, canEdit: !disabled })))),
                        React.createElement("div", { className: "col-7" },
                            React.createElement(FieldArray, { name: `${entityGroupPath}.entities`, render: (arrayHelpersEntities) => (React.createElement(AvailableEntities, { entityGroup: entityGroup, entityGroupPath: entityGroupPath, disabled: disabled, setFieldValue: setFieldValue, entityGroupIdx: entityGroupIdx, onViewCreate: onViewCreate, onViewOpen: onViewOpen, arrayHelpersEntities: arrayHelpersEntities })) })),
                        React.createElement("div", { className: "col-1 my-auto" },
                            React.createElement(Tooltip, { title: t('APPS_CUSTOMER_PORTAL__REMOVE_GROUP_OPTION') },
                                React.createElement(Button, { className: "action-button danger", onClick: () => arrayHelpersGroupsEntitiesViews.remove(entityGroupIdx), disabled: disabled },
                                    React.createElement(FaTrash, { preserveAspectRatio: "none" }))))));
                }),
                React.createElement("div", { className: "d-flex flex-row align-items-top p-2 mt-2" },
                    React.createElement("div", { className: "col" },
                        React.createElement(Button, { className: "action-button", onClick: () => arrayHelpersGroupsEntitiesViews.push({
                                entities: [
                                    {
                                        entityId: undefined,
                                        views: []
                                    }
                                ],
                                groupIds: []
                            }), inversed: true, disabled: disabled },
                            React.createElement(FaPlus, { className: "me-2" }),
                            t('APPS_CUSTOMER_PORTAL__ADD_GROUP_OPTION')))))) })));
};
export default AvailableEntitiesForm;
