import { get, keys, pick } from 'lodash';
import queryString from 'query-string';
import { getUserNotificationsState } from '../reducers/rootSelector';
import { getParamsKey, initialListState } from './reducer';
const getNotificationsState = (s) => getUserNotificationsState(s);
const getListStateByParams = (s, params) => get(getNotificationsState(s), ['groupedLists', getParamsKey(params)], initialListState);
export const getUserNotificationsListStatus = (s, params) => pick(getListStateByParams(s, params), ['pending', 'failed', 'totalCount', 'page', 'pageSize']);
export const getUserNotificationsByPage = (s, params, page) => get(getListStateByParams(s, params), ['idsByPage', page.toString()], []).map((id) => get(getNotificationsState(s), ['byId', id]));
export const getFetchParams = (s) => keys(getNotificationsState(s).groupedLists).map((stringifiedParams) => {
    const parsedParams = pick(queryString.parse(stringifiedParams), ['showDeleted', 'showOpened']);
    return {
        ...parsedParams,
        showDeleted: parsedParams.showDeleted === 'true' ? true : false,
        showOpened: parsedParams.showOpened === 'true' ? true : false
    };
});
