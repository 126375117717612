export const AUTH_API_PATH = '/v2-auth';
export const AUTH_PATH = '/auth';
export const OAUTH2_PATH = '/oauth2';
export const GET_STARTED_BY_EMAIL_PATH = '/get-started/email';
export const GET_STARTED_CODE_PATH = '/get-started/code';
export const ACCOUNTS_PATH = '/accounts';
export const AUTH_EMAIL_PARAM = 'email';
export const AUTH_CHANNEL_EMAIL = 'EMAIL';
export const REQUIRED_JWT_PROPERTIES = [
    'tenantId',
    'tenantDefaultLanguage',
    'sub',
    'groups',
    'tenantPermissions'
];
