import { TENANT_ID } from 'config';
const basePath = '/v2-core';
export const fetchDisplayTenant = () => ({
    type: 'TENANT__FETCH_DISPLAY_TENANT',
    meta: {
        http: {
            path: `${basePath}/tenants/${TENANT_ID}/display`,
            method: 'GET'
        },
        auth: false
    }
});
export const fetchTenant = () => ({
    type: 'TENANT__FETCH_TENANT',
    meta: {
        http: {
            path: `${basePath}/tenants/me`,
            method: 'GET'
        },
        auth: true
    }
});
export const updateTenant = (tenant) => ({
    type: 'TENANT__UPDATE_TENANT',
    payload: tenant,
    meta: {
        http: {
            path: `${basePath}/tenant`,
            method: 'PUT',
            allowPromiseReject: true
        },
        auth: true
    }
});
