import React, { useCallback } from 'react';
import { useUpdateFileProperty } from '../../../../hooks/useUpdateFileProperty';
import { useUpdateProperty } from '../../../../hooks/useUpdateProperty';
import { TableFields } from '../../item-property/TableFields';
import styles from './HighlightedPropertiesList.module.scss';
const HighlightedPropertiesList = ({ definitionId, entityId, highlightedProperties }) => {
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const { mutateAsync: updateFileProperty } = useUpdateFileProperty({
        definitionId
    });
    const updatePropertyData = useCallback(async (propertyId, value) => await updateProperty({
        entityId,
        propertyId,
        value
    }), [entityId, updateProperty]);
    const uploadFilesHandler = useCallback(async (propertyId, files) => await updateFileProperty({
        entityId,
        files,
        propertyId
    }), [entityId, updateFileProperty]);
    if (!highlightedProperties) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.wrapper} px-3 pt-1 pb-3` },
        React.createElement(TableFields, { doubleCol: false, fieldsData: highlightedProperties, updatePropertyData: updatePropertyData, canEdit: true, uploadFiles: uploadFilesHandler, definitionId: definitionId, entityId: entityId, fileCarouselHeight: 500 })));
};
export default HighlightedPropertiesList;
