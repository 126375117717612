import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { TITLE_PROPERTY_ID } from '../../../../../cms';
import { useEntityDisplayData } from '../../../../../cms/common/components/withEntityDisplayData';
import { getPropertyById } from '../../../../../cms/common/utils/utils';
import { PROCESS_STATUS_DEFINITION_ID, TASK_STATUS_DEFINITION_ID } from '../../../../../cms/constants';
import { useEntityItems } from '../../../../../cms/hooks/useEntityItems';
import styles from './ProcessStatusBanner.module.scss';
const ProcessStatusBanner = ({ status, className }) => {
    const { t, language } = useI18n();
    const { displayData: processStatusDisplayData } = useEntityDisplayData(PROCESS_STATUS_DEFINITION_ID);
    const titleProcessStatusDisplayData = getPropertyById(processStatusDisplayData, TITLE_PROPERTY_ID);
    const titleProcessStatusTranslationKey = titleProcessStatusDisplayData?.meta?.translations?.[language];
    const responseFieldsTaskStatus = useMemo(() => {
        const responseFields = [];
        if (titleProcessStatusTranslationKey) {
            responseFields.push(titleProcessStatusTranslationKey);
        }
        return responseFields;
    }, [titleProcessStatusTranslationKey]);
    const { data } = useEntityItems({
        definitionId: TASK_STATUS_DEFINITION_ID,
        ids: [status],
        extraPayloadProps: {
            responseFields: responseFieldsTaskStatus
        },
        enabled: !!titleProcessStatusTranslationKey
    });
    const icon = useMemo(() => {
        switch (status) {
            case 'SUCCEEDED':
                return 'check-circle';
            case 'RUNNING':
                return 'play-circle';
            case 'FAILED':
                return 'exclamation-circle';
            case 'CANCELLED':
                return 'times-circle';
        }
    }, [status]);
    const dataProcessStatus = useMemo(() => {
        return data?.find((item) => item.entityId === status);
    }, [data, status]);
    return (React.createElement("div", { className: `${className || ''} ${styles.statusWrapper} ${status || ''}` },
        icon && React.createElement(FontAwesomeIcon, { icon: icon }),
        status && (React.createElement("div", { className: styles.text }, (titleProcessStatusTranslationKey &&
            dataProcessStatus?.[titleProcessStatusTranslationKey]) ||
            t(status)))));
};
export default ProcessStatusBanner;
