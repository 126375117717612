import { BasicTextArea } from '@hypercharge/hyper-react-base/lib/form';
import { parse, stringify } from 'json5';
import React, { useState } from 'react';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './JsonEditor.module.scss';
const JsonEditor = ({ value, onChange, disabled, onKeyDown, autoFocus = true, editing = true, errorMessage }) => {
    const [text, setText] = useState(value != null ? stringify(value, null, 4) : '');
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(BasicTextArea
            // @ts-expect-error
            , { 
                // @ts-expect-error
                input: { value: text }, autoFocus: autoFocus, onChange: (e) => {
                    const target = e.target;
                    setText(target.value);
                    try {
                        onChange && onChange(target.value === '' ? null : parse(target.value));
                    }
                    catch (e) {
                        // this is a bit hackish, meaning that what we effectively save is the last valid json,
                        // which might not correspond to the latest change.
                        // Since this is not supposed to be used that often, especially by muggles, I think this
                        // is an acceptable first version that didn't require much effort.
                    }
                }, disabled: disabled, onKeyDown: (e) => {
                    if (onKeyDown && e.key !== 'Enter') {
                        onKeyDown(e);
                    }
                }, rows: text.split('\n').length })),
        React.createElement(FieldError, { error: errorMessage })));
};
export default JsonEditor;
