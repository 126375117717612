import { validate as isEmail } from 'email-validator';
import { get, includes, isEmpty, some } from 'lodash';
import { t } from '../../i18n';
const isNullOrEmpty = (value) => Array.isArray(value) ? isEmpty(value) : value == null || value === '';
const createGetFirstError = (rules) => (value, data) => rules
    .map((rule) => rule(value, data)) // construct an array of the validation outputs (undefined or string)
    .filter((error) => !!error)[0]; // filter out the ones that gave undefined and select the first error. If the array was empty, this returns undefined.
export function email(value) {
    if (!isNullOrEmpty(value) && !isEmail(value)) {
        return t(`VALIDATIONS__EMAIL`);
    }
    return undefined;
}
export function phone(value) {
    if (!isNullOrEmpty(value)) {
        const isPhoneNumber = /^\d+$/.test(value.substring(1));
        if (typeof value !== 'string' || !value.startsWith('+')) {
            return t('VALIDATIONS__PHONE_COUNTRY_CODE');
        }
        if (!isPhoneNumber) {
            // TODO: improve
            return t('VALIDATIONS__PHONE_NUMBERS');
        }
        return undefined;
    }
    return undefined;
}
export const atLeastOneRequired = (fieldNames) => (value, data) => some(fieldNames, (name) => !isNullOrEmpty(data[name]))
    ? undefined
    : t('VALIDATIONS__AT_LEAST_ONE', { fields: fieldNames.join(' or ') });
const urlPattern = /^((http|https):\/\/)/;
export function website(value) {
    if (!isNullOrEmpty(value) && !urlPattern.test(value)) {
        return t('VALIDATIONS__URL');
    }
    return undefined;
}
export const required = (value) => isNullOrEmpty(value) ? t('VALIDATIONS__REQUIRED') : undefined;
export function integer(value) {
    if (!isNullOrEmpty(value) &&
        ((typeof value === 'number' && !Number.isInteger(value)) ||
            (typeof value === 'string' && !Number.isInteger(Number(value))) ||
            !includes(['number', 'string'], typeof value))) {
        return t('VALIDATIONS__NUMBER');
    }
    return undefined;
}
export const minLength = (min) => (value) => {
    if (typeof value === 'string' && value.length < min) {
        return t('VALIDATIONS__MIN_LENGTH', { min });
    }
    return undefined;
};
export const maxLength = (max) => (value) => {
    if (typeof value === 'string' && value.length > max) {
        return t('VALIDATIONS__MAX_LENGTH', { max });
    }
    return undefined;
};
export function createValidator(rules) {
    return (data = {}) => {
        const errors = {};
        Object.keys(rules).forEach((key) => {
            const getFirstError = createGetFirstError([].concat(rules[key])); // the concat 'flattens' the rules. [].concat(f1) -> [f1] or [].concat([f1, f2]) -> [f1, f2]
            const error = getFirstError(get(data, key), data); // first error message for the field or undefined
            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
}
export default {
    email,
    phone,
    atLeastOneRequired,
    website,
    required,
    integer,
    minLength,
    maxLength,
    createValidator
};
