import { LabelText } from '@hypercharge/hyper-react-base/lib/form/components/utils';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { media } from '../../../utils/styledComponentsUtils';
export const Label = styled(LabelText) `
  padding-top: 1rem;
`;
export const StyledExternalLinkAlt = styled(FaExternalLinkAlt) `
  color: var(--primaryColor-6);
  font-size: 0.8rem;
`;
export const Text = styled.div `
  font-size: 0.8rem;
  padding-bottom: 1rem;
  color: var(--grayscaleColor-8);

  ${media.sm} {
    font-size: 0.9rem;
  }
`;
export const GroupName = styled(Text) `
  font-weight: 600;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;

  ${media.sm} {
    font-weight: inherit;
  }
`;
export const SwitchContainer = styled.div `
  justify-content: center !important;

  ${media.sm} {
    justify-content: start !important;
  }
`;
