import { createReducer } from '../utils';
import { getLanguageFromStorage, setLanguageToStorage } from './i18nUtils';
export const i18nReducerFactory = (defaultLang, availableLanguages) => {
    const languageFromStorage = getLanguageFromStorage();
    const initialState = {
        currentLanguage: languageFromStorage || defaultLang,
        availableLanguages: availableLanguages,
        countryCode: undefined,
        manuallyChanged: !!languageFromStorage
    };
    return createReducer(initialState, {
        I18N__CHANGE_LANGUAGE: (s, a) => {
            setLanguageToStorage(a.payload.language);
            s.currentLanguage = a.payload.language;
            s.manuallyChanged = true;
        },
        I18N__CHANGE_LANGUAGE_BY_SYSTEM: (s, a) => {
            if (!s.manuallyChanged) {
                s.currentLanguage = getLanguageFromStorage() || a.payload.language;
            }
        },
        I18N__CHANGE_AVAILABLE_LANGUAGES: (s, a) => {
            s.availableLanguages = a.payload.languages;
        },
        I18N__RETRIEVE_COUNTRY_CODE_SUCCESS: (s, a) => {
            s.countryCode = a.payload['country'];
        }
    });
};
// Selectors
export const getCurrentLanguage = (s) => s.currentLanguage;
export const getAvailableLanguages = (s) => s.availableLanguages;
export const getCountryCode = (s) => s.countryCode;
export const getManuallyChanged = (s) => s.manuallyChanged;
