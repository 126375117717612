import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button, Tooltip, Tree } from 'antd';
import cn from 'classnames';
import { isEmpty, map } from 'lodash';
import React from 'react';
import { FaAngleDown, FaPlus } from 'react-icons/fa';
import { MdChevronLeft } from 'react-icons/md';
import { ENTITY_ID_PROPERTY_ID, QuickAddItemButton } from '../../../cms';
import CollapsibleSearchInput from '../../../common/components/side-menu/CollapsibleSearchInput';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { Content } from '../../../common/utils/stylingUtils';
import { GROUP_CMS_DEFINITION_ID } from '../../constants';
import { CollapseExpand } from './CollapseExpand';
import styles from './Hierarchy.module.scss';
export const Hierarchy = ({ className = '', contentHeight, isCollapsed, onCollapseChange, canCreateAndDeleteGroup, entityTitle, saving, handleSelectGroup, expandedKeys, setExpandedKeys, searchText, parentGroupMap, setSearchText, selectedKeys, onExpand, filteredGroups, parentGroupIds, treeData, getTree, toggleMobileDrawer }) => {
    const { t } = useI18n();
    const { isDesktop } = useBrowserBreakpoints();
    const isCollapsedAvailable = isDesktop && isCollapsed;
    const toggleCollapseState = () => {
        if (onCollapseChange) {
            onCollapseChange(!isCollapsed);
        }
    };
    return (React.createElement("div", { className: cn(className, styles.container, 'pe-3 pt-1 bg-transparent overflow-hidden', {
            collapsed: isCollapsedAvailable,
            'border-0 m-0': !isDesktop
        }) },
        isDesktop && (React.createElement("div", { className: cn(styles.container__sectionCardHeading, 'SectionCardHeading') },
            React.createElement(Button, { className: cn(styles.container__sectionCardHeadingIcon, {
                    'm-0': isCollapsedAvailable
                }), onClick: toggleCollapseState },
                React.createElement(MdChevronLeft, { size: 16, className: cn({ CollasedIcon: isCollapsedAvailable }) })),
            React.createElement("div", { className: cn(styles.container__sectionCardHeadingText, 'SectionCardHeadingText') }, t('GROUP__NAV_LINK')),
            React.createElement(CollapseExpand, { expandedKeys: expandedKeys, setExpandedKeys: setExpandedKeys, searchText: searchText, parentGroupMap: parentGroupMap }),
            canCreateAndDeleteGroup && (React.createElement(QuickAddItemButton, { definitionId: GROUP_CMS_DEFINITION_ID, entityTitle: entityTitle, loading: saving, onClick: (groupId) => {
                    handleSelectGroup([groupId]);
                }, customButtonComponent: ({ onClick }) => (React.createElement(Tooltip, { placement: "top", title: t('ADD_CONDITION_GROUP') },
                    React.createElement(Button, { onClick: onClick, className: cn(styles.container__add, {
                            [styles.container__addCollapsed]: isCollapsed
                        }) },
                        React.createElement(FaPlus, null),
                        !isCollapsed && t('NEW')))) })))),
        React.createElement(CollapsibleSearchInput, { value: searchText, onChange: setSearchText, collapsed: isCollapsedAvailable, onCollapseToggle: toggleCollapseState }),
        React.createElement(Content, { className: cn(styles.container__content, 'ContentOuter custom-scroll-styling'), height: contentHeight }, !isEmpty(treeData) && (React.createElement(Tree, { selectedKeys: selectedKeys, onSelect: (selected) => {
                handleSelectGroup(selected);
                toggleMobileDrawer?.();
            }, onExpand: (selected) => onExpand(selected), expandedKeys: searchText
                ? map(filteredGroups, ENTITY_ID_PROPERTY_ID).filter((id) => parentGroupIds.includes(id))
                : expandedKeys, treeData: treeData.map(getTree), switcherIcon: React.createElement(FaAngleDown, null), className: styles.tree })))));
};
