import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { Typography } from 'antd';
import { groupBy, isNumber } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { MY_USER_COMPUTED } from '../../../../../cms/common/utils/constants';
import { ItemsViewKanban } from '../../../../../cms/data/components/items/ItemsView';
import useDisplayItemMetaList from '../../../../../cms/hooks/useDisplayItemMetaList';
import { useMyContact } from '../../../../../crm/components/use-my-contact';
import { useView } from '../../../../../views/components/ViewContext';
import { getFromEndWeekToEndMonthFilter, getFromNowToEndDayFilter, getFromTomorrowToEndWeekFilter, getLaterEndMonthFilter, getLessThenNowFilter } from '../../../constants';
import { TasksFilterType } from '../../../types';
import { TasksDashboardDeadlineColumns, tasksDashboardDeadlineColumns } from '../../../utils';
import { useTaskDashboard } from '../TaskDashboardContext/TaskDashboardProvider';
const TasksKanban = ({ baseUrl, getCustomRouteHandleCardClick }) => {
    const { t } = useI18n();
    const { kanban, view, definitionId } = useView();
    const { contactId } = useMyContact();
    const { mapProperty } = useTaskDashboard();
    const { data: displayItemMetaList } = useDisplayItemMetaList();
    const customColumnsTitles = useMemo(() => {
        if (kanban?.groupBy === mapProperty[TasksFilterType.relatedTo]) {
            return displayItemMetaList.reduce((acc, displayItemMeta) => ({
                ...acc,
                [displayItemMeta.definitionId]: displayItemMeta.title
            }), {});
        }
        if (kanban?.groupBy === mapProperty[TasksFilterType.assignees]) {
            return {
                [FilterOperatorTypes.empty]: t('NOT_ASSIGNED'),
                [MY_USER_COMPUTED]: t('ASSIGN_TO_ME')
            };
        }
        if (kanban?.groupBy === mapProperty[TasksFilterType.deadline]) {
            return {
                ...tasksDashboardDeadlineColumns.reduce((acc, column) => ({ ...acc, [column]: t(column) }), {}),
                [FilterOperatorTypes.empty]: t('NO_DEADLINE')
            };
        }
    }, [kanban?.groupBy, mapProperty, displayItemMetaList, t]);
    const getItemsGroupBy = useCallback((items) => {
        if (kanban?.groupBy === mapProperty[TasksFilterType.assignees]) {
            const groupedItems = groupBy(items, (item) => {
                if (kanban?.columns.includes(MY_USER_COMPUTED) &&
                    item[mapProperty[TasksFilterType.assignees]] === contactId) {
                    return MY_USER_COMPUTED;
                }
                return item[mapProperty[TasksFilterType.assignees]];
            });
            if (Object.keys(groupedItems).includes(MY_USER_COMPUTED) && contactId) {
                return {
                    ...groupedItems,
                    [contactId]: groupedItems[MY_USER_COMPUTED]
                };
            }
            return groupedItems;
        }
        if (kanban?.groupBy === mapProperty[TasksFilterType.deadline]) {
            const currentDate = moment();
            // Define the categories
            const categories = {
                OVERDUE: [],
                TODAY: [],
                THIS_WEEK: [],
                THIS_MONTH: [],
                LATER: [],
                EMPTY: []
            };
            return items.reduce((acc, item) => {
                if (isNumber(item[mapProperty[TasksFilterType.deadline]])) {
                    const taskDeadline = moment(item[mapProperty[TasksFilterType.deadline]]);
                    if (taskDeadline.isBefore(currentDate)) {
                        return { ...acc, OVERDUE: [...acc.OVERDUE, item] };
                    }
                    if (taskDeadline.isSame(currentDate, 'day')) {
                        return { ...acc, TODAY: [...acc.TODAY, item] };
                    }
                    if (taskDeadline.isBetween(currentDate, currentDate.clone().endOf('isoWeek'), 'day', '[]')) {
                        return { ...acc, THIS_WEEK: [...acc.THIS_WEEK, item] };
                    }
                    if (taskDeadline.isBetween(currentDate, currentDate.clone().endOf('month'), 'day', '[]')) {
                        return { ...acc, THIS_MONTH: [...acc.THIS_MONTH, item] };
                    }
                    return { ...acc, LATER: [...acc.LATER, item] };
                }
                return { ...acc, EMPTY: [...acc.EMPTY, item] };
            }, categories);
        }
    }, [contactId, kanban?.columns, kanban?.groupBy, mapProperty]);
    const getCustomColumnCountFilters = useCallback((columnId) => {
        if (kanban?.groupBy === mapProperty[TasksFilterType.deadline]) {
            switch (columnId) {
                case TasksDashboardDeadlineColumns.overdue:
                    return [getLessThenNowFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.today:
                    return [getFromNowToEndDayFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.thisWeek:
                    return [getFromTomorrowToEndWeekFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.thisMonth:
                    return [getFromEndWeekToEndMonthFilter(mapProperty[TasksFilterType.deadline])];
                case TasksDashboardDeadlineColumns.later:
                    return [getLaterEndMonthFilter(mapProperty[TasksFilterType.deadline])];
            }
        }
    }, [kanban?.groupBy, mapProperty]);
    if (!definitionId) {
        return React.createElement(Typography.Text, { type: "danger" }, t('SOMETHING_WENT_WRONG'));
    }
    return (React.createElement("div", { className: "pb-2 h-100" },
        React.createElement(ItemsViewKanban, { getCustomItemsGroupBy: getItemsGroupBy, customColumnsTitles: customColumnsTitles, getCustomColumnCountFilters: getCustomColumnCountFilters, getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, noFilters: true, noSearch: true, view: view, noViewActions: true, baseUrl: baseUrl, definitionId: definitionId })));
};
export default TasksKanban;
