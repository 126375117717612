import React, { useCallback } from 'react';
import { FaTasks } from 'react-icons/fa';
import { IoMdGitNetwork } from 'react-icons/io';
import { ACTIVITY_DEFINITION_ID } from '../../../../../../common/activity';
import { BROWSE_PATH, VIEW_PATH } from '../../../../../../common/utils/constants';
import { isCmsDefinition, isWorkflowDefinition } from '../../../../../../common/utils/url';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../../../workflows';
import { TASK_PROCESS_LINK_PROPERTY_ID } from '../../../../../../workflows/common/utils/constants';
import useDisplayItemMeta from '../../../../../hooks/useDisplayItemMeta';
import styles from './KanbanCard.module.scss';
import { CardFields, CardHeader } from './components';
const KanbanCard = ({ baseUrl, card, definitionId, fields, provided: { innerRef, draggableProps, dragHandleProps }, viewId, getCustomRouteHandleCardClick }) => {
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const isProcess = isWorkflowDefinition(displayItemMeta);
    const isTask = isProcess && card.definitionId?.indexOf('task__') !== -1;
    const isActivityTask = card.definitionId === ACTIVITY_DEFINITION_ID;
    const handleCardClick = useCallback(() => {
        let to = '';
        const customTo = getCustomRouteHandleCardClick?.(card);
        if (customTo) {
            to = customTo;
        }
        else if (isCmsDefinition(displayItemMeta)) {
            to = `${baseUrl}${BROWSE_PATH}/${card.entityId}`;
        }
        else if (isProcess) {
            const processRunId = card.entityId;
            to = viewId
                ? `${baseUrl}${VIEW_PATH}/${viewId}/${processRunId}`
                : `${baseUrl}${BROWSE_PATH}/${processRunId}`;
            if (definitionId === ALL_PROCESSES_DEFINITION_ID) {
                const updatedBaseUrl = baseUrl.replace(ALL_PROCESSES_DEFINITION_ID, card.definitionId || '');
                to = viewId
                    ? `${updatedBaseUrl}${VIEW_PATH}/${viewId}/${processRunId}`
                    : `${updatedBaseUrl}${BROWSE_PATH}/${processRunId}`;
            }
            else if (definitionId === ALL_TASKS_DEFINITION_ID) {
                const taskLink = card[TASK_PROCESS_LINK_PROPERTY_ID];
                if (taskLink) {
                    to = taskLink.link;
                }
            }
        }
        if (to) {
            window.open(to, '_blank')?.focus();
        }
    }, [
        baseUrl,
        card,
        definitionId,
        displayItemMeta,
        getCustomRouteHandleCardClick,
        isProcess,
        viewId
    ]);
    return (React.createElement("div", { ...draggableProps, ...dragHandleProps, className: `${styles.card} ${!dragHandleProps ? styles.card_disabledDrag : ''} d-flex flex-column align-items-stretch cursor-pointer overflow-hidden`, onClick: handleCardClick, ref: innerRef },
        React.createElement("div", { className: `${(isTask && 'isTask') || ''} ${(isActivityTask && 'isActivityTask') || ''} position-relative overflow-hidden` },
            React.createElement("div", { className: `${styles.card_header} d-flex justify-content-start align-items-center fw-500` }, card.title || card.entityId),
            React.createElement("div", { className: `${styles.card_section} d-flex flex-column align-items-stretch` },
                React.createElement(CardHeader, { card: card, isProcess: isProcess }),
                React.createElement(CardFields, { card: card, fields: fields })),
            isTask && (React.createElement("div", { className: "position-absolute end-0 bottom-0 d-flex align-items-center justify-content-center icon-kanban-card workflows-task" },
                React.createElement(IoMdGitNetwork, null))),
            isActivityTask && (React.createElement("div", { className: "position-absolute end-0 bottom-0 d-flex align-items-center justify-content-center icon-kanban-card activity-task" },
                React.createElement(FaTasks, null))))));
};
export default KanbanCard;
