import { omit, pick } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
const isExternalLink = (to) => /^https?:\/\//.test(to);
export const VersatileNavLink = ({ to, children, ...props }) => {
    if (!to) {
        return (React.createElement("div", { ...pick(props, ['className', 'onClick']) },
            React.createElement(React.Fragment, null, children)));
    }
    if (isExternalLink(to)) {
        return (React.createElement("a", { href: to, target: "_blank", rel: "noopener noreferrer", ...omit(props, ['exact']) },
            React.createElement(React.Fragment, null, children)));
    }
    return (React.createElement(NavLink, { to: to, ...props }, children));
};
