import React, { useMemo } from 'react';
import { Drawer, DrawerContent } from '../../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../../common/components/Drawer/DrawerContentBlock';
import { useEntityDisplayData } from '../../../../../common/components/withEntityDisplayData';
import { TITLE_PROPERTY_ID } from '../../../../../common/utils/constants';
import SectionListPreview from './SectionListPreview';
import TabsPreview from './TabsPreview';
const ItemQuickPreview = ({ entity, definitionId, onClose, open }) => {
    const { displayData: definition } = useEntityDisplayData(definitionId);
    const sections = useMemo(() => {
        return definition?.data
            ? definition?.data
                .filter((section) => section.sectionId !== 'system')
                .sort((a) => (a.pinned ? -1 : 0))
            : [];
    }, [definition]);
    return (React.createElement(Drawer, { title: React.createElement("div", null, entity?.[TITLE_PROPERTY_ID]), onClose: onClose, open: open, destroyOnClose: true }, entity && (React.createElement(DrawerContent, null,
        React.createElement(DrawerContentBlock, null, definition && definition?.tabs && definition?.tabs.length > 1 ? (React.createElement(TabsPreview, { definitionId: definitionId, entity: entity, sections: sections, tabs: definition.tabs })) : (React.createElement(SectionListPreview, { definitionId: definitionId, sections: sections, entity: entity })))))));
};
export default ItemQuickPreview;
