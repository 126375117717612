import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { InputContainer, LabelText } from '@hypercharge/hyper-react-base/lib/form';
import React, { useState } from 'react';
import { IoImage } from 'react-icons/io5';
import styled from 'styled-components';
import { getDataTransferItems } from '../../storage';
const FileInput = ({ label, subTitle, buttonTitle, value, disabled, loading, uploadFn, onChange, canRemove = false, removeButtonTitle }) => {
    let uploadInput = null;
    const [uploading, setUploading] = useState(false);
    const clickUploadInput = (e) => {
        e.preventDefault();
        if (uploadInput !== null) {
            uploadInput.click();
        }
    };
    const clickRemoveInput = (e) => {
        e.preventDefault();
        onChange(null);
    };
    const handleLogoUpload = (e) => {
        e.preventDefault();
        const files = getDataTransferItems(e);
        if (files.length > 0) {
            setUploading(true);
            uploadFn(files[0]).then((file) => {
                onChange(file);
                setUploading(false);
            }, (_) => {
                setUploading(false);
            });
        }
    };
    return (React.createElement(InputContainer, null,
        label && React.createElement(LabelText, null, label),
        subTitle && React.createElement(LabelText, null, subTitle),
        React.createElement("div", { className: "d-flex justify-content-start flex-column flex-sm-row" },
            React.createElement(PreviewContainer, { className: "cp-i-none cp-c-column cp-c-align-center-center" }, loading ? (React.createElement(Skeleton, { isLoading: true },
                React.createElement(TextPlaceholder, { widthInPx: 100, heightInPx: 100, radiusInPx: 4 }))) : value && value.downloadUrl ? (React.createElement(PreviewImage, { src: value.downloadUrl, alt: "logo" })) : (React.createElement(DefaultImage, null))),
            React.createElement("div", { className: "d-flex flex-column flex-sm-row flex-wrap align-items-start px-sm-3 mt-2 mt-sm-0 gap-2" },
                React.createElement(Button, { disabled: uploading || disabled, onClick: clickUploadInput, loading: uploading },
                    buttonTitle,
                    React.createElement("input", { style: { display: 'none' }, type: "file", accept: "image/*", tabIndex: -1, onChange: handleLogoUpload, multiple: false, disabled: uploading || disabled, ref: (node) => (uploadInput = node) })),
                canRemove && value && (React.createElement(RemoveButton, { disabled: uploading || disabled, onClick: clickRemoveInput }, removeButtonTitle))))));
};
const RemoveButton = styled((props) => React.createElement(Button, { ...props })) `
  background-color: var(--errorColor-5);
  border-color: var(--errorColor-5);

  &:hover {
    background-color: var(--errorColor-4);
    border-color: var(--errorColor-4);
  }
`;
const PreviewImage = styled.img `
  max-width: 100%;
  max-height: 100%;
`;
const PreviewContainer = styled.div `
  width: 8rem;
  height: 8rem;
  border: 1px solid var(--grayscaleColor-6);
`;
const DefaultImage = styled(IoImage) `
  font-size: 2.5rem;
`;
export default FileInput;
