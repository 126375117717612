import { fetchUserNotifications } from '../actions';
import { getFetchParams, getUserNotificationsListStatus } from '../selectors';
const module = {
    processMessage: (store, message) => {
        const state = store.getState();
        switch (message.type) {
            case 'UPDATED_NOTIFICATIONS': {
                const listParams = getFetchParams(state); // Note that lists that are potentially no longer displayed will still be fetched
                listParams.forEach((params) => {
                    const { page, pageSize } = getUserNotificationsListStatus(state, params);
                    store.dispatch(fetchUserNotifications(page, pageSize, params));
                });
                break;
            }
        }
        return true;
    }
};
export default module;
