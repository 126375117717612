import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { uploadFiles } from '../../common/storage';
import { useUpdateProperty } from './useUpdateProperty';
const defaultMutationKey = 'UpdateFileProperty';
export const useUpdateFileProperty = ({ definitionId, mutationKey = defaultMutationKey }) => {
    const { mutateAsync: updateProperty } = useUpdateProperty({
        definitionId
    });
    const { t } = useI18n();
    const dispatch = useDispatch();
    const notificationDispatch = useDispatch();
    return useMutation([mutationKey], async ({ entityId, propertyId, files }) => {
        const uploadedData = await uploadFiles(dispatch, files, entityId);
        await updateProperty({
            entityId,
            propertyId,
            value: uploadedData.map((f) => f.id)
        });
    }, {
        onError: () => {
            notificationDispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    });
};
