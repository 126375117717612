import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { PropertyTypes } from '../../../common/types';
import queryClient, { oneHourInMs } from '../../../common/utils/react-query-client';
import { getFlattenedDisplayDataList } from '../../data/utils';
import { CMS_API_PATH } from '../utils/constants';
export const entityDisplayDataQueryKey = 'entity-display-data';
export const entitiesDisplayDataQueryKey = 'entities-display-data';
export const invalidateEntityDisplayDataQueries = (definitionId) => {
    void queryClient.invalidateQueries({
        predicate: ({ queryKey }) => (queryKey[0] === entityDisplayDataQueryKey && queryKey[1] === definitionId) ||
            (queryKey[0] === entitiesDisplayDataQueryKey &&
                Array.isArray(queryKey[1]) &&
                queryKey[1].includes(definitionId))
    });
};
const filterHiddenProperty = (data) => data?.map((item) => ({
    ...item,
    data: item.data.reduce((sections, section) => [
        ...sections,
        {
            ...section,
            values: section.values.filter(({ meta = {} }) => !meta.hidden)
        }
    ], [])
}));
const fetchQueryFn = async (definitionId, dispatch) => {
    if (!definitionId.length) {
        return Promise.resolve([]);
    }
    const result = await dispatch(fetchEntitiesDisplayData(definitionId));
    return result.filter((displayDataList) => !!displayDataList);
};
export const useEntityDisplayData = (definitionId) => {
    const dispatchFetchEntityDisplayData = useDispatch();
    const { data, isError, isFetching } = useQuery([entityDisplayDataQueryKey, definitionId], () => {
        if (!definitionId) {
            return Promise.resolve(undefined);
        }
        return dispatchFetchEntityDisplayData(fetchEntityDisplayData(definitionId));
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    return useMemo(() => ({
        displayData: data && {
            ...data,
            // Filter out the hidden fields
            data: data.data.reduce((sections, section) => [
                ...sections,
                {
                    ...section,
                    values: section.values.filter(({ meta = {} }) => !meta.hidden)
                }
            ], [])
        },
        displayDataWithHidden: data,
        displayDataStatus: {
            isAvailable: !!data,
            isPending: isFetching,
            isFailed: isError
        }
    }), [data, isError, isFetching]);
};
export const useEntitiesDisplayData = (definitionId) => {
    const dispatchFetchEntitiesDisplayData = useDispatch();
    const { data, isError, isFetching } = useQuery([entitiesDisplayDataQueryKey, definitionId.sort()], async () => fetchQueryFn(definitionId, dispatchFetchEntitiesDisplayData), {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    return useMemo(() => {
        return {
            displayData: filterHiddenProperty(data),
            displayDataWithHidden: data,
            displayDataStatus: {
                isAvailable: !!data,
                isPending: isFetching,
                isFailed: isError
            }
        };
    }, [data, isError, isFetching]);
};
export const useHandleGetEntitiesDisplayData = () => {
    const dispatchFetchEntitiesDisplayData = useDispatch();
    const handleFetch = async (definitionId) => {
        const data = await queryClient.fetchQuery({
            queryKey: [entitiesDisplayDataQueryKey, definitionId.sort()],
            queryFn: async () => fetchQueryFn(definitionId, dispatchFetchEntitiesDisplayData),
            cacheTime: oneHourInMs,
            staleTime: oneHourInMs
        });
        return {
            displayData: filterHiddenProperty(data),
            displayDataWithHidden: data
        };
    };
    return {
        handleFetch
    };
};
export const useNestedDisplayData = (displayData) => {
    const nestedDefinitionIds = useMemo(() => {
        if (!displayData) {
            return [];
        }
        const flattenedDisplayDataProperties = getFlattenedDisplayDataList(displayData);
        const entityProperties = flattenedDisplayDataProperties.reduce((acc, property) => {
            if (property.type === PropertyTypes.entity) {
                acc.push(property.meta.definitionId);
            }
            return acc;
        }, []);
        return uniq(entityProperties);
    }, [displayData]);
    return useEntitiesDisplayData(nestedDefinitionIds);
};
export const fetchEntityDisplayData = (definitionId) => ({
    type: 'ENTITY_DISPLAY_DATA_FETCH',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const fetchEntitiesDisplayData = (definitionIds) => ({
    type: 'ENTITIES_DISPLAY_DATA_FETCH',
    payload: { definitionIds },
    meta: {
        http: {
            path: `${CMS_API_PATH}/definitions/data`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
