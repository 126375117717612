import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Table } from 'antd';
import { Field } from 'formik';
import React, { Fragment, useState } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import styled from 'styled-components';
import * as Yup from 'yup';
import { FieldLabel } from '../../../../cms/data/components/item-property/FieldElements';
import Toggle from '../../../../common/components/Toggle';
import FormikField from '../../../../common/components/formik/FormikField';
export const CronosInitialValues = {
    enabled: false,
    makeSettings: [
        {
            make: '',
            username: '',
            password: ''
        }
    ],
    notificationEmail: ''
};
export const getCronosFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.bool(),
        makeSettings: Yup.array()
            .of(Yup.object({
            make: Yup.string().when('enabled', {
                is: true,
                then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
            }),
            username: Yup.string().when('enabled', {
                is: true,
                then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
            }),
            password: Yup.string().when('enabled', {
                is: true,
                then: Yup.string().required(t('VALIDATIONS__REQUIRED'))
            })
        }))
            .when('enabled', {
            is: true,
            then: Yup.array().required(t('VALIDATIONS__REQUIRED'))
        }),
        notificationEmail: Yup.string()
    });
};
const newRow = { make: '', username: '', password: '' };
const Form = ({ instance, disabled }) => {
    const { t } = useI18n();
    const [showPassword, setShowPassword] = useState({});
    const tableData = (instance.configuration.import?.cronos?.makeSettings &&
        instance.configuration.import.cronos.makeSettings.length > 0
        ? instance.configuration.import.cronos.makeSettings
        : [newRow]).map(({ make, username, password }, index) => {
        return {
            key: index,
            make: (React.createElement(FormikField, { name: `configuration.import.cronos.makeSettings[${index}].make`, component: BasicInput, value: make, disabled: disabled || !instance.configuration?.import?.cronos?.enabled })),
            username: (React.createElement(FormikField, { name: `configuration.import.cronos.makeSettings[${index}].username`, component: BasicInput, value: username, disabled: disabled || !instance.configuration?.import?.cronos?.enabled })),
            password: (React.createElement(FormikField, { name: `configuration.import.cronos.makeSettings[${index}].password`, component: BasicInput, value: password, disabled: disabled || !instance.configuration?.import?.cronos?.enabled, type: showPassword[index] ? 'text' : 'password' })),
            add: (React.createElement("div", { className: "d-flex justify-content-center" },
                React.createElement("div", { className: "px-3 py-1 text-center cursor-pointer", onClick: () => setShowPassword({
                        ...showPassword,
                        [index]: !showPassword[index]
                    }) }, showPassword[index] ? React.createElement(IoEyeOff, { size: 24 }) : React.createElement(IoEye, { size: 24 })),
                React.createElement(Field, { name: "add" }, ({ form }) => index === Math.max(0, tableData.length - 1) ? (React.createElement(Button, { onClick: () => {
                        void form.setFieldValue(`configuration.import.cronos.makeSettings`, [
                            ...form.values.configuration.import.cronos.makeSettings,
                            newRow
                        ]);
                    }, disabled: disabled || !instance.configuration?.import?.cronos?.enabled }, "+")) : (React.createElement(Button, { inversed: true, onClick: () => {
                        void form.setFieldValue('configuration.import.cronos.makeSettings', form.values.configuration.import.cronos.makeSettings.filter((_, rowIndex) => index !== rowIndex));
                    }, disabled: disabled || !instance.configuration?.import?.cronos?.enabled }, "-")))))
        };
    });
    return (React.createElement(Fragment, null,
        React.createElement("div", { style: { paddingBottom: '0.7rem' } },
            React.createElement(FieldLabel, { label: t('ENABLE_INTEGRATION'), className: "ps-0" }),
            React.createElement(FormikField, { name: "configuration.import.cronos.enabled", component: Toggle, label: `${t('ENABLE_INTEGRATION')}`, value: instance.configuration?.import?.cronos?.enabled, disabled: disabled })),
        React.createElement(ScTable, { pagination: {
                total: 100,
                pageSize: 100,
                hideOnSinglePage: true
            }, dataSource: tableData, columns: [
                {
                    title: `${t('INTEGRATIONS_CRONOS_MAKE')} *`,
                    dataIndex: 'make'
                },
                {
                    title: `${t('INTEGRATIONS_CRONOS_USERNAME')} *`,
                    dataIndex: 'username'
                },
                {
                    title: `${t('INTEGRATIONS_CRONOS_PASSWORD')} *`,
                    dataIndex: 'password'
                },
                {
                    title: '',
                    dataIndex: 'add'
                }
            ] }),
        React.createElement("div", { style: { paddingTop: '0.7rem' } },
            React.createElement(FieldLabel, { label: t('APPS_HYPER_DEALER__NOTIFICATION_EMAIL'), help: t('APPS_HYPER_DEALER__NOTIFICATION_TOOLTIP'), className: "ps-0" }),
            React.createElement(FormikField, { name: "configuration.import.cronos.notificationEmail", component: BasicInput, value: instance.configuration?.import?.cronos?.notificationEmail, disabled: disabled }))));
};
const ScTable = styled(Table) `
  td > button {
    margin-bottom: 0.7rem;
  }
`;
export default Form;
