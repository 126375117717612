import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { Field } from 'redux-form';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { ConditionQueryField } from '../../../../../common/components/ConditionQuery';
const LoopForm = ({ metaDefinitionId, disabled }) => {
    const { t } = useI18n();
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_LOOP_CRITERIA'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(Field, { className: "cp-i-100", name: "query", component: ConditionQueryField, definitionId: metaDefinitionId, disabled: disabled }))));
};
export default LoopForm;
