import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigsState } from '../common/reducers/rootSelector';
import { fetchConfig } from './actions';
import { getConfig, hasFetchConfigFailed, isConfigAvailable, isFetchConfigPending } from './selectors';
export const useConfig = (configType, configId) => {
    const dispatch = useDispatch();
    const isAvailable = useSelector((s) => isConfigAvailable(getConfigsState(s), configType, configId));
    const isFailed = useSelector((s) => hasFetchConfigFailed(getConfigsState(s), configType, configId));
    const isPending = useSelector((s) => isFetchConfigPending(getConfigsState(s), configType, configId));
    const data = useSelector((s) => getConfig(getConfigsState(s), configType, configId));
    const loadConfig = useCallback(async () => {
        await dispatch(fetchConfig(configType, configId));
    }, [dispatch, configType, configId]);
    useEffect(() => {
        if (!isAvailable && !isPending) {
            void loadConfig();
        }
    }, [isAvailable, isPending, loadConfig]);
    return useMemo(() => ({
        data,
        isAvailable: !!data,
        isFetching: isPending,
        isError: isFailed
    }), [data, isFailed, isPending]);
};
