import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isEqual, toArray } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FaCog } from 'react-icons/fa';
import { Fields, FormSection } from 'redux-form';
import { Drawer, DrawerContent } from '../../../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../../../common/components/Drawer/DrawerContentBlock';
import GroupsField from '../../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../../groups/constants';
import { defaultDeadlineReadGroups, defaultDeadlineWriteGroups } from '../../../activity-diagram/nodes';
const DeadlinePermissionField = ({ disabled, parentViewGroups, timeLimitPermissions }) => {
    const { t } = useI18n();
    const [deadlineDrawerVisible, setDeadlineDrawerVisible] = useState(false);
    const viewGroups = toArray(timeLimitPermissions?.viewGroups);
    const executeGroups = toArray(timeLimitPermissions?.executeGroups);
    const isDeadlinePermissionsChanged = useMemo(() => {
        if (timeLimitPermissions?.viewGroups &&
            !isEqual(defaultDeadlineReadGroups, viewGroups.sort())) {
            return true;
        }
        if (timeLimitPermissions?.executeGroups &&
            !isEqual(defaultDeadlineWriteGroups, executeGroups.sort())) {
            return true;
        }
        return false;
    }, [
        executeGroups,
        timeLimitPermissions?.executeGroups,
        timeLimitPermissions?.viewGroups,
        viewGroups
    ]);
    const showDeadlinePermissionsDrawer = useCallback(() => {
        setDeadlineDrawerVisible(true);
    }, []);
    const hideDeadlinePermissionsDrawer = useCallback(() => {
        setDeadlineDrawerVisible(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", onClick: showDeadlinePermissionsDrawer },
            React.createElement(FaCog, { className: isDeadlinePermissionsChanged ? 'changed' : '' })),
        React.createElement(Drawer, { title: t('DEADLINE_PERMISSIONS'), placement: "right", onClose: hideDeadlinePermissionsDrawer, open: deadlineDrawerVisible, destroyOnClose: true },
            React.createElement(DrawerContent, null,
                React.createElement(DrawerContentBlock, null,
                    React.createElement(FormSection, { name: "timeLimitPermissions" },
                        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups, AvailableSystemGroups.executeGroups], component: GroupsField, disabled: disabled, withValidation: true, parentViewGroups: parentViewGroups })))))));
};
export default DeadlinePermissionField;
