import { datePattern, dateTimePattern, getMilliseconds, timePattern } from '@hypercharge/hyper-react-base/lib/utils';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getScrollableAreaOrBody } from '../../utils/common';
const DateField = ({ input, value, onChange, disabled = false, onKeyDown, autoFocus = false, withTime = false, className, allowClear }) => {
    const [open, setOpen] = useState(autoFocus);
    const dateRef = useRef(null);
    const parsedValue = useMemo(() => {
        if (input && input.value) {
            return dayjs(input.value);
        }
        if (value !== undefined && value !== null) {
            return dayjs(value);
        }
    }, [value, input]);
    const _onChange = useCallback((value) => {
        let milliseconds = null;
        if (value) {
            milliseconds = getMilliseconds(moment(value.valueOf()), !!withTime);
        }
        onChange && onChange(milliseconds);
        input && input.onChange && input.onChange(milliseconds);
    }, [onChange, input, withTime]);
    useEffect(() => {
        if (dateRef.current) {
            const inputElement = dateRef.current.querySelector('.ant-picker .ant-picker-input > input');
            if (inputElement && autoFocus) {
                inputElement.focus({ preventScroll: true });
            }
        }
    }, [autoFocus]);
    return (React.createElement("div", { ref: dateRef, onClick: (e) => e.stopPropagation(), onMouseDown: (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
        } },
        React.createElement(DatePicker, { allowClear: !!allowClear, className: `w-100 ${className || ''}`, autoFocus: false, open: open, format: withTime ? dateTimePattern : datePattern, value: parsedValue, onChange: _onChange, onOk: () => setOpen(false), onClick: () => setOpen(true), onFocus: () => setOpen(true), onBlur: () => setOpen(false), disabled: disabled, onKeyDown: (e) => onKeyDown && onKeyDown(e), showTime: withTime ? { format: timePattern, minuteStep: 15 } : false, getPopupContainer: getScrollableAreaOrBody })));
};
export default DateField;
