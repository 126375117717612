import { QueryClient } from 'react-query';
/**
 * for good you need to use hook useQueryClient
 * but eslint swears that utils should be used inside functional components,
 * so the queryClient was moved to a separate module and acts as a singleton
 */
const queryClient = new QueryClient();
export const oneHourInMs = 1000 * 60 * 60;
export const oneMinuteInMs = 1000 * 60;
export default queryClient;
