import { isEmpty, map } from 'lodash';
import { validateGroups } from '../../../../../groups';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../../../../common/utils/types';
import { HP3N_NODE_PREFIX } from '../node-form/Hp3nNodeForm/Hp3nNodeForm';
import { isDecisionNode } from './nodes';
import { validateDelayParameter, validateIframeNodeParameters, validateQuery, validateRequiredHp3nParameters, validateRequiredMilestoneParameters, validateRequiredParameters, validateTitles } from './utils';
export const validateDiagram = ({ activity }, { t, credentialTypes, nodes }) => {
    let errors = {};
    map(activity, (value, nodeId) => {
        let taskErrors = {};
        if (value.type === ActivityNodeTypes.ACTION) {
            taskErrors = validateTitles(nodeId, taskErrors, value.titles, t);
            if (value.target === 'EMAIL') {
                taskErrors = validateRequiredParameters(['destinationEmails', 'template.id'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'STEP') {
                taskErrors = validateRequiredParameters(['step'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'SEND_SMS') {
                taskErrors = validateRequiredParameters(['phoneNumberField', 'defaultMessage'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'CREATE_PROCESS_RUN') {
                taskErrors = validateRequiredParameters(['processMetaId'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'CREATE_ENTITY') {
                taskErrors = validateRequiredParameters(['definitionId'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'GET_ENTITY_VALUES') {
                taskErrors = validateRequiredParameters(['definitionId'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'CANCEL_PROCESS') {
                taskErrors = validateRequiredParameters(['definitionId', 'conditionQuery'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'GENERATE_DOCUMENT') {
                taskErrors = validateRequiredParameters(['filePropertyId', 'templateId'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target === 'MILESTONE') {
                taskErrors = validateRequiredMilestoneParameters(['startTaskId', 'unit'], nodeId, taskErrors, value.parameters?.milestones, t);
            }
            else if (value.target === 'GENERATE_QR_CODE') {
                taskErrors = validateRequiredParameters(['data', 'binaryProperty'], nodeId, taskErrors, value.parameters, t);
            }
            else if (value.target?.startsWith(HP3N_NODE_PREFIX)) {
                taskErrors = validateRequiredHp3nParameters(value, credentialTypes, nodes, nodeId, taskErrors, t);
            }
            else if (isDecisionNode(value)) {
                taskErrors = validateTitles(nodeId, taskErrors, value.titles, t);
                taskErrors = validateQuery(nodeId, taskErrors, value.query, t);
                if (value.parameters?.wait) {
                    taskErrors = validateDelayParameter(nodeId, taskErrors, value.parameters.delay, t);
                }
            }
            else if (value.target === 'ACTIVITY') {
                taskErrors = validateRequiredParameters(['parameters.available'], nodeId, taskErrors, value, t);
            }
            else if (value.target === 'DELAY') {
                taskErrors = validateRequiredParameters(['value', 'unit'], nodeId, taskErrors, value.parameters, t);
            }
            if (!value.subtype || value.subtype === ActivityActionNodeSubtypes.MANUAL) {
                const nodePermissionErrors = validateGroups(['viewGroups', 'executeGroups'], value);
                if (!isEmpty(nodePermissionErrors)) {
                    taskErrors = {
                        ...taskErrors,
                        [nodeId]: {
                            ...(taskErrors[nodeId] || {}),
                            ...nodePermissionErrors
                        }
                    };
                }
                taskErrors = validateIframeNodeParameters('height', nodeId, taskErrors, value.parameters?.iframe, t);
            }
        }
        else if (value.type === ActivityNodeTypes.LOOP_WHILE) {
            taskErrors = validateQuery(nodeId, taskErrors, value.query, t);
        }
        if (!isEmpty(taskErrors)) {
            errors = { ...errors, ...taskErrors };
        }
    });
    if (!isEmpty(errors)) {
        return {
            activity: {
                ...errors
            }
        };
    }
    return {};
};
