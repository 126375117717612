import { Skeleton, TextPlaceholder } from '@hypercharge/hyper-react-base/lib/common/skeletons';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
const LoadingBar = ({ className }) => (React.createElement(Skeleton, { isLoading: true, className: className },
    React.createElement(TextPlaceholder, { className: "text-placeholder", heightInPx: 14, widthInPx: 100 })));
export const LoadingCommonBar = ({ loading }) => {
    const { t } = useI18n();
    return loading ? React.createElement(LoadingBar, null) : React.createElement("i", null, t('NONE'));
};
export default LoadingBar;
