import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../common/button';
import { useI18n } from '../../i18n';
import { goBack } from '../../router';
import { Wrapper } from '../../styling';
const Container = styled(Wrapper) `
  user-select: none;
  padding: 0.5rem;
  margin-bottom: ${({ withoutMargin }) => (withoutMargin ? '0' : '2rem')};
`;
const ButtonContainer = styled.div `
  text-align: center;
`;
const StyledButton = styled((props) => React.createElement(Button, { ...props })) `
  width: 100%;
`;
const FormFooterContainer = ({ className, showCancel, submitting, disabled, withoutMargin, leftAligned, onCancel, onSubmit, goBack, cancelText, submitText, resetText, reset }) => {
    const { t } = useI18n();
    return (React.createElement(Container, { className: `cp-c-row cp-c-align-center-center cp-c-sm-align-${leftAligned ? 'start' : 'end'}-center cp-c-padding-2 ${className || ''}`, withoutMargin: !!withoutMargin },
        showCancel && (React.createElement(ButtonContainer, { className: "cp-i-flex cp-i-sm-none" },
            React.createElement(StyledButton, { inversed: true, onClick: () => {
                    if (onCancel) {
                        onCancel();
                    }
                    else if (goBack) {
                        goBack();
                    }
                }, disabled: submitting }, cancelText ? t(cancelText) : t('FORM__CANCEL')))),
        !!reset && (React.createElement(ButtonContainer, { className: "cp-i-flex cp-i-sm-none" },
            React.createElement(StyledButton, { inversed: true, onClick: reset, disabled: disabled || submitting }, resetText ? t(resetText) : t('FORM__RESET')))),
        React.createElement(ButtonContainer, { className: "cp-i-flex cp-i-sm-none" },
            React.createElement(StyledButton, { type: onSubmit ? 'button' : 'submit', onClick: onSubmit, loading: submitting, disabled: disabled }, submitText ? t(submitText) : t('FORM__SAVE')))));
};
export const FormFooter = connect(null, { goBack })(FormFooterContainer);
