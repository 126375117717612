import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AVAILABLE_LANGUAGES as CONFIG_AVAILABLE_LANGUAGES } from 'config';
import React, { useCallback } from 'react';
import { Option, Select } from '../Select';
export const LanguageSelectorInput = ({ label, className, value, disabled, allowClear = true, onChange, input, languages, ...otherProps }) => {
    const { t } = useI18n();
    const _onChange = useCallback((value) => {
        if (onChange) {
            onChange(value || null);
        }
        else if (input?.onChange) {
            input.onChange(value || null);
        }
    }, [input, onChange]);
    const currentValue = (input ? input.value : value) || undefined;
    return (React.createElement("div", { className: `w-100 ${className || ''}` },
        label && React.createElement(LabelText, null, label),
        React.createElement(Select, { className: "w-100", popupMatchSelectWidth: false, placeholder: t('COMMON__LANGUAGE'), disabled: disabled, onChange: _onChange, value: currentValue, allowClear: allowClear, ...otherProps }, languages?.length ? (React.createElement(React.Fragment, null, languages.map(({ value, label }) => (React.createElement(Option, { key: value, value: value }, label))))) : (React.createElement(React.Fragment, null, CONFIG_AVAILABLE_LANGUAGES.map((language) => (React.createElement(Option, { key: language, value: language }, t(language)))))))));
};
export default LanguageSelectorInput;
