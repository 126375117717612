import { Body, Cell, DeleteConfirmationButton, InformationRow, Row, Table } from '@hypercharge/hyper-react-base/lib/common/table';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Spin, Tooltip } from 'antd';
import { INITIAL_PAGE_SIZE } from 'config';
import React, { Fragment } from 'react';
import { IoAdd, IoEye } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import styled, { withTheme } from 'styled-components';
import { EditIcon } from '../../../common/components/EditIcon';
import { FullTextSearchBase } from '../../../common/components/data-table/FullTextSearch';
import TablePagination from '../../../common/components/table-pagination';
import { StyledWrapper } from '../../../common/utils/stylingUtils';
import { FORMS_NEW_PATH, FORMS_PATH, FORM_PATH } from '../../constants';
import styles from './FormsList.module.scss';
const FormsList = ({ loading, forms, totalCount, page, pageSize, fullText, onClickDelete, onPageChange, onPageSizeChange, onChangeSearch }) => {
    const { t } = useI18n();
    return (React.createElement(Fragment, null,
        React.createElement(StyledWrapper, { className: `${styles.wrapper} pt-3` },
            React.createElement("div", { className: "cp-c-row cp-c-align-end-center" },
                React.createElement(ActionsContainer, { className: "py-2 px-3 my-3 w-100 rounded border bg-white d-flex flex-wrap flex-row align-items-center align-content-center justify-content-between gap-2 actions-container" },
                    React.createElement(FullTextSearchBase, { value: fullText, onChange: (searchValue) => onChangeSearch({ fullText: searchValue, page: 1, pageSize: Number(pageSize) }), localStorageKey: 'forms' }),
                    React.createElement(Link, { className: "quick-add-item-link p-2 d-flex justify-content-center", to: [FORMS_PATH, FORMS_NEW_PATH].join('') },
                        React.createElement(IoAdd, { className: "me-1" }),
                        React.createElement("span", { className: "d-none d-sm-inline" },
                            React.createElement("span", null, t('DATA_TABLE__ADD')),
                            React.createElement("span", { className: "me-2" }, ":"),
                            React.createElement("span", null, t('FORM_TEMPLATE')))))),
            React.createElement(Table, null,
                React.createElement(Body, { loading: loading, loadingRows: INITIAL_PAGE_SIZE, cellLength: 1 }, loading ? (React.createElement(Spin, null)) : forms?.length === 0 ? (React.createElement(InformationRow, { cellLength: 1 }, t('NO_FORMS'))) : (forms.map(({ id, title }) => {
                    return (React.createElement(Row, { key: id },
                        React.createElement(StyledCell, null,
                            React.createElement(FormName, null, title)),
                        React.createElement(ActionsCell, { className: "actions" },
                            React.createElement("div", null,
                                React.createElement(ButtonLink, { className: "safe-space", target: "_blank", to: `${FORM_PATH}/${id}`, onClick: (e) => e.stopPropagation() },
                                    React.createElement(Tooltip, { placement: "top", title: t('FORMS__PREVIEW') },
                                        React.createElement(IoEye, { size: 24 }))),
                                React.createElement(ButtonLink, { className: "safe-space", to: `${FORMS_PATH}/${id}`, onClick: (e) => e.stopPropagation() },
                                    React.createElement(EditIcon, null)),
                                React.createElement(DeleteConfirmationButton, { className: "safe-space", onClick: () => onClickDelete(id) })))));
                })))),
            !loading && totalCount > 0 && (React.createElement(TablePagination, { totalCount: totalCount, pageSize: pageSize, page: page, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange })))));
};
const ButtonLink = styled(Link) `
  display: flex;
  align-items: center;
  margin: 0 0.3rem;

  svg {
    fill: var(--neutralColor-6);
  }

  :hover {
    svg {
      fill: var(--primaryColor-5);
    }
  }
`;
const ActionsContainer = styled.div `
  margin-bottom: 1rem;
`;
const StyledCell = styled(Cell) `
  vertical-align: top;
`;
const FormName = styled.div `
  font-weight: 600;
  padding-bottom: 0.2rem;
`;
const ActionsCell = styled(Cell) `
  width: 140px;
`;
export default compose(withTheme)(FormsList);
