import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import RichTextDisplay from '../../../../cms/data/components/item-property/display/RichTextDisplay';
import { useDisplayTenant } from '../../../../tenant/useDisplayTenant';
import { getPositionClassName } from '../utils';
import styles from './AuthContainer.module.scss';
const AuthContainer = ({ className, children, ...otherProps }) => {
    const { t } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const positionClassName = useMemo(() => getPositionClassName(displayTenant?.loginPage?.position), [displayTenant?.loginPage?.position]);
    const backgroundImage = useMemo(() => {
        if (!displayTenant?.loginPage?.backgroundImageUrl) {
            return;
        }
        return `url(${displayTenant?.loginPage?.backgroundImageUrl})`;
    }, [displayTenant?.loginPage?.backgroundImageUrl]);
    return (React.createElement("div", { ...otherProps, className: `${styles.wrapper} d-flex align-items-center ${className || ''} ${positionClassName}`, style: { backgroundImage } },
        React.createElement("div", { className: "content" },
            children,
            React.createElement("div", { className: "footer mt-5" }, displayTenant?.loginPage?.bottomText ? (React.createElement(RichTextDisplay, { value: displayTenant?.loginPage?.bottomText })) : (React.createElement("a", { href: "mailto:support@hypercharge.io", target: "_blank", rel: "noopener noreferrer" }, t('CONTACT_SUPPORT')))))));
};
export default AuthContainer;
