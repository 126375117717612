export const getDashboards = () => ({
    type: 'STATISTICS__DASHBOARDS',
    meta: {
        http: {
            path: '/v2-statistics/dashboards',
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
export const getDashboardUrl = (dashboardId) => ({
    type: 'STATISTICS__DASHBOARDS',
    meta: {
        http: {
            path: `/v2-statistics/dashboard-url/${dashboardId}`,
            method: 'GET',
            allowPromiseReject: true
        }
    }
});
