import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { get } from 'lodash';
import React from 'react';
import { getPropertyTypeComponent } from '../../../workflows/meta/components/meta-definition-form/utils';
import { ThisIsHiddenProperty } from '../ThisIsHiddenProperty/ThisIsHiddenProperty';
import { TooltipTruncate } from '../TooltipTruncate';
const ConditionQueryLabel = ({ className = '', parentDisplayData, displayData }) => {
    const { t, language } = useI18n();
    const Icon = getPropertyTypeComponent(displayData?.type);
    const isHidden = displayData?.meta.hidden;
    return (React.createElement("label", { className: cn(className, 'd-flex align-items-center gap-2') },
        isHidden && React.createElement(ThisIsHiddenProperty, null),
        Icon && React.createElement(Icon, null),
        React.createElement(TooltipTruncate, { className: "flex-truncate" },
            parentDisplayData ? getTranslation(parentDisplayData?.labels, language) + ' / ' : null,
            displayData && getTranslation(displayData.labels, language),
            getTranslation(get(displayData, 'meta.units'), language) &&
                ` (${getTranslation(get(displayData, 'meta.units'), language)})`),
        parentDisplayData?.type === 'entity' && parentDisplayData.meta.list && (React.createElement(Tooltip, { title: t('CONDITION_ENTITY_LIST_LIMITATION'), placement: "top" },
            React.createElement(FontAwesomeIcon, { icon: "exclamation-triangle", className: "entity-list-notify" })))));
};
export default ConditionQueryLabel;
