import React from 'react';
import styled, { keyframes } from 'styled-components';
const LoadingBarAnimation = keyframes `
	from { left: -40%; }
	to { left: 100%; }
`;
export const Loader = styled.div `
  position: absolute;
  bottom: 1px;
  width: 96%;
  left: 2%;
  height: 3px;

  &:after {
    content: '';
    display: inline;
    position: absolute;
    width: 40%;
    height: 100%;
    left: 50%;
    text-align: center;
    background-color: ${({ useTheme, theme, inversed }) => useTheme ? theme.mainColor : inversed ? '#d9d9d9' : '#fff'};
    animation: ${LoadingBarAnimation} 0.7s ease-in-out infinite;
  }
`;
export const CircularLoader = styled.div `
  &,
  &:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
  }
  font-size: 10px;
  text-indent: -9999em;
  border: 2px solid
    ${({ useTheme, theme, inversed }) => useTheme ? theme.mainColor : inversed ? '#d9d9d9' : '#fff'};
  border-left-color: transparent;
  transform: translateZ(0);
  animation: load8 0.8s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Divider = styled.hr `
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid #dcdcdc;
  height: 1px;

  @media print {
    margin: 1.5rem 0;
  }
`;
const TitleContainer = styled.div `
  padding-bottom: 1rem;
  font-weight: 600;
`;
export const Section = ({ className, icon, title, children, ...otherProps }) => (React.createElement("div", { className: className, ...otherProps },
    React.createElement(Divider, null),
    title && (React.createElement(TitleContainer, { className: "cp-c-row cp-c-align-start-center" },
        icon && React.createElement("div", { className: "cp-i-none" }, icon),
        React.createElement("div", { className: "cp-i-flex" }, title))),
    children));
export const ShadowedBox = styled.div `
  position: relative;
  margin-bottom: 2rem;
  background: #fff;
  box-shadow: ${({ theme }) => theme.cardBoxShadow};
  border-radius: ${({ theme }) => theme.cardBorderRadius};
  padding: 1.5rem;

  @media print {
    box-shadow: none;
    padding-top: 0;
  }
`;
export const Title = styled.h1 `
  font-size: 2rem;
  padding: 0;
  margin: 1rem;
  font-weight: normal;
`;
