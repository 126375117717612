import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Radio } from 'antd';
import { findKey, isEmpty, isString } from 'lodash';
import React, { useCallback } from 'react';
import { ENTITY_ID_PROPERTY_ID } from '../../../../../cms';
import DateRangeSelector from '../../../../../common/components/DateRangeSelector';
import { Select } from '../../../../../common/components/Select';
import { Tooltip } from '../../../../../common/components/Tooltip';
import { useView } from '../../../../../views/components/ViewContext';
import { ViewTypes } from '../../../../../views/types';
import { TasksFilterType, isTasksFilterType } from '../../../types';
import { tasksDashboardDeadlineColumns } from '../../../utils';
import { useTaskDashboard } from '../TaskDashboardContext/TaskDashboardProvider';
import styles from './TaskDashboardPageFilters.module.scss';
const TaskDashboardPageFilters = ({ relatedToTitleKey, className = '' }) => {
    const { t } = useI18n();
    const { viewType, kanban, rowGrouping, updateRouteWithView, filterRequest, metrics } = useView();
    const { searchValue, handleChangeSearchValue, selectedFilters, availableFilters, handleChangeFilters, mapProperty, getNewFilterForTaskDashboard, getTaskFilterTypeConfig } = useTaskDashboard();
    const handleChangeGroupBy = useCallback((event) => {
        if (event.target.name) {
            const groupByKey = findKey(mapProperty, (item) => item === event.target.name);
            if (!isTasksFilterType(groupByKey)) {
                return;
            }
            if (groupByKey === TasksFilterType.deadline) {
                const newFilterRequest = getNewFilterForTaskDashboard({
                    filterRequest,
                    field: mapProperty[TasksFilterType.deadline],
                    filterName: TasksFilterType.deadline,
                    value: selectedFilters[TasksFilterType.deadline]
                });
                return updateRouteWithView({
                    newFilters: newFilterRequest,
                    newMetrics: metrics,
                    kanban: {
                        groupBy: event.target.name,
                        columns: tasksDashboardDeadlineColumns
                    },
                    rowGrouping
                });
            }
            let newColumns = selectedFilters[groupByKey] || [];
            if (isEmpty(newColumns)) {
                newColumns =
                    availableFilters[groupByKey].value.options
                        .map((option) => option.value)
                        .filter(isString) || [];
            }
            const defaultFilter = TasksFilterType.assignees;
            const fieldGroupBy = getTaskFilterTypeConfig(groupByKey).fieldGroupBy;
            const newFilterRequest = getNewFilterForTaskDashboard({
                filterRequest,
                field: fieldGroupBy,
                filterName: groupByKey || defaultFilter,
                value: newColumns
            });
            updateRouteWithView({
                newFilters: newFilterRequest,
                newMetrics: metrics,
                kanban: {
                    groupBy: event.target.name,
                    columns: newColumns
                },
                rowGrouping
            });
        }
    }, [
        availableFilters,
        filterRequest,
        getNewFilterForTaskDashboard,
        getTaskFilterTypeConfig,
        mapProperty,
        metrics,
        rowGrouping,
        selectedFilters,
        updateRouteWithView
    ]);
    return (React.createElement("div", { id: "TaskDashboardPageFilters", className: `${styles.wrapper} TaskDashboardPageFilters d-flex flex-wrap gap-2 ${className}` },
        React.createElement("div", null,
            React.createElement(Select, { prefixComponent: viewType === ViewTypes.kanban && (React.createElement(Tooltip, { mouseEnterDelay: 1, title: t('GROUP_BY_THIS_FIELD') },
                    React.createElement(Radio, { disabled: availableFilters[TasksFilterType.relatedTo].loading, rootClassName: "m-0", name: mapProperty[TasksFilterType.relatedTo], checked: kanban?.groupBy === mapProperty[TasksFilterType.relatedTo], onChange: handleChangeGroupBy }))), checkmarks: true, popupMatchSelectWidth: false, loading: availableFilters[TasksFilterType.relatedTo].loading, placeholder: t(relatedToTitleKey), searchValue: searchValue?.relatedTo, onSearchDropdown: handleChangeSearchValue('relatedTo'), options: availableFilters[TasksFilterType.relatedTo].value.options, totalCount: availableFilters[TasksFilterType.relatedTo].value.totalCount, value: selectedFilters.relatedTo, filterOption: false, onChange: handleChangeFilters(TasksFilterType.relatedTo, mapProperty[TasksFilterType.relatedTo]), mode: "multiple", maxTagCount: "responsive" })),
        React.createElement("div", null,
            React.createElement(Select, { prefixComponent: viewType === ViewTypes.kanban && (React.createElement(Tooltip, { mouseEnterDelay: 1, title: t('GROUP_BY_THIS_FIELD') },
                    React.createElement(Radio, { disabled: availableFilters[TasksFilterType.taskTitles].loading, rootClassName: "m-0", name: mapProperty[TasksFilterType.taskTitles], checked: kanban?.groupBy === mapProperty[TasksFilterType.taskTitles], onChange: handleChangeGroupBy }))), checkmarks: true, popupMatchSelectWidth: false, loading: availableFilters[TasksFilterType.taskTitles].loading, placeholder: t('TASK_TITLE'), searchValue: searchValue?.taskTitle, onSearchDropdown: handleChangeSearchValue('taskTitle'), value: selectedFilters.taskTitles, filterOption: false, onChange: handleChangeFilters(TasksFilterType.taskTitles, mapProperty[TasksFilterType.taskTitles]), options: availableFilters[TasksFilterType.taskTitles].value.options, totalCount: availableFilters[TasksFilterType.taskTitles].value.totalCount, mode: "multiple", maxTagCount: "responsive" })),
        React.createElement("div", null,
            React.createElement(Select, { prefixComponent: viewType === ViewTypes.kanban && (React.createElement(Tooltip, { mouseEnterDelay: 1, title: t('GROUP_BY_THIS_FIELD') },
                    React.createElement(Radio, { disabled: availableFilters[TasksFilterType.assignees].loading, rootClassName: "m-0", name: mapProperty[TasksFilterType.assignees], checked: kanban?.groupBy === mapProperty[TasksFilterType.assignees], onChange: handleChangeGroupBy }))), checkmarks: true, popupMatchSelectWidth: false, loading: availableFilters[TasksFilterType.assignees].loading, placeholder: t('ASSIGNEE'), searchValue: searchValue?.assignee, onSearchDropdown: handleChangeSearchValue('assignee'), value: selectedFilters[TasksFilterType.assignees], filterOption: false, onChange: handleChangeFilters(TasksFilterType.assignees, `${mapProperty[TasksFilterType.assignees]}.${ENTITY_ID_PROPERTY_ID}`), options: availableFilters[TasksFilterType.assignees].value.options, totalCount: availableFilters[TasksFilterType.assignees].value.totalCount, mode: "multiple", maxTagCount: "responsive" })),
        React.createElement("div", null,
            React.createElement(DateRangeSelector, { prefixComponent: viewType === ViewTypes.kanban && (React.createElement(Tooltip, { mouseEnterDelay: 1, title: t('GROUP_BY_THIS_FIELD') },
                    React.createElement(Radio, { rootClassName: "m-0", name: mapProperty[TasksFilterType.deadline], checked: kanban?.groupBy === mapProperty[TasksFilterType.deadline], onChange: handleChangeGroupBy }))), value: selectedFilters.deadline, onChange: handleChangeFilters(TasksFilterType.deadline, mapProperty[TasksFilterType.deadline]), showQuickRelative: false, showCustomButton: false }))));
};
export default TaskDashboardPageFilters;
