import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { BsArrowDownCircle, BsArrowUpCircle, BsExclamationCircle } from 'react-icons/bs';
import { TaskPriority } from '../../../../../../../workflows/common/utils/types';
import { useEntityItem } from '../../../../../../hooks/useEntityItem';
import styles from '../KanbanCard.module.scss';
const CardTaskPriorityIconMap = {
    [TaskPriority.LOW]: BsArrowDownCircle,
    [TaskPriority.NORMAL]: BsExclamationCircle,
    [TaskPriority.HIGH]: BsArrowUpCircle,
    [TaskPriority.URGENT]: BsArrowUpCircle
};
export const CardTaskPriority = ({ taskPriority, priorityDefinitionId }) => {
    const { data: priority } = useEntityItem(priorityDefinitionId, taskPriority);
    const Icon = useMemo(() => (priority && CardTaskPriorityIconMap[priority.entityId]) || BsExclamationCircle, [priority]);
    return (React.createElement(Tooltip, { title: priority?.title || '', placement: "top" },
        React.createElement("div", { className: `${styles.cardHeader__taskPriority} class-${taskPriority} d-flex align-items-center` },
            React.createElement(Icon, null))));
};
