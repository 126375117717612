import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { useStore } from 'react-redux';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SortablePropertyListField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput, AntInputField } from '../../../../../../common/components/AntInput';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import LanguageSelectorInput from '../../../../../../common/components/LanguageSelectorInput/LanguageSelectorInput';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import FormsSelectorField from '../../../../../../forms/components/common/FormsSelector';
import { getForm } from '../../../../../../forms/selectors';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import ProcessPropertyMappingsField from '../ProcessPropertyMappingsField';
const GenerateFormUrlForm = ({ disabled, parentViewGroups, changeFieldValue, metaDefinitionId, node }) => {
    const { t } = useI18n();
    const store = useStore();
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, disabled: disabled, placeholder: t('COMMON__TITLE') }),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FieldLabel, { label: t('FORM'), className: "ps-0" }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement("div", { className: "d-flex flex-row" },
                React.createElement("div", { className: "col-8 me-2" },
                    React.createElement(Field, { name: "formId", component: FormsSelectorField, disabled: disabled, validate: required, autoFocus: false, onChange: (_, newValue, previousValue, name) => {
                            if (newValue != previousValue && name) {
                                const form = newValue ? getForm(store.getState(), newValue) : null;
                                const defaultValues = {};
                                if (form) {
                                    (form.form.schema.required || []).forEach((field) => (defaultValues[field] = ''));
                                    Object.values(form.form.schema.properties).forEach((field) => {
                                        (field.required || []).forEach((field) => (defaultValues[field] = ''));
                                    });
                                }
                                changeFieldValue(name.replace('formId', 'propertyMappings'), defaultValues);
                                changeFieldValue('formId', newValue);
                            }
                        } })),
                React.createElement("div", { className: "col-4 ps-0" },
                    React.createElement(Field, { name: "formLanguage", component: ComputedInputField, popupMatchSelectWidth: false, computedComponent: LanguageSelectorInput, disabled: disabled }))),
            React.createElement(Field, { name: "formId", component: FormPropertySection, metaDefinitionId: metaDefinitionId }),
            React.createElement(Divider, null, t('SETTINGS')),
            React.createElement(FieldLabel, { label: t('TASK_GENERATE_FORM_URL_PROPERTY'), help: t('TASK_GENERATE_FORM_URL_PROPERTY_INFO') }),
            React.createElement(Field, { name: "linkPropertyId", component: SortablePropertyListField, definitionId: metaDefinitionId, includeProperty: isForLinkProperty, includeNestedProperties: false, sortable: false, disabled: disabled || !!node?.parameters?.linkPropertyId, parse: (newValue) => (newValue.length ? newValue[0] : null), format: (value) => (value ? [value] : []) })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
const isForLinkProperty = (displayData) => ['link', 'text'].includes(displayData.type);
const FormPropertySection = ({ disabled, input }) => {
    const { t } = useI18n();
    const store = useStore();
    const form = input.value ? getForm(store.getState(), input.value) : null;
    const formDefinitionId = form?.definitionId;
    return formDefinitionId ? (React.createElement(React.Fragment, null,
        React.createElement(FieldLabel, { label: t('TASK_SET_ENTITY_VALUES_PROPERTY_MAPPINGS'), className: "ps-0" }),
        React.createElement(Field, { name: "propertyMappings", component: ProcessPropertyMappingsField, definitionId: formDefinitionId, includeNestedProperties: false, disabled: disabled, className: "mb-2" }),
        React.createElement(FieldLabel, { label: t('TASK_GENERATE_FORM_URL_EXPIRES'), help: React.createElement("span", { style: { whiteSpace: 'pre-line' } }, t('TASK_GENERATE_FORM_URL_EXPIRES_INFO')) }),
        React.createElement(Field, { name: "linkExpires", component: AntInputField, disabled: disabled, className: "mb-2" }),
        React.createElement(FieldLabel, { label: t('TASK_GENERATE_FORM_URL_LIMIT_AMOUNT_OF_SUBMIT'), help: t('TASK_GENERATE_FORM_URL_LIMIT_AMOUNT_OF_SUBMIT_INFO') }),
        React.createElement(Field, { name: "formSubmissionsLimit", component: AntInputField, disabled: disabled, className: "mb-2" }))) : null;
};
export default GenerateFormUrlForm;
