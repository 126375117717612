import { BasicSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { fetchProperties, getPropertiesByDefinitionId } from '@hypercharge/hyper-react-base/lib/metadata';
import { sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { getMetadataState } from '../../../common/reducers/rootSelector';
class DependantPropertyField extends Component {
    componentDidMount() {
        const { properties } = this.props;
        if (properties.length === 0) {
            this.props.fetchAllProperties();
        }
    }
    render() {
        const { properties, t, language, fieldName } = this.props;
        return (React.createElement(Field, { name: fieldName, label: t('PROPERTY_DEPENDANT_PROPERTY'), component: BasicSelect, options: properties.map((prop) => ({
                value: prop.id,
                labels: prop.labels
            })), disabled: properties.length == 0, emptyOption: true, emptyDisabled: false, language: language }));
    }
}
function mapStateToProps(state, { definitionId, propertyId, language }) {
    return {
        // $FlowFixMe
        properties: sortBy(getPropertiesByDefinitionId(getMetadataState(state), definitionId).filter((p) => p.id != propertyId), (p) => {
            return p.labels[language];
        })
    };
}
function mapDispatchToProps(dispatch, { definitionId }) {
    return {
        // @ts-expect-error
        fetchAllProperties: () => dispatch(fetchProperties(definitionId))
    };
}
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps))(DependantPropertyField);
