import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import useFiles from '../../../../common/storage/useFiles';
import { LoadingBlock } from '../../../../common/utils/formUtils';
const PDFViewer = ({ fileId }) => {
    const { t } = useI18n();
    const filesData = useFiles(fileId);
    return (React.createElement("div", { className: "w-100 h-100" }, !filesData ? (React.createElement(LoadingBlock, null)) : (React.createElement("object", { data: filesData.downloadUrl, width: "100%", height: "100%" },
        t('TEMPLATE_NO_PDF_READER'),
        React.createElement("br", null),
        React.createElement("a", { href: filesData.downloadUrl, target: "_blank", rel: "noopener noreferrer" }, t('TEMPLATE_DOWNLOAD'))))));
};
export default PDFViewer;
