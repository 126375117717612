import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import React, { useCallback } from 'react';
import { FaGripVertical, FaPen, FaTrash } from 'react-icons/fa';
import styles from './Widget.module.scss';
export const Widget = ({ className, onClickMenu, showControlButtons, children }) => {
    const handleEditClick = useCallback(() => {
        if (onClickMenu) {
            onClickMenu('edit');
        }
    }, [onClickMenu]);
    const handleDeleteClick = useCallback(() => {
        if (onClickMenu) {
            onClickMenu('delete');
        }
    }, [onClickMenu]);
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''}` },
        !!showControlButtons && (React.createElement("div", { className: "control-buttons" },
            React.createElement(Button, { inversed: true, className: "action-button px-2", onClick: handleEditClick },
                React.createElement(FaPen, null)),
            React.createElement(Button, { inversed: true, className: "action-button delete-btn px-2 mx-2", onClick: handleDeleteClick },
                React.createElement(FaTrash, null)),
            React.createElement(Button, { inversed: true, className: "action-button grip-btn px-2 drag-handler" },
                React.createElement(FaGripVertical, null)))),
        children));
};
