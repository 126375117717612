import React, { useCallback, useState } from 'react';
import { ExpandIcon } from '../../../../icons';
import ComputedExpressionEditor from '../ComputedExpressionEditor';
import ComputedModal from '../ComputedModal';
import styles from './ComputedInputInline.module.scss';
const ComputedInputInline = ({ value, onChange, propertiesTree }) => {
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const onOpenEditor = useCallback(() => {
        setIsEditorOpen(true);
    }, []);
    const onEditorOk = useCallback((value) => {
        onChange && onChange(value);
        setIsEditorOpen(false);
    }, [onChange]);
    const onEditorCancel = useCallback(() => {
        setIsEditorOpen(false);
    }, []);
    return (React.createElement("div", { className: `${styles.wrapper} ComputedInputInlineOuter container p-0` },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col" },
                React.createElement(ComputedExpressionEditor, { focus: true, value: value?.toString(), height: "150px", propertiesTree: propertiesTree, onChange: onChange, debounceChangePeriod: 200 })),
            React.createElement("div", { className: "btn col-auto ps-0 pt-0" },
                React.createElement(ExpandIcon, { onClick: onOpenEditor }))),
        React.createElement(ComputedModal, { value: value ?? undefined, propertiesTree: propertiesTree, open: isEditorOpen, onCancel: onEditorCancel, onOk: onEditorOk })));
};
export default ComputedInputInline;
