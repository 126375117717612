import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';
import { find, flatten, get, has, map, uniq } from 'lodash';
import { combineReducers } from 'redux';
import { TITLE_PROPERTY_ID } from '../common/utils/constants';
const processSearchResponse = (s, a) => {
    const { definitionId } = a.meta.actionContext;
    a.payload.results.forEach((item) => {
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        s[definitionId][item.entityId] = {
            ...(s[definitionId][item.entityId] || {}),
            ...item
        };
    });
};
const itemsById = createReducer({}, {
    CMS__SEARCH_ITEMS_SUCCESS: processSearchResponse,
    CMS__DELETE_ITEMS_SUCCESS: (s, a) => {
        const { definitionId, ids } = a.meta.actionContext;
        ids?.forEach((id) => {
            if (has(s, [definitionId, id])) {
                delete s[definitionId][id];
            }
        });
    },
    CMS__FETCH_ITEMS_BY_GROUPED_DEFINITION_ID_SUCCESS: (s, a) => {
        a.payload.results.forEach((item) => {
            // @ts-expect-error
            if (!s[item.definitionId]) {
                // @ts-expect-error
                s[item.definitionId] = {};
            }
            // @ts-expect-error
            s[item.definitionId][item.entityId] = {
                // @ts-expect-error
                ...(s[item.definitionId][item.entityId] || {}),
                ...item
            };
        });
    },
    WORKFLOWS__DELETE_PROCESSES_SUCCESS: (s, a) => {
        const { processMetaId, processRunIds } = a.meta.actionContext;
        processRunIds.forEach((processRunId) => {
            if (has(s, [processMetaId, processRunId])) {
                delete s[processMetaId][processRunId];
            }
        });
    },
    CMS__CREATE_ITEM_SUCCESS: (s, a) => {
        const { definitionId, id } = a.meta.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        s[definitionId][id] = a.payload;
    },
    ENTITY_DATA__UPDATE_VALUE_SUCCESS: (s, a) => {
        const { definitionId, entityId, propertyId, value } = a.meta.actionContext.value;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        const item = s[definitionId][entityId];
        if (item) {
            item[propertyId] = value;
        }
    },
    ENTITY_DATA__FETCH_SINGLE_SUCCESS: (s, a) => {
        // Check if the corresponding item was fetched before (to be displayed in tables, properties, ...)
        // If so, then update the 'title' property value so that the EntityDisplay component shows the latest value
        // This is especially relevant if the 'title' property is a computed expression,
        // because if the title was updated directly, the above function would already update the item based on ENTITY_DATA__UPDATE_VALUE_SUCCESS.
        const { entityId, definitionId } = a.meta.actionContext;
        const item = s[definitionId] && s[definitionId][entityId];
        const newItemTitle = get(find(flatten(map(a.payload.data, 'values')), { propertyId: TITLE_PROPERTY_ID }), 'value', '');
        if (item && item.title !== newItemTitle) {
            // @ts-expect-error
            item.title = newItemTitle;
        }
    }
});
const itemsStatus = createReducer({}, {
    CMS__FETCH_ITEMS_BY_GROUPED_DEFINITION_ID: (s, a) => {
        const { idsByDefinition, responseFields } = a.meta.http.actionContext;
        const definitionsIds = Object.keys(idsByDefinition);
        for (const definitionId of definitionsIds) {
            if (!s[definitionId]) {
                s[definitionId] = {};
            }
            const ids = idsByDefinition[definitionId];
            ids.forEach((id) => {
                const status = s[definitionId][id] || {};
                status.pending = true;
                status.failed = false;
                status.responseFields = responseFields;
                s[definitionId][id] = status;
            });
        }
    },
    CMS__FETCH_ITEMS_BY_GROUPED_DEFINITION_ID_SUCCESS: (s, a) => {
        const { idsByDefinition } = a.meta.actionContext;
        const definitionsIds = Object.keys(idsByDefinition);
        for (const definitionId of definitionsIds) {
            if (!s[definitionId]) {
                s[definitionId] = {};
            }
            const ids = idsByDefinition[definitionId];
            ids.forEach((id) => {
                const status = s[definitionId][id] || {};
                status.pending = false;
                status.failed = false;
                status.lastFetch = new Date();
                s[definitionId][id] = status;
            });
        }
    },
    CMS__FETCH_ITEMS_BY_GROUPED_DEFINITION_ID_FAIL: (s, a) => {
        const { idsByDefinition } = a.meta.actionContext;
        const definitionsIds = Object.keys(idsByDefinition);
        for (const definitionId of definitionsIds) {
            if (!s[definitionId]) {
                s[definitionId] = {};
            }
            const ids = idsByDefinition[definitionId];
            ids.forEach((id) => {
                const status = s[definitionId][id] || {};
                status.pending = false;
                status.failed = true;
                if (status.responseFields) {
                    delete status.responseFields;
                }
                s[definitionId][id] = status;
            });
        }
    },
    CMS__SEARCH_ITEMS_SUCCESS: (s, a) => {
        const { definitionId, responseFields } = a.meta.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        a.payload.results.forEach((item) => {
            const status = s[definitionId][item.entityId] || {};
            status.pending = false;
            status.failed = false;
            status.lastFetch = new Date();
            status.responseFields = uniq([
                ...((status.responseFields && status.responseFields) || []),
                ...responseFields
            ]);
            s[definitionId][item.entityId] = status;
        });
    },
    CMS__DELETE_ITEMS_SUCCESS: (s, a) => {
        const { definitionId, ids } = a.meta.actionContext;
        ids?.forEach((id) => {
            if (has(s, [definitionId, id])) {
                delete s[definitionId][id];
            }
        });
    },
    WORKFLOWS__DELETE_PROCESSES_SUCCESS: (s, a) => {
        const { processMetaId, processRunIds } = a.meta.actionContext;
        processRunIds.forEach((processRunId) => {
            if (has(s, [processMetaId, processRunId])) {
                delete s[processMetaId][processRunId];
            }
        });
    },
    CMS__CREATE_ITEM: (s, a) => {
        const { definitionId } = a.meta.http.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        s[definitionId][a.meta.http.actionContext.id] = {
            pending: true,
            failed: false,
            responseFields: ['*']
        };
    },
    CMS__CREATE_ITEM_SUCCESS: (s, a) => {
        const { id, definitionId } = a.meta.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        const status = s[definitionId][id] || {};
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[definitionId][id] = status;
    },
    CMS__CREATE_ITEM_FAIL: (s, a) => {
        const { id, definitionId } = a.meta.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        const status = s[definitionId][id] || {};
        status.pending = false;
        status.failed = true;
        if (status.responseFields) {
            delete status.responseFields;
        }
        s[definitionId][id] = status;
    }
});
const items = combineReducers({
    byId: itemsById,
    status: itemsStatus
});
const byId = createReducer({}, {
    ENTITY_DATA__FETCH_SINGLE_SUCCESS: (s, a) => {
        const { entityId, definitionId } = a.meta.actionContext;
        const dataList = a.payload;
        if (dataList) {
            if (!s[definitionId]) {
                s[definitionId] = {};
            }
            s[definitionId][entityId] = {
                ...dataList,
                // @ts-expect-error
                data: dataList.data.map((section) => ({
                    ...section,
                    values: section.values.map((displayData) => getPropertyKey(definitionId, entityId, displayData.propertyId))
                }))
            };
        }
    }
});
const status = createReducer({}, {
    ENTITY_DATA__FETCH_SINGLE: (s, a) => {
        const { entityId, definitionId } = a.meta.http.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        const status = s[definitionId][entityId] || {};
        status.pending = true;
        status.failed = false;
        s[definitionId][entityId] = status;
    },
    ENTITY_DATA__FETCH_SINGLE_FAIL: (s, a) => {
        const { entityId, definitionId } = a.meta.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        const status = s[definitionId][entityId];
        status.pending = false;
        if (a.payload && a.payload.status === 204) {
            status.lastFetch = new Date();
            status.failed = false;
        }
        else {
            status.failed = true;
        }
        s[definitionId][entityId] = status;
    },
    ENTITY_DATA__FETCH_SINGLE_SUCCESS: (s, a) => {
        const { entityId, definitionId } = a.meta.actionContext;
        if (!s[definitionId]) {
            s[definitionId] = {};
        }
        const status = s[definitionId][entityId];
        status.pending = false;
        status.failed = false;
        status.lastFetch = new Date();
        s[definitionId][entityId] = status;
    }
});
const dataListsReducer = combineReducers({
    byId,
    status
});
const displayDataById = createReducer({}, {
    ENTITY_DATA__FETCH_SINGLE_SUCCESS: (s, a) => {
        if (a.payload) {
            const { entityId, timeStamp, definitionId } = a.meta.actionContext;
            a.payload.data.forEach((section) => {
                section.values.forEach((displayData) => {
                    const key = getPropertyKey(definitionId, entityId, displayData.propertyId || '');
                    if (!s[key] || timeStamp > (s[key].timeStamp || 0)) {
                        s[key] = {
                            ...displayData,
                            timeStamp: s[key] ? s[key].timeStamp : 0
                        };
                    }
                });
            });
        }
    },
    ENTITY_DATA__UPDATE_VALUE: (s, a) => {
        const { entityId, propertyId, timeStamp, definitionId } = a.meta.http.actionContext;
        const subState = s[getPropertyKey(definitionId, entityId, propertyId)];
        if (subState) {
            subState.value = a.payload.value;
            subState.timeStamp = timeStamp;
        }
    },
    ENTITY_DATA__UPDATE_VALUE_SUCCESS: (s, a) => {
        const { entityId, propertyId, value, timeStamp, definitionId } = a.meta.actionContext;
        const subState = s[getPropertyKey(definitionId, entityId, propertyId)];
        if (subState && timeStamp >= (subState.timeStamp || 0)) {
            subState.value = value.value;
        }
    }
});
const displayDatasReducer = combineReducers({
    byId: displayDataById
});
export const getPropertyKey = (definitionId, entityId, propertyId) => `${definitionId}_${entityId}_${propertyId}`;
const propertiesById = createReducer({}, {
    ENTITY_DATA__FETCH_PROPERTY_SINGLE_SUCCESS: (s, a) => {
        const { definitionId, entityId } = a.meta.actionContext;
        if (Array.isArray(a.payload)) {
            a.payload.forEach((property) => {
                const key = getPropertyKey(definitionId, entityId, property.propertyId);
                s[key] = property;
            });
        }
    },
    ENTITY_DATA__UPDATE_VALUE_SUCCESS: (s, a) => {
        const { definitionId, entityId, propertyId } = a.meta.actionContext;
        const key = getPropertyKey(definitionId, entityId, propertyId);
        delete s[key];
    }
});
const propertiesStatusById = createReducer({}, {
    ENTITY_DATA__FETCH_PROPERTY_SINGLE: (s, a) => {
        const { definitionId, entityId, propertyId } = a.meta.http.actionContext;
        if (propertyId) {
            const key = getPropertyKey(definitionId, entityId, propertyId);
            const status = s[key] || {};
            status.pending = true;
            status.failed = false;
            s[key] = status;
        }
    },
    ENTITY_DATA__FETCH_PROPERTY_SINGLE_SUCCESS: (s, a) => {
        const { definitionId, entityId } = a.meta.actionContext;
        if (Array.isArray(a.payload)) {
            a.payload.forEach((property) => {
                const key = getPropertyKey(definitionId, entityId, property.propertyId);
                const status = s[key] || {};
                status.pending = false;
                status.failed = false;
                status.lastFetch = new Date();
                s[key] = status;
            });
        }
    },
    ENTITY_DATA__UPDATE_VALUE_SUCCESS: (s, a) => {
        const { definitionId, entityId, propertyId } = a.meta.actionContext;
        const key = getPropertyKey(definitionId, entityId, propertyId);
        delete s[key];
    }
});
const propertiesDatasReducer = combineReducers({
    byId: propertiesById,
    status: propertiesStatusById
});
const properties = combineReducers({
    dataLists: dataListsReducer,
    displayData: displayDatasReducer,
    properties: propertiesDatasReducer
});
export const getItemKey = (definitionId, entityId) => [definitionId, entityId].join('_');
const itemReferralsById = createReducer({}, {
    CMS__FETCH_ITEM_REFERRALS_SUCCESS: (s, a) => {
        const { definitionId, entityId } = a.meta.actionContext;
        const key = getItemKey(definitionId, entityId);
        s[key] = a.payload;
    },
    CMS__DELETE_ITEMS_SUCCESS: (s, a) => {
        const { definitionId, ids } = a.meta.actionContext;
        ids?.forEach((id) => {
            const key = getItemKey(definitionId, id);
            if (s[key]) {
                delete s[key];
            }
        });
    },
    WORKFLOWS__DELETE_PROCESSES_SUCCESS: (s, a) => {
        const { processMetaId, processRunIds } = a.meta.actionContext;
        processRunIds.forEach((processRunId) => {
            const key = getItemKey(processMetaId, processRunId);
            if (s[key]) {
                delete s[key];
            }
        });
    }
});
const itemReferralsStatusById = createReducer({}, {
    CMS__FETCH_ITEM_REFERRALS: (s, a) => {
        const { definitionId, entityId } = a.meta.http.actionContext;
        const key = getItemKey(definitionId, entityId);
        const status = s[key] || {};
        status.pending = true;
        status.failed = false;
        s[key] = status;
    },
    CMS__FETCH_ITEM_REFERRALS_SUCCESS: (s, a) => {
        const { definitionId, entityId } = a.meta.actionContext;
        const key = getItemKey(definitionId, entityId);
        const status = s[key];
        status.pending = false;
        status.failed = false;
        s[key] = status;
    },
    CMS__FETCH_ITEM_REFERRALS_FAIL: (s, a) => {
        const { definitionId, entityId } = a.meta.actionContext;
        const key = getItemKey(definitionId, entityId);
        const status = s[key];
        status.pending = false;
        status.failed = true;
        s[key] = status;
    },
    CMS__DELETE_ITEMS_SUCCESS: (s, a) => {
        const { definitionId, ids } = a.meta.actionContext;
        ids?.forEach((id) => {
            const key = getItemKey(definitionId, id);
            if (s[key]) {
                delete s[key];
            }
        });
    },
    WORKFLOWS__DELETE_PROCESSES_SUCCESS: (s, a) => {
        const { processMetaId, processRunIds } = a.meta.actionContext;
        processRunIds.forEach((processRunId) => {
            const key = getItemKey(processMetaId, processRunId);
            if (s[key]) {
                delete s[key];
            }
        });
    }
});
const itemReferrals = combineReducers({
    byId: itemReferralsById,
    status: itemReferralsStatusById
});
export default combineReducers({
    items,
    properties,
    itemReferrals
});
