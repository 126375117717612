import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback } from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { ComputedExpressionsDocumentationLink } from '../../../../../common/components/documentation-link';
import { ItemMetaSelector } from '../../../../../common/components/entity-selectors';
import GroupsField from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
import { isProcess } from '../../../utils';
import ProcessPropertyMappingsField from './ProcessPropertyMappingsField';
const StartProcessForm = ({ disabled, parentViewGroups, metaDefinitionId, node, changeFieldValue }) => {
    const { t } = useI18n();
    const handleChangeProcessMetaId = useCallback((_, newValue, previousValue, name) => {
        if (newValue != previousValue) {
            // Reset the property mappings that were based on the previously selected process
            const propertyMappingsFieldName = name?.replace('processMetaId', 'propertyMappings');
            if (propertyMappingsFieldName) {
                changeFieldValue(propertyMappingsFieldName, {});
            }
        }
    }, [changeFieldValue]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", placeholder: t('COMMON__TITLE'), name: "titles", component: AntInput, disabled: disabled })),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('PROCESS'), className: "ps-0" }),
            React.createElement(Field, { name: "processMetaId", 
                // @ts-expect-error error types
                component: ItemMetaSelector, includeItemMeta: isProcess, placeholder: `${t('SEARCH')} ${t('SEARCH__PROCESS')}`, disabled: disabled, validate: required, onChange: handleChangeProcessMetaId })),
        node.parameters?.processMetaId ? (React.createElement("div", { className: "d-flex flex-column align-items-stretch mt-2" },
            React.createElement(FieldLabel, { label: t('PROCESS_RUN_MAPPINGS'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "ps-0" }),
            React.createElement(FormSection, { name: "parameters" },
                React.createElement(Field, { name: "propertyMappings", component: ProcessPropertyMappingsField, definitionId: node.parameters.processMetaId, definitionIdForComputedExpression: metaDefinitionId, includeNestedProperties: false, disabled: disabled })))) : null,
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default StartProcessForm;
