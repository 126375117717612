import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { ENTITY_ID_PROPERTY_ID } from '../../../cms';
import { isPropertyList } from '../../../cms/common/context/PropertiesProvider';
import SelectPropertyButton from '../../../cms/data/components/item-property/SelectProperty/SelectPropertyButton';
import { AVAILABLE_GROUPING_ROWS } from '../../../cms/data/components/items/utils';
import { TermsAggregationFilterOrderBy } from '../../../cms/hooks/useAggregation';
import { DrawerContentBlockSubTitle } from '../../../common/components/Drawer/DrawerContentBlockSubTitle';
import SelectField from '../../../common/components/Select/SelectField';
export const GroupingRow = ({ input: { value, onChange }, asFilter = false, definitionId, includeNestedProperties = false, labelGroupingBy, children }) => {
    const { t } = useI18n();
    const _onChange = useCallback((field) => {
        onChange(field);
    }, [onChange]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectPropertyButton, { asFilter: asFilter, includeNestedProperties: includeNestedProperties, label: t('VIEWS__GROUPING_ROW'), definitionId: definitionId, onChange: _onChange, includeProperty: (displayData) => !isPropertyList(displayData) &&
                AVAILABLE_GROUPING_ROWS.includes(displayData.type) &&
                displayData.propertyId !== ENTITY_ID_PROPERTY_ID, value: value }),
        value && (React.createElement(React.Fragment, null,
            React.createElement(DrawerContentBlockSubTitle, null, labelGroupingBy || t('VIEWS__GROUPING_ROW_ORDER_BY')),
            React.createElement(Field, { name: "rowGroupingOrderBy", component: SelectField, options: Object.values(TermsAggregationFilterOrderBy).map((orderBy) => ({
                    value: orderBy,
                    label: t(`VIEWS__GROUPING_ROW_ORDER_BY_${orderBy}`)
                })) }),
            children))));
};
