import { compact, isArray } from 'lodash';
import { EntityListDisplayView, EntitySingleDisplayView, isPropertyItemMeta } from '../../types';
import CheckboxDisplay from './display/CheckboxDisplay';
import DateDisplay from './display/DateDisplay';
import EmailDisplay from './display/EmailDisplay';
import EntityDisplay from './display/EntityDisplay';
import FileDisplay from './display/FileDisplay';
import JsonDisplay from './display/JsonDisplay';
import LinkDisplay from './display/LinkDisplay';
import NumberDisplay from './display/NumberDisplay';
import PhoneNumberDisplay from './display/PhoneNumberDisplay';
import PlainTextDisplay from './display/PlainTextDisplay';
import RichTextDisplay from './display/RichTextDisplay';
import SelectDisplay from './display/SelectDisplay';
import CheckboxEditor from './editors/CheckboxEditor';
import DateEditor from './editors/DateEditor';
import EmailEditor from './editors/EmailEditor';
import EntityEditor from './editors/EntityEditor';
import JsonEditor from './editors/JsonEditor';
import LinkEditor from './editors/LinkEditor';
import MultiTextEditor from './editors/MultiTextEditor';
import NumberEditor from './editors/NumberEditor';
import PhoneNumberEditor from './editors/PhoneNumberEditor';
import PlainTextEditor from './editors/PlainTextEditor';
import RichTextEditor from './editors/RichTextEditor';
import SelectEditor from './editors/SelectEditor';
const editorComponents = {
    text: PlainTextEditor,
    richtext: RichTextEditor,
    multitext: MultiTextEditor,
    number: NumberEditor,
    date: DateEditor,
    select: SelectEditor,
    entity: EntityEditor,
    link: LinkEditor,
    checkbox: CheckboxEditor,
    phoneNumber: PhoneNumberEditor,
    email: EmailEditor,
    json: JsonEditor
};
const displayComponents = {
    text: PlainTextDisplay,
    richtext: RichTextDisplay,
    multitext: PlainTextDisplay,
    number: NumberDisplay,
    date: DateDisplay,
    select: SelectDisplay,
    entity: EntityDisplay,
    file: FileDisplay,
    link: LinkDisplay,
    checkbox: CheckboxDisplay,
    phoneNumber: PhoneNumberDisplay,
    email: EmailDisplay,
    json: JsonDisplay
};
export const getEditorComponentForType = (type) => editorComponents[type] || PlainTextEditor;
export const getDisplayComponentForType = (type) => displayComponents[type] || PlainTextDisplay;
export const getFilePropertyValueList = (value) => {
    if (value) {
        if (isArray(value)) {
            return compact(value);
        }
        return [value];
    }
    return [];
};
export const isPropertyList = (meta) => {
    return isPropertyItemMeta(meta) && !!meta.list;
};
export const isEntityInSingleMode = (meta) => {
    return !(meta && 'list' in meta ? meta.list : false);
};
export const isDisplayEntityAsTable = (meta) => {
    return (isPropertyItemMeta(meta) &&
        !!meta.list &&
        !!meta.selector?.displayView &&
        meta.selector.displayView === EntityListDisplayView.table);
};
export const isExpandedView = (meta) => {
    return (isPropertyItemMeta(meta) &&
        meta.selector?.displayView === EntitySingleDisplayView.expandedView &&
        !!meta.selector.expandedView?.properties?.length);
};
export const titleValidations = {
    maxLength: 60
};
