import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import NotFound from '@hypercharge/hyper-react-base/lib/common/not-found';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { replace } from '@hypercharge/hyper-react-base/lib/router';
import { EMAIL_TEMPLATES_DEFINITION_ID } from '@hypercharge/portal-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { MdPhonelinkErase } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../auth';
import useCreateItem from '../../../../cms/hooks/useCreateItem';
import useDeleteItems from '../../../../cms/hooks/useDeleteItems';
import { useEntityItem } from '../../../../cms/hooks/useEntityItem';
import { useBrowserBreakpoints } from '../../../../common/components/with-browser-breakpoints';
import { APP_ADMIN_PERMISSION, MAIL_TEMPLATES_PREVIEW_ON_CLIENTS_PERMISSION } from '../../../../common/utils/constants';
import { MARKETING_PATH } from '../../../common/utils/constants';
import { generateCrossClientsPreview, sendTestEmail } from '../../actions';
import { EMAIL_TEMPLATES_PATH } from '../../constants';
import useAuthenticateStripo from '../../hooks/useAuthenticateStripo';
import TemplateForm from './TemplateForm';
import { defaultTemplate } from './utils';
const TemplateFormContainer = ({ templateId }) => {
    const { t } = useI18n();
    const { isMobile } = useBrowserBreakpoints();
    const dispatch = useDispatch();
    const { mutateAsync: emailTemplatesCreateItem } = useCreateItem({
        definitionId: EMAIL_TEMPLATES_DEFINITION_ID
    });
    const { mutateAsync: emailTemplatesDeleteItems } = useDeleteItems({
        definitionId: EMAIL_TEMPLATES_DEFINITION_ID
    });
    const dispatchSendTestEmail = useDispatch();
    const dispatchGenerateCrossClientsPreview = useDispatch();
    const [breadcrumbsContainer, setBreadcrumbsContainer] = useState();
    const hasAdminPermission = useSelector((s) => hasTenantPermission(s, APP_ADMIN_PERMISSION));
    const hasCrossClientPreviewPermission = useSelector((s) => hasTenantPermission(s, MAIL_TEMPLATES_PREVIEW_ON_CLIENTS_PERMISSION));
    const breadcrumbs = useMemo(() => {
        return [
            { text: t('TEMPLATES_NAV_LINK'), to: MARKETING_PATH },
            {
                text: t('EMAILS_NAV_LINK'),
                to: `${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}`
            }
        ];
    }, [t]);
    const isNew = !templateId;
    const title = isNew ? t('CREATE_TEMPLATE__TITLE') : t('EDIT_TEMPLATE__TITLE');
    const { data: template = defaultTemplate, isError, isFetching } = useEntityItem(EMAIL_TEMPLATES_DEFINITION_ID, templateId, ['title', 'image', 'configuration']);
    const _sendTestEmail = useCallback((id, email, language) => {
        return dispatchSendTestEmail(sendTestEmail(id, email, language));
    }, [dispatchSendTestEmail]);
    const emitErrorNotification = useCallback((config) => {
        dispatch(error(config));
    }, [dispatch]);
    const { data: authDataStripo, isPending: isPendingLoginStripo, isFailed: isFailedLoginStripo } = useAuthenticateStripo();
    const _generateCrossClientPreview = useCallback((id, language) => {
        return dispatchGenerateCrossClientsPreview(generateCrossClientsPreview(id, language));
    }, [dispatchGenerateCrossClientsPreview]);
    const _saveTemplate = useCallback(async (template) => {
        try {
            const response = await emailTemplatesCreateItem(template);
            dispatch(replace(`${MARKETING_PATH}${EMAIL_TEMPLATES_PATH}/${response.entityId}`));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('SAVE_TEMPLATE__SUCCESS_MESSAGE')
            }));
            return response;
        }
        catch (err) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('SAVE_TEMPLATE__ERROR_MESSAGE')
            }));
        }
    }, [dispatch, emailTemplatesCreateItem, t]);
    const _deleteTemplate = useCallback(async (id) => {
        try {
            await emailTemplatesDeleteItems({
                ids: [id]
            });
            dispatch(replace([MARKETING_PATH, EMAIL_TEMPLATES_PATH].join('')));
            dispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('DELETE_TEMPLATE__SUCCESS_MESSAGE')
            }));
        }
        catch (err) {
            dispatch(error({
                title: t('COMMON__FAILURE'),
                message: t('DELETE_TEMPLATE__ERROR_MESSAGE')
            }));
        }
    }, [dispatch, emailTemplatesDeleteItems, t]);
    if (isMobile) {
        return (React.createElement(NotFound, { style: {
                height: `calc(100% - ${breadcrumbsContainer ? breadcrumbsContainer.offsetHeight : 0}px)`
            }, title: React.createElement(MdPhonelinkErase, null), content: t('TEMPLATES_EDITOR_UNAVAILABLE') }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: setBreadcrumbsContainer },
            React.createElement(Breadcrumbs, { links: breadcrumbs, currentBreadcrumbText: title })),
        isError ? (React.createElement(NotFound, null)) : template && !isFetching ? (React.createElement(TemplateForm, { hasAdminPermission: hasAdminPermission, hasCrossClientPreviewPermission: hasCrossClientPreviewPermission, template: template, isNew: isNew, save: _saveTemplate, emitErrorNotification: emitErrorNotification, deleteTemplate: _deleteTemplate, sendTestEmail: _sendTestEmail, generateCrossClientPreview: _generateCrossClientPreview, authDataStripo: authDataStripo, isPendingLoginStripo: isPendingLoginStripo, isFailedLoginStripo: isFailedLoginStripo })) : (React.createElement(LoadingRectangles, null))));
};
export default TemplateFormContainer;
