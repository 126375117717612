import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useEntityDisplayData } from '../../../cms/common/components/withEntityDisplayData';
import { onErrorUpdatePropertyHandler, onSettledUpdatePropertyHandler } from '../../../cms/hooks/useUpdateProperty';
import { useDisplayTenant } from '../../../tenant/useDisplayTenant';
import { updateTaskRunField } from '../actions';
const MUTATION_KEY = 'UpdateTaskRunField';
export const useUpdateTaskRunField = ({ processMetaId, processRunId, taskMetaId, taskRunId }) => {
    const { language, t } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const { displayDataWithHidden } = useEntityDisplayData(processMetaId);
    const updatePropertyDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    return useMutation([MUTATION_KEY], ({ entityId, propertyId, value }) => {
        return updatePropertyDispatch(updateTaskRunField(processMetaId, processRunId, taskMetaId, taskRunId, {
            entityId,
            propertyId,
            value
        }, language));
    }, {
        onError: onErrorUpdatePropertyHandler({
            displayTenant,
            displayDataWithHidden,
            language,
            t,
            notificationDispatch
        }),
        onSettled: (_, __, updatePropertyArgs) => {
            let definitionId = processMetaId;
            let propertyId = updatePropertyArgs.propertyId;
            const [baseDefinitionId, nestedPropertyId] = propertyId.split('.');
            if (nestedPropertyId) {
                definitionId = baseDefinitionId;
                propertyId = nestedPropertyId;
            }
            onSettledUpdatePropertyHandler({ definitionId, ...updatePropertyArgs, propertyId });
        }
    });
};
