import { FilterOperatorTypes } from '@hypercharge/portal-utils';
import { omit, toString } from 'lodash';
import React, { useCallback } from 'react';
import EntityEditor from '../../../cms/data/components/item-property/editors/EntityEditor';
import { isComputedExpression } from '../../../utils/computedExpression';
import { ComputedInput } from '../ComputedExpressionEditor';
import BaseFilter from './BasicFilter';
import { buildFilter } from './utils';
const availableOperators = [
    { value: FilterOperatorTypes.empty },
    { value: FilterOperatorTypes.notEmpty }
];
const availableOperatorsNonList = [
    ...availableOperators,
    { value: FilterOperatorTypes.is },
    { value: FilterOperatorTypes.isNot }
];
const availableOperatorsForList = [
    ...availableOperators,
    { value: FilterOperatorTypes.contains },
    { value: FilterOperatorTypes.notContains }
];
const EntityFilter = ({ className, filter, onChange, meta, showSelectOperator, disabledSelectOperator, disabled, allowComputed, propertiesTree, autoFocus = false }) => {
    const data = 'data' in filter ? toString(filter.data) : undefined;
    const onChangeInput = useCallback((value) => {
        value = Array.isArray(value) ? value[0] : value;
        const newFilter = buildFilter(filter.field, filter.operator, value);
        onChange(newFilter);
    }, [filter.field, filter.operator, onChange]);
    const operators = 'list' in meta && meta.list ? availableOperatorsForList : availableOperatorsNonList;
    return (React.createElement(BaseFilter, { onChange: onChange, operators: operators, filter: filter, showSelectOperator: showSelectOperator, disabledSelectOperator: disabledSelectOperator, className: className, disabled: disabled }, allowComputed || isComputedExpression(data) ? (React.createElement(ComputedInput, { component: EntityEditor, value: data, onChange: onChangeInput, meta: omit(meta, 'list'), disabled: disabled, autoFocus: autoFocus, simpleClear: true, propertiesTree: propertiesTree, disableWrapperHover: true, editing: false })) : (React.createElement(EntityEditor, { value: data, onChange: onChangeInput, 
        // @ts-expect-error
        meta: omit(meta, 'list'), disabled: disabled, autoFocus: autoFocus, simpleClear: true }))));
};
export default EntityFilter;
