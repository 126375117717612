import { Badge } from 'antd';
import React, { useMemo } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { getBadgeCount } from '../../utils/common';
import DropdownButton from '../DropdownButton';
import { useBrowserBreakpoints } from '../with-browser-breakpoints';
import ConfirmationCloseButton from './ConfirmationCloseButton';
import ItemStickyTab from './ItemStickyTab';
import styles from './StickyTabs.module.scss';
import { useStickyTabs } from './StickyTabsProvider';
const StickyTabs = () => {
    const { activeTabs, changeOverflowTabs, filteredTab, maxCountActiveTabs } = useStickyTabs();
    const { isMobile } = useBrowserBreakpoints();
    const overflowTabs = useMemo(() => (isMobile ? activeTabs : activeTabs.slice(maxCountActiveTabs, activeTabs.length)), [activeTabs, maxCountActiveTabs, isMobile]);
    const overflowTabsCount = useMemo(() => getBadgeCount(overflowTabs.length), [overflowTabs.length]);
    const overflowTabsMenu = useMemo(() => ({
        className: `${styles.overflowTabsContainer}`,
        items: overflowTabs.map(({ id, title, Icon, isDirty }) => ({
            key: title,
            icon: (React.createElement("div", { className: "m-0 position-relative flex-shrink-0" },
                Icon,
                isDirty && (React.createElement("span", { className: "dirty-indicator rounded-circle border border-2 border-white position-absolute top-0 start-100 translate-middle" })))),
            onClick: () => changeOverflowTabs(id),
            label: (React.createElement("p", { className: "m-0 d-flex align-items-center ps-2" },
                React.createElement("p", { className: "w-100 mb-0 overflow-hidden text-nowrap text-ellipsis" }, title),
                React.createElement(ConfirmationCloseButton, { isShowModal: isDirty, onClose: () => filteredTab(id) })))
        }))
    }), [changeOverflowTabs, filteredTab, overflowTabs]);
    if (!activeTabs.length) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.activeTabsContainer} bottom-0 w-100 d-flex position-fixed overflow-visible flex-column-reverse flex-md-row-reverse flex-nowrap align-items-end justify-content-start` },
        activeTabs.map((props, index) => (React.createElement(ItemStickyTab, { ...props, index: index, key: props.id }))),
        !!overflowTabs.length && (React.createElement(DropdownButton, { renderToParent: true, placement: "topLeft", className: "overflow-menu rounded-top position-relative cursor-pointer", menuIcon: React.createElement(Badge, { count: overflowTabsCount },
                React.createElement(BsThreeDotsVertical, { size: 20 })), menu: overflowTabsMenu }))));
};
export default StickyTabs;
