import { pick } from 'lodash';
import queryString from 'query-string';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { NO_PERMISSIONS_LANDING_PAGE_PATH, REQUIRED_PORTAL_PERMISSION } from '../common/utils/constants';
import { HOME_PATH } from '../home';
import { AUTH_EMAIL_PARAM, AUTH_PATH, OAUTH2_PATH } from './constants';
import { getAuthJwt, getIsUiAuthenticated, hasTenantPermission } from './selectors';
const locationHelper = locationHelperBuilder({});
const getRedirectPath = (location) => {
    if (location && location.search) {
        const search = queryString.stringify(pick(queryString.parse(location.search), [AUTH_EMAIL_PARAM]));
        return `${AUTH_PATH}?${search}`;
    }
    return AUTH_PATH;
};
export const redirectIfAuthenticated = (component) => {
    const fn = connectedRouterRedirect({
        // The user is redirected if this returns false
        authenticatedSelector: (s) => !getIsUiAuthenticated(s),
        redirectPath: (s, ownProps) => {
            // The user is authenticated
            if (!hasTenantPermission(s, REQUIRED_PORTAL_PERMISSION)) {
                return NO_PERMISSIONS_LANDING_PAGE_PATH;
            }
            const jwt = getAuthJwt(s);
            if (!jwt) {
                // this output should never be used since the redirectPath gets used after being authenticated
                return getRedirectPath(ownProps.location);
            }
            // if after we have a valid auth we are redirecting to /auth
            // then this is related to the bug that has double auth redirect
            let redirectPath = locationHelper.getRedirectQueryParam(ownProps);
            if (redirectPath &&
                redirectPath.startsWith(AUTH_PATH) &&
                !redirectPath.startsWith(`${AUTH_PATH}${OAUTH2_PATH}`)) {
                redirectPath = null;
            }
            return redirectPath || HOME_PATH;
        },
        // This prevents us from adding the query parameter when we send the user away from the login page
        allowRedirectBack: false,
        wrapperDisplayName: 'redirectIfAuthenticated'
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return fn(component);
};
export const redirectIfNoPermission = (component) => {
    const fn = connectedRouterRedirect({
        authenticatedSelector: (s, { permission }) => {
            // The user is redirected if this returns false
            return (hasTenantPermission(s, REQUIRED_PORTAL_PERMISSION) && hasTenantPermission(s, permission));
        },
        redirectPath: (s, { location }) => {
            return getIsUiAuthenticated(s) ? NO_PERMISSIONS_LANDING_PAGE_PATH : getRedirectPath(location);
        },
        wrapperDisplayName: 'redirectIfNoPermission'
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return fn(component);
};
