import React from 'react';
import WithResizable from '../../../../../common/components/WithResizable/WithResizable';
import { useBrowserBreakpoints } from '../../../../../common/components/with-browser-breakpoints';
import { ProcessSideContent } from './ProcessSideContent';
import styles from './ProcessSideContent.module.scss';
const MIN_WIDTH_RIGHT_BLOCK = 400;
const ProcessSideContentWrapper = ({ isSidebarActive, parentWidth, ...otherProps }) => {
    const { isMobile } = useBrowserBreakpoints();
    return (React.createElement("div", { className: `${styles.sideContentWrapper} ${isSidebarActive ? '' : styles.sideContentWrapper__hidden}` },
        React.createElement(WithResizable, { minWidth: MIN_WIDTH_RIGHT_BLOCK, enabled: isSidebarActive, uniqKey: otherProps.processMetaId, parentWidth: parentWidth },
            React.createElement(ProcessSideContent, { ...otherProps, isSidebarActive: !isMobile && isSidebarActive, showToggleButton: true }))));
};
export default ProcessSideContentWrapper;
