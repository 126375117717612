import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addSubscription, removeSubscription } from '../actions';
import { addDataChangedCallback, removeDataChangedCallback } from '../sessionMiddlewareFactory';
const SessionSubscription = ({ subscriptionKey }) => {
    const dispatchAdd = useDispatch();
    const dispatchRemove = useDispatch();
    useEffect(() => {
        dispatchAdd(addSubscription(subscriptionKey));
        return () => {
            dispatchRemove(removeSubscription(subscriptionKey));
        };
    }, [dispatchAdd, dispatchRemove, subscriptionKey]);
    return null;
};
export default SessionSubscription;
export const useSessionSubscriptionCallback = ({ messageKey, callback }) => {
    useEffect(() => {
        const callbackDebounce = debounce(callback, 500);
        addDataChangedCallback(messageKey, callbackDebounce);
        return () => {
            removeDataChangedCallback(messageKey, callbackDebounce);
        };
    }, [messageKey, callback]);
};
