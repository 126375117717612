import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tabs } from 'antd';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import FeatureFlagsOptionsForm, { getFeatureFlagsOptionsFormSchema } from './components/FeatureFlagsOptionsForm';
import GeneralOptionsForm, { getGeneralFormSchema } from './components/GeneralOptionsForm';
import WhitelabelOptionsForm, { getWhitelabelFormSchema } from './components/WhitelabelOptionsForm';
export const initialValues = {
    enabled: true,
    configuration: {}
};
var AvailableTabs;
(function (AvailableTabs) {
    AvailableTabs["GENERAL"] = "GENERAL";
    AvailableTabs["WHITELABEL"] = "WHITELABEL";
    AvailableTabs["FEATURE_FLAGS"] = "FEATURE_FLAGS";
})(AvailableTabs || (AvailableTabs = {}));
const isTabAvailable = (tabName) => !!AvailableTabs[tabName];
export const getFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.boolean().default(true),
        title: Yup.string().default('Unnamed'),
        configuration: Yup.object({
            ...getGeneralFormSchema(),
            ...getWhitelabelFormSchema(),
            ...getFeatureFlagsOptionsFormSchema()
        }).required()
    });
};
const Form = ({ instance, setFieldValue, disabled }) => {
    const { t } = useI18n();
    const [activeTab, setActiveTab] = useState(AvailableTabs.GENERAL);
    const onChangeTab = useCallback((tabToActivate) => {
        if (isTabAvailable(tabToActivate)) {
            setActiveTab(tabToActivate);
        }
    }, []);
    return (React.createElement(Tabs, { activeKey: activeTab, onChange: onChangeTab },
        React.createElement(Tabs.TabPane, { tab: t('APPS_HYPER_EVENT__GENERAL'), key: AvailableTabs.GENERAL },
            React.createElement(GeneralOptionsForm, { configuration: instance.configuration, setFieldValue: setFieldValue, disabled: disabled })),
        React.createElement(Tabs.TabPane, { tab: t('APPS_HYPER_EVENT__WHITELABEL'), key: AvailableTabs.WHITELABEL },
            React.createElement(WhitelabelOptionsForm, { configuration: instance.configuration.whiteLabel, setFieldValue: setFieldValue, disabled: disabled })),
        React.createElement(Tabs.TabPane, { tab: t('APPS_HYPER_EVENT__FEATURES'), key: AvailableTabs.FEATURE_FLAGS },
            React.createElement(FeatureFlagsOptionsForm, { configuration: instance.configuration.featureFlags, setFieldValue: setFieldValue, disabled: disabled }))));
};
export default Form;
