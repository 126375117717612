import { CircularLoader } from '@hypercharge/hyper-react-base/lib/common';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdReply } from 'react-icons/md';
import posed, { PoseGroup } from 'react-pose';
import styles from './Conversation.module.scss';
import EmptyComments from './EmptyComments';
import Message from './Message';
const Item = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 }
});
const Conversation = ({ className, loading, messages, accountId, messagePlaceholder, deleteMessage, createMessage }) => {
    const { t } = useI18n();
    const [replyingTo, setReplyingTo] = useState(null);
    const [deleting, setDeleting] = useState({});
    const [saving, setSaving] = useState(false);
    const existedMessagesOuterRef = useRef(null);
    const toggleDeleting = useCallback((id) => setDeleting({ ...deleting, [id]: !deleting[id] }), [deleting]);
    const deleteMessageHandler = useCallback(async (id) => {
        toggleDeleting(id);
        await deleteMessage(id);
        toggleDeleting(id);
    }, [toggleDeleting, deleteMessage]);
    const saveMessage = useCallback(async (content, mentions, replyToMessageId) => {
        setSaving(true);
        await createMessage(accountId, content, mentions, replyToMessageId);
        setSaving(false);
        if (!replyToMessageId && existedMessagesOuterRef.current) {
            existedMessagesOuterRef.current.scrollTop = existedMessagesOuterRef.current.scrollHeight;
        }
    }, [createMessage, accountId, existedMessagesOuterRef]);
    const getMessageJsx = useCallback((message) => {
        const deleted = deleting[message.id];
        const replying = message.id === replyingTo;
        return (React.createElement("div", { key: message.id, className: "message-container MessageContainerOuter" },
            React.createElement(Message, { contactId: message.authorId, disabled: loading, message: message }),
            !message.hidden && !replying && (React.createElement("div", { className: "actions-outer" },
                !deleted && (React.createElement(Button, { onClick: () => setReplyingTo(message.id), className: "action-link" },
                    React.createElement("span", null, t('MESSAGE_REPLY')))),
                accountId == message.authorId && (React.createElement(Button, { onClick: () => deleteMessageHandler(message.id), className: "action-link delete-button" }, !deleted ? (React.createElement("span", null, t('MESSAGE_DELETE'))) : (React.createElement(CircularLoader, { className: "conversation-loader" })))))),
            React.createElement(PoseGroup, null, message.replies.map((m) => (React.createElement(Item, { key: m.id }, getMessageJsx(m))))),
            replying && (React.createElement("div", { className: "cp-c-row" },
                React.createElement(MdReply, { className: "reply-icon" }),
                React.createElement("div", { className: "message-container cp-i-flex replying" },
                    React.createElement(Message, { className: "ReplyingMessageOuter", contactId: accountId, editable: true, disabled: loading || saving, placeholder: messagePlaceholder, onSave: async (content, mentions) => {
                            await saveMessage(content, message.authorId == accountId
                                ? mentions
                                : [...mentions, ['account', message.authorId].join('__')], message.id);
                            setReplyingTo(null);
                        }, onCancel: () => setReplyingTo(null), replying: replying }))))));
    }, [
        accountId,
        deleteMessageHandler,
        deleting,
        loading,
        messagePlaceholder,
        replyingTo,
        saveMessage,
        saving,
        t
    ]);
    useEffect(() => {
        if (existedMessagesOuterRef.current) {
            existedMessagesOuterRef.current.scrollTop = existedMessagesOuterRef.current.scrollHeight;
        }
    }, [existedMessagesOuterRef]);
    return (React.createElement("div", { className: `${styles.container} ${className || ''} ConversationContainerOuter` },
        React.createElement("div", { className: "existing-messages ExistingMessagesOuter", ref: existedMessagesOuterRef }, messages?.length ? (React.createElement(PoseGroup, null, messages.map((m) => (React.createElement(Item, { key: m.id }, getMessageJsx(m)))))) : (React.createElement(EmptyComments, null))),
        !replyingTo && (React.createElement(Message, { className: "MainMessageOuter", contactId: accountId, disabled: (loading && messages.length === 0) || saving, editable: true, placeholder: messagePlaceholder, collapsable: true, onSave: saveMessage, mainInput: true, hideCancelIfEmptyContent: true }))));
};
export default Conversation;
