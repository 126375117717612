import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Input } from 'antd';
import { omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { BiSlider } from 'react-icons/bi';
import { getFiltersCount } from '../../../../../cms/common/utils/utils';
import { SortablePropertyList } from '../../../../../cms/data/components/item-property/SelectProperty';
import { EntityListDisplayView, EntitySingleDisplayView } from '../../../../../cms/data/types';
import { Drawer } from '../../../../../common/components/Drawer';
import { Option, Select } from '../../../../../common/components/Select';
import Toggle from '../../../../../common/components/Toggle';
import { Tooltip } from '../../../../../common/components/Tooltip';
import { DEFAULT_SELECT_OPTIONS_SIZE } from '../../../../../common/components/entity-selectors';
import { getScrollableAreaOrBody } from '../../../../../common/utils/common';
import EditViewForm from '../../../../../views/components/EditViewForm';
import { normalizeNumber } from '../utils';
import { ResetLink } from './styled';
const availableListDisplayView = [
    {
        value: EntityListDisplayView.selectBox,
        label: 'ENTITY_DISPLAY_VIEW_SELECT_BOX'
    },
    {
        value: EntityListDisplayView.table,
        label: 'ENTITY_DISPLAY_VIEW_TABLE'
    }
];
const availableSingleDisplayView = [
    {
        value: EntitySingleDisplayView.selectBox,
        label: 'ENTITY_DISPLAY_VIEW_SELECT_BOX'
    },
    {
        value: EntitySingleDisplayView.expandedView,
        label: 'EXPANDED_VIEW'
    }
];
export const isVisible = (type) => type === 'entity';
const SearchSelectorTab = ({ value, disabled, onChange, metaDefinitionId }) => {
    const { t } = useI18n();
    const [isEditViewDrawerVisible, setIsEditDrawerDrawerVisible] = useState(false);
    const onChangeLimit = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta.selector,
                    limit: normalizeNumber(event.target.value) || 0
                }
            }
        });
    }, [onChange, value]);
    const onChangeMaxHeight = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta.selector,
                    maxHeight: normalizeNumber(event.target.value) || 0
                }
            }
        });
    }, [onChange, value]);
    const onClickResetLimit = useCallback(() => {
        if (value.meta.selector) {
            onChange({
                ...value,
                meta: {
                    ...value.meta,
                    selector: omit(value.meta.selector, ['limit'])
                }
            });
        }
    }, [onChange, value]);
    const onChangeFiltering = useCallback(({ newFilters, newMetrics, rowHeightType, rowGrouping, rowGroupingOrderBy }) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta.selector,
                    filtering: {
                        filterRequest: newFilters,
                        metrics: newMetrics,
                        rowHeightType: rowHeightType,
                        rowGroupingOrderBy,
                        rowGrouping
                    }
                }
            }
        });
    }, [onChange, value]);
    const onChangeDisplayView = useCallback((displayView) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta.selector,
                    displayView
                }
            }
        });
    }, [onChange, value]);
    const onOpenDrawer = useCallback(() => {
        setIsEditDrawerDrawerVisible(true);
    }, []);
    const onCloseDrawer = useCallback(() => {
        setIsEditDrawerDrawerVisible(false);
    }, []);
    const onChangeOnlySelectedSwitcherSettings = useCallback((name) => (newValue) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta?.selector,
                    onlySelectedSwitch: {
                        ...value.meta?.selector?.onlySelectedSwitch,
                        [name]: newValue
                    }
                }
            }
        });
    }, [onChange, value]);
    const onChangeExpandedViewShowDefaultValue = useCallback((showDefaultValue) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta?.selector,
                    expandedView: {
                        ...value.meta.selector?.expandedView,
                        showDefaultValue
                    }
                }
            }
        });
    }, [onChange, value]);
    const onChangeExpandedViewProperties = useCallback((properties) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                selector: {
                    ...value.meta?.selector,
                    expandedView: {
                        ...value.meta.selector?.expandedView,
                        properties
                    }
                }
            }
        });
    }, [onChange, value]);
    const filtersCount = useMemo(() => getFiltersCount(value.meta.selector?.filtering?.filterRequest), [value.meta.selector?.filtering?.filterRequest]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "pb-2 w-100" },
            React.createElement(LabelText, null,
                t('NUMBER_OF_SEARCH_RESULTS_TO_SHOW'),
                !disabled && (React.createElement(React.Fragment, null,
                    ' (',
                    React.createElement(ResetLink, { onClick: onClickResetLimit },
                        t('RESET_TO_DEFAULT_NUMBER_OF_SEARCH_RESULTS'),
                        DEFAULT_SELECT_OPTIONS_SIZE),
                    ')'))),
            React.createElement(Input, { type: "number", value: value.meta.selector?.limit === undefined || value.meta.selector?.limit === null
                    ? ''
                    : value.meta.selector.limit, onChange: onChangeLimit })),
        React.createElement(Button, { className: `d-flex align-items-center fw-600`, onClick: onOpenDrawer, inversed: true, disabled: disabled },
            React.createElement(BiSlider, { size: "1.2rem" }),
            t('VIEWS__EDIT_VIEW'),
            !!filtersCount && React.createElement("span", { className: "count-badge" }, filtersCount)),
        value.meta.definitionId && (React.createElement(Drawer, { className: "bg-transparent", onClose: onCloseDrawer, open: isEditViewDrawerVisible, destroyOnClose: true },
            React.createElement(EditViewForm, { noKanban: true, metaDefinitionId: metaDefinitionId, onCloseCallback: onCloseDrawer, definitionId: value.meta.definitionId, filterRequest: value.meta.selector?.filtering?.filterRequest || {}, metrics: value.meta.selector?.filtering?.metrics || [], rowHeightType: value.meta.selector?.filtering?.rowHeightType, updateRouteWithView: onChangeFiltering, allowComputedForConditionQuery: true, rowGrouping: value.meta.selector?.filtering?.rowGrouping, rowGroupingOrderBy: value.meta.selector?.filtering?.rowGroupingOrderBy }))),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('HIDE_ONLY_SELECTED_SWITCH'), disabled: disabled, onChange: onChangeOnlySelectedSwitcherSettings('hide'), checked: value.meta?.selector?.onlySelectedSwitch?.hide }),
        React.createElement(Toggle, { className: "w-100 py-2", label: t('DEFAULT_VALUE_FOR_ONLY_SELECTED_SWITCH'), disabled: disabled, onChange: onChangeOnlySelectedSwitcherSettings('defaultValue'), checked: value.meta?.selector?.onlySelectedSwitch?.defaultValue }),
        value.meta.list && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "d-flex align-items-center w-50" },
                React.createElement("div", { className: "label fw-500 text-nowrap" },
                    t('DISPLAY_ENTITY_VIEW'),
                    ":"),
                React.createElement(Select, { className: "ms-2 w-100", disabled: disabled, defaultValue: EntityListDisplayView.selectBox, showSearch: true, getPopupContainer: getScrollableAreaOrBody, value: value.meta.selector?.displayView, onChange: onChangeDisplayView }, availableListDisplayView.map(({ value, label }) => (React.createElement(Option, { key: value, value: value }, t(label)))))),
            value.meta.selector?.displayView === EntityListDisplayView.table && (React.createElement(React.Fragment, null,
                React.createElement(Toggle, { className: "w-100 py-2", label: t('DELETE_ITEM_ON_REMOVE'), disabled: disabled, onChange: onChangeOnlySelectedSwitcherSettings('deleteOnRemove'), checked: value.meta?.selector?.onlySelectedSwitch?.deleteOnRemove }),
                React.createElement(Toggle, { className: "w-100 py-2", label: t('RESTRICT_TO_LINK_ITEM'), tooltip: t('RESTRICT_TO_LINK_ITEM_DESCRIPTION'), disabled: disabled, onChange: onChangeOnlySelectedSwitcherSettings('restrictToLink'), checked: value.meta?.selector?.onlySelectedSwitch?.restrictToLink }),
                React.createElement("div", { className: "py-2 w-50" },
                    React.createElement(LabelText, null,
                        React.createElement(Tooltip, { title: t('MAX_HEIGHT_TOOLTIP') }, t('PROPERTY_MAX_HEIGHT'))),
                    React.createElement(Input, { type: "number", value: value.meta.selector?.maxHeight === undefined ||
                            value.meta.selector?.maxHeight === null
                            ? ''
                            : value.meta.selector.maxHeight, onChange: onChangeMaxHeight })))))),
        !value.meta.list && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "d-flex align-items-center w-50" },
                React.createElement("div", { className: "label fw-500 text-nowrap" },
                    t('DISPLAY_ENTITY_VIEW'),
                    ":"),
                React.createElement(Select, { className: "ms-2 w-100", disabled: disabled, defaultValue: EntitySingleDisplayView.selectBox, showSearch: true, getPopupContainer: getScrollableAreaOrBody, value: value.meta.selector?.displayView, onChange: onChangeDisplayView }, availableSingleDisplayView.map(({ value, label }) => (React.createElement(Option, { key: value, value: value }, t(label)))))),
            value.meta.selector?.displayView === EntitySingleDisplayView.expandedView && (React.createElement("div", null,
                React.createElement(Toggle, { className: "w-100 py-2", label: t('EXPANDED_BY_DEFAULT'), disabled: disabled, onChange: onChangeExpandedViewShowDefaultValue, checked: value.meta?.selector?.expandedView?.showDefaultValue }))),
            value.meta.definitionId &&
                value.meta.selector?.displayView === EntitySingleDisplayView.expandedView ? (React.createElement(SortablePropertyList, { disabled: disabled, value: value.meta.selector?.expandedView?.properties, onChange: onChangeExpandedViewProperties, definitionId: value.meta.definitionId, includeNestedProperties: false })) : null))));
};
export default SearchSelectorTab;
