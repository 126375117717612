import { flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Button } from 'antd';
import cn from 'classnames';
import { isNumber, isString } from 'lodash';
import React, { memo, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdArrowUpward } from 'react-icons/md';
import ReactTableBodyVirtualized, { ReactTableBodyVirtualizedWindow } from './ReactTableBodyVirtualized';
import { TABLE_EXTRA_COLS } from './utils';
const ReactTable = (props) => {
    const { className = '', data, columns, loading = false, LoadingComponent, NoDataComponent, rowHeight, selectWidth = 50, selectable = true, selectAll, isSelected, toggleSelection, toggleAll, onSortedChange, sorted, showPagination, tableContainerScrollerRef, windowScroller, pageSize, pageIndex, rowGrouping, setExpandedMap } = props;
    const tableContainerRef = useRef(null);
    const [isHorizontallyScrolled, setIsHorizontallyScrolled] = useState(false);
    const [isVerticallyScrolled, setIsVerticallyScrolled] = useState(false);
    useEffect(() => {
        const tableScrollWrapper = tableContainerRef?.current?.parentNode;
        const scrollHandler = (event) => {
            startTransition(() => {
                setIsVerticallyScrolled(() => {
                    if (!tableContainerRef.current) {
                        return false;
                    }
                    return event.target.scrollTop > tableContainerRef.current.offsetTop;
                });
                setIsHorizontallyScrolled(() => event.target.scrollLeft !== 0);
            });
        };
        tableScrollWrapper?.addEventListener('scroll', scrollHandler, false);
        return () => {
            tableScrollWrapper?.removeEventListener('scroll', scrollHandler, false);
        };
    }, []);
    const grouping = useMemo(() => (rowGrouping ? [rowGrouping] : []), [rowGrouping]);
    const computedColumns = useMemo(() => selectable && isSelected && toggleSelection
        ? [
            {
                id: 'select',
                header: () => React.createElement(Checkbox, { checked: selectAll, onChange: toggleAll }),
                cell: ({ row }) => (React.createElement(Checkbox, { checked: isString(row.original.entityId) && isSelected(row.original.entityId), onChange: () => {
                        toggleSelection(row.original.entityId);
                    } }))
            },
            ...columns
        ]
        : columns, [columns, isSelected, selectAll, selectable, toggleAll, toggleSelection]);
    const [expanded, setExpanded] = useState({});
    const table = useReactTable({
        data,
        columns: computedColumns,
        state: {
            pagination: {
                pageSize: pageSize,
                pageIndex: pageIndex
            },
            grouping,
            expanded
        },
        initialState: {
            columnSizing: {
                ...TABLE_EXTRA_COLS.reduce((acc, col) => ({ ...acc, [col]: 50 }), {})
            },
            columnPinning: {
                left: TABLE_EXTRA_COLS
            }
        },
        onExpandedChange: setExpanded,
        getRowCanExpand: (row) => '_countInGroup' in row.original &&
            isNumber(row.original._countInGroup) &&
            row.original._countInGroup > 0,
        getSubRows: (row) => row?.subRows,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        manualGrouping: true,
        manualPagination: showPagination
    });
    const [expandedPagination, setExpandedPagination] = useState({});
    useEffect(() => {
        // reset expanded rows when rowGrouping changes
        setExpanded({});
        setExpandedMap?.({});
        setExpandedPagination({});
        // rowGrouping required in this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowGrouping, setExpandedMap]);
    const sortHandler = useCallback((fieldId, event) => {
        const target = event.target;
        if (target.closest('.skip-sort') || !sorted || !onSortedChange) {
            return;
        }
        const fieldSort = sorted.find(({ id }) => fieldId === id)?.desc;
        onSortedChange([
            {
                id: fieldId,
                desc: fieldSort === false
            }
        ]);
    }, [sorted, onSortedChange]);
    const TableBody = windowScroller ? ReactTableBodyVirtualizedWindow : ReactTableBodyVirtualized;
    return (React.createElement("div", { className: cn(className, 'ReactTableWrapper'), ref: tableContainerRef },
        React.createElement("table", { className: cn('rt-table ReactTable', { isHorizontallyScrolled, isVerticallyScrolled }) },
            React.createElement("thead", { className: "rt-thead -header" }, table.getHeaderGroups().map((headerGroup) => (React.createElement("tr", { className: "rt-tr", key: headerGroup.id }, headerGroup.headers.map((header) => {
                const sort = !!sorted && sorted.find(({ id }) => header.id === id)?.desc;
                const sortClass = sort === true ? '-sort-desc' : sort === false ? '-sort-asc' : '';
                const isResizing = header.column.getIsResizing();
                return (React.createElement("th", { className: cn('rt-th', {
                        [header.id]: TABLE_EXTRA_COLS.includes(header.id),
                        resizingColumn: isResizing
                    }), key: header.id, colSpan: header.colSpan, style: TABLE_EXTRA_COLS.includes(header.column.id)
                        ? { width: `${selectWidth}px` }
                        : { width: header.getSize() } },
                    React.createElement("div", { className: cn('wrapper w-100 d-flex align-items-center rt-resizable-header position-relative', { [header.id]: TABLE_EXTRA_COLS.includes(header.id) }) },
                        React.createElement(React.Fragment, null, header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())),
                        TABLE_EXTRA_COLS.includes(header.id) ? null : (React.createElement(React.Fragment, null,
                            React.createElement(Button, { shape: "circle", type: "text", className: "safe-space sort-button", onClick: (event) => !TABLE_EXTRA_COLS.includes(header.id) && sortHandler(header.id, event) },
                                React.createElement(MdArrowUpward, { color: sortClass ? 'var(--neutralColor-6)' : 'var(--neutralColor-4)', className: sortClass })),
                            React.createElement("div", { onMouseDown: header.getResizeHandler(), onTouchStart: header.getResizeHandler(), onClick: (event) => event.stopPropagation(), className: cn('resizer ms-auto', { isResizing }) }))))));
            }))))),
            React.createElement(TableBody, { table: table, loading: loading, rowHeight: rowHeight, selectWidth: selectWidth, tableContainerScrollerRef: tableContainerScrollerRef || tableContainerRef, expandedPagination: expandedPagination, setExpandedPagination: setExpandedPagination, setExpandedMap: setExpandedMap }, loading && LoadingComponent && (React.createElement("tr", null,
                React.createElement("td", null,
                    React.createElement(LoadingComponent, null))))),
            !data.length && !loading && NoDataComponent && (React.createElement("tr", { className: "w-100 d-block" },
                React.createElement("td", { className: "w-100 d-block" },
                    React.createElement(NoDataComponent, null)))))));
};
const Checkbox = ({ className = '', ...otherProps }) => {
    const ref = useRef(null);
    return (React.createElement("label", { className: "p-3 safe-space", role: "button" },
        React.createElement("input", { type: "checkbox", ref: ref, className: cn(className, 'cursor-pointer'), ...otherProps })));
};
export default memo(ReactTable, (prevProps, nextProps) => {
    const prevEntries = Object.entries(prevProps);
    const nextEntries = Object.entries(nextProps);
    return (prevEntries.length === nextEntries.length &&
        prevEntries.every(([key, value]) => {
            if (key === 'sorted') {
                return value?.every(({ id, desc }, index) => {
                    const nextSorted = nextProps.sorted?.[index];
                    return nextSorted && id === nextSorted.id && desc === nextSorted.desc;
                });
            }
            return value === nextProps[key];
        }));
});
