import ArrowSection from '@hypercharge/hyper-react-base/lib/common/arrow-section';
import React from 'react';
import { IoIosContact } from 'react-icons/io';
import { MdLocationCity } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { Wrapper, media } from './styledComponentsUtils';
export const Container = styled.div `
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  &:last-child {
    padding-bottom: 0.5rem;
  }
`;
export const Title = styled.div `
  font-weight: 600;
  font-size: 1rem;
  color: var(--neutralColor-6);
`;
export const Text = styled.div `
  color: var(--grayscaleColor-7);
  font-size: 0.9rem;
  font-weight: 400;
`;
export const IntroText = styled.div `
  color: var(--grayscaleColor-7);
  font-weight: 500;
  padding-bottom: 1rem;
  font-size: 0.9rem;
`;
export const iconCss = css `
  font-size: 1.5rem;
  color: var(--grayscaleColor-8);
  transition: color 300ms ease;

  &:hover {
    cursor: pointer;
    color: var(--primaryColor-5);
  }
`;
export const StyledProfile = styled(IoIosContact) `
  color: var(--grayscaleColor-8);
  font-size: 5rem;
  margin-right: 1rem;
`;
export const StyledOffice = styled(MdLocationCity) `
  color: var(--grayscaleColor-8);
  font-size: 5rem;
  margin-right: 1rem;
`;
export const StyledRelatedSection = styled(ArrowSection) `
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--grayscaleColor-5);
  margin-bottom: 1rem;
  min-height: 87px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
export const StyledWrapper = styled(Wrapper) `
  padding: 0 1rem 2rem;
`;
export const ValueContainer = styled.div `
  padding-bottom: 0.4rem;

  > div {
    word-break: break-word;
  }
`;
export const LoadingOverlay = styled.div `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const inlineActionIconSpacingCss = css `
  margin-right: 0.7rem;

  ${media.sm} {
    margin-right: 0.4rem;
  }
`;
export const editingButtonStyles = css `
  cursor: pointer;
  z-index: 2;
`;
const HeightContent = styled.div `
  height: ${(props) => props.height || null};
  overflow-y: auto;
  position: relative;
`;
export const Content = ({ height, children, className }) => (React.createElement(HeightContent, { className: `scrollable-area ${className || ''}`, height: height }, children));
export const dropdownStyle = { maxHeight: 400, overflow: 'auto' };
