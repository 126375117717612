import React from 'react';
import { AggregationType, TermsAggregationFilterOrderBy } from '../../../../../../cms/hooks/useAggregation';
import { PropertyTypes } from '../../../../../../common/types';
import { PropertySelector } from '../../../components/PropertySelector';
const propertyToFacet = (property) => {
    const facet = {
        agg: {
            field: property.type === PropertyTypes.entity
                ? `${property.propertyId}.title`
                : property.propertyId,
            type: property.type === PropertyTypes.number ? AggregationType.stats : AggregationType.term
        },
        propertyId: property.propertyId,
        labels: property.labels
    };
    if (facet.agg.type === 'TERM') {
        facet.agg.orderBy = TermsAggregationFilterOrderBy.count;
    }
    return facet;
};
const FacetsAggregationSelector = (props) => {
    return React.createElement(PropertySelector, { ...props, valueAdapter: propertyToFacet });
};
export default FacetsAggregationSelector;
