import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { STORAGE_URL, TENANT_ID } from 'config';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { fetchUploadUrl, uploadFile } from '../../../../../../common/storage';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './RichTextEditor.module.scss';
import './editorExtend';
const EDITOR_DEFAULT_STATE = { ops: [] };
const EDITOR_CONFIG = {
    theme: 'snow',
    formats: [
        'header',
        'color',
        'bold',
        'italic',
        'strike',
        'code',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'imageBlot',
        'width',
        'code-block',
        'blockquote',
        'align'
    ]
};
const RichTextEditor = ({ value, entityId, autoFocus = true, disabled = false, editing = true, onChange, errorMessage }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    // @ts-expect-error it is not very clear how to create an instance of the Delta class
    const [editorState, setEditorState] = useState(value || EDITOR_DEFAULT_STATE);
    const [prevValue, setPrevValue] = useState(value);
    const editor = useRef(null);
    const fetchUploadUrlDispatch = useCallback((file, groupId, isPublic) => dispatch(fetchUploadUrl(file, groupId, isPublic)), [dispatch]);
    const uploadFileDispatch = useCallback(async (file, data) => {
        if (data.uploadUrl) {
            return dispatch(uploadFile(file, data, data.uploadUrl));
        }
        else {
            return;
        }
    }, [dispatch]);
    const errorDispatch = useCallback((content) => dispatch(error(content)), [dispatch]);
    const modules = useMemo(() => ({
        toolbar: [
            [
                { header: '1' },
                { header: '2' },
                { header: '3' },
                { header: '4' },
                'bold',
                'italic',
                'link',
                'strike',
                'code',
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { color: [] },
                // { background: [] },
                'image',
                'code-block',
                'blockquote',
                { align: [] }
            ]
        ],
        imageUploader: {
            upload: (file) => new Promise((resolve, reject) => {
                const filesize = file.size / 1024 / 1024;
                if (filesize > 1) {
                    errorDispatch({
                        title: t('ERROR'),
                        message: t('CMS_EDITOR_IMAGE_TOO_BIG'),
                        autoDismiss: 5
                    });
                    setTimeout(() => {
                        reject(new Error('Too big'));
                    }, 500);
                }
                else {
                    fetchUploadUrlDispatch(file, entityId || 'rich-text-editor').then((data) => {
                        uploadFileDispatch(file, data).then(() => {
                            const storageUrl = `storage.${window.location.hostname
                                .split('.')
                                .slice(1, 3)
                                .join('.')}`;
                            resolve(`https://${STORAGE_URL || storageUrl}/${TENANT_ID}/${data.id}`);
                        }, (e) => {
                            errorDispatch({
                                title: t('ERROR'),
                                message: t('UPLOAD_FAILED')
                            });
                            reject(e);
                        });
                    }, (e) => {
                        errorDispatch({
                            title: t('ERROR'),
                            message: t('UPLOAD_FAILED')
                        });
                        reject(e);
                    });
                }
            })
        },
        clipboard: {
            matchers: [
                [
                    Node.TEXT_NODE,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (node, delta) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (typeof node.data !== 'string') {
                            return;
                        }
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        const data = node.data;
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        const matches = data.match(/https?:\/\/[^\s]+/g);
                        if (matches && matches.length > 0) {
                            const ops = [];
                            let str = data;
                            matches.forEach((match) => {
                                const split = str.split(match);
                                const beforeLink = split.shift();
                                ops.push({ insert: beforeLink });
                                ops.push({ insert: match, attributes: { link: match } });
                                str = split.join(match);
                            });
                            ops.push({ insert: str });
                            // eslint-disable-next-line no-param-reassign
                            delta.ops = ops;
                        }
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, consistent-return
                        return delta;
                    }
                ]
            ]
        },
        imageResize: {
            modules: ['Resize', 'DisplaySize']
        }
    }), [entityId, errorDispatch, fetchUploadUrlDispatch, t, uploadFileDispatch]);
    useEffect(() => {
        if (autoFocus && editor.current) {
            editor.current.focus();
        }
    }, []);
    useEffect(() => {
        if (!prevValue && value && editorState === EDITOR_DEFAULT_STATE) {
            // @ts-expect-error it is not very clear how to create an instance of the Delta class
            setEditorState(value || EDITOR_DEFAULT_STATE);
        }
        setPrevValue(value);
    }, [value]);
    return (React.createElement("div", { "data-text-editor": "rich-text-container" },
        React.createElement(FieldWrapper, { className: styles.editor, disabled: disabled, editing: editing, error: !!errorMessage },
            React.createElement(ReactQuill, { ref: editor, value: editorState, onChange: (value, delta, source, editor) => {
                    const currentEditorState = editor.getContents();
                    // this condition is always true as we compare Delta instance with plain object
                    if (!isEqual(editorState, currentEditorState)) {
                        setEditorState(currentEditorState);
                        if (onChange) {
                            const newValue = editor.getLength() !== 1 && currentEditorState.ops
                                ? {
                                    ops: currentEditorState.ops || EDITOR_DEFAULT_STATE.ops
                                }
                                : null;
                            onChange(newValue);
                        }
                    }
                }, placeholder: t('RICH_TEXT_EDITOR_PLACEHOLDER'), modules: modules, readOnly: disabled, style: { minHeight: '3rem' }, bounds: `[data-text-editor="rich-text-container"]`, ...EDITOR_CONFIG })),
        React.createElement(FieldError, { error: errorMessage })));
};
export default RichTextEditor;
