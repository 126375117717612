import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import React, { useEffect } from 'react';
import { LoadingOverlay } from '../../../../../common/utils/stylingUtils';
import { useDispatch } from 'react-redux';
import { useNodes } from '../activity-diagram/useNode';
import ActivityContainer from '../ActivityContainer/ActivityContainer';
import styles from './ActivityContainerWrapper.module.scss';
const ActivityContainerWrapper = (props) => {
    const { data, isLoading, isError } = useNodes();
    const dispatch = useDispatch();
    const { t } = useI18n();
    useEffect(() => {
        if (isError) {
            dispatch(error({ title: t('ERROR'), message: t('PROCESS_META__NODES_FETCHING_FAILED') }));
        }
    }, [dispatch, isError, t]);
    return isLoading ? (React.createElement("div", { className: styles.loadingContainer },
        React.createElement(LoadingOverlay, null,
            React.createElement(LoadingRectangles, null)))) : (React.createElement(ActivityContainer, { nodes: data?.nodes, credentialTypes: data?.credentials, disabled: isError, t: t, ...props }));
};
export default ActivityContainerWrapper;
