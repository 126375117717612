import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { oneHourInMs } from '../../../common/utils/react-query-client';
import { authenticateStripo } from '../actions';
const STRIPO_QUERY_KEY = 'STRIPO_QUERY_KEY';
const useAuthenticateStripo = () => {
    const dispatchAuthenticateStripo = useDispatch();
    const { data, isError, isLoading } = useQuery([STRIPO_QUERY_KEY], async () => {
        return dispatchAuthenticateStripo(authenticateStripo());
    }, {
        cacheTime: oneHourInMs,
        staleTime: oneHourInMs
    });
    return useMemo(() => {
        return {
            data,
            isPending: isLoading,
            isFailed: isError
        };
    }, [data, isError, isLoading]);
};
export default useAuthenticateStripo;
