import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { invalidateProcessMetaListCache } from '../../../../cms/hooks/useDisplayProcessMetaList';
import ActionsButton from '../../../../common/components/ActionsButton';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import { deleteProcessMeta } from '../../actions';
const ProcessMetaActions = ({ className, canDelete = true, canSave = true, save, extraActions = [], saving, disabled, id }) => {
    const { t } = useI18n();
    const deleteProcessMetaDispatch = useDispatch();
    const dispatch = useDispatch();
    const deleteAction = useCallback(() => {
        return new Promise((resolve, reject) => {
            deleteProcessMetaDispatch(deleteProcessMeta(id))
                .then(() => {
                invalidateProcessMetaListCache(); // to remove the deleted process from the sidebar
                dispatch(push(`${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`));
                dispatch(success({
                    title: t('SUCCESS'),
                    message: t('WORKFLOWS__PROCESS_DELETE_SUCCESS')
                }));
                resolve(null);
            })
                .catch(() => {
                dispatch(error({
                    title: t('ERROR'),
                    message: t('WORKFLOWS__PROCESS_DELETE_FAIL')
                }));
                reject(t('WORKFLOWS__PROCESS_DELETE_FAIL'));
            });
        });
    }, [deleteProcessMetaDispatch, dispatch, id, t]);
    return (React.createElement(ActionsButton, { className: `d-inline-block ${className || ''}`, buttonAction: {
            action: save,
            canRun: canSave,
            buttonLabel: 'FORM__SAVE'
        }, menuActions: [
            {
                action: deleteAction,
                canRun: canDelete,
                buttonLabel: 'WORKFLOWS__PROCESS_DELETE',
                confirmationLabel: 'WORKFLOWS__PROCESS_DELETE',
                delete: true
            },
            ...extraActions
        ], submitting: saving, disabled: disabled, inversed: false }));
};
export default ProcessMetaActions;
