import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectIfNoPermission } from '../../../auth';
import { DOCUMENT_TEMPLATES_PATH } from '../../constants';
import DocumentTemplateEditor from '../../templates/components/editor/DocumentTemplateEditor';
import DocumentTemplateList from '../../templates/components/list/DocumentTemplateList';
const DocumentTemplateEditorPage = ({ match }) => (React.createElement(DocumentTemplateEditor, { templateId: match.params.id }));
const Routes = ({ match: { url } }) => (React.createElement(ErrorBoundary, null,
    React.createElement(Switch, null,
        React.createElement(Route, { path: `${url}${DOCUMENT_TEMPLATES_PATH}/create`, component: DocumentTemplateEditorPage }),
        React.createElement(Route, { path: `${url}${DOCUMENT_TEMPLATES_PATH}/:id`, component: DocumentTemplateEditorPage }),
        React.createElement(Route, { path: `${url}${DOCUMENT_TEMPLATES_PATH}`, component: DocumentTemplateList }),
        React.createElement(Redirect, { to: `${url}${DOCUMENT_TEMPLATES_PATH}` }))));
export default redirectIfNoPermission(Routes);
