import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { useStore } from 'react-redux';
import { Field, Fields, FormSection, formValueSelector } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import MilestoneListForm from './MilestoneListForm';
const MilestoneForm = ({ disabled, metaDefinitionId, activeNodeId, parentViewGroups }) => {
    const { t } = useI18n();
    const store = useStore();
    const activity = formValueSelector('activitySettings')(store.getState(), 'activity');
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { name: "titles", className: "w-100", placeholder: t('COMMON__TITLE'), component: AntInput, disabled: disabled })),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(Field, { name: "milestones", component: MilestoneListForm, activeNodeId: activeNodeId, metaDefinitionId: metaDefinitionId, activity: activity, disabled: disabled })),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default MilestoneForm;
