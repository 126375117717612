import { CircularLoader } from '@hypercharge/hyper-react-base/lib/common';
import { MdHistory, MdOutlineEdit } from 'react-icons/md';
import styled from 'styled-components';
import { editingButtonStyles } from '../../utils/stylingUtils';
export const EditButton = styled(MdOutlineEdit) `
  ${editingButtonStyles};
  width: 18px;
  height: 18px;
`;
export const ViewHistoryButton = styled(MdHistory) `
  ${editingButtonStyles};
  width: 20px;
  height: 20px;
`;
export const Loader = styled(CircularLoader) `
  border-color: var(--primaryColor-6);
  border-left-color: transparent;
  margin: 0.5rem;

  &,
  &:after {
    width: 15px;
    height: 15px;
    padding: 0 !important;
  }
`;
