import { first } from 'lodash';
import { useMemo } from 'react';
import { getFlattenedDisplayDataList } from '../../cms';
import useDisplayData from '../../cms/hooks/useDisplayData';
import { CONTACT_CMS_DEFINITION_ID } from '../../crm';
export function useContactFromEntityItem({ definitionId, entityId }) {
    const { data: displayDataList } = useDisplayData({ definitionId, entityId });
    const foundedContactInEntityItem = useMemo(() => {
        if (!definitionId || !entityId) {
            return;
        }
        return first(getFlattenedDisplayDataList(displayDataList)
            .filter((property) => !!(property?.meta &&
            !property.meta.hidden &&
            'definitionId' in property.meta &&
            !property.meta.list &&
            property.meta.definitionId === CONTACT_CMS_DEFINITION_ID &&
            !['processStatusUpdatedBy', 'entityOwner'].includes(property.propertyId)))
            .map((property) => property?.value));
    }, [definitionId, displayDataList, entityId]);
    if (foundedContactInEntityItem && typeof foundedContactInEntityItem === 'string') {
        return foundedContactInEntityItem;
    }
}
