import { NO_IMAGE_PATH } from '@hypercharge/hyper-react-base/lib/common/image';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { findIndex } from 'lodash';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import { SortableFile } from '../../../components/SortableFileWrapper';
import { useBrowserBreakpoints } from '../../../components/with-browser-breakpoints';
import useElementSize from '../../../hooks/useElementSize';
import FileLightBox from '../FileLightBox';
import documentFormats, * as FILE_TYPE from '../constants';
import EarmarkFileIcon from './EarmarkFileIcon';
import styles from './FileCarousel.module.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const SliderControl = styled.button `
  transition: 200ms ease;
  transition-property: left, right;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  z-index: 1;
  cursor: pointer;
  border: 0;
  pointer-events: auto;
  outline: 0;
`;
const SliderControlPrevious = styled(SliderControl) `
  left: -25%;
  background: linear-gradient(
    to left,
    rgba(var(--grayscaleColorRGB-1), 0) 0%,
    rgba(var(--grayscaleColorRGB-13), 0.25) 100%
  );
`;
const SliderControlNext = styled(SliderControl) `
  right: -25%;
  background: linear-gradient(
    to right,
    rgba(var(--grayscaleColorRGB-1), 0) 0%,
    rgba(var(--grayscaleColorRGB-13), 0.25) 100%
  );
`;
const SliderControlIcon = styled.svg.attrs(() => ({
    viewBox: '0 0 18 18',
    role: 'img',
    focusable: 'false'
})) `
  height: 1.5rem;
  width: 1.5rem;
  display: block;
  fill: var(--grayscaleColor-1);
`;
const Container = styled.div `
  position: relative;
  overflow: hidden;

  &:hover ${SliderControlPrevious} {
    left: 0;
  }

  &:hover ${SliderControlNext} {
    right: 0;
  }

  .slick-slider.slick-slider {
    .slick-dots {
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 8px 0;
      background: linear-gradient(
        to bottom,
        rgba(var(--grayscaleColorRGB-13), 0) 0%,
        rgba(var(--grayscaleColorRGB-13), 0.3) 100%
      );
      line-height: 0;

      li {
        width: 6px;
        height: 6px;
        margin: 2px 4px;
        cursor: default;

        button {
          width: 6px;
          height: 6px;
          padding: 0;
          pointer-events: none;
        }

        button:before {
          opacity: 1;
          color: var(--grayscaleColor-9);
          line-height: 6px;
          width: 6px;
          height: 6px;
          font-size: 6px;
        }

        &.slick-active {
          width: 8px;
          height: 8px;
          margin: 1px 5px;

          button {
            width: 8px;
            height: 8px;
            padding: 0;
          }

          button:before {
            opacity: 1;
            color: var(--grayscaleColor-1);
            line-height: 10px;
            width: 8px;
            height: 8px;
            font-size: 8px;
          }
        }
      }
    }
  }
`;
const Image = styled.div `
  background: ${(props) => `url(${props.src}) center no-repeat`};
  background-size: ${(props) => (props.imageCover ? 'cover' : 'contain')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const PrevArrow = ({ onClick }) => (React.createElement(SliderControlPrevious, { className: "cp-c-row cp-c-align-center-center", onClick: onClick },
    React.createElement(SliderControlIcon, { className: "cp-i-none", "aria-label": "Previous" },
        React.createElement("path", { fillRule: "evenodd", d: "M13.703 16.293a1 1 0 1 1-1.415 1.414l-7.995-8a1 1 0 0 1 0-1.414l7.995-8a1 1 0 1 1 1.415 1.414L6.413 9l7.29 7.293z" }))));
const NextArrow = ({ onClick }) => (React.createElement(SliderControlNext, { className: "cp-c-row cp-c-align-center-center", onClick: onClick },
    React.createElement(SliderControlIcon, { className: "cp-i-none", "aria-label": "Next" },
        React.createElement("path", { fillRule: "evenodd", d: "M4.293 1.707A1 1 0 1 1 5.708.293l7.995 8a1 1 0 0 1 0 1.414l-7.995 8a1 1 0 1 1-1.415-1.414L11.583 9l-7.29-7.293z" }))));
const carouselSettingsMobile = {
    adaptiveHeight: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    dots: true,
    arrow: false
};
const carouselSettings = {
    ...carouselSettingsMobile,
    arrow: true,
    adaptiveHeight: false,
    draggable: false,
    prevArrow: React.createElement(PrevArrow, null),
    nextArrow: React.createElement(NextArrow, null)
};
const NoFiles = ({ height }) => (React.createElement("img", { src: NO_IMAGE_PATH, height: height, width: "100%", alt: "No image" }));
const Content = ({ file, height, width, imageCover }) => {
    const fileSizeMb = file.size / 1024 / 1024;
    if (file.type === FILE_TYPE.PDF && fileSizeMb < 10) {
        return (React.createElement(Document, { className: `${styles.document} mh-100 mw-100 d-flex ${imageCover
                ? 'overflow-y-auto custom-scroll-styling'
                : 'overflow-hidden align-items-center'}`, loading: React.createElement("div", { className: "h-100 d-flex align-items-center justify-content-center" },
                React.createElement(LoadingRectangles, null)), onLoadError: (error) => console.error(error), file: file.downloadUrl },
            React.createElement(Page, { pageNumber: 1, height: height - 50, width: width ? width - 5 : undefined, renderTextLayer: false, renderAnnotationLayer: false })));
    }
    if ((file.type && documentFormats.includes(file.type)) ||
        file.type?.includes(FILE_TYPE.AUDIO) ||
        file.type?.includes(FILE_TYPE.VIDEO)) {
        return React.createElement(EarmarkFileIcon, { text: file.extension });
    }
    if (file.type?.includes(FILE_TYPE.IMAGE)) {
        return React.createElement(Image, { style: { width, height }, imageCover: imageCover, src: file.downloadUrl || '' });
    }
    return React.createElement(NoFiles, { height: height });
};
const CarouselFileContainer = ({ height, onClick, imageCover, file }) => {
    const { size, ref } = useElementSize();
    return (React.createElement("div", { className: "position-relative d-flex justify-content-center align-items-center overflow-hidden", role: "button", ref: ref, onClick: onClick, style: { height } },
        React.createElement(Content, { file: file, height: height, imageCover: imageCover, width: imageCover ? size.width : undefined })));
};
const FileCarousel = ({ files, height: customHeight, imageCover, propertyId, isDraggableFile = false }) => {
    const { isMobile } = useBrowserBreakpoints();
    const [previewIndex, setPreviewIndex] = useState(0);
    const [isPreviewing, setIsPreviewing] = useState(false);
    const settings = isMobile ? carouselSettingsMobile : carouselSettings;
    const height = isMobile ? 200 : customHeight || 400;
    if (files.length === 0) {
        return React.createElement(NoFiles, { height: height });
    }
    return (React.createElement(Container, null,
        React.createElement(Slider, { ...settings }, files.map((file, i) => (React.createElement(SortableFile, { key: file.id, index: i, className: "sortable", collection: propertyId, disabled: !isDraggableFile },
            React.createElement(CarouselFileContainer, { height: height, onClick: () => {
                    setPreviewIndex(findIndex(files, file));
                    setIsPreviewing(true);
                }, imageCover: imageCover, file: file }))))),
        isPreviewing && (React.createElement(FileLightBox, { files: files, previewIndex: previewIndex, closeImagePreview: () => setIsPreviewing(false) }))));
};
export default FileCarousel;
