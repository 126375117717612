import { LabelText } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Typography } from 'antd';
import React from 'react';
import { AceEditor, AceEditorMode } from '../../../../../common/components/AceEditor';
import FormikField from '../../../../../common/components/formik/FormikField';
import { HyperFormCustomFontsFields } from '../HyperFormCustomFontsFields';
export const HyperFormThemeForm = ({ isSubmitting, setFieldValue, values, errors }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "p-2 pt-3 border-top mt-3" },
        React.createElement(Typography.Title, { level: 4 }, t('TENANT_HYPER_FORM_THEME_TITLE')),
        React.createElement(HyperFormCustomFontsFields, { values: values, errors: errors, isSubmitting: isSubmitting, setFieldValue: setFieldValue }),
        React.createElement("div", { className: "border-top mt-4 pt-3" },
            React.createElement(LabelText, { className: "mb-2" }, t('TENANT_HYPER_FORM_THEME_CSS_STYLES')),
            React.createElement(FormikField, { name: "hyperFormTheme.cssStyles", component: AceEditor, mode: AceEditorMode.css, disabled: isSubmitting, beautify: true }))));
};
