import { FormFooter } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { success } from '@hypercharge/hyper-react-base/lib/notifications';
import { Modal } from 'antd';
import { isEqual, isString } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FaEye } from 'react-icons/fa';
import { error } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { CMS_PATH, ENTITY_ID_PROPERTY_ID, searchItems } from '../../../cms';
import { isEntityInSingleMode } from '../../../cms/data/components/item-property/utils';
import { QuickAddItemButton } from '../../../cms/data/components/item/QuickAddItemButton';
import useDisplayItemMeta from '../../../cms/hooks/useDisplayItemMeta';
import HeightContext, { HeightContextProvider } from '../../../common/components/height-context';
import { useView } from '../../../views/components/ViewContext';
import { BROWSE_PATH } from '../../utils/constants';
import { isCmsDefinition } from '../../utils/url';
import { DrawerFooter } from '../Drawer';
import DataTable2Container from '../data-table/DataTable2Container';
import styles from './FilterModal.module.scss';
const otherElementSelectors = ['#header', '#featured-entities', '#items-data-action-block'];
const otherHeightInPx = 188;
const FilterModal = ({ open, definitionId, handleCancel, handleOk, showFootter = true, meta, requiredFieldsConfig }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const dispatchSearchItems = useDispatch();
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId });
    const { setSelectedItems, filterRequest, initialSelectedItems, isAllItemsSelected, selectedItems, rowGrouping } = useView();
    const onHandleCancel = useCallback(() => handleCancel(filterRequest.query), [filterRequest, handleCancel]);
    const onHandleApply = useCallback(async () => {
        if (rowGrouping) {
            if (isAllItemsSelected) {
                const allItems = await dispatchSearchItems(searchItems(definitionId, {
                    ...filterRequest,
                    offset: 0,
                    limit: 500,
                    responseFields: [ENTITY_ID_PROPERTY_ID]
                }));
                if (allItems?.totalCount > 500) {
                    dispatch(error({
                        title: t('COMMON__FAILURE'),
                        message: t('WARNING_ROW_GROUPING_ADD_MORE_THEN_LIMIT')
                    }));
                    return;
                }
                else {
                    handleOk?.(allItems.results.map(({ entityId }) => entityId), filterRequest);
                    return;
                }
            }
            else if (!selectedItems.length) {
                handleOk?.([]);
                return;
            }
        }
        handleOk?.(selectedItems, filterRequest);
    }, [
        definitionId,
        dispatch,
        dispatchSearchItems,
        filterRequest,
        handleOk,
        isAllItemsSelected,
        rowGrouping,
        selectedItems,
        t
    ]);
    const columnRenderer = useCallback(({ row }) => (React.createElement("a", { href: `${CMS_PATH}/${row.original.definitionId}${BROWSE_PATH}/${row.original.entityId}`, target: "_blank", rel: "noreferrer", className: "safe-space" },
        React.createElement(FaEye, null))), []);
    const singleMode = isEntityInSingleMode(meta);
    const actions = useMemo(() => {
        const actions = [];
        if (isCmsDefinition(displayItemMeta) && displayItemMeta?.canCreate) {
            actions.push({
                key: 'add',
                buttonComponent: QuickAddItemButton,
                buttonProps: {
                    definitionId,
                    entityTitle: displayItemMeta?.title || t('NO_TITLE'),
                    className: 'QuickAddItemButton',
                    requiredFieldsConfig
                },
                action: ({ selectedIds }, createdEntityId) => {
                    dispatch(success({
                        title: t('COMMON__SUCCESS'),
                        message: t('CMS__CREATE_ITEM_SUCCESS')
                    }));
                    if (isString(createdEntityId) && selectedIds) {
                        if (singleMode) {
                            setSelectedItems([createdEntityId]);
                        }
                        else {
                            setSelectedItems([...selectedIds, createdEntityId]);
                        }
                    }
                }
            });
        }
        return actions;
    }, [
        definitionId,
        displayItemMeta,
        dispatch,
        requiredFieldsConfig,
        setSelectedItems,
        singleMode,
        t
    ]);
    return (React.createElement(Modal, { centered: true, open: open, footer: showFootter && (React.createElement(DrawerFooter, { className: "border-0" },
            React.createElement(FormFooter, { showCancel: true, withoutMargin: true, disabled: isEqual(initialSelectedItems, selectedItems) && !rowGrouping, submitText: t('INTEGRATIONS_WEBHOOKS_UPDATE_BUTTON'), onSubmit: onHandleApply, onCancel: onHandleCancel }))), 
        // TODO: replace closeIcon with null when antd is updated
        closeIcon: React.createElement(React.Fragment, null), onCancel: onHandleCancel, destroyOnClose: true, wrapClassName: styles.modal },
        React.createElement(HeightContextProvider, { otherElementSelectors: otherElementSelectors, otherHeightInPx: otherHeightInPx },
            React.createElement(HeightContext.Consumer, null, ({ contentHeight }) => (React.createElement(DataTable2Container, { noKanban: true, noRowHeightAction: true, contentHeight: contentHeight, idField: ENTITY_ID_PROPERTY_ID, columnRenderer: columnRenderer, isPending: false, singleMode: singleMode, showStartProcessSelector: false, showFilterSelected: !meta.selector?.onlySelectedSwitch?.hide, actions: actions }))))));
};
export default FilterModal;
