import { includes, without } from 'lodash';
import React, { Component } from 'react';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components';
import { Button } from '../../common/button';
import { Input } from './BasicInput';
import ListItem from './ListItem';
import { InputContainer, LabelText } from './utils';
const AddToListButton = styled((props) => React.createElement(Button, { ...props })) `
  margin-left: 0.5rem;
`;
const ListItems = styled.div `
  margin-top: 1rem;
`;
class MultitextInput extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            newTextValue: ''
        };
        this.handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.addToList();
            }
        };
        this.addToList = () => {
            const { newTextValue } = this.state;
            if (newTextValue && !this.newTextValueIsDuplicate()) {
                this.props.input.onChange([newTextValue, ...this.props.input.value]);
                this.setState({ newTextValue: '' });
            }
        };
        this.handleRemove = (text) => {
            this.props.input.onChange(without(this.props.input.value, text));
        };
        this.handleNewTextInputChange = (event) => {
            if (event.target instanceof HTMLInputElement) {
                this.setState({ newTextValue: event.target.value });
            }
        };
        this.newTextValueIsDuplicate = () => includes(this.props.input.value, this.state.newTextValue);
    }
    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.input.value !== this.props.input.value ||
            nextState.newTextValue !== this.state.newTextValue);
    }
    render() {
        const { className, input, label, placeholder, disabled } = this.props;
        const { newTextValue } = this.state;
        return (React.createElement(InputContainer, { className: className },
            label && React.createElement(LabelText, null, label),
            React.createElement("div", { className: "cp-c-row cp-c-align-start-start" },
                React.createElement(Input, { className: "cp-i-flex", onChange: this.handleNewTextInputChange, onKeyPress: this.handleKeyPress, value: newTextValue, type: "text", placeholder: placeholder || '' }),
                React.createElement(AddToListButton, { className: "cp-i-none", onClick: this.addToList, disabled: !newTextValue || this.newTextValueIsDuplicate() },
                    React.createElement(MdAdd, null))),
            input.value.length > 0 && (React.createElement(ListItems, null, input.value.map((value) => (React.createElement(ListItem, { key: value, value: value, display: value, disabled: !!disabled, onRemove: this.handleRemove })))))));
    }
}
export default MultitextInput;
