import { HTTP_SUFFIX_SUCCESS } from '../http';
export const metadataMiddleware = (store) => (next) => (action) => {
    const { payload, type } = action;
    if (payload && payload.meta && type.endsWith(HTTP_SUFFIX_SUCCESS)) {
        const meta = payload.meta;
        if (meta && meta.properties) {
            store.dispatch({
                type: 'METADATA__MIDDLEWARE_PROPERTIES',
                payload: meta.properties
            });
        }
        if (meta && meta.options) {
            store.dispatch({
                type: 'METADATA__MIDDLEWARE_OPTIONS',
                payload: meta.options
            });
        }
    }
    return next(action);
};
export default metadataMiddleware;
