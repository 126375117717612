import React, { Fragment, useMemo } from 'react';
import { FieldWrapper } from '../../FieldElements';
import MaterializedLinks from '../../MaterializedLinks';
import styles from './PlainTextDisplay.module.scss';
const PlainTextDisplay = ({ value, disabled, asField, className, style, tabIndex, onKeyDown }) => {
    const content = useMemo(() => {
        if (!value) {
            return '\u00a0';
        }
        if (Array.isArray(value)) {
            return value.length
                ? value.map((val, index) => (React.createElement("div", { key: `${val}-${index}`, className: `list-item d-flex align-items-center ${value.length > 1 ? 'multiline' : ''}` },
                    React.createElement("span", { className: "list-item-bullet" }, "-"),
                    React.createElement("span", { className: "list-item-text" },
                        React.createElement(MaterializedLinks, { value: val })))))
                : '\u00a0';
        }
        const lines = String(value).split('\n');
        if (lines.length > 1) {
            return (React.createElement("span", { className: "text-row" }, lines.map((item, key) => (React.createElement(Fragment, { key: `${item}-${key}` },
                React.createElement(MaterializedLinks, { value: `${item} ` }),
                React.createElement("br", null))))));
        }
        const line = lines[0];
        return line ? (React.createElement("span", { className: "text-row" },
            React.createElement(MaterializedLinks, { value: line }))) : ('\u00a0');
    }, [value]);
    return (React.createElement(FieldWrapper, { tabIndex: tabIndex, onKeyDown: onKeyDown, simple: !asField, disabled: disabled, className: `${styles.outer} ${className ?? ''} ${Array.isArray(value) ? 'kanbanFieldMultiTextType' : 'kanbanFieldTextType'} PlainTextDisplayOuter ${asField ? '' : 'simple'}`, style: style }, content));
};
export default PlainTextDisplay;
