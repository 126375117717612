import { difference, filter, flatten, head, intersection, isEmpty, keys, reduce, uniq, values } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useProperties } from '../../../../cms/common/context/PropertiesProvider';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { ActivityPriority, ActivityStatus } from '@hypercharge/portal-utils';
import { useContactFromEntityItem } from '../../../../common/activity';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import { TaskStatus } from '../../../common/utils/types';
import { getManualTaskProperties } from '../../../tasks/utils';
import { ErrorValidationResponseDataMessage } from '../../types';
const RequiredFieldsContext = createContext(undefined);
const isNestedFieldOf = (propertyId) => (field) => {
    const dividedPropertyIds = field.split('.');
    return dividedPropertyIds.length > 1 && dividedPropertyIds[0] === propertyId;
};
const RequiredFieldsProvider = ({ definitionId, processRunId, tasks, ...props }) => {
    const { contactId } = useMyContact();
    const { propertiesStatus: requiredFieldsStatus, getFieldLabel } = useProperties(definitionId);
    const availableTasks = useMemo(() => {
        return tasks.filter((task) => [TaskStatus.WAITING, TaskStatus.RUNNING].includes(task.status) && task.canExecute);
    }, [tasks]);
    const myAssignedTasks = useMemo(() => {
        return filter(availableTasks, { assignedTo: contactId });
    }, [contactId, availableTasks]);
    const [errorFieldsMap, setErrorFieldsMap] = useState({});
    const [successFieldsMap, setSuccessFieldsMap] = useState({});
    const foundedContactInEntityItem = useContactFromEntityItem({
        definitionId,
        entityId: processRunId
    });
    const isUserChangedContactMap = useRef({});
    const getTaskActivity = useCallback((task, prevTaskActivity) => {
        const contact = isUserChangedContactMap?.current[task.taskRunId];
        if (prevTaskActivity) {
            return {
                ...prevTaskActivity,
                contact: contact || foundedContactInEntityItem
            };
        }
        if (!task.activity || !task.activity.available?.length || !contactId) {
            return;
        }
        return {
            title: task.taskTitle ?? 'No title',
            assignedTo: contactId,
            entityId: generateId(),
            typeText: task.activity.available[0],
            priority: ActivityPriority.NORMAL,
            statusItem: ActivityStatus.Completed,
            relatedToDefinitionId: definitionId,
            relatedToEntityId: processRunId,
            contact: contact || foundedContactInEntityItem
        };
    }, [contactId, definitionId, foundedContactInEntityItem, processRunId]);
    const getSelectedTaskActivityMap = useCallback((prevSelectedTaskActivityMap) => {
        return availableTasks.reduce((acc, task) => {
            const taskActivity = getTaskActivity(task, prevSelectedTaskActivityMap?.[task.taskRunId]);
            if (taskActivity) {
                return {
                    ...acc,
                    [task.taskRunId]: taskActivity
                };
            }
            return acc;
        }, {});
    }, [availableTasks, getTaskActivity]);
    const [selectedTaskActivityMap, setSelectedTaskActivityMap] = useState(getSelectedTaskActivityMap(undefined));
    const onChangeTaskActivity = useCallback((taskRunId, activity) => {
        setSelectedTaskActivityMap((prevState) => {
            if (prevState[taskRunId]?.contact !== activity.contact) {
                isUserChangedContactMap.current[taskRunId] = activity?.contact;
            }
            return { ...prevState, [taskRunId]: activity };
        });
    }, []);
    useEffect(() => {
        setSelectedTaskActivityMap((prevState) => getSelectedTaskActivityMap(prevState));
    }, [getSelectedTaskActivityMap]);
    const requiredFieldsMap = useMemo(() => {
        return availableTasks.reduce((acc, task) => {
            const newRequiredProperties = getManualTaskProperties(task, 'required', selectedTaskActivityMap[task.taskRunId]);
            if (newRequiredProperties.length) {
                return {
                    ...acc,
                    [task.taskRunId]: newRequiredProperties
                };
            }
            return acc;
        }, {});
    }, [availableTasks, selectedTaskActivityMap]);
    const myRequiredFields = useMemo(() => {
        const myRequiredFields = [];
        for (const task of myAssignedTasks) {
            if (requiredFieldsMap[task.taskRunId]?.length) {
                myRequiredFields.push(...requiredFieldsMap[task.taskRunId]);
            }
        }
        return myRequiredFields;
    }, [myAssignedTasks, requiredFieldsMap]);
    const myErrorFields = useMemo(() => intersection(uniq(flatten(values(errorFieldsMap))), myRequiredFields), [errorFieldsMap, myRequiredFields]);
    const mySuccessFields = useMemo(() => intersection(uniq(flatten(values(successFieldsMap))), myRequiredFields), [successFieldsMap, myRequiredFields]);
    const isErrorField = useCallback((propertyId) => {
        return !!myErrorFields.find((field) => head(field.split('.')) === propertyId); // This filters the properties that are either invalid, or have invalid nested fields inside
    }, [myErrorFields]);
    const getNestedRequiredFields = useCallback((propertyId) => {
        return myRequiredFields.filter(isNestedFieldOf(propertyId));
    }, [myRequiredFields]);
    const getNestedErrorFields = useCallback((propertyId) => {
        return myErrorFields.filter(isNestedFieldOf(propertyId));
    }, [myErrorFields]);
    const getNestedSuccessFields = useCallback((propertyId) => {
        return mySuccessFields.filter(isNestedFieldOf(propertyId));
    }, [mySuccessFields]);
    const requiredFieldsConfig = useMemo(() => {
        return {
            hasRequiredFields: !isEmpty(myRequiredFields),
            isRequiredField: (propertyId) => {
                // This filters the properties that are either required, or have nested required fields inside
                return !!myRequiredFields.find((field) => head(field.split('.')) === propertyId);
            },
            isErrorField,
            isSuccessField: (propertyId) => {
                // This filters the properties that are valid, and have all nested required fields valid as well
                return (!isErrorField(propertyId) &&
                    !!mySuccessFields.find((field) => head(field.split('.')) === propertyId));
            },
            getNestedRequiredFields,
            getNestedErrorFields,
            getNestedSuccessFields,
            getNestedRequiredFieldsConfig: (field) => {
                if (field.type !== 'entity') {
                    return;
                }
                // In case there are nested required props, we want to pass that info so that we can display it
                // In the future we can also validate on updates
                const nestedRequiredFields = getNestedRequiredFields(field.propertyId);
                const nestedErrorFields = getNestedErrorFields(field.propertyId);
                const nestedSuccessFields = getNestedSuccessFields(field.propertyId);
                if (isEmpty(nestedRequiredFields)) {
                    return;
                }
                return {
                    nestedErrorFields,
                    hasRequiredFields: !isEmpty(nestedRequiredFields),
                    isRequiredField: (propertyId) => !!nestedRequiredFields.find((field) => field.split('.')[1] === propertyId),
                    isErrorField: (propertyId) => !!nestedErrorFields.find((field) => field.split('.')[1] === propertyId),
                    isSuccessField: (propertyId) => !!nestedSuccessFields.find((field) => field.split('.')[1] === propertyId)
                };
            }
        };
    }, [
        getNestedErrorFields,
        getNestedRequiredFields,
        getNestedSuccessFields,
        isErrorField,
        myRequiredFields,
        mySuccessFields
    ]);
    const clearErrorFields = useCallback((taskRunId) => {
        setErrorFieldsMap((prevState) => ({
            ...prevState,
            [taskRunId]: []
        }));
    }, []);
    const addErrorFields = useCallback((taskRunId, validationObject) => {
        const newErrorFields = reduce(validationObject, (errorFields, validationKey, field) => {
            if (validationKey === ErrorValidationResponseDataMessage.missingError) {
                return [...errorFields, field];
            }
            return errorFields;
        }, []);
        setErrorFieldsMap((prevState) => ({
            ...prevState,
            [taskRunId]: newErrorFields
        }));
    }, []);
    const clearSuccessFields = useCallback((taskRunId) => {
        setSuccessFieldsMap((prevState) => ({
            ...prevState,
            [taskRunId]: []
        }));
    }, []);
    const addSuccessFields = useCallback((taskRunId, responseData, requiredProperties) => {
        const newSuccessFields = difference(requiredProperties, keys(responseData));
        setSuccessFieldsMap((prevState) => ({
            ...prevState,
            [taskRunId]: newSuccessFields
        }));
    }, []);
    const value = useMemo(() => ({
        getFieldLabel,
        requiredFieldsStatus,
        requiredFieldsMap,
        selectedTaskActivityMap,
        onChangeTaskActivity,
        clearErrorFields,
        addErrorFields,
        availableTasks,
        myAssignedTasks,
        clearSuccessFields,
        addSuccessFields,
        requiredFieldsConfig
    }), [
        getFieldLabel,
        requiredFieldsStatus,
        requiredFieldsMap,
        selectedTaskActivityMap,
        onChangeTaskActivity,
        clearErrorFields,
        addErrorFields,
        availableTasks,
        myAssignedTasks,
        clearSuccessFields,
        addSuccessFields,
        requiredFieldsConfig
    ]);
    return React.createElement(RequiredFieldsContext.Provider, { value: value, ...props });
};
const useRequiredFields = () => {
    const context = useContext(RequiredFieldsContext);
    if (context == null) {
        throw new Error('useRequiredFields must be used within an RequiredFieldsProvider');
    }
    return context;
};
export { RequiredFieldsProvider, useRequiredFields };
