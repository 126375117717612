import { BasicInput, BasicTextArea } from '@hypercharge/hyper-react-base/lib/form';
import React from 'react';
import { FieldError, FieldWrapper } from '../../FieldElements';
import styles from './PlainTextEditor.module.scss';
const PlainTextEditor = ({ meta, value, onChange, disabled, onKeyDown, errorMessage, autoFocus = true, editing = true, placeholder }) => {
    const isTextarea = meta && meta.multiline;
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWrapper, { className: isTextarea ? styles.textarea : styles.input, editing: editing, disabled: disabled, error: !!errorMessage }, isTextarea ? (React.createElement(BasicTextArea, { placeholder: placeholder, 
            // @ts-expect-error
            input: { value: value || '' }, autoFocus: autoFocus, 
            // @ts-expect-error
            onChange: (e) => onChange && onChange(e.target.value), disabled: disabled, onKeyDown: (e) => {
                if (onKeyDown && e.key !== 'Enter') {
                    onKeyDown(e);
                }
            }, rows: (value || '').split('\n').length })) : (React.createElement(BasicInput, { placeholder: placeholder, 
            // @ts-expect-error
            input: { value: value || '' }, autoFocus: autoFocus, onChange: (e) => onChange && onChange(e.target.value || null), disabled: disabled, onKeyDown: onKeyDown, maxLength: (meta && meta.validations && meta.validations.maxLength) || undefined }))),
        React.createElement(FieldError, { error: errorMessage })));
};
export default PlainTextEditor;
