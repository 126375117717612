import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { createValidator, email, LabelText, required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import { Divider } from 'antd';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { FcGoogle } from 'react-icons/fc';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RichTextDisplay from '../../../cms/data/components/item-property/display/RichTextDisplay';
import { AntInputField } from '../../../common/components/AntInput';
import FormikField from '../../../common/components/formik/FormikField';
import LoadingIndicator from '../../../common/components/loading-indicator';
import { createRequest } from '../../../common/utils/request';
import { useDisplayTenant } from '../../../tenant/useDisplayTenant';
import { redirectIfAuthenticated } from '../../authRedirectWrappers';
import { AUTH_API_PATH, AUTH_EMAIL_PARAM, AUTH_PATH, GET_STARTED_CODE_PATH } from '../../constants';
import { SsoTypes } from '../../types';
import authStyles from '../AuthRoutes.module.scss';
import { Logo } from '../common/Logo';
import { MicrosoftIcon } from '../common/MicrosoftIcon';
import styles from './EmailFormPage.module.scss';
const ssoButtons = {
    [SsoTypes.microsoft]: {
        text: 'Microsoft',
        icon: React.createElement(MicrosoftIcon, { size: "16", className: "me-2" })
    },
    [SsoTypes.google]: {
        text: 'Google',
        icon: React.createElement(FcGoogle, { size: "16", className: "me-2" })
    }
};
export const emailFormValidator = createValidator({ email: [required, email] });
const EmailFormPage = () => {
    const { t } = useI18n();
    const location = useLocation();
    const { displayTenant } = useDisplayTenant();
    const dispatch = useDispatch();
    const initialValues = useMemo(() => {
        const search = queryString.parse(location.search);
        const email = search[AUTH_EMAIL_PARAM];
        const code = search['code'];
        if (email && !Array.isArray(email)) {
            return {
                email: email,
                ...(code && { code })
            };
        }
        return {};
    }, [location.search]);
    const { data: ssoListResults, isLoading } = useQuery(['ssoList'], () => {
        if (!displayTenant?.id) {
            return;
        }
        const { response } = createRequest({
            url: `${AUTH_API_PATH}/sso/${displayTenant.id}`,
            method: 'GET'
        });
        return response;
    }, {
        enabled: !!displayTenant?.id && !initialValues.code,
        cacheTime: Infinity,
        staleTime: Infinity
    });
    const onSubmit = useCallback((values) => {
        dispatch(push({
            ...location,
            pathname: `${AUTH_PATH}${GET_STARTED_CODE_PATH}`,
            search: queryString.stringify(omit(queryString.parse(location.search), [AUTH_EMAIL_PARAM, 'code'])),
            state: values
        }));
    }, [dispatch, location]);
    useEffect(() => {
        const { email, code } = initialValues;
        if (email && code) {
            onSubmit(initialValues);
        }
    }, [initialValues, onSubmit]);
    if (!displayTenant) {
        return null;
    }
    return (React.createElement("div", { className: "position-relative overflow-hidden" },
        React.createElement(Helmet, { title: `${t('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant.name}` }),
        React.createElement(Logo, null),
        React.createElement("div", null, initialValues.email && initialValues.code ? (React.createElement("div", { className: `${styles.loadingWrapper} position-relative` },
            React.createElement(LoadingIndicator, null))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${authStyles.subtitle} mt-5 mb-5` }, displayTenant?.loginPage?.topText ? (React.createElement(RichTextDisplay, { value: displayTenant?.loginPage?.topText })) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: `${authStyles.title} fw-600` }, t('AUTH_GET_STARTED')),
                t('AUTH_GET_STARTED_BY_EMAIL_SUBTITLE')))),
            React.createElement(Formik, { initialValues: initialValues, onSubmit: onSubmit, validate: emailFormValidator },
                React.createElement(Form, { noValidate: true },
                    React.createElement(LabelText, null, t('AUTH_EMAIL')),
                    React.createElement(FormikField, { name: "email", component: AntInputField, type: "text", inputMode: "email", placeholder: "john@company.com", "data-test-id": "input-login-email" }),
                    React.createElement("div", null,
                        React.createElement(Button, { type: "submit", className: `${authStyles.submitButton} w-100 mt-4 p-2`, "data-test-id": "submit-login-email" }, t('COMMON__CONTINUE'))),
                    isLoading ? (React.createElement("div", { className: `${styles.loadingWrapper} position-relative` },
                        React.createElement(LoadingIndicator, null))) : (!!ssoListResults?.results.length && (React.createElement(React.Fragment, null,
                        React.createElement(Divider, { className: `${styles.ssoTitle} w-100 my-4` }, t('AUTH_LOGIN_WITH_SSO')),
                        ssoListResults.results.map((sso) => (React.createElement("div", { key: sso.type, className: "mb-3" },
                            React.createElement(Button, { href: sso.authUri, type: "button", className: `${styles.ssoButton} d-flex justify-content-center align-items-center w-100 p-2`, "data-test-id": "submit-login-email", inversed: true },
                                ssoButtons[sso.type].icon,
                                t(ssoButtons[sso.type].text || sso.type)))))))))))))));
};
export default redirectIfAuthenticated(EmailFormPage);
