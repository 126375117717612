import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useMemo } from 'react';
import { MdCheckCircle, MdCloudUpload } from 'react-icons/md';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FileField } from '../../../../../common/storage';
import styles from './UploadForm.module.scss';
export var UploadStatus;
(function (UploadStatus) {
    UploadStatus["idle"] = "idle";
    UploadStatus["uploading"] = "uploading";
    UploadStatus["success"] = "success";
    UploadStatus["fail"] = "fail";
})(UploadStatus || (UploadStatus = {}));
const UploadForm = ({ uploadStatus, onSave }) => {
    const { t } = useI18n();
    const uploadContainerContentJsx = useMemo(() => {
        switch (uploadStatus) {
            case 'idle':
                return (React.createElement(React.Fragment, null,
                    React.createElement(MdCloudUpload, { className: "uploadIcon display-1 mb-3 me-0" }),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOAD_CONTAINER_TEXT_1')),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOAD_CONTAINER_TEXT_2'))));
            case 'uploading':
                return (React.createElement(React.Fragment, null,
                    React.createElement(MdCloudUpload, { className: "uploadIcon display-1 mb-3 me-0" }),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOADING'))));
            case 'fail':
                return (React.createElement(React.Fragment, null,
                    React.createElement(MdCloudUpload, { className: "uploadIcon display-1 mb-3 me-0" }),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOAD_CONTAINER_TEXT_1')),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOAD_CONTAINER_TEXT_2')),
                    React.createElement("div", { className: "error-message" }, t('SOMETHING_WENT_WRONG'))));
            case 'success':
                return (React.createElement(React.Fragment, null,
                    React.createElement(MdCheckCircle, { className: "uploadedIcon display-1 mb-3 me-0" }),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOAD_SUCCESS_TEXT_1')),
                    React.createElement("p", null, t('CMS_IMPORTER__UPLOAD_SUCCESS_TEXT_2'))));
        }
    }, [t, uploadStatus]);
    return (React.createElement(FileField, { files: [], meta: fileFieldMeta, error: uploadStatus === 'fail', success: uploadStatus === 'success', canEdit: true, loading: uploadStatus === 'uploading', onSave: onSave, className: `${styles.wrraper} mt-0 justify-content-center ` },
        React.createElement("div", { className: "text-center file-upload-placeholder" }, uploadContainerContentJsx)));
};
export default compose(connect())(UploadForm);
//
// Utils
//
const fileFieldMeta = {
    accept: '.csv,.xml,.json,.txt',
    validations: { required: true },
    carousel: false,
    list: false
};
