import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { info } from '@hypercharge/hyper-react-base/lib/notifications';
import { push, replace } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDisplayTenant } from '../../../tenant/useDisplayTenant';
import { logIn, requestAuthCodeByEmail, retrieveFactorAccounts } from '../../actions';
import { redirectIfAuthenticated } from '../../authRedirectWrappers';
import { ACCOUNTS_PATH, AUTH_PATH } from '../../constants';
import { emailFormValidator } from '../EmailFormPage';
import { handleFailure } from '../common/utils';
import CodeForm from './CodeForm';
const CodeFormPage = ({ location }) => {
    const { t } = useI18n();
    const { displayTenant } = useDisplayTenant();
    const dispatchRequestCode = useDispatch();
    const dispatchRequestAccounts = useDispatch();
    const dispatchLogin = useDispatch();
    const dispatch = useDispatch();
    const { email, code: codeFromSso } = location.state;
    const requestCode = useCallback(async (newCode) => {
        if (!displayTenant || !email) {
            return;
        }
        try {
            await dispatchRequestCode(requestAuthCodeByEmail(displayTenant.id, email));
            if (newCode) {
                dispatch(info({
                    title: t('COMMON__INFO'),
                    message: t('AUTH_A_NEW_CODE_WAS_SENT')
                }));
            }
        }
        catch (error) {
            handleFailure(dispatch, 'AUTH_REQUEST_CODE_ERROR_MESSAGE');
        }
    }, [dispatch, dispatchRequestCode, displayTenant, email, t]);
    const redirectOnWrongInit = useCallback(() => {
        dispatch(replace(AUTH_PATH));
    }, [dispatch]);
    const onSubmit = useCallback(async ({ code }) => {
        if (!displayTenant || !email) {
            return;
        }
        try {
            const factorAccounts = await dispatchRequestAccounts(retrieveFactorAccounts(displayTenant.id, email, code));
            if (factorAccounts.length === 0) {
                // TODO: handle this case -> they don't have an account
                return;
            }
            else if (factorAccounts.length === 1) {
                const factorAccount = factorAccounts[0];
                await dispatchLogin(logIn(displayTenant.id, factorAccount.entityId, email, code));
            }
            else {
                dispatch(push({
                    ...location,
                    pathname: `${AUTH_PATH}${ACCOUNTS_PATH}`,
                    search: location.search,
                    state: { factorAccounts, email, code }
                }));
            }
        }
        catch (err) {
            handleFailure(dispatch);
        }
    }, [dispatch, dispatchLogin, dispatchRequestAccounts, displayTenant, email, location]);
    useEffect(() => {
        const isValidEmail = emailFormValidator({ email }).email;
        if (isValidEmail) {
            redirectOnWrongInit();
        }
        else if (codeFromSso) {
            void onSubmit({ code: codeFromSso });
        }
        else {
            void requestCode();
        }
    }, [email, codeFromSso, redirectOnWrongInit, requestCode, onSubmit]);
    if (!displayTenant || !email) {
        return null;
    }
    return (React.createElement(CodeForm, { displayTenant: displayTenant, email: email, onSubmit: onSubmit, onClickSendNewCode: requestCode, loading: !!(email && codeFromSso) }));
};
export default redirectIfAuthenticated(CodeFormPage);
