import { ORIGINAL_DATA_PROPERTY_ID } from './constants';
export var ImportTypes;
(function (ImportTypes) {
    ImportTypes["manual"] = "MANUAL";
    ImportTypes["scheduled"] = "SCHEDULED";
})(ImportTypes || (ImportTypes = {}));
export var ImportModes;
(function (ImportModes) {
    ImportModes["CREATE_ONLY"] = "CREATE_ONLY";
    ImportModes["UPDATE_ONLY"] = "UPDATE_ONLY";
    ImportModes["CREATE_UPDATE"] = "CREATE_UPDATE";
})(ImportModes || (ImportModes = {}));
export var IMPORT_STATUS;
(function (IMPORT_STATUS) {
    IMPORT_STATUS["RUNNING"] = "RUNNING";
    IMPORT_STATUS["PAUSED"] = "PAUSED";
})(IMPORT_STATUS || (IMPORT_STATUS = {}));
