const authEnhanceRootReducer = (rootReducer, persistConfig) => (state, action) => {
    // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    let newState = state;
    if (['AUTH__LOG_OUT', 'AUTH__REFRESH_AUTH_TOKEN_FAIL'].includes(action.type)) {
        newState = undefined;
        persistConfig.storage.removeItem(`persist:${persistConfig.key}`);
    }
    if (['AUTH__LOG_OUT_SUCCESS', 'AUTH__REFRESH_AUTH_TOKEN_FAIL'].includes(action.type)) {
        location.reload();
    }
    return rootReducer(newState, action);
};
export default authEnhanceRootReducer;
