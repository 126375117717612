import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tabs } from 'antd';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import AvailableEntitiesForm, { getAvailableEntitiesFormSchema } from './components/AvailableEntitiesForm/AvailableEntitiesForm';
import FormsOptionsForm, { getFormsOptionsFormSchema } from './components/FormsOptionsForm';
import WhitelabelOptionsForm, { getWhitelabelFormSchema } from './components/WhitelabelOptionsForm';
export const initialValues = {
    enabled: true,
    configuration: {}
};
var AvailableTabs;
(function (AvailableTabs) {
    AvailableTabs["WHITELABEL"] = "WHITELABEL";
    AvailableTabs["FORMS"] = "FORMS";
    AvailableTabs["ENTITIES"] = "ENTITIES";
})(AvailableTabs || (AvailableTabs = {}));
const isTabAvailable = (tabName) => !!AvailableTabs[tabName];
export const getFormSchema = () => {
    return Yup.object().shape({
        enabled: Yup.boolean().default(true),
        title: Yup.string().default('Unnamed'),
        configuration: Yup.object()
            .shape({
            whitelabel: getWhitelabelFormSchema(),
            availableForms: getFormsOptionsFormSchema(),
            availableEntities: getAvailableEntitiesFormSchema()
        })
            .required()
    });
};
const Form = ({ instance, setFieldValue, disabled }) => {
    const { t } = useI18n();
    const [activeTab, setActiveTab] = useState();
    const onChangeTab = useCallback((activeTab) => {
        if (isTabAvailable(activeTab)) {
            setActiveTab(activeTab);
        }
    }, []);
    return (React.createElement(Tabs, { activeKey: activeTab, onChange: onChangeTab, items: [
            {
                key: AvailableTabs.WHITELABEL,
                label: t('APPS_CUSTOMER_PORTAL_WHITELABEL__HEADER'),
                children: (React.createElement(WhitelabelOptionsForm, { whitelabel: instance.configuration.whitelabel, setFieldValue: setFieldValue, disabled: disabled }))
            },
            {
                key: AvailableTabs.FORMS,
                label: t('FORMS_NAV_LINK'),
                children: (React.createElement(FormsOptionsForm, { availableForms: instance.configuration.availableForms, disabled: disabled }))
            },
            {
                key: AvailableTabs.ENTITIES,
                label: t('ENTITIES'),
                children: (React.createElement(AvailableEntitiesForm, { availableEntities: instance.configuration.availableEntities, setFieldValue: setFieldValue, disabled: disabled }))
            }
        ] }));
};
export default Form;
