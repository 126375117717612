import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { TooltipTruncate } from '../TooltipTruncate';
import styles from './ShowItemsLimit.module.scss';
const ShowItemsLimit = ({ withDivider, show, maxItemsCount }) => {
    const { t } = useI18n();
    if (show) {
        return (React.createElement("div", { className: `${styles.countWarning} ${withDivider ? 'withDivider' : ''} fw-600 d-flex align-items-center flex-truncate` },
            React.createElement(BsFillExclamationTriangleFill, { className: "me-2" }),
            React.createElement(TooltipTruncate, { className: "text-nowrap flex-truncate" }, t('MAX_COUNT_WARNING', {
                maxNumberItems: maxItemsCount
            }))));
    }
    return null;
};
export default ShowItemsLimit;
