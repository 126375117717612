import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { isString } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import FilterModal from '../../../../../../../common/components/InlineEditor/FilterModal';
import { getDefaultValueForOnlySelectedSwitch, getInitialFiltersQuery } from '../../../../../../../common/components/InlineEditor/utils';
import { getDataForSave } from '../../../../../../../common/utils/common';
import { ViewProvider } from '../../../../../../../views';
import { getFilterRequestWithoutFilters } from '../../../../../../common/utils/utils';
import { isPropertyItemMeta } from '../../../../../types';
import { isDisplayEntityAsTable, isEntityInSingleMode } from '../../../utils';
import { EntityDisplayAsTable } from './EntityDisplayAsTable';
const getModifiedMeta = (additionalColumns, meta) => {
    const metaFilterRequest = isPropertyItemMeta(meta)
        ? meta?.selector?.filtering?.filterRequest
        : null;
    const fullTextFields = isDisplayEntityAsTable(meta) && metaFilterRequest?.fullTextFields
        ? metaFilterRequest?.fullTextFields
        : [];
    const responseFields = isDisplayEntityAsTable(meta) && metaFilterRequest?.responseFields
        ? metaFilterRequest?.responseFields
        : [];
    const filteredAdditionalColumnsTextFields = additionalColumns.filter((item) => !fullTextFields.includes(item));
    const filteredAdditionalColumnsResponseFields = additionalColumns.filter((item) => !responseFields.includes(item));
    return isPropertyItemMeta(meta)
        ? {
            ...meta,
            selector: {
                ...meta?.selector,
                filtering: {
                    ...meta?.selector?.filtering,
                    filterRequest: {
                        ...meta?.selector?.filtering?.filterRequest,
                        fullTextFields: [...filteredAdditionalColumnsTextFields, ...fullTextFields],
                        responseFields: [...filteredAdditionalColumnsResponseFields, ...responseFields]
                    }
                }
            }
        }
        : {};
};
export const EntityDisplayAsTableContainer = ({ meta, value, onSave, canEdit, definitionId, entityId, updateNestedPropertyData, bulkUpdateNestedItems, permissionFieldsConfig, additionalColumns, nestedRequiredFieldsConfig }) => {
    const { t } = useI18n();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const filterRequest = useMemo(() => {
        const initialMeta = additionalColumns?.length ? getModifiedMeta(additionalColumns, meta) : meta;
        return getInitialFiltersQuery(initialMeta);
    }, [meta, additionalColumns]);
    const modifiedFilterRequestParams = useMemo(() => getFilterRequestWithoutFilters(filterRequest), [filterRequest]);
    const handleSave = useCallback((ids) => {
        setIsModalOpen(false);
        void onSave?.(getDataForSave(ids, isEntityInSingleMode(meta)));
    }, [meta, onSave]);
    const handleModalClose = () => setIsModalOpen(false);
    const handleModalOpen = () => setIsModalOpen(true);
    const viewSelected = useMemo(() => {
        if (Array.isArray(value)) {
            return value;
        }
        if (isString(value)) {
            return [value];
        }
        return [];
    }, [value]);
    const viewQueryFilterContext = useMemo(() => {
        if (definitionId && entityId) {
            return {
                definitionId,
                entityId
            };
        }
    }, [definitionId, entityId]);
    if (!isPropertyItemMeta(meta)) {
        return React.createElement("div", null, t('NO_SUPPORTED_PROPERTIES_AVAILABLE'));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ViewProvider, { definitionId: meta.definitionId, filterRequest: modifiedFilterRequestParams, rowHeightType: meta.selector?.filtering?.rowHeightType, metrics: meta.selector?.filtering?.metrics, initialRowGrouping: meta.selector?.filtering?.rowGrouping, initialRowGroupingOrderBy: meta.selector?.filtering?.rowGroupingOrderBy, filteredItems: viewSelected, enableGrouping: true },
            React.createElement(EntityDisplayAsTable, { meta: meta, onShowFilterModal: handleModalOpen, onSave: onSave, 
                // @ts-expect-error
                value: value, canEdit: canEdit, updatePropertyData: updateNestedPropertyData, bulkUpdateItems: bulkUpdateNestedItems, permissionFieldsConfig: permissionFieldsConfig, nestedRequiredFieldsConfig: nestedRequiredFieldsConfig })),
        React.createElement(ViewProvider, { definitionId: meta.definitionId, filterRequest: filterRequest, context: viewQueryFilterContext, rowHeightType: meta.selector?.filtering?.rowHeightType, metrics: meta.selector?.filtering?.metrics, selectedItems: viewSelected, initialFilterSelected: getDefaultValueForOnlySelectedSwitch(meta), initialSelectedItems: viewSelected, initialRowGroupingOrderBy: meta.selector?.filtering?.rowGroupingOrderBy, initialRowGrouping: meta.selector?.filtering?.rowGrouping, enableGrouping: true },
            React.createElement(FilterModal, { open: isModalOpen, meta: meta, definitionId: meta.definitionId, handleOk: handleSave, requiredFieldsConfig: nestedRequiredFieldsConfig, handleCancel: handleModalClose }))));
};
