import React, { useMemo } from 'react';
import { ErrorLabel, getIsFormHasError } from '../../utils/formUtils';
import { Select } from './Select';
const SelectField = ({ input: { onChange, value }, ...otherProps }) => {
    const { mode, meta } = otherProps;
    if (mode && ['', null, undefined].includes(value)) {
        value = [];
    }
    const showError = useMemo(() => getIsFormHasError(meta), [meta]);
    return (React.createElement("div", { className: "position-relative" },
        React.createElement(Select, { className: "w-100", ...otherProps, onChange: onChange, value: value || undefined, status: showError ? 'warning' : undefined }),
        React.createElement(ErrorLabel, { show: !!showError, error: meta?.error, position: 'absolute' })));
};
export default SelectField;
