import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
const SystemSectionsVisibilityContext = React.createContext({
    hiddenSections: [],
    isSystemSectionsVisible: false,
    setIsSystemSectionsVisible: () => undefined,
    flipIsSystemSectionsVisible: () => undefined
});
const STORAGE_KEY = 'systemSectionsVisibilityState';
const getSystemSectionsVisibilityState = () => {
    const storageData = localStorage.getItem(STORAGE_KEY);
    const systemSectionsVisibilityState = storageData
        ? JSON.parse(storageData)
        : {};
    return systemSectionsVisibilityState;
};
export const SystemSectionsVisibilityProvider = ({ id, children, hiddenSections = [] }) => {
    const [isSystemSectionsVisible, setIsSystemSectionsVisible] = useState(false);
    const flipIsSystemSectionsVisible = useCallback(() => setIsSystemSectionsVisible(!isSystemSectionsVisible), [isSystemSectionsVisible]);
    const value = useMemo(() => ({
        hiddenSections,
        isSystemSectionsVisible,
        setIsSystemSectionsVisible,
        flipIsSystemSectionsVisible
    }), [hiddenSections, isSystemSectionsVisible, flipIsSystemSectionsVisible]);
    useEffect(() => {
        const state = getSystemSectionsVisibilityState();
        setIsSystemSectionsVisible(state[id] || false);
    }, [id]);
    useEffect(() => {
        const state = getSystemSectionsVisibilityState();
        state[id] = isSystemSectionsVisible;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }, [id, isSystemSectionsVisible]);
    return (React.createElement(SystemSectionsVisibilityContext.Provider, { value: value }, children));
};
export const useSystemSectionsVisibility = () => {
    const context = useContext(SystemSectionsVisibilityContext);
    if (context == null) {
        throw new Error('useSystemSectionsVisibility must be used within an SystemSectionsVisibilityProvider');
    }
    return context;
};
