import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import { FieldArray } from 'formik';
import React, { useCallback } from 'react';
import { FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyButton } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { isEntityListProperty, isFileProperty } from '../../../../../../cms/data/components/item-property/SelectProperty/utils';
import { isEntityDisplayData } from '../../../../../../cms/data/types';
import Toggle from '../../../../../../common/components/Toggle';
import { ItemMetaSelector } from '../../../../../../common/components/entity-selectors';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { INSPECTION_DEFINITION_ID, INSPECTION_GUIDE_IMAGE_DEFINITION_ID } from '../../constants';
import { InspectionSteps } from '../../types';
import { BaseSectionSchema, BaseStepSchema } from '../utils/schema';
import { SectionStepDetailsSettingsForm } from './SectionStepDetailsSettingsForm';
export const GalleryStepSectionSchema = Yup.object().shape({
    ...BaseSectionSchema.fields,
    propertyId: Yup.string().required(),
    useGuided: Yup.boolean(),
    additionalUpdate: Yup.array()
        .of(Yup.object().shape({
        propertyId: Yup.string().required('Required'),
        definitionId: Yup.string().required('Required'),
        entityIdFrom: Yup.string().required('Required')
    }))
        .optional()
});
const isEntityGuideImageListProperty = (displayData) => isEntityListProperty(displayData) &&
    displayData.meta.definitionId === INSPECTION_GUIDE_IMAGE_DEFINITION_ID;
export const GalleryStepTypeSchema = Yup.object().shape({
    ...BaseStepSchema.fields,
    sections: Yup.array().of(GalleryStepSectionSchema).required().min(1),
    type: Yup.string().required().oneOf([InspectionSteps.gallery])
});
const GalleryStepDetailsSettingsForm = ({ disabled, step, setFieldValue, basicPath, ...otherProps }) => {
    const { t } = useI18n();
    const sectionConfig = useCallback(({ sectionIndex }) => (React.createElement(React.Fragment, null,
        React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_GALLERY__USE_GUIDED') }),
        React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].useGuided`, component: Toggle, onChange: () => {
                setFieldValue(`${basicPath}.sections[${sectionIndex}].propertyId`, null, true);
            } }),
        React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_GALLERY__PROPERTY_FOR_FILES') }),
        React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}].propertyId`, component: SelectPropertyButton, definitionId: INSPECTION_DEFINITION_ID, includeProperty: step?.sections?.[sectionIndex].useGuided
                ? isEntityGuideImageListProperty
                : isFileProperty, includeNestedProperties: false, disabled: disabled }),
        step?.sections?.[sectionIndex].propertyId && (React.createElement(React.Fragment, null,
            React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_GALLERY__ADDITIONAL_UPLOAD') }),
            React.createElement(FieldArray, { name: `${basicPath}.sections[${sectionIndex}.additionalUpdate`, render: (additionalUpdateArrayHelpers) => {
                    return (React.createElement("div", { className: "d-flex flex-column gap-3" },
                        step.sections?.[sectionIndex].additionalUpdate?.map((additionalUpdate, additionalUpdateIndex) => (React.createElement("div", { className: "d-flex flex-column gap-3 border rounded p-2", key: additionalUpdateIndex },
                            React.createElement("div", { className: "d-flex flex-column gap-2" },
                                React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_GALLERY__ADDITIONAL_UPLOAD_DEFINITION') }),
                                React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}.additionalUpdate[${additionalUpdateIndex}].definitionId`, component: ItemMetaSelector, className: "w-100", onChange: () => {
                                        setFieldValue(`${basicPath}.sections[${sectionIndex}.additionalUpdate[${additionalUpdateIndex}].propertyId`, undefined);
                                    }, disabled: disabled })),
                            additionalUpdate.definitionId && (React.createElement("div", { className: "d-flex flex-column gap-2" },
                                React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_GALLERY__ADDITIONAL_UPLOAD_PROPERTY') }),
                                React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}.additionalUpdate[${additionalUpdateIndex}].propertyId`, component: SelectPropertyButton, className: "w-100", definitionId: additionalUpdate.definitionId, includeNestedProperties: false, includeProperty: step?.sections?.[sectionIndex].useGuided
                                        ? isEntityGuideImageListProperty
                                        : isFileProperty }))),
                            additionalUpdate.definitionId && additionalUpdate.propertyId && (React.createElement("div", { className: "d-flex flex-column gap-2" },
                                React.createElement(FieldLabel, { label: t('APPS_HYPER_INSPECTION_STEPS_CONFIG_GALLERY__ADDITIONAL_UPLOAD_PROPERTY_GET_ENTITY_ID') }),
                                React.createElement(FormikField, { name: `${basicPath}.sections[${sectionIndex}.additionalUpdate[${additionalUpdateIndex}].entityIdFrom`, component: SelectPropertyButton, className: "w-100", definitionId: INSPECTION_DEFINITION_ID, includeNestedProperties: false, includeProperty: (displayData) => isEntityDisplayData(displayData) &&
                                        displayData.meta.definitionId === additionalUpdate.definitionId }))),
                            React.createElement(Button, { danger: true, onClick: () => {
                                    additionalUpdateArrayHelpers.remove(additionalUpdateIndex);
                                }, icon: React.createElement(FaTrash, null) })))),
                        React.createElement("div", null,
                            React.createElement(Button, { onClick: () => {
                                    additionalUpdateArrayHelpers.push({});
                                } }, t('ADD')))));
                } }))))), [basicPath, disabled, setFieldValue, step?.sections, t]);
    return (React.createElement(SectionStepDetailsSettingsForm, { ...otherProps, step: step, disabled: disabled, sectionConfig: sectionConfig, basicPath: basicPath, setFieldValue: setFieldValue }));
};
export default GalleryStepDetailsSettingsForm;
