export const goBack = () => ({
    type: 'ROUTER__LOCATION_BACK'
});
export const push = (location) => ({
    type: 'ROUTER__LOCATION_PUSH',
    payload: location
});
export const replace = (location) => ({
    type: 'ROUTER__LOCATION_REPLACE',
    payload: location
});
export const locationChange = (location) => ({
    type: 'LOCATION_CHANGE',
    payload: {
        hash: location.hash,
        pathname: location.pathname,
        search: location.search
    }
});
