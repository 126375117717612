import React from 'react';
export const MicrosoftIcon = ({ size = 24, className }) => (React.createElement("svg", { width: size, height: size, className: className, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_3987_576289)" },
        React.createElement("path", { d: "M0 0H24V24H0V0Z", fill: "#F3F3F3" }),
        React.createElement("path", { d: "M1.04297 1.04348H11.4778V11.4783H1.04297V1.04348Z", fill: "#F35325" }),
        React.createElement("path", { d: "M12.5215 1.04348H22.9563V11.4783H12.5215V1.04348Z", fill: "#81BC06" }),
        React.createElement("path", { d: "M1.04297 12.5217H11.4778V22.9565H1.04297V12.5217Z", fill: "#05A6F0" }),
        React.createElement("path", { d: "M12.5215 12.5217H22.9563V22.9565H12.5215V12.5217Z", fill: "#FFBA08" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_3987_576289" },
            React.createElement("rect", { width: "24", height: "24", fill: "white" })))));
