import { compact, filter, find, flatMap, has, reduce } from 'lodash';
import { isConditionQuery } from '../../../common/components/ConditionQuery/utils';
import { isTextMeta } from '../../data/types';
export const getField = (displayData, parentPropertyId, asFilter) => {
    // This complexity is needed to accommodate parent properties of type entity
    // being reflected with their internal entityId property in redux-form.
    // That is because the backend has the expanded objects to filter on at this level.
    if (parentPropertyId) {
        // None of the values that are nested are expanded in the backend,
        // so there is no need to append .entityId at this level
        return `${parentPropertyId}.${displayData.propertyId}`;
    }
    else if (asFilter && displayData.type === 'entity') {
        // Root-level properties of type entity are expanded in the
        // backend so need to be matched on their nested entityId property
        return `${displayData.propertyId}.entityId`;
    }
    else if (asFilter && displayData?.type === 'link') {
        return `${displayData.propertyId}.text`; // This could also be implemented as 'link'
    }
    return displayData.propertyId;
};
export const getFiltersCount = (filterRequest) => {
    let count = 0;
    const countFilters = (query) => {
        query.filters.forEach((filter) => {
            if (isConditionQuery(filter)) {
                countFilters(filter);
            }
            else {
                count += 1;
            }
        });
    };
    if (filterRequest && filterRequest.query) {
        countFilters(filterRequest.query);
    }
    return count;
};
export const getFilterRequestWithoutFilters = (filterRequest) => {
    if (filterRequest?.query?.filters) {
        return {
            ...filterRequest,
            query: {
                ...filterRequest.query,
                filters: []
            }
        };
    }
    return filterRequest;
};
export const getPropertyById = (displayData, propertyId) => {
    if (!displayData) {
        return;
    }
    return find(compact(flatMap(displayData.data, 'values')), (item) => item.propertyId === propertyId);
};
export const getAllProperties = (displayDataList) => {
    if (displayDataList?.data) {
        return displayDataList.data.reduce((acc, section) => {
            section.values.forEach((property) => {
                if (property) {
                    acc[property.propertyId] = property.value;
                }
            });
            return acc;
        }, {});
    }
    return {};
};
export const getPropertiesForTranslation = (data) => reduce(data, (properties, section) => [
    ...properties,
    ...reduce(filter(section.values, (value) => has(value, ['meta', 'translations'])), (acc, value) => [
        ...acc,
        ...(Object.values(isTextMeta(value.meta) && value.meta.translations ? value.meta.translations : {}) || [])
    ], [])
], []);
