import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { flatten, isString } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableFields } from '../../../../cms/data/components/item-property/TableFields';
import { getFilePropertyValueList } from '../../../../cms/data/components/item-property/utils';
import useDisplayData from '../../../../cms/hooks/useDisplayData';
import { getFileData, isFileDataPending, uploadFiles } from '../../../../common/storage';
import { useBulkUpdateTaskRunField } from '../../hooks/useBulkUpdateTaskRunField';
import { useUpdateTaskRunField } from '../../hooks/useUpdateTaskRunField';
const TaskFields = ({ canEdit = true, processMetaId, processRunId, taskMetaId, taskRunId, fields, requiredFieldsConfig, permissionFieldsConfig, additionalFields, onChange }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const { mutateAsync: updateTaskRunField } = useUpdateTaskRunField({
        processMetaId,
        processRunId,
        taskMetaId,
        taskRunId
    });
    const { mutateAsync: bulkUpdateTaskRunField } = useBulkUpdateTaskRunField({
        processMetaId,
        processRunId,
        taskMetaId,
        taskRunId
    });
    const updatePropertyData = useCallback(async (propertyId, value) => {
        await updateTaskRunField({
            entityId: processRunId,
            propertyId,
            value
        });
        if (onChange) {
            void onChange(propertyId, value);
        }
    }, [updateTaskRunField, processRunId, onChange]);
    const updateNestedPropertyData = useCallback(async (entityId, propertyId, value) => {
        await updateTaskRunField({
            entityId,
            propertyId,
            value
        });
    }, [updateTaskRunField]);
    const bulkUpdateNestedItems = useCallback(async (propertyId, value, entityIds) => {
        await bulkUpdateTaskRunField({ propertyId, entityIds, item: value });
    }, [bulkUpdateTaskRunField]);
    const uploadFilesHandler = useCallback(async (propertyId, files) => {
        try {
            const uploadData = await uploadFiles(dispatch, files, processRunId);
            await updatePropertyData(propertyId, uploadData.map((file) => file.id));
        }
        catch (e) {
            notificationDispatch(error({ title: t('ERROR'), message: t('UPLOAD_FAILED') }));
        }
    }, [dispatch, notificationDispatch, processRunId, t, updatePropertyData]);
    const { data: displayDataList } = useDisplayData({
        definitionId: processMetaId,
        entityId: processRunId
    });
    const fieldsData = useSelector((state) => {
        let dataList;
        if (displayDataList) {
            dataList = {
                ...displayDataList,
                data: displayDataList.data.map((section) => ({
                    ...section,
                    values: section.values
                        .filter((displayData) => !displayData?.meta?.hidden)
                        .map((displayData) => {
                        if (displayData?.type === 'file') {
                            const values = getFilePropertyValueList(displayData.value);
                            const displayDataValues = [];
                            values.forEach((file) => {
                                if (isString(file)) {
                                    displayDataValues.push(getFileData(state, file) || file);
                                }
                                else if (file) {
                                    displayDataValues.push(getFileData(state, file.id) || file.id);
                                }
                            });
                            displayData.value = displayDataValues;
                            displayData.pending = values.reduce((result, file) => file
                                ? isFileDataPending(state, typeof file === 'string' ? file : file.id)
                                : result, false);
                        }
                        return displayData;
                    })
                        .filter((property) => {
                        const propertyId = property?.propertyId;
                        if (!propertyId) {
                            return false;
                        }
                        if (fields.includes(propertyId)) {
                            return true;
                        }
                        return fields.some((field) => field.startsWith(`${propertyId}.`));
                    })
                }))
            };
        }
        return flatten(dataList?.data?.map((dataItem) => dataItem.values)).sort((a, b) => fields.indexOf(a.propertyId) - fields.indexOf(b.propertyId));
    });
    return (React.createElement(TableFields, { doubleCol: true, fieldsData: fieldsData, definitionId: processMetaId, entityId: processRunId, updatePropertyData: updatePropertyData, updateNestedPropertyData: updateNestedPropertyData, bulkUpdateNestedItems: bulkUpdateNestedItems, uploadFiles: uploadFilesHandler, canEdit: canEdit, requiredFieldsConfig: requiredFieldsConfig, permissionFieldsConfig: permissionFieldsConfig, additionalFields: additionalFields }));
};
export default TaskFields;
