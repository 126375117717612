import { ConditionType, FilterOperatorTypes } from '@hypercharge/portal-utils';
import { isEmpty, isEqual, uniqWith } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useView } from '../ViewContext';
import MetricItemDisplay from './MetricItemDisplay';
import styles from './MetricsDisplayContainer.module.scss';
export const prepareMetricGroupQuery = (field, groupingElements) => {
    if (!groupingElements?.length || !field) {
        return;
    }
    return {
        condition: ConditionType.and,
        filters: groupingElements.map((value) => {
            if (value === null) {
                return {
                    field,
                    operator: FilterOperatorTypes.notEmpty
                };
            }
            return {
                data: value,
                field,
                operator: FilterOperatorTypes.isNot
            };
        })
    };
};
const MetricsDisplayContainer = ({ className }) => {
    const { definitionId, filterRequest, filterRequestForSearch, metrics, updateRouteWithView, viewType, rowGrouping } = useView();
    const updateRouteAndMetrics = useCallback((selectedMetric, filtersToApply, metrics) => {
        const searchQuery = {
            ...filterRequest,
            query: {
                ...filterRequest.query,
                filters: uniqWith(filtersToApply, isEqual)
            }
        };
        const updatedMetrics = metrics.map((metric) => {
            if (metric.id === selectedMetric.id) {
                return selectedMetric;
            }
            return metric;
        });
        updateRouteWithView({
            newFilters: searchQuery,
            newMetrics: updatedMetrics,
            shouldReplacePath: false,
            viewType,
            rowGrouping
        });
    }, [filterRequest, rowGrouping, updateRouteWithView, viewType]);
    const onChangeMetric = useCallback((selectedMetric) => {
        let filtersToApply;
        let metricGroupQuery;
        let metricQuery;
        if (selectedMetric.query?.filters) {
            metricQuery = {
                condition: selectedMetric.query?.condition,
                filters: selectedMetric.query?.filters
            };
        }
        if (selectedMetric?.rowGrouping) {
            metricGroupQuery = prepareMetricGroupQuery(selectedMetric.rowGrouping, selectedMetric.inactiveGroupingElements);
        }
        if (selectedMetric.selected) {
            filtersToApply = [...filterRequest.query.filters];
            metricQuery && filtersToApply.push(metricQuery);
            metricGroupQuery && filtersToApply.push(metricGroupQuery);
        }
        else {
            filtersToApply = filterRequest.query.filters.filter((group) => !isEqual(group, metricQuery) && !isEqual(group, metricGroupQuery));
        }
        updateRouteAndMetrics(selectedMetric, filtersToApply, metrics);
    }, [filterRequest.query.filters, metrics, updateRouteAndMetrics]);
    const onChangeAggregation = useCallback((selectedMetric, previousFilter) => {
        let filtersToApply;
        if (selectedMetric.selected && selectedMetric.rowGrouping) {
            const newMetricGroup = prepareMetricGroupQuery(selectedMetric.rowGrouping, selectedMetric.inactiveGroupingElements);
            const oldMetricGroup = prepareMetricGroupQuery(selectedMetric.rowGrouping, previousFilter);
            const cleanedFilters = filterRequest.query.filters.filter((group) => !isEqual(group, oldMetricGroup));
            filtersToApply = [...cleanedFilters];
            newMetricGroup && filtersToApply.push(newMetricGroup);
        }
        else {
            filtersToApply = [...filterRequest.query.filters];
        }
        updateRouteAndMetrics(selectedMetric, filtersToApply, metrics);
    }, [filterRequest.query.filters, metrics, updateRouteAndMetrics]);
    const conditionalQuery = useMemo(() => ({
        ...filterRequestForSearch,
        limit: 0,
        offset: 0,
        fullTextFields: [],
        fullText: '',
        sortBy: [],
        responseFields: []
    }), [filterRequestForSearch]);
    if (!definitionId || isEmpty(metrics)) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.wrapper} ${className || ''} pb-2 MetricsDisplayOuter`, id: "aggregation-content" }, metrics.map((metric) => (React.createElement(MetricItemDisplay, { key: metric.id, definitionId: definitionId, metric: metric, onChangeMetric: onChangeMetric, onChangeAggregation: onChangeAggregation, additionalMetricFilter: conditionalQuery })))));
};
export default MetricsDisplayContainer;
