import { FORMS__CREATE_ACTION, FORMS__DELETE_ACTION, FORMS__FETCH_LIST_ACTION, FORMS__FETCH_SINGLE_ACTION, FORMS__SAVE_ACTION } from './constants';
const baseUrl = '/v2-forms';
export const fetchList = (page, pageSize, query) => ({
    type: FORMS__FETCH_LIST_ACTION,
    meta: {
        http: {
            path: `${baseUrl}/`,
            method: 'GET',
            params: {
                offset: (page - 1) * pageSize,
                limit: pageSize,
                ...(query ? { query } : {})
            },
            actionContext: { page, pageSize, query }
        },
        auth: true
    }
});
export const fetchForm = (id) => ({
    type: FORMS__FETCH_SINGLE_ACTION,
    payload: id,
    meta: {
        http: {
            path: `${baseUrl}/${id}`,
            method: 'GET',
            actionContext: { id }
        },
        auth: true
    }
});
export const saveForm = (formId, form) => ({
    type: FORMS__SAVE_ACTION,
    payload: form,
    meta: {
        http: {
            path: `${baseUrl}/${formId}`,
            method: 'PUT',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const createForm = (form) => ({
    type: FORMS__CREATE_ACTION,
    payload: form,
    meta: {
        http: {
            path: `${baseUrl}/`,
            method: 'POST',
            allowPromiseReject: true
        },
        auth: true
    }
});
export const deleteForm = (id) => ({
    type: FORMS__DELETE_ACTION,
    meta: {
        http: {
            path: `${baseUrl}/${id}`,
            method: 'DELETE',
            allowPromiseReject: true,
            actionContext: { id }
        },
        auth: true
    }
});
