import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { Button as AntButton } from 'antd';
import { isString } from 'lodash';
import React, { useCallback } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { useDispatch } from 'react-redux';
function ClipboardCopyTableButton({ disabled, asyncCopy, className }) {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const _onClick = useCallback(async (e) => {
        e.stopPropagation();
        try {
            let asyncValue;
            if (!asyncCopy) {
                return;
            }
            if (asyncCopy) {
                asyncValue = await asyncCopy();
            }
            if (Array.isArray(asyncValue) &&
                asyncValue.every((item) => item instanceof ClipboardItem)) {
                await navigator.clipboard.write(asyncValue);
            }
            else {
                const valueString = isString(asyncValue)
                    ? asyncValue
                    : JSON.stringify(asyncValue, undefined, 2);
                await navigator.clipboard.writeText(valueString);
            }
            dispatch(success({
                title: t('SUCCESS'),
                message: t('COPIED')
            }));
        }
        catch (err) {
            dispatch(error({
                title: t('ERROR'),
                message: t('COPY')
            }));
            return;
        }
    }, [asyncCopy, dispatch, t]);
    return (React.createElement(AntButton, { className: className, onClick: _onClick, disabled: disabled || !asyncCopy },
        React.createElement(MdContentCopy, { size: 18 })));
}
export default ClipboardCopyTableButton;
