import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';
export const StyledSectionCard = styled(SectionCard) `
  max-width: none;
  margin: 0 0.5rem;
  border: 1px solid var(--grayscaleColor-5);
`;
export const styledSectionCardHeaderStyle = {
    padding: '11px 16px 13px',
    position: 'relative'
};
export const Form = styled.form `
  height: 100%;
  position: relative;
  box-sizing: border-box;
  max-width: 600px;
  margin: 0 auto;
`;
export const FormContent = styled.div `
  height: 100%;
`;
export const ResetButton = styled((props) => React.createElement(Button, { ...props })) `
  margin-right: 1rem;
`;
export const StyledCheck = styled(IoMdCheckmark) `
  font-size: 0.8rem;
`;
export const StyledCross = styled(IoClose) `
  font-size: 0.8rem;
`;
export const SwitchContainer = styled.div `
  padding-right: 1rem;
`;
export const FormSectionTitle = styled.div `
  font-weight: 600;
  margin-top: 1.5rem;
`;
export const FormButtonsContainer = styled.div `
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
export const InfoContainer = styled.div `
  padding: 5rem 1rem;

  ${media.xl} {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const InfoText = styled.div `
  text-align: center;
  margin-bottom: 1.5rem;
`;
