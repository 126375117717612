import { AutoComplete, Table } from 'antd';
import React, { useMemo } from 'react';
import FormikField from '../../../../common/components/formik/FormikField';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
const XDMSModuleFromXdmsForm = ({ disabled, entityProperties, xdmsSchema, mappings = {} }) => {
    const { t } = useI18n();
    const propertyOptions = useMemo(() => Object.keys(xdmsSchema).map((property) => ({
        value: property,
        label: property
    })), [xdmsSchema]);
    const tableData = useMemo(() => entityProperties.map((property, index) => {
        return {
            key: index,
            cmsProperty: property.title,
            xdmsProperty: (React.createElement(FormikField, { name: `mappings.fromXdms.${property.propertyId}`, value: mappings[property.propertyId] || '', backfill: true, component: AutoComplete, disabled: disabled, style: { width: '100%' }, placeholder: t('INTEGRATIONS_XDMS_MODULE_SELECT_PROPERTY'), options: propertyOptions }))
        };
    }), [entityProperties, mappings, disabled, t, propertyOptions]);
    return (React.createElement(Table, { pagination: {
            total: entityProperties.length,
            pageSize: entityProperties.length,
            hideOnSinglePage: true
        }, dataSource: tableData, columns: [
            {
                title: t('INTEGRATIONS_XDMS_MODULE_CMS_PROPERTY'),
                dataIndex: 'cmsProperty',
                width: 200
            },
            {
                title: t('INTEGRATIONS_XDMS_MODULE_XDMS_PROPERTY'),
                dataIndex: 'xdmsProperty'
            }
        ], style: { marginTop: 20 } }));
};
export default XDMSModuleFromXdmsForm;
