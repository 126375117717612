import { emptyToNull, required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider, Input } from 'antd';
import React, { useCallback } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Field, FieldArray, Fields, FormSection } from 'redux-form';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../../common/components/AntInput';
import { ComputedInputField } from '../../../../../../common/components/ComputedExpressionEditor';
import { usePropertiesTree } from '../../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import LanguageSelectorInput from '../../../../../../common/components/LanguageSelectorInput/LanguageSelectorInput';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import { StyledLink } from '../../../../../../common/components/data-table/utils';
import { AVAILABLE_EMAIL_LANGUAGES } from '../../../../../../common/utils/constants';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import { EMAIL_TEMPLATES_PATH, MARKETING_PATH, TEMPLATE_CREATE_PATH, TemplateSelector } from '../../../../../../marketing';
import NodeSettingsSection from '../NodeSettingsSection';
import ConditionalTemplatesField from './ConditionalTemplatesField';
import styles from './EmailForm.module.scss';
import FormsArrayField from './FormsArrayField';
//
// Utils
//
const MAXIMUM_RECIPIENTS = 15;
const parametersSectionName = 'parameters';
const EmailForm = ({ disabled, parentViewGroups, changeFieldValue, metaDefinitionId }) => {
    const { t } = useI18n();
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    const isFileProperty = useCallback((displayData) => displayData.type === 'file', []);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", placeholder: t('COMMON__TITLE'), component: AntInput, disabled: disabled, maxLength: 60 }),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FormSection, { name: parametersSectionName },
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_FROM_NAME_EMAIL')}`, help: t('TASK_FROM_NAME_EMAIL_INFO') }),
                React.createElement(Field, { name: "sourceName", component: ComputedInputField, computedComponent: AntInput, propertiesTree: propertiesTree, disabled: disabled, normalize: emptyToNull, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_FROM_NAME_EMAIL')}` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_FROM_EMAIL')}`, help: t('TASK_FROM_EMAIL_INFO') }),
                React.createElement(Field, { name: "source", component: ComputedInputField, computedComponent: AntInput, propertiesTree: propertiesTree, disabled: disabled, normalize: emptyToNull, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_FROM_EMAIL')}` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: t('TASK_REPLY_TO_EMAIL') }),
                React.createElement(Field, { name: "replyTo", component: ComputedInputField, computedComponent: AntInput, propertiesTree: propertiesTree, disabled: disabled, normalize: emptyToNull, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_REPLY_TO_EMAIL')}` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_DESTINATION_EMAILS')} (to)`, labelRight: t('TASK_MAXIMUM_EMAILS', { limit: MAXIMUM_RECIPIENTS }), help: t('TASK_DESTINATION_EMAILS_INFO') }),
                React.createElement(Field, { name: "destinationEmails", component: ComputedInputField, computedComponent: AntInput, propertiesTree: propertiesTree, validate: required, disabled: disabled, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_DESTINATION_EMAILS')} (to)` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_DESTINATION_EMAILS')} (cc)`, labelRight: t('TASK_MAXIMUM_EMAILS', { limit: MAXIMUM_RECIPIENTS }), help: t('TASK_DESTINATION_EMAILS_INFO') }),
                React.createElement(Field, { name: "ccDestinationEmails", component: ComputedInputField, computedComponent: Input, propertiesTree: propertiesTree, disabled: disabled, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_DESTINATION_EMAILS')} (cc)` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_DESTINATION_EMAILS')} (bcc)`, labelRight: t('TASK_MAXIMUM_EMAILS', { limit: MAXIMUM_RECIPIENTS }), help: t('TASK_DESTINATION_EMAILS_INFO') }),
                React.createElement(Field, { name: "bccDestinationEmails", component: ComputedInputField, computedComponent: Input, propertiesTree: propertiesTree, disabled: disabled, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_DESTINATION_EMAILS')} (bcc)` })),
            React.createElement("div", null,
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_ATTACHMENT_PROPERTY')}`, help: t('TASK_ATTACHMENT_PROPERTY_INFO') }),
                React.createElement(Field, { name: "attachment", component: ComputedInputField, computedComponent: SelectPropertyField, definitionId: metaDefinitionId, includeProperty: isFileProperty, includeNestedProperties: false, disabled: disabled, placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_ATTACHMENT_PROPERTY')}` })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { className: "ps-0", label: `${t('TASK_EMAIL_LOG')}` }),
                React.createElement(Field, { name: "logEmail", component: ComputedInputField, computedComponent: Toggle, propertiesTree: propertiesTree, disabled: disabled })),
            React.createElement("div", { className: "mb-2" },
                React.createElement(FieldLabel, { label: t('TASK_EMAIL_TEMPLATE'), labelRight: React.createElement(StyledLink, { className: "d-flex align-items-center text-nowrap ms-2", to: [MARKETING_PATH, EMAIL_TEMPLATES_PATH, TEMPLATE_CREATE_PATH].join(''), target: "_blank" },
                        React.createElement("span", { className: "me-1" }, t('CREATE_TEMPLATE__TITLE')),
                        React.createElement(FaExternalLinkAlt, null)), className: "px-0" }),
                React.createElement("div", { className: "d-flex flex-row align-items-center" },
                    React.createElement("div", { className: "col-8 me-2" },
                        React.createElement(Field, { name: "template.id", component: ComputedInputField, computedComponent: TemplateSelector, propertiesTree: propertiesTree, autoFocus: false, validate: required, disabled: disabled, normalize: emptyToNull })),
                    React.createElement("div", { className: "col-4" },
                        React.createElement(Field, { name: "template.lang", component: ComputedInputField, popupMatchSelectWidth: false, computedComponent: LanguageSelectorInput, propertiesTree: propertiesTree, disabled: disabled, languages: AVAILABLE_EMAIL_LANGUAGES })))),
            React.createElement(Field, { name: "templateMappingPropertyId", component: ConditionalTemplatesField, changeFieldValue: changeFieldValue, definitionId: metaDefinitionId, propertiesTree: propertiesTree }),
            React.createElement(Divider, null, `${t('FORM')} ${t('WORKFLOWS_SETTINGS_PARAMETERS').toLowerCase()}`),
            React.createElement("div", { className: "mb-4" },
                React.createElement(FieldArray, { name: "forms", component: FormsArrayField, disabled: disabled, changeFieldValue: changeFieldValue, metaDefinitionId: metaDefinitionId }))),
        React.createElement(Field, { name: "settings", component: NodeSettingsSection, propertiesTree: propertiesTree, disabled: disabled }),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default EmailForm;
