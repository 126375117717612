import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { success } from '@hypercharge/hyper-react-base/lib/notifications';
import { Divider } from 'antd';
import { isEqual, isString } from 'lodash';
import * as NodeHelpers from 'n8n-workflow/dist/NodeHelpers';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Field, Fields, formValueSelector, getFormSyncErrors } from 'redux-form';
import { WORKFLOWS_API_PATH } from '../../../../..';
import { FieldLabel } from '../../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../../common/components/AntInput';
import { usePropertiesTree } from '../../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import MultiLanguageField from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import GroupsField from '../../../../../../groups';
import { AvailableSystemGroups } from '../../../../../../groups/constants';
import { useNodes } from '../../activity-diagram/useNode';
import NodeSettingsSection from '../NodeSettingsSection';
import CredentialList from './Credentials/CredentialsList';
import styles from './Hp3nNodeForm.module.scss';
import ParameterList from './Parameters/ParameterList';
export const HP3N_NODE_PREFIX = 'hp3n_';
function getHttpMethod(webhookDescription, parameters) {
    if (webhookDescription.httpMethod &&
        isString(webhookDescription.httpMethod) &&
        webhookDescription.httpMethod != '={{$parameter["httpMethod"]}}') {
        return webhookDescription.httpMethod;
    }
    if (parameters.httpMethod && isString(parameters.httpMethod)) {
        return parameters.httpMethod;
    }
    return 'GET';
}
function getWebHookUrl(webhookDescription, parameters, definitionId, webhookId) {
    return `https://${window.location.hostname}${WORKFLOWS_API_PATH}/webhooks/${definitionId}/${webhookId || ''}`;
}
const Hp3nNodeForm = ({ disabled, node, metaDefinitionId }) => {
    const { t } = useI18n();
    const store = useStore();
    const dispatch = useDispatch();
    const formErrors = useSelector(getFormSyncErrors('activitySettings'));
    const formNode = formValueSelector('activitySettings')(store.getState(), 'activity.' + node.id);
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    const { data: nodesDescriptions } = useNodes();
    const nodeDescription = nodesDescriptions?.nodes[node.target];
    const onClickWebhookUrl = useCallback((webhookUrl) => {
        navigator.clipboard
            .writeText(webhookUrl)
            .then(() => {
            dispatch(success({
                title: t('COPIED'),
                message: t('TASK_WEBHOOK_SUCCESS_MESSAGE_INFO')
            }));
        })
            .catch(() => {
            dispatch(console.error({
                title: t('FAILED'),
                message: t('TASK_WEBHOOK_ERROR_MESSAGE_INFO')
            }));
        });
    }, [dispatch, t]);
    if (!nodeDescription) {
        return null;
    }
    return (React.createElement("div", { className: `${styles.wrapper}` },
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, placeholder: t('COMMON__TITLE'), disabled: disabled }),
        React.createElement(FieldLabel, { label: t('ACTIVE'), className: "ps-0" }),
        React.createElement(Field, { name: "active", component: Toggle, disabled: disabled }),
        'webhookId' in formNode && nodeDescription.webhooks && nodeDescription.webhooks.length ? (React.createElement(React.Fragment, null,
            React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_WEBHOOK_URLS'), className: "ps-0" }),
            nodeDescription.webhooks.map((webhook, index) => {
                const httpMethod = getHttpMethod(webhook, formNode.parameters);
                const webhookUrl = getWebHookUrl(webhook, formNode.parameters, metaDefinitionId, formNode.webhookId);
                return (React.createElement("div", { key: index, className: "mb-2" },
                    React.createElement("span", { className: "http-method px-2 me-2" }, httpMethod),
                    React.createElement("span", { onClick: () => {
                            onClickWebhookUrl(webhookUrl);
                        }, className: "http-url" }, webhookUrl)));
            }))) : null,
        React.createElement(Field, { name: "credentials", disabled: disabled, node: formNode, component: FormCredentialsSection, nodeDescription: nodeDescription, errors: formErrors?.['activity']?.[node.id]?.['credentials'] }),
        React.createElement(Field, { name: "parameters", disabled: disabled, node: formNode, propertiesTree: propertiesTree, component: FormParametersSection, nodeDescription: nodeDescription, errors: formErrors?.['activity']?.[node.id]?.['parameters'] }),
        React.createElement(Field, { name: "settings", component: NodeSettingsSection, disabled: disabled }),
        !nodeDescription.webhooks || !nodeDescription.webhooks.length ? (React.createElement(React.Fragment, null,
            React.createElement(Divider, null, t('PERMISSIONS')),
            React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled }))) : null));
};
const FormParametersSection = ({ disabled, errors, input, node, propertiesTree, nodeDescription }) => {
    const { t } = useI18n();
    useEffect(() => {
        if (!nodeDescription.properties) {
            return;
        }
        const parameters = NodeHelpers.getNodeParameters(nodeDescription.properties, input.value, true, false, null);
        if (!isEqual(parameters, node.parameters)) {
            input.onChange(parameters);
        }
    }, [node.parameters, input, nodeDescription]);
    const onChangeParameter = useCallback((parameter, value) => {
        input.onChange({ ...input.value, [parameter.name]: value });
    }, [input]);
    if (!nodeDescription.properties.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(ParameterList, { path: "parameters", errors: errors, parameters: nodeDescription.properties, disabled: disabled, propertiesTree: propertiesTree, node: node, onChange: onChangeParameter })));
};
const FormCredentialsSection = ({ disabled, errors, input, node, nodeDescription }) => {
    const { t } = useI18n();
    const filteredCredentials = useMemo(() => {
        if (!nodeDescription.credentials) {
            return [];
        }
        return nodeDescription.credentials.filter((credential) => NodeHelpers.displayParameterPath(node.parameters, credential, '', null));
    }, [nodeDescription, node.parameters]);
    const onChangeCredential = useCallback((parameter, value) => {
        input.onChange({ ...input.value, [parameter.name]: value });
    }, [input]);
    if (!filteredCredentials || !filteredCredentials.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_CREDENTIALS'), className: "ps-0 mt-3" }),
        React.createElement("div", { className: "row credential ps-4" },
            React.createElement("div", { className: "col" },
                React.createElement(CredentialList, { path: "credentials", credentialsDescription: filteredCredentials, disabled: disabled, node: node, onChange: onChangeCredential, errors: errors })))));
};
export default Hp3nNodeForm;
