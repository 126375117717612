import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import Toggle from '../../../../../../common/components/Toggle';
import ComputedExpressionField from '../../ComputedExpressionField';
const TextField = ({ disabled, metaDefinitionId, onChange, value, error }) => {
    const { t } = useI18n();
    const onChangeMultiline = useCallback((checked) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                multiline: checked
            }
        });
    }, [onChange, value]);
    const onChangeComputedExpression = useCallback((val) => {
        onChange({ ...value, computedValue: val });
    }, [onChange, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Toggle, { className: "w-100 py-2", label: t('PROPERTY_MULTI_LINE'), disabled: disabled, onChange: onChangeMultiline, checked: value?.meta.multiline }),
        React.createElement(ComputedExpressionField, { className: "w-100 py-2", disabled: disabled, metaDefinitionId: metaDefinitionId, onChange: onChangeComputedExpression, value: value.computedValue, error: error })));
};
export default TextField;
