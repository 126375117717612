import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Drawer, DrawerContent, DrawerFooter } from '../../../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../../../common/components/Drawer/DrawerContentBlock';
import { DrawerFormFooter } from '../../../../../common/components/Drawer/DrawerFormFooter';
import Cron from './react-js-cron/src/Cron';
import { DEFAULT_LOCALE_EN } from './react-js-cron/src/locale';
const locales = {
    en: DEFAULT_LOCALE_EN
};
const SchedulingFormDrawer = ({ open, value, onChange, onClose }) => {
    const [newValue, setNewValue] = useState(value);
    const { t, language } = useI18n();
    return (React.createElement(Drawer, { title: t('COMMON__EDIT'), onClose: onClose, open: open, destroyOnClose: true },
        React.createElement(DrawerContent, null,
            React.createElement(DrawerContentBlock, null,
                React.createElement(ScCron, { clearButton: false, locale: locales[language] || DEFAULT_LOCALE_EN, 
                    // @ts-expect-error
                    value: newValue, setValue: setNewValue, clockFormat: "12-hour-clock" }))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { submitText: t('INTEGRATIONS_WEBHOOKS_UPDATE_BUTTON'), onSubmit: () => {
                    onChange(newValue);
                }, onCancel: onClose }))));
};
const ScCron = styled(Cron) `
  display: block !important;

  .react-js-cron-field {
    display: block;
  }

  div.react-js-cron-select {
    margin-left: 0;
    display: block !important;
  }
  .react-js-cron-clear-button {
    margin-left: 0;
  }
`;
export default SchedulingFormDrawer;
