import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import LoadingIndicator from '../../../../../../common/components/loading-indicator';
import { KanbanCard } from '../KanbanCard';
import { KanbanList } from '../KanbanList';
import styles from './KanbanBoard.module.scss';
const KanbanBoard = ({ baseUrl, contentHeight, definitionId, isPending, isDropDisabled, fields, lists, viewId, onDragEnd, getCustomColumnCountFilters, getCustomRouteHandleCardClick }) => {
    const [hiddenColumns, setHiddenColumns] = useState(new Set());
    const onToggleList = useCallback((entityId) => {
        if (hiddenColumns.has(entityId)) {
            hiddenColumns.delete(entityId);
        }
        else {
            hiddenColumns.add(entityId);
        }
        setHiddenColumns(new Set([...hiddenColumns]));
    }, [hiddenColumns]);
    useEffect(() => {
        const stopPropagation = (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        };
        // Virtuoso's resize observer can this error,
        // which is caught by DnD and aborts dragging.
        window.addEventListener('error', stopPropagation);
        return () => window.removeEventListener('error', stopPropagation);
    }, []);
    return (React.createElement("div", null,
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement("div", { className: `custom-scroll-styling ${styles.board}`, style: {
                    height: contentHeight
                } }, isPending ? (React.createElement(LoadingIndicator, null)) : (lists.map(({ cards, propertyId, title, theme }) => (React.createElement(Droppable, { key: propertyId, droppableId: propertyId, mode: "virtual", isDropDisabled: isDropDisabled, renderClone: (provided, snapshot, rubric) => {
                    return (React.createElement(KanbanCard, { baseUrl: baseUrl, card: cards[rubric.source.index], definitionId: definitionId, fields: fields, provided: provided, viewId: viewId }));
                } }, ({ innerRef }) => (React.createElement(KanbanList, { getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, getCustomColumnCountFilters: getCustomColumnCountFilters, baseUrl: baseUrl, cards: cards, contentHeight: contentHeight, definitionId: definitionId, fields: fields, isDropDisabled: isDropDisabled, hidden: hiddenColumns.has(propertyId), propertyId: propertyId, innerRef: innerRef, onToggleList: onToggleList, title: title, theme: theme, viewId: viewId }))))))))));
};
export default KanbanBoard;
