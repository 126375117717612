import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React from 'react';
import { Field, Fields, FormSection } from 'redux-form';
import { useProperties } from '../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { AntInput } from '../../../../../common/components/AntInput';
import { usePropertiesTree } from '../../../../../common/components/ComputedExpressionEditor/usePropertiesTree';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { ComputedExpressionsDocumentationLink } from '../../../../../common/components/documentation-link';
import GroupsField from '../../../../../groups';
import { AvailableSystemGroups } from '../../../../../groups/constants';
import NodeSettingsSection from './NodeSettingsSection';
import ProcessPropertyMappingsField from './ProcessPropertyMappingsField';
import ProcessPropertyMappingsUpdateMethodField from './ProcessPropertyMappingsUpdateMethodField';
const SetProcessValuesForm = ({ disabled, metaDefinitionId, parentViewGroups }) => {
    const { t } = useI18n();
    const { isPropertyList } = useProperties(metaDefinitionId);
    const propertiesTree = usePropertiesTree(metaDefinitionId);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement("div", { className: "cp-c-row cp-c-wrap" },
            React.createElement(MultiLanguageField, { className: "cp-i-100", placeholder: t('COMMON__TITLE'), name: "titles", component: AntInput, disabled: disabled })),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FieldLabel, { label: t('PROCESS_RUN_MAPPINGS'), labelRight: React.createElement(ComputedExpressionsDocumentationLink, null), className: "ps-0" }),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement("div", { className: "cp-c-row cp-c-wrap" },
                React.createElement(Field, { className: "cp-i-100", name: "propertyExpressions", component: ProcessPropertyMappingsField, definitionId: metaDefinitionId, disabled: disabled, additionLabelContent: (field) => isPropertyList(field) ? (React.createElement(Field, { className: "cp-i-100", name: "propertyExpressionsUpdateMethods", component: ProcessPropertyMappingsUpdateMethodField, field: field })) : null }))),
        React.createElement(Field, { name: "settings", component: NodeSettingsSection, propertiesTree: propertiesTree, disabled: disabled }),
        React.createElement(Divider, null, t('PERMISSIONS')),
        React.createElement(Fields, { names: [AvailableSystemGroups.viewGroups], component: GroupsField, disabled: disabled, parentViewGroups: parentViewGroups })));
};
export default SetProcessValuesForm;
