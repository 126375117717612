import { Input } from 'antd';
import { get } from 'lodash';
import React, { Component, createRef } from 'react';
import { IoMdClose } from 'react-icons/io';
import styled, { keyframes } from 'styled-components';
const highlightNewMappingAnimation = keyframes `
  from { background-color: rgba(var(--primaryColorRGB-6), 0.38); }
  to { background-color: var(--grayscaleColor-1); }
`;
const MappingFieldContainer = styled.div `
  animation-duration: ${(props) => props.newMappingAnimationDurationInMs}ms;
  animation-name: ${(props) => (props.isNew ? highlightNewMappingAnimation : 'none')};
`;
const RemoveButton = styled(IoMdClose) `
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  font-size: 1.5rem;
  color: var(--grayscaleColor-6);

  &:hover {
    color: ${(props) => (props.disabled ? null : 'var(--errorColor-5)')};
  }
`;
class MappingField extends Component {
    constructor() {
        super(...arguments);
        this.ref = createRef();
        this.scrollToElementIfNew = () => {
            if (this.props.isNew && get(this.ref, 'current.scrollIntoViewIfNeeded')) {
                setTimeout(() => {
                    if (get(this.ref, 'current.scrollIntoViewIfNeeded')) {
                        // @ts-expect-error
                        this.ref.current.scrollIntoViewIfNeeded();
                    }
                }, this.props.scrollToViewDelayInMs);
            }
        };
    }
    componentDidMount() {
        this.scrollToElementIfNew();
    }
    componentDidUpdate() {
        this.scrollToElementIfNew();
    }
    render() {
        const { value, fieldLabel, isNew, disabled, onChange, onRemove, placeholder, newMappingAnimationDurationInMs, ...otherProps } = this.props;
        return (React.createElement(MappingFieldContainer
        // @ts-expect-error
        , { 
            // @ts-expect-error
            ref: this.ref, isNew: isNew, newMappingAnimationDurationInMs: newMappingAnimationDurationInMs, ...otherProps },
            React.createElement("label", null, fieldLabel),
            React.createElement("div", { className: "cp-c-row" },
                React.createElement(Input, { className: "mr-2", value: value, onChange: (e) => {
                        onChange(e.target.value);
                    }, placeholder: placeholder, disabled: disabled }),
                React.createElement("div", null,
                    React.createElement(RemoveButton, { disabled: disabled, onClick: () => {
                            if (!disabled) {
                                onRemove();
                            }
                        } })))));
    }
}
export default MappingField;
