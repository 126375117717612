import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingIndicator from '../../../../common/components/loading-indicator';
import { PAYMENT_PATH, SETTINGS_PATH } from '../../../constants';
import { changeCustomerData, getCustomer } from '../actions';
import ContactInfoForm from './ContactInfoForm';
import PaymentCards from './PaymentCards';
import styles from './PaymentContainer.module.scss';
import SubscriptionPlan from './SubscriptionPlan';
const PaymentContainer = ({ setLinks, setCurrentBreadcrumbText, onError, onSuccess, contentHeight }) => {
    const { t } = useI18n();
    const [customer, setCustomer] = useState();
    const [loading, setLoading] = useState(true);
    const httpDispatch = useDispatch();
    const httpDispatchChangeCustomer = useDispatch();
    const fetchCustomer = useCallback(async () => {
        setLoading(true);
        try {
            const result = await httpDispatch(getCustomer());
            setCustomer(result || undefined);
        }
        catch (e) {
            const responseMessage = get(e, ['response', 'data', 'message'], '');
            if (!responseMessage.includes('not found')) {
                onError(e);
            }
        }
        finally {
            setLoading(false);
        }
    }, [httpDispatch, onError]);
    const onChangeCustomer = useCallback(async (data) => {
        setLoading(true);
        try {
            const result = await httpDispatchChangeCustomer(changeCustomerData({ ...customer, ...data }));
            // hak, when creating customer
            // app should wait until the search keys are registered in the Stripe
            setTimeout(() => {
                setCustomer(result);
                onSuccess(t('SAVE_SETTINGS_SUCCESS'));
                setLoading(false);
            }, customer ? 0 : 20000);
        }
        catch (e) {
            onError(e instanceof Error && e.message ? e : Error(t('SAVE_SETTINGS_FAIL')));
            setLoading(false);
        }
    }, [customer, httpDispatchChangeCustomer, onError, onSuccess, t]);
    useEffect(() => {
        void fetchCustomer();
    }, [fetchCustomer]);
    useEffect(() => {
        setLinks([
            { text: t('SETTINGS'), to: SETTINGS_PATH },
            { text: t('SUBSCRIPTIONS'), to: `${SETTINGS_PATH}${PAYMENT_PATH}` }
        ]);
        setCurrentBreadcrumbText(t('PAYMENT_DETAILS'));
    }, [setCurrentBreadcrumbText, setLinks, t]);
    const contactInfo = useMemo(() => ({
        name: customer?.name || '',
        vat: customer?.vat || '',
        contactName: customer?.contactName || '',
        phone: customer?.phone || '',
        email: customer?.email || '',
        city: customer?.city || '',
        country: customer?.country || '',
        addressLine1: customer?.addressLine1 || '',
        addressLine2: customer?.addressLine2,
        postalCode: customer?.postalCode || ''
    }), [customer]);
    return (React.createElement("div", { className: styles.container, style: { minHeight: contentHeight } },
        loading ? React.createElement(LoadingIndicator, null) : null,
        customer && React.createElement(SubscriptionPlan, { onError: onError, onSuccess: onSuccess }),
        (!loading || customer) && (React.createElement(ContactInfoForm, { contactInfo: contactInfo, onSubmit: onChangeCustomer })),
        customer && (React.createElement(PaymentCards, { defaultPaymentMethod: customer.defaultPaymentMethod, onChangeCustomer: onChangeCustomer, onError: onError, onSuccess: onSuccess }))));
};
export default PaymentContainer;
