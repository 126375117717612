import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { EMAIL_TEMPLATES_DEFINITION_ID } from '@hypercharge/portal-utils';
import React from 'react';
import { CustomEntitySelector } from '../../../common/components/entity-selectors';
import { fetchForm, fetchList } from '../../actions';
import { getForm } from '../../selectors';
const getOptionId = (c) => c.id;
const getOptionContent = (c) => c.title;
const getIsAvailable = () => false;
const getIsPending = () => false;
const searchTemplatesFn = (q) => fetchList(1, 10, q.textSearchQuery || undefined);
const FormsSelectorField = (otherProps) => {
    const { t } = useI18n();
    return (React.createElement(CustomEntitySelector, { ...otherProps, definitionId: EMAIL_TEMPLATES_DEFINITION_ID, placeholder: t('SEARCH_FORM'), 
        // @ts-expect-error
        getOptionId: getOptionId, 
        // @ts-expect-error
        getOptionContent: getOptionContent, getEntity: getForm, isEntityAvailable: getIsAvailable, isEntityPending: getIsPending, searchEntities: searchTemplatesFn, fetchEntity: fetchForm, simpleClear: true, withoutCreateNew: true }));
};
export default FormsSelectorField;
