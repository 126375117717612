import { CMS_API_PATH } from '../../../common/utils/constants';
import { IMPORT_DEFINITION_ID } from './constants';
export const validateUrl = (data) => ({
    type: 'ENTITY_IMPORT_VALIDATE_URL_FETCH',
    payload: data,
    meta: {
        http: {
            path: `${CMS_API_PATH}/${IMPORT_DEFINITION_ID}/validate-url`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const fetchPreviewData = (importEntityId, entityImportSettings) => ({
    type: 'ENTITY_IMPORT_PREVIEW_DATA_FETCH',
    payload: entityImportSettings,
    meta: {
        http: {
            path: `${CMS_API_PATH}/${IMPORT_DEFINITION_ID}/${importEntityId}/preview`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const importData = (importEntityId, entityImportSettings) => ({
    type: 'ENTITY_IMPORT_IMPORT_FILE',
    payload: entityImportSettings,
    meta: {
        http: {
            path: `${CMS_API_PATH}/import/${importEntityId}/import-data`,
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
export const saveImportItem = (importEntityId, importItem) => ({
    type: 'ENTITY_IMPORT_SAVE_IMPORT_ITEM',
    payload: importItem,
    meta: {
        http: {
            path: `${CMS_API_PATH}/import/${importEntityId}`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
export const deleteImportItem = (importEntityIds) => ({
    type: 'ENTITY_IMPORT_DELETE_IMPORT_ITEM',
    payload: importEntityIds,
    meta: {
        http: {
            path: `${CMS_API_PATH}/import/`,
            method: 'DELETE',
            allowPromiseReject: true
        }
    }
});
export const changeStatusImportItem = (entityIds, status) => ({
    type: 'ENTITY_IMPORT_DELETE_IMPORT_ITEM',
    payload: { entityIds, status },
    meta: {
        http: {
            path: `${CMS_API_PATH}/import/status/`,
            method: 'PUT',
            allowPromiseReject: true
        }
    }
});
