import React, { useCallback } from 'react';
import { MdAdd, MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from '../../../../../common/components/file-input';
import FormikField from '../../../../../common/components/formik/FormikField';
import { fetchUploadUrl, uploadFile } from '../../../../../common/storage';
import { getTenant } from '../../../../../tenant';
import styles from './TenantCompanyLogoField.module.scss';
export const TenantSettingUploadFileField = ({ label, subTitle, name, value, buttonAddTitle, removeButtonTitle, disabled }) => {
    const dispatchFetchUploadUrl = useDispatch();
    const dispatchFetchUploadFile = useDispatch();
    const tenant = useSelector(getTenant);
    const handleUploadFile = useCallback(async (file) => {
        const data = await dispatchFetchUploadUrl(fetchUploadUrl(file, tenant?.id, true));
        if (data.uploadUrl) {
            await dispatchFetchUploadFile(uploadFile(file, data, data.uploadUrl));
        }
        return data;
    }, [dispatchFetchUploadFile, dispatchFetchUploadUrl, tenant]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "p-2" },
            React.createElement(FormikField, { name: name, component: FileInput, disabled: disabled, label: label, subTitle: subTitle, buttonTitle: React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement(MdAdd, { className: styles.buttonIcon }),
                    React.createElement("span", { className: "ms-1" }, buttonAddTitle)), canRemove: true, removeButtonTitle: React.createElement("div", { className: "d-flex align-items-center" },
                    React.createElement(MdDeleteOutline, { className: styles.buttonIcon }),
                    React.createElement("span", { className: "ms-1" }, removeButtonTitle)), value: value ? { downloadUrl: value } : null, uploadFn: handleUploadFile, clearValue: (newFile) => (newFile ? newFile.downloadUrl : null) }))));
};
