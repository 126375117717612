import { i18nChangeLanguage, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error } from '@hypercharge/hyper-react-base/lib/notifications';
import { Modal } from 'antd';
import { TENANT_ID } from 'config';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onlyUpdateForKeys } from 'recompose';
import { compose } from 'redux';
import { getRefreshTokenExpiry, signOut } from '../../../../auth';
import { requestSwitchAccount } from '../../../../auth/actions';
import { getAuthRefreshToken } from '../../../../auth/selectors';
import { LoadingScreenBlocker } from '../../../../common/components/LoadingScreenBlocker';
import { useMyContact } from '../../../../crm/components/use-my-contact';
import UserSkeleton from './UserSkeleton';
import UserDesktop from './desktop/UserDesktop';
import UserMobile from './mobile/UserMobile';
import { reloadPage } from './utils';
const User = ({ menu, isMobile, className }) => {
    const { t } = useI18n();
    const { contact } = useMyContact();
    const [isSwitchUserLoading, setIsSwitchUserLoading] = useState(false);
    const [isSwitchUserAsking, setIsSwitchUserAsking] = useState(false);
    const [userIdForSwitching, setUserIdForSwitching] = useState();
    const refreshTokenExpiry = useSelector(getRefreshTokenExpiry);
    const refreshToken = useSelector((s) => getAuthRefreshToken(s));
    const signOutDispatch = useDispatch();
    const switchUserDispatch = useDispatch();
    const switchUserErrorDispatch = useDispatch();
    const memoizedSignOut = () => signOutDispatch(signOut(TENANT_ID, (contact && contact.entityId) || '', isNaN(refreshTokenExpiry) ? new Date(refreshTokenExpiry).getTime() : refreshTokenExpiry || 0));
    const switchAccount = async (entityId) => {
        if (!entityId || !refreshToken) {
            switchUserErrorDispatch(error({
                title: t('ERROR'),
                message: t('MAIN_MENU__SWITCH_USER_ERROR')
            }));
            return;
        }
        try {
            setIsSwitchUserLoading(true);
            await switchUserDispatch(requestSwitchAccount(entityId, refreshToken));
            // ? This timeout is set so that the redux persister has time to save the data in the storage
            // ? before the page is reloaded. Otherwise, the storage will be loaded by the previous user
            setTimeout(reloadPage, 10);
        }
        catch (e) {
            switchUserErrorDispatch(error({
                title: t('ERROR'),
                message: t('MAIN_MENU__SWITCH_USER_ERROR')
            }));
            setIsSwitchUserLoading(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        contact &&
            ((isMobile && (React.createElement(UserMobile, { className: className || '', signOut: memoizedSignOut, menu: menu }))) || (React.createElement(UserDesktop, { className: className || '', changeLanguage: (lang) => {
                    signOutDispatch(i18nChangeLanguage(lang));
                }, signOut: memoizedSignOut, setIsSwitchUserAsking: setIsSwitchUserAsking, setUserIdForSwitching: setUserIdForSwitching }))),
        !contact && React.createElement(UserSkeleton, null),
        React.createElement(Modal, { centered: true, open: isSwitchUserAsking, onOk: () => void switchAccount(userIdForSwitching), onCancel: () => setIsSwitchUserAsking(false), destroyOnClose: true, bodyStyle: {
                padding: 0
            } },
            React.createElement("div", { className: "p-4" }, t('MAIN_MENU__SWITCH_USER_CONFIRM'))),
        React.createElement(LoadingScreenBlocker, { visible: isSwitchUserLoading, message: t('MAIN_MENU__SWITCH_USER_LOADING') })));
};
export default compose(withRouter, onlyUpdateForKeys(['isMobile', 'menu']))(User);
