import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Input, Modal } from 'antd';
import { DEFAULT_LANGUAGE } from 'config';
import memoize from 'fast-memoize';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { MultiLanguageInput } from '../../../../../common/components/MultiLanguageField';
import { MultiLanguageOptionalValidationSchema } from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import FormikField from '../../../../../common/components/formik/FormikField';
import { Widget } from '../Widget';
import ActivityWidget from './ActivityWidget';
import styles from './EditActivityForm.module.scss';
const validationSchema = Yup.object({
    type: Yup.string().default('activity').required('Required'),
    id: Yup.string().required('Required'),
    title: MultiLanguageOptionalValidationSchema,
    layout: Yup.object({
        i: Yup.string().required('Required'),
        x: Yup.number().default(0).required('Required'),
        y: Yup.number().default(0).required('Required'),
        w: Yup.number().default(2).required('Required'),
        h: Yup.number().default(6).required('Required')
    })
});
export function getDefaultActivitySettings() {
    const id = generateId();
    return {
        type: 'activity',
        id: id,
        title: { [DEFAULT_LANGUAGE]: '' },
        layout: {
            i: id,
            x: 0,
            y: 0,
            w: 3,
            h: 6
        }
    };
}
const memoizedCastSettings = memoize((settings) => {
    return validationSchema.cast(settings, {
        stripUnknown: true,
        context: settings
    });
});
const EditActivityForm = ({ title, value, open, onCancel, onOk }) => {
    const { t } = useI18n();
    const _onCancel = useCallback(() => {
        onCancel?.();
    }, [onCancel]);
    const onSave = useCallback((data, formikHelpers) => {
        const stripedData = memoizedCastSettings(data);
        onOk(stripedData);
    }, [onOk]);
    const validate = useCallback((value) => {
        try {
            void validateYupSchema(value, validationSchema, true, value);
        }
        catch (err) {
            const errors = yupToFormErrors(err); // for rendering validation errors
            return errors;
        }
        return {};
    }, []);
    return (React.createElement(Formik, { initialValues: value || getDefaultActivitySettings(), validateOnBlur: true, validateOnChange: false, onSubmit: onSave, validate: validate }, ({ values, handleSubmit }) => {
        return (React.createElement(Modal, { destroyOnClose: true, width: "80%", centered: true, open: open, title: title, onCancel: _onCancel, onOk: () => handleSubmit() },
            React.createElement("div", { className: `${styles.wrapper} row w-100` },
                React.createElement("div", { className: "col-8" },
                    React.createElement(Widget, { className: "widget-wrapper", showControlButtons: false },
                        React.createElement(ActivityWidget, { className: "h-100", settings: values }))),
                React.createElement("div", { className: "col-4" },
                    React.createElement(FormikField, { name: "title", label: t('HYPER_BI_TITLES'), component: MultiLanguageInput, fieldComponent: Input })))));
    }));
};
export default EditActivityForm;
