import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push as routerPush } from '@hypercharge/hyper-react-base/lib/router';
import { Wrapper } from '@hypercharge/hyper-react-base/lib/styling';
import { Divider } from 'antd';
import cn from 'classnames';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { SubmissionError } from 'redux-form';
import { ClipboardCopyButton, ClipboardPasteButton } from '../../../../../common/components/ClipboardButtons';
import { ColorCustomization } from '../../../../../common/components/Color/ColorCustomization';
import { MultiLanguageInput } from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import FormikField from '../../../../../common/components/formik/FormikField';
import { FieldsContainer, FieldsContainerTitle } from '../../../../../common/utils/formUtils';
import GroupsField from '../../../../../groups';
import { ADMINS_PERMISSION_GROUP, AvailableSystemGroups, OWNER_GROUP_PLACEHOLDER } from '../../../../../groups/constants';
import { CMS_PATH } from '../../../../common/utils/constants';
import { isItemMeta } from '../../../types';
import EntityActions from '../entity-actions/EntityActions';
import styles from './ItemMetaForm.module.scss';
import { convertToItemMeta, onItemMetaFormSubmit } from './utils';
const PERMISSION_GROUPS = [
    AvailableSystemGroups.viewGroups,
    AvailableSystemGroups.createGroups,
    AvailableSystemGroups.deleteGroups
];
const ALLOWED_ROW_PERMISSIONS = ['view'];
const ItemMetaForm = ({ create, entityMeta, entityMetaId, extraActionButtons, isSystem, setCurrentBreadcrumbText, setLinks, formValue }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { viewGroups, createGroups, deleteGroups, permissions, ...restFormValue } = formValue;
    const formikValue = {
        ...restFormValue,
        itemMetaGroups: {
            viewGroups: viewGroups || [],
            createGroups: createGroups || [],
            deleteGroups: deleteGroups || [],
            permissions: permissions
        }
    };
    useEffect(() => {
        if (!entityMeta) {
            setLinks([{ text: t('CMS_NAV_LINK'), to: CMS_PATH }]);
            setCurrentBreadcrumbText(t('CREATE_ENTITY_META__TITLE'));
        }
    }, [entityMeta, t, setLinks, setCurrentBreadcrumbText]);
    const clipboardProperties = useMemo(() => {
        return ['titles', 'custom', `itemMetaGroups`, ...PERMISSION_GROUPS];
    }, []);
    const isValidClipboard = useCallback((clipText) => {
        try {
            const clipValue = JSON.parse(clipText);
            if (isItemMeta(convertToItemMeta(clipValue))) {
                return true;
            }
        }
        catch (error) {
            return false;
        }
        return false;
    }, []);
    const handleFormSubmit = async (values, actions) => {
        try {
            const itemMeta = convertToItemMeta(values);
            const errors = await onItemMetaFormSubmit(itemMeta, dispatch, {
                itemMeta: entityMeta,
                t
            });
            if (errors) {
                Object.entries(errors).forEach(([key, value]) => {
                    // @ts-ignore
                    actions.setFieldError(key.toString(), value);
                });
                throw new SubmissionError(errors);
            }
            actions.resetForm({ values }); // Reset the form after successful submission
        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    const isPermissionToggleDisabled = (groupId, permission) => {
        return (groupId === ADMINS_PERMISSION_GROUP ||
            (groupId === OWNER_GROUP_PLACEHOLDER &&
                // TODO: Delete must be enabled when fixed https://trello.com/c/rEuiWDPm/1545-cms-owner-permission-cant-delete-record
                [AvailableSystemGroups.createGroups, AvailableSystemGroups.deleteGroups].some((p) => p === permission)));
    };
    return (React.createElement(Wrapper, { className: "h-100" },
        React.createElement(Formik, { initialValues: formikValue, validateOnBlur: true, validateOnChange: false, onSubmit: handleFormSubmit, enableReinitialize: true }, (formikProps) => {
            const { isSubmitting, handleSubmit, dirty, values, resetForm, setValues } = formikProps;
            return (React.createElement("div", { className: "h-100" },
                React.createElement("div", { className: cn(styles.formButtons, 'position-absolute top-0 end-0 mt-1 me-3', {
                        isNew: create
                    }) },
                    React.createElement(React.Fragment, null,
                        React.createElement(ClipboardCopyButton, { value: values, inversed: true, className: "me-3", clipboardProperties: clipboardProperties }),
                        React.createElement(ClipboardPasteButton, { className: "me-3", inversed: true, isValid: isValidClipboard, onClick: (clipText) => {
                                try {
                                    const clipValue = JSON.parse(clipText);
                                    setValues(clipValue);
                                }
                                catch (error) {
                                    console.error('Invalid JSON: ', error);
                                }
                            } }),
                        create ? (React.createElement(Button, { inversed: true, onClick: () => {
                                dispatch(routerPush(CMS_PATH));
                            }, disabled: isSubmitting, className: "me-3" }, t('FORM_CANCEL'))) : (dirty && (React.createElement(Button, { inversed: true, onClick: () => {
                                resetForm();
                            }, disabled: isSubmitting, className: "me-3" }, t('FORM__RESET')))),
                        React.createElement(EntityActions, { id: entityMetaId, save: handleSubmit, saving: isSubmitting, canSave: dirty, canDelete: !create && !isSystem }),
                        extraActionButtons)),
                React.createElement("div", { className: "h-100 cp-c-row cp-c-wrap cp-c-padding-2" },
                    React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50" },
                        React.createElement(FieldsContainerTitle, null, t('ENTITY_NAME')),
                        React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
                            React.createElement(FormikField, { name: "titles", label: t('HYPER_BI_TITLES'), component: MultiLanguageInput, fieldComponent: BasicInput })),
                        React.createElement(ColorCustomization, { submitting: isSubmitting, formik: true })),
                    React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-50 " },
                        React.createElement(Divider, null, t('PERMISSIONS')),
                        React.createElement(FormikField, { fast: true, name: "itemMetaGroups", component: GroupsField, names: PERMISSION_GROUPS, disabled: isSubmitting, withValidation: true, isPermissionToggleDisabled: isPermissionToggleDisabled, allowedRowPermissions: ALLOWED_ROW_PERMISSIONS, itemMetaDefinitionId: entityMeta?.definitionId }))),
                React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') })));
        })));
};
export default ItemMetaForm;
