import { Breadcrumbs } from '@hypercharge/hyper-react-base/lib/common/breadcrumbs';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { Body, Cell, DeleteConfirmationButton, Header, HeaderCell, InformationRow, Row, Table } from '@hypercharge/hyper-react-base/lib/common/table';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import styled from 'styled-components';
import { StyledWrapper } from '../../../../common/utils/stylingUtils';
import { ADMIN_PATH } from '../../../utils/constants';
const ActionsContainer = styled.div `
  margin-bottom: 1rem;
`;
const OptionGroups = ({ optionGroups, isPending, failed, deleteGroup, isMobile }) => {
    const { t } = useI18n();
    return (React.createElement(React.Fragment, null,
        React.createElement(Breadcrumbs, { links: [{ text: t('ADMIN_NAV_LINK'), to: `${ADMIN_PATH}` }], currentBreadcrumbText: t('SETTINGS_OPTIONS_NAV_LINK') }),
        React.createElement(StyledWrapper, null,
            React.createElement(ActionsContainer, { className: "cp-c-row cp-c-align-end-center" },
                React.createElement(Button, { to: `${ADMIN_PATH}/option-groups/add` }, t('SETTINGS__OPTIONS_ADD_OPTION_GROUP'))),
            React.createElement(Table, null,
                React.createElement(Header, null,
                    React.createElement(HeaderCell, { style: { width: isMobile ? '40%' : '30%' } }, t('SETTINGS__OPTIONS_GROUP_ID')),
                    React.createElement(HeaderCell, { style: { width: isMobile ? '40%' : '20%' } }, t('SETTINGS__OPTIONS_SIZE')),
                    React.createElement(HeaderCell, { className: "actions" })),
                React.createElement(Body, { loading: isPending, cellLength: 3 }, optionGroups.length === 0 && !isPending ? (React.createElement(InformationRow, null, t(failed ? 'COMMON__DATA_FETCH_FAILED' : 'SETTINGS__OPTIONS_NO_OPTION_GROUPS'))) : (optionGroups.map(({ groupId, size }) => (React.createElement(Row, { key: groupId, to: `${ADMIN_PATH}/option-groups/${groupId}/options` },
                    React.createElement(Cell, null, groupId),
                    React.createElement(Cell, null, size),
                    React.createElement(Cell, { className: "actions" },
                        React.createElement("div", null,
                            React.createElement(DeleteConfirmationButton, { onClick: () => deleteGroup(groupId) }))))))))))));
};
export default OptionGroups;
