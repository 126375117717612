import React, { useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';
const FullScreenPreview = ({ className, children, closePreview }) => {
    useEffect(() => {
        const appBody = document.querySelector('#hyper-body');
        if (appBody) {
            // @ts-expect-error
            appBody.style.overflow = 'hidden';
        }
        return () => {
            const appBody = document.querySelector('#hyper-body');
            // @ts-expect-error
            if (appBody && appBody.style.overflow === 'hidden') {
                // @ts-expect-error
                appBody.style.overflow = null;
            }
        };
    }, []);
    return (React.createElement(ScContainer, { className: `cp-c-row cp-c-align-spacearound-center ${className || ''}` },
        React.createElement(ScCloseButtonContainer, null,
            React.createElement(ScCloseButton, { onClick: closePreview })),
        children));
};
export default FullScreenPreview;
//
// Utils
//
const ScContainer = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--grayscaleColor-2);
  z-index: 2000;
`;
const ScCloseButtonContainer = styled.div `
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  background: var(--grayscaleColor-2);
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 1px 2px 0 rgba(var(--grayscaleColorRGB-9), 0.302),
    0 1px 3px 1px rgba(var(--grayscaleColorRGB-9), 0.149);
  transition: box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-9), 0.302),
      0 4px 8px 3px rgba(var(--grayscaleColorRGB-9), 0.149);
    color: var(--infoColor-5);
  }
`;
const ScCloseButton = styled(IoMdClose) `
  cursor: pointer;
  font-size: 3rem;
`;
