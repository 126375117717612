import { Switch } from 'antd';
import React, { useId } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Tooltip } from '../Tooltip';
import { TooltipTruncate } from '../TooltipTruncate';
import styles from './Toggle.module.scss';
const Toggle = ({ label, input, tooltip, onChange, checked = false, disabled, className = '', ...switchProps }) => {
    const id = useId();
    return (React.createElement("div", { className: `${styles.wrapper} ${disabled ? 'disabled' : ''} ${className || ''}` },
        React.createElement(Tooltip, { placement: "top", title: tooltip, showTooltip: true },
            React.createElement("div", { className: "d-inline-flex flex-row align-items-center flex-row-reverse justify-content-end" },
                label && (React.createElement("label", { htmlFor: id, className: "label ps-3 fw-500" },
                    React.createElement(TooltipTruncate, null, label))),
                React.createElement(Switch, { ...switchProps, id: id, onChange: input ? input.onChange : onChange, checked: input ? input.value || false : checked, checkedChildren: React.createElement(IoMdCheckmark, null), unCheckedChildren: React.createElement(IoClose, null), disabled: disabled })))));
};
export default Toggle;
