import React from 'react';
import { defaultColors } from '../ColorPicker/constants';
import { Color, ColorContainer, ResetColor, StyledCheck, StyledClose } from './styles';
const ColorField = ({ input, disabled, resetColor }) => (React.createElement("div", { className: "cp-c-row cp-c-wrap" },
    defaultColors.map((color) => (React.createElement(ColorContainer, { key: color, disabled: disabled },
        React.createElement(Color, { "data-test-id": "color-customization-icon-bg-color", color: color, onClick: () => input.onChange(color) }),
        input.value === color && React.createElement(StyledCheck, null)))),
    resetColor && input.value && (React.createElement(ColorContainer, { disabled: disabled },
        React.createElement(ResetColor, { "data-test-id": "color-customization-reset-icon-bg-color", onClick: () => input.onChange('') }),
        React.createElement(StyledClose, { size: "1.5rem" })))));
export default ColorField;
