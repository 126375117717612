import { keyBy } from 'lodash';
export const metrics = [
    {
        labels: {
            en: 'Average',
            nl: 'Gemiddelde',
            de: 'Durchschnittlich',
            fr: 'Moyenne',
            it: 'Media',
            pl: 'Średnia'
        },
        value: 'avg',
        propertyTypes: ['number']
    },
    {
        labels: {
            en: 'Sum',
            nl: 'Som',
            de: 'Summe',
            fr: 'Somme',
            it: 'Somma',
            pl: 'Suma'
        },
        value: 'sum',
        propertyTypes: ['number']
    },
    {
        labels: {
            en: 'Min',
            nl: 'Min',
            de: 'Min',
            fr: 'Min',
            it: 'Min',
            pl: 'Min'
        },
        value: 'min',
        propertyTypes: ['number']
    },
    {
        labels: {
            en: 'Max',
            nl: 'Max',
            de: 'Max',
            fr: 'Max',
            it: 'Massimo',
            pl: 'Makx'
        },
        value: 'max',
        propertyTypes: ['number']
    },
    {
        labels: {
            en: 'Count unique values',
            nl: 'Tel unieke waarden',
            de: 'Zählen Sie eindeutige Werte',
            fr: 'Compter des valeurs uniques',
            it: 'Contare valori unici',
            pl: 'Policz unikalne wartości'
        },
        value: 'cardinality'
    },
    {
        labels: {
            en: 'Count values',
            nl: 'Tel waarden',
            de: 'Werte zählen',
            fr: 'Compter les valeurs',
            it: 'Contare i valori',
            pl: 'Policz wartości'
        },
        value: 'value_count'
    }
];
export const byMetric = keyBy(metrics, 'value');
