import React from 'react';
import styled from 'styled-components';
import { getImageSrc } from './utils';
const Container = styled.div `
  position: relative;
`;
const Image = styled.div `
  ${({ imgUrl, theme }) => `background-image: url(${imgUrl}); background-color: ${theme.skeletonColor}`};

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ResizableImage = ({ className, image, config }) => (React.createElement(Container, { className: className },
    React.createElement(Image, { imgUrl: getImageSrc(image, config) })));
export default ResizableImage;
