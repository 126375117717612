import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { Drawer } from '../../../../../common/components/Drawer';
import { EditIcon } from '../../../../../common/components/EditIcon';
import { FeaturedDefinitionIdFolderForm } from '../FeaturedDefinitionIdFolderForm';
import styles from './EditFeaturedDefinitionIdFolder.module.scss';
const EditFeaturedDefinitionIdFolder = ({ featuredDefinitionId, onSave }) => {
    const { t } = useI18n();
    const [openEditFolder, setOpenEditFolder] = useState(false);
    const onOpenEditFolderDrawerClose = useCallback(() => {
        setOpenEditFolder(false);
    }, []);
    const onOpenEditFolderDrawerOpen = useCallback(() => {
        setOpenEditFolder(true);
    }, []);
    const _onSave = (values) => {
        onSave(values.id, values.titles);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "py-0 px-1", type: "text", icon: React.createElement(EditIcon, null), onClick: onOpenEditFolderDrawerOpen }),
        React.createElement(Drawer, { rootClassName: styles.drawer, title: t('EDIT_FOLDER'), onClose: onOpenEditFolderDrawerClose, open: openEditFolder, destroyOnClose: true },
            React.createElement(FeaturedDefinitionIdFolderForm, { submitText: t('FORM__SAVE'), initValue: featuredDefinitionId, onSave: _onSave, onClose: onOpenEditFolderDrawerClose }))));
};
export default EditFeaturedDefinitionIdFolder;
