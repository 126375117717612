import { invalidateRetrieveAccountsQuery } from '../../auth/hooks';
import { sleep } from '../../utils/time';
import { invalidateColumnsCountQuery } from '../data/components/items/Kanban/KanbanListHeader/KanbanListHeader';
import { invalidateAggregationQuery } from './useAggregation';
import { invalidateDisplayDataQuery } from './useDisplayData';
import { invalidateEntityItemsQuery } from './useEntityItems';
import { invalidateHistoryDataQuery } from './useHistoryData';
import { invalidateSearchItemsQuery } from './useSearchItems';
export const invalidateItemsQuery = async ({ definitionId, ids, changedPropertyIds, waitNewData }) => {
    await invalidateEntityItemsQuery({
        definitionId,
        entityIds: ids,
        changedPropertyIds
    });
    await invalidateDisplayDataQuery({ definitionId, entityIds: ids });
    await invalidateHistoryDataQuery({ definitionId, entityIds: ids });
    if (waitNewData) {
        await sleep(1000); // We wait few seconds to allow ElasticSearch to reindex
    }
    await invalidateSearchItemsQuery({ definitionId });
    await invalidateAggregationQuery({ definitionId });
    await invalidateColumnsCountQuery({ definitionId });
    await invalidateRetrieveAccountsQuery({ definitionId });
};
