import { DEFAULT_SORT_BY } from 'config';
import { pickBy, keys, isEmpty, map, mapKeys, uniq, sortBy, find, toString } from 'lodash';
import { stringify } from 'query-string';
const TERM_COMPRESSION_SEP = '~';
const TERM_NAME_SEP = '~';
const TERM_FILTERS_PREFIX = 'ft_';
const RANGE_FILTERS_PREFIX = 'fr_';
const RANGE_MAX_FILTERS_PREFIX = 'max_';
const RANGE_MIN_FILTERS_PREFIX = 'min_';
export const SORT_BY_FILTERS_PREFIX = 's_';
const TEXT_SEARCH_QUERY = 'textSearchQuery';
const PARSE_INT_RADIX = 10;
const getParameters = (prefix, locationSearch) => {
    // @ts-ignore
    const rawKeyValues = pickBy(locationSearch, (value, key) => key.startsWith(prefix));
    return mapKeys(rawKeyValues, (value, key) => key.substring(prefix.length));
};
const mapParamsToTerms = (params) => map(params, (value, key) => {
    const split = key.split(TERM_NAME_SEP);
    const propertyPath = split[2];
    const definitionId = split[0];
    const propertyId = split[1];
    return {
        definitionId,
        propertyId,
        propertyPath,
        values: [...value.split(TERM_COMPRESSION_SEP)]
    };
});
const mapParamsToRanges = (params) => {
    const maxParams = mapKeys(pickBy(params, (value, key) => key.startsWith(RANGE_MAX_FILTERS_PREFIX)), (value, key) => key.substring(RANGE_MAX_FILTERS_PREFIX.length));
    const minParams = mapKeys(pickBy(params, (value, key) => key.startsWith(RANGE_MIN_FILTERS_PREFIX)), (value, key) => key.substring(RANGE_MIN_FILTERS_PREFIX.length));
    const properties = uniq([...keys(maxParams), ...keys(minParams)]);
    return properties.map((key) => {
        const split = key.split(TERM_NAME_SEP);
        return {
            definitionId: split[0],
            propertyId: split[1],
            propertyPath: split[2],
            max: (maxParams[key] &&
                !isNaN(Number(maxParams[key])) &&
                parseInt(maxParams[key], PARSE_INT_RADIX)) ||
                Number(maxParams[key]),
            min: (minParams[key] &&
                !isNaN(Number(minParams[key])) &&
                parseInt(minParams[key], PARSE_INT_RADIX)) ||
                Number(minParams[key])
        };
    });
};
const mapParamsToSortBy = (params, defaultSortBy) => 
// @ts-ignore
isEmpty(params)
    ? defaultSortBy || DEFAULT_SORT_BY
    : map(params, (value, key) => ({
        propertyPath: key,
        order: value
    }));
export const getBaseQuery = (locationSearch, defaultSortBy) => ({
    terms: mapParamsToTerms(getParameters(TERM_FILTERS_PREFIX, locationSearch)),
    ranges: mapParamsToRanges(getParameters(RANGE_FILTERS_PREFIX, locationSearch)),
    sortBy: mapParamsToSortBy(getParameters(SORT_BY_FILTERS_PREFIX, locationSearch), locationSearch[TEXT_SEARCH_QUERY] ? [] : defaultSortBy || DEFAULT_SORT_BY),
    textSearchQuery: toString(locationSearch[TEXT_SEARCH_QUERY] || '')
});
export const getBaseQueryFromSearchQuery = ({ terms, ranges, sortBy, textSearchQuery }) => ({
    terms: terms || [],
    ranges: ranges || [],
    sortBy: sortBy || [],
    textSearchQuery: textSearchQuery
});
export const updateQueryTerm = (query, propertyPath, definitionId, propertyId, newValues) => {
    if (newValues == null || newValues.length === 0) {
        return {
            ...query,
            terms: query.terms.filter((t) => !(t.propertyPath === propertyPath &&
                t.definitionId === definitionId &&
                t.propertyId === propertyId)) // delete
        };
    }
    const term = find(query.terms, {
        propertyPath,
        definitionId,
        propertyId
    });
    const newTerm = { propertyPath, definitionId, propertyId, values: newValues };
    if (term) {
        return {
            ...query,
            terms: query.terms.map((t) => t.propertyPath === propertyPath &&
                t.definitionId === definitionId &&
                t.propertyId === propertyId
                ? newTerm
                : t) // update
        };
    }
    return {
        ...query,
        terms: [...query.terms, newTerm] // add
    };
};
export const updateQueryRange = (query, propertyPath, definitionId, propertyId, min, max) => {
    if (min == null && max == null) {
        return {
            ...query,
            ranges: query.ranges.filter((t) => !(t.propertyPath === propertyPath &&
                t.definitionId === definitionId &&
                t.propertyId === propertyId)) // delete
        };
    }
    const range = find(query.ranges, { propertyPath, definitionId, propertyId });
    const newRange = { propertyPath, definitionId, propertyId, min, max };
    if (range) {
        return {
            ...query,
            ranges: query.ranges.map((r) => r.propertyPath === propertyPath &&
                r.definitionId === definitionId &&
                r.propertyId === propertyId
                ? newRange
                : r) // update
        };
    }
    return {
        ...query,
        ranges: [...query.ranges, newRange] // add
    };
};
export const getTermSuffix = (t) => {
    return `${t.definitionId}${TERM_NAME_SEP}${t.propertyId}${TERM_NAME_SEP}${t.propertyPath}`;
};
const mapTermsToParams = (prefix, terms) => {
    return sortBy(terms, ['propertyPath'])
        .filter((t) => t.values.length > 0)
        .reduce((result, t) => {
        const value = sortBy(t.values).join(TERM_COMPRESSION_SEP);
        if (value) {
            result[`${prefix}${getTermSuffix(t)}`] = value;
        }
        return result;
    }, {});
};
const mapRangesToParams = (ranges) => sortBy(ranges, ['propertyPath']).reduce((result, r) => {
    const suffix = getTermSuffix(r);
    if (r.min != null) {
        result[`${RANGE_FILTERS_PREFIX}${RANGE_MIN_FILTERS_PREFIX}${suffix}`] = r.min;
    }
    if (r.max != null) {
        result[`${RANGE_FILTERS_PREFIX}${RANGE_MAX_FILTERS_PREFIX}${suffix}`] = r.max;
    }
    return result;
}, {});
export const mapSortByToParams = (sortBy) => sortBy.reduce((result, s) => {
    result[`${SORT_BY_FILTERS_PREFIX}${s.propertyPath}`] = s.order;
    return result;
}, {});
export const mapTextSearchQueryToParams = (textSearchQuery) => textSearchQuery ? { textSearchQuery } : {};
export const getBaseQueryParams = ({ terms, ranges, sortBy, textSearchQuery }) => stringify({
    ...mapTermsToParams(TERM_FILTERS_PREFIX, terms),
    ...mapRangesToParams(ranges),
    ...mapSortByToParams(sortBy || DEFAULT_SORT_BY),
    ...mapTextSearchQueryToParams(textSearchQuery)
});
