import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Switch } from 'antd';
import React, { Fragment, useCallback } from 'react';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { FEATURED_ENTITIES_PATH } from '../../../cms';
import { LoadingBar } from '../../../common/components/LoadingBar';
import { useBrowserBreakpoints } from '../../../common/components/with-browser-breakpoints';
import { CONTACT_CMS_DEFINITION_ID, ContactDisplayName } from '../../../crm';
import { useContact } from '../../../crm/components/use-contact';
import { INDIVIDUAL_GROUP_PREFIX, PERMISSION_GROUP_TRANSLATION_KEYS } from '../../constants';
import ParentViewPermissionWarningIcon from '../ParentViewPermissionWarningIcon';
import ToggleDrawerButton from '../ToggleDrawerButton';
import { isConfiguredRowBasedPermission } from '../utils';
import styles from './GroupContact.module.scss';
const GroupContact = ({ contactId, disabled, groupListNames, isGroupChecked, isPermissionToggleDisabled, parentViewGroups, rowPermissions, allowedRowPermissions, setSelectedGroupId, toggleGroup, onRemove }) => {
    const { isMobile } = useBrowserBreakpoints();
    const { t } = useI18n();
    const { contact, contactStatus: { isFailed } } = useContact(contactId);
    const groupId = [INDIVIDUAL_GROUP_PREFIX, contactId].join('');
    const onClickRemove = useCallback(() => {
        onRemove?.(contactId);
    }, [contactId, onRemove]);
    return (React.createElement("div", { className: `${styles.fieldsContainer} cp-c-row flex-wrap py-sm-3 px-2` },
        React.createElement("div", { className: `${styles.text} ${styles.groupName} cp-i-100 cp-i-sm-flex cp-c-row cp-c-align-start-center py-sm-3 px-2` },
            React.createElement("div", { className: "d-flex" }, contact ? (React.createElement(React.Fragment, null,
                React.createElement(Link, { to: [FEATURED_ENTITIES_PATH, CONTACT_CMS_DEFINITION_ID, contactId].join('/') },
                    React.createElement(ContactDisplayName, { contact: contact })),
                React.createElement(ParentViewPermissionWarningIcon, { groupId: groupId, groupListNames: groupListNames, isGroupChecked: isGroupChecked, parentViewGroups: parentViewGroups }))) : isFailed ? null : (React.createElement(LoadingBar, { className: "d-inline-block align-baseline", placeholderHeight: 14, placeholderWidth: 100 }))),
            !disabled && isMobile && (React.createElement(IoMdClose, { className: `${styles.styledRemoveIcon}`, onClick: onClickRemove })),
            allowedRowPermissions?.length && (React.createElement(ToggleDrawerButton, { active: isConfiguredRowBasedPermission(groupId, allowedRowPermissions, rowPermissions), onClick: () => {
                    setSelectedGroupId(groupId);
                } }))),
        groupListNames.map((name) => (React.createElement(Fragment, { key: name },
            React.createElement("div", { className: `${styles.text} cp-i-80 d-sm-none py-sm-3 px-2` }, t(PERMISSION_GROUP_TRANSLATION_KEYS[name])),
            React.createElement("div", { className: "py-sm-3 px-2 justify-content-start justify-content-sm-center cp-i-20 cp-i-sm-flex cp-c-row cp-c-align-start-start cp-c-sm-align-start-center" },
                React.createElement(Switch, { checked: isGroupChecked(name, groupId), checkedChildren: React.createElement(IoMdCheckmark, { className: styles.smallText }), unCheckedChildren: React.createElement(IoClose, { className: styles.smallText }), onChange: () => toggleGroup(name, groupId), disabled: disabled || isPermissionToggleDisabled(groupId, name) }))))),
        React.createElement("div", { className: "cp-i-10 cp-c-row cp-c-align-end-center py-sm-3 px-2" }, !disabled && !isMobile && (React.createElement(IoMdClose, { className: `${styles.styledRemoveIcon} cp-i-none`, onClick: onClickRemove })))));
};
export default GroupContact;
