const analyzer = (action, emitter) => {
    if (action.type === 'SEARCHBAR__PRODUCTS_FETCH') {
        emitter.event({
            category: 'Search',
            action: 'Query',
            label: action.payload.textSearchQuery
        });
    }
};
export default analyzer;
