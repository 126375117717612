import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Tooltip } from 'antd';
import React from 'react';
import { MdEdit } from 'react-icons/md';
const EditIcon = () => {
    const { t } = useI18n();
    return (React.createElement(Tooltip, { placement: "top", title: t('COMMON__EDIT') },
        React.createElement(MdEdit, { size: 24 })));
};
export default EditIcon;
