import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { DEFAULT_LANGUAGE } from 'config';
import React, { useMemo, useState } from 'react';
import { IoLanguageOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { hasTenantPermission } from '../../../../auth';
import { getPropertyById } from '../../../../cms/common/utils/utils';
import { isTextDisplayData } from '../../../../cms/data/types';
import useDisplayData from '../../../../cms/hooks/useDisplayData';
import { MULTI_LANGUAGE_PROPERTY_PERMISSION } from '../../../utils/constants';
import { Option, Select } from '../../Select';
import styles from './SelectLanguage.module.scss';
export const useTranslation = ({ meta, entityId, definitionId, propertyId }) => {
    const { language } = useI18n();
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const multiLanguagePermission = useSelector((s) => hasTenantPermission(s, MULTI_LANGUAGE_PROPERTY_PERMISSION));
    const { data: displayDataList } = useDisplayData({ definitionId, entityId });
    const translationOptions = useMemo(() => {
        if (meta?.translations && propertyId) {
            const defaultDisplayData = getPropertyById(displayDataList, propertyId);
            return Object.entries(meta.translations).reduce((translationOptions, [language, key]) => {
                const displayData = getPropertyById(displayDataList, key);
                return {
                    ...translationOptions,
                    [language]: isTextDisplayData(displayData) ? displayData : undefined
                };
            }, {
                [DEFAULT_LANGUAGE]: isTextDisplayData(defaultDisplayData) ? defaultDisplayData : undefined
            });
        }
        return undefined;
    }, [displayDataList, meta?.translations, propertyId]);
    return useMemo(() => ({
        translationOptions,
        selectedLanguage,
        multiLanguagePermission,
        setSelectedLanguage
    }), [translationOptions, multiLanguagePermission, selectedLanguage]);
};
export const SelectLanguage = ({ value, onChange, translationOptions, position = 'top' }) => {
    const { t } = useI18n();
    return (React.createElement("div", { onClick: (event) => event.stopPropagation(), className: `${styles.container} position-absolute pos-${position}` },
        React.createElement(Select, { tabIndex: -1, popupMatchSelectWidth: false, className: styles.select, prefixIcon: IoLanguageOutline, optionLabelProp: "label", value: value, onChange: onChange }, Object.entries(translationOptions).map(([language, value]) => (React.createElement(Option, { key: language, label: t(language), value: language }, `${t(language)} | ${value?.value || ''}`))))));
};
