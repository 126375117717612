import { DeleteConfirmationButton } from '@hypercharge/hyper-react-base/lib/common/table';
import React from 'react';
const FeaturedDefinitionIdRowActions = ({ startAdditionalAction, onDelete }) => {
    return (React.createElement("div", { className: "d-flex align-items-center", onClick: (event) => {
            event.stopPropagation();
        } },
        startAdditionalAction,
        React.createElement(DeleteConfirmationButton, { onClick: onDelete })));
};
export default FeaturedDefinitionIdRowActions;
