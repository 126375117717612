import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FieldArray } from 'formik';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import AvailableEntityView from './AvailableEntityView';
const AvailableEntityViews = ({ entity, entityPath, disabled, entityIdx, entityGroupIdx, onViewCreate, onViewOpen }) => {
    const { t } = useI18n();
    return (React.createElement("div", { className: "col-4" },
        React.createElement("span", null,
            React.createElement("span", null,
                t('VIEWS__SIDE_MENU_SECTION_TITLE'),
                ": "),
            React.createElement(FieldArray, { name: `${entityPath}.views`, render: (arrayHelpersEntitiesViews) => (React.createElement("div", null,
                    entity.views.map((view, viewIdx) => (React.createElement(AvailableEntityView, { key: viewIdx, entity: entity, view: view, viewIdx: viewIdx, entityIdx: entityIdx, entityGroupIdx: entityGroupIdx, onViewOpen: onViewOpen, arrayHelpersEntitiesViews: arrayHelpersEntitiesViews, disabled: disabled }))),
                    React.createElement(Button, { className: "action-button mt-2", onClick: () => {
                            onViewCreate(entityGroupIdx, entityIdx, entity.entityId, arrayHelpersEntitiesViews);
                        }, disabled: !entity.entityId || disabled, inversed: true },
                        React.createElement(FaPlus, { className: "me-2" }),
                        t('VIEWS__CREATE_VIEW')))) }))));
};
export default AvailableEntityViews;
