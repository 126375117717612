import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push as routerPush } from '@hypercharge/hyper-react-base/lib/router';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { BROWSE_PATH } from '../../../../common/utils/constants';
import { ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP } from '../../../../groups';
import { AvailableSystemGroups } from '../../../../groups/constants';
import { ALL_PROCESSES_DEFINITION_ID, PROCESSES_PATH, WORKFLOWS_PATH } from '../../../common/utils/constants';
import { permissionGroupFieldNames } from '../../utils';
import ProcessMetaForm from './ProcessMetaForm';
import { formName, onProcessMetaFormSubmit } from './utils';
const DEFAULT_ACTIVITY_DIAGRAM = {
    Start: { id: 'Start', nextId: 'End', type: 'START' },
    End: { id: 'End', type: 'END' }
};
const initialValues = {
    titles: {},
    custom: {},
    idPrefix: '',
    color: '#4da0ff',
    activity: DEFAULT_ACTIVITY_DIAGRAM,
    viewGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
    executeGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
    deleteGroups: [ADMINS_PERMISSION_GROUP],
    permissions: undefined
};
const mapStateToProps = (s, { processMeta }) => {
    const isTriggerProcess = !!processMeta?.trigger;
    return {
        // @ts-expect-error
        initialValues: processMeta || initialValues,
        isNew: processMeta == null,
        isTriggerProcess,
        permissionGroupFieldNames: isTriggerProcess
            ? [AvailableSystemGroups.viewGroups]
            : permissionGroupFieldNames,
        form: processMeta ? `${formName}_${processMeta.id}` : formName
    };
};
const mapDispatchToProps = (dispatch) => ({
    cancel: () => 
    // @ts-expect-error
    dispatch(routerPush(`${WORKFLOWS_PATH}${PROCESSES_PATH}/${ALL_PROCESSES_DEFINITION_ID}${BROWSE_PATH}`))
});
export default compose(withProps(() => {
    const { t, language } = useI18n();
    return { language, t };
}), 
// @ts-expect-error
connect(mapStateToProps, mapDispatchToProps), reduxForm({
    // @ts-expect-error
    onSubmit: onProcessMetaFormSubmit,
    enableReinitialize: true
}))(ProcessMetaForm);
