import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingIndicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { cloneDeep, isEmpty, isEqual, isError, toNumber, toString } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { IoAlert } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import * as Yup from 'yup';
import { hasTenantPermission } from '../../../../auth';
import { invalidateEntityDisplayDataQueries } from '../../../../cms/common/components/withEntityDisplayData';
import { invalidateDisplayItemMetaListCache } from '../../../../cms/hooks/useDisplayItemMetaList';
import { updateItemMeta } from '../../../../cms/settings/actions';
import { ClipboardCopyButton, ClipboardPasteButton } from '../../../../common/components/ClipboardButtons';
import { MultiLanguageValidationSchema } from '../../../../common/components/MultiLanguageField';
import { MultiLanguageOptionalValidationSchema } from '../../../../common/components/MultiLanguageField/MultiLanguageField';
import FormikField from '../../../../common/components/formik/FormikField';
import TabCloseConfirmation from '../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { PropertyTypes, isMetaDefinition } from '../../../../common/types';
import { PLATFORM_ADMIN_PERMISSION } from '../../../../common/utils/constants';
import { ADMINS_PERMISSION_GROUP, ALL_USERS_PERMISSION_GROUP } from '../../../../groups';
import { isProcessMeta } from '../../../common/utils/utils';
import { saveMetaDefinition, saveProcessMeta } from '../../actions';
import { useDefinition } from '../common/withMetaDefinition';
import ActionButtonsSection from './ActionButtonsSection';
import styles from './MetaDefinitionForm.module.scss';
import MetaPropertyListItems from './MetaPropertyListItems';
import TestFieldsDrawerButton from './TestFieldsDrawerButton';
import { addIsNewToFormValue, generateReadableId, getErrorsFromFailedRequest, getFormValueFromMetaDefinition, getHighlightedPropertiesFromDefinition, getMetaDefinitionFromFormValue, mergeMetaDefinitions } from './utils';
const propertyValidationSchema = Yup.lazy((property) => {
    let basePropertySchema = Yup.object({
        propertyId: Yup.string().required('Required'),
        type: Yup.string().oneOf([property.type]),
        // @TODO check max length for labels MAX_DEFINITION_LABEL_LENGTH
        labels: MultiLanguageValidationSchema,
        system: Yup.boolean().default(false),
        readGroups: Yup.array(Yup.string()),
        writeGroups: Yup.array(Yup.string()),
        meta: Yup.object({
            hidden: Yup.boolean().default(false),
            validations: Yup.object({
                required: Yup.boolean().default(false)
            })
        })
    });
    switch (property.type) {
        case PropertyTypes.text:
            basePropertySchema = basePropertySchema.shape({
                meta: Yup.object({
                    hidden: Yup.boolean().default(false),
                    multiline: Yup.boolean().default(false),
                    validations: Yup.object({
                        required: Yup.boolean().default(false),
                        maxLength: Yup.number().nullable(),
                        minLength: Yup.number().nullable(),
                        regexPattern: Yup.string()
                    }),
                    translations: MultiLanguageOptionalValidationSchema
                }),
                computedValue: Yup.string().nullable()
            });
            break;
        case PropertyTypes.date:
            basePropertySchema = basePropertySchema.shape({
                meta: Yup.object({
                    hidden: Yup.boolean().default(false),
                    withTime: Yup.boolean().default(false),
                    validations: Yup.object({
                        required: Yup.boolean().default(false)
                    })
                }),
                computedValue: Yup.string().nullable()
            });
            break;
        case PropertyTypes.entity:
            /**
             * @TODO
             *
             *
             * check if definitionId exists
             * t('CMS_ENTITY_UNAVAILABLE', {
                  entity: definitionId
                })
             *
             **/
            basePropertySchema = basePropertySchema.shape({
                meta: Yup.object({
                    hidden: Yup.boolean().default(false),
                    definitionId: Yup.string().required('Required'),
                    list: Yup.boolean().default(false),
                    nonExpandable: Yup.boolean().default(false),
                    validations: Yup.object({
                        required: Yup.boolean().default(false)
                    }),
                    selector: Yup.object({
                        limit: Yup.number().nullable(),
                        filtering: Yup.object().nullable()
                    }).nullable()
                })
            });
            break;
        case PropertyTypes.number:
            basePropertySchema = basePropertySchema.shape({
                meta: Yup.object({
                    hidden: Yup.boolean().default(false),
                    precision: Yup.number().nullable().required('Required'),
                    useGrouping: Yup.boolean().default(false),
                    units: Yup.object().nullable(),
                    validations: Yup.object({
                        required: Yup.boolean().default(false),
                        max: Yup.number().nullable(),
                        min: Yup.number().nullable()
                    })
                }),
                computedValue: Yup.string().nullable()
            });
            break;
        case PropertyTypes.checkbox:
            basePropertySchema = basePropertySchema.shape({
                computedValue: Yup.string().nullable()
            });
            break;
        case PropertyTypes.file:
            basePropertySchema = basePropertySchema.shape({
                meta: Yup.object({
                    hidden: Yup.boolean().default(false),
                    accept: Yup.string().nullable(),
                    list: Yup.boolean().default(false),
                    carousel: Yup.boolean().default(false),
                    validations: Yup.object({
                        required: Yup.boolean().default(false)
                    })
                    /* .when('accept', {
                        is: 'image/*',
                        then: carousel: Yup.boolean().default(false),
                      }),*/
                }),
                computedValue: Yup.string().nullable()
            });
            break;
        case PropertyTypes.select:
            basePropertySchema = basePropertySchema.shape({
                meta: Yup.object({
                    hidden: Yup.boolean().default(false),
                    list: Yup.boolean().default(false),
                    source: Yup.string().required('Required'),
                    validations: Yup.object({
                        required: Yup.boolean().default(false)
                    })
                })
            });
            break;
        case PropertyTypes.json:
        case PropertyTypes.multitext:
        case PropertyTypes.email:
        case PropertyTypes.phoneNumber:
        case PropertyTypes.link:
        case PropertyTypes.richtext:
            break;
        default:
            basePropertySchema = basePropertySchema.shape({
                type: Yup.string().notOneOf([property.type], `Unknown property type '${property.type}'`)
            });
    }
    return basePropertySchema;
});
const sectionValidationSchema = Yup.object({
    id: Yup.string().required('Required'),
    titles: MultiLanguageValidationSchema,
    system: Yup.boolean().default(false),
    properties: Yup.array(propertyValidationSchema)
});
const validationSchema = Yup.object({
    definitionId: Yup.string().required('Required'),
    tabs: Yup.array(Yup.object({
        id: Yup.string().required('Required'),
        titles: MultiLanguageValidationSchema,
        system: Yup.boolean().default(false),
        sections: Yup.array(sectionValidationSchema)
    }))
});
const generateNewSection = (values) => ({
    id: generateReadableId(values.tabs, 'section', 'Data'),
    isNew: true,
    titles: {
        en: 'Data',
        de: 'Daten',
        fr: 'Les données',
        it: 'Dati',
        nl: 'Data'
    },
    system: false,
    properties: []
});
const MetaDefinitionForm = ({ metaDefinitionId, parentViewGroups, mainActionButtonComponent, extraActionButtons, className, itemMeta }) => {
    const { t } = useI18n();
    const isPlatformAdmin = useSelector((s) => hasTenantPermission(s, PLATFORM_ADMIN_PERMISSION));
    const [activeId, setActiveId] = useState();
    const [activeTabId, setActiveTabId] = useState();
    const [showHiddenFields, setShowHiddenFields] = useState(true);
    const { definition, definitionStatus } = useDefinition(metaDefinitionId);
    const dispatch = useDispatch();
    const saveDefinitionDispatch = useDispatch();
    const updateDefinitionItemMetaDispatch = useDispatch();
    const updateDefinitionProcessMetaDispatch = useDispatch();
    const initialValues = useMemo(() => {
        if (!definition) {
            return;
        }
        return getFormValueFromMetaDefinition(definition);
    }, [definition]);
    const toggleShowHiddenFields = useCallback(() => {
        setShowHiddenFields((oldValue) => !oldValue);
    }, []);
    const onActiveChange = useCallback((newActiveId) => {
        setActiveId(newActiveId);
    }, []);
    const onActiveTabChange = useCallback((newActiveId) => {
        setActiveTabId(newActiveId);
    }, []);
    const showError = useCallback(() => {
        dispatch(error({
            title: t('ERROR'),
            message: t('SAVE_SETTINGS_FAIL')
        }));
    }, [dispatch, t]);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        const newDefinition = getMetaDefinitionFromFormValue(values);
        try {
            const storedDefinition = await saveDefinitionDispatch(saveMetaDefinition(newDefinition));
            // Update the potentially cached version of the entityDisplayData fetched by withEntityDisplayData
            /**
             * for good, you need to use hook useQueryClient
             * but eslint swears that utils should be used inside functional components,
             * so the queryClient was moved to a separate module and acts as a singleton
             */
            invalidateEntityDisplayDataQueries(metaDefinitionId);
            const highlightedPropertiesList = getHighlightedPropertiesFromDefinition(itemMeta, newDefinition);
            if (!isEqual(highlightedPropertiesList, itemMeta.custom?.highlightedProperties)) {
                if (isProcessMeta(itemMeta)) {
                    await updateDefinitionProcessMetaDispatch(saveProcessMeta({
                        ...itemMeta,
                        custom: {
                            ...itemMeta?.custom,
                            highlightedProperties: highlightedPropertiesList
                        }
                    }));
                }
                else {
                    await updateDefinitionItemMetaDispatch(updateItemMeta({
                        ...itemMeta,
                        custom: {
                            ...itemMeta?.custom,
                            highlightedProperties: highlightedPropertiesList
                        }
                    }));
                }
                void invalidateDisplayItemMetaListCache(); // to update highlighted properties
            }
            dispatch(success({
                title: t('SUCCESS'),
                message: t('SAVE_SETTINGS_SUCCESS')
            }));
            const newFormValue = getFormValueFromMetaDefinition(storedDefinition);
            formikHelpers.resetForm({
                values: newFormValue
            });
        }
        catch (e) {
            if (isError(e) && 'isAxiosError' in e && e.isAxiosError) {
                const err = e;
                if (err.response?.data) {
                    const { response } = err;
                    const responseErrors = getErrorsFromFailedRequest(values, response.data);
                    if (!isEmpty(responseErrors)) {
                        formikHelpers.setErrors(responseErrors);
                    }
                }
            }
            showError();
        }
    }, [
        dispatch,
        itemMeta,
        metaDefinitionId,
        saveDefinitionDispatch,
        showError,
        t,
        updateDefinitionItemMetaDispatch,
        updateDefinitionProcessMetaDispatch
    ]);
    const validate = useCallback((value) => {
        try {
            void validateYupSchema(value, validationSchema, true, value);
        }
        catch (err) {
            const errors = yupToFormErrors(err); // for rendering validation errors
            return errors;
        }
        return {};
    }, []);
    const isValidClipboard = useCallback((clipText) => {
        try {
            const clipValue = JSON.parse(clipText);
            if (isMetaDefinition(clipValue)) {
                return true;
            }
        }
        catch (error) {
            return false;
        }
        return false;
    }, []);
    const loading = definitionStatus.isPending;
    if (loading) {
        return (React.createElement("div", { className: "py-5" },
            React.createElement(LoadingIndicator, null)));
    }
    if (!initialValues) {
        return null;
    }
    const MainButtonComponent = mainActionButtonComponent;
    return (React.createElement("div", { className: `${styles.wrapper} h-100 ${className || ''}` },
        React.createElement(Formik, { initialValues: initialValues, onSubmit: onSubmit, validate: validate, validateOnChange: false, validateOnBlur: true }, ({ isSubmitting, handleSubmit, dirty, values, resetForm, errors, setFieldValue, setErrors }) => {
            return (React.createElement(Form, { noValidate: true, className: "h-100" },
                React.createElement("div", { className: "h-100 cp-c-row cp-c-wrap cp-c-padding-2" },
                    React.createElement("div", { className: "position-relative overflow-auto cp-i-100 cp-i-lg-30" },
                        React.createElement(ActionButtonsSection, { loading: definitionStatus.isPending, submitting: isSubmitting, showHiddenFields: showHiddenFields, toggleShowHiddenFields: toggleShowHiddenFields, addItem: (type) => {
                                const tabIndex = activeTabId ? toNumber(activeTabId) : 0;
                                const newValue = cloneDeep(values.tabs);
                                if (type == 'tab') {
                                    const newTab = {
                                        id: generateReadableId(values.tabs, 'tab'),
                                        isNew: true,
                                        sections: [],
                                        system: false,
                                        titles: {
                                            en: '',
                                            de: '',
                                            fr: '',
                                            it: '',
                                            nl: ''
                                        }
                                    };
                                    newValue.push(newTab);
                                    setActiveTabId(toString(newValue.length - 1));
                                    setActiveId(newTab.id);
                                }
                                else if (type === 'section') {
                                    const newSection = generateNewSection(values);
                                    newValue[tabIndex].sections.push(newSection);
                                    setActiveId(newSection.id);
                                }
                                else {
                                    let currentSection = newValue[tabIndex].sections[newValue[tabIndex].sections.length - 1];
                                    if (!newValue[tabIndex].sections.length || currentSection.system) {
                                        const newSection = generateNewSection(values);
                                        newValue[tabIndex].sections.push(newSection);
                                        currentSection =
                                            newValue[tabIndex].sections[newValue[tabIndex].sections.length - 1];
                                    }
                                    const newProperty = {
                                        propertyId: generateReadableId(values.tabs, type),
                                        isNew: true,
                                        type: type,
                                        labels: {
                                            en: ''
                                        },
                                        meta: {
                                            hidden: false
                                        },
                                        readGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP],
                                        writeGroups: [ALL_USERS_PERMISSION_GROUP, ADMINS_PERMISSION_GROUP]
                                    };
                                    currentSection.properties.push(newProperty);
                                    setActiveId(newProperty.propertyId);
                                }
                                setFieldValue('tabs', newValue);
                            } })),
                    React.createElement("div", { className: "custom-scroll-styling position-relative overflow-auto cp-i-100 cp-i-lg-70" },
                        React.createElement(FormikField, { name: "tabs", component: MetaPropertyListItems, values: values, canDeleteProperties: isPlatformAdmin, metaDefinition: definition, showHiddenFields: showHiddenFields, activeId: activeId, activeTabId: activeTabId, onActiveChange: onActiveChange, onActiveTabChange: onActiveTabChange, submitting: isSubmitting, parentViewGroups: parentViewGroups }),
                        !isEmpty(errors) /* submitFailed*/ && (React.createElement("div", { className: "p-3 d-flex align-items-center justify-content-end error-container" },
                            React.createElement(IoAlert, { className: "me-2 ", size: 24 }),
                            React.createElement("div", null, t('CORRECT_INVALID_FIELDS')))))),
                React.createElement("div", { className: "form-buttons-container" },
                    React.createElement(ClipboardCopyButton, { value: getMetaDefinitionFromFormValue(values), className: "me-3", inversed: true, disabled: isSubmitting }),
                    React.createElement(ClipboardPasteButton, { className: "me-3", inversed: true, disabled: isSubmitting, isValid: isValidClipboard, onClick: (clipText) => {
                            const clipValue = JSON.parse(clipText);
                            if (!isMetaDefinition(clipValue)) {
                                return;
                            }
                            const newDefinition = mergeMetaDefinitions(getMetaDefinitionFromFormValue(values), clipValue);
                            let newFormValue = getFormValueFromMetaDefinition(newDefinition);
                            newFormValue = addIsNewToFormValue(values, newFormValue);
                            setFieldValue('tabs', newFormValue.tabs);
                        } }),
                    dirty && (React.createElement(Button, { className: "me-3", inversed: true, onClick: () => {
                            setActiveTabId('0');
                            resetForm();
                        }, disabled: isSubmitting }, t('FORM__RESET'))),
                    definition && (React.createElement(TestFieldsDrawerButton, { disabled: isSubmitting || !isEmpty(errors), definition: getMetaDefinitionFromFormValue(values), onErrorDefinitionValidation: (responseData) => {
                            const responseErrors = getErrorsFromFailedRequest(values, responseData);
                            if (!isEmpty(responseErrors)) {
                                setErrors(responseErrors);
                                return;
                            }
                            showError();
                        } })),
                    React.createElement(MainButtonComponent, { metaDefinitionId: metaDefinitionId, handleSubmit: handleSubmit, submitting: isSubmitting, dirty: dirty, disabled: definitionStatus.isPending }),
                    extraActionButtons),
                React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
                React.createElement(TabCloseConfirmation, { dirty: dirty })));
        })));
};
export default MetaDefinitionForm;
