import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer } from '../../../../../common/components/Drawer';
import { isAxiosError } from '../../../../../common/utils/request';
import { invalidateItemsQuery } from '../../../../hooks/invalidateCache';
import BulkEditForm from './BulkEditForm';
import { bulkUpdateAction } from './actions';
const BulkEditButton = ({ className, drawerTitle, selectedIds, disabled, children, definitionId, filterRequest, preventDefaultSubmit, permissionFieldsConfig, onClick }) => {
    const { t } = useI18n();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const httpDispatch = useDispatch();
    const notificationDispatch = useDispatch();
    const toggleDrawer = useCallback(() => {
        setIsDrawerOpen((prevIsDrawerOpen) => !prevIsDrawerOpen);
    }, []);
    const onSubmit = useCallback(async (itemData) => {
        if (isEmpty(itemData)) {
            return;
        }
        try {
            if (onClick) {
                await onClick(itemData);
            }
            if (!preventDefaultSubmit) {
                await httpDispatch(bulkUpdateAction(definitionId, itemData, selectedIds, filterRequest));
            }
            await invalidateItemsQuery({
                definitionId,
                waitNewData: true,
                ids: selectedIds,
                changedPropertyIds: Object.keys(itemData)
            });
            notificationDispatch(success({
                title: t('COMMON__SUCCESS'),
                message: t('GENERIC_ASYNC_CHANGES_WARNING')
            }));
            toggleDrawer();
        }
        catch (err) {
            if (isAxiosError(err)) {
                if (err.response?.status === 403) {
                    notificationDispatch(error({ title: t('COMMON__FAILURE'), message: t('PERMISSION_DENIED') }));
                }
            }
            else {
                notificationDispatch(error({
                    title: t('COMMON__FAILURE'),
                    message: `${t('ENTITIES_BULK_EDIT_FAIL')}`
                }));
            }
        }
    }, [
        onClick,
        preventDefaultSubmit,
        httpDispatch,
        definitionId,
        selectedIds,
        filterRequest,
        notificationDispatch,
        t,
        toggleDrawer
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: className, onClick: toggleDrawer, disabled: disabled }, children),
        React.createElement(Drawer, { title: drawerTitle, onClose: toggleDrawer, open: isDrawerOpen, destroyOnClose: true },
            React.createElement(BulkEditForm, { definitionId: definitionId, onCancel: toggleDrawer, onSubmit: onSubmit, permissionFieldsConfig: permissionFieldsConfig }))));
};
export default BulkEditButton;
