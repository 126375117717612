import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert } from 'antd';
import { flatten, get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { SelectPropertyField } from '../../../cms/data/components/item-property/SelectProperty';
import { AntInputField } from '../../../common/components/AntInput';
import { ConditionQueryField } from '../../../common/components/ConditionQuery';
import { DrawerContent, DrawerFooter } from '../../../common/components/Drawer';
import { DrawerContentBlock } from '../../../common/components/Drawer/DrawerContentBlock';
import { DrawerContentBlockTitle } from '../../../common/components/Drawer/DrawerContentBlockTitle';
import { DrawerFormFooter } from '../../../common/components/Drawer/DrawerFormFooter';
import MultiLanguageField from '../../../common/components/MultiLanguageField/MultiLanguageField';
import { SelectField } from '../../../common/components/Select';
import Toggle from '../../../common/components/Toggle';
import { GroupingRow } from '../GroupingRow';
import { useView } from '../ViewContext';
import styles from './EditMetricForm.module.scss';
import { byMetric, metrics } from './constants';
import { AggregationTypes } from './types';
const getDefinitionProperties = (displayData) => {
    return displayData != null ? flatten(displayData.data.map((s) => s.values)) : [];
};
const AMOUNT_MIN_SIZE = 1;
const AMOUNT_MAX_SIZE = 50;
const EditMetricForm = ({ title, handleSubmit, onCancel, displayData, form, definitionId, change }) => {
    const store = useStore();
    const { view } = useView();
    const formValues = getFormValues(form)(store.getState());
    const { t, language } = useI18n();
    const properties = useMemo(() => {
        if (!formValues?.metric) {
            return [];
        }
        const metric = formValues.metric;
        return getDefinitionProperties(displayData)
            .filter((p) => !byMetric[metric]?.propertyTypes || byMetric[metric]?.propertyTypes?.includes(p.type))
            .map(({ propertyId, labels }) => ({ value: propertyId, labels }));
    }, [displayData, formValues]);
    const allowViewUnitsTitle = !formValues?.rowGrouping;
    const allowPercent = ['value_count', 'cardinality'].includes(formValues?.metric || '') && allowViewUnitsTitle;
    const allowUnits = (!allowPercent || !get(formValues, 'inPercents', false)) && allowViewUnitsTitle;
    const onChangeMetricFields = useCallback(() => {
        change('inactiveGroupingElements', []);
    }, [change]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerContent, { className: styles.metricForm },
            React.createElement("form", { noValidate: true, className: "d-flex flex-column gap-2 flex-basis-100" },
                React.createElement(DrawerContentBlock, null,
                    React.createElement(DrawerContentBlockTitle, { className: "headerTitle", title: title }),
                    view && view.isDefault ? (React.createElement(Alert, { message: t('VIEWS__PERMISSIONS_WARNING'), type: "warning", closable: false })) : null,
                    React.createElement(DrawerContentBlockTitle, { title: t('VIEWS__TITLE_LABEL') }),
                    React.createElement(MultiLanguageField, { component: AntInputField, name: "titles", type: "text" }),
                    React.createElement(DrawerContentBlockTitle, { title: t('VIEWS__METRIC_LABEL') }),
                    React.createElement(Field, { onChange: onChangeMetricFields, component: SelectField, options: metrics.map((metric) => ({
                            label: metric.labels[language],
                            value: metric.value
                        })), name: "metric", language: language }),
                    (formValues?.metric !== AggregationTypes.value_count || !formValues?.rowGrouping) && (React.createElement(React.Fragment, null,
                        React.createElement(DrawerContentBlockTitle, { title: t('VIEWS__FIELD_LABEL') }),
                        React.createElement(Field, { name: "field", disabled: get(formValues, 'metric') == null, component: SelectPropertyField, placeholder: t('SELECT_PROPERTY'), onChange: onChangeMetricFields, definitionId: definitionId, includeNestedProperties: false, asFilter: true, includeProperty: ({ propertyId }) => properties.some(({ value }) => value === propertyId) }))),
                    React.createElement(DrawerContentBlockTitle, { title: t('VIEWS__GROUPING') }),
                    React.createElement(Field, { asFilter: true, name: "rowGrouping", includeNestedProperties: true, component: GroupingRow, definitionId: definitionId, onChange: onChangeMetricFields, labelGroupingBy: t('VIEWS__GROUPING_ROW_ORDER_BY') },
                        React.createElement(DrawerContentBlockTitle, { title: t('HYPER_BI_SIZE_LABEL') }),
                        React.createElement(Field, { className: "mt-2", name: "size", component: AntInputField, min: AMOUNT_MIN_SIZE, max: AMOUNT_MAX_SIZE, type: "number", validate: required })),
                    allowViewUnitsTitle && (React.createElement(React.Fragment, null,
                        allowPercent && (React.createElement(Field, { component: Toggle, label: t('VIEWS__FIELD_IN_PERCENTS'), name: "inPercents" })),
                        allowUnits && (React.createElement(React.Fragment, null,
                            React.createElement(DrawerContentBlockTitle, { title: t('VIEWS__UNITS_LABEL') }),
                            React.createElement(MultiLanguageField, { component: AntInputField, name: "units", type: "text" }))))),
                    React.createElement(DrawerContentBlockTitle, { title: t('VIEWS__FILTERS') }),
                    React.createElement(Field, { name: "query", component: ConditionQueryField, definitionId: displayData.definitionId })))),
        React.createElement(DrawerFooter, null,
            React.createElement(DrawerFormFooter, { onSubmit: handleSubmit, onCancel: onCancel }))));
};
export default EditMetricForm;
