import { required } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Divider } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';
import { Field, FormSection, formValueSelector } from 'redux-form';
import { useProperties } from '../../../../../cms/common/context/PropertiesProvider';
import { FieldLabel } from '../../../../../cms/data/components/item-property/FieldElements';
import { SortablePropertyListField } from '../../../../../cms/data/components/item-property/SelectProperty';
import { AntInput } from '../../../../../common/components/AntInput';
import ComputedInputField from '../../../../../common/components/ComputedExpressionEditor/ComputedInputField/ComputedInputField';
import MultiLanguageField from '../../../../../common/components/MultiLanguageField/MultiLanguageField';
import { SelectField } from '../../../../../common/components/Select';
import TemplateSelector from '../../../../../documents/templates/components/common/TemplateSelector';
import UpdateMethodInputField from './UpdateMethodInputField';
const GenerateDocumentForm = ({ disabled, metaDefinitionId, node }) => {
    const store = useStore();
    const { t } = useI18n();
    const { isPropertyList } = useProperties(metaDefinitionId);
    const { parameters } = formValueSelector('activitySettings')(store.getState(), 'activity.' + node.id);
    const isFileFieldList = useMemo(() => isPropertyList(node?.parameters?.filePropertyId), [node, isPropertyList]);
    const isDocumentProperty = useCallback((displayData) => {
        if (displayData.type !== 'file') {
            return false;
        }
        if (displayData.meta && displayData.meta.accept) {
            const acceptPatterns = displayData.meta.accept.split('|');
            const type = parameters?.fileType === 'docx'
                ? '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                : 'application/pdf';
            if (!acceptPatterns.includes(type)) {
                return false;
            }
        }
        return true;
    }, [parameters]);
    return (React.createElement("div", null,
        React.createElement(FieldLabel, { label: t('WORKFLOWS_SETTINGS_TITLE'), className: "ps-0" }),
        React.createElement(MultiLanguageField, { name: "titles", component: AntInput, placeholder: t('COMMON__TITLE'), disabled: disabled }),
        React.createElement(Divider, null, t('WORKFLOWS_SETTINGS_PARAMETERS')),
        React.createElement(FormSection, { name: "parameters" },
            React.createElement(FieldLabel, { label: t('TASK_GENERATE_DOCUMENT_TEMPLATE') }),
            React.createElement(Field, { name: "templateId", component: ComputedInputField, computedComponent: TemplateSelector, disabled: disabled, validate: required, className: "mb-2", placeholder: t('SEARCH_TEMPLATE') }),
            React.createElement(FieldLabel, { label: t('TASK_GENERATE_DOCUMENT_FILE_TYPE') }),
            React.createElement(Field, { component: SelectField, name: "fileType", options: ['pdf', 'docx'].map((type) => ({
                    value: type,
                    labels: type
                })), className: "mb-2", placeholder: `${t('SELECT_INPUT')} ${t('ACTIVITY_SHORT_TYPE_LABEL')}` }),
            React.createElement(FieldLabel, { label: t('TASK_GENERATE_DOCUMENT_FILE_PROPERTY'), help: t('TASK_GENERATE_DOCUMENT_FILE_PROPERTY_INFO'), labelRight: isFileFieldList && React.createElement(Field, { name: "updateMethod", component: UpdateMethodInputField }) }),
            React.createElement(Field, { name: "filePropertyId", component: SortablePropertyListField, definitionId: metaDefinitionId, includeProperty: isDocumentProperty, includeNestedProperties: false, sortable: false, disabled: disabled || !!node?.parameters?.filePropertyId, parse: (newValue) => (newValue.length ? newValue[0] : null), format: (value) => (value ? [value] : []), validate: required, className: "mb-2" }),
            React.createElement(FieldLabel, { label: t('TASK_GENERATE_DOCUMENT_NAME') + ':' }),
            React.createElement(Field, { name: "fileName", component: ComputedInputField, computedComponent: AntInput, disabled: disabled, className: "mb-2", placeholder: `${t('COMMON__INPUT_PLACEHOLDER')} ${t('TASK_GENERATE_DOCUMENT_NAME')}` }))));
};
export default GenerateDocumentForm;
