import { Divider } from 'antd';
import { get, isEmpty, toPath } from 'lodash';
import React, { useMemo } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FieldLabel } from '../../../../../../../cms/data/components/item-property/FieldElements';
import { displayNodeParameter } from '../utils';
import ParameterInput from './ParameterInput';
const ParameterList = ({ parameters, node, propertiesTree, className, onChange, path, disabled, onRemove, errors, ...otherProps }) => {
    const filteredParameters = useMemo(() => {
        if (!parameters.length) {
            return [];
        }
        return parameters.filter((property) => {
            return displayNodeParameter(property, node, path);
        });
    }, [parameters, node, path]);
    return (React.createElement("div", { className: `parameter-list mb-3 ${className || ''}` }, filteredParameters.map((parameter, index) => {
        const key = [...toPath(path), parameter.name].filter((item) => !isEmpty(item));
        const value = get(node, key, parameter.default);
        return (React.createElement("div", { key: index, className: "row mb-2" },
            onRemove && (React.createElement("div", { className: "col-1" },
                React.createElement(FaRegTrashAlt, { onClick: () => onRemove(parameter), className: "remove-icon" }))),
            React.createElement("div", { className: "col" },
                ['collection', 'fixedCollection'].includes(parameter.type) ? (React.createElement(Divider, null, parameter.displayName)) : (React.createElement(FieldLabel, { label: parameter.displayName, help: parameter.description })),
                React.createElement(ParameterInput, { ...otherProps, parameter: parameter, path: path, node: node, propertiesTree: propertiesTree, value: value, disabled: disabled, onChange: (value) => {
                        // @ts-expect-error
                        onChange && onChange(parameter, value);
                    }, error: errors?.[parameter.name] }))));
    })));
};
export default ParameterList;
