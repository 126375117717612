import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { normalizeNumber } from '../../utils';
const NumberValidationFields = ({ disabled, onChange, value }) => {
    const { t } = useI18n();
    const onChangeMin = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                validations: {
                    ...value.meta.validations,
                    min: normalizeNumber(event.target.value)
                }
            }
        });
    }, [onChange, value]);
    const onChangeMax = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                validations: {
                    ...value.meta.validations,
                    max: normalizeNumber(event.target.value)
                }
            }
        });
    }, [onChange, value]);
    return (React.createElement("div", { className: "row" },
        React.createElement(BasicInput, { className: "col-12 col-sm-6", type: "number", label: t('PROPERTY_MIN_VALUE'), disabled: disabled, value: value.meta.validations?.min, onChange: onChangeMin }),
        React.createElement(BasicInput, { className: "col-12 col-sm-6", type: "number", label: t('PROPERTY_MAX_VALUE'), disabled: disabled, value: value.meta.validations?.max, onChange: onChangeMax })));
};
export default NumberValidationFields;
