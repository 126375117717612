export var ProcessStatus;
(function (ProcessStatus) {
    ProcessStatus["SUCCEEDED"] = "SUCCEEDED";
    ProcessStatus["RUNNING"] = "RUNNING";
    ProcessStatus["FAILED"] = "FAILED";
    ProcessStatus["CANCELLED"] = "CANCELLED";
})(ProcessStatus || (ProcessStatus = {}));
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["SUCCEEDED"] = "SUCCEEDED";
    TaskStatus["RUNNING"] = "RUNNING";
    TaskStatus["WAITING"] = "WAITING";
    TaskStatus["FAILED"] = "FAILED";
    TaskStatus["CANCELLED"] = "CANCELLED";
})(TaskStatus || (TaskStatus = {}));
export var SESEventType;
(function (SESEventType) {
    SESEventType["BOUNCE"] = "Bounce";
    SESEventType["COMPLAINT"] = "Complaint";
    SESEventType["DELIVERY"] = "Delivery";
    SESEventType["OPEN"] = "Open";
    SESEventType["REJECT"] = "Reject";
    SESEventType["RENDERING_FAILURE"] = "Rendering Failure";
    SESEventType["SEND"] = "Send";
    SESEventType["DELIVERY_DELAY"] = "DeliveryDelay";
    SESEventType["CLICK"] = "Click";
})(SESEventType || (SESEventType = {}));
export var ChangeEventAction;
(function (ChangeEventAction) {
    ChangeEventAction["CREATE"] = "CREATE";
    ChangeEventAction["UPDATE"] = "UPDATE";
    ChangeEventAction["DELETE"] = "DELETE";
})(ChangeEventAction || (ChangeEventAction = {}));
// //////////////////////////////////
//
// Activity diagrams
//
// //////////////////////////////////
export var ActivityNodeTypes;
(function (ActivityNodeTypes) {
    ActivityNodeTypes["MERGE"] = "MERGE";
    ActivityNodeTypes["FORK"] = "FORK";
    ActivityNodeTypes["JOIN"] = "JOIN";
    ActivityNodeTypes["ACTION"] = "ACTION";
    ActivityNodeTypes["LOOP_DO"] = "LOOP_DO";
    ActivityNodeTypes["LOOP_WHILE"] = "LOOP_WHILE";
    ActivityNodeTypes["START"] = "START";
    ActivityNodeTypes["END"] = "END";
})(ActivityNodeTypes || (ActivityNodeTypes = {}));
export var ActivityActionNodeSubtypes;
(function (ActivityActionNodeSubtypes) {
    ActivityActionNodeSubtypes["MANUAL"] = "MANUAL";
    ActivityActionNodeSubtypes["AUTOMATED"] = "AUTOMATED";
    ActivityActionNodeSubtypes["SEMIAUTOMATED"] = "SEMIAUTOMATED";
})(ActivityActionNodeSubtypes || (ActivityActionNodeSubtypes = {}));
export const isTaskPriority = (value) => typeof value === 'string' && Object.values(TaskPriority).includes(value);
export var TaskPriority;
(function (TaskPriority) {
    TaskPriority["LOW"] = "2-LOW";
    TaskPriority["NORMAL"] = "4-NORMAL";
    TaskPriority["HIGH"] = "6-HIGH";
    TaskPriority["URGENT"] = "8-URGENT";
})(TaskPriority || (TaskPriority = {}));
