import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { Option } from '../../../../../../common/components/Select';
import FormikField from '../../../../../../common/components/formik/FormikField';
import TabCloseConfirmation from '../../../../../../common/components/tab-close-confirmation/TabCloseConfirmation';
import { ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID } from '../../../../../../workflows';
import useDisplayItemMetaList from '../../../../../hooks/useDisplayItemMetaList';
import EntityActions from '../../entity-actions/EntityActions';
import { onItemMetaFormSubmit } from '../../meta-entity-form/utils';
import styles from '../HighlightedItems.module.scss';
import HighlightedListField from '../components/HighlightedListField';
import HighlightedSelectField from '../components/HighlightedSelectField';
const HighlightedReferralsForm = ({ entityMetaId, extraActionButtons, entityMeta, isSystem }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { data: entities } = useDisplayItemMetaList();
    const cmsEntities = useMemo(() => entities.filter((entity) => ![ALL_PROCESSES_DEFINITION_ID, ALL_TASKS_DEFINITION_ID].includes(entity.definitionId)), [entities]);
    const cmsEntitiesById = useMemo(() => cmsEntities.reduce((acc, item) => ({ ...acc, [item.definitionId]: item }), {}), [cmsEntities]);
    const initialValues = useMemo(() => ({
        titles: entityMeta?.titles || {},
        viewGroups: entityMeta?.viewGroups || [],
        custom: { highlightedReferrals: [], ...(entityMeta?.custom || {}) }
    }), [entityMeta?.custom, entityMeta?.titles, entityMeta?.viewGroups]);
    const handleSubmit = useCallback(async (values) => {
        await onItemMetaFormSubmit(values, dispatch, {
            itemMeta: entityMeta,
            t: t
        });
    }, [dispatch, entityMeta, t]);
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: handleSubmit, enableReinitialize: true }, ({ dirty, isSubmitting, resetForm, setFieldValue, submitForm, values }) => (React.createElement("div", { className: `${styles.container} py-4 px-3` },
        React.createElement("div", { className: "form-buttons-container" },
            dirty && (React.createElement(Button, { className: "reset-btn", inversed: true, onClick: () => resetForm(), disabled: isSubmitting }, t('FORM__RESET'))),
            React.createElement(EntityActions, { id: entityMetaId, save: submitForm, saving: isSubmitting, canSave: dirty, canDelete: !isSystem }),
            extraActionButtons),
        React.createElement("form", { noValidate: true },
            React.createElement("div", null,
                React.createElement("div", { className: "fw-500 mb-2" }, t('HIGHLIGHTED_REFERRALS_SETTINGS_SELECT_LABEL')),
                React.createElement(FormikField, { name: "custom.highlightedReferrals", component: HighlightedSelectField, data: cmsEntities, getValue: (value) => value.map((referral) => referral.definitionId), onSelectHandler: (selected) => {
                        void setFieldValue('custom.highlightedReferrals', selected.map((definitionId) => {
                            const value = values?.custom?.highlightedReferrals || [];
                            const existed = value.find((item) => item.definitionId === definitionId);
                            return { definitionId, active: !!existed?.active };
                        }));
                    }, getOptions: (data) => data.map(({ definitionId, title }) => (React.createElement(Option, { key: definitionId, value: definitionId, disabled: false, label: title }, title))) }),
                React.createElement("div", { className: "fw-500 mb-2 mt-4" }, t('HIGHLIGHTED_REFERRALS_SETTINGS_LIST_LABEL')),
                React.createElement(FormikField, { name: "custom.highlightedReferrals", component: HighlightedListField, dataById: cmsEntitiesById }))),
        React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
        React.createElement(TabCloseConfirmation, { dirty: dirty })))));
};
export default HighlightedReferralsForm;
