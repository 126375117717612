import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaChartPie, FaPlus, FaSync, FaTable, FaTasks, FaTrash } from 'react-icons/fa';
import { MdDoneAll } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { hasTenantPermission } from '../../../../auth';
import { titleValidations } from '../../../../cms';
import ActionsButton from '../../../../common/components/ActionsButton';
import ConfirmationButtonModal from '../../../../common/components/ConfirmationButtonModal';
import DateRangeSelector from '../../../../common/components/DateRangeSelector';
import { isRelativeRangeValue } from '../../../../common/components/DateRangeSelector/types';
import { getDateRangeSelectorValue } from '../../../../common/components/DateRangeSelector/utils';
import DropdownButton from '../../../../common/components/DropdownButton';
import { HYPER_BI_PERMISSION } from '../../../constants';
import styles from './DashboardControlPanel.module.scss';
import DashboardSelector from './DashboardSelector';
const DashboardControlPanel = ({ dashboard, value, isDirty, onClickApply, onClickRefresh, onClickDelete, onClickSave, onClickShare, onClickAddWidget, onUpdateTitle, canEdit, canDelete, canCreate, canShare }) => {
    const { t } = useI18n();
    const location = useLocation();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(value || {});
    const hasHyperBiPermission = useSelector((s) => hasTenantPermission(s, HYPER_BI_PERMISSION));
    const onClickAddChart = useCallback(() => {
        return onClickAddWidget && onClickAddWidget('chart');
    }, [onClickAddWidget]);
    const onClickAddActivity = useCallback(() => {
        return onClickAddWidget && onClickAddWidget('activity');
    }, [onClickAddWidget]);
    const onClickAddTable = useCallback(() => {
        return onClickAddWidget && onClickAddWidget('table');
    }, [onClickAddWidget]);
    const onClickAddYourTasks = useCallback(() => {
        return onClickAddWidget && onClickAddWidget('your-tasks');
    }, [onClickAddWidget]);
    const dateRangeSelectorValue = useMemo(() => getDateRangeSelectorValue(filter.rangePicker), [filter.rangePicker]);
    const onChangedDateRange = useCallback((value) => {
        const newFilter = { ...filter };
        if (isRelativeRangeValue(value)) {
            newFilter.rangePicker = {
                ...value
            };
        }
        else {
            newFilter.rangePicker = {
                start: value.time[0]?.valueOf(),
                end: value.time[1]?.valueOf()
            };
        }
        setFilter(newFilter);
        if (onClickApply) {
            void onClickApply(newFilter);
        }
    }, [onClickApply, filter]);
    const onSelect = useCallback((id) => {
        const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
        dispatch(push({
            ...location,
            pathname: `${baseUrl}/${id}`
        }));
    }, [dispatch, location]);
    const onAdd = useCallback(() => {
        const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
        dispatch(push({
            ...location,
            pathname: `${baseUrl}/create`
        }));
    }, [dispatch, location]);
    useEffect(() => {
        setFilter(value || {});
    }, [value]);
    const canCreateDropdownButtonMenu = useMemo(() => {
        const items = [];
        if (hasHyperBiPermission) {
            items.push({
                key: 'chart',
                icon: React.createElement(FaChartPie, null),
                onClick: onClickAddChart,
                label: t('HYPER_BI_CHART')
            });
        }
        items.push({
            key: 'activity',
            icon: React.createElement(FaTasks, null),
            onClick: onClickAddActivity,
            label: t('ACTIVITY')
        }, {
            key: 'table',
            icon: React.createElement(FaTable, null),
            onClick: onClickAddTable,
            label: t('HYPER_BI_TABLE')
        });
        items.push({
            key: 'your-tasks',
            icon: React.createElement(MdDoneAll, null),
            onClick: onClickAddYourTasks,
            label: t('TASKS')
        });
        return {
            items
        };
    }, [
        hasHyperBiPermission,
        onClickAddActivity,
        onClickAddChart,
        onClickAddTable,
        onClickAddYourTasks,
        t
    ]);
    return (React.createElement("div", { className: styles.editRow },
        React.createElement(DashboardSelector, { className: "me-3", onChange: onUpdateTitle, dashboard: dashboard, validations: {
                ...titleValidations,
                required: true
            }, onSelect: onSelect, onAdd: canCreate ? onAdd : undefined }),
        React.createElement(DateRangeSelector, { className: "date-range", value: dateRangeSelectorValue, onChange: onChangedDateRange }),
        React.createElement(Button, { inversed: true, className: "action-button refresh-btn", onClick: onClickRefresh },
            React.createElement(FaSync, null)),
        !!onClickSave && !!canEdit && (React.createElement(ActionsButton, { className: "save-btn", buttonAction: {
                action: onClickSave,
                canRun: !!isDirty,
                buttonLabel: 'HYPER_BI_SAVE'
            }, menuActions: onClickShare && [
                {
                    action: onClickShare,
                    canRun: !!canShare,
                    buttonLabel: 'HYPER_BI_SHARE'
                }
            ], inversed: true })),
        canDelete ? (React.createElement(ConfirmationButtonModal, { danger: true, className: "action-button delete-btn", onClick: onClickDelete, tooltip: t('HYPER_BI_DELETE_DASHBOARD'), confirmationTitle: t('HYPER_BI_DELETE_DASHBOARD'), confirmationMessage: t('HYPER_BI_DELETE_DASHBOARD_CONFIRMATION') },
            React.createElement(FaTrash, null))) : null,
        !!canCreate && (React.createElement(DropdownButton, { className: "add-widget-btn", menu: canCreateDropdownButtonMenu, menuText: React.createElement(React.Fragment, null,
                React.createElement(FaPlus, { className: "me-2" }),
                t('HYPER_BI_ADD_WIDGET')) }))));
};
export default DashboardControlPanel;
