import { connect } from 'formik';
import { Component } from 'react';
/*
 Would need a better way to track the fields
 maybe use the react-scroll
*/
class Focus extends Component {
    componentDidUpdate(prevProps) {
        const { isSubmitting, isValidating, errors } = prevProps.formik;
        const keys = Object.keys(errors);
        if (keys.length > 0 && isSubmitting && !isValidating) {
            const firstError = document.querySelector(`#${keys[0]}`);
            firstError &&
                firstError.parentElement?.scrollIntoView({
                    behavior: 'smooth'
                });
        }
    }
    render() {
        return null;
    }
}
// @ts-expect-error
const ErrorFocus = connect(Focus);
export default ErrorFocus;
