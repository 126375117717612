import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Virtuoso } from 'react-virtuoso';
import { KanbanCard } from '../KanbanCard';
import { KanbanListHeader } from '../KanbanListHeader';
import styles from './KanbanList.module.scss';
const LIST_BLOCK_PADDING = '8px';
const KANBAN_COLUMN_HEADER_HEIGHT = '64px';
const HeightPreservingItem = ({ children, ...props }) => {
    const [size, setSize] = useState(0);
    const knownSize = props['data-known-size'];
    useEffect(() => {
        setSize((prevSize) => (knownSize == 0 ? prevSize : knownSize));
    }, [knownSize]);
    return (React.createElement("div", { ...props, className: "height-preserving-container", style: {
            '--child-height': `${size}px`
        } }, children));
};
const KanbanList = ({ baseUrl, cards, contentHeight, definitionId, isDropDisabled, fields, hidden, propertyId, innerRef, onToggleList, title, viewId, theme, getCustomColumnCountFilters, getCustomRouteHandleCardClick }) => {
    return (React.createElement("div", { className: `${styles.list} ${hidden ? styles.list__hidden : ''}`, style: { borderColor: theme?.primary } },
        React.createElement(KanbanListHeader, { getCustomColumnCountFilters: getCustomColumnCountFilters, baseUrl: baseUrl, definitionId: definitionId, propertyId: propertyId, onToggleList: onToggleList, hidden: hidden, title: title, theme: theme }),
        React.createElement("div", { ref: innerRef },
            React.createElement(Virtuoso, { className: `custom-scroll-styling ${styles.list_draggableArea} ${hidden ? styles.list_draggableArea__hidden : ''}`, components: {
                    Item: HeightPreservingItem
                }, data: cards, style: {
                    width: 356,
                    minWidth: 356,
                    maxWidth: 356,
                    paddingTop: 8,
                    height: contentHeight
                        ? `calc(${contentHeight} - ${KANBAN_COLUMN_HEADER_HEIGHT} - ${LIST_BLOCK_PADDING} - ${LIST_BLOCK_PADDING})`
                        : '100vh'
                }, itemContent: (index, item) => (React.createElement(Draggable, { draggableId: item.entityId, index: index, key: item.entityId, isDragDisabled: isDropDisabled }, (provided) => (React.createElement(KanbanCard, { getCustomRouteHandleCardClick: getCustomRouteHandleCardClick, baseUrl: baseUrl, card: item, definitionId: definitionId, fields: fields, provided: provided, viewId: viewId })))) }))));
};
export default KanbanList;
