import { email, required } from '@hypercharge/hyper-react-base/lib/form';
import { get, isEmpty, isString } from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';
function validatePropertyValue(propertyValue, type, meta, t) {
    let error;
    const isRequiredProperty = !!get(meta, 'validations.required', false);
    if (isRequiredProperty) {
        const requiredMsg = required(propertyValue);
        if (requiredMsg) {
            return requiredMsg;
        }
    }
    if (propertyValue === null || propertyValue === undefined || propertyValue === '') {
        return;
    }
    switch (type) {
        case 'email':
            error = email(propertyValue);
            break;
        case 'phoneNumber':
            if (!isString(propertyValue)) {
                error = t('VALIDATIONS__INVALID_PHONE_NUMBER');
            }
            else if (!isValidPhoneNumber(propertyValue) && propertyValue !== '') {
                error = t('VALIDATIONS__INVALID_PHONE_NUMBER');
            }
            break;
    }
    return error;
}
export function validatePartialEntityItem(entityItem, displayData, t) {
    const properties = displayData.data.reduce((properties, section) => {
        if (!section) {
            return properties;
        }
        properties = section.values.reduce((properties, property) => {
            properties[property.propertyId] = property;
            return properties;
        }, properties);
        return properties;
    }, {});
    const errors = {};
    for (const [propertyId, propertyValue] of Object.entries(entityItem)) {
        const { type, meta } = properties[propertyId];
        const error = validatePropertyValue(propertyValue, type, meta, t);
        if (error) {
            errors[propertyId] = error;
        }
    }
    return isEmpty(errors) ? undefined : errors;
}
