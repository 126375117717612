import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { default as React } from 'react';
import { getPropertyTypeComponent } from '../../../../../../workflows/meta/components/meta-definition-form/utils';
import { FieldLabel } from '../../../item-property/FieldElements';
import styles from './PropertyFieldPreview.module.scss';
import PropertyPreview from './PropertyPreview';
const PropertyFieldPreview = ({ field, entity }) => {
    const { language } = useI18n();
    const Icon = getPropertyTypeComponent(field.type);
    return (React.createElement("div", { className: "col-12", key: field.propertyId },
        React.createElement("section", { className: `${styles.fieldWrapper} d-flex flex-wrap mt-2 t-${field.type}` },
            React.createElement("div", { className: "FieldLabelWrapper d-flex align-items-center" },
                React.createElement(FieldLabel, { icon: Icon ? React.createElement(Icon, null) : null, label: getTranslation(field.labels, language), computed: field.computed, required: field.meta?.validations?.required })),
            React.createElement(PropertyPreview, { property: field, item: entity }))));
};
export default PropertyFieldPreview;
