import { BasicSelect } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { generateId } from '@hypercharge/hyper-react-base/lib/utils';
import { Button, Collapse, Input, Modal } from 'antd';
import { DEFAULT_LANGUAGE } from 'config';
import memoize from 'fast-memoize';
import { Field, FieldArray, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { GoPlus, GoX } from 'react-icons/go';
import * as Yup from 'yup';
import { SelectPropertyField } from '../../../../../../cms/data/components/item-property/SelectProperty';
import { AggregationType, DEFAULT_FILTER } from '../../../../../../cms/hooks/useAggregation';
import { ConditionQueryField } from '../../../../../../common/components/ConditionQuery';
import { MultiLanguageInput, MultiLanguageValidationSchema } from '../../../../../../common/components/MultiLanguageField';
import { MultiLanguageOptionalValidationSchema } from '../../../../../../common/components/MultiLanguageField/MultiLanguageField';
import Toggle from '../../../../../../common/components/Toggle';
import { ItemMetaSelector } from '../../../../../../common/components/entity-selectors';
import FormikField from '../../../../../../common/components/formik/FormikField';
import { AggregationsOptions, getDefaultAggregation, hasSubAggregation } from '../../../aggregations';
import { getDefaultMetric, isAvailableMetricField } from '../../../metrics';
import { Widget } from '../../Widget';
import ChartWidget from '../ChartWidget';
import AggregationField from './AggregationForm';
import styles from './EditChartForm.module.scss';
import { ChartTypesOptions, DateHistogramIntervalOptions, TermsOrderByOptions, getAvailableMetrics, isAllowAggregations, isAllowManyMetrics, isAllowMetricPercent, isAllowMetricPercentByMetric, isAllowMetricUnits, isAllowSubAggregations } from './utils';
const metricSchema = Yup.lazy((metricSettingsItem, options) => {
    const chartSettings = options.context;
    const availableMetrics = getAvailableMetrics(chartSettings.chart.type);
    let baseMetricSchema = Yup.object({
        id: Yup.string().nullable().required('Required'),
        field: Yup.string().nullable().required('Required'),
        type: Yup.string()
            .label('Metric')
            .oneOf(availableMetrics.map((item) => item.value), `Metric must be one of the following values: ${availableMetrics
            .map((item) => item.labels.en)
            .join(', ')}`)
            .required('Required')
    });
    if (isAllowMetricPercent(chartSettings.chart.type) &&
        isAllowMetricPercentByMetric(metricSettingsItem)) {
        baseMetricSchema = baseMetricSchema.shape({
            inPercents: Yup.boolean()
        });
    }
    if (isAllowMetricUnits(chartSettings.chart.type)) {
        baseMetricSchema = baseMetricSchema.shape({
            units: MultiLanguageOptionalValidationSchema
        });
    }
    return baseMetricSchema;
});
const aggregationSchema = Yup.lazy((aggregationFilterItem, options) => {
    const chartSettings = options.context;
    if (chartSettings.chart.type === 'metric') {
        return Yup.array(Yup.object()).notRequired();
    }
    const { type } = aggregationFilterItem;
    let baseAggregationSchema = Yup.object({
        id: Yup.string().nullable().required('Required'),
        field: Yup.string().nullable().required('Required'),
        type: Yup.string()
            .oneOf(AggregationsOptions.map((item) => item.value))
            .required('Required')
    });
    if (isAllowSubAggregations(chartSettings.chart.type)) {
        baseAggregationSchema = baseAggregationSchema.shape({
            aggregations: Yup.array(aggregationSchema).length(1)
        });
    }
    switch (type) {
        case AggregationType.term:
            baseAggregationSchema = baseAggregationSchema.shape({
                orderBy: Yup.string()
                    .oneOf(TermsOrderByOptions.map((item) => item.value))
                    .required('Required'),
                size: Yup.number().required('Required')
            });
            if (chartSettings.chart.type == 'funnel') {
                baseAggregationSchema = baseAggregationSchema.shape({
                    customOrder: Yup.object({
                        enabled: Yup.boolean(),
                        values: Yup.array(Yup.string())
                    })
                });
            }
            break;
        case AggregationType.dateHistogram:
            baseAggregationSchema = baseAggregationSchema.shape({
                interval: Yup.string()
                    .oneOf(DateHistogramIntervalOptions.map((item) => item.value))
                    .required('Required'),
                minDocCount: Yup.number(),
                timezone: Yup.string()
            });
            break;
    }
    return baseAggregationSchema;
});
const validationSchema = Yup.object({
    type: Yup.string().default('chart').required('Required'),
    id: Yup.string().required('Required'),
    definitionId: Yup.string().required('Required'),
    metrics: Yup.array(metricSchema).required('Required'),
    aggregations: Yup.array(aggregationSchema).max(1).required('Required'),
    filter: Yup.object({
        languageCode: Yup.string().required('Required'),
        sortBy: Yup.array(Yup.object().shape({
            field: Yup.string().required('Required'),
            order: Yup.string().required('Required')
        })).required('Required'),
        query: Yup.object().shape({
            condition: Yup.string().required('Required'),
            filters: Yup.array()
        })
    }).required('Required'),
    chart: Yup.object({
        type: Yup.string()
            .oneOf(ChartTypesOptions.map((item) => item.value))
            .required('Required'),
        title: Yup.object({
            text: MultiLanguageValidationSchema,
            position: Yup.string().oneOf(['right', 'center', 'left']).nullable()
        })
    }).required('Required'),
    layout: Yup.object({
        i: Yup.string().required('Required'),
        x: Yup.number().default(0).required('Required'),
        y: Yup.number().default(0).required('Required'),
        w: Yup.number().default(2).required('Required'),
        h: Yup.number().default(4).required('Required')
    })
}).transform((currentValue) => {
    if (currentValue.chart.type === 'metric') {
        currentValue.aggregations = [];
    }
    return currentValue;
});
export function getDefaultChartSettings() {
    const id = generateId();
    return {
        type: 'chart',
        id: id,
        definitionId: '',
        metrics: [getDefaultMetric()],
        aggregations: [getDefaultAggregation()],
        filter: cloneDeep(DEFAULT_FILTER),
        chart: {
            title: {
                text: {
                    [DEFAULT_LANGUAGE]: ''
                },
                position: 'left'
            },
            type: 'pie'
        },
        layout: {
            i: id,
            x: 0,
            y: 0,
            w: 3,
            h: 4
        }
    };
}
const collapsibleDisabled = 'disabled';
const memoizedCastSettings = memoize((chartSettings) => {
    return validationSchema.cast(chartSettings, {
        stripUnknown: true,
        context: chartSettings
    });
});
const EditChartForm = ({ title, value, open, onCancel, onOk }) => {
    const { t, language } = useI18n();
    const _onCancel = useCallback(() => {
        onCancel?.();
    }, [onCancel]);
    const onSave = useCallback((data, formikHelpers) => {
        const stripedData = memoizedCastSettings(data);
        onOk(stripedData);
    }, [onOk]);
    const validate = useCallback((value) => {
        try {
            void validateYupSchema(value, validationSchema, true, value);
        }
        catch (err) {
            const errors = yupToFormErrors(err); // for rendering validation errors
            return errors;
        }
        return {};
    }, []);
    const defaultFilter = useMemo(() => {
        return {
            startTime: Date.now() - 365 * 24 * 60 * 60 * 1000,
            endTime: Date.now()
        };
    }, []);
    return (React.createElement(Formik, { initialValues: value || getDefaultChartSettings(), validateOnBlur: true, validateOnChange: false, onSubmit: onSave, validate: validate }, ({ handleSubmit, values, setFieldValue }) => {
        const availableMetrics = getAvailableMetrics(values.chart.type);
        const allowManyMetrics = isAllowManyMetrics(values.chart.type);
        const allowAggregations = isAllowAggregations(values.chart.type);
        const allowMetricUnits = isAllowMetricUnits(values.chart.type);
        const allowMetricPercent = isAllowMetricPercent(values.chart.type);
        if (allowAggregations &&
            (!values.aggregations || !values.aggregations.length || isEmpty(values.aggregations[0]))) {
            setFieldValue('aggregations[0]', getDefaultAggregation());
        }
        const allowSubAggregations = isAllowSubAggregations(values.chart.type) && !hasSubAggregation(values.aggregations[0]);
        return (React.createElement(Modal, { destroyOnClose: true, width: "80%", centered: true, open: open, title: title, onCancel: _onCancel, onOk: () => handleSubmit() },
            React.createElement("div", { className: `${styles.wrapper} row w-100` },
                React.createElement("div", { className: "col-8" },
                    React.createElement(Widget, { className: "widget-wrapper", showControlButtons: false },
                        React.createElement(ChartWidget, { className: "h-100", settings: memoizedCastSettings(values), defaultFilter: defaultFilter }))),
                React.createElement("div", { className: "col-4" },
                    React.createElement(FormikField, { name: "chart.title.text", label: t('HYPER_BI_TITLES'), component: MultiLanguageInput, fieldComponent: Input }),
                    React.createElement(Collapse, { className: "mt-2", defaultActiveKey: value?.definitionId ? ['metrics', 'aggregations'] : ['target'], items: [
                            {
                                key: 'target',
                                label: t('HYPER_BI_TARGET'),
                                children: (React.createElement(React.Fragment, null,
                                    React.createElement(FormikField, { name: "definitionId", label: t('ENTITY'), component: ItemMetaSelector, className: "pb-2" }),
                                    React.createElement(FormikField, { name: "chart.type", component: BasicSelect, options: ChartTypesOptions, label: t('HYPER_BI_CHART_TYPE'), language: language })))
                            },
                            {
                                key: 'metrics',
                                label: t('HYPER_BI_METRICS'),
                                collapsible: !values.definitionId ? collapsibleDisabled : undefined,
                                children: (React.createElement(FieldArray, { name: "metrics" }, (arrayHelpers) => {
                                    return (React.createElement(React.Fragment, null,
                                        React.createElement(Collapse, { ghost: true, defaultActiveKey: [0], items: values.metrics
                                                .filter((_, index) => !(!allowManyMetrics && index >= 1))
                                                .map((metric, index) => {
                                                const metricDescription = availableMetrics.find((item) => item.value === metric.type);
                                                let panelTitle = metricDescription?.labels[language] || '';
                                                if (metric.field) {
                                                    panelTitle += ` (${metric.field})`;
                                                }
                                                let allowPercents = allowMetricPercent;
                                                if (!isAllowMetricPercentByMetric(metric)) {
                                                    allowPercents = false;
                                                }
                                                let allowUnits = allowMetricUnits;
                                                if (allowPercents &&
                                                    'inPercents' in metric &&
                                                    metric.inPercents) {
                                                    allowUnits = false;
                                                }
                                                return {
                                                    key: index.toString(),
                                                    label: panelTitle,
                                                    extra: allowManyMetrics && values.metrics.length > 1 ? (React.createElement(Button, { size: "small", type: "link", danger: true, icon: React.createElement(GoX, null), onClick: () => arrayHelpers.remove(index) })) : undefined,
                                                    children: (React.createElement(React.Fragment, null,
                                                        React.createElement(FormikField, { name: `metrics[${index}].type`, component: BasicSelect, options: availableMetrics, label: t('HYPER_BI_METRICS'), language: language, emptyDisabled: false }),
                                                        metric.type !== AggregationType.count && (React.createElement(FormikField, { name: `metrics[${index}].field`, component: SelectPropertyField, label: t('HYPER_BI_PROPERTY'), definitionId: values.definitionId, placeholder: t('SELECT_PROPERTY'), asFilter: true, includeProperty: (displayData) => {
                                                                return isAvailableMetricField(metric.type, displayData);
                                                            }, includeNestedProperties: true })),
                                                        allowPercents && (React.createElement(FormikField, { className: "mb-4", name: `metrics[${index}].inPercents`, label: t('VIEWS__FIELD_IN_PERCENTS'), component: Toggle })),
                                                        allowUnits && (React.createElement(FormikField, { name: `metrics[${index}].units`, label: t('VIEWS__UNITS'), component: MultiLanguageInput, fieldComponent: Input }))))
                                                };
                                            }) }),
                                        allowManyMetrics && (React.createElement(Button, { className: "mt-2", type: "primary", icon: React.createElement(GoPlus, { size: "1.2em", className: "me-2" }), onClick: () => arrayHelpers.push(getDefaultMetric()) }, t('HYPER_BI_ADD_METRIC')))));
                                }))
                            },
                            ...((allowAggregations && [
                                {
                                    key: 'aggregations',
                                    label: t('HYPER_BI_CHART_FORM_AGGREGATIONS_SECTION_LABEL'),
                                    collapsible: !values.definitionId ? collapsibleDisabled : undefined,
                                    children: (React.createElement(Field, { name: "aggregations[0]", allowSubAggregations: allowSubAggregations, component: AggregationField }))
                                }
                            ]) ||
                                []),
                            {
                                key: 'filter',
                                label: t('VIEWS__FILTERS'),
                                collapsible: !values.definitionId ? collapsibleDisabled : undefined,
                                children: (React.createElement(FormikField, { name: "filter.query", component: ConditionQueryField, definitionId: values.definitionId }))
                            }
                        ] })))));
    }));
};
export default EditChartForm;
