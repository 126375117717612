const analyzer = (action, emitter) => {
    // should we track only success? or all attempts as in user intent?
    if (action.type === 'SUBSCRIBE_TO_SEARCH') {
        emitter.event({
            category: 'Subscription',
            action: 'Search',
            label: '-'
        });
    }
};
export default analyzer;
