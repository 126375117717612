import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { t, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import * as Yup from 'yup';
import FormikField from '../../../../common/components/formik/FormikField';
import { FieldsContainer } from '../../../../common/utils/formUtils';
export const getFormSchema = () => {
    return Yup.object().shape({
        host: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        user: Yup.string().required(t('VALIDATIONS__REQUIRED')),
        password: Yup.string().required(t('VALIDATIONS__REQUIRED'))
    });
};
const XDMSIntegrationForm = ({ configuration: { host = '', user = '', password = '' }, disabled }) => {
    const { t } = useI18n();
    return (React.createElement(FieldsContainer, { className: "cp-c-padding-2" },
        React.createElement(FormikField, { name: "host", component: BasicInput, label: `${t('XDMS_HOST')} *`, value: host, disabled: disabled }),
        React.createElement(FormikField, { name: "user", component: BasicInput, label: `${t('XDMS_USER')} *`, value: user, disabled: disabled }),
        React.createElement(FormikField, { name: "password", component: BasicInput, label: `${t('XDMS_PASSWORD')} *`, value: password, type: "password", disabled: disabled })));
};
export default XDMSIntegrationForm;
