import React from 'react';
import styled, { keyframes } from 'styled-components';
const SlidingBlockAnimation = keyframes `
	from { transform: translateX(-100%); }
	to { transform: translateX(100%); }
`;
export const SlidingBlock = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.4),
    rgba(0, 0, 0, 0)
  );
  animation: ${SlidingBlockAnimation} 900ms linear infinite;
`;
const SkeletonContainer = styled.div `
  position: relative;
  overflow: hidden;
`;
export const Skeleton = ({ className, isLoading, children, ...otherProps }) => (React.createElement(SkeletonContainer, { className: className, ...otherProps },
    isLoading !== false && React.createElement(SlidingBlock, null),
    children));
export const TextPlaceholderDiv = styled.div `
  height: ${({ heightInPx }) => (heightInPx ? `${heightInPx}px` : '100%')};
  width: ${({ widthInPx }) => (widthInPx ? `${widthInPx}px` : '100%')};
  background: ${({ colored, theme }) => (colored ? theme.mainColor : theme.skeletonColor)};
  border-radius: ${({ radiusInPx }) => `${radiusInPx ? radiusInPx : '15'}px`};
`;
export const TextPlaceholder = (props) => React.createElement(TextPlaceholderDiv, { ...props });
export default {
    TextPlaceholder,
    Skeleton,
    SlidingBlock
};
