import Indicator from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { i18nChangeAvailableLanguages } from '@hypercharge/hyper-react-base/lib/i18n';
import Config from 'config';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classes from './DisplayTenantProvider.module.scss';
import { useDisplayTenant } from './useDisplayTenant';
const DisplayTenantProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { displayTenant, isFailed, isPending } = useDisplayTenant();
    useEffect(() => {
        if (displayTenant) {
            if (displayTenant.defaultLanguage) {
                Config.DEFAULT_LANGUAGE = displayTenant.defaultLanguage;
            }
            if (displayTenant.availableLanguages) {
                Config.AVAILABLE_LANGUAGES = displayTenant.availableLanguages;
                dispatch(i18nChangeAvailableLanguages(displayTenant.availableLanguages));
            }
            if (displayTenant.logoUrl) {
                Config.TENANT_LOGO_SRC = displayTenant.logoUrl;
            }
        }
    }, [dispatch, displayTenant]);
    if (isFailed) {
        return React.createElement("div", { className: classes.ErrorMessage }, "404");
    }
    if (!displayTenant || isPending) {
        return (React.createElement("div", { className: classes.LoadingIndicatorWrapper },
            React.createElement(Indicator, { className: classes.LoadingIndicator })));
    }
    return children;
};
export default DisplayTenantProvider;
