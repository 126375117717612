import React from 'react';
import { MdLightbulbOutline } from 'react-icons/md';
import styled from 'styled-components';
import { useI18n } from '../../i18n';
import { media } from '../../styling';
const TipsContainer = styled.div `
  margin-${({ position }) => (position === 'above' ? 'bottom' : 'top')}: 1rem;
  text-align: center;

  ${({ mediaBoundary }) => `${media[mediaBoundary]} {
    display: none;
  }`};
`;
const Tip = styled.em `
  font-style: italic;
  font-size: 0.9rem;
  margin-left: 0.5rem;

  @media print {
    display: none;
  }
`;
const MobileHorizontalScrollTip = ({ className, position = 'above', mediaBoundary = 'sm' }) => {
    const { t } = useI18n();
    return (React.createElement(TipsContainer, { className: className, position: position, mediaBoundary: mediaBoundary },
        React.createElement(MdLightbulbOutline, null),
        React.createElement(Tip, null, t('COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP'))));
};
export default MobileHorizontalScrollTip;
