// organize-imports-ignore need for saving ACE-modules sequence for correct build
import React, { useEffect, useMemo, useRef } from 'react';
import { getAce, getAutocompleteProperties } from './utils';
import { get } from 'lodash';
import './mode/computed-expression-mode';
import { AceEditor, AceEditorMode } from '../AceEditor';
/**
 * add sniped
 * https://coderoad.ru/26089258/%D0%A0%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%BE%D1%80-Ace-%D0%B2%D1%80%D1%83%D1%87%D0%BD%D1%83%D1%8E-%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D1%8F%D0%B5%D1%82-%D1%81%D0%BD%D0%B8%D0%BF%D0%BF%D0%B5%D1%82%D1%8B
 *
 * add completer
 * https://www.programmerall.com/article/13031693810/
 */
const langTools = getAce().acequire('ace/ext/language_tools');
const ComputedExpressionEditor = ({ value, propertiesTree, ...otherProps }) => {
    const ref = useRef(null);
    const propertiesCompleter = useMemo(() => ({
        // identifierRegexps: [/[a-zA-Z_0-9]/],
        getCompletions: function (editor, session, pos, prefix, callback) {
            const token = session.getTokenAt(pos.row, pos.column);
            if (token?.type === 'string') {
                callback(null, []);
                return;
            }
            const path = token?.value.split('.').slice(0, -1);
            let properties;
            if (path && path.length) {
                properties = getAutocompleteProperties(get(propertiesTree, path));
            }
            else {
                properties = getAutocompleteProperties(propertiesTree);
            }
            callback(null, properties);
        }
    }), [propertiesTree]);
    const snippetCompleter = useMemo(() => ({
        getCompletions: function (editor, session, pos, prefix, callback) {
            const token = session.getTokenAt(pos.row, pos.column);
            if (token?.type && token.type === 'string') {
                // if constant string
                callback(null, []);
                return;
            }
            if (token?.type === 'identifier' && token.value && token.value.indexOf('.') >= 0) {
                // if variable with dot
                callback(null, []);
                return;
            }
            langTools.snippetCompleter.getCompletions(editor, session, pos, prefix, callback);
        },
        getDocTooltip: function (item) {
            if (langTools.snippetCompleter.getDocTooltip) {
                return langTools.snippetCompleter.getDocTooltip(item);
            }
        }
    }), []);
    const keyWordCompleter = useMemo(() => ({
        getCompletions: function (editor, session, pos, prefix, callback) {
            const token = session.getTokenAt(pos.row, pos.column);
            if (token?.type && token.type === 'string') {
                // if constant string
                callback(null, []);
                return;
            }
            if (token?.type === 'identifier' && token.value && token.value.indexOf('.') >= 0) {
                // if variable with dot
                callback(null, []);
                return;
            }
            langTools.keyWordCompleter.getCompletions(editor, session, pos, prefix, callback);
        },
        getDocTooltip: function (item) {
            if (langTools.keyWordCompleter.getDocTooltip) {
                return langTools.keyWordCompleter.getDocTooltip(item);
            }
        }
    }), []);
    useEffect(() => {
        if (ref.current) {
            ref.current.editor.completers = [snippetCompleter, keyWordCompleter, propertiesCompleter];
            ref.current.editor.commands.removeCommand('find');
        }
    }, [keyWordCompleter, propertiesCompleter, ref, snippetCompleter]);
    return (React.createElement(AceEditor, { ...otherProps, className: "w-100", mode: AceEditorMode.computed_expression, theme: "textmate", ref: ref, value: value ?? '' }));
};
export default ComputedExpressionEditor;
