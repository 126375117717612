import { Button } from '@hypercharge/hyper-react-base/lib/common/button';
import LoadingRectangles from '@hypercharge/hyper-react-base/lib/common/loading-rectangles';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications/notificationActions';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { invalidateItemsQuery } from '../../../cms/hooks/invalidateCache';
import { useEntityItem } from '../../../cms/hooks/useEntityItem';
import ErrorFocus from '../../../common/components/formik/ErrorFocus';
import { FieldsContainer } from '../../../common/utils/formUtils';
import { INTEGRATIONS_PATH, SETTINGS_PATH } from '../../constants';
import { Title } from './Integrations';
import { enableIntegrationModuleById, saveModuleConfig } from './actions';
import { HYPER_INTEGRATION_MODULE } from './constants';
import { getConfigurationFormFor, getEnableActionFor, getFormInitialValuesFor, getFormSchemaFor } from './utils';
const IntegrationModule = ({ moduleId }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const { data: integrationModule, isFetching: isPendingIntegrationModules } = useEntityItem(HYPER_INTEGRATION_MODULE, moduleId, ['*']);
    const enableModule = async (moduleId, newConfig) => {
        const customAction = getEnableActionFor(moduleId);
        try {
            if (customAction) {
                await dispatch(customAction(newConfig));
            }
            else {
                await dispatch(enableIntegrationModuleById(moduleId));
                await dispatch(saveModuleConfig(moduleId, newConfig));
            }
            await invalidateItemsQuery({
                definitionId: HYPER_INTEGRATION_MODULE,
                waitNewData: true,
                ids: [moduleId]
            });
            dispatch(success({
                title: t('SUCCESS'),
                message: t('INTEGRATIONS_MODULE_SUCCESS_UPDATE')
            }));
        }
        catch (e) {
            error({
                title: t('ERROR'),
                message: t('INTEGRATIONS_MODULE_FAILED_UPDATE')
            });
        }
    };
    const onSubmit = async (values) => {
        if (integrationModule) {
            await enableModule(moduleId, values);
        }
    };
    const ConfigurationForm = getConfigurationFormFor(moduleId);
    const formSchema = getFormSchemaFor(moduleId);
    const initialValues = integrationModule && !isEmpty(integrationModule.configuration)
        ? integrationModule.configuration
        : getFormInitialValuesFor(moduleId);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "m-3", inversed: true, to: `${SETTINGS_PATH}${INTEGRATIONS_PATH}/${integrationModule ? integrationModule.integration : ''}` },
            "< ",
            t('INTEGRATIONS_BACK')),
        integrationModule && !isPendingIntegrationModules ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "cp-i-flex" },
                React.createElement("div", { className: "cp-c-col" },
                    React.createElement("div", { className: "cp-i-noshrink cp-c-column cp-c-align-start-center cp-c-padding-1", style: { borderBottom: '1px solid var(--grayscaleColor-5)' } },
                        React.createElement(Title, null, integrationModule.title)))),
            (ConfigurationForm || !integrationModule.enabled) && (React.createElement(FieldsContainer, { className: "w-75 my-3 mx-auto cp-c-padding-2" },
                React.createElement(Formik, { initialValues: initialValues, onSubmit: onSubmit, validateOnBlur: true, validateOnChange: false, enableReinitialize: true, validationSchema: formSchema && formSchema(t) }, ({ handleSubmit, isSubmitting, dirty, values }) => (React.createElement("form", { noValidate: true, onSubmit: handleSubmit },
                    ConfigurationForm && (React.createElement(ConfigurationForm, { moduleId: moduleId, configuration: values, disabled: isSubmitting, dispatch: dispatch })),
                    React.createElement(Button, { className: "mt-4 w-100", loading: isSubmitting, onClick: () => handleSubmit(), type: "button" }, t(integrationModule.enabled ? 'FORM__SAVE' : 'ENABLE_INTEGRATION')),
                    React.createElement(Prompt, { when: dirty && !isSubmitting, message: t('UNSAVED_DATA_CONFIRM') }),
                    React.createElement(ErrorFocus, null)))))))) : (React.createElement(LoadingRectangles, null))));
};
export default IntegrationModule;
