import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { normalizeNumber } from '../../utils';
const TextValidationFields = ({ disabled, onChange, value }) => {
    const { t } = useI18n();
    const onChangeMinLength = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                validations: {
                    ...value.meta.validations,
                    minLength: normalizeNumber(event.target.value)
                }
            }
        });
    }, [onChange, value]);
    const onChangeMaxLength = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                validations: {
                    ...value.meta.validations,
                    maxLength: normalizeNumber(event.target.value)
                }
            }
        });
    }, [onChange, value]);
    const onChangeRegexpPattern = useCallback((event) => {
        onChange({
            ...value,
            meta: {
                ...value.meta,
                validations: {
                    ...value.meta.validations,
                    regexPattern: event.target.value
                }
            }
        });
    }, [onChange, value]);
    return (React.createElement("div", { className: "row" },
        React.createElement(BasicInput, { className: "col-12 col-sm-4", type: "number", label: t('PROPERTY_MIN_LENGTH'), disabled: disabled, value: value.meta.validations?.minLength, onChange: onChangeMinLength }),
        React.createElement(BasicInput, { className: "col-12 col-sm-4", type: "number", label: t('PROPERTY_MAX_LENGTH'), disabled: disabled, value: value.meta.validations?.maxLength, onChange: onChangeMaxLength }),
        React.createElement(BasicInput, { className: "col-12 col-sm-4", type: "text", placeholder: "e.g.: \\w \\d \\s", label: React.createElement(React.Fragment, null,
                t('PROPERTY_TEXT_PATTERN') + ' ',
                React.createElement("a", { href: "https://www.w3schools.com/jsref/jsref_obj_regexp.asp", target: "_blank", rel: "noopener noreferrer" },
                    t('DOCUMENTATION') + ' ',
                    React.createElement(FaExternalLinkAlt, null))), disabled: disabled, value: value.meta.validations?.regexPattern, onChange: onChangeRegexpPattern })));
};
export default TextValidationFields;
