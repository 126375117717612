import { isEmpty } from 'lodash';
import { CMS_API_PATH, ENTITY_ID_PROPERTY_ID, TITLE_PROPERTY_ID } from '../common/utils/constants';
export const fetchDisplayItemMetaList = (languageCode) => ({
    type: 'CMS__FETCH_DISPLAY_ITEM_META_LIST',
    meta: {
        http: {
            path: `${CMS_API_PATH}/display-item-meta`,
            method: 'GET',
            params: { languageCode },
            allowPromiseReject: true
        }
    }
});
// ////////////////////
// Items
// ////////////////////
export const fetchItemsById = (definitionId, ids, extraPayloadProps) => fetchItemsByGroupedDefinitionId({ [definitionId]: ids }, extraPayloadProps);
export const fetchItemsByGroupedDefinitionId = (idsByDefinition, extraPayloadProps) => {
    const responseFields = extraPayloadProps?.responseFields || [
        ENTITY_ID_PROPERTY_ID,
        TITLE_PROPERTY_ID
    ];
    const definitionIds = Object.keys(idsByDefinition);
    const query = definitionIds.reduce((acc, definitionId) => {
        acc.push({
            definitionId: definitionId,
            entityIds: idsByDefinition[definitionId],
            propertyIds: responseFields.includes('*') ? undefined : responseFields
        });
        return acc;
    }, []);
    return {
        type: 'CMS__FETCH_ITEMS_BY_GROUPED_DEFINITION_ID',
        payload: query,
        meta: {
            http: {
                path: `${CMS_API_PATH}/items`,
                method: 'POST',
                actionContext: { idsByDefinition, responseFields }
            }
        }
    };
};
export const deleteItems = (definitionId, payload) => ({
    type: 'CMS__DELETE_ITEMS',
    payload,
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data`,
            allowPromiseReject: true,
            method: 'DELETE',
            actionContext: { ids: payload.ids, definitionId }
        }
    }
});
export const createItem = (definitionId, item, languageCode) => ({
    type: 'CMS__CREATE_ITEM',
    payload: item,
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/entity/create`,
            method: 'POST',
            params: { languageCode },
            allowPromiseReject: true,
            actionContext: { id: item.entityId, definitionId }
        }
    }
});
// ////////////////////
// Items special search for entities
// ////////////////////
export const searchItems = (definitionId, query) => {
    const fullTextFields = isEmpty(query.fullTextFields) || !query.fullTextFields
        ? [TITLE_PROPERTY_ID]
        : query.fullTextFields;
    const responseFields = isEmpty(query.responseFields) || !query.responseFields
        ? [TITLE_PROPERTY_ID]
        : query.responseFields;
    return {
        type: 'CMS__SEARCH_ITEMS',
        payload: { ...query, responseFields, fullTextFields },
        meta: {
            http: {
                path: `${CMS_API_PATH}/search/items/${definitionId}`,
                actionContext: {
                    definitionId,
                    responseFields
                },
                method: 'POST',
                allowPromiseReject: true
            }
        }
    };
};
// ////////////////////
// Export for entities
// ////////////////////
export const exportItems = (definitionId, query) => ({
    type: 'VIEWS__EXPORT_ITEMS',
    payload: query,
    meta: {
        http: {
            path: `${CMS_API_PATH}/search/items/${definitionId}/export`,
            actionContext: {
                definitionId
            },
            method: 'POST',
            allowPromiseReject: true
        }
    }
});
// ////////////////////
// Item properties
// ////////////////////
export const fetchDisplayData = (definitionId, entityId) => ({
    type: 'ENTITY_DATA__FETCH_SINGLE',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/${entityId}`,
            method: 'GET',
            actionContext: {
                definitionId,
                entityId,
                timeStamp: new Date().getTime()
            },
            allowPromiseReject: true
        }
    }
});
export const updateEntityData = (definitionId, entityId, value, languageCode) => ({
    type: 'ENTITY_DATA__UPDATE_VALUE',
    payload: value,
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/${entityId}`,
            method: 'PUT',
            params: { languageCode },
            actionContext: {
                definitionId,
                entityId,
                propertyId: value.propertyId,
                value,
                timeStamp: new Date().getTime()
            },
            allowPromiseReject: true
        }
    }
});
export const fetchEntityDataProperty = (definitionId, entityId, propertyId, languageCode, limit, offset, propertyIdsExcluded) => ({
    type: 'ENTITY_DATA__FETCH_PROPERTY_SINGLE',
    meta: {
        http: {
            path: `${CMS_API_PATH}/history/${definitionId}/${entityId}`,
            method: 'GET',
            params: {
                languageCode,
                propertyId,
                limit,
                offset,
                propertyIdsExcluded: propertyIdsExcluded?.join(',')
            },
            actionContext: { definitionId, entityId, propertyId },
            allowPromiseReject: true
        }
    }
});
// ////////////////////
// Item referrals
// ////////////////////
export const fetchItemReferrals = (definitionId, entityId, languageCode) => ({
    type: 'CMS__FETCH_ITEM_REFERRALS',
    meta: {
        http: {
            path: `${CMS_API_PATH}/definition/${definitionId}/data/${entityId}/referrals`,
            method: 'GET',
            params: { languageCode },
            actionContext: { definitionId, entityId },
            allowPromiseReject: true
        }
    }
});
