import { isEmpty, uniq } from 'lodash';
import { ActivityActionNodeSubtypes, ActivityNodeTypes } from '../common/utils/types';
export const getLastTaskMailStatus = (mailStatuses) => {
    if (!mailStatuses || !mailStatuses.length) {
        return false;
    }
    return mailStatuses[mailStatuses.length - 1];
};
export const isManualTask = (task) => task.taskType === ActivityNodeTypes.ACTION &&
    task.taskSubType === ActivityActionNodeSubtypes.MANUAL;
export const getTaskTitle = (task) => {
    if (!task.taskTitle) {
        return;
    }
    const repeatNumber = (task.counter ?? 0) + 1;
    const suffix = isManualTask(task) && repeatNumber > 1 ? ` (${repeatNumber})` : '';
    return `${task.taskTitle}${suffix}`;
};
export const getManualTaskProperties = (task, type, activity) => {
    const properties = {
        required: task.requiredProperties?.filter((field) => field) || [],
        optional: task.optionalProperties?.filter((field) => field) || []
    };
    if (!task.activity) {
        // Manual task
        return properties[type];
    }
    // Activity task
    const activitySettings = task.activity;
    if (!activity ||
        !activitySettings?.available?.length ||
        !activitySettings.propertyMapping?.[type] ||
        isEmpty(activitySettings.propertyMapping[type])) {
        return [];
    }
    if (!task.activity.propertyMapping || isEmpty(task.activity.propertyMapping)) {
        return properties[type];
    }
    const returnProperties = [];
    for (const propertyId of properties[type]) {
        if (!activitySettings.propertyMapping[type][propertyId]?.length) {
            returnProperties.push(propertyId);
            continue;
        }
        if (activitySettings.propertyMapping[type][propertyId].includes(activity.typeText)) {
            returnProperties.push(propertyId);
        }
    }
    return uniq(returnProperties);
};
