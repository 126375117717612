import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
const TableContext = createContext(undefined);
const TableProvider = ({ data, children }) => {
    const [items, setItems] = useState(data);
    useEffect(() => {
        setItems(data);
    }, [data]);
    const updateItem = useCallback(({ property, entityId, value }) => {
        setItems((prevItems) => {
            let index = -1;
            for (let i = 0; i < prevItems.length; i++) {
                const currentItem = prevItems[i];
                if (currentItem.subRows) {
                    const subIndex = currentItem.subRows.findIndex((subItem) => subItem.entityId === entityId);
                    if (subIndex !== -1) {
                        index = [i, subIndex];
                        break;
                    }
                }
                else if (currentItem.entityId === entityId) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                return prevItems;
            }
            if (Array.isArray(index)) {
                const newItems = [...prevItems];
                newItems[index[0]].subRows[index[1]][property] = value;
                return newItems;
            }
            const newItems = [...prevItems];
            newItems[index][property] = value;
            return newItems;
        });
    }, []);
    const value = useMemo(() => {
        return {
            updateItem,
            items
        };
    }, [items, updateItem]);
    return React.createElement(TableContext.Provider, { value: value }, children);
};
export const useTableData = () => {
    const context = useContext(TableContext);
    if (context == null) {
        throw new Error('useStickyTabs must be used within an StickyTabsProvider');
    }
    return context;
};
export default TableProvider;
