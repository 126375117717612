import ModalConfirmationButton from '@hypercharge/hyper-react-base/lib/common/modal-confirmation-button';
import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { error, success } from '@hypercharge/hyper-react-base/lib/notifications';
import { isString } from 'lodash';
import React, { useMemo } from 'react';
import { FaFilter, FaTrash } from 'react-icons/fa';
import { IoMdEye } from 'react-icons/io';
import { LuUnlink } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import DataTable2Container from '../../../../../../../common/components/data-table/DataTable2Container';
import { ActionWhen } from '../../../../../../../common/components/data-table/types';
import { useBrowserBreakpoints } from '../../../../../../../common/components/with-browser-breakpoints';
import { BROWSE_PATH, BULK_EDIT_PERMISSION } from '../../../../../../../common/utils/constants';
import { useView } from '../../../../../../../views/components/ViewContext';
import { DEFAULT_COLUMNS_VIEW } from '../../../../../../../views/constants';
import { useEntityDisplayData } from '../../../../../../common/components/withEntityDisplayData';
import { CMS_PATH, ENTITY_ID_PROPERTY_ID } from '../../../../../../common/utils/constants';
import useDeleteItems from '../../../../../../hooks/useDeleteItems';
import useDisplayItemMeta from '../../../../../../hooks/useDisplayItemMeta';
import { getFlattenedDisplayDataList } from '../../../../../utils';
import { QuickAddItemButton } from '../../../../item/QuickAddItemButton';
import BulkEditButton from '../../../../items/BulkEditButton/BulkEditButton';
import BulkEditIcon from '../../../../items/BulkEditButton/BulkEditIcon';
import { memoizedGetItemsTableColumns } from '../../../../items/utils';
import { isEntityInSingleMode } from '../../../utils';
const HEIGHT_TABLE = 370;
export const EntityDisplayAsTable = ({ meta, value, onSave, onShowFilterModal, canEdit, updatePropertyData, bulkUpdateItems, permissionFieldsConfig, nestedRequiredFieldsConfig }) => {
    const { isMobile } = useBrowserBreakpoints();
    const { displayData } = useEntityDisplayData(meta.definitionId);
    const { filterRequest, setSelectedItems, setIsAllItemsSelected, initialSelectedItems, rowGrouping, rowHeightType } = useView();
    const { mutateAsync: asyncDeleteItems } = useDeleteItems({
        definitionId: meta.definitionId
    });
    const dispatch = useDispatch();
    const { data: displayItemMeta } = useDisplayItemMeta({ definitionId: meta.definitionId });
    const isRestrictedToLink = meta?.selector?.onlySelectedSwitch?.restrictToLink;
    const isDeleteOnRemove = meta?.selector?.onlySelectedSwitch?.deleteOnRemove;
    const contentMaxHeight = meta?.selector?.maxHeight;
    const singleMode = isEntityInSingleMode(meta);
    const { language, t } = useI18n();
    const columns = useMemo(() => {
        const columnPropertyIds = filterRequest?.fullTextFields || DEFAULT_COLUMNS_VIEW;
        const viewableEntityProperties = getFlattenedDisplayDataList(displayData);
        return memoizedGetItemsTableColumns(meta.definitionId, columnPropertyIds, viewableEntityProperties, language, {
            id: 'open-item',
            accessorKey: 'open-item',
            header: '',
            cell: ({ row }) => (React.createElement("a", { href: `${CMS_PATH}/${row.original.definitionId}${BROWSE_PATH}/${row.original.entityId}`, target: "_blank", rel: "noreferrer", className: "safe-space" },
                React.createElement(IoMdEye, null)))
        }, rowHeightType, nestedRequiredFieldsConfig, updatePropertyData, permissionFieldsConfig);
    }, [
        filterRequest?.fullTextFields,
        displayData,
        meta.definitionId,
        language,
        rowHeightType,
        nestedRequiredFieldsConfig,
        updatePropertyData,
        permissionFieldsConfig
    ]);
    const leftActions = useMemo(() => {
        const actionsList = [];
        if (!isRestrictedToLink) {
            actionsList.push({
                key: 'select',
                buttonProps: {
                    className: 'FilterItemButton save-btn',
                    tooltip: 'Select item(s)',
                    inversed: true
                },
                buttonContent: (React.createElement(React.Fragment, null,
                    React.createElement(FaFilter, null))),
                action: () => {
                    onShowFilterModal?.();
                }
            });
        }
        return actionsList;
    }, [isRestrictedToLink, onShowFilterModal]);
    const actions = useMemo(() => {
        const actionsList = [];
        actionsList.push({
            key: 'bulkedit',
            when: ActionWhen.selected,
            // @ts-expect-error
            buttonComponent: BulkEditButton,
            buttonContent: (React.createElement(React.Fragment, null,
                React.createElement(BulkEditIcon, null),
                !isMobile && React.createElement("span", { className: "ms-1" },
                    " ",
                    t('BULK_EDIT_TITLE')))),
            buttonProps: {
                drawerTitle: t('BULK_EDIT_TITLE'),
                definitionId: meta.definitionId,
                permissionFieldsConfig,
                preventDefaultSubmit: !!bulkUpdateItems
            },
            clearSelection: true,
            permission: BULK_EDIT_PERMISSION,
            action: async ({ selectedIds }, item) => {
                if (!selectedIds?.length) {
                    return;
                }
                // @ts-expect-error
                await bulkUpdateItems?.(item, selectedIds);
            }
        });
        if (displayItemMeta?.canDelete && isDeleteOnRemove) {
            actionsList.push({
                key: 'delete',
                when: ActionWhen.selected,
                buttonComponent: ModalConfirmationButton,
                buttonContent: (React.createElement(React.Fragment, null,
                    React.createElement(FaTrash, { preserveAspectRatio: "none" }),
                    !isMobile && React.createElement("span", { className: "ms-1" },
                        " ",
                        t('COMMON__DELETE')))),
                buttonProps: {
                    confirmationTitle: t('ENTITIES_DELETE_CONFIRMATION_TITLE'),
                    warningMessage: t('DELETE_WARNING_CONCERNING_REFERRALS'),
                    confirmationMessage: t('ENTITIES_DELETE_CONFIRMATION_MESSAGE'),
                    destructive: true
                },
                clearSelection: true,
                resetPage: true,
                action: async ({ selectedIds, allSelected, query }) => {
                    try {
                        await asyncDeleteItems({
                            ids: selectedIds,
                            filterRequest: query
                        });
                        if (rowGrouping && allSelected) {
                            await onSave?.([]);
                            setIsAllItemsSelected(false);
                            return;
                        }
                        if (value && selectedIds) {
                            await onSave?.(value.filter((id) => !selectedIds.includes(id)));
                        }
                        setSelectedItems(initialSelectedItems);
                        dispatch(success({
                            title: t('COMMON__SUCCESS'),
                            message: t('GENERIC_ASYNC_CHANGES_WARNING')
                        }));
                    }
                    catch (e) {
                        dispatch(error({
                            title: t('COMMON__FAILURE'),
                            message: `${t('ENTITIES_DELETE_FAIL')} ${selectedIds ? selectedIds.length : undefined}`
                        }));
                    }
                }
            });
        }
        if (canEdit && !isDeleteOnRemove) {
            actionsList.push({
                key: 'select',
                when: ActionWhen.selected,
                buttonProps: {
                    className: 'd-flex align-items-center ActionDeleteButton',
                    danger: true
                },
                buttonContent: (React.createElement(React.Fragment, null,
                    React.createElement(LuUnlink, { preserveAspectRatio: "none" }),
                    !isMobile && React.createElement("span", { className: "ms-1" }, t('COMMON__REMOVE')))),
                action: async ({ selectedIds, allSelected }) => {
                    if (rowGrouping && allSelected) {
                        await onSave?.([]);
                        setIsAllItemsSelected(false);
                        return;
                    }
                    if (value && selectedIds) {
                        await onSave?.(value.filter((id) => !selectedIds.includes(id)));
                    }
                    setSelectedItems(initialSelectedItems);
                }
            });
        }
        if (displayItemMeta?.canCreate) {
            actionsList.push({
                key: 'add',
                buttonComponent: QuickAddItemButton,
                buttonProps: {
                    definitionId: meta.definitionId,
                    entityTitle: displayItemMeta?.title || t('NO_TITLE'),
                    className: 'QuickAddItemButton',
                    requiredFieldsConfig: nestedRequiredFieldsConfig
                },
                action: (_, createdEntityId) => {
                    dispatch(success({
                        title: t('COMMON__SUCCESS'),
                        message: t('CMS__CREATE_ITEM_SUCCESS')
                    }));
                    if (isString(createdEntityId)) {
                        void onSave?.(singleMode || !value ? [createdEntityId] : [...value, createdEntityId]);
                    }
                }
            });
        }
        return actionsList;
    }, [
        isMobile,
        t,
        meta.definitionId,
        permissionFieldsConfig,
        bulkUpdateItems,
        displayItemMeta?.canDelete,
        displayItemMeta?.canCreate,
        displayItemMeta?.title,
        isDeleteOnRemove,
        canEdit,
        asyncDeleteItems,
        rowGrouping,
        value,
        setSelectedItems,
        initialSelectedItems,
        dispatch,
        onSave,
        setIsAllItemsSelected,
        nestedRequiredFieldsConfig,
        singleMode
    ]);
    return (React.createElement("div", { className: 'w-100 mt-3 d-flex flex-column row-gap-2' },
        React.createElement(DataTable2Container, { noKanban: true, noRowHeightAction: true, noFilters: true, contentHeight: `${HEIGHT_TABLE}px`, contentMaxHeight: contentMaxHeight ? `${contentMaxHeight}px` : '', idField: ENTITY_ID_PROPERTY_ID, columns: columns, isPending: false, singleMode: singleMode, showStartProcessSelector: false, actions: actions, leftActions: leftActions })));
};
