import { getTranslation, useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import DropdownArrowButton from '../../../../../../common/components/DropdownArrowButton';
import { TooltipTruncate } from '../../../../../../common/components/TooltipTruncate';
import DataListTabs from '../../../../components/item-property/DataList/DataListTabs';
import SectionListPreview from './SectionListPreview';
import styles from './TabsPreview.module.scss';
const TabsPreview = ({ definitionId, entity, sections, tabs }) => {
    const { language } = useI18n();
    const [activeTabKey, setActiveTabKey] = useState('');
    useEffect(() => {
        if (!tabs.length) {
            return;
        }
        if (!activeTabKey || tabs.findIndex((tab) => tab.tabId === activeTabKey) === -1) {
            setActiveTabKey(tabs[0].tabId);
        }
    }, [activeTabKey, tabs]);
    const handleTabChange = useCallback((tab) => {
        setActiveTabKey(tab);
    }, []);
    return (React.createElement("div", { className: `${styles.tabWrapper} ${styles.collapse}` },
        React.createElement(DataListTabs, { className: "tabs-with-styled-more-btn", activeKey: activeTabKey, type: "card", moreIcon: React.createElement(DropdownArrowButton, { injectable: true }), onChange: handleTabChange, items: tabs.map((tab) => {
                return {
                    key: tab.tabId,
                    label: (React.createElement("div", { className: "tabLabel fw-400 fs-6" },
                        React.createElement(TooltipTruncate, null, getTranslation(tab.titles, language)))),
                    children: (React.createElement(SectionListPreview, { definitionId: definitionId, sections: sections?.filter((section) => section.tabId === tab.tabId), entity: entity }))
                };
            }) })));
};
export default TabsPreview;
