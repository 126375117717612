import { useI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import ConfirmationButtonModal from '../ConfirmationButtonModal';
const ConfirmationCloseButton = ({ isShowModal, onClose }) => {
    const { t } = useI18n();
    return isShowModal ? (React.createElement(ConfirmationButtonModal, { className: "bg-transparent p-0 border-0", onClick: () => onClose(), confirmationTitle: t('STICKY_TAB_TITLE'), confirmationMessage: t('STICKY_TAB_CONFIRMATION') },
        React.createElement(IoClose, { size: 24 }))) : (React.createElement(IoClose, { size: 24, onClick: (e) => {
            e.stopPropagation();
            onClose();
        } }));
};
export default ConfirmationCloseButton;
